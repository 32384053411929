// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-university .university-name {
  font-size: 16px;
  font-weight: 500;
  color: #1677ff; }
`, "",{"version":3,"sources":["webpack://./src/pages/category-management/university-management/university-management.scss"],"names":[],"mappings":"AAAA;EAEQ,eAAe;EACf,gBAAgB;EAChB,cAAc,EAAA","sourcesContent":[".table-university {\n    .university-name {\n        font-size: 16px;\n        font-weight: 500;\n        color: #1677ff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
