import React, { useEffect, useRef, useState } from "react";
import { Form } from "antd";

import { toNumber } from "lodash";
import { SortableList } from "../../../../../../../SortableList";

function Connect({ data }: any) {
  const [answers, setAnswers] = useState<any>([]);

  const connectRef = useRef<HTMLDivElement | null>(null);
  const [itemHeight, setItemHeight] = useState<number | undefined>(0);

  useEffect(() => {
    setItemHeight(connectRef.current?.clientHeight);
  }, [connectRef.current, window.innerWidth]);

  useEffect(() => {
    if (data?.questions && data?.questions?.length > 0) {
      setAnswers(
        data?.questions?.map((ans: any) => ({
          id: ans?.content?.right.key,
          value: ans?.content?.right.content,
        }))
      );
    }
  }, [data]);

  const handleDragChange = () => {};

  return (
    <div className="flex">
      <div className="connect-quest w-50 mr-3">
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: number) => (
            <div
              ref={connectRef}
              className={`connect-quest-item-connecting`}
              key={`${quiz.uuid}-${index}-left`}
            >
              <span className="font-size-14 line-height-24">
                {quiz?.content?.left?.content}
              </span>
            </div>
          ))}
      </div>
      <Form.Item className="w-50 ml-3">
        <SortableList
          items={answers}
          disabled
          className="gap-16"
          onChange={handleDragChange}
          renderItem={(record: any) => (
            <SortableList.Item
              className="connect-item"
              id={record.value}
              styles={{ height: toNumber(itemHeight) + 2 }}
            >
              <div className="font-size-14">{record.value}</div>
            </SortableList.Item>
          )}
        />
      </Form.Item>
    </div>
  );
}

export default Connect;
