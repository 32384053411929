import {
  CheckCircleOutlined,
  DownOutlined,
  FileWordOutlined,
  FundProjectionScreenOutlined,
  HolderOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  message,
  Modal,
  Radio,
  RadioChangeEvent,
  Spin,
  Tree,
  TreeDataNode,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../../../../components/modal/common";
import { routesConfig } from "../../../../../config/routes";
import {
  getReportAttendance,
  getSettingAttendanceReport,
} from "../../../../../service/setting-attendance";
import { useStudyManagementStore } from "../../../../../stores/stores";
import { getPermissionsAuthority } from "../../../../../service/setting-authority";
import { DEFAULT_PERMISSION_ATTENDANCE } from "../../../../../constants/setting-identity";

const { Title, Text } = Typography;

interface IProps {
  id: string;
  setTitle: any;
}

const {
  ATTENDANCE,
  NOTIFICATION,
} = DEFAULT_PERMISSION_ATTENDANCE;

const MyCourseContentDetail = ({ id, setTitle }: IProps) => {
  const navigation = useNavigate();
  const { setTabAttendance } = useStudyManagementStore((state) => state);

  const [dataCourse, setDataCourse] = useState([]);
  const [attendanceDoc, setAttendanceDoc] = useState({
    title: "",
    id: 0,
  });

  const [isDisable, setIsDisable] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenLoading, setIsOpenLoading] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [permissionAttendance, setPermissionAttendance] = useState<string[]>(
    []
  );
  const [valueCheckIn, setValueCheckIn] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();

  const fetchPermissionData = async () => {
    try {
      const res = await getPermissionsAuthority(`${id}`);
      const { data } = res.data;
      setPermissionAttendance(data?.attendancePermission);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPermissionData();
  }, []);

  const onCloseModal = () => {
    setIsDisable(false);
    setIsOpenForm(false);
    setIsOpenConfirm(false);
    setIsOpenSuccess(false);
    setIsOpenLoading(false);
  };

  const handleConfirmForm = () => {
    setIsOpenForm(false);
    setIsOpenConfirm(true);
  };
  const handleConfirmCommit = async () => {
    setIsOpenLoading(true);
    setIsOpenConfirm(false);
    try {
      await getReportAttendance(attendanceDoc.id, valueCheckIn);
      setIsOpenLoading(false);
      setIsOpenSuccess(true);
    } catch (error) {
      setIsOpenLoading(false);
    }
  };
  const handleConfirmSuccess = () => {
    setIsOpenSuccess(false);
    messageApi.open({
      type: "success",
      content: "Kết quả điểm danh đã được lưu thành công",
    });
  };
  const onChange = (e: RadioChangeEvent) => {
    setValueCheckIn(e.target.value);
  };
  const handleAttendance = (title: string, id: number) => {
    setAttendanceDoc({
      title: title,
      id: id,
    });
    if (isActive) {
      setIsDisable(true);
    } else {
      setIsOpenForm(true);
    }
  };
  const handleNavigateReport = (id: number) => {
    setTabAttendance("3");
    navigation(`/management-study/${routesConfig.reportCourseDocument}/${id}`);
  };
  const CommonSession = (props: any) => {
    const { name, mooc_course_sequence } = props.value;
    const treeData: TreeDataNode[] = mooc_course_sequence?.map(
      (value: any) => ({
        title: value?.name,
        key: value?.id,
        icon: <FundProjectionScreenOutlined />,
        children:
          value?.mooc_course_unit
            ?.flatMap((item: any) => item?.mooc_course_block)
            .map((list: any) => {
              if (list?.module === "video") {
                return {
                  title: (
                    <Flex
                      justify="space-between"
                      align="center"
                      wrap="wrap"
                      className="border-b pl-2 py-2"
                    >
                      <div className="py-2">
                        <Flex>
                          <Typography.Text className="pl-2">
                            {list.title}
                          </Typography.Text>
                        </Flex>
                      </div>
                      <Flex className="w-full sm:w-fit">
                        {permissionAttendance.includes(ATTENDANCE) &&<Button
                          className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                          onClick={() =>
                            handleAttendance(list?.title, list?.id)
                          }
                        >
                          Điểm danh ngay
                        </Button>}
                        {[ATTENDANCE, NOTIFICATION].some(element => permissionAttendance.includes(element)) && <Button
                          className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                          onClick={() => handleNavigateReport(list?.id)}
                        >
                          Xem báo cáo
                        </Button>}
                      </Flex>
                    </Flex>
                  ),
                  key: list?.id,
                  icon: <FileWordOutlined />,
                };
              }
            })
            .filter((item: any) => item) || [],
      })
    );
    return (
      <Card className="mt-4">
        <Flex className="border-b pb-2">
          <HolderOutlined style={{ fontSize: "40px", color: "#91D5FF" }} />
          <Title level={4} className="">
            {name}
          </Title>
        </Flex>
        <Tree
          showIcon
          className="tree-course"
          defaultExpandAll
          switcherIcon={<DownOutlined />}
          treeData={treeData}
        />
      </Card>
    );
  };

  const fetchDataReport = async () => {
    try {
      const response = await getSettingAttendanceReport(id);
      const { data } = response.data;
      setTitle(data[0]?.name);
      setDataCourse(data[0]?.mooc_course_section || []);
      setIsActive(data[0]?.mooc_setting_attendance[0]?.is_active);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataReport();
  }, []);

  return (
    <div className="w-full lg:w-2/3 xl:w-1/2 m-auto my-12">
      {contextHolder}
      <Card className="mt-4">
        <Flex justify="space-between">
          <div>
            <Title level={3} className="!leading-3">
              Thực hiện điểm danh
            </Title>
            <Text className="text-[#00000073]">
              Chi tiết thông tin điểm danh theo bài giảng
            </Text>
          </div>
        </Flex>
      </Card>
      {dataCourse?.map((courseData) => (
        <CommonSession value={courseData} />
      ))}
      <Modal
        title={
          <div>
            <InfoCircleOutlined
              style={{ color: "#1677FF", marginRight: "10px" }}
            />
            <span>Tính năng đang bị vô hiệu hóa</span>
          </div>
        }
        open={isDisable}
        footer=""
        centered
        width={500}
        closeIcon={false}
        onCancel={() => setIsDisable(false)}
        className="processing-checkin-modal"
      >
        <div className="px-6">
          Vui lòng tắt chế độ điểm danh tự động trên hệ thống MOOC để sử dụng
          tính năng điểm danh thủ công
        </div>
      </Modal>
      <CommonModal
        open={isOpenForm}
        title={"Chọn hình thức điểm danh"}
        desc={
          <Radio.Group onChange={onChange} value={valueCheckIn}>
            <Radio value={0}>Bằng địa chỉ tham gia Video</Radio>
            <Radio value={1}>Bằng khuôn mặt</Radio>
          </Radio.Group>
        }
        closeIcon={false}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmForm}
        closeModal={onCloseModal}
      />
      <CommonModal
        open={isOpenConfirm}
        title={"Xác nhận bắt đầu tiến trình điểm danh"}
        desc={
          <div>
            Bạn chắc chắn xác nhận và tiến hành điểm danh bài{" "}
            {attendanceDoc.title}?
          </div>
        }
        closeIcon={true}
        okText={"Bắt đầu"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmCommit}
        closeModal={onCloseModal}
        className={""}
      />
      <Modal
        title="Đang xử lý . . ."
        open={isOpenLoading}
        footer=""
        centered
        onCancel={onCloseModal}
        className="processing-checkin-modal"
      >
        <div className="text-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
          <p className="pt-4">
            {" "}
            Hệ thống đang tiến hành điểm danh. Vui lòng chờ
          </p>
        </div>
      </Modal>
      <CommonModal
        open={isOpenSuccess}
        title={"Hoàn tất điểm danh"}
        desc={
          <div>
            Tiến trình điểm danh đã hoàn tất. Xác nhận để lưu kết quả điểm danh
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <CheckCircleOutlined
            style={{ color: "#52C41A", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmSuccess}
        closeModal={onCloseModal}
        className={""}
      />
    </div>
  );
};

export default MyCourseContentDetail;
