// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transcript-list .page-header-group--layout {
  z-index: 1; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-management/transcript-list/TranscriptList.scss"],"names":[],"mappings":"AAAA;EAEI,UAAU,EAAA","sourcesContent":[".transcript-list {\n  .page-header-group--layout {\n    z-index: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
