import React, { useEffect, useRef, useState } from "react";
import { Form } from "antd";

import { isArray, toNumber } from "lodash";
import { SortableList } from "../../../../../../components/SortableList";
import { QuizAnsProps } from "../../../../../../types/scoring-course";

function Connect({ data, showCorrect, result }: QuizAnsProps) {
  const currentAnsOfUser = result?.results[0];

  const [answers, setAnswers] = useState<any>([]);

  const connectRef = useRef<HTMLDivElement | null>(null);
  const [itemHeight, setItemHeight] = useState<number | undefined>(0);

  useEffect(() => {
    setItemHeight(connectRef.current?.clientHeight);
  }, [connectRef.current, window.innerWidth]);

  useEffect(() => {
    if (showCorrect) {
      if (data?.questions && data?.questions?.length > 0) {
        setAnswers(
          data?.questions?.map((ans: any) => ({
            id: ans?.content?.right.key,
            value: ans?.content?.right.content,
          }))
        );
      }
    } else {
      if (isArray(currentAnsOfUser)) {
        setAnswers(
          currentAnsOfUser?.map((ans: any) => ({
            id: ans?.content?.right.key,
            value: ans?.content?.right.content || "N/A",
          }))
        );
      }
    }
  }, [data, showCorrect, currentAnsOfUser]);

  const handleDragChange = () => {};

  const renderConnect = (isTrue: boolean | null) => {
    if (showCorrect) return "connect-quest-item-connecting";

    if (isTrue === true) return "connect-quest-item-connecting";
    if (isTrue === false) return "connect-wrong";

    return "not-connect";
  };

  const isCorrect = (key: string) => {
    if (!currentAnsOfUser) return false;

    if (isArray(currentAnsOfUser)) {
     
      for (let i = 0; i < currentAnsOfUser?.length; i++) {
        if (currentAnsOfUser[i]?.content?.left?.key === key) return currentAnsOfUser[i]?.isTrue;
      }
    }
  };

  return (
    <div className="flex">
      <div className="connect-quest w-50 mr-3">
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: number) => (
            <div
              ref={connectRef}
              className={`connect-quest-item ${renderConnect(
                isCorrect(quiz?.content?.left?.key)
              )}`}
              key={`${quiz.uuid}-${index}-left`}
            >
              <span className="font-size-14 line-height-24">
                {quiz?.content?.left?.content}
              </span>
            </div>
          ))}
      </div>
      <Form.Item className="w-50 ml-3">
        <SortableList
          items={answers}
          disabled
          className="gap-16"
          onChange={handleDragChange}
          renderItem={(record: any) => (
            <SortableList.Item
              className="connect-item"
              id={record.value}
              styles={{ height: toNumber(itemHeight) + 2 }}
            >
              <div className="font-size-14">{record.value}</div>
            </SortableList.Item>
          )}
        />
      </Form.Item>
    </div>
  );
}

export default Connect;
