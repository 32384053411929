import { Modal, Typography } from "antd";
import React, { useState } from "react";
import ConstructionPlan from "../../../mooc-course-construction-plan1/components/ConstructionPlan";
import { TypeCourseConstructionPlan } from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { FileDoneOutlined } from "@ant-design/icons";
import { CreareCoursePlanArroval } from "../../../../../types/course-plan-managment/mooc-approval";
import { approvalStatusEum } from "../../constants/data";
import { approveCoursePlan } from "../../../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import { HelpService } from "../../../../../service/helper.service";
import { get } from "lodash";
import { useAuthStore } from "../../../../../stores/stores";

interface ModalCourseConstructApprovalProps {
  isOpen: boolean;
  setIsOpen: any;
  dataCourseStructure: any;
  expandAll: any;
  setExpandAll: any;
  expandedKeys: any;
  setExpandedKeys: any;
  courseConstructDetail?: TypeCourseConstructionPlan;
  handleListCourse: () => void;
}

const ModalCourseConstructApproval = (
  props: ModalCourseConstructApprovalProps
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState<boolean>(false);
  const {
    isOpen,
    setIsOpen,
    dataCourseStructure,
    expandAll,
    setExpandAll,
    expandedKeys,
    setExpandedKeys,
    courseConstructDetail,
    handleListCourse,
  } = props;

  const helpService = new HelpService();
  const userId = useAuthStore((states) => states.user?.id);
  const handleCancel = () => {
    setIsOpen(false);
  };
  const handleOk = () => {
    setIsOpenModalConfirm(true);
  };
  const handleCancelConfirm = () => {
    setIsOpenModalConfirm(false);
  };

  const handleOkConfirm = () => {
    const dataSend: CreareCoursePlanArroval = {
      userId: Number(userId),
      courseId: Number(courseConstructDetail?.courseConstructionPlanId),
      organzationId: courseConstructDetail?.organzationId ?? undefined,
      courseStatus: approvalStatusEum.APPROVED,
    };
    approveCoursePlan(dataSend).then((res) => {
      const { data, statusCode, message } = res.data;
      if (statusCode === 200) {
        handleListCourse();
        helpService.successMessage(get(data, "message", message));
        setIsOpen(false);
        setIsOpenModalConfirm(false);
      }
    });
  };
  return (
    <>
      <Modal
        open={isOpen}
        title={`Phê duyệt nhanh kế hoạch ${courseConstructDetail?.registrationName}`}
        width={900}
        confirmLoading={isLoading}
        centered={true}
        onOk={handleOk}
        okText="Phê duyệt"
        onCancel={handleCancel}
        cancelText="Huỷ"
      >
        <ConstructionPlan
          dataCourseStructure={dataCourseStructure}
          expandAll={expandAll}
          setExpandAll={setExpandAll}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
        />
      </Modal>

      {isOpenModalConfirm && (
        <Modal
          open={isOpenModalConfirm}
          width={450}
          centered={true}
          onOk={handleOkConfirm}
          okText="Phê duyệt"
          onCancel={handleCancelConfirm}
          cancelText="Huỷ"
          closeIcon={""}
        >
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 35,
              }}
            >
              <FileDoneOutlined
                style={{ color: "#1890FF", marginRight: 12, fontSize: 22 }}
              />
              <Typography.Text
                style={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: "500",
                }}
              >
                Xác nhận phê duyệt kế hoạch khoá học
              </Typography.Text>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default ModalCourseConstructApproval;
