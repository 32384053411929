// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.question-true-false-box .radio-center {
  display: flex;
  width: 100%; }

.question-true-false-box .gap-item {
  gap: 18px;
  display: flex;
  flex-direction: column; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/question-item/true-or-false/styles.scss"],"names":[],"mappings":"AAAA;EAQI,aAAa;EACb,WAAW,EAAA;;AATf;EAYI,SAAS;EACT,aAAa;EACb,sBAAsB,EAAA","sourcesContent":[".question-true-false-box {\n  .padding-bottom-box{\n    // margin-bottom: 12px;\n    .padding-bottom-radio{\n        // margin-bottom: 12px;\n    }\n  }\n  .radio-center{\n    display: flex;\n    width: 100%;\n  }\n  .gap-item{\n    gap: 18px;\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
