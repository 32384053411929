import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { routesConfig } from "../../../../../../config/routes";
import { Col, Layout, Row, Spin, Tabs, TabsProps, Tooltip } from "antd";
import PageHeader from "../../../../../../components/page-header/PageHeader";
import "./index.scss";
import grapesjs, { Block, Blocks, Category, Editor } from "grapesjs";
import GjsEditor from "@grapesjs/react";
import gjsPresetWeb from "grapesjs-preset-webpage";
import gjsTailWind from "grapesjs-tailwind";
import { getTemplateByCourseId } from "../../../../../../service/page-builder/page-builder";
import ApprovedInformationTab from "../../../../../../components/approved-information-tab/approvedInformationTab";
import parse, { domToReact } from "html-react-parser";
import { isEmpty } from "lodash";
import { uiDefault } from "../../../../../page-builder/grapesjs-ui";
var indexDB = require("idbcache").default;

type Props = {
  setTab: (value: string) => void;
  tab: string;
  mode: string;
};

export default function IntroductionPage(props: Props) {
  const { setTab, tab, mode } = props;
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // getTemplateCourseBrowse()
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const reloadAllTemplate = (editor: Editor, objTemplate: string) => {
    const parseCourseSavedTempalte = JSON.parse(objTemplate);
    editor.setComponents(parseCourseSavedTempalte.html);
    editor.setStyle(parseCourseSavedTempalte.css);
  };

  const getDetailTemplatesByCoueseId = (cb?: any) => {
    getTemplateByCourseId(params?.id).then((res) => {
      const { data } = res.data;
      cb && cb(data);
    });
  };

  const loadDefaultUI = (editor: Editor) => {
    editor.setComponents(uiDefault.html);
    editor.setStyle(uiDefault.css);
  };

  const onEditor = (editor: Editor) => {
    editor.on("component:selected", (res) => {
      if(mode && mode === 'view') {
        res.set({
          editable: false,
          resizable: false,
          draggable: false,
        });

        // Prevent deletion
        // Override the remove method to prevent deletion
        res.remove = function() {
          return this;
        }
      }
    })

    // Prevent movement
    editor.on("component:drag:end", () => {
      if (mode && mode === 'view') {
        editor.UndoManager.undo();
        return;
      }
    });
  };

  const checkTemplateIsNull = (editor: Editor) => {
    const components = editor.getComponents();
    if (components.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const addDetailTemplatesByCoueseId = (editor: Editor) => {
    indexDB.get("courseSavedTemplete-" + params?.id).then((val: any) => {
      if (val) {
        reloadAllTemplate(editor, val);
      }
      getDetailTemplatesByCoueseId((res: any) => {
        if (!isEmpty(res)) {
          const checkUIIsNull = checkTemplateIsNull(editor);
          if (
            checkUIIsNull &&
            res.template === null &&
            res.template_draft === null &&
            res.template_status_code === null
          ) {
            loadDefaultUI(editor);
          } else {
            reloadAllTemplate(editor, res.template);
          }
        }
      });
    });
  };

  return (
    <>
      <div className="w-full">
        <div className="course-browse-layout course-wrapper flex">
          <div className="w-full">
            <GjsEditor
              grapesjs={grapesjs}
              className={`my-editor ${(mode === 'view') ? 'view' : ''}`}
              grapesjsCss="https://unpkg.com/grapesjs/dist/css/grapesjs.min.css"
              waitReady={true}
              options={{
                height: "calc(100vh)",
                storageManager: false,
                fromElement: true,
                showOffsets: true,
                plugins: [
                  gjsPresetWeb,
                  gjsTailWind,
                  addDetailTemplatesByCoueseId,
                ],

                pluginsOpts: {
                  "grapesjs-preset-webpage": {
                    modalImportTitle: "Import Template",
                    modalImportLabel:
                      '<div style="margin-bottom: 10px; font-size: 13px;">Paste here your HTML/CSS and click Import</div>',
                    modalImportContent: (editor: Editor) => {
                      return (
                        editor.getHtml() +
                        "<style>" +
                        editor.getCss() +
                        "</style>"
                      );
                    },
                  },
                },
              }}
              onEditor={onEditor}
            />
          </div>
        </div>
      </div>
    </>
  );
}
