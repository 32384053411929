import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigToUploadBase } from "../../config/api/configApiUploadFile";

export const createFileAutoScore: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/test-result/createMany`, dataReq);
};

export const getStudentAutoScore: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/score/search-by-block`, dataReq);
};

export const getCourseAutoScore: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.get(`/score/get-course/${id}`);
};

export const getFormExamAutoScore: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/score/get-block/${id}`);
};

export const getPermissionAutoScore: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/setting-score-authority/get/${id}`);
};

export const getShareAutoScore: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.get(`/report-score-share/get/${id}`);
};

export const getListStudentExam: (
  param: any,
  id: number
) => Promise<AxiosResponse<any>> = (param, id) => {
  return axiosConfig.get(
    `/score-manage/get/${id}?search=${param.search}&skip=${param.skip}&take=${param.take}&sort=${param.sort}`
  );
};

export const getListAnswerByStudent: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.get(`/score/get-by-id/${id}`);
};

export const getLastByBlock: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.get(`/score-manage/get-last-by-block/${id}`);
};

export const getDetailAutoScore: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/score-manage/get-by-id`, dataReq);
};

export const getManagementAutoScore: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/score-manage/search-by-block`, dataReq);
};

export const getRatingListAutoScore: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/score/search-by-score-manage`, dataReq);
};

export const testResultAutoScore: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfigToUploadBase.put(`/test-result/auto-score/${id}`, dataReq);
};

export const updatePermissionAutoScore: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-score-authority/update/${id}`, dataReq);
};

export const updateShareScore: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/report-score-share/update/${id}`, dataReq);
};

export const updateRatingScore: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/score-manage/update/${id}`, dataReq);
};

export const updateScoreById: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/score/update/${id}`, dataReq);
};

export const deleteAutoScore: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.delete(`/score-manage/delete/${id}`);
};
