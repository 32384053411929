import { Form, TableColumnsType, TableProps, Typography } from "antd";
import { formatDateV2 } from "../../../../../utils/converDate";
import "../student-history/studentHistory.scss";
import FilterTable from "../../components/filter-table/FilterTable";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import TableData from "../../../../table-data/TableData";
import { isEmpty } from "lodash";
import { getStudentDetailByFilter } from "../../../../../service/category-management/student-management";
import FormFilter from "../../components/filter-group/FormFilter";


interface DataType {
    key: React.Key;
    id: number;
    action: string;
    description: string;
    createdBy: string;
    createdDate: string;
}
type StudentHistoryProps = {
    idStudent: number;
}

const studentHistory = ({idStudent}: StudentHistoryProps) => {
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dataStudent, setDataStudent] = useState<any>([]);
  const [form] = Form.useForm();
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [filterData, setFilterData] = useState<any>({
    keyword: null,
    status: null,
    university: null,
    createdDateFrom: null,
    createdDateTo: null,
  });
    const columns: TableColumnsType<DataType> = [
        {
            title: "ID",
            dataIndex: "id",
            className: "survey-form-id"
          },
          {
            title: "Thời gian",
            dataIndex: "createdDate",
            className: "survey-form-date",
            sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
            render: (_: any, record) => {
              return (
                <>{record.createdDate ? formatDateV2(record.createdDate) : null}</>
              );
            },
          },
          {
            title: "Mô tả",
            dataIndex: "description",
          },
          {
            title: "Tài khoản thực hiện",
            dataIndex: "createdByName",
          },
          {
            title: "Thao tác",
            dataIndex: "name",
          },
    ];
    const handleTableChange: TableProps["onChange"] = (
      pagination,
      filters,
      sorter: any
    ) => {
      const data =
        sorter.order === "ascend"
          ? "asc"
          : sorter.order === "descend"
            ? "desc"
            : "";
      setStatusOrder(data);
      setFieldSortOrder(sorter.field);
    };

    const getDataHistoryStudentDetail = () => {
      setLoading(true);
      const dataReq = {
      page: pageNumber,
      size: pageSize,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ["created_date,desc"] : [`created_date,${statusOrder}`],
      keyword: searchValue,
      userId: idStudent,
      universityId: filterData.university,
      createdDateFrom: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdDateTo: filterData.createdDateTo ? filterData.createdDateTo : null,
      };
      getStudentDetailByFilter(dataReq)
      .then((res) => {
        setDataStudent(res.data.data.data);
        setTotalRecords(res.data.data.total);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      })
    };

    useEffect(() => {
      getDataHistoryStudentDetail();
    }, [pageNumber, pageSize, filterData, statusOrder]);

    const handleOpenFilter = () => {
      setShowFilter((showFilter) => !showFilter);
    };
    const handleCloseFilter = () => {
      setShowFilter(false);
    };
    const clearFilter = () => {
      form.resetFields();
      setFilterData({
        createdDateFrom: "",
        createdDateTo: "",
      });
    };

    const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
      if(e.key === "Enter"){
        setPageNumber(1);
        getDataHistoryStudentDetail();
      } 
    };

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchValue(value);
    };

    const handleSubmitSearch = () => {
      setPageNumber(1);
      getDataHistoryStudentDetail();
    };
    const onFinish = (values: any) => {
      setFilterData({
        ...filterData,
        createdDateFrom: values?.rangeDate ? (values?.rangeDate[0]).add(1, 'day').toISOString() : "",
        createdDateTo: values?.rangeDate ? (values?.rangeDate[1]).add(1, 'day').toISOString() : "",
      });
    };

    return (
    <div className="wrapper-settings-history">
        <section className="history-page">
            <div className="history-header">
                <Typography.Title className="history-title" style={{margin: 0}}>
                    Lịch sử thao tác
                </Typography.Title>
                <FilterTable
                handleOpenFilter={handleOpenFilter}
                showFilter={showFilter}
                searchValue={searchValue}
                handleSearch={handleSearch}
                handleChangeInput={handleChangeInput}
                handleSubmitSearch={handleSubmitSearch}
                />
            </div>
            <div className="filter-detail">
              <FormFilter
              handleCloseFilter={handleCloseFilter}
              clearFilter={clearFilter}
              form={form}
              showFilter={showFilter}
              filterData={filterData}
              onFinish={onFinish}
              />
            </div>
            <div className="tab-heading">
            <TableData
            dataTable={dataStudent}
            dataColumns={columns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
            />
            </div>
        </section>
    </div>

)
}
export default studentHistory;