import { FunctionComponent, useEffect } from "react";
import { RegistrationClassTabProps } from "../registration-class";

function SettingsTab(props: RegistrationClassTabProps) {

  useEffect(() => {
    if (props.currentTab === '4') {

    }
  }, [props.currentTab]);

  return (
    <>

    </>
  );
};

export default SettingsTab;
