import React, { useEffect } from "react";
import "./style.scss";
import { message, Spin } from "antd";
import { ssoAuthorize } from "../../service/auth";
import { useLocation } from "react-router-dom";
import { toString } from "lodash";

function Sso() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const client_id = toString(queryParams.get("client_id"));
  const redirect_uri = toString(queryParams.get("redirect_uri"));
  const state = toString(queryParams.get("state"));

  useEffect(() => {
    const authorize = async () => {
      if (!client_id && !redirect_uri && !state) {
        message.warning("Xác thực thất bại, vui lòng cung cấp đủ thông tin");
      } else {
        try {
          const res = await ssoAuthorize({
            response_type: "code",
            client_id: client_id,
            redirect_uri: redirect_uri,
            scope: "openid",
            state: state,
          });
          const redirectUri = res.data?.redirectUri;

          if (!redirectUri)
            message.warning("Có lỗi xảy ra, vui lòng thử lại sau");

          window.location.replace(redirectUri);
        } catch (err: any) {
          message.error(err || "Có lỗi xảy ra, vui lòng thử lại sau");
        }
      }
    };
    authorize();
  }, []);

  return (
    <div className="mooc-sso-page">
      <Spin size="large" spinning={true}></Spin>
    </div>
  );
}

export default Sso;
