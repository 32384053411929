import React, { useRef, FunctionComponent, useEffect } from "react";
import "./index.scss";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Form,
  DatePicker,
  FormInstance,
} from "antd";
type HighChartsProps = {
  options?: any;
  onChartAdded?: () => void;
  title?: string;
  numberQuestion?: number;
  data?: any;
};
const ResultShot: FunctionComponent<HighChartsProps> = ({
  options,
  onChartAdded,
  title,
  numberQuestion,
  data,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    console.log(data);
  }, []);
  return (
    <div className="block-chart">
      <div className="result-shot">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
          <Col
            md={{ span: 8, offset: 0 }}
            lg={{ span: 8, offset: 0 }}
            xl={{ span: 8, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            <div className="result-shot-title">
              Câu {numberQuestion}: {title}
            </div>
          </Col>
        </Row>
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
          <Col
            md={{ span: 24, offset: 0 }}
            lg={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xs={{ span: 24, offset: 0 }}
          >
            {data &&
              data.map((item: any, index: any) => {
                return (
                  <div className="result-answer" key={index}>
                    {item}
                  </div>
                );
              })}
            <br />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ResultShot;
