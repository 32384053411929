// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.appraisal-board-member-list-modal .table-wrapper {
  padding: 24px 0px; }

.appraisal-board-member-list-modal .total-member {
  padding-top: 12px; }
  .appraisal-board-member-list-modal .total-member .total {
    font-size: 13px;
    opacity: 0.7; }
`, "",{"version":3,"sources":["webpack://./src/components/approved-information-tab/appraisal-board-member-list-modal/AppraisalBoardListMemberModal.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB,EAAA;;AAFrB;EAME,iBAAiB,EAAA;EANnB;IAQI,eAAe;IACf,YAAY,EAAA","sourcesContent":[".appraisal-board-member-list-modal{\n  .table-wrapper{\n    padding: 24px 0px;\n  }\n\n .total-member{\n  padding-top: 12px;\n  .total{\n    font-size: 13px;\n    opacity: 0.7;\n  }\n }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
