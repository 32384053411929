// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-info-banner-content .search {
  gap: 18px; }

.course-info-banner-content .banner-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
  /* 125% */
  letter-spacing: -0.96px; }

.course-info-banner-content .search-input {
  height: 48px; }

.course-info-banner-content .mooc-btn-violet {
  height: 48px;
  padding: 12px 18px; }

.content-layout {
  padding: 0 80px; }
  .content-layout .tabs-ss {
    padding: 48px 0; }
  .content-layout .filter-content {
    padding: 15px 0;
    display: flex; }
    .content-layout .filter-content .ant-form-item {
      margin-bottom: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/list-program/style.scss"],"names":[],"mappings":"AAAA;EAEI,SAAS,EAAA;;AAFb;EAMI,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EAAE,SAAA;EACnB,uBAAuB,EAAA;;AAV3B;EAcI,YAAY,EAAA;;AAdhB;EAkBI,YAAY;EACZ,kBAAkB,EAAA;;AAItB;EACE,eAAe,EAAA;EADjB;IAGI,eAAe,EAAA;EAHnB;IAOI,eAAe;IACf,aAAa,EAAA;IARjB;MAUM,gBAAgB,EAAA","sourcesContent":[".course-info-banner-content {\n  .search {\n    gap: 18px;\n  }\n\n  .banner-title {\n    font-size: 48px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 60px; /* 125% */\n    letter-spacing: -0.96px;\n  }\n\n  .search-input {\n    height: 48px;\n  }\n\n  .mooc-btn-violet {\n    height: 48px;\n    padding: 12px 18px;\n  }\n}\n\n.content-layout {\n  padding: 0 80px;\n  .tabs-ss {\n    padding: 48px 0;\n  }\n\n  .filter-content {\n    padding: 15px 0;\n    display: flex;\n    .ant-form-item {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
