import { StateCreator } from "zustand";

export interface ColorState {
  backgroundColor: string;
  textColor: string;
  sidebarColor: string;
  setBackgroundColor: (obj: any) => void;
  setTextColor: (obj: any) => void;
  setSidebarColor: (obj: any) => void;
}

export const colorSettingSlice: StateCreator<ColorState> = (set) => ({
  backgroundColor: "#FFFFFF",
  textColor: "FFFFFF",
  sidebarColor: "FFFFFF",

  setBackgroundColor: (value: string) => {
    set((state) => ({ backgroundColor: value }));
  },
  setTextColor: (value: string) => {
    set((state) => ({ textColor: value }));
  },
  setSidebarColor: (value: string) => {
    set((state) => ({ sidebarColor: value }));
  },
});
