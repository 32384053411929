import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { BarStackedChartProps } from "../../types/static-report";

interface ColumnsChartProps {
  data: BarStackedChartProps[];
  title?: string;
  colors?: string[];
  yAxisTitle?: string;
  hasTotal?: boolean;
}

const ColumnsChart = forwardRef((props: ColumnsChartProps, ref) => {
  const {
    data,
    title,
    colors,
    yAxisTitle = "Số lượng",
    hasTotal = true,
  } = props;

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const categories = data.map((item) => item.criteria);

  const seriesData = data[0]?.values.map((val) => ({
    name: val.criteria,
    data: data.map((item) => {
      const value = item.values.find((v) => v.criteria === val.criteria);
      return value ? value.count : 0;
    }),
  }));

  const calculateTotal = () => {
    let total = 0;
    data.forEach((item) => {
      item.values.forEach((value) => {
        total += value.count;
      });
    });
    return total;
  };
  const generateTotalHTML = () => {
    let totalHTML = `<div class="text-center text-description-12 mb-1">
        Tổng số: <span class="static-chart-count">${calculateTotal()}</span>
      </div>
      <br />
      <div class="flex justify-center gap-16 mb-1">`;

    seriesData?.forEach((it) => {
      const seriesTotal = it.data.reduce((sum, item) => sum + (item || 0), 0);
      totalHTML += `<span class="text-description-12"
      style="margin-right: 16px;"
      >Tổng ${it.name.toLowerCase()}: 
        <span class="static-chart-count">${seriesTotal}</span>
        &nbsp;&nbsp;&nbsp;&nbsp;</span>`;
    });

    totalHTML += `</div>`;
    return totalHTML;
  };

  const options: Highcharts.Options = {
    chart: {
      type: "column",
      scrollablePlotArea: {
        minWidth: categories?.length * 98,
        scrollPositionX: 1,
      },
    },
    title: {
      text: hasTotal ? generateTotalHTML() : title || "",
    },
    xAxis: {
      min: 0,
      categories: categories,
    },
    yAxis: {
      min: 1,
      type: "logarithmic",
      title: {
        text: yAxisTitle,
      },
      labels: {
        formatter: function () {
          return this.value.toLocaleString();
        },
      },
    },
    series: seriesData as Highcharts.SeriesOptionsType[],
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        pointWidth: 20,
        minPointLength: 5,
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      itemStyle: { fontSize: "12px" },
    },
    colors: colors,
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
      },
    },
  };

  useImperativeHandle(ref, () => ({
    getChart: () => chartRef.current?.chart,
  }));

  return (
    <div className="mt-2">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
});

export default ColumnsChart;
