import React, { useEffect, useState } from 'react'
import { Badge, Button, Form, message, Modal, Typography } from "antd";
import CustomCard from '../../../custom-card/CustomCard';
import { BrowsingResuls, DetailCourseData, Enterprises, PlanInfo } from '../../../../types/assign-course-construction';
import AssignedInformation from './assignmentInformation';
import { isEmpty } from 'lodash';
import { formatDateToIsoString } from '../../../../utils/converDate';
import { assignCoursePlan, getEnterprises } from '../../../../service/assign-course-construction';
import { Spin } from 'antd';
import { LoadingComponent } from '../../../loading/loading';

type Prop = {
  planInfo: any,
  mode: string,
  detailData: DetailCourseData,
  setTab: (value: string) => void,
  setDisableAssign:(data: any) => void,
}

const AssignedPlanInformation = (props: Prop) => {

  const { planInfo, mode, detailData, setTab, setDisableAssign } = props
  const [form] = Form.useForm()
  const [enterprises, setEnterprises] = useState<Enterprises[]>([])
  const [dataUpdate, setDataUpdate] = useState<any>([])
  const [dataSponsorUpdate, setDataSponsorUpdate] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getListEnterprises = async () => {
    const data = {
      keyword: "",
      pageNumber: 1,
      pageSize: 1000
    }
    await getEnterprises(data).then(res => {
      const { data } = res.data
      setEnterprises(data.data)
    })
  }
  useEffect(() => {
    getListEnterprises()
  }, [])

  const infoData: PlanInfo = {
    planName: detailData.name ?? '',
    status: !detailData.isAssigned ? "Chưa phân công" : "Đã phân công",
    dateConfirm: formatDateToIsoString(detailData?.approvedDate ?? ''),
    peopleConfirm: detailData?.approveName ?? '',
    description: detailData?.description ?? ''
  }

  const convertObjectData = Object.entries(infoData);

  const renderSwitch = (value: string) => {
    switch (value) {
      case 'planName':
        return <div>Tên kế hoạch</div>;
      case 'status':
        return <div>Trạng thái</div>;
      case 'dateConfirm':
        return <div>Ngày phê duyệt</div>;
      case 'peopleConfirm':
        return <div>Người phê duyệt</div>;
      case 'description':
        return <div>Mô tả</div>;
      default:
        return '';
    }
  }

  const listInfo = convertObjectData.map((item: any, index) => (
    <div className="list-item" key={index}>
      <Typography.Title level={5} className="list-item--title">
        {renderSwitch(item[0])}
      </Typography.Title>
      <div className="list-item--description">
        <Typography.Text className="content">
          {item[0] === 'status' ? <Badge key={!detailData.isAssigned ? 'lightgray' : 'green'} color={!detailData.isAssigned ? 'lightgray' : 'green'} /> : null} {item[1]}
        </Typography.Text>
      </div>
    </div>
  ))

  return (
    <>
      <Spin spinning={loading}>
        <Form.Provider onFormFinish={async (name, { values, forms }) => {
          if (name === 'create-course-plan') {
            const data = dataUpdate.filter((item: any) => item.display)
            const params = data.map((item: any) => {
              const params = item.assigned.map((item: any) => { return item.assignUserId })
              return {
                enterpriseUUID: item.enterpriseId,
                assigner: params,
                isSponsor: false
              }
            })

            const dataSponsor = dataSponsorUpdate.map((item: any) => {
              const params = item.assigned.map((item: any) => { return item.assignUserId })
              return {
                enterpriseUUID: item.enterpriseId,
                assigner: params,
                isSponsor: true
              }
            })
            const dataApi = [...params, ...dataSponsor]
            await assignCoursePlan(detailData.id, { assigners: dataApi }).then(res => {
              if (res.status === 200) {
                message.success("Lưu thông tin thành công")
                setTab("2")
              }
            })
              .catch(err => {
                // setTab("2")
              })
          }
        }}>
          <CustomCard title={
            <div className="card-heading--info">
              <Typography.Title level={5}>
                Thông tin kế hoạch
              </Typography.Title>
              <Typography.Text className="sub-title">
                Thông tin chi tiết về hoạt động của kế hoạch
              </Typography.Text>
            </div>
          } className="card-info--wrapper">
            <div className="list-wrapper">
              {listInfo}
            </div>
          </CustomCard>

          <AssignedInformation enterprises={enterprises}
            planInfo={planInfo} mode={mode}
            detailData={detailData}
            setLoading={setLoading}
            setDataUpdate={setDataUpdate}
            setDisableAssign={setDisableAssign}
            setDataSponsorUpdate={setDataSponsorUpdate} />
        </Form.Provider>
      </Spin>
    </>
  )
}

export default AssignedPlanInformation


