// @ts-nocheck
import { FunctionComponent } from "react";
import { Layout, Row } from "antd";
import { DataType } from "../../types/listUsers";

const { Content } = Layout;
type ProfileInfoProps = {
    dataUser?: DataType;
};

const TitleComponent: FunctionComponent<ProfileInfoProps> = (props, context) => {
    const isRegistered = true;
    const displayFor = props['display-for'];
    let isHidden = displayFor == 'hidden';
    if (displayFor == 'all') {
        isHidden = false;
    }
    if (displayFor == 'registered_only') {
        isHidden = !isRegistered;
    }
    return (
        <>
            {isHidden ? '' : props.children}
        </>

    );
};

export default TitleComponent;

