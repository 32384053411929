// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-plan-managment-detail-info-appraisal .card-info--wrapper:first-child {
  margin-top: -25px; }

.course-plan-managment-detail-info-appraisal .card-info--wrapper .ant-card-head {
  border-bottom: none;
  padding-bottom: 0; }

.course-plan-managment-detail-info-appraisal .wrapper-card:last-child {
  padding-top: 5px; }
  .course-plan-managment-detail-info-appraisal .wrapper-card:last-child .ant-card-head {
    padding-bottom: -40px; }
  .course-plan-managment-detail-info-appraisal .wrapper-card:last-child .ant-card-body {
    padding-bottom: -16px; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/organize-appraisal-board/organize-appraisal-board-detail/detail-info-tab/DetailInfoTab.scss"],"names":[],"mappings":"AAAA;EAEQ,iBAAiB,EAAA;;AAFzB;EAMQ,mBAAoB;EACpB,iBAAiB,EAAA;;AAPzB;EAWQ,gBAAgB,EAAA;EAXxB;IAcY,qBAAqB,EAAA;EAdjC;IAkBY,qBAAqB,EAAA","sourcesContent":[".course-plan-managment-detail-info-appraisal {\n    .card-info--wrapper:first-child {\n        margin-top: -25px;\n    }\n\n    .card-info--wrapper .ant-card-head {\n        border-bottom : none;\n        padding-bottom: 0;\n    }\n\n    .wrapper-card:last-child {\n        padding-top: 5px;\n\n        .ant-card-head {\n            padding-bottom: -40px;\n        }\n\n        .ant-card-body {\n            padding-bottom: -16px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
