import React from "react";
import { QuestionProps } from "../../../../../types/course";
import { Checkbox, Form, Radio } from "antd";

function ChooseMultiImage({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <div>
      <div className="flex layout-two-col">
        <div
          className={`image-quiz-wrap ${isCorrect ? "active-quiz-correct choose-correct" : ""} ${isIncorrect ? "active-quiz-wrong choose-incorrect" : ""}`}
        >
          <Form.Item
            className={`mb-12`}
            name="choose_one"
            valuePropName="checked"
            initialValue={{ choose_one: true }}
          >
            <Checkbox id="1" disabled>
              <span className="text-result font-size-16 line-height-24 font-weight-5">
                Figma ipsum component, Figma ipsum
              </span>
            </Checkbox>
          </Form.Item>
          <label htmlFor="1">
            <img
              className="mt-1"
              src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
              alt="quiz-card"
            />
          </label>
        </div>
        <div  className={`image-quiz-wrap ${isCorrect ? "active-quiz-correct choose-correct" : ""} ${isIncorrect ? "active-quiz-wrong choose-incorrect" : ""}`}>
          <Form.Item
            className={`mb-12`}
            name="choose_one"
            valuePropName="checked"
            initialValue={{ choose_one: true }}
          >
            <Checkbox id="2" disabled>
              <span className="text-result font-size-16 line-height-24 font-weight-5">
                Figma ipsum component, Figma ipsum
              </span>
            </Checkbox>
          </Form.Item>
          <label htmlFor="2">
            <img
              className="mt-1"
              src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
              alt="quiz-card"
            />
          </label>
        </div>
        <div className={`image-quiz-wrap `}>
          <Form.Item
            className="mb-12"
            name="ChooseMultiText-3"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox id="3">
              <span className="font-size-16 line-height-24 font-weight-5">
                Figma ipsum component,
              </span>
            </Checkbox>
          </Form.Item>
          <label htmlFor="3">
            <img
              className="mt-1"
              src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
              alt="quiz-card"
            />
          </label>
        </div>
        <div className={`image-quiz-wrap`}>
          <Form.Item
            className="mb-12"
            name="ChooseMultiText-4"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox id="4">
              <span className="font-size-16 line-height-24 font-weight-5">
                Figma ipsum component,
              </span>
            </Checkbox>
          </Form.Item>
          <label htmlFor="4">
            <img
              className="mt-1"
              src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
              alt="quiz-card"
            />
          </label>
        </div>
      </div>
    </div>
  );
}

export default ChooseMultiImage;
