import {AxiosResponse} from "axios";
import { axiosConfigV2 } from "../../../config/api/configApiv2";

export const getListQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/search-quiz-bank`, data);
};

export const getDetailListQuestion: (
  id: any
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/question-bank/get-quiz-bank/${id}`);
};

export const postSentPermissionQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/question-bank/censor/send-approve`, data);
};

export const postEditPermissionQuestion: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/question-bank/update-access-unit-bank`, data);
};
