import React, { ReactNode, useState } from "react";
import { Modal } from "antd";

interface ScoreDetailModalProps {
  children?: ReactNode;
}

function ScoreDetailModal({ children }: ScoreDetailModalProps) {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };
  return (
    <div>
      <div onClick={showModal}>{children}</div>
      <Modal
        title={
          <span className="font-size-24 line-height-32 font-weight-6">
            Bảng điểm chi tiết
          </span>
        }
        width={708}
        open={open}
        maskClosable={false}
        centered
        className="score-detail-modal"
        onCancel={hideModal}
      >
        <div className="score-detail-table">
          <div className="row score-table-header">
            <div className="cell">Phần bài</div>
            <div className="cell">Tên phần</div>
            <div className="cell">Điểm số</div>
          </div>
          <div className="row">
            <div className="cell">
              Phần 05 <p>(05 câu)</p>
            </div>
            <div className="cell font-weight-6">
              Figma ipsum component variant main layer?
            </div>
            <div className="cell">0.25/1.0</div>
          </div>
          <div className="row">
            <div className="cell">
              Phần 05 <p>(05 câu)</p>
            </div>
            <div className="cell font-weight-6">
              Figma ipsum component variant main layer?
            </div>
            <div className="cell">0.25/1.0</div>
          </div>
          <div className="row">
            <div className="cell">
              Phần 05 <p>(05 câu)</p>
            </div>
            <div className="cell font-weight-6">
              Figma ipsum component variant main layer?
            </div>
            <div className="cell">0.25/1.0</div>
          </div>
          <div className="row">
            <div className="cell">
              Phần 05 <p>(05 câu)</p>
            </div>
            <div className="cell font-weight-6">
              Figma ipsum component variant main layer?
            </div>
            <div className="cell">0.25/1.0</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ScoreDetailModal;
