// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recall {
  color: #FF4D4F !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-construction/assigned-course-construction/index.scss"],"names":[],"mappings":"AAEA;EACE,yBAA8B,EAAA","sourcesContent":["@import \"../../../styles/variables\";\n//đã phân công\n.recall {\n  color: $text-recall !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
