import { StateCreator } from "zustand";
import { ConnectQuestionData, QuestionData, SettingPopupQuestionProps, SettingQuestionProps } from "../../types/course-construction";
import { QuestionProgressModeClick } from "../../constants";

export interface Lesson {
  reviewList: any;
  setReviewList: (data: object) => void;
  question: QuestionData | ConnectQuestionData;
  setQuestion: (data: QuestionData | {} | ConnectQuestionData) => void;
  modeQuestion: string;
  setModeQuestion: (data: string) => void;
  addQuestionModal: boolean;
  setAddQuestionModal: (value: boolean) => void;
  lessonDataList: any;
  setLessonDataList: (data: object) => void;
  questionDataList: any;
  setQuestionDataList: (data: any) => void;
  typeQuestionChoose: string | number;
  setTypeQuestionChoose: (data: number | string) => void;
  typeMultiQuestionChoose: string;
  setTypeMultiQuestionChoose: (data: string) => void;
  modeClick: string;
  setModeClick: (data: string) => void;
  settingQuestion: SettingQuestionProps;
  setSettingQuestion: (value: SettingQuestionProps) => void;
  currentQuestionInfo: any;
  setCurrentQuestionInfo: (value: any) => void;
  refreshQuizBlock: boolean;
  onRefreshQuizBlock: () => void;
  questionSettingsBe: QuestionData;
  setQuestionSettingsBe: (data: QuestionData) => void;
  flag: boolean;
  toggleFlag: () => void;
  flagCourseEditing: boolean;
  toggleFlagCourseEditing: () => void;
  typeCreateQuestion: string;
  setTypeCreateQuestion: (data: string) => void;
  settingPopupQuestion: SettingPopupQuestionProps;
  setSettingPopupQuestion: (value: SettingPopupQuestionProps) => void;
  currentIdQuestionAdd: number | null;
  setCurrentIdQuestionAdd: (data: number) => void;
  updateTimeToPlayFromOutside: (newTimeToPlay: number) => void; 
  defaultTimeToPlay: number | null;
  setDefaultTimeToPlay: (data: number) => void;
}

export const AddLessonStore: StateCreator<Lesson> = (set, get) => ({
  reviewList: {},
  question: {},
  modeQuestion: "",
  typeQuestionChoose: 0,
  modeClick: QuestionProgressModeClick.START,
  typeMultiQuestionChoose: "",
  lessonDataList: {},
  questionDataList: [],
  addQuestionModal: false,
  flag: true,
  flagCourseEditing: true,
  settingQuestion: {},
  currentQuestionInfo: {},
  refreshQuizBlock: false,
  questionSettingsBe: {},
  typeCreateQuestion: '',
  settingPopupQuestion: {},
  currentIdQuestionAdd: null,
  defaultTimeToPlay: 0,

  setReviewList: (data: object) => { set({ reviewList: data }); },
  setQuestion: (data: QuestionData | ConnectQuestionData) => { set({ question: data }); },
  setAddQuestionModal: (data: boolean) => { set({ addQuestionModal: data }); },
  setModeQuestion: (data: string) => { set({ modeQuestion: data }); },
  setTypeQuestionChoose: (data: string | number) => { set({ typeQuestionChoose: data }); },
  setTypeMultiQuestionChoose: (data: string) => { set({ typeMultiQuestionChoose: data }); },
  setLessonDataList: (data: object) => { set({ lessonDataList: data }); },
  setQuestionDataList: (data: any) => { set({ questionDataList: data }); },
  setModeClick: (data: string) => { set({ modeClick: data }); },
  setSettingQuestion: (data: SettingQuestionProps) => {
    set(state => (
      { settingQuestion: { ...state.settingQuestion, ...data } }
    ));
  },
  setCurrentQuestionInfo: (data: any) => { set({ currentQuestionInfo: data }); },
  toggleFlag: () => set((state) => ({ flag: !state.flag })),
  toggleFlagCourseEditing: () => set((state) => ({ flagCourseEditing: !state.flagCourseEditing })),
  onRefreshQuizBlock: () => { set((state) => ({ refreshQuizBlock: !state.refreshQuizBlock })); },
  setQuestionSettingsBe: (data: QuestionData) => { set({ questionSettingsBe: data }); },
  setTypeCreateQuestion: (data: string) => { set({ typeCreateQuestion: data }); },
  setSettingPopupQuestion: (data: SettingPopupQuestionProps) => {
    set(state => (
      { settingPopupQuestion: { ...state.settingPopupQuestion, ...data } }
    ));
  },
  setCurrentIdQuestionAdd: (data: number) => { set({ currentIdQuestionAdd: data }); },
  updateTimeToPlayFromOutside: (newTimeToPlay: number) => {
    set({ settingQuestion: { ...get().settingQuestion, timeToPlay: newTimeToPlay } });
  },
  setDefaultTimeToPlay: (data: number) => {set({ defaultTimeToPlay: data})},
});
