// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assigned-course-construction .ant-divider {
  margin-top: unset !important; }

.assigned-course-construction .card-heading--info h5, .assigned-course-construction .card-heading--info .sub-title {
  margin-bottom: unset !important; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/assign-course-construction/assigned-plan-information/assignmentInformation.scss"],"names":[],"mappings":"AACA;EAEI,4BAA4B,EAAA;;AAFhC;EAMM,+BAA+B,EAAA","sourcesContent":["\n.assigned-course-construction {\n  .ant-divider {\n    margin-top: unset !important;\n  }\n  .card-heading--info {\n    h5, .sub-title {\n      margin-bottom: unset !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
