import React, { FunctionComponent, useEffect, useState } from "react";
import { message } from "antd/lib";
import { get, isEmpty } from "lodash";
import { FormInstance } from "antd/lib";
import { useNavigate } from "react-router-dom";
import DrawerCustom from "../../../../../../components/drawer/Drawer";
import { UrlPattern, regexEmail } from "../../../../../../utils/regex";
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { Input } from 'antd';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  CloseOutlined,
  PlusOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import FormInput from "../../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import FormRadio from "../../../../../../components/form-radio/FormRadio";
import grapesjs, { Block, Editor } from "grapesjs";
import GjsEditor, { useEditor, WithEditor } from "@grapesjs/react";
import gjsBlocksBasic from "grapesjs-blocks-basic";
import gjsTailWind from "grapesjs-tailwind";
import gjsNavbar from "grapesjs-navbar";
import gjsPresetWeb from "grapesjs-preset-webpage";
import gjsStyleBg from "grapesjs-style-bg";
import gjsStyleFilter from "grapesjs-style-filter";
import gjsStyleGradient from "grapesjs-style-gradient";

import { getMoocActionHistoryById, getLookupAuthUser, } from "../../../../../../service/course-plan-managment/mooc-action-history/mooc-action-history-api";
import { DetailTypeMoocActionHistory } from "../../../../../../types/course-plan-managment/mooc-action-history";

const validations = {
  id: {
    required: false,
    message: "Vui lòng chọn khoa Title of id áp dụng",
    validator: (_: unknown, value: string) => {
      value = 'x';
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên Title of id"));
      }
      return Promise.resolve();
    },
  },
  userId: {
    required: false,
    message: "Vui lòng chọn khoa Title of user_id áp dụng",
    validator: (_: unknown, value: string) => {
      value = 'x';
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên Title of user_id"));
      }
      return Promise.resolve();
    },
  },
  loggedTime: {
    required: false,
    message: "Vui lòng chọn khoa Title of logged_time áp dụng",
    validator: (_: unknown, value: string) => {
      value = 'x';
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên Title of logged_time"));
      }
      return Promise.resolve();
    },
  },
  userIp: {
    required: false,
    message: "Vui lòng chọn khoa Title of user_ip áp dụng",
    validator: (_: unknown, value: string) => {
      value = 'x';
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên Title of user_ip"));
      }
      return Promise.resolve();
    },
  },
  actionDetail: {
    required: false,
    message: "Vui lòng chọn khoa Title of action_detail áp dụng",
    validator: (_: unknown, value: string) => {
      value = 'x';
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên Title of action_detail"));
      }
      return Promise.resolve();
    },
  },
  createdDate: {
    required: false,
    message: "Vui lòng chọn khoa Title of created_date áp dụng",
    validator: (_: unknown, value: string) => {
      value = 'x';
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên Title of created_date"));
      }
      return Promise.resolve();
    },
  },
  updatedDate: {
    required: true,
    message: "Vui lòng chọn khoa Title of updated_date áp dụng",
    validator: (_: unknown, value: string) => {
      value = 'x';
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên Title of updated_date"));
      }
      return Promise.resolve();
    },
  },
  moocActionHistoryTypes: {
    required: false,
    message: "Vui lòng chọn khoa Title of mooc_action_history_types áp dụng",
    validator: (_: unknown, value: string) => {
      value = 'x';
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên Title of mooc_action_history_types"));
      }
      return Promise.resolve();
    },
  },
};

type FormCreateMoocActionHistoryProps = {
  onFinish?: any;
  dataOptions?: any;
  id?: any;
  detailData?: DetailTypeMoocActionHistory;
  isOpened?: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData?: React.Dispatch<
    React.SetStateAction<DetailTypeMoocActionHistory>
  >;
  setDataUpdate?: (data: any) => void;
  setDataSponsorUpdate?: (data: any) => void;
};

export const FormCreateMoocActionHistory: FunctionComponent<FormCreateMoocActionHistoryProps> = ({
  onFinish,
  dataOptions,
  id,
  detailData,
  isOpened,
  setIsOpened,
  setDetailData,
  setDataUpdate,
  setDataSponsorUpdate,
}) => {
  const dateFormat = 'DD/MM/YYYY';
  const { TextArea } = Input;
const onChange: DatePickerProps['onChange'] = (date, dateString) => {
  // console.log(date, dateString);
};
  const [form] = Form.useForm();
  const [error, setError] = useState<any>({});
  const [detailDataEdit, setDetailDataEdit] =
    useState<DetailTypeMoocActionHistory>(detailData!);

  useEffect(() => {
    form.resetFields();
    if (detailData) {
      form.setFieldsValue({
        id: detailData?.id || "",
        userId: detailData?.userId || "",
        loggedTime: dayjs(detailData?.loggerTime) || "",
        userIp: detailData?.userIp || "",
        actionDetail: detailData?.actionDetail || "",
        createdDate: dayjs(detailData?.createdDate) || "",
        updatedDate: dayjs(detailData?.updatedDate) || "",
        moocActionHistoryTypes: detailData?.moocActionHistoryTypes || "",
      });
    }
  }, [detailData]);

  useEffect(() => {
    form.resetFields();
    if(id && id != 0)
      getDetailMoocActionHistoryData();
  }, [id]);
  
  const navigate = useNavigate();
  const onStartInput = () => {
    if (false) {
      //setError({ ...error, [`lecturerName`]: true });
      return;
    } else {
      form.submit();
    }
  };

  const getDetailMoocActionHistoryData = async () => {
    try {
      await getMoocActionHistoryById(id).then((res) => {
        const { detailData } = res.data;
        form.setFieldsValue({
        id: detailData?.id || "",
        userId: detailData?.userId || "",
        loggedTime: dayjs(detailData?.loggedTime) || "",
        userIp: detailData?.userIp || "",
        actionDetail: detailData?.actionDetail || "",
        createdDate: dayjs(detailData?.createdDate) || "",
        updatedDate: dayjs(detailData?.updatedDate) || "",
        moocActionHistoryTypes: detailData?.moocActionHistoryTypes || "",
        });
      })
      .catch((err) => {
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  // forgein key function call apis
  const [lstUserId, setLstUserId] = useState<any[]>([]);
  const getListLstUserId = async () => {
    try {
      await getLookupAuthUser().then((res) => {
        const { data } = res.data;
        setLstUserId(data.map((item: any) => {
          if(!('name' in item)) item['name'] = item['id'];
          return item;
        }));
      })
      .catch((err) => {
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    getListLstUserId();
  }, []);

  return (
    <>
      <DrawerCustom
        title={id && id !== 0 ? "Chỉnh sửa Lịch sử hoạt động" : "Thêm mới Lịch sử hoạt động"}
        open={isOpened}
        onClose={() => {
          //setDetailData({});
          setIsOpened(false);
          form.resetFields();
          // console.log("closed");
        }}
        extraNode={
          <Space>
            <Button
              onClick={() => {
                setIsOpened(false);
                //setDetailData({});
                form.resetFields();
              }}
              className="btn btn-outlined"
            >
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={onStartInput}
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
        widthDrawer={736}
      >
        <Form
          className="form-create-edit"
          layout={"vertical"}
          form={form}
          onFinish={onFinish}
          name="create-edit-mooc-action-history-detail"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="userId"
                label="Title of user_id"
                rules={[validations.userId]}
              >
                <FormItemDropdown
                  modeDropdown="multiple"
                  options={lstUserId}
                  allowClear={true}
                  placeholder="Title of user_id"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="loggedTime"
                rules={[validations.loggedTime]}
                label="Title of logged_time"
              >
                <DatePicker placeholder="Nhập Title of logged_time" format={dateFormat} />
                {/* <span className="label-down-text">
                Title of logged_time
              </span> */}
              </Form.Item>
              <span className="label-down-text">
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                name="userIp"
                rules={[validations.userIp]}
                label="Title of user_ip"
              >
                <FormInput placeholder="Nhập Title of user_ip" />
                {/* <span className="label-down-text">
                Title of user_ip
              </span> */}
              </Form.Item>
              <span className="label-down-text">

              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                name="actionDetail"
                rules={[validations.actionDetail]}
                label="Title of action_detail"
              >
                <TextArea rows={ 4 } placeholder="Nhập Title of action_detail" />
                {/* <span className="label-down-text">
                Title of action_detail
              </span> */}
              </Form.Item>
              <span className="label-down-text">
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                name="createdDate"
                rules={[validations.createdDate]}
                label="Title of created_date"
              >
                <DatePicker placeholder="Nhập Title of created_date" format={dateFormat} />
                {/* <span className="label-down-text">
                Title of created_date
              </span> */}
              </Form.Item>
              <span className="label-down-text">
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                name="updatedDate"
                rules={[validations.updatedDate]}
                label="Title of updated_date"
              >
                <DatePicker placeholder="Nhập Title of updated_date" format={dateFormat} />
                {/* <span className="label-down-text">
                Title of updated_date
              </span> */}
              </Form.Item>
              <span className="label-down-text">
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                name="moocActionHistoryTypes"
                rules={[validations.moocActionHistoryTypes]}
                label="Title of mooc_action_history_types"
              >
                <FormInput placeholder="Nhập Title of mooc_action_history_types" />
                {/* <span className="label-down-text">
                Title of mooc_action_history_types
              </span> */}
              </Form.Item>
              <span className="label-down-text">

              </span>
            </Col>
          </Row>
        </Form>
      </DrawerCustom>
    </>
  );
};
