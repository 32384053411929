import React, {useEffect, useState} from 'react';
import './index.scss';
import {Link, useLocation, useNavigation, useParams} from "react-router-dom";
import {Button, Form, Tabs, TabsProps, Typography} from "antd";
import {Content} from "antd/es/layout/layout";
import PageHeader from "../../../components/page-header/PageHeader";
import {routesConfig} from "../../../config/routes";
import SettingContentCourse
  from "../../../components/course-construction/content-course-construction/setting-content-course";
import {CheckOutlined, UploadOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {getConfigCourse, updateSettingCourse} from "../../../service/content-course/contenCourseApi";


const DetailContentConstruction = () => {

  const location = useLocation()
  const navigate = useNavigation()
  const params = useParams()
  const [isShowSubmitButton, setIsShowSubmitButton] = useState(false)
  const [dataConfig, setDataConfig] = useState({})
  const [initialValues, setInitialValues] = useState({
    isHasCertification: true,
    isScheduleByTeacher: true,
    isCostFree: true,
  })
  const [dataForm, setDataForm] = useState<any>({})
  const [form] = useForm()

  const editSettingCourse = async (id: string) => {
    try {
      await getConfigCourse(id).then(res => {
        const {data, statusCode} = res.data
        if (statusCode === 200) {
          setDataConfig(data)
        }
      })

    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    if (params.id) {
      editSettingCourse(params.id)
    }
  }, [params.id]);


  const onFinish = (name: string, {forms, values}: any) => {
    setDataForm(values)
  }

  // @ts-ignore
  const submitData = async (id?: string, dataReq: any) => {
    try {
      const response = await updateSettingCourse(id, dataReq)
      const {data} = response
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (Object.keys(dataForm).length > 0) {
      submitData(params?.id, dataForm)
    }
  }, [dataForm]);


  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Trang giới thiệu'
    },
    {
      key: '2',
      label: 'Cấu trúc khoá học'
    },
    {
      key: '3',
      label: 'Cài đặt',
      children: <Form.Provider onFormFinish={onFinish}>
        <Form form={form} name="settingCourse" initialValues={initialValues}>
          <SettingContentCourse dataSetting={dataConfig} form={form} setIsCertificate={() => {
          }} setCertificateId={() => {
          }} resetForm={() => {
          }}/>
        </Form>
      </Form.Provider>
    },
    {
      key: '4',
      label: 'Kết quả duyệt nội dung'
    }
  ]

  const listBreadcrumbs = [
    {
      title: <Link to={routesConfig.contentConstruction}>Danh sách khóa học</Link>
    },
    {
      title: location.state.courseName
    }
  ]

  const handleChange = (value: string) => {
    if (value === "3") {
      setIsShowSubmitButton(true)
    } else {
      setIsShowSubmitButton(false)
    }

  }

  return (
    <>
      <Content className="setting-course--page">
        <PageHeader title={location.state.courseName} listBreadcrumb={listBreadcrumbs}
                    arrowLeft={'/images/arrowLeft.svg'}
                    positionItem="column"
                    headerGroupButton={isShowSubmitButton}
                    childrenGroupButton={isShowSubmitButton && <>
                        <div className="group-button">
                            <Button className="btn btn-outlined" size="small" onClick={() => form.submit()}>
                                <CheckOutlined/>
                                <Typography.Text>
                                    Yêu cầu duyệt
                                </Typography.Text>
                            </Button>
                            <Button className="btn" disabled={true} size="small">
                                <UploadOutlined/>
                                <Typography.Text>
                                    Xuất bản</Typography.Text>
                            </Button>
                        </div>
                    </>}
                    breadcrumb={true}/>
        <div className="tabs-heading">
          <Tabs defaultActiveKey="1" onChange={handleChange} items={items}/>
        </div>
      </Content>
    </>
  );
};

export default DetailContentConstruction;
