import React, { useEffect, useState } from "react";
import { Button, Empty, Form, Input, Modal, Upload, message } from "antd";
import Comment from "./components/comment";
import { CommentProps, ReplyValue } from "./components/comment/Reply";
import {
  createDiscussion,
  deleteComment,
  discussionReact,
  getAllComment,
  replyDiscussion,
  updateComment,
} from "../../../../service/course-construct/statistic";
import { useParams } from "react-router-dom";
import { toNumber } from "lodash";
import {
  DISLIKE_REACTION,
  LIKE_REACTION,
  TEACHER_DISCUSSION,
} from "../../../../constants";
import {
  CommentsBeProps,
  DiscussionProps,
} from "../../../../types/course-construction";
import {
  ExclamationCircleFilled,
  FileTextOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import UploadItem from "./components/comment/UploadItem";
import { getCleanText } from "../../../../utils/format";
import { useAuthStore } from "../../../../stores/stores";
import useKeyword from "../../../../hooks/useKeyword";
import useUploadFile from "../../../../hooks/useUploadFile";
import {
  createDiscussionTopic,
  deleteCommentTopic,
  discussionReactTopic,
  getAllCommentTopic,
  replyDiscussionTopic,
  updateCommentTopic,
} from "../../../../service/blog-and-forum";
interface DiscussProp {
  isSidebarCollapsed?: boolean;
  className?: string;
  idTopic?: number | undefined;
  isCreated?: boolean;
  onRefetch?: (v: boolean) => void;
  setAllLoading?: (v: boolean) => void;
}

function Discuss({
  className = "",
  isSidebarCollapsed = false,
  idTopic,
  isCreated,
  onRefetch = () => { },
  setAllLoading = () => { },
}: DiscussProp) {
  const params = useParams();
  const userInfo = useAuthStore((state) => state.user);
  const unitId = toNumber(useKeyword("unitId"));
  const courseId = toNumber(params?.id);
  const [reFetch, setRefetch] = useState(false);
  const [isCreatingDiscussion, setIsCreatingDiscussion] = useState<boolean>();
  const [form] = Form.useForm();

  //create discussion payload
  const {
    filesPreview,
    discussionPayloadFiles,
    handleChangeFiles,
    handleDeleteFile,
    resetFiles,
  } = useUploadFile();

  const [discussionText, setDiscussionText] = useState<string>("");
  const [isLoadingAction, setLoadingAction] = useState<boolean>(false);

  // discussion data
  const [comments, setComments] = useState<{
    total: number;
    discussions: DiscussionProps[];
  }>();

  useEffect(() => {
    const getComments = async () => {
      if (unitId || idTopic) {
        setLoadingAction(true);
        let res: any = {};
        if (idTopic) {
          const data = {
            page: 1,
            size: 10,
            sort: [],
            topicId: idTopic,
            enterpriseUUID: userInfo?.enterpriseUUID ?? "",
          };
          res = await getAllCommentTopic(data);
        } else {
          res = await getAllComment({
            discussionType: TEACHER_DISCUSSION,
            unitId: unitId,
          });
        }
        setLoadingAction(false);
        setAllLoading(false);
        setComments(res.data.data);
      }
    };
    getComments();
    onRefetch(reFetch);
  }, [unitId, reFetch]);

  const discussions =
    comments?.discussions.map((dis: DiscussionProps) => ({
      ...dis.rootComment,
      level: 1,
      replies:
        dis.subComments
          .filter((comment: CommentsBeProps) => comment.level === 2)
          .map((commentLv2: CommentsBeProps) => ({
            ...commentLv2,
            parent: commentLv2.parentId,
            replies:
              dis.subComments
                .filter(
                  (cmt: CommentsBeProps) =>
                    cmt.level === 3 && cmt.parentId === commentLv2.id
                )
                .map((commentLv3: CommentsBeProps) => ({
                  ...commentLv3,
                  parent: commentLv3.parentId,
                  replies: [],
                })) || [],
          })) || [],
    })) || [];

  const handleLike = async (comment: CommentProps) => {
    try {
      if (idTopic) {
        await discussionReactTopic({
          id: comment.id,
          reactionType: LIKE_REACTION,
        });
      } else {
        await discussionReact({
          id: comment.id,
          reactionType: LIKE_REACTION,
        });
      }
      setRefetch(!reFetch);
    } catch (err) { }
  };

  const handleDislike = async (comment: CommentProps) => {
    try {
      if (idTopic) {
        await discussionReactTopic({
          id: comment.id,
          reactionType: DISLIKE_REACTION,
        });
      } else {
        await discussionReact({
          id: comment.id,
          reactionType: DISLIKE_REACTION,
        });
      }
      setRefetch(!reFetch);
    } catch (err) { }
  };

  const handleDeleteComment = (comment: CommentProps) => {
    Modal.confirm({
      title: "Xác nhận xoá thảo luận",
      icon: <ExclamationCircleFilled />,
      content:
        "Thảo luận đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá thảo luận?",
      okText: "Xác nhận",
      cancelText: "Huỷ",
      centered: true,
      onOk: async () => {
        try {
          if (idTopic) {
            await deleteCommentTopic(comment.id);
          } else {
            await deleteComment(comment.id);
          }
          message.success("Xoá thành công");
          setRefetch(!reFetch);
        } catch (err: any) { }
      },
      onCancel() { },
    });
  };

  const handleAddDiscussion = async () => {
    const payload: any = new FormData();
    payload.append("unitId", `${unitId}`);
    payload.append("courseId", `${courseId}`);
    payload.append("content", discussionText);
    payload.append("discussionType", `1`);
    Array.from(discussionPayloadFiles).forEach((file) => {
      payload.append("attachments", file);
    });
    try {
      setLoadingAction(true);
      await createDiscussion(payload);
      setRefetch(!reFetch);
      setIsCreatingDiscussion(false);

      setDiscussionText("");
      resetFiles();
    } catch (err) { } finally {
      setLoadingAction(false);
    }
  };

  const handleReplyComment = async (value: ReplyValue) => {
    const payload: any = new FormData();
    payload.append("content", `${value?.replyText}`);
    payload.append("parentId", `${value?.comment?.id}`);
    Array.from(value?.file).forEach((file) => {
      payload.append("attachments", file);
    });
    try {
      setLoadingAction(true);
      if (idTopic) {
        await replyDiscussionTopic(payload);
      } else {
        await replyDiscussion(payload);
      }
      setRefetch(!reFetch);
    } catch (err) { } finally {
      setLoadingAction(false);
    }
  };

  const handleSaveEditComment = async (value: ReplyValue) => {
    const payload: any = new FormData();
    payload.append("id", `${value?.comment?.id}`);
    payload.append("content", `${value?.replyText}`);
    Array.from(value?.file).forEach((file) => {
      payload.append("attachments", file);
    });
    payload.append(
      "deleteAttachmentIds",
      `${value?.attachmentRemoved?.join(",")}`
    );
    try {
      setLoadingAction(true);
      if (idTopic) {
        await updateCommentTopic(payload);
      } else {
        await updateComment(payload);
      }
      setRefetch(!reFetch);
    } catch (err) { } finally {
      setLoadingAction(false);
    }
  };

  const addCommenByReactQuill = async (values: any) => {
    const payload: any = new FormData();
    payload.append("topicId", `${idTopic}`);
    payload.append("content", discussionText);
    if (values?.uploadFile?.fileList) {
      Array.from(values?.uploadFile?.fileList).forEach((file: any) => {
        payload.append("attachments", file.originFileObj);
      });
    }
    try {
      setLoadingAction(true);
      await createDiscussionTopic(payload);
      setRefetch(!reFetch);
      setIsCreatingDiscussion(false);
      setDiscussionText("");
      form.resetFields();
    } catch (err) { } finally {
      setLoadingAction(false);
    }
  };

  const beforeUpload = (file: any) => {
    return false;
  };

  return (
    <div
      className={`statistic-course-discuss ${className} ${isSidebarCollapsed ? "width-45" : ""
        }`}
    >
      {idTopic && isCreated ? (
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "form-add-comment") {
              addCommenByReactQuill(values);
            }
          }}
        >
          <Form layout={"vertical"} form={form} name="form-add-comment">
            <div className="">
              <div className="create-form-content">
                <img
                  className="avt-comment"
                  src={userInfo?.anhDaiDien || ""}
                  alt="avt"
                />
                <div className="form-content w-full">
                  <Form.Item name="uploadFile" label="">
                    <Upload
                      beforeUpload={beforeUpload}
                      listType="picture"
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>
                        Tải file đính kèm
                      </Button>
                    </Upload>
                  </Form.Item>

                  <Input.TextArea
                    className="mt-1 w-full"
                    autoSize={{ minRows: 3 }}
                    value={discussionText}
                    placeholder="Nội dung thảo luận của bạn"
                    onChange={(e) =>
                      setDiscussionText(getCleanText(e.target.value))
                    }
                  ></Input.TextArea>
                </div>
              </div>
              <Button
                type="primary"
                className="float-right mx-4"
                onClick={() => form.submit()}
              >
                Bình luận
              </Button>
            </div>
          </Form>
        </Form.Provider>
      ) : null}
      {unitId && !idTopic ? (
        <div className="discuss-header flex align-center justify-space-between">
          <div className="header-left flex align-center">
            <span className="text-20 text-bold">Thảo luận</span>
            <span className="badge ml-2">{comments?.total || 0}</span>
          </div>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setIsCreatingDiscussion(true)}
          >
            Tạo thảo luận
          </Button>
        </div>
      ) : (
        ""
      )}

      {isCreatingDiscussion && !idTopic && (
        <div className="discuss-create-form">
          <div className="create-form-title">
            <span className="font-size-16 line-height-24 font-weight-6">
              Tạo thảo luận mới
            </span>
          </div>
          <div className="create-form-content">
            <img
              className="avt-comment"
              src={userInfo?.anhDaiDien || ""}
              alt="avt"
            />
            <div className="form-content w-full">
              <Button icon={<FileTextOutlined />}>
                <label htmlFor="file-upload">Tải tệp đính kèm</label>
              </Button>
              <input
                id="file-upload"
                type="file"
                onChange={handleChangeFiles}
                multiple
                style={{ display: "none" }}
              />
              <Input.TextArea
                className="mt-1 w-full"
                autoSize={{ minRows: 3 }}
                value={discussionText}
                placeholder="Nội dung thảo luận của bạn"
                onChange={(e) =>
                  setDiscussionText(getCleanText(e.target.value))
                }
              ></Input.TextArea>
            </div>
          </div>
          {filesPreview?.length > 0 && (
            <div className="files-preview">
              {filesPreview.map((file, index) => (
                <UploadItem
                  key={index}
                  file={file}
                  hasDelete={true}
                  onDelete={handleDeleteFile}
                />
              ))}
            </div>
          )}
          <div className="create-form-footer">
            <Button
              onClick={() => {
                setIsCreatingDiscussion(false);
                setDiscussionText("");
                resetFiles();
              }}
            >
              Huỷ
            </Button>
            <Button
              className="btn-primary"
              onClick={handleAddDiscussion}
              loading={isLoadingAction}
            >
              Tạo thảo luận
            </Button>
          </div>
        </div>
      )}

      {discussions?.length > 0 ? (
        discussions?.map((discussion: any) => (
          <div className={`discuss-content`} key={discussion.id}>
            <Comment
              data={discussion}
              onDelete={handleDeleteComment}
              onLike={handleLike}
              onDislike={handleDislike}
              onReply={handleReplyComment}
              onSaveEditComment={handleSaveEditComment}
            />
          </div>
        ))
      ) : (
        <div className="center">
          <Empty description="Không có bình luận"></Empty>
        </div>
      )}
    </div>
  );
}

export default Discuss;
