import React from "react";
import { Form, Select } from "antd";
import { JSON_SUB_TYPE, QuestionBeProps, QuestionProps, QuestionPropsV2 } from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";

function Dropdown({ data, initialData, disabled }: QuestionPropsV2) {
  return (
    <Form.Item
      initialValue={initialData?.answer[0]}
      name={`dropdown-${data?.id}-${QuestionTypeEnum.DROP_DOWN}-${JSON_SUB_TYPE.ChoosingRequest}`}
    >
      {data?.questions && data?.questions?.length > 0 ? (
        <Select
          disabled={disabled}
          placeholder="Chọn đáp án của bạn"
          options={data?.questions?.map((quiz: QuestionBeProps, index) => ({
            label:
              `${convertNumberingTypeToText(
                index + 1,
                Number(JSON.parse(data?.settingGeneral).numberingType)
              )}. ${quiz?.content}` || "Không có nội dung",
            value: quiz?.uuid || index,
          }))}
        />
      ) : (
        <div className="center">
          <EmptyComponent description="Không có nội dung" />
        </div>
      )}
    </Form.Item>
  );
}

export default Dropdown;
