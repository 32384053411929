import {AxiosResponse} from "axios";
import {axiosConfigV2} from "../../config/api/configApiv2";

//Số lượng khoá học theo hình thức xuất bản
export const getCoursesByFormat: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-course-by-format`, payload);
};

export const exportCoursesByFormat: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-course-by-format`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Số lượng bài giảng theo trạng thái xuất bản
export const getSequenceByAvailableStatus: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-sequence-by-available-status`,
    payload
  );
};

export const exportSequenceByAvailableStatus: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-sequence-by-available-status`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Số lượng bài giảng theo khoa
export const getSequenceByDepartment: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-sequence-by-industry-group`,
    payload
  );
};
export const exportSequenceByDepartment: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-sequence-by-industry-group`,
    data,
    {responseType: "arraybuffer"}
  );
};
//Số lượng Scorm & xAPI
export const getNumberSCORMxAPI: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-scorm-and-xapi-unit`, payload);
};
export const exportNumberSCORMxAPI: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-scorm-and-xapi-unit`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Số lượng học liệu đa phương tiện theo loại
export const getMultimaterialsByType: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-multi-media-unit`, payload);
};

export const exportMultimaterialsByType: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-multi-media-unit`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Số lượng học liệu theo khoá học

export const getUnitByCourse: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/chart-teacher/get-unit-by-course`, payload);
};

export const exportUnitByCourse: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-unit-by-course`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Số lượng bài kiểm tra theo loại

export const getTestByType: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/chart-teacher/get-test-by-type`, payload);
};
export const exportTestByType: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-test-by-type`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Số lượng tài liệu tham khảo theo loại

export const getReferenceSourceByType: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-reference-source-by-type`,
    payload
  );
};

export const exportReferenceSourceByType: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-reference-source-by-type`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Tỷ lệ kết quả đánh giá khóa học
export const getRateUnitByCourse: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-rate-unit-by-course`, payload);
};

export const exportRateUnitByCourse: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-unit-by-course`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Tỷ lệ kết quả làm đánh giá
export const getRateUnitByModule: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-rate-unit-by-module`, payload);
};

export const exportRateUnitByModule: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-unit-by-module`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Tỷ lệ kết quả đánh giá theo bài giảng
export const getRateUnitBySequence: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-rate-unit-by-sequence`,
    payload
  );
};

export const exportRateUnitBySequence: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-unit-by-sequence`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Thống kê số lượng tải về, xem, chia sẻ học liệu
export const getStaticUnitAction: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/by-time/get-unit-action`, payload);
};

export const exportStaticUnitAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-unit-action-by-time`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Thống kê số lượng đánh giá, truy cập học liệu
export const getUnitReviewAndAccess: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/by-time/get-unit-review-and-access`,
    payload
  );
};

export const exportUnitReviewAndAccess: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-unit-review-and-access`,
    data,
    {responseType: "arraybuffer"}
  );
};

//Thống kê số lượng phản hồi theo bài giảng
export const getRateSequenceAction: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-rate-sequence-action`, payload);
};

export const exportRateSequenceAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-sequence-action`,
    data,
    {responseType: "arraybuffer"}
  );
};
//Thống kê số lượng phản hồi theo lớp
export const getRateClassAction: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-rate-class-action`, payload);
};
export const exportRateClassAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-rate-class-action`,
    data,
    {responseType: "arraybuffer"}
  );
};
//Số lượng khoá học theo phân công
export const getCourseAssignTeacher: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/by-time/get-course-assign-teacher`,
    payload
  );
};

export const exportCourseAssignTeacher: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-course-assign-teacher-by-time`,
    data,
    {responseType: "arraybuffer"}
  );
};

export const exportUnitAction: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-university/by-time/get-unit-action`,
    data,
    {responseType: "arraybuffer"}
  );
};
//Thống kê lượng tìm kiếm toàn hệ thống theo thời gian
export const getSearchHistory: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(
    `/chart-teacher/by-time/get-search-history`,
    payload
  );
};
export const exportSearchHistory: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-search-history-by-time`,
    data,
    {responseType: "arraybuffer"}
  );
};
//Thống kê tìm kiếm theo từ khoá
export const getSearchKeywordCount: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/chart-teacher/get-search-keyword-count`, payload);
};
export const exportSearchKeywordCount: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-search-keyword-count`,
    data,
    {responseType: "arraybuffer"}
  );
};
// Thống kê số lượng phản hồi theo thời gian
export const getUnitDiscuss: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigV2.post(`/chart-teacher/by-time/get-unit-discuss`, payload);
};
export const exportUnitDiscuss: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-unit-discuss-by-time`,
    data,
    {responseType: "arraybuffer"}
  );
};

// Tỷ lệ kết quả đánh giá học liệu theo lớp
export const getRateUnitByClass: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/chart-teacher/get-star-rating-exam`,
    payload
  );
};
export const exportRateUnitByClass: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/get-star-rating-exam`,
    data,
    {responseType: "arraybuffer"}
  );
};

// Lấy danh sách khoa

export const getIndustryGroupFilter: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`chart-teacher/get-industry-group-filter`);
};

// Lấy danh sách lớp

export const getClassList: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`teacher-possession/filter-class-enroll`);
};

// === Ministry ===

// Export all
// Tab overview
export const exportAllTabOverviewMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/export-all-tab-overview`, data, {responseType: "arraybuffer"});
};

// Tab resource
export const exportAllTabResourceMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/export-all-tab-resource`, data, {responseType: "arraybuffer"});
};

export const exportTableVisitsMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/export-visits`, data, {responseType: "arraybuffer"})
}

export const exportTableVisitorMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/export-visitors`, data, {responseType: "arraybuffer"})
}

export const exportTableTeacherIndustryMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-teacher-by-universit`, data, {responseType: "arraybuffer"})
}

export const exportTableStudentIndustryMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-student-by-university`, data, {responseType: "arraybuffer"})
}

export const exportTableCourseFormatByUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-course-by-format-university`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityRelation: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-university-realtion`, data, {responseType: "arraybuffer"})
}

export const exportTableCourseByUniversitySponsor: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-course-by-university-sponsor`, data, {responseType: "arraybuffer"})
}

export const exportTableUnitRecourses: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-unit-resources-table`, data, {responseType: "arraybuffer"})
}

export const exportTableCourseByIndustry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-course-by-industry-group`, data, {responseType: "arraybuffer"})
}

export const exportTableUnitFormatByIndustry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-unit-by-industry-group`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityTestByType: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-university-test-by-type`, data, {responseType: "arraybuffer"})
}

export const exportTableReferenceSourceByType: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-university-reference-source-by-type`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityScomXapiUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-university-scorm-xapi-unit`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityMultiMediaUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-university-multi-media-unit`, data, {responseType: "arraybuffer"})
}

// Tab Feedback and evaluation
export const exportAllTabReviewMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/export-all-tab-review`, data, {responseType: "arraybuffer"});
};


export const exportTableUnitDiscussByTime: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/by-time/get-unit-discuss`, data, {responseType: "arraybuffer"})
}

export const exportTableDiscussBySequence: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-discuss-by-sequence`, data, {responseType: "arraybuffer"})
}

export const exportTableUnitReviewAndAccess: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/by-time/get-unit-review-and-access`, data, {responseType: "arraybuffer"})
}

export const exportTableCourseUnitByCourse: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-rate-course-by-course`, data, {responseType: "arraybuffer"})
}

export const exportTableRateUnitByCourse: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-rate-unit-by-course`, data, {responseType: "arraybuffer"})
}

export const exportTableRateUnitByModule: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-rate-unit-by-module`, data, {responseType: "arraybuffer"})
}

// Tab User behavior
export const exportAllTabBehaviorMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/export-all-tab-behavior`, data, {responseType: "arraybuffer"});
};

export const exportTableSearchKeyword: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/get-search-keyword-count`, data, {responseType: "arraybuffer"})
}
export const exportTableSearchHistory: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/by-time/get-search-history`, data, {responseType: "arraybuffer"})
}
export const exportTableUnitAction: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-admin-export/by-time/get-unit-action`, data, {responseType: "arraybuffer"})
}

// Lấy danh sách khối
export const getIndustryGroupMinistry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`chart-admin/get-industry-filter`);
}

// Lấy danh sách khóa học
export const getCourseFilterMinistry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`chart-admin/get-course-filter`);
}

// Lấy danh sách trường
export const getUniversityEnrollList: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`chart-admin/filter-university-enroll`);
};

// Tổng hợp số liệu Tổng quan
export const getSynthesizeDataUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-synthesize-data-university`, data);
}

// lấy số lượt truy cập và số người truy cập
export const getVisitsAndVisitors: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-visits-and-visitors`, data);
}

// Lấy đối tượng truy cập
export const getVisitsAndVisitorsByRegister: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-visits-and-visitors-by-register`, data);
}

// lấy danh sách trường liên kết
export const getUniversityRealtion: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-university-realtion`, data);
}

export const getAdminVisits: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-table-visits`, data);
}

export const getAdminVisitors: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-table-visitors`, data);
}

// Lấy danh sách giảng viên
export const getTeacherByUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-teacher-by-university`, data);
}

// Lấy danh sách học viên
export const getStudentByUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-student-by-university`, data);
}

// Lấy danh sách khóa học theo trường
export const getCourseByUniversitySponsor: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-course-by-university-sponsor`, data);
}

// Lấy số lượng tài nguyên
export const getUnitResources: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-unit-resources`, data);
}

// Lấy số lượng bảng tài nguyên
export const getUnitResourcesTable: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-unit-resources-table`, data);
}

// Tổng hợp số liệu Tài nguyên
export const getSynthesizeCourseUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-synthesize-course-university`, data);
}

// lấy danh sách khóa học
export const getCourseByFormatUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-course-by-format-university`, data);
}

// Lấy danh sách khóa học theo khoa
export const getCourseByIndustryGroup: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-course-by-industry-group`, data);
}

// Lấy danh sách bài giảng theo khoa
export const getUnitByIndustryGroup: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-unit-by-industry-group`, data);
}

// Lấy số lượng bài kiểm tra
export const getUniversityTestByType: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-university-test-by-type`, data);
}

// Lấy số lượng tài liệu tham khảo
export const getUniversityReferenceSourceByType: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-university-reference-source-by-type`, data);
}

// Lấy số lượng Scorm & xAPI
export const getUniversityScormXapiUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-university-scorm-xapi-unit`, data);
}

// lấy số lượng học liệu đa phương tiện
export const getUniversityMultiMediaUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-university-multi-media-unit`, data);
}

// Lấy số lượng phản hồi theo thời gian
export const getUnitDiscussForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/by-time/get-unit-discuss`, data);
}

// Lấy số lượng phản hồi theo bài giảng
export const getDiscussCourseActionForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-discuss-by-sequence`, data);
}

// Lấy số lượt đánh giá vs số lượt hoàn thành học liệu
export const getUnitReviewAndAccessForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/by-time/get-unit-review-and-access`, data);
}

// Lấy tỷ lệ đánh giá khóa học
export const getRateUnitByCourseForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-rate-unit-by-course`, data);
}

// Lấy tỷ lệ đánh giá bài giảng
export const getRateUnitBySequenceForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-rate-unit-by-sequence`, data);
}

// Lấy tỷ lệ đánh giá học liệu theo phân loại
export const getRateUnitByModuleForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-rate-unit-by-module`, data);
}

// Lấy số lượt tìm kiếm theo từ khóa
export const getSearchKeywordCountForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/get-search-keyword-count`, data);
}

// Lấy số lượt tìm kiếm theo từ khóa
export const getSearchHistoryForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/by-time/get-search-history`, data);
}

// Lấy số lượt tải về - xem - chia sẻ tài nguyên
export const getUnitActionForMinistry: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-admin/by-time/get-unit-action`, data);
}

// ====== University ======
// Lấy trường hiện tại
export const getCurrentUniversity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/chart-university/get-current-university`);
}

// Lấy danh sách giảng viên
export const getTeacherFilterUniversity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/chart-university/get-teacher-filter`);
}

// Lấy danh sách khối
export const getIndustryGroupFilterUniversity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/chart-university/get-industry-group-filter`);
}

// Lấy danh sách khóa học
export const getCourseFilterUniversity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/chart-university/get-course-filter`);
}

// === Tab Overview ===
// Lấy Tổng hợp số liệu
export const getListSynthesizeDataUniversityOverview: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-synthesize-data-university`, data);
}

export const getUniversityVisits: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-table-visits`, data);
}

export const getUniversityVisitors: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-table-visitors`, data);
}

// lấy số lượt truy cập và số người truy cập
export const getVisitsAndVisitorsUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-visits-and-visitors`, data);
}

// Lấy đối tượng truy cập
export const getVisitsAndVisitorsByRegisterUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-visits-and-visitors-by-register`, data);
}

// Lấy danh sách số lượng giảng viên
export const getListTeacherByIndustryGroupOverview: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-teacher-by-industry-group`, data);
}

// Lấy danh sách số lượng học viên 
export const getListStudentByIndustryGroup: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-student-by-industry-group`, data);
}

// Lấy danh sách khóa học theo khoa
export const getListCourseByUniversitySponsor: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-course-by-university-sponsor`, data);
}

// Lấy số lượng tài nguyên
export const getListUnitResources: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-unit-resources`, data);
}

// === Tab Resource ===
// Lấy Tổng hợp số liệu 
export const getListSynthesizeCourseUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-synthesize-course-university`, data);
}

// Lấy khóa học theo hình thức xuất bản
export const getListCourseByFormatUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-course-by-format-university`, data);
}

export const getListCourseByTeacherUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-teacher-assign-by-instruct`, data);
}

// Lấy số lượng khóa học theo khoa
export const getListCourseByIndustryGroup: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-course-by-industry-group`, data);
}

// Lấy số lượng bài giảng theo khoa
export const getListUnitByIndustryGroup: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-unit-by-industry-group`, data);
}

// Lấy số lượng bài kiểm tra theo loại
export const getListUniversityTestByType: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-university-test-by-type`, data);
}

// Lấy số lượng tài liệu tham khảo theo loại
export const getListUniversityReferenceSourceByType: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-university-reference-source-by-type`, data);
}

// Lấy số lượng Scorm & xAPI
export const getListUniversityScormXapiUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-university-scorm-xapi-unit`, data);
}

// Lấy số lượng đa phương tiện theo loại
export const getListUniversityMultiMediaUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-university-multi-media-unit`, data);
}

// === Tab Feedback and evaluation ===
// Lấy số lượt đánh giá vs số lượng hoàn thành học liệu
export const getUnitDiscussUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/by-time/get-unit-discuss`, data)
}

export const getCourseDiscussActionUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-discuss-course-action`, data)
}

export const getListUnitReviewAndAccess: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/by-time/get-unit-review-and-access`, data);
}

// Lấy tỷ lệ đánh giá học liệu theo phân loại
export const getListRateUnitByModule: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-rate-unit-by-module`, data);
}

// Lấy tỷ lệ đánh giá khóa học
export const getListRateUnitByCourse: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-rate-unit-by-course`, data);
}

// Lấy tỷ lệ đánh giá bài giảng
export const getListRateUnitBySequence: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/get-rate-unit-by-sequence`, data);
}

// === Tab User behavior ===
// Lấy số lượt tải về/xem/chia sẻ tài nguyên
export const getListUnitAction: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university/by-time/get-unit-action`, data);
}

export const getSynthesizeUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-synthesize-data-university`, data)
}

export const getTeacherByIndustryUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-teacher-by-industry-group`, data)
}

export const getSearchHistoryUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/by-time/get-search-history`, data)
}
export const getSearchKeywordUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/get-search-keyword-count`, data)
}
export const getUnitActionUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`chart-university/by-time/get-unit-action`, data)
}

export const exportTableVisitorUniversity: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/export-visitors`, data, {responseType: "arraybuffer"})
}

export const exportTableCourseFormatUniversityAction: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-course-by-format-university`, data, {responseType: "arraybuffer"})
}
export const exportTableTeacherAssignByInstruct: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-teacher-assign-by-instruct`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityCourseBySponsor: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-course-by-university-sponsor`, data, {responseType: "arraybuffer"})
}

// export const exportTableUniversityUnitResources: (data: any) => Promise<AxiosResponse<any>> = (data) => {
//   return axiosConfigV2.post(`/chart-university-export/get-unit-resources-table`, data, {responseType: "arraybuffer"})
// }

export const exportTableUnitByIndustryGroup: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-unit-by-industry-group`, data, {responseType: "arraybuffer"})
}

export const exportTableTestByIndustryGroup: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-university-test-by-type`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityReferenceSourceByType: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-university-reference-source-by-type`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityScormUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-university-scorm-xapi-unit`, data, {responseType: "arraybuffer"})
}

export const exportTableMultiMediaUnit: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-university-multi-media-unit`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityUnitDiscussByTime: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/by-time/get-unit-discuss`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityUnitDiscussCourseAction: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-discuss-course-action`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityUnitReviewAccess: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/by-time/get-unit-review-and-access`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityRateUnitByModule: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-rate-unit-by-module`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityRateUnitByCourse: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-rate-unit-by-course`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityRateUnitBySequence: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-rate-unit-by-sequence`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversitySearchKeywordCount: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/get-search-keyword-count`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversitySearchHistory: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/by-time/get-search-history`, data, {responseType: "arraybuffer"})
}

export const exportTableUniversityDownloadResources: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/chart-university-export/by-time/get-unit-action`, data, {responseType: "arraybuffer"})
}


// export all
//tab tài nguyên
export const exportAllResource: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-resource`,
    data,
    {responseType: "arraybuffer"}
  );
};

//tab phản hồi và đánh giá
export const exportAllFeedbackAndEvaluation: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-feedback`,
    data,
    {responseType: "arraybuffer"}
  );
};


//tab hành vi người dùng
export const exportAllUserBehavior: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(
    `/chart-teacher-excel/export-excel/export-all-tab-behavior`,
    data,
    {responseType: "arraybuffer"}
  );
};


export const getCourseLevel: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-courses/get-course-level`)
}
