// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-certification--sample {
  margin-top: 24px; }
  .save-certification--sample .group-button {
    justify-content: flex-start; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/tabs-setting-common/tab-certification/index.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;EADlB;IAII,2BAA2B,EAAA","sourcesContent":[".save-certification--sample {\n  margin-top: 24px;\n\n  .group-button {\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
