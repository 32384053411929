import {
  Button,
  Col,
  Flex,
  Form,
  message,
  Modal,
  Progress,
  Row,
  Select,
  SelectProps,
  Table,
  TableColumnsType,
  TabsProps,
  Typography,
  UploadFile,
  UploadProps
} from "antd";
import {MouseEvent, ReactElement, useEffect, useState} from "react";
import "./index.scss";
import {DeleteOutlined, FileExcelOutlined, PlusOutlined} from "@ant-design/icons";
import {
  getListEmail,
  getListSchool,
  importExcelFileShare,
  shareUsersAndUniversity
} from "../../../service/content-course/contenCourseApi";
import {regexEmail} from "../../../utils/regex";
import {get, isEmpty} from "lodash";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";
import {getAccountUsers} from "../../../service/list-account-users/listAccountUsersApi";
import {getDataTeachers} from "../../../service/list-account-teachers/listAccountTeachersApi";
import {getDataMinistry} from "../../../service/list-acoount-ministry/listAccountMinistry";
import ModalUploadFileExcel from "../modal-upload-file-excel";
import {getSampleTemplateExcel} from "../../../service/course-construct";
import {saveAs} from "file-saver";
import {UploadListType} from "antd/es/upload/interface";
import {ExcelFileIcon} from "../../icons/svg";
import {useAuthStore} from "../../../stores/stores";
import {FileType} from "../../../utils/uploadImage";
import {convertErrors} from "../../../utils/arrays";
import {getUniversities} from "../../../service/university";

type Props = {
  open: boolean
  handleClose: () => void
  quickReviewId: string,
  availableStatus: number,
  dataGeneralCostConfig: any
  dataGeneralConfig: any
};

interface IColumns {
  id: number;
  email: string;
  tittle: string;
  dataIndex: string;
  render: any;
}

export default function PublishShareModal(props: Props) {
  const {open, handleClose, quickReviewId, availableStatus, dataGeneralCostConfig, dataGeneralConfig} = props
  const [dataOptions, setDataOptions] = useState<any[]>([]);
  const [dataOptionsSchool, setDataOptionsSchool] = useState<any[]>([]);
  let timeout: ReturnType<typeof setTimeout> | null;
  let currentValue: string;
  const [listEmail, setListEmail] = useState<SelectProps["options"]>([]);
  const [listSchool, setListSchool] = useState<SelectProps["options"]>([]);
  const [listAllSchool, setListAllSchool] = useState<SelectProps["options"]>([]);
  const [valueEmail, setValueEmail] = useState<string>();
  const [valueSchool, setValueSchool] = useState<string>();
  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [isOpenedImport, setIsOpenedImport] = useState<boolean>(false)
  const [isHasUsers, setIsHasUsers] = useState<boolean>(false)
  const [checkUploadFile, setCheckUploadFile] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileList, setFileList] = useState<FileType[]>([]);


  const [dataEmail, setDataEmail] = useState({});
  const [dataEmailSchool, setDataEmailSchool] = useState<any>({});
  const [tab, setTab] = useState<string>("1");
  const [optionsUsers, setOptionsUsers] = useState<any[]>([])
  const [optionsUniversity, setOptionsUniversity] = useState<any[]>([])
  const [newOptionsUsers, setNewOptionsUsers] = useState<any[]>([])
  const [newOptionsUniversity, setNewOptionsUniversity] = useState<any[]>([])
  const [percentUpload, setPercentUpload] = useState(0);
  const [progress, setProgress] = useState(0);
  const {accessToken} = useAuthStore()
  const [form] = Form.useForm()

  const handleChange = (value: string) => {
    if (!value.match(regexEmail)) {
      setIsCheckEmail(true);
    }
    if (value.match(regexEmail)) {
      setIsCheckEmail(false);
    }
    setValueEmail(value);
  };

  const handleChangeSchool = (value: string) => {
    setValueSchool(value);
  };

  const handleDelete = (id: number) => {
    setDataOptions((prevState) => {
      return prevState.filter((i) => i.id !== id);
    });
  };

  const handleDeleteSchool = (id: number) => {
    setDataOptionsSchool((prevState) => {
      return prevState.filter((i) => i.id !== id);
    });
  };

  const handleAddEmail = () => {
    if (dataOptions.every((i) => i.email !== valueEmail) && !isEmpty(valueEmail)) {
      setDataEmail({
        ...dataEmail,
        email: valueEmail,
      });
    }
  };

  const handleAddSchool = () => {
    if (valueSchool) {
      const nameSchool = listAllSchool?.filter((item) => item.id === valueSchool)
      if (dataOptionsSchool.every((i) => Number(i.id) !== Number(valueSchool))) {
        setDataEmailSchool({
          ...dataEmailSchool,
          school: valueSchool,
          name: nameSchool?.[0]?.name
        });
      }
    }
  };

  const getListEmailUser = (value: string, callBack: Function) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    const getData = async () => {
      try {
        let response: any = null
        if (tab === '1') {
          response = await getListEmail(`?keyword=${value}`);
        } else {
          response = await getListSchool();
        }
        const {data, statusCode} = response?.data;
        if (statusCode === 200) {
          callBack(data);
        }

        let dataEmail = {}
        if (tab === '1') {
          dataEmail = data?.map((data: any) => ({
            value: data,
            text: data,
          }));
        } else {
          dataEmail = data?.map((data: any) => ({
            value: data.id,
            text: data.name,
          }));
          setListAllSchool(data)
        }

        callBack(dataEmail);
      } catch (err) {
        console.error(err);
      }
    };
    if (value) {
      timeout = setTimeout(getData, 300);
    } else {
      callBack([]);
    }
  };

  const fetchDataOption = async () => {
    const data = {
      page: 1,
      size: 1000
    }
    const dataReqUser = {
      pageNumber: 1,
      pageSize: 999
    }
    try {

      const response = await Promise.all([getAccountUsers({
        pageNumber: 1,
        pageSize: 11
      }), getDataTeachers(dataReqUser), getDataMinistry(dataReqUser), getUniversities(data)])
      const dataUsers = response[0].data.data
      const dataTeachers = response[1].data.data
      const dataMinistry = response[2].data.data
      const dataUniversity = response[3].data
      setOptionsUsers(prevState => [
        ...prevState,
        ...dataUsers.data,
        ...dataTeachers.data,
        ...dataMinistry.data
      ])
      setOptionsUniversity(dataUniversity.content)
    } catch (err) {
      console.error(err)
    }
  }
  const newDataUsersOption = optionsUsers.filter((obj, index, self) => index === self.findIndex((o) => o.email === obj.email)).map((user: any) => {
    return {
      name: user.fullName,
      code: user.email
    }
  })

  const newDataUniversityOption = optionsUniversity.filter((obj, index, self) => index === self.findIndex((o) => o.uuid === obj.uuid)).map((university: any) => {
    return {
      name: university.name,
      code: university.uuid,
    }
  })


  const handleSearch = (value: string) => {
    getListEmailUser(value, setListEmail);
  };

  const handleSearchSchool = (value: string) => {
    getListEmailUser(value, setListSchool);
  };


  const columns: TableColumnsType<IColumns> = [
    ...(tab === "1"
      ? [
        {
          key: "email",
          dataIndex: "email",
          title: "Email",
          width: "80%",
        },
      ]
      : [
        {
          key: "school",
          dataIndex: "school",
          title: "School",
          width: "80%",
        }
      ]),
    ...(tab === "1"
      ? [
        {
          key: "action",
          dataIndex: "action",
          width: "10%",
          render: (_: any, record: { id: number; email: string }) => {
            return (
              <>
                <span
                  className="icon-delete"
                  onClick={() => handleDelete(record.id || 0)}
                >
                  <DeleteOutlined/>
                </span>
              </>
            );
          },
        },
      ]
      : [
        {
          key: "action",
          dataIndex: "action",
          width: "10%",
          render: (_: any, record: { id: number; email: string }) => {
            return (
              <>
                <span
                  className="icon-delete"
                  onClick={() => handleDeleteSchool(record.id || 0)}
                >
                  <DeleteOutlined/>
                </span>
              </>
            );
          },
        },
      ]),
  ];

  useEffect(() => {
    handleSearch("")
  }, [tab])

  useEffect(() => {
    if (Object.keys(dataEmail).length > 0) {
      dataOptions.push(dataEmail);
      setDataOptions(
        dataOptions.map((i, index) => ({id: index + 1, email: i.email})),
      );
    }
  }, [dataEmail]);

  useEffect(() => {
    if (Object.keys(dataEmailSchool).length > 0) {
      const obj = {
        id: dataOptionsSchool.length + 1,
        school: dataEmailSchool?.name
      }
      setDataOptionsSchool([...dataOptionsSchool, ...[obj]]);
    }
  }, [dataEmailSchool]);

  useEffect(() => {
    if (open) {
      fetchDataOption()
    }
  }, [open])

  useEffect(() => {
    if (dataGeneralCostConfig) {
      form.setFieldsValue({
        users: dataGeneralCostConfig.shareUsers,
        university: dataGeneralCostConfig.shareEnterprise
      })
    }
  }, [dataGeneralCostConfig])

  const beforeUploadFile = (file: FileType) => {
    const isExcel = file.type === "text/csv" || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'

    if (!isExcel) {
      message.error("Chỉ tải lên được file xlsx/csv");
    }
    setFileList([file])

    return isExcel;
  };

  const handleUpload = async (options: any) => {
    const {onSuccess, onError, file, onProgress} = options;
    const fmData = new FormData();
    const config = {
      headers: {"content-type": "multipart/form-data"},
      onUploadProgress: (event: { loaded: number; total: number; }) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        onProgress({percent: (event.loaded / event.total) * 100});
      }
    };
    fmData.append('file', file);
    fmData.append('isEnterprise', isHasUsers ? 'true' : 'false');
    fmData.append('courseId', Number(quickReviewId).toString());

    try {
      setErrorMessage(null)
      const response = await importExcelFileShare(fmData, config);
      onSuccess(response)
      if (response && response.status === 200 && !isHasUsers) {
        const newOptions = newDataUsersOption.filter(user => response.data.data.includes(user.code)).map(user => user.code)
        console.log(newOptions, response.data.data)
        setNewOptionsUsers(newOptions)
      }
      if (response && response.status === 200 && isHasUsers) {
        const newOptions = newDataUniversityOption.filter(university => response.data.data.includes(university.code)).map(university => university.code)
        setNewOptionsUniversity(newOptions)
      }

    } catch (error: any) {
      if (error.response.data.codeStatus === 400) {
        setCheckUploadFile(true)
        message.error(`Tệp ${file.name} tải lên thất bại.`);
        setErrorMessage(error.response.data.message)
      }
      console.error(error);
      onError(error);
    }
  }

  const propsModal: UploadProps = {
    name: 'file',
    maxCount: 1,
    customRequest: handleUpload,
    beforeUpload: beforeUploadFile,
    fileList: fileList,
    onChange(info) {
      const {status, percent} = info.file;
      if (status !== 'uploading') {
        setCheckUploadFile(true)
      }
      if (status === 'done') {
        message.success(`Tệp ${info.file.name} tải lên thành công.`);
        if (percent) {
          setPercentUpload(percent);
        }

        setCheckUploadFile(false)
      }
    },
    onDrop(e) {
    },
    iconRender: (file: UploadFile<any>, listType?: UploadListType | undefined) => {
      const isExcel = file.type === "text/csv" || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
      return (
        (<FileExcelOutlined style={{color: `${isExcel ? '#52C41A' : '#FF4D4F'}`}}/>)
      )
    },
    itemRender: (originNode: ReactElement, file: UploadFile, fileList,
                 actions: { download: any, preview: any, remove: any }
    ) => {
      const isExcel = file.type === "text/csv" || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
      const errorMessages = errorMessage && convertErrors(errorMessage)
      return (
        <>
          {
            <>
              <Flex justify={'space-between'} className="item-render">
                <p className="status-progress--upload">
                  <span>
                    <ExcelFileIcon/>
                  </span>
                  <span>
                    {file.name}
                  </span></p>
                <Progress percent={progress}
                          status={checkUploadFile ? 'exception' : 'success'}
                          showInfo={true}
                          size={'default'}
                          style={{width: 200}}/>
              </Flex>
              {
                !isExcel ? (
                  <div className="errorUpload">
                    <span>Lỗi định dạng file !</span>
                  </div>
                ) : isExcel && errorMessage ? (
                  <div className="errorUpload">
                    <Flex vertical={true} gap={"small"}>
                      <span>
                        Lỗi dữ liệu file!
                      </span>
                      {errorMessages && errorMessages.map((mess: string, index: number) => (
                        <span key={index}>
                          {mess}
                        </span>
                      ))}
                    </Flex>
                  </div>
                ) : (
                  <></>
                )
              }
            </>
          }
        </>
      )
    }
  };


  const handleSendEmail = async (values: any) => {
    const dataReq = {
      idCourse: quickReviewId,
      courseFormatId: dataGeneralCostConfig.formatId,
      displayPlace: dataGeneralCostConfig.displayPlace,
      costCourse: dataGeneralCostConfig.cost,
      costCredit: dataGeneralCostConfig.costCredit,
      costCertification: dataGeneralCostConfig.costCertification,
      paymentMethodId: dataGeneralCostConfig.paymentMethod,
      isScheduleByTeacher: dataGeneralCostConfig.isScheduleByTeacher,
      isCostFree: dataGeneralConfig.isCostFree,
      limit: dataGeneralCostConfig.limit,
      typeLimit: dataGeneralCostConfig.typeLimit,
      notificationConfigId: dataGeneralConfig.notificationConfigId,
      notificationConfig: {
        isDefault: dataGeneralCostConfig.notificationIsDefault,
        receivedOverMail: dataGeneralCostConfig.receivedOverMail,
        receivedOverSystem: dataGeneralCostConfig.receivedOverSystem,
        notificationOptionalId: !dataGeneralCostConfig.notificationIsDefault ? get(
          dataGeneralCostConfig,
          "notificationOptionals",
          [1]
        ) : []
      },
      uuids: values.university,
      userEmails: values.users,
    }


    try {
      const response = await shareUsersAndUniversity(dataReq)

      if (response.data) {
        message.success("Chia sẻ khoá học thành công")
        setTab("1")
        handleClose()
        setDataOptions([])
        setDataOptionsSchool([])
        setDataEmail({})
        setDataEmailSchool({})
        setValueEmail("")
        setValueSchool("")
      }

    } catch (err) {
      console.error(err)
    }

  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      // icon: <UserOutlined />,
      label: "Người nhận",
      children: (
        <>
          <div className="flex items-center gap-3">
            <Row gutter={8} className="w-100">
              <Col md={4} className="flex items-center">
                <Typography.Text>Nhập email:</Typography.Text>
              </Col>
              <Col md={16}>
                <Select
                  showSearch
                  placeholder="Nhập email"
                  className="w-full"
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  virtual={true}
                  filterOption={false}
                  onSearch={handleSearch}
                  options={listEmail?.map((d) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                  value={valueEmail}
                  onChange={handleChange}/>
              </Col>
              <Col md={4}>
                <Button onClick={handleAddEmail} className="btn">
                  <PlusOutlined/>
                  <Typography.Text>Thêm</Typography.Text>
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <Table
              className="mt-2"
              virtual={true}
              scroll={{y: 250}}
              pagination={false}
              columns={columns}
              rowKey={(obj) => obj.id}
              dataSource={dataOptions}/>
          </div>
        </>
      ),
    },
    {
      key: "2",
      // icon: <HistoryOutlined />,
      label: "Trường đại học",
      children: (

        <>
          <div className="flex items-center gap-3">
            <Row gutter={8} className="w-100">
              <Col md={6} className="flex items-center">
                <Typography.Text>Nhập tên trường:</Typography.Text>
              </Col>
              <Col md={14}>
                <Select
                  showSearch
                  placeholder="Nhập tên trường"
                  className="w-full"
                  notFoundContent={null}
                  defaultActiveFirstOption={false}
                  suffixIcon={null}
                  virtual={true}
                  filterOption={false}
                  onSearch={handleSearchSchool}
                  options={listSchool?.map((d) => ({
                    value: d.value,
                    label: d.text,
                  }))}
                  value={valueSchool}
                  onChange={(e: any) => handleChangeSchool(e)}/>
              </Col>
              <Col md={4}>
                <Button onClick={handleAddSchool} className="btn">
                  <PlusOutlined/>
                  <Typography.Text>Thêm</Typography.Text>
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <Table
              className="mt-2"
              virtual={true}
              scroll={{y: 250}}
              pagination={false}
              columns={columns}
              rowKey={(obj) => obj.id}
              dataSource={dataOptionsSchool}/>
          </div>
        </>
      ),
    },
  ]

  const handleCancel = () => {
    setIsOpenedImport(false)
    setFileList([])
  }
  const handleOK = () => {
    setIsOpenedImport(false)
    if (!isHasUsers && newOptionsUsers.length > 0) {
      form.setFieldsValue({
        users: newOptionsUsers
      })
    }
    if (isHasUsers && newOptionsUniversity.length > 0) {
      form.setFieldsValue({
        university: newOptionsUniversity
      })
    }
    setFileList([])
    setErrorMessage(null)
    message.success("Cập nhật danh sách thành công")
  }


  const downloadSampleTemplate = async (e: MouseEvent) => {
    e.preventDefault()
    try {
      const response = await getSampleTemplateExcel(isHasUsers, {
        responseType: 'arraybuffer',
        headers: {'Content-Type': 'blob'},
      })
      if (response.data) {
        const outputFilename = `sampleTemplate.xlsx`;
        let blobFile = new Blob([response.data], {type: 'application/json'});

        saveAs(blobFile, outputFilename)
      }
    } catch (err) {
      console.error(err);
    }

  }

  const handleOpenImport = (check: boolean, status: boolean) => {
    setIsOpenedImport(check)
    setIsHasUsers(status)
  }


  return (
    <Modal
      title="Chia sẻ"
      okText="Chia sẻ"
      open={open}
      width={600}
      onCancel={handleClose}
      onOk={() => form.submit()}
      okButtonProps={{htmlType: "submit"}}
      className=""
      cancelText="Hủy"
    >
      <Form form={form} onFinish={handleSendEmail}>
        <div className="info-publish-wrapper select-display">
          <div className="title">
            Người dùng:
          </div>
          <div className="form-item--info_publish">
            <Form.Item name="users">
              <FormItemDropdown modeDropdown="multiple" options={newDataUsersOption}
                                placeholder="Chọn người dùng"/>
            </Form.Item>
          </div>
          <div className="form-item--info_import">
            <Button onClick={() => handleOpenImport(true, false)}><PlusOutlined/>
              Import
            </Button>
          </div>
        </div>
        <div className="info-publish-wrapper select-display">
          <div className="title">
            Trường:
          </div>
          <div className="form-item--info_publish ">
            <Form.Item name="university">
              <FormItemDropdown modeDropdown="multiple" options={newDataUniversityOption}
                                placeholder="Chọn trường"/>
            </Form.Item>
          </div>
          <div className="form-item--info_import">
            <Button onClick={() => handleOpenImport(true, true)}><PlusOutlined/>
              Import
            </Button>
          </div>
        </div>
      </Form>
      <ModalUploadFileExcel
        title={`Import danh sách ${!isHasUsers ? 'người dùng' : 'trường'}`}
        open={isOpenedImport}
        isHasUsers={!isHasUsers}
        onOk={handleOK}
        onCancel={handleCancel}
        okText="Xác nhận"
        cancelText="Huỷ"
        checkError={checkUploadFile}
        propsDragger={propsModal}
        downloadSample={downloadSampleTemplate}
      />
    </Modal>
  );
}
