import React from 'react';
import {Button, Col, ConfigProvider, DatePicker, Form, FormInstance, Row, Typography} from "antd";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import {DeleteOutlined, FilterOutlined} from "@ant-design/icons";
import './filter-form.scss'
import {useForm} from "antd/es/form/Form";
import {surveyMethodsFilter} from "../../../../pages/course-construction/survey-plan/constants/data";
import locale from 'antd/locale/vi_VN';
import dayjs from 'dayjs';

import 'dayjs/locale/vi';

dayjs.locale('vi');


type FilterFormProps = {
  showFilter?: boolean
  onFinish?: (values: any) => void;
  handleCloseFilter?: () => void;
  clearFilter: (form: FormInstance) => void;
  filterData?: any;
  dataOptions?: any
}


const dataOptionsStatus = [
  {
    name: 'Đang tiến hành',
    code: 'in_process',
  },
  {
    name: 'Chưa tiến hành',
    code: 'not_started',
  },
  {
    name: 'Hết hạn',
    code: 'finish'
  }
]


const FilterForm = ({showFilter, onFinish, clearFilter, filterData, dataOptions}: FilterFormProps) => {
  const [form] = useForm()
  return (
    <ConfigProvider locale={locale}>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{xs: 8, xl: 16, md: 16}, 8]}>
            <Col
              xl={{span: 5}}
              md={{span: 5}}
              sm={{span: 5}}
              xs={{span: 24}}
            >
              <Typography.Title level={5}>Phương pháp khảo sát</Typography.Title>
              <Form.Item name="surveyTarget">
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Chọn phương pháp khảo sát"
                  value={filterData?.surveyTarget}
                  options={surveyMethodsFilter}
                />
              </Form.Item>
            </Col>
            <Col xl={{span: 5}}
                 md={{span: 5}}
                 sm={{span: 5}}
                 xs={{span: 24}}>
              <Typography.Title level={5}>Thời gian</Typography.Title>

              <Form.Item name="rangeDate">

                <DatePicker.RangePicker
                  allowEmpty={[true, true]}
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  format='DD/MM/YYYY'
                />

              </Form.Item>
            </Col>
            <Col xl={{span: 5}}
                 md={{span: 5}}
                 sm={{span: 5}}
                 xs={{span: 24}}>
              <Typography.Title level={5}>Đối tượng</Typography.Title>
              <Form.Item name="surveyObject">
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Chọn đối tượng"
                  value={filterData?.surveyObject}
                  options={dataOptions?.dataSurveyObject}
                />
              </Form.Item>
            </Col>
            <Col xl={{span: 5}}
                 md={{span: 5}}
                 sm={{span: 5}}
                 xs={{span: 24}}>
              <Typography.Title level={5}>Chọn trạng thái</Typography.Title>
              <Form.Item name="statusSurveys">
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Chọn trạng thái"
                  options={dataOptionsStatus}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{span: 4}}
              md={{span: 4}}
              sm={{span: 4}}
              xs={{span: 24}}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                >
                  <FilterOutlined/>
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={() => clearFilter(form)}
                >
                  <DeleteOutlined/>
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </ConfigProvider>

  );
};

export default FilterForm;
