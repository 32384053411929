import {
  Button,
  Card,
  Flex,
  Layout,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import { useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import SelectUiCard from "../../../../components/select-ui";
import { CloseOutlined, InfoCircleOutlined } from "@ant-design/icons";
import CommonModal from "../../../../components/modal/common";
import FormInput from "../../../../components/form-input-login/FormInput";
import { verifyPassword } from "../../../../service/auth";
import { isEmpty } from "lodash";
import {
  getAllTagSystemSuggestCourse,
  getSystemSuggestCourseById,
  updateSystemSuggestCourse,
} from "../../../../service/system-suggest-course";
import { CheckRole, getPermissions } from "../../../../utils/checkRole";
import { Permission } from "../../../../layout/sidebar/permission.enum";
const imgPath = "/images/";

const { Title, Text } = Typography;
const { Content } = Layout;

const InfoCourseSystemDetail = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const params = useParams();
  const [password, setPassWord] = useState("");
  const [checkPass, setCheckPassword] = useState<boolean>(true);
  const [optionSelectTag, setOptionSelectTag] = useState([]);
  const [dataTag, setDataTag] = useState<any>([]);
  const [isShowAll, setIsShowAll] = useState(false);
  const [isShowAllSpecializes, setIsShowAllSpecializes] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isConfirmCreate, setIsConfirmCreate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const [idTag, setIdTag] = useState<any>([]);
  const [idTagItem, setIdTagItem] = useState<any>(0);
  const [displayRoleSetting, setDisplayRoleSetting] = useState(false);

  const [listPermission, setListPermission] = useState([]);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleSetting = [Permission.LearningResourcesSystemUpdate];

    const checkRoleSetting = CheckRole(roleSetting, listPermission);
    if (checkRoleSetting.includes(true)) {
      setDisplayRoleSetting(true);
    }
  }, [listPermission]);

  const fetchDataById = async () => {
    try {
      const response = await getSystemSuggestCourseById(params?.id || "");
      const { data } = response.data;
      setDataTag(data);
      setIdTag(
        data?.mooc_recommendation?.map(
          (value: any) => value?.mooc_recommendation_tag?.id
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
  const fetchListTag = async () => {
    try {
      const response = await getAllTagSystemSuggestCourse();
      const { data } = response.data;

      setOptionSelectTag(
        data?.data?.map((tag: any) => ({
          label: tag?.name,
          value: tag?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDataById();
    fetchListTag();
  }, [isRefresh]);

  const onChangeShareReport = (val: any, name: string) => {
    setIdTag(val);
  };
  const handleChange = (value: any) => {
    setPassWord(value);
  };
  const handleNotice = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };
  const handleConfirmDelete = () => {
    setIsConfirmDelete(true);
    setIsOpenDelete(false);
    setIdTagItem(null);
  };
  const handleDeleteItem = (id: any) => {
    setIsConfirmDelete(true);
    setIdTagItem(id);
  };
  const handleSubmitDelete = async () => {
    let filterTag = dataTag?.mooc_recommendation.filter(
      (item: any) => item?.mooc_recommendation_tag?.id !== idTagItem
    );
    const paramsTag = filterTag?.map(
      (value: any) => value?.mooc_recommendation_tag?.id
    );
    try {
      const response = await verifyPassword({ password });
      const { data } = response;
      if (data?.statusCode === 200) {
        await updateSystemSuggestCourse(params?.id || "", {
          tag_ids: idTagItem ? paramsTag : [],
        });

        handleNotice("Xóa thành công");
        setCheckPassword(true);
        setIsConfirmDelete(false);
        setIsRefresh(!isRefresh);
      } else {
        setCheckPassword(false);
      }
    } catch (error) {
      setCheckPassword(false);
      console.log(error);
    }
  };
  const cancelModal = () => {
    setPassWord("");
    setIsConfirmDelete(false);
    setCheckPassword(true);
    setIsConfirmCreate(false);
  };

  const handleUpdateTag = async () => {
    try {
      const response = await verifyPassword({ password });
      const { data } = response;
      if (data?.statusCode === 200) {
        await updateSystemSuggestCourse(params?.id || "", { tag_ids: idTag });
        handleNotice("Lưu thông tin thành công");
        setIsRefresh(!isRefresh);
        setIsConfirmCreate(false);
      } else {
        setCheckPassword(false);
      }
    } catch (error) {
      setCheckPassword(false);
      console.log(error);
    }
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        title="Cài đặt mở rộng"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() =>
          navigate(
            `/system-suggest-course/${routesConfig.learningDocumentationSystem}`
          )
        }
      />
      <Card className="w-full lg:w-2/3 m-auto mt-12">
        <div className="pb-3">
          <Title level={4}>Thông tin khoá học</Title>
          <Text className="text-[#00000073]">
            Cài đặt thông tin môn học được thiết lập
          </Text>
        </div>
        <Flex wrap="wrap">
          <div className="pt-2 sm:pt-0 pr-12">
            <p>Tên khoá học</p>
            <p className="font-medium">{dataTag?.name}</p>
          </div>
          <div className="pt-2 sm:pt-0 pr-12">
            <p>Mã khoá học</p>
            <p className="font-medium">{dataTag?.code}</p>
          </div>
          <div className="pt-2 sm:pt-0 pr-12">
            <p>Trường chủ trì</p>
            <p className="font-medium">{dataTag?.university?.name}</p>
          </div>
        </Flex>
      </Card>
      <Card className="w-full lg:w-2/3 m-auto mt-12">
        <div className="border-b pb-3">
          <Title level={4}>Khoa/chuyên ngành áp dụng</Title>
          <Text className="text-[#00000073]">
            Thông tin chuyên ngành và khoa thích hợp với khoá học
          </Text>
        </div>
        <div className="border-b pt-4 pb-3 flex flex-wrap">
          <Title level={5} className="pr-4 w-full sm:w-1/3">
            Chuyên ngành
          </Title>
          <Flex wrap="wrap" className="w-full sm:w-2/3">
            {dataTag?.mooc_course_industry?.map((value: any, index: number) => {
              if (index <= 5 && !isShowAllSpecializes) {
                return (
                  <div
                    key={index}
                    className="border p-1 bg-[#FAFAFA] mb-1 mr-2 rounded"
                  >
                    {value?.mooc_industry?.name}
                  </div>
                );
              } else if (isShowAllSpecializes) {
                return (
                  <div
                    key={index}
                    className="border p-1 bg-[#FAFAFA] mb-1 mr-2 rounded"
                  >
                    {value?.mooc_industry?.name}
                  </div>
                );
              }
            })}
            {dataTag?.mooc_course_industry?.length > 4 && (
              <Button
                type="primary"
                onClick={() => setIsShowAllSpecializes(!isShowAllSpecializes)}
              >
                {isShowAllSpecializes ? "Thu gọn" : "Xem tất cả"}
              </Button>
            )}
          </Flex>
        </div>
        <div className="border-b pt-4 pb-3 flex flex-wrap">
          <Title level={5} className="pr-4 w-full sm:w-1/3">
            Khoa
          </Title>
          <Flex wrap="wrap" className="w-full sm:w-2/3">
            {dataTag?.mooc_course_industry_group?.map(
              (value: any, index: number) => {
                if (index <= 5 && !isShowAll) {
                  return (
                    <div
                      key={index}
                      className="border p-1 bg-[#FAFAFA] mb-1 mr-2 rounded"
                    >
                      {value?.mooc_industry_group?.name}
                    </div>
                  );
                } else if (isShowAll) {
                  return (
                    <div
                      key={index}
                      className="border p-1 bg-[#FAFAFA] mb-1 mr-2 rounded"
                    >
                      {value?.mooc_industry_group?.name}
                    </div>
                  );
                }
              }
            )}
            {dataTag?.mooc_course_industry_group?.length > 4 && (
              <Button type="primary" onClick={() => setIsShowAll(!isShowAll)}>
                {isShowAll ? "Thu gọn" : "Xem tất cả"}
              </Button>
            )}
          </Flex>
        </div>
      </Card>
      <Card className="w-full lg:w-2/3 m-auto mt-12">
        <div className="border-b pb-3">
          <Title level={4}>Sở thích theo chủ đề</Title>
          <Text className="text-[#00000073]">
            Chọn danh sách tag sở thích theo chủ đề
          </Text>
        </div>
        <div className="border-b pt-4 pb-3 flex items-center flex-wrap">
          <Title level={5} className="w-full sm:w-1/3">
            Tag sở thích
          </Title>
          <Flex className="w-full sm:w-2/3">
            <SelectUiCard
              className="font-medium w-full"
              name="tag"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectTag}
              mode="multiple"
              placeholder="Nhập tên Tag"
              allowClear
              value={idTag}
              onChange={(val) => onChangeShareReport(val, "tag")}
              optionRender={(option: any) => (
                <Space>
                  <span role="img" aria-label={option.data.label}>
                    {option.data.label}
                  </span>
                  {option.data.desc}
                </Space>
              )}
            />
            {displayRoleSetting && <Button
              className="ml-3 mb-[5px]"
              type="primary"
              disabled={isEmpty(idTag)}
              onClick={() => setIsConfirmCreate(true)}
            >
              Lưu
            </Button>}
          </Flex>
        </div>
        <div className="border-b pt-4 pb-3 flex flex-wrap">
          <Title level={5} className="pr-4 w-full sm:w-1/3">
            Tag đã chọn
            <p
              className="text-[#1677FF] cursor-pointer"
              onClick={() => setIsOpenDelete(true)}
            >
              Xoá tất cả
            </p>
          </Title>
          <div className="w-full sm:w-2/3">
            <Flex wrap="wrap" className="w-full">
              {dataTag?.mooc_recommendation?.map((value: any) => (
                <div className="border p-1 bg-[#FAFAFA] mb-1 mr-2 rounded">
                  {value?.mooc_recommendation_tag?.name}
                  <CloseOutlined
                    className="pl-2 w-[18px] cursor-pointer"
                    onClick={() =>
                      handleDeleteItem(value?.mooc_recommendation_tag?.id)
                    }
                  />
                </div>
              ))}
            </Flex>
          </div>
        </div>
      </Card>
      <CommonModal
        open={isOpenDelete}
        title={`Xoá file thẻ tag`}
        desc={
          <div>
            Thẻ tag đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá
            toàn bộ thẻ tag?
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmDelete}
        closeModal={() => setIsOpenDelete(false)}
        className={""}
      />
      <Modal
        title={
          <div>
            <InfoCircleOutlined
              style={{ color: "#FFC53D", marginRight: "10px" }}
            />{" "}
            Xoá thẻ tag
          </div>
        }
        open={isConfirmDelete}
        centered
        className="!w-[465px] report-delete-confirm"
        onCancel={cancelModal}
        closeIcon={true}
        footer={[
          <Flex>
            <Button
              key="ok"
              className="mr-2"
              onClick={cancelModal}
            >
              Hủy
            </Button>
            <Button
              key="ok"
              className="bg-[#FF4D4F] text-white"
              onClick={handleSubmitDelete}
            >
              Xoá thẻ tag
            </Button>
          </Flex>,
        ]}
      >
        <div>
          <FormInput
            type="password"
            value={password}
            title="Mã UserID"
            onChange={handleChange}
            isPassword
            placeholder="Nhập mật khẩu của bạn"
          />
          {!checkPass && (
            <Typography.Text className="text-[#FF4D4F]">
              Mật khẩu chưa chính xác. Vui lòng nhập lại
            </Typography.Text>
          )}
        </div>
      </Modal>
      <CommonModal
        open={isConfirmCreate}
        title={`Xác nhận lưu thông tin`}
        desc={
          <div>
            <FormInput
              type="password"
              value={password}
              title="Mã UserID"
              onChange={handleChange}
              isPassword
              placeholder="Nhập mật khẩu của bạn"
            />
            {!checkPass && (
              <Typography.Text className="text-[#FF4D4F]">
                Mật khẩu chưa chính xác. Vui lòng nhập lại
              </Typography.Text>
            )}
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleUpdateTag}
        closeModal={cancelModal}
        className={"!w-[465px]"}
      />
    </Content>
  );
};

export default InfoCourseSystemDetail;
