import { IDataCreateUser } from "../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig, axiosConfigUpload } from "../../../config/api/configApi";
import {
  TypeChapterList,
  TypeCourseDescriptionDocument,
} from "../../../types/course-plan-managment/mooc-course-construction-plan";

export const checkExistCodeName: (
  input: string
) => Promise<AxiosResponse<any>> = (input) => {
  return axiosConfig.get(
    `/planning-course-construction/check-exist-code-name/${input}`
  );
};
export const postPlanningCourseConstructionSearch: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/planning-course-construction/search`, data);
};

export const getMoocCourseConstructionPlanById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/planning-course-construction/get-by-id/${id}`);
};

export const getListPlanningCourseConstruction: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(
    `/planning-course-construction/list-course-construction`
  );
};

export const putUpdateMoocCourseConstructionPlan: (
  id: number,
  data: object
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/planning-course-construction/update/${id}`, data);
};

export const postCreatePlanningCourseConstruction: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/planning-course-construction/create`, data);
};

export const deleteMoocCourseConstructionPlan: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.put(`/planning-course-construction/delete/${id}`);
};
export const deleteStructurePlanningCourseConstruction: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.put(
    `/planning-course-construction/delete-structure/${id}`
  );
};
export const getMoocCourseConstructionPlanHistory: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/planning-course-construction/get-action`, data);
};
export const sendApproval: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/planning-course-construction/send-approval`, data)
}
// Categories

export const getLookupAuthUser: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-user`);
};
export const getLookupMoocUniversity: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`/lookup/get-schools`);
};
export const getLookupIndustry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-industry`);
};
export const getLookupIndustryGroup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-industry-group`);
};

export const createIndustryGroup: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`mooc-construction-plan-industry-group/create`, data);
};
export const createIndustry: (data: object) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post(`/mooc-construction-plan-industry/create`, data);
};

//course-structure
export const getListMoocCourseStructureById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/get-list-course-structure-by-id/${Number(
      id
    )}`
  );
};

export const getListMoocCourseStructureChapterById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/get-list-course-structure-chapter-by-id/${Number(
      id
    )}`
  );
};

export const getListMoocCourseDescriptionDocumentById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/get-list-course-description-document-by-id/${Number(
      id
    )}`
  );
};

export const postUpdateCourseDescriptionDocumentById: (
  data: TypeCourseDescriptionDocument[],
  id: number
) => Promise<AxiosResponse<any>> = (data, id) => {
  return axiosConfig.post(
    `/mooc-course-construction-plan/create-mooc-course-description-document-by-id/${id}`,
    { descriptionDocumentList: data }
  );
};

export const postUpdateCourseStructureById: (
  data: TypeChapterList[],
  id: number
) => Promise<AxiosResponse<any>> = (data, id) => {
  return axiosConfig.post(
    `/mooc-course-construction-plan/create-mooc-course-structure-by-id/${id}`,
    { courseStructureList: data }
  );
};

export const upLoadFileDescriptionDocument: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigUpload.post(
    `/s3-client/upload-course-description-document`,
    data
  );
};

export const downloadFileDescriptionDocument: (data: {
  url: string;
  destination?: string;
}) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.get(
    `/s3-client/downloadFile-course-description-document`,
    data
  );
};

export const downloadFile: (data: {
  filePath: any;
  destination: any;
}) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/s3-client/downloadFile`, data);
};
//approval-configuration

export const getApprovalConfiguration: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-approval-configuration/search`, data);
};

export const getApprovalResultCourseConstructPlan: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/get-list-approval-result-course-construct-plan-by-id/${Number(
      id
    )}`
  );
};

export const revokeCourseConstructPlan: (data: object) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post(`/planning-course-construction/revoke-course-construct-plan-by-id`, data);
};
