import React, { ReactNode, useState } from "react";
import { App, Input, Modal } from "antd";
import DOMPurify from "dompurify";
import useKeyword from "../../../../../../hooks/useKeyword";
import { toNumber } from "lodash";
import { notifySuccess } from "../../../../../../utils/notification";
import { reportExam } from "../../../../../../service/learning-course";

interface ReportModalProps {
  children?: ReactNode;
  sessionId: string;
}

function ReportModal({ children, sessionId }: ReportModalProps) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const blockId = useKeyword("blockId");

  const showModal = () => {
    setOpen(true);
  };

  const handleSendReport = async () => {
    if (value) {
      try {
        setLoading(true);
        await reportExam({
          content: DOMPurify.sanitize(value),
          blockId: toNumber(blockId),
          sessionId: sessionId,
        });
        notifySuccess("Gửi báo cáo thành công");
        setOpen(false);
        setValue("");
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <App>
      <div onClick={showModal}>{children}</div>
      <Modal
        title={
          <span className="font-size-24 line-height-32 font-weight-6">
            Báo cáo
          </span>
        }
        open={open}
        confirmLoading={loading}
        maskClosable={false}
        centered
        className="report-modal"
        onOk={handleSendReport}
        onCancel={() => setOpen(false)}
        okText={"Gửi báo cáo"}
        cancelText="Hủy"
      >
        <p className="font-size-16 mb-3">
          Bạn muốn gửi feedback về bài tập/bài thi/bài kiểm tra? Hay có thắc mắc
          về kết quả bài làm?{" "}
        </p>
        <Input.TextArea
          value={value}
          onChange={(e) => setValue(DOMPurify.sanitize(e.target.value))}
          placeholder="Nhập mô tả tại đây"
          className="mb-2"
        />
      </Modal>
    </App>
  );
}

export default ReportModal;
