import React, { useEffect, useState } from "react";
import { Pagination, PaginationProps, Table, TableColumnsType } from "antd";

import { get, isEmpty, set } from "lodash";
import { useParams } from "react-router-dom";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./DetailConfirm.scss";
import { getMoocApprovedHistoryById } from "../../../../../service/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-registration-api";
import { FilterDataMoocApproval } from "../../../../../types/course-plan-managment/mooc-approval";
import { formatDateToIsoStringHHss } from "../../../../../utils/converDate";
const DetailConfirm = () => {
  const [listApproval, setListApproval] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [idDetail, setIdDetail] = useState(0);

  const params = useParams();
  const { id } = useParams();
  const getlistData = async () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };
    await getMoocApprovedHistoryById(Number(id), data)
      .then(async (res) => {
        const { data } = res.data;
        setListApproval(data.data);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getlistData();
  }, [pageNumber, pageSize]);
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2, "0");
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên cán bộ duyệt",
      width: 10,
      render: (_, record: Record<string, any>) => {
        return <div>{record?.individualName}</div>;
      },
    },
    {
      title: "Thời gian",
      width: 6,
      render: (_, record: Record<string, any>) => {
        const formattedTime = (time: string) => moment(time).format("HH:mm:ss");
        const formattedDate = (date: string) =>
          moment(date).format("DD/MM/YYYY");
        const times = Object.values<any>(record).map((item) => (
          // <div key={item.id} style={{ marginBottom: "8px" }}>
          //   {formattedTime(item[9])} <br />
          //   {formattedDate(item[9])}
          // </div>
          <></>
        ));
        return (
          <div>
            {record?.createdDate
              // ? formatDateToIsoStringHHss(record?.createdDate.toString())
              ? !isEmpty(record?.createdDate) ? moment(record?.createdDate).format('HH:mm:ss DD/MM/YYYY') : null
              : null}
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      width: 6,
      render: (_: any, record: Record<string, any>) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.constructionPlanStatus === 1
                    ? "#D9D9D9"
                    : record.constructionPlanStatus === 2
                    ? "#52C41A"
                    : record.constructionPlanStatus === 3
                    ? "#FF0000"
                    : record.constructionPlanStatus === 4
                    ? "#FAAD14"
                    : "white",
              }}
            ></div>
            {record.constructionPlanStatus === 1
              ? "Chờ duyệt"
              : record.constructionPlanStatus === 2
              ? "Đã duyệt"
              : record.constructionPlanStatus === 3
              ? "Từ chối"
              : record.constructionPlanStatus === 4
              ? "Thu hồi"
              : "white"}
          </div>
        );
      },
    },
    {
      title: "Mô tả",
      width: 9,
      render: (_, record) => {
        return (
          <div>
            {record.approvalRemark === "" || record.approvalRemark == null ? (
              "Không có ý kiến nhận xét "
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: record.approvalRemark }}
              ></div>
            )}
          </div>
        );
      },
    },
  ];
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
    }
  };
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  return (
    <>
      <div className="table-wrapper">
        <Table
          size="middle"
          tableLayout="fixed"
          pagination={false}
          columns={dataColumns}
          dataSource={listApproval}
          scroll={{ y: 600 }}
          loading={loading}
        />
        <Pagination
          total={totalRecords}
          showSizeChanger
          pageSize={pageSize}
          current={pageNumber}
          className="pagination-table"
          onChange={onChange}
          itemRender={itemRender}
          locale={{
            items_per_page: "/ trang",
            jump_to: "Đi đến trang",
            page: "",
          }}
          showQuickJumper
          showTotal={(total) => `Tổng số ${total} bản ghi`}
        />
      </div>
    </>
  );
};

export default DetailConfirm;
