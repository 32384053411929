import React, {useState} from 'react';
import {Form, Upload, UploadFile} from "antd";
import {UploadProps} from "antd/es/upload/interface";
// import {
//     UploadProgressEvent
// } from 'rc-upload/lib/interface';
import axios from "axios";
import {getPreSignedUploadUrl} from "./file-uploader.service";

interface FileUploaderProps extends UploadProps {
    children?: React.ReactNode;
    prefix?: string;
    onUploadSuccess?: (fileKeys : string[], arg: any) => void;
}

interface FileUploadModel{
    fileKey: string,
    signedUrl: string
}

const FileUploader: React.FC<FileUploaderProps> = (props, context) => {
    let newProps = {...props}
    newProps.beforeUpload = (...args) => {
        const [file, fileList] = args;
        return props.beforeUpload?.apply(null, args) || true;
    }


    newProps.onChange = (...args) => {
        const { fileList} = args[0];
        const allFilesUploaded = fileList.every(file => file.response && file.status === 'done');
        if (allFilesUploaded) {
            props.onUploadSuccess && props.onUploadSuccess(fileList.map(file => file.response.fileKey), args[0]);
        }
        return props.onChange?.apply(null, args);
    }

    newProps.customRequest = (uploadRequestOption) => {
        let onSuccess = uploadRequestOption.onSuccess;
        let onError = uploadRequestOption.onError;
        let onProgress = uploadRequestOption.onProgress;
        let file = uploadRequestOption.file;

        const getFileDuration = (file: File): Promise<number | null> => {
            return new Promise((resolve, reject) => {
                if (!file.type.startsWith('audio') && !file.type.startsWith('video')) {
                    resolve(null); // Only retrieve duration for audio and video files
                    return;
                }

                const url = URL.createObjectURL(file);
                const media = new Audio(url); // Can be Audio or Video based on file.type

                media.onloadedmetadata = () => {
                    resolve(media.duration); // Duration in seconds
                    URL.revokeObjectURL(url); // Clean up
                };

                media.onerror = () => {
                    resolve(null)
                };
            });
        };

        const uploadFile = async (file: any, onProgress: ((event: any) => void) | undefined): Promise<UploadFile<any>> => {
            try {
                const fileDuration = await getFileDuration(file);
                const fileUploadModel = ((await getPreSignedUploadUrl(props.prefix, file.name, file.type, file.size, fileDuration)).data) as FileUploadModel;
                const response = await axios.put(fileUploadModel.signedUrl, file, {
                    headers: {
                        'Content-Type': file.type,
                    },
                    onUploadProgress: (event: any) => {
                        if (event.total) {
                            const percent = Math.round((event.loaded / event.total) * 100);
                            if (onProgress && percent<80) {
                                onProgress({percent: percent});
                            }
                        }
                    },
                });
                return {
                    ...file,
                    status: 'done',
                    fileKey: fileUploadModel.fileKey
                };
            } catch (error) {
                return {
                    ...file,
                    status: 'error',
                    response: error, // Custom error handling
                };
            }
        }

        uploadFile(file, onProgress)
            .then(result => {
                onSuccess && onSuccess(result);
            })
            .catch(error => {
                onError && onError(error as Error, null);
            });
    }

    return (
        <>
            <Form.Item name="attachFile">
                <Upload
                    {...newProps}
                >
                    {newProps.children}
                </Upload>
            </Form.Item>
        </>
    );
};

export default FileUploader;
