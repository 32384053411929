import { Input, Radio, Row, Space, ConfigProvider } from "antd";

const QuestionType3 = (props: any) => {
    return (
        <div>
            <div>
                <br />
                <ConfigProvider
                    theme={{
                        components: {
                            Input: {
                                colorTextPlaceholder: "black",
                                paddingInline: 0,
                                paddingBlock: 0,
                                colorTextDisabled: "black",
                            },
                            Button: {
                                paddingInline: 2,
                                paddingBlock: 0,
                            },
                        },
                    }}
                >
                    <Space direction="vertical" size="small" style={{ display: "flex" }}>
                        <Row
                            style={{
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Input
                                prefix={<Radio checked={false}></Radio>}
                                variant="borderless"
                                value={`Đúng`}
                                disabled
                            />
                        </Row>
                        <Row
                            style={{
                                margin: "0 auto",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Input
                                prefix={<Radio checked={false}></Radio>}
                                variant="borderless"
                                value={`Sai`}
                                disabled
                            />
                        </Row>
                    </Space>
                </ConfigProvider>
                <br />
            </div>
        </div>
    );
};

export default QuestionType3;
