import { Card, Flex, Layout, Typography, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import HeadSynthesisReportSystem from "./HeadSynthesisReportSystem";
import TableListTagSystem from "./TableListTagSystem";
import { useEffect, useState } from "react";
import { routesConfig } from "../../../config/routes";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import StatisticalCourseTag from "./StatisticalCourseTag";
import { UpOutlined } from "@ant-design/icons";
import TableSynthesisReportSystem from "./TableSynthesisReportSystem";
import { getSystemSuggestCourseAll } from "../../../service/system-suggest-course";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
const { Content } = Layout;
const imgPath = "/images/";

const SynthesisReportSystem = () => {
  const [headValue, setHeadValue] = useState<any>([]);
  const [isShow, setIsShow] = useState<any>([]);
  const [dateInfo, setDateInfo] = useState<any>([]);
  const [dateBehavior, setDateBehavior] = useState<any>([]);

  const [infoTag, setInfoTag] = useState({ tagsCourse: 0, noTags: 0 });
  const [behaviourTag, setBehaviourTag] = useState({ success: 0, fail: 0 });

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getSystemSuggestCourseAll(searchParam);
      const { data } = response.data;

      setInfoTag({
        tagsCourse: data?.info?.tags_course,
        noTags: data?.info?.no_tags,
      });
      setBehaviourTag({
        success: data?.behaviour?.success,
        fail: data?.behaviour?.fail,
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      info_start_date: !isEmpty(dateInfo) ? new Date(dateInfo[0]) : "",
      info_end_date:
        !isEmpty(dateInfo) && dateInfo[1] !== ""
          ? dayjs(new Date(dateInfo[1])).add(24, "hour").utc().format()
          : "",
      behaviour_start_date: !isEmpty(dateBehavior)
        ? new Date(dateBehavior[0])
        : "",
      behaviour_end_date: !isEmpty(dateBehavior) && dateBehavior[1] !== ""
        ? dayjs(new Date(dateBehavior[1])).add(24, "hour").utc().format()
        : "",
      tab: 0,
      name: "",
    });
  }, [dateInfo, dateBehavior]);
  return (
    <Content>
      <div className="p-6 bg-white">
        <PageHeaderCommon title="Báo cáo tổng hợp" />
      </div>
      <HeadSynthesisReportSystem headValue={headValue} />
      <StatisticalCourseTag
        infoTag={infoTag}
        behaviourTag={behaviourTag}
        setDateInfo={setDateInfo}
        setDateBehavior={setDateBehavior}
      />
      <div className="px-3 pt-6">
        <Card className="mx-3 table-list-score">
          <Flex
            justify="space-between"
            align="center"
            className="p-6 cursor-pointer"
            onClick={() => setIsShow(!isShow)}
          >
            <Typography.Title level={5} className="!mb-0">
              Danh sách thẻ tag theo sở thích
            </Typography.Title>
            <UpOutlined
              className={`${
                isShow ? "rotate-180" : ""
              } ease-in-out duration-500`}
            />
          </Flex>
          <div
            className={`${
              isShow ? "h-fit" : "h-0"
            } overflow-hidden transition duration-500 ease-in-out`}
          >
            <TableListTagSystem setHeadValue={setHeadValue} />
          </div>
        </Card>
      </div>
      <div className="px-6 pt-6">
        <TableSynthesisReportSystem />
      </div>
    </Content>
  );
};

export default SynthesisReportSystem;
