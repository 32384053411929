import React, { forwardRef, useImperativeHandle, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts, { Options } from "highcharts";

interface ComboDualAxesChartProps {
  data: {
    criteria: string;
    columnValue: number;
    lineValue: number;
  }[];
  columnName?: string;
  columnColor?: string;
  lineName?: string;
  lineColor?: string;
  hasTotal?: boolean;
  title?: string;
}

const ComboDualAxesChart = forwardRef((props: ComboDualAxesChartProps, ref) => {
  const {
    data,
    columnName,
    columnColor,
    lineName,
    lineColor,
    hasTotal = true,
    title,
  } = props;

  const categories = data.map((item) => item.criteria);
  const columnData = data.map((item) => item.columnValue);
  const lineData = data.map((item) => item.lineValue);
  const totalColumn = data.reduce((sum, item) => sum + item.columnValue, 0);
  const totalLine = data.reduce((sum, item) => sum + item.lineValue, 0);
  const generateTotalHTML = () => {
    let totalHTML = `
      <div style="text-align: start;">
        <div class="static-chart-total">
          Tổng ${columnName?.toLowerCase()}: 
          <span class="static-chart-count">${totalColumn}</span>
        </div>
        <div class="static-chart-total">
          Tổng ${lineName?.toLowerCase()}: 
          <span class="static-chart-count">${totalLine}</span>
        </div>
      </div>`;
    return totalHTML;
  };

  const options: Options = {
    chart: {
      type: "column",
      zooming: {
        type: "xy",
      },
      scrollablePlotArea: {
        minWidth: categories?.length * 98,
        scrollPositionX: 1,
      },
    },
    title: {
      text: hasTotal ? generateTotalHTML() : title || "",
      align: "left",
      verticalAlign: "top",
      useHTML: true,
    },
    xAxis: {
      categories: categories,
      crosshair: true,
    },
    yAxis: [
      {
        title: {
          text: null,
        },
        labels: {
          format: "{value}",
        },
      },
      {
        title: {
          text: null,
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      layout: "horizontal",
      align: "center",
      verticalAlign: "bottom",
      itemStyle: { fontSize: "12px" },
    },
    series: [
      {
        type: "column",
        name: columnName,
        data: columnData,
        color: columnColor,
        tooltip: {
          valueSuffix: "",
        },
      },
      {
        type: "line",
        name: lineName,
        data: lineData,
        yAxis: 1,
        color: lineColor,
        tooltip: {
          valueSuffix: "",
        },
        marker: {
          enabled: false,
        },
      },
    ],
    plotOptions: {
      column: {
        pointWidth: 32,
      },
    },
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
      },
    },
  };

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useImperativeHandle(ref, () => ({
    getChart: () => chartRef.current?.chart,
  }));

  return (
    <div className="mt-2">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
});

export default ComboDualAxesChart;
