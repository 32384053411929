import React from "react";
import { Carousel } from "antd";
import "./carousel.scss";
const imgPath = '/images/';

const CustomCarousel: React.FC = () => (
  <Carousel autoplay>
    <div className="item-carousel">
      {/* <div className="item-carousel__image" /> */}
      <img
        className="item-carousel__image"
        loading="eager"
        alt=""
        src={imgPath + "login-banner.png"}
      />
      <h1 className="item-carousel__content">
        <p className="item-carousel__content__text">
          QUẢN LÝ GIÁO DỤC TRỰC TUYẾN HIỆU QUẢ
        </p>
      </h1>
    </div>
    <div className="item-carousel">
      <img
        className="item-carousel__image"
        loading="eager"
        alt=""
        src={imgPath + "login-banner.png"}
      />
      <h1 className="item-carousel__content">
        <p className="item-carousel__content__text">
          QUẢN LÝ GIÁO DỤC TRỰC TUYẾN HIỆU QUẢ
        </p>
      </h1>
    </div>
    <div className="item-carousel">
      <img
        className="item-carousel__image"
        loading="eager"
        alt=""
        src={imgPath + "login-banner.png"}
      />
      <h1 className="item-carousel__content">
        <p className="item-carousel__content__text">
          QUẢN LÝ GIÁO DỤC TRỰC TUYẾN HIỆU QUẢ
        </p>
      </h1>
    </div>
    <div className="item-carousel">
      <img
        className="item-carousel__image"
        loading="eager"
        alt=""
        src={imgPath + "login-banner.png"}
      />
      <h1 className="item-carousel__content">
        <p className="item-carousel__content__text">
          QUẢN LÝ GIÁO DỤC TRỰC TUYẾN HIỆU QUẢ
        </p>
      </h1>
    </div>
  </Carousel>
);

export default CustomCarousel;
