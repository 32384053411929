import { Button, Dropdown, Form, Layout, MenuProps, message, Modal, Space, TableColumnsType, TableProps, } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState, } from "react";
import PageHeader from "../../components/page-header/PageHeader";

import { useNavigate } from "react-router-dom";
import "../../styles/_variables.scss";
import {
  BookOutlined,
  CloudSyncOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SendOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import "./index.scss";
import { isEmpty } from "lodash";
import { formatDateV2 } from "../../utils/converDate";
import { routesConfig } from "../../config/routes";
import FilterTableCourseConstruction from "../../components/portal-adminstration/filter-table/FilterTable";
import AddPortalAdminstrationDrawer from "../../components/drawer/add-portal-adminstration";
import { deletePortalAdministration, getListPortalAdministration } from "../../service/portal-administration";
import CommonModal from "../../components/modal/common";
import TableData from "../../components/table-data/TableData";
import { CheckRole, getPermissions } from "../../utils/checkRole";
import { Permission } from "../../layout/sidebar/permission.enum";



type ContentConstructionProps = {
  title?: string;
};

const PortalAdministrationPage = (props: ContentConstructionProps) => {
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [listPortalAdministration, setListPortalAdministration] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [idDetail, setIdDetail] = useState(0);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [listPermission, setListPermission] = useState([]);
  const [displayAddPortalPage, setDisplayAddPortalPage] = useState(false);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params))
    setListPermission(listPermissionConvert)
  }


  useEffect(() => {
    getRoles()
  }, [])

  useEffect(() => {
    const roleAddPortalPage = [
      Permission.PortalManagementAddNewPortalUpdate,
    ]
    const checkAddPortalPage = CheckRole(roleAddPortalPage, listPermission)
    if (checkAddPortalPage.includes(true)) {
      setDisplayAddPortalPage(true)
    }
  }, [listPermission])

  const getListData = async () => {
    const data = {
      keyword: searchValue,
      page: pageNumber,
      size: pageSize
    }
    await getListPortalAdministration(data).then((res) => {
      setListPortalAdministration(res.data.data.list)
      setTotalRecords(res.data.data.total)
    })
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
  };

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const gotoDetail = (record: any) => {
    navigate(`${routesConfig.detailPortalAdministration}/${record?.enterpriseId}`, {
      state: { mode: "detail", name: record?.name },
    });
  };

  const deletePortalAdmin = async () => {
    await deletePortalAdministration(deleteId).then((res) => {
      if (res.status === 200) {
        message.success("Xoá thành công cổng thông tin")
        setIsOpenedConfirm(false)
        getListData()
      }
    })
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "Số thứ tự",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder ?? 1}</span>
        );
      },
    },
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      width: 5,
      render: (_: any, record) => {
        return (
          <img src={record?.logo} alt="portal-administration-avatar" />
        );
      },
    },
    {
      title: "Trường đại học",
      dataIndex: "name",
      key: "name",
      width: 13,
      sorter: true,
      render: (_: any, record) => {
        return (
          <p className="underline cursor-pointer" onClick={() => gotoDetail(record)}>{record?.name}</p>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdPortalDate",
      key: "createdPortalDate",
      width: 5,
      sorter: true,
      render: (_: any, record) => {
        return (
          <>{record.createdPortalDate ? formatDateV2(record.createdPortalDate) : null}</>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setDeleteId(record.enterpriseId)
                  setIsOpenedConfirm(true)
                }
                }
              >
                <DeleteOutlined />
              </a>
            </Space >
          </>
        );
      },
      fixed: "right",
      width: 3,
    },
  ];

  const handleOpenCreate = () => {
    // add course here
    setIdDetail(0);
    setIsOpened(true);
  };

  const listBreadcrumb = [
    {
      title: 'Quản trị vai trò',
    },
  ]

  return (
    <Layout className="page-header-group portal-adminstration">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {

        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTableCourseConstruction
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              displayAddPortalPage={displayAddPortalPage}
              tab={tab}
            />
          </div>
          <div className={`wrapper-filter`}>
          </div>
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listPortalAdministration}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>

        <AddPortalAdminstrationDrawer
          open={isOpened}
          handleClose={() => setIsOpened(false)}
          getListData={getListData}
          id={"1"}
        />
      </Form.Provider>
      <CommonModal
        open={isOpenedConfirm}
        title={'Xoá cổng thông tin'}
        desc={`Bạn có chắc muốn xoá cổng thông tin này?`}
        closeIcon={true}
        okText={'Xác nhận'}
        cancelText={"Huỷ"}
        icon={<WarningOutlined style={{ color: "red" }} />}
        confirm={deletePortalAdmin}
        closeModal={() => setIsOpenedConfirm(false)}
        className="modal-delete-common"
      />
    </Layout>
  );
};

export default PortalAdministrationPage;
