import { Editor, Plugin } from "grapesjs";
import { createRoot } from "react-dom/client";
import { commonMedia } from "../../grapesjs-config";
import { nameLecturersBlock } from "../../grapesjs-ui";
import { nameHeaderBlocks } from "../../../portar-adminstration/course-list/grapesjs-ui";
import CourseListBlock from "../../../../components/list-program/list-course";
import ListBlogContent from "../../../../components/blog-and-forum/design-theme-blog-content/list-blog-content";

export type PluginOptions = {
  courseId?: number;
};

const ListBlogContentPlugin: Plugin<PluginOptions> | any = (editor: Editor, opts = {}) => {
  const currentUrl = window.location.href;

  // Create a URL object
  const url = new URL(currentUrl);

  // Get the pathname from the URL
  const pathname = url.pathname;

  // Split the pathname into segments
  const segments = pathname.split("/");

  // Find the index of the segment that contains 'content-course-detail'
  const detailIndex = segments.indexOf("content-course-detail");

  // The courseId should be the next segment after 'content-course-detail'
  const courseId = segments[detailIndex + 1];

  const defaultOptions = {
    courseId: courseId,
  };

  const options = { ...defaultOptions, ...opts };
  editor.Components.addType("ListBlogContent", {
    extend: "react-component",
    model: {
      defaults: {
        component: ListBlogContent,
        stylable: true,
        resizable: true,
        editable: true,
        draggable: true,
        droppable: true,
        attributes: {
          editable: true,
        },
        traits: [
          {
            type: "choose-lecturer-type",
            label: "Giảng viên",
            name: "userid",
          },
        ],
      },
    },
    isComponent: (el) =>
      el.tagName === "ListBlogContent".toUpperCase(),
  });


  editor.BlockManager.add("ListBlogContent", {
    media: commonMedia,
    label: 'Phần Nội dung',
    category: nameHeaderBlocks,
    content: '<ListBlogContent component-class="ListBlogContent" courseid="' + options.courseId + '"></ListBlogContent>',
  });
};
export default ListBlogContentPlugin;
