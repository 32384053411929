export const dataSurveyMethod = [
  {
    value: true,
    label: "Phỏng vấn trực tiếp",
  },
  {
    value: false,
    label: "Phiếu khảo sát trực tuyến",
  },
];

export const surveyMethodsFilter = [
  {
    code: 0,
    label: "Phiếu khảo sát trực tuyến",
  },
  {
    code: 1,
    label: "Phỏng vấn trực tiếp",
  },
];

export const dataSurveyObject = [
  {
    value: true,
    label: "Nhóm đối tượng cụ thể",
  },
  {
    value: false,
    label: "Bất kỳ ai có đường dẫn",
  },
];

export const dataSurveyTarget = [
  {
    code: 1,
    label: "Khảo sát khóa học",
  },
  {
    code: 2,
    label: "Khảo sát chương trình học",
  },
  {
    code: 3,
    label: "Khác",
  },
];

// export const dataSurveyObject = [
//   {
//     code: 1,
//     label: "Tất cả",
//   },
//   {
//     code: 2,
//     label: "Theo trường",
//   },
//   {
//     code: 3,
//     label: "Theo khóa học đăng ký",
//   },
//   {
//     code: 4,
//     label: "Theo khóa học hoàn thành",
//   },
//   {
//     code: 5,
//     label: "Theo chuyên ngành",
//   },
//   {
//     code: 6,
//     label: "Bất kỳ ai có đường dẫn",
//   }
// ]

export const validateFormCreate = [
  "planTitle",
  "planCode",
  "surveyMethod",
  "objectValues",
  "executionTime",
  "surveyObject",
  "surveyTarget",
  "course",
  "program",
  "description",
  "universityObject",
  "universityCourse",
  "universityCourse",
  "universityList",
  "registerCourseList",
  "completedCourseList",
  'universityCheckbox',
  'registerCourseCheckbox',
  'completedCourseCheckbox',
  'specializedCheckbox',
  "specializedList",
];
