import React from "react";
import ChooseText from "./choose-text/ChooseText";
import { Form } from "antd";
import ChooseImage from "./choose-image";
import ChooseVideo from "./choose-video";
import TrueOrFalse from "./true-or-false";
import DropDown from "./drop-down";
import ChooseMultiText from "./choose-multi-text";
import ChooseMultiImage from "./choose-multi-image";
import ChooseMultiVideo from "./choose-multi-video";
import Connect from "./connect";
import ShortText from "./short-text";
import FileUpload from "./file-upload";
import Video from "./video";
import Mp3 from "./mp3";
import LongText from "./long-text";
import { PartBlock, Quiz } from "../../../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../../../constants";
import "./style.scss";

interface ResultQuizLayoutProps {
  data?: any;
  groups?: PartBlock[];
  loading?: boolean;
  quizzes?: Quiz[];
  disabledQuiz?: boolean;
}

function ResultQuizLayout({ data }: ResultQuizLayoutProps) {
  const [form] = Form.useForm();
  const type = data?.type;

  const quiz = { ...data, questions: JSON.parse(data?.answers) };

  const renderQuestionByType = () => {
    switch (type) {
      case QuestionTypeEnum.CHOOSE_TEXT:
        return <ChooseText data={quiz} />;

      case QuestionTypeEnum.CHOOSE_IMAGE:
        return <ChooseImage data={quiz} />;

      case QuestionTypeEnum.CHOOSE_VIDEO:
        return <ChooseVideo data={quiz} />;

      case QuestionTypeEnum.TRUE_OR_FALSE:
        return <TrueOrFalse data={quiz} />;

      case QuestionTypeEnum.DROP_DOWN:
        return <DropDown data={quiz} />;

      case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
        return <ChooseMultiText data={quiz} />;

      case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
        return <ChooseMultiImage data={quiz} />;

      case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
        return <ChooseMultiVideo data={quiz} />;

      case QuestionTypeEnum.CONNECT:
        return <Connect data={quiz} form={form} />;

      case QuestionTypeEnum.SHORT_TEXT:
        return <ShortText data={quiz} />;

      case QuestionTypeEnum.LONG_TEXT:
        return <LongText data={quiz} />;

      case QuestionTypeEnum.FILE_UPLOAD:
        return <FileUpload data={quiz} form={form} />;

      case QuestionTypeEnum.VIDEO:
        return <Video data={quiz} />;

      case QuestionTypeEnum.MP3:
        return <Mp3 data={quiz} />;

      default:
        break;
    }
  };

  return (
    <div className="quiz-layout flex flex-column quiz-view-layout">
      <div className="question-content flex flex-column">
        {renderQuestionByType()}
      </div>
    </div>
  );
}

export default ResultQuizLayout;
