// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-settings-history {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .wrapper-settings-history .history-page {
    margin: 15px;
    background-color: #fff;
    border-radius: 4px; }
    .wrapper-settings-history .history-page .history-header {
      padding: 10px 0 0 20px; }
      .wrapper-settings-history .history-page .history-header .history-title {
        font-size: 22px; }
    .wrapper-settings-history .history-page .filter-detail {
      padding: 0 15px 0 0; }
    .wrapper-settings-history .history-page .ant-table-thead th.survey-form-id {
      width: 10%; }
    .wrapper-settings-history .history-page .ant-table-thead th.survey-form-date {
      width: 15%; }
    .wrapper-settings-history .history-page .ant-table-thead th.survey-form-name {
      width: 15%; }
    .wrapper-settings-history .history-page .ant-table-thead th.survey-form-event {
      overflow: hidden; }
    .wrapper-settings-history .history-page .ant-table-thead th.survey-form-actions {
      width: 15%; }
`, "",{"version":3,"sources":["webpack://./src/components/category-management/management-detail/university-info/university-history/universityHistory.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,aAAa;EACb,sBAAsB,EAAA;EAH1B;IAMM,YAAY;IACZ,sBAAsB;IACtB,kBAAkB,EAAA;IARxB;MAWQ,sBAAsB,EAAA;MAX9B;QAcU,eAAe,EAAA;IAdzB;MAmBQ,mBAAmB,EAAA;IAnB3B;MAwBU,UAAU,EAAA;IAxBpB;MA4BU,UAAU,EAAA;IA5BpB;MAgCU,UAAU,EAAA;IAhCpB;MAoCU,gBAAgB,EAAA;IApC1B;MAwCU,UAAU,EAAA","sourcesContent":[".wrapper-settings-history {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  \n    .history-page {\n      margin: 15px;\n      background-color: #fff;\n      border-radius: 4px;\n  \n      .history-header {\n        padding: 10px 0 0 20px;\n  \n        .history-title {\n          font-size: 22px;\n        }\n      }\n  \n      .filter-detail {\n        padding: 0 15px 0 0;\n      }\n  \n      .ant-table-thead {\n        th.survey-form-id {\n          width: 10%;\n        }\n  \n        th.survey-form-date {\n          width: 15%;\n        }\n  \n        th.survey-form-name {\n          width: 15%;\n        }\n  \n        th.survey-form-event {\n          overflow: hidden;\n        }\n  \n        th.survey-form-actions {\n          width: 15%;\n        }\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
