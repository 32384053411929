import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";
import { IDataCreateSpecializedGroup } from "../../../types/course-plan-managment/specialized-group/specialized-group";

export const findOrganizationsWithMembers: (data: any) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfig.post(`/organize-appraisal-board/search`, data)
}

export const changeActive: (id: string, dataEdit: any) => Promise<AxiosResponse<any>> = (id, dataEdit) => {
  return axiosConfig.patch(`/organize-appraisal-board/lock-unlock/${id}`, dataEdit)
}

export const getDetailOrganizeAppraisalBoard: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/organize-appraisal-board/${id}`)
}

export const createOrganizeAppraisalBoard: (
  organization: IDataCreateSpecializedGroup
) => Promise<AxiosResponse<any>> = (specializedGroup) => {
  return axiosConfig.post(`/organize-appraisal-board/create`, specializedGroup);
};

export const getOrganizeAppraisalBoardDetail: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/organize-appraisal-board/${Number(id)}`);
};

export const OrganizeAppraisalBoardDelete: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.patch(`/organize-appraisal-board/delete/${id}`);
};