import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface ChartData {
  type: number | null;
  criteria: string;
  values: Array<{
    count: number;
    percentage: number | null;
    criteria: string;
  }>;
}

interface AreaStackedChartProps {
  data: ChartData[];
  title?: string;
  colors?: string[];
  yAxisTitle?: string;
  hasTotal?: boolean;
}

const AreaStackedChart = forwardRef(
  (
    { data, title, colors, yAxisTitle, hasTotal = true }: AreaStackedChartProps,
    ref
  ) => {
    const categories = data.map((item) => item.criteria);
    const seriesData: { [key: string]: number[] } = {};

    const chartRef = useRef<HighchartsReact.RefObject>(null);

    useImperativeHandle(ref, () => ({
      getChart: () => chartRef.current?.chart,
    }));

    const calculateTotal = () => {
      let total = 0;
      data.forEach((item) => {
        item.values?.forEach((value) => {
          total += value.count;
        });
      });
      return total;
    };

    data.forEach((item) => {
      item.values?.forEach((value) => {
        if (!seriesData[value.criteria]) {
          seriesData[value.criteria] = [];
        }
        seriesData[value.criteria].push(value.count);
      });
    });

    const series = Object.keys(seriesData).map((criteria, index) => ({
      name: criteria,
      data: seriesData[criteria],
      marker: {
        symbol:
          index === 0
            ? "circle"
            : index === 1
            ? "square"
            : index === 2
            ? "diamond"
            : index === 3
            ? "triangle"
            : "triangle-down",
      },
    }));

    const options: Highcharts.Options = {
      chart: {
        type: "area",
        scrollablePlotArea: {
          minWidth: categories?.length * 50,
          scrollPositionX: 1,
        },
      },
      title: {
        text: hasTotal
          ? `<div class="static-chart-total">Tổng số: <span class="static-chart-count">${calculateTotal()}</span></div>`
          : title || "",
        useHTML: true,
      },
      xAxis: {
        categories,
        tickmarkPlacement: "on",
        title: {
          text: null,
        },
      },
      yAxis: {
        title: {
          text: yAxisTitle,
        },
      },
      tooltip: {
        shared: true,
      },
      plotOptions: {
        area: {
          stacking: "normal",
          lineColor: "#666666",
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: "#666666",
          },
        },
      },
      series: series as Highcharts.SeriesOptionsType[],
      colors: colors || undefined,
      legend: {
        align: "center",
        verticalAlign: "bottom",
        layout: "horizontal",
        symbolHeight: 12,
        symbolWidth: 12,
        symbolRadius: 6,
        itemStyle: { fontSize: "12px" },
      },
      exporting: {
        enabled: true,
        allowHTML: true,
        chartOptions: {
          credits: {
            enabled: false,
          },
        },
      },
    };

    return (
      <div className="mt-2">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
    );
  }
);

export default AreaStackedChart;
