import React from "react";
import { Checkbox } from "antd";

function ChooseMultiVideo({ data }: any) {
  return (
    <div>
      <div className="flex layout-two-col">
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: any) => (
            <div
              className={`image-quiz-wrap ${
                quiz?.isTrue && "active-quiz-correct choose-correct"
              }`}
              key={quiz?.uuid + index}
            >
              <Checkbox disabled={true} checked={quiz?.isTrue}>
                <span
                  className={`font-size-16 line-height-24 font-weight-5 ${
                    quiz?.isTrue && "text-result"
                  }`}
                >
                  {quiz?.content}
                </span>
              </Checkbox>

              <video className="quest-video" controls>
                <source src={quiz?.filePath || ""} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ChooseMultiVideo;
