import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from 'react';
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, TableColumnType, Typography } from 'antd';

import FormInput from "../../../../components/form-input/FormInput";
import './filter-table.scss'
import { DownloadOutlined, DownOutlined, PlusOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { DataType } from "../../../../types/listUsers";
import { CheckboxOptionType } from "antd/lib";

type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  checkedList: Array<string>,
  setCheckedList: (value: string[]) => void,
  dataColumns: TableColumnType<DataType>[]
  setExportMode: (value: boolean) => void
}


const FilterTable: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
  setExportMode
}) => {

  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key
  }))

  return (
    <>
      <div className="heading-extra">
        <Row gutter={8}>
          <Col md={{ span: 4.5 }} lg={{ span: 4.5 }} xl={{ span: 4.5 }} xs={{ span: 4.5 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>
                  Bộ lọc
                </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }} xs={{ span: 9 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập tên, email để tìm kiếm" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          <Col md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 7 }}>

            <div className="heading-resigter">
              <Button onClick={handleOpen} className="btn btn-primary">
                <PlusOutlined />
                <Typography.Text>Tạo tài khoản excel</Typography.Text>
              </Button>
            </div>
          </Col>
          <Col md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} xs={{ span: 3 }}>

            <div className="heading-resigter">
              <Button className="btn" onClick={() => setExportMode(true)}>
                <DownloadOutlined/>
              </Button>
            </div>
          </Col>
          {/* <Col md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} xs={{ span: 3 }}>
            <div className="heading-setting">
              <Dropdown className="btn btn-outlined btn-setting" menu={menuProps} trigger={["click"]}>
                <SettingOutlined />
              </Dropdown>
            </div>
          </Col> */}

        </Row>
      </div>
    </>
  );
};

export default FilterTable;
