export enum LESSON_TYPE {
  DONE = 1,
  VIDEO = 2,
  READ = 3,
}
export const DEFAULT_GENERAL_SETTING_BOOLEAN = true;
export const DEFAULT_TEXAREA_ROW = 1;
export const DEFAULT_MAX_LENGTH_COURSE_TEXTAREA = 512;
export const DEFAULT_MAX_LENGTH_QUESTION_BANK = 255;
export const DEFAULT_SCORE_QUESTION = 0;
export const TEACHER_DISCUSSION = 1;
export const LIKE_REACTION = 1;
export const DISLIKE_REACTION = 2;

export const initialQuestionSettingValue = {
  isVisibleToViewers: true,
  isRequiredAnswer: true,
  canEnterAnswer: true,
  isRandomOrder: true,
  markNumber: 1,
  displayScoreCriteria: true,
  markNumber2: 1,
  isSetScore4EachAns: false,
  totalScore: 0,
  scoreOfCorrect: 0,
  scoreOfInCorrect: 0,
  isShowGuide: false,
  isFeedback4EachAns: false,
  correctAns: "",
  incorrectAns: "",
  notAnswer: "",
  scores: [],
  feeds: [],
  hintContent: "",
  scoreCriteria: [],
  fileQuantity: 0,
  fileCapacity: 0,
  timeToPlay: 0,
  uri: 'https://',
  schema: '',
};

export enum QuestionTypeEnum {
  CHOOSE_TEXT = 1,
  CHOOSE_IMAGE = 2,
  CHOOSE_VIDEO = 3,
  TRUE_OR_FALSE = 4,
  DROP_DOWN = 5,
  CHOOSE_MULTI_TEXT = 6,
  CHOOSE_MULTI_IMAGE = 7,
  CHOOSE_MULTI_VIDEO = 8,
  LONG_TEXT = 10,
  CONNECT = 11,
  SHORT_TEXT = 9,
  FILE_UPLOAD = 12,
  VIDEO = 13,
  MP3 = 14,
  FillText = 15
}

export enum QuestionProgressModeClick {
  START = "start",
  INPROGRESS = "inprogress",
  END = "end",
}

export enum ModeQuestion {
  CREATE = "create",
  UPDATE = "update",
  VIEW = "view",
}

export enum TypeCreateQuestion {
  COURSEWARE = "courseware",
  SURVEY = "survey",
}

export const initialChooseTextQuestionValue = [
  {
    id: 1,
    value: "",
    key: 1,
    isTrue: false,
    point: 0,
    response: "",
  },
];

export const listItems = [
  {
    id: 1,
    key: 1,
    value: "",
    isTrue: false,
    isDisplayContent: false,
    filePath: '',
    fileName: '',
    point: 0,
  },
]


export const initialChooseImageQuestionValue = [
  {
    id: 1,
    key: 1,
    value: "",
    isTrue: false,
    isDisplayContent: false,
    filePath: '',
    fileName: '',
    point: 0,
  },
];

export const initialChooseVideoQuestionValue = [
  {
    id: 1,
    isTrue: false,
    filePath: "",
    isDisplayContent: false,
    value: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseMultiVideoQuestionValue = [
  {
    id: 1,
    isTrue: false,
    filePath: "",
    isDisplayContent: false,
    value: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseShortTextQuestionValue = [
  {
    id: 1,
    value: "",
    key: 1,
    isTrue: false,
    point: 0,
  },
];

export const initialChooseConnectTextQuestionValue = [
  {
    id: 1,
    content: {
      left: {
        content: "",
      },
      right: {
        content: "",
      },
    },
    orderNumber: 1,
    point: 0,
    response: "",
    isDisplayContent: false,
    filePath: "",
    fileName: "",
    isTrue: true,
  },
  {
    id: 2,
    content: {
      left: {
        content: "",
      },
      right: {
        content: "",
      },
    },
    orderNumber: 2,
    point: 0,
    response: "",
    isDisplayContent: false,
    filePath: "",
    fileName: "",
    isTrue: true,
  },
];

export const initialChooseVideoCriteriaValue = [
  {
    id: 1,
    key: 1,
    value: "",
    isTrue: false,
    isDisplayContent: false,
    filePath: '',
    fileName: '',
    point: 0,
  },
];

export const initialChooseMp3CriteriaValue = [
  {
    id: 1,
    value: "",
    key: 1,
    filePath: "",
    fileName: "",
    point: 0,
  },
];

export const initialChooseFileUploadQuestionValue = [
  {
    id: 1,
    key: 1,
    value: "",
    isTrue: true,
    isDisplayContent: false,
    filePath: '',
    fileName: '',
    point: 0,
  },
];

export const initialChooseFileUploadCriteriaValue = [
  {
    id: 1,
    value: "",
    key: 1,
    isTrue: true,
    point: 0,
  },
];

export const initialTrueFalseQuestionValue = [
  {
    id: 1,
    value: "Đúng",
    key: 1,
    isTrue: true,
  },
  {
    id: 2,
    value: "Sai",
    key: 2,
    isTrue: false,
  },
];
