// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-portfolio .portfolio-name {
  font-size: 16px;
  font-weight: 500;
  color: #1677ff; }

.page-header-portfolio .ant-tabs-left {
  width: 100%; }
  .page-header-portfolio .ant-tabs-left .table-wrapper {
    padding: 0; }
    .page-header-portfolio .ant-tabs-left .table-wrapper .pagination-table {
      padding-right: 15px; }
  .page-header-portfolio .ant-tabs-left .ant-tabs-nav {
    overflow: auto;
    width: 210px;
    padding-right: 5px; }
    .page-header-portfolio .ant-tabs-left .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
      padding: 8px 10px; }
    .page-header-portfolio .ant-tabs-left .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
      background-color: transparent; }

.page-header-portfolio .ant-tabs-content-left .ant-tabs-tabpane {
  padding-left: 0 !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/category-management/portfolio-value-management/portfolio-value-management.scss"],"names":[],"mappings":"AAAA;EAEQ,eAAe;EACf,gBAAgB;EAChB,cAAc,EAAA;;AAItB;EAEQ,WAAW,EAAA;EAFnB;IAKY,UAAU,EAAA;IALtB;MAQgB,mBAAmB,EAAA;EARnC;IAaY,cAAc;IAEd,YAAY;IACZ,kBAAkB,EAAA;IAhB9B;MAoBoB,iBAAiB,EAAA;IApBrC;MAwBoB,6BAA6B,EAAA;;AAxBjD;EAiCY,0BAA0B,EAAA","sourcesContent":[".table-portfolio {\n    .portfolio-name {\n        font-size: 16px;\n        font-weight: 500;\n        color: #1677ff;\n    }\n}\n\n.page-header-portfolio {\n    .ant-tabs-left {\n        width: 100%;\n\n        .table-wrapper {\n            padding: 0;\n\n            .pagination-table {\n                padding-right: 15px;\n            }\n        }\n\n        .ant-tabs-nav {\n            overflow: auto;\n            // max-height: 777px;\n            width: 210px;\n            padding-right: 5px;\n\n            .ant-tabs-nav-list {\n                .ant-tabs-tab {\n                    padding: 8px 10px;\n                    // margin-top: 5px;\n                }\n                .ant-tabs-ink-bar {\n                    background-color: transparent;\n                }\n            }\n            \n        }\n    }\n\n    .ant-tabs-content-left {\n        .ant-tabs-tabpane {\n            padding-left: 0 !important;\n        }\n    }\n    \n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
