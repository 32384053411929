export const typeOptionManagementData = [
  {
    id: 1,
    name: 'Đồng bộ'
  },
  {
    id: 2,
    name: 'Chia sẻ'
  },
  {
    id: 3,
    name: 'Xác thực'
  }
]

export const optionSyncData = [
  {
    id: 1,
    name: 'Danh mục A'
  },
  {
    id: 2,
    name: 'Danh mục B'
  },
  {
    id: 3,
    name: 'Danh sách danh mục trong File'
  }
]

export const optionShareData = [
  {
    id: 1,
    name: 'Hồ sơ học tập'
  },
  {
    id: 2,
    name: 'Tổng hợp hồ sơ học tập'
  },
  {
    id: 3,
    name: 'Kết quả học tập'
  },
  {
    id: 4,
    name: 'Tổng hợp kết quả học tập'
  }
]

export const optionAccuracyData = [
  {
    id: 1,
    name: 'Giảng viên'
  },
  {
    id: 2,
    name: 'Sinh viên'
  },
]