import { ContactsOutlined } from "@ant-design/icons";
import { Input, Layout, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import NewCourse from "./TabListTop/NewCourse";
import PopularCourse from "./TabListTop/PopularCourse";
import { SearchProps } from "antd/es/input";
import './tab-list-top.scss'
const imgPath = "/images/";

const { Content } = Layout;
const { Search } = Input;

const SearchFilter = ({ onSearch }: any) => {
  const [searchText, setSearchText] = useState<string>("");
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };
  return (
    <div>
      <Search
        placeholder="Nhập tên khoá học cần tìm kiếm "
        onSearch={onSearch}
        value={searchText}
        onChange={handleChangeSearch}
      />
    </div>
  );
};

const TableSynthesisReportSystem = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");

  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <ContactsOutlined />,
      label: "Khoá học phổ biến",
      children: <PopularCourse searchValue={searchValue} />,
    },
    {
      key: "2",
      icon: <ContactsOutlined />,
      label: "Khoá học mới nhất",
      children: <NewCourse searchValue={searchValue} />,
    },
  ];
  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };
  return (
    <Content>
      <PageHeaderCommon
        title={"Thống kê bảng xếp hạng"}
        positionItem="column"
        childrenGroupButton={<SearchFilter onSearch={onSearch} />}
        headerGroupButton
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey={"1"} items={items} />
      </div>
    </Content>
  );
};

export default TableSynthesisReportSystem;
