import { StateCreator } from "zustand";
import { TypeChapterList, TypeCourseDescriptionDocument } from "../../../types/course-plan-managment/mooc-course-construction-plan";
import { postUpdateCourseDescriptionDocumentById, postUpdateCourseStructureById, syncCourseStructureById } from "../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { message } from "antd";

export interface CourseStrutureSlice {
  loading: boolean;
  error: boolean;
  success: boolean;
  saveDraftCourseStructure: (showMessage: (content:string)=> void ,data: TypeChapterList[], id: number, type?:string, activeTab?:number) => void;
  saveDraftDescriptionDocument: (showMessage: (content:string)=> void ,data: TypeCourseDescriptionDocument[], id: number, type?:string, activeTab?:number) => void;

}

export const createCourseStrutureSlice: StateCreator<CourseStrutureSlice> = (
  set,
  get
) => ({
  loading: false,
  error: false,
  success: false,
  saveDraftCourseStructure: async (showMessage, data, id, type, activeTab) => {
    try {
      const response = await postUpdateCourseStructureById(data, id);
      // console.log(response);
      if (response.status === 201) {
        if(!type) {showMessage(`Lưu thành công`);}
        if(type=="changeTab") {showMessage(`Chuyển Tab và đã lưu thành công trạng thái của Tab trước`)}
        if(type=="manualSave") {showMessage(`Lưu thành công`)}

      } else {
        showMessage(`Gặp vấn đề trong quá trình lưu, vui lòng thao tác lại`);
      }
    } catch (error) {
      set({ loading: false, error: true });
    }
  },
  saveDraftDescriptionDocument: async (showMessage, data, id, type, activeTab) => {
    try {
      const response = await postUpdateCourseDescriptionDocumentById(data, id);
      // console.log(response);
      if (response.status === 201) {
        if(!type) {showMessage(`Lưu thành công`);}
        if(type=="changeTab") {showMessage(`Chuyển Tab và đã lưu thành công trạng thái của Tab trước`)}
        if(type=="manualSave") {showMessage(`Lưu thành công`)}
      } else {
        showMessage(`Gặp vấn đề trong quá trình lưu, vui lòng thao tác lại`);
      }
    } catch (error) {
      set({ loading: false, error: true });
      
    }
  }
});
