import React from "react";
// import "./detail.scss";

import { SearchOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Space, Tooltip } from "antd";

interface RightControlProps {
    handleShowFilterQuestion?: () => void;
}

const RightControl: React.FC<RightControlProps> = ({ handleShowFilterQuestion }) => {
    return (
        <div>
            <ConfigProvider
                theme={{
                    components: {
                        Tooltip: {
                            colorTextLightSolid: "black",
                            colorBgSpotlight: "white",
                        },
                    },
                }}
            >
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <div
                        style={{
                            marginRight: "-85px",
                            position: "absolute",
                            top: "50px",
                        }}
                    >
                        <Space
                            size="small"
                            direction="vertical"
                            style={{
                                backgroundColor: "white",
                                borderRadius: "10px",
                                // padding: "10px",
                            }}
                        >
                            <Tooltip placement="top" title="Tìm kiếm câu hỏi trong khảo sát">
                                <Button onClick={handleShowFilterQuestion} icon={<SearchOutlined />} />
                            </Tooltip>
                        </Space>
                    </div>
                </div>
            </ConfigProvider>
        </div>
    );
};

export default RightControl;
