import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getTemplateCourseBrowse: (id: string) => Promise<AxiosResponse<string>> = (id) => {
  return axiosConfig.post(`/courses/templates/get-template-by-course-id/${id}`)
}

// phê duyệt khoá học - lịch sử phê duyệt
export const getAppriseResultInformationByCourse: (id: string) => Promise<AxiosResponse<string>> = (id) => {
  return axiosConfigV2.get(`/apprise-result/get-apprise-result-information-by-course?courseId=${id}`)
}

export const getListAppriseResultInformationByCourse: (params: string) => Promise<AxiosResponse<any>> = (params) => {
  return axiosConfigV2.get(`/apprise-result/get-apprise-result-by-course?${params}`)
}

export const getProfessionalApproveResultByCourse: (params: string) => Promise<AxiosResponse<any>> = (params) => {
  return axiosConfigV2.get(`/apprise-result/get-professional-approve-result-by-course?${params}`)
}