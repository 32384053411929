// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-table-university .ant-row .ant-col .heading-resigter .btn-add-new {
  width: 100%; }

.university-import-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  padding: 5px 0 20px 0;
  font-size: 16px;
  font-weight: 500; }

.university-import-modal .ant-modal-content .ant-modal-body .ant-space-compact {
  width: 85%;
  margin-left: 30px;
  margin-bottom: 21px; }
  .university-import-modal .ant-modal-content .ant-modal-body .ant-space-compact .name-label {
    cursor: auto; }
  .university-import-modal .ant-modal-content .ant-modal-body .ant-space-compact .ant-upload-wrapper .ant-upload-list-text {
    display: none; }

.university-import-modal .ant-modal-content .ant-modal-body .upload-progress .progress-label {
  float: right;
  margin-right: 40px;
  color: #00000080; }

.university-import-modal .ant-modal-content .ant-modal-body .upload-progress .ant-progress {
  width: 85%;
  margin-left: 30px; }

.university-import-modal .ant-modal-content .ant-modal-body .progress-off {
  display: none; }

.university-import-modal .ant-modal-content .ant-modal-footer {
  justify-content: center;
  margin: 24px 0 12px 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/category-management/university-management/components/filter-table/filter-table.scss"],"names":[],"mappings":"AAAA;EAKU,WAAW,EAAA;;AAOrB;EAIQ,qBAAqB;EACrB,eAAe;EACf,gBAAgB,EAAA;;AANxB;EAYQ,UAAU;EACV,iBAAiB;EACjB,mBAAmB,EAAA;EAd3B;IAiBU,YAAY,EAAA;EAjBtB;IAsBY,aAAa,EAAA;;AAtBzB;EA6BU,YAAY;EACZ,kBAAkB;EAClB,gBAAgB,EAAA;;AA/B1B;EAmCU,UAAU;EACV,iBAAiB,EAAA;;AApC3B;EAyCQ,aAAa,EAAA;;AAzCrB;EA8CM,uBAAuB;EACvB,qBAAqB,EAAA","sourcesContent":[".filter-table-university {\n  .ant-row {\n    .ant-col {\n      .heading-resigter {\n        .btn-add-new {\n          width: 100%;\n        }\n      }\n    }\n  }\n}\n\n.university-import-modal {\n  .ant-modal-content {\n    .ant-modal-header {\n      .ant-modal-title {\n        padding: 5px 0 20px 0;\n        font-size: 16px;\n        font-weight: 500;\n      }\n    }\n\n    .ant-modal-body {\n      .ant-space-compact {\n        width: 85%;\n        margin-left: 30px;\n        margin-bottom: 21px;\n\n        .name-label {\n          cursor: auto;\n        }\n\n        .ant-upload-wrapper {\n          .ant-upload-list-text {\n            display: none;\n          }\n        }\n      }\n\n      .upload-progress {\n        .progress-label {\n          float: right;\n          margin-right: 40px;\n          color: #00000080;\n        }\n\n        .ant-progress {\n          width: 85%;\n          margin-left: 30px;\n        }\n      }\n\n      .progress-off {\n        display: none;\n      }\n    }\n\n    .ant-modal-footer {\n      justify-content: center;\n      margin: 24px 0 12px 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
