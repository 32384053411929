import { Col, Form, Row, Spin, Tag } from "antd";
import { FC } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import "../lecturer-detail/lecturerDetail.scss";
interface LecturerDetailProps {
  lecturerDetail: any;
  specialized: string[];
  academicLevel: string[];
  regency: string[];
  loadingDetail: boolean;
}

const LecturerDetail: FC<LecturerDetailProps> = ({
  lecturerDetail,
  specialized,
  academicLevel,
  regency,
  loadingDetail,
}) => {
  return (
    <div className="wrapper-settings">
      <section className="setting-lecturer">
        <CustomCard className="lecturer-card" title="Thông tin giảng viên">
          {loadingDetail ? (
            <div className="statistic-course-content statistic-course-loading">
              <Spin size="large" />
            </div>
          ) : (
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Form.Item className="setting-item" name="name" label="Tên giảng viên">
                  <p className="lecturer-content">{lecturerDetail?.name}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="university" label="Đơn vị công tác">
                  <p className="lecturer-content">{lecturerDetail?.universityName}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="code" label="Mã giảng viên">
                  <p className="lecturer-content">{lecturerDetail?.code}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="gender" label="Giới tính">
                  <p className="lecturer-content">{lecturerDetail?.gender}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="department" label="Khoa">
                  <p className="lecturer-content">{lecturerDetail?.industryGroupInfo?.name}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="domicile" label="Nguyên quán">
                  <p className="lecturer-content">{lecturerDetail?.provinceDto.name}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="specialized" label="Chuyên ngành">
                  <div className="lecturer-content">
                    {specialized?.map((item: any) => (
                      <Tag style={{ marginBottom: 5 }} key={item}>
                        {item}
                      </Tag>
                    ))}
                  </div>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="academicLevel" label="Trình độ học vấn">
                  <div className="lecturer-content">
                    {academicLevel?.map((item: any) => (
                      <Tag style={{ marginBottom: 5 }} key={item}>
                        {item}
                      </Tag>
                    ))}
                  </div>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="regency" label="Chức vụ">
                  <div className="lecturer-content">
                    {regency?.map((item: any) => (
                      <Tag style={{ marginBottom: 5 }} key={item}>
                        {item}
                      </Tag>
                    ))}
                  </div>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="email" label="Email">
                  <p className="lecturer-content">{lecturerDetail?.email}</p>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item className="setting-item" name="phone" label="Điện thoại liên hệ">
                  <p className="lecturer-content">{lecturerDetail?.phoneNumber}</p>
                </Form.Item>
              </Col>
            </Row>
          )}
        </CustomCard>
      </section>
    </div>
  );
};

export default LecturerDetail;
