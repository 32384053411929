import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Layout, message, Switch } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CommonModal from "../../components/modal/common";
import PageHeaderCommon from "../../components/page-header-common/PageHeaderCommon";
import {
  updateStatusCourseStructure
} from "../../service/setting-course-structure";
import "./setting-structure.scss";
import SettingStructureContent from "./SettingStructure";
const imgPath = "/images/";

const { Content } = Layout;

const SettingStructureCourse = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [isRefresh, setIsRefresh] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const [isView, setIsView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [dataParam, setDataParam] = useState<any>({
    is_active: false,
  });
  const [isActive, setIsActive] = useState(false);

  const handleRefresh = () => {
    setIsRefresh(!isRefresh)
  }
  const handleNotice = (type: any, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  const onChangeActive = (checked: boolean) => {
    setIsActive(checked)
  };

  const handleConfirmStatus = async () => {
    const status = isActive ? 1 : 0;
    try {
      await updateStatusCourseStructure(params?.id || "", status);
      setIsOpen(false);
      handleNotice("success", "Thay đổi trạng thái thành công");
      handleRefresh()
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        title="Cài đặt cấu trúc bài giảng"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(-1)}
        headerGroupButton
        childrenGroupButton={
          <Flex>
            {dataParam?.is_active && (
              <Button onClick={() => setIsView(!isView)} className="mr-4">
                <EyeOutlined className="pr-2" />
                {!isView ? "Chế độ xem" : "Thoát chế độ xem"}
              </Button>
            )}
            <Button onClick={() => setIsOpen(true)}>Trạng thái</Button>
          </Flex>
        }
      />
      <SettingStructureContent
        handleNotice={handleNotice}
        dataParam={dataParam}
        setDataParam={setDataParam}
        setIsActive={setIsActive}
        isView={isView}
        isRefresh={isRefresh}
        handleRefresh={handleRefresh}
      />
      <CommonModal
        open={isOpen}
        title={`Thay đổi trạng thái`}
        desc={
          <div>
            <p>Chọn trạng thái hoạt động của cấu trúc bài giảng mặc định</p>
            <Flex>
              <span className="!font-semibold pr-8">Trạng thái</span>
              <Switch
                checkedChildren="Bật"
                unCheckedChildren="Tắt"
                onChange={onChangeActive}
                defaultChecked={dataParam?.is_active}
              />
            </Flex>
          </div>
        }
        closeIcon={false}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmStatus}
        closeModal={() => setIsOpen(false)}
        className={""}
      />
    </Content>
  );
};

export default SettingStructureCourse;
