// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-settings {
  width: 100%; }
  .wrapper-settings .setting-course {
    display: flex;
    width: 100%; }
  .wrapper-settings .form-settings {
    display: flex;
    flex-direction: column;
    align-items: center; }

.modal-setting--fee .ant-checkbox-group {
  flex-wrap: nowrap;
  flex-direction: column; }

.modal-setting--fee #settingFee > .form-item--fee {
  display: flex;
  justify-content: flex-end; }
  .modal-setting--fee #settingFee > .form-item--fee .ant-row {
    width: 383px; }

.modal-setting--fee .ant-input-number-input {
  text-align: right; }

.modal-setting--fee .form-item--payment {
  margin-bottom: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/setting-content-course/setting-fee/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;EADb;IAII,aAAa;IACb,WAAW,EAAA;EALf;IASI,aAAa;IACb,sBAAsB;IACtB,mBAAmB,EAAA;;AAKvB;EAEI,iBAAiB;EACjB,sBAAsB,EAAA;;AAH1B;EAQM,aAAa;EACb,yBAAyB,EAAA;EAT/B;IAYQ,YAAY,EAAA;;AAZpB;EAkBI,iBAAiB,EAAA;;AAlBrB;EAsBI,gBAAgB,EAAA","sourcesContent":[".wrapper-settings {\n  width: 100%;\n\n  .setting-course {\n    display: flex;\n    width: 100%;\n  }\n\n  .form-settings {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n}\n\n.modal-setting--fee {\n  .ant-checkbox-group {\n    flex-wrap: nowrap;\n    flex-direction: column;\n  }\n\n  #settingFee {\n    > .form-item--fee {\n      display: flex;\n      justify-content: flex-end;\n\n      .ant-row {\n        width: 383px;\n      }\n    }\n  }\n\n  .ant-input-number-input {\n    text-align: right;\n  }\n\n  .form-item--payment {\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
