import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  Radio,
  Space,
  Tree,
  message,
} from "antd";
import type { RadioChangeEvent } from "antd";

import "./detail.scss";
import ModalDownloadInformationPlan from "./components/ModalDownloadInformationPlan";
import ModalDownloadCourseConstructionPlan from "./components/ModalDownloadCourseConstructionPlan";
import ModalDowloadAllPlan from "./components/ModalDowloadAllPlan";
import ModalProgressBar from "./components/ModalProgressBar";
import {
  downloadFile,
  getListMoocCourseDescriptionDocumentById,
  getListMoocCourseStructureById,
  getMoocCourseConstructionPlanById,
} from "../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import {
  DetailTypeMoocCourseConstructionPlan,
  TypeChapterList,
  TypeCourseDescriptionDocument,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../types/course-plan-managment/mooc-course-construction-plan";
import InformationPlan from "./components/InformationPlan";
import ConstructionPlan from "./components/ConstructionPlan";
import { ArrowDownOutlined } from "@ant-design/icons";
import ConstructionPlan2 from "./components/ConstructionPlan2";

const CourseConstructionPlan = (props: any) => {
  const { isOpen, setIsOpen, courseConstructPlanId } = props;
  // console.log({ courseConstructPlanId })
  // const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isShowModalDetailDownload, setIsShowModalDetailDownload] =
    useState<boolean>(false);
  const [isOpenModalProgressBar, setIsOpenModalProgressBar] =
    useState<boolean>(false);
  const [listCourseConstruct, setListCourseConstruct] = useState<
    TypeChapterList[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typeDownload, setTypeDownload] = useState<number[]>([]);
  const [detailCourseConstruct, setDetailCourseConstruct] =
    useState<DetailTypeMoocCourseConstructionPlan>();
  // const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [defaultActive, setDefaultActive] = useState<number[]>(
    Array.from({ length: listCourseConstruct.length }, (_, index) => index + 1)
  );

  const [expandAll, setExpandAll] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [dataDescriptionDocument, setDataDescriptionDocument] = useState<
    TypeCourseDescriptionDocument[]
  >([]);
  const refFilePdf = useRef<HTMLInputElement>(null);
  const refConstructPlanPdf = useRef<HTMLInputElement>(null);

  // const handleOpenModalDownloadFile = () => {
  //   setIsOpen(true)
  //   console.log({ isOpen })
  // }

  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure.filter(
      (item) => item.structureItemLevel === 1
    );
    const level2 = dataCourseStructure.filter(
      (item) => item.structureItemLevel === 2
    );
    const level3 = dataCourseStructure.filter(
      (item) => item.structureItemLevel === 3
    );

    const chapterList: TypeChapterList[] = level1.map((l1) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        // structureItemOrder: l1.structureItemOrder,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2.filter((l2) => l2.parentId === l1.id);

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          // structureItemOrder: subject.structureItemOrder,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3.filter((l3) => l3.parentId === subject.id);

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          // structureItemOrder: lesson.structureItemOrder,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });
    // console.log(`chapterList::`, chapterList);
    return chapterList;
  };
  const handleCancel = () => {
    setIsOpen(false);
    setTypeDownload([]);
  };
  const handleChangeSelectTypeDownload = (list: number[]) => {
    setTypeDownload(list);
  };
  const handleOk = () => {
    // console.log({ typeDownload })
    setIsOpenModalProgressBar(true);
    setIsLoading(true);
    setExpandAll(true);
    try {
      setIsShowModalDetailDownload(false);
    } catch (error) { }
  };

  const handleShowView = () => {
    // console.log({ typeDownload })
    setIsShowModalDetailDownload(true);
  };

  const getListMoocCourseStructureByIdData = async () => {
    try {
      await getListMoocCourseStructureById(courseConstructPlanId)
        .then((res) => {
          const data = res.data.data;
          // console.log(`dataList[]::`, data);
          setListCourseConstruct(changeToChapterList(data));
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    getListMoocCourseStructureByIdData();
  }, []);

  // console.log({ listCourseConstruct })

  // console.log({ courseConstructPlanId })
  const fetDetailCourseConstruct = async () => {
    const res = await getMoocCourseConstructionPlanById(courseConstructPlanId);

    console.log({ res });
    setDetailCourseConstruct(res.data.data);
    console.log({ res });
  };

  useEffect(() => {
    fetDetailCourseConstruct();
  }, []);

  const generateTreeData = (nodes: any) => {
    return nodes.map((node: any, index: number) => {
      const keyPrefix = node.parentId ? `${node.parentId}-` : "";
      const treeDataNode: any = {
        title: node.structureItemName,
        key: `${keyPrefix}${node.id}`,
        children: [],
      };

      if (node.subjectList && node.subjectList.length > 0) {
        treeDataNode.children = generateTreeData(node.subjectList);
      }

      if (node.lessonList && node.lessonList.length > 0) {
        treeDataNode.children = [
          ...treeDataNode.children,
          ...generateTreeData(node.lessonList),
        ];
      }

      return treeDataNode;
    });
  };

  const treeData = generateTreeData(listCourseConstruct);
  const getAllKeys = (data: any, keys: any = []) => {
    data.forEach((item: any) => {
      keys.push(item?.key);
      if (item.children) {
        getAllKeys(item.children, keys);
      }
    });
    return keys;
  };

  useEffect(() => {
    const allKeys = getAllKeys(treeData);
    setExpandedKeys(allKeys);
  }, []);
  const allKeys = getAllKeys(treeData);

  const getListMoocCourseDescriptionDocumentByIData = async () => {
    try {
      const response = await getListMoocCourseDescriptionDocumentById(
        courseConstructPlanId
      );
      const data = response.data.data;
      const newData: TypeCourseDescriptionDocument[] = [...data];

      const updatedData = await Promise.all(
        newData.map(async (item) => {
          try {
            const downloadResult = await downloadFile({
              filePath: item?.filePath,
            });
            return {
              ...item,
              filePath: downloadResult.data,
              fileName: `TaiLieuMoTa/${item.fileName}.${item.fileType}`,
            };
          } catch (error) {
            console.error(error);
            return item;
          }
        })
      );

      console.log(`res-DescriptionDocument:: `, updatedData);
      setDataDescriptionDocument(updatedData);
    } catch (error) {
      console.error(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    getListMoocCourseDescriptionDocumentByIData();
  }, [courseConstructPlanId]);

  return (
    <div>
      <>
        {/* <Space style={{ marginTop: 20, marginLeft: 50 }}>
          <Button type="primary" onClick={handleOpenModalDownloadFile}>
            Open Modal
          </Button>

        </Space> */}
        <Modal
          width={600}
          // open={isOpen && !isShowModalDetailDownload}
          open={true}
          title={`Tải xuống Kế hoạch ${detailCourseConstruct?.registrationName
              ? detailCourseConstruct.registrationName
              : ""
            }`}
          centered={isOpen}
          onOk={handleOk}
          okText="Tải xuống"
          onCancel={handleCancel}
          cancelText="Huỷ"
          okButtonProps={{ disabled: typeDownload.length > 0 ? false : true }}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <Button
                disabled={typeDownload.length > 0 ? false : true}
                onClick={handleShowView}
              >
                Xem trước
              </Button>
              <OkBtn />
            </>
          )}
        >
          <div className="course-plan-managment-download">
            <Space>
              <Checkbox.Group
                defaultValue={typeDownload.length > 0 ? typeDownload : []}
                onChange={handleChangeSelectTypeDownload}
              >
                <Checkbox value={0} className="radio-item">
                  Tải xuống thông tin chung kế hoạch
                </Checkbox>
                <Checkbox value={1} className="radio-item">
                  Tải xuống cấu trúc kế hoạch
                </Checkbox>
                <Checkbox value={2} className="radio-item">
                  Tải tất cả nội dung kế hoạch
                </Checkbox>
              </Checkbox.Group>
            </Space>
            {refFilePdf && (
              <div ref={refFilePdf} className="container infor-plan-none">
                <InformationPlan
                  detailCourseConstruct={detailCourseConstruct}
                />
              </div>
            )}
            {refConstructPlanPdf && (
              <div ref={refConstructPlanPdf} className="construct-plan-none">
                <ConstructionPlan2
                  // defaultActive={defaultActive}
                  // setDefaultActive={setDefaultActive}
                  // listCourseConstruct={listCourseConstruct}
                  // expandedKeys={allKeys}
                  // setExpandedKeys={setExpandedKeys}
                  // autoExpandParent={true}
                  // setAutoExpandParent={setAutoExpandParent}
                  // treeData={treeData}
                  // getAllKeys={getAllKeys}
                  dataCourseStructure={listCourseConstruct}
                  expandAll={expandAll}
                  setExpandAll={setExpandAll}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                />
                {/* <ConstructionPlan2 dataCourseStructure={listCourseConstruct} /> */}
              </div>
            )}
          </div>
        </Modal>
        {isShowModalDetailDownload && (
          <>
            {typeDownload.includes(0) &&
              !typeDownload.includes(1) &&
              !typeDownload.includes(2) && (
                <ModalDownloadInformationPlan
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isShowModalDetailDownload={isShowModalDetailDownload}
                  setIsShowModalDetailDownload={setIsShowModalDetailDownload}
                  detailCourseConstruct={detailCourseConstruct}
                />
              )}
            {!typeDownload.includes(0) &&
              typeDownload.includes(1) &&
              !typeDownload.includes(2) && (
                <ModalDownloadCourseConstructionPlan
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isShowModalDetailDownload={isShowModalDetailDownload}
                  setIsShowModalDetailDownload={setIsShowModalDetailDownload}
                  listCourseConstruct={listCourseConstruct}
                />
              )}
            {!typeDownload.includes(0) &&
              !typeDownload.includes(1) &&
              typeDownload.includes(2) && (
                <ModalDowloadAllPlan
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isShowModalDetailDownload={isShowModalDetailDownload}
                  setIsShowModalDetailDownload={setIsShowModalDetailDownload}
                  detailCourseConstruct={detailCourseConstruct}
                  defaultActive={defaultActive}
                  setDefaultActive={setDefaultActive}
                  listCourseConstruct={listCourseConstruct}
                  // expandedKeys={allKeys}
                  // setExpandedKeys={setExpandedKeys}
                  autoExpandParent={true}
                  setAutoExpandParent={setAutoExpandParent}
                  treeData={treeData}
                  getAllKeys={getAllKeys}
                  courseConstructPlanId={courseConstructPlanId}
                  expandAll={expandAll}
                  setExpandAll={setExpandAll}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                />
              )}

            {typeDownload.includes(0) &&
              typeDownload.includes(1) &&
              !typeDownload.includes(2) && (
                <ModalDowloadAllPlan
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isShowModalDetailDownload={isShowModalDetailDownload}
                  setIsShowModalDetailDownload={setIsShowModalDetailDownload}
                  detailCourseConstruct={detailCourseConstruct}
                  defaultActive={defaultActive}
                  setDefaultActive={setDefaultActive}
                  listCourseConstruct={listCourseConstruct}
                  // expandedKeys={allKeys}
                  // setExpandedKeys={setExpandedKeys}
                  autoExpandParent={true}
                  setAutoExpandParent={setAutoExpandParent}
                  treeData={treeData}
                  getAllKeys={getAllKeys}
                  courseConstructPlanId={courseConstructPlanId}
                  expandAll={expandAll}
                  setExpandAll={setExpandAll}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                />
              )}
            {typeDownload.includes(0) &&
              !typeDownload.includes(1) &&
              typeDownload.includes(2) && (
                <ModalDowloadAllPlan
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isShowModalDetailDownload={isShowModalDetailDownload}
                  setIsShowModalDetailDownload={setIsShowModalDetailDownload}
                  detailCourseConstruct={detailCourseConstruct}
                  defaultActive={defaultActive}
                  setDefaultActive={setDefaultActive}
                  listCourseConstruct={listCourseConstruct}
                  // expandedKeys={allKeys}
                  // setExpandedKeys={setExpandedKeys}
                  autoExpandParent={true}
                  setAutoExpandParent={setAutoExpandParent}
                  treeData={treeData}
                  getAllKeys={getAllKeys}
                  courseConstructPlanId={courseConstructPlanId}
                  expandAll={expandAll}
                  setExpandAll={setExpandAll}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                />
              )}
            {!typeDownload.includes(0) &&
              typeDownload.includes(1) &&
              typeDownload.includes(2) && (
                <ModalDowloadAllPlan
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isShowModalDetailDownload={isShowModalDetailDownload}
                  setIsShowModalDetailDownload={setIsShowModalDetailDownload}
                  detailCourseConstruct={detailCourseConstruct}
                  defaultActive={defaultActive}
                  setDefaultActive={setDefaultActive}
                  listCourseConstruct={listCourseConstruct}
                  // expandedKeys={allKeys}
                  // setExpandedKeys={setExpandedKeys}
                  autoExpandParent={true}
                  setAutoExpandParent={setAutoExpandParent}
                  treeData={treeData}
                  getAllKeys={getAllKeys}
                  courseConstructPlanId={courseConstructPlanId}
                  expandAll={expandAll}
                  setExpandAll={setExpandAll}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                />
              )}
            {typeDownload.includes(0) &&
              typeDownload.includes(1) &&
              typeDownload.includes(2) && (
                <ModalDowloadAllPlan
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  isShowModalDetailDownload={isShowModalDetailDownload}
                  setIsShowModalDetailDownload={setIsShowModalDetailDownload}
                  detailCourseConstruct={detailCourseConstruct}
                  defaultActive={defaultActive}
                  setDefaultActive={setDefaultActive}
                  listCourseConstruct={listCourseConstruct}
                  // expandedKeys={allKeys}
                  // setExpandedKeys={setExpandedKeys}
                  autoExpandParent={true}
                  setAutoExpandParent={setAutoExpandParent}
                  treeData={treeData}
                  getAllKeys={getAllKeys}
                  courseConstructPlanId={courseConstructPlanId}
                  expandAll={expandAll}
                  setExpandAll={setExpandAll}
                  expandedKeys={expandedKeys}
                  setExpandedKeys={setExpandedKeys}
                />
              )}
          </>
        )}

        {isOpenModalProgressBar && typeDownload.length > 0 && (
          <ModalProgressBar
            isOpen={isOpenModalProgressBar}
            setIsOpen={setIsOpenModalProgressBar}
            isOpenModalFather={isOpen}
            setIsOpenModalFather={setIsOpen}
            refFilePdf={refFilePdf}
            refConstructPlanPdf={refConstructPlanPdf}
            typeDownload={typeDownload}
            dataDescriptionDocument={dataDescriptionDocument}
            detailCourseConstruct={detailCourseConstruct}
          />
        )}
      </>
    </div>
  );
};

export default CourseConstructionPlan;
