export const courseStatus = [
  { value: 1, text: "Bản nháp", color: "lightgray" },
  { value: 2, text: "Chờ tổ chuyên môn phê duyệt", color: "lightgray" },
  { value: 3, text: "Đã phê duyệt", color: "green" },
  { value: 4, text: "Từ chối", color: "red" },
  { value: 5, text: "Đã xuất bản", color: "orange" },
  { value: 6, text: "Đã kết thúc", color: "blue" },
];
export const courseType = [
  { value: 1, text: "Tự thực hiện" },
  { value: 2, text: "Hợp tác xây dựng" },
  { value: 3, text: "Dùng chung" },
];
export const courseStatusApprasal = [
  { value: 1, text: "Bản nháp", color: "lightgray" },
  { value: 2, text: "Chờ thẩm định", color: "lightgray" },
  { value: 3, text: "Đã thẩm định", color: "green" },
  { value: 4, text: "Từ chối", color: "red" },
  { value: 5, text: "Đã xuất bản", color: "orange" },
  { value: 6, text: "Đã kết thúc", color: "blue" },
];
export const approvalStatus = [
  { value: 1, text: "Chờ duyệt", color: "lightgray" },
  { value: 2, text: "Đã duyệt", color: "green" },
  { value: 3, text: "Từ chối", color: "red" },
  { value: 4, text: "Đã xuất bản", color: "orange" },
];

export enum courseStatusEum {
  SAVE_DRAFT = 1,
  WAIT_APPROVE = 2,
  APPROVED = 3,
  REFUSE = 4,
  PUBLISHED = 5,
  END = 6,
}
export enum approvalStatusEum {
  WAIT_APPROVE = 1,
  APPROVED = 2,
  REFUSE = 3,
  RECUPERATE = 4,
}

export enum OrganizationalGroupId {
  TCM = 1,
  HDTM = 2,
}