// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-traning-filter .heading-extra {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  text-align: center;
  font-size: 14px; }
  .survey-managment-traning-filter .heading-extra .filter-button {
    background-color: #fff;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    border: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 15px;
    gap: 10px; }
    .survey-managment-traning-filter .heading-extra .filter-button span {
      margin: 0; }
    @media screen and (max-width: 465px) {
      .survey-managment-traning-filter .heading-extra .filter-button {
        flex-wrap: nowrap; } }
  .survey-managment-traning-filter .heading-extra .btn-setting {
    padding: 7px 10px;
    border-radius: 6px; }
  .survey-managment-traning-filter .heading-extra .heading-search .ant-input-group-addon {
    background: transparent; }

.survey-managment-traning-filter .ant-dropdown-menu {
  width: 180px; }
  .survey-managment-traning-filter .ant-dropdown-menu .ant-checkbox-wrapper {
    padding: 5px 0; }

@media screen and (max-width: 480px) {
  .survey-managment-traning-filter .heading-resigter button {
    max-width: 100%;
    width: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/training-need/components/filter-table/filter-table.scss"],"names":[],"mappings":"AAAA;EAEI,aAAqB;EACrB,mBAAoB;EACpB,mBAAuB;EACvB,2BAA2B;EAC3B,QAAoB;EACpB,kBAAuB;EACvB,eAAqB,EAAA;EARzB;IAWM,sBAAsB;IACtB,uCAA6C;IAC7C,yBAAmC;IACnC,aAAsB;IACtB,mBAAqB;IACrB,mBAAwB;IACxB,uBAAwB;IACxB,iBAA0B;IAC1B,SAAsB,EAAA;IAnB5B;MAsBQ,SAAS,EAAA;IAGX;MAzBN;QA0BQ,iBAAiB,EAAA,EAEpB;EA5BL;IA+BM,iBAAuB;IACvB,kBAAkB,EAAA;EAhCxB;IAsCQ,uBAAuB,EAAA;;AAtC/B;EA6CI,YAAY,EAAA;EA7ChB;IAgDM,cAAc,EAAA;;AAIlB;EApDF;IAuDQ,eAAe;IACf,WAAe,EAAA,EAChB","sourcesContent":[".survey-managment-traning-filter {\n  .heading-extra {\n    display        : flex;\n    flex-direction : row;\n    align-items    : center;\n    justify-content: flex-start;\n    gap            : 8px;\n    text-align     : center;\n    font-size      : 14px;\n\n    .filter-button {\n      background-color: #fff;\n      box-shadow      : 0 2px 0 rgba(0, 0, 0, 0.02);\n      border          : 1px solid #d9d9d9;\n      display         : flex;\n      flex-direction  : row;\n      align-items     : center;\n      justify-content : center;\n      padding         : 4px 15px;\n      gap             : 10px;\n\n      span {\n        margin: 0;\n      }\n\n      @media screen and (max-width: 465px) {\n        flex-wrap: nowrap;\n      }\n    }\n\n    .btn-setting {\n      padding      : 7px 10px;\n      border-radius: 6px;\n\n    }\n\n    .heading-search {\n      .ant-input-group-addon {\n        background: transparent;\n      }\n    }\n\n  }\n\n  .ant-dropdown-menu {\n    width: 180px;\n\n    .ant-checkbox-wrapper {\n      padding: 5px 0;\n    }\n  }\n\n  @media screen and (max-width: 480px) {\n    .heading-resigter {\n      button {\n        max-width: 100%;\n        width    : 100%;\n      }\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
