// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-statistical .ant-statistic-content .ant-statistic-content-value {
  width: 100%;
  justify-content: space-between;
  display: flex; }
  .card-statistical .ant-statistic-content .ant-statistic-content-value .percentage-difference {
    font-size: 14px;
    align-self: center; }

.row-staticstical-chart {
  justify-content: space-between; }
  .row-staticstical-chart .col-staticstical-chart {
    max-width: 20%; }
`, "",{"version":3,"sources":["webpack://./src/pages/statistical-report/for-university/component/chart/overview-tab/overview-chart-tab.scss"],"names":[],"mappings":"AAAA;EAGM,WAAW;EACX,8BAA8B;EAC9B,aAAa,EAAA;EALnB;IAQQ,eAAe;IACf,kBAAkB,EAAA;;AAM1B;EACE,8BAA8B,EAAA;EADhC;IAGI,cAAc,EAAA","sourcesContent":[".card-statistical {\n  .ant-statistic-content {\n    .ant-statistic-content-value {\n      width: 100%;\n      justify-content: space-between;\n      display: flex;\n\n      .percentage-difference {\n        font-size: 14px;\n        align-self: center;\n      }\n    }\n  }\n}\n\n.row-staticstical-chart {\n  justify-content: space-between;\n  .col-staticstical-chart {\n    max-width: 20%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
