import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import { Button, Dropdown, MenuProps, message, Modal, Space } from "antd";
import { EyeOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import { Layout, Tabs, TabsProps, Form } from "antd";
import { useParams } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";
import FilterChart from "../filter-chart/FilterTable";
import {
  getSurveyById,
  getResult,
} from "../../../../../service/survey-managment/survey-synthesis/survey-systhesis";

import { isEmpty } from "lodash";
import { FilterResultChart } from "../../../../../types/survey-managment/listSurvey";
import { Table, Pagination } from "antd";
import type { TableProps, TableColumnsType } from "antd";
import FilterSynthesis from "../filter-synthesis/FilterTable";
import ResultChartRender from "../result-chart-render";

interface DataType {
  key: React.Key;
  questionId: number;
  type: number;
  question: string;
  questionData: Array<Object>;
  ordernumber: number;
  answerCounts: Array<string>;
  totalAnswers: number;
  dateReply: string;
  answerOther: Array<string>;
  answerText: Array<string>;
  isParent: boolean;
}
const handleReturnType = (typeCode: number) => {
  switch (typeCode) {
    case 1:
      return "Nhiều lựa chọn văn bản";
    case 2:
      return "Nhiều lựa chọn ảnh ";
    case 3:
      return "Lựa chọn đúng, sai ";
    case 4:
      return "Lựa chọn dropdown";
    case 5:
      return "Nhiều trả lời văn bản";
    case 6:
      return "Nhiều trả lời ảnh";
    case 7:
      return "Câu hỏi kết nối";
    case 8:
      return "Câu trả lời ngắn";
    default:
      return "";
  }
};
const formatDateV2 = (date: string) => {
  return moment(date).format("DD/MM/yyyy");
};

const { Content } = Layout;
const imgPath = "/images/";
type ResultChartProps = {
  refChild?: any;
};
const ResultSynthesis: FunctionComponent<ResultChartProps> = ({ refChild }) => {
  const location = useLocation();
  const [dataFilter, setDataFilter] = useState<FilterResultChart>({
    repDate: null,
    typeQuestion: null,
    nameQuestion: null,
  });
  const childRef = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [params, setParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const paramsId = useParams();
  const [dataResult, setDataResult] = useState<DataType[]>([]);
  const [isOpened, setIsOpened] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [form] = Form.useForm();
  const getResultSurvey = async () => {
    const dataFilterResult = {
      id: Number(paramsId.id),
      repDateFrom: !isEmpty(dataFilter.repDate?.[0])
        ? new Date(dataFilter.repDate?.[0]).toISOString()
        : "",
      repDateTo: !isEmpty(dataFilter.repDate?.[1])
        ? new Date(dataFilter.repDate?.[1]).toISOString()
        : "",
      typeQuestion: !isEmpty(dataFilter.typeQuestion)
        ? dataFilter.typeQuestion
        : null,
      nameQuestion: !isEmpty(dataFilter.nameQuestion)
        ? dataFilter.nameQuestion
        : null,
    };
    getResult(dataFilterResult).then((res) => {
      const { totalRecords, data, pageSize, pageNumber } = res.data.data;
      const modifiedData = data.map((item: any) => {
        // Thêm thuộc tính 'key' với giá trị là 'id' vào mỗi phần tử
        return { ...item, key: item.questionId };
      });
      const dataCSV = modifiedData.map((item: any) => {
        // Check if answerOther exists and has elements

        return item;
      });
      console.log(modifiedData);
      const dataFormat = modifiedData.map((item: any) => {
        const children = [];
        if (item.answerOther && item.answerOther.length > 0) {
          item.answerOther.forEach((answer: string) => {
            children.push(
              ...item.answerOther.map((i: any, index: number) => ({
                key: `${item.questionId}-answerOther-${index}`,
                question: "Khác ( " + i + " )",
                totalAnswers:
                  1 + " (" + `${((1 / item.totalAnswers) * 100).toFixed(2)}%)`,
              }))
            );
          });
        }
        if (item.questionData && item.questionData.length > 0) {
          children.push(
            ...item.questionData.map((i: any, index: number) => ({
              key: `${item.questionId}-questionData-${index}`,
              question: "Đáp án: " + i[0],
              totalAnswers:
                i[1] +
                " (" +
                `${((i[1] / item.totalAnswers) * 100).toFixed(2)}%)`,
            }))
          );
        }
        if (item.answerText && item.answerText.length > 0) {
          children.push(
            ...item.answerText.map((i: any, index: number) => ({
              key: `${item.questionId}-answerText-${index}`,
              question: "Câu trả lời: " + i,
              totalAnswers: 1,
            }))
          );
        }
        return {
          ...item,
          isParent: true,
          children,
        };
      });
      console.log("format", dataFormat);
      setPageNumber(pageNumber);
      setPageSize(pageSize);
      setTotalRecords(totalRecords);
      setDataResult(dataFormat);
    });
  };

  useEffect(() => {
    getResultSurvey();
  }, []);
  const ref = useRef<HTMLDivElement>(null); // Sử dụng useRef với kiểu HTMLDivElement
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const handleSubmitSearch = () => {
    // if (searchValue === "") {
    //   setPageNumber(1);
    // }
    getResultSurvey();
  };
  const handleChangeFilter = (value: any, name: string) => {
    setDataFilter({ ...dataFilter, [name]: value });
  };
  const columns: TableColumnsType<DataType> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        if (record.isParent === true) {
          return <span className="table-order-header">{orderNumber}</span>;
        }
      },
    },
    {
      title: "Tên câu hỏi",
      dataIndex: "question",
      width: "25%",
      key: "question",
    },
    {
      title: "Loại câu hỏi",
      dataIndex: "type",
      width: "35%",
      key: "type",
      render: (_: any, record) => {
        return <div>{handleReturnType(record?.type)}</div>;
      },
    },
    {
      title: "Số lượt trả lời",
      dataIndex: "totalAnswers",
      width: "15%",
      key: "totalAnswers",
    },
    {
      title: "Trả lời gần nhất",
      width: "15%",
      dataIndex: "dateReply",
      render: (_: any, record) => {
        return <>{record.dateReply ? formatDateV2(record.dateReply) : null}</>;
      },
    },
  ];
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };
  const handleOpenCreate = () => {
    setIsOpened(true);
  };
  const clearFilter = () => {
    form.resetFields();
    setDataFilter({
      repDate: null,
      typeQuestion: null,
      nameQuestion: null,
    });
  };
  const onChange = (page: any, pageSize: any) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
      setTotalRecords(totalRecords);
    }
  };
  const itemRender = (_: any, type: any, originalElement: any) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <>
      <Layout className="page-header-group">
        <Form.Provider>
          <div
            className={`page-header-group--layout ${
              showFilter ? "open-filter" : ""
            }`}
          >
            <div className="table-header--wrapper">
              <FilterSynthesis
                childRef={childRef}
                handleOpen={handleOpenCreate}
                searchValue={searchValue}
                filterData={dataFilter}
                setFilterData={handleChangeFilter}
                handleChangeInput={handleChangeInput}
                handleSubmitSearch={handleSubmitSearch}
                clearFilter={clearFilter}
                form={form}
                dataExport={dataResult}
              />
            </div>
          </div>
          {/* <div className="list-chart">
            <Table
              columns={columns}
              expandable={{
                expandedRowRender: (record) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {record.type !== 8 ? (
                      <>
                        {record.questionData.map((dataItem: any, index) => (
                          <div
                            key={index}
                            style={{ display: "flex", marginLeft: "10.2%" }}
                          >
                            <div style={{ width: "100%" }}>
                              Đáp án: {dataItem[0]}
                            </div>
                            <div
                              style={{
                                width: "46%",
                              }}
                            >
                              {dataItem[1]}{" "}
                              <span>
                                (
                                {(
                                  (Number(dataItem[1]) / record?.totalAnswers) *
                                  100
                                ).toFixed(2)}
                                %)
                              </span>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      record.answerText.map((dataItem: any, index: any) => (
                        <div
                          key={index}
                          style={{ display: "flex", marginLeft: "10.2%" }}
                        >
                          <div style={{ marginBottom: 20 }}>
                            Câu trả lời: {dataItem}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                ),
              }}
              dataSource={dataResult}
            />
          </div> */}
          <div className="list-chart">
            <Table columns={columns} dataSource={dataResult} />
          </div>
        </Form.Provider>
        <div style={{ display: "none" }}>
          <ResultChartRender
            id={Number(paramsId.id)}
            repDateFrom={
              !isEmpty(dataFilter.repDate?.[0])
                ? new Date(dataFilter.repDate?.[0]).toISOString()
                : ""
            }
            repDateTo={
              !isEmpty(dataFilter.repDate?.[1])
                ? new Date(dataFilter.repDate?.[1]).toISOString()
                : ""
            }
            refChild={childRef}
            typeQuestion={
              !isEmpty(dataFilter.typeQuestion) ? dataFilter.typeQuestion : null
            }
            nameQuestion={
              !isEmpty(dataFilter.nameQuestion) ? dataFilter.nameQuestion : null
            }
          />
        </div>
      </Layout>
    </>
  );
};

export default ResultSynthesis;
