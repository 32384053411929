import React, { useState } from "react";
import CustomCard from "../../../custom-card/CustomCard";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Select,
  Space,
  Table,
  TableColumnType,
  TableColumnsType,
  TreeDataNode,
  Typography,
} from "antd";
import DirectoryTree, { DirectoryTreeProps } from "antd/es/tree/DirectoryTree";
import Icon, {
  CaretDownOutlined,
  CaretUpOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileOutlined,
  InfoCircleOutlined,
  MinusCircleTwoTone,
  MoreOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
// import "./course-construct.scss"
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import { isEmpty } from "lodash";
import "./course-construct.scss";
import { DetailCourseData } from "../../../../types/assign-course-construction";
import { ConstructCourseDetailType } from "../../../../types/course-construction";
import { Key } from "antd/lib/table/interface";
import { routesConfig } from "../../../../config/routes";
import { downloadCourseUnit } from "../../../../service/course-construct";
import { HelpService } from "../../../../service/helper.service";

type Props = {
  detailData: ConstructCourseDetailType;
};

export default function CourseDetailView(props: Props) {
  const helpService = new HelpService();
  const { detailData } = props;
  const [expandAll, setExpandAll] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const data: any =
    !isEmpty(detailData) &&
    detailData?.sections?.map((item: any, index: number) => {
      return {
        key: item.key,
        name: item.name,
        assignedName: item.assignedName,
        assignedId: item.assignedId,
        children: item.children,
      };
    });

  type CourseChapter = {
    id: number;
    name: string;
    assignedId: number | null;
    assignedName: string;
    orderNumber: number;
    children: CourseChapter[];
    key: string;
  };

  const getAllKeysToExpand = (chapters: CourseChapter[]): string[] => {
    let keys: string[] = [];

    function traverse(chapters: CourseChapter[]) {
      for (const chapter of chapters) {
        keys.push(chapter.key);
        if (chapter.children && chapter.children.length > 0) {
          traverse(chapter.children);
        }
      }
    }

    traverse(chapters);
    return keys;
  };

  const handleClickExpand = () => {
    setExpandAll(!expandAll);
    if (expandAll) {
      handleCollapseAll();
    } else {
      handleExpandAll();
    }
  };
  const handleExpandAll = () => {
    const allKey = getAllKeysToExpand(data);
    setExpandedKeys(allKey);
  };

  const handleCollapseAll = () => {
    setExpandedKeys([]);
  };

  const downloadUnit = (unitLink: string, fileName: string) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        const blobUrl = window.URL.createObjectURL(xmlHttp.response);
        const e = document.createElement('a');
        e.href = blobUrl;
        e.download = fileName;  // Set the file name for download
        document.body.appendChild(e);
        e.click();
        document.body.removeChild(e);
        window.URL.revokeObjectURL(blobUrl); // Clean up the URL.createObjectURL resource
      }
    };
    xmlHttp.responseType = 'blob';
    xmlHttp.open('GET', unitLink, true);
    xmlHttp.send(null);
  };

  const downloadUnitFeedback = async (id: number) => {
    await downloadCourseUnit(id).then((res: any) => {
      const link = document.createElement('a');
      link.href = res.data;
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch((error: any) => {
      helpService.errorMessage(error.response.data.message);
    });
  }

  interface DataType {
    key: React.ReactNode;
    name: string;
    assignedName: string;
    assignedId: string;
    children?: DataType[];
  }

  const columns: TableColumnsType<any> = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "",
      dataIndex: "assignedName",
      width: "40%",
      key: "assignedName",
      render: (_: any, record) => {
        return (
          <>
            {isEmpty(record?.block) ?
              <p>
                {!isEmpty(record.assignedName)
                  ? `${record.assignedName} (${record.assignedId})`
                  : ""}
              </p> :
              <Space className="dropdown-course-browse">
                <Dropdown
                  className="dropdown-course-browse"
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: "Tải xuống",
                        icon: <DownloadOutlined />,
                      },
                    ],
                    onClick: ({ key }) => {
                      if (key === "1") {
                        if (record.block?.[0]?.module !== 'text' && record.block?.[0]?.module !== 'quizz' && record.block?.[0]?.module !== 'quiz') {
                          const fileName = record.block?.[0]?.mainKey.split('/').pop(); // Extract the file name from the URL
                          downloadUnit(record.block?.[0]?.mainKey, fileName);
                        }
                        if (record.block?.[0]?.module === 'quiz') {
                          downloadUnitFeedback(record.id);
                        }
                      }
                    }
                  }}
                  placement="bottomRight"
                >
                  <Button>
                    <MoreOutlined />
                  </Button>
                </Dropdown>
              </Space >
            }
          </>
        );
      },
    },
  ];

  const locale = {
    emptyText: "Không có dữ liệu",
  };

  return (
    <div className="construct-view-ctn">
      <CustomCard
        title={
          <div className="card-heading--info--construct-view">
            <Row gutter={[{ xs: 12, xl: 8, md: 8 }, 8]}>
              <Col
                xl={{ span: 12 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 12 }}
              >
                <Typography.Title level={5}>
                  Cấu trúc của khoá học
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Chi tiết cấu trúc được duyệt của kế hoạch
                </Typography.Text>
              </Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 4 }}
                sm={{ span: 4 }}
                xs={{ span: 4 }}
              ></Col>
              <Col
                xl={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 8 }}
                xs={{ span: 8 }}
              >
                <Button
                  disabled={data.length === 0}
                  onClick={() => handleClickExpand()}
                  className="float-right"
                >
                  {expandAll ? "Thu gọn" : "Hiển thị chi tiết"}
                </Button>
              </Col>
            </Row>
          </div>
        }
        className="card-info--wrapper"
      >
        <div className="list-wrapper">
          <Table
            size="small"
            // indentSize={0}
            locale={locale}
            columns={columns}
            dataSource={data}
            pagination={false}
            expandable={{
              expandedRowKeys: expandedKeys,
              onExpandedRowsChange: (keys: readonly Key[]) => {
                setExpandedKeys([...keys]);
              },
            }}
            rowClassName={(record, index) => {
              if (!isEmpty(record.children)) {
                return "expand-record";
              }
              return "";
            }}
          />
        </div>
      </CustomCard>
    </div>
  );
}
