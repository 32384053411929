import { getPermission } from "../service/common";

export const checkPermission = async (permission: string[]) => {
  const res = await getPermission();
  if (res && res.data && res.data.data && res.data.data.permissions) {
    const listUserPermission = res.data.data.permissions;
    // console.log(`res.data.data.permissions:::`, listUserPermission);
    const hasPermission = permission.some(perm => listUserPermission.includes(perm));
    return hasPermission;
  }
  return false;
};

