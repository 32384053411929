import {Col, DatePicker, Form, Row, Select} from "antd";
import {FormInstance} from "antd/lib";
import {FunctionComponent} from "react";
import FormInput from "../../../../../form-input/FormInput";
import dayjs from "dayjs";

type FormCreateFormSurveyProps = {
  form: FormInstance;
  onFinish?: (values: any) => void;
  dataOptions?: any;
  isOpened: boolean;
  surveyPlan: any;
};

const {Option} = Select;

const validations = {
  surveyFormTitle: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tiêu đề."));
      } else if (value.length > 512) {
        return Promise.reject(new Error("Không nhập quá 512 ký tự."));
      }
      return Promise.resolve();
    },
  },
  surveyCode: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập mã khảo sát."));
      } else if (value.length > 512) {
        return Promise.reject(new Error("Không nhập quá 512 ký tự."));
      }
      return Promise.resolve();
    },
  },
  executionTime: {
    required: true,
    message: "Vui lòng chọn thời gian thực hiện.",
  },
};

const {RangePicker} = DatePicker;

const FormCreateSurveyForm: FunctionComponent<FormCreateFormSurveyProps> = ({
                                                                              form,
                                                                              onFinish,
                                                                              dataOptions,
                                                                              isOpened,
                                                                              surveyPlan,
                                                                            }) => {
  const startDate = dayjs(surveyPlan.timeStart);
  const endDate = dayjs(surveyPlan.timeEnd);

  const disabledDate = (current: any) => {
    return current && (current < startDate || current > endDate);
  };

  return (
    <>
      <Form
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        name="createSurveyForm"
        className="form-create-survey-form"
      >
        <Row gutter={16}>
          <Col span={12} className="col-table-create">
            <Form.Item
              name="surveyTitle"
              rules={[validations.surveyFormTitle]}
              label="Tiêu đề"
            >
              <FormInput placeholder="Nhập tiêu đề"/>
            </Form.Item>
          </Col>
          <Col span={12} className="col-table-create">
            <Form.Item
              name="surveyCode"
              label="Mã khảo sát"
              rules={[validations.surveyCode]}
            >
              <FormInput placeholder="Nhập mã khảo sát"/>
            </Form.Item>
          </Col>
          <Col span={12} className="col-table-create">
            <Form.Item name="surveyForm" label="Mẫu khảo sát">
              <Select placeholder="Chọn mẫu" showSearch={true}>
                {dataOptions?.dataSurveyForm.map(
                  (item: string, index: number) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} className="col-table-create">
            <Form.Item
              name="executionTime"
              label="Thời gian thực hiện"
              rules={[validations.executionTime]}
            >
              <RangePicker
                allowClear
                style={{width: "100%"}}
                placeholder={["Từ ngày", "Đến ngày"]}
                format={"DD/MM/YYYY"}
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FormCreateSurveyForm;
