
import React, { useEffect, useState } from "react";
import "./detail.scss";
import {
  Button,
  Card,
  Input,
  Radio,
  Row,
  Select,
  Space,
  ConfigProvider,
  Switch,
  Dropdown,
  MenuProps,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  FundViewOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import toolbarOptions from "./toolbarOptions";
import QuestionType1 from "../question-type-1/QuestionType1";
import QuestionType2 from "../question-type-2/QuestionType2";
import QuestionType3 from "../question-type-3/QuestionType3";
import QuestionType4 from "../question-type-4/QuestionType4";
import QuestionType6 from "../question-type-6/QuestionType6";
import QuestionType5 from "../question-type-5/QuestionType5";
import QuestionType7 from "../question-type-7/QuestionType7";
import QuestionType8 from "../question-type-8/QuestionType8";
import { optionsDropDownQuestionType } from "../constant/data";
import { ListQuestion, OptionTypeQuestion1 } from "../../../../../../../../../types/survey-managment/survey";
import { FORMAT_REACT_QUILL } from "../../../../../../../../../constants";

const modules = {
  toolbar: toolbarOptions,
};


const QuestionUI_original = (props: any) => {
  const [typeQuestionId, setTypeQuestionId] = useState(1);
  const [isOpenAddDescription, setIsOpenAddDescription] = useState(false);
  const [valueQuillContainer, setValueQuillContainer] = useState("");

  // console.log(valueQuillContainer)
  const listSectionQuestions: ListQuestion[] = props.listSectionQuestions;

  const onChangeStatusIsQuestionAllow = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  const onFocusReactQuill = () => { };

  const handleOpenAddDescription = () => {
    setIsOpenAddDescription(!isOpenAddDescription);
  };

  const optionsMoreQuestion: MenuProps["items"] = [
    {
      key: "1",
      label:
        valueQuillContainer === ""
          ? isOpenAddDescription
            ? "Ẩn mô tả"
            : "Thêm mô tả"
          : isOpenAddDescription
            ? "Ẩn mô tả"
            : "Hiển thị mô tả",
      icon: <FundViewOutlined onClick={handleOpenAddDescription} />,
    },
    {
      key: "2",
      label: "Di chuyển lên trên",
      icon: <ArrowUpOutlined />,
    },
    {
      key: "3",
      label: "Di chuyển xuống dưới",
      icon: <ArrowDownOutlined />,
    },
  ];

  const handleChangeTypeQuestion = (value: string) => {
    const objectType = optionsDropDownQuestionType.find(
      (option) => option.value === value
    );
    if (objectType && objectType.type) {
      setTypeQuestionId(objectType.type);
    }
  };

  const questionsUI = (typeQuestionId: number) => {
    const optionsExample: OptionTypeQuestion1[] = [
      { id: 111, content: "vb 01", order: 1 },
      { id: 332, content: "vb 02", order: 2 },
      { id: 223, content: "vb 03", order: 3 },
      { id: 404, content: "vb 04", order: 4 },
    ];

    switch (typeQuestionId) {
      case 1:
        return <QuestionType1 listOptions={optionsExample} />;
      case 2:
        return <QuestionType2 listOptions={optionsExample} />;
      case 3:
        return <QuestionType3 listOptions={optionsExample} />;
      case 4:
        return <QuestionType4 listOptions={optionsExample} />;
      case 5:
        return <QuestionType5 listOptions={optionsExample} />;
      case 6:
        return <QuestionType6 listOptions={optionsExample} />;
      case 7:
        return <QuestionType7 listOptions={optionsExample} />;

      case 8:
        return <QuestionType8 listOptions={optionsExample} />;
      default:
        return null;
    }
  };

  const getNameQuestionType = (type: number) => {
    const thisQuestionKind = optionsDropDownQuestionType.find(item => item.type === type)
    return thisQuestionKind?.value
  }

  return (
    <div>
      <div>
        {listSectionQuestions.length > 0 ? (
          <>
            {listSectionQuestions.map((sectionQuestion, index) => {
              const {
                questionName,
                questionType,
              }: ListQuestion = sectionQuestion;
              return (
                <Card
                  key={index}
                  title={
                    <TextArea
                      className="input-header-title"
                      value={questionName}
                      style={{
                        height: "auto",
                        width: 450,
                        marginTop: 15,
                        fontSize: 20,
                        fontWeight: "lighter",
                        color: "black",
                        wordWrap: "break-word",
                        resize: "none",
                      }}
                    />
                  }
                  styles={{ header: { border: "none" } }}
                  className="wrapper-settings--card form-setting--common"
                  extra={
                    <>
                      <Select
                        value={`${getNameQuestionType(Number(questionType))}`}
                        onChange={handleChangeTypeQuestion}
                        options={optionsDropDownQuestionType}
                      />
                    </>
                  }
                >
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ display: "flex" }}
                  >
                    {/* <Button
              onClick={handleOpenAddDescription}
              style={
                isOpenAddDescription
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              {valueQuillContainer === "" ? (
                <span>Thêm mô tả ... </span>
              ) : (
                <span>Hiển thị mô tả ... </span>
              )}
            </Button> */}
                    <ReactQuill
                      modules={modules}
                      formats={FORMAT_REACT_QUILL}
                      theme="snow"
                      onChange={setValueQuillContainer}
                      placeholder="Thêm mô tả tại đây ... "
                      onFocus={onFocusReactQuill}
                      style={
                        !isOpenAddDescription
                          ? { display: "none" }
                          : {
                            display: "flex",
                            flexDirection: "column-reverse",
                            alignItems: "flex-end",
                          }
                      }
                    />
                  </Space>

                  {questionsUI(typeQuestionId)}

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Space size="large">
                      <Space size="small">
                        <span>Bắt Buộc </span>
                        <Switch
                          defaultChecked={false}
                          onChange={onChangeStatusIsQuestionAllow}
                          size="small"
                        />
                      </Space>
                      <Space size="small">
                        <Button icon={<CopyOutlined />} />
                        <Button icon={<DeleteOutlined />} />
                        <Dropdown
                          menu={{
                            items: optionsMoreQuestion,
                            onClick: ({ key }) => {
                              if (key === "1") {
                                handleOpenAddDescription();
                              }
                              if (key === "2") {
                                // Modal.confirm({
                                //   title: `Bạn có muốn tạo bản sao cho ${record.name}`,
                                //   content: `Tên của bản sao khảo sát là "${record.name} (bản sao)"`,
                                //   onOk: () => copyData(record),
                                //   okText: "Lưu",
                                //   cancelText: "Huỷ",
                                //   centered: true,
                                //   icon: <CopyOutlined style={{ color: "#1677FF" }} />,
                                //   footer: (_, { OkBtn, CancelBtn }) => (
                                //     <>
                                //       <CancelBtn />
                                //       <OkBtn />
                                //     </>
                                //   ),
                                // });
                              }
                            },
                          }}
                          placement="bottomRight"
                        >
                          <Button icon={<MoreOutlined />} />
                        </Dropdown>
                      </Space>
                    </Space>
                  </div>
                </Card>
              );
            })}

            <Card
              title={
                <TextArea
                  className="input-header-title"
                  placeholder="Thêm câu hỏi"
                  style={{
                    height: "auto",
                    width: 450,
                    marginTop: 15,
                    fontSize: 20,
                    fontWeight: "lighter",
                    color: "black",
                    wordWrap: "break-word",
                    resize: "none",
                  }}
                />
              }
              styles={{ header: { border: "none" } }}
              className="wrapper-settings--card form-setting--common"
              extra={
                <>
                  <Select
                    defaultValue="Nhiều câu hỏi văn bản"
                    onChange={handleChangeTypeQuestion}
                    options={optionsDropDownQuestionType}
                  />
                </>
              }
            >
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                {/* <Button
              onClick={handleOpenAddDescription}
              style={
                isOpenAddDescription
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              {valueQuillContainer === "" ? (
                <span>Thêm mô tả ... </span>
              ) : (
                <span>Hiển thị mô tả ... </span>
              )}
            </Button> */}
                <ReactQuill
                  modules={modules}
                  formats={FORMAT_REACT_QUILL}
                  theme="snow"
                  onChange={setValueQuillContainer}
                  placeholder="Thêm mô tả tại đây ... "
                  onFocus={onFocusReactQuill}
                  style={
                    !isOpenAddDescription
                      ? { display: "none" }
                      : {
                        display: "flex",
                        flexDirection: "column-reverse",
                        alignItems: "flex-end",
                      }
                  }
                />
              </Space>

              {questionsUI(typeQuestionId)}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space size="large">
                  <Space size="small">
                    <span>Bắt Buộc </span>
                    <Switch
                      defaultChecked={false}
                      onChange={onChangeStatusIsQuestionAllow}
                      size="small"
                    />
                  </Space>
                  <Space size="small">
                    <Button icon={<CopyOutlined />} />
                    <Button icon={<DeleteOutlined />} />
                    <Dropdown
                      menu={{
                        items: optionsMoreQuestion,
                        onClick: ({ key }) => {
                          if (key === "1") {
                            handleOpenAddDescription();
                          }
                          if (key === "2") {

                          }
                        },
                      }}
                      placement="bottomRight"
                    >
                      <Button icon={<MoreOutlined />} />
                    </Dropdown>
                  </Space>
                </Space>
              </div>
            </Card>
          </>
        ) : (
          <>
            <Card
              title={
                <TextArea
                  className="input-header-title"
                  placeholder="Thêm câu hỏi"
                  style={{
                    height: "auto",
                    width: 450,
                    marginTop: 15,
                    fontSize: 20,
                    fontWeight: "lighter",
                    color: "black",
                    wordWrap: "break-word",
                    resize: "none",
                  }}
                />
              }
              styles={{ header: { border: "none" } }}
              className="wrapper-settings--card form-setting--common"
              extra={
                <>
                  <Select
                    defaultValue="Nhiều câu hỏi văn bản"
                    onChange={handleChangeTypeQuestion}
                    options={optionsDropDownQuestionType}
                  />
                </>
              }
            >
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                {/* <Button
              onClick={handleOpenAddDescription}
              style={
                isOpenAddDescription
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              {valueQuillContainer === "" ? (
                <span>Thêm mô tả ... </span>
              ) : (
                <span>Hiển thị mô tả ... </span>
              )}
            </Button> */}
                <ReactQuill
                  formats={FORMAT_REACT_QUILL}
                  modules={modules}
                  theme="snow"
                  onChange={setValueQuillContainer}
                  placeholder="Thêm mô tả tại đây ... "
                  onFocus={onFocusReactQuill}
                  style={
                    !isOpenAddDescription
                      ? { display: "none" }
                      : {
                        display: "flex",
                        flexDirection: "column-reverse",
                        alignItems: "flex-end",
                      }
                  }
                />
              </Space>

              {questionsUI(typeQuestionId)}

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space size="large">
                  <Space size="small">
                    <span>Bắt Buộc </span>
                    <Switch
                      defaultChecked={false}
                      onChange={onChangeStatusIsQuestionAllow}
                      size="small"
                    />
                  </Space>
                  <Space size="small">
                    <Button icon={<CopyOutlined />} />
                    <Button icon={<DeleteOutlined />} />
                    <Dropdown
                      menu={{
                        items: optionsMoreQuestion,
                        onClick: ({ key }) => {
                          if (key === "1") {
                            handleOpenAddDescription();
                          }
                          if (key === "2") {

                          }
                        },
                      }}
                      placement="bottomRight"
                    >
                      <Button icon={<MoreOutlined />} />
                    </Dropdown>
                  </Space>
                </Space>
              </div>
            </Card>
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionUI_original;


/* import React, { useEffect, useState } from "react";
import "./detail.scss";
import {
  Button,
  Card,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  ConfigProvider,
  Switch,
} from "antd";
import {
  ArrowDownOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  FileImageOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import TextArea from "antd/es/input/TextArea";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import toolbarOptions from "./toolbarOptions";
import QuestionType1 from "../question-type-1/QuestionType1";
import QuestionType2 from "../question-type-2/QuestionType2";

const contentListNoTitle: Record<string, React.ReactNode> = {
  article: <p>article content</p>,
  app: <p>app content</p>,
  project: <p>project content</p>,
};

const modules = {
  toolbar: toolbarOptions,
};

const tabListNoTitle = [
  {
    key: "article",
    label: "article",
  },
  {
    key: "app",
    label: "app",
  },
  {
    key: "project",
    label: "project",
  },
];

const QuestionUI_original = (props: any) => {
  const options1: MenuProps["items"] = [
    {
      key: "1",
      label: "Tạo bản sao",
      icon: <CopyOutlined />,
    },
    {
      key: "2",
      label: "Chuyển xuống dưới",
      icon: <ArrowDownOutlined />,
    },
    {
      key: "3",
      label: "Xóa khảo sát",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const [activeTabKey2, setActiveTabKey2] = useState<string>("app");

  const onTab2Change = (key: string) => {
    setActiveTabKey2(key);
  };

  const dynamiclyData = [
    { id: 1, value: "Lưa chọn văn bản 01" },
    { id: 2, value: "Lưa chọn văn bản 02" },
    { id: 3, value: "Lưa chọn văn bản 03" },
    { id: 4, value: "Lưa chọn văn bản 04" },
  ];
  const [idChekedFromRequest, setIdChekedFromRequest] = useState(2);

  const questionsUI = () => {
    return (
      <>
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "black",
                paddingInline: 0,
                paddingBlock: 0,
              },
              Button: {
                paddingInline: 2,
                paddingBlock: 0,
              },
            },
          }}
        >
          <Space direction="vertical" size="small" style={{ display: "flex" }}>
            <Row
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                prefix={<Radio checked={false}></Radio>}
                suffix={
                  <Button style={{ width: 20, height: 25 }}>
                    <CloseOutlined />
                  </Button>
                }
                defaultValue="Lựa chọn văn bản 01"
                variant="borderless"
              />
            </Row>
            <Row
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                prefix={<Radio checked={false}></Radio>}
                suffix={
                  <Button style={{ width: 20, height: 25 }}>
                    <CloseOutlined />
                  </Button>
                }
                defaultValue="Lựa chọn văn bản 02"
                variant="borderless"
              />
            </Row>
            <Row
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                prefix={
                  <>
                    <Radio checked={false}></Radio> Thêm lựa chọn hoặc trả lời "
                    <Button
                      type="link"
                      onClick={() => {
                        console.log("add option");
                      }}
                      style={{ textDecoration: "underline" }}
                    >
                      Khác
                    </Button>
                    "
                  </>
                }
                variant="borderless"
                type="submit"
              />
            </Row>
          </Space>
        </ConfigProvider>
      </>
    );
  };

  // Editor state
  const [value, setValue] = useState("");

  const onChangeStatusIsQuestionAllow = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  const onFocusReactQuill = () => {};

  const [isOpenAddDescription, setIsOpenAddDescription] = useState(false);
  const handleOpenAddDescription = () => {
    setIsOpenAddDescription(!isOpenAddDescription);
  };
  return (
    <div>
      <div>
        <Card
          title={
            <TextArea
              className="input-header-title"
              placeholder="Câu hỏi 1"
              style={{
                height: "auto",
                width: 450,
                marginTop: 15,
                fontSize: 20,
                fontWeight: "lighter",
                color: "black",
                wordWrap: "break-word",
                resize: "none",
              }}
            />
          }
          styles={{ header: { border: "none" } }}
          className="wrapper-settings--card form-setting--common"
          extra={
            <>
              <Select
                defaultValue="Nhiều câu hỏi văn bản"
                onChange={handleChange}
                options={[
                  {
                    value: "Nhiều lựa chọn văn bản",
                    label: "Nhiều lựa chọn văn bản",
                  },
                  { value: "Nhiều lựa chọn ảnh", label: "Nhiều lựa chọn ảnh" },
                  { value: "Lựa chọn đúng sai", label: "Lựa chọn đúng sai" },
                  { value: "Lựa chọn Dropdown", label: "Lựa chọn Dropdown" },
                  {
                    value: "Nhiều trả lời văn bản",
                    label: "Nhiều trả lời văn bản",
                  },
                  { value: "Nhiều trả lời ảnh", label: "Nhiều trả lời ảnh" },
                  { value: "Câu hỏi kết nối", label: "Câu hỏi kết nối" },
                  { value: "Câu trả lời ngắn", label: "Câu trả lời ngắn" },
                ]}
              />
            </>
          }
        >
          <Space direction="vertical" size="small" style={{ display: "flex" }}>
            <Button
              onClick={handleOpenAddDescription}
              style={
                isOpenAddDescription
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              Thêm mô tả
            </Button>
            <ReactQuill
              modules={modules}
              theme="snow"
              onChange={setValue}
              placeholder="Thêm mô tả tại đây ... "
              onFocus={onFocusReactQuill}
              style={
                !isOpenAddDescription
                  ? { display: "none" }
                  : {
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignItems: "flex-end",
                    }
              }
            />
          </Space>
          <QuestionType2/>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Space size="large">
              <Space size="small">
                <span>Bắt Buộc </span>
                <Switch
                  defaultChecked={false}
                  onChange={onChangeStatusIsQuestionAllow}
                  size="small"
                />
              </Space>
              <Space size="small">
                <Button icon={<CopyOutlined />} />
                <Button icon={<DeleteOutlined />} />
                <Button icon={<FileImageOutlined />} />
                <Button icon={<MoreOutlined />} />
              </Space>
            </Space>
          </div>
        </Card>
      </div>

    </div>
  );
};

export default QuestionUI_original;

*/