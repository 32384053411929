import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../../config/api/configApiv2";


export const createMoocCourseBlockQuiz: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post('/mooc-course-block-quiz', data)
}

export const createMoocCourseBlockQuizConnect: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post('/mooc-course-block-quiz/create-quiz-connect', data)
}

export const createManyMoocCourseBlockQuiz: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post('/mooc-course-block-quiz/create-many', data)

}

export const updateFileMoocCourseBlockQuiz: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post('/mooc-course-block-quiz/update-file', data)
}

export const postDuplicateQuiz: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post('/mooc-course-block-quiz/create-many', data)
}

export const getAllQuizByUnit: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-course-block-quiz/get-all-quiz-by-unit`, data);
};

export const getAllQuizBySurvey: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/survey-group-question/get-all`, data);
};


export const updateMoocCourseBlockQuiz: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-course-block-quiz?id=${data.id}`, data)
}

export const updateMoocCourseBlockQuizConnect: (data: Partial<any>) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-course-block-quiz/update-quiz-connect?id=${data.id}`, data)
}

export const addQuizToQuestionBank: (id: number, data: string) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.post(`/mooc-course-block-quiz/add-quiz-to-question-bank?idQuiz=${id}&bankCode=${data}`)
}

export const addUnitQuizFormBank: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-course-block-quiz/add-new-unit-quiz-from-bank`, data)
}

export const addUnitQuizSurveyFormBank: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/survey-plan/add-new-survey-quiz-from-bank`, data)
}

export const addSurveyToQuestionBank: (id: number, data: string) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.post(`/survey-plan/add-survey-quiz-to-bank?idQuiz=${id}&bankCode=${data}`)
}

export const deleteMoocCourseBlockQuiz: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-course-block-quiz?id=${id}`)
}

export const changeOrderNumberQuiz: (dataReq: Partial<object>) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post('/mooc-course-block-quiz/change-order-number', dataReq)
}

export const changeOrderGroupNumberQuiz: (dataReq: Partial<object>) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post('/mooc-course-block-quiz/change-group-order-number', dataReq)
}

export const addUnitBank: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/mooc-course-unit/add-unit-to-bank`, dataReq)
}

export const updateOrderInsideUnit: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/mooc-course-block-quiz/update-order-inside-unit`, dataReq)
}
