import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import {
  getMoocSurveyObjectsFilter,
  postMoocPublishSurvey,
  postMoocSurveyFilterListStudent,
} from "../../../../../service/survey-managment/mooc-survey-publish/mooc-survey-publish-api";
import {
  Button,
  Col,
  DatePicker,
  DatePickerProps,
  Form,
  Pagination,
  PaginationProps,
  Row,
  Select,
  Table,
  TableColumnsType,
  TimePicker,
  Typography,
  message,
} from "antd/lib";
import { useParams } from "react-router-dom";
import CustomCard from "../../../../../components/custom-card/CustomCard";
//import "./ConditionPublishSurvey.scss";
import dayjs, { Dayjs } from "dayjs";
import vi from "antd/es/date-picker/locale/vi_VN";
import { get } from "lodash";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Input, Spin } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import {
  getIndustry,
  getUniversity,
  getdataIndustryGroup,
} from "../../../../../service/common";

const validate = {
  startDate: {
    required: true,
    message: "Vui lòng chọn ngày xuất bản",
  },
  startTime: {
    required: true,
    message: "Vui lòng chọn giờ xuất bản",
  },
};

const locale: typeof vi = {
  ...vi,
  lang: {
    ...vi.lang,
    dateSelect: "Chọn ngày",
    placeholder: "Chọn ngày",
    timeSelect: "Chọn giờ",
  },
  timePickerLocale: {
    placeholder: "Chọn giờ",
  },
};

interface IListStudentFilter {
  id: number;
  studentName: string;
  university: string;
  email: string;
}

type DataTypeMoocSurveyObjectsFilter = {
  id?: number;
  surveyId?: number;
  filterOperator?: boolean;
  filterCriteria?: string;
  filterCondition?: number;
  filterFromValue?: string;
  filterToValue?: string;
  filterValue?: string;
  filterValueString?: string;
};

type DataProps = {
  // dataFilter: DataTypeMoocSurveyObjectsFilter[];
  submitRef: any;
  dataOptions: any;
  setDataOptions: any;
};

const ConditionPublishSurvey: FunctionComponent<DataProps> = ({
  submitRef,
  dataOptions,
  setDataOptions,
}) => {
  const { id } = useParams();
  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [listFilter, setListFilter] = useState<any[]>();
  // const [dataFilter, setDataFilter] = useState<DataTypeMoocSurveyObjectsFilter[]>([]);

  const dataFilter = useRef<DataTypeMoocSurveyObjectsFilter[]>([]);
  const dataUniversity = useRef<any[]>([]);
  const dataIndustry = useRef<any[]>([]);
  const dataIndustryGroup = useRef<any[]>([]);

  const [selectStartedDate, setStartedDate] = useState(
    get(dataOptions, "enrollStatedDate", "")
  );
  const [selectEndDate, setSelectEndDate] = useState();
  const [selectStartedTime, setStartedTime] = useState(
    dayjs(get(dataOptions, "enrollStatedDate", ""))
  );
  const [selectEndTime, setEndTime] = useState(
    dayjs(get(dataOptions, "enrollClosedDate", ""))
  );
  dayjs.extend(customParseFormat);
  dayjs.extend(customParseFormat);

  const [listFilterStudent, setListFilterStudent] = useState<
    IListStudentFilter[]
  >([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [viewDataTable, setViewDataTable] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;
    (async (id: any) => {
      try {
        await getMoocSurveyObjectsFilter(id)
          .then((res) => {
            // debugger;
            if (mounted) {
              dataFilter.current = res.data?.data?.filters;
            }
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });

        const response = await Promise.all([
          getUniversity(),
          getIndustry(),
          getdataIndustryGroup(),
        ]);
        if (mounted) {
          dataUniversity.current = response[0].data.data;
          dataIndustry.current = response[1].data.data;
          dataIndustryGroup.current = response[2].data.data;
        }
      } catch (error) {
        message.error("Có lỗi chưa xác định");
      }

      setListFilter(
        dataFilter.current.map((item, index) => {
          const filterOperator =
            index == 0 ? "" : item.filterCondition == 1 ? "Và" : "Hoặc";
          const filterCriteria =
            item.filterCriteria == "university_id"
              ? "Trường đại học"
              : item.filterCriteria == "industry_id"
              ? "Chuyên ngành"
              : "Khoa";
          const filterCondition =
            item.filterCondition == 1
              ? "Bằng"
              : item.filterCondition == 2
              ? "Gần đúng"
              : item.filterCondition == 3
              ? "Một trong những"
              : "Khác";
          const filterValue = item.filterValue?.split(",");
          return (
            <Row gutter={12} key={index}>
              <Col span={3}>
                {index == 0 ? null : (
                  <Form.Item>
                    <Select value={filterOperator} disabled></Select>
                  </Form.Item>
                )}
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Select
                    placeholder="Chọn tiêu chí lọc"
                    value={filterCriteria}
                    disabled
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Select
                    placeholder="Điều kiện lọc"
                    value={filterCondition}
                    disabled
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={11}>
                {dataFilter.current[index].filterCondition == 2 ? (
                  <Form.Item>
                    <Input value={filterValue} disabled />
                  </Form.Item>
                ) : dataFilter.current[index].filterCriteria ==
                  "university_id" ? (
                  <Form.Item>
                    <FormItemDropdown
                      modeDropdown="multiple"
                      value={item.filterValue?.split(",").map((x) => Number(x))}
                      options={dataUniversity.current}
                      allowClear={true}
                      disabled
                    />
                  </Form.Item>
                ) : dataFilter.current[index].filterCriteria ==
                  "industry_id" ? (
                  <Form.Item>
                    <FormItemDropdown
                      modeDropdown="multiple"
                      value={item.filterValue?.split(",").map((x) => Number(x))}
                      options={dataIndustryGroup.current}
                      allowClear={true}
                      disabled
                    />
                  </Form.Item>
                ) : dataFilter.current[index].filterCriteria ==
                  "industry_group_id" ? (
                  <Form.Item>
                    <FormItemDropdown
                      modeDropdown="multiple"
                      value={item.filterValue?.split(",").map((x) => Number(x))}
                      options={dataIndustryGroup.current}
                      allowClear={true}
                      disabled
                    />
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <FormItemDropdown
                      options={[]}
                      allowClear={true}
                      value={item.filterValue?.split(",")}
                      disabled
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          );
        })
      );
    })(id);

    return () => {
      mounted = false;
    };
  }, []);

  // Date time publish survey
  const handleChangeStartedDate: DatePickerProps<Dayjs[]>["onChange"] = (
    date,
    dateString
  ) => {
    setStartedDate(date);
  };

  const handleChangeStartedTime = (time: any) => {
    setStartedTime(time);
  };

  const handleChangeEndedTime = (time: any) => {
    setEndTime(time);
  };

  const handleChangeEndedDate = (date: any) => {
    setSelectEndDate(date);
  };

  // const disabledHours = () => {
  //     if (!selectStartedDate) return [];
  //     // If no date selected, all hours are available
  //     const currentDate = dayjs();
  //     const selectedDay = dayjs(selectStartedDate);
  //     if (selectedDay.isSame(currentDate, "day")) {
  //         // If selected date is today, disable past hours
  //         // @ts-ignore
  //         return [...Array(currentDate.hour()).keys()];
  //     }
  //     return [];
  // };

  // const disabledMinutes = () => {
  //     if (!selectStartedDate) return [];
  //     const currentDate = dayjs();
  //     const selectedDay = dayjs(selectStartedDate);
  //     if (selectedDay.isSame(currentDate, "day")) {
  //         // If selected date is today, disable past minutes
  //         // @ts-ignore
  //         return [...Array(currentDate.minute()).keys()];
  //     }
  //     return [];
  // };

  // const disableHoursEndDate = () => {
  //     if (!selectEndDate) return [];
  //     // If no date selected, all hours are available
  //     const currentDate = dayjs();
  //     const selectedDay = dayjs(selectEndDate);
  //     if (selectedDay.isSame(currentDate, "day")) {
  //         // If selected date is today, disable past hours
  //         // @ts-ignore
  //         return [...Array(currentDate.hour()).keys()];
  //     }
  //     return [];
  // };

  // const disabledMinutesEndDate = () => {
  //     if (!selectEndDate) return [];
  //     const currentDate = dayjs();
  //     const selectedDay = dayjs(selectEndDate);
  //     if (selectedDay.isSame(currentDate, "day")) {
  //         // If selected date is today, disable past minutes
  //         // @ts-ignore
  //         return [...Array(currentDate.minute()).keys()];
  //     }
  //     return [];
  // };

  const disableDays = (currentDate: any) => {
    if (!selectStartedDate)
      return currentDate && currentDate.isBefore(dayjs().subtract(1, "day"));
    if (selectStartedDate) {
      return currentDate && currentDate.isBefore(dayjs(selectStartedDate));
    }
  };

  useEffect(() => {
    // handleSearch()
    // if (dataOptions.dataFilter) {
    //     form.setFieldsValue({
    //         disabled: dataOptions.dataFilter.isAllowedHiddenSurveyObjects ?? false,
    //         endTime:
    //             dayjs(dataOptions.dataFilter.surveyClose)
    //                 .set("hour", dayjs(dataOptions.dataFilter.surveyClose).hour() - 7)
    //                 .set("minute", dayjs(dataOptions.dataFilter.surveyClose).minute())
    //                 .set("second", dayjs(dataOptions.dataFilter.surveyClose).second()) ?? null,
    //         enrollClosedDate: dataOptions.dataFilter.surveyClose ?? null,
    //         enrollStatedDate: dataOptions.dataFilter.surveyOpen ?? null,
    //         startTime:
    //             dayjs(dataOptions.dataFilter.surveyOpen)
    //                 .set("hour", dayjs(dataOptions.dataFilter.surveyOpen).hour() - 7)
    //                 .set("minute", dayjs(dataOptions.dataFilter.surveyOpen).minute())
    //                 .set("second", dayjs(dataOptions.dataFilter.surveyOpen).second()) ?? null,
    //     });
    // }
    if (dataOptions.dataFilter) {
      form.setFieldsValue({
        disabled: dataOptions.dataFilter.isAllowedHiddenSurveyObjects ?? false,
        endTime:
          dataOptions.dataFilter.surveyClose != null
            ? dayjs(dataOptions.dataFilter.surveyClose)
                .set(
                  "hour",
                  dayjs(dataOptions.dataFilter.surveyClose).hour() - 7
                )
                .set(
                  "minute",
                  dayjs(dataOptions.dataFilter.surveyClose).minute()
                )
                .set(
                  "second",
                  dayjs(dataOptions.dataFilter.surveyClose).second()
                )
            : null,
        enrollClosedDate: dataOptions.dataFilter.surveyClose ?? null,
        enrollStatedDate: dataOptions.dataFilter.surveyOpen ?? null,
        startTime:
          dataOptions.dataFilter.surveyOpen != null
            ? dayjs(dataOptions.dataFilter.surveyOpen)
                .set(
                  "hour",
                  dayjs(dataOptions.dataFilter.surveyOpen).hour() - 7
                )
                .set(
                  "minute",
                  dayjs(dataOptions.dataFilter.surveyOpen).minute()
                )
                .set(
                  "second",
                  dayjs(dataOptions.dataFilter.surveyOpen).second()
                )
            : null,
      });
    }
  }, [dataOptions]);

  // Define Column table
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2, "0");
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên đối tượng",
      sorter: true,
      width: 150,
      dataIndex: "student_name",
    },
    {
      title: "Trường đại học",
      sorter: true,
      width: 200,
      dataIndex: "university",
    },
    {
      title: "Email",
      sorter: true,
      width: 150,
      dataIndex: "email",
    },
  ];

  // Get list filter student
  const getListFilterStudentSurvey = async () => {
    // const dataFil = dataFilter.current.filter((item) => {
    //     return item.filterFromValue != undefined && item.filterFromValue != "";
    // });
    // const data = [...dataFil];
    // for (let i = 0; i < data.length; i++) {
    //     if (data[i].filterCondition == 2) {
    //         if (data[i].filterCriteria == "university_id") {
    //             data[i].filterCriteria = "u.name";
    //         }
    //         if (data[i].filterCriteria == "industry_id") {
    //             data[i].filterCriteria = "i.name";
    //         }
    //         if (data[i].filterCriteria == "industry_group_id") {
    //             data[i].filterCriteria = "g.name";
    //         }
    //     }
    // }

    const data = {
      keyword: "",
      pageNumber: pageNumber,
      pageSize: pageSize,
      filters: dataFilter.current,
    };

    for (let i = 0; i < data.filters.length; i++) {
      if (data.filters[i].filterCondition == 2) {
        if (data.filters[i].filterCriteria == "university_id") {
          data.filters[i].filterCriteria = "u.name";
        }
        if (data.filters[i].filterCriteria == "industry_id") {
          data.filters[i].filterCriteria = "i.name";
        }
        if (data.filters[i].filterCriteria == "industry_group_id") {
          data.filters[i].filterCriteria = "g.name";
        }
      }
    }

    console.log(data.filters);

    if (data.filters.length > 0) {
      try {
        await postMoocSurveyFilterListStudent(data).then((res) => {
          setTotalRecords(res.data.totalRecords);
          setListFilterStudent(res.data.data);
        });
      } catch (error) {
        console.log(error);
        message.error("Có lỗi chưa xác định");
      }
    } else {
      // console.log("No data");
      setListFilterStudent([]);
    }
  };

  useEffect(() => {
    getListFilterStudentSurvey();
  }, [pageNumber, pageSize]);

  const handleViewDataTable = () => {
    getListFilterStudentSurvey();
    setViewDataTable((prevState) => {
      return !prevState;
    });
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };

  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
      setTotalRecords(totalRecords);
    }
  };

  const onFinish = async (name: string, { forms, values }: any) => {
    const dataPost = {
      id: 1,
      surveyId: id,
      surveyOpen: values.startTime
        ? dayjs(values.enrollStatedDate)
            .set("hour", dayjs(values.startTime).hour())
            .set("minute", dayjs(values.startTime).minute())
        : null,
      surveyClose: values.endTime
        ? dayjs(values.enrollClosedDate)
            .set("hour", dayjs(values.endTime).hour())
            .set("minute", dayjs(values.endTime).minute())
        : null,
      filters: dataFilter,
    };

    try {
      setIsLoading(true);
      await postMoocPublishSurvey(dataPost)
        .then((res: any) => {
          if (res.data.statusCode == 200) {
            message.success(res.data.message);
          } else {
            message.error("Có lỗi chưa xác định.");
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định.");
        });
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      message.error("Có lỗi chưa xác định.");
    }
  };

  return (
    <div className="survey-managment-condition-publish">
      <Form.Provider onFormFinish={onFinish}>
        <Form form={form}>
          <div className="wrapper-settings">
            <section className="setting-course">
              <div className="form-settings">
                <CustomCard
                  title="Đối tượng khảo sát"
                  className="wrapper-settings--card form-setting--common"
                >
                  {listFilter}
                </CustomCard>

                <CustomCard
                  title="Thời gian khảo sát"
                  className="wrapper-settings--card"
                >
                  <div className="time-register--form">
                    <div className="form-select--time">
                      <Form.Item
                        name="enrollStatedDate"
                        label="Ngày mở khảo sát"
                        className="form-item--date"
                        getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
                        dependencies={["enrollClosedDate"]}
                        rules={[
                          validate.startTime,
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const futureDate = getFieldValue("endTime");
                              const startDate =
                                getFieldValue("enrollStatedDate");
                              const closeDate =
                                getFieldValue("enrollClosedDate");

                              if (
                                dayjs(startDate).isSame(closeDate) &&
                                dayjs(value).isAfter(futureDate) &&
                                futureDate
                              ) {
                                return Promise.reject(
                                  new Error(
                                    "Thời gian mở khảo sát không được sau thời gian đóng khảo sát"
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          disabledDate={(current) =>
                            current.isBefore(dayjs().subtract(1, "day"))
                          }
                          locale={locale}
                          format={"DD/MM/YYYY"}
                          onChange={handleChangeStartedDate}
                        />
                      </Form.Item>
                      <Form.Item
                        name="startTime"
                        getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
                        rules={[validate.startTime]}
                        className="form-item--time"
                      >
                        <TimePicker
                          locale={locale}
                          value={
                            selectStartedDate &&
                            dayjs(selectStartedDate)
                              .set("hour", 0)
                              .set("minute", 0)
                              .toDate()
                          }
                          onChange={handleChangeStartedTime}
                        />
                      </Form.Item>
                    </div>
                    <div className="form-select--time">
                      <Form.Item
                        name="enrollClosedDate"
                        label="Ngày đóng khảo sát"
                        className="form-item--date"
                        getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
                      >
                        <DatePicker
                          disabledDate={(current) => disableDays(current)}
                          onChange={handleChangeEndedDate}
                          format={"DD/MM/YYYY"}
                        />
                      </Form.Item>
                      <Form.Item
                        name="endTime"
                        className="form-item--time"
                        getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              const futureTime = getFieldValue("startTime");
                              const startDate =
                                getFieldValue("enrollStatedDate");
                              const closeDate =
                                getFieldValue("enrollClosedDate");

                              if (
                                dayjs(startDate).isSame(closeDate) &&
                                dayjs(value).isBefore(futureTime) &&
                                futureTime
                              ) {
                                return Promise.reject(
                                  new Error(
                                    "Thời gian mở khảo sát không được sau thời gian đóng khảo sát"
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <TimePicker
                          onChange={handleChangeEndedTime}
                          locale={locale}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </CustomCard>

                <div className="group-button">
                  <Button
                    className="ant-btn-primary"
                    onClick={handleViewDataTable}
                  >
                    <Typography.Text>
                      Lấy danh sách đối tượng khảo sát
                    </Typography.Text>
                  </Button>
                </div>

                {!viewDataTable ? (
                  ""
                ) : (
                  <>
                    <div
                      className="mb-1"
                      style={{
                        width: "100%",
                        fontSize: "15px",
                        fontWeight: "700",
                      }}
                    >
                      Đối tượng khảo sát ({totalRecords})
                    </div>
                    <CustomCard className="wrapper-settings--card form-setting--common data-table">
                      <Table
                        size="middle"
                        pagination={false}
                        columns={dataColumns}
                        dataSource={listFilterStudent}
                        rowKey={"id"}
                        rowSelection={undefined}
                      />
                      <Pagination
                        total={totalRecords}
                        showSizeChanger
                        pageSize={pageSize}
                        current={pageNumber}
                        className="pagination-table"
                        onChange={onChange}
                        itemRender={itemRender}
                        locale={{
                          items_per_page: "/ trang",
                          jump_to: "Đi đến trang",
                          page: "",
                        }}
                        showQuickJumper
                        showTotal={(total) => `Tổng số ${total} bản ghi`}
                      />
                    </CustomCard>
                  </>
                )}
                <button
                  ref={submitRef}
                  onClick={() => form?.submit()}
                  style={{ display: "none" }}
                />
              </div>
            </section>
          </div>
          <Spin spinning={isLoading} fullscreen />
        </Form>
      </Form.Provider>
    </div>
  );
};

export default ConditionPublishSurvey;
