import React, {
  FunctionComponent,
  Key,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Transfer,
  TransferProps,
  Typography,
} from "antd";
import FormInput from "../../../../components/form-input/FormInput";
import FormRadio from "../../../../components/form-radio/FormRadio";
import { dataStatus } from "../../constants/data";
import { FormInstance } from "antd/lib";
import {
    getListUsersExist,
  getPermissionsRoles,
  updatePermissionsRoles,
  updatePermissionsUsers,
} from "../../../../service/role-management/roleManagementApi";
import { get, isEmpty, toInteger } from "lodash";
import { TransferDirection, TransferItem } from "antd/es/transfer";

type RecordType = {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
};


type RoleAccountProps = {
  form?: FormInstance;
  checkEdit?: boolean;
  checkIsFormEdit?: boolean;
  isOpened?: boolean;
  dataEdit?: any;
  resetPermissions?: any;
};


const RoleAccount: FunctionComponent<RoleAccountProps> = ({
  checkEdit,
  checkIsFormEdit,
  isOpened,
  dataEdit,
}) => {
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [dataTransfer, setDataTransfer] = useState<RecordType[]>([]);
  const [permissionsRole, setPermissionsRole] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState(100);
  const [statusExists, setStatusExists] = useState(false);
  const [valueSearchLeft, setValueSearchLeft] = useState("");
  const [allPermissions, setAllPermissions] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const [isCreating, setIsCreating] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectRight, setSelectRight] = useState('')
  const [selectLeft, setSelectLeft] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const fetchData = (dataSearch?: any) => {
    getListUsersExist(dataSearch).then((res) => {
      let { data } = res.data.data;
      data = mergeArraysDistinctByKey(data, allPermissions);
      setAllPermissions([...data]);
    });
  };

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  const accountType = ['Người dùng cấp Bộ', 'Quản trị cơ sở', 'Giảng viên'];

  const handleTypeAccount = (dir:string) =>{
    let dirSelect = ''
    if(dir === 'left') dirSelect = selectLeft
    if(dir === 'right') dirSelect = selectRight
    switch (dirSelect) {
      case 'Người dùng cấp Bộ':
        return 'is_qtcs';
      case 'Quản trị cơ sở':
        return 'is_giang_vien';
      case 'Giảng viên':
        return 'is_sv';
      default:
        return '';
    }
  }
  

  useEffect(() => {
    if (checkIsFormEdit && dataEdit) {
      setStatusExists(true);
    }
  }, [dataEdit, statusExists]);

  useEffect(() => {
    if (!isOpened) {
      return;
    }
   
    if (!id) {
      setSelectedPermissions([]);
      setTargetKeys([]);
      setIsCreating(true);
      return;
    }
    setIsCreating(false);
    getListUsersExist({ id: toInteger(id), isExist: true }).then((res) => {
      let { data } = res.data.data;
      setSelectedPermissions(data);
    });
    fetchData({ pageSize: pageSize, keyword: "", typeAccount: "" });
  }, [isOpened]);

  useEffect(()=>{
    getListUsersExist({ id: toInteger(id), isExist: true, typeAccount: handleTypeAccount('right') }).then((res) => {
      let { data } = res.data.data;
      setSelectedPermissions(data);
    });
  }, [pageSize, selectRight])

  useEffect(() => {
    fetchData({ pageSize: pageSize, keyword: valueSearchLeft, typeAccount: handleTypeAccount('left') });
  }, [pageSize, valueSearchLeft, selectLeft]);

  function mergeArraysDistinctByKey(arr1: any[], arr2: any[]) {
    const combined = [...arr1, ...arr2];
    const keySet = new Set();

    return combined.filter((item) => {
      if (keySet.has(item["id"])) {
        return false;
      }
      keySet.add(item["id"]);
      return true;
    });
  }

  const getNewData = () => {
    const newData: RecordType[] = mergeArraysDistinctByKey(
      allPermissions,
      selectedPermissions
    ).map((role: any) => {
      return {
        key: role.id.toString(),
        title: role.fullName || '(Không có tên)',
        description: role.fullName|| '(Không có tên)',
        chosen: statusExists,
      };
    });
    setDataTransfer(newData);
    if (!isCreating) {
      const targetKey = selectedPermissions.map((permission: any) =>
        String(permission.id)
      );
      setTargetKeys([...targetKey]);
    }
  };

  useEffect(() => {
    getNewData();
  }, [permissionsRole, statusExists, selectedPermissions, allPermissions]);

  const handleChange: TransferProps["onChange"] = (
    newTargetKeys,
    direction
  ) => {
    setTargetKeys(newTargetKeys);
    if (direction === "right" && dataEdit) {
        updatePermissionsUsers(get(dataEdit, "id", ""), {
        isAdd: true,
        nguoiDungs: selectedKeys,
      }).then((res) => {
        const { data, statusCode } = res.data;
        if (statusCode === 200) {
          message.success(get(data, "message", "Cập nhật gán tài khoản thành công"));
          getListUsersExist({ id: toInteger(id), isExist: true }).then((res) => {
            let { data } = res.data.data;
            setSelectedPermissions(data);
          });
        }
      });
    }
    if (direction === "left" && dataEdit) {
        updatePermissionsUsers(get(dataEdit, "id", ""), {
        isAdd: false,
        nguoiDungs: selectedKeys,
      }).then((res) => {
        const { data, statusCode } = res.data;
        if (statusCode === 200) {
          message.success(get(data, "message", "Cập nhật gán tài khoản thành công"));
          getListUsersExist({ id: toInteger(id), isExist: true }).then((res) => {
            let { data } = res.data.data;
            setSelectedPermissions(data);
          });
        }
      });
    }
  };

  const renderItem = (item: RecordType) => {
    const customLabel = (
      <span className="custom-item">
          {item.title}
        </span>
    );
    return {
      label: customLabel, // for displayed item
      value: item.title, // for title and filter matching
    };
  };

  const filterOption = (inputValue: string, option: RecordType) => {
    return (
      option.description
        .toLowerCase()
        .indexOf(inputValue.toLowerCase().trim()) > -1
    );
  };

  const onSearch = (direction: TransferDirection, value: string) => {
    setSearchValue(value);
  };

  const handleSearch: TransferProps['onSearch'] = (dir, value) => {
    if (dir === 'left') {
      setValueSearchLeft(value.trim());
    }
  };

  const onCategoryChange = (
    direction: TransferDirection,
    value: string | null
  ) => {
    setSelectedCategory(value);
  };



  const renderDropdown = () => (
    <Select
    allowClear
    disabled={checkEdit}
    placeholder="Loại tài khoản"
    style={{ width: 140 }}
    options={accountType.map((item) => ({ label: item, value: item }))}
    onChange={(value:string)=>setSelectLeft(value)}
  />
  );

  const renderDropdownRight = () => (
    <Select
    disabled={checkEdit}
    allowClear
    placeholder="Loại tài khoản"
    style={{ width: 140 }}
    options={accountType.map((item) => ({ label: item, value: item }))}
    onChange={(value:string)=>setSelectRight(value)}
  />
  );

  const renderSearchBarWithDropdownLeft = () => (
    <div style={{ width: 200, display: 'flex', alignItems: 'center', padding: '8px 12px' }}>
      {renderDropdown()}
    </div>
  )

  const renderSearchBarWithDropdownRight = () => (
    <div style={{ width: 200, display: 'flex', alignItems: 'center', padding: '8px 12px' }}>
      {renderDropdownRight()}
    </div>
  )

  const loadMore = () => {
    setPageSize(pageSize + 100);
  };


  const renderFooter: TransferProps["footer"] = (_, info) => {
    if (info?.direction === "left") {
      return (
        <Button
          size="small"
          style={{ float: "left", margin: 5 }}
          onClick={loadMore}
        >
          Tải thêm
        </Button>
      );
    }
  };

  return (
    <>
      <div className="list-roles">
        <div className="list-roles--title">
          <Typography.Title level={5}>
            Danh sách tài khoản của vai trò
          </Typography.Title>
        </div>
        <div className="roles-transfer">
          <Form.Item name="nguoiDungs">
            <Transfer
              listStyle={{ width: "47%", height: 550 }}
              dataSource={dataTransfer}
                showSearch
              disabled={checkEdit}
              selectAllLabels={[
                ({ totalCount }) => (
                  <span>{totalCount} tài khoản</span>
                ),
                ({ totalCount }) => (
                  <span>{totalCount} tài khoản</span>
                ),
              ]}
              footer={renderFooter}
              selectedKeys={selectedKeys as any}
                locale={{searchPlaceholder: "Nhập từ khóa"}}
              filterOption={filterOption}
                onSearch={handleSearch}
              titles={[renderSearchBarWithDropdownLeft(), renderSearchBarWithDropdownRight()]}
              onChange={handleChange}
              onSelectChange={(
                sourceSelectedKeys: Key[],
                targetSelectedKeys: Key[]
              ) => {
                setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
              }}
              render={renderItem}
              targetKeys={targetKeys as string[]}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default RoleAccount;
