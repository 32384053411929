// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gc-style .wrapper-filter {
  width: 100%;
  background: #FFFFFF;
  overflow: hidden;
  max-height: 0;
  margin-left: -1.5%;
  transition: max-height 0.3s ease-in-out; }
  .gc-style .wrapper-filter .ant-form-item {
    margin-bottom: 0; }

.gc-style .wrapper-filter.open {
  max-height: 100%; }

.gc-style .group-btn--filter {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin-left: 15px; }

.gc-style .created_date_filter {
  width: 100%; }

.gc-style .itemDelete {
  width: 14px;
  padding: 0 0;
  margin-left: 5px; }

@media screen and (min-width: 996px) {
  .gc-style .filter-btn {
    margin-right: 10px; } }

@media screen and (max-width: 768px) {
  .gc-style .filter-btn {
    width: 35%; }
  .gc-style .clear-filter-btn {
    width: 60%; }
  .gc-style .group-btn--filter {
    justify-content: space-between; } }

@media screen and (max-width: 480px) {
  .gc-style .filter-btn,
  .gc-style .clear-filter-btn {
    width: 50%; }
  .gc-style .group-btn--filter {
    justify-content: space-around; }
  .gc-style .wrapper-filter {
    height: 200px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/planning-course-construction/list-planning-course-construction/components/filter-group/filter-group.scss"],"names":[],"mappings":"AAAA;EAEI,WAAiB;EACjB,mBAAoB;EACpB,gBAAmB;EACnB,aAAc;EACd,kBAAkB;EAClB,uCAAwC,EAAA;EAP5C;IAUM,gBAAgB,EAAA;;AAVtB;EAeI,gBAAgB,EAAA;;AAfpB;EAmBI,aAAqB;EACrB,yBAAyB;EACzB,UAAkB;EAClB,iBAAqB,EAAA;;AAtBzB;EA0BI,WAAW,EAAA;;AA1Bf;EA8BI,WAAiB;EACjB,YAAgB;EAChB,gBAAgB,EAAA;;AAGlB;EAnCF;IAqCM,kBAAkB,EAAA,EACnB;;AAIH;EA1CF;IA4CM,UAAU,EAAA;EA5ChB;IAgDM,UAAU,EAAA;EAhDhB;IAoDM,8BAA8B,EAAA,EAE/B;;AAGH;EAzDF;;IA6DM,UAAU,EAAA;EA7DhB;IAiEM,6BAA6B,EAAA;EAjEnC;IAqEM,aAAa,EAAA,EACd","sourcesContent":[".gc-style {\n  .wrapper-filter {\n    width      : 100%;\n    background : #FFFFFF;\n    overflow   : hidden;\n    max-height : 0;\n    margin-left: -1.5%;\n    transition : max-height 0.3s ease-in-out;\n\n    .ant-form-item {\n      margin-bottom: 0;\n    }\n  }\n\n  .wrapper-filter.open {\n    max-height: 100%;\n  }\n\n  .group-btn--filter {\n    display        : flex;\n    justify-content: flex-end;\n    padding        : 0;\n    margin-left    : 15px;\n  }\n\n  .created_date_filter {\n    width: 100%;\n  }\n\n  .itemDelete {\n    width      : 14px;\n    padding    : 0 0;\n    margin-left: 5px;\n  }\n\n  @media screen and (min-width: 996px) {\n    .filter-btn {\n      margin-right: 10px;\n    }\n  }\n\n\n  @media screen and (max-width: 768px) {\n    .filter-btn {\n      width: 35%;\n    }\n\n    .clear-filter-btn {\n      width: 60%;\n    }\n\n    .group-btn--filter {\n      justify-content: space-between;\n\n    }\n  }\n\n  @media screen and (max-width: 480px) {\n\n    .filter-btn,\n    .clear-filter-btn {\n      width: 50%;\n    }\n\n    .group-btn--filter {\n      justify-content: space-around;\n    }\n\n    .wrapper-filter {\n      height: 200px;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
