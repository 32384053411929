import React, { FunctionComponent } from "react";
import { Col, Form, message, Row } from "antd";
import { FormInstance } from "antd/lib";
import { get, isEmpty } from "lodash";
import "./FormCreateSpecializedGroup.scss";
import FormInput from "../../../../../../components/form-input/FormInput";

const validations = {
  organizationName: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length < 6) {
        return Promise.reject(
          new Error("Tên tổ chuyên môn phải trên 6 ký tự.")
        );
      }
      if (value && value.length > 255) {
        return Promise.reject(
          new Error("Tên tổ chuyên môn không vượt quá 255 ký tự.")
        );
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên tổ chuyên môn."));
      }
      return Promise.resolve();
    },
  },
  organizationCode: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length < 2) {
        return Promise.reject(new Error("Mã tổ chuyên môn phải trên 2 ký tự."));
      }
      if (value && value.length > 25) {
        return Promise.reject(
          new Error("Mã tổ chuyên môn không vượt quá 25 ký tự.")
        );
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập mã tổ chuyên môn."));
      }
      return Promise.resolve();
    },
  },
};

type FormCreateSpecializedGroupProps = {
  form: FormInstance;
  onFinish?: any;
  id?: any;
};

const FormCreateSpecializedGroup: FunctionComponent<
  FormCreateSpecializedGroupProps
> = ({ form, onFinish }) => {
  return (
    <div className="course-plan-managment-create-speialized">
      <Form
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        name="createSpecializedGroup"
        initialValues={{
          ["active"]: true,
        }}
      >
        <Row gutter={12}>
          <Col span={24} className="colItem">
            <Form.Item
              name="organizationName"
              rules={[validations.organizationName]}
              label="Tên tổ chuyên môn"
            >
              <FormInput placeholder="Nhập tên tổ chuyên môn" />
            </Form.Item>
            <span className="label-down-text">
              Tên hiển thị công khai tổ chuyên môn
            </span>
          </Col>
          <Col span={24} className="colItem">
            <Form.Item
              name="organizationCode"
              rules={[validations.organizationCode]}
              label="Mã tổ chuyên môn"
            >
              <FormInput placeholder="Nhập mã tổ chuyên môn" />
            </Form.Item>
            <span className="label-down-text">
              Định danh duy nhất xác định tổ chuyên môn
            </span>
          </Col>
          <Col span={24}>
            <Form.Item name="organizationRemark" label="Ghi chú">
              <FormInput placeholder="Nhập ghi chú (nếu có)" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormCreateSpecializedGroup;
