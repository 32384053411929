import { Col, Form, Row, Spin, Switch, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import FormInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { get } from "lodash";

const validations = {
	name: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên giá trị không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên giá trị"))
			}
			return Promise.resolve()
		}
	},
	code:
	{
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã giá trị không vượt quá 512 ký tự"))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã giá trị"))
			}
			return Promise.resolve()
		}
	},
	categoryId: 
	{
		required: true,
		validator: (_: unknown, value: string) => {
			if (!value) {
				return Promise.reject(new Error("Vui lòng chọn danh mục"))
			}
			return Promise.resolve()
		}
	}
}

type FormCreatePortfolioValueProps = {
	form: FormInstance,
	onFinish?: any,
	dataOptions?: any,
	loadingForm: boolean,
}

const FormCreatePortfolioValue: FunctionComponent<FormCreatePortfolioValueProps> = ({ form, onFinish, dataOptions, loadingForm }) => {
    return (
        <>
            <Form layout={"vertical"} form={form} onFinish={onFinish} name="createPortfolioValue" initialValues={{ status : true}}>
				{loadingForm ?
					<div className="statistic-course-content statistic-course-loading">
						<Spin size="large" />
					</div> 
					:
					<Row gutter={[16,16]}>
						<Col span={12}>
							<Form.Item name="name" rules={[validations.name]} label="Tên giá trị">
								<FormInput placeholder="Nhập tên giá trị" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="code" label="Mã giá trị" rules={[validations.code]}>
								<FormInput placeholder="Nhập mã giá trị" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="portfolioId" label="Danh mục" rules={[validations.categoryId]}>
								<FormItemDropdown options={get(dataOptions, 'dataPortfolio', '')} allowClear={true} placeholder="Chọn danh mục" />
							</Form.Item>
						</Col>
						<Col span={24}>
							<Form.Item name="cateDad" label="Giá trị cha">
								<FormItemDropdown options={get(dataOptions, 'dataPortfolioValue', '')} allowClear={true} placeholder="Chọn giá trị cha" />
							</Form.Item>
						</Col>
						<Col span={24} style={{display:"flex", justifyContent:"space-between"}}>
							<span>Trạng thái</span>
							<Form.Item name="status">
								<Switch defaultChecked />
							</Form.Item>
						</Col>
					</Row>
				}
			</Form>
        </>
    )
}

export default FormCreatePortfolioValue;