import { Checkbox, Typography } from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import CommonModal from "../../../../components/modal/common";
import {
  createLearningTrends,
  getLearningTrendByGroup,
} from "../../../../service/prediction-model";

const { Text, Title } = Typography;
interface IProps {
  isOpenCreate: boolean;
  clearModal: any;
  handleNotice: any;
  handleRefresh: () => void;
  isRefresh: boolean;
}

const ModalGroupInput = ({
  isOpenCreate,
  clearModal,
  handleNotice,
  handleRefresh,
  isRefresh, 
}: IProps) => {
  const [dataInput, setDataInput] = useState<any>({});
  const [dataOption, setDataOption] = useState<any>([]);

  const fetchOptionData = async () => {
    try {
      const response = await getLearningTrendByGroup("1");
      const { data } = response.data;
      setDataOption(data?.mooc_learning_trends_categories);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchOptionData();
  }, [isRefresh]);
  const onChangeSelect = (e: any) => {
    setDataInput({
      ...dataInput,
      [e.target.name]: e.target.checked,
    });
  };

  const handleCreate = async () => {
    const fields = [];

    for (const key in dataInput) {
      if (dataInput[key] === true) {
        fields.push(key);
      }
    }
    const param = {
      group_id: 1,
      fields: fields,
    };
    if (isEmpty(param?.fields)) {
      handleNotice(
        "error",
        "Lưu thông tin thất bại, phải chọn ít nhất 1 trường"
      );
    } else {
      try {
        await createLearningTrends(param);
        handleNotice("success", "Lưu thông tin thành công");
        handleRefresh();
        setDataInput({});
      } catch (error) {
        console.log(error);
      }
    }
    clearModal();
  };

  const handleCancel = () => {
    clearModal();
    setDataInput({});
  };

  return (
    <CommonModal
      open={isOpenCreate}
      title={"Tạo nhóm nội dung đầu vào"}
      desc={
        <div>
          {dataOption?.map((data: any) => (
            <div className="py-4 border-b">
              <Title level={5}>{data?.name}</Title>
              <div className="max-h-[200px] overflow-auto">
                {data?.mooc_learning_trends_fields?.map((value: any) => (
                  <Checkbox
                    className="w-full m-0"
                    checked={dataInput[value?.id]}
                    name={value?.id}
                    onChange={onChangeSelect}
                    disabled={!isEmpty(value?.mooc_learning_trends_data)}
                  >
                    {value?.name}
                  </Checkbox>
                ))}
              </div>
            </div>
          ))}
        </div>
      }
      closeIcon={true}
      okText={"Lưu"}
      cancelText={"Huỷ"}
      icon={""}
      confirm={handleCreate}
      closeModal={handleCancel}
      className="!w-[400px]"
    />
  );
};

export default ModalGroupInput;
