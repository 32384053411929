import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Form,
  Layout,
  message,
  TableColumnsType,
  TableProps,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { formatDateToIsoStringHHss, formatDateV2 } from "../../../utils/converDate";
import TableData from "../../../components/table-data/TableData";
import "../../../styles/_variables.scss";

import {
  DetailTypeAuthUser,
} from "../../../types/mooc-share-all";
import {
  DataTypeMoocActionHistory,
  FilterDataMoocActionHistory,
} from "../../../types/course-plan-managment/mooc-action-history";
import {
  postMoocActionHistorySearch,
  getLookupAuthUser,
} from "../../../service/course-plan-managment/mooc-action-history/mooc-action-history-api";
import React from "react";
import moment from "moment";


type OrganizationActionHistoryProps = {
  organizationId?: any,
};

const OrganizationActionHistoryList: FunctionComponent<OrganizationActionHistoryProps> = ({ organizationId }) => {
  const [listMoocActionHistory, setListMoocActionHistory] = useState<DataTypeMoocActionHistory[]>([]);
  const [filterData, setFilterData] = useState<FilterDataMoocActionHistory>({});
  const [lstUserId, setLstUserId] = useState<DetailTypeAuthUser[]>([]);
  const getListLstUserId = async () => {
    try {
      setLoading(true);
      await getLookupAuthUser().then((res) => {
        const { data } = res.data;
        setLstUserId(data.map((item: any) => {
          if (!('name' in item)) item['name'] = item['id'];
          return item;
        }));
      })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    getListLstUserId();
  }, []);

  const dataColumns: TableColumnsType<DataTypeMoocActionHistory> = [
    {
      title: "STT",
      width: "5%",
      dataIndex: "id",
      render: (_: any, record, index: number) => {
        return <span className="table-order-header">{index + 1}</span>;
      },
    },
    {
      title: "Người thao tác",
      dataIndex: "user_id",
      key: "userId",
      width: "25%",
      sorter: {
        compare: (a, b) => 0 // compare: (a, b) => a.userId.length - b.userId.length
      },
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.username}</span>
        );
      },
    },
    {
      title: "Thời gian",
      dataIndex: "logged_time",
      key: "loggedTime",
      width: "20%",
      sorter: {
        compare: (a, b) => 0 // compare: (a, b) => a.loggedTime.length - b.loggedTime.length
      },
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.loggerTime ? moment(record?.loggerTime).format('HH:mm:ss DD/MM/YYYY') : null}</span>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action_detail",
      key: "actionDetail",
      sorter: {
        compare: (a, b) => 0 // compare: (a, b) => a.actionDetail.length - b.actionDetail.length
      },
      render: (_: any, record, index: number) => {
        const listAction = record?.actionDetail.split("-")
        return (
          <div>
            <span className="table-order-header">{listAction[0]}</span>
            <br />
            {
              listAction[1]?.split("*").map(item => (
                <React.Fragment key={item}>
                  <span style={{ marginLeft: "12px" }}>&#8226; {item}</span>
                  <br />
                </React.Fragment>
              ))
            }
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const getListData = () => {
    setLoading(true);

    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      id: !isEmpty(filterData.id)
        ? filterData.id
        : null,
      userId: !isEmpty(filterData.userId)
        ? filterData.userId
        : null,
      loggedTime: !isEmpty(filterData.loggerTime)
        ? filterData.loggerTime
        : null,
      loggedTimeFrom: !isEmpty(filterData.loggedTimeFromTo?.[0])
        ? filterData.loggedTimeFromTo?.[0].toString()
        : null,
      loggedTimeTo: !isEmpty(filterData.loggedTimeFromTo?.[1])
        ? filterData.loggedTimeFromTo?.[1].toString()
        : null,
      userIp: !isEmpty(filterData.userIp)
        ? filterData.userIp
        : null,
      actionDetail: !isEmpty(filterData.actionDetail)
        ? filterData.actionDetail
        : null,
      createdDate: !isEmpty(filterData.createdDate)
        ? filterData.createdDate
        : null,
      createdDateFrom: !isEmpty(filterData.createdDateFromTo?.[0])
        ? filterData.createdDateFromTo?.[0].toString()
        : null,
      createdDateTo: !isEmpty(filterData.createdDateFromTo?.[1])
        ? filterData.createdDateFromTo?.[1].toString()
        : null,
      updatedDate: !isEmpty(filterData.updatedDate)
        ? filterData.updatedDate
        : null,
      updatedDateFrom: !isEmpty(filterData.updatedDateFromTo?.[0])
        ? filterData.updatedDateFromTo?.[0].toString()
        : null,
      updatedDateTo: !isEmpty(filterData.updatedDateFromTo?.[1])
        ? filterData.updatedDateFromTo?.[1].toString()
        : null,
      moocActionHistoryTypes: !isEmpty(filterData.moocActionHistoryTypes)
        ? filterData.moocActionHistoryTypes
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
      organizationId: Number(organizationId),
    };
    console.log(data);
    postMoocActionHistorySearch(data)
      .then((res) => {
        console.log(res.data.data);
        setListMoocActionHistory(res.data.data);
        setTotalRecords(res.data.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
  };

  // common state variables, no change
  const [tab, setTab] = useState(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const randomCode = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16).replace("-", "");
      }
    );
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider>
        <div className="tabs-heading">
          <TableData
            dataTable={listMoocActionHistory}
            dataColumns={newColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            // scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default OrganizationActionHistoryList;


