import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Form,
  Layout,
  PaginationProps,
  Table,
  TableColumnsType,
} from "antd";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getNotificationHistory } from "../../../service/notifications";
import { toNumber } from "lodash";
import { NotifyHistory } from "../../../types/notifications";
import { DEFAULT_PAGE_NUM, DEFAULT_PAGE_SIZE } from "../../../constants";
import { routesConfig } from "../../../config/routes";

function NotificationHistory() {
  const navigate = useNavigate();
  const params = useParams();
  const notificationId = toNumber(params?.id);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [history, setHistory] = useState<{
    notificationHistories: NotifyHistory[];
    total: number;
  }>();

  useEffect(() => {
    const getHistories = async () => {
      if (notificationId) {
        try {
          setLoading(true);
          const res = await getNotificationHistory({
            id: notificationId,
            page: pageNum,
            size: pageSize,
          });
          setHistory(res.data.data);
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }
    };
    getHistories();
  }, [notificationId, pageNum, pageSize]);

  const handleChangePaginate = (page: number, size: number) => {
    setPageNum(page);
    setPageSize(size);
  };

  const breadcrumb = [
    {
      title: <Link to={routesConfig.notifications}>Danh sách thông báo</Link>,
    },
    {
      title: "Lịch sử hoạt động",
    },
    {
      title: notificationId,
    },
  ];

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: "5%",
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Thời gian",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Hoạt động",
      dataIndex: "activity",
      key: "activity",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const renderAction = (action: number) => {
    switch (action) {
      case 1:
        return "Khởi tạo";
      case 2:
        return "Xem";
      case 3:
        return "Chỉnh sửa";
      case 4:
        return "Gửi thông báo";
      case 5:
        return "Thu hồi";
      case 6:
        return "Khởi tạo lại";
      default:
        return "Khởi tạo";
    }
  };

  const data = history?.notificationHistories?.map(
    (item: NotifyHistory, index: number) => ({
      key: item?.id,
      id: item?.id,
      time: moment(item?.createdDate).format("HH:mm:ss - DD/MM/YYYY"),
      email: item?.creatorEmail,
      activity: renderAction(item?.action),
      numericalOrder:
        pageSize && pageNum ? pageSize * (pageNum - 1) + index + 1 : index + 1,
    })
  );

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="notifications-page w-full">
          <div className="notifications-page-header w-full">
            <Breadcrumb items={breadcrumb} className="mb-1" />
            <div className="flex justify-between align-center">
              <div className="flex gap-8 align-center">
                <ArrowLeftOutlined
                  className="arrowleft-icon"
                  onClick={() => navigate(routesConfig.notifications)}
                />{" "}
                <h3 className="page-title">
                  Lịch sử hoạt động {notificationId}
                </h3>
              </div>
            </div>
          </div>

          <div className="notifications-content">
            <Table
              loading={loading}
              dataSource={data}
              columns={dataColumns}
              className="pagination-table"
              pagination={{
                total: history?.total,
                pageSize: pageSize,
                current: pageNum,
                itemRender: itemRender,
                showSizeChanger: true,
                onChange: handleChangePaginate,
                locale: {
                  items_per_page: "/ trang",
                  jump_to: "Đi đến trang",
                  page: "",
                },
                showQuickJumper: true,
                showTotal: (total: number) => `Tổng số ${total} bản ghi`,
              }}
            />
          </div>
        </div>
      </Form.Provider>
    </Layout>
  );
}

export default NotificationHistory;
