import React from "react";
import { Checkbox, Col, Row } from "antd";
import {
  QuizAnsProps,
  ResultDetail,
} from "../../../../../../types/scoring-course";
import { isArray } from "lodash";

function ChooseMultiImage({ data, showCorrect = true, result }: QuizAnsProps) {
  const currentAnsOfUser = result?.results[0];

  const renderTextQuiz = (isTrueOption: boolean, uuid: string, type = "") => {
    if (showCorrect) {
      // show result in single menu
      if (isTrueOption)
        return type === "wrap" ? "active-quiz-correct" : "text-result";
      return "";
    } else {
      // show answers of current user
      if (isArray(currentAnsOfUser)) {
        const currentAns = currentAnsOfUser?.find(
          (item: ResultDetail) => item.uuid === uuid
        );
        if (
          currentAns &&
          currentAns?.uuid === uuid &&
          currentAns?.isTrue === true
        )
          return (type = "wrap" ? "active-quiz-correct" : "text-result");
        if (
          currentAns &&
          currentAns?.uuid === uuid &&
          currentAns?.isTrue === false
        )
          return type === "wrap" ? "active-quiz-wrong" : "text-danger";
      }
      return "";
    }
  };

  const renderCheckAns = (isTrueOption: boolean, uuid: string) => {
    if (showCorrect) {
      if (isTrueOption) return true;
      return false;
    } else {
      // show answers of current user

      if (isArray(currentAnsOfUser)) {
        const currentAns = currentAnsOfUser?.find(
          (item: ResultDetail) => item.uuid === uuid
        );
        if (currentAns && currentAns?.uuid === uuid) return true;
        return false;
      }

      return false;
    }
  };

  const renderCheckStyle = (uuid: string) => {
    if (showCorrect) return "checkbox-correct";
    else {
      // show answers of current user
      if (isArray(currentAnsOfUser)) {
        const currentAns = currentAnsOfUser?.find(
          (item: ResultDetail) => item.uuid === uuid
        );
        if (
          currentAns &&
          currentAns?.uuid === uuid &&
          currentAns?.isTrue === false
        )
          return "checkbox-danger";
      }
      return "";
    }
  };

  return (
    <div className={showCorrect ? "checkbox-correct" : ""}>
      <Row gutter={[32, 32]}>
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: number) => (
            <Col xs={24} md={12} key={quiz.uuid + index}>
              <div
                className={`image-quiz-wrap ${renderTextQuiz(
                  quiz?.isTrue,
                  quiz?.uuid,
                  "wrap"
                )}`}
              >
                <div>
                  <Checkbox
                    className={`${renderCheckStyle(quiz?.uuid)}`}
                    id={`${index}`}
                    disabled={true}
                    checked={renderCheckAns(quiz?.isTrue, quiz?.uuid)}
                  >
                    <span
                      className={`${renderTextQuiz(quiz?.isTrue, quiz?.uuid)}`}
                    >
                      {quiz?.content || "N/A"}
                    </span>
                  </Checkbox>
                </div>
                <label htmlFor={`${index}`}>
                  <img
                    className="mt-1"
                    src={quiz?.filePath || ""}
                    alt="quiz-card"
                  />
                </label>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default ChooseMultiImage;
