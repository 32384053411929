import { ReactNode } from "react";
import { Modal } from "antd";
import "./modal-confirm.scss";
import { CloseOutlined } from "@ant-design/icons";

interface ModalConfirmProps {
  title?: string;
  description?: string | ReactNode;
  type?: "danger" | "notify" | "confirm";
  icon?: ReactNode;
  okText?: string;
  cancelText?: string;
  canDestroy?: boolean;
  onOk?: (val?: any) => void;
  onCancel?: (val?: any) => void;
}

function useModalConfirm() {
  const showConfirm = ({
    title = "Xác nhận xóa",
    icon = null,
    description = "Bạn chắc chắn muốn xoá?",
    type = "danger",
    okText = "Ok",
    cancelText = "Cancel",
    canDestroy = true,
    onOk = () => {},
    onCancel = () => {},
  }: ModalConfirmProps) => {
    switch (type) {
      case "danger":
        Modal.confirm({
          className: "modal-confirm-global",
          centered: true,
          title: (
            <div>
              {icon ? (
                <div>{icon}</div>
              ) : (
                <img
                  className="success-icon"
                  src={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
                />
              )}

              <div className="success-icon bg-icon-title">
                {/* <img
                  src={`${process.env.PUBLIC_URL}/assets/img/bg-head-modal.png`}
                /> */}
              </div>

              <CloseOutlined
                className="close-modal-icon"
                onClick={canDestroy ? () => Modal.destroyAll() : () => {}}
              />
            </div>
          ),
          content: (
            <>
              <p className="font-size-18 line-height-28 mt-3">
                <b>{title}</b>
              </p>
              <p className="font-size-14 line-height-20 text-secondary mt-1">
                {description}
              </p>
            </>
          ),
          okText: okText,
          cancelText: cancelText,
          okButtonProps: {
            className: "height-44 w-full button-danger",
          },
          cancelButtonProps: {
            className: "height-44 w-full button-default",
          },
          onOk() {
            onOk();
          },
          onCancel() {
            onCancel();
          },
        });
        break;
      case "notify":
        Modal.confirm({
          className: "modal-confirm-global",
          centered: true,
          title: (
            <div>
              {icon ? (
                <div>{icon}</div>
              ) : (
                <img
                  className="success-icon"
                  src={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
                />
              )}

              <div className="success-icon bg-icon-title">
                {/* <img
                  src={`${process.env.PUBLIC_URL}/assets/img/bg-head-modal.png`}
                /> */}
              </div>

              <CloseOutlined
                className="close-modal-icon"
                onClick={canDestroy ? () => Modal.destroyAll() : () => {}}
              />
            </div>
          ),
          content: (
            <>
              <p className="font-size-18 line-height-28 mt-3">
                <b>{title}</b>
              </p>
              <p className="font-size-14 line-height-20 text-secondary mt-1">
                {description}
              </p>
            </>
          ),
          okText: okText,
          cancelText: cancelText,
          okButtonProps: {
            className: "height-44 w-full button-primary",
          },
          cancelButtonProps: {
            className: "height-44 w-full button-default d-none",
          },
          onOk() {
            onOk();
          },
        });
        break;
      case "confirm":
        Modal.confirm({
          className: "modal-confirm-global",
          centered: true,
          title: (
            <div>
              {icon ? (
                <div className="success-icon"> {icon}</div>
              ) : (
                <img
                  className="success-icon"
                  src={`${process.env.PUBLIC_URL}/assets/icons/delete-icon.svg`}
                />
              )}

              <div className="success-icon bg-icon-title">
                {/* <img
                  src={`${process.env.PUBLIC_URL}/assets/img/bg-head-modal.png`}
                /> */}
              </div>

              <CloseOutlined
                className="close-modal-icon"
                onClick={canDestroy ? () => Modal.destroyAll() : () => {}}
              />
            </div>
          ),
          content: (
            <>
              <p className="font-size-18 line-height-28 mt-3">
                <b>{title}</b>
              </p>
              <p className="font-size-14 line-height-20 text-secondary mt-1">
                {description}
              </p>
            </>
          ),
          okText: okText,
          cancelText: cancelText,
          okButtonProps: {
            className:
              "height-44 w-full button-primary border-violet background-violet",
          },
          cancelButtonProps: {
            className: "height-44 w-full button-default",
          },
          onOk() {
            onOk();
          },
          onCancel() {
            onCancel();
          },
        });
        break;
      default:
        break;
    }
  };

  return showConfirm;
}

export default useModalConfirm;
