// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.description {
  color: grey; }

* {
  box-sizing: border-box; }

body {
  margin: 0; }

* {
  box-sizing: border-box; }

body {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px; }

* {
  box-sizing: border-box; }

body {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px; }

#i3lz {
  height: 1367px; }

#iilc {
  font-family: Georgia, serif; }

#i2ak {
  font-family: Georgia, serif;
  color: #b4814a; }

#i76x1 {
  font-family: Georgia, serif; }

#ir0ml {
  font-family: Georgia, serif;
  color: #7e7f79; }

#i1c4b {
  font-family: Georgia, serif; }

#i2ifr {
  font-family: Georgia, serif; }

#i781e {
  font-family: Georgia, serif; }
`, "",{"version":3,"sources":["webpack://./src/pages/certificate/certificate-template/index.scss"],"names":[],"mappings":"AACA;EACI,WAAW,EAAA;;AAEb;EACE,sBAAsB,EAAA;;AAExB;EACE,SAAS,EAAA;;AAEX;EACE,sBAAqB,EAAA;;AAEvB;EACE,eAAc;EACd,iBAAgB;EAChB,kBAAiB;EACjB,gBAAe,EAAA;;AAEjB;EACE,sBAAqB,EAAA;;AAEvB;EACE,eAAc;EACd,iBAAgB;EAChB,kBAAiB;EACjB,gBAAe,EAAA;;AAEjB;EACE,cAAa,EAAA;;AAEf;EACE,2BAA0B,EAAA;;AAE5B;EACE,2BAA0B;EAC1B,cAAuB,EAAA;;AAEzB;EACE,2BAA0B,EAAA;;AAE5B;EACE,2BAA0B;EAC1B,cAAwB,EAAA;;AAE1B;EACE,2BAA0B,EAAA;;AAE5B;EACE,2BAA0B,EAAA;;AAE5B;EACE,2BAA0B,EAAA","sourcesContent":["\n.description {\n    color: grey;\n  }\n  * {\n    box-sizing: border-box;\n  }\n  body {\n    margin: 0;\n  }\n  *{\n    box-sizing:border-box;\n  }\n  body{\n    margin-top:0px;\n    margin-right:0px;\n    margin-bottom:0px;\n    margin-left:0px;\n  }\n  *{\n    box-sizing:border-box;\n  }\n  body{\n    margin-top:0px;\n    margin-right:0px;\n    margin-bottom:0px;\n    margin-left:0px;\n  }\n  #i3lz{\n    height:1367px;\n  }\n  #iilc{\n    font-family:Georgia, serif;\n  }\n  #i2ak{\n    font-family:Georgia, serif;\n    color:rgb(180, 129, 74);\n  }\n  #i76x1{\n    font-family:Georgia, serif;\n  }\n  #ir0ml{\n    font-family:Georgia, serif;\n    color:rgb(126, 127, 121);\n  }\n  #i1c4b{\n    font-family:Georgia, serif;\n  }\n  #i2ifr{\n    font-family:Georgia, serif;\n  }\n  #i781e{\n    font-family:Georgia, serif;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
