import { Modal, ModalProps } from "antd";
import React, {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useState,
} from "react";

interface CustomModalProps extends ModalProps {
  children?: ReactNode;
  content?: ReactNode;
}

const CustomModal = forwardRef(
  ({ children, content, ...props }: CustomModalProps, ref) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };

    const handleOk = () => {
      setIsModalOpen(false);
    };

    const handleCancel = () => {
      setIsModalOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open: showModal,
      close: handleCancel,
    }));

    return (
      <>
        <div onClick={showModal}>{children}</div>
        <Modal
          title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          {...props}
        >
          {content}
        </Modal>
      </>
    );
  }
);

export default CustomModal;
