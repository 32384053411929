import {
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleFilled,
  EyeInvisibleOutlined,
  MoreOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Modal, Popover } from "antd";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { NotificationItemProps } from "../../../types/notifications";
import { scrollToElement } from "../../../utils/getPageFromLink";
import { displayStatusNotification } from "../../../utils/notification";
import useKeyword from "../../../hooks/useKeyword";

interface NotificationLargeItemProps extends NotificationItemProps {
  content?: any;
  status?: string;
  onClick?: (id?: number) => void;
  onClickViewed?: (id?: number, isViewed?: boolean) => void;
  onClickUnView?: (id?: number, isViewed?: boolean) => void;
  onRemove?: (id?: number) => void;
  onShowMore?: (id?: number) => void;
}

function NotificationItem({
  message = " Lorem ipsum dolor sit amet consectetur adipisicing elit.",
  senderName,
  isViewed,
  created = "",
  title = "",
  id = useParams().id as any,
  onClick = () => {},
  onClickViewed = () => {},
  onClickUnView = () => {},
  onRemove = () => {},
  onShowMore = () => {},
}: NotificationLargeItemProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState(false);
  const [canExpand, setCanExpand] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const maxHeight = 150;
  const notifyIdClick = Number(useKeyword("id"));

  useEffect(() => {
    scrollToElement(`notify-${id}`);
    if (notifyIdClick && notifyIdClick === id) {
      setExpanded(true);
    }
  }, [id, notifyIdClick]);
  console.log("notifyIdClick", notifyIdClick);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleConfirm = () => {
    onRemove(id);
  };

  useEffect(() => {
    if (textRef.current) {
      const computedStyle = getComputedStyle(textRef.current);
      const lineHeight = parseFloat(computedStyle.lineHeight || "1.2");
      const calculatedHeight = lineHeight * 2;

      if (calculatedHeight > maxHeight) {
        if (textRef.current.scrollHeight > maxHeight) {
          setCanExpand(true);
        }
      } else {
        if (textRef.current.scrollHeight > calculatedHeight) {
          setCanExpand(true);
        }
      }
    }
  }, []);

  const toggleExpand = () => setExpanded(!expanded);

  const calculatedMaxHeight = textRef.current
    ? `${Math.min(
        maxHeight,
        2 * parseFloat(getComputedStyle(textRef.current).lineHeight || "1.2")
      )}px`
    : `${maxHeight}px`;

  const renderSettingContent = () => {
    return (
      <div className="setting-popover-list">
        {isViewed ? (
          <div
            className="popover-item"
            onClick={() => {
              onClickUnView(id, isViewed);
              hide();
            }}
          >
            <EyeInvisibleOutlined />
            Đánh dấu là chưa xem
          </div>
        ) : (
          ""
        )}

        <div
          className="popover-item span-red"
          onClick={() => {
            Modal.confirm({
              title: "Xác nhận xoá thông báo",
              icon: <ExclamationCircleFilled />,
              content:
                "Thông báo đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá thông báo?",
              okText: "Xác nhận",
              cancelText: "Huỷ",
              centered: true,
              onOk() {
                handleConfirm();
              },
              onCancel() {},
            });
          }}
        >
          <DeleteOutlined />
          Xoá
        </div>
      </div>
    );
  };
  return (
    <div
      className={`notification-item ${!isViewed ? "active-item" : ""}`}
      onClick={() => onClick(id)}
      id={`notify-${id}`}
    >
      <div className="item-content-wrap">
        <div className="notify-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M26.8571 25.1386H26V12.9958C26 7.9565 22.275 3.79221 17.4286 3.09935V1.71007C17.4286 0.92078 16.7893 0.281494 16 0.281494C15.2107 0.281494 14.5714 0.92078 14.5714 1.71007V3.09935C9.725 3.79221 6 7.9565 6 12.9958V25.1386H5.14286C4.51071 25.1386 4 25.6494 4 26.2815V27.4244C4 27.5815 4.12857 27.7101 4.28571 27.7101H12C12 29.9172 13.7929 31.7101 16 31.7101C18.2071 31.7101 20 29.9172 20 27.7101H27.7143C27.8714 27.7101 28 27.5815 28 27.4244V26.2815C28 25.6494 27.4893 25.1386 26.8571 25.1386ZM16 29.4244C15.0536 29.4244 14.2857 28.6565 14.2857 27.7101H17.7143C17.7143 28.6565 16.9464 29.4244 16 29.4244ZM8.57143 25.1386V12.9958C8.57143 11.0101 9.34286 9.14578 10.7464 7.74221C12.15 6.33864 14.0143 5.56721 16 5.56721C17.9857 5.56721 19.85 6.33864 21.2536 7.74221C22.6571 9.14578 23.4286 11.0101 23.4286 12.9958V25.1386H8.57143Z"
              fill="black"
              fill-opacity="0.45"
            />
          </svg>
        </div>

        <div className="item-content flex-1">
          <span className="font-size-14">
            <b>Từ: {senderName}</b>
            <span className="font-size-12 line-height-18 ml-2">
              {displayStatusNotification(created)}
            </span>
          </span>
          <div className="hr-tag"></div>
          <div onClick={() => onClickViewed(id, isViewed)}>
            <p className="font-weight-6 font-size-16 mt-2">{title}</p>

            <>
              <div>
                <div
                  ref={textRef}
                  style={{
                    maxHeight: expanded ? "none" : calculatedMaxHeight,
                    overflow: "hidden",
                    whiteSpace: "pre-wrap",
                    transition: "max-height 0.3s ease",
                  }}
                  className="text-secondary"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: message as string }}
                  ></div>
                </div>
              </div>
              {canExpand && (
                <div
                  onClick={toggleExpand}
                  className="text-primary font-weight-5 flex gap-8 mt-1"
                  style={{ cursor: "pointer" }}
                >
                  {expanded ? (
                    <>
                      Thu gọn
                      <UpOutlined />
                    </>
                  ) : (
                    <>
                      Xem chi tiết
                      <DownOutlined />
                    </>
                  )}
                </div>
              )}
            </>
          </div>
        </div>
        <Popover
          content={renderSettingContent()}
          open={open}
          trigger="click"
          placement="bottomRight"
          overlayClassName="notification-popup"
          onOpenChange={handleOpenChange}
        >
          <div className="pointer more-icon">
            <MoreOutlined />
          </div>
        </Popover>
      </div>
    </div>
  );
}

export default NotificationItem;
