import React from "react";
import ChooseText from "./choose-text/ChooseText";
import ChooseImage from "./choose-image";
import ChooseVideo from "./choose-video";
import TrueOrFalse from "./true-or-false";
import DropDown from "./drop-down";
import ChooseMultiText from "./choose-multi-text";
import ChooseMultiImage from "./choose-multi-image";
import ChooseMultiVideo from "./choose-multi-video";
import Connect from "./connect";
import ShortText from "./short-text";
import FileUpload from "./file-upload";
import Video from "./video";
import Mp3 from "./mp3";
import LongText from "./long-text";
import "./style.scss";
import { PartBlock, Quiz } from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import EmptyComponent from "../../../../../components/empty";
import { QuizProps } from "../../../../../types/scoring-course";

interface ResultQuizLayoutProps {
  data?: QuizProps;
  groups?: PartBlock[];
  loading?: boolean;
  quizzes?: Quiz[];
  disabledQuiz?: boolean;
  showCorrect?: boolean;
}

function ResultQuizLayout({ data, showCorrect }: ResultQuizLayoutProps) {
  const type = data?.type;
  const quiz = { ...data } as QuizProps;
  const result = quiz?.result;

  const renderQuestionByType = () => {
    switch (type) {
      case QuestionTypeEnum.CHOOSE_TEXT:
        return (
          <ChooseText data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.CHOOSE_IMAGE:
        return (
          <ChooseImage data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.CHOOSE_VIDEO:
        return (
          <ChooseVideo data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.TRUE_OR_FALSE:
        return (
          <TrueOrFalse data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.DROP_DOWN:
        return (
          <DropDown data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
        return (
          <ChooseMultiText
            data={quiz}
            showCorrect={showCorrect}
            result={result}
          />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
        return (
          <ChooseMultiImage
            data={quiz}
            showCorrect={showCorrect}
            result={result}
          />
        );

      case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
        return (
          <ChooseMultiVideo
            data={quiz}
            showCorrect={showCorrect}
            result={result}
          />
        );

      case QuestionTypeEnum.CONNECT:
        return (
          <Connect data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.SHORT_TEXT:
        return (
          <ShortText data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.LONG_TEXT:
        return (
          <LongText data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.FILE_UPLOAD:
        return (
          <FileUpload data={quiz} showCorrect={showCorrect} result={result} />
        );

      case QuestionTypeEnum.VIDEO:
        return <Video data={quiz} showCorrect={showCorrect} result={result} />;

      case QuestionTypeEnum.MP3:
        return <Mp3 data={quiz} showCorrect={showCorrect} result={result} />;

      default:
        return <EmptyComponent description="Không có kết quả" />;
    }
  };

  return (
    <div className="quiz-layout flex flex-column quiz-view-layout">
      <div className="question-content flex flex-column">
        {renderQuestionByType()}
      </div>
    </div>
  );
}

export default ResultQuizLayout;
