import React, {useEffect, useMemo, useState, useTransition} from "react";
import {debounce} from "lodash";
import ResourceChartTab from "../chart/resource-tab/ResourceChartTab";
import {BarStackedChartProps, PieChart} from "../../../../../types/static-report";
import {staticReportStore} from "../../../../../stores/stores";
import dayjs from "dayjs";
import {
  getListCourseByFormatUniversity,
  getListCourseByIndustryGroup,
  getListCourseByTeacherUniversity,
  getListSynthesizeCourseUniversity,
  getListUnitByIndustryGroup,
  getListUniversityMultiMediaUnit,
  getListUniversityReferenceSourceByType,
  getListUniversityScormXapiUnit,
  getListUniversityTestByType
} from "../../../../../service/statistical-report";
import ResourceTable from "../table/resource-table";

type Props = {
  dataType: string;
  universityId?: number;
};

const ResourceTab = (props: Props) => {
  const {dataType, universityId} = props;
  const {staticReportType, filterValues, tab} = staticReportStore();
  const [isPending, startTransition] = useTransition();

  const [chartData, setChartData] = useState({
    dataListSynthesizeCourseUniversity: [] as Array<any>,
    dataListCourseByFormatUniversity:[] as PieChart[],
    dataListCourseByIndustryGroup:[] as BarStackedChartProps[],
    dataListCourseByTeacherUniversity:[] as BarStackedChartProps[],
    dataListUnitByIndustryGroup:[] as PieChart[],
    dataListUniversityTestByType:[] as PieChart[],
    dataListUniversityReferenceSourceByType:[] as PieChart[],
    dataListUniversityScormXapiUnit:[] as PieChart[],
    dataListUniversityMultiMediaUnit:[] as PieChart[]  
  });

  const [loading, setLoading] = useState({
    dataListCourseByFormatUniversity: false,
    dataListCourseByIndustryGroup: false,
    dataListCourseByTeacherUniversity: false,
    dataListUnitByIndustryGroup: false,
    dataListUniversityTestByType: false,
    dataListUniversityReferenceSourceByType: false,
    dataListUniversityScormXapiUnit: false,
    dataListUniversityMultiMediaUnit: false,
  });

  const fetchData = async () => {
    const payload = {
      timeUnit: filterValues.timeUnit,
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: filterValues?.universityIds,
    };
    try {
      const apiCallList = [
        {
          key: "dataListSynthesizeCourseUniversity",
          api: getListSynthesizeCourseUniversity,
          params: payload,
        },
        {
          key: "dataListCourseByFormatUniversity",
          api: getListCourseByFormatUniversity,
          params: payload,
        },
        {
          key: "dataListCourseByTeacherUniversity",
          api: getListCourseByTeacherUniversity,
          params: payload,
        },
        {
          key: "dataListCourseByIndustryGroup",
          api: getListCourseByIndustryGroup,
          params: payload,
        },
        {
          key: "dataListUnitByIndustryGroup",
          api: getListUnitByIndustryGroup,
          params: payload,
        },
        {
          key: "dataListUniversityTestByType",
          api: getListUniversityTestByType,
          params: payload,
        }, {
          key: "dataListUniversityReferenceSourceByType",
          api: getListUniversityReferenceSourceByType,
          params: payload,
        }, {
          key: "dataListUniversityScormXapiUnit",
          api: getListUniversityScormXapiUnit,
          params: payload,
        }, {
          key: "dataListUniversityMultiMediaUnit",
          api: getListUniversityMultiMediaUnit,
          params: payload,
        },
      ]
      await Promise.allSettled(
        apiCallList.map(async ({key, api, params}) => {
          setLoading((prev) => ({ ...prev, [key]: true }));
          try {
            const response = await api(params);
            startTransition(() => {
              setChartData((prevState) => ({
                ...prevState,
                [key]: response?.data?.data?.statistic || response?.data?.data,
              }));
            })
          } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
            startTransition(() => {
              setChartData(prevState => ({
                ...prevState,
                [key]: [], // Provide fallback empty data
              }));
            });
          } finally {
            setLoading((prev) => ({ ...prev, [key]: false }));
          }
        })
      );
    } catch (err) {
      console.error(err)
    }
  }

  const debouncedFetchData = useMemo(
    () => debounce(fetchData, 300),
    [filterValues]
  );

  useEffect(() => {
    
    if (tab === "2" && universityId && universityId !== 0) {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  const memoizedChartData = useMemo(() => {
    return {
      dataListSynthesizeCourseUniversity: chartData.dataListSynthesizeCourseUniversity,
      dataListCourseByFormatUniversity: chartData.dataListCourseByFormatUniversity,
      dataListCourseByTeacherUniversity: chartData.dataListCourseByTeacherUniversity ? chartData.dataListCourseByTeacherUniversity : [],
      dataListCourseByIndustryGroup: chartData.dataListCourseByIndustryGroup,
      dataListUnitByIndustryGroup: chartData.dataListUnitByIndustryGroup,
      dataListUniversityTestByType: chartData.dataListUniversityTestByType,
      dataListUniversityReferenceSourceByType: chartData.dataListUniversityReferenceSourceByType,
      dataListUniversityScormXapiUnit: chartData.dataListUniversityScormXapiUnit,
      dataListUniversityMultiMediaUnit: chartData.dataListUniversityMultiMediaUnit,
    };
  }, [chartData]);

  return (
    ( universityId && universityId !== 0)
      ?
        (dataType === "1") ? 
        <ResourceChartTab {...memoizedChartData} loading={loading}/> :
        <ResourceTable {...memoizedChartData} loading={isPending}/>
      :
      <p style={{fontSize:18, color:"#5f5c5c", textAlign:"center"}}>Không có dữ liệu</p>
  ) 
};

export default ResourceTab;
