import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, Row, Space, TableColumnsType, TableProps, TimePicker, Tooltip, Typography, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import { CheckCircleOutlined, CloseOutlined, DownloadOutlined, PlusOutlined, ReloadOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
import moment from 'moment'
import { saveSectionTemplateCourse } from '../../../service/content-list-course/contentListCourseApi'

type Props = {
  open: boolean
  codeLesson?: string
  handleClose: () => void
  sectionId: string
}

export default function SaveTemplateSequenceModal(props: Props) {
  const { open, handleClose, codeLesson, sectionId } = props

  const [form] = Form.useForm()

  const validation = {
    codeTemplateSequence: {
      required: true,
      message: "Vui lòng nhập mã mẫu chương",
    },
    namTemplateSection: {
      required: true,
      message: "Vui lòng nhập tên mẫu chương",
    },
  };

  const randomCodeTemplateSequence = () => {
    const date = moment().format('YYMMDD HHmmss')
    const leftDate = date.split(" ")
    const codeLessonRandom = `${codeLesson}_${leftDate[0]}_${leftDate[1]}`
    form.setFieldValue("codeTemplateSequence", codeLessonRandom)
  }

  const saveData = async (values: any) => {
    const data = {
      ids: [sectionId] ?? [],
      key: values.codeTemplateSequence ?? null,
      name: values.namTemplateSection ?? null
    }
    await saveSectionTemplateCourse(data).then((res) => {
      if (res.status === 200) {
        message.success("Lưu mẫu chương thành công")
        handleClose()
        form.resetFields()
      }
    })
  }

  useEffect(() => {
    if (open) {
      randomCodeTemplateSequence()
    }
  }, [open])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === "save-template-lesson-modal") {

        Modal.confirm({
          title: `Xác nhận lưu mẫu chương`,
          content: `Xin vui lòng xác nhận lưu mẫu chương.`,
          onOk: () => {
            saveData(values);
          },
          okText: "Xác nhận",
          cancelText: "Đóng",
          centered: true,
          icon: <CheckCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });

      }
    }}>
      <Modal
        className="save-template-lesson-modal"
        title={"Lưu mẫu chương"}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"450px"}
      >
        <Form
          form={form}
          layout="vertical"
          name="save-template-lesson-modal"
        >
          <Row gutter={30} className='p-6'>
            <Col md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }} xs={{ span: 22 }}>
              <Form.Item name="codeTemplateSequence" label="Mã mẫu chương" rules={[validation.codeTemplateSequence]}>
                <FormInput placeholder='Nhập mã mẫu hoặc tự sinh mã tự động' />
              </Form.Item>
            </Col>
            <Col md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} xs={{ span: 2 }} className='flex'>
              <Tooltip title="Tạo mã mẫu chương ngẫu nhiên">
                <ReloadOutlined className='mt-3 cursor-pointer' onClick={randomCodeTemplateSequence} />
              </Tooltip>
            </Col>

            <Col md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }} xs={{ span: 22 }} className='mt-2'>
              <Form.Item name="namTemplateSection" label="Tên mẫu chương" rules={[validation.namTemplateSection]}>
                <FormInput placeholder='Nhập tên mẫu chương' />
              </Form.Item>
            </Col>

            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Lưu
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider >
  )
}
