import { Badge, Layout, message, Tabs, TabsProps, Typography } from "antd";
import { useLocation, useParams } from "react-router-dom";
import PageHeader from "../../../../../components/page-header/PageHeader";
import { getMoocApprovedCourseById } from "../../../../../service/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-registration-api";
import { FunctionComponent, useEffect, useState } from "react";
import {
  getListMoocCourseStructureById,
  getMoocCourseConstructionPlanById,
} from "../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { DetailContent } from "../../../../survey-managment/mooc-survey/components/detail-content/DetailContent";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import { StatusEum } from "../../../mooc-course-construction-plan/list-course-plan/constants/data";
import CourseConstructionPlanningAssignment from "./course-structure-planning-assignment";
import {
  TypeChapterList,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import {
  getAllTeachers,
  getDataTeachers,
} from "../../../../../service/list-account-teachers/listAccountTeachersApi";

type Props = {};
const PlanningAssignment: FunctionComponent<Props> = ({}) => {
  const location = useLocation();
  const { state } = location;
  const mode = state && state.mode ? state.mode : null;
  const { Content } = Layout;
  const imgPath = "/images/";
  const params = useParams();

  const [detailData, setDetailData] = useState<any>({});
  const [listInfo, setListInfo] = useState<any[]>();
  const [dataListCourseStructure, setDataListCourseStructure] = useState<
    TypeChapterList[]
  >([]);
  const [dataOptions, setDataOptions] = useState({
    dataTeachers: [],
  });

  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: "Phân công lập kế hoạch",
    },
    {
      title: detailData.registrationName,
    },
  ];

  const handleReturnCourseType = (courseType: number) => {
    return (
      <div className="table-course-type">
        {courseType === 2 ? (
          <div className="table-course-type-2">Hợp tác xây dựng</div>
        ) : courseType === 1 ? (
          <div className="table-course-type-1">Tự triển khai</div>
        ) : (
          <div className="table-course-type-3">Dùng chung</div>
        )}
      </div>
    );
  };

  const handleReturnStatus = (status: number) => {
    let courseStatusString: string;

    switch (status) {
      case StatusEum.SAVE_DRAFT:
        courseStatusString = "Lưu bản nháp";
        break;
      case StatusEum.WAIT_APPROVE:
        courseStatusString = "Chờ duyệt";
        break;
      case StatusEum.APPROVED:
        courseStatusString = "Đã duyệt";
        break;
      case StatusEum.REFUSE:
        courseStatusString = "Từ chối";
        break;
      case StatusEum.PUBLISHED:
        courseStatusString = "Đã xuất bản";
        break;
      case StatusEum.END:
        courseStatusString = "Kết thúc";
        break;
      default:
        courseStatusString = "";
        break;
    }
    return (
      <div className="table-status">
        <div
          className="color-icon"
          style={{
            backgroundColor:
              status === 1
                ? "#D9D9D9"
                : status === 2
                ? "#FAAD14"
                : status === 3
                ? "#1677FF"
                : status === 4
                ? "#FF0000"
                : status === 5
                ? "#1677FF"
                : status === 6
                ? "#52C41A"
                : "white",
          }}
        ></div>
        {courseStatusString}
      </div>
    );
  };

  const renderSwitch = (value: string) => {
    switch (value) {
      case "registrationName":
        return "Tên kế hoạch";
      case "registrationCode":
        return "Mã kế hoạch";
      case "constructionPlanStatus":
        return "Trạng thái";
      case "industryName":
        return "Khoa";
      case "industryGroupName":
        return "Chuyên ngành";
      case "hostUniversityName":
        return "Trường chủ trì";
      case "cooperativeUniversityName":
        return "Trường hợp tác";
      case "courseType":
        return "Loại khóa học";
      default:
        return "";
    }
  };
  const [nameAssigneeId, setNameAssigneeId] = useState("");
  const getDetailMoocCourseConstructionPlanData = async (id: any) => {
    try {
      await getMoocCourseConstructionPlanById(id)
        .then((res) => {
          const data = res.data.data;
          const name = data.moocCoursePlanAssignment.find(
            (x: any) => x.courseSturctureItemId === null
          )?.authUserMoocCoursePlanAssignmentAssigneeIdToauthUser
            ?.authUserprofile;
          if (name) {
            setNameAssigneeId(name.name);
          }
          setDetailData(data);
          const industryName = data.moocConstructionPlanIndustry
            .map(
              (item: { moocIndustry: { name: string } }) =>
                item.moocIndustry.name
            )
            .join(" ;");
          const industryGroupName = data.moocConstructionPlanIndustryGroup
            .map(
              (item: { moocIndustryGroup: { name: string } }) =>
                item.moocIndustryGroup.name
            )
            .join(" ;");
          setDetailData({
            registrationName: data.registrationName ?? "",
            registrationCode: data.registrationCode ?? "",
            constructionPlanStatus: data.constructionPlanStatus ?? "",
            industryName: industryName ?? "",
            industryGroupName: industryGroupName ?? "",
            courseType: data.courseType ?? "",
          });
        })
        .catch((err) => {});
    } catch (error) {}
  };

  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 1)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level2 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 2)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level3 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 3)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );

    const chapterList: TypeChapterList[] = level1.map((l1, index) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        assigneeId: l1?.moocCoursePlanAssignment?.[0]?.assigneeId,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        orderNumber: l1.orderNumber,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2
        .filter((l2) => l2.parentId === l1.id)
        .sort((a, b) =>
          a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
        );

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject, index) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          orderNumber: subject.orderNumber,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3
          .filter((l3) => l3.parentId === subject.id)
          .sort((a, b) =>
            a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
          );

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson, index) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          orderNumber: lesson.orderNumber,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });
    return chapterList;
  };

  const getListMoocCourseStructureByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureById(id)
        .then((res) => {
          const data = res.data.data;
          setDataListCourseStructure(changeToChapterList(data));
        })
        .catch((err) => {
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListDataTeachers = async () => {
    try {
      await getAllTeachers().then((res) => {
        const { data } = res.data;
        setDataOptions({
          dataTeachers: data.map((member: any) => ({
            value: member.id,
            label: `${member.fullName} (${member.email})`,
          })),
        });
      });
    } catch (error) {}
  };

  useEffect(() => {
    getDetailMoocCourseConstructionPlanData(params.id);
    getListMoocCourseStructureByIdData(params.id);
    getListDataTeachers();
  }, []);

  useEffect(() => {
    const convertObjectData = Object.entries(detailData);
    const listInfo = convertObjectData.map((item: any, index) => {
      if (item[0] == "constructionPlanStatus") {
        item[1] = handleReturnStatus(item[1]);
      } else if (item[0] == "courseType") {
        item[1] = handleReturnCourseType(item[1]);
      }
      return (
        <div className="list-item" key={index}>
          <Typography.Title level={5} className="list-item--title">
            {renderSwitch(item[0])}
          </Typography.Title>
          <div className="list-item--description">
            <Typography.Text className="content">
              {item[0] === "status" ? (
                <Badge
                  key={!detailData.isAssigned ? "lightgray" : "green"}
                  color={!detailData.isAssigned ? "lightgray" : "green"}
                />
              ) : null}{" "}
              {item[1]}
            </Typography.Text>
          </div>
        </div>
      );
    });
    setListInfo(listInfo);
  }, [detailData]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: (
        <DetailContent>
          <CustomCard
            title={
              <div className="card-heading--info">
                <Typography.Title level={5}>
                  Thông tin đăng ký kế hoạch
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Thông tin chung về đăng ký kế hoạch xây dựng khóa học
                </Typography.Text>
              </div>
            }
            className="card-info--wrapper"
          >
            <div className="list-wrapper">{listInfo}</div>
          </CustomCard>
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: "Cấu trúc khoá học",
      children: (
        <DetailContent>
          <CourseConstructionPlanningAssignment
            dataCourseStructure={dataListCourseStructure}
            dataOptions={dataOptions}
            nameAssigneeId={nameAssigneeId}
          />
        </DetailContent>
      ),
    },
  ];

  return (
    <Content>
      <PageHeader
        title={`Phân công lập kế hoạch Đăng ký ${
          detailData.registrationName ?? ""
        }`}
        listBreadcrumb={listBreadcrumb}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey={state.tab} items={items} />
      </div>
    </Content>
  );
};
export default PlanningAssignment;
