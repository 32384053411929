import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from 'react';
import { Button, Checkbox, MenuProps, TableColumnType, Typography } from 'antd';

import FormInput from "../../../../components/form-input/FormInput";
import './filter-data.scss'
import { DownOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { CheckboxOptionType } from "antd/lib";

type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  placeholder?: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLInputElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  checkedList: Array<string>,
  setCheckedList: (value: string[]) => void,
  dataColumns?: TableColumnType<any>[]
}


const FilterData: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  placeholder,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList
}) => {

  const options = dataColumns?.map(({ key, title }) => ({
    label: title,
    value: key
  }))


  const items: MenuProps['items'] = [
    {
      label: (
        <Checkbox.Group options={options as CheckboxOptionType[]} value={checkedList} onChange={(value) => {
          setCheckedList(value as string[])
        }} />

      ),
      key: 'radio',
    }
  ]

  const menuProps = {
    items
  }


  return (
    <>
      <div className="heading-extra">
        <div className="heading-filter">
          <Button className="filter-button" onClick={handleOpenFilter}>
            <Typography.Text>
              Bộ lọc
            </Typography.Text>
            <DownOutlined />
          </Button>
        </div>
        <div className="heading-search">
          <FormInput placeholder={placeholder} value={searchValue}
            onChange={handleChangeInput}
            onKeyPress={handleSearch}
            afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
        </div>
        <div className="heading-resigter">
          <Button onClick={handleOpen} className="btn btn-primary">
            <PlusOutlined />
            <Typography.Text>Tạo vai trò</Typography.Text>
          </Button>
        </div>
      </div>
    </>
  );
};

export default FilterData;
