import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Layout, Typography } from "antd";
import { MenuProps } from "antd/lib";
import parse from "html-react-parser";
import { get, isEmpty } from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseContentPage from "../../components/course-content";
import { ConstructCourseDetailType } from "../../types/course-construction";
import "./course-preview.scss";
var indexDB = require("idbcache").default;

interface Lesson {
  id: number;
  title: string;
  watched: boolean;
  videoUrl: string;
  moduleId: string;
  moduleTitle: string;
}

interface Module {
  id: any;
  title: string;
  lessons: Lesson[];
}

const ContentPreview: FunctionComponent = () => {
  const [typeTemplates, setTypeTemplates] =
    useState<String>("Trang giới thiệu");
  const [courseDetail, setCourseDetail] = useState<ConstructCourseDetailType>(
    {}
  );
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation()

  const [html, setHTML] = useState<string>("");
  indexDB.get("courseSavedTemplete-" + params?.id).then((val: any) => {
    if (val) {
      let html = "";
      const parsecourseSavedTempalte = JSON.parse(val);
      if (parsecourseSavedTempalte.id === params?.id) {
        html = parsecourseSavedTempalte.html;
        html = html.replace(new RegExp("<body.*?>"), "").replace("</body>", "");
        const style = document.createElement("style");
        style.append(parsecourseSavedTempalte.css);
        const head = document.querySelector("head");
        head?.appendChild(style);
        setHTML(html);
      }
    }
  });

  const [modules, setModules] = useState<Module[]>([]);
  const [currentLesson, setCurrentLesson] = useState<Lesson | null>(
    modules[0]?.lessons[0]
  );
  const markAsWatched = (moduleId: string, lessonId: number) => {
    setModules((prevModules) =>
      prevModules.map((module) =>
        module.id === moduleId
          ? {
            ...module,
            lessons: module.lessons.map((lesson) =>
              lesson.id === lessonId ? { ...lesson, watched: true } : lesson
            ),
          }
          : module
      )
    );
  };

  const selectLesson = (lesson: Lesson) => {
    setCurrentLesson(lesson);
  };

  const handleVideoEnd = () => {
    if (currentLesson) {
      markAsWatched(currentLesson.moduleId, currentLesson.id);
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: (
        <Typography.Text
          className="span-full"
          onClick={() => {
            setTypeTemplates("Trang giới thiệu");
          }}
        >
          Trang giới thiệu
        </Typography.Text>
      ),
    },
    {
      key: "1",
      label: (
        <Typography.Text
          className="span-full"
          onClick={() => {
            setTypeTemplates("Trình phát khóa học");
          }}
        >
          Trình phát khóa học
        </Typography.Text>
      ),
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => { };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    if (!isEmpty(location.search)) {
      setTypeTemplates("Trình phát khóa học")
    } else {
      setTypeTemplates("Trang giới thiệu");
    }
  }, [navigate]);

  const [menuHeight, setMenuHeight] = useState<"auto" | "100vh">("auto");
  const [scrollDistance, setScrollDistance] = useState<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const distance = window.scrollY;
      setScrollDistance(distance);
      if (window.scrollY >= 80) {
        setMenuHeight("100vh");
      } else {
        setMenuHeight("auto");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Layout>
        {!menuHeight ? null : (
          <div className="course-preview-action">
            <div className="course-preview-action__dropdowns">
              <Dropdown
                overlayClassName="course-preview-action__dropdowns__item"
                trigger={["click"]}
                menu={menuProps}
              >
                <Button className="btn">
                  <Typography.Text>{typeTemplates}</Typography.Text>
                  <DownOutlined />
                </Button>
              </Dropdown>

              <div className="course-preview-action--title">
                {courseDetail?.name}
              </div>
            </div>
          </div>
        )}

        {typeTemplates === "Trang giới thiệu" ? (
          html === "" || html === null ? (
            <h1 style={{ textAlign: "center" }}>Chưa có mẫu</h1>
          ) : (
            parse(html)
          )
        ) : modules === null && currentLesson === null ? (
          <h1 style={{ textAlign: "center" }}>Chưa có mẫu </h1>
        ) : (
          <div className="course-preview">
            <CourseContentPage isHideApprovedInformationTab={true} preview={true} />
          </div>
        )}
      </Layout >
    </>
  );
};

export default ContentPreview;
