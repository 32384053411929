import { ChangeEvent, useEffect, useState } from "react";
import { Checkbox, CheckboxProps, Col, Radio, Row, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { DEFAULT_MAX_LENGTH_COURSE_TEXTAREA, TypeCreateQuestion, initialQuestionSettingValue } from "../../../../constants";
import { useAddLesson } from "../../../../stores/stores";

interface QuestionTextProps {
  index: number;
  value?: string;
  valueRadio?: any;
  onChange?: (val: { id: number; value: string }) => void;
  onDelete?: (id: number) => void;
  onChangeChecked?: (checked: boolean) => void;
}

function QuestionTrueFalseItem({ index, value, valueRadio, onChange = () => { }, onChangeChecked = () => { }, }: QuestionTextProps) {
  const { Text, Link } = Typography;

  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);

  const [textValue, setTextValue] = useState<string>("");
  const [disableChecked, setDisableChecked] = useState<boolean>(false);

  useEffect(() => {
    if (value) setTextValue(value);
  }, [value]);

  useEffect(() => {
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      if (Object.keys(settingQuestion).length > 0) {
        setDisableChecked(!settingQuestion.canEnterAnswer);
      } else {
        setDisableChecked(!initialQuestionSettingValue.canEnterAnswer);
      }
    }
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      setDisableChecked(false);
    }
  }, [settingQuestion]);

  const handleChangeText = (changeValue: string) => {
    setTextValue(changeValue);
    onChange({ id: index, value: changeValue });
  };
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(e.target.value);
    handleChangeText(e.target.value);
  };

  return (
    <div className="answer mb-1">
      <Radio className="mt-3" value={valueRadio} disabled={disableChecked}></Radio>
      <div className="ml-2 form-value w-full">
        <Row>
          <Col span={24} className="form-value__header">
            <Text>Câu trả lời {index}</Text>
          </Col>
          <Col span={24}>
            <TextArea
              onChange={handleChange}
              value={textValue}
              showCount
              maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
              autoSize={{ minRows: 1, maxRows: 10 }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default QuestionTrueFalseItem;
