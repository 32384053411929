import { SearchOutlined } from "@ant-design/icons";
import { Button, Modal, Space, TableColumnsType, TableProps } from "antd";
import { KeyboardEvent, useEffect, useState } from "react";
import { getAppraisalBoardMemberList } from "../../../service/course-construct";
import { AppraisalBoardListMemberType } from "../../../types/assign-course-construction";
import { objectToQueryString } from "../../../utils/getPageFromLink";
import FormInput from "../../form-input/FormInput";
import TableData from "../../table-data/TableData";
import "./AppraisalBoardListMemberModal.scss";

type Props = {
  isOpen?: boolean;
  onCloseModal?: () => void;
  appraisalBoardId?: number;
};
const AppraisalBoardListMemberModal = (props: Props) => {
  const { isOpen, onCloseModal, appraisalBoardId } = props;
  const [appraisalBoardMember, setAppraisalBoardMember] = useState<DataType>();
  const [searchValue, setSearchValue] = useState("");

  const getAppraisalBoardMemberListData = async () => {
    const params = {
      keyword: searchValue || undefined,
      groupId: appraisalBoardId,
    };
    const keyParams = objectToQueryString(params);
    await getAppraisalBoardMemberList(keyParams).then((res: any) => {
      const { data } = res;

      setAppraisalBoardMember(data);
    });
  };

  useEffect(() => {
    if (isOpen === true) {
      getAppraisalBoardMemberListData();
    }
  }, [appraisalBoardId, isOpen]);

  const handleTableChange: TableProps["onChange"] = (sorter: any) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
  };

  const handleSubmitSearch = () => {
    getAppraisalBoardMemberListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getAppraisalBoardMemberListData();
    }
  };
  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  const dataColumns: TableColumnsType<AppraisalBoardListMemberType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên thành viên",
      dataIndex: "name",
      key: "name",
      width: 14,
    },
    {
      title: "Vai trò",
      dataIndex: "code",
      key: "code",
      width: 8,
      render: (_: any, record, index: number) => {
        return <div>{!record?.isLeader ? "Thành viên" : "Leader"}</div>;
      },
    },
  ];
  return (
    <Modal
      open={isOpen}
      title={appraisalBoardMember?.groupName}
      centered
      width="800px"
      className="appraisal-board-member-list-modal"
      maskClosable={false}
      onCancel={onCloseModal}
      footer={(_, {}) => (
        <div className="modal-btn-center">
          <Space size="middle">
            <Button onClick={onCloseModal}>Đóng</Button>
          </Space>
        </div>
      )}
    >
      <FormInput
        placeholder="Nhập từ khoá cần tìm"
        value={searchValue}
        onChange={handleChangeInput}
        onKeyPress={handleSearch}
        afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
      />
      <div className="total-member">
        Danh sách thành viên
        <span className="total">
          {" "}
          Tổng số {appraisalBoardMember?.total} thành viên
        </span>
      </div>
      <TableData
        dataTable={appraisalBoardMember?.users}
        dataColumns={dataColumns}
        handleTableChangeProps={handleTableChange}
      />
    </Modal>
  );
};

export default AppraisalBoardListMemberModal;
export interface DataType {
  listUserAppriseGroup: Array<any>;
  appriseGroupName: string;
  name: string;
  groupName: string;
  users: any;
  total: number;
}
