import { BehaviorSubject, Subscription } from 'rxjs';
export class ConnectService {
  subscriptions: Subscription[] = [];
  setDataFluent: BehaviorSubject<any> = new BehaviorSubject('');
  getDataFluent = this.setDataFluent.asObservable();

  assembleSub(sub: any) {
    this.subscriptions.push(sub);
  }

  disassembleSub() {
    this.subscriptions.forEach(sb => sb.unsubscribe());
  }
}