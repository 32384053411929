// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-lession-drawer .last-form-item {
  margin-bottom: 10px !important; }
`, "",{"version":3,"sources":["webpack://./src/components/drawer/add-lesstion/index.scss"],"names":[],"mappings":"AAAA;EAEI,8BAA8B,EAAA","sourcesContent":[".add-lession-drawer {\n  .last-form-item {\n    margin-bottom: 10px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
