import { Avatar, Button, message, Modal, Slider, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import "./AvatarUpload.scss";
import { editAccountInfo } from "../../pages/user-info/account/services";
import axios from "axios";
import { useAuthStore } from "../../stores/stores";
import { EditOutlined } from "@ant-design/icons";

interface AvatarUploadProps {
  imgUrl?: any;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({ imgUrl }) => {
  const [currentImage, setCurrentImage] = useState<string | null>(
    imgUrl || null
  );
  const [editorImage, setEditorImage] = useState<string | null>(null);
  const [scale, setScale] = useState<number>(1);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const editorRef = useRef<AvatarEditor | null>(null);
  const saveUserInfo = useAuthStore((state) => state.saveUserInfo);
  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });
  useEffect(() => {
    if (imgUrl) {
      setCurrentImage(imgUrl);
    }
  }, [imgUrl]);

  const handleBeforeUpload = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setEditorImage(reader.result as string);
      setModalIsOpen(true);
    };
    reader.readAsDataURL(file);
    return false;
  };

  const handleScaleChange = (value: number) => {
    setScale(value);
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (editorRef.current) {
      const canvas = editorRef.current.getImage();
      canvas.toBlob(async (blob) => {
        if (blob) {
          const newFile = new File([blob], "avatar.png", { type: "image/png" });

          const formData = new FormData();
          formData.append("avatar", newFile);

          try {
            const response = await editAccountInfo(formData);
            const dataUrl = URL.createObjectURL(blob);
            setCurrentImage(dataUrl);
            setModalIsOpen(false);
            setIsLoading(false);
            saveUserInfo({ ...getUserInfo, anhDaiDien: dataUrl });
            message.success(`Lưu ảnh đại diện thành công`);
          } catch (error) {
            setModalIsOpen(false);
          }
        }
      }, "image/png");
    }
  };

  return (
    <div className="avatar-upload">
      <Upload showUploadList={false} beforeUpload={handleBeforeUpload}>
        <div className="avatar-choose-upload">
          <Avatar
            src={currentImage || ""}
            size={120}
            style={{ minWidth: "120px", cursor: "pointer" }}
          />

          <div className="icon-edit-upload-avatar">
            <EditOutlined />
          </div>
        </div>
      </Upload>
      <Modal
        open={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        centered
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={() => setModalIsOpen(false)}>
            Hủy
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleSave}
            loading={isLoading}
          >
            Lưu
          </Button>,
        ]}
        className="upload-avatar-modal"
      >
        <div className="upload-avatar-content">
          {editorImage && (
            <div>
              <AvatarEditor
                ref={editorRef}
                image={editorImage}
                width={480}
                height={480}
                border={50}
                scale={scale}
                borderRadius={9999}
              />
              <Slider
                min={1}
                max={3}
                step={0.01}
                value={scale}
                onChange={handleScaleChange}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AvatarUpload;
