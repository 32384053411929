import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Checkbox, Col, Divider, Form, Row, Space, Tag, Tooltip, Typography, } from "antd";
import FormInput from "../../../form-input/FormInput";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import { message } from "antd/lib";
import { UrlPattern } from "../../../../utils/regex";
import { isEmpty } from "lodash";
import "./formCreateCourse.scss";
import { CloseOutlined, PlusOutlined, SaveOutlined, } from "@ant-design/icons";
import { ConstructCourseDetailType } from "../../../../types/course-construction";
import { Enterprises } from "../../../../types/assign-course-construction";
import { getEnterprises, getLecturersFromEnterprises } from "../../../../service/assign-course-construction";
import DrawerCustom from "../../../drawer/Drawer";
import { useAuthStore } from "../../../../stores/stores";

const validations = {
  name: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length < 6) {
        return Promise.reject(
          new Error("Tên khóa học phải từ 6 ký tự trở lên")
        );
      }
      if (value && value.length > 255) {
        return Promise.reject(
          new Error("Tên khóa học không vượt quá 255 ký tự")
        );
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên khóa học"));
      }
      return Promise.resolve();
    },
  },
  code: {
    required: true,
    validator: (_: unknown, value: any) => {
      if (!isEmpty(value) && !value.match(new RegExp(UrlPattern))) {
        return Promise.reject(
          new Error("Định dạng Mã khóa học không đúng, vui lòng nhâp lại")
        );
      }
      if (isEmpty(value)) {
        return Promise.reject(new Error("Vui lòng nhập mã khóa học"));
      }
      return Promise.resolve();
    },
  },
  department: {
    required: true,
    message: "Vui lòng chọn khoa khóa học áp dụng",
  },
  field: {
    required: true,
    message: "Vui lòng chọn chuyên ngành áp dụng",
  },
  courseLevel: {
    required: true,
    message: "Vui lòng chọn cấp độ khóa học",
  },
  units: {
    required: true,
    message: "Vui lòng chọn đơn vị công tác",
  },
  hostSchool: {
    required: true,
    message: "Vui lòng chọn trường chủ trì",
  },
  lecturerName: {
    required: true,
    message: "Vui lòng chọn giảng viên",
  },
  coopSchool: {
    required: true,
    message: "Vui lòng chọn trường hợp tác",
  },
};

type FormCreateCourseProps = {
  enterprises: Enterprises[];
  onFinish?: any;
  dataOptions?: any;
  industryGroup?: Enterprises[];
  industry?: Enterprises[];
  courseLevel?: Enterprises[];
  coursePlan?: Enterprises[];
  id?: any;
  detailData: ConstructCourseDetailType;
  isOpened?: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData: React.Dispatch<
    React.SetStateAction<ConstructCourseDetailType>
  >;
  setDataUpdate: (data: any) => void;
  setDataSponsorUpdate: (data: any) => void;
};

type StartSchool = {
  id: string,
  name: string
}

export const FormCreateCourse: FunctionComponent<FormCreateCourseProps> = ({
  enterprises,
  onFinish,
  dataOptions,
  industryGroup,
  industry,
  courseLevel,
  coursePlan,
  id,
  detailData,
  isOpened,
  setIsOpened,
  setDetailData,
  setDataUpdate,
  setDataSponsorUpdate,
}) => {
  const user = useAuthStore((state) => state.user);
  const [hadPlan, setHadPlan] = useState(false);
  const [countSchool, setCountSchool] = useState<any>([]);
  const [dataOption, setDataOption] = useState<any>({});
  const [form] = Form.useForm();
  const [error, setError] = useState<any>({});
  const [dataAssigners, setDataAssigners] = useState<any>([]);
  const [dataNotAssigners, setDataNotAssigners] = useState<any>([]);
  const [SponsorOptionLecturers, setSponsorOptionLecturers] = useState<Enterprises[]>([]);
  const [isSync, setIsSync] = useState(false);
  const [countSchoolSponsor, setCountSchoolSponsor] = useState<any>([{
    assigned: [{
      assignUserId: "",
      assignUserName: "",
      construct: false,
      instruct: true,
    }],
    enterpriseId: user?.enterpriseUuid || '',
    enterpriseName: "",
    isSponsor: true,
  }]);
  const [sponsorSchool, setSponsorSchool] = useState<StartSchool[]>([{
    id: user?.enterpriseUuid || '',
    name: user?.tenTruong || '',
  }]);

  useEffect(() => {
    getValue(user?.enterpriseUuid);
    form.resetFields();
    // setCountSchoolSponsor([]);
    setCountSchool([]);
    if (detailData?.coursePlanId) {
      setHadPlan(true)
    }
    if (detailData?.isSynchronizeCourse) {
      setIsSync(detailData?.isSynchronizeCourse);
    }
    if (detailData) {
      form.setFieldsValue({
        name: detailData?.name || "",
        code: detailData?.code || "",
        industryGroup: detailData?.faculties?.map((item: any, index: any) => { return item.id }),
        industry: detailData?.industries?.map((item: any, index: any) => { return item.id }),
        courseLevel: detailData?.courseLevel ? String(detailData?.courseLevel) : "Chọn cấp độ khóa học",
        coursePlanId: detailData?.coursePlanId,
      });
      getDetailCourseData();
    }
  }, [detailData]);

  const getDataCoopDefault = async (value: any, index: number) => {
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: value,
    };
    getLecturersFromEnterprises(data).then(async (res) => {
      setDataOption({ ...dataOption, [`lecturer${index}`]: res.data.content });
    });
  };

  const getValue = async (value: any) => {
    form.resetFields(["lecturerName"]);
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: value,
    };
    await getLecturersFromEnterprises(data).then((res) => {
      const content = res.data?.content;
      setSponsorOptionLecturers(content);
      setDataOption({ ...dataOption, [`lecturer`]: content });
    });
  };

  const getValueCooperative = (value: string, index: number) => {
    const itemResult = countSchool.filter(
      (item: any) => item.enterpriseId === value
    );
    if (isEmpty(itemResult)) {
      let newArr = [...countSchool];
      newArr[index].assigned = [];
      newArr[index].enterpriseId = value;
      setCountSchool(newArr);
    }
    form.resetFields([`lecturerCooperativeName${index}`]);
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: value,
    };
    getLecturersFromEnterprises(data).then(async (res) => {
      setDataOption({ ...dataOption, [`lecturer${index}`]: res.data.content });
    });
  };

  const addSchool = () => {
    if (countSchool.filter((item: any) => item.display).length === 5) {
      message.warning("Không thể tạo quá 5 trường hợp tác");
    } else {
      setCountSchool(
        countSchool.concat({
          id: countSchool.length + 1,
          display: true,
        })
      );
    }
  };

  const saveData = () => {
    if (isEmpty(countSchoolSponsor?.[0]?.assigned)) {
      setError({ ...error, [`lecturerName`]: true });
      return;
    } else {
      setDataUpdate(countSchool);
      setDataSponsorUpdate(countSchoolSponsor);
      form.submit();
      setError({ ...error, [`lecturerName`]: false });
    }
  };

  const handleAddTeacherSponsor = () => {
    if (countSchoolSponsor && countSchoolSponsor.length !== 0) {
      let arr = [...countSchoolSponsor];
      const newTeacher = {
        assignUserId: "",
        assignUserName: "",
        construct: false,
        instruct: false,
      };
      const newAssignSponsor = [
        ...countSchoolSponsor[0].assigned,
        ...[newTeacher],
      ];
      arr[0].assigned = newAssignSponsor;
      setCountSchoolSponsor(arr);
    }
  };

  const handleAddTeacherCoop = (index: number) => {
    if (countSchool && countSchool.length !== 0) {
      const newTeacher = {
        assignUserId: "",
        assignUserName: "",
        construct: false,
        instruct: false,
      };
      const newAssignSponsor = [
        ...countSchool[index].assigned,
        ...[newTeacher],
      ];
      let arr = [...countSchool];

      if (!isEmpty(arr)) arr[index].assigned = newAssignSponsor;
      setCountSchool(arr);
    }
  };

  const handleRemoveTeacherSponsor = (index: number) => {
    let copyData = [];
    copyData = [...countSchoolSponsor];
    copyData[0].assigned.splice(index, 1)
    const newAssignSponsor = copyData[0].assigned
    let arr = [...countSchoolSponsor];
    if (!isEmpty(arr)) arr[0].assigned = newAssignSponsor;
    setCountSchoolSponsor(arr);
  };

  const handleRemoveTeacherCoop = (index: number, i: number) => {
    let copyData = [];
    copyData = [...countSchool];
    copyData[index].assigned.splice(i, 1)
    const newAssignSponsor = copyData[0].assigned
    let arr = [...countSchool];
    if (!isEmpty(arr)) arr[index].assigned = newAssignSponsor;
    setCountSchool(arr);
  };

  const removeSchool = (id: string) => {
    const arr = countSchool.filter((item: any, index: any) => {
      if (index === id) {
        item.display = false;
      }
      return item;
    });
    setCountSchool(arr);
  };

  const handleChangeTeacherSponsor = (index: number, value: any) => {
    const itemResult = dataOption[`lecturer`].filter(
      (result: any) => result.assignUserId === value
    );
    let arr = [...countSchoolSponsor];
    const newItem = itemResult.map((i: any) => ({ ...i, "instruct": true }))
    if (!isEmpty(arr)) arr[0].assigned[index] = newItem[0];
    setCountSchoolSponsor(arr);
  };

  const handleChangeTeacherCoop = (index: number, i: number, value: any) => {
    const itemResult = dataOption[`lecturer${index}`].filter(
      (result: any) => result.assignUserId === value
    );
    let arr = [...countSchool];
    const newItem = itemResult.map((i: any) => ({ ...i, "instruct": true }))
    if (!isEmpty(arr)) arr[index].assigned[i] = newItem[0];
    setCountSchool(arr);
  };

  const handleCheckboxChangeSponsor = (
    index: number,
    value: any,
    mode: any
  ) => {
    let arr = [...countSchoolSponsor];
    if (mode === "construct") {
      arr[0].assigned[index].construct = value.target.checked;
      setCountSchoolSponsor(arr);
    } else {
      arr[0].assigned[index].instruct = value.target.checked;
      setCountSchoolSponsor(arr);
    }
  };

  const handleCheckboxChangeCoop = (
    index: number,
    i: number,
    value: any,
    mode: any
  ) => {
    let arr = [...countSchool];
    if (mode === "construct") {
      arr[index].assigned[i].construct = value.target.checked;
      setCountSchool(arr);
    } else {
      arr[index].assigned[index].instruct = value.target.checked;
      setCountSchool(arr);
    }
  };

  const renderListCooperationSchool = countSchool.map(
    (school: any, index: any) => {
      return (
        <div key={index}>
          {school.display ? (
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Row gutter={24}>
                    <Col span={22} className="filter-school mb-24">
                      <Form.Item
                        name={`cooperativeSchool${index}`}
                        label="Trường hợp tác"
                        required={true}
                      >
                        <FormItemDropdown
                          options={enterprises}
                          onChange={(value) => getValueCooperative(value, index)}
                          placeholder="Nhập từ khoá cần tìm"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} className="text-right icon-close">
                      <Tooltip placement="top" title={"Xoá"}>
                        <CloseOutlined
                          className="cursor-pointer"
                          onClick={() => removeSchool(index)}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <>
                        {school?.assigned?.map((item: any, i: number) => {
                          return (
                            <div key={i} className="teacher-select-container mb-24">
                              <Col className="" span={12}>
                                <Form.Item
                                  name={`lecturerCooperativeName${index}-${i}`}
                                  rules={[validations.lecturerName]}
                                  label="Giảng viên"
                                  initialValue={item.assignUserName}
                                >
                                  <FormItemDropdown
                                    value={item.assignUserName}
                                    options={dataOption[`lecturer${index}`]}
                                    className="full-width"
                                    onChange={(value) =>
                                      handleChangeTeacherCoop(index, i, value)
                                    }
                                    placeholder="Chọn giảng viên"
                                  />
                                </Form.Item>
                              </Col>

                              <Col span={4} className="check-box-sync mt-24">
                                <div className={`text-sync`}>
                                  <Checkbox
                                    onChange={(value) =>
                                      handleCheckboxChangeCoop(
                                        index,
                                        i,
                                        value,
                                        "instruct"
                                      )
                                    }
                                    checked={item.instruct}
                                  />
                                  Hướng dẫn
                                </div>
                                {/* </Form.Item> */}
                              </Col>
                              <Col span={4} className="check-box-sync mt-24">
                                {/* <Form.Item name="isSyncPlan"> */}
                                <div className={`text-sync`}>
                                  <Checkbox
                                    // disabled={!hadPlan}
                                    onChange={(value) =>
                                      handleCheckboxChangeCoop(
                                        index,
                                        i,
                                        value,
                                        "construct"
                                      )
                                    }
                                    checked={
                                      (item.construct && true) || false
                                    }
                                  />
                                  Xây dựng
                                </div>
                                {/* </Form.Item> */}
                              </Col>
                              {i !== 0 && (
                                <Col span={2} className="text-right icon-close">
                                  <Button
                                    title="Xóa"
                                    danger
                                    onClick={() => {
                                      handleRemoveTeacherCoop(index, i);
                                    }}
                                  >
                                    Xóa
                                  </Button>
                                </Col>
                              )}
                            </div>
                          )
                        }
                        )}
                        <Tag
                          className="mb-24 pointer"
                          onClick={() =>
                            handleAddTeacherCoop(index)
                          }
                        >
                          <PlusOutlined /> Thêm giảng viên
                        </Tag>
                      </>
                    </Col>
                  </Row>
                </Col>
                {countSchool.length - 1 !== index ? <Divider /> : null}
              </Row>
            </>
          ) : null}
        </div>
      )
    }
  );

  const getDefaultValue = async (value: any) => {
    let newArr = [...countSchoolSponsor];
    if (!isEmpty(newArr)) {
      newArr[0].enterpriseId = value;
      setCountSchoolSponsor(newArr);
    }

    form.resetFields(["lecturerName"]);
    const data = {
      keyword: "",
      page: 1,
      size: 10,
      enterpriseUUID: value,
    };
    await getLecturersFromEnterprises(data).then((res) => {
      const { data } = res.data;
      setSponsorOptionLecturers(data.data);
    });
  };

  const getDetailCourseData = async () => {
    const dataAssignersGet = detailData.assigners?.filter(
      (item: any) => item.isSponsor
    );
    const dataNotAssigners = detailData.assigners?.filter(
      (item: any) => !item.isSponsor
    );
    if (dataNotAssigners && dataNotAssigners.length !== 0) {
      const getDefaultValue = dataNotAssigners.map(
        (item: any, index: number) => {
          getDataCoopDefault(item?.enterpriseId, index);
        }
      );
    }
    if (!isEmpty(dataNotAssigners)) {
      const arr = dataNotAssigners?.map((item: any) => {
        return { ...item, [`display`]: true };
      });
      setCountSchool(arr);
    }

    if (!isEmpty(dataAssignersGet)) {
      setCountSchoolSponsor(dataAssignersGet);
      getDefaultValue(dataAssignersGet?.[0]?.enterpriseId);
    }
    setDataAssigners(dataAssignersGet);
    setDataNotAssigners(dataNotAssigners);
    form.setFieldValue("hostSchool", dataAssignersGet?.[0]?.enterpriseId);
    form.setFieldValue(
      "cooperativeSchool0",
      !isEmpty(dataNotAssigners?.[0]) && dataNotAssigners?.[0]?.enterpriseId
    );
    form.setFieldValue(
      "cooperativeSchool1",
      !isEmpty(dataNotAssigners?.[1]) && dataNotAssigners?.[1]?.enterpriseId
    );
    form.setFieldValue(
      "cooperativeSchool2",
      !isEmpty(dataNotAssigners?.[2]) && dataNotAssigners?.[2]?.enterpriseId
    );
    form.setFieldValue(
      "cooperativeSchool3",
      !isEmpty(dataNotAssigners?.[3]) && dataNotAssigners?.[3]?.enterpriseId
    );
    form.setFieldValue(
      "cooperativeSchool4",
      !isEmpty(dataNotAssigners?.[4]) && dataNotAssigners?.[4]?.enterpriseId
    );
  };
  const filterOptions = (selectedIds: string | any[], options: any[]) => {
    if (selectedIds && options) {
      return options.map(option => ({
        ...option,
        disabled: selectedIds.includes(option.assignUserName),
      }));
    } else {
      return [];
    }
  };

  const closeForm = () => {
    setDetailData({});
    setIsOpened(false);
    form.resetFields();
    // setCountSchool([]);
    // setCountSchoolSponsor([]);
    setError({});
  }

  return (
    <>
      <DrawerCustom
        title={id && id !== 0 ? "Chỉnh sửa khóa học" : "Thêm mới khóa học"}
        open={isOpened}
        onClose={closeForm}
        extraNode={
          <Space>
            <Button
              onClick={closeForm}
              className="btn btn-outlined"
            >
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={saveData}
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
        widthDrawer={736}
      >
        <Form
          className="form-create-course"
          layout={"vertical"}
          form={form}
          onFinish={onFinish}
          name="create-course-detail"
        >
          <Row gutter={16}>
            <Col className="mb-24" span={12}>
              <Form.Item
                name="name"
                rules={[validations.name]}
                label="Tên khóa học"
              >
                <FormInput placeholder="Nhập tên khóa học" />
              </Form.Item>
              <span className="label-down-text">
                Tên hiển thị công khai cho khóa học của bạn
              </span>
            </Col>
            <Col className="mb-24" span={12}>
              <Form.Item
                name="code"
                label="Mã khóa học"
                rules={[validations.code]}
              // hasFeedback
              >
                <FormInput placeholder="Nhập mã khóa học" />
              </Form.Item>
              <span className="label-down-text ">
                Số định danh duy nhất xác định khoá học của bạn
              </span>
            </Col>
            <Col className="mb-24" span={24}>
              <Form.Item
                name="industryGroup"
                label="Khoa"
                rules={[validations.department]}
              >
                <FormItemDropdown
                  // priority="code"
                  modeDropdown="multiple"
                  options={industryGroup}
                  allowClear={true}
                  placeholder="Chọn khoa khóa học áp dụng"
                  
                />
              </Form.Item>
            </Col>
            <Col className="mb-24" span={24}>
              <Form.Item
                name="industry"
                label="Chuyên ngành"
                rules={[validations.field]}
              >
                <FormItemDropdown
                  // priority="code"
                  modeDropdown="multiple"
                  options={industry}
                  allowClear={true}
                  placeholder="Chọn chuyên ngành áp dụng"
                />
              </Form.Item>
            </Col>
            <Col className="mb-24" span={24}>
              <Form.Item
                name="courseLevel"
                label="Cấp độ"
                rules={[validations.courseLevel]}
              >
                <FormItemDropdown
                  options={courseLevel}
                  allowClear={true}
                  placeholder="Chọn cấp độ khóa học"
                />
              </Form.Item>
            </Col>
            <Col className="mb-24" span={12}>
              <Form.Item name="coursePlanId" label="Kế hoạch">
                <FormItemDropdown
                  options={coursePlan}
                  allowClear={true}
                  placeholder="Chọn kế hoạch muốn đồng bộ"
                  onChange={(value) => {
                    if (value) setHadPlan(true);
                    else setHadPlan(false);
                  }}
                />
              </Form.Item>
              <span className="label-down-text">
                Kế hoạch xây dựng đã được phê duyệt
              </span>
            </Col>
            <Col span={12} className="check-box-sync mb-24">
              <Form.Item name="isSyncPlan">
                <div className={`text-sync ${hadPlan ? "" : "text-sync-grey"}`}>
                  <Checkbox
                    disabled={!hadPlan}
                    onChange={() => setIsSync(!isSync)}
                    checked={isSync}
                  />{" "}
                  Đồng bộ cấu trúc khoá học
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Typography.Title level={5}>Trường chủ trì khoá học</Typography.Title>
          <Row>
            <Col span={24} className="filter-school mb-24">
              <Form.Item
                name="hostSchool"
                rules={[validations.hostSchool]}
                label="Trường chủ trì"
                initialValue={sponsorSchool[0].name}
              >
                <FormItemDropdown
                  options={sponsorSchool}
                  allowClear={false}
                  onChange={getValue}
                  placeholder="Nhập từ khoá cần tìm"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row>
                <Col className="" span={24}>
                  {countSchoolSponsor?.[0]?.assigned.map((item: any, index: number) => {
                    const selectSponsor = countSchoolSponsor?.[0].assigned.map((i: any) => i.assignUserName).filter(Boolean);
                    const filterData = filterOptions(selectSponsor, dataOption['lecturer']);
                    let defaultValue;
                    if (index === 0) {
                      if (item.assignUserName) {
                        defaultValue = item.assignUserName;
                      } else {
                        if (filterData.length > 0) {
                          const userHoVaTen = user?.hoVaTen;
                          const existUser = filterData.find((x: any) => { return x.assignUserName === userHoVaTen });
                          let updatecountSchoolSponsor = countSchoolSponsor;
                          let firstSchoolSponsor = updatecountSchoolSponsor[0].assigned[0];
                          if (existUser) {
                            defaultValue = existUser.assignUserName;
                            firstSchoolSponsor.assignUserId = existUser.assignUserId;
                            firstSchoolSponsor.assignUserName = existUser.assignUserName;
                          } else if (filterData[0].assignUserName) {
                            defaultValue = filterData[0].assignUserName;
                            firstSchoolSponsor.assignUserId = filterData[0].assignUserId;
                            firstSchoolSponsor.assignUserName = filterData[0].assignUserName;
                          }
                          setCountSchoolSponsor(updatecountSchoolSponsor);
                        } else {
                          defaultValue = undefined;
                        }
                      }
                    } else {
                      defaultValue = item.assignUserName;
                    }
                    return (
                      <div key={index} className="teacher-select-container mb-24">
                        <Col className="" span={12}>
                          <Form.Item
                            name={`lecturerName-${index}`}
                            rules={[validations.lecturerName]}
                            label="Giảng viên"
                            initialValue={defaultValue}
                          >
                            <FormItemDropdown
                              value={defaultValue}
                              options={filterData}
                              className="full-width"
                              onChange={(value) =>
                                handleChangeTeacherSponsor(index, value)
                              }
                              placeholder="Chọn giảng viên"
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4} className="check-box-sync mt-24">
                          <div className={`text-sync`}>
                            <Checkbox
                              onChange={(value) =>
                                handleCheckboxChangeSponsor(index, value, "instruct")
                              }
                              checked={(item.instruct && true) || false}
                            />
                            Hướng dẫn
                          </div>
                        </Col>
                        <Col span={4} className="check-box-sync mt-24">
                          <div className={`text-sync`}>
                            <Checkbox
                              onChange={(value) =>
                                handleCheckboxChangeSponsor(index, value, "construct")
                              }
                              checked={(item.construct && true) || false}
                            />
                            Xây dựng
                          </div>
                        </Col>
                        {index !== 0 && (
                          <Col span={2} className="text-right icon-close">
                            <Button
                              title="Xóa"
                              danger
                              onClick={() => {
                                handleRemoveTeacherSponsor(index);
                              }}
                            >
                              Xóa
                            </Button>
                          </Col>
                        )}
                      </div>
                    )
                  }
                  )}

                  <Tag className="mb-24 pointer"
                    onClick={() =>
                      handleAddTeacherSponsor()
                    }
                  >
                    <PlusOutlined /> Thêm giảng viên
                  </Tag>
                </Col>
                {error.lecturerName ? (
                  <p style={{ color: "red" }}>
                    Vui lòng chọn giảng viên
                  </p>
                ) : null}
              </Row>
            </Col>
          </Row>
          {!isEmpty(countSchool) && countSchool.length > 0 ? (
            <>
              <Typography.Title level={5}>
                Trường hợp tác xây dựng
              </Typography.Title>
              {renderListCooperationSchool}
            </>
          ) : null}
          <>
            <Button
              type="primary"
              onClick={addSchool}
              className="w-full button-sticky"
            >
              <PlusOutlined />
              Thêm Trường hợp tác
            </Button>
          </>
        </Form>
      </DrawerCustom>
    </>
  );
};
