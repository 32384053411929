import React, { useMemo } from "react";
import { ListAnswer } from "../../../../../../types/survey-managment/survey";
// import QuestionType1 from "./QuestionType1";
import QuestionType2 from "./QuestionType2";
import QuestionType3 from "./QuestionType3";
import QuestionType4 from "./QuestionType4";
import QuestionType5 from "./QuestionType5";
import QuestionType6 from "./QuestionType6";
import QuestionType7 from "./QuestionType7";
import QuestionType8 from "./QuestionType8";
import QuestionType1 from "./QuestionType1";

interface QuestionViewProps {
    typeQuestionId: number;
    listAnswer?: ListAnswer[];
    handleUpdateAnswerData?: (data: ListAnswer[]) => void;
}

const QuestionView: React.FC<QuestionViewProps> = ({
    typeQuestionId,
    listAnswer,
    handleUpdateAnswerData,
}) => {
    const updateListAnswer = (listAnswer: ListAnswer[]) => {
        handleUpdateAnswerData && handleUpdateAnswerData(listAnswer);
    };

    const listAnswers = useMemo(() => listAnswer, [listAnswer]);

    switch (typeQuestionId) {
        case 1:
            return <QuestionType1 listOptions={listAnswers} setUpdateListAnswer={updateListAnswer} />;
        case 2:
            return <QuestionType2 listOptions={listAnswers} setUpdateListAnswer={updateListAnswer} />;
        case 3:
            return <QuestionType3 listOptions={listAnswers} setUpdateListAnswer={updateListAnswer} />;
        case 4:
            return <QuestionType4 listOptions={listAnswers} setUpdateListAnswer={updateListAnswer} />;
        case 5:
            return <QuestionType5 listOptions={listAnswers} setUpdateListAnswer={updateListAnswer} />;
        case 6:
            return <QuestionType6 listOptions={listAnswers} setUpdateListAnswer={updateListAnswer} />;
        case 7:
            return <QuestionType7 listOptions={listAnswers} setUpdateListAnswer={updateListAnswer} />;
        case 8:
            return <QuestionType8 listOptions={listAnswers} setUpdateListAnswer={updateListAnswer} />;
        default:
            return null;
    }
    // return <div>Question View</div>;
};

export default QuestionView;
