import { FunctionComponent, useEffect, useState } from "react";
import "./index.scss";
import DetailInfo from "../../detail/components/detail-info/DetailInfo";
import { Layout, Tabs, TabsProps } from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import { useParams } from "react-router-dom";
import { HistoryOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
// import { DetailContent } from "../components/detail-content/DetailContent";
import UserDocument from "../../detail/components/user-document/UserDocument";
import { useAppStore } from "../../../stores/stores";
import { get } from "lodash";
import { getAccountDetail } from "../../../service/list-account-users/listAccountUsersApi";
import { useLocation } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import PlanInformation from "../../../components/course-construction/assign-course-construction/plan-information";
import DetailedAssignment from "../../../components/course-construction/assign-course-construction/detailed-assignment";
import BrowsingResults from "../../../components/course-construction/assign-course-construction/browsing-results";
import { getDetailCourse } from "../../../service/assign-course-construction";
import { DetailCourseData } from "../../../types/assign-course-construction";
import CourseBrowsingResults from "../../../components/course-construction/assign-course-construction/browsing-results/course-browsing";

const { Content } = Layout;
const imgPath = '/images/';


const DetailCourseConstruction: FunctionComponent = () => {
	const location = useLocation()
	const [detailData, setDetailData] = useState<any>({
		appraisedDate: "",
		appraisers: [],
		approveName: "",
		approveUserId: 0,
		approvedDate: "",
		assigners: [],
		description: "",
		id: 0,
		name: "",
		isApproved: false,
		isAssigned: false,
		sections: [],
	})
	const { accountInfo, setAccountDetail } = useAppStore((state) => {
		return {
			accountInfo: state?.accountInfo,
			setAccountDetail: state?.setAccountDetail
		}
	})

	const params = useParams()

	const getDetailCourseData = async () => {
		await getDetailCourse(params?.id).then(res => {
			const { data } = res.data
			setDetailData(data)
		})
	}

	useEffect(() => {
		getDetailCourseData()
	}, [])

	const items: TabsProps['items'] = [
		{
			key: '1',
			// icon: <UserOutlined />,
			label: 'Thông tin kế hoạch',
			children:
				<div className="wrapper">
					<section className="detail-account detail-course">
						<div className="form-detail">
							<PlanInformation planInfo={location.state} detailData={detailData} />
						</div>
					</section></div>
		},
		{
			key: '2',
			// icon: <HistoryOutlined />,
			label: 'Phân công chi tiết',
			children:
				<div className="wrapper detail-course-construction">
					<section className="detail-account">
						<div className="form-detail">
							<DetailedAssignment detailData={detailData} />
						</div></section>
				</div>
		},
		{
			key: '3',
			// icon: <LockOutlined />,
			label: 'Kết quả duyệt',
			children:
				<div className="wrapper browsing-results">
					<section className="detail-account">
						<div className="form-detail">
							<CourseBrowsingResults planInfo={location.state} detailData={detailData} />
						</div>
					</section>
				</div>
		}
	];

	const listBreadcrumb = [
		{
			title: 'Xây dựng khoá học',
		},
		{
			title: <a href={`/course-construction/${routesConfig.assignCourseConstruction}`}>Phân công xây dựng khoá học</a>,
		},
		{
			title: location.state.name,
		},
	]

	return (
		<>
			<Content>
				<PageHeader title={location.state.name} subTitle="Chi tiết" listBreadcrumb={listBreadcrumb} arrowLeft={imgPath + 'arrowLeft.svg'}
					positionItem="column"
					breadcrumb={true}
				/>
				<div className="tabs-heading">
					<Tabs defaultActiveKey="1" items={items} />
				</div>
			</Content>
		</>
	);
};

export default DetailCourseConstruction;
