import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import "./filter-group.scss";
import { FormInstance } from "antd/lib";
// import { dataStatus } from '../../../pages/list-user/constants/data';
import { get } from "lodash";

import type { DatePickerProps } from "antd";
import { Dayjs } from "dayjs";
import { FilterData } from "./filter";
import { getLecturerAssign } from "../../../../service/assign-course-construction";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  clearFilter: () => void;
  onFinish?: () => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  dataOptions?: any;
  tab: string;
  filterData: FilterData;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  handleCloseFilter,
  onFinish,
  form,
  showFilter,
  clearFilter,
  dataOptions,
  filterData,
  setFilterData,
  tab,
}) => {
  const { RangePicker } = DatePicker;
  const [dataOptionsFilter, setDataOptionsFilter] = useState<any>([]);

  const getListLecturerFilter = async () => {
    await getLecturerAssign().then((res) => {
      setDataOptionsFilter(res.data.data);
    });
  };

  useEffect(() => {
    getListLecturerFilter();
  }, []);

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        {tab === "1" ? (
          // chưa phân công
          <Form form={form} name="filterForm" onFinish={onFinish}>
            <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
              <Col
                xl={{ span: 10 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              ></Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Typography.Title level={5}>Ngày tạo kế hoạch</Typography.Title>
                <Form.Item name="planCreationDate">
                  <RangePicker
                    placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                    format="YYYY-MM-DD"
                    value={filterData.planCreationDate}
                    onChange={(_value, dateString: any) =>
                      setFilterData(dateString, "planCreationDate")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Typography.Title level={5}>
                  Ngày phê duyệt kế hoạch
                </Typography.Title>
                <Form.Item name="planApprovalDate">
                  <RangePicker
                    placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                    format="YYYY-MM-DD"
                    value={filterData.planApprovalDate}
                    onChange={(_value, dateString: any) =>
                      setFilterData(dateString, "planApprovalDate")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 3 }}
                md={{ span: 9 }}
                sm={{ span: 9 }}
                xs={{ span: 24 }}
                className="flex align-end"
              >
                <div className="group-btn--filter">
                  <Button
                    className="btn btn-primary filter-btn"
                    htmlType="submit"
                    onClick={() => form.submit()}
                  >
                    <FilterOutlined />
                    <Typography.Text>Lọc</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-outlined clear-filter-btn"
                    onClick={clearFilter}
                  >
                    <DeleteOutlined />
                    <Typography.Text>Xóa bộ lọc</Typography.Text>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        ) : (
          //đã phân công
          <Form form={form} name="filterForm" onFinish={onFinish}>
            <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
              <Col
                xl={{ span: 1 }}
                md={{ span: 1 }}
                sm={{ span: 1 }}
                xs={{ span: 24 }}
              ></Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Typography.Title level={5}>Ngày tạo kế hoạch</Typography.Title>
                <Form.Item name="planCreationDate">
                  <RangePicker
                    placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                    format="YYYY-MM-DD"
                    value={filterData.planCreationDate}
                    onChange={(_value, dateString: any) =>
                      setFilterData(dateString, "planCreationDate")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Typography.Title level={5}>
                  Ngày duyệt kế hoạch
                </Typography.Title>
                <Form.Item name="planApprovalDate">
                  <RangePicker
                    placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                    format="YYYY-MM-DD"
                    value={filterData.planCreationDate}
                    onChange={(_value, dateString: any) =>
                      setFilterData(dateString, "planApprovalDate")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Typography.Title level={5}>Ngày phân công</Typography.Title>
                <Form.Item name="assignmentDate">
                  <RangePicker
                    placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                    format="YYYY-MM-DD"
                    value={filterData.assignmentDate}
                    onChange={(_value, dateString: any) =>
                      setFilterData(dateString, "assignmentDate")
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Typography.Title level={5}>
                  Giảng viên được phân công
                </Typography.Title>
                <Form.Item name="assignedInstructors">
                  <FormItemDropdown
                    modeDropdown="multiple"
                    placeholder="Chọn giảng viên"
                    onChange={(option) =>
                      setFilterData(option, "assignedInstructors")
                    }
                    options={dataOptionsFilter}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 3 }}
                md={{ span: 9 }}
                sm={{ span: 9 }}
                xs={{ span: 24 }}
                className="flex align-end"
              >
                <div className="group-btn--filter">
                  <Button
                    className="btn btn-primary filter-btn"
                    htmlType="submit"
                    onClick={() => form.submit()}
                  >
                    <FilterOutlined />
                    <Typography.Text>Lọc</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-outlined clear-filter-btn"
                    onClick={clearFilter}
                  >
                    <DeleteOutlined />
                    <Typography.Text>Xóa bộ lọc</Typography.Text>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </>
  );
};

export default FormFilter;
