import { FunctionComponent, useEffect, useState } from "react";
import { Button, Col, Layout, Row, Space, Tabs, TabsProps } from "antd";
import PageHeader from "../../../../components/page-header/PageHeader";
import { useLocation, useParams } from "react-router-dom";
import { message } from "antd/lib";
import { useAppStore, useCourseStructure } from "../../../../stores/stores";

import { DetailContent } from "./component/detail-content/DetailContent";
import {
  getListMoocCourseDescriptionDocumentById,
  getListMoocCourseStructureById,
  getListMoocCourseStructureChapterById,
  getMoocCourseConstructionPlanById,
} from "../../../../service/course-plan-managment/planning-course-construction/PlanningCourseConstructionApi";
import {
  TypeChapterList,
  TypeCourseDescriptionDocument,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../../types/course-plan-managment/mooc-course-construction-plan";
import DescriptionDocument from "./component/description-document/DescriptionDocument";
import IsLoading from "./component/is-loading/IsLoading";
import DetailInfo from "./component/detail-info/DetailInfo";
import PreviewCourseStructure from "./component/preview-course-structure/PreviewCourseStructure";
import CourseStructure from "./component/course-structure/CourseStructure";
import PreviewDescriptionDocument from "./component/preview-description-document/PreviewDescriptionDocument";
import ApproveCouresConstruction from "../list-planning-course-construction/components/approve-coures-constuction/approveCouresConstruction";
import DetailConfirm from "../../mooc-approval-plan/components/detail-confirm/DetailConfirm";

const { Content } = Layout;
const imgPath = "/images/";

const DetailPlanningCourseConstruction: FunctionComponent = () => {
  const { id } = useParams();
  const location = useLocation();
  const [detailData, setDetailData] = useState<any>({}); //DetailTypeMoocCourseConstructionPlan
  const [ltsIndustry, setLtsIndustry] = useState<any[]>([]); //DetailTypeMoocCourseConstructionPlan
  const [ltsIndustryGroup, setLtsIndustryGroup] = useState<any[]>([]); //DetailTypeMoocCourseConstructionPlan
  const [ltsCollabUniversity, setLtsCollabUniversity] = useState<any[]>([]);
  const [hostUniversity, setHostUniversity] = useState<any[]>([]);
  const { state } = location;

  const mode = state && state.mode ? state.mode : null;
  const { accountInfo, setAccountDetail } = useAppStore((state) => {
    return {
      accountInfo: state?.accountInfo,
      setAccountDetail: state?.setAccountDetail,
    };
  });

  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [dataListCourseStructure, setDataListCourseStructure] = useState<
    TypeChapterList[]
  >([]);

  const [dataDescriptionDocument, setDataDescriptionDocument] = useState<
    TypeCourseDescriptionDocument[]
  >([]);

  const getDetailMoocCourseConstructionPlanData = async (id: any) => {
    try {
      await getMoocCourseConstructionPlanById(id)
        .then((res) => {
          const data = res.data.data;
          setDetailData(data);
          const allIndustries = data.mooc_construction_plan_industry.flatMap(
            (e: any) => e.mooc_industry
          );
          setLtsIndustry(allIndustries);
          const allIndustryGroups =
            data.mooc_construction_plan_industry_group.flatMap(
              (e: any) => e.mooc_industry_group
            );
          setLtsIndustryGroup(allIndustryGroups);
          const allCollabUniversity =
            data.mooc_construction_plan_collaborator.flatMap(
              (e: any) => e.mooc_university
            );
          setLtsCollabUniversity(allCollabUniversity);
          setHostUniversity(data.mooc_university);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListMoocCourseStructureByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureById(id)
        .then((res) => {
          const data = res.data.data;
          setDataListCourseStructure(changeToChapterList(data));
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListMoocCourseStructureChapterByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureChapterById(id)
        .then((res) => {
          const data = res.data.data;
          setListChapter(data);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListMoocCourseDescriptionDocumentByIData = async (id: any) => {
    {
      try {
        await getListMoocCourseDescriptionDocumentById(id)
          .then((res) => {
            const data = res.data.data;
            setDataDescriptionDocument(data);
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });
      } catch (error) {
        console.log(error);
        message.error("Có lỗi chưa xác định");
      }
    }
  };

  const [activeTab, setActiveTab] = useState<string>(state.numberTab);
  useEffect(() => {
    getDetailMoocCourseConstructionPlanData(id);
    getListMoocCourseStructureByIdData(id);
    getListMoocCourseStructureChapterByIdData(id);
    getListMoocCourseDescriptionDocumentByIData(id);
  }, [id, activeTab]);

  const changeToChapterList = (dataCourseStructure: TypeChapterList[]) => {
    const level1 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 1)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level2 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 2)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level3 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 3)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );

    const chapterList: TypeChapterList[] = level1.map((l1, index) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        orderNumber: l1.orderNumber,
        isDeleted: l1.isDeleted,
        subjectList: [],
        moocCoursePlanAssignment: l1.moocCoursePlanAssignment,
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2
        .filter((l2) => l2.parentId === l1.id)
        .sort((a, b) =>
          a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
        );

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject, index) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          orderNumber: subject.orderNumber,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3
          .filter((l3) => l3.parentId === subject.id)
          .sort((a, b) =>
            a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
          );

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson, index) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          orderNumber: lesson.orderNumber,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });
    return chapterList;
  };

  const [listChapter, setListChapter] = useState<TypeCourseStructure[]>([]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <DetailContent>
          <DetailInfo
            detailData={{
              ...detailData,
              approval_status: state?.record?.approvalStatus ?? "",
              approval_level_name: state?.record?.approvalLevelName ?? "",
            }}
            ltsIndustry={ltsIndustry}
            ltsIndustryGroup={ltsIndustryGroup}
            ltsCollabUniversity={ltsCollabUniversity}
            hostUniversity={hostUniversity}
          />
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: "Cấu trúc khóa học",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <>
          {mode !== "view" && (
            <DetailContent>
              <CourseStructure
                dataCourseStructure={dataListCourseStructure}
                setDataCourseStructure={setDataListCourseStructure}
                detailData={detailData}
              />
            </DetailContent>
          )}
          {mode === "view" && (
            <DetailContent>
              <PreviewCourseStructure
                dataCourseStructure={dataListCourseStructure}
              />
            </DetailContent>
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Tài liệu mô tả",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <>
          {mode === "view" && (
            <DetailContent>
              <PreviewDescriptionDocument
                listChapter={listChapter}
                dataDescriptionDocument={dataDescriptionDocument}
              />
            </DetailContent>
          )}
          {mode !== "view" && (
            <DetailContent isReadOnly={mode === "view" ? true : false}>
              <DescriptionDocument
                listChapter={listChapter}
                dataDescriptionDocument={dataDescriptionDocument}
                setDataDescriptionDocument={setDataDescriptionDocument}
              />
            </DetailContent>
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Kết quả duyệt",
      children: <DetailConfirm />,
    },
    // {
    //   key: "4",
    //   label: "Kết quả duyệt",
    //   children: isLoading ? (
    //     <IsLoading />
    //   ) : (
    //     <DetailContent>
    //       <ApprovalResult courseConstructPlanId={id} />
    //     </DetailContent>
    //   ),
    // },
  ];

  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: (
        <a
          href={`/course-plan/organize-course-plan/planning-course-construction-list`}
        >
          Lập kế hoạch khóa học
        </a>
      ),
    },
    {
      title: detailData?.registration_name || "",
    },
  ];

  const showMessage = (messageContent: string) => {
    if (messageContent.includes("thành công")) {
      message.success(messageContent);
    } else {
      message.error(messageContent);
    }
  };

  const { saveDraftCourseStructure, saveDraftDescriptionDocument } =
    useCourseStructure((state) => {
      return {
        saveDraftCourseStructure: state.saveDraftCourseStructure,
        saveDraftDescriptionDocument: state.saveDraftDescriptionDocument,
      };
    });

  const addWherePostToList = (list: TypeChapterList[]) => {
    return list.map((item) => ({
      ...item,
      wherePost: 1,
    }));
  };
  const handleSaveDraftCourseStructure = async (
    type?: string,
    activeTab?: number
  ) => {
    const updatedDataList = addWherePostToList(dataListCourseStructure);
    console.log("updatedDataList", updatedDataList);
    // setIsLoading(true);
    saveDraftCourseStructure(
      showMessage,
      updatedDataList,
      Number(id),
      type,
      activeTab
    );
    setIsLoading(false); // Kết thúc dù có lỗi xảy ra hay không
  };

  const handleSaveDraftDescriptionDocument = async (
    type?: string,
    activeTab?: number
  ) => {
    setIsLoading(true);

    await saveDraftDescriptionDocument(
      showMessage,
      dataDescriptionDocument,
      Number(id),
      type,
      activeTab
    );

    setIsLoading(false);
  };
  const [isOpenModalApprove, setIsOpenModalApprove] = useState<boolean>(false);
  const [browseData, setBrowseData] = useState<any>();

  const closeApproveModal = () => {
    setIsOpenModalApprove(false);
    getDetailMoocCourseConstructionPlanData(id);
  };

  // console.log(`detail:::`, detailData)
  return (
    <>
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={"Xem chi tiết " + detailData?.registration_name}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <Tabs
            activeKey={activeTab} // Set active tab
            onChange={(key) => setActiveTab(key)}
            items={items}
            tabBarExtraContent={
              <Row>
                <Space>
                  <Col>
                    {Number(activeTab) === 2 && (
                      <Button
                        type="default"
                        onClick={() =>
                          handleSaveDraftCourseStructure(
                            "manualSave",
                            Number(activeTab)
                          )
                        }
                        disabled={
                          mode === "view"
                            ? true
                            : detailData.construction_plan_status
                            ? detailData.construction_plan_status !== 1
                            : true
                        }
                      >
                        Lưu bản nháp
                      </Button>
                    )}
                    {Number(activeTab) === 3 && (
                      <Button
                        type="default"
                        onClick={() =>
                          handleSaveDraftDescriptionDocument(
                            "manualSave",
                            Number(activeTab)
                          )
                        }
                        disabled={
                          mode === "view"
                            ? true
                            : detailData.construction_plan_status
                            ? detailData.construction_plan_status !== 1
                            : true
                        }
                      >
                        Lưu tài liệu
                      </Button>
                    )}
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      disabled={
                        mode === "view"
                          ? true
                          : detailData.construction_plan_status
                          ? detailData.construction_plan_status !== 1
                          : true
                      }
                      onClick={() => {
                        setIsOpenModalApprove(true);
                        setBrowseData(detailData);
                      }}
                    >
                      Gửi duyệt
                    </Button>
                    <ApproveCouresConstruction
                      open={isOpenModalApprove}
                      handleCancel={() => {
                        closeApproveModal();
                      }}
                      browseData={browseData}
                    />
                  </Col>
                </Space>
              </Row>
            }
          />
        </div>
      </Content>
    </>
  );
};

export default DetailPlanningCourseConstruction;
