const download = function (data: BlobPart, filename: any, type: string) {
  const bom = "\uFEFF"; // UTF-8 BOM
  let blob = new Blob([bom + data], { type: "text/csv;charset=utf-8;" });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", `${filename}${type}`);
  a.click();
};

function convertToCSV(data: any) {
  const array = [Object.keys(data[0])].concat(data);

  return array
    .map((it) => {
      let values = Object.values(it);
      return values.map((value) => {
        if (!value) return "";
        else if (Array.isArray(value)) return Object.values(value[0])[0];
        else if (typeof value === "object") return Object.values(value)[0];
        return value.toString();
      });
    })
    .join("\n");
}


export const exportData = async function (
  data: any,
  filename: any,
  type: string
) {
  if (!data || data.length === 0) return;
  const csvdata = convertToCSV(data);
  download(csvdata, filename, type);
};
