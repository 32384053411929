import React, { useEffect, useState } from "react";
import { VideoAnswerInfo } from "../../../../types/course-construction";
import { Button, Checkbox, CheckboxProps, Col, GetProp, message, Radio, Row, Typography, Upload, } from "antd";
import ReactPlayer from "react-player";
import { DeleteOutlined, LoadingOutlined, PlusOutlined, } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { confirmMessage } from "../../../../pages/page-builder/grapesjs-service";
import { isEmpty } from "lodash";
import { UploadProps } from "antd/lib";
import { useAddLesson, useAuthStore } from "../../../../stores/stores";
import { DEFAULT_MAX_LENGTH_COURSE_TEXTAREA, initialQuestionSettingValue, TypeCreateQuestion } from "../../../../constants";
import { TextAreaProps } from "antd/lib/input";
import { BASE_URL_V2 } from "../../../../config/api/configApiv2";

type QuestionVideoProps = {
  data: VideoAnswerInfo;
  handleUpdateVideoAnswer: (data: VideoAnswerInfo, typeAction: number) => void;
  valueRadio?: any;
};

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const QuestionsVideo = ({ data, handleUpdateVideoAnswer, valueRadio, }: QuestionVideoProps) => {
  const { accessToken } = useAuthStore();

  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);

  const [dataAnswer, setDataAnswer] = useState<VideoAnswerInfo>(data);
  const [loading, setLoading] = useState(false);
  const [disableChecked, setDisableChecked] = useState<boolean>(false);

  useEffect(() => {
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      if (Object.keys(settingQuestion).length > 0) {
        setDisableChecked(!settingQuestion.canEnterAnswer);
      } else {
        setDisableChecked(!initialQuestionSettingValue.canEnterAnswer);
      }
    }
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      setDisableChecked(false);
    }
  }, [settingQuestion]);

  const uploadBeforeFile = (fileUpload: FileType) => {
    setLoading(true);
    const isVideo = fileUpload.type === "video/mp4";
    if (!isVideo) {
      message.error("Bạn phải tải lên file video/mp4");
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }
    if (fileUpload.size / 1024 / 1024 > 100) {
      message.error("File tải lên không vượt quá 100mb");
      setTimeout(() => {
        setLoading(false);
      }, 300)
    }
    return isVideo;
  };

  // @ts-ignore
  const onChange: UploadProps["onChange"] = (fileUpload) => {
    let answer = dataAnswer;
    if (fileUpload.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (fileUpload.file.status === "error") {
      message.error("Xảy ra lỗi khi tải ảnh lên !");
    }

    if (fileUpload.file.status === "done") {
      message.success("Tải video lên thành công!");
      setLoading(false);
      const { response } = fileUpload.file;
      answer.filePath = response.filePath;
      answer.fileName = response.fileName;
      setDataAnswer(answer);
      handleUpdateVideoAnswer(dataAnswer, 1);
    }
  };


  const handleChangeText: TextAreaProps["onChange"] = (e) => {
    const saveAnswer = dataAnswer;
    saveAnswer.value = e.target.value;
    setDataAnswer(saveAnswer);
    handleUpdateVideoAnswer(saveAnswer, 2);
  };

  const handleChangeDisplay: CheckboxProps["onChange"] = (e) => {
    const saveAnswer = dataAnswer;
    saveAnswer.isDisplayContent = e.target.checked;
    setDataAnswer(saveAnswer);
    handleUpdateVideoAnswer(saveAnswer, 3);
  };

  const handleDeleteSection = () => {
    confirmMessage(
      "Xóa lựa chọn",
      0,
      "Bạn có chắc muốn xóa lựa chọn này ?",
      () => {
        handleUpdateVideoAnswer(dataAnswer, 0);
      }
    );
  };

  return (
    <section className="answer">
      <Radio value={dataAnswer.id} disabled={disableChecked} />
      <Upload
        accept=".mp4"
        listType="picture-card"
        className="avatar-uploader ml-2 mb-1"
        showUploadList={false}
        action={BASE_URL_V2 + "/mooc-course-block-quiz/upload-file"}
        headers={{ Authorization: `Bearer ${accessToken}` }}
        onChange={onChange}
        beforeUpload={uploadBeforeFile}
      >
        {loading ? (
          <div className="upload-content">
            <LoadingOutlined />
          </div>
        ) : !isEmpty(dataAnswer.filePath) ? (
          <ReactPlayer
            width={88}
            height={102}
            url={dataAnswer.filePath ?? undefined}
            controls={true}
          />
        ) : (
          <div className="upload-content">
            <PlusOutlined />
            <span>Tải video</span>
          </div>
        )}
      </Upload>
      <div className="mb-3 ml-2 form-value w-full">
        <Col>
          <Row>
            <Col span={24} className="form-value__header">
              <Typography.Text>Lựa chọn {dataAnswer.id}</Typography.Text>
              <Checkbox
                // value={dataAnswer.isDisplayContent}
                checked={dataAnswer.isDisplayContent}
                onChange={handleChangeDisplay}
              >
                Hiển thị nội dung mô tả
              </Checkbox>
            </Col>
            <Col span={24}>
              <TextArea
                onChange={handleChangeText}
                showCount
                value={dataAnswer.value}
                placeholder="Nhập nôi dung mô tả"
                maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
                autoSize={{ minRows: 1, maxRows: 10 }}
              />
            </Col>
          </Row>
        </Col>
      </div>
      <Button
        className="btn-delete-item ml-2"
        type="default"
        onClick={handleDeleteSection}
        icon={<DeleteOutlined />}
      />
    </section>
  );
};

export default QuestionsVideo;
