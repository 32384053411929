import { SearchOutlined } from "@ant-design/icons";
import { Flex, Form, Layout, TableColumnsType, TableProps } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInput from "../../../components/form-input/FormInput";
import PageHeader from "../../../components/page-header/PageHeader";
import TableData from "../../../components/table-data/TableData";
import { routesConfig } from "../../../config/routes";
import { getCourseManagementList } from "../../../service/course-management";
import "./CourseList.scss";

type ListStudentSystemProps = {
  title?: string;
};

const CourseList = ({ title }: ListStudentSystemProps) => {
  const navigate = useNavigate();
  const [listStudentSystem, setListStudentSystem] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [nameSort, setNameSort] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getCourseManagementList(searchParam);
      const { data } = response.data;
      setListStudentSystem(data?.content);
      setTotalPage(data?.totalElements);
      setLoading(false);
    } catch (error) { }
  };
  useEffect(() => {
    fetchData({
      size: pageSize,
      page: pageNumber,
      keyword: searchValue,
      sort: nameSort ? [`${fieldSortOrder},${nameSort}`] : [],
    });
  }, [pageSize, pageNumber, nameSort]);

  const dataColumnsReportCheckIn: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: "55px",
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "id",
      key: "id",
      width: "250px",
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      key: "name",
      render: (_: any, record) => {
        return (
          <div
            onClick={() =>
              navigate(
                `${routesConfig.courseManagement}/${routesConfig.courseList}/${record.id}`
              )
            }
            className="course-name-link"
          >
            {record.name}
          </div>
        );
      },
      sorter: true,
    },
  ];

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        size: pageSize,
        page: pageNumber,
        keyword: searchValue,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      size: pageSize,
      page: pageNumber,
      keyword: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setNameSort(data);
    setFieldSortOrder(sorter.field);
  };

  return (
    <Layout className="page-header-group course-management-list">
      <Form.Provider>
        <div className={`page-header-group--layout`}>
          <div className="table-header--wrapper justify-between pb-6">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <Flex>
              <FormInput
                placeholder="Nhập mã hoặc tên khoá học"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
                style={{ width: "400px" }}
              />
            </Flex>
          </div>
        </div>
        <TableData
          dataTable={listStudentSystem}
          dataColumns={dataColumnsReportCheckIn}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default CourseList;
