import React from "react";
import { Form, Radio } from "antd";
import { JSON_SUB_TYPE, QuestionBeProps, QuestionProps, QuestionPropsV2 } from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";


function TrueOrFalse({ data, disabled, initialData }: QuestionPropsV2) {
  return (
    <Form.Item
      initialValue={initialData?.answer[0]}
      name={`TrueOrFalse-${data?.id}-${QuestionTypeEnum.TRUE_OR_FALSE}-${JSON_SUB_TYPE.ChoosingRequest}`}
    >
      <Radio.Group className="flex flex-column gap-12" disabled={disabled}>
        {data?.questions && data?.questions?.length > 0 ? (
          data?.questions?.map((quiz: QuestionBeProps, index) => (
            <Radio key={quiz?.uuid + index} value={quiz?.uuid}>
              <span className="font-size-16 line-height-24 font-weight-5">
                {convertNumberingTypeToText(
                  index + 1,
                  Number(JSON.parse(data?.settingGeneral).numberingType)
                )}
                . {quiz?.content || "Không có nội dung"}
              </span>
            </Radio>
          ))
        ) : (
          <div className="center">
            <EmptyComponent description="Không có nội dung" />
          </div>
        )}
      </Radio.Group>
    </Form.Item>
  );
}

export default TrueOrFalse;
