import React, {useState} from 'react';
import {Col, Empty, Row, Select, Spin, TableColumnsType} from "antd";
import StatisticalReportCard from '../../StatisticalReportCard';
import TableWithTotals from "../../../../../../components/table-with-totals/TableWithTotals";
import {handleExportReport} from "../../../../../../utils/staticReport";
import {
  exportTableSearchHistory,
  exportTableSearchKeyword,
  exportTableUnitAction
} from "../../../../../../service/statistical-report";
import {staticReportStore} from "../../../../../../stores/stores";
import dayjs from "dayjs";
import {BarStackedChartProps, LineChartProps, PieChart} from "../../../../../../types/static-report";
import {createTableData} from '../../../../for-instructors/components/table/tableConvertData';

type Props = {
  loading: boolean,
  searchKeywordCountForMinistry: PieChart[],
  searchHistoryForMinistry: BarStackedChartProps[],
  unitDownloadResource: LineChartProps[],
  unitPreviewResource: LineChartProps[],
  unitShareResource: LineChartProps[],
  setActionModuleMapping: (value: any) => void,
}

const BehaviorTable = ({
                         loading,
                         searchKeywordCountForMinistry,
                         searchHistoryForMinistry,
                         unitDownloadResource,
                         unitPreviewResource,
                         unitShareResource,
                         setActionModuleMapping
                       }: Props) => {
  const {staticReportType, filterValues, tab} = staticReportStore();
  const [statusOrder, setStatusOrder] = useState("");
  const [fieldSortOrder, setFieldSortOrsearchKeywordCountForMinistryder] = useState("");

  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
  };


  const tableQuatitySeachColumn: TableColumnsType<any> = [
    {
      title: "Từ khoá",
      dataIndex: "criteria",
      key: "criteria"
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count"
    }
  ]

  const tableColumnResource: TableColumnsType<any> = [
    {
      title: "Thời gian",
      dataIndex: "criteria",
      key: "criteria"
    },
    {
      title: "Số lượng",
      dataIndex: "value",
      key: "value"
    },
  ]

  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng tìm kiếm
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt tìm kiếm theo từ khóa"
            content={
              <Spin spinning={loading}>
                {searchKeywordCountForMinistry.length > 0 ? (
                  <TableWithTotals
                    data={searchKeywordCountForMinistry}
                    columns={tableQuatitySeachColumn}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableSearchKeyword,
                    "Số lượt tìm kiếm theo từ khóa.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableSearchKeyword,
                    "Số lượt tìm kiếm theo từ khóa.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 16}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt tìm kiếm tài nguyên theo thời gian"
            content={
              <Spin spinning={loading}>
                {searchHistoryForMinistry.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(searchHistoryForMinistry).dataSource}
                    columns={[
                      {
                        title: "Thời gian",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(searchHistoryForMinistry).columns,
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableSearchHistory,
                    "Số lượt tìm kiếm tài nguyên theo thời gian.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableSearchHistory,
                    "Số lượt tìm kiếm tài nguyên theo thời gian.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượt xem, tải về, chia sẻ
        </p>
      </div>
      <Row gutter={[{xs: 8, sm: 8, md: 16, lg: 16}, {xs: 8, sm: 8, md: 16, lg: 16}]}>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt tải về tài nguyên"
            content={
              <Spin spinning={loading}>
                {unitDownloadResource.length > 0 ? (
                  <TableWithTotals
                    data={unitDownloadResource}
                    columns={tableColumnResource}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            moreOption={
              <Select
                className="mr-2"
                defaultValue={1}
                style={{minWidth: 160}}
                onSelect={(value) => {
                  setActionModuleMapping({
                    actionType: 3,
                    moduleGroup: value
                  })
                }}
                options={[
                  {value: 1, label: 'Bài kiểm tra'},
                  {value: 2, label: 'Tài liệu tham khảo'},
                  {value: 3, label: 'Đa phương tiện'},
                  {value: 4, label: 'Bài giảng'},
                  {value: 5, label: 'Khoá học'},
                ]}
              />
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUnitAction,
                    "Số lượt tải về tài nguyên.xlsx",
                    "excel",
                    {...exportPayload, unitActionType: 3, courseStructureType: 'unit'}
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUnitAction,
                    "Số lượt tải về tài nguyên.pdf",
                    "pdf",
                    {...exportPayload, unitActionType: 3, courseStructureType: 'unit'}
                  ),
              },
            ]}
          />

        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt xem tài nguyên"
            content={
              <Spin spinning={loading}>
                {unitPreviewResource.length > 0 ? (
                  <TableWithTotals
                    data={unitPreviewResource}
                    columns={tableColumnResource}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            moreOption={
              <Select
                className="mr-2"
                defaultValue={1}
                style={{minWidth: 160}}
                onSelect={(value) => {
                  setActionModuleMapping({
                    actionType: 1,
                    moduleGroup: value
                  })
                }}
                options={[
                  {value: 1, label: 'Bài kiểm tra'},
                  {value: 2, label: 'Tài liệu tham khảo'},
                  {value: 3, label: 'Đa phương tiện'},
                  {value: 4, label: 'Bài giảng'},
                  {value: 5, label: 'Khoá học'},
                ]}
              />
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUnitAction,
                    "Số lượt xem tài nguyên.xlsx",
                    "excel",
                    {...exportPayload, unitActionType: 1, courseStructureType: 'unit'}
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUnitAction,
                    "Số lượt xem tài nguyên.pdf",
                    "pdf",
                    {...exportPayload, unitActionType: 1, courseStructureType: 'unit'}
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt chia sẻ tài nguyên"
            content={
              <Spin spinning={loading}>
                {unitShareResource.length > 0 ? (
                  <TableWithTotals
                    data={unitShareResource}
                    columns={tableColumnResource}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            moreOption={
              <Select
                className="mr-2"
                defaultValue={1}
                style={{minWidth: 160}}
                onSelect={(value) => {
                  setActionModuleMapping({
                    actionType: 2,
                    moduleGroup: value
                  })
                }}
                options={[
                  {value: 1, label: 'Bài kiểm tra'},
                  {value: 2, label: 'Tài liệu tham khảo'},
                  {value: 3, label: 'Đa phương tiện'},
                  {value: 4, label: 'Bài giảng'},
                  {value: 5, label: 'Khoá học'},
                ]}
              />
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUnitAction,
                    "Số lượt chia sẻ tài nguyên.xlsx",
                    "excel",
                    {...exportPayload, unitActionType: 2, courseStructureType: 'unit'}
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUnitAction,
                    "Số lượt chia sẻ tài nguyên.pdf",
                    "pdf",
                    {...exportPayload, unitActionType: 2, courseStructureType: 'unit'}
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default BehaviorTable;
