import { Editor, Plugin } from "grapesjs";
import { ConstructCourseDetailType } from "../../../../types/course-construction";
import { postCourseSearch } from "../../../../service/course-construct";
import { useEffect, useState } from "react";
import { getDataGeneral } from "../../../../service/content-course/contenCourseApi";
import { optionsSkills } from "../../../../components/course-construction/content-course-construction/setting-content-course/condition-register/constants/data";

interface ConditionsBlockCustomProps {
  courseid: string;
  userid: string;
  uuid?: string;
}

export const ConditionBlockCustom: React.FC<ConditionsBlockCustomProps> = ({ courseid, userid, uuid }) => {

  const [renderSkills, setRenderSkills] = useState<any[]>([]);
  const [renderCourse, setRenderCourse] = useState<any[]>([]);


  const getDataCourse = async () => {
    let allOptionCourse: any[] = [];
    let preCourses: any[] = [];
    let preSkills: any[] = [];

    try {
      // Await both API calls to complete using Promise.all
      const [getAllCourses, getSettingsGeneral] = await Promise.all([
        postCourseSearch({ pageSize: 0 }),
        getDataGeneral(Number(courseid))
      ]);

      // Process the responses
      allOptionCourse = getAllCourses.data.data.data.filter((data: any) => data.status === 13); // Adjust the path according to your API response structure
      preCourses = getSettingsGeneral.data.data.preCourses; // Adjust the path according to your API response structure
      preSkills = getSettingsGeneral.data.data.preSkills; // Adjust the path according to your API response structure

      let preRenderSkills: any = [];
      let preRenderCourse: any = [];

      optionsSkills.forEach((x: any) => {
        preSkills.forEach((y) => {
          if (y === x.code) {
            preRenderSkills.push(x.label);
          }
        });
      });

      allOptionCourse.forEach((x: any) => {
        preCourses.forEach((y) => {
          if (y === x.code) {
            preRenderCourse.push(x.name);
          }
        });
      });

      setRenderSkills(preRenderSkills);
      setRenderCourse(preRenderCourse);
    } catch (error) { }
  };

  useEffect(() => {
    getDataCourse();
  }, [courseid]);

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 mx-auto flex flex-wrap py-8">
        <div className="flex flex-col flex-wrap lg:text-left border-2 rounded-lg lg:pt-12 lg:pl-12 lg:pr-12 mr-4 lg:w-1/3">
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="flex-grow w-full">
              <h2 className="text-black title-font font-medium text-2xl mb-4">
                <span>Khóa học tiên quyết</span>
              </h2>
              {renderCourse?.map((item: any, index: number) => {
                return (
                  <div key={index + 1} className="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
                    <span>{item}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-wrap lg:text-left border-2 rounded-lg lg:pt-12 lg:pl-12 lg:pr-12 mr-4 lg:w-1/3">
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="flex-grow w-full">
              <h2 className="text-black title-font font-medium text-2xl mb-4">
                <span>Điều kiện kỹ năng</span>
              </h2>
              {renderSkills?.map((item: any, index: number) => {
                return (
                  <div key={index + 1} className="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
                    <span>{item}</span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
