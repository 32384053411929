import { Modal, TableColumnsType, TableProps } from "antd"
import { useAuthStore } from "../../../../../stores/stores";
import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import FormItemInput from "../../../../../components/form-input/FormInput";
import { SearchOutlined } from "@ant-design/icons";
import { formatDateV2 } from "../../../../../utils/converDate";
import './ManyApprovalsSurvey.scss';
import TableData from "../../../../../components/table-data/TableData";
import { postSurveySearchApprove } from "../../../../../service/survey-managment/list-survey";
import { ApprovalType } from "../../../../../types/survey-browse";
import { SurveyStatusEnum } from "../../../survey/constants/data";
import { get } from "lodash";
import { HelpService } from "../../../../../service/helper.service";
import { approveManySurvey } from "../../../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";

interface RowKeyType {
    surveyId: number;
}
type Props = {
    open: boolean;
    handleCancel: () => void,
}
export default function ManyApprovalsSurvey(props: Props) {
    const { open, handleCancel } = props
    const userId = useAuthStore((states) => states.user?.id)
    const [searchValue, setSearchValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [listSurveyMany, setListSurveyMany] = useState<ApprovalType[]>([])
    const helpService = new HelpService();
    const handleReturnStatus = (record: any) => {
        switch (record.surveyStatus) {
            case SurveyStatusEnum.SAVE_DRAFT:
                return "Lưu bản nháp"
            case SurveyStatusEnum.WAIT_APPROVE:
                return "Chờ duyệt"
            case SurveyStatusEnum.APPROVED:
                return "Đã duyệt"
            case SurveyStatusEnum.REFUSE:
                return "Từ chối"
            case SurveyStatusEnum.PUBLISHED:
                return "Đã xuất bản"
            case SurveyStatusEnum.END:
                return "Kết thúc"
            default:
                return "";
        }
    }
    const dataColumns: TableColumnsType<any> = [
        {
            title: "STT",
            width: 10,
            render: (_: any, record, index: number) => {
                const orderNumber = (index + 1).toString().padStart(2, '0');
                return (
                    <span className="table-order-header">{orderNumber}</span>
                );
            },
        },
        {
            title: "Tên đợt khảo sát",
            sorter: (a, b) => a.surveyName.localeCompare(b.surveyName),
            width: 60,
            dataIndex: "surveyName"
        },
        {
            title: "Ngày gửi",
            width: 20,
            dataIndex: "submissionDate",
            render: (_: any, record) => {
                return (
                    <>{record.submissionDate ? formatDateV2(record.submissionDate) : null}</>
                );
            },
        },
        {
            title: "Trạng thái",
            width: 30,
            dataIndex: "surveyStatus",
            render: (_: any, record) => {
                return (
                    <div className="table-status">
                        <div
                            className="color-icon"
                            style={{
                                backgroundColor:
                                    record.surveyStatus === 1
                                        ? "#D9D9D9"
                                        : record.surveyStatus === 2
                                            ? "#FAAD14"
                                            : record.surveyStatus === 3
                                                ? "#1677FF"
                                                : record.surveyStatus === 4
                                                    ? "#FF0000"
                                                    : record.surveyStatus === 5
                                                        ? "#1677FF"
                                                        : record.surveyStatus === 6
                                                            ? "#52C41A"
                                                            : "white",
                            }}
                        ></div>
                        {handleReturnStatus(record)}
                    </div>
                );
            },
        },
    ]
    const handleTableChange: TableProps["onChange"] = (
        pagination,
        filters,
        sorter: any
    ) => {
        const data =
            sorter.order === "ascend"
                ? "asc"
                : sorter.order === "descend"
                    ? "desc"
                    : "";
        // setStatusOrder(data);
        // setFieldSortOrder(sorter.field);
    };
    const getListData = () => {
        const data = {
            keyword: searchValue,
            individualId: userId,
            type: false,
            isPagination: false,
        }
        postSurveySearchApprove(data).then((res) => {
            const { data } = res.data;
            setListSurveyMany(data);
            setLoading(false);
        })
            .catch((err) => {
                console.log(err);
            });
    }
    useEffect(() => {
        if (open) {
            getListData();
        }
    }, [open])
    const onOk = () => {
        if (selectedRowKeys.length > 0) {
            const dataSend = {
                individualId: userId,
                surveyIds: (selectedRowKeys as unknown as RowKeyType[]).map((i) => i.surveyId)
            };
            approveManySurvey(dataSend).then((res) => {
                const { data, statusCode, message } = res.data;
                if (statusCode === 200) {
                    helpService.successMessage(get(data, "message", message));
                    handleCancel();
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    };

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value);
    }
    const handleSubmitSearch = () => {
        getListData();
    }
    const handleSearchEnter = (e: KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === "Enter") {
            getListData();
        }
    };
    const disableButton = selectedRowKeys.length === 0 ? true : false;

    return (
        <>
            <Modal title="Phê duyệt nhiều đợi khảo sát" centered
                open={open}
                okText="Xác nhận phê duyệt"
                cancelText="Huỷ"
                onOk={onOk}
                onCancel={handleCancel}
                width={1000}
                okButtonProps={{ disabled: disableButton }}
            >
                <FormItemInput
                    placeholder="Nhập từ khoá cần tìm"
                    value={searchValue}
                    onChange={handleChangeInput}
                    onKeyPress={handleSearchEnter}
                    afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
                />
                <div className="title-custom-many-approval">
                    <h1 className="title-custom-many-approval-h1">Danh sách đợt khảo sát </h1><span className="title-custom-many-approval-span">(Đã chọn {selectedRowKeys.length}/{listSurveyMany.length} khảo sát)</span>
                </div>
                <TableData
                    dataTable={listSurveyMany}
                    dataColumns={dataColumns}
                    hideSelection={true}
                    loadingTable={loading}
                    setSelectedRowKeysProps={setSelectedRowKeys}
                    handleTableChangeProps={handleTableChange}
                />
            </Modal>
        </>
    )
}