import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { Draft } from "../../types/scoring-course";
import { useAuthStore } from "../../stores/stores";

export const getCourseOfTeacher: (payload: {
  keyword: string;
  page: number;
  size: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/mooc-courses/get-course-of-teacher`, payload);
};

export const getUnitByCourse: (payload: {
  id: number;
  keyword: string;
  page: number;
  size: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/mooc-courses/get-unit-by-course/${payload.id}`,
    payload
  );
};

export const getExamSessions: (payload: {
  blockId: number;
  status?: number;
  markUserId?: number;
  start?: string;
  end?: string;
  studentUsername?: string;
  page: number;
  size: number;
  statuses?: number[];
  sort?: any;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/mooc-courses/get-exam-sessions/${payload.blockId}`,
    payload
  );
};

export const searchTeachers: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-courses/search-teachers?courseId=${id}`);
};

export const saveDraft: (payload: {
  sessionId: string;
  commentByTeacher: string;
  data: Draft[];
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/teacher/mark/save-draft`, payload);
};

export const markSubmit: (payload: {
  sessionId: string;
  commentByTeacher: string;
  data: Draft[];
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/teacher/mark/submit`, payload);
};

export const deleteDraft: (sessionId: string) => Promise<AxiosResponse<any>> = (
  sessionId
) => {
  return axiosConfigV2.delete(`/teacher/mark/delete-draft/${sessionId}`);
};

export const resultOfTeacher: (
  sessionId: string
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.get(
    `/course/structure/exam/result-of-teacher/${payload}`
  );
};

export const getFileInfoFromURL = async (url: string) => {
  const accessToken = useAuthStore.getState().accessToken;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      Authorization: `${accessToken}`,
    },
  });
  return response;
};
