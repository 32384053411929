import { Button, Checkbox, Col, ColorPicker, ColorPickerProps, Divider, Drawer, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Space, Switch, Typography, Upload, UploadProps, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import FormRadio from '../../form-radio/FormRadio'
import { CloseOutlined, CopyOutlined, ExclamationCircleOutlined, HeatMapOutlined, InfoCircleOutlined, LoadingOutlined, PicCenterOutlined, PlayCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
// import "./index.scss"
import CommonModal from '../../modal/common'
import { useLocation, useNavigate } from 'react-router-dom'
import { FileType } from '../../../utils/uploadImage'
import { createPortalAdministration, getAllUniversity } from '../../../service/portal-administration';
import { isEmpty } from 'lodash';
import { uploadFileImageQuiz } from '../../../service/course-construct'
import { getDetailAdminInterface, putSettingAdminInterface, saveTemplateAdminInterface } from '../../../service/admin-interface'
import { getUniversities } from '../../../service/university'
import moment from 'moment'
var indexDB = require("idbcache").default;

type Props = {
  open: boolean
  handleClose: () => void
  getListData: () => void
  tab: string
  mode: string
  dataUpdate: any
}

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  return false
}

export default function SaveTemplateAdminInterfaceDrawer(props: Props) {
  const { open, handleClose, getListData, tab, dataUpdate, mode } = props
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<boolean>(false);

  const validation = {
    adminInterface: {
      required: true,
      message: "Vui lòng nhập tên trang quản trị"
    },
    codeAdminInterface: {
      required: true,
      message: "Vui lòng nhập mã giao diện"
    },
  }

  useEffect(() => {
    if (open) {
      form.setFieldValue("typeStatus", 1)
    }
  }, [open])

  const saveAdminInterface = async (values: any) => {
    const data = {
      name: values.adminInterface,
      code: values.codeAdminInterface
    }
    await saveTemplateAdminInterface(dataUpdate?.id, data).then((res) => {
      if (res.status === 200) {
        message.success("Lưu thông tin thành công")
        handleClose()
        form.resetFields()
        getListData()
      }
    })
  }

  const changeStatus = (checked: boolean) => {
    if (checked === true) {
      const date = moment().format('YYMMDD HHmmss')
      const leftDate = date.split(" ")
      const codeLessonRandom = `Ma_${leftDate[0]}_${leftDate[1]}`
      form.setFieldValue("codeAdminInterface", codeLessonRandom)
    }
    setStatus(checked)
  }

  const clearForm = () => {
    handleClose()
    form.resetFields()
  }

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === 'form-save-template-admin-interface') {
        Modal.confirm({
          title: `Xác nhận lưu mẫu giao diện quản trị`,
          content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu`,
          onOk: () => {
            saveAdminInterface(values)
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>

      <Drawer
        title="Lưu mẫu giao diện"
        placement={"right"}
        className='save-template-admin-interface-drawer'
        width={600}
        onClose={clearForm}
        open={open}
        extra={
          <Space>
            <Button onClick={clearForm} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>
                Hủy
              </Typography.Text>
            </Button>
            <Button className="btn btn-primary" disabled={loading}
              onClick={() => form.submit()
              }>
              <SaveOutlined />
              <Typography.Text>
                Lưu
              </Typography.Text>
            </Button>
          </Space>
        }
      >
        <>
          <Form layout={"vertical"} form={form} name="form-save-template-admin-interface">
            <Row gutter={24}>
              <Col span={24} className='flex items-center'>
                <Form.Item name="status" valuePropName="checked" label="">
                  <Switch defaultChecked value={status} onChange={changeStatus} className='mt-2'/>
                </Form.Item>
                <p className='ml-3'>Sinh mã tự động</p>
              </Col>
            </Row>

            <Row gutter={24} className='mt-2'>
              <Col span={24}>
                <Form.Item name="codeAdminInterface" rules={[validation.codeAdminInterface]} label="Mã giao diện mẫu">
                  <FormInput
                    placeholder="Nhập mã giao diện mẫu"
                    disabled={status}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="adminInterface" rules={[validation.adminInterface]} label="Tên mẫu trang quản trị">
                  <FormInput
                    placeholder="Nhập tên mẫu trang quản trị"
                    disabled={mode === 'view'}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      </Drawer>
    </Form.Provider >
  )
}