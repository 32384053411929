// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-upload {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .avatar-upload .avatar-choose-upload {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 4px solid #fff;
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    position: relative; }
  .avatar-upload .icon-edit-upload-avatar {
    position: absolute;
    bottom: -6px;
    right: 4px;
    padding: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: center; }
    .avatar-upload .icon-edit-upload-avatar span {
      margin-bottom: 0px; }

.upload-avatar-modal .modal-content {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .upload-avatar-modal .modal-content h2 {
    margin-bottom: 20px; }
  .upload-avatar-modal .modal-content .ant-slider {
    margin: 10px 0; }

.upload-avatar-modal .upload-avatar-content {
  width: 100%; }
  .upload-avatar-modal .upload-avatar-content canvas {
    width: 100% !important;
    height: 100% !important; }

.avatar-editor {
  border-radius: 50% !important;
  overflow: hidden; }
`, "",{"version":3,"sources":["webpack://./src/components/AvatarUploader/AvatarUpload.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;EAHrB;IAMI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,8FACyC;IACzC,kBAAkB,EAAA;EAZtB;IAgBI,kBAAkB;IAElB,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;IACtB,+CAA+C;IAC/C,aAAa;IACb,mBAAmB;IACnB,uBAAuB,EAAA;IA7B3B;MA+BM,kBAAkB,EAAA;;AAKxB;EAEI,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAA;EAJvB;IAOM,mBAAmB,EAAA;EAPzB;IAWM,cAAc,EAAA;;AAXpB;EAgBI,WAAW,EAAA;EAhBf;IAmBM,sBAAsB;IACtB,uBAAuB,EAAA;;AAK7B;EACE,6BAA6B;EAC7B,gBAAgB,EAAA","sourcesContent":[".avatar-upload {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  .avatar-choose-upload {\n    width: 130px;\n    height: 130px;\n    border-radius: 50%;\n    border: 4px solid #fff;\n    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),\n      0px 4px 6px -2px rgba(16, 24, 40, 0.03);\n    position: relative;\n  }\n\n  .icon-edit-upload-avatar {\n    position: absolute;\n    right: 0px;\n    bottom: -6px;\n    right: 4px;\n    padding: 8px;\n    width: 32px;\n    height: 32px;\n    border-radius: 50%;\n    border: 1px solid #d9d9d9;\n    background-color: #fff;\n    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    span {\n      margin-bottom: 0px;\n    }\n  }\n}\n\n.upload-avatar-modal {\n  .modal-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    h2 {\n      margin-bottom: 20px;\n    }\n\n    .ant-slider {\n      margin: 10px 0;\n    }\n  }\n\n  .upload-avatar-content {\n    width: 100%;\n\n    canvas {\n      width: 100% !important;\n      height: 100% !important;\n    }\n  }\n}\n\n.avatar-editor {\n  border-radius: 50% !important;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
