// @ts-nocheck
import { FunctionComponent, useState } from "react";
import { Layout } from "antd";
import { DataType } from "../../types/listUsers";
import "./page-builder-view.scss";
import parse, { domToReact } from 'html-react-parser';
import TitleComponent from "../../components/page-builder/TitleComponent";
import { isEmpty } from "lodash";
const { Content } = Layout;
import { useParams } from 'react-router-dom';
var indexDB = require('idbcache').default;
import ContactComponent from "../../components/page-builder/ContactComponents";
import LinkedCourseComponent from "../../components/page-builder/LinkedCourseComponent";
import HeaderGeneralIntroduction from "../../components/portal-adminstration/general-introduction/HeaderGeneralIntroduction";
import { LecturerBlockCustom } from "./components/lecturer-block";
import { ConditionBlockCustom } from "./components/condition-block";

type ProfileInfoProps = {
    dataUser?: DataType;
};

const PageBuilderView: FunctionComponent<ProfileInfoProps> = () => {
    const [html, setHTML] = useState<string>('');
    const params = useParams();
    indexDB.get('courseSavedTemplete-' + params?.id).then((val: any) => {
        if (val) {
            let html = '';
            const parsecourseSavedTempalte = JSON.parse(val);
            if (parsecourseSavedTempalte.id === params?.id) {
                html = parsecourseSavedTempalte.html;
                html = html.replace(new RegExp('<body.*?>'), '').replace('</body>', '');
                const style = document.createElement('style');
                style.append(parsecourseSavedTempalte.css);
                const head = document.querySelector('head');
                head.appendChild(style);
                setHTML(html);
            }
        }
    });

    return (
        <>
            {
                !isEmpty(html) ? <Content className="page-builder-view">
                    {
                        parse(html, {
                            replace(domNode) {
                                if ((domNode as Element).attribs && domNode.attribs['advanced-setting-display-subject'] === 'hidden') {
                                    return <></>;
                                }

                                if ((domNode as Element).attribs && domNode.attribs['component-class']) {
                                    switch ((domNode as Element).attribs['component-class']) {
                                        case 'TitleComponent': {
                                            const props = domNode.attribs;
                                            return <TitleComponent {...props}>
                                                {domToReact((domNode as Element).children)}
                                            </TitleComponent>;
                                        }
                                        case 'LecturerBlockCustom': {
                                            const props = domNode.attribs;
                                            return <LecturerBlockCustom {...props}>
                                                {domToReact((domNode as Element).children)}
                                            </LecturerBlockCustom>;
                                        }
                                        // case 'ContactComponent': {
                                        //     const props = domNode.attribs;
                                        //     return <ContactComponent {...props}>
                                        //         {domToReact((domNode as Element).children)}
                                        //     </ContactComponent>;
                                        // }
                                        // case 'LinkedCourseComponent': {
                                        //     const props = domNode.attribs;
                                        //     return <LinkedCourseComponent {...props}>
                                        //         {domToReact((domNode as Element).children)}
                                        //     </LinkedCourseComponent>;
                                        // }
                                        case 'HeaderGeneralIntroduction': {
                                            const props = domNode.attribs;
                                            return <HeaderGeneralIntroduction {...props}>
                                                {domToReact((domNode as Element).children)}
                                            </HeaderGeneralIntroduction>;
                                        }
                                        case 'ConditionBlockCustom': {
                                            const props = domNode.attribs;
                                            return <ConditionBlockCustom {...props}>
                                                {domToReact((domNode as Element).children)}
                                            </ConditionBlockCustom>;
                                        }
                                    }
                                }
                            },
                        })
                    }
                </Content> : null
            }
        </>

    );
};

export default PageBuilderView;

