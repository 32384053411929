// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password-modal .header-title {
  padding: 16px 24px 0px 24px; }

.change-password-modal .ant-modal-content {
  padding: 0px; }
  .change-password-modal .ant-modal-content .ant-form-item {
    margin-top: 24px; }

.change-password-modal .ant-modal-body,
.change-password-modal .ant-modal-footer {
  padding: 12px 24px; }

.change-password-modal .form-input {
  height: 32px; }
`, "",{"version":3,"sources":["webpack://./src/pages/detail/components/change-password/ChangePassword.scss"],"names":[],"mappings":"AAAA;EAGI,2BAA2B,EAAA;;AAH/B;EAOI,YAAY,EAAA;EAPhB;IAUM,gBAAgB,EAAA;;AAVtB;;EAgBI,kBAAkB,EAAA;;AAhBtB;EAoBI,YAAY,EAAA","sourcesContent":[".change-password-modal {\n\n  .header-title {\n    padding: 16px 24px 0px 24px;\n  }\n\n  .ant-modal-content {\n    padding: 0px;\n\n    .ant-form-item {\n      margin-top: 24px;\n    }\n  }\n\n  .ant-modal-body,\n  .ant-modal-footer {\n    padding: 12px 24px;\n  }\n\n  .form-input {\n    height: 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
