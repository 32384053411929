import { IDataCreateUser } from "./../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const postMoocActionHistorySearch: (data: object) => Promise<AxiosResponse<any>> = ( data) => {
  return axiosConfig.post(`/mooc-action-history/get-action`, data);
};
export const postMoocActionHistorySearchSign: (data: object) => Promise<AxiosResponse<any>> = ( data) => {
  return axiosConfig.post(`/mooc-action-history/get-action-sign`, data);
};
export const getMoocActionHistoryById: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-action-history/get-by-id/${id}`);
};

export const putUpdateMoocActionHistory: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/mooc-action-history/update/${id}`, data)
}

export const postCreateMoocActionHistory: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-action-history/create`, data)
}

export const deleteMoocActionHistory: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/mooc-action-history/delete/${id}`)
}

// Categories 

export const getLookupAuthUser: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-user`)
}


