import React, { useEffect, useMemo, useState } from "react";
// import "./detail.scss";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { ListQuestion } from "../../../../../types/survey-managment/survey";
import QuestionCard from "./QuestionCard";

interface DetailQuestionProps {
    listSectionQuestions: ListQuestion[];
    sectionId: number;
    updateListSectionQuestions: (data: ListQuestion[]) => void;
    showDetailSection: boolean;
    checkIndexQuestionPosition: (index: number) => void;
    needCheckQuestionPosition: boolean;
}

const DetailQuestion: React.FC<DetailQuestionProps> = ({
    listSectionQuestions,
    sectionId,
    updateListSectionQuestions,
    showDetailSection,
    checkIndexQuestionPosition,
    needCheckQuestionPosition,
}) => {
    useEffect(() => {
        checkMiddleElement();
    }, [needCheckQuestionPosition]);

    const listData = useMemo(() => listSectionQuestions, [listSectionQuestions]);

    const checkMiddleElement = () => {
        listData.forEach((item, index) => {
            const element = document.getElementById(`questionElement-${index}`);
            if (element) {
                const rect = element.getBoundingClientRect();
                const elementTop = rect.top;
                const elementBottom = rect.bottom;
                const elementHeight = rect.height;

                // Calculate the vertical position of the horizontal line
                const horizontalLinePosition = (window.innerHeight / 3) * 2;

                // Check if the horizontal line passes through the element
                if (elementTop <= horizontalLinePosition && horizontalLinePosition <= elementBottom) {
                    checkIndexQuestionPosition(index);
                }
            }
        });
    };

    return (
        <div>
            <div>
                {listData.map((sectionQuestion: ListQuestion, index: number) => {
                    // console.log(`detailQuestion - listQuestion:: `, sectionQuestion)
                    const updateSectionQuestionData = (data: ListQuestion) => {
                        // console.log(`data::`, data)
                        const updatedList = [...listData];

                        // Find the index of the element you want to update (assuming you have an identifier like id)
                        const indexToUpdate = updatedList.findIndex(
                            (item) => item.orderNumber === data.orderNumber
                        );

                        // If the element is found, update it with the new data
                        if (indexToUpdate !== -1) {
                            updatedList[indexToUpdate] = data;
                            // Update the state with the modified array
                            updateListSectionQuestions(updatedList);
                        }
                    };

                    return (
                        <div key={index} id={`questionElement-${index}`}>
                            <QuestionCard
                                index={index}
                                sectionQuestion={sectionQuestion}
                                showDetailSection={showDetailSection}
                                updateNewSectionQuestion={updateSectionQuestionData}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DetailQuestion;
