import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  MenuProps,
  Row,
  TableColumnType,
  Typography,
} from "antd";

import FormInput from "../../../../components/form-input/FormInput";
// import "./filter-table.scss";
import {
  DownOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { DataType } from "../../../../types/listUsers";
import { CheckboxOptionType } from "antd/lib";

type FilterTableProps = {
  handleOpen?: () => void;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
  checkedList: Array<string>;
  setCheckedList: (value: string[]) => void;
  dataColumns: TableColumnType<DataType>[];
};

const FilterTable: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
}) => {
  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const items: MenuProps["items"] = [
    {
      label: (
        <Checkbox.Group
          options={options as CheckboxOptionType[]}
          value={checkedList}
          onChange={(value) => {
            setCheckedList(value as string[]);
          }}
        />
      ),
      key: "radio",
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <>
      <Flex>
        <div className="">
          <FormInput
            placeholder="Nhập tên khóa học cần tìm "
            value={searchValue}
            onChange={handleChangeInput}
            onKeyPress={handleSearch}
            afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
          />
        </div>
      </Flex>
    </>
  );
};

export default FilterTable;
