// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-course--page .heading-right .group-button .btn {
  height: 32px; }
  .setting-course--page .heading-right .group-button .btn span {
    font-size: 14px; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-construction/detail-content-construction/index.scss"],"names":[],"mappings":"AAAA;EAIQ,YAAY,EAAA;EAJpB;IAOU,eAAe,EAAA","sourcesContent":[".setting-course--page {\n  .heading-right {\n    .group-button {\n      .btn {\n        height: 32px;\n\n        span {\n          font-size: 14px;\n        }\n      }\n    }\n  }\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
