import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigToUploadBase } from "../../config/api/configApiUploadFile";
import { AuthSSOPayload } from "../../types/auth";

export const verifyPassword: (
  param: any
) => Promise<AxiosResponse<any>> = (param: any) => {
  return axiosConfig.post(`/authentications/verify-password`, param);
};

export const ssoAuthorize: (
  param: AuthSSOPayload
) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfigToUploadBase.post(`/v2/oauth2/authorize`, param);
};
