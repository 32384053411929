import { IDataCreateUser } from "./../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";
import { TypeCoursePlanAssignment } from "../../../types/course-plan-managment/mooc-course-construction-plan";

export const postMoocCoursePlanAssignmentSearch: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-plan-assignment/search`, data);
};

export const getMoocCoursePlanAssignmentById: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-course-plan-assignment/get-by-id/${id}`);
};

export const putUpdateMoocCoursePlanAssignment: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/mooc-course-plan-assignment/update/${id}`, data)
}

export const postCreateMoocCoursePlanAssignment: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-plan-assignment/create`, data)
}

export const deleteMoocCoursePlanAssignment: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/mooc-course-plan-assignment/delete/${id}`)
}


export const getMoocCoursePlanAssignmentHistory: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-plan-assignment/get-action-history`, data)
}
export const getCoursePlanAssignmentHistory: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-plan-assignment/get-plan-assignment-history`, data)
}
// Categories 

export const getLookupMoocCourseConstructionPlan: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-course-construction-plan/lookup`)
}
export const getLookupMoocCourseStructure: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-course-structure/lookup`)
}
export const getLookupMoocUniversity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-schools`)
}


//a.Hien
export const createUpdateCoursePlanAssignmentMany: (data: TypeCoursePlanAssignment[], listId: Number[]) => Promise<AxiosResponse<any>> = (data, listId) => {
  return axiosConfig.post(`/course-planning-assignment/create-update-course-plan-assignment-many`, { listCourseAssignMany: data, ids: listId })
}
