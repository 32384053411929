import { ReactNode } from "react";
import "./index.scss";
type DetailContentProps = {
  children?: ReactNode;
  isReadOnly?: boolean;
};

export function DetailContent({ children, isReadOnly }: DetailContentProps) {
  return (
    <div className={`wrapper-responed ${isReadOnly ? "read-only" : ""}`}>
      <section className="detail-account">
        <div className="form-detail">{children}</div>
      </section>
    </div>
  );
}
