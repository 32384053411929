import {AxiosResponse} from "axios";
import {axiosConfig} from "../../config/api/configApi";
import {IBodyRequestDataPost} from "../../types/roleManagement";
import { Key } from "react";

export const getPermissions: (data?: IBodyRequestDataPost) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/roles/get-permissions`, data)
}

export const getOptionsUser: (data?: IBodyRequestDataPost) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/roles/get-users`, data)
}


export const getDataRoles: (dataSearch: any) => Promise<AxiosResponse<any>> = (dataSearch) => {
  return axiosConfig.post('/roles/search', dataSearch)
}

export const createRole: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post('/roles', data)
}

export const editRole: (id: string, dataEdit: any) => Promise<AxiosResponse<any>> = (id, dataEdit) => {
  return axiosConfig.put(`/roles/${id}`, dataEdit)
}

export const viewInfoRole: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/roles/get-by-id/${id}`)
}

export const deleteRole: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/roles/delete/${id}`)
}

export const getPermissionsRoles: (data?: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/roles/get-permission-is-exist`, data)
}

export const updatePermissionsRoles: (id?: string, data?: {
  isAdd: boolean,
  quyens: Key[]
}) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/roles/update-role-permission/${id}`, data)
}

export const updatePermissionsUsers: (id?: string, data?: {
  isAdd: boolean,
  nguoiDungs: Key[]
}) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/roles/update-role-user/${id}`, data)
}

export const getListUsersExist: (dataSearch: any) => Promise<AxiosResponse<any>> = (dataSearch) => {
  return axiosConfig.post(`/roles/get-users-is-exist`, dataSearch)
}
