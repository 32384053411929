import { ReactNode } from "react"

export interface DataType {
    id: number,
    name: string,
    unit: string,
    regency: string,
    email: string,
    status: boolean,
    createdDate: string,
    numericalOrder: ReactNode,
}
export const dataStatus = [
    {
        value: true,
        label: "Hoạt động"
    },
    {
        value: false,
        label: "Không hoạt động"
    }
]

export const validateFieldCreate = ["name", "organization", "regency", "academicLevel", "gender", "domicile", "phone", "email", "status"]

export const validateFieldFilter = []
