import { IDataCreateUser } from "./../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const postMoocApprovalSearch: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-approval/search`, data);
};

export const getMoocApprovalById: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-approval/get-by-id/${id}`);
};

export const putUpdateMoocApproval: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/mooc-approval/update/${id}`, data)
}

export const postCreateMoocApproval: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-approval/create`, data)
}

export const deleteMoocApproval: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/mooc-approval/delete/${id}`)
}

// Categories 

export const getLookupMoocCourseConstructionPlan: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-course-construction-plan/lookup`)
}
export const getLookupMoocSurvey: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-survey/lookup`)
}
export const getLookupAuthUser: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-user`)
}
export const getLookupMoocOrganization: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-organization/lookup`)
}

