import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import "./Highcharts.scss";
import htmlToImage from "html-to-image";
import { toPng, toJpeg } from "html-to-image";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Form,
  DatePicker,
  FormInstance,
} from "antd";
import React, { useRef, FunctionComponent, useEffect } from "react";
type HighChartsProps = {
  options?: any;
  onChartAdded?: () => void;
  title: string;
  numberQuestion?: number;
};
const HighCharts: FunctionComponent<HighChartsProps> = ({
  options,
  onChartAdded,
  title,
  numberQuestion,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && typeof onChartAdded === "function") {
      onChartAdded(); // Gọi hàm onChartAdded khi biểu đồ được thêm vào
    }
  }, [ref.current]);
  return (
    <div className="highchart-custom">
      <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
        <Col
          md={{ span: 8, offset: 0 }}
          lg={{ span: 8, offset: 0 }}
          xl={{ span: 8, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <div className="highchart-custom title">
            Câu {numberQuestion}: {title}
          </div>
        </Col>
      </Row>
      <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
        <Col
          md={{ span: 12, offset: 6 }}
          lg={{ span: 12, offset: 6 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 12, offset: 6 }}
          xs={{ span: 24, offset: 0 }}
        >
          <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
          <br />
        </Col>
      </Row>
    </div>
  );
};

export default HighCharts;
