import { DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, Row, Typography } from 'antd'
import React, { ChangeEvent } from 'react'
import FormInput from '../../../form-input/FormInput'
import './filterBankExam.scss'

type FilterBankExamProps = {
    handleOpenFilter: () => void
    handleOpen?: () => void
    showFilter: boolean
    searchValue: string
    handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
    handleSubmitSearch: () => void
  }
  

const FilterBankExam = (
    {handleOpenFilter, showFilter, searchValue, handleChangeInput, handleSubmitSearch } : FilterBankExamProps
) => {
  return (
    <>
    <div className = "heading-extra">
      <Row gutter = {8} >
        <Col>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                  <Typography.Text>
                    Bộ lọc
                  </Typography.Text>
                  {showFilter ? <UpOutlined/> : <DownOutlined/>}
              </Button>
            </div>
        </Col>
        <Col>
            <div className="heading-search">
                <FormInput 
                    placeholder='Nhập từ khóa cần tìm'
                    value={searchValue} 
                    onChange={handleChangeInput}
                    afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch}/>}
                />
            </div>
        </Col>
      </Row>
    </div>
    </>
  )
}

export default FilterBankExam
