import React from "react";

function Mp3({ data }: any) {
  const renderContent = () => {
    return <div className="center">Câu hỏi tự luận</div>;
  };

  return <div>{renderContent()}</div>;
}

export default Mp3;
