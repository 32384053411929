import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from 'react';
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, TableColumnType, Typography } from 'antd';

import './filter-table.scss'
import { DownOutlined, PlusOutlined, SearchOutlined, SettingOutlined, UserAddOutlined } from "@ant-design/icons";
import FormInput from '../../../form-input/FormInput';

type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  tab: number,
  handleChangeInput: (e: any) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  dataColumns: TableColumnType<any>[]
  selectedRowKeys: React.Key[]
}


const FilterTableBlogContent: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  tab,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
}) => {

  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 19 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khoá" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
            <div className="heading-resigter">
              <Button onClick={handleOpen} className="btn btn-primary" disabled={tab===2}>
                <PlusOutlined />
                <Typography.Text>Thêm bài đăng</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterTableBlogContent;
