import PageHeader from "../../../../components/page-header/PageHeader";
import {
  Breadcrumb,
  Button,
  Card,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Space,
  TableColumnsType,
  Typography,
} from "antd";
import React, { ChangeEvent, useState } from "react";
import FilterBankExam from "../../../../components/course-library/bank-exam/filterBankExam/FilterBankExam";
import "./bank-exam-list.scss";
import {
  CloseOutlined,
  EyeOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import TableData from "../../../show-component/table-data/TableData";
import FilterForm from "../../../../components/course-library/bank-exam/filterForm/FilterForm";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import "./bank-exam-list.scss";
import { icons } from "antd/es/image/PreviewGroup";
import { routesConfig } from "../../../../config/routes";
import { useNavigate } from "react-router-dom";

type BankExamListProps = {
  title: string;
};

const fakeData = [
  {
    id: 1,
    code: "001",
    name: "Test Kỹ thuật",
    numberOfQuestion: 30,
    topic: "Kỹ thuật",
    hierarchy: "Cap bo",
    usage: 2,
    unit: "Bo giao duc",
    updatedDate: "20/12/2023",
  },
  {
    id: 2,
    code: "002",
    name: "Toán học cơ bản",
    numberOfQuestion: 40,
    topic: "Toán học",
    hierarchy: "Trung học",
    usage: 5,
    unit: "Bo giao duc",
    updatedDate: "10/11/2023",
  },
  {
    id: 3,
    code: "003",
    name: "Lý thuyết vật lý",
    numberOfQuestion: 50,
    topic: "Vật lý",
    hierarchy: "Đại học",
    usage: 3,
    unit: "Bo giao duc",
    updatedDate: "05/01/2024",
  },
  {
    id: 4,
    code: "004",
    name: "Hoá học nâng cao",
    numberOfQuestion: 35,
    topic: "Hoá học",
    hierarchy: "Cấp ba",
    usage: 4,
    unit: "Bo giao duc",
    updatedDate: "12/02/2024",
  },
];

const BankExamList = ({ title }: BankExamListProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(5);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const navigate = useNavigate();
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleTableChange = () => {};

  const listBreadcrumb = [
    {
      title: "Thư viện",
    },
    {
      title: "Ngân hàng đề",
    },
    {
      title: "Danh sách ngân hàng đề",
    },
  ];
  const handleSubmitSearch = () => {};
  const clearFilter = () => {};
  const onFinish = (values: any) => {};

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      {
        key: "0",
        label: "Thêm vào khóa học",
      },
      {
        key: "1",
        label: "Lưu trữ",
      },
    ];
    return options1;
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 10,
    },
    {
      title: "Mã đề",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Tiêu đề",
      dataIndex: "name",
      key: "name",
      filters: [
        { text: "Test Kỹ thuật text", value: "Test Kỹ thuật" },
        { text: "Toán học cơ bản", value: "Toán học cơ bản" },
        { text: "Lý thuyết vật lý", value: "Lý thuyết vật lý" },
        { text: "Hoá học nâng cao", value: "Hoá học nâng cao" },
      ],
      onFilter: (value, record) => record.name.includes(value as string),
      width: 30,
    },
    {
      title: "Số câu hỏi",
      dataIndex: "numberOfQuestion",
      key: "numberOfQuestion",
      width: 20,
    },
    {
      title: "Chủ đề",
      dataIndex: "topic",
      key: "topic",
      width: 20,
    },
    {
      title: "Phân cấp",
      dataIndex: "hierarchy",
      key: "hierarchy",
      width: 20,
    },
    {
      title: "Lượt dùng",
      dataIndex: "usage",
      key: "usage",
      width: 20,
    },
    {
      title: "Đơn vị xuất bản",
      dataIndex: "unit",
      key: "unit",
      width: 20,
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "updatedDate",
      key: "updatedDate",
      filters: [
        { text: "2023", value: "2023" },
        { text: "2024", value: "2024" },
      ],
      onFilter: (value, record) => record.updatedDate.includes(value as string),
      width: 20,
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigate(
                    `/${routesConfig.ministryContentModeration}/${routesConfig.previewExam}?id=${record.id}`,
                    {
                      state: {
                        backUrl: `/${routesConfig.courseLibrary}/${routesConfig.bankExam}/${routesConfig.bankExamList}`,
                        mode: "0",
                        titleBreadCrumb: "Quản lý ngân hàng đề",
                      },
                    }
                  );
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: createOptions(record),
                  onClick: ({ key }) => {
                    if (key === "0") {
                      showModal();
                    }
                    if (key === "1") {
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <Breadcrumb items={listBreadcrumb} />
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterBankExam
              showFilter={showFilter}
              searchValue={searchValue}
              handleOpenFilter={() => setShowFilter((prevState) => !prevState)}
              handleChangeInput={handleChangeInput}
              handleSubmitSearch={handleSubmitSearch}
            />
          </div>
          <FilterForm
            showFilter={showFilter}
            onFinish={onFinish}
            clearFilter={clearFilter}
          />
        </div>
        <div className="tabs-heading">
          <TableData
            dataTable={fakeData}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <Modal
          className="add-exam-course-modal"
          title="Thêm đề vào khóa học"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            icon: <PlusOutlined />,
          }}
          okText="Thêm"
          cancelButtonProps={{
            icon: <CloseOutlined />,
          }}
          cancelText="Hủy"
        >
          <Form layout={"vertical"}>
            <Form.Item
              name="course"
              label="Chọn khóa học"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn khóa học",
                },
              ]}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                // options={}
                allowClear={true}
                placeholder="Chọn khóa học"
              />
            </Form.Item>
            <Form.Item
              name="course"
              label="Chọn Chương"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn Chương",
                },
              ]}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                // options={}
                allowClear={true}
                placeholder="Chọn khóa học"
              />
            </Form.Item>
            <Form.Item
              name="course"
              label="Chọn Bài giảng"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn Bài giảng",
                },
              ]}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                // options={}
                allowClear={true}
                placeholder="Chọn Bài giảng"
              />
            </Form.Item>
          </Form>
        </Modal>
      </Form.Provider>
    </Layout>
  );
};

export default BankExamList;
