import { Button, Modal, Typography } from 'antd'
import React, { useEffect } from 'react'
import { verifyStudentAgain } from '../../../service/list-account-student/listAccountStudent'
import { formatOriginTime } from '../../../utils/converDate'

type Props = {
  open: boolean
  handleClose: () => void
  detailData: any
}
const VerifyCCCD = (props: Props) => {
  const { open, handleClose, detailData } = props
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [dataDetail, setDataDetail] = React.useState<any>(true);

  const showLoading = async () => {
    setLoading(true);
    await verifyStudentAgain(detailData?.fileIdPersonal).then((res) => {
      if(res.status === 200) {
        setDataDetail(res.data?.data)
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    })

    // Simple loading mock. You should add cleanup logic in real world.
  };

  useEffect(() => {
    if (open) {
      showLoading()
    }
  }, [open]);

  return (
    <Modal
      centered
      title="Thông tin xác thực"
      open={open}
      onCancel={handleClose}
      loading={loading}
      footer={
        <Button onClick={handleClose}>
          Đóng
        </Button>
      }
    >
      <div className='m-5'>
        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[100px]'>Họ và tên</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.name}</p></span>
        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[100px]'>Địa chỉ</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.address}</p></span>
        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[100px]'>Giới tính</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.gender}</p></span>
        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[100px]'>Ngày cấp</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.dateJoined ? formatOriginTime(dataDetail?.dateJoined) : null}</p></span>
      </div>
    </Modal>
  )
}
export default VerifyCCCD;
