import { Form, Input } from "antd";
import React from "react";
import { QuestionProps } from "../../../../../types/course";

function ShortText({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <Form.Item
      name="shortText"
      className={`${isCorrect ? "choose-correct" : ""} ${isIncorrect ? "choose-incorrect" : ""}`}
      initialValue={"font-size-16 line-height-24 font-weight-5 text-result"}
    >
      <Input.TextArea
        disabled
        style={{ width: "50%", resize: "none" }}
        rows={3}
        placeholder="Nhập câu trả lời của bạn"
        className={`text-result ${isCorrect ? "active-quiz-correct" : ""} ${isIncorrect ? "active-quiz-wrong" : ""}`}
      />
    </Form.Item>
  );
}

export default ShortText;
