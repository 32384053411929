import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from 'react';
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, TableColumnType, Typography } from 'antd';

import FormInput from '../../../form-input/FormInput';
import './filter-table.scss'
import { DownOutlined, PlusOutlined, SearchOutlined, SettingOutlined, UserAddOutlined } from "@ant-design/icons";

import { CheckboxOptionType } from "antd/lib";
import { DataType } from '../../../../types/course-construction';

type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  tab: number,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  checkedList: Array<string>,
  setCheckedList: (value: string[]) => void,
  dataColumns: TableColumnType<DataType>[]
  selectedRowKeys: React.Key[]
}


const FilterTableCourseConstruction: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  tab,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
  selectedRowKeys
}) => {

  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key
  }))


  const items: MenuProps['items'] = [
    {
      label: (
        <Checkbox.Group options={options as CheckboxOptionType[]} value={checkedList} onChange={(value) => {
          setCheckedList(value as string[])
        }} />

      ),
      key: 'radio',
    }
  ]

  const menuProps = {
    items
  }


  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 7 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>
                  Bộ lọc
                </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 19 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khoá cần tìm" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          <Col md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 21 }}>
            <div className="heading-resigter">
              <Button onClick={handleOpen} className="btn btn-primary">
                <PlusOutlined />
                <Typography.Text>Thêm khóa học</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterTableCourseConstruction;
