import { commonMedia } from "./grapesjs-config";
export const nameCategoryBlocks = "Khối giao diện";
export const nameCategoryTemplates = "Mẫu hoàn chỉnh";
export const nameContactBlock = "Khối liên hệ";
export const nameLinkedCourseBlock = "Khối khóa học mẫu";
export const nameLecturersBlock = "Khối giảng viên";
export const nameBasicBlock = "Giao diện cơ bản";
export const nameCountBlocks = "Khối thống kê mẫu";
export const nameRoadMapBlocks = "Khối lộ trình khóa học";
export const nameHeaderBlocks = "Giới thiệu chung";
export const nameRegisterBlocks = "Khối ghi danh";
export const nameConditionBlocks = "Khối điều kiện";
export const nameAffiliatedBlocks = "Khối liên kết";

export const generalHeaderIntroduction = (data?: any) => {
  const blockUI = {
    id: "header-block-id",
    label: "Phần đầu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body id="i6ll">
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="i1z3t-2" class="text-gray-600 body-font bg-red-700">
    <div id="ibtwy-2" class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <img id="i2fag-2" src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T08%3A14%3A49.301501154Z/f065a648-df6c-4f8e-8741-df5559902bc4images.png" class="w-8"/>
      <a id="ix94a-2" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"><span id="itksl-2" class="ml-3 text-xl text-white">Đại học Bách khoa Hà Nội</span></a>
       <nav id="ie4ll" class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400-flex flex-wrap items-center text-base justify-center flex">
          <a id="i067i" class="text-white hover:bg-red-100 hover:text-red-700 p-4" component-class="ExploreSchool">Khám phá</a>
          <a id="i722x" class="text-white hover:bg-red-100 hover:text-red-700 p-4" component-class="BlogHeaderLink" Uuid="${data}">Blog</a>
        </nav>
        <div component-class="HeaderLoginDiv">
        <button id="i37d7-2" class="px-4 py-2 rounded mx-2 bg-white text-red-700 hover:bg-red-100">Đăng nhập</button>
        <button id="ipphw-2" class="border px-4 py-2 rounded hover:bg-red-100 bg-red-700 border-white text-white hover:text-red-700">Đăng ký</button>
        </div>
    </div>
  </header>
  <section id="iayb-2" class="text-gray-600 body-font">
  </section>
  <section id="i4xe-2" class="text-gray-600 body-font">
  </section>
  <header id="ikzvv" class="text-gray-600 body-font shadow-md">
    <div id="i63k" class="container mx-auto">
      <div id="iqlb" class="flex">
        <!-- Filter Column -->
        <!-- Courses Column -->
      </div>
    </div>
    <footer id="i5d9ox" class="text-gray-600 body-font bg-gray-100">
    </footer>
  </header>
</body>
  `;

  return blockUI;
};

export const generalSearchCourseList = (data?: any) => {
  const blockUI = {
    id: "search-block-id",
    label: "Phần thanh tìm kiếm",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body id="i23n">
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="i1z3t-2" class="text-gray-600 body-font bg-red-700">
  </header>
  <div id="iuph" class="bg-red-600 flex flex-col items-center justify-center text-white py-24">
    <div id="i9fg" class="text-center max-w-2xl">
      <span id="ioszd" class="inline-block py-1 px-2 rounded text-xs font-medium tracking-widest mb-4">Danh sách khoá học</span>
      <h1 id="ir54" class="text-4xl font-bold mb-8">Tất cả khoá học
      </h1>
      <p id="i1l4g" class="text-lg mb-6">Với 1000+ các khoá học với nhiều chủ đề khác nhau
      </p>
      <div class="flex justify-center w-full">
        <input type="text" placeholder="Nhập từ khoá cần tìm" id="i8ujj" class="w-full md:w-1/2 px-4 py-2 text-gray-700 focus:outline-none mr-2 rounded"/>
        <button id="iytk3" class="text-white px-4 py-2 hover:bg-gray-700 rounded bg-red-400">Tìm kiếm</button>
      </div>
    </div>
  </div>
</body>
  `;

  return blockUI;
};

export const generalContentIntroduction = (data?: any) => {
  const blockUI = {
    id: "content-block-id",
    label: "Phần nội dung",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="ikzvv" class="text-gray-600 body-font shadow-md">
    <div id="i63k" class="container mx-auto">
      <div id="iqlb" class="flex">
        <!-- Filter Column -->
        <div id="i0ic" class="w-1/4 p-5 rounded-lg">
          <h2 id="i5yz3" class="text-xl font-bold mb-4 text-red-700">Bộ lọc
          </h2>
          <!-- Category Filter -->
          <div class="mb-6">
            <h3 id="ic9wb" class="font-semibold mb-2 text-red-700">Chuyên ngành
            </h3>
            <div id="ih3z9">
              <label id="innbn" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i7wqu" class="ml-2 text-gray-700">Photoshop (90)</span></label>
              <label id="ic24p" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ilhz8" class="ml-2 text-gray-700">Blender (80)</span></label>
              <label id="ii37x" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ita4e" class="ml-2 text-gray-700">Canva (80)</span></label>
              <label id="i7g9c4" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i1vajn" class="ml-2 text-gray-700">Dựng mô hình 3D (80)</span></label>
              <label id="ib7zf3" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ialpty" class="ml-2 text-gray-700">Thiết kế Website (80)</span></label>
            </div>
          </div>
          <!-- Rating Filter -->
          <div class="mb-6">
            <h3 id="iugib" class="font-semibold mb-2 text-red-700">Xếp hạng
            </h3>
            <div id="ieja8">
              <label id="iet7j" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">1 Sao</span></label>
              <label id="iavik" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">2 Sao</span></label>
              <label id="ifj6t" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">3 Sao</span></label>
              <label id="iwzbv" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">4 Sao</span></label>
              <label id="i5pqg" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">5 Sao</span></label>
            </div>
          </div>
          <!-- Price Filter -->
          <div class="mb-6">
            <h3 id="iyla3" class="font-semibold mb-2 text-red-700">Giá
            </h3>
            <div id="is3hk">
              <label id="iwujh" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">Miễn phí</span></label>
              <label class="inline-flex items-center mb-2"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">Trả phí</span></label>
            </div>
          </div>
          <!-- Course Type Filter -->
          <div class="mb-6">
            <h3 id="ivchk" class="font-semibold mb-2 text-red-700">Loại khoá học
            </h3>
            <div>
              <label id="io0y9" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i13ng" class="ml-2 text-gray-700">Tự do ghi danh</span></label>
              <label id="in3cj" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i8x4r" class="ml-2 text-gray-700">Thẻ ghi danh</span></label>
            </div>
          </div>
        </div>
        <!-- Courses Column -->
        <div id="iek3h" class="w-3/4 p-5">
          <div id="ilngk" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div id="iuf75s-2" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 id="ibrl-2-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ics33-2-2" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div id="iahwd-2" class="border-gray-200 border-opacity-60 flex">
                  <img id="ihxeuh-2" src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p id="iut2a5-2" class="leading-relaxed text-base">
                    <span id="ibkvye-2" class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p id="id6wj-2-2" class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i5qqpy" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="iby9bo" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ig1e91" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ik1oql" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ieis6r" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i98as3" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i1ztmg" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i74fvg" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="im8mf9" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ifmq58" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ixm299" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i15hyl" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="iitoqb" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i4exk9" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i9nose" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i9sw46" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <!-- Course Card 1 -->
            <!-- Course Card 2 -->
            <!-- Course Card 3 -->
            <!-- Add more course cards as needed -->
          </div>
          <div id="icl2y" class="container mx-auto p-5">
            <!-- Pagination Section -->
            <div class="flex justify-between items-center mb-6">
              <!-- Items per page selection -->
              <div>
                <label for="itemsPerPage" id="iy572" class="mr-2 text-gray-700">Khoá học / trang</label>
                <select id="itemsPerPage" class="border-gray-300 rounded-md shadow-sm p-2 border"><option value="10">10</option><option value="20">20</option><option value="30">30</option><option value="40">40</option><option value="50">50</option></select>
              </div>
              <!-- Pagination controls -->
              <div class="flex items-center">
                <button id="i4pfe" class="px-4 py-2 text-gray-700 hover:bg-gray-300 rounded mx-4">Trang trước</button>
                <ul id="iq1qr" class="flex list-none border-gray-300 overflow-hidden rounded">
                  <li>
                    <button id="i5f7a" class="px-4 py-2 hover:bg-gray-300 rounded bg-red-100 text-red-700">1</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">2</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">3</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">4</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">5</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">6</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">7</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">8</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">9</button>
                  </li>
                  <li>
                    <button id="i3a0o" class="px-4 py-2 text-gray-700 hover:bg-gray-300">10</button>
                  </li>
                </ul>
                <button id="i2h0g" class="px-4 py-2 text-gray-700 hover:bg-gray-300 rounded mx-4">Trang sau</button>
              </div>
              <!-- Go to page selection -->
              <div>
                <label for="goToPage" id="ixggg" class="mr-2 text-gray-700">Đi đến trang</label>
                <input type="number" id="goToPage" min="1" max="10" class="w-16 border-gray-300 rounded-md shadow-sm p-2 border"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</body>
  `;

  return blockUI;
};

export const fieldCourse = (data?: any) => {
  const blockUI = {
    id: "categories-block-id",
    label: "Phần Nội dung",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
    
  };
  

  blockUI.content = `
   <body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="i1z3t-2" class="text-gray-600 body-font bg-red-700">
  </header>
  <div component-class="" data-gjs-editable="false" data-gjs-removable="false">
  
  <section id="iayb-2" class="text-gray-600 body-font" data-gjs-editable="false" data-gjs-removable="false">
    <div id="iz66-2" class="container px-5 mx-auto flex flex-wrap pt-8">
      <div id="ihji-2">
        <div id="icxj-2" class="flex flex-col mb-10 lg:items-start items-center">
        </div>
      </div>
      <div id="igyb-2" class="flex-grow w-full">
        <div id="i23y6h" class="border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2 text-red-700 px-4 bg-red-100">
          <span id="iynbp8">Tất cả</span>
        </div>
        <div id="ihicf" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="iyj56">Thiết kế đồ hoạ</span>
        </div>
        <div id="i3bfa" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ikidw">Thiết kế Website</span>
        </div>
        <div id="ixwck" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ia816">Công cụ thiết kế</span>
        </div>
        <div id="ismzm" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="i1bdi">Thiết kế Game</span>
        </div>
        <div id="inou9" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ivhf1">Thiết kế nội thất</span>
        </div>
        <div id="i86wm" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="iu0a9">Thiết kế thời trang</span>
        </div>
        <div id="istv9" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ih2jr">Công nghệ thông tin</span>
        </div>
        <div id="idbkx" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ip8db">Quản trị kinh doanh</span>
        </div>
        <div id="imfde" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="i2oxq">Khác (12)</span>
        </div>
        <div id="isjvj" class="rounded-lg inline-block p-2 mr-2 mb-2 text-gray-500">
          <span id="ih3n6">Xem thêm</span>
        </div>
      </div>
    </div>
  </section>
  <section id="i4xe-2" class="text-gray-600 body-font">
  </section>
  <section id="i4xe-2" class="text-gray-600 body-font">
    <div id="ixht-2" class="container px-5 mx-auto py-8">
      <div id="ieau9-2" class="flex flex-col w-full mb-8">
        <h1 id="i11l-2" class="text-2xl font-medium title-font mb-4 sm:text-3xl">Khoá học để bạn bắt đầu
        </h1>
      </div>
      <div id="iccjpk" class="flex flex-wrap -m-4" data-gjs-editable="false" data-gjs-removable="false">
        <div id="iuf75s" class="lg:w-1/4 md:w-1/2 p-4 w-full" data-gjs-editable="false" data-gjs-removable="false">
          <a class="block relative h-48 rounded overflow-hidden"><img  data-gjs-editable="false" data-gjs-removable="false" alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ibrl-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ics33-2" class="text-gray-900 title-font text-lg font-medium mb-2" data-gjs-editable="false" data-gjs-removable="false">Nhập môn vẽ kỹ thuật số - Digital Painting            
            </h2>
            <div id="iahwd" class="border-gray-200 border-opacity-60 flex">
              <img data-gjs-editable="false" data-gjs-removable="false" id="ihxeuh" src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
              <p id="iut2a5" class="leading-relaxed text-base">
                <span id="ibkvye" class="text-xl text-red-700" data-gjs-editable="false" data-gjs-removable="false">Miễn phí</span>
              </p>
            </div>
            <p id="id6wj-2" class="mt-1" data-gjs-editable="false" data-gjs-removable="false">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="iq9zvy" class="lg:w-1/4 md:w-1/2 p-4 w-full" data-gjs-editable="false" data-gjs-removable="false">
          <a class="block relative h-48 rounded overflow-hidden"><img  data-gjs-editable="false" data-gjs-removable="false" alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ih28xq" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i1e1uh" class="text-gray-900 title-font text-lg font-medium mb-2" data-gjs-editable="false" data-gjs-removable="false">Làm quen với phần mềm chỉnh sửa ảnh Lightroom           
            </h2>
            <div id="inr971" class="border-gray-200 border-opacity-60 flex">
              <img data-gjs-editable="false" data-gjs-removable="false" id="iokrd1" src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
              <p id="iut2a5" class="leading-relaxed text-base">
                <span id="ipqjm2" class="text-xl text-red-700" data-gjs-editable="false" data-gjs-removable="false">Miễn phí</span>
              </p>
            </div>
            <p id="itkivg" class="mt-1" data-gjs-editable="false" data-gjs-removable="false">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="i15vh" class="lg:w-1/4 md:w-1/2 p-4 w-full data-gjs-editable="false" data-gjs-removable="false"">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block" data-gjs-editable="false" data-gjs-removable="false"/></a>
          <div class="mt-4">
            <h3 id="i098d-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-6-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected" data-gjs-editable="false" data-gjs-removable="false">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ij199-2" class="text-gray-900 title-font text-lg font-medium mb-2">Thiết kế Website bán hàng chuyên nghiệp
              <br/>
              <br/>
            </h2>
            <div id="if7y35" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i4txwx" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iqhw6l" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i3a6y-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="i5qu3n" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="i9va6q" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i6lj8v" class="text-gray-900 title-font text-lg font-medium mb-2">Chỉnh sửa, cắt ghép ảnh với Adobe Photoshop
            </h2>
            <div id="ivfmrv" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="ilvuxk" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iulh7j" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i68u45" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="iheeib" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ic7gs-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-4-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ib1uf-2" class="text-gray-900 title-font text-lg font-medium mb-2">Làm quen với phần mềm chỉnh sửa ảnh Lightroom
            </h2>
            <div id="iidcz7" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="ixu3b8" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="i1ew2t" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="iv1io-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ix582s" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="ihl1k2" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ievwtw" class="text-gray-900 title-font text-lg font-medium mb-2">Kỹ năng Canva cơ bản
              <br/>
              <br/>
            </h2>
            <div id="i8gzy9" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i61mtl" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iher7n" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="iwwxlh" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ipw3c3" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="iy6wp-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-5-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i7ebm-2" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
              <br/>
              <br/>
            </h2>
            <div id="iplgk8" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="igf0sj" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ikt5i5" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="ivug2-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ixb3fi" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="ifdssg" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="io7pp9" class="text-gray-900 title-font text-lg font-medium mb-2">Cấu trúc dữ liệu và giải thuật 
              <br/>
              <br/>
            </h2>
            <div id="inoi9m" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="it7pp8" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="il1fmf" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i52syh" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <section id="iayb" class="text-gray-600 body-font">
        </section>
      </div>
    </div>
  </section>
  <section id="iayb-15664" class="text-gray-600 body-font" >
  <header id="ikzvv" class="text-gray-600 body-font shadow-md">
    <div id="i63k" class="container mx-auto">
      <div id="iqlb" class="flex">
        <!-- Filter Column -->
        <div id="i0ic" class="w-1/4 p-5 rounded-lg">
          <h2 id="i5yz3" class="text-xl font-bold mb-4 text-red-700">Bộ lọc
          </h2>
          <!-- Category Filter -->
          <div class="mb-6">
            <h3 id="ic9wb" class="font-semibold mb-2 text-red-700">Chuyên ngành
            </h3>
            <div id="ih3z9">
              <label id="innbn" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i7wqu" class="ml-2 text-gray-700">Photoshop (90)</span></label>
              <label id="ic24p" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ilhz8" class="ml-2 text-gray-700">Blender (80)</span></label>
              <label id="ii37x" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ita4e" class="ml-2 text-gray-700">Canva (80)</span></label>
              <label id="i7g9c4" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i1vajn" class="ml-2 text-gray-700">Dựng mô hình 3D (80)</span></label>
              <label id="ib7zf3" class="inline-flex items-center mb-2 mr-20"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="ialpty" class="ml-2 text-gray-700">Thiết kế Website (80)</span></label>
            </div>
          </div>
          <!-- Rating Filter -->
          <div class="mb-6">
            <h3 id="iugib" class="font-semibold mb-2 text-red-700">Xếp hạng
            </h3>
            <div id="ieja8">
              <label id="iet7j" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">1 Sao</span></label>
              <label id="iavik" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">2 Sao</span></label>
              <label id="ifj6t" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">3 Sao</span></label>
              <label id="iwzbv" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">4 Sao</span></label>
              <label id="i5pqg" class="inline-flex items-center mb-2 mr-44"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">5 Sao</span></label>
            </div>
          </div>
          <!-- Price Filter -->
          <div class="mb-6">
            <h3 id="iyla3" class="font-semibold mb-2 text-red-700">Giá
            </h3>
            <div id="is3hk">
              <label id="iwujh" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">Miễn phí</span></label>
              <label class="inline-flex items-center mb-2"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span class="ml-2 text-gray-700">Trả phí</span></label>
            </div>
          </div>
          <!-- Course Type Filter -->
          <div class="mb-6">
            <h3 id="ivchk" class="font-semibold mb-2 text-red-700">Loại khoá học
            </h3>
            <div>
              <label id="io0y9" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i13ng" class="ml-2 text-gray-700">Tự do ghi danh</span></label>
              <label id="in3cj" class="inline-flex items-center mb-2 mr-10"><input type="checkbox" class="form-checkbox h-5 w-5 text-gray-600"/><span id="i8x4r" class="ml-2 text-gray-700">Thẻ ghi danh</span></label>
            </div>
          </div>
        </div>
        <!-- Courses Column -->
        <div id="iek3h" class="w-3/4 p-5">
          <div id="ilngk" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div id="iuf75s-2" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 id="ibrl-2-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ics33-2-2" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div id="iahwd-2" class="border-gray-200 border-opacity-60 flex">
                  <img id="ihxeuh-2" src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p id="iut2a5-2" class="leading-relaxed text-base">
                    <span id="ibkvye-2" class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p id="id6wj-2-2" class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i5qqpy" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="iby9bo" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ig1e91" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ik1oql" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ieis6r" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i98as3" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i1ztmg" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i74fvg" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="im8mf9" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="ifmq58" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="ixm299" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i15hyl" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="iitoqb" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i4exk9" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <div id="i9nose" class="p-4 w-full">
              <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
              <div class="mt-4">
                <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
                </h3>
                <h2 id="i9sw46" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
                  <br/>
                </h2>
                <div class="border-gray-200 border-opacity-60 flex">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                  <p class="leading-relaxed text-base">
                    <span class="text-xl text-red-700">Miễn phí</span>
                  </p>
                </div>
                <p class="mt-1">Giảng viên: Name01, Name02
                </p>
              </div>
            </div>
            <!-- Course Card 1 -->
            <!-- Course Card 2 -->
            <!-- Course Card 3 -->
            <!-- Add more course cards as needed -->
          </div>
          <div id="icl2y" class="container mx-auto p-5">
            <!-- Pagination Section -->
            <div class="flex justify-between items-center mb-6">
              <!-- Items per page selection -->
              <div>
                <label for="itemsPerPage" id="iy572" class="mr-2 text-gray-700">Khoá học / trang</label>
                <select id="itemsPerPage" class="border-gray-300 rounded-md shadow-sm p-2 border"><option value="10">10</option><option value="20">20</option><option value="30">30</option><option value="40">40</option><option value="50">50</option></select>
              </div>
              <!-- Pagination controls -->
              <div class="flex items-center">
                <button id="i4pfe" class="px-4 py-2 text-gray-700 hover:bg-gray-300 rounded mx-4">Trang trước</button>
                <ul id="iq1qr" class="flex list-none border-gray-300 overflow-hidden rounded">
                  <li>
                    <button id="i5f7a" class="px-4 py-2 hover:bg-gray-300 rounded bg-red-100 text-red-700">1</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">2</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">3</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">4</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">5</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">6</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">7</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">8</button>
                  </li>
                  <li>
                    <button class="px-4 py-2 text-gray-700 bg-white hover:bg-gray-300">9</button>
                  </li>
                  <li>
                    <button id="i3a0o" class="px-4 py-2 text-gray-700 hover:bg-gray-300">10</button>
                  </li>
                </ul>
                <button id="i2h0g" class="px-4 py-2 text-gray-700 hover:bg-gray-300 rounded mx-4">Trang sau</button>
              </div>
              <!-- Go to page selection -->
              <div>
                <label for="goToPage" id="ixggg" class="mr-2 text-gray-700">Đi đến trang</label>
                <input type="number" id="goToPage" min="1" max="10" class="w-16 border-gray-300 rounded-md shadow-sm p-2 border"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  </section>
  </div>
</body>
  `;

  return blockUI;
};

export const courseRecommendIntroduction = (data?: any) => {
  const blockUI = {
    id: "recommend-course-block-id",
    label: "Phần khóa học tiêu biểu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <section id="i4xe-2" class="text-gray-600 body-font">
    <div id="ixht-2" class="container px-5 mx-auto py-8">
      <div id="ieau9-2" class="flex flex-col w-full mb-8">
        <h1 id="i11l-2" class="text-2xl font-medium title-font mb-4 sm:text-3xl">Khoá học để bạn bắt đầu
        </h1>
      </div>
      <div id="iccjpk" class="flex flex-wrap -m-4">
        <div id="iuf75s" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ibrl-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ics33-2" class="text-gray-900 title-font text-lg font-medium mb-2" editable="false">Nhập môn vẽ kỹ thuật số - Digital Painting 123

            <br/>
              <br/>
            </h2>
            <div id="iahwd" class="border-gray-200 border-opacity-60 flex">
              <img id="ihxeuh" src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
              <p id="iut2a5" class="leading-relaxed text-base">
                <span id="ibkvye" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="id6wj-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="iq9zvy" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ih28xq" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i1e1uh" class="text-gray-900 title-font text-lg font-medium mb-2">Làm quen với phần mềm chỉnh sửa ảnh Lightroom
            </h2>
            <div id="inr971" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="iokrd1" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ipqjm2" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="itkivg" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="i15vh" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="i098d-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-6-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ij199-2" class="text-gray-900 title-font text-lg font-medium mb-2">Thiết kế Website bán hàng chuyên nghiệp
              <br/>
              <br/>
            </h2>
            <div id="if7y35" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i4txwx" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iqhw6l" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i3a6y-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="i5qu3n" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="i9va6q" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i6lj8v" class="text-gray-900 title-font text-lg font-medium mb-2">Chỉnh sửa, cắt ghép ảnh với Adobe Photoshop
            </h2>
            <div id="ivfmrv" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="ilvuxk" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iulh7j" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i68u45" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="iheeib" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ic7gs-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-4-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ib1uf-2" class="text-gray-900 title-font text-lg font-medium mb-2">Làm quen với phần mềm chỉnh sửa ảnh Lightroom
            </h2>
            <div id="iidcz7" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="ixu3b8" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="i1ew2t" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="iv1io-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ix582s" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="ihl1k2" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ievwtw" class="text-gray-900 title-font text-lg font-medium mb-2">Kỹ năng Canva cơ bản
              <br/>
              <br/>
            </h2>
            <div id="i8gzy9" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i61mtl" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iher7n" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="iwwxlh" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ipw3c3" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="iy6wp-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-5-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i7ebm-2" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
              <br/>
              <br/>
            </h2>
            <div id="iplgk8" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="igf0sj" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ikt5i5" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="ivug2-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ixb3fi" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="ifdssg" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="io7pp9" class="text-gray-900 title-font text-lg font-medium mb-2">Cấu trúc dữ liệu và giải thuật 
              <br/>
              <br/>
            </h2>
            <div id="inoi9m" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="it7pp8" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="il1fmf" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i52syh" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <section id="iayb" class="text-gray-600 body-font">
        </section>
      </div>
    </div>
  </section>
</body>
  `;
  return blockUI;
};

export const generalFooterIntroduction = (data?: any) => {
  const blockUI = {
    id: "footer-block-id",
    label: "Phần cuối",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Hero Section with Background
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <footer id="i5d9ox" class="text-gray-600 body-font bg-gray-100">
    <div id="iy8na6" class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T08%3A14%3A49.301501154Z/f065a648-df6c-4f8e-8741-df5559902bc4images.png" id="i4mk8z" class="w-8"/>
      <div id="ijhjhp" class="w-90 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
        <span id="inz6nz" class="text-xl ml-8">Đại học Bách Khoa Hà Nội</span>
        <p id="ihqko5" class="text-sm text-gray-500 ml-8 mt-8">Bản quyền thuộc về Đại học Bách khoa Hà Nội
          <br/>Địa chỉ: Số 1 Đại Cồ Việt, Hai Bà Trưng, Hà Nội
          <br/>Điện thoại: 024 3869 4242
        </p>
      </div>
      <div id="iozwhq" class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
        <div id="im9se" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
          </nav>
        </div>
        <div id="iw0yh" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="i18zz" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Giới thiệu</a>
            </li>
            <li id="iu5b6" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Khám phá</a>
            </li>
            <li id="ivzynq" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Blog</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="ik4k7l" class="mb-4">
              <a id="iuw93t" class="text-gray-600 hover:text-gray-800">Chính sách</a>
            </li>
            <li id="iw2yl9" class="mb-4">
              <a id="ihhfkr" class="text-gray-600 hover:text-gray-800">Quyền riêng tư</a>
            </li>
            <li id="ipzazs" class="mb-4">
              <a id="i2jcjh" class="text-gray-600 hover:text-gray-800">Liên hệ</a>
            </li>
            <li id="iuvvzm">
            </li>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</body>`;

  return blockUI;
};
