import React, { FunctionComponent, useEffect, useState } from "react";
import { message } from "antd/lib";
import { get, isEmpty } from "lodash";
import { FormInstance } from "antd/lib";
import { useNavigate } from "react-router-dom";
import DrawerCustom from "../../../../../../components/drawer/Drawer";
import { UrlPattern, regexEmail } from "../../../../../../utils/regex";
import type { DatePickerProps } from "antd";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { Input } from "antd";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import {
  CloseOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { useAuthStore } from "../../../../../../stores/stores";
import { useForm } from "antd/es/form/Form";
import FormInput from "../../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import FormRadio from "../../../../../../components/form-radio/FormRadio";
import grapesjs, { Block, Editor } from "grapesjs";
import GjsEditor, { useEditor, WithEditor } from "@grapesjs/react";
import gjsBlocksBasic from "grapesjs-blocks-basic";
import gjsTailWind from "grapesjs-tailwind";
import gjsNavbar from "grapesjs-navbar";
import gjsPresetWeb from "grapesjs-preset-webpage";
import gjsStyleBg from "grapesjs-style-bg";
import gjsStyleFilter from "grapesjs-style-filter";
import gjsStyleGradient from "grapesjs-style-gradient";

import {
  getMoocCourseConstructionPlanById,
  getLookupAuthUser,
  getLookupMoocUniversity,
  getLookupIndustryGroup,
  getLookupIndustry,
  checkExistCodeName,
} from "../../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { DetailTypeMoocCourseConstructionPlan } from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { getLookupMoocUniversityHost } from "../../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
type FormCreateMoocCourseConstructionPlanProps = {
  onFinish?: any;
  dataOptions?: any;
  id?: any;
  detailData?: DetailTypeMoocCourseConstructionPlan;
  isOpened?: boolean;
  modeOpen: any;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData?: React.Dispatch<
    React.SetStateAction<DetailTypeMoocCourseConstructionPlan>
  >;
  setDataUpdate?: (data: any) => void;
  setDataSponsorUpdate?: (data: any) => void;
};

export const FormCreateMoocCourseConstructionPlan: FunctionComponent<
  FormCreateMoocCourseConstructionPlanProps
> = ({
  onFinish,
  dataOptions,
  id,
  detailData,
  isOpened,
  setIsOpened,
  modeOpen,
  setDetailData,
  setDataUpdate,
  setDataSponsorUpdate,
}) => {
  const validations = {
    id: {
      required: false,
      message: "Vui lòng chọn khoa Title of id áp dụng",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(new Error("Vui lòng nhập tên Title of id"));
      //   }
      //   return Promise.resolve();
      // },
    },
    registrationCode: {
      required: true,
      // message: "Vui lòng nhập mã đăng ký",
      validator: async (_: unknown, value: string) => {
        // if (value && value.length < 6) {
        //   return Promise.reject(
        //     new Error("Tên khóa học phải từ 6 ký tự trở lên")
        //   );
        // }
        if (value && value.length > 25) {
          return Promise.reject(
            new Error("Mã đăng ký khóa học không vượt quá 25 ký tự")
          );
        }
        if (value !== "") {
          if (modeOpen === "edit") {
            // console.log("edit")
            if (value !== detailData?.registrationCode) {
              // console.log("check di")
              await checkExistCodeName(value).then((res) => {
                if (res.data.data === true) {
                  return Promise.reject(
                    new Error("Mã đăng ký khóa học đã tồn tại")
                  );
                }
              });
            }
          } else if (modeOpen === "create") {
            // console.log("create")
            await checkExistCodeName(value).then((res) => {
              if (res.data.data === true) {
                return Promise.reject(
                  new Error("Mã đăng ký khóa học đã tồn tại")
                );
              }
            });
          }
        }
        if (!value) {
          return Promise.reject(new Error("Vui lòng nhập mã đăng ký"));
        }
        return Promise.resolve();
      },
    },
    registrationName: {
      required: true,
      // message: "Vui lòng nhập tên đăng ký",
      validator: (_: unknown, value: string) => {
        // if (value && value.length < 6) {
        //   return Promise.reject(
        //     new Error("Tên khóa học phải từ 6 ký tự trở lên")
        //   );
        // }
        if (value && value.length > 255) {
          return Promise.reject(
            new Error("Tên đăng ký khóa học không vượt quá 255 ký tự")
          );
        }
        if (!value) {
          return Promise.reject(new Error("Vui lòng nhập tên đăng ký"));
        }
        return Promise.resolve();
      },
    },
    courseIndustry: {
      required: true,
      message: "Vui lòng chọn khoa áp dụng",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(new Error("Vui lòng chọn khoa áp dụng"));
      //   }
      //   return Promise.resolve();
      // },
    },
    courseStart: {
      required: false,
      message: "Vui lòng chọn ngày",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(new Error("Vui lòng chọn ngày"));
      //   }
      //   return Promise.resolve();
      // },
    },
    courseDuration: {
      required: false,
      message: "Vui lòng nhâp thời gian dự kiến",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(new Error("Vui lòng nhâp thời gian dự kiến"));
      //   }
      //   return Promise.resolve();
      // },
    },

    constructionPlanStatus: {
      required: false,
      message: "Vui lòng chọn khoa Title of construction_plan_status áp dụng",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(
      //       new Error("Vui lòng nhập tên Title of construction_plan_status")
      //     );
      //   }
      //   return Promise.resolve();
      // },
    },
    courseType: {
      required: true,
      message: "Vui lòng chọn loại khóa học áp dụng",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(new Error("Vui lòng chọn loại khóa học áp dụng"));
      //   }
      //   return Promise.resolve();
      // },
    },
    registrationSubmissionDate: {
      required: true,
      message:
        "Vui lòng chọn khoa Title of registration_submission_date áp dụng",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(
      //       new Error("Vui lòng nhập tên Title of registration_submission_date")
      //     );
      //   }
      //   return Promise.resolve();
      // },
    },
    registrationSubmitterId: {
      required: true,
      message: "Vui lòng chọn khoa Title of registration_submitter_id áp dụng",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(
      //       new Error("Vui lòng nhập tên Title of registration_submitter_id")
      //     );
      //   }
      //   return Promise.resolve();
      // },
    },
    universityId: {
      required: true,
      message: "Vui lòng chọn trường chủ trì áp dụng",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(
      //       new Error("Vui lòng nhập tên Title of university_id")
      //     );
      //   }
      //   return Promise.resolve();
      // },
    },
    // universityId: {
    //   required: true,
    //   message: "Vui lòng chọn trường chủ trì áp dụng",
    //   // validator: (_: unknown, value: string) => {
    //   //   value = "x";
    //   //   if (!value) {
    //   //     return Promise.reject(
    //   //       new Error("Vui lòng nhập tên Title of university_id")
    //   //     );
    //   //   }
    //   //   return Promise.resolve();
    //   // },
    // },
    courseIndustryGroup: {
      required: true,
      message: "Vui lòng chọn chuyên ngành đăng ký",
      // validator: (_: unknown, value: string) => {
      //   value = "x";
      //   if (!value) {
      //     return Promise.reject(new Error("Vui lòng chọn chuyên ngành đăng ký"));
      //   }
      //   return Promise.resolve();
      // },
    },
  };
  const dateFormat = "DD/MM/YYYY";
  const { TextArea } = Input;
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    // console.log(date, dateString);
  };
  const [form] = Form.useForm();
  const [error, setError] = useState<any>({});
  const [detailDataEdit, setDetailDataEdit] =
    useState<DetailTypeMoocCourseConstructionPlan>(detailData!);
  const [listUniCollaborated, setListUniCollaborated] = useState<any[]>([]);
  const [universityHost, setUniversityHost] = useState<any[]>([]);
  useEffect(() => {
    form.resetFields();
    if (
      detailData !== null &&
      detailData !== undefined &&
      modeOpen === "edit"
    ) {
      // console.log(detailData?.moocConstructionPlanIndustryGroup.flatMap(
      //   (e: any) => e.mooc_industry_group
      // ));
      console.log(
        "Hello",
        detailData?.moocConstructionPlanCollaborator.flatMap(
          (e: any) => e.collaboratedUniversityId
        )
      );
      form.setFieldsValue({
        id: detailData?.id,
        registrationCode: detailData?.registrationCode,
        registrationName: detailData?.registrationName,
        courseStart: dayjs(detailData?.courseStart),
        courseDuration: detailData?.courseDuration,
        constructionPlanStatus: detailData?.constructionPlanStatus,
        courseType: detailData?.courseType,
        registrationSubmissionDate: dayjs(
          detailData?.registrationSubmissionDate
        ),
        registrationSubmitterId: detailData?.registrationSubmitterId,
        universityId: detailData?.universityId,
        registrationStageId: detailData?.registrationStageId,
        createdDate: dayjs(detailData?.createdDate),
        updatedDate: dayjs(detailData?.updatedDate),
        isDeleted: detailData?.isDeleted,
        moocApproval: detailData?.moocApproval,
        moocConstructionPlanCollaborator:
          detailData?.moocConstructionPlanCollaborator,

        lstIndustryIds:
          detailData?.moocConstructionPlanIndustry !== undefined &&
          detailData?.moocConstructionPlanIndustry.length > 0
            ? detailData?.moocConstructionPlanIndustry.flatMap(
                (e: any) => `${e.industryId}`
              )
            : [],

        lstIndustryGroupIds:
          detailData?.moocConstructionPlanIndustryGroup.length > 0
            ? detailData?.moocConstructionPlanIndustryGroup.flatMap(
                (e: any) => `${e.industryGroupId}`
              )
            : [],
        lstCollaboratedUniversityIds:
          detailData?.moocConstructionPlanCollaborator.length > 0
            ? detailData?.moocConstructionPlanCollaborator.flatMap(
                (e: any) => e.collaboratedUniversityId
              )
            : [],
        moocCourseConstructionPlan: detailData?.moocCourseConstructionPlan,
        otherMoocCourseConstructionPlan:
          detailData?.otherMoocCourseConstructionPlan,
        moocCoursePlanAssignment: detailData?.moocCoursePlanAssignment,
        moocCourseStructure: detailData?.moocCourseStructure,
      });
    }
  });

  useEffect(() => {
    //  console.log(modeOpen)
    form.resetFields();
    // if (id && id != 0) getDetailMoocCourseConstructionPlanData();
  }, [id]);
  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });
  const navigate = useNavigate();
  const onStartInput = () => {
    if (false) {
      //setError({ ...error, [`lecturerName`]: true });
      return;
    } else {
      form.submit();
    }
  };

  const [loading, setLoading] = useState<boolean>(true);

  // forgein key function call apis
  const [lstRegistrationSubmitterId, setLstRegistrationSubmitterId] = useState<
    any[]
  >([]);
  const [lstUniversityId, setLstUniversityId] = useState<any[]>([]);
  const [lstUniversityIdHost, setLstUniversityIdHost] = useState<any[]>([]);
  const [dataIndustry, setDataIndustry] = useState<any[]>([]);
  const [dataIndustryGroup, setDataIndustryGroup] = useState<any[]>([]);
  const [dataCourseType, setDataCourseType] = useState<any[]>([]);
  const getListLstRegistrationSubmitterId = async () => {
    try {
      await getLookupAuthUser()
        .then((res) => {
          const { data } = res.data;
          setLstRegistrationSubmitterId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return item;
            })
          );
        })
        .catch((err) => {
          console.log(err);
          // message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListLstUniversityIdHost = async () => {
    try {
      await getLookupMoocUniversityHost()
        .then((res) => {
          const { data } = res.data;
          // console.log("truong");
          // console.log(data);
          const dataConvert: any[] = [];
          const universityHostFind = data.find(
            (u: any) => u.enterprise_id === getUserInfo?.enterpriseUUID
          );
          // form.resetFields();
          setUniversityHost([universityHostFind?.id]);
          data.forEach((e: any) => {
            dataConvert.push({
              value: e.id,
              label: e.name,
            });
          });

          // console.log(dataConvert);
          setLstUniversityIdHost(dataConvert);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListLstUniversityId = async () => {
    try {
      await getLookupMoocUniversity()
        .then((res) => {
          const { data } = res.data;
          // console.log("truong");
          // console.log(data);
          const dataConvert: any[] = [];
          data.forEach((e: any) => {
            dataConvert.push({
              value: e.id,
              label: e.name,
            });
          });
          // console.log("Hello", dataConvert);
          setLstUniversityId(dataConvert);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getDataIndustry = async () => {
    try {
      setLoading(true);
      await getLookupIndustry()
        .then((res) => {
          const { data } = res.data;
          const dataConvert: any[] = [];
          data.forEach((e: any) => {
            dataConvert.push({
              value: `${e.id}`,
              label: e.name,
            });
          });

          setDataIndustry(dataConvert);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getDataIndustryGroup = async () => {
    try {
      setLoading(true);
      await getLookupIndustryGroup()
        .then((res) => {
          const { data } = res.data;
          const dataConvert: any[] = [];
          data.forEach((e: any) => {
            dataConvert.push({
              value: `${e.id}`,
              label: e.name,
            });
          });

          setDataIndustryGroup(dataConvert);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const filterOption = (input: any, option: any) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  useEffect(() => {
    getListLstRegistrationSubmitterId();
    getListLstUniversityId();
    getListLstUniversityIdHost();
    getDataIndustry();
    getDataIndustryGroup();
    setDataCourseType([
      {
        label: "Tự triển khai",
        value: 1,
      },
      {
        label: "Hợp tác xây dựng",
        value: 2,
      },
      {
        label: "Dùng chung",
        value: 3,
      },
    ]);
  }, []);

  return (
    <>
      <DrawerCustom
        title={
          id && id !== 0 ? "Chỉnh sửa kế hoạch" : "Thêm mới đăng ký kế hoạch"
        }
        open={isOpened}
        onClose={() => {
          form.resetFields();
          // setIsShowCollaborated(false);
          //setDetailData({});

          setIsOpened(false);
          console.log("closed");
        }}
        extraNode={
          <Space>
            <Button
              onClick={() => {
                setIsOpened(false);
                //setDetailData({});
                form.resetFields();
              }}
              className="btn btn-outlined"
            >
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={onStartInput}
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
        widthDrawer={736}
      >
        <Form
          className="form-create-edit"
          layout={"vertical"}
          form={form}
          onFinish={onFinish}
          name="create-edit-mooc-course-construction-plan-detail"
        >
          <Row gutter={24} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Form.Item
                name="registrationName"
                rules={[validations.registrationName]}
                label="Nhập tên đăng ký"
                style={{ marginBottom: 5 }}
              >
                <FormInput placeholder="Nhập tên đăng ký" />
              </Form.Item>
              <span className="label-down-text">
                Tên hiển thị công khai cho đăng ký của bạn
              </span>
            </Col>
            <Col span={12}>
              <Form.Item
                name="registrationCode"
                rules={[validations.registrationCode]}
                label="Mã đăng ký"
                style={{ marginBottom: 5 }}
              >
                <FormInput placeholder="Nhập mã đăng ký" />
              </Form.Item>
              <span className="label-down-text">
                Số định danh duy nhất xác định đăng ký của bạn
              </span>
            </Col>
          </Row>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Form.Item
              name="lstIndustryIds"
              label="Khoa"
              rules={[validations.courseIndustry]}
            >
              <Select
                filterOption={filterOption}
                mode="multiple"
                options={dataIndustry}
                allowClear={true}
                placeholder="Chọn khoa đăng ký kế hoạch áp dụng"
                onChange={(e) => {
                  console.log(dataIndustry);
                  console.log(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Form.Item
              name="lstIndustryGroupIds"
              label="Chuyên ngành"
              rules={[validations.courseIndustryGroup]}
            >
              <Select
                filterOption={filterOption}
                mode="multiple"
                options={dataIndustryGroup}
                allowClear={true}
                placeholder="Chọn chuyên ngành đăng ký khóa học áp dụng"
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginBottom: 10 }}>
            <Form.Item
              name="courseType"
              label="Loại khóa học"
              rules={[validations.courseType]}
            >
              <Select
                showSearch
                optionFilterProp="children"
                options={dataCourseType}
                filterOption={filterOption}
                allowClear={true}
                placeholder="Chọn loại khóa học áp dụng"
                // onChange={(e) => {
                //   listDataMemberUpdate[index].organizationMemberId =
                //     parseInt(e);
                //   setListDataMember(listDataMemberUpdate);
                // }}
              />
            </Form.Item>
          </Col>
          <Row gutter={24} style={{ marginBottom: 10 }}>
            <Col span={12}>
              <Form.Item
                name="courseStart"
                rules={[validations.courseStart]}
                label="Thời gian bắt đầu"
                style={{ marginBottom: 5 }}
              >
                <DatePicker placeholder="Chọn ngày" format={dateFormat} />
              </Form.Item>
              <span className="label-down-text">
                Dự kiến thời gian bắt đầu khóa học
              </span>
            </Col>
            <Col span={12}>
              <Form.Item
                name="courseDuration"
                rules={[validations.courseDuration]}
                label="Thời lượng dự kiến"
                style={{ marginBottom: 5 }}
              >
                <Input type="number" placeholder="Nhập thời lượng dự kiến" />
              </Form.Item>
              <span className="label-down-text">Dự kiến số lượng tín chỉ</span>
            </Col>
          </Row>

          <div style={{ marginTop: 20 }}>Trường chủ trì đăng ký kế hoạch</div>
          <Col span={24} style={{ marginTop: 10 }}>
            <Form.Item
              name="universityId"
              label="Trường chủ trì"
              rules={[validations.universityId]}
              initialValue={universityHost}
            >
              <Select
                showSearch
                optionFilterProp="children"
                options={lstUniversityIdHost}
                filterOption={filterOption}
                allowClear={true}
                placeholder="Chọn trường đại học chủ trì"
              />
            </Form.Item>
          </Col>
          <div style={{ marginTop: 20 }}>Trường hợp tác đăng ký kế hoạch</div>
          <Col span={24} style={{ marginTop: 10 }}>
            <Form.Item
              name="lstCollaboratedUniversityIds"
              label="Trường hợp tác"
              // rules={[validations.universityId]}
            >
              <Select
                showSearch
                mode="multiple"
                optionFilterProp="children"
                options={lstUniversityId}
                filterOption={filterOption}
                allowClear={true}
                placeholder="Chọn trường đại học hợp tác"
              />
            </Form.Item>
          </Col>
        </Form>
      </DrawerCustom>
    </>
  );
};
