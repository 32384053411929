import { isArray } from "lodash";
import React from "react";
import { QuizAnsProps } from "../../../../../../types/scoring-course";
import H5AudioPlayer from "react-h5-audio-player";
import { PauseCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import "./style.scss";
import EmptyComponent from "../../../../../../components/empty";

function Mp3({ result }: QuizAnsProps) {
  const currentAnsOfUser = result?.results[0];

  return (
    <div className="mp3-screen">
      <div className="audio-control">
        {isArray(currentAnsOfUser) ? (
          <H5AudioPlayer
            src={currentAnsOfUser[0]?.contents[0]}
            className="audio-play"
            autoPlay={false}
            autoPlayAfterSrcChange={false}
            loop={false}
            showFilledVolume={false}
            showJumpControls={false}
            customAdditionalControls={[]}
            customIcons={{
              play: (
                <PlayCircleOutlined
                  className="text-primary"
                  style={{ fontSize: 20 }}
                />
              ),
              pause: (
                <PauseCircleOutlined
                  className="text-primary"
                  style={{ fontSize: 20 }}
                />
              ),
            }}
          />
        ) : (
          <EmptyComponent description="Không có câu trả lời" />
        )}
      </div>
    </div>
  );
}

export default Mp3;
