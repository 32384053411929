import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import {
  Button,
  Col,
  Row,
  Typography,
} from "antd";

import "./filter-table.scss";
import {
  CloseOutlined,
  SaveOutlined,
} from "@ant-design/icons";

type FilterTableProps = {
  handleClose: () => void;
  saveAccount: () => void;
  tab: string
};

const FilterStudentAccountsImport: FunctionComponent<FilterTableProps> = ({
  saveAccount,
  handleClose,
  tab
}) => {

  return (
    <>
      <div className="heading-extra">
        <Row gutter={8} className="row-filter">
          <Col lg={{ span: 13 }}></Col>
          <Col
            md={{ span: 2.5 }}
            lg={{ span: 2.5 }}
            xl={{ span: 2.5 }}
            xs={{ span: 2.5 }}
            className="filter-group-select"
          >
            <div className="heading-filter">
              <Button className="" onClick={handleClose}>
                <CloseOutlined />
                <Typography.Text>Đóng</Typography.Text>
              </Button>
            </div>
          </Col>
          <Col
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xs={{ span: 8 }}
          >
            <div className="heading-search">
              <Button className="btn btn-primary" onClick={saveAccount}>
                <SaveOutlined />
                <Typography.Text>Lưu tài khoản hợp lệ</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterStudentAccountsImport;
