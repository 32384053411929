import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const getMoocSurveyObjectsFilter: (id: string) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfig.get(`/mooc-survey-publish/get-surveys-objects-filter/${id}`);
};

export const getMoocSurveyTimePublish: (id: string) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfig.get(`/mooc-survey-publish/get-time-publish-survey/${id}`);
};

export const postMoocSurveyFilterListStudent: (data: object) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfig.post(`/mooc-survey-publish/filter-student-survey`, data);
};

export const postMoocPublishSurvey: (data: object) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfig.post(`/mooc-survey-publish/publish-survey`, data);
};

export const postMoocRevokeSurvey: (id: string) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfig.get(`/mooc-survey-publish/revoke-survey/${id}`);
};
