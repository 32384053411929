import { IDataCreateUser } from "../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig, axiosConfigUpload } from "../../../config/api/configApi";
import {
  TypeChapterList,
  TypeCourseDescriptionDocument,
} from "../../../types/course-plan-managment/mooc-course-construction-plan";

export const checkExistCodeName: (
  input: string
) => Promise<AxiosResponse<any>> = (input) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/check-exist-code-name/${input}`
  );
};
export const postMoocCourseConstructionPlanSearch: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-construction-plan/search`, data);
};

export const getMoocCourseConstructionPlanById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-course-construction-plan/get-by-id/${id}`);
};

export const getCourseApprovalInfomationById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/get-course-information-approval-by-id/${id}`
  );
};

export const putUpdateMoocCourseConstructionPlan: (
  id: number,
  data: object
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/mooc-course-construction-plan/update/${id}`, data);
};

export const postCreateMoocCourseConstructionPlan: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-construction-plan/create`, data);
};

export const deleteMoocCourseConstructionPlan: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.put(`/mooc-course-construction-plan/delete/${id}`);
};

// Categories

export const getLookupAuthUser: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-user`);
};
export const getLookupMoocUniversity: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`/lookup/get-schools`);
};
export const getLookupMoocUniversityHost: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`/lookup/get-unniversity-host`);
};
export const getLookupIndustry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-industry`);
};
export const getLookupIndustryGroup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-industry-group`);
};

export const createIndustryGroup: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`mooc-construction-plan-industry-group/create`, data);
};
export const createIndustry: (data: object) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post(`/mooc-construction-plan-industry/create`, data);
};

//course-structure
export const getListMoocCourseStructureById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/get-list-course-structure-by-id/${Number(
      id
    )}`
  );
};

export const getListCoursePlanAssignmentById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/course-planning-assignment/get-list-course-plan-assignment/${Number(
      id
    )}`
  );
};


export const getListMoocCourseStructureChapterById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/get-list-course-structure-chapter-by-id/${Number(
      id
    )}`
  );
};

export const getListMoocCourseDescriptionDocumentById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/mooc-course-construction-plan/get-list-course-description-document-by-id/${Number(
      id
    )}`
  );
};

export const postUpdateCourseDescriptionDocumentById: (
  data: TypeCourseDescriptionDocument[],
  id: number
) => Promise<AxiosResponse<any>> = (data, id) => {
  return axiosConfig.post(
    `/mooc-course-construction-plan/create-mooc-course-description-document-by-id/${id}`,
    { descriptionDocumentList: data }
  );
};

export const postUpdateCourseStructureById: (
  data: TypeChapterList[],
  id: number
) => Promise<AxiosResponse<any>> = (data, id) => {
  return axiosConfig.post(
    `/mooc-course-construction-plan/create-mooc-course-structure-by-id/${id}`,
    { courseStructureList: data }
  );
};
export const syncCourseStructureById: (
  data: TypeChapterList[],
  id: number
) => Promise<AxiosResponse<any>> = (data, id) => {
  return axiosConfig.post(
    `/mooc-course-construction-plan/sync-mooc-course-structure-by-id/${id}`,
    { courseStructureList: data }
  );
};

export const upLoadFileDescriptionDocument: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigUpload.post(
    `/s3-client/upload-course-description-document`,
    data
  );
};

export const downloadFileDescriptionDocument: (data: {
  url: string;
  destination?: string;
}) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.get(
    `/s3-client/downloadFile-course-description-document`,
    data
  );
};

export const downloadFile: (data: {
  filePath: any;
  destination?: any;
}) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/s3-client/downloadFile`, data);
};
//approval-configuration

export const getApprovalConfiguration: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-approval-configuration/search`, data);
};
