import React, { useEffect, useMemo, useRef, useState } from "react";
import EditorQuill from "../editor-quill/EditorQuill";
import { Button, Col, Input, Row, Space } from "antd";
import ReactQuill from "react-quill";
import toolbarOptions from "./toolbarOptions";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";


interface InputInfoProps {
  structureItemName: string;
  structureItemDescription: string;
  isChapter?: boolean;
  isSubject?: boolean;
  isLesson?: boolean;
  onChangeDescription?: (description: string) => void;
  onChangeName?: (name: string) => void;
  onAddNewCourseStructure?: () => void;
  onDeleteCourseStructure?: () => void;
}

const InputInfo: React.FC<InputInfoProps> = ({
  structureItemName,
  structureItemDescription,
  isChapter,
  isSubject,
  isLesson,
  onChangeDescription,
  onChangeName,
  onAddNewCourseStructure,
  onDeleteCourseStructure,
}) => {
  const modules = {
    toolbar: toolbarOptions,
  };

  const name = useMemo(() => {
    return structureItemName;
  }, [structureItemName]);

  const [isEdit, setIsEdit] = useState(false);
  const quillRef = useRef<HTMLDivElement>(null);

  const handleEditorFocus = () => {
    setIsEdit(true);
  };

  const handleBlurDescription = () => {
    setIsEdit(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        quillRef.current &&
        !quillRef.current.contains(event.target as Node)
      ) {
        setIsEdit(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={quillRef}>
      <div style={{ padding: "5px" }}>
        <Space direction="vertical" size="small" style={{ display: "flex" }}>
          <Row
            // style={{ margin: "0 auto", display: "flex", justifyContent: "space-between",alignItems: "center", }}
            style={{
              margin: "5px auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Col
              style={
                isChapter
                  ? { flexGrow: 1, marginRight: "10px", fontWeight: "normal" }
                  : { flexGrow: 1, marginRight: "10px" }
              }
            >
              <Input
                style={
                  isChapter
                    ? { width: "100%", fontSize: "25px" }
                    : { width: "100%" }
                }
                variant="borderless"
                value={name}
                onFocus={handleEditorFocus}
                onChange={(e) => onChangeName && onChangeName(e.target.value)}
                onBlur={(e) => onChangeName && onChangeName(e.target.value)}
              />
              {isEdit && (
                <div>
                  <ReactQuill
                    value={structureItemDescription}
                    modules={modules}
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignItems: "flex-end",
                    }}
                    onBlur={() => handleBlurDescription}
                    onChange={onChangeDescription}
                    placeholder={
                      structureItemDescription === "" ||
                      !structureItemDescription
                        ? `Nhập mô tả`
                        : ``
                    }
                  />
                </div>
              )}
              {!isEdit && (
                <div>
                  {isChapter && (
                    <Input
                      readOnly
                      variant="borderless"
                      value={`Mô tả cho chương ${name}`}
                    />
                  )}
                  {isSubject && (
                    <Input
                      readOnly
                      variant="borderless"
                      value={`Mô tả cho bài giảng ${name}`}
                    />
                  )}
                  {isLesson && (
                    <Input
                      readOnly
                      variant="borderless"
                      value={`Mô tả cho học liệu ${name}`}
                    />
                  )}
                </div>
              )}
            </Col>
            <Col
              style={
                isChapter ? { display: "none" } : { alignSelf: "flex-start" }
              }
            >
              <Space direction="horizontal" size="small">
                <Button
                  icon={<PlusOutlined />}
                  size="small"
                  onClick={onAddNewCourseStructure}
                ></Button>
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  onClick={onDeleteCourseStructure}
                ></Button>
              </Space>
            </Col>
          </Row>
        </Space>
      </div>
    </div>
  );
};

export default InputInfo;
