// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-settings {
  display: flex;
  justify-content: center; }
  .wrapper-settings .setting-portfolio {
    width: 50%;
    height: auto;
    gap: 16px;
    border-radius: 8px; }
    .wrapper-settings .setting-portfolio .portfolio-card {
      margin-top: 20px;
      padding: 24px; }
      .wrapper-settings .setting-portfolio .portfolio-card .ant-card-head .ant-card-head-title {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500; }
      .wrapper-settings .setting-portfolio .portfolio-card .setting-item {
        padding: 12px 0;
        margin: 0;
        border-top: 1px solid #d9d9d9; }
        .wrapper-settings .setting-portfolio .portfolio-card .setting-item .ant-form-item-label {
          width: 30%;
          display: flex;
          justify-content: start; }
          .wrapper-settings .setting-portfolio .portfolio-card .setting-item .ant-form-item-label label {
            font-weight: 700;
            line-height: 22px; }
        .wrapper-settings .setting-portfolio .portfolio-card .setting-item .ant-form-item-control {
          width: 50%; }
`, "",{"version":3,"sources":["webpack://./src/pages/category-management/portfolio-value-management/portfolio-value-management-detail/portfolio-value-management-detail.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAA;EAFzB;IAKI,UAAU;IACV,YAAY;IACZ,SAAS;IACT,kBAAkB,EAAA;IARtB;MAWM,gBAAgB;MAChB,aAAa,EAAA;MAZnB;QAgBU,eAAe;QACf,iBAAiB;QACjB,gBAAgB,EAAA;MAlB1B;QAuBQ,eAAe;QACf,SAAS;QACT,6BAA6B,EAAA;QAzBrC;UA4BU,UAAU;UACV,aAAa;UACb,sBAAsB,EAAA;UA9BhC;YAiCY,gBAAgB;YAChB,iBAAiB,EAAA;QAlC7B;UAuCU,UAAU,EAAA","sourcesContent":[".wrapper-settings {\n  display: flex;\n  justify-content: center;\n\n  .setting-portfolio {\n    width: 50%;\n    height: auto;\n    gap: 16px;\n    border-radius: 8px;\n\n    .portfolio-card {\n      margin-top: 20px;\n      padding: 24px;\n\n      .ant-card-head {\n        .ant-card-head-title {\n          font-size: 20px;\n          line-height: 28px;\n          font-weight: 500;\n        }\n      }\n\n      .setting-item {\n        padding: 12px 0;\n        margin: 0;\n        border-top: 1px solid #d9d9d9;\n\n        .ant-form-item-label {\n          width: 30%;\n          display: flex;\n          justify-content: start;\n\n          label {\n            font-weight: 700;\n            line-height: 22px;\n          }\n        }\n\n        .ant-form-item-control {\n          width: 50%;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
