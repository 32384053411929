import { Card, Empty } from 'antd'
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Row,
  TreeDataNode,
  Typography,
  Space,
  Input,
  Select,
  message,
  Divider,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import CustomCard from '../../../../components/custom-card/CustomCard';
import { TypeCourseDescriptionDocument } from '../../../../types/course-plan-managment/mooc-course-construction-plan';
import axios from 'axios';
import JSZip from 'jszip';
import './detail.scss'


interface ListDocumentPlanProps {
  dataListDocument: TypeCourseDescriptionDocument[];
}

const ListDocumentPlan = (props: ListDocumentPlanProps) => {
  const { dataListDocument } = props
  // console.log({ dataListDocument })

  const handleShowDocument = (document: any) => {
    // console.log({ document })
    // if (document && document.filePath) {
    window.open(document.filePath, '_blank');
    // }
  };

  // const handleDownload = async (document: any) => {

  //   if (document.filePath && document.filePath !== "") {
  //     try {
  //       const response = await axios.get(document.filePath, {
  //         responseType: "arraybuffer",
  //       });
  //       const blob = new Blob([response.data], {
  //         type: document.fileType,
  //       });
  //       const url = URL.createObjectURL(blob);
  //       const downloadLink = document.createElement("a");
  //       downloadLink.href = url;
  //       downloadLink.setAttribute(
  //         "download",
  //         document.fileName || ""
  //       );
  //       message.success(
  //         `Đang tải về! Vui lòng kiểm tra file trong thư mục trên máy tính!`
  //       );
  //       document.body.appendChild(downloadLink);
  //       downloadLink.click();
  //       document.body.removeChild(downloadLink);
  //       URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error("Error downloading file:", error);
  //     }
  //   } else {
  //     console.log("Không có đường dẫn tài liệu.");
  //   }
  // };

  const handleDownload = async (documentFile: any) => {
    console.log({ documentFile })
    if (documentFile.filePath && documentFile.filePath !== "") {
      try {
        const response = await axios.get("https://s3.moooc.xyz/bucket/test/1714921575247_T%C3%83%C2%ADch%20h%C3%A1%C2%BB%C2%A3p%20Logging%20s%C3%A1%C2%BB%C2%AD%20d%C3%A1%C2%BB%C2%A5ng%20serilog.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ihWotP7nBIXENZiD%2F20240505%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240505T150616Z&X-Amz-Expires=900&X-Amz-Signature=08d8863ef07eeca5a237da2d459e350e230e9d4f759b23591847b3a902e228b0&X-Amz-SignedHeaders=host", {
          responseType: "arraybuffer",
        });
        const zip = new JSZip();
        zip.file(documentFile.fileName, response.data);

        // Create a new folder in the zip and add the file to it
        const folderName = "Documents"; // Change folder name as needed
        const zipFolder = zip.folder(folderName);
        zipFolder?.file(documentFile.fileName, response.data);

        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: "blob" });
        const url = URL.createObjectURL(zipBlob);

        // Download the zip file
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.setAttribute("download", "documents.zip"); // Change the zip file name as needed
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading and zipping file:", error);
      }
    } else {
      console.log("Không có đường dẫn tài liệu.");
    }
  };


  return (
    // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    //   <Empty />
    //   <p>Không tìm thấy file tài liệu nào</p>
    // </div>
    <Card >
      <CustomCard className="card-info--wrapper">
        <Row justify="space-between" style={{ marginTop: "20px" }}>
          <Col>
            <Row>
              <Typography.Title level={4}>Tài liệu mô tả</Typography.Title>
            </Row>
            <Row>
              <Typography.Text style={{ color: "GrayText" }}>
                Danh sách tài liệu đính kèm mô tả đăng ký kế hoạch xây dựng khóa
                học
              </Typography.Text>
            </Row>
          </Col>
        </Row>
        <Divider style={{ marginTop: "5px" }} />
        {dataListDocument &&
          dataListDocument.map((document, indexDocument) => {
            return (
              // <div key={indexDocument}>
              <div key={indexDocument} style={{ marginTop: "20px", display: 'flex', justifyContent: 'space-between' }}>
                <Space>

                  {document && document.fileName && document && document.fileType && document?.fileName.includes(document.fileType) ? (
                    <h5 className='document-file-name'>{document.fileName}</h5>
                  ) : (
                    <h5 className='document-file-name'>{`${document.fileName}.${document.fileType}`}</h5>
                  )}


                </Space>
                <Space >
                  <Space direction="horizontal" size="small">
                    {document.filePath && document.filePath !== "" && (
                      <>
                        <Button
                          onClick={() => handleShowDocument(document)}

                        >
                          Xem
                        </Button>
                        {/* <Button onClick={() => handleDownload(document)}>
                          Tải về
                        </Button> */}
                      </>
                    )}


                  </Space>
                </Space>
              </div>
              // <Divider style={{ marginTop: "5px" }} />
              // </div>
            );
          })}
        <Divider />

      </CustomCard>
    </Card>
  )
}

export default ListDocumentPlan