// import "./detail.scss";
import { Button, Input, Radio, Row, Space, ConfigProvider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ListAnswer } from "../../../../../../types/survey-managment/survey";

const QuestionType4 = (props: any) => {
    const [options, setOptions] = useState<ListAnswer[]>(props.listOptions || []);
    // const [showPlaceholder, setShowPlaceholder] = useState(true);

    const onHandleDeleteOption = (index: number) => {
        const newOptions = options.filter((_, i) => i !== index);

        setOptions(newOptions);
        props.setUpdateListAnswer(newOptions);
    };

    return (
        <div>
            <div>
                <br />
                <ConfigProvider
                    theme={{
                        components: {
                            Input: {
                                colorTextPlaceholder: "black",
                                paddingInline: 0,
                                paddingBlock: 0,
                            },
                            Button: {
                                paddingInline: 2,
                                paddingBlock: 0,
                            },
                        },
                    }}
                >
                    <Space direction="vertical" size="small" style={{ display: "flex" }}>
                        {options.map((option, index) => {
                            return (
                                <Row
                                    style={{
                                        margin: "0 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    key={index}
                                >
                                    <Input
                                        prefix={<>{index + 1}.</>}
                                        // suffix={
                                        //     <Button
                                        //         style={{ width: 20, height: 25 }}
                                        //         icon={<CloseOutlined />}
                                        //         // onClick={() => {
                                        //         //     onHandleDeleteOption(index);
                                        //         // }}
                                        //     />
                                        // }
                                        variant="borderless"
                                        value={
                                            option.answerText || option.answerText === ""
                                                ? option.answerText
                                                : `Lựa chọn văn bản ${options.length}`
                                        }
                                        // onChange={(event) => handleChangeAnswerText(event, index)}
                                    />
                                </Row>
                            );
                        })}
                    </Space>
                </ConfigProvider>
                <br />
            </div>
        </div>
    );
};

export default QuestionType4;
