import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { AccountUserSlice, createAccountUserSlice, } from "./list-account-users/slices";
import { AccountTeacherSlice, createAccountTeachersSlice, } from "./list-account-teachers/slices";
import { AccountMinistrySlice, createMinistryTeachersSlice, } from "./list-account-ministry/slice";
import {
  createSurveyDetailSlice,
  createSurveyInfoSlice,
  SurveyDetailSlice,
  SurveyInfoSlice,
} from "./survey-managment/detail-survey/slice";
import { createSurveyRespondSlice, SurveyRespondSlice, } from "./survey-managment/mooc-survey-respond/slice";
import { IRolesSlice, rolesSlices } from "./list-roles/slices";
import { AuthState, AuthTokenSlice } from "./auth/slice";
import { Sider, SiderStore } from "./sider/sider";
import { AddLessonStore, Lesson } from "./add-lession/addLesson";
import { createStudyManagementSlice, StudyManagementSlice, } from "./study-management/slices";
import { AutomaticScoreSlice, createAutomaticScoreSlice, } from "./automatic-scoring/slices";
import { ThemeSurvey, ThemeSurveyStore, } from "./survey-managment/survey-theme/store-theme";
import { CourseStrutureSlice, createCourseStrutureSlice, } from "./course-plan-managment/detail-course-plan/slice";
import { AddSurveyStore, Survey } from "./add-survey/addSurvey";
import { coursesSlice } from "./courses/slice";
import { globalSlice } from "./global/global";
import { curriculumSlice } from "./curriculum/slice";
import { headerSlice } from "./header";
import { notificationSlice, NotificationState } from "./notification/slice";
import { colorSettingSlice, ColorState } from "./color-setting/color";
import { staticReportSlice, StaticReportState } from "./static-report/slice";
import { AddPermissionStore, Permission } from "./permission/slice";

export const useAppStore = create<
  AccountUserSlice & AccountTeacherSlice & AccountMinistrySlice
>()(
  devtools(
    (...a) => ({
      ...createAccountUserSlice(...a),
      ...createAccountTeachersSlice(...a),
      ...createMinistryTeachersSlice(...a),
    }),
    {
      name: "list-account",
    }
  )
);

export const useRolesStore = create<IRolesSlice>()(
  devtools<IRolesSlice>(
    (...b) => ({
      ...rolesSlices(...b),
    }),
    {
      name: "list-roles",
    }
  )
);

export const useStudyManagementStore = create<StudyManagementSlice>()(
  devtools<StudyManagementSlice>(
    (...b) => ({
      ...createStudyManagementSlice(...b),
    }),
    {
      name: "study-management",
    }
  )
);

export const useAuthStore = create<AuthState>()(
  devtools(
    persist<AuthState>(
      (...a) => ({
        ...AuthTokenSlice(...a),
      }),
      { name: "auth-storage" }
    ),
    { name: "auth" }
  )
);

export const useSider = create<Sider>()(
  devtools(
    persist<Sider>(
      (...a) => ({
        ...SiderStore(...a),
      }),
      { name: "sider-storage" }
    ),
    { name: "auth" }
  )
);

export const useAddLesson = create<Lesson>()(
  devtools(
    persist<Lesson>(
      (...a) => ({
        ...AddLessonStore(...a),
      }),
      { name: "add-lesson-storage" }
    )
  )
);

export const useSurveyDetail = create<SurveyDetailSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createSurveyDetailSlice(...a),
      }),
      { name: "listSurveySection-storage" }
    ),
    { name: "listSurveySection" }
  )
);

export const useSurveyInfo = create<SurveyInfoSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createSurveyInfoSlice(...a),
      }),
      { name: "surveyInfo-storage" }
    ),
    { name: "surveyInfo" }
  )
);

export const useSurveyRespond = create<SurveyRespondSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createSurveyRespondSlice(...a),
      }),
      { name: "surveyRespond-storage" }
    ),
    { name: "surveyRespond" }
  )
);

export const useAutomaticScoreStore = create<AutomaticScoreSlice>()(
  devtools<AutomaticScoreSlice>(
    (...b) => ({
      ...createAutomaticScoreSlice(...b),
    }),
    {
      name: "auto-score",
    }
  )
);
export const useAddSurvey = create<Survey>()(
  devtools(
    persist<Survey>(
      (...a) => ({
        ...AddSurveyStore(...a),
      }),
      { name: "add-survey-storage" }
    )
  )
);

export const useAddPermission = create<Permission>()(
  devtools(
    persist<Permission>(
      (...a) => ({
        ...AddPermissionStore(...a),
      }),
      { name: "permission-storage" }
    )
  )
);

export const useThemeSurvey = create<ThemeSurvey>()(
  devtools(
    persist<ThemeSurvey>(
      (...a) => ({
        ...ThemeSurveyStore(...a),
      }),
      { name: "Theme-survey-storage" }
    ),
    { name: "auth" }
  )
);

export const useCourseStructure = create<CourseStrutureSlice>()(
  devtools(
    persist(
      (...a) => ({
        ...createCourseStrutureSlice(...a),
      }),
      { name: "courseStructure-storage" }
    ),
    { name: "courseStructure" }
  )
);

export const useCoursesStore = create<any>()(
  devtools<any>(
    (...b) => ({
      ...coursesSlice(...b),
    }),
    {
      name: "courses",
    }
  )
);

export const globalStore = create<any>()(
  devtools<any>(
    (...b) => ({
      ...globalSlice(...b),
    }),
    {
      name: "notification",
    }
  )
);

export const useCurriculumStore = create<any>()(
  devtools<any>(
    (...b) => ({
      ...curriculumSlice(...b),
    }),
    {
      name: "curriculum",
    }
  )
);

export const headerStore = create<any>()(
  devtools<any>(
    (...b) => ({
      ...headerSlice(...b),
    }),
    {
      name: "header",
    }
  )
);

export const useNotificationStore = create<NotificationState>()(
  devtools<NotificationState>(
    (...b) => ({
      ...notificationSlice(...b),
    }),
    {
      name: "notification",
    }
  )
);

export const colorStore = create<ColorState>()(
  devtools<ColorState>(
    (...b) => ({
      ...colorSettingSlice(...b),
    }),
    {
      name: "color-setting",
    }
  )
);

export const staticReportStore = create<StaticReportState>()(
  devtools<StaticReportState>(
    (...b) => ({
      ...staticReportSlice(...b),
    }),
    {
      name: "static-report",
    }
  )
);



