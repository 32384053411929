// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  padding: 50px;
  width: 100%;
  height: calc(100vh - 168px);
  overflow-x: auto; }
  .wrapper .detail-account {
    display: flex;
    width: 100%;
    justify-content: center; }
    .wrapper .detail-account .form-detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto; }
    .wrapper .detail-account .avatar {
      margin-bottom: 40px; }
  .wrapper .card-info--wrapper {
    width: 560px;
    margin-bottom: 24px; }
    .wrapper .card-info--wrapper.no-bottom-space {
      margin-bottom: 0; }
  .wrapper .card-heading--info h5 {
    width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-size: 20px; }
  .wrapper .card-heading--info .sub-title {
    position: relative;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
    text-align: left; }
`, "",{"version":3,"sources":["webpack://./src/pages/detail/detail.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,2BAA2B;EAC3B,gBAAgB,EAAA;EAJlB;IAOI,aAAa;IACb,WAAW;IACX,uBAAuB,EAAA;IAT3B;MAYM,aAAa;MACb,sBAAsB;MACtB,mBAAmB;MACnB,YAAY,EAAA;IAflB;MAmBM,mBAAmB,EAAA;EAnBzB;IAwBI,YAAY;IACZ,mBAAmB,EAAA;IAzBvB;MA4BM,gBAAgB,EAAA;EA5BtB;IAoCM,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,gBAAgB;IAChB,eAAe,EAAA;EA5CrB;IAgDM,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,0BAA0B;IAC1B,gBAAgB,EAAA","sourcesContent":[".wrapper {\n  padding: 50px;\n  width: 100%;\n  height: calc(100vh - 168px);\n  overflow-x: auto;\n\n  .detail-account {\n    display: flex;\n    width: 100%;\n    justify-content: center;\n\n    .form-detail {\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      height: auto;\n    }\n\n    .avatar {\n      margin-bottom: 40px;\n    }\n  }\n\n  .card-info--wrapper {\n    width: 560px;\n    margin-bottom: 24px;\n\n    &.no-bottom-space {\n      margin-bottom: 0;\n    }\n  }\n\n  .card-heading--info {\n\n\n    h5 {\n      width: 100%;\n      position: relative;\n      overflow: hidden;\n      display: flex;\n      flex-direction: row;\n      align-items: center;\n      justify-content: flex-start;\n      text-align: left;\n      font-size: 20px;\n    }\n\n    .sub-title {\n      position: relative;\n      font-size: 14px;\n      line-height: 22px;\n      color: rgba(0, 0, 0, 0.45);\n      text-align: left;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
