import { Form, Layout, PaginationProps, Tabs, TabsProps } from "antd";
import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import FilterBrowseSurvey from "./components/FilterBrowseSurvey/FilterBrowseSurvey";
import TableWaitApprove from "./components/BrowseTableSurvey/TableWaitApprove";
import TableApproved from "./components/BrowseTableSurvey/TableApproved";
import {
  ApprovalType,
  FilterDateSubmitSurvey,
} from "../../../types/survey-browse";
import {
  postSurveySearchApprove,
  postSurveySearchSubmit,
} from "../../../service/survey-managment/list-survey";
import { isEmpty } from "lodash";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useAuthStore } from "../../../stores/stores";
import ManyApprovalsSurvey from "./components/many-approvals-survey/ManyApprovalsSurvey";
import "./index.scss";
import { useLocation } from "react-router-dom";
type ContentConstructionProps = {
  title?: string;
};
const BrowseSurvey = (props: ContentConstructionProps) => {
  const { title } = props;
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState<string>(
    location?.state?.keyTab ?? "1"
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchValueNotFound, setSearchValueNotFound] = useState<string>("");
  const [form] = Form.useForm();
  const [dateSubmitFilter, setDateSubmitFilter] =
    useState<FilterDateSubmitSurvey>({
      submissionDate: null,
      approvalDate: null,
    });
  const [listSurveyApproveSubmit, setListSurveyApproveSubmit] = useState<
    ApprovalType[]
  >([]);
  const [listSurveyApprove, setListSurveyApprove] = useState<ApprovalType[]>(
    []
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecordsSubmit, setTotalRecordsSubmit] = useState<number>(0);
  const [totalRecordsApprove, setTotalRecordsApprove] = useState<number>(0);
  const userId = useAuthStore((state) => state.user?.id);
  const [listIdApprove, setListIdApprove] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const getListDataApprove = () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      individualId: userId,
      submitedDateFrom: !isEmpty(dateSubmitFilter.submissionDate?.[0])
        ? new Date(dateSubmitFilter.submissionDate?.[0]).toISOString()
        : "",
      submitedDateTo: !isEmpty(dateSubmitFilter.submissionDate?.[1])
        ? new Date(dateSubmitFilter.submissionDate?.[1]).toISOString()
        : "",
      approvalDateFrom: !isEmpty(dateSubmitFilter.approvalDate?.[0])
        ? new Date(dateSubmitFilter.approvalDate?.[0]).toISOString()
        : "",
      approvalDateTo: !isEmpty(dateSubmitFilter.approvalDate?.[1])
        ? new Date(dateSubmitFilter.approvalDate?.[1]).toISOString()
        : "",
      // sortField: fieldSortOrder,
      // sortOrder: statusOrder,
    };
    postSurveySearchApprove(data)
      .then((res) => {
        const { data } = res.data;
        setTotalRecordsApprove(res.data.data.totalRecords);
        setListSurveyApprove(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const [isOpenManyApprovalsSurvey, setIsOpenManyApprovalsSurvey] =
    useState<boolean>(false);
  const handleCoverDate = (dateString: string) => {
    if (dateString == undefined) {
      return null;
    } else {
      const [day, month, year] = dateString.split("-");
      return new Date(`${year}-${month}-${day}`);
    }
  };
  const getListDataSurvey = (type: boolean) => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      individualId: userId,
      submitedDateFrom: !isEmpty(dateSubmitFilter.submissionDate?.[0])
        ? new Date(dateSubmitFilter.submissionDate?.[0]).toISOString()
        : "",
      submitedDateTo: !isEmpty(dateSubmitFilter.submissionDate?.[1])
        ? new Date(dateSubmitFilter.submissionDate?.[1]).toISOString()
        : "",
      approvalDateFrom: !isEmpty(dateSubmitFilter.approvalDate?.[0])
        ? new Date(dateSubmitFilter.approvalDate?.[0]).toISOString()
        : "",
      approvalDateTo: !isEmpty(dateSubmitFilter.approvalDate?.[1])
        ? new Date(dateSubmitFilter.approvalDate?.[1]).toISOString()
        : "",
      type: type,
      isPagination: true,
      // sortField: fieldSortOrder,
      // sortOrder: statusOrder,
    };
    postSurveySearchApprove(data)
      .then((res) => {
        const { data } = res.data;
        if (type == true) {
          setTotalRecordsApprove(res.data.data.totalRecords);
          setListSurveyApprove(data.data);
        } else {
          setTotalRecordsSubmit(res.data.data.totalRecords);
          setListSurveyApproveSubmit(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getListDataSurveyResetSearch = (type: boolean) => {
    setLoading(true);
    const data = {
      keyword: "",
      pageNumber: pageNumber,
      pageSize: pageSize,
      individualId: userId,
      submitedDateFrom: !isEmpty(dateSubmitFilter.submissionDate?.[0])
        ? new Date(dateSubmitFilter.submissionDate?.[0]).toISOString()
        : "",
      submitedDateTo: !isEmpty(dateSubmitFilter.submissionDate?.[1])
        ? new Date(dateSubmitFilter.submissionDate?.[1]).toISOString()
        : "",
      approvalDateFrom: !isEmpty(dateSubmitFilter.approvalDate?.[0])
        ? new Date(dateSubmitFilter.approvalDate?.[0]).toISOString()
        : "",
      approvalDateTo: !isEmpty(dateSubmitFilter.approvalDate?.[1])
        ? new Date(dateSubmitFilter.approvalDate?.[1]).toISOString()
        : "",
      type: type,
      isPagination: true,
      // sortField: fieldSortOrder,
      // sortOrder: statusOrder,
    };
    postSurveySearchApprove(data)
      .then((res) => {
        const { data } = res.data;
        if (type == true) {
          setTotalRecordsApprove(res.data.data.totalRecords);
          setListSurveyApprove(data.data);
        } else {
          setTotalRecordsSubmit(res.data.data.totalRecords);
          setListSurveyApproveSubmit(data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setPageNumber(1);
    setPageSize(10);
    clearFilter();
    getListDataSurveyResetSearch(false);
    getListDataSurveyResetSearch(true);
  }, [activeTabKey]);

  useEffect(() => {
    getListDataSurveyResetSearch(false);
    getListDataSurveyResetSearch(true);
  }, [pageNumber, pageSize]);

  const multipleApprove = () => {
    console.log(listIdApprove);
  };
  const locale = {
    emptyText: (
      <span>
        <p>
          <img
            className="image-empty-data"
            src="/images/empty-img-gray.png"
            alt="empty-img"
          ></img>
        </p>
        <p className="nodata-text">
          Không tìm kết quả với từ khoá "{searchValueNotFound}"
        </p>
      </span>
    ),
    triggerDesc: "Sắp xếp theo thứ tự Z-A",
    triggerAsc: "Sắp xếp thứ tự A-Z",
    cancelSort: "Huỷ sắp xếp",
  };
  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          <span>Chờ phê duyệt </span>
          <span className="border-total-record">{totalRecordsSubmit}</span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableWaitApprove
            activeTabKey={activeTabKey}
            totalRecords={totalRecordsSubmit}
            listSurveyApprove={listSurveyApproveSubmit}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            setListIdApprove={setListIdApprove}
            locale={locale}
            getListData={() => getListDataSurvey(false)}
            loading={loading}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <span>Đã phê duyệt </span>
          <span className="border-total-record">{totalRecordsApprove}</span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableApproved
            activeTabKey={activeTabKey}
            totalRecords={totalRecordsApprove}
            listSurveyApprove={listSurveyApprove}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            locale={locale}
            loading={loading}
          />
        </div>
      ),
    },
  ];
  const handleSubmitSearch = () => {
    setSearchValueNotFound(searchValue);
    if (activeTabKey === "1") {
      getListDataSurvey(false);
    } else {
      getListDataSurvey(true);
    }
  };
  const clearFilter = () => {
    form.resetFields();
    setDateSubmitFilter({
      submissionDate: null,
      approvalDate: null,
    });
    setLoading(true);
    if (activeTabKey == "1") {
      const data = {
        keyword: "",
        pageNumber: 1,
        pageSize: 10,
        individualId: userId,
        type: false,
        isPagination: true,
      };
      postSurveySearchApprove(data)
        .then((res) => {
          const { data } = res.data;
          setTotalRecordsSubmit(res.data.data.totalRecords);
          setListSurveyApproveSubmit(data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const data = {
        keyword: "",
        pageNumber: 1,
        pageSize: 10,
        individualId: userId,
        type: true,
        isPagination: true,
      };
      postSurveySearchApprove(data)
        .then((res) => {
          const { data } = res.data;
          setTotalRecordsApprove(res.data.data.totalRecords);
          setListSurveyApprove(data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSearchEnter = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleSubmitSearch();
    }
  };

  const handleChangeFilter = (value: any, name: string) => {
    setDateSubmitFilter({ ...dateSubmitFilter, [name]: value });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  const OpenManyApprovalsSurvey = () => {
    setIsOpenManyApprovalsSurvey(true);
  };
  const handleCloseManyApprovalsSurvey = () => {
    setIsOpenManyApprovalsSurvey(false);
    form.resetFields();
    setDateSubmitFilter({
      submissionDate: null,
      approvalDate: null,
    });
    setTimeout(() => {
      setLoading(true);
      const data1 = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        individualId: userId,
        type: false,
        isPagination: true,
        submissionDateTo: null,
        submissionDateFrom: null,
        approvalDateTo: null,
        approvalDateFrom: null,
      };
      postSurveySearchApprove(data1)
        .then((res) => {
          const { data } = res.data;
          if (data1.type == true) {
            setTotalRecordsApprove(res.data.data.totalRecords);
            setListSurveyApprove(data.data);
          } else {
            setTotalRecordsSubmit(res.data.data.totalRecords);
            setListSurveyApproveSubmit(data.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });

      const data2 = {
        pageNumber: pageNumber,
        pageSize: pageSize,
        individualId: userId,
        type: true,
        isPagination: true,
        submissionDateTo: null,
        submissionDateFrom: null,
        approvalDateTo: null,
        approvalDateFrom: null,
      };
      postSurveySearchApprove(data2)
        .then((res) => {
          const { data } = res.data;
          if (data2.type == true) {
            setTotalRecordsApprove(res.data.data.totalRecords);
            setListSurveyApprove(data.data);
          } else {
            setTotalRecordsSubmit(res.data.data.totalRecords);
            setListSurveyApproveSubmit(data.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }, 500);
  };
  return (
    <Layout className="page-header-group survey-managment">
      <Form.Provider>
        <div className="table-header--wrapper">
          <FilterBrowseSurvey
            title={title}
            activeTabKey={activeTabKey}
            filterData={dateSubmitFilter}
            searchValue={searchValue}
            handleChangeInput={handleChangeInput}
            setFilterData={handleChangeFilter}
            clearFilter={clearFilter}
            form={form}
            handleSubmitSearch={handleSubmitSearch}
            multipleApprove={multipleApprove}
            handleOpenManyApprovalsSurvey={OpenManyApprovalsSurvey}
            handleSearchEnter={handleSearchEnter}
          />
        </div>
        <div className="tabs-heading">
          <Tabs
            items={itemsTab}
            activeKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
          />
        </div>
        <ManyApprovalsSurvey
          open={isOpenManyApprovalsSurvey}
          handleCancel={() => {
            handleCloseManyApprovalsSurvey();
          }}
        />
      </Form.Provider>
    </Layout>
  );
};

export default BrowseSurvey;
