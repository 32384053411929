import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getPreSignedUploadUrl: (prefix: string | undefined, filename: string, contentType: string, fileSize: number, duration: number | null) => Promise<AxiosResponse<any>> = (prefix, filename, contentType, fileSize, duration) => {
    let data = {
        "prefix": prefix || 'upload',
        "filename": filename,
        "contentType": contentType || '',
        "fileSize": fileSize || 0,
        "duration": duration,
    };
    return axiosConfigV2.post(`/file-manager/prepare-file-key`, data)
}
