import React, { useEffect, useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Badge, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CourseDetail,
  SectionChildren,
  SectionInfo,
  SectionYoungChildren,
} from "../../../../types/course";
import { isEmpty } from "lodash";
import { useViewport } from "../../../../hooks/useViewport";
import CustomDraw from "../../../drawer/CustomDraw";

interface SidebarProps {
  course?: CourseDetail;
  onCollapsed?: (v: boolean) => void;
}

function Sidebar({ course, onCollapsed = () => {} }: SidebarProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { width } = useViewport();
  const [collapsed, setCollapsed] = useState(false);
  const [activeKey, setActiveKey] = useState<string>("");

  const menu = course?.sections?.map((section: SectionInfo) => ({
    key: `${section.id}`,
    label: (
      <div className="flex align-center">
        <span className="text-14 flex-1 menu-title-discussion">
          {section?.name}
        </span>{" "}
        <Badge showZero count={section?.totalDiscussion} />
      </div>
    ),
    className: "sidebar-row-title",
    children: section?.children?.map((children1: SectionChildren) => ({
      key: `${children1.id}`,
      label: (
        <div className="flex align-center">
          <span className="text-14 flex-1 menu-title-discussion">
            {children1?.name}
          </span>{" "}
          <Badge showZero count={children1?.totalDiscussion} />
        </div>
      ),
      className: "children",
      children: children1?.children?.map((children2: SectionYoungChildren) => ({
        key: `${children2.id}`,
        label: (
          <div className="flex align-center">
            <span className="text-14 flex-1 menu-title-discussion">
              {children2?.name}
            </span>{" "}
            <Badge showZero count={children2?.totalDiscussion} />
          </div>
        ),
        className: "children",
      })),
    })),
  }));

  const handleMenuClick = (menu: any) => {
    navigate(`${location.pathname}?unitId=${menu?.key}`);
    setActiveKey(menu?.key);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    onCollapsed(!collapsed);
  };
  return (
    <div className="statistic-course-sidebar">
      {width < 1650 ? (
        <CustomDraw
          placement="left"
          title="Cấu trúc khoá học"
          content={
            <div className="statistic-course-sidebar">
              <Menu
                className="course-menu"
                style={{
                  width: 330,
                }}
                defaultOpenKeys={course?.sections?.map(
                  (item: SectionInfo) => `${item.id}`
                )}
                mode="inline"
                activeKey={activeKey}
                inlineCollapsed={collapsed}
                items={menu}
                onClick={handleMenuClick}
                expandIcon={({ isOpen }) => {
                  return isOpen ? (
                    <MinusSquareOutlined />
                  ) : (
                    <PlusSquareOutlined />
                  );
                }}
              />
            </div>
          }
        >
          <div className="btn-collapsed pointer center" style={{width: 50, height: 50}}>
            <MenuFoldOutlined />
          </div>
        </CustomDraw>
      ) : (
        !isEmpty(course?.sections) && (
          <div>
            <div
              className={`course-sidebar-header ${
                collapsed ? "btn-collapsed" : ""
              }`}
              style={{ width: collapsed ? 50 : 280 }}
            >
              <div className="pointer" onClick={toggleCollapsed}>
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </div>
              {!collapsed ? (
                <span className="text-16">Cấu trúc khóa học</span>
              ) : (
                ""
              )}
            </div>

            <Menu
              className="course-menu"
              style={{
                width: collapsed ? 0 : 280,
                display: collapsed ? "none" : "block",
              }}
              defaultOpenKeys={course?.sections?.map(
                (item: SectionInfo) => `${item.id}`
              )}
              mode="inline"
              activeKey={activeKey}
              inlineCollapsed={collapsed}
              items={menu}
              onClick={handleMenuClick}
              expandIcon={({ isOpen }) => {
                return isOpen ? (
                  <MinusSquareOutlined />
                ) : (
                  <PlusSquareOutlined />
                );
              }}
            />
          </div>
        )
      )}
    </div>
  );
}

export default Sidebar;
