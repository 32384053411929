import React, { useCallback, useRef, useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import toolbarOptions from "./toolbarOptions";
import "react-quill/dist/quill.snow.css";
import { FORMAT_REACT_QUILL } from "../../../../../../constants";
import { upLoadFileSurvey } from "../../../../../../service/survey-managment/list-survey";
import "./editorHtml.scss";
import { Spin } from "antd";
// import { ImageActions } from '@xeger/quill-image-actions';
// import { ImageFormats } from '@xeger/quill-image-formats';

// Quill.register('modules/imageActions', ImageActions);
// Quill.register('modules/imageFormats', ImageFormats);
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);

interface EditorCustomizeProps {
  valueInitial?: string;
  setUpdateValue?: (data: string) => void;
  handleOnChangeSelection?: (data: string) => void;
  handleOnChange?: (data: string) => void;
  handleOnBlur?: (data: string) => void;
  handleMouseLeave?: (data: string) => void;
  sizeContainer?: number;
}

const EditorHtml: React.FC<EditorCustomizeProps> = ({
  valueInitial,
  setUpdateValue,
  handleOnChangeSelection,
  handleOnChange,
  handleOnBlur,
  sizeContainer,
  handleMouseLeave,
}) => {
  const [listUrlFile, setListUrlFile] = useState<any>([]);
  const reactQuillRef = useRef<ReactQuill>(null);
  const editorRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    // Detect click outside of editor
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editorRef.current &&
        !editorRef.current.contains(event.target as Node)
      ) {
        const quill = reactQuillRef.current?.getEditor();
        if (quill) {
          const content = quill.root.innerHTML; // Get editor's HTML content
          handleOnChangeSelection && handleOnChangeSelection(content);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleOnChangeSelection]);

  const uploadImageToS3 = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".jpg,.jpeg,.png");
    input.click();
    // Listen upload local image and save to state
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      console.log("vanh đây", { file });
      if (file) {
        setIsLoading(true);
        try {
          // Create a FormData object to send the file
          let data = new FormData();
          data.append("file", file);

          // Send the file to the server using fetch or axios
          const response = await upLoadFileSurvey(data);
          const fileData = response.data.data;
          // console.log(`res-uploadFile::`, response.data.data);

          // Check if the request was successful
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const quill = reactQuillRef.current;
            if (quill) {
              const range = quill.getEditorSelection();
              const quillEditor = quill.getEditor();
              const url = `${fileData.filePath}`;
              // range && quill.getEditor().insertEmbed(range.index, "image", url);

              const p = document.createElement("p");
              p.classList.add("list-media");
              const img = document.createElement("img");
              img.src = url;

              p.appendChild(img);

              // Insert the P element at the current selection
              range &&
                quillEditor.clipboard.dangerouslyPasteHTML(
                  range.index,
                  p.outerHTML
                );

              console.log({ url });
              console.log({ range });
            }

            console.log("File uploaded successfully");
          } else {
            // Handle errors if the request was not successful
            console.error("File upload failed");
          }
          // reader.onload = async () => {

          // };
        } catch (error) {
          // Handle any errors that occurred during the file upload process
          console.error("An error occurred during file upload:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.warn("No file selected.");
      }
    };
  }, []);

  const uploadVideoToS3 = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".mp4,.mov,.wmv,.flv,.avi,.mkv,.webm");
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        setIsLoading(true);
        try {
          // Đổi tên file thành đuôi .mp4
          // const newFile = new File(
          //   [file],
          //   file.name.replace(/\.[^/.]+$/, "") + ".mp4",
          //   { type: "video/mp4" }
          // );

          // Tạo đối tượng FormData để gửi file
          let data = new FormData();
          data.append("file", file);

          // Gửi file lên server bằng fetch hoặc axios
          const response = await upLoadFileSurvey(data);
          const fileData = response.data.data;

          // Kiểm tra nếu request thành công
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const quill = reactQuillRef.current;
            if (quill) {
              const range = quill.getEditorSelection();
              const url = `${fileData.filePath}`;
              range && quill.getEditor().insertEmbed(range.index, "video", url);
              console.log({ url });
            }

            console.log("Video uploaded and embedded successfully");
          } else {
            console.error("Video upload failed");
          }
        } catch (error) {
          console.error("An error occurred during video upload:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.warn("No video selected.");
      }
    };
  }, []);

  // const uploadVideoToS3 = useCallback(() => {
  //   const input = document.createElement("input");
  //   input.setAttribute("type", "file");
  //   input.setAttribute("accept", "video/*"); // Accept only video files
  //   input.click();

  //   // Listen for when a video is selected
  //   input.onchange = async () => {
  //     const file = input.files ? input.files[0] : null;
  //     if (file) {
  //       try {
  //         // Create FormData to send the video file
  //         let data = new FormData();
  //         data.append("file", file);

  //         // Send the video file to the server using fetch or axios
  //         const response = await upLoadFileSurvey(data);
  //         const fileData = response.data.data;
  //         // console.log(`res-uploadFile::`, response.data.data);

  //         // Check if the request was successful
  //         if (
  //           response &&
  //           (response.status === 200 || response.status === 201)
  //         ) {
  //           // Update document properties with fileData
  //           const quill = reactQuillRef.current;
  //           if (quill) {
  //             const range = quill.getEditorSelection();
  //             const quillEditor = quill.getEditor();
  //             const url = `${fileData.filePath}`;
  //             // range && quill.getEditor().insertEmbed(range.index, "video", url);
  //             const p = document.createElement('p');
  //             p.classList.add('list-media');
  //             const video = document.createElement('video');
  //             video.src = url;
  //             video.controls = true; // Add video controls (play, pause, etc.)

  //             p.appendChild(video);

  //             range && quillEditor.clipboard.dangerouslyPasteHTML(range.index, p.outerHTML);
  //           }

  //           console.log("Video uploaded and embedded successfully");
  //         } else {
  //           // Handle errors if the request was not successful
  //           console.error("Video upload failed");
  //         }
  //       } catch (error) {
  //         // Handle any errors that occurred during the video upload process
  //         console.error("An error occurred during video upload:", error);
  //       }
  //     } else {
  //       console.warn("No video selected.");
  //     }
  //   };
  // }, []);

  const handleLink = useCallback(() => {
    const message = "Điền link vào bên dưới (Ví dụ: https://example.com)";
    const url = prompt(message, "https://");

    if (url !== null) {
      const quill = reactQuillRef.current;
      if (quill) {
        const range = quill.getEditorSelection();
        if (range) {
          const linkText = prompt("Enter the link text", "Link text");
          if (linkText !== null) {
            quill.getEditor().insertText(range.index, linkText, "link", url); // Insert link with text
          }
        } else {
          console.warn("No selection range found.");
        }
      }
    }
  }, []);

  const modules = {
    imageActions: {},
    imageFormats: {},
    toolbar: {
      container: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ color: [] }, { background: [] }],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
      ],

      handlers: {
        link: handleLink,
        image: uploadImageToS3,
        video: uploadVideoToS3,
      },
    },
  };

  const formats = [
    "align",
    "background",
    "blockquote",
    "bold",
    "code-block",
    "color",
    "float",
    "font",
    "header",
    "height",
    "image",
    "italic",
    "link",
    "script",
    "strike",
    "size",
    "underline",
    "width",
    "video",
  ];
  useEffect(() => {
    // Detect click on button with id='luu-ban-nhap'
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;

      if (
        targetElement.tagName === "BUTTON" && // Check if the clicked element is a button
        targetElement.id === "luu-ban-nhap" // Check if the button has the specific id
      ) {
        const quill = reactQuillRef.current?.getEditor();
        if (quill) {
          const content = quill.root.innerHTML; // Get editor's HTML content
          handleOnChangeSelection && handleOnChangeSelection(content);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleOnChangeSelection]);
  return (
    <div ref={editorRef} className="survey-managment-quill">
      {isLoading && (
        <div className="loading-spinner">
          <Spin tip="Đang tải lên..." />
        </div>
      )}
      <ReactQuill
        formats={FORMAT_REACT_QUILL}
        modules={modules}
        theme="snow"
        onChangeSelection={(range, source, editor) => {
          const content = editor.getHTML();
          handleOnChangeSelection && handleOnChangeSelection(content);
        }}
        onChange={handleOnChange}
        onBlur={(range, source, editor) => {
          const content = editor.getHTML();
          if (source === "user") {
            handleOnBlur && handleOnBlur(content);
          }
        }}
        value={valueInitial ? valueInitial : undefined}
        placeholder={!valueInitial ? "Thêm mô tả tại đây ..." : undefined}
        style={
          sizeContainer
            ? {
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "flex-end",
              }
            : {
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "flex-end",
              }
        }
        preserveWhitespace={true}
        ref={reactQuillRef}
      />
    </div>
  );
};

export default EditorHtml;
