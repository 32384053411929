import { Button } from "antd";
import moment from "moment";
import React from "react";
import { convertMinutesToTimeString } from "../../../../utils/format";

interface StartScreenProps {
  timeTraining?: number;
  timeDeadline?: number | string;
  onStart?: (v?: any) => void;
}
function StartScreen({ timeTraining = 1000, onStart }: StartScreenProps) {
  return (
    <div className="start-screen">
      <div className="center flex-column content-center">
        <p className="font-size-16">
          Thời gian làm bài cho bài tập này:{" "}
          <span className="text-primary-violet">
            <b>{convertMinutesToTimeString(timeTraining)}</b>
          </span>
        </p>
        <p className="font-size-16">
          Bạn cần hoàn thành bài tập trước:{" "}
          <span className="text-primary-violet">
            <b>00:00:00 - 15/05/2024</b>
          </span>
        </p>
      </div>
      <Button className="btn-primary background-violet border-violet h-40 mt-2 btn-start" onClick={onStart}>
        Bắt đầu làm bài
      </Button>
    </div>
  );
}

export default StartScreen;
