// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment .table-wrapper {
  width: 100%; }
  .survey-managment .table-wrapper .table-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px; }
    .survey-managment .table-wrapper .table-status .color-icon {
      width: 6px;
      height: 6px;
      border-radius: 999px; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/detail-confirm/DetailConfirm.scss"],"names":[],"mappings":"AAAA;EAEQ,WAAW,EAAA;EAFnB;IAKY,aAAoB;IACpB,mBAAmB;IACnB,mBAAsB;IACtB,QAAmB,EAAA;IAR/B;MAWgB,UAAkB;MAClB,WAAkB;MAClB,oBAAoB,EAAA","sourcesContent":[".survey-managment {\n    .table-wrapper {\n        width: 100%;\n\n        .table-status {\n            display       : flex;\n            flex-direction: row;\n            align-items   : center;\n            gap           : 8px;\n\n            .color-icon {\n                width        : 6px;\n                height       : 6px;\n                border-radius: 999px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
