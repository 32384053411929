import "./detail.scss";
import { Button, Input, Radio, Row, Space, ConfigProvider, Col, Card, Select, Checkbox, Divider } from "antd";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useEffect, useState } from "react";
const { Option } = Select;


const QuestionType7 = (props: any) => {
  const [answerValue, setAnswerValue] = useState<Object[]>([]);
  const listSelectOption = props.listOptions.filter((item: any) => (item.answerText === "" || item.answerText === null) && (!item.isOther));
  const listLinkOption = props.listOptions.filter((item: any) => item.answerText !== null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [inputValue, setInputValue] = useState('');


  const linkOption = listLinkOption.reduce((obj: any, item: any, index: number) => {
    const key = index;
    obj[key] = item.answerId;
    return obj;
  }, {});

  const optionsObject = listSelectOption.reduce((obj: any, item: any, index: number) => {
    const key = String.fromCharCode(65 + index);
    obj[key] = item.answerId;
    return obj;
  }, {});

  const handleAnswerValue = (value: string, index: number) => {
    var newAnswerValue = [...answerValue]
    var exitKey = false
    newAnswerValue.map((item: any) => {
      if (item.key === linkOption[index]) {
        item.value = optionsObject[value]
        exitKey = true
      }
    })
    if (!exitKey) {
      newAnswerValue.push({
        key: linkOption[index],
        value: optionsObject[value]
      })
    }
    setAnswerValue(newAnswerValue)
  }

  const handleSelectChange = (value: string, index: number) => {
    setIsOtherSelected(false)
    const newSelected = [...selectedOptions];
    newSelected[index] = value;
    setSelectedOptions(newSelected);
    handleAnswerValue(value, index)
  };

  const handleOtherChange = (e: any) => {
    setSelectedOptions([])
    setAnswerValue([]);
    setIsOtherSelected(e.target.checked);
  };

  const handleChangeInput = (e: any) => {
    setInputValue(e.target.value);
  };

  const availableOptions = Object.keys(optionsObject).filter(key => !selectedOptions.includes(key));

  var leftOption = 1;
  var rightOption = 0;

  useEffect(() => {
    const initialSelected = props.listOptions.filter((item: any) => (item.answerText === "" || item.answerText === null) && (!item.isOther))
      .map((item: any, index: any) => {
        if (props.questionAnswer) {
          const foundAnswer = props.questionAnswer.find((ans: any) => ans.key === item.answerId);
          if (foundAnswer) {
            const optionKey = Object.keys(optionsObject).find(key => optionsObject[key] === foundAnswer.value);
            return optionKey || "";
          }
          return "";
        }
      });
    setSelectedOptions(initialSelected);
  }, [props.questionAnswer]);

  return (
    <Row gutter={3} style={{ width: "100%" }}>
      <Col span={8}>
        {props.listOptions.filter((item: any) => (item.answerText !== null) && (!item.isOther)).map((item: any) => (
          <Row key={item.answerId} gutter={3} style={{ marginBottom: '5px' }}>
            <Col>
              <Input
                value={item.answerText}
                readOnly
                styles={{
                  suffix: {
                    display: 'inline-block',
                    border: "1px solid #D9D9D9",
                    borderRadius: "4px",
                    padding: "0px 6px",
                    height: "100%",
                    margin: "auto 0 auto 5px",
                  },
                  input: {
                    border: "1px solid #D9D9D9",
                    borderRadius: "6px",
                    padding: "5px 12px",
                  },
                  affixWrapper: {
                    border: 'none',
                    outline: 'none',
                    backgroundColor: 'none',
                    borderRadius: 'none'
                  }
                }}
                suffix={<span>{leftOption++}</span>}
              ></Input>
            </Col>
          </Row>
        ))}
      </Col>
      <Col span={7}>
        {props.listOptions.filter((item: any) => (item.answerText !== null) && (!item.isOther)).map((item: any, index: number) => (
          <Row key={item.answerId} gutter={3} style={{ marginBottom: '15px' }}>
            <Col>
              <Select
                showSearch
                placeholder="Câu trả lời"
                optionFilterProp="children"
                onChange={(value) => handleSelectChange(value, index)}
                filterOption={(input, option) =>
                  ((option?.children as unknown) as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={selectedOptions[index]}
                disabled={props.typeSurvey === "2"}
                allowClear
              >
                {availableOptions.map((optionKey) => (
                  <Option key={optionKey} value={optionKey} >{optionKey}</Option>
                ))}
              </Select>
            </Col>
          </Row>
        ))}
      </Col>
      <Col span={9}>
        {props.listOptions.filter((item: any) => (item.answerText === "" || item.answerText === null) && (!item.isOther)).map((item: any) => (
          <Row key={item.answerId} gutter={3} style={{ marginBottom: '5px' }}>
            <Col>
              <Input
                value={item.linkedAnswerText}
                readOnly
                styles={{
                  prefix: {
                    display: 'inline-block',
                    border: "1px solid #D9D9D9",
                    borderRadius: "4px",
                    padding: "0px 6px",
                    height: "100%", // Set the height of the prefix to match the input height
                    margin: "auto 5px auto 0", // Add margin to separate prefix from input
                  },
                  input: {
                    border: "1px solid #D9D9D9",
                    borderRadius: "6px",
                    padding: "5px 12px",
                  },
                  affixWrapper: {
                    border: 'none',
                    outline: 'none',
                    backgroundColor: 'none',
                    borderRadius: 'none'
                  }
                }}
                prefix={<span>{String.fromCharCode(65 + rightOption++)}</span>}
              ></Input>
            </Col>
          </Row>
        ))}
      </Col>
      {props.listOptions.map((option: any, index: any) => (
        option.isOther ? (
          <Col span={24} style={{ marginTop: '12px' }} key={index}>
            <Checkbox
              checked={isOtherSelected}
              onChange={handleOtherChange}
              disabled={props.typeSurvey === "2"}
            >
              Khác
            </Checkbox>
            {isOtherSelected && (
              <Input
                placeholder="Vui lòng nhập..."
                value={inputValue}
                onChange={handleChangeInput}
                style={{ marginTop: '12px', marginBottom: '12px' }}
                disabled={props.typeSurvey === "2"}
              />
            )}
            {!isOtherSelected && <Divider style={{ margin: '12px 0' }} />}
          </Col>
        ) : null
      ))}
    </Row>
  );
};

export default QuestionType7;
