import { DownOutlined, UpOutlined } from "@ant-design/icons";
import React, { useState, useEffect, useRef, ReactNode } from "react";

interface ExpandableTextProps {
  text: ReactNode;
  maxLines: number;
  isViewMore?: boolean;
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  maxLines,
  isViewMore = true,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [canExpand, setCanExpand] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const maxHeight = 150;

  useEffect(() => {
    if (textRef.current) {
      const computedStyle = getComputedStyle(textRef.current);
      const lineHeight = parseFloat(computedStyle.lineHeight || "1.2");
      const calculatedHeight = lineHeight * maxLines;

      if (calculatedHeight > maxHeight) {
        if (textRef.current.scrollHeight > maxHeight) {
          setCanExpand(true);
        }
      } else {
        if (textRef.current.scrollHeight > calculatedHeight) {
          setCanExpand(true);
        }
      }
    }
  }, [text, maxLines]);

  const toggleExpand = () => setExpanded(!expanded);

  const calculatedMaxHeight = textRef.current
    ? `${Math.min(
        maxHeight,
        maxLines *
          parseFloat(getComputedStyle(textRef.current).lineHeight || "1.2")
      )}px`
    : `${maxHeight}px`;

  return (
    <>
      <div>
        <div
          ref={textRef}
          style={{
            maxHeight: expanded ? "none" : calculatedMaxHeight,
            overflow: "hidden",
            whiteSpace: "pre-wrap",
            transition: "max-height 0.3s ease", // thêm hiệu ứng chuyển đổi
          }}
          className="text-secondary"
        >
          <div dangerouslySetInnerHTML={{ __html: text as string }}></div>
        </div>
      </div>
      <>
        {canExpand && (
          <div
            onClick={isViewMore ? toggleExpand : undefined}
            className="text-primary font-weight-5 flex gap-8 mt-1"
            style={{ cursor: "pointer" }}
          >
            {expanded ? (
              <>
                Thu gọn
                <UpOutlined />
              </>
            ) : (
              <>
                Xem chi tiết
                <DownOutlined />
              </>
            )}
          </div>
        )}
      </>
    </>
  );
};

export default ExpandableText;
