import { Badge, Layout, message, Tabs, TabsProps, Typography } from "antd";
import PageHeader from "../../../../../components/page-header/PageHeader";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getCourseApprovalInfomationById,
  getListMoocCourseDescriptionDocumentById,
  getListMoocCourseStructureById,
  getListMoocCourseStructureChapterById,
} from "../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { DetailContent } from "../../../../survey-managment/mooc-survey/components/detail-content/DetailContent";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import { StatusEum } from "../../../mooc-course-construction-plan/list-course-plan/constants/data";
import DetailConfirm from "../../../mooc-approval-plan/components/detail-confirm/DetailConfirm";
import {
  TypeChapterList,
  TypeCourseDescriptionDocument,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import PreviewCourseStructure from "../../../mooc-course-construction-plan/detail-course-plan/component/preview-course-structure/PreviewCourseStructure";
import PreviewDescriptionDocument from "../../../mooc-course-construction-plan/detail-course-plan/component/preview-description-document/PreviewDescriptionDocument";
import { useLocation } from "react-router-dom";
type Props = {
  title: string;
};

const CourseConstructionPlanDetail: FunctionComponent<Props> = ({ title }) => {
  const { Content } = Layout;
  const imgPath = "/images/";
  const params = useParams();
  const location = useLocation();
  const [detailData, setDetailData] = useState<any>({});
  const [listInfo, setListInfo] = useState<any[]>();
  const [activeTabKey, setActiveTabKey] = useState<string>("1");
  const [dataListCourseStructure, setDataListCourseStructure] = useState<
    TypeChapterList[]
  >([]);
  const [listChapter, setListChapter] = useState<TypeCourseStructure[]>([]);
  const [dataDescriptionDocument, setDataDescriptionDocument] = useState<
    TypeCourseDescriptionDocument[]
  >([]);

  const handleReturnCourseType = (courseType: number) => {
    return (
      <div className="table-course-type">
        {courseType === 2 ? (
          <div className="table-course-type-2">Hợp tác xây dựng</div>
        ) : courseType === 1 ? (
          <div className="table-course-type-1">Tự triển khai</div>
        ) : (
          <div className="table-course-type-3">Dùng chung</div>
        )}
      </div>
    );
  };

  const handleReturnStatus = (status: number) => {
    let courseStatusString: string;

    switch (status) {
      case StatusEum.SAVE_DRAFT:
        courseStatusString = "Lưu bản nháp";
        break;
      case StatusEum.WAIT_APPROVE:
        courseStatusString = "Chờ duyệt";
        break;
      case StatusEum.APPROVED:
        courseStatusString = "Đã duyệt";
        break;
      case StatusEum.REFUSE:
        courseStatusString = "Từ chối";
        break;
      case StatusEum.PUBLISHED:
        courseStatusString = "Đã xuất bản";
        break;
      case StatusEum.END:
        courseStatusString = "Kết thúc";
        break;
      default:
        courseStatusString = "";
        break;
    }
    return (
      <div className="table-status">
        <div
          className="color-icon"
          style={{
            backgroundColor:
              status === 1
                ? "#D9D9D9"
                : status === 2
                  ? "#FAAD14"
                  : status === 3
                    ? "#1677FF"
                    : status === 4
                      ? "#FF0000"
                      : status === 5
                        ? "#1677FF"
                        : status === 6
                          ? "#52C41A"
                          : "white",
          }}
        ></div>
        {courseStatusString}
      </div>
    );
  };

  const renderSwitch = (value: string) => {
    switch (value) {
      case "registrationName":
        return "Tên kế hoạch";
      case "registrationCode":
        return "Mã kế hoạch";
      case "constructionPlanStatus":
        return "Trạng thái";
      case "industryName":
        return "Khoa";
      case "industryGroupName":
        return "Chuyên ngành";
      case "hostUniversityName":
        return "Trường chủ trì";
      case "cooperativeUniversityName":
        return "Trường hợp tác";
      case "courseType":
        return "Loại khóa học";
      default:
        return "";
    }
  };

  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 1)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level2 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 2)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level3 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 3)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const chapterList: TypeChapterList[] = level1.map((l1, index) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        orderNumber: l1.orderNumber,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2
        .filter((l2) => l2.parentId === l1.id)
        .sort((a, b) =>
          a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
        );

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject, index) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          orderNumber: subject.orderNumber,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3
          .filter((l3) => l3.parentId === subject.id)
          .sort((a, b) =>
            a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
          );

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson, index) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          orderNumber: lesson.orderNumber,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });
    return chapterList;
  };

  const getDetailMoocSurveyData = async (id: any) => {
    try {
      await getCourseApprovalInfomationById(id)
        .then((res) => {
          const data = res.data.data;
          setDetailData(data);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListMoocCourseStructureByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureById(id)
        .then((res) => {
          const data = res.data.data;
          setDataListCourseStructure(changeToChapterList(data));
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListMoocCourseStructureChapterByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureChapterById(id)
        .then((res) => {
          const data = res.data.data;
          setListChapter(data);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListMoocCourseDescriptionDocumentByIData = async (id: any) => {
    {
      try {
        await getListMoocCourseDescriptionDocumentById(id)
          .then((res) => {
            const data = res.data.data;
            setDataDescriptionDocument(data);
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });
      } catch (error) {
        console.log(error);
        message.error("Có lỗi chưa xác định");
      }
    }
  };

  useEffect(() => {
    getDetailMoocSurveyData(params.id);
    getListMoocCourseStructureByIdData(params.id);
    getListMoocCourseStructureChapterByIdData(params.id);
    getListMoocCourseDescriptionDocumentByIData(params.id);
  }, []);

  useEffect(() => {
    delete detailData.id;
    const convertObjectData = Object.entries(detailData);
    const listInfo = convertObjectData.map((item: any, index) => {
      if (item[0] == "constructionPlanStatus") {
        if(location.state.keyTab == '2'){
          item[1] = handleReturnStatus(3);
        }
        else{
          item[1] = handleReturnStatus(item[1]);
        }
      } else if (item[0] == "courseType") {
        item[1] = handleReturnCourseType(item[1]);
      }
      return (
        <div className="list-item" key={index}>
          <Typography.Title level={5} className="list-item--title">
            {renderSwitch(item[0])}
          </Typography.Title>
          <div className="list-item--description">
            <Typography.Text className="content">
              {item[0] === "status" ? (
                <Badge
                  key={!detailData.isAssigned ? "lightgray" : "green"}
                  color={!detailData.isAssigned ? "lightgray" : "green"}
                />
              ) : null}{" "}
              {item[1]}
            </Typography.Text>
          </div>
        </div>
      );
    });
    setListInfo(listInfo);
  }, [detailData]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: (
        <DetailContent>
          <CustomCard
            title={
              <div className="card-heading--info">
                <Typography.Title level={5}>
                  Thông tin kế hoạch khoá học
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Thông tin chung về kế hoạch xây dựng khoá học
                </Typography.Text>
              </div>
            }
            className="card-info--wrapper"
          >
            <div className="list-wrapper">{listInfo}</div>
          </CustomCard>
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: "Cấu trúc khoá học",
      children: (
        <DetailContent>
          <PreviewCourseStructure
            dataCourseStructure={dataListCourseStructure}
          />
        </DetailContent>
      ),
    },
    {
      key: "3",
      label: "Tài liệu mô tả",
      children: (
        <DetailContent>
          <PreviewDescriptionDocument
            listChapter={listChapter}
            dataDescriptionDocument={dataDescriptionDocument}
          />
        </DetailContent>
      ),
    },
    {
      key: "4",
      label: "Kết quả duyệt",
      children: <DetailConfirm />,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: title,
    },
    {
      title: `${detailData.registrationName ?? ""}`,
    },
  ];
  return (
    <Content>
      <PageHeader
        listBreadcrumb={listBreadcrumb}
        breadcrumb={true}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        title={`Xem chi tiết ${detailData.registrationName ?? ""}`}
      />
      <div className="tabs-heading">
        {location.state.tab == 4 ? (
          <Tabs defaultActiveKey="4" items={items} />
        ) : (
          <Tabs defaultActiveKey="1" items={items} />
        )}
      </div>
    </Content>
  );
};
export default CourseConstructionPlanDetail;
