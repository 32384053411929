import { Button, Card, Checkbox, Col, DatePicker, Form, GetProp, Input, Modal, Row, Space, TableColumnsType, TableProps, TimePicker, Typography, Upload, message } from 'antd'
import React, { ChangeEvent, useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import { CloseOutlined, DownloadOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import { getListSequenceById, getSyncTemplate, syncSequenceCourse } from '../../../service/content-list-course/contentListCourseApi'
import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'

type Props = {
  open: boolean
  handleClose: () => void
  tab: string;
  searchValue: string;
  filterData: any;
}

export default function SyncContentLesson(props: Props) {
  const { open, handleClose, tab, filterData, searchValue } = props

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [searchValueSynPopup, setSearchValueSynPopup] = useState<string>('')
  const [listPlan, setListPlan] = useState<any>([])
  const [listCheckboxGroup, setListCheckboxGroup] = useState<any>([1])
  const [errFile, setErrFile] = useState(false)
  const params = useParams()
  const [form] = Form.useForm()
  const [filterDataDate, setFilterDataDate] = useState<any>({
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
  });

  const renderStatus = (value: string) => {
    switch (Number(value)) {
      case 0:
        return "Bản nháp"
        break;
      case 1:
        return "Riêng tư"
        break;
      case 2:
        return "Công khai"
        break;
      default:
        return "Bản nháp"
        break;
    }
  }

  const CheckboxGroup = Checkbox.Group;
  const dataColumnsLesson: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 20,
      render: (_: any, record, index) => {
        return <div>{record?.numericalOrder ?? 1}</div>;
      },
    },
    {
      title: "Tên bài giảng",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
    },
    {
      title: "Tên chương",
      dataIndex: "sectionName",
      key: "sectionName",
      sorter: true,
      width: 20,
    },
    {
      title: "Trạng thái",
      dataIndex: "availableStatus",
      key: "availableStatus",
      sorter: true,
      width: 30,
      render: (_: any, record) => {
        return <div>{renderStatus(record?.availableStatus)}</div>;
      },
    }
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => { };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValueSynPopup(value);
  };

  const getDataSequenceByid = async () => {
    const data = {
      courseId: Number(params?.id) ?? 0,
      type: 2,
      keyword: searchValueSynPopup,
      sectionId: tab === "1" ? null : Number(filterData.sectionId),
      sequenceId: tab === "1" || tab === '2' ? null : Number(filterData.sequenceId),
      startedDateFrom: isEmpty(filterData.planCreationDate) ? filterData.planCreationDate?.[0] : null,
      startedDateTo: isEmpty(filterData.planCreationDate) ? filterData.planCreationDate?.[0] : null,
      publishedDateFrom: isEmpty(filterData.planApprovalDate) ? filterData.planApprovalDate?.[1] : null,
      publishedDateTo: isEmpty(filterData.planApprovalDate) ? filterData.planApprovalDate?.[1] : null,
      availableStatus: 1,
      sortByName: statusOrder,
      page: pageNumber,
      size: pageSize
    }
    await getListSequenceById(data).then((res: any) => {
      setListPlan(res.data?.body?.content)
      setTotalRecords(res.data?.body?.totalElements)
    })

  }

  const getListData = async () => {
    getDataSequenceByid()
  };

  useEffect(() => {
    getListData();
    // if (!isEmpty(searchValue)) {
    //   setSearchValueSynPopup(searchValue)
    // }
  }, [open, pageNumber, statusOrder]);

  const downloadTemplate = async () => {
    await getSyncTemplate().then((res) => {
      const fileData = res?.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = `template.xlsx`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
  }

  const handleChangeFilter = (value: any, name: string) => {
    setFilterDataDate({ ...filterDataDate, [name]: value });
  };

  const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues: any) => {
    if (isEmpty(checkedValues)) {
      return
    }
    setListCheckboxGroup(checkedValues)
  };

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === 'sync-content-course-modal') {
          if (isEmpty(selectedRowKeys)) {
            message.error("Bạn chưa chọn bài giảng đồng bộ")
            return
          }

          const data: any = selectedRowKeys.map((item: any) => {
            const scheduleStartDate = `${filterDataDate.startDate}T${filterDataDate.startTime}:00.000Z`
            const scheduleEndDate = `${filterDataDate.endDate}T${filterDataDate.endTime}:00.000Z`
            return {
              sequenceId: item.id,
              scheduleStartDate: scheduleStartDate,
              scheduleEndDate: scheduleEndDate
            }
          })

          const formData = new FormData();

          if (isEmpty(values.templateFile?.fileList[0].originFileObj) && listCheckboxGroup.includes(2)) {
            setErrFile(true)
          } else {
            setErrFile(false)
            formData.append("file", values.templateFile?.fileList[0]?.originFileObj ?? null);
            formData.append("request", JSON.stringify(data));

            await syncSequenceCourse(formData).then((res) => {
              if (res.status) {
                message.success("Đồng bộ thành công")
                handleClose()
              }
            })
          }

        }
      }}
    >
      <Modal
        className="sync-content-course-modal"
        title={"Đồng bộ nội dung bài giảng"}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"80%"}
      >
        <Form form={form} name="sync-content-course-modal">
          <Row gutter={40} className='p-6'>
            <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
              <Form.Item name="search">
                <FormInput
                  placeholder="Nhập từ khoá cần tìm"
                  value={searchValueSynPopup}
                  onChange={handleChangeInput}
                  afterPrefixIcon={<SearchOutlined onClick={() => getListData()} className='cursor-pointer' />}
                />
              </Form.Item>

              <TableData
                dataTable={listPlan}
                dataColumns={dataColumnsLesson}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                pageSize={pageSize}
                loadingTable={loading}
                scroll={{ x: 1500, y: 600 }}
                totalRecords={totalRecords}
                handleTableChangeProps={handleTableChange}
                setSelectedRowKeysProps={setSelectedRowKeys}
                pagination={true}
                hideSelection={true}
              />
            </Col>
            <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
              <CheckboxGroup value={listCheckboxGroup} className='flex flex-col' onChange={onChange}>
                <div>
                  <Checkbox value={1}>
                    Lịch trình học
                  </Checkbox>

                  <div className="time-picker flex mt-2">
                    <div className="time-picker-content">
                      <Form.Item name="startDate" rules={[{ required: listCheckboxGroup.includes(1) ? true : false, message: 'Thông tin ngày bắt đầu không được bỏ trống' }]}>
                        <DatePicker
                          onChange={(date, dateString: any) => handleChangeFilter(dateString, 'startDate')}
                          // locale={viLocale}
                          format={"YYYY-MM-DD"}
                          value={filterDataDate.startDate}
                          placeholder='Ngày bắt đầu'
                        />
                      </Form.Item>
                    </div>
                    <div className="time-picker-content ml-3">
                      <Form.Item name="startTime" rules={[{ required: listCheckboxGroup.includes(1) ? true : false, message: 'Thông tin giờ bắt đầu không được bỏ trống' }]}>
                        <TimePicker
                          placeholder='Giờ bắt đầu'
                          // locale={viLocale}
                          value={filterDataDate.startTime}
                          onChange={(date, dateString: any) => handleChangeFilter(dateString, 'startTime')}
                          format={"HH:mm"}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className="time-picker flex mt-1">
                    <div className="time-picker-content">
                      <Form.Item name="endDate" rules={[{ required: listCheckboxGroup.includes(1) ? true : false, message: 'Thông tin ngày kết thúc không được bỏ trống' }]}>
                        <DatePicker
                          onChange={(date, dateString: any) => handleChangeFilter(dateString, 'endDate')}
                          // locale={viLocale}
                          format={"YYYY-MM-DD"}
                          value={filterDataDate.endDate}
                          placeholder='Ngày kết thúc'
                        />
                      </Form.Item>
                    </div>
                    <div className="time-picker-content ml-3">
                      <Form.Item name="endTime" rules={[{ required: listCheckboxGroup.includes(1) ? true : false, message: 'Thông tin giờ kết thúc không được bỏ trống' }]}>
                        <TimePicker
                          placeholder='Giờ kết thúc'
                          // locale={viLocale}
                          value={filterDataDate.endTime}
                          onChange={(date, dateString: any) => handleChangeFilter(dateString, 'endTime')}
                          format={"HH:mm"}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className='mt-5'>
                  <Checkbox value={2}>
                    Danh sách học viên
                  </Checkbox>

                  <div className='flex mt-2'>
                    <div>
                      <Form.Item name="templateFile">
                        <Upload {...props} beforeUpload={
                          () => { return false }
                        } maxCount={1}>
                          <Button icon={<UploadOutlined />}>Tải lên danh sách học viên</Button>
                        </Upload>
                      </Form.Item>

                      {errFile ? <p className='span-red'>Vui lòng tải lên dánh sách học viên</p> : null}
                    </div>

                    <Button className='ml-3' icon={<DownloadOutlined />} onClick={downloadTemplate}>Tải xuống file mẫu</Button>
                  </div>
                </div>
              </CheckboxGroup>

              <div className='mt-9 w-full'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Đồng bộ
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider>
  )
}
