import React, { useEffect, useState } from "react";
import { Button, Flex, Spin } from "antd";
import { RefreshIcon } from "../../../../../../components/icons";
import moment from "moment";
import { formatSecondTimeToString } from "../../../../../../utils/format";
import { ExamConfigs } from "../../../../../../types/course";
import { toNumber } from "lodash";
import { TimeExpiredOption } from "../../../../../../constants/course";
import { RedoOutlined } from "@ant-design/icons";
import Countdown from "../../../../../../components/countdown/CountDown";

interface FinishScreenProps {
  timeTraining?: number;
  timeDeadline?: number | string;
  isAbleToRetry?: boolean;
  isShowResult?: boolean;
  answerSendTime?: string;
  loading?: boolean;
  type?: "userFinish" | "timeFinish" | "";
  config?: ExamConfigs;
  isFinalFinish?: boolean;
  remainingRetryNumber?: number;
  waitingTimeForNextExecution?: number | null;
  onRetrain?: (v?: any) => void;
  onViewResult?: () => void;
  onContinue?: (v?: any) => void;
}
function FinishScreen({
  timeTraining = 0,
  type = "timeFinish",
  answerSendTime = "",
  isAbleToRetry = true,
  isShowResult = true,
  loading = false,
  config,
  isFinalFinish = false,
  remainingRetryNumber = 0,
  waitingTimeForNextExecution = 0,
  onRetrain = () => {},
  onViewResult = () => {},
  onContinue = () => {},
}: FinishScreenProps) {
  const [isDisableRetry, setIsDisableRetry] = useState<boolean>(false);
  const executionTimeExpired = config?.actionConfig?.executionTimeExpired;
  const executionTimeExpiredOption = toNumber(
    config?.actionConfig?.executionTimeExpiredOption
  );
  const executionTimeExpiredValue =
    config?.actionConfig?.executionTimeExpiredValue || 0;

  useEffect(() => {
    setIsDisableRetry(!isAbleToRetry)
  },[isAbleToRetry])

  const renderRetryBtn = () => (
    <Button
      icon={<RefreshIcon />}
      className="h-40 mt-2 center"
      onClick={onRetrain}
      disabled={isDisableRetry}
    >
      Làm lại
    </Button>
  );

  const renderFinalResult = () => {
    return (
      <>
        <p className="font-size-16 font-weight-5">
          Tổng thời gian làm bài:{" "}
          <span className="text-primary">
            {formatSecondTimeToString(timeTraining)}
          </span>
        </p>
        <p className="font-size-16 mt-1 font-weight-5">
          Thời gian nộp bài:{" "}
          {answerSendTime ? (
            <span className="text-primary">
              {moment(answerSendTime).format("HH:mm:ss - DD/MM/YYYY")}
            </span>
          ) : (
            <span className="text-primary">Không có dữ liệu</span>
          )}
        </p>
        {remainingRetryNumber < 1000000 && (
          <Flex className="retry-info" gap={16}>
            <p className="font-size-14 font-weight-6">
              Số lần làm lại cho phép: {remainingRetryNumber}
            </p>
            <Flex className="font-size-14 text-primary" align="center" gap={8}>
              Làm lại sau:{" "}
              <Countdown
                onFinish={() => setIsDisableRetry(false)}
                initialTime={toNumber(waitingTimeForNextExecution)}
              />
              <RedoOutlined className="redo-icon" />
            </Flex>
          </Flex>
        )}
      </>
    );
  };

  const renderExecutionTimeExpiredOption = () => {
    // hết thời gian chính + nới
    if (isFinalFinish) {
      return renderFinalResult();
    }

    if (executionTimeExpired && type === "timeFinish") {
      switch (executionTimeExpiredOption) {
        case TimeExpiredOption.AUTO:
          return (
            <>
              <div className="message-display">
                <Flex align="center" gap={8}>
                  <div className="dot green"></div>
                  <span>Bài làm của bạn đã được tự động gửi đến hệ thống</span>
                </Flex>
              </div>
              <p className="font-size-16 font-weight-5">
                Tổng thời gian làm bài:{" "}
                <span className="text-primary">
                  {formatSecondTimeToString(timeTraining)}
                </span>
              </p>
              <p className="font-size-16 mt-1 font-weight-5">
                Thời gian nộp bài:{" "}
                {answerSendTime ? (
                  <span className="text-primary">
                    {moment(answerSendTime).format("HH:mm:ss - DD/MM/YYYY")}
                  </span>
                ) : (
                  <span className="text-primary">Không có dữ liệu</span>
                )}
              </p>
            </>
          );
        case TimeExpiredOption.CONTINUE:
          return (
            <>
              <div className="message-display">
                <Flex align="center" gap={8}>
                  <div className="dot violet"></div>
                  <span>Bạn được gia hạn thêm thời gian để làm bài</span>
                </Flex>
              </div>
              <p className="font-size-16 font-weight-5">
                Thời gian gia hạn:{" "}
                <span className="text-primary">
                  {executionTimeExpiredValue} phút
                </span>
              </p>
            </>
          );
        case TimeExpiredOption.CANCEL:
          return (
            <>
              <div className="message-display">
                <Flex align="center" gap={8}>
                  <div className="dot red"></div>
                  <span>Hệ thống đã huỷ kết quả làm bài của bạn</span>
                </Flex>
              </div>
            </>
          );
        default:
          break;
      }
    }
    return renderFinalResult();
  };

  const renderBtn = () => {
    if (isFinalFinish) {
      return (
        <div className="center gap-8 btn-group">
          {renderRetryBtn()}
          {isShowResult && (
            <Button
              disabled={!isShowResult}
              className="btn-primary h-40 mt-2"
              onClick={onViewResult}
            >
              Kiểm tra kết quả
            </Button>
          )}
        </div>
      );
    }

    if (executionTimeExpired && type === "timeFinish") {
      switch (executionTimeExpiredOption) {
        case TimeExpiredOption.AUTO:
          return (
            <div className="center gap-8 btn-group">
              {renderRetryBtn()}
              {isShowResult && (
                <Button
                  disabled={!isShowResult}
                  className="btn-primary h-40 mt-2"
                  onClick={onViewResult}
                >
                  Kiểm tra kết quả
                </Button>
              )}
            </div>
          );
        case TimeExpiredOption.CONTINUE:
          return (
            <div className="center gap-8 btn-group">
              <Button className="btn-primary h-40 mt-2" onClick={onContinue}>
                Tiếp tục làm bài
              </Button>
            </div>
          );
        case TimeExpiredOption.CANCEL:
          return (
            <div className="center gap-8 btn-group">{renderRetryBtn()}</div>
          );

        default:
          break;
      }
    }
    return (
      <div className="center gap-8 btn-group">
        {renderRetryBtn()}
        {isShowResult && (
          <Button
            disabled={!isShowResult}
            className="btn-primary h-40 mt-2"
            onClick={onViewResult}
          >
            Kiểm tra kết quả
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className="finish-screen">
      <Spin spinning={loading}>
        <div className="center flex-column content-center">
          {type === "userFinish" ? (
            <span className="font-weight-6 font-size-16 mb-1">
              Chúc mừng bạn đã nộp bài thành công!
            </span>
          ) : (
            <span className="font-weight-6 font-size-16 mb-1">
              Thời gian làm bài đã kết thúc!
            </span>
          )}

          {renderExecutionTimeExpiredOption()}
        </div>
        {renderBtn()}
      </Spin>
    </div>
  );
}

export default FinishScreen;
