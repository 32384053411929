import React, { useEffect, useMemo, useState } from "react";
import { Card, Space, Switch, Typography, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
// import { MenuProps } from "antd/lib";
import { ListAnswer, ListQuestion } from "../../../../../types/survey-managment/survey";
import { optionsDropDownQuestionType } from "../../../survey-construction/detail-survey/component/detail-question/constant/data";
import EditorQuill from "../../../survey-construction/detail-survey/component/editor-quill/EditorQuill";
import toolbarOptions from "../../../survey-construction/detail-survey/component/detail-question/toolbarOptions";
import QuestionView from "./question-view/QuestionView";

const modules = {
    toolbar: toolbarOptions,
};

interface QuestionCardProps {
    index: number;
    sectionQuestion: ListQuestion;
    showDetailSection: boolean;
    updateNewSectionQuestion: (data: ListQuestion) => void;
}

const QuestionCard: React.FC<QuestionCardProps> = ({
    index,
    sectionQuestion,
    showDetailSection,
    updateNewSectionQuestion,
}) => {
    const {
        questionName,
        questionDescription,
        questionType,
        orderNumber,
        isRequired,
        moocSurveyQuestionAnswer,
    } = sectionQuestion;

    const listAnswer = useMemo(() => sectionQuestion.moocSurveyQuestionAnswer, [sectionQuestion]);

    const isQuestionHasOther = useMemo(() => {
        return sectionQuestion.moocSurveyQuestionAnswer.some((answer) => answer.isOther);
    }, [sectionQuestion]);

    useEffect(() => {}, [sectionQuestion]);

    const [isRequiredQuestion, setIsRequiredQuestion] = useState<boolean>(isRequired || false);

    const [valueQuillContainer, setValueQuillContainer] = useState(questionDescription);
    const [isOpenAddDescription, setIsOpenAddDescription] = useState(false);

    const handleUpdateAnswerData = (newAnswerData: ListAnswer[]) => {
        // console.log(newAnswerData);
        updateNewSectionQuestion({
            ...sectionQuestion,
            moocSurveyQuestionAnswer: newAnswerData,
        });
    };

    const handleUpdateQuestionDescription = (newDescription: string) => {
        setValueQuillContainer(newDescription);
        updateNewSectionQuestion({
            ...sectionQuestion,
            questionDescription: newDescription,
        });
    };

    return (
        <Card
            key={orderNumber}
            title={
                !showDetailSection ? (
                    <Typography>{`Câu hỏi ${index + 1}`}</Typography>
                ) : (
                    <TextArea
                        value={questionName ? questionName : undefined}
                        placeholder={!questionName ? `Tên câu hỏi` : undefined}
                        style={{
                            height: "auto",
                            width: 450,
                            marginTop: 15,
                            fontSize: 20,
                            fontWeight: "lighter",
                            color: "black",
                            wordWrap: "break-word",
                            resize: "none",
                        }}
                        disabled
                    />
                )
            }
            styles={{
                header: { border: "none" },
                body: { display: showDetailSection ? "block" : "none" },
                extra: { display: showDetailSection ? "block" : "none" },
            }}
            className="wrapper-settings--card form-setting--common"
            extra={
                <>
                    <Select
                        value={`${
                            optionsDropDownQuestionType.find((opt) => opt.type === questionType)?.value
                        }`}
                        options={optionsDropDownQuestionType}
                        disabled
                    />
                </>
            }
        >
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                {isOpenAddDescription && (
                    <EditorQuill
                        valueInitial={questionDescription}
                        setUpdateValue={handleUpdateQuestionDescription}
                    />
                )}
            </Space>

            {/* Hiển thị nội dung câu hỏi */}
            <QuestionView
                typeQuestionId={Number(questionType)}
                listAnswer={listAnswer}
                handleUpdateAnswerData={handleUpdateAnswerData}
            />

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space size="large">
                    <Space size="small">
                        <span>Bắt Buộc </span>
                        <Switch checked={isRequiredQuestion} size="small" disabled />
                    </Space>
                </Space>
            </div>
        </Card>
    );
};

export default QuestionCard;
