import { axiosConfig } from "../../config/api/configApi";
import { AxiosResponse } from "axios";
import { axiosConfigV2OpenAPI } from "../../config/api/configOpenApiV2";

export const getUniversities: (payload: {
  page?: number;
  size?: number;
  keyword?: string;
  industryGroupId?: number;
  traningLevelId?: number;
  rate?: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2OpenAPI.post(`/enterprise/search-enterprise`, payload);
};

export const exportExcelListEnterprise: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2OpenAPI.get(`/enterprise/export-excel-list-enterprise`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const exportExcelChartEnterprise: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2OpenAPI.get(`/enterprise/export-excel-chart-enterprise`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getTrainingLevelFilter: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2OpenAPI.get(`/enterprise/get-training_level-filter`);
};

export const getIndustriesFilter: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2OpenAPI.get(`/enterprise/get-industry-filter`);
};
