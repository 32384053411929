import {
  Badge,
  Button,
  Col,
  Collapse,
  Form,
  Modal,
  Radio,
  Row,
  Typography,
  message,
} from "antd";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import { FileDoneOutlined } from "@ant-design/icons";
import {
  CreareCoursePlanArroval,
  InfoResultArroval,
  InfoSendArroval,
} from "../../../../../types/course-plan-managment/mooc-approval";
import { get } from "lodash";
import ReactQuill from "react-quill";
import { useAuthStore } from "../../../../../stores/stores";
import { HelpService } from "../../../../../service/helper.service";
import { useNavigate, useParams } from "react-router-dom";
import toolbarOptions from "../../../common/editor-quill/toolbarOptions";
import { useEffect, useState } from "react";
import { getUniversity } from "../../../../../service/survey-managment/common";
import { getIndustry } from "../../../../../service/course-construct";
import { getdataIndustryGroup } from "../../../../../service/common";
import {
  approveCoursePlan,
  getInfoPlanApproval,
} from "../../../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import moment from "moment";
import {
  approvalStatus,
  approvalStatusEum,
} from "../../../mooc-approval-plan/constants/data";
import { routesConfig } from "../../../../../config/routes";

const ApprovalCourseRegister = (props: { courseId: number }) => {
  const { courseId } = props;
  const params = useParams();
  const { Text } = Typography;
  const { Panel } = Collapse;
  const navigation = useNavigate();
  const [form] = Form.useForm();
  const userId = useAuthStore((states) => states.user?.id);
  const helpService = new HelpService();
  const [dataOptions, setDataOptions] = useState({
    dataIndustry: [],
    dataIndustryGroup: [],
    dataUniversity: [],
    dataFilter: {},
  });
  const [isRequiredRemark, setIsRequiredRemark] = useState(false);
  const handleOk = () => {
    form
      .validateFields()
      .then(() => {
        Modal.confirm({
          icon: <FileDoneOutlined />,
          title: `Xác nhận Phê duyệt đăng ký khóa học?`,
          content: (
            <>
              <p>
                Đảm bảo rằng tất cả các
                <Text type="warning">
                  {" "}
                  thông tin liên quan đã được kiểm tra và cặp nhật đầy đủ{" "}
                </Text>
                trước khi Xác nhận kết quả phê duyệt đăng ký khóa học
              </p>
            </>
          ),
          onOk: () => form.submit(),
          centered: true,
          okText: "Xác nhận",
          cancelText: "Đóng",
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      })
      .catch((error) => { });
  };
  const modules = {
    toolbar: toolbarOptions,
  };
  const [listInfoResultApproval, setListInfoResultApproval] = useState<any>();
  const [listInfoSendApproval, setListInfoSendApproval] = useState<any[]>();

  const [infoResultApproval, setInfoResultApproval] =
    useState<InfoResultArroval>({
      approvalStatus: 1,
      userArrovel: undefined,
      approvalDate: undefined,
      approvalRemark: undefined,
    });

  const [infoSendApproval, setInfoSendApproval] = useState<InfoSendArroval>({
    unniversityName: undefined,
    submissionDate: undefined,
    submitterName: undefined,
  });

  const renderSwitchApproval = (value: string) => {
    switch (value) {
      case "unniversityName":
        return "Trường chủ trì: ";
      case "submitterName":
        return "Người gửi: ";
      case "submissionDate":
        return "Ngày gửi: ";
      case "submissionDate":
        return "Ngày gửi: ";
      case "userArrovel":
        return "Người duyệt: ";
      case "approvalDate":
        return "Ngày duyệt: ";
      case "approvalRemark":
        return "Mô tả: ";
      default:
        return "";
    }
  };

  const fetchDataOptions = async () => {
    const response = await Promise.all([
      getUniversity(),
      getIndustry(),
      getdataIndustryGroup(),
    ]);
    const dataUniversity = response[0].data.data;
    const dataIndustry = response[1].data.data;
    const dataIndustryGroup = response[2].data.data;
    setDataOptions({
      ...dataOptions,
      dataIndustry: dataIndustry,
      dataUniversity: dataUniversity,
      dataIndustryGroup: dataIndustryGroup,
    });
  };

  const getInfoApprovalById = async (id: any) => {
    await getInfoPlanApproval(id)
      .then((res) => {
        const resData = res.data.data;
        setInfoSendApproval({
          unniversityName: resData?.unniversityName ?? "",
          submissionDate: moment(resData?.submissionDate ?? undefined).format(
            "DD/MM/yyyy"
          ),
          submitterName: resData?.submitterName ?? "",
        });
        setInfoResultApproval({
          approvalStatus: resData.approvalStatus ?? "",
          userArrovel: resData.userArrovel ?? "",
          approvalDate: moment(resData?.approvalDate ?? undefined).format(
            "DD/MM/yyyy"
          ),
          approvalRemark: resData.approvalRemark ?? "",
        });
      })
      .catch((err) => {
        message.error("Có lỗi chưa xác định");
      });
  };

  useEffect(() => {
    fetchDataOptions();
    getInfoApprovalById(courseId);
  }, []);

  useEffect(() => {
    // Object.entries. chuyển 1 đôi tượng với các thuộc tính thình mảng các thuộc tijnshn theo dạng key value
    // vdL {id:1, name:2}  => [[id,1],[name,2]]
    const convertObjectDataInfoSendApproval = Object.entries(infoSendApproval);
    const listInfoSendApproval = convertObjectDataInfoSendApproval.map(
      (item: any, index) => {
        return (
          <div className="info-row-approval" key={index}>
            <label className="label-row">
              {renderSwitchApproval(item[0])}{" "}
            </label>{" "}
            <span className="sub-title-approval-custom-card">{item[1]}</span>
          </div>
        );
      }
    );

    const listInfoReSultApproval = (
      <>
        <div className="info-row-approval">
          <label className="label-row">{infoResultApproval.userArrovel} </label>{" "}
        </div>
        <div className="info-row-approval">
          <span className="sub-title-approval-custom-card">
            {infoResultApproval.approvalDate}
          </span>{" "}
          {infoResultApproval.approvalStatus && (
            <Badge
              color={
                approvalStatus.filter(
                  (x) => x.value == infoResultApproval.approvalStatus
                )[0].color ?? null
              }
            />
          )}
          <span className="sub-title-approval-custom-card">
            {infoResultApproval.approvalStatus && (approvalStatus.filter(
              (x) => x.value == infoResultApproval.approvalStatus
            )[0].text ?? null)}
          </span>
        </div>
        <div className="info-row-approval">
          <label className="label-row">Mô tả </label>{" "}
          <span
            style={{ fontWeight: 400, fontFamily: "roboto", fontSize: "14px" }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: ` ${infoResultApproval.approvalRemark ?? ""}`,
              }}
            />
          </span>
        </div>
      </>
    );

    setListInfoSendApproval(listInfoSendApproval);
    setListInfoResultApproval(listInfoReSultApproval);
  }, [infoSendApproval, infoResultApproval]);

  return (
    <>
      <div className="approval-custom-card">
        <div className="approval-space"></div>
        <div className="approval-card-container">
          <CustomCard
            title={
              <div>
                <Typography.Title level={4}>
                  Thông tin duyệt
                </Typography.Title>
                <Collapse defaultActiveKey={['1', '2']}>
                  <Panel header="Thông tin gửi" key="1">
                    <div style={{ margin: '8px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                      {listInfoSendApproval}
                    </div>
                  </Panel>
                  <Panel header="Kết quả phê duyệt" key="2">
                    <div style={{ margin: '8px' }}>
                      <Form.Provider
                        onFormFinish={(name, { values, forms }) => {
                          if (name === "approval") {
                            const dataSend: CreareCoursePlanArroval = {
                              courseId: Number(courseId),
                              individualId: userId ?? undefined,
                              approvalRemark: values.approvalRemark,
                              courseStatus: values.surveyStatus
                                ? approvalStatusEum.APPROVED
                                : approvalStatusEum.REFUSE,
                            };
                            approveCoursePlan(dataSend).then((res) => {
                              const { data, statusCode, message } = res.data;
                              if (statusCode === 200) {
                                helpService.successMessage(
                                  get(data, "message", message)
                                );
                                navigation(
                                  `/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}`
                                );
                              }
                            });
                          }
                        }}
                      >
                        <Form
                          form={form}
                          layout="vertical"
                          initialValues={{
                            surveyStatus: true,
                          }}
                          name="approval"
                        >
                          <Row gutter={16}>
                            <Col span={24}>
                              <Form.Item
                                name="surveyStatus"
                                rules={[
                                  () => ({
                                    validator(_, value) {
                                      if (value === true) {
                                        form.setFields([
                                          {
                                            name: "approvalRemark",
                                            errors: [],
                                          },
                                        ]);
                                        setIsRequiredRemark(false);
                                        return Promise.resolve();
                                      } else {
                                        setIsRequiredRemark(true);
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                                validateTrigger={["onChange", "onBlur"]}
                              >
                                <Radio.Group>
                                  <Radio value={true}>Đồng ý</Radio>
                                  <Radio value={false}>Không đồng ý</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={24}>
                              <Form.Item
                                name="approvalRemark"
                                label="Mô tả phê duyệt"
                                rules={[
                                  { required: isRequiredRemark, message: "" },
                                  () => ({
                                    validator(_, value) {
                                      if (
                                        isRequiredRemark &&
                                        (!value ||
                                          value.replace(/<[^>]+>/g, "").trim() === "")
                                      ) {
                                        return Promise.reject(
                                          "Vui lòng nhập mô tả phê duyệt!"
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                                validateTrigger={["onChange", "onBlur"]}
                              >
                                <ReactQuill
                                  modules={modules}
                                  theme="snow"
                                  style={{
                                    height: "200px",
                                    marginBottom: "48px",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col span={24}>
                              <div className="btn-approval">
                                <Button
                                  onClick={() => {
                                    navigation(`/survey-training/browse-survey`);
                                  }}
                                >
                                  Hủy
                                </Button>
                                <Button onClick={handleOk} type="primary">
                                  Lưu
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      </Form.Provider>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            }
          ></CustomCard>
        </div>
      </div>
    </>
  );
};

export default ApprovalCourseRegister;
