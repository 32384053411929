import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, FormInstance, Row, Typography } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import FormItemDropdown from '../../form-dropdown/FormItemDropdown';
import { getUniversityListFilter } from '../../../service/account-register';
import { getAllEnterpriseUniversity } from '../../../service/management-participate-platform';

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  clearFilter: (form: FormInstance) => void;
  onFinish?: (values: any) => void;
  filterData: any;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
};

const FormTable = ({
  onFinish,
  showFilter,
  clearFilter,
  filterData,
  setFilterData,
}: FormFilterProps) => {
  const { RangePicker } = DatePicker;
  const [form] = useForm()
  const [schoolFilter, setSchoolFilter] = useState([])
  const [listEnterPrise, setListEnterPrise] = useState<any>([])

  const resourceRegister = [
    {
      id: 1,
      name: 'Tự đăng ký'
    },
    {
      id: 2,
      name: 'Trường đăng ký'
    }
  ]

  const getListEnterprise = async () => {
    const data = {
      page: 1,
      size: 10000,
      sort: [],
      keyword: ""
    }
    await getAllEnterpriseUniversity(data).then((res) => {
      if (res.status === 200) {
        const arr = res.data?.data?.content?.map((item: any) => ({
          id: item.universityId,
          name: item.name
        }))
        setListEnterPrise(arr)
      }
    })
  }

  useEffect(() => {
    getListEnterprise()
  }, [])


  return (
    <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
      <Form
        form={form}
        name="filterForm"
        className="filter-form"
        onFinish={onFinish}
      >
        <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]} className="row-filter">
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 4 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Trường đại học</Typography.Title>
            <Form.Item name="universityId">
              <FormItemDropdown placeholder="Chọn trường đại học"
                options={listEnterPrise} onChange={(option) => setFilterData(option, 'universityId')} />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 4 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Nguồn</Typography.Title>
            <Form.Item name="sourceRegister">
              <FormItemDropdown placeholder="Chọn nguồn đăng ký"
                options={resourceRegister}
                onChange={(option) => setFilterData(option, 'sourceRegister')}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 4 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Ngày tạo</Typography.Title>
            <Form.Item name="approvedDate">
              <RangePicker
                placeholder={["Bắt đầu", "Kết thúc"]}
                format="DD/MM/YYYY"
                value={filterData.enrolledDate}
                onChange={(_value, dateString: any) => {
                  setFilterData(dateString, "rangeDate");
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 4 }}
            xs={{ span: 24 }}
            className="flex align-end col-filter"
          >
            <div className="group-btn--filter">
              <Button
                className="btn btn-primary filter-btn"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <FilterOutlined />
                <Typography.Text>Lọc</Typography.Text>
              </Button>
              <Button
                className="btn btn-outlined clear-filter-btn"
                onClick={() => clearFilter(form)}
              >
                <DeleteOutlined />
                <Typography.Text>Xóa tất cả</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormTable;
