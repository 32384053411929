import React, { FunctionComponent } from 'react';
import { Button, Col, Form, Row, Typography, DatePicker } from "antd";
// import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import "./filter-group.scss"
import { dataStatus } from "../../constants/data";
import { get } from "lodash";
import { useForm } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";

type FormFilterProps = {
  showFilter?: boolean
  clearFilter: (form: FormInstance) => void,
  onFinish?: any,
  dataOptions?: any
}

const FormFilter: FunctionComponent<FormFilterProps> = ({
  onFinish,
  showFilter,
  clearFilter,
  dataOptions,
}) => {

  const [form] = useForm()

  return (
    <div className="survey-managment-traning-filter-group">
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form name="filterForm" onFinish={onFinish} form={form}>
          <Row gutter={[{ xs: 8, xl: 4, md: 16 }, 12]}>
            <Col
              xl={{ span: 9 }}
              md={{ span: 9 }}
              sm={{ span: 8 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="surveyOpen">
                <DatePicker
                  placeholder="Ngày bắt đầu"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 9 }}
              md={{ span: 9 }}
              sm={{ span: 8 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="surveyClose">
                <DatePicker
                  placeholder="Ngày kết thúc"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 8 }}
              xs={{ span: 24 }}
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={() => clearFilter(form)}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default FormFilter;
