import { ReactNode } from 'react';

type DetailContentProps = {
    children?: ReactNode
}

export function DetailContent({ children }: DetailContentProps) {
    return (
        <div className="wrapper" >
            <section className="detail-account">
                <div className="form-detail" >
                    {children}
                </div>
            </section>
        </div>
    )
}
