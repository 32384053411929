// @ts-nocheck
import { FunctionComponent, useEffect, useState } from "react";
import { Layout, Tooltip } from "antd";
import { DataType } from "../../types/listUsers";
// import "./page-builder-view.scss";
import parse, { domToReact } from "html-react-parser";
import { isEmpty } from "lodash";
const { Content } = Layout;
import { useParams } from "react-router-dom";
import PageHeader from "../../../components/page-header/PageHeader";

var indexDB = require("idbcache").default;
import { useAuthStore } from "../../../stores/stores";
import ListBlogContent from "../../../components/blog-and-forum/design-theme-blog-content/list-blog-content";

type ProfileInfoProps = {
  dataUser?: DataType;
};

const DetailContentPreview: FunctionComponent<ProfileInfoProps> = () => {
  const [html, setHTML] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const imgPath = "/images/";

  const params = useParams();
  const userInfo = useAuthStore((state) => state.user);
  const universityID = userInfo?.enterpriseUUID || "0";
  indexDB.get("forumSavedTempUniversity-" + universityID).then((val: any) => {
    if (val) {
      let html = "";
      const parsecourseSavedTempalte = JSON.parse(val);
      // if (parsecourseSavedTempalte.id === params?.id) {
      html = parsecourseSavedTempalte.html;
      html = html.replace(new RegExp("<body.*?>"), "").replace("</body>", "");
      const style = document.createElement("style");
      style.append(parsecourseSavedTempalte.css);
      const head = document.querySelector("head");
      head.appendChild(style);
      setHTML(html);
      // }
    }
  });

  const resizePage = (size: "pc" | "tablet" | "mobile") => {
    const container = document.getElementById("resize-container");
    if (container) {
      switch (size) {
        case "pc":
          container.style.width = "100%";
          break;
        case "tablet":
          container.style.width = "70%";
          break;
        case "mobile":
          container.style.width = "768px";
          break;
        default:
          break;
      }
    }
  };

  return (
    <Layout>
      <div className="w-full">
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="w-full">
              <PageHeader title={"Danh sách bài đăng"}
                arrowLeft={imgPath + "arrowLeft.svg"}
                positionItem="column"
                breadcrumb={true}
              />
            </div>
          </div>
          <div className={`wrapper-filter`}>
          </div>
        </div>
        <div className="flex justify-center gap-3">
          <Tooltip title="PC">
            <svg
              style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
              viewBox="0 0 24 24"
              onClick={() => resizePage("pc")}
            >
              <path
                fill="#8b8b8b"
                d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z"
              ></path>
            </svg>
          </Tooltip>
          <Tooltip title="Tablet">
            <svg
              style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
              viewBox="0 0 24 24"
              onClick={() => resizePage("tablet")}
            >
              <path
                fill="#8b8b8b"
                d="M19,18H5V6H19M21,4H3C1.89,4 1,4.89 1,6V18A2,2 0 0,0 3,20H21A2,2 0 0,0 23,18V6C23,4.89 22.1,4 21,4Z"
              ></path>
            </svg>
          </Tooltip>
          <Tooltip title="Mobile">
            <svg
              style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
              viewBox="0 0 24 24"
              onClick={() => resizePage("mobile")}
            >
              <path
                fill="#8b8b8b"
                d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z"
              ></path>
            </svg>
          </Tooltip>
        </div>
        <div id="resize-container">
          {!isEmpty(html) ? (
            <Content className="page-builder-view">
              {parse(html, {
                replace(domNode) {
                  if (
                    (domNode as Element).attribs &&
                    domNode.attribs["component-class"]
                  ) {
                    switch ((domNode as Element).attribs["component-class"]) {
                      case "TitleComponent": {
                        const props = domNode.attribs;
                        return (
                          <TitleComponent {...props}>
                            {domToReact((domNode as Element).children)}
                          </TitleComponent>
                        );
                      }
                      case "ContactComponent": {
                        const props = domNode.attribs;
                        return (
                          <ContactComponent {...props}>
                            {domToReact((domNode as Element).children)}
                          </ContactComponent>
                        );
                      }
                      case "LinkedCourseComponent": {
                        const props = domNode.attribs;
                        return (
                          <LinkedCourseComponent {...props}>
                            {domToReact((domNode as Element).children)}
                          </LinkedCourseComponent>
                        );
                      }
                      case "HeaderGeneralIntroduction": {
                        const props = domNode.attribs;
                        return (
                          <HeaderGeneralIntroduction {...props}>
                            {domToReact((domNode as Element).children)}
                          </HeaderGeneralIntroduction>
                        );
                      }
                      case "CourseListBlock": {
                        const props = domNode.attribs;
                        return (
                          <CourseListBlock {...props}>
                            {domToReact((domNode as Element).children)}
                          </CourseListBlock>
                        );
                      }
                      case "ListBlogContent": {
                        const props = domNode.attribs;
                        return (
                          <ListBlogContent {...props}>
                            {domToReact((domNode as Element).children)}
                          </ListBlogContent>
                        );
                      }
                    }
                  }
                },
              })}
            </Content>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export default DetailContentPreview;
