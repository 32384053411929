// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-custom-many-approval {
  display: flex;
  align-items: center;
  margin: 12px 0; }

.title-custom-many-approval-h1 {
  margin-right: 5px;
  font-size: 18px; }

.title-custom-many-approval-span {
  font-style: italic; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-approval-plan/components/many-approvals/ManyApprovals.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,mBAAmB;EACnB,cAAc,EAAA;;AAGlB;EACI,iBAAiB;EACjB,eAAe,EAAA;;AAEnB;EACI,kBAAkB,EAAA","sourcesContent":[".title-custom-many-approval {\n    display: flex;\n    align-items: center;\n    margin: 12px 0;\n}\n\n.title-custom-many-approval-h1 {\n    margin-right: 5px;\n    font-size: 18px;\n}\n.title-custom-many-approval-span{\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
