// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-role {
  max-width: 450px;
  overflow: hidden; }
  .item-role .ant-checkbox {
    overflow: visible; }
  .item-role span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/setting-permission-course/style.scss"],"names":[],"mappings":"AAAA;EACQ,gBAAgB;EAChB,gBAAgB,EAAA;EAFxB;IAIY,iBAAiB,EAAA;EAJ7B;IAOY,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe,EAAA","sourcesContent":[".item-role {\n        max-width: 450px;\n        overflow: hidden;\n        .ant-checkbox{\n            overflow: visible;\n        }\n        span {\n            text-overflow: ellipsis;\n            overflow: hidden;\n            white-space: nowrap;\n            max-width: 100%;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
