import {
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps,
  Tooltip,
  Typography,
} from "antd";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import TableData from "../../../../components/table-data/TableData";
import { formatDate } from "../../../../utils/converDate";
import { getListTagFavorite } from "../../../../service/system-suggest-course";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";

type TableRateScoreProps = {
  infoFavoriteStudent: any;
  setPageNumber: (item: number) => void;
  setPageSize: (item: number) => void;
  pageNumber: number;
  pageSize: number;
};
interface filterParamProps {
  skip: number;
  take: number;
}
const TableRateScore = ({
  infoFavoriteStudent,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
}: TableRateScoreProps) => {
  const params = useParams()
  const [listTagFavorite, setListTagFavorite] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchListTagFavorite = async (filterParam: filterParamProps) => {
    try {
      const res = await getListTagFavorite({
        ...filterParam,
        user_id: Number(params?.id),
        tag_ids: infoFavoriteStudent?.data?.mooc_student_favorite
          ? infoFavoriteStudent?.data?.mooc_student_favorite?.map(
              (value: any) => value?.mooc_recommendation_tag?.id
            )
          : [],
      });
      const { data } = res.data;
      setListTagFavorite(data?.data);
      setTotalPage(data?.total?._count?.id);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchListTagFavorite({
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
  }, [pageSize, pageNumber, infoFavoriteStudent?.data]);
  const dataColumnsQualityAutoScore: TableColumnsType<any> = [
    {
      title: "Mã người dùng",
      dataIndex: "code",
      key: "code",
      width: 20,
      render: (_: any, record) => {
        return <Typography.Text>{record?.id}</Typography.Text>;
      },
    },
    {
      title: "Nhãn thẻ tag theo sở thich",
      dataIndex: "tag",
      key: "tag",
      width: 70,
      render: (_: any, record) => {
        return (
          <div>
            {!isEmpty(record?.mooc_student_favorite) ? (
              <Flex align="center">
                {record?.mooc_student_favorite?.map(
                  (record: any, index: number) => {
                    if (index <= 2) {
                      return (
                        <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
                          {record?.mooc_recommendation_tag?.name}
                        </div>
                      );
                    }
                  }
                )}
                {record?.mooc_student_favorite?.length > 3 && (
                  <Tooltip
                    placement="topLeft"
                    title={record?.mooc_student_favorite?.map(
                      (value: any, index: number) => {
                        if (
                          index >= 3 &&
                          value?.mooc_recommendation_tag?.name
                        ) {
                          return (
                            <span>
                              {value?.mooc_recommendation_tag?.name
                                ? value?.mooc_recommendation_tag?.name + ", "
                                : ""}
                            </span>
                          );
                        }
                      }
                    )}
                    className="border rounded-md bg-[#FAFAFA] px-2 mr-1"
                  >
                    +{record?.mooc_student_favorite?.length - 3}
                  </Tooltip>
                )}
              </Flex>
            ) : (
              <div>Chưa có dữ liệu</div>
            )}
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumnsQualityAutoScore.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsQualityAutoScore.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group  table-list-top">
      <Form.Provider>
        <TableData
          dataTable={listTagFavorite}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default TableRateScore;
