import { Button, Dropdown, Form, Layout, MenuProps, Modal, Popconfirm, Space, Switch, TableColumnsType, TableProps, Tag, Typography, message } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { HelpService } from "../../../service/helper.service";
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, MoreOutlined, SaveOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib";
import { routesConfig } from "../../../config/routes";
import { DataType, validateFieldCreate } from "./constants/data";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import TableData from "../../../components/table-data/TableData";
import DrawerCustom from "../../../components/drawer/Drawer";
import FormCreateLecturer from "./components/form-create-lecturer/FormCreateLecturer";
import "./lecturer-management.scss"
import { formatDateV2 } from "../../../utils/converDate";
import { get, isEmpty } from "lodash";
import { addMoocLecturer, changeStatusLecturer, deleteMoocLecturer, downloadLecturerTemplateFile, exportExcelMoocLecturer, getAcademicLevelLecturer, getAddressProvinces, getAllLecturerByFilter, getIndustryGroupLecturer, getIndustryLecturer, getMoocEnterpriseUniversity, getMoocLecturerById, getPositionsLecturer, importExcelMoocLecturer, updateMoocLecturer } from "../../../service/category-management/lecturer-management";

type UniversityManagementProps = {
  title?: string;
};

const LecturerManagement = ({ title }: UniversityManagementProps) => {
  const [form] = Form.useForm();
  const [params, setParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const navigate = useNavigate();
  const helpService = new HelpService();
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [editId, setEditId] = useState<number>(0);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [dataLecturer, setDataLecturer] = useState<any>([]);
  const [dataOptions, setDataOptions] = useState({
    dataUniversity: [],
    dataAcademicLevel: [],
    dataRegency: [],
    dataProvinces: [],
    dataDepartment: [],
    dataSpecialized: [],
  });

  const [filterData, setFilterData] = useState<any>({
    keyword: null,
    status: null,
    university: null,
    regency: null,
    createdDateFrom: null,
    createdDateTo: null,
  });

  const lecturersOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa giảng viên",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa giảng viên",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên giảng viên",
      dataIndex: "name",
      key: "name",
      className:"title-sequence",
      sorter: true,
      width: 30,
      render: (_: any, record) => {
        return (
          <div className="table-lecturer cursor-pointer">
            <div>
              <a
                className="lecturer-name"
                onClick={() =>
                  navigate(
                    `${routesConfig.lecturerManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                      },
                    }
                  )
                }
              >
                {record.name}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mã giảng viên",
      dataIndex: "code",
      key: "code",
      className:"title-sequence",
      width: 20,
    },
    {
      title: "Đơn vị công tác",
      dataIndex: "universityName",
      key: "universityName",
      className:"title-sequence",
      width: 40,
    },
    {
      title: "Chức vụ",
      dataIndex: "authPositions",
      key: "authPositions",
      className:"title-sequence",
      width: 30,
      render: (authPositions) => {
        return (
          <div>
            {authPositions.map((position: any) => (
              <Tag style={{ marginBottom: 5 }} key={position.name}>{position.name}</Tag>
            ))}
          </div>
        );
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className:"title-sequence",
      width: 30,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any, index: number) => {
        return record.createdDate ? formatDateV2(record.createdDate) : null;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: { status: boolean; id: number }) => {
        const handleChange = (checked: boolean) => {
          record.status = checked;
        };

        const confirm = (id: number) => {
          setLoading(true);
          changeStatusLecturer({
            usedId: id,
            isActive: record?.status,
          }).then((res) => {
            if (res.status === 200) {
              setTimeout(() => {
                setLoading(false);
              }, 333);
              helpService.successMessage("Thay đổi trạng thái thành công!");
              getAllLecturer();
            }
          }).catch(err => {
            setLoading(false);
            console.error(err);
          })
        };

        const cancel = () => {
          helpService.errorMessage("Hủy thao tác thành công!");
        };

        return (
          <Popconfirm
            rootClassName="popconfirm-change-status"
            description={`Xác nhận đổi trạng thái?`}
            onConfirm={() => confirm(get(record, 'id'))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title="">
            <Switch defaultChecked={record.status}
              checked={record.status}
              onChange={handleChange} />
          </Popconfirm>
        );
      },
      width: 20,
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.lecturerManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                        idEdit: record?.id,
                      },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: lecturersOptions,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      getLecturerUpdate(record.id);
                      handleOpenEdit(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        title: `Xoá giảng viên`,
                        content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                        onOk: () => deleteLecturer(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị danh mục",
    },
  ];

  // Get data lecturer
  const getAllLecturer = (data?: any) => {
    setLoading(true);
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      status: filterData.status,
      universityId: filterData.university,
      positionId: filterData.regency,
      createdFromDate: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdToDate: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`]
    };
    getAllLecturerByFilter(dataReq)
      .then((res) => {
        setDataLecturer(res.data.data.moocUniversityMemberData);
        setTotalRecords(res.data.data.total);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };
  
  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      university: null,
      regency: null,
      createdDateFrom: null,
      createdDateTo: null,
      status: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      getAllLecturer();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    getAllLecturer();
  };

  const handleFinish = (values: any) => {
    setFilterData({
      ...filterData,
      status: values?.status,
      university: values?.university,
      regency: values?.regency,
      createdDateFrom: values?.time ? (values?.time[0]).add(1, "day").toISOString() : null,
      createdDateTo: values?.time ? (values?.time[1]).add(1, "day").toISOString() : null,
    });
  };


  // Get lecturer to edit
  const getLecturerUpdate = async (id: number) => {
    setLoadingForm(true);
    await getMoocLecturerById(id).then((res) => {
      const { data } = res.data;
      setDataDetail(data);
      setTimeout(() => {
        setLoadingForm(false);
      }, 333);
    })
      .catch((err) => {
        setLoadingForm(false);
        console.error(err);
      });
  };

  // Modal create | edit
  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    form.resetFields();
  };

  const handleCreateAndEdit = () => {
    Modal.confirm({
      title: !isOpenedEdit ? `Thêm mới giảng viên` : `Chỉnh sửa giảng viên` ,
      content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu`,
      onOk: () => form.submit(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  const onFinishCreate = async (values: any) => {
    if (Object.keys(values).length > 0) {
      if (isOpenedEdit) {
        const dataPayload = {
          name: values.name,
          universityId: values.university,
          gender: values.gender,
          code: values.code,
          provinceCode: values.domicile ? values.domicile : null,
          positionIds: values.regency ? values.regency : null,
          educationLevels: values.academicLevel ? values.academicLevel : null,
          industryGroupId: values.department ? values.department : null,
          industryIds: values.specialized ? values.specialized : null,
          phoneNumber: values.phone,
          email: values.email,
          status: values.status,
        };
        setIsSaving(true);
        try {
          await updateMoocLecturer(editId, dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Cập nhật giảng viên thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllLecturer();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      } else {
        const dataPayload = {
          name: values.name,
          universityId: values.university,
          gender: values.gender,
          code: values.code,
          provinceCode: values.domicile ? values.domicile : null,
          positionIds: values.regency ? [values.regency] : null,
          educationLevels: values.academicLevel ? values.academicLevel : null,
          industryGroupId: values.department ? values.department : null,
          industryIds: values.specialized ? values.specialized : null,
          phoneNumber: values.phone,
          email: values.email,
          status: values.status,
        };
        
        setIsSaving(true);
        try {
          await addMoocLecturer(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Thêm mới giảng viên thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllLecturer();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  // Delete lecturer
  const deleteLecturer = async (lecturer: any) => {
    try {
      await deleteMoocLecturer(lecturer.id).then((res) => {
        if (res.status === 200) {
          message.success("Xóa giảng viên thành công!");
          getAllLecturer();
        }
      });
    } catch (err) { }
  };

  // Import file Excel to add new
  const importExcel = async (file: any) => {
    setLoading(true);
    await importExcelMoocLecturer(file).then((res) => {
      if (res.status === 200) {
        message.success("Thêm mới giảng viên thành công!");
        setLoading(false);
        setTimeout(() => {
          setLoading(false);
        }, 300);
        getAllLecturer();
      }
    }).catch((err) => {
      {
        setLoading(false);
        console.error(err);
      }
    })
  }

  // Export file Excel
  const exportExcel = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      universityId: filterData.university,
      positionId: filterData.regency,
      createdFromDate: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdToDate: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`],
      status: filterData.status,
    };
    setIsRendering(true);
    try {
      await exportExcelMoocLecturer(dataReq).then((res) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Danh sách giảng viên.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!");
        setIsRendering(false);
      })
    } catch (err) {
      setIsRendering(false);
      console.error("Error downloading Excel file:", err);
    }
  }

  // Download file template excel
  const handleDownloadFile = async () => {
    try {
      await downloadLecturerTemplateFile().then((res) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Mẫu thêm mới giảng viên.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!")
      })
    } catch (err) {
      console.error("Error downloading Excel file:", err);
    }
  }

  // Sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // useEffect
  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      const educationLevels = dataDetail.moocTeacherProfiles.map((item: any) => {
        return (item.id)
      })

      const positionIds = dataDetail.authPositions.map((item: any) => {
        return (item.id)
      })

      const industryIds = dataDetail.industryGroupInfo?.children.map((item: any) => {
        return (item.id)
      })
      
      form.setFieldsValue({
        name: dataDetail.name,
        university: dataDetail.universityId,
        gender: dataDetail.gender,
        code: dataDetail.code,
        domicile: dataDetail.provinceCode ? dataDetail.provinceCode : null,
        regency: positionIds ? positionIds : null,
        academicLevel: educationLevels ? educationLevels : null,
        department: dataDetail.industryGroupInfo?.id ? dataDetail.industryGroupInfo?.id : null,
        specialized: industryIds ? industryIds : null,
        phone: dataDetail.phoneNumber,
        email: dataDetail.email,
        status: dataDetail.status,
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    getAllLecturer();
  }, [pageNumber, pageSize, filterData, statusOrder]);

  useEffect(() => {
    setLoading(true);
    Promise.all([getAcademicLevelLecturer(), getIndustryGroupLecturer(), getIndustryLecturer(''),
    getPositionsLecturer(), getMoocEnterpriseUniversity({ isEnterprise: true }), getAddressProvinces()])
      .then((res) => {
        const dataAcademicLevel = res[0].data.data.map((item: any) => {
          return ({
            id: item.id,
            name: item.levelName,
          })
        })
        const dataIndustryGroup = res[1].data.data;
        const dataIndustry = res[2].data.data;
        const dataPositions = res[3].data.data;
        const dataEnterpriseUniversity = res[4].data.data.list;
        const dataProvinces = res[5].data.data.map((item: any) => {
          return ({
            id: item.code,
            name: item.name,
          })
        });

        setDataOptions({
          ...dataOptions,
          dataAcademicLevel: dataAcademicLevel,
          dataDepartment: dataIndustryGroup,
          dataSpecialized: dataIndustry,
          dataRegency: dataPositions,
          dataUniversity: dataEnterpriseUniversity,
          dataProvinces: dataProvinces,
        })

        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
  }, [])
  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createLecturer") {
            const { createLecturer } = forms;
            const data = createLecturer.getFieldsValue(validateFieldCreate);
            onFinishCreate(data);
          }
        }}
      >
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`} >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              exportExcel={exportExcel}
              handleDownloadFile={handleDownloadFile}
              importExcel={importExcel}
              isRendering={isRendering}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>

        <TableData
          dataTable={dataLecturer}
          dataColumns={dataColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          loadingTable={loading}
          handleTableChangeProps={handleTableChange}
        />

        <DrawerCustom title={`${isOpenedEdit ? "Chỉnh sửa giảng viên" : "Thêm mới giảng viên"}`}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" htmlType="submit" onClick={handleCreateAndEdit} disabled={isSaving}>
                {isSaving ? <LoadingOutlined /> : <SaveOutlined />}
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateLecturer form={form} dataOptions={dataOptions} loadingForm={loadingForm} />
        </DrawerCustom>
      </Form.Provider>
    </Layout>
  );
};

export default LecturerManagement;
