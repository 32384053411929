import {
  InfoCircleOutlined,
  PercentageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Flex, Progress, Typography, message } from "antd";
import { useState } from "react";
import FormInputCommon from "../../../../../../components/form-input-common/FormInputCommon";
import CommonModal from "../../../../../../components/modal/common";
import { updateRatingSettingAttendance } from "../../../../../../service/setting-attendance";

const HeadQualityEvaluation = ({ rateHead, headValue, setRate }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onCloseModal = () => {
    setIsOpen(false);
  };
  const handleSaveEvaluation = () => {
    if (rateHead?.rating > 0) {
      setIsOpen(true);
    } else {
      messageApi.open({
        type: "error",
        content: "Đánh giá báo cáo phải lớn hơn 0%",
      });
    }
  };
  const handleConfirmEvaluation = async () => {
    try {
      await updateRatingSettingAttendance(rateHead?.idRate, {
        rating: rateHead?.rating,
      });
      setIsOpen(false);
      messageApi.open({
        type: "success",
        content: "Lưu đánh giá báo cáo thành công",
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Lưu đánh giá báo cáo thất bại",
      });
    }
  };
  const handleChangeInput = (e: any) => {
    if (!/^\d*$/.test(e?.target?.value as string)) {
      return;
    }
    if (Number(e?.target?.value) >= 0 && Number(e?.target?.value) <= 100) {
      setRate({
        ...rateHead,
        rating: e?.target?.value,
      });
    }
  };
  const percent = Math.round((rateHead?.attendance / rateHead?.total) * 100);
  return (
    <div>
      {contextHolder}
      <div className="px-3 pt-6 mx-3">
        <Card className="">
          <Flex className="w-full" justify="space-between" wrap="wrap">
            {headValue?.map((dataReport: any, index: number) => (
              <div
                className={`${
                  index + 1 === headValue.length ? "" : "sm:border-r"
                } px-4 w-full sm:w-1/2 md:w-1/4 mb-2 xl:mb-0 xl:w-fit`}
                key={index}
              >
                <p className="w-full">{dataReport.label}</p>
                <p className="font-medium text-lg">{dataReport?.value}</p>
              </div>
            ))}
          </Flex>
        </Card>
      </div>
      <div className="mx-6 mt-6">
        <Card>
          <Flex
            className="w-full"
            align="center"
            wrap="wrap"
            justify="space-between"
          >
            <Flex className="w-full xl:w-2/3" align="center">
              <Typography.Text>Đánh giá chất lượng báo cáo:</Typography.Text>
              <FormInputCommon
                className="w-2/3 ml-2"
                type="number"
                placeholder="0"
                value={rateHead?.rating}
                max={100}
                onChange={handleChangeInput}
                addonAfter={<PercentageOutlined />}
              />
            </Flex>
            <Flex className="w-1/3">
              <Button
                className="btn btn-primary ml-2"
                onClick={handleSaveEvaluation}
              >
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Flex>
          </Flex>
        </Card>
      </div>
      <Flex className="px-3 pt-6 w-full">
        <Card className="w-full mx-3">
          <div className="border-b">
            <Typography.Title level={5}>Sĩ số điểm danh</Typography.Title>
          </div>
          <Flex className="w-full pt-6" wrap="wrap">
            <div className="border rounded-lg mr-6">
              <UserOutlined className="p-2 h-full px-3" />
            </div>
            <Flex align="baseline">
              <Typography.Text className="text-[38px] leading-[1]">
                {rateHead?.attendance}
              </Typography.Text>
              <Typography.Text>/{rateHead?.total}</Typography.Text>
              <Typography.Text>(sinh viên)</Typography.Text>
            </Flex>
          </Flex>
          <Progress percent={percent} className="pt-6" />
          <Typography.Text className="pt-4">
            Tỉ lệ phần trăm (%) sinh viên có mặt
          </Typography.Text>
        </Card>
        {/* <Card className="w-1/2 mx-3 !flex items-center rate-chart">
          <div className="border-b">
            <Typography.Title level={5}>
              Tỷ lệ sinh viên theo điểm danh
            </Typography.Title>
          </div>
          <Flex className="w-full pt-6" wrap="wrap">
            <Tooltip
              title="3 done / 3 in progress / 4 to do"
              className="w-full lg:w-1/3"
            >
              <Progress
                type="circle"
                percent={100}
                strokeWidth={15}
                size={140}
                strokeColor={conicColors}
                format={() => (
                  <div>
                    <Typography.Text>Sĩ số:</Typography.Text>
                    <Typography>100 học viên</Typography>
                  </div>
                )}
              />
            </Tooltip>
            <div className="w-full lg:w-2/3 pt-4">
              <Flex className="pb-3">
                <Badge
                  key={"#52C41A"}
                  color={"#52C41A"}
                  text={"Có mặt đầy đủ"}
                  className="w-1/2 pl-4"
                />
                <Flex className="w-1/2 pl-4 border-l">
                  <Typography.Text>90 học viên</Typography.Text>
                  <Typography.Text>90%</Typography.Text>
                </Flex>
              </Flex>
              <Flex className="pb-3">
                <Badge
                  key={"#FAAD14"}
                  color={"#FAAD14"}
                  text={"Vắng mặt 1 phần"}
                  className="w-1/2 pl-4"
                />
                <Flex className="w-1/2 pl-4 border-l">
                  <Typography.Text>90 Vắng mặt 1 phần</Typography.Text>
                  <Typography.Text>90%</Typography.Text>
                </Flex>
              </Flex>
              <Flex className="pb-3">
                <Badge
                  key={"#FF4D4F"}
                  color={"#FF4D4F"}
                  text={"Vắng mặt"}
                  className="w-1/2 pl-4"
                />
                <Flex className="w-1/2 pl-4 border-l">
                  <Typography.Text>90 Vắng mặt</Typography.Text>
                  <Typography.Text>90%</Typography.Text>
                </Flex>
              </Flex>
            </div>
          </Flex>
        </Card> */}
      </Flex>
      <CommonModal
        open={isOpen}
        title={"Xác nhận đánh giá chất lượng báo cáo"}
        desc={
          <Typography.Text>
            Đảm bảo rằng tất cả các{" "}
            <span className="text-[#FAAD14]">
              thông tin liên quan đã được kiểm tra và cập nhật đầy đủ
            </span>{" "}
            trước khi xác nhận lưu thông tin này
          </Typography.Text>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmEvaluation}
        closeModal={onCloseModal}
      />
    </div>
  );
};

export default HeadQualityEvaluation;
