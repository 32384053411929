import React, {FunctionComponent} from 'react';
import {Radio} from "antd";
import './form-radio.scss';

type FormRadioProps = {
  data?: any[],
  onChange?: () => void,
  defaultValue?: string | boolean
  value?: string | boolean
  disabled?: boolean
}

const FormRadio: FunctionComponent<FormRadioProps> = ({data, onChange, defaultValue, value, disabled}) => {
  return (
    <>
      <Radio.Group onChange={onChange} defaultValue={defaultValue} value={value} disabled={disabled}>
        {
          data?.map((d) => (
            <Radio.Button value={d.value} className="form-radio" key={d.label}>
              {d.label}
            </Radio.Button>
          ))
        }
      </Radio.Group>
    </>
  );
};

export default FormRadio;
