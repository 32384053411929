import React from 'react';
import {Col, Empty, Row, Spin, TableColumnsType} from "antd";
import StatisticalReportCard from '../../StatisticalReportCard';
import TableWithTotals from "../../../../../../components/table-with-totals/TableWithTotals";
import {createTableData} from "../../../../for-instructors/components/table/tableConvertData";
import {handleExportReport} from "../../../../../../utils/staticReport";
import {
  exportTableCourseFormatUniversityAction,
  exportTableMultiMediaUnit,
  exportTableTeacherAssignByInstruct,
  exportTableTestByIndustryGroup,
  exportTableUnitByIndustryGroup,
  exportTableUniversityReferenceSourceByType,
  exportTableUniversityScormUnit
} from "../../../../../../service/statistical-report";
import {staticReportStore} from '../../../../../../stores/stores';
import dayjs from "dayjs";

type ResouceTableProps = {
  loading: boolean,
  dataListCourseByFormatUniversity: Array<any>,
  dataListCourseByTeacherUniversity: Array<any>,
  dataListUnitByIndustryGroup: Array<any>,
  dataListUniversityTestByType: Array<any>,
  dataListUniversityReferenceSourceByType: Array<any>,
  dataListUniversityScormXapiUnit: Array<any>,
  dataListUniversityMultiMediaUnit: Array<any>
}

const ResourceTable = ({
                         loading,
                         dataListCourseByFormatUniversity,
                         dataListCourseByTeacherUniversity,
                         dataListUnitByIndustryGroup,
                         dataListUniversityTestByType,
                         dataListUniversityReferenceSourceByType,
                         dataListUniversityScormXapiUnit,
                         dataListUniversityMultiMediaUnit,
                       }: ResouceTableProps) => {
  const {filterValues} = staticReportStore();


  const tableColumn: TableColumnsType<any> = [
    {
      title: "Loại đánh giá",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count"
    },
  ]


  const tableCourseByDepartmentColumn: TableColumnsType<any> = [
    {
      title: "Khoa",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count"
    },
  ]

  const tableQuantityDataColumn: TableColumnsType<any> = [
    {
      title: "Định dạng tài liệu",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count"
    },
  ]


  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
  };

  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê khoá học
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 24}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khoá học theo hình thức xuất bản"
            content={
              <Spin spinning={loading}>
                {dataListCourseByFormatUniversity.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(dataListCourseByFormatUniversity).dataSource}
                    columns={[
                      {
                        title: "Khoa",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(dataListCourseByFormatUniversity).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableCourseFormatUniversityAction,
                    "Số lượng khoá học theo hình thức xuất bản.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableCourseFormatUniversityAction,
                    "Số lượng khoá học theo hình thức xuất bản.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khóa học theo giảng viên"
            content={
              <Spin spinning={loading}>
                {dataListCourseByTeacherUniversity.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(dataListCourseByTeacherUniversity).dataSource}
                    columns={[
                      {
                        title: "Giảng viên",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(dataListCourseByTeacherUniversity).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableTeacherAssignByInstruct,
                    "Số lượng khóa học theo giảng viên.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableTeacherAssignByInstruct,
                    "Số lượng khóa học theo giảng viên.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng bài giảng theo khoa"
            content={
              <Spin spinning={loading}>
                {dataListUnitByIndustryGroup.length > 0 ? (
                  <TableWithTotals
                    data={dataListUnitByIndustryGroup}
                    columns={tableCourseByDepartmentColumn}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUnitByIndustryGroup,
                    "Số lượng bài giảng theo khoa.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUnitByIndustryGroup,
                    "Số lượng bài giảng theo khoa.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng học liệu
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng bài kiểm tra"
            content={
              <Spin spinning={loading}>
                {dataListUniversityTestByType.length > 0 ? (
                  <TableWithTotals
                    data={dataListUniversityTestByType}
                    columns={tableColumn}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableTestByIndustryGroup,
                    "Số lượng bài kiểm tra.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableTestByIndustryGroup,
                    "Số lượng bài kiểm tra.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng tài liệu tham khảo"
            content={
              <Spin spinning={loading}>
                {dataListUniversityReferenceSourceByType.length > 0 ? (
                  <TableWithTotals
                    data={dataListUniversityReferenceSourceByType}
                    columns={tableQuantityDataColumn}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityReferenceSourceByType,
                    "Số lượng tài liệu tham khảo.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityReferenceSourceByType,
                    "Số lượng tài liệu tham khảo.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng Scorm & xAPI"
            content={
              <Spin spinning={loading}>
                {dataListUniversityScormXapiUnit.length > 0 ? (
                  <TableWithTotals
                    data={dataListUniversityScormXapiUnit}
                    columns={tableQuantityDataColumn}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityScormUnit,
                    "Số lượng Scorm & xAPI.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityScormUnit,
                    "Số lượng Scorm & xAPI.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng học liệu đa phương tiện"
            content={
              <Spin spinning={loading}>
                {dataListUniversityMultiMediaUnit.length > 0 ? (
                  <TableWithTotals
                    data={dataListUniversityMultiMediaUnit}
                    columns={tableQuantityDataColumn}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableMultiMediaUnit,
                    "Số lượng học liệu đa phương tiện.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableMultiMediaUnit,
                    "Số lượng học liệu đa phương tiện.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default ResourceTable;
