// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approval-tabs-heading {
  display: grid;
  grid-template-columns: 70% 30%;
  justify-items: space-between; }

.approval-tabs-container {
  grid-column: 1; }

.approval-custom-card {
  grid-column: 2; }

.approval-space {
  height: 45px;
  background-color: #fff; }

.approval-card-container {
  margin: 28px 0 0 16px; }

.sub-title-approval-custom-card {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000D9; }

.info-row-approval {
  margin: 12px 0; }
  .info-row-approval .label-row {
    font-size: 14px;
    font-weight: 500; }

.btn-approval {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 48% 48%;
  align-items: center;
  gap: 14px; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-approval-course-plan/components/detail-approval-plan/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,8BAA8B;EAC9B,4BAA4B,EAAA;;AAIhC;EACI,cAAc,EAAA;;AAGlB;EACI,cAAc,EAAA;;AAIlB;EACI,YAAY;EACZ,sBAAsB,EAAA;;AAG1B;EACI,qBAAqB,EAAA;;AAEzB;EAEA,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB,EAAA;;AAIhB;EACI,cAAc,EAAA;EADlB;IAGQ,eAAe;IACf,gBAAgB,EAAA;;AAGxB;EACI,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS,EAAA","sourcesContent":[".approval-tabs-heading {\n    display: grid;\n    grid-template-columns: 70% 30%; \n    justify-items: space-between;\n   \n}\n\n.approval-tabs-container {\n    grid-column: 1;\n}\n\n.approval-custom-card {\n    grid-column: 2;\n   \n}\n\n.approval-space{\n    height: 45px;\n    background-color: #fff;\n}\n\n.approval-card-container{\n    margin: 28px 0 0 16px;\n}\n.sub-title-approval-custom-card{\n   //styleName: Body/medium;\nfont-family: Roboto;\nfont-size: 14px;\nfont-weight: 400;\nline-height: 22px;\ntext-align: left;\ncolor: #000000D9;\n\n\n}\n.info-row-approval{\n    margin: 12px 0;\n    .label-row{\n        font-size: 14px;\n        font-weight: 500;\n    }\n}\n.btn-approval{\n    margin-top: 24px;\n    display: grid;\n    grid-template-columns: 48% 48%; \n    align-items: center;\n    gap: 14px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
