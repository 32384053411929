// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approval-tabs-heading {
  display: grid;
  grid-template-columns: 70% 30%;
  justify-items: space-between; }

.approval-tabs-container {
  grid-column: 1; }

.approval-custom-card {
  grid-column: 2; }

.approval-space {
  height: 45px;
  background-color: #fff; }

.approval-card-container {
  margin: 28px 0 0 16px; }

.sub-title-approval-custom-card {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  margin: 0 6px; }

.info-row-approval {
  margin: 12px; }

.btn-approval {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 48% 48%;
  align-items: center;
  gap: 14px; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-approval/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,8BAA8B;EAC9B,4BAA4B,EAAA;;AAIhC;EACI,cAAc,EAAA;;AAGlB;EACI,cAAc,EAAA;;AAIlB;EACI,YAAY;EACZ,sBAAsB,EAAA;;AAG1B;EACI,qBAAqB,EAAA;;AAEzB;EACI,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,gBAAgB;EAChB,aAAa,EAAA;;AAEjB;EACI,YAAY,EAAA;;AAEhB;EACI,gBAAgB;EAChB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,SAAS,EAAA","sourcesContent":[".approval-tabs-heading {\n    display: grid;\n    grid-template-columns: 70% 30%; \n    justify-items: space-between;\n   \n}\n\n.approval-tabs-container {\n    grid-column: 1;\n}\n\n.approval-custom-card {\n    grid-column: 2;\n   \n}\n\n.approval-space{\n    height: 45px;\n    background-color: #fff;\n}\n\n.approval-card-container{\n    margin: 28px 0 0 16px;\n}\n.sub-title-approval-custom-card{\n    font-size: 14px;\n    line-height: 22px;\n    color: rgba(0, 0, 0, 0.45);\n    text-align: left;\n    margin: 0 6px;\n}\n.info-row-approval{\n    margin: 12px;\n}\n.btn-approval{\n    margin-top: 24px;\n    display: grid;\n    grid-template-columns: 48% 48%; \n    align-items: center;\n    gap: 14px; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
