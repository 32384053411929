// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-section-publish {
  width: 100%;
  height: 100%; }
  .survey-managment-section-publish .form-input {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    flex: 1 0 !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/mooc-survey-publish/components/detail-survey-section/detailSurveySection.scss"],"names":[],"mappings":"AAAA;EACI,WAAY;EACZ,YAAY,EAAA;EAFhB;IAKQ,wBAA4B;IAC5B,8BAA8B;IAC9B,sBAA4B;IAC5B,oBAA+B,EAAA","sourcesContent":[".survey-managment-section-publish {\n    width : 100%;\n    height: 100%;\n\n    .form-input {\n        display    : flex !important;\n        align-items: center !important;\n        width      : 100% !important;\n        flex       : 1 0 0 o !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
