import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import PageHeader from "../../../components/page-header/PageHeader";
import "./list-user.scss";
import {
  Form,
  Layout,
  Space,
  TableProps,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import FilterTable from "./components/filter-table/FilterTable";
import { DataType } from "../../../types/listTrainingNeeds";
import { EyeOutlined } from "@ant-design/icons";
import FormFilter from "./components/filter-group/FormFilter";
import { Link, useNavigate } from "react-router-dom";
import TableData from "../../../components/table-data/TableData";
import { get } from "lodash";
import { validateFieldCreate } from "./constants/data";
import { useAuthStore, useSurveyRespond } from "../../../stores/stores";
import { createUser } from "../../../service/list-account-users/listAccountUsersApi";
import { HelpService } from "../../../service/helper.service";
import { routesConfig } from "../../../config/routes";
import { FormInstance } from "antd/lib";
import {
  getSurveyStudent,
  getListNumberSurveyStudent,
} from "../../../service/survey-managment/list-survey-student/listSurveyStudentApi";
import { ColumnType } from "antd/es/table";

type ListTrainingNeedProps = {
  title?: string;
};

const ListTrainingNeed: FunctionComponent<ListTrainingNeedProps> = ({
  title,
}) => {
  const helpService = new HelpService();
  const navigate = useNavigate();
  const userInfo = useAuthStore((state) => state.user);
  const [userId, setUserId] = useState<any>(userInfo?.id);
  const [tab, setTab] = useState<string>("1");
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [listSurveyStudent, setListSurveyStudent] = useState<DataType[]>([]);
  const [countTrueRecord, setCountTrueRecord] = useState<number>();
  const [countFalseRecord, setCountFalseRecord] = useState<number>();
  const { setSurveyData } = useSurveyRespond();
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");

  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: [],
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [form] = Form.useForm();

  const handleClickSurvey = (
    event: any,
    id: any,
    typeSurvey: any,
    theme: any,
    checkEnd: any
  ) => {
    event.preventDefault();
    setSurveyData([]); // Assuming setSurveyData is defined elsewhere in your component
    navigate(`${routesConfig.surveyRespond}/${id}/${typeSurvey}/${theme}`, {
      state: { checkEnd },
    });
  };

  const fetchData = async () =>
    await getSurveyStudent(
      {
        keyword: searchValue,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortField: fieldSortOrder,
        sortOrder: statusOrder,
      },
      userId,
      tab
    )
      .then((res) => {
        const { data, totalRecords } = res.data.data;
        setListSurveyStudent(data);
        setTotalRecords(totalRecords);
      })
      .finally(() => setLoading(false));

  useEffect(() => {
    fetchData();
  }, [pageSize, pageNumber, statusOrder]);

  useEffect(() => {
    const fetchData = async () =>
      await getListNumberSurveyStudent(userId).then((res) => {
        const { countNotIn, countIn } = res.data.data;
        setCountFalseRecord(countNotIn);
        setCountTrueRecord(countIn);
      });
    fetchData();
  }, []);

  const TableStatus: React.FC<{ text: any; color: any }> = ({
    text,
    color,
  }) => {
    return (
      <div className="table-status">
        <div className="color-icon" style={{ backgroundColor: color }}></div>
        {text}
      </div>
    );
  };

  const dataColumns = useMemo(() => {
    return [
      {
        title: "STT",
        dataIndex: "id",
        key: "id",
        width: 0.5,
        render: (_: any, record: any, index: any) => (
          <Typography.Text>{index + 1}</Typography.Text>
        ),
      },
      {
        title: "Tên đợt khảo sát",
        dataIndex: "name",
        key: "name",
        sorter: true,
        width: 4,
      },
      {
        title: "Trạng thái",
        dataIndex: "status",
        key: "status",
        render(_: any, record: any) {
          if (tab === "1") {
            const currentDate = Date.now();
            const surveyOpenDate = Date.parse(record.surveyOpen);
            const surveyCloseDate = Date.parse(record.surveyClose);
            if (!isNaN(surveyOpenDate) && !isNaN(surveyCloseDate)) {
              const isInSurveyPeriod =
                currentDate >= surveyOpenDate && currentDate <= surveyCloseDate;
              return isInSurveyPeriod ? (
                <TableStatus text="Đang mở" color="#52C41A" />
              ) : (
                <TableStatus text="Kết thúc" color="#1677FF" />
              );
            } else {
              return "Sai định dạng ngày";
            }
          } else if (tab === "2") {
            return record.status === 1 ? (
              <TableStatus text="Lưu nháp" color="#52C41A" />
            ) : (
              <TableStatus text="Đã gửi" color="#1677FF" />
            );
          }
        },
        width: 2.5,
      },
      {
        title: "Ngày bắt đầu",
        dataIndex: "surveyOpen",
        key: "surveyOpen",
        width: 2,
        render: (date: any) => convertDate(date),
      },
      {
        title: "Ngày kết thúc",
        dataIndex: "surveyClose",
        key: "surveyClose",
        width: 2,
        render: (date: any) => convertDate(date),
      },
      {
        title: "",
        dataIndex: "action",
        key: "operation",
        render: (_: any, record: any) => {
          const checkEnd =
            Date.now() >= Date.parse(record.surveyClose) ? true : false;
          const location = {
            id: `${record.surveyId}`,
            typeSurvey: `${record.status}`,
            themeSurvey: `${record.surveyTheme}`,
          };

          return (
            <Space>
              <Link
                className="action-table"
                to={`${routesConfig.surveyRespond}/${location.id}/0`}
                onClick={(e) =>
                  handleClickSurvey(
                    e,
                    location.id,
                    location.typeSurvey,
                    location.themeSurvey,
                    checkEnd
                  )
                }
              >
                <EyeOutlined />
              </Link>
            </Space>
          );
        },
        fixed: "right",
        width: 0.5,
      },
    ];
  }, [tab]);

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const convertDate = (date: string) => {
    const dateConvert = new Date(date);
    const day = dateConvert.getDate();
    const month = dateConvert.getMonth() + 1;
    const year = dateConvert.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const newColumns = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleClose = () => {
    form.resetFields();
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getSurveyStudent(
        {
          keyword: searchValue,
          sortField: fieldSortOrder,
          sortOrder: statusOrder,
        },
        userId,
        tab
      )
        .then((users) => {
          const { data, totalRecords } = users.data.data;
          setListSurveyStudent(data);
          setTotalRecords(totalRecords);
        })
        .catch((err) => console.error(err));
    }
  };

  const handleSubmitSearch = () => {
    getSurveyStudent(
      {
        keyword: searchValue,
        sortField: fieldSortOrder,
        sortOrder: statusOrder,
      },
      userId,
      tab
    )
      .then((users) => {
        const { data, totalRecords } = users.data.data;
        setListSurveyStudent(data);
        setTotalRecords(totalRecords);
      })
      .catch((err) => console.error(err));

    if (searchValue === "") {
      setPageNumber(1);
    }
  };

  const clearFilter = (formInstance: FormInstance) => {
    setLoading(true);
    getSurveyStudent({}, userId, tab)
      .then((users) => {
        const { data, totalRecords } = users.data.data;
        setListSurveyStudent(data);
        setLoading(false);
        formInstance.resetFields();
        setTotalRecords(totalRecords);
      })
      .catch((err) => console.error(err));
  };

  const handleFinish = (values: any) => {
    setLoading(true);
    getSurveyStudent(
      {
        surveyOpen: get(values, "surveyOpen", null),
        surveyClose: get(values, "surveyClose", null),
        sortField: fieldSortOrder,
        sortOrder: statusOrder,
      },
      userId,
      tab
    )
      .then((res) => {
        const { data, totalRecords } = res.data.data;
        setListSurveyStudent(data);
        setTotalRecords(totalRecords);
      })
      .finally(() => setLoading(false));
  };

  const onFinishCreate = (values: any) => {
    createUser(values)
      .then((res) => {
        const { data, statusCode } = res.data;
        if (statusCode === 200) {
          helpService.successMessage(
            get(data, "message", "Tạo tài khoản thành công")
          );
          form.resetFields();
          fetchData();
          handleClose();
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    if (sorter.field === "name") {
      setFieldSortOrder("survey_name");
    }
  };

  const listBreadcrumb = [
    {
      title: "Khảo sát nhu  cầu đào tạo",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setPageNumber(1);
    form.resetFields();
  };

  useEffect(() => {
    clearFilter(form);
  }, [tab]);

  const TabLabel = ({ count, text }: { count: any; text: any }) => (
    <div>
      {text}
      <span id="border-count">{count}</span>
    </div>
  );

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: <TabLabel count={countFalseRecord} text="Chờ tham gia" />,
      children: (
        <div className="waiting-assignment">
          <TableData
            dataTable={listSurveyStudent}
            dataColumns={dataColumns as ColumnType<any>[]}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <TabLabel count={countTrueRecord} text="Đã tham gia" />,
      children: (
        <div className="assigned">
          <TableData
            dataTable={listSurveyStudent}
            dataColumns={dataColumns as ColumnType<any>[]}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  return (
    <Layout className="survey-managment-traning">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createUser") {
            const { createUser } = forms;
            const data = createUser.getFieldsValue(validateFieldCreate);
            onFinishCreate(data);
          }
        }}
      >
        <div
          className={`survey-managment-traning--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              searchValue={searchValue}
              dataColumns={dataColumns as ColumnType<any>[]}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>

        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default ListTrainingNeed;
