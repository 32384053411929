import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row, TreeDataNode, Typography, Space, Card } from "antd";
import { CarryOutOutlined, PlusOutlined } from "@ant-design/icons";

import DetailChapter from "./detail-chapter/DetailChapter";
import {
  TypeCourseStructure,
  TypeChapterList,
  TypeSubjectList,
} from "../../../../../../../types/course-plan-managment/mooc-course-construction-plan";

interface CourseStructureProps {
  dataCourseStructure: TypeChapterList[];
  setDataCourseStructure: (data: TypeChapterList[]) => void;
  typeAssignment: any;
}

const CourseStructure: React.FC<CourseStructureProps> = ({
  dataCourseStructure,
  setDataCourseStructure,
  typeAssignment,
}) => {
  const treeData: TreeDataNode[] = [
    {
      title: (
        <Row>
          <div className="card-heading--info">
            <Typography.Title style={{ fontSize: 17 }}>
              Chương 1: Giới thiệu
            </Typography.Title>
            <Typography.Text className="sub-title" style={{ fontSize: 15 }}>
              Lịch trình: 15/12/2023 ; 09:00 - 11:00
            </Typography.Text>
          </div>
        </Row>
      ),
      key: "0-0",
      // icon: <MenuUnfoldOutlined style={{ marginRight: 20 }} />,
      children: [
        {
          title: (
            <div
              // onClick={}
              // className="w-full button-sticky"
              style={{ display: "flex", alignContent: "center" }}
            >
              <PlusOutlined style={{ fontSize: 20, marginRight: 20 }} />
              <Typography.Text className="sub-title" style={{ fontSize: 15 }}>
                Thêm bài tập
              </Typography.Text>
            </div>
            // <div className="card-heading--info">
            //   <Typography.Title className="sub-title">
            //     Chương 1: Giới thiệu
            //   </Typography.Title>
            // </div>
          ),
          // switcherIcon: false,
          style: { marginTop: 10 },
          key: "0-0-0",
          // icon: <CarryOutOutlined />,
          children: [
            {
              title: (
                <div
                  // onClick={}
                  // className="w-full button-sticky"
                  style={{ display: "flex", alignContent: "center" }}
                >
                  <Typography.Text
                    className="sub-title"
                    style={{ fontSize: 15, marginLeft: 20 }}
                  >
                    Thêm học liệu
                  </Typography.Text>
                </div>
              ),
              key: "0-0-0-0",
              icon: <CarryOutOutlined />,
              style: { marginTop: 10 },
            },

            {
              title: (
                <div
                  // onClick={}
                  // className="w-full button-sticky"
                  style={{ display: "flex", alignContent: "center" }}
                >
                  <Typography.Text
                    className="sub-title"
                    style={{ fontSize: 15, marginLeft: 20 }}
                  >
                    Thêm học liệu
                  </Typography.Text>
                </div>
              ),
              key: "0-0-0-2",
              icon: <CarryOutOutlined />,
              style: { marginTop: 10 },
            },
          ],
        },
      ],
    },
  ];
  const [expandAll, setExpandAll] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const handleClickExpand = () => {
    setExpandAll(!expandAll);
  };
  useEffect(() => {
    if (expandAll) {
      setExpandedKeys(["0-0", "0-0-0"]);
    } else {
      setExpandedKeys([]);
    }
  }, [expandAll]);
  const onSelect = (selectedKeys: React.Key[], info: any) => {};

  const updateChapterList = (newChapterList: TypeChapterList[]) => {
    setDataCourseStructure(newChapterList);
  };

  const handleAddNewChapter = () => {
    const newChapter: TypeChapterList = {
      structureItemName: "Chương mới",
      structureItemLevel: 1,
      orderNumber: dataCourseStructure ? dataCourseStructure.length + 1 : 1, // Tính toán số thứ tự chương mới
      subjectList: [
        {
          structureItemName: "Tên bài giảng",
          structureItemLevel: 2,
          orderNumber: 1,
          lessonList: [
            {
              structureItemName: "Tên học liệu",
              structureItemLevel: 3,
              orderNumber: 1,
            },
          ],
        },
      ],
    };

    // Thêm chương mới vào danh sách chapterList
    const newChapterList = [...(dataCourseStructure || []), newChapter];

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(newChapterList);
  };

  const [isShowDetailAll, setIsShowDetailAll] = useState<boolean>(true);
  const handleToggleShowDetailAll = () => {
    setIsShowDetailAll(!isShowDetailAll);
  };

  return (
    <Space size="middle" direction="vertical" style={{ width: "100%" }}>
      <Card style={{ marginBottom: "20px", minWidth: "680px" }}>
        <Row justify="space-between" style={{ marginTop: "20px" }}>
          <Col>
            <Row>
              <Typography.Title level={4}>Cấu trúc khóa học</Typography.Title>
            </Row>
            <Row>
              <Typography.Text style={{ color: "GrayText" }}>
                Xây dựng cấu trúc của khoá học
              </Typography.Text>
            </Row>
          </Col>
          <Col>
            <Button onClick={handleToggleShowDetailAll}>
              {isShowDetailAll ? "Thu gọn" : "Hiển thị chi tiết"}
            </Button>
          </Col>
        </Row>
      </Card>

      <DetailChapter
        // chapterList={chapterList}
        chapterList={dataCourseStructure}
        updateChapterList={updateChapterList}
        isShowDetailAll={isShowDetailAll}
        setIsShowDetailAll={setIsShowDetailAll}
      />

      {/* thêm chương */}
      <>
        <Button
          type="primary"
          onClick={handleAddNewChapter}
          className="w-full button-sticky"
        >
          <PlusOutlined />
          Thêm chương
        </Button>
      </>
    </Space>
  );
};

export default CourseStructure;
