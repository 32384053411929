import { Document, Packer, Paragraph, Table, TableCell, TableRow, TextRun } from "docx";
import { saveAs } from "file-saver";

export const generateDocx = (data: any,headers: string[],fileName: string) => {

  // Tạo các ô tiêu đề
  const headerRow = new TableRow({
    children: headers.map(header => new TableCell({
      children: [new Paragraph({ children: [new TextRun({ text: header, bold: true })] })],
    })),
  });

  // Tạo các hàng dữ liệu
  const dataRows = data.map((item: any) => {
    return new TableRow({
      children: Object.values(item).map((value: any) => new TableCell({
        children: [new Paragraph(value)],
      })),
    });
  });

  // Tạo bảng
  const table = new Table({
    rows: [headerRow, ...dataRows],
  });

  // Tạo tài liệu
  const doc = new Document({
    sections: [{
      children: [table],
    }],
  });

  // Tạo file DOCX và tải xuống
  Packer.toBlob(doc).then((blob: any) => {
    saveAs(blob, fileName);
  });
};
