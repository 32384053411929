interface CircleProps {
  color: string;
}
export const Circle: React.FC<CircleProps> = ({ color }) => {
  const circleStyle = {
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    backgroundColor: color,
    display: "inline-block",
    margin: "2px",
  };

  return <div style={circleStyle}></div>;
};
