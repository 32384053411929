import {CaretUpOutlined} from "@ant-design/icons";
import {Card, Col, Empty, Row, Spin, Statistic} from "antd";
import {get} from "lodash";
import React, {useEffect, useState} from "react";
import "./overview-chart-tab.scss";
import StatisticalReportCard from "../../StatisticalReportCard";
import ComboDualAxesChart from "../../../../../../components/types-of-charts/ComboDualAxesChart";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import { BarStackedChartProps, DualChart, PieChart } from "../../../../../../types/static-report";
import useChartExport from "../../../../for-instructors/components/chart/useChartExport";
import { staticReportStore } from "../../../../../../stores/stores";

type Props = {
  loading: Record<string, boolean>;
  visitsAndVisitors: DualChart[];
  visitsAndVisitorsByRegister: PieChart[];
  synthesizeUniversityList: Array<any>,
  teacherByUniversity: BarStackedChartProps[],
  studentByUniversity: BarStackedChartProps[],
  courseByUniversitySponsor: BarStackedChartProps[],
  unitResources: Array<any>,
};

const OverviewChartTab = (props: Props) => {
  const {
    loading,
    visitsAndVisitors,
    visitsAndVisitorsByRegister,
    synthesizeUniversityList,
    teacherByUniversity,
    studentByUniversity,
    courseByUniversitySponsor,
    unitResources,
  } = props;

  const [dataStatistical, setDataStatistical ] = useState({
    totalVisit: 0,
    rateVisit: 0,
    totalLecturer: 0,
    rateLecturer: 0,
    totalStudent: 0,
    rateStudent: 0,
    totalCourse: 0,
    rateCourse: 0,
    totalResource: 0,
    rateResource: 0,
  });

  // Hook for managing chart exports
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  useEffect(() => {
    const visit: any = synthesizeUniversityList.find((item: any) => item?.criteria === "Lượt truy cập");
    const lecturer: any = synthesizeUniversityList.find((item: any) => item?.criteria === "Giảng viên");
    const student: any = synthesizeUniversityList.find((item: any) => item?.criteria === "Sinh viên");
    const course: any = synthesizeUniversityList.find((item: any) => item?.criteria === "Khóa học");
    const resource: any = synthesizeUniversityList.find((item: any) => item?.criteria === "Tài nguyên");
    
    setDataStatistical({
      totalVisit: visit?.count,
      rateVisit: typeof visit?.percentage === 'number' ? visit.percentage.toFixed(2) : '0',
      totalLecturer: lecturer?.count,
      rateLecturer: typeof lecturer?.percentage === 'number' ? lecturer.percentage.toFixed(2) : '0',
      totalStudent: student?.count,
      rateStudent: typeof student?.percentage === 'number' ? student.percentage.toFixed(2) : '0',
      totalCourse: course?.count,
      rateCourse: typeof course?.percentage === 'number' ? course.percentage.toFixed(2) : '0',
      totalResource: resource?.count,
      rateResource: typeof resource?.percentage === 'number' ? resource.percentage.toFixed(2) : '0',
    });
  }, [synthesizeUniversityList])

  const renderStatistical = () => {
    return (
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}} className="row-staticstical-chart">
        <Col xs={{span: 24}} className="col-staticstical-chart">
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Lượt truy cập"
              value={get(dataStatistical, 'totalVisit', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span className="percentage-difference">{get(dataStatistical, 'rateVisit', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col xs={{span: 24}} className="col-staticstical-chart">
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Giảng viên"
              value={get(dataStatistical, 'totalLecturer', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateLecturer', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col xs={{span: 24}} className="col-staticstical-chart">
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Học viên"
              value={get(dataStatistical, 'totalStudent', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateStudent', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col xs={{span: 24}} className="col-staticstical-chart">
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Khóa học"
              value={get(dataStatistical, 'totalCourse', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateCourse', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col xs={{span: 24}} className="col-staticstical-chart">
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Tài nguyên"
              value={get(dataStatistical, 'totalResource', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateResource', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
      </Row>
    )
  }
  return (
    <>
      {renderStatistical()}
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt truy cập và số người truy cập"
            content={
              <Spin spinning={loading.visitsAndVisitors}>
                {visitsAndVisitors.length > 0 ? (
                  <ComboDualAxesChart
                    data={visitsAndVisitors}
                    columnName={`Số người truy cập`}
                    columnColor="#69C0FF"
                    lineName={`Số lượt truy cập`}
                    lineColor="#FF7875"
                    hasTotal={false}
                    ref={createChartRef(tab, "visitsAndVisitors")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                handleExportImage(tab, "visitsAndVisitors", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                handleExportImage(
                  tab,
                  "visitsAndVisitors",
                  "application/pdf"
                ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Đối tượng truy cập"
            content={
              <Spin spinning={loading.visitsAndVisitorsByRegister}>
                {visitsAndVisitorsByRegister.length > 0 ? (
                  <BarChart
                    data={visitsAndVisitorsByRegister}
                    colors={["#69C0FF", "#FFC069"]}
                    showLegend={true}
                    hasTotal={false}
                    ref={createChartRef(tab, "visitsAndVisitorsByRegister")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                handleExportImage(tab, "visitsAndVisitorsByRegister", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                handleExportImage(
                  tab,
                  "visitsAndVisitorsByRegister",
                  "application/pdf"
                ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng giảng viên theo trình độ học vấn"
            content={
              <Spin spinning={loading.teacherByUniversity}>
                {teacherByUniversity.length > 0 ? (
                  <BarStackedChart
                    data={teacherByUniversity}
                    colors={["#FFC069", "#5CDBD3", "#69C0FF"]}
                    hasTotal={true}
                    ref={createChartRef(tab, "teacherByUniversity")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                handleExportImage(tab, "teacherByUniversity", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                handleExportImage(
                  tab,
                  "teacherByUniversity",
                  "application/pdf"
                ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng học viên"
            content={
              <Spin spinning={loading.studentByUniversity}>
                {studentByUniversity.length > 0 ? (
                  <BarStackedChart
                    data={studentByUniversity}
                    colors={["#5CDBD3", "#69C0FF"]}
                    hasTotal={true}
                    ref={createChartRef(tab, "studentByUniversity")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                handleExportImage(tab, "studentByUniversity", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                handleExportImage(
                  tab,
                  "studentByUniversity",
                  "application/pdf"
                ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khóa học"
            content={
              <Spin spinning={loading.courseByUniversitySponsor}>
                {courseByUniversitySponsor.length > 0 ? (
                  <BarStackedChart
                    data={courseByUniversitySponsor}
                    colors={["#5CDBD3", "#69C0FF"]}
                    hasTotal={true}
                    ref={createChartRef(tab, "courseByUniversitySponsor")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                handleExportImage(tab, "courseByUniversitySponsor", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                handleExportImage(
                  tab,
                  "courseByUniversitySponsor",
                  "application/pdf"
                ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng tài nguyên"
            content={
              <Spin spinning={loading.unitResources}>
                {unitResources.length > 0 ? (
                  <DonutChart
                    data={unitResources}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#69C0FF", "#5CDBD3", "#FFC069", "#FF7875"]}
                    ref={createChartRef(tab, "unitResources")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                handleExportImage(tab, "unitResources", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                handleExportImage(
                  tab,
                  "unitResources",
                  "application/pdf"
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default OverviewChartTab;
