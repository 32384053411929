import React from 'react'

export default function BlogHeaderLink(props: any) {

  const gotoBlogPage = () => {
    window.open(`/${props.uuid}`, "_blank")
  }
  return (
    <a className="text-white hover:bg-red-100 hover:text-red-700 p-4 rounded" onClick={gotoBlogPage}>Blog</a>
  )
}
