import { RightOutlined } from "@ant-design/icons";
import { Flex } from "antd";
import React, { ReactNode, useState } from "react";
import "./style.scss";

interface SingleMenuCustomProps {
  title?: string | ReactNode;
  children?: ReactNode;
}

function SingleMenuCustom({ title, children = <></> }: SingleMenuCustomProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <div className="single-menu-custom">
      <Flex
        className="menu-title"
        align="center"
        gap={8}
        onClick={() => setIsOpen(!isOpen)}
      >
        <RightOutlined
          className={`menu-icon-arrow ${isOpen ? "menu-custom-icon-open" : ""}`}
        />
        <div className="text-20">{title}</div>
      </Flex>
      {isOpen && <div className="menu-content">{children}</div>}
    </div>
  );
}

export default SingleMenuCustom;
