import { Flex } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { QuizAnsProps } from "../../../../../../types/scoring-course";
import { isArray } from "lodash";

function ShortText({ data, showCorrect = true, result }: QuizAnsProps) {
  const [isExpend, setIsExpend] = useState<boolean>(false);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const currentAnsOfUser = result?.results[0];
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (contentRef.current && countLines(contentRef.current) > 2) {
      setShowFooter(true);
    }
  }, [contentRef.current, currentAnsOfUser]);

  function countLines(element: HTMLElement | null): number {
    if (element) {
      const style = window.getComputedStyle(element);
      let lineHeight: number = parseFloat(style.lineHeight);

      // Trường hợp line-height là "normal"
      if (isNaN(lineHeight)) {
        const tempSpan: HTMLSpanElement = document.createElement("span");
        tempSpan.className = "hidden";
        tempSpan.innerText = "M";
        element.appendChild(tempSpan);
        lineHeight = tempSpan.offsetHeight;
        element.removeChild(tempSpan);
      }

      const totalHeight: number = element.clientHeight;
      return Math.round(totalHeight / lineHeight);
    }
    return 0;
  }

  return (
    <div>
      {data?.questions &&
        showCorrect &&
        data?.questions?.map((quiz: any, index: number) => (
          <p key={index} className={`font-size-16 line-height-24 text-result`}>
            {quiz?.content || "Không có câu trả lời"}
          </p>
        ))}

      {isArray(currentAnsOfUser) && (
        <div className="text-answer-wrap">
          <div
            ref={contentRef}
            id="quiz-content-text"
            className={`quiz-content-text ${!isExpend ? "three-line" : ""}`}
          >
            {currentAnsOfUser[0]?.contents[0]}
          </div>
          {showFooter && (
            <Flex justify="flex-end">
              <div
                className="quiz-btn-collapse text-primary pointer mt-1"
                onClick={() => setIsExpend(!isExpend)}
              >
                {isExpend
                  ? "Thu gọn câu trả lời"
                  : "Hiển thị đầy đủ câu trả lời"}
              </div>
            </Flex>
          )}
        </div>
      )}
    </div>
  );
}

export default ShortText;
