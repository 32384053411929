import React, {useEffect, useMemo, useState, useTransition} from "react";
import {BarStackedChartProps, PieChart} from "../../../../../types/static-report";
import {staticReportStore} from "../../../../../stores/stores";
import dayjs from "dayjs";
import {
  getCourseByFormatUniversity,
  getCourseByIndustryGroup,
  getSynthesizeCourseUniversity,
  getUnitByIndustryGroup,
  getUniversityMultiMediaUnit,
  getUniversityReferenceSourceByType,
  getUniversityScormXapiUnit,
  getUniversityTestByType
} from "../../../../../service/statistical-report";
import ResourceChartTab from "../chart/resource-tab/ResourceChartTab";
import ResourceTable from "../table/resource-table";
import {debounce} from "lodash";

type Props = {
  dataType: string
};

const ResourceTab = ({dataType}: Props) => {

  const {staticReportType, filterValues, tab} = staticReportStore();
  const [isPending, startTransition] = useTransition();
  const [chartData, setChartData] = useState({
    courseFormatUniversityList: [] as BarStackedChartProps[],
    courseByIndustry: [] as PieChart[],
    unitByIndustry: [] as PieChart[],
    universityByTestType: [] as PieChart[],
    universityReferenceSource: [] as PieChart[],
    universityScormXapiUnit: [] as PieChart[],
    universityMultiMediaUnit: [] as PieChart[],
    synthesizeCourseUniversity: [] as Array<any>,
  });

  const [loading, setLoading] = useState({
    courseFormatUniversityList: false,
    courseByIndustry: false,
    unitByIndustry: false,
    universityByTestType: false,
    universityReferenceSource: false,
    universityScormXapiUnit: false,
    universityMultiMediaUnit: false,
  });

  const fetchDataChart = async () => {
    const payload = {
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: filterValues?.universityIds,
      timeUnit: filterValues.timeUnit,
    };

    const listApi = [
      {
        key: 'courseFormatUniversityList',
        api: getCourseByFormatUniversity,
        params: payload,
      },
      {
        key: 'courseByIndustry',
        api: getCourseByIndustryGroup,
        params: payload,
      },
      {
        key: 'unitByIndustry',
        api: getUnitByIndustryGroup,
        params: payload,
      },
      {
        key: 'universityByTestType',
        api: getUniversityTestByType,
        params: payload,
      },
      {
        key: 'universityReferenceSource',
        api: getUniversityReferenceSourceByType,
        params: payload,
      },
      {
        key: 'universityScormXapiUnit',
        api: getUniversityScormXapiUnit,
        params: payload,
      },
      {
        key: 'universityMultiMediaUnit',
        api: getUniversityMultiMediaUnit,
        params: payload,
      },
      {
        key: 'synthesizeCourseUniversity',
        api: getSynthesizeCourseUniversity,
        params: payload,
      },
    ]

    await Promise.allSettled(
      listApi.map(async ({key, api, params}) => {
        setLoading((prev) => ({ ...prev, [key]: true }));
        try {
          const response = await api(params);
          startTransition(() => {
            setChartData((prevState) => ({
              ...prevState,
              [key]: response?.data?.data?.statistic || response?.data?.data,
            }));
          })
        } catch (error) {
          console.error(`Error fetching data for ${key}:`, error);
          startTransition(() => {
            setChartData(prevState => ({
              ...prevState,
              [key]: [], // Provide fallback empty data
            }));
          });
        } finally {
          setLoading((prev) => ({ ...prev, [key]: false }));
        }
      })
    );
  }

  const debouncedFetchData = useMemo(
    () => debounce(fetchDataChart, 500),
    [filterValues]
  );

  useEffect(() => {
    if (tab === "2") {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  const memoizedChartData = useMemo(() => {
    return {
      courseFormatUniversityList: chartData.courseFormatUniversityList,
      courseByIndustry: chartData.courseByIndustry,
      unitByIndustry: chartData.unitByIndustry,
      universityByTestType: chartData.universityByTestType,
      universityReferenceSource: chartData.universityReferenceSource,
      universityScormXapiUnit: chartData.universityScormXapiUnit,
      universityMultiMediaUnit: chartData.universityMultiMediaUnit,
      synthesizeCourseUniversity: chartData.synthesizeCourseUniversity,
    };
  }, [chartData]);

  return (
    (dataType === "1") ?
      <ResourceChartTab  {...memoizedChartData} loading={loading} /> :
      <ResourceTable {...memoizedChartData} loading={isPending}/>
  )
};

export default ResourceTab;

