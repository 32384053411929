import axios from "axios";
import { useAuthStore } from "../../stores/stores";
import { message } from "antd";
import { HelpService } from "../../service/helper.service";
export const BASE_URL = process.env.REACT_APP_BASE_API_URL?.replaceAll('"', "");
export const configApiWithoutNotify = axios.create({
  baseURL: `${BASE_URL}v2`,
  timeout: 15000,
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
  },
});

configApiWithoutNotify.interceptors.request.use(
  (config) => {
    const accessToken = useAuthStore.getState().accessToken;
    if (!config.headers["Authorization"] || accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

configApiWithoutNotify.interceptors.response.use(
  (response) => response,
  async (error) => {
    const prevRequest = error?.config;
    if (error?.response?.status === 403 && !prevRequest?.sent) {
      prevRequest.sent = true;
      try {
        const refreshToken = useAuthStore.getState().refreshToken;
        const accessToken = useAuthStore.getState().accessToken;
        if (refreshToken && accessToken) {
          prevRequest.headers["Authorization"] = `Bearer ${accessToken}`;
          return configApiWithoutNotify(prevRequest);
        }
      } catch (error) {
        location.replace("/login");
      }
    } else if (
      error?.response?.status === 401 ||
      error?.response?.status === 503
    ) {
      const logout = useAuthStore.getState().logout;
      logout();
    }
    return Promise.reject(error);
  }
);
