// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme__1 {
  background-color: #969D94; }

.theme__2 {
  background-color: #969D94; }

.theme__3 {
  background-color: #A5C59C; }

.theme__4 {
  background-color: #869D80; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/common/style/theme/index.scss"],"names":[],"mappings":"AAAA;EACI,yBAAyB,EAAA;;AAI7B;EACI,yBAAyB,EAAA;;AAG5B;EACI,yBAAyB,EAAA;;AAI7B;EACC,yBAAyB,EAAA","sourcesContent":[".theme__1{\n    background-color: #969D94;\n    // color: #673AB7;\n\n}\n.theme__2{\n    background-color: #969D94;\n    // color: #673AB7;\n }\n .theme__3{\n     background-color: #A5C59C;\n    //  color: #53A940;\n \n }\n .theme__4{\n  background-color: #869D80;\n//   color: #1B7C6B;\n//    color: red;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
