import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import DrawerCustom from "../../../../components/drawer/Drawer";
import CommonModal from "../../../../components/modal/common";
import ElementStatistical from "./ElementStatistical";
import { TrendsType } from "../../../../constants";
import {
  createLearningTrendsReport,
  updateLearningTrendsReport,
} from "../../../../service/prediction-model";
import { useParams } from "react-router-dom";

interface IProps {
  isOpenForm: boolean;
  handleClose: any;
  handleNotice: any;
  handleRefresh: () => void;
  isEdit?: boolean;
  fieldsOptionInput?: any;
  fieldsOptionAction?: any;
}
const FormCreateStatistical = ({
  isOpenForm,
  handleClose,
  handleNotice,
  handleRefresh,
  fieldsOptionInput,
  fieldsOptionAction,
  isEdit,
}: IProps) => {
  const [isConfirm, setConfirm] = useState<boolean>(false);
  const [fieldsInput, setFieldsInput] = useState<any>({});
  const [fieldsAction, setFieldsAction] = useState<any>({});
  const params = useParams();

  const [element, setElement] = useState<any>({
    infoInput: isEdit,
    infoAction: isEdit,
    name: "",
  });

  const handleCancel = () => {
    setFieldsInput({});
    setFieldsAction({});
    if (!isEdit) {
      setElement({
        infoInput: false,
        infoAction: false,
        name: "",
      });
    }
    handleClose();
  };

  const handleConfirmCreate = async () => {
    const fieldsActionConvert = [];
    for (const key in fieldsAction) {
      if (fieldsAction[key] === true) {
        fieldsActionConvert.push(Number(key));
      }
    }
    const fieldsInputConvert = [];
    for (const key in fieldsInput) {
      if (fieldsInput[key] === true) {
        fieldsInputConvert.push(Number(key));
      }
    }
    const paramsCreate = {
      name: element?.name,
      type:
        element?.infoInput && element?.infoAction
          ? TrendsType.BOTH
          : element?.infoInput && !element?.infoAction
          ? TrendsType.INFO
          : TrendsType.BEHAVIOR,
      data: [
        {
          fields: element?.infoInput ? fieldsInputConvert : [],
          group_id: 1,
        },
        {
          fields: element?.infoAction ? fieldsActionConvert : [],
          group_id: 2,
        },
      ],
    };
    try {
      if (isEdit) {
        await updateLearningTrendsReport(params?.id || "", paramsCreate);
      } else {
        await createLearningTrendsReport(paramsCreate);
        setFieldsInput({});
        setFieldsAction({});
        setElement({
          name: "",
          infoInput: false,
          infoAction: false,
        });
      }
      handleNotice("success", "Lưu thông tin thành công");
      setConfirm(false);
      handleRefresh();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenSave = () => {
    if (!element?.infoInput && !element?.infoAction) {
      handleNotice(
        "error",
        "Chọn ít nhất 1 yếu tố thống kê cài đặt mô hình phân tích xu hướng"
      );
    } else if (element?.name === "" && !isEdit) {
      handleNotice("error", "Tên danh mục cài đặt là bắt buộc");
    } else {
      setConfirm(true);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setFieldsInput(fieldsOptionInput);
      setFieldsAction(fieldsOptionAction);
    }
  }, [isOpenForm]);
  return (
    <DrawerCustom
      title={isEdit ? "Thêm yếu tố" : "Tạo yếu tố thông kê"}
      open={isOpenForm}
      onClose={handleCancel}
      extraNode={
        <Space>
          <>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={handleOpenSave}
            >
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </>
        </Space>
      }
      widthDrawer={730}
    >
      <ElementStatistical
        element={element}
        setElement={setElement}
        setFieldsInput={setFieldsInput}
        fieldsInput={fieldsInput}
        setFieldsAction={setFieldsAction}
        fieldsAction={fieldsAction}
        isEdit={isEdit}
      />
      <CommonModal
        open={isConfirm}
        title={"Xác nhận lưu thông tin"}
        desc={<div>Bạn có chắc chắn muốn lưu những nội dung đã cài đặt?</div>}
        closeIcon={false}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#1677FF", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmCreate}
        closeModal={() => setConfirm(false)}
      />
    </DrawerCustom>
  );
};

export default FormCreateStatistical;
