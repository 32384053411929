import { Button, Col, ConfigProvider, DatePicker, Form, FormInstance, Row, Typography } from 'antd'
import React from 'react'
import FormItemDropdown from '../../../form-dropdown/FormItemDropdown'
import {useForm} from "antd/es/form/Form";
import { DeleteOutlined, FilterOutlined } from '@ant-design/icons'
import locale from 'antd/locale/vi_VN';
import form from 'antd/es/form';

const { RangePicker } = DatePicker;

type FilterFormProps = {
    showFilter?: boolean
    onFinish?: (values: any) => void;
    clearFilter: (form: FormInstance) => void;
}
  
const FilterForm = ({showFilter, onFinish, clearFilter}: FilterFormProps) => {
    const [form] = useForm()
    return (
        // <ConfigProvider locale={locale}>
        <>
        <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
            <Form name="filterForm" onFinish={onFinish}>
            <Row gutter={5}>
                <Col
                span={4}
                >   
                    <Typography.Title level={5}>Chủ đề</Typography.Title>
                    <Form.Item name="">
                        <FormItemDropdown
                        modeDropdown="multiple"
                        placeholder="Chọn chủ đề"
                        />
                    </Form.Item>
                </Col>

                <Col
                span={4}
                >
                    <Typography.Title level={5}>Phân cấp</Typography.Title>
                    <Form.Item name="">
                        <FormItemDropdown
                            modeDropdown="multiple"
                            placeholder="Chọn phân cấp"
                            options ={[
                                {
                                    name: 'Cấp bộ',
                                    code: 'tmp1'
                                },
                                {
                                    name: 'Cấp trường',
                                    code: 'tmp2'
                                },
                                {
                                    name: 'Cấp giảng viên',
                                    code: 'tmp3'
                                },
                            ]}
                        />
                    </Form.Item>
                </Col>

                <Col
                span={4}
                >   
                    <Typography.Title level={5}>Đơn vị xuất bản</  Typography.Title>
                    <Form.Item name="">
                        <FormItemDropdown
                        modeDropdown="multiple"
                        placeholder="Chọn đơn vị xuất bản"
                        />
                    </Form.Item>
                </Col>

                <Col
                span={4}
                >   
                    <Typography.Title level={5}>Tác giả</Typography.Title>
                    <Form.Item name="">
                        <FormItemDropdown
                        modeDropdown="multiple"
                        placeholder="Chọn tác giả"
                        />
                    </Form.Item>
                </Col>
                
                <Col
                span={4}
                >
                    <Typography.Title level={5}>Thời gian</Typography.Title>
                    <Form.Item name="">
                        <RangePicker 
                            placeholder={["Từ ngày", "Đến ngày"]}
                            format='DD/MM/YYYY'
                        />
                    </Form.Item>
                </Col>

                <Col
                    span={4}
                    className="flex align-end"
                >
                    <div className="group-btn--filter">
                        <Button
                        className="btn btn-primary filter-btn"
                        htmlType="submit"
                        >
                            <FilterOutlined/>
                            <Typography.Text>Lọc</Typography.Text>
                        </Button>
                        <Button
                        className="btn btn-outlined clear-filter-btn"
                        onClick={() => clearFilter(form)}
                        >
                            <DeleteOutlined/>
                            <Typography.Text>Xóa bộ lọc</Typography.Text>
                        </Button>
                    </div>
                </Col>
            </Row>
            </Form>
        </div>
        </>
        // </ConfigProvider>
    )
}

export default FilterForm
