// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-card-survey-managment {
  padding: 24px;
  box-sizing: border-box;
  width: 70%;
  margin: 0; }
  .wrapper-card-survey-managment .ant-card-body {
    padding: 0; }
  .wrapper-card-survey-managment .ant-card-head {
    padding: 0 0 16px 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/custom-card/custom-card.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,SAAS,EAAA;EAJX;IAQI,UAAU,EAAA;EARd;IAYI,mBAAmB,EAAA","sourcesContent":["@import \"../../../../../../styles/variables\";\n\n.wrapper-card-survey-managment {\n  padding: 24px;\n  box-sizing: border-box;\n  width: 70%;\n  margin: 0;\n  // overflow-y: hidden;\n\n  .ant-card-body {\n    padding: 0;\n  }\n\n  .ant-card-head {\n    padding: 0 0 16px 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
