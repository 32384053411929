import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import "./select-ui.scss";
interface SelectPropsUiCard {
  label?: string;
  name?: string;
  className?: string;
  mode?: "multiple" | "tags";
  options?: { label: string; value: number | string }[];
  value?: any;
  required?: boolean;
  allowClear?: boolean
  onChange?: (val?: any) => void;
  filterOption?: any;
  placeholder?: string;
  disabled?: boolean;
  optionRender?: any
  messageError?: string;
}

function SelectUiCard({
  label,
  name,
  mode,
  className,
  options,
  value,
  required,
  allowClear,
  filterOption,
  placeholder,
  disabled,
  onChange,
  optionRender,
  messageError,
  ...rest
}: SelectPropsUiCard) {
  return (
    <div className={className}>
      {/* <span className="row-item">{label}</span> */}
      <Form.Item
        label={label}
        name={name}
        className="card-form-item"
        valuePropName={value}
        rules={[{ required: required, message: messageError }]}
      >
        <Select
          allowClear={allowClear}
          style={{ width: "100%" }}
          value={value}
          onChange={onChange}
          options={options}
          disabled={disabled}
          mode={mode}
          filterOption={filterOption}
          placeholder={placeholder}
          optionRender={optionRender}
          maxTagCount={"responsive"}
        />
      </Form.Item>
    </div>
  );
}

export default SelectUiCard;
