import {
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Select,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { FunctionComponent, useEffect, useState } from "react";
import AvatarUploader from "../../../components/AvatarUploader/AvatarUploader";
import CustomCard from "../../../components/custom-card/CustomCard";
import { VerifiedIcon } from "../../../components/icons";
import PageHeader from "../../../components/page-header/PageHeader";
import { useAuthStore } from "../../../stores/stores";
import { DataType } from "../../../types/listUsers";
import {
  editAccountInfo,
  getACademicLevel,
  getAllIndustry,
  getCountry,
  getDistricts,
  getInfoLoginUser,
  getpositions,
  getProvinces,
  getTeacherOrganization,
  getWards,
} from "../account/services";
import "./profile.scss";
import locale from "antd/es/date-picker/locale/vi_VN";

const { Content } = Layout;
const { Option } = Select;

type ProfileInfoProps = {
  dataUser?: DataType;
};

const Profile: FunctionComponent<ProfileInfoProps> = () => {
  const [form] = Form.useForm();
  const [dataUser, setDataUser] = useState<DataType>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isEditContact, setIsEditContact] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingContactInfo, setIsLoadingContactInfo] =
    useState<boolean>(false);

  const [positionsList, setPositionsList] = useState<any[]>([]);
  const [industryList, setIndustryList] = useState<any[]>([]);
  const [aCademicLevelList, setACademicLevelList] = useState<any[]>([]);
  const [teacherOrganizations, setTeacherOrganizations] = useState<any[]>([]);
  const [countrys, setCountrys] = useState<Array<any>>([]);
  const [provinces, setProvinces] = useState<Array<any>>([]);
  const [districts, setDistricts] = useState<Array<any>>([]);
  const [wards, setWards] = useState<Array<any>>([]);
  const countryCode = Form.useWatch("countryCode", form);
  const [seeDateOfBirth, setSeeDateOfBirth] = useState<boolean>(false);
  const [seeAddress, setSeeAddress] = useState<boolean>(false);
  const [seePhoneNumber, setSeePhoneNumber] = useState<boolean>(false);

  const saveUserInfo = useAuthStore((state) => state.saveUserInfo);

  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });

  const handleGetAccountInfo = async () => {
    try {
      await getInfoLoginUser().then((res) => {
        setDataUser(res.data.data);
        saveUserInfo(res.data.data);
        form.setFieldsValue(res.data.data);
      });
    } catch (error) {}
  };

  const fetchpositions = async () => {
    try {
      const res = await getpositions();

      setPositionsList(
        res.data?.data?.map((item: any) => ({
          label: item.name,
          value: item?.id,
        }))
      );
    } catch (error) {}
  };
  const fetchAllIndustry = async () => {
    const payload = {
      page: 1,
      size: 99999,
    };
    try {
      const res = await getAllIndustry(payload);

      setIndustryList(
        res.data?.content?.map((item: any) => ({
          label: item.name,
          value: item?.id,
        }))
      );
    } catch (error) {}
  };
  const fetchACademicLevel = async () => {
    try {
      const res = await getACademicLevel();

      setACademicLevelList(
        res.data?.data?.map((item: any) => ({
          label: item.levelName,
          value: item?.id,
        }))
      );
    } catch (error) {}
  };
  const fetchAllEnterprise = async () => {
    try {
      const payload = {
        page: 1,
        size: 99999,
      };
      const res = await getTeacherOrganization(payload);
      setTeacherOrganizations(
        res.data?.data.content?.map((item: any) => ({
          label: item.name,
          value: item.id,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    fetchpositions();
    fetchAllIndustry();
    fetchACademicLevel();
    fetchAllEnterprise();
  }, []);

  useEffect(() => {
    handleGetAccountInfo();
  }, []);

  const handleSave = async () => {
    setIsLoading(true);
    const values = form.getFieldsValue();
    const formData = new FormData();

    if (values.hoVaTen) {
      formData.append("name", values.hoVaTen);
    }
    if (values.ngaySinh) {
      formData.append("dateOfBirth", new Date(values.ngaySinh).toISOString());
    }
    if (values.gioiTinh) {
      formData.append("gender", values.gioiTinh);
    }

    if (values.chucVus?.length > 0) {
      formData.append("positionIds", values.chucVus);
    }
    if (values.chuyenNganh?.length > 0) {
      formData.append("industryIds", values.chuyenNganh);
    }
    if (values.trinhDo) {
      formData.append("academicLevelIds", values.trinhDo);
    }

    if (values.donViCongTac) {
      formData.append("organizationId", values.donViCongTac);
    }
    const selectedOrganization = teacherOrganizations?.find(
      (it) => it?.value === values?.donViCongTac
    );

    try {
      const response = await editAccountInfo(formData);
      saveUserInfo({
        ...getUserInfo,
        hoVaTen: values?.hoVaTen,
        ngaySinh: values?.ngaySinh ? dayjs(values?.ngaySinh) : null,
        gioiTinh: values?.gioiTinh,
        donViCongTac: selectedOrganization ? selectedOrganization.label : null,

        chucVus:
          values?.chucVus &&
          positionsList?.filter((obj: any) =>
            values?.chucVus.includes(obj.value)
          ),
        chuyenNganh:
          values?.chuyenNganh &&
          industryList?.filter((obj: any) =>
            values?.chuyenNganh?.includes(obj.value)
          ),
        trinhDo:
          values?.trinhDo &&
          aCademicLevelList?.filter((obj: any) =>
            values?.trinhDo?.includes(obj.value)
          ),
      });
      message.success(`Cập nhật thông tin tài khoản thành công`);
      setIsEdit(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSaveContactInfo = async () => {
    message.success(`Cập nhật thông tin tài khoản thành công`);
    setIsLoadingContactInfo(true);
    const values = form.getFieldsValue();
    const formData = new FormData();
    if (values.diaChi) formData.append("address", values.diaChi);
    if (values.provinceCode)
      formData.append("provinceCode", values.provinceCode);
    if (values.districtCode)
      formData.append("districtCode", values.districtCode);
    if (values.wardCode) formData.append("wardCode", values.wardCode);
    if (values.countryCode) formData.append("countryCode", values.countryCode);
    try {
      const response = await editAccountInfo(formData);
      setIsLoading(false);
      saveUserInfo({
        ...getUserInfo,
        diaChi: values?.diaChi,
        tinhThanh: provinces.find((it) => it.value === values.provinceCode),
        quanHuyen: districts.find((it) => it.value === values.districtCode),
        phuongXa: wards.find((it) => it.value === values.wardCode),
      });

      setIsEditContact(false);
      setIsLoadingContactInfo(false);
    } catch (error) {
      setIsLoading(false);
      setIsLoadingContactInfo(false);
    }
  };

  const fetchCountry = async () => {
    try {
      const res = await getCountry();
      setCountrys(
        res.data?.data?.map((item: any) => ({
          ...item,
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (error) {}
  };

  const fetchProvinces = async () => {
    try {
      const res = await getProvinces();
      setProvinces(
        res.data?.data?.map((item: any) => ({
          ...item,
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (error) {}
  };

  const fetchDistricts = async (value: string) => {
    try {
      const res = await getDistricts(form.getFieldValue("provinceCode"));
      setDistricts(
        res.data?.data.map((item: any) => ({
          ...item,
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (error) {}
  };

  const fetchWards = async (value: string) => {
    try {
      const res = await getWards(form.getFieldValue("districtCode"));
      setWards(
        res.data?.data.map((item: any) => ({
          ...item,
          label: item?.name,
          value: item?.code,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    fetchProvinces();
    fetchCountry();
  }, []);

  useEffect(() => {
    if (form.getFieldValue("provinceCode")) {
      fetchDistricts(form.getFieldValue("provinceCode"));
    }
  }, [form.getFieldValue("provinceCode")]);

  useEffect(() => {
    if (form.getFieldValue("districtCode")) {
      fetchWards(form.getFieldValue("districtCode"));
    }
  }, [form.getFieldValue("districtCode")]);

  const handleCountryChange = (value: string) => {
    form.setFieldsValue({
      countryCode: value,
      // provinceCode: undefined,
      // districtCode: undefined,
      // wardCode: undefined,
    });
  };

  const handleProvinceChange = (value: string) => {
    form.setFieldsValue({
      provinceCode: value,
      districtCode: undefined,
      wardCode: undefined,
    });
    fetchDistricts(value);
  };

  const handleDistrictChange = (value: string) => {
    form.setFieldsValue({ district: value, ward: null });
    fetchWards(value);
  };
  const removeDiacritics = (str: string) =>
    str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .trim();

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) =>
    removeDiacritics(option?.label ?? "")
      .toLowerCase()
      .includes(removeDiacritics(input).toLowerCase());

  useEffect(() => {
    if (getUserInfo) {
      form.setFieldsValue({
        hoVaTen: getUserInfo?.hoVaTen,
        ngaySinh: getUserInfo.ngaySinh ? dayjs(getUserInfo.ngaySinh) : null,
        gioiTinh: getUserInfo?.gioiTinh,
        donViCongTac: getUserInfo?.chiTietDonViCongtac?.id,
        chucVus: getUserInfo?.chucVus?.map((it: any) => {
          return it?.id || it.value;
        }),
        chuyenNganh: getUserInfo?.chuyenNganh?.map((it: any) => {
          return it?.id || it.value;
        }),
        trinhDo: getUserInfo?.trinhDo?.map((it: any) => {
          return it?.id || it.value;
        }),
        dienThoai: getUserInfo?.dienThoai,
        diaChi: getUserInfo?.diaChi,
        countryCode: getUserInfo?.quocGia?.code,
        provinceCode: getUserInfo?.tinhThanh?.code,
        districtCode: getUserInfo?.quanHuyen?.code,
        wardCode: getUserInfo?.phuongXa?.code,
      });
    }
  }, [getUserInfo]);

  const getAddress = () => {
    const provinceCode =
      form.getFieldValue("provinceCode") || getUserInfo?.tinhThanh?.code;

    const districtCode =
      form.getFieldValue("districtCode") || getUserInfo?.quanHuyen?.code;

    const wardCode =
      form.getFieldValue("wardCode") || getUserInfo?.phuongXa?.code;

    const provinceName = provinces.find(
      (it) => it?.code === provinceCode
    )?.fullName;

    const districtName = districts.find(
      (it) => it?.code === districtCode
    )?.fullName;

    const wardName = wards.find((it) => it?.code === wardCode)?.fullName;

    const addressParts = [
      getUserInfo?.diaChi,
      provinceName,
      districtName,
      wardName,
    ];

    return addressParts.filter(Boolean).join(", ").trim();
  };

  const cancelEdit = () => {
    setIsEdit(false);
    if (getUserInfo) {
      form.setFieldsValue({
        hoVaTen: getUserInfo?.hoVaTen,
        ngaySinh: getUserInfo.ngaySinh ? dayjs(getUserInfo.ngaySinh) : null,
        gioiTinh: getUserInfo?.gioiTinh,
        donViCongTac: getUserInfo?.chiTietDonViCongtac?.id,
        chucVus: getUserInfo?.chucVus?.map((it: any) => {
          return it?.id || it.value;
        }),
        chuyenNganh: getUserInfo?.chuyenNganh?.map((it: any) => {
          return it?.id || it.value;
        }),
        trinhDo: getUserInfo?.trinhDo?.map((it: any) => {
          return it?.id || it.value;
        }),
      });
    }
  };

  const cancelEditContact = () => {
    setIsEditContact(false);
    if (getUserInfo) {
      form.setFieldsValue({
        dienThoai: getUserInfo?.dienThoai,
        diaChi: getUserInfo?.diaChi,
        countryCode: getUserInfo?.quocGia?.code,
        provinceCode: getUserInfo?.tinhThanh?.code,
        districtCode: getUserInfo?.quanHuyen?.code,
        wardCode: getUserInfo?.phuongXa?.code,
      });
    }
  };

  return (
    <div className="account-profile">
      <Content>
        <PageHeader
          title="Hồ sơ cá nhân"
          positionItem="column"
          breadcrumb={false}
        />
        <div className="wrapper wrapper-account">
          <section className="detail-account">
            <div className="form-detail">
              <div className="avatar-info">
                <AvatarUploader imgUrl={getUserInfo?.anhDaiDien} />

                <div className="avatar-title">
                  <Typography.Title level={5}>
                    {dataUser?.hoVaTen}
                  </Typography.Title>
                  <VerifiedIcon width={"25"} height={"24"} />
                </div>
              </div>
              <CustomCard
                title={
                  <div className="card-heading--info">
                    <div>
                      <Typography.Title level={5}>
                        Thông tin chung
                      </Typography.Title>
                      <Typography.Text className="sub-title">
                        Thay đổi thông tin chung hồ sơ cá nhân của người dùng
                      </Typography.Text>
                    </div>
                    <div className="account-profile-icon">
                      {isEdit ? (
                        <div className="flex gap-8">
                          <Button
                            type="primary"
                            onClick={handleSave}
                            loading={isLoading}
                          >
                            Lưu
                          </Button>
                          <Button onClick={cancelEdit}>Huỷ</Button>
                        </div>
                      ) : (
                        <div
                          className="edit-outline"
                          onClick={() => setIsEdit(true)}
                        >
                          <EditOutlined />
                        </div>
                      )}
                    </div>
                  </div>
                }
                className="card-info--wrapper"
              >
                <Form
                  name="profileForm"
                  layout="vertical"
                  form={form}
                  className="profile-form pr-3"
                >
                  <div className="hr-tag"></div>
                  <Form.Item name="hoVaTen" label="Họ và tên">
                    {isEdit ? <Input /> : <span>{getUserInfo?.hoVaTen}</span>}
                  </Form.Item>
                  <div className="hr-tag"></div>

                  <Form.Item name="ngaySinh" label="Ngày sinh">
                    {isEdit ? (
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Chọn ngày sinh"
                        format="DD/MM/YYYY"
                        disabledDate={(current) =>
                          current && current > dayjs()?.endOf("day")
                        }
                        locale={locale}
                        popupClassName="dateofbirth-picker"
                      />
                    ) : (
                      <span>
                        {getUserInfo?.ngaySinh ? (
                          <span className="flex align-center justify-between gap-16">
                            {seeDateOfBirth ? (
                              getUserInfo?.ngaySinh ? (
                                dayjs(getUserInfo?.ngaySinh).format(
                                  "DD/MM/YYYY"
                                )
                              ) : null
                            ) : (
                              <span>**/**/****</span>
                            )}
                            <span
                              className="pointer"
                              onClick={() => setSeeDateOfBirth(!seeDateOfBirth)}
                            >
                              {seeDateOfBirth ? (
                                <EyeOutlined />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </span>
                          </span>
                        ) : null}
                      </span>
                    )}
                  </Form.Item>
                  <div className="hr-tag"></div>
                  <Form.Item name="gioiTinh" label="Giới tính">
                    {isEdit ? (
                      <Select placeholder="Chọn giới tính">
                        <Option value="male">Nam</Option>
                        <Option value="female">Nữ</Option>
                      </Select>
                    ) : (
                      <span>
                        {getUserInfo?.gioiTinh === "male"
                          ? "Nam"
                          : getUserInfo?.gioiTinh === "female"
                          ? "Nữ"
                          : ""}
                      </span>
                    )}
                  </Form.Item>
                  <div className="hr-tag"></div>
                  <Form.Item name="donViCongTac" label="Đơn vị công tác">
                    {isEdit ? (
                      <Select
                        options={teacherOrganizations}
                        placeholder="Chọn đơn vị công tác"
                      />
                    ) : (
                      <span>{getUserInfo?.donViCongTac}</span>
                    )}
                  </Form.Item>

                  <div className="hr-tag"></div>
                  <Form.Item name="chucVus" label="Chức vụ">
                    {isEdit ? (
                      <Select
                        mode="multiple"
                        options={positionsList}
                        placeholder="Chọn chức vụ"
                      ></Select>
                    ) : (
                      <span>
                        {getUserInfo?.chucVus?.map(
                          (val: any, index: number) => (
                            <div key={index}>{val.name || val.label}</div>
                          )
                        )}
                      </span>
                    )}
                  </Form.Item>
                  <div className="hr-tag"></div>
                  <Form.Item name="chuyenNganh" label="Chuyên ngành">
                    {isEdit ? (
                      <Select
                        mode="multiple"
                        options={industryList}
                        placeholder="Chọn chuyên ngành"
                      ></Select>
                    ) : (
                      <span>
                        {getUserInfo?.chuyenNganh?.map(
                          (val: any, index: number) => (
                            <div key={index}>{val.name || val.label}</div>
                          )
                        )}
                      </span>
                    )}
                  </Form.Item>
                  <div className="hr-tag"></div>
                  <Form.Item name="trinhDo" label="Trình độ học vấn">
                    {isEdit ? (
                      <Select
                        options={aCademicLevelList}
                        placeholder="Chọn trình độ học vấn"
                      ></Select>
                    ) : (
                      <span>
                        {getUserInfo?.trinhDo?.map(
                          (val: any, index: number) => (
                            <div key={index}>{val.levelName || val.label}</div>
                          )
                        )}
                      </span>
                    )}
                  </Form.Item>
                </Form>
              </CustomCard>
              <CustomCard
                title={
                  <div className="card-heading--info">
                    <div>
                      <Typography.Title level={5}>
                        Thông tin liên hệ
                      </Typography.Title>
                      <Typography.Text className="sub-title">
                        Thay đổi thông tin liên hệ của người dùng.
                      </Typography.Text>
                    </div>
                    <div className="account-profile-icon">
                      <div className="account-profile-icon">
                        {isEditContact ? (
                          <div className="flex gap-8">
                            <Button
                              type="primary"
                              onClick={handleSaveContactInfo}
                              loading={isLoadingContactInfo}
                            >
                              Lưu
                            </Button>
                            <Button onClick={cancelEditContact}>Huỷ</Button>
                          </div>
                        ) : (
                          <div
                            className="edit-outline"
                            onClick={() => setIsEditContact(true)}
                          >
                            <EditOutlined />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }
                className="card-info--wrapper"
              >
                <div className="hr-tag"></div>
                <Form
                  name="profileForm"
                  layout="vertical"
                  form={form}
                  className="profile-form"
                >
                  <Tooltip title="Cài đặt không thể thay đổi" placement="right">
                    <Form.Item name="dienThoai" label="Số điện thoại">
                      {isEditContact ? (
                        <Input
                          readOnly
                          className="readonly-input"
                          suffix={
                            <EyeInvisibleOutlined
                              style={{ color: "rgba(0,0,0,.45)" }}
                            />
                          }
                        />
                      ) : (
                        <span className="hide-info flex justify-between align-center pr-3">
                          {seePhoneNumber ? (
                            <span>{getUserInfo?.dienThoai}</span>
                          ) : (
                            "**********"
                          )}

                          <span
                            className="pointer"
                            onClick={() => setSeePhoneNumber(!seePhoneNumber)}
                          >
                            {seePhoneNumber ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </span>
                        </span>
                      )}
                    </Form.Item>
                  </Tooltip>

                  <div className="hr-tag"></div>
                  <div className="address-contact-information">
                    <div className="ant-form-item-label">Địa chỉ</div>
                    <div className="flex-1">
                      {isEditContact ? (
                        <Form.Item name="countryCode" label="Quốc gia">
                          <Select
                            options={countrys}
                            onChange={handleCountryChange}
                            placeholder="Chọn Quốc gia"
                            filterOption={filterOption}
                            showSearch
                          />
                        </Form.Item>
                      ) : null}

                      {isEditContact && countryCode === 4 ? (
                        <>
                          <Form.Item name="provinceCode" label="Tỉnh/Thành phố">
                            <Select
                              options={provinces}
                              onChange={handleProvinceChange}
                              placeholder="Chọn Tỉnh/Thành phố"
                              filterOption={filterOption}
                              showSearch
                            />
                          </Form.Item>

                          <Form.Item name="districtCode" label="Quận/Huyện">
                            <Select
                              options={districts}
                              placeholder="Chọn QuậnQuận/Huyện"
                              onChange={handleDistrictChange}
                              disabled={!form.getFieldValue("provinceCode")}
                              filterOption={filterOption}
                              showSearch
                            />
                          </Form.Item>
                          <Form.Item name="wardCode" label="Phường/Xã">
                            <Select
                              options={wards}
                              placeholder="Chọn Phường/Xã"
                              disabled={
                                !form.getFieldValue("provinceCode") ||
                                !form.getFieldValue("districtCode")
                              }
                              filterOption={filterOption}
                              showSearch
                            />
                          </Form.Item>
                        </>
                      ) : null}

                      {isEditContact ? (
                        <Form.Item name="diaChi" label="Địa chỉ cụ thể">
                          <Input />
                        </Form.Item>
                      ) : (
                        <span className="hide-info flex justify-between align-center pr-3">
                          {seeAddress ? (
                            <span>{getAddress()}</span>
                          ) : (
                            "Thông tin bảo mật"
                          )}

                          <span
                            className="pointer"
                            onClick={() => setSeeAddress(!seeAddress)}
                          >
                            {seeAddress ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </span>
                        </span>
                      )}
                    </div>
                  </div>
                </Form>
              </CustomCard>
            </div>
          </section>
        </div>
      </Content>
    </div>
  );
};

export default Profile;
