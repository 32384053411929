import {
  Flex
} from "antd";
import { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";

import {
  SearchOutlined
} from "@ant-design/icons";
import FormInput from "../../../components/form-input/FormInput";
// import "./filter-table.scss";

type FilterTableProps = {
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
};

const FilterTable: FunctionComponent<FilterTableProps> = ({
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
}) => {

  return (
    <>
      <Flex>
        <div className="">
          <FormInput
            placeholder="Nhập tên hoặc mã khoá học cần tìm kiếm"
            value={searchValue}
            onChange={handleChangeInput}
            onKeyPress={handleSearch}
            afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
          />
        </div>
      </Flex>
    </>
  );
};

export default FilterTable;
