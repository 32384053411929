// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-student--enroll .col-table-create {
  margin-bottom: 15px; }
  .create-student--enroll .col-table-create .item-time .ant-picker {
    width: 100%; }
  .create-student--enroll .col-table-create .item-time .ant-form-item {
    width: 100%; }
    .create-student--enroll .col-table-create .item-time .ant-form-item .ant-picker {
      width: 100%; }
  .create-student--enroll .col-table-create .ant-input-number {
    width: 100%; }

.title-checkbox {
  font-weight: 400; }

.sub-title {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  font-weight: 300; }

.form-create--student {
  margin-bottom: 30px; }
  .form-create--student .group-button {
    justify-content: flex-end; }
    .form-create--student .group-button button {
      max-width: 70px; }
`, "",{"version":3,"sources":["webpack://./src/pages/content-construction/registration-class/list-student/create-student/index.scss"],"names":[],"mappings":"AAAA;EAEI,mBAAmB,EAAA;EAFvB;IAMQ,WAAW,EAAA;EANnB;IAUQ,WAAW,EAAA;IAVnB;MAaU,WAAW,EAAA;EAbrB;IAmBM,WAAW,EAAA;;AAOjB;EACE,gBAAgB,EAAA;;AAGlB;EACE,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB,EAAA;;AAGlB;EACE,mBAAmB,EAAA;EADrB;IAII,yBAAyB,EAAA;IAJ7B;MAOM,eAAe,EAAA","sourcesContent":[".create-student--enroll {\n  .col-table-create {\n    margin-bottom: 15px;\n\n    .item-time {\n      .ant-picker {\n        width: 100%;\n      }\n\n      .ant-form-item {\n        width: 100%;\n\n        .ant-picker {\n          width: 100%;\n        }\n      }\n    }\n\n    .ant-input-number {\n      width: 100%;\n    }\n  }\n\n\n}\n\n.title-checkbox {\n  font-weight: 400;\n}\n\n.sub-title {\n  font-size: 14px;\n  line-height: 22px;\n  color: rgba(0, 0, 0, 0.45);\n  text-align: left;\n  font-weight: 300;\n}\n\n.form-create--student {\n  margin-bottom: 30px;\n\n  .group-button {\n    justify-content: flex-end;\n\n    button {\n      max-width: 70px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
