import React, {FunctionComponent, Key, useEffect, useRef, useState} from 'react';
import {Button, Col, Form, Input, message, Row, Transfer, TransferProps, Typography} from "antd";
import FormInput from "../../../../components/form-input/FormInput";
import FormRadio from "../../../../components/form-radio/FormRadio";
import {dataStatus} from "../../constants/data";
import {FormInstance} from "antd/lib";
import {getPermissionsRoles, updatePermissionsRoles} from "../../../../service/role-management/roleManagementApi";
import {get, isEmpty, toInteger} from "lodash";

type RecordType = {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
}

const validate = {
  nameRole: {
    required: true,
    message: "Vui lòng nhập tên vai trò"
  },
  description: {
    required: true,
    message: "Vui lòng nhập mô tả."
  },
  status: {
    required: true,
    message: "Vui lòng chọn trạng thái."
  }
}

type InfoRoleFormProps = {
  form?: FormInstance,
  checkEdit?: boolean,
  checkIsFormEdit?: boolean,
  isOpened?: boolean,
  dataEdit?: any,
  resetPermissions?: any
}

const InfoRoleForm: FunctionComponent<InfoRoleFormProps> = ({
                                                              form,
                                                              checkEdit,
                                                              checkIsFormEdit,
                                                              isOpened,
                                                              dataEdit,
                                                              resetPermissions
                                                            }) => {
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [dataTransfer, setDataTransfer] = useState<RecordType[]>([])
  const [permissionsRole, setPermissionsRole] = useState<any[]>([])
  const [pageSize, setPageSize] = useState(100)
  const [statusExists, setStatusExists,] = useState(false)
  const [valueSearchLeft, setValueSearchLeft] = useState('');
  const [allPermissions, setAllPermissions] = useState<any[]>([]);
  const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
  const [isCreating, setIsCreating] = useState<boolean>(true);
  const transferRef = useRef();
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get('id');
  const fetchData = (dataSearch?: any) => {
    getPermissionsRoles(dataSearch).then(res => {
      let {data} = res.data
      data = mergeArraysDistinctByKey(data, allPermissions);
      setAllPermissions([...data]);
    })
  }

  useEffect(() => {
    if (checkIsFormEdit && dataEdit) {
      setStatusExists(true)

    }
  }, [dataEdit, statusExists]);

  useEffect(() => {
    if (!isOpened) {
      return;
    }
 
    if (!id) {
      setSelectedPermissions([]);
      setTargetKeys([]);
      setIsCreating(true);
      return;
    }
    setIsCreating(false);
    getPermissionsRoles({id: toInteger(id), isExist: true}).then(res => {
      let {data} = res.data
      setSelectedPermissions(data);
    });
    fetchData({pageSize: pageSize, keyword: ''});
  }, [isOpened]);

  useEffect(() => {
    fetchData({pageSize: pageSize, keyword: valueSearchLeft});
  }, [pageSize, valueSearchLeft]);

  function mergeArraysDistinctByKey(arr1: any[], arr2:any[]) {
    const combined = [...arr1, ...arr2];
    const keySet = new Set();

    return combined.filter(item => {
      if (keySet.has(item['id'])) {
        return false;
      }
      keySet.add(item['id']);
      return true;
    });
  }

  const getNewData = () => {
    const newData: RecordType[] = mergeArraysDistinctByKey(allPermissions, selectedPermissions).map((role: any, i: number) => {
      return {
        key: role.id.toString(),
        title: role.name,
        description: role.name,
        chosen: statusExists,
      }
    })
    setDataTransfer(newData)
    if (!isCreating) {
      const targetKey = selectedPermissions.map((permission: any, i) => String(permission.id));
      setTargetKeys([...targetKey]);
    }
  }

  useEffect(() => {
    getNewData()
  }, [permissionsRole, statusExists, selectedPermissions, allPermissions]);


  const handleChange: TransferProps['onChange'] = (newTargetKeys, direction) => {
    setTargetKeys(newTargetKeys);
    if (direction === 'right' && dataEdit) {
      updatePermissionsRoles(get(dataEdit, 'id', ''), {isAdd: true, quyens: selectedKeys}).then(res => {
        const {data, statusCode} = res.data
        if (statusCode === 200) {
          message.success(get(data, 'message', 'Cập nhật quyền thành công'))
          getPermissionsRoles({id: toInteger(id), isExist: true}).then(res => {
            let {data} = res.data
            setSelectedPermissions(data);
          });
          // fetchData({pageSize: pageSize, keyword: valueSearchLeft});
        }
      })
    }
    if (direction === 'left' && dataEdit) {
      updatePermissionsRoles(get(dataEdit, 'id', ''), {isAdd: false, quyens: selectedKeys}).then(res => {
        const {data, statusCode} = res.data
        if (statusCode === 200) {
          message.success(get(data, 'message', 'Cập nhật quyền thành công'))
          getPermissionsRoles({id: toInteger(id), isExist: true}).then(res => {
            let {data} = res.data
            setSelectedPermissions(data);
          });
          // fetchData({pageSize: pageSize, keyword: valueSearchLeft});
        }
      })
    }
  };

  const filterOption = (inputValue: string, option: RecordType) => {
    return option.description.toLowerCase().indexOf(inputValue.toLowerCase().trim()) > -1;
  }

  const handleSearch: TransferProps['onSearch'] = (dir, value) => {
    if (dir === 'left') {
      setValueSearchLeft(value.trim());
    }
  };

  const loadMore = () => {
    setPageSize(pageSize + 100)
  }


  const renderItem = (item: RecordType) => {
    const customLabel = (
      <span className="custom-item">
          {item.title}
        </span>
    );


    return {
      label: customLabel, // for displayed item
      value: item.title, // for title and filter matching
    };
  };

  const renderFooter: TransferProps['footer'] = (_, info) => {
    if (info?.direction === 'left') {
      return (
        <Button size="small" style={{float: 'left', margin: 5}} onClick={loadMore}>
          Tải thêm
        </Button>
      );
    }
  };

  return (
    <>
      <div className="list-roles">
        <div className="list-roles--title">
          <Typography.Title level={5}>
            Danh sách quyền của vai trò
          </Typography.Title>
        </div>
        <div className="roles-transfer">
          <Form.Item name="quyens">
            <Transfer listStyle={{width: "47%", height: 550}} dataSource={dataTransfer}
                      showSearch
                      disabled={checkEdit}
                      selectAllLabels={[
                        ({selectedCount, totalCount}) => (
                          <span>
															{totalCount} quyền hạn
														</span>
                        )
                        , ({selectedCount, totalCount}) => (
                          <span>
                            {totalCount} quyền hạn
                          </span>
                        )
                      ]}
                      footer={renderFooter}
                      selectedKeys={selectedKeys as any}
                      locale={{searchPlaceholder: "Tìm quyền hạn"}}
                      filterOption={filterOption}
                      onSearch={handleSearch}
                      titles={["Tất cả quyền hạn", "Quyền hạn đã lựa chọn"]}
                      onChange={handleChange}
                      onSelectChange={(sourceSelectedKeys: Key[], targetSelectedKeys: Key[]) => {
                        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
                        const res = permissionsRole.filter((item: any) => {
                          return sourceSelectedKeys.indexOf(item.id.toString()) >= 0
                        })
                      }}
                      render={renderItem}
                      targetKeys={targetKeys as string[]}/>
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default InfoRoleForm;