import { FC } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import { Col, Form, Row, Tag } from "antd";
import "../student-detail/studentDetail.scss";
interface StudentDetailProps {
    studentRecord: any;
    specialized: string[];
}

const StudentDetail:FC<StudentDetailProps> = ({
    studentRecord,
    specialized,
}) => {
    return (
        <div className="wrapper-settings">
      <section className="setting-student">
        <CustomCard className="student-card" title="Thông tin danh mục dùng chung">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item className="setting-item" name="name" label="Tên sinh viên">
                <p className="student-content">{studentRecord?.name}</p>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="setting-item" name="code" label="Mã sinh viên">
                <p className="student-content">{studentRecord?.code}</p>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="setting-item" name="gender" label="Giới tính">
                <p className="student-content">{studentRecord?.gender}</p>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="setting-item" name="department" label="Khoa">
                <p className="student-content">{studentRecord?.industryGroupInfo?.name}</p>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="setting-item" name="domicile" label="Nguyên quán">
                <p className="student-content">{studentRecord?.provinceDto.name}</p>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="setting-item" name="universityName" label="Tên trường">
                <p className="student-content">{studentRecord?.universityName}</p>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="setting-item" name="specialized" label="Chuyên ngành">
                <div className="student-content">
                  {specialized?.map((item: any) => (
                    <Tag style={{ marginBottom: 5 }} key={item}>
                      {item}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="setting-item" name="email" label="Email">
                <p className="student-content">{studentRecord?.email}</p>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="setting-item" name="phone" label="Điện thoại liên hệ">
                <p className="student-content">{studentRecord?.phoneNumber}</p>
              </Form.Item>
            </Col>
          </Row>
        </CustomCard>
      </section>
    </div>
    )
}
export default StudentDetail;