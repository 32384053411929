import React from 'react'
import { Badge, Button, Form, message, Modal, Typography } from "antd";
import CustomCard from '../../../custom-card/CustomCard';
import { DetailCourseData, PlanInfo } from '../../../../types/assign-course-construction';
import AssignmentInformation from './assignmentInformation';
import { formatDateToIsoString } from '../../../../utils/converDate';

type Prop = {
  planInfo: PlanInfo
  detailData: DetailCourseData
}

const dataOptions1 = [
  {
    id: 1,
    name: "Đại học Quốc gia Hà Nội"
  }
]

const dataOptions2 = [
  {
    id: 1,
    name: "Nguyễn Minh Nghĩa"
  }
]

const PlanInformation = (props: Prop) => {

  const { planInfo, detailData } = props
  const [form] = Form.useForm()

  const infoData: PlanInfo = {
    planName: detailData.name ?? '',
    status: !detailData.isAssigned ? "Chưa phân công" : "Đã phân công",
    dateConfirm: formatDateToIsoString(detailData?.approvedDate ?? ''),
    peopleConfirm: detailData?.approveName ?? '',
    description: detailData?.description ?? ''
  }

  const convertObjectData = Object.entries(infoData);

  const renderSwitch = (value: string) => {
    switch (value) {
      case 'planName':
        return <div>Tên kế hoạch</div>;
      case 'status':
        return <div>Trạng thái</div>;
      case 'dateConfirm':
        return <div>Ngày phê duyệt</div>;
      case 'peopleConfirm':
        return <div>Người phê duyệt</div>;
      case 'description':
        return <div>Mô tả</div>;
      default:
        return '';
    }
  }

  const listInfo = convertObjectData.map((item, index) => (
    <div className="list-item" key={index}>
      <Typography.Title level={5} className="list-item--title">
        {renderSwitch(item[0])}
      </Typography.Title>
      <div className="list-item--description">
        <Typography.Text className="content">
          {item[0] === 'status' ? <Badge key={!detailData.isAssigned ? 'lightgray' : 'green'} color={!detailData.isAssigned ? 'lightgray' : 'green'}  /> : null} {item[1]}
        </Typography.Text>
      </div>
    </div>
  ))

  return (
    <>
      <Form.Provider onFormFinish={(name, { values, forms }) => {
      }}>
        <CustomCard title={
          <div className="card-heading--info">
            <Typography.Title level={5}>
              Thông tin kế hoạch
            </Typography.Title>
            <Typography.Text className="sub-title">
              Thông tin chi tiết về hoạt động của kế hoạch
            </Typography.Text>
          </div>
        } className="card-info--wrapper">
          <div className="list-wrapper">
            {listInfo}
          </div>
        </CustomCard>

        <AssignmentInformation dataOptions1={dataOptions1} dataOptions2={dataOptions2} detailData={detailData}/>
      </Form.Provider>
    </>
  )
}

export default PlanInformation


