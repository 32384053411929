import React from "react";
import { Button } from "antd";
import { convertMinutesToTimeString } from "../../../../utils/format";
import { RefreshIcon } from "../../../../components/icons/svg";

interface PauseScreencreenProps {
  timeTraining?: number;
  timeDeadline?: number | string;
  type?: "userFinish" | "timeFinish";
  onRetrain?: (v?: any) => void;
  onViewResult?: () => void;
}
function FinishScreen({
  timeTraining = 1000,
  type = "timeFinish",
  onRetrain,
  onViewResult = () => {},
}: PauseScreencreenProps) {
  return (
    <div className="pause-screen">
      <div className="center flex-column content-center">
        {type === "userFinish" ? (
          <span className="font-weight-6 font-size-16 mb-1">
            Chúc mừng bạn đã nộp bài thành công!
          </span>
        ) : (
          <span className="font-weight-6 font-size-16 mb-1 text-danger">
            Thời gian làm bài đã kết thúc!
          </span>
        )}

        <p className="font-size-16">
          Tổng thời gian làm bài:{" "}
          <span className="text-primary-violet">
            {convertMinutesToTimeString(timeTraining)}
          </span>
        </p>
        <p className="font-size-16">
          Thời gian nộp bài:{" "}
          <span className="text-primary-violet">00:00:00 - 15/05/2024</span>
        </p>
      </div>
      <div className="center gap-8 btn-group">
        <Button
          icon={<RefreshIcon />}
          className="h-40 mt-2 center"
          onClick={onRetrain}
        >
          Làm lại
        </Button>
        <Button className="btn-primary background-violet border-violet h-40 mt-2" onClick={onViewResult}>
          Kiểm tra kết quả
        </Button>
      </div>
    </div>
  );
}

export default FinishScreen;
