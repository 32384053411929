import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import FormInput from "../../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import FormRadio from "../../../../../../components/form-radio/FormRadio";
import { FormInstance } from "antd/lib";
import { UrlPattern, regexEmail } from "../../../../../../utils/regex";
import { get, isEmpty } from "lodash";
import "./formCreateSurvey.scss";
import { message } from "antd/lib";
import {
  CloseOutlined,
  PlusOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { getCourseDetail } from "../../../../../../service/course-construct";
import { ConstructCourseDetailType } from "../../../../../../types/course-construction";
import { useForm } from "antd/es/form/Form";
import { Enterprises } from "../../../../../../types/assign-course-construction";
import { getLecturersFromEnterprises } from "../../../../../../service/assign-course-construction";
import DrawerCustom from "../../../../../../components/drawer/Drawer";
import { useNavigate } from "react-router-dom";

const validations = {
  name: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length < 6) {
        return Promise.reject(
          new Error("Tên khảo sát phải từ 6 ký tự trở lên")
        );
      }
      if (value && value.length > 255) {
        return Promise.reject(
          new Error("Tên khảo sát không vượt quá 255 ký tự")
        );
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên khảo sát"));
      }
      return Promise.resolve();
    },
  },
  code: {
    required: true,
    validator: (_: unknown, value: any) => {
      if (!isEmpty(value) && !value.match(new RegExp(UrlPattern))) {
        return Promise.reject(
          new Error("Định dạng Mã khảo sát không đúng, vui lòng nhâp lại")
        );
      }
      if (isEmpty(value)) {
        return Promise.reject(new Error("Vui lòng nhập mã khảo sát"));
      }
      return Promise.resolve();
    },
  },
  department: {
    required: true,
    message: "Vui lòng chọn khoa khảo sát áp dụng",
  },
  field: {
    required: true,
    message: "Vui lòng chọn chuyên ngành áp dụng",
  },
  units: {
    required: true,
    message: "Vui lòng chọn đơn vị công tác",
  },
  hostSchool: {
    required: true,
    message: "Vui lòng chọn trường chủ trì",
  },
  lecturerName: {
    required: true,
    message: "Vui lòng chọn giảng viên",
  },
};

type FormCreateSurveyProps = {
  enterprises: Enterprises[];
  onFinish?: any;
  dataOptions?: any;
  industryGroup?: Enterprises[];
  industry?: Enterprises[];
  coursePlan?: Enterprises[];
  id?: any;
  detailData: ConstructCourseDetailType;
  isOpened?: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setDetailData: React.Dispatch<
    React.SetStateAction<ConstructCourseDetailType>
  >;
  setDataUpdate: (data: any) => void;
  setDataSponsorUpdate: (data: any) => void;
};

export const FormCreateSurvey: FunctionComponent<FormCreateSurveyProps> = ({
  enterprises,
  onFinish,
  dataOptions,
  industryGroup,
  industry,
  coursePlan,
  id,
  detailData,
  isOpened,
  setIsOpened,
  setDetailData,
  setDataUpdate,
  setDataSponsorUpdate,
}) => {
  const [hadPlan, setHadPlan] = useState(false);
  const [teacherPresides, setTeacherPresides] = useState<any>([]);
  const [countSchool, setCountSchool] = useState<any>([]);
  const [countSchoolSponsor, setCountSchoolSponsor] = useState<any>([]);
  const [dataOption, setDataOption] = useState<any>({});
  const [teacherCooperative, setTeacherCooperative] = useState({});
  const [inputVisible, setInputVisible] = useState<any>({});
  const [form] = Form.useForm();
  const [error, setError] = useState<any>({});
  const [dataAssigners, setDataAssigners] = useState<any>([]);
  const [dataNotAssigners, setDataNotAssigners] = useState<any>([]);
  const [SponsorOptionLecturers, setSponsorOptionLecturers] = useState<
    Enterprises[]
  >([]);
  const [isSync, setIsSync] = useState(false);
  const [detailDataEdit, setDetailDataEdit] =
    useState<ConstructCourseDetailType>(detailData);

  useEffect(() => {
    form.resetFields();
    setCountSchoolSponsor([]);
    setCountSchool([]);
    if (detailData?.coursePlanId) setHadPlan(true);
    if (detailData) {
      form.setFieldsValue({
        name: detailData?.name || "",
        code: detailData?.code || "",
        industryGroup: detailData?.faculties?.map((item: any, index: any) => {
          return item.id;
        }),
        industry: detailData?.industries?.map((item: any, index: any) => {
          return item.id;
        }),
        coursePlanId: detailData?.coursePlanId,
      });
      getDetailCourseData();
    }
  }, [detailData]);

  const getValue = async (value: any) => {
    let newArr = [...countSchoolSponsor];
    if (!isEmpty(countSchoolSponsor)) {
      newArr[0].assigned = [];
      newArr[0].enterpriseId = value;
      setCountSchoolSponsor(newArr);
    } else {
      newArr.push({
        assigned: !isEmpty(dataAssigners) ? dataAssigners[0].assigned : [],
        enterpriseId: value,
        enterpriseName: "",
        isSponsor: true,
      });
      setCountSchoolSponsor(newArr);
    }
    //api để lấy thông tin giáo viên theo trường chủ trì
    form.resetFields(["lecturerName"]);
    const data = {
      keyword: "",
      pageNumber: 0,
      pageSize: 0,
      enterpriseUUID: value,
    };
    await getLecturersFromEnterprises(data).then((res) => {
      const { data } = res.data;
      setSponsorOptionLecturers(data.data);
    });
  };
  const getValueCooperative = (value: string, index: number) => {
    const itemResult = countSchool.filter(
      (item: any) => item.enterpriseId === value
    );
    if (isEmpty(itemResult)) {
      let newArr = [...countSchool];
      newArr[index].assigned = [];
      newArr[index].enterpriseId = value;
      setCountSchool(newArr);
    }
    //api để lấy thông tin giáo viên theo trường hợp tác
    form.resetFields([`lecturerCooperativeName${index}`]);
    const data = {
      keyword: "",
      pageNumber: 0,
      pageSize: 0,
      enterpriseUUID: value,
    };
    getLecturersFromEnterprises(data).then(async (res) => {
      const { data } = res.data;

      const dataChange = data?.data?.map((item: any, index: number) => {
        return item.assignUserId;
      });
      const result = {
        enterpriseUUID: value,
        assigner: dataChange,
        isSponsor: false,
      };
      setDataOption({ ...dataOption, [`lecturer${index}`]: data.data });
    });
  };

  const addSchool = () => {
    if (countSchool.filter((item: any) => item.display).length === 5) {
      message.warning("Không thể tạo quá 5 trường hợp tác");
    } else {
      setCountSchool(
        countSchool.concat({
          id: countSchool.length + 1,
          display: true,
        })
      );
    }
  };

  const navigate=useNavigate();
  const onStartInputSurvey = () => {
    /*
    if (isEmpty(countSchoolSponsor?.[0]?.assigned)) {
      setError({ ...error, [`lecturerName`]: true });
      return;
    } else {
      form.submit();
      setError({ ...error, [`lecturerName`]: false });
      setDataUpdate(countSchool);
      setDataSponsorUpdate(countSchoolSponsor);
    }*/
    navigate(`/survey-training/survey-detail/id`)
    
  };

  const closeItemSponsor = (item: any) => {
    const finishData = [...countSchoolSponsor];
    const arr = finishData.filter(
      (data: any) => data.enterpriseId === item.enterpriseId
    );
    const data = finishData[0].assigned.filter(
      (result: any) => result.assignUserId !== item.assignUserId
    );
    finishData[0].assigned = data;
  };

  const openAddLecturer = (index: number, id: string) => {
    const arrAssigner = countSchool?.[index]?.assigned?.map(
      (item: any, index: any) => {
        return item.assignUserId;
      }
    );
    form.setFieldsValue({
      [`lecturerCooperativeName${index}`]: arrAssigner,
    });
    setInputVisible({ ...inputVisible, [`inputVisible${index}`]: true });
    const data = {
      keyword: "",
      pageNumber: 0,
      pageSize: 0,
      enterpriseUUID: id,
    };
    getLecturersFromEnterprises(data).then((res) => {
      const { data } = res.data;

      const dataChange = data?.data?.map((item: any, index: number) => {
        return item.assignUserId;
      });

      setDataOption({ ...dataOption, [`lecturer${index}`]: data.data });
    });
  };

  const openAddLecturerSponsor = (id: string) => {
    const arrAssigner =
      !isEmpty(countSchoolSponsor) &&
      countSchoolSponsor[0].assigned.map((item: any, index: any) => {
        return item.assignUserId;
      });
    if (!isEmpty(countSchoolSponsor)) {
      form.setFieldsValue({
        [`lecturerName`]: arrAssigner,
      });
    }
    setInputVisible({ ...inputVisible, [`inputVisible`]: true });
    const data = {
      keyword: "",
      pageNumber: 0,
      pageSize: 0,
      enterpriseUUID: id,
    };
    getLecturersFromEnterprises(data).then(async (res) => {
      const { data } = res.data.data;

      //  let { data } = res.data.data;
      //   data = data.map((item: any) => {
      //     return {
      //       id: item.assignUserId,
      //       name: item.assignUserName,
      //       image: item.image
      //     }
      //   });
      setDataOption({ ...dataOption, [`lecturer`]: data });
    });
  };

  const updateInfoAccount = (index: number) => {
    const dataSelect = form.getFieldValue([`lecturerCooperativeName${index}`]);
    const data = dataSelect.map((item: any) => {
      const itemResult = dataOption[`lecturer${index}`].filter(
        (result: any) => result.assignUserId === item
      );
      return { ...itemResult };
    });

    const finishData = data.map((item: any, index: number) => {
      return { ...item[0] };
    });

    let arr = [...countSchool];
    arr[index].assigned = finishData;
    setCountSchool(arr);
    setInputVisible({ ...inputVisible, [`inputVisible${index}`]: false });
    form.resetFields([`lecturerCooperativeName${index}`]);
  };

  const removeSchool = (id: string) => {
    const arr = countSchool.filter((item: any, index: any) => {
      if (index === id) {
        item.display = false;
      }
      return item;
    });
    setCountSchool(arr);
  };

  const getValueTeacher = (value: any) => {
    console.log("value", value);
  };

  const closeItem = (index: number, item: any, enterpriseId: string) => {
    const finishData = [...countSchool];
    const arr = finishData.filter(
      (data: any) => data.enterpriseId === enterpriseId
    );
    if (!isEmpty(arr)) {
      const data = arr[0].assigned.filter(
        (result: any) => result.assignUserId !== item.assignUserId
      );
      arr[0].assigned = data;
      // setCountSchool(finishData)
    }
  };

  const updateSponsor = () => {
    const dataSelect = form.getFieldValue([`lecturerName`]);
    const data = dataSelect.map((item: any) => {
      const itemResult = dataOption[`lecturer`].filter(
        (result: any) => result.assignUserId === item
      );
      return { ...itemResult };
    });

    const finishData = data.map((item: any, index: number) => {
      return { ...item[0] };
    });

    let arr = [...countSchoolSponsor];
    if (!isEmpty(arr)) arr[0].assigned = finishData;
    setCountSchoolSponsor(arr);
    setInputVisible({ ...inputVisible, [`inputVisible`]: false });
    form.resetFields([`lecturerName`]);
  };

  const cancelUpdateSponsor = () => {
    setInputVisible({ ...inputVisible, [`inputVisible`]: false });
    form.resetFields([`lecturerName`]);
  };

  const cancelUpdate = (index: number) => {
    setInputVisible({ ...inputVisible, [`inputVisible${index}`]: false });
    form.resetFields([`lecturerCooperativeName${index}`]);
  };

  const renderListCooperationSchool = countSchool.map(
    (school: any, index: any) => (
      <div key={index}>
        {school.display ? (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={22}>
                    <Form.Item
                      name={`cooperativeSchool${index}`}
                      label="Trường hợp tác"
                    >
                      <FormItemDropdown
                        options={enterprises}
                        allowClear={true}
                        onChange={(value) => getValueCooperative(value, index)}
                        placeholder="Nhập từ khoá cần tìm"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="text-right">
                    <Tooltip placement="top" title={"Xoá"}>
                      <CloseOutlined
                        className="cursor-pointer"
                        onClick={() => removeSchool(index)}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Col>
              <Col span={22}>
                {!inputVisible[`inputVisible${index}`] ? (
                  <Flex gap="4px 8px" wrap="wrap">
                    {school?.assigned?.map((item: any) => (
                      <Tag
                        bordered={false}
                        closable
                        color="success"
                        key={item.assignUserId}
                        onClose={() =>
                          closeItem(index, item, school.enterpriseId)
                        }
                      >
                        {item.assignUserName}
                      </Tag>
                    ))}
                    <Tag
                      onClick={() =>
                        openAddLecturer(
                          index,
                          form.getFieldValue([`cooperativeSchool${index}`])
                        )
                      }
                    >
                      <PlusOutlined /> Thêm giảng viên
                    </Tag>
                  </Flex>
                ) : null}
                <Row>
                  <Col span={24}>
                    {inputVisible[`inputVisible${index}`] ? (
                      <div>
                        <Form.Item
                          name={`lecturerCooperativeName${index}`}
                          label="Tên giảng viên"
                          hasFeedback
                        >
                          <FormItemDropdown
                            modeDropdown="multiple"
                            options={dataOption[`lecturer${index}`]}
                            allowClear={true}
                            placeholder="Chọn giảng viên"
                          />
                        </Form.Item>
                        <Flex gap="0px 8px" wrap="wrap" className="-mt-2">
                          <Button
                            type="primary"
                            onClick={() => updateInfoAccount(index)}
                          >
                            Cập nhật
                          </Button>
                          <Button onClick={() => cancelUpdate(index)}>
                            Huỷ
                          </Button>
                        </Flex>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Col>
              {countSchool.length - 1 !== index ? <Divider /> : null}
            </Row>
          </>
        ) : null}
      </div>
    )
  );

  const getDefaultValue = async (value: any) => {
    let newArr = [...countSchoolSponsor];
    if (!isEmpty(newArr)) {
      newArr[0].enterpriseId = value;
      setCountSchoolSponsor(newArr);
    }

    form.resetFields(["lecturerName"]);
    const data = {
      keyword: "",
      pageNumber: 0,
      pageSize: 0,
      enterpriseUUID: value,
    };
    await getLecturersFromEnterprises(data).then((res) => {
      const { data } = res.data;
      setSponsorOptionLecturers(data.data);
    });
  };

  const getDetailCourseData = async () => {
    // try {
    // await getCourseDetail(id).then((res) => {
    // const { data } = res.data;
    const dataAssignersGet = detailData.assigners?.filter(
      (item: any) => item.isSponsor
    );
    const dataNotAssigners = detailData.assigners?.filter(
      (item: any) => !item.isSponsor
    );
    if (!isEmpty(dataNotAssigners)) {
      const arr = dataNotAssigners?.map((item: any) => {
        return { ...item, [`display`]: true };
      });
      setCountSchool(arr);
    }

    if (!isEmpty(dataAssignersGet)) {
      setCountSchoolSponsor(dataAssignersGet);
      getDefaultValue(dataAssignersGet?.[0]?.enterpriseId);
    }
    setDataAssigners(dataAssignersGet);
    setDataNotAssigners(dataNotAssigners);
    form.setFieldValue("hostSchool", dataAssignersGet?.[0]?.enterpriseId);
    form.setFieldValue(
      "cooperativeSchool0",
      !isEmpty(dataNotAssigners?.[0]) && dataNotAssigners?.[0]?.enterpriseId
    );
    form.setFieldValue(
      "cooperativeSchool1",
      !isEmpty(dataNotAssigners?.[1]) && dataNotAssigners?.[1]?.enterpriseId
    );
    form.setFieldValue(
      "cooperativeSchool2",
      !isEmpty(dataNotAssigners?.[2]) && dataNotAssigners?.[2]?.enterpriseId
    );
    form.setFieldValue(
      "cooperativeSchool3",
      !isEmpty(dataNotAssigners?.[3]) && dataNotAssigners?.[3]?.enterpriseId
    );
    form.setFieldValue(
      "cooperativeSchool4",
      !isEmpty(dataNotAssigners?.[4]) && dataNotAssigners?.[4]?.enterpriseId
    );
    // });
    // } catch (error) {}
  };

  return (
    <>
      <DrawerCustom
        title={id && id !== 0 ? "Chỉnh sửa khảo sát" : "Thêm mới khảo sát"}
        open={isOpened}
        onClose={() => {
          setDetailData({});
          setIsOpened(false);
          form.resetFields();
          console.log("closed");
        }}
        extraNode={
          <Space>
            <Button
              onClick={() => {
                setIsOpened(false);
                setDetailData({});
                form.resetFields();
              }}
              className="btn btn-outlined"
            >
              <CloseOutlined />
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={onStartInputSurvey}
            >
              <SaveOutlined />
              <Typography.Text>Lưu</Typography.Text>
            </Button>
          </Space>
        }
        widthDrawer={736}
      >
        <Form
          className="form-create-course"
          layout={"vertical"}
          form={form}
          onFinish={onFinish}
          name="create-course-detail"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                rules={[validations.name]}
                label="Tên đợt khảo sát"
              >
                <FormInput placeholder="Nhập tên đợt khảo sát" />
                {/* <span className="label-down-text">
                Tên hiển thị công khai cho khảo sát của bạn
              </span> */}
              </Form.Item>
              <span className="label-down-text">
                Tên hiển thị công khai cho khảo sát của bạn
              </span>
            </Col>
            <Col span={24}>
              <Form.Item
                name="industryGroup"
                label="Khoa/nhóm ngành"
                rules={[validations.department]}
              >
                <FormItemDropdown
                  // priority="code"
                  modeDropdown="multiple"
                  options={industryGroup}
                  allowClear={true}
                  placeholder="Chọn khoa/nhóm ngành khảo sát áp dụng"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="industry"
                label="Chuyên ngành"
                rules={[validations.field]}
              >
                <FormItemDropdown
                  // priority="code"
                  modeDropdown="multiple"
                  options={industry}
                  allowClear={true}
                  placeholder="Chọn chuyên ngành áp dụng"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="coursePlanId" label="Loại khảo sát">
                <FormItemDropdown
                  options={coursePlan}
                  allowClear={true}
                  placeholder="Chọn loại khảo sát muốn đồng bộ"
                  onChange={(value) => {
                    if (value) setHadPlan(true);
                    else setHadPlan(false);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </DrawerCustom>
    </>
  );
};
