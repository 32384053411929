import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Row, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { confirmMessage } from "../../../../pages/page-builder/grapesjs-service";
import { useEffect, useState } from "react";
import { UploadProps } from "antd/lib";
import FormInputText from "../../../form-input-text/FormInputText";

interface CriteriaTextProps {
  index: number;
  value?: string;
  onChange: (val: { id: number, value: string }) => void;
  onDelete: (id: number) => void;
}

function FileUploadCriteria({
  index,
  value,
  onChange,
  onDelete,
}: CriteriaTextProps) {
  const [textValue, setTextValue] = useState<string>("");
  const handleChangeText = (changeValue: string) => {
    setTextValue(changeValue);
    onChange({ id: index, value: changeValue });
  };

  const handleDeleteCriteria = (index: number) => {
    confirmMessage(
      'Xóa tiêu chí',
      0,
      'Bạn có chắc muốn xóa tiêu chí này?',
      () => {
        onDelete(index)
      }
    );
  }

  useEffect(() => {
    if (value) setTextValue(value);
  }, [value]);

  return (
    <div className="question-by-type flex-1 mb-2">
      <div className="flex align-center">
        <FormInputText
          // name="scoreCriteria"
          value={textValue}
          onChange={handleChangeText}
          placeholder="Nhập tiêu chí tính điểm"
          autoSize={{ minRows: 1, maxRows: 6 }}
          containerClassName="w-full mb-1"
          label=""
          type="textarea"
        />
        <div className="mb-1 ml-2">
          <Button
            className="delete-btn"
            onClick={() => handleDeleteCriteria(index)}
            icon={<DeleteOutlined />}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default FileUploadCriteria;
