export const template1 = `
     
        <template-certificate1 class="temple-container" title="temp1">
            <template-group class="group">
            <div class="title">
            CERTIFICATE OF APPRECIATION
</div>
<div class="certificate-sub-title">
THIS CERTIFICATE IS PRESENTED TO
</div>
<div class="surname" contenteditable="false">
Name Surname
</div>
<div class="company-name">
THE COMPANY NAME
</div>
<div class="certificate-content">
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias amet architecto consequuntur deserunt dicta, dolore dolorum earum eligendi esse impedit itaque magnam molestiae necessitatibus, nisi quod sint suscipit velit voluptas.
</div>
<div class="group-date">
    <div class="date" contenteditable="false">
       
        DATE
    </div>
    <div class="certificate-signature" aria-readonly="true" contenteditable="false"> 
    SIGNATURE
     
</div>
</div>
</template-group>
        </template-certificate1>
    
    <style>
    .temple-container {
        background-image: url('/images/group-2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 650px;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        position: relative;
   }
   .group {
    margin: 0 auto;
    width: 600px;
    max-width: 100%;
    position: relative;
    height: 315px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
   }
    .title {
    margin-top: 40px;
    color: #305B68
    font-size: 18px;
    }
    .certificate-sub-title {
      margin-top: 12px;
      font-size: 13px;
      color: #E5A931;
    }
    
    .surname {
        margin-top: 40px;
        color: #305B68;
        font-size: 32px;
    }
    .company-name {
    margin-top: 12px;
   color: #E5A931;
   font-size: 13px;;
    }
    .certificate-content {
      margin-top: 5px;
      color: #939E9D;
      font-size: 12px;
    }
    .group-date {
        margin-top: 60px;
        display: flex;
        justify-content: space-evenly;
      
    }
    .date,
     .certificate-signature {
      color: #E5A931;
    }
    
   
    
    
</style>
`
export const template2 = `
     
        <template-certificate1 class="temple-container" title="temp1">
            <template-group class="group">
            <div class="title">
            CERTIFICATE OF APPRECIATION
</div>
<div class="certificate-sub-title">
THIS CERTIFICATE IS PRESENTED TO
</div>
<div class="surname" contenteditable="false">
Name Surname 2
</div>
<div class="company-name">
THE COMPANY NAME 2
</div>
<div class="certificate-content">
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias amet architecto consequuntur deserunt dicta, dolore dolorum earum eligendi esse impedit itaque magnam molestiae necessitatibus, nisi quod sint suscipit velit voluptas.
</div>
<div class="group-date">
    <div class="date" contenteditable="false">
       
        DATE
    </div>
    <div class="certificate-signature" aria-readonly="true" contenteditable="false"> 
    SIGNATURE
     
</div>
</div>
</template-group>
        </template-certificate1>
    
    <style>
    .temple-container {
        background-image: url('/images/group-3.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 650px;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        position: relative;
   }
   .group {
    margin: 0 auto;
    width: 600px;
    max-width: 100%;
    position: relative;
    height: 315px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
   }
    .title {
    margin-top: 40px;
    color: #305B68
    font-size: 18px;
    }
    .certificate-sub-title {
      margin-top: 12px;
      font-size: 13px;
      color: #E5A931;
    }
    
    .surname {
        margin-top: 40px;
        color: #305B68;
        font-size: 32px;
    }
    .company-name {
    margin-top: 12px;
   color: #E5A931;
   font-size: 13px;;
    }
    .certificate-content {
      margin-top: 5px;
      color: #939E9D;
      font-size: 12px;
    }
    .group-date {
        margin-top: 60px;
        display: flex;
        justify-content: space-evenly;
      
    }
    .date,
     .certificate-signature {
      color: #E5A931;
    }
    
   
    
    
</style>
`

export const template3 = `
     
        <template-certificate1 class="temple-container" title="temp1">
            <template-group class="group">
            <div class="title">
            CERTIFICATE OF APPRECIATION
</div>
<div class="certificate-sub-title">
THIS CERTIFICATE IS PRESENTED TO
</div>
<div class="surname" contenteditable="false">
Name Surname
</div>
<div class="company-name">
THE COMPANY NAME
</div>
<div class="certificate-content">
Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias amet architecto consequuntur deserunt dicta, dolore dolorum earum eligendi esse impedit itaque magnam molestiae necessitatibus, nisi quod sint suscipit velit voluptas.
</div>
<div class="group-date">
    <div class="date" contenteditable="false">
       
        DATE
    </div>
    <div class="certificate-signature" aria-readonly="true" contenteditable="false"> 
    SIGNATURE
     
</div>
</div>
</template-group>
        </template-certificate1>
    
    <style>
    .temple-container {
        background-image: url('/images/group-4.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 650px;
        width: 90%;
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        position: relative;
   }
   .group {
    margin: 0 auto;
    width: 600px;
    max-width: 100%;
    position: relative;
    height: 315px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
   }
    .title {
    margin-top: 40px;
    color: #305B68
    font-size: 18px;
    }
    .certificate-sub-title {
      margin-top: 12px;
      font-size: 13px;
      color: #E5A931;
    }
    
    .surname {
        margin-top: 40px;
        color: #305B68;
        font-size: 32px;
    }
    .company-name {
    margin-top: 12px;
   color: #E5A931;
   font-size: 13px;;
    }
    .certificate-content {
      margin-top: 5px;
      color: #939E9D;
      font-size: 12px;
    }
    .group-date {
        margin-top: 60px;
        display: flex;
        justify-content: space-evenly;
      
    }
    .date,
     .certificate-signature {
      color: #E5A931;
    }
    
   
    
    
</style>
`
