import { TableColumnsType, Typography, Form } from "antd";
import { formatDateV2 } from "../../../../../utils/converDate";
import TableData from "../../../../table-data/TableData";
import { TableProps } from "antd/lib";
import { useEffect, useState, KeyboardEvent, ChangeEvent } from "react";
import { isEmpty } from "lodash";
import FilterTable from "../../components/filter-table/FilterTable";
import FormFilter from "../../components/filter-group/FormFilter";
import './universityHistory.scss'
import { getUniversityrDetailByFilter } from "../../../../../service/category-management/university-management";

interface DataType {
    key: React.Key;
    id: number;
    action: string;
    description: string;
    createdBy: string;
    createdDate: string;
}

interface UniversityHistoryProps {
    idUniversity: number;
}

function UniversityHistory({idUniversity}: UniversityHistoryProps) {
    const [statusOrder, setStatusOrder] = useState<string>("");
    const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [dataLecturer, setDataLecturer] = useState<any>([]);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [searchValue, setSearchValue] = useState<string>("");
    const [filterData, setFilterData] = useState<any>({
        keyword: null,
        status: null,
        createdDateFrom: null,
        createdDateTo: null,
    });
    const columns: TableColumnsType<DataType> = [
        {
        title: "ID",
        dataIndex: "id",
        className: "survey-form-id",
        sorter: (a, b) => a.id - b.id,
        },
        {
        title: "Thời gian",
        dataIndex: "createdDate",
        className: "survey-form-date",
        sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
        render: (_: any, record) => {
            return (
            <>{record.createdDate ? formatDateV2(record.createdDate) : null}</>
            );
        },
        },
        {
        title: "Mô tả",
        dataIndex: "description",
        },
        {
        title: "Tài khoản thực hiện",
        dataIndex: "createdByName",
        sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
        },
        {
        title: "Thao tác",
        dataIndex: "name",
        sorter: (a, b) => a.action.localeCompare(b.action),
        },

    ];

    const getDataHistoryUniversityDetail = () => {
        setLoading(true);
        const dataReq = {
            page: pageNumber,
            size: pageSize,
            sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ["created_date,desc"] : [`${fieldSortOrder}
                ,${statusOrder}`],
            keyword: searchValue,
            userId: filterData.userId,
            universityId: idUniversity,
            createdDateFrom: filterData.createdDateFrom ? filterData.createdDateFrom : null,
            createdDateTo: filterData.createdDateTo ? filterData.createdDateTo : null,
        };
        getUniversityrDetailByFilter(dataReq)
        .then((res) => {
            setDataLecturer(res.data.data.data);
            setTotalRecords(res.data.data.total);
            setTimeout(() => {
            setLoading(false);
            }, 300);
        })
        .catch((err) => {
            console.error(err);
            setLoading(false);
        })
    }

    useEffect(() => {
        getDataHistoryUniversityDetail();
    }, [pageNumber,pageSize,filterData,statusOrder]);

    const handleTableChange: TableProps["onChange"] = (
        pagination,
        filters,
        sorter: any
    ) => {
        const data =
        sorter.order === "ascend"
            ? "asc"
            : sorter.order === "descend"
            ? "desc"
            : "";
        setStatusOrder(data);
        setFieldSortOrder(sorter.field);
    };

    const handleOpenFilter = () => {
        setShowFilter((showFilter) => !showFilter);
    };

    const handleCloseFilter = () => {
        setShowFilter(false);
    };
    const clearFilter = () => {
        form.resetFields();
        setFilterData({
            createdDateFrom: "",
            createdDateTo: "",
        });
    };

    const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
        if (e.key === "Enter") {
            setPageNumber(1);
            getDataHistoryUniversityDetail();
        }
    };
    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchValue(value);
    };

    const handleSubmitSearch = () => {
        setPageNumber(1);
        getDataHistoryUniversityDetail();
    };

    const onFinish = (values: any) => {
        setFilterData({
            ...filterData,
            createdDateFrom: values?.rangeDate ? (values?.rangeDate[0]).add(1, 'day').toISOString() : "",
            createdDateTo: values?.rangeDate ? (values?.rangeDate[1]).add(1, 'day').toISOString() : "",
          });
    };
    return (
        <div className="wrapper-settings-history">
            <section className="history-page">
            <div className="history-header">
                <Typography.Title className="history-title" style={{ margin: 0 }}>
                Lịch sử thao tác
                </Typography.Title>
                <FilterTable
                handleOpenFilter={handleOpenFilter}
                showFilter={showFilter}
                searchValue={searchValue}
                handleSearch={handleSearch}
                handleChangeInput={handleChangeInput}
                handleSubmitSearch={handleSubmitSearch}
                />
            </div>
            <div className="filter-detail">
                <FormFilter
                handleCloseFilter={handleCloseFilter}
                clearFilter={clearFilter}
                form={form}
                showFilter={showFilter}
                filterData={filterData}
                onFinish={onFinish}
                />
            </div>
            <div className="tabs-heading">
                <TableData
                dataTable={dataLecturer}
                dataColumns={columns}
                setPageNumber={setCurrentPage}
                setPageSize={setPageSize}
                pageNumber={currentPage}
                pageSize={pageSize}
                loadingTable={loading}
                scroll={{ x: 1500, y: 600 }}
                totalRecords={totalRecords}
                handleTableChangeProps={handleTableChange}
                pagination={true}
                />
            </div>
            </section>
    </div>
    )
}

export default UniversityHistory
