// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-radio {
  border-start-start-radius: 0 !important;
  border-start-end-radius: 0 !important;
  border-end-start-radius: 0 !important;
  border-end-end-radius: 0 !important; }
`, "",{"version":3,"sources":["webpack://./src/components/form-radio/form-radio.scss"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,qCAAqC;EACrC,qCAAqC;EACrC,mCAAmC,EAAA","sourcesContent":[".form-radio {\n  border-start-start-radius: 0 !important;\n  border-start-end-radius: 0 !important;\n  border-end-start-radius: 0 !important;\n  border-end-end-radius: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
