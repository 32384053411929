import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  Button,
  DatePicker,
  Form,
  MenuProps,
  Select,
  Switch,
  Typography,
} from "antd";
import "./custom-list.scss";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { get, isArray, isEmpty, values } from "lodash";
import dayjs from "dayjs";
import { FormInstance } from "antd/lib";
import { phoneNumberRegex, regexEmail } from "../../../../../../utils/regex";
import {
  getDistricts,
  getWards,
} from "../../../../../../service/info-detail/infoDetailApi";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import FormItemInput from "../../../../../../components/form-input/FormInput";
import { convertArrayToString } from "../../../../../../utils/arrays";

type CustomListProps = {
  title?: string;
  name?: string;
  description?: string | Array<string> | boolean;
  editFieldName?: string;
  fieldValue?: string | never[] | boolean | any;
  options?: Array<unknown> | any;
  isActiveEdit?: string | boolean | string[];
  actions?: ReactNode;
  closeActions?: () => void;
  languageOptions?: Array<any>;
  timeOptions?: Array<any>;
  data?: any;
  setLocation?: (data: any) => void;
  form?: FormInstance;
};

const { Option } = Select;

const validateForm = {
  email: {
    validator: (_: unknown, value: any) => {
      if (!isEmpty(value) && !value.match(new RegExp(regexEmail))) {
        return Promise.reject(
          new Error("Định dạng email không đúng, vui lòng nhâp lại")
        );
      }
      if (isEmpty(value)) {
        return Promise.reject(new Error("Vui lòng nhập địa chỉ email"));
      }
      return Promise.resolve();
    },
  },
  phone: {
    validator: (_: unknown, value: any) => {
      if (!isEmpty(value) && !value.match(new RegExp(phoneNumberRegex))) {
        return Promise.reject(
          new Error("Định dạng số điện thoại không đúng, vui lòng nhâp lại")
        );
      }
      if (!isEmpty(value) && (value.length > 11 || value.length < 9)) {
        return Promise.reject(
          new Error("Độ dài số điện thoại không đúng, vui lòng kiểm tra lại")
        );
      }
      return Promise.resolve();
    },
  },
};

const CustomList: FunctionComponent<CustomListProps> = ({
  title,
  name,
  description,
  fieldValue,
  editFieldName,
  options,
  actions,
  closeActions,
  languageOptions,
  timeOptions,
  form,
  setLocation,
}) => {
  const [provinceCode, setProvinceCode] = useState("");
  const [districtCode, setDistrictCode] = useState("");
  const [dataWards, setDataWards] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);

  const handleChangeValue = (value: any) => {
    setDistrictCode(value);
  };

  const handleChangeProvince = (value: any) => {
    setProvinceCode(value);
  };

  useEffect(() => {
    if (districtCode) {
      getWards(districtCode, "").then((res) => {
        const { data } = res.data;
        setDataWards(data);
      });
    }
    if (provinceCode) {
      getDistricts(provinceCode, "").then((res) => {
        const { data } = res.data;
        setDataDistrict(data);
      });
    }
  }, [districtCode, provinceCode]);

  const handleLocationChange = (e: any) => {};

  const renderFormFields = (name: string | undefined, value: any | Date) => {
    // console.log('Giá trị:', value);
    const handleChange = () => {};
    if (typeof value === "boolean") {
      return (
        <Form.Item name={name}>
          <Switch onChange={handleChange} />
        </Form.Item>
      );
    }
    if (Array.isArray(fieldValue) && name === "vaiTros") {
      return (
        <Form.Item name={name}>
          <FormItemDropdown
            value={value}
            modeDropdown="multiple"
            options={get(options, "dataRoles")}
          />
        </Form.Item>
      );
    }
    if (Array.isArray(fieldValue) && name === "chucVus") {
      return (
        <Form.Item name={name}>
          <FormItemDropdown
            modeDropdown="multiple"
            options={get(options, "dataOffices", "")}
          />
        </Form.Item>
      );
    }
    if (Array.isArray(fieldValue) && name === "donViCongTac") {
      return (
        <Form.Item name={name}>
          <FormItemDropdown
            value={value}
            options={get(options, "dataUnits", "")}
          />
        </Form.Item>
      );
    }
    if (Array.isArray(fieldValue) && name === "ngonNgu") {
      return (
        <Form.Item name={name}>
          <FormItemDropdown options={get(options, "dataLanguages", "")} />
        </Form.Item>
      );
    }
    if (Array.isArray(fieldValue) && name === "muiGio") {
      return (
        <Form.Item name={name}>
          <FormItemDropdown options={get(options, "dataTimeZone", "")} />
        </Form.Item>
      );
    }
    if (Array.isArray(fieldValue)) {
      return (
        <Form.Item name={name}>
          <FormItemDropdown
            value={value}
            modeDropdown="multiple"
            options={options}
          />
        </Form.Item>
      );
    }

    if (name === "ngaySinh") {
      return (
        <Form.Item name="ngaySinh" getValueProps={(i) => ({ value: dayjs(i) })}>
          <DatePicker
            format="DD/MM/YYYY"
            value={!isEmpty(value) ? dayjs(value) : null}
            allowClear={false}
            className="form-select--date"
          />
        </Form.Item>
      );
    }

    if (name === "diaChi") {
      return (
        <>
          <Form.Item name="diaChi">
            <Form.Item
              name="thanhPho"
              label="Tỉnh/Thành phố"
              style={{ marginBottom: "12px" }}
            >
              <FormItemDropdown
                onChange={handleChangeProvince}
                options={get(options, "dataProvinces", "")}
              />
            </Form.Item>
            <Form.Item
              name="quan"
              label="Quận/Huyện"
              style={{ marginBottom: "12px" }}
            >
              <FormItemDropdown
                onChange={handleChangeValue}
                options={dataDistrict}
              />
            </Form.Item>
            <Form.Item
              name="phuong"
              label="Phường/Xã"
              style={{ marginBottom: "12px" }}
            >
              <FormItemDropdown options={dataWards} />
            </Form.Item>
            <Form.Item name="diaChiCuThe" label="Địa chỉ cụ thể">
              <FormItemInput onChange={handleLocationChange} />
            </Form.Item>
          </Form.Item>
        </>
      );
    }

    if (name === "gioiTinh") {
      return (
        <Form.Item name="gioiTinh">
          <Select>
            <Option value="Nam">Nam</Option>
            <Option value="Nữ">Nữ</Option>
            <Option value="Khác">Khác</Option>
          </Select>
        </Form.Item>
      );
    }

    if (typeof value === "string") {
      return (
        <Form.Item
          name={name}
          rules={
            name === "email"
              ? [validateForm.email]
              : name === "dienThoai"
              ? [validateForm.phone]
              : []
          }
        >
          <FormItemInput />
        </Form.Item>
      );
    }
  };

  return (
    <div className="course-plan-managment-custom-list">
      <div className="list-wrapper">
        <div className="list-item">
          <Typography.Title level={5} className="list-item--title">
            {title}
          </Typography.Title>
          <div className="list-item--description">
            {name === editFieldName ? (
              renderFormFields(name, fieldValue)
            ) : name === "ngaySinh" && typeof description === "string" ? (
              dayjs(description).format("DD/MM/YYYY").toString()
            ) : (
              <Typography.Text className="content">
                {typeof description === "boolean"
                  ? !description
                    ? "Không hoạt động"
                    : "Đang hoạt động"
                  : isArray(description)
                  ? convertArrayToString(description)
                  : description}
              </Typography.Text>
            )}
          </div>
          {actions && <>{actions}</>}
        </div>
      </div>
    </div>
  );
};

export default CustomList;
