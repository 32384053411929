// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Action_Action__YoYY2 {
  display: flex;
  width: 16px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent; }
  .Action_Action__YoYY2 svg {
    flex: 0 0 auto;
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: visible; }
  .Action_Action__YoYY2:active {
    background-color: var(--background, rgba(0, 0, 0, 0.05)); }
    .Action_Action__YoYY2:active svg {
      fill: var(--fill, #788491); }
  .Action_Action__YoYY2:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe; }
`, "",{"version":3,"sources":["webpack://./src/components/multiple-containers/components/Item/components/Action/Action.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,WAAW;EAEX,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,kBAAkB;EAClB,8BAA8B;EAE9B,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,6BAA6B;EAC7B,wCAAwC,EAAA;EAd1C;IAiBI,cAAc;IACd,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,iBAAiB,EAAA;EArBrB;IAyBI,wDAAwD,EAAA;IAzB5D;MA4BM,0BAA0B,EAAA;EA5BhC;IAiCI,aAAa;IACb,mEApC2B,EAAA","sourcesContent":["$focused-outline-color: #4c9ffe;\n\n.Action {\n  display: flex;\n  width: 16px;\n  // padding: 15px;\n  align-items: center;\n  justify-content: center;\n  flex: 0 0 auto;\n  touch-action: none;\n  cursor: var(--cursor, pointer);\n  // border-radius: 5px;\n  border: none;\n  outline: none;\n  appearance: none;\n  background-color: transparent;\n  -webkit-tap-highlight-color: transparent;\n\n  svg {\n    flex: 0 0 auto;\n    margin: auto;\n    width: 100%;\n    height: 100%;\n    overflow: visible;\n  }\n\n  &:active {\n    background-color: var(--background, rgba(0, 0, 0, 0.05));\n\n    svg {\n      fill: var(--fill, #788491);\n    }\n  }\n\n  &:focus-visible {\n    outline: none;\n    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0),\n      0 0px 0px 2px $focused-outline-color;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Action": `Action_Action__YoYY2`
};
export default ___CSS_LOADER_EXPORT___;
