// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-record {
  margin: 15px 0 25px 0; }
  .upload-record button {
    width: 100% !important;
    pointer-events: none; }

.criteria {
  width: 100%; }

.val-file-upload {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45); }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/question-item/file-upload/style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAqB,EAAA;EADvB;IAII,sBAAsB;IACtB,oBAAoB,EAAA;;AAKxB;EACE,WAAW,EAAA;;AAGb;EACE,eAAe;EACf,0BAA0B,EAAA","sourcesContent":[".upload-record {\n  margin: 15px 0 25px 0;\n\n  button {\n    width: 100% !important;\n    pointer-events: none;\n  }\n}\n\n\n.criteria {\n  width: 100%;\n}\n\n.val-file-upload {\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.45);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
