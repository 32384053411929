// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-course-construct {
  width: 100%; }
  .tree-course-construct .course-list .course-list--item {
    justify-content: start;
    align-items: start;
    padding: 10px; }
    .tree-course-construct .course-list .course-list--item .sortableList-card {
      width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/list-course-construction/tree-course-construct/TreeCourseConstruct.scss"],"names":[],"mappings":"AAAA;EACI,WAAW,EAAA;EADf;IAKY,sBAAsB;IAEtB,kBAAkB;IAClB,aAAa,EAAA;IARzB;MAcgB,WAAW,EAAA","sourcesContent":[".tree-course-construct {\n    width: 100%;\n    .course-list {\n        \n        .course-list--item {\n            justify-content: start;\n            // width: 100%;\n            align-items: start;\n            padding: 10px;\n            \n            .sortableList-card{\n\n                // display: flex;\n                // flex-direction: column;\n                width: 100%; \n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
