import React, { useState } from 'react'

type Props = {
  setValue?: (value: string) => void
  setRefresh?: (value: boolean) => void
  refresh?: boolean
}

export default function SearchInputBlock(props: any) {
  const {setValue, setRefresh, refresh} = props

  const handleSearchBlog = () => {
    setRefresh(!refresh)
  }
  return (
    <div className="flex gap-4 mt-3" component-className="SearchInputBlock">
      <input type="text" onChange={(e) => setValue(e.target.value)} placeholder="Nhập từ khoá cần tìm" id="i8ujj" className="w-full px-4 py-2 text-gray-700 focus:outline-none mr-2 rounded" />
      <button id="iytk3" className="text-white px-4 py-2 hover:bg-gray-700 rounded bg-blue-400 whitespace-pre" onClick={handleSearchBlog}>Tìm kiếm</button>
    </div>
  )
}
