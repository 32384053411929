import React, {useEffect, useMemo, useState, useTransition} from "react";
import OverviewChartTab from "../chart/overview-tab/OverviewChartTab";
import OverviewTable from "../table/overview-table";
import dayjs from "dayjs";
import {staticReportStore} from "../../../../../stores/stores";
import {
  getAdminVisitors,
  getAdminVisits,
  getCourseByUniversitySponsor,
  getStudentByUniversity,
  getSynthesizeDataUniversity,
  getTeacherByUniversity,
  getUnitResources, getUnitResourcesTable,
  getUniversityRealtion,
  getVisitsAndVisitors,
  getVisitsAndVisitorsByRegister
} from "../../../../../service/statistical-report";
import {debounce} from "lodash";
import {BarStackedChartProps, DualChart, PieChart} from "../../../../../types/static-report";

type Props = {
  dataType: string;
};

const OverviewTab = ({dataType}: Props) => {
  const [isPending, startTransition] = useTransition();
  const {staticReportType, filterValues, tab} = staticReportStore();

  const [chartData, setChartData] = useState({
    visitsAndVisitors: [] as DualChart[],
    visitsAndVisitorsByRegister: [] as PieChart[],
    adminVisits: [] as Array<any>,
    adminVisitors: [] as Array<any>,
    universityRelation: [] as Array<any>,
    teacherByUniversity: [] as BarStackedChartProps[],
    studentByUniversity: [] as BarStackedChartProps[],
    courseByUniversitySponsor: [] as BarStackedChartProps[],
    unitResources: [] as Array<any>,
    unitResourceTable: [] as Array<any>,
    synthesizeDataUniversity: [] as Array<any>,
  });

  const [loading, setLoading] = useState({
    visitsAndVisitors: false,
    visitsAndVisitorsByRegister: false,
    universityRelationList: false,
    teacherByUniversity: false,
    studentByUniversity: false,
    courseByUniversitySponsor: false,
    unitResources: false,
  });

  const fetchData = async () => {
    const payload = {
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: filterValues.universityIds,
      timeUnit: filterValues.timeUnit
    };
    try {
      const apiCall = [
        {
          key: "visitsAndVisitors",
          api: getVisitsAndVisitors,
          params: payload,
        },
        {
          key: "visitsAndVisitorsByRegister",
          api: getVisitsAndVisitorsByRegister,
          params: payload,
        },
        {
          key: "universityRelation",
          api: getUniversityRealtion,
          params: payload,
        },
        {
          key: "teacherByUniversity",
          api: getTeacherByUniversity,
          params: payload,
        },
        {
          key: "studentByUniversity",
          api: getStudentByUniversity,
          params: payload,
        },
        {
          key: "adminVisits",
          api: getAdminVisits,
          params: payload,
        },
        {
          key: "adminVisitors",
          api: getAdminVisitors,
          params: payload,
        },
        {
          key: "courseByUniversitySponsor",
          api: getCourseByUniversitySponsor,
          params: payload,
        },
        {
          key: "unitResources",
          api: getUnitResources,
          params: payload,
        },
        {
          key: 'unitResourceTable',
          api: getUnitResourcesTable,
          params: payload,
        },
        {
          key: "synthesizeDataUniversity",
          api: getSynthesizeDataUniversity,
          params: payload,
        },
      ]
      await Promise.allSettled(
        apiCall.map(async ({key, api, params}) => {
          setLoading((prev) => ({ ...prev, [key]: true }));
          try {
            const response = await api(params);
            startTransition(() => {
              setChartData((prevState) => ({
                ...prevState,
                [key]: response?.data?.data?.statistic || response?.data?.data,
              }));
            })
          } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
            startTransition(() => {
              setChartData(prevState => ({
                ...prevState,
                [key]: [], // Provide fallback empty data
              }));
            });
          } finally {
            setLoading((prev) => ({ ...prev, [key]: false }));
          }
        })
      );
    } catch (err) {
      console.error(err)
    }
  }

  const debouncedFetchData = useMemo(
    () => debounce(fetchData, 500),
    [filterValues]
  );

  useEffect(() => {
    if (tab === "1") {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  const memoizedChartData = useMemo(() => {
    return {
      visitsAndVisitors: chartData.visitsAndVisitors,
      visitsAndVisitorsByRegister: chartData.visitsAndVisitorsByRegister,
      adminVisits: chartData.adminVisits,
      adminVisitors: chartData.adminVisitors,
      universityRelationList: chartData.universityRelation,
      teacherByUniversity: chartData.teacherByUniversity,
      studentByUniversity: chartData.studentByUniversity,
      courseByUniversitySponsor: chartData.courseByUniversitySponsor,
      unitResources: chartData.unitResources,
      unitResourceTable: chartData.unitResourceTable,
      synthesizeDataUniversity: chartData.synthesizeDataUniversity,
    };
  }, [chartData]);

  return (
    dataType === "1" ?
      <OverviewChartTab {...memoizedChartData} loading={loading}/> :
      <OverviewTable {...memoizedChartData} loading={isPending}/>
  );
};

export default OverviewTab;
