import React, {ChangeEvent, KeyboardEvent, useEffect, useState, useTransition} from 'react';
import TableData from "../../../../table-data/TableData";
import PreviewDetail from "../preview-detail";
import {getQuizById} from "../../../../../service/course-construct";
import {Form, Space, TableColumnsType, TableProps, Typography} from "antd";
import HtmlToText from '../../../../html-to-text';
import {getQuizSurvey} from "../../../../../service/course-construct/survey-plan";
import {EyeOutlined} from "@ant-design/icons";
import FilterTableSurveyQuestion from "./components/filter-table-survey-question";
import FormFilterSurveyQuestion from "./components/filter-group-survey-question";
import './index.scss'
import {useLocation} from "react-router-dom";
import {isNull} from "lodash";
import { convertQuillToString } from '../../../../../utils/convertQuillToString';

type SurveyQuestionProps = {
  id: number
  tab: any,
}

const renderType = (value: string) => {
  switch (Number(value)) {
    case 1:
      return "Nhiều lựa chọn văn bản"
    case 2:
      return "Nhiều lựa chọn ảnh"
    case 3:
      return "Nhiều lựa chọn video"
    case 4:
      return "Nhiều lựa chọn đúng sai"
    case 5:
      return "Danh sách"
    case 6:
      return "Nhiều đáp án văn bản"
    case 7:
      return "Nhiều đáp án hình ảnh"
    case 9:
      return "Câu trả lời ngắn"
    case 10:
      return "Điền vào chỗ trống"
    case 11:
      return "Trình soạn thảo"
    case 12:
      return "Kết nối"
    case 13:
      return "Sắp xếp"
    case 14:
      return "Ghi âm"
    case 15:
      return "Ghi hình"
    case 16:
      return "Đính kèm"
    default:
      return "Bản nháp"
  }
}


const SurveyQuestionTab = ({id, tab}: SurveyQuestionProps) => {
  const [form] = Form.useForm()
  const location = useLocation()
  const {idEdit} = location.state
  const [isPending, startTransition] = useTransition()
  const [dataContent, setDataContent] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [isOpenPreviewDetail, setIsOpenPreviewDetail] = useState(false);
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  const [isClearFiltered, setIsClearFiltered] = useState(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const [listQuestion, setListQuestion] = useState<any>([])


  const [dataFilter, setDataFilter] = useState({
    surveyPlanFormIds: [],
    isShow: null
  })


  const getQuizSurveyPlan = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim(),
      surveyPlanId: id,
      isShow: dataFilter.isShow,
      sortBy: statusOrder
    }
    try {
      const res = await getQuizSurvey(dataReq)
      const {data, success} = res.data
      if (success) {
        startTransition(() => {
          setListQuestion(isNull(data) ? [] : data.content)
          setTotalRecords(data?.totalElements)
          setIsSubmitSearch(false)
          setPageSize(data.size)
        })
      }
    } catch (err) {
      console.error(err)
    }
  }


  const getDetailQuizSurveyPlan = async (id?: number) => {
    try {
      const res = await getQuizById(id?.toString());
      const {data, status} = res
      startTransition(() => {
        if (status === 200) {
          setDataContent(data.body)
        }
      })
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (id && tab === '5') {
      getQuizSurveyPlan()
    }
  }, [tab, pageSize, pageNumber, id, isSubmitSearch, isClearFiltered, statusOrder]);


  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "question,asc"
        : sorter.order === "descend"
          ? "question,desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const dataColumnQuestions: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder ?? 1}</div>;
      },
    },
    {
      title: "Câu hỏi",
      dataIndex: "question",
      key: "question",
      sorter: true,
      render: (_: any, record, index: number) => {
        return convertQuillToString(record?.question);
      },
      width: 30,
    },
    {
      title: "Loại câu hỏi",
      dataIndex: "quizType",
      key: "quizType",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{renderType(record?.quizType)}</div>;
      },
    },
    {
      title: "Khảo sát",
      dataIndex: "surveyFormName",
      key: "surveyFormName",
      width: 10,
    },
    {
      title: "Hiển thị",
      dataIndex: "isShow",
      key: "isShow",
      width: 10,
      render: (_: any, record, index: number) => {
        return <span>{record.isShow ? 'Hiển thị' : 'Ẩn'}</span>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenPreviewDetail(true)
                  getDetailQuizSurveyPlan(record.quizId)
                }}
              >
                <EyeOutlined/>
              </a>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 5,
    },
  ]

  const handleOpenFilter = () => {
    setShowFilter((showFilter: any) => !showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    form.resetFields();
    setDataFilter({
      surveyPlanFormIds: [],
      isShow: null
    });
    setIsClearFiltered((prev: any) => !prev)
  };
  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      setIsSubmitSearch(true)
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchValue(value);
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    setIsSubmitSearch(true);
  };


  const onFinish = (values: any) => {
    setDataFilter({
      ...dataFilter,
      isShow: values.isShow
    });
    setPageNumber(1);
    setIsSubmitSearch(true)
  };

  const handleChangeFilter = (value: any, name: string) => {
    setDataFilter({...dataFilter, [name]: value});
  };

  return (
    <div className="wrapper-settings_tab-survey">
      <section className="tab-survey-page">
        <div className="tab-survey-page_header">
          <Typography.Title className="question-survey_title" style={{margin: 0}}>
            Câu hỏi khảo sát
          </Typography.Title>
          <FilterTableSurveyQuestion
            handleOpenFilter={handleOpenFilter}
            showFilter={showFilter}
            searchValue={searchValue}
            handleSearch={handleSearch}
            handleChangeInput={handleChangeInput}
            handleSubmitSearch={handleSubmitSearch}
          />
        </div>
        <div className="filter-detail">
          <FormFilterSurveyQuestion
            handleCloseFilter={handleCloseFilter}
            // dataOptions={dataOptions}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={dataFilter}
            setFilterData={handleChangeFilter}
            onFinish={onFinish}
          />
        </div>
        <div className="tabs-heading">
          <TableData dataColumns={dataColumnQuestions}
                     dataTable={listQuestion}
                     setPageNumber={setPageNumber}
                     setPageSize={setPageSize}
                     pageNumber={pageNumber}
                     pageSize={pageSize}
                     loadingTable={isPending}
                     scroll={{x: 1500, y: 600}}
                     totalRecords={totalRecords}
                     handleTableChangeProps={handleTableChange}
                     pagination={true}/>
          <PreviewDetail open={isOpenPreviewDetail} detailData={dataContent}
                         onClose={() => setIsOpenPreviewDetail(false)}/>
        </div>
      </section>
    </div>
  );
};

export default SurveyQuestionTab;
