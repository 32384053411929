// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-password-lecturer-account-drawer table {
  width: 100% !important; }
`, "",{"version":3,"sources":["webpack://./src/components/drawer/basic-admin-account/change-password/index.scss"],"names":[],"mappings":"AAAA;EAEI,sBAAsB,EAAA","sourcesContent":[".change-password-lecturer-account-drawer {\n  table {\n    width: 100% !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
