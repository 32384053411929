import { Button, Dropdown, Form, Layout, MenuProps, message, Modal, Space, TableColumnsType, TableProps, } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState, } from "react";

import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  MoreOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";


// import "./index.scss";
import { isEmpty } from "lodash";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTableBlogContent from "../../../components/blog-and-forum/blog-content/filter-table/FilterTable";
import { routesConfig } from "../../../config/routes";
import { formatDateV2 } from "../../../utils/converDate";
import AddBlogContentDrawer from "../../../components/drawer/add-blog-content";
import CommonModal from "../../../components/modal/common";
import { deleteContentBlog, getListContentBlog, publishContentBlog } from "../../../service/blog-and-forum";
import TableData from "../../../components/table-data/TableData";
// import CommonModal from "../../components/modal/common";

type Props = {
  title?: string;
};

const BlogContentPage = (props: Props) => {
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [listBlogContent, setListBlogContent] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [detailData, setDetailData] = useState<any>();

  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [filterData, setFilterData] = useState<any>({
    createDate: null,
    status: null,
  });
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const getListData = async () => {
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      keyword: searchValue.trim(),
    }
    await getListContentBlog(data).then((res) => {
      setListBlogContent(res.data.data.universityBlogData)
      setTotalRecords(res.data.data.total)
    })
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const gotoDetail = (record: any) => {
    navigate(`${routesConfig.detailPortalAdministration}/${record?.enterpriseId}`, {
      state: { mode: "detail", name: record?.name },
    });
  };

  const deletePortalAdmin = async () => {
    await deleteContentBlog(deleteId).then((res) => {
      if (res.status === 200) {
        message.success("Xoá bài đăng thành công")
        setIsOpenedConfirm(false)
        getListData()
      }
    })
  };

  const postContentBlog = async (id: string | undefined) => {
    await publishContentBlog(id).then((res) => {
      if (res.status === 200) {
        message.success("Đăng bài thành công")
        getListData()
      }
    })
  };

  const renderStatus = (value: number) => {
    switch (value) {
      case 1:
        return <p>Nháp</p>
        break;
      case 2:
        return <p>Đã đăng</p>
        break;
      case 3:
        return <p>Xoá</p>
        break;
      default:
        break;
    }
  }

  const menuItems = [
    {
      key: "1",
      label: "Đăng bài",
      icon: <UploadOutlined />,
    },
    {
      key: "2",
      label: "Sửa bài đăng",
      icon: <EditOutlined />,
    },
    {
      key: "3",
      label: "Xoá bài đăng",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ]

  const dataColumns: TableColumnsType<any> = [
    {
      title: "Số thứ tự",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên nội dung",
      dataIndex: "name",
      key: "name",
      width: 5,
      sorter: true,
      render: (_: any, record, index: number) => {
        return (
          <div dangerouslySetInnerHTML={{ __html: record?.name }}></div>
        );
      },
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "name",
      key: "name",
      width: 13,
      render: (_: any, record) => {
        return (
          <>{record?.updatedDate ? formatDateV2(record?.updatedDate) : null}</>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "createdPortalDate",
      key: "createdPortalDate",
      width: 5,
      render: (_: any, record) => {
        return (
          <div className="flex items-center gap-2">
            <div className="table-status">
              <div
                className="color-icon"
                style={{
                  backgroundColor:
                    record.status === 1
                      ? "#1677FF"
                      : record.status === 2
                        ? "#24b524"
                        : record.status === 3
                          ? "gray"
                          : "white"
                }}
              ></div></div>
            {renderStatus(record?.status)}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  // navigate(
                  //   `${routesConfig.detailContentBlog}/${record.id}`,
                  //   { state: { id: record?.id } }
                  // );
                  window.open(`${routesConfig.blogContent}/${routesConfig.detailContentBlog}/${record.id}`, "_blank")
                }
                }
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: menuItems,
                  onClick: ({ key }) => {
                    if (key === '1') {
                      Modal.confirm({
                        title: `Xác nhận đăng bài`,
                        content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi đăng bài`,
                        onOk: () => {
                          postContentBlog(record?.id)
                        },
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        centered: true,
                        icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }

                    if (key === '2') {
                      setDetailData(record);
                      setIsOpened(true);
                    }

                    if (key === "3") {
                      setDeleteId(record?.id)
                      setIsOpenedConfirm(true)
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space >
          </>
        );
      },
      fixed: "right",
      width: 3,
    },
  ];

  const handleOpenCreate = () => {
    // add course here
    setIsOpened(true);
    setDetailData({})
  };

  const listBreadcrumb = [
    {
      title: 'Quản trị vai trò',
    },
  ]

  return (
    <Layout className="page-header-group portal-adminstration">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {

        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTableBlogContent
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
            />
          </div>
          <div className={`wrapper-filter`}>
          </div>
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listBlogContent}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>

        <AddBlogContentDrawer
          open={isOpened}
          handleClose={() => setIsOpened(false)}
          getListData={getListData}
          detailData={detailData}
        />
      </Form.Provider>

      <CommonModal
        open={isOpenedConfirm}
        title={'Xoá bài đăng'}
        desc={`Bài đăng đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá bài đăng này không?`}
        closeIcon={true}
        okText={'Xác nhận'}
        cancelText={"Huỷ"}
        icon={<WarningOutlined style={{ color: "red" }} />}
        confirm={deletePortalAdmin}
        closeModal={() => setIsOpenedConfirm(false)}
        className="modal-delete-common"
      />
    </Layout>
  );
};

export default BlogContentPage;
