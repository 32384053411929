import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSettingQualityAssessment: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`/setting-quality-assessment/get-public`);
};

export const getSettingQualityAssessmentAllForm: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`/setting-quality-assessment/get-all`);
};

export const getCourseQualityAssessment: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/course-quality-assessment/get/${id}`);
};

export const createSettingQualityAssessment: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-quality-assessment/create`, dataReq);
};

export const createSettingGroupQualityAssessment: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-quality-assessment/create-group`, dataReq);
};

export const createFormPublishGroupQualityAssessment: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-quality-assessment/publish-group`, dataReq);
};

export const updateSettingQualityAssessment: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.put(`/setting-quality-assessment/update`, dataReq);
};

export const updateSettingGroupQualityAssessment: (
  id: string,
  dataReq: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(
    `/setting-quality-assessment/update-group/${id}`,
    dataReq
  );
};

export const updateRateResult: (
  id: string,
  dataReq: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/course-quality-assessment/update-group/${id}`, dataReq);
};

export const deleteQualityAssessmentGroup: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/setting-quality-assessment/delete-group/${id}`);
};
