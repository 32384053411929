import { DatePicker, Form, TimePicker, Typography } from "antd";
import React, { useState } from "react";
import CustomCard from "../../../../../../../components/custom-card/CustomCard";
import FormItemDropdown from "../../../../../../../components/form-dropdown/FormItemDropdown";
import vi from "antd/es/date-picker/locale/vi_VN";
import "dayjs/locale/vi";
import "./index.scss";
import { FormInstance } from "antd/lib";
import dayjs from "dayjs";
import "dayjs/locale/vi";
// import {optionsCourse} from "./constants/data";
import { get } from "lodash";
import customParseFormat from "dayjs/plugin/customParseFormat";

const validate = {
  startDate: {
    required: true,
    message: "Vui lòng chọn ngày mở khảo sát",
  },
  startTime: {
    required: true,
    message: "Vui lòng chọn giờ mở khảo sát",
  },
};
const locale: typeof vi = {
  ...vi,
  lang: {
    ...vi.lang,
    dateSelect: "Chọn ngày",
    placeholder: "Chọn ngày",
    timeSelect: "Chọn giờ",
  },
  timePickerLocale: {
    placeholder: "Chọn giờ",
  },
};
type TimeSurveyProps = {
  formInstance?: FormInstance;
  dataConfig?: any;
};

const TimeSurvey = ({ formInstance, dataConfig }: TimeSurveyProps) => {
  const [selectStartedDate, setStartedDate] = useState(
    get(dataConfig, "enrollStatedDate", "")
  );
  const [selectEndDate, setSelectEndDate] = useState();
  const [selectStartedTime, setStartedTime] = useState(
    dayjs(get(dataConfig, "enrollStatedDate", ""))
  );
  const [selectEndTime, setEndTime] = useState(
    dayjs(get(dataConfig, "enrollClosedDate", ""))
  );
  dayjs.extend(customParseFormat);

  const handleChangeStartedDate = (date: any) => {
    setStartedDate(date);
  };

  const handleChangeStartedTime = (time: any) => {
    setStartedTime(time);
  };

  const handleChangeEndedTime = (time: any) => {
    setEndTime(time);
  };

  const handleChangeEndedDate = (date: any) => {
    setSelectEndDate(date);
  };
  // const disabledHours = () => {
  //   if (!selectStartedDate) return []
  //   // If no date selected, all hours are available
  //   const currentDate = dayjs();
  //   const selectedDay = dayjs(selectStartedDate);
  //   if (selectedDay.isSame(currentDate, 'day')) {
  //     // If selected date is today, disable past hours
  //     // @ts-ignore
  //     return [...Array(currentDate.hour()).keys()];
  //   }
  //   return []
  // };

  // const disabledMinutes = () => {
  //   if (!selectStartedDate) return []
  //   const currentDate = dayjs();
  //   const selectedDay = dayjs(selectStartedDate);
  //   if (selectedDay.isSame(currentDate, 'day')) {
  //     // If selected date is today, disable past minutes
  //     // @ts-ignore
  //     return [...Array(currentDate.minute()).keys()];
  //   }
  //   return []
  // }

  // const disableHoursEndDate = () => {
  //   if (!selectEndDate) return []
  //   // If no date selected, all hours are available
  //   const currentDate = dayjs();
  //   const selectedDay = dayjs(selectEndDate);
  //   if (selectedDay.isSame(currentDate, 'day')) {
  //     // If selected date is today, disable past hours
  //     // @ts-ignore
  //     return [...Array(currentDate.hour()).keys()];
  //   }
  //   return []
  // };

  // const disabledMinutesEndDate = () => {
  //   if (!selectEndDate) return []
  //   const currentDate = dayjs();
  //   const selectedDay = dayjs(selectEndDate);
  //   if (selectedDay.isSame(currentDate, 'day')) {
  //     // If selected date is today, disable past minutes
  //     // @ts-ignore
  //     return [...Array(currentDate.minute()).keys()];
  //   }
  //   return []
  // }

  const disableDays = (currentDate: any) => {
    if (!selectStartedDate)
      return currentDate && currentDate.isBefore(dayjs().subtract(1, "day"));
    if (selectStartedDate) {
      return currentDate && currentDate.isBefore(dayjs(selectStartedDate));
    }
  };

  return (
    <div className="survey-managment-time">
      <CustomCard title="Thời gian khảo sát" className="wrapper-settings--card">
        <div className="time-register--form">
          <div className="form-select--time">
            <Form.Item
              name="enrollStatedDate"
              label="Ngày mở khảo sát"
              className="form-item--date"
              getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
              dependencies={["enrollClosedDate"]}
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn mở khảo sát",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const futureDate = getFieldValue("enrollClosedDate");

                    if (dayjs(value).isAfter(futureDate) && futureDate) {
                      return Promise.reject(
                        new Error(
                          "Ngày mở khảo sát không được sau ngày đóng khảo sát"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker
                disabledDate={(current) =>
                  current.isBefore(dayjs().subtract(1, "day"))
                }
                locale={locale}
                format={"DD/MM/YYYY"}
                onChange={handleChangeStartedDate}
              />
            </Form.Item>
            <Form.Item
              name="startTime"
              getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
              dependencies={["endTime", "enrollStatedDate", "enrollClosedDate"]}
              rules={[
                validate.startTime,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const futureDate = getFieldValue("endTime");
                    const startDate = getFieldValue("enrollStatedDate");
                    const closeDate = getFieldValue("enrollClosedDate");

                    if (
                      dayjs(startDate).isSame(closeDate) &&
                      dayjs(value).isAfter(futureDate) &&
                      futureDate
                    ) {
                      return Promise.reject(
                        new Error(
                          "Thời gian mở khảo sát không được sau thời gian đóng khảo sát"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              className="form-item--time"
            >
              <TimePicker
                locale={locale}
                value={
                  selectStartedDate &&
                  dayjs(selectStartedDate)
                    .set("hour", 0)
                    .set("minute", 0)
                    .toDate()
                }
                onChange={handleChangeStartedTime}
              />
            </Form.Item>
          </div>
          <div className="form-select--time">
            <Form.Item
              name="enrollClosedDate"
              label="Ngày đóng khảo sát"
              className="form-item--date"
              getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
            >
              <DatePicker
                disabledDate={(current) => disableDays(current)}
                onChange={handleChangeEndedDate}
                locale={locale}
                format={"DD/MM/YYYY"}
              />
            </Form.Item>
            <Form.Item
              name="endTime"
              className="form-item--time"
              getValueProps={(i) => ({ value: i ? dayjs(i) : "" })}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const futureTime = getFieldValue("startTime");
                    const startDate = getFieldValue("enrollStatedDate");
                    const closeDate = getFieldValue("enrollClosedDate");

                    if (
                      dayjs(startDate).isSame(closeDate) &&
                      dayjs(value).isBefore(futureTime) &&
                      futureTime
                    ) {
                      return Promise.reject(
                        new Error(
                          "Thời gian mở khảo sát không được sau thời gian đóng khảo sát"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TimePicker onChange={handleChangeEndedTime} locale={locale} />
            </Form.Item>
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default TimeSurvey;
