import {
  DeleteOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Flex,
  Form,
  Layout,
  MenuProps,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import FormInput from "../../../components/form-input-login/FormInput";
import CommonModal from "../../../components/modal/common";
import TableData from "../../../components/table-data/TableData";
import { routesConfig } from "../../../config/routes";
import { TrendsType } from "../../../constants";
import { verifyPassword } from "../../../service/auth";
import {
  deleteLearningTrendReport,
  getLearningTrendsReport,
} from "../../../service/prediction-model";
import { formatDateV2 } from "../../../utils/converDate";

interface SearchProps {
  take: number;
  tab?: number;
  skip: number;
  name?: string;
}
interface IProps {
  searchValue: string;
  handleNotice: (type: any, mess: string) => void;
  isRefresh: boolean;
  handleRefresh: () => void;
}

const SettingPredictionTable = ({
  searchValue,
  handleNotice,
  isRefresh,
  handleRefresh,
}: IProps) => {
  const [predictionTableData, setSettingPredictionTableData] = useState<any[]>([]);
  const [predictionTableItem, setSettingPredictionTableItem] = useState<any>({
    id: null,
    isOpen: false,
  });
  const [password, setPassWord] = useState("");
  const [checkPass, setCheckPassword] = useState<boolean>(true);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);

  const fetchData = async (searchParam: SearchProps) => {
    try {
      const response = await getLearningTrendsReport(searchParam);
      const { data } = response.data;

      setSettingPredictionTableData(data?.data);
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      name: searchValue,
    });
  }, [pageSize, pageNumber, searchValue, isRefresh]);

  const handleOpenDelete = (idItem: string) => {
    setSettingPredictionTableItem({
      id: idItem,
      isOpen: true,
    });
  };
  const handleChange = (value: any) => {
    setPassWord(value);
  };

  const dataColumnsQualityEvaluation: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },

    {
      title: "Tên mô hình",
      dataIndex: "note",
      key: "note",
      width: 20,
      render: (_, data: any) => {
        return <div>{data?.name}</div>;
      },
    },
    {
      title: "Phân loại",
      dataIndex: "note",
      key: "note",
      width: 20,
      render: (_, data: any) => {
        return (
          <div>
            {data?.type === TrendsType.BOTH
              ? "Thông tin hỗn hợp"
              : data?.type === TrendsType.BEHAVIOR
              ? "Thông tin hành vi"
              : "Thông tin đầu vào"}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createDate",
      key: "createDate",
      width: 10,
      render: (_: any, record) => {
        return (
          <>
            {record.created_at != null
              ? formatDateV2(record.created_at.toString())
              : ""}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const idDocument = `${record?.id}`;

        const items: MenuProps["items"] = [
          {
            label: (
              <Link
                to={`/prediction-model/${routesConfig.settingDetailModel}/${idDocument}`}
              >
                <Flex>
                  <SettingOutlined />
                  <Typography.Text className="pl-2">
                    Cài đặt mô hình
                  </Typography.Text>
                </Flex>
              </Link>
            ),
            key: "0",
          },
          {
            label: (
              <Link
                to={`/prediction-model/${routesConfig.viewSettingDetailModel}/${idDocument}`}
              >
                <Flex>
                  <EyeOutlined />
                  <Typography.Text className="pl-2">
                    Xem cài đặt
                  </Typography.Text>
                </Flex>
              </Link>
            ),
            key: "1",
          },
          {
            label: (
              <Flex onClick={() => handleOpenDelete(idDocument)} className="text-[#FF4D4F]">
                <DeleteOutlined />
                <Typography.Text className="pl-2 text-[#FF4D4F]">Xoá cài đặt</Typography.Text>
              </Flex>
            ),
            key: "4",
          },
        ];
        return (
          <Space>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              className="action-table"
            >
              <a onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsQualityEvaluation.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsQualityEvaluation.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  const handleOpenVerifyDelete = () => {
    setIsConfirmDelete(true);
    setSettingPredictionTableItem({
      ...predictionTableItem,
      isOpen: false,
    });
  };
  const handleConfirmDelete = async () => {
    try {
      const response = await verifyPassword({ password });
      const { data } = response;
      if (data?.statusCode === 200) {
        await deleteLearningTrendReport(predictionTableItem?.id || "");

        handleNotice("success", "Xóa thành công");
        setIsConfirmDelete(false);
        handleRefresh();
      } else {
        setCheckPassword(false);
      }
    } catch (error) {
      setCheckPassword(false);
      console.log(error);
    }
  };
  const cancelModal = () => {
    setPassWord("");
    setIsConfirmDelete(false);
    setCheckPassword(true);
  };
  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <TableData
          dataTable={predictionTableData}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
      <CommonModal
        open={predictionTableItem?.isOpen}
        title={"Xác nhận xoá file dữ liệu"}
        desc={
          <div>
            Dữ liệu đã xoá sẽ không thể khôi phục. 
            <br />
            Bạn có chắc chắn muốn xoá dữ liệu?
          </div>
        }
        closeIcon={false}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleOpenVerifyDelete}
        closeModal={() =>
          setSettingPredictionTableItem({ id: null, isOpen: false })
        }
      />
      <CommonModal
        open={isConfirmDelete}
        title={`Xác nhận lưu thông tin`}
        desc={
          <div>
            <FormInput
              type="password"
              value={password}
              title="Mã UserID"
              onChange={handleChange}
              isPassword
              placeholder="Nhập mật khẩu của bạn"
            />
            {!checkPass && (
              <Typography.Text className="text-[#FF4D4F]">
                Mật khẩu chưa chính xác. Vui lòng nhập lại
              </Typography.Text>
            )}
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmDelete}
        closeModal={cancelModal}
        className={"!w-[465px] report-delete-confirm"}
      />
    </Layout>
  );
};

export default SettingPredictionTable;
