import React from "react";
import QuizComponent from "./QuizComponent";
import { PartBlock } from "../../../../../../types/course";
import { isEmpty } from "lodash";

interface ContentLayoutProps {
  data: PartBlock[];
}
function ContentQuizLayout({ data }: ContentLayoutProps) {
  return (
    <div className="content-quiz-layout">
      {!isEmpty(data) &&
        data?.map((group: PartBlock) => (
          <div key={group?.groupId}>
            <p className="font-size-16 line-height-20 font-weight-6">{group.title}</p>
            <p className="font-size-14 line-height-20">{group.description}</p>
            <QuizComponent quizzes={group?.quizzes} />
          </div>
        ))}
    </div>
  );
}

export default ContentQuizLayout;
