import { Breadcrumb, Form, Layout, TableColumnsType, TableProps } from "antd";
import React, { KeyboardEvent, useEffect, useState } from "react";
import FormItemInput from "../../../components/form-input/FormInput";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import "../style.scss";
import TableData from "../../../components/table-data/TableData";
import { UnitBaseInfo } from "../../../types/scoring-course";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { getUnitByCourse } from "../../../service/scoring";
import { toNumber } from "lodash";
import { DEFAULT_PAGE_SIZE } from "../../../constants";

interface CourseScoringListProps {
  title?: string;
}

function ExerciseList({ title }: CourseScoringListProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const courseId = toNumber(params?.id);
  const courseName = location.state?.name;

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [units, setUnits] = useState();

  useEffect(() => {
    getUnits();
  }, [pageSize, currentPage]);

  const getUnits = async () => {
    try {
      setLoading(true);
      const res = await getUnitByCourse({
        id: courseId,
        keyword: searchValue,
        page: currentPage,
        size: pageSize,
      });
      setTotalElements(res.data.data.total);
      setUnits(res.data.data.data);
    } catch (err) {
      console.error;
    } finally {
      setLoading(false);
    }
  };

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSubmitSearch = () => {
    getUnits();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleSubmitSearch();
    }
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    // setStatusOrder(data);
    // setFieldSortOrder(sorter.field);
  };

  const dataColumns: TableColumnsType<UnitBaseInfo> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      align: "center",
      width: "10%",
      render: (_: any, record, index: number) => {
        return <div>{record.numericalOrder}</div>;
      },
    },
    {
      title: "Tên bài tập/ bài kiểm tra/ bài thi",
      dataIndex: "name",
      sorter: true,
      key: "name",
      width: "70%",
      render: (_: any, record) => {
        return (
          <Link
            className="text-primary"
            to={`${routesConfig.scoringListCourse}/${courseId}/exercise?blockId=${record?.blockId}`}
            state={{
              courseName: courseName,
              unitName: record.name,
            }}
          >
            {record.name}
          </Link>
        );
      },
    },
  ];

  const breadcrumb = [
    {
      title: (
        <Link to={`${routesConfig.scoringListCourse}`}>Danh sách khoá học</Link>
      ),
    },
    {
      title: courseName,
    },
  ];

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="scoring-course w-full">
          <div className="scoring-page-header ">
            <Breadcrumb items={breadcrumb} className="mb-1" />
            <div className="flex justify-between align-center">
              <div className="flex gap-8 align-center">
                <ArrowLeftOutlined
                  className="arrowleft-icon"
                  onClick={() => navigate(routesConfig.scoringListCourse)}
                />{" "}
                <h3 className="page-title">{title}</h3>
              </div>
              <div className="search-input">
                <FormItemInput
                  style={{ width: 280 }}
                  placeholder="Nhập tên bài tập"
                  value={searchValue}
                  onChange={handleChangeInput}
                  onKeyPress={handleSearch}
                  afterPrefixIcon={
                    <SearchOutlined onClick={handleSubmitSearch} />
                  }
                />
              </div>
            </div>
          </div>
          <div className="scoring-content">
            <TableData
              dataTable={units}
              dataColumns={dataColumns}
              scroll={{ x: 1000, y: 600 }}
              pagination
              pageNumber={currentPage}
              pageSize={pageSize}
              loadingTable={loading}
              totalRecords={totalElements}
              handleTableChangeProps={handleTableChange}
              setPageNumber={setCurrentPage}
              setPageSize={setPageSize}
            />
          </div>
        </div>
      </Form.Provider>
    </Layout>
  );
}

export default ExerciseList;
