import React, {ChangeEvent, KeyboardEvent, useEffect, useState, useTransition} from 'react';
import {
  createEnrolledClass,
  deleteEnrolledClass,
  getDetailEnrolledClass,
  getEnrolledClassForm,
  getEnrolledClassStatus,
  getListStudentCourse,
  postEnrolledClass,
  updateEnrolledClass,
  updateStatusEnrolledClass
} from "../../../../service/course-construct";
import {Button, Dropdown, Form, FormInstance, Modal, Space, TableColumnsType, TableProps, Typography} from "antd";
import TableData from '../../../table-data/TableData';
import {
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  PlayCircleOutlined,
  SaveOutlined
} from '@ant-design/icons';
import FilterTable from "./filter-table";
import DrawerCustom from "../../../drawer/Drawer";
import CreateClass from "./create-class";
import FormTable from "./form-table";
import {get, isNull} from "lodash";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import {message} from "antd/lib";
import './index.scss'
import {formatDateV2} from "../../../../utils/converDate";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {routesConfig} from '../../../../config/routes';
import {Page403} from "../../../../pages";
import 'dayjs/locale/vi';
import {getAllAccountTeacher} from "../../../../service/list-account-teachers/listAccountTeachersApi";

dayjs.extend(isBetween);
type ClassRegisterProps = {
  paramId: number | string | undefined;

}


const ClassRegister = ({paramId}: ClassRegisterProps) => {
  const [form] = Form.useForm()
  const {confirm} = Modal;
  const [dataClass, setDataClass] = useState([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [isPending, startTransition] = useTransition()
  const [isOpenErrolled, setIsOpenErrolled] = useState(false)
  const [hasError, setHasError] = useState<boolean>(false);
  const [totalElements, setTotalElements] = useState<number>(0)
  const [statusClass, setStatusClass] = useState(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(false)
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isTitleEdit, setIsTitleEdit] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [clearedFilter, setClearedFilter] = useState<boolean>(false)
  const [dataOptions, setDataOptions] = useState({
    dataStatusOptions: [],
    dataOptionsForm: [],
    dataOptionsTeachers: [],
  });
  const location = useLocation();
  const navigate = useNavigate();
  const {id} = useParams();

  const [dataDetail, setDataDetail] = useState<any>({})


  const [filterData, setFilterData] = useState<any>({
    status: null,
    form: null,
    createDate: null,
  });

  const getDataClass = async () => {
    setLoading(true)
    const dataReq = {
      keyword: searchValue,
      size: pageSize,
      page: pageNumber,
      courseId: Number(id),
      status: !isNull(filterData.status) ? Number(filterData.status) : null,
      form: !isNull(filterData.form) ? Number(filterData.form) : null,
    }
    try {
      const response = await postEnrolledClass(dataReq)
      const {content, totalElements, size, totalPages} = response.data
      if (response.status === 200) {
        setDataClass(content)
        setTotalRecords(totalElements)
        setTotalPage(totalPages)
        setPageSize(size)
        setLoading(false)
        setError(false)
      }
    } catch (e) {
      setError(true)
    }
  }


  const getDataOptions = async () => {
    const dataReq = {
      page: 1,
      size: 10,
      sort: [
        "dateJoined,desc"
      ],
      enterpriseId: null,
      createdFromDate: null,
      createdToDate: null,
      status: null
    }
    try {

      const response = await Promise.all([getEnrolledClassStatus(), getEnrolledClassForm(), getAllAccountTeacher(dataReq)])
      setDataOptions({
        ...dataOptions,
        dataStatusOptions: response[0].data,
        dataOptionsForm: response[1].data,
        dataOptionsTeachers: response[2].data.data.data
      })
    } catch (error) {
    }
  }


  const getStudents = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      courseId: id,
    }

    try {
      const response = await getListStudentCourse(dataReq)
      const {data, status} = response
      if (status === 200) {
        startTransition(() => {
          setTotalElements(data.totalElements);
          setHasError(false);
        });
      }

    } catch (error) {
      setHasError(true)
    }
  };

  const getDataEdit = async (id: number) => {
    try {
      const response = await getDetailEnrolledClass(id)
      const {data} = response
      setDataDetail(data)
    } catch (error) {
    }
  }

  const createNewEnrollClass = async (dataReq: any) => {
    try {
      const response = await createEnrolledClass(dataReq)
      const {success} = response.data
      if (success) {
        message.success('Mở lớp ghi danh thành công')
      }
      handleClose()
      getDataClass()
    } catch (error) {
    }
  }
  const editNewEnrollClass = async (id: number, dataReq: any) => {
    try {
      const response = await updateEnrolledClass(id, dataReq)
      const {success} = response.data
      if (success) {
        message.success('Cập nhật lớp ghi danh thành công')
      }
      handleClose()
      getDataClass()
    } catch (error) {
    }
  }

  const handleDeleteClass = async (id: number) => {
    setLoading(true)
    try {
      const response = await deleteEnrolledClass(id)
      const {success} = response.data
      if (success) {
        message.success('Xóa lớp ghi danh thành công')
      }
      getDataClass()
      setLoading(false)
    } catch (error) {
    }
  }
  const performAsyncOperation = async (dataReq: any) => {
    setLoading(true);
    try {
      const response = await updateStatusEnrolledClass(dataReq);
      if (isOpenErrolled) {
        message.success("Mở lớp ghi danh thành công")
      }
      if (!isOpenErrolled) {
        message.success("Đóng lớp ghi danh thành công")
      }
      getDataClass(); // Refresh data
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenedClosedClass = (dataReq: any) => {
    startTransition(() => {
      performAsyncOperation(dataReq);
    });
  };

  useEffect(() => {
    getDataOptions()
    getStudents()
  }, []);


  useEffect(() => {
    getDataClass()
  }, [pageSize, pageNumber, clearedFilter]);


  useEffect(() => {
    if (isEdit && isTitleEdit && Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        'name': get(dataDetail, 'name', ''),
        'numberStudent': dataDetail?.numberStudent ? get(dataDetail, 'numberStudent', 0).toString() : "",
        'form': dataDetail?.form ? dataDetail?.form.toString() : null,
        'numberOfSession': dataDetail?.numberOfSession ? dataDetail?.numberOfSession : null,
        'expectedTime': dataDetail?.expectedTime ? dataDetail?.expectedTime : null,
        'expectedTimeType': dataDetail?.expectedTimeType ? dataDetail?.expectedTimeType : null,
        'instructorTeachers': dataDetail?.instructorTeachers ? dataDetail?.instructorTeachers.split(';').map(Number) : null,
        'openingDate': dayjs(get(dataDetail, 'openingDate', '')),
        'openingTime': dayjs(get(dataDetail, 'openingTime', ''), 'HH:mm:ss'),
        'closedDate': dayjs(get(dataDetail, 'closedDate', '')),
        'closedTime': dayjs(get(dataDetail, 'closedTime', ''), 'HH:mm:ss'),
        'closingDate': dayjs(get(dataDetail, 'closingDate', '')),
        'closingTime': dayjs(get(dataDetail, 'closingTime', ''), 'HH:mm:ss'),
        'enrolledDate': dayjs(get(dataDetail, 'enrolledDate', '')),
        'enrolledTime': dayjs(get(dataDetail, 'enrolledTime', ''), 'HH:mm:ss')
      })
    }
  }, [isEdit, dataDetail]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setLoading(true)
      postEnrolledClass({
        keyword: searchValue,
        size: pageSize,
        page: pageNumber
      }).then(res => {
        const {content, totalElements, size, totalPages} = res.data
        setDataClass(content)
        setTotalRecords(totalElements)
        setLoading(false)
        setPageSize(size)
        setTotalPage(totalPages)
      }).catch(err => {
      })
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("")
    }
  };


  const handleOpen = () => {
    setIsOpenDrawer(true)
    setIsEdit(false)
    setIsTitleEdit(false)
  }

  const handleClose = () => {
    setIsOpenDrawer(false)
    setDataDetail({})
    form.resetFields()
  }

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleSubmitSearch = () => {
    setLoading(true);
    postEnrolledClass({
      keyword: searchValue,
      size: pageSize,
      page: pageNumber
    }).then(res => {
      const {content, totalElements, totalPages, size} = res.data
      setDataClass(content)
      setLoading(false)
      setTotalRecords(totalElements)
      setTotalPage(totalPages)
      setPageSize(size)
    }).catch(err => {
    })
    if (searchValue === '') {
      setPageNumber(1)
    }
  };
  const clearFilter = (formInstance: FormInstance) => {

    formInstance.resetFields()
    setFilterData({
      status: null,
      form: null,
      createDate: null,
    });
    setClearedFilter(prev => !prev)
  };

  const showConfirm = (id: number) => {
    confirm({
      title: (
        <div className="title-container">
          <div style={{color: "red", fontSize: "20px"}}>
            <DeleteFilled/>
          </div>
          <span>Bạn muốn xóa lớp ghi danh?</span>
        </div>
      ),
      icon: null,
      okText: "Xác nhận",
      cancelText: "Hủy",
      okType: "danger",
      onOk() {
        handleDeleteClass(id);
      },
      onCancel() {
      },
    });
  };


  const convertStatus = (currentDate: any, record: any) => {
    const {enrolledDate, closedDate, openingDate} = record;
    if (!enrolledDate) {
      return "Chưa mở ghi danh"; // No enrolledDate provided
    }

    if (currentDate.isBefore(enrolledDate)) {
      return "Chưa mở ghi danh";
    } else if (currentDate.isBetween(enrolledDate, closedDate, null, '[]')) {
      return "Đang mở ghi danh";
    } else if (currentDate.isAfter(closedDate)) {
      if (currentDate.isAfter(openingDate)) {
        return "Đã khai giảng";
      }
      return "Đóng ghi danh";
    } else {
      return "Đã kết thúc"; // Default case if no other condition is met
    }
  }


  const convertForm = (form: number) => {
    switch (form) {
      case 10:
        return "Trực tuyến"
      case 20:
        return "Tại cơ sở đào tạo"
      default:
        return "Kết hợp"
    }
  }

  const columns: TableColumnsType = [
    {
      key: 'name',
      title: 'Lớp ghi danh',
      dataIndex: 'name',
      width: 60
    },
    {
      key: 'numberOfSession',
      dataIndex: 'numberOfSession',
      title: 'Học viên',
      render: (_: any, record: any) => {
        return (

          <span>{totalElements}/{record.numberOfSession}</span>

        );
      },
      width: 20,
    },
    {
      key: 'closedDate',
      dataIndex: 'closedDate',
      title: 'Thời hạn',
      render: (_, record: any) => {
        return record.enrolledDate ? formatDateV2(record.enrolledDate) : null;
      },
      width: 20,
    },
    {
      key: 'openingDate',
      dataIndex: 'openingDate',
      title: 'Khai giảng',
      render: (_, record: any) => {
        return record.openingDate ? formatDateV2(record.openingDate) : null;
      },
      width: 20,
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'Trạng thái',
      render: (_, record: any) => {
        const currentDate = dayjs()
        return (
          <>
            <div className="table-status survey-content">
              <div
                className="color-icon"
                style={{
                  backgroundColor:
                    !currentDate || currentDate.isBefore(record.enrolledDate)
                      ? "#D9D9D9"
                      : currentDate.isBetween(record.enrolledDate, record.closedDate, null, '[]')
                        ? "#52C41A"
                        : currentDate.isAfter(record.openingDate)
                          ? "#1677FF" :
                          currentDate.isAfter(record.closedDate) ?
                            "#FF4D4F"
                            : "#FAAD14",
                }}
              ></div>
              <span>{convertStatus(currentDate, record)}</span>
            </div>
          </>
        )
      },
      width: 30,
    },
    {
      key: 'form',
      dataIndex: 'form',
      title: 'Hình thức',
      width: 20,
      render: (_, record: any) => {
        return (
          <>
            <div className="tags-wrapper">
              <div className={`tag 
                  ${record.form === 10 ? 'tag-green' : record.form === 20 ? 'tag-gray' : 'tag-blue'}
                `}>
                <span>
                  {convertForm(record.form)}
                </span>
              </div>
            </div>
          </>
        )
      }
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record: any) => {
        const currentDate = dayjs()

        return (
          <>

            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigate(`${routesConfig.registrationClass}`, {state: record,},);
                }
                }
              >
                <EyeOutlined/>
              </a>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: "Chỉnh sửa",
                      icon: <EditOutlined/>,
                    },
                    ...(() => {
                      if (currentDate.isBefore(record.enrolledDate)) {
                        return [
                          {
                            key: "2",
                            label: "Mở ghi danh",
                            icon: <PlayCircleOutlined/>,
                          },
                          {
                            key: "3",
                            label: "Xóa lớp",
                            icon: <DeleteOutlined/>,
                            danger: true,
                          },
                        ];
                      } else if (currentDate.isBetween(record.enrolledDate, record.closedDate, null, '[]')) {
                        return [
                          {
                            key: "2",
                            label: "Đóng ghi danh",
                            icon: <PlayCircleOutlined/>,
                          },
                          {
                            key: "3",
                            label: "Xóa lớp",
                            icon: <DeleteOutlined/>,
                            danger: true,
                          },
                        ];
                      } else {
                        return [];
                      }
                    })(),
                  ],
                  onClick: ({key}) => {
                    if (key === "1") {
                      // edit item
                      setIsEdit(true)
                      setIsTitleEdit(true)
                      setIsOpenDrawer(true)
                      getDataEdit(Number(record.id))
                    }
                    if (key === "2") {
                      handleOpenedClosedClass({
                        enrollClassId: Number(record.id),
                        isOpen: !currentDate.isBetween(record.enrolledDate, record.closedDate, null, '[]'),
                        enrollClassDate: currentDate.format('YYYY-MM-DD'),
                        enrollClassTime: currentDate.format('HH:mm:ss')
                      })
                      if (!currentDate.isBetween(record.enrolledDate, record.closedDate, null, '[]')) {
                        setIsOpenErrolled(true)
                      } else {
                        setIsOpenErrolled(false)
                      }
                    }
                    if (key === "3") {
                      showConfirm(Number(record.id));
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button className="action-course">
                  <MoreOutlined/>
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ]

  const onFinish = (values: any): void => {
    const dataReq = {
      courseId: Number(id),
      name: values.name,
      numberStudent: Number(values.numberStudent),
      form: values.form && values.form.length > 1 ? "9999" : values.form ? values.form.join() : null,
      expectedTime: values.expectedTime,
      expectedTimeType: values.expectedTimeType,
      instructorTeachers: values.instructorTeachers ? values.instructorTeachers.join(';') : "",
      numberOfSession: values.numberOfSession,
      enrolledDate: dayjs(values.enrolledDate).format('YYYY-MM-DD'),
      enrolledTime: dayjs(values.enrolledTime).format('HH:mm:ss'),
      openingDate: dayjs(values.openingDate).format('YYYY-MM-DD'),
      openingTime: dayjs(values.openingTime).format('HH:mm:ss'),
      ...((get(dataDetail, 'status', 0) || get(dataDetail, 'isDeleted', 0)) ?
        {
          status: get(dataDetail, 'status', 0),
          isDeleted: get(dataDetail, 'isDeleted', 0)
        } : {}),
      ...((values.closedDate || values.closedTime ||
        values.closingDate ||
        values.closingTime) ? {
        closedDate: dayjs(values.closedDate).format('YYYY-MM-DD'),
        closedTime: dayjs(values.closedTime).format('HH:mm:ss'),
        closingDate: dayjs(values.closingDate).format('YYYY-MM-DD'),
        closingTime: dayjs(values.closingTime).format('HH:mm:ss'),
      } : {})
    }

    if (isEdit && isTitleEdit && Object.keys(dataDetail).length > 0) {
      editNewEnrollClass(get(dataDetail, 'id', 0), dataReq)
    } else {
      createNewEnrollClass(dataReq)
    }
  }


  const onFinishFilter = (values: any) => {
    setFilterData({
      ...filterData,
      status: values.status ? values.status : null,
      form: values.form ? values.form : null,
      createDate: values.closingDate ? values.closingDate : null,

    })
    getDataClass()

  }


  return (
    <>
      <div className="wrapper-settings-history">
        <Form.Provider onFormFinish={(name, {values, forms}) => {
          if (name === 'createEnrolledClass') {
            const {createEnrolledClass} = forms
            const checkCondition = createEnrolledClass.isFieldValidating(['name', 'enrolledDate', 'openingDate'])
            if (!checkCondition) {
              onFinish(values)
            }
          }
        }}>
          <section className="history-page">

            <div className="history-header">
              <Typography.Title className="history-title" style={{margin: 0}}>
                Danh sách lớp ghi danh
              </Typography.Title>
              <FilterTable
                handleOpenFilter={handleOpenFilter}
                showFilter={showFilter}
                searchValue={searchValue}
                handleSearch={handleSearch}
                handleOpen={handleOpen}
                handleChangeInput={handleChangeInput}
                handleSubmitSearch={handleSubmitSearch}
              />

            </div>
            <div className="filter-class--register">
              <FormTable
                handleCloseFilter={handleCloseFilter}
                dataOptions={dataOptions}
                clearFilter={clearFilter}
                showFilter={showFilter}
                filterData={filterData}
                // setFilterData={handleChangeFilter}
                onFinish={onFinishFilter}
              />
            </div>
            <div className="tabs-heading">

              {!error ? (
                <TableData
                  dataTable={dataClass}
                  dataColumns={columns}
                  pageNumber={pageNumber}
                  totalRecords={totalRecords}
                  pageSize={pageSize}
                  setPageNumber={setPageNumber}
                  setPageSize={setPageSize}
                  pagination={true}
                  loadingTable={loading}
                  scroll={{x: 1500, y: 600}}
                  handleTableChangeProps={handleTableChange}/>
              ) : (<Page403/>)}
            </div>
          </section>
          <DrawerCustom
            title={isTitleEdit ? "Chi tiết lớp ghi danh" : "Mở lớp ghi danh"} open={isOpenDrawer} onClose={handleClose}
            extraNode={
              <Space>
                <>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <CloseOutlined/>
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" htmlType="submit" onClick={() => form.submit()}>
                    <SaveOutlined/>
                    <Typography.Text>
                      Lưu
                    </Typography.Text>
                  </Button>
                </>
              </Space>
            }
            widthDrawer={730}>
            <CreateClass form={form} dataOptions={dataOptions} isEdit={isEdit} dataDetail={dataDetail}/>
          </DrawerCustom>
        </Form.Provider>

      </div>
    </>
  );
};

export default ClassRegister;
