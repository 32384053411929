import React, {FunctionComponent} from "react";
import {FormInstance} from "antd/lib";
import {DeleteOutlined, FilterOutlined} from "@ant-design/icons";
import {Button, Col, Form, Row, Typography} from "antd";
import FormItemDropdown from "../../../../../../form-dropdown/FormItemDropdown";
import "./index.scss";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  setFilterData: (value: string[], name: string) => void;
  clearFilter: () => void;
  onFinish?: (values: any) => void;
  dataOptions?: any;
  filterData: any; //FilterDataSurveyOperationHistory;
};

const optionShow = [
  {
    value: true,
    label: 'Hiển thị'
  },
  {
    value: false,
    label: 'Ẩn'
  }
]

const FormFilterSurveyQuestion: FunctionComponent<FormFilterProps> = ({
                                                                        onFinish,
                                                                        dataOptions,
                                                                        form,
                                                                        showFilter,
                                                                        clearFilter,
                                                                        filterData,
                                                                        setFilterData,
                                                                      }) => {

  return (
    <div className="filter-style">
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form
          form={form}
          name="filterForm"
          className="filter-form"
          onFinish={onFinish}
        >
          <Row gutter={[{xs: 8, xl: 16, md: 16}, 12]} className="row-filter">
            <Col
              xl={{span: 8}}
              md={{span: 6}}
              sm={{span: 6}}
              xs={{span: 24}}
            >
              <Typography.Title level={5}>Hiển thị</Typography.Title>
              <Form.Item name="isShow">
                <FormItemDropdown
                  placeholder="Chọn trạng thái"
                  onChange={(option) => setFilterData(option, "isShow")}
                  options={optionShow}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{span: 16}}
              md={{span: 6}}
              sm={{span: 6}}
              xs={{span: 24}}
              className="flex align-end col-filter"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined/>
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined/>
                  <Typography.Text>Xóa tất cả</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default FormFilterSurveyQuestion;
