// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-exam-course-modal .ant-modal-header {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.add-exam-course-modal .ant-modal-footer {
  justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/pages/library/bank-exam/bank-exam-list/bank-exam-list.scss"],"names":[],"mappings":"AAAA;EAEQ,gCAAgC;EAChC,oBAAoB;EACpB,mBAAmB,EAAA;;AAJ3B;EAOQ,uBACJ,EAAA","sourcesContent":[".add-exam-course-modal{\n    .ant-modal-header{\n        border-bottom: 1px solid #f0f0f0;\n        padding-bottom: 20px;\n        margin-bottom: 20px;\n    }\n    .ant-modal-footer{\n        justify-content: center\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
