// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sortableItem {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  padding: 10px 0;
  background-color: #fff;
  list-style: none; }
  .sortableItem:active {
    box-shadow: unset; }
  .sortableItem:focus-visible {
    box-shadow: unset;
    outline: unset; }

.dragHandle {
  display: flex;
  width: 30px;
  padding: 5px 16px;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
  box-shadow: unset !important; }
`, "",{"version":3,"sources":["webpack://./src/components/SortableList/components/SoftableItem/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,sBAAsB;EACtB,gBAAgB,EAAA;EAPlB;IAWI,iBAAiB,EAAA;EAXrB;IAeI,iBAAiB;IACjB,cAAc,EAAA;;AAIlB;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EAEjB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,wCAAwC;EACxC,4BAA4B,EAAA","sourcesContent":[".sortableItem {\n  display: flex;\n  justify-content: space-between;\n  flex-grow: 1;\n  align-items: center;\n  padding: 10px 0;\n  background-color: #fff;\n  list-style: none;\n\n  &:active {\n    // box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.2), 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.25);\n    box-shadow: unset;\n  }\n\n  &:focus-visible {\n    box-shadow: unset;\n    outline: unset;\n  }\n}\n\n.dragHandle {\n  display: flex;\n  width: 30px;\n  padding: 5px 16px;\n  // margin-right: 20px;\n  align-items: center;\n  justify-content: center;\n  border: none;\n  outline: none;\n  background-color: transparent;\n  -webkit-tap-highlight-color: transparent;\n  box-shadow: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
