import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { valueType } from "antd/es/statistic/utils";

interface FormSelectProps {
  label: string;
  name: string;
  options?: { label: string; value: number }[];
  defaultValue?: number,
  onChange?: (val: any, name: string) => void;
}

function FormSelect(props: FormSelectProps) {
  const { label, name, options, defaultValue, onChange } = props;
  const handleSelect = (value: any) => {
    onChange && onChange(value, name);
  }
  return (
    <div className="setting-question-row">
      <div className="row-item">
        <span className="row-item">{label}</span>
        <Form.Item name={name}>
          <Select
            style={{ width: 120 }}
            options={options}
            onChange={handleSelect}
            defaultValue={defaultValue}
          />
        </Form.Item>
      </div>
    </div>
  );
}

export default FormSelect;
