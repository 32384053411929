import {AxiosResponse} from "axios";
import {axiosConfig} from "../../config/api/configApi";
import {axiosConfigV2} from "../../config/api/configApiv2";
import {axiosConfigToUpload} from "../../config/api/configApiUpload";


export const getConfigCourse: (id?: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`courses/config/get-config-by-id/${id}`)
}

export const getOptionNotification: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/courses/config/notification-optionals`)
}

export const getFormats: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/courses/config/formats`)
}

export const getCertifications: (id?: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/courses/config/certifications/${id}`)
}

export const getPaymentMethods: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/courses/config/payment-methods`)
}

export const importExcelFileShare: (data: any, config: any)
  => Promise<AxiosResponse<any>> = (data: any, config: any) => {
  return axiosConfigToUpload.post(`/courses/config/import-file-share-course`, data, config);
};

export const updateSettingCourse: (id?: string, dataReq?: any) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfigV2.put(`/courses/config/course-config/${id}`, dataReq)
}

export const createSettingCourse: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/courses/config/course-config`, dataReq)
}

export const updateImageTeacher: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.put(`courses/config/course-config/update-image-teacher`, dataReq)
}

export const createTeacherInstruct: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`courses/config/update-instruct-teacher`, dataReq)
}

export const getTeacherCourse: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/courses/config/get-teacher-by-uuid/${id}`)
}

export const getEnterpriseCourse: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/courses/config/get-enterprise-by-course/${id}`)
}


export const createCertification: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/courses/config/course-config-certification`, dataReq)
}

export const createCost: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/courses/config/course-cost-config`, dataReq)
}


export const shareUsersAndUniversity: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/courses/config/share-course`, dataReq)
}

export const createCertificate: (dataReq?: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/courses/config/add-certification`, dataReq)
}

export const getCostCourse: (id?: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/courses/config/get-course-cost-config/${id}`)
}

export const getInstructTeacher: (id?: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/courses/config/get-instruct-teacher/${id}`)
}

export const getDataGeneral: (id?: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/courses/config/get-course-config-general/${id}`)
}

export const getCertificationCourse: (id?: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/courses/config/get-course-config-certification/${id}`)
}


export const sendEmail: (dataReq: {
  emails: string[];
  courseId: string | undefined
}) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/courses/config/send-mail-invite-course`, dataReq)
}

export const getListEmail: (param?: string) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfig.get(`/pesonals/get-list-personal-email` + `${param}`)
}

export const putPublishCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/v1/courses/approve-apart`, data)
}

export const appriseContentCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/v1/courses/apprise-courses`, data)
}

export const unPublishslidesCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/v1/courses/unpublish-slide`, data)
}

export const unPublishContentCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-courses/un-publish`, data)
}

export const publishContentCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-courses/publish`, data)
}

export const publishOneContentCourse: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses/publish-course-with-format-id`, data)
}

export const getCourseHistory: (id: string | undefined, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.post(`/course/structure/approve-history/${id}`, data)
}

export const getAllPublishCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses-apprise/search-course-publish`, data)
}

export const getAllStatusReviewCourse: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course/structure/apprise-details/${id}`)
}

export const getCourseFormatId: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-courses/get-course-format-id?idCourse=${id}`)
}

export const getListSchool: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-schools`)
}

export const shareMail: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/courses/config/send-mail-invite-course`, data)
}

export const duplicateUnit: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfigV2.post(`/mooc-courses/duplicate-unit`, dataReq)
}
