import React, { useEffect, useState } from "react";
import { Button, Col, Radio, Row, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { confirmMessage } from "../../../../pages/page-builder/grapesjs-service";
import { useAddLesson } from "../../../../stores/stores";
import { TypeCreateQuestion, initialQuestionSettingValue } from "../../../../constants";
import TextEditor from "../../../text-editor/TextEditor";

const { Text } = Typography;

interface QuestionTextProps {
  dataItem?: any;
  valueRadio?: any;
  onChange?: (val: { id: number; value: string }) => void;
  onDelete?: (id: number) => void;
}

function QuestionTextItem({ dataItem, valueRadio, onChange = () => { }, onDelete = () => { }, }: QuestionTextProps) {
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);

  const [disableChecked, setDisableChecked] = useState<boolean>(false);

  useEffect(() => {
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      if (Object.keys(settingQuestion).length > 0) {
        setDisableChecked(!settingQuestion.canEnterAnswer);
      } else {
        setDisableChecked(!initialQuestionSettingValue.canEnterAnswer);
      }
    }
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      setDisableChecked(false);
    }
  }, [settingQuestion]);

  const handleChangeText = (changeValue: string) => {
    onChange({ id: dataItem.id, value: changeValue });
  };

  const handleDelete = (id: number) => {
    confirmMessage('Xóa lựa chọn', 0, 'Bạn có chắc muốn xóa lựa chọn này?', () => { onDelete(id) });
  }

  return (
    <section className='answer'>
      <Radio value={valueRadio} disabled={disableChecked}></Radio>
      <div className='ml-2 form-value w-full'>
        <Row>
          <Col span={24} className='form-value__header'>
            <Text>Câu trả lời {dataItem.id}</Text>
          </Col>
          <Col span={24}>
            <TextEditor
              value={dataItem.value ? dataItem.value : 'Nhập câu trả lời'}
              onChange={handleChangeText}
            />
            {/* <FormInputText
              value={textValue}
              onChange={handleChangeText}
              placeholder="Nhập câu trả lời"
              autoSize={{ minRows: 1, maxRows: 6 }}
              containerClassName="w-full"
              label=""
              type="textarea"
            /> */}
          </Col>
        </Row>
      </div>
      <Button
        className="btn-delete-item ml-2"
        onClick={() => handleDelete(dataItem.id)}
        icon={<DeleteOutlined />}
      ></Button>
    </section>
  );
}

export default QuestionTextItem;
