// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-settings {
  display: flex;
  justify-content: center; }
  .wrapper-settings .setting-survey {
    width: 50%;
    height: auto;
    gap: 16px;
    border-radius: 8px; }
    .wrapper-settings .setting-survey .survey-card {
      padding: 24px; }
      .wrapper-settings .setting-survey .survey-card .ant-card-head .ant-card-head-title {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500; }
      .wrapper-settings .setting-survey .survey-card .setting-item {
        padding: 12px 0;
        margin: 0;
        border-top: 1px solid #d9d9d9; }
        .wrapper-settings .setting-survey .survey-card .setting-item .ant-form-item-label {
          width: 30%;
          display: flex;
          justify-content: start; }
          .wrapper-settings .setting-survey .survey-card .setting-item .ant-form-item-label label {
            font-weight: 700;
            line-height: 22px; }
        .wrapper-settings .setting-survey .survey-card .setting-item .ant-form-item-control {
          width: 50%; }
          .wrapper-settings .setting-survey .survey-card .setting-item .ant-form-item-control .object-item-selected .row-survey-content {
            margin-bottom: 10px; }
            .wrapper-settings .setting-survey .survey-card .setting-item .ant-form-item-control .object-item-selected .row-survey-content .label-survey {
              margin-bottom: 15px; }
            .wrapper-settings .setting-survey .survey-card .setting-item .ant-form-item-control .object-item-selected .row-survey-content .survey-content {
              display: flex;
              flex-wrap: wrap;
              margin-top: 5px; }
            .wrapper-settings .setting-survey .survey-card .setting-item .ant-form-item-control .object-item-selected .row-survey-content .survey-content-more {
              margin-top: 50px; }
      .wrapper-settings .setting-survey .survey-card .item-setting:last-child {
        padding-bottom: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/survey-plan/survey-detail/plan-info/plan-info.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAA;EAFzB;IAKI,UAAU;IACV,YAAY;IACZ,SAAS;IACT,kBAAkB,EAAA;IARtB;MAWM,aAAa,EAAA;MAXnB;QAeU,eAAe;QACf,iBAAiB;QACjB,gBAAgB,EAAA;MAjB1B;QAsBQ,eAAe;QACf,SAAS;QACT,6BAA6B,EAAA;QAxBrC;UA2BU,UAAU;UACV,aAAa;UACb,sBAAsB,EAAA;UA7BhC;YAgCY,gBAAgB;YAChB,iBAAiB,EAAA;QAjC7B;UAsCU,UAAU,EAAA;UAtCpB;YAyCc,mBAAmB,EAAA;YAzCjC;cA2CgB,mBAAmB,EAAA;YA3CnC;cA+CgB,aAAa;cACb,eAAe;cACf,eAAe,EAAA;YAjD/B;cAqDgB,gBAAgB,EAAA;MArDhC;QA6DQ,iBAAiB,EAAA","sourcesContent":[".wrapper-settings {\n  display: flex;\n  justify-content: center;\n\n  .setting-survey {\n    width: 50%;\n    height: auto;\n    gap: 16px;\n    border-radius: 8px;\n\n    .survey-card {\n      padding: 24px;\n\n      .ant-card-head {\n        .ant-card-head-title {\n          font-size: 20px;\n          line-height: 28px;\n          font-weight: 500;\n        }\n      }\n\n      .setting-item {\n        padding: 12px 0;\n        margin: 0;\n        border-top: 1px solid #d9d9d9;\n\n        .ant-form-item-label {\n          width: 30%;\n          display: flex;\n          justify-content: start;\n\n          label {\n            font-weight: 700;\n            line-height: 22px;\n          }\n        }\n\n        .ant-form-item-control {\n          width: 50%;\n          .object-item-selected {\n            .row-survey-content {\n              margin-bottom: 10px;\n              .label-survey {\n                margin-bottom: 15px;\n              }\n\n              .survey-content {\n                display: flex;\n                flex-wrap: wrap;\n                margin-top: 5px;\n              }\n\n              .survey-content-more {\n                margin-top: 50px;\n              }\n            }\n          }\n        }\n      }\n\n      .item-setting:last-child {\n        padding-bottom: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
