import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  MenuProps,
  Row,
  TableColumnType,
  Typography,
} from "antd";

import FormInput from "../../../form-input/FormInput";
import "./filter-table.scss";
import {
  DownOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { DataType } from "../../../../types/assign-course-construction";
import { CheckboxOptionType } from "antd/lib";

type FilterTableProps = {
  handleOpen: () => void;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
  checkedList: Array<string>;
  setCheckedList: (value: string[]) => void;
  dataColumns: TableColumnType<DataType>[];
  selectedRowKeys: React.Key[];
  tab: string
};

const FilterHistoryManagementData: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
  selectedRowKeys,
  tab
}) => {
  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const items: MenuProps["items"] = [
    {
      label: (
        <Checkbox.Group
          options={options as CheckboxOptionType[]}
          value={checkedList}
          onChange={(value) => {
            setCheckedList(value as string[]);
          }}
        />
      ),
      key: "radio",
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <>
      <div className="heading-extra">
        <Row gutter={24} className="row-filter">
          <Col
            md={{ span: 3 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            xs={{ span: 3 }}
            className="filter-group-select"
          >
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>Bộ lọc</Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xs={{ span: 12 }}
          >
            <div className="heading-search">
              <FormInput
                placeholder="Nhập từ khoá cần tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterHistoryManagementData;
