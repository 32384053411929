import {
  ReadOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCourseDetail } from "../../service/courses";
import CourseStudentList from "./course-student-list/CourseStudentList";
import "./style.scss";
import TranscriptList from "./transcript-list/TranscriptList";
import SafeLink from "../../components/link/SafeLink";
import { routesConfig } from "../../config/routes";
import CourseManager from "../study-management/course";
import MyCourseContentDetail from "../study-management/course/my-course/my-course-detail/MyCourseContentDetail";
import PageHeader from "../../components/page-header/PageHeader";
import MyCourseDetail from "../study-management/course/my-course/my-course-detail";
type Props = {};
const { Header, Content, Footer, Sider } = Layout;

const CourseManagement = (props: Props) => {
  const { id } = useParams();
  const [selectedKey, setSelectedKey] = useState("1");

  const items = [
    {
      label: "Quản lý học viên",
      key: "1",
      icon: <UsergroupAddOutlined />,
    },
    {
      label: "Quản lý bảng điểm",
      key: "2",
      icon: <ReadOutlined />,
    },
  ];

  const renderContent = () => {
    switch (selectedKey) {
      case "1":
        return <CourseStudentList />;
      case "2":
        return <TranscriptList />;
      default:
        return null;
    }
  };
  const [detailData, setDetailData] = useState<any>(null);
  const getDetailCourseData = async () => {
    await getCourseDetail(Number(id)).then((res) => {
      const { data } = res.data;
      setDetailData(data);
    });
  };

  useEffect(() => {
    getDetailCourseData();
  }, []);

  return (
    <Layout className="course-management">
      <Breadcrumb style={{ padding: "8px 12px" }}>
        <Breadcrumb.Item>
          <SafeLink
            to={`${routesConfig.courseManagement}/${routesConfig.courseList}`}
          >
            Danh sách khoá học
          </SafeLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{detailData?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Menu
          mode="horizontal"
          defaultSelectedKeys={["1"]}
          items={items}
          style={{ flex: 1, minWidth: 0 }}
          onClick={(e) => setSelectedKey(e.key)}
        />
      </Header>
      <Content>{renderContent()}</Content>
    </Layout>
  );
};

export default CourseManagement;
