import {
  ContactsOutlined,
  EyeOutlined,
  FileTextOutlined,
  FolderOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Flex, Layout, message, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommonModal from "../../components/modal/common";
import PageHeaderCommon from "../../components/page-header-common/PageHeaderCommon";
import ModalQualityForm from "./ModalQualityForm";
import RateQuality from "./rate-quality";
import SettingStandard from "./setting-standard";
import {
  getSettingQualityAssessment,
  getSettingQualityAssessmentAllForm,
  updateSettingQualityAssessment,
} from "../../service/control-quality-course";
import ModalSettingCourse from "./ModalSettingCourse";
import { CheckRole, getPermissions } from "../../utils/checkRole";
import { Permission } from "../../layout/sidebar/permission.enum";

const { Content } = Layout;
const imgPath = "/images/";

const ControlQualityCourse = () => {
  const navigate = useNavigate();

  const [isView, setIsView] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [isSetting, setIsSetting] = useState(false);

  const [numberTab, setNumberTab] = useState("1");

  const [isConfirmClose, setIsConfirmClose] = useState(false);
  const [dataStandard, setDataStandard] = useState<any>([]);
  const [displayRoleUpdate, setDisplayRoleUpdate] = useState(false);
  const [listPermission, setListPermission] = useState([]);
  const [isStandard, setIsStandard] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleNotice = (type: any, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleUpdate = [
      Permission.CourseQualityAssessmentCriteriaSettingUpdate,
    ];

    const checkRoleUpdate = CheckRole(roleUpdate, listPermission);
    if (checkRoleUpdate.includes(true)) {
      setDisplayRoleUpdate(true);
      setNumberTab("1")
    }
    if (!checkRoleUpdate.includes(true)) {
      setNumberTab("2")
    }
  }, [listPermission]);

  const fetchData = async () => {
    try {
      const response = await getSettingQualityAssessmentAllForm();
      const { data } = response.data;
      setDataStandard(data?.mooc_setting_quality_assessment_group);
      setIsOpen(data?.is_active);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, [isRefresh]);

  const handleRefresh = () => setIsRefresh(!isRefresh);
  const GroupButton = () => {
    const handleIsOpen = async () => {
      if (isOpen) {
        setIsConfirmClose(true);
      } else {
        setIsOpen(true);
        try {
          await updateSettingQualityAssessment({
            is_active: true,
          });
          handleNotice("success", "Thay đổi trạng thái thành công");
        } catch (error) { }
      }
    };
    const handleCloseSetting = async () => {
      setIsOpen(false);
      setIsConfirmClose(false);
      try {
        await updateSettingQualityAssessment({
          is_active: false,
        });
        handleNotice("success", "Thay đổi trạng thái thành công");
      } catch (error) { }
    };
    return (
      <div>
        {isView ? (
          <Button onClick={() => setIsView(false)}>
            <EyeOutlined className="pr-2" />
            Thoát chế độ xem
          </Button>
        ) : (
          <Flex>
            <Button className="mr-2" onClick={() => setIsView(true)}>
              <EyeOutlined className="pr-2" />
              Chế độ xem
            </Button>
            {displayRoleUpdate && (
              <Button className="mr-2" onClick={() => setIsStandard(true)}>
                <FolderOutlined className="pr-2" />
                Bộ tiêu chí mẫu
              </Button>
            )}
            <Button className="mr-2" onClick={() => setIsSetting(true)}>
              <SettingOutlined className="pr-2" />
              Cài đặt cấu trúc
            </Button>
            <Button type="primary" onClick={handleIsOpen}>
              {!isOpen ? "Bật chế độ" : "Tắt chế độ"}
            </Button>
          </Flex>
        )}
        <CommonModal
          open={isConfirmClose}
          title={"Xác nhận tắt cài đặt kiểm soát chất lượng?"}
          desc={`Tắt chế độ sẽ xoá hết cài đặt hiện tại. Bạn có chắc chắn muốn tắt cài đặt?`}
          closeIcon={true}
          okText={"Xác nhận"}
          cancelText={"Huỷ"}
          icon={
            <InfoCircleOutlined
              style={{ color: "#FFC53D", marginRight: "10px" }}
            />
          }
          confirm={handleCloseSetting}
          closeModal={() => setIsConfirmClose(false)}
        />
      </div>
    );
  };

  const listBreadcrumb = [
    {
      title: "Cài đặt nâng cao",
    },
    {
      title: "Kiểm soát chất lượng khoá học",
    },
  ];
  const items: TabsProps["items"] = [
    ...(displayRoleUpdate
      ? [
        {
          key: "1",
          icon: <ContactsOutlined />,
          label: "Thiết lập tiêu chí",
          children: (
            <SettingStandard
              isOpen={isOpen}
              handleNotice={handleNotice}
              isView={isView}
              dataStandard={dataStandard}
              setDataStandard={setDataStandard}
              handleRefresh={handleRefresh}
            />
          ),
        },
      ]
      : []),
    {
      key: "2",
      icon: <FileTextOutlined />,
      label: "Đánh giá chất lượng",
      children: (
        <RateQuality
          isOpen={isOpen}
          handleNotice={handleNotice}
          isView={isView}
        />
      ),
    },
  ];

  const handleClose = () => {
    setIsStandard(false);
    setIsSetting(false);
  };
  const handleChangeTab = (tab: any) => {
    setNumberTab(tab);
  };
  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Cài đặt kiểm soát chất lượng khoá học"
        // arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        // handleBack={() => navigate("/")}
        headerGroupButton
        childrenGroupButton={<GroupButton />}
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey="1" items={items} activeKey={numberTab} onChange={handleChangeTab} />
      </div>
      <ModalQualityForm
        isOpen={isStandard}
        handleClose={handleClose}
        handleNotice={handleNotice}
        numberTab={numberTab}
        handleRefresh={handleRefresh}
      />
      <ModalSettingCourse
        isOpen={isSetting}
        handleClose={handleClose}
        handleNotice={handleNotice}
        numberTab={numberTab}
        handleRefresh={handleRefresh}
      />
    </Content>
  );
};

export default ControlQualityCourse;
