import { CloseOutlined, EditOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Switch } from "antd";
import { useEffect, useState } from "react";
import "./styles.scss";
import {
  getDetailCertificate,
  putUpdateCertificateTemplate,
} from "../../../service/certificate";
import { HelpService } from "../../../service/helper.service";

interface Template {
  id?: number;
  name?: string;
  code?: string;
  description?: string;
  template?: string;
  templateDraft?: string;
  status?: number;
  type?: number;
  createdDate?: string;
}

interface SettingCertificate {
  setNameCertificate?: React.Dispatch<React.SetStateAction<string>>;
}

const CertificateSetting = (props: SettingCertificate) => {
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [oldName, setOldName] = useState("");
  const [code, setCode] = useState("");
  const [oldCode, setOldCode] = useState("");
  const [status, setStatus] = useState(false);
  const [oldStatus, setOldStatus] = useState(false);
  const [error, setError] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [dataDetail, setDataDetail] = useState<Template>();

  const helpService = new HelpService();

  const onReturnChange = () => {
    setName(oldName);
    setCode(oldCode);
    setStatus(oldStatus);
    setIsEdit(false);
    setError("");
    setErrorCode("");
  };

  const onChange = (checked: boolean) => {
    setStatus(checked);
    // setDataDetail((prevState) => ({
    //   ...prevState,
    //   status: status ? 1 : 0,
    // }));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setError(""); // Clear error when user starts typing
    // setDataDetail((prevState) => ({
    //   ...prevState,
    //   name: name || "",
    // }));
  };

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
    // setDataDetail((prevState) => ({
    //   ...prevState,
    //   code: code || "",
    // }));
    setErrorCode(""); // Clear error when user starts typing
  };

  const handleSaveName = () => {
   
    setDataDetail((prevState) => ({
      ...prevState,
      name: name || '',
      code: code || '',
      status: status ? 1 : 0
    }))

    const dataSent = {
      ...dataDetail,
      name: name || '',
      code: code || '',
      status: status ? 1 : 0
    }
    
    putUpdateCertificateTemplate(dataSent, universityID).then(
      (res: { data: { data: string } }) => {
        setOldCode(code);
        setOldName(name);
        setOldStatus(status);
        if (props.setNameCertificate) props.setNameCertificate(name);
        helpService.successMessage(res.data.data);
      }
    );

    

    // handleChange
    // setMyObject((prevState) => ({
    //   ...prevState,
    //   name: inputValue,
    //   code: code,
    //   status: status
    // }));
  };

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const pathname = url.pathname;
  const segments = pathname.split("/");
  const universityID = segments[segments.length - 1];

  const getDetailData = () => {
    getDetailCertificate(universityID).then((res) => {
      setDataDetail({...res.data.data, isTemplateSample:false});
      setOldCode(res.data.data.code);
      setCode(res.data.data.code);
      setOldName(res.data.data.name);
      setName(res.data.data.name);
      setOldStatus(res.data.data.status === 1 ? true : false);
      setStatus(res.data.data.status === 1 ? true : false);
    });
  };

  useEffect(() => {
    getDetailData();
  }, []);

  return (
    <div className="certificate-setting">
      {/* header */}
      <div className="header-certificate-setting">
        <div>
          <div className="title-certificate">Thông tin hoạt động</div>
          <div className="description-certificate">
            Cài đặt thông tin hoạt động của mẫu
          </div>
        </div>
        <div>
          {!isEdit ? (
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setIsEdit(true);
              }}
              // loading={loadingSubmit}
              // disabled={modeQuestion === '' ? true : false}
            ></Button>
          ) : (
            <div className="button-container">
              <Button
                icon={<CloseOutlined />}
                onClick={() => {
                  onReturnChange();
                }}
                // loading={loadingSubmit}
                // disabled={modeQuestion === '' ? true : false}
              >
                Hủy
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => {
                  if (!name.trim()) {
                    setError("Trường thông tin này là bắt buộc");
                    return;
                  }
                  if (!code.trim()) {
                    setErrorCode("Trường thông tin này là bắt buộc");
                    return;
                  }

                  Modal.confirm({
                    title: `Xác nhận lưu cài đặt`,
                    content: `Đảm bảo rằng tất cả các thông tin đã được kiểm tra đầy đủ trước khi lưu`,
                    onOk: () => {
                      handleSaveName();
                    },
                    okText: "Xác nhận",
                    cancelText: "Huỷ",
                    centered: true,
                    icon: (
                      <ExclamationCircleOutlined
                        style={{ color: "#1677FF" }}
                      />
                    ),
                    footer: (_, { OkBtn, CancelBtn }) => (
                      <>
                        <CancelBtn />
                        <OkBtn />
                      </>
                    ),
                  });
                }}
                // loading={loadingSubmit}
                // disabled={modeQuestion === '' ? true : false}
              >
                Lưu
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* content */}
      <div className="content-certificate-setting">
        {/* items */}
        <div
          className={isEdit ? "certificate-item" : "certificate-item-status"}
        >
          <div className="description-item">* Tên mẫu chứng nhận/chứng chỉ</div>
          {isEdit ? (
            <div className="input-certificate">
              {error && (
                <p style={{ color: "red", marginBottom: "5px" }}>{error}</p>
              )}
              <Input
                type="text"
                value={name}
                onChange={handleNameChange}
                placeholder="Nhập tên chứng nhận/chứng chỉ"
              />
            </div>
          ) : (
            <div>{name}</div>
          )}
        </div>
        <div
          className={isEdit ? "certificate-item" : "certificate-item-status"}
        >
          <div className="description-item">* Mã mẫu chứng nhận/chứng chỉ</div>
          {isEdit ? (
            <div className="input-certificate">
              {errorCode && (
                <p style={{ color: "red", marginBottom: "5px" }}>{errorCode}</p>
              )}
              <Input
                type="text"
                value={code}
                onChange={handleCodeChange}
                placeholder="Nhập mã mẫu chứng nhận/chứng chỉ"
              />
            </div>
          ) : (
            <div>{code}</div>
          )}
        </div>
        <div className="certificate-item-status">
          <div className="description-item">Trạng thái</div>
          <Switch value={status} disabled={!isEdit} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default CertificateSetting;
