// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publish-course-program-modal .setting-chapter-description {
  margin-left: 8px;
  font-size: 14px;
  opacity: 0.7;
  font-style: italic;
  font-weight: normal; }

.publish-course-program-modal .ant-radio {
  align-self: start;
  margin-top: 3px; }
`, "",{"version":3,"sources":["webpack://./src/components/modal/publish-course-program/index.scss"],"names":[],"mappings":"AAAA;EAEI,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,mBAAmB,EAAA;;AANvB;EASI,iBAAiB;EACjB,eAAe,EAAA","sourcesContent":[".publish-course-program-modal {\n  .setting-chapter-description {\n    margin-left: 8px;\n    font-size: 14px;\n    opacity: 0.7;\n    font-style: italic;\n    font-weight: normal;\n  }\n  .ant-radio {\n    align-self: start;\n    margin-top: 3px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
