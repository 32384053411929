import { FunctionComponent, useEffect, useState } from "react";
import "./login-page.scss";

import { useNavigate } from "react-router-dom";
import LoadingFullScreen from "../../../../components/loading-full-screen/LoadingFullScreen";
import { routesConfig } from "../../../../config/routes";
import { useAuthStore } from "../../../../stores/stores";
import { LoginCard } from "../login-card/LoginCard";
import { ForgotPassword } from "../forgot-password/ForgotPassword";
import { VerifyOTP } from "../verify-otp/VerifyOTP";
import { UpdatePassword } from "../update-password/UpdatePassword";
import { UpdateSuccess } from "../update-sucess/UpdateSucess";

const LoginPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [forgotPasswordStep, setForgotPasswordStep] = useState(false);
  const [verifyMailStep, setVerifyMailStep] = useState(false);
  const [verifyMail, setVerifyMail] = useState("");
  const [OTPCode, setOTPCode] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [isCountTimeLeft, setIsCountTimeLeft] = useState(false);
  const [timeLeftLocate, setTimeLeftLocate] = useState(0);
  const [spinning, setSpinning] = useState(false);
  const accessToken = useAuthStore((state) => state.accessToken);

  useEffect(() => {
    if (accessToken) {
      setSpinning(true);
      setTimeout(() => {
        navigate("/");
        setSpinning(false);
      }, 500);
    }
  }, [accessToken]);

  useEffect(() => {
    if (timeLeftLocate > 0) {
      const intervalId = setInterval(() => {
        setTimeLeftLocate((timeLeft) => timeLeft - 1);
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    } else if (timeLeftLocate === 0) {
      setSpinning(true);
      setTimeout(() => {
        setSpinning(false);
        backToLogin("update-success");
        handleAddClassShow("login-card");
        setOTPCode("");
        setVerifyMail("");
      }, 1000);
    }
  }, [timeLeftLocate]);

  useEffect(() => {
    if (isCountTimeLeft && timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((timeLeft) => timeLeft - 1);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    } else if (timeLeft === 0) {
      setIsCountTimeLeft(false);
    }
  }, [isCountTimeLeft, timeLeft]);

  const handleAddClassHide = (id: string) => {
    var element = document.getElementById(id);
    element?.classList.add("inactive");
    element?.classList.remove("active");
  };

  const backToLogin = (id: string) => {
    var element = document.getElementById(id);
    element?.classList.remove("inactive");
    element?.classList.remove("active");
  };
  const handleAddClassShow = (id: string) => {
    var element = document.getElementById(id);
    element?.classList.add("active");
    element?.classList.remove("inactive");
  };

  return (
    <div className="login-text">
      <div className="qtht-parent">
        <div className="qtht">
          <div className="qun-tr-h">QUẢN TRỊ HỆ THỐNG</div>
        </div>
        <div className="inputsuffix">
          <h1 className="mooc3">MOOC</h1>
          <h3 className="massive-open-online">(Massive open online course)</h3>
        </div>
      </div>
      <div className="secondary-button">
        <div className="login-card">
          {LoginCard(
            forgotPasswordStep,
            verifyMailStep,
            loadingLogin,
            setLoadingLogin,
            setForgotPasswordStep,
            handleAddClassHide,
            handleAddClassShow
          )}

          {ForgotPassword(
            loadingLogin,
            backToLogin,
            handleAddClassShow,
            setForgotPasswordStep,
            setIsCountTimeLeft,
            setLoadingLogin,
            verifyMailStep,
            setVerifyMailStep,
            setTimeLeft,
            handleAddClassHide,
            verifyMail,
            setVerifyMail
          )}
          {VerifyOTP(
            verifyMailStep,
            loadingLogin,
            setVerifyMailStep,
            handleAddClassHide,
            handleAddClassShow,
            setLoadingLogin,
            setIsCountTimeLeft,
            verifyMail,
            OTPCode,
            setOTPCode,
            timeLeft,
            setTimeLeft
          )}

          {UpdatePassword(
            loadingLogin,
            setLoadingLogin,
            OTPCode,
            verifyMail,
            setOTPCode,
            setTimeLeft,
            setIsCountTimeLeft,
            setTimeLeftLocate,
            handleAddClassHide,
            handleAddClassShow
          )}
          {UpdateSuccess(
            timeLeftLocate,
            loadingLogin,
            backToLogin,
            handleAddClassShow,
            setVerifyMail,
            setOTPCode,
            setTimeLeftLocate
          )}
        </div>
        {spinning && <LoadingFullScreen spinning={spinning} />}
      </div>
    </div>
  );
};

export default LoginPage;
