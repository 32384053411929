import { StateCreator } from "zustand";

export interface Sider {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
}

export const SiderStore: StateCreator<Sider> = (set, get) => ({
  isOpen: true,
  setIsOpen: (value: boolean) => {
    set({ isOpen: value });
  },
});
