// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-category .category-name {
  font-size: 16px;
  font-weight: 500;
  color: #1677ff; }

.popconfirm-change-status .ant-popconfirm-inner-content {
  padding: 5px; }
  .popconfirm-change-status .ant-popconfirm-inner-content .ant-popconfirm-buttons {
    display: flex;
    justify-content: end; }
`, "",{"version":3,"sources":["webpack://./src/pages/category-management/shared-categories-management/shared-categories-management.scss"],"names":[],"mappings":"AAAA;EAEQ,eAAe;EACf,gBAAgB;EAChB,cAAc,EAAA;;AAItB;EAEQ,YAAY,EAAA;EAFpB;IAKY,aAAa;IACb,oBAAoB,EAAA","sourcesContent":[".table-category {\n    .category-name {\n        font-size: 16px;\n        font-weight: 500;\n        color: #1677ff;\n    }\n}\n\n.popconfirm-change-status {\n    .ant-popconfirm-inner-content {\n        padding: 5px;\n\n        .ant-popconfirm-buttons {\n            display: flex;\n            justify-content: end;\n        }\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
