import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSettingIdentity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/setting-identity/get-setting`);
};

export const createSettingIdentity: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-identity/create-setting`, dataReq);
};

export const updateSettingIdentity: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-identity/update-setting/${id}`, dataReq);
};


export const getSettingIdentityDocument: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/setting-identity-document/get`);
};

export const createSettingIdentityDocument: (dataReq: any) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-identity-document/create`, dataReq);
};

export const updateSettingIdentityDocument: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-identity-document/update/${id}`, dataReq);
};
export const deleteSettingIdentityDocument: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/setting-identity-document/delete/${id}`);
};