import React, { useEffect, useState } from "react";
import "../student-data-analysis/StudentDataAnalysis.scss";
import { Button, Table, TableColumnsType } from "antd";
import moment from "moment";
import {
  exportExcelLearningStatus,
  exportExcelRegistrationForm,
  getEnrollmentRateStatistics,
  getStudyStatusRateStatistics,
} from "../../../../../service/course-management";
import { useParams } from "react-router-dom";

interface Statistics {
  code: number;
  description: string;
  count: number;
  percentage: number;
}

interface Props {}

const QuantityStatistics: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const [enrollmentRateStatistics, setEnrollmentRateStatistics] = useState<
    Statistics[]
  >([]);
  const [enrollLoading, setEnrollLoading] = useState<boolean>(false);
  const [studyStatusRateStatistics, setStudyStatusRateStatistics] = useState<
    Statistics[]
  >([]);
  const [studyStatus, setStudyStatus] = useState<boolean>(false);

  const fetchEnrollmentRateStatistics = async () => {
    setEnrollLoading(true);
    try {
      const res = await getEnrollmentRateStatistics(Number(id));
      setEnrollmentRateStatistics(res.data.data.statistics);
      setEnrollLoading(false);
    } catch (error) {
      setEnrollLoading(false);
    }
  };

  const fetchStudyStatusRateStatistics = async () => {
    setStudyStatus(true);

    try {
      const res = await getStudyStatusRateStatistics(Number(id));
      setStudyStatusRateStatistics(res.data.data.statistics);
      setStudyStatus(false);
    } catch (error) {
      setStudyStatus(false);
    }
  };

  useEffect(() => {
    fetchEnrollmentRateStatistics();
    fetchStudyStatusRateStatistics();
  }, []);

  const generateColumns = (data: Statistics[]): TableColumnsType<any> =>
    data.map((item, index) => ({
      title: item.description,
      children: [
        {
          title: "Số lg",
          dataIndex: `count_${index}`,
          key: `count_${index}`,
        },
        {
          title: "Tỉ lệ",
          dataIndex: `percentage_${index}`,
          key: `percentage_${index}`,
        },
      ],
    }));

  const transformData = (data: Statistics[]): Record<string, any>[] => {
    const transformed: Record<string, any> = {};
    data.forEach((item, index) => {
      transformed[`count_${index}`] = item.count;
      transformed[`percentage_${index}`] = `${item.percentage}%`;
    });
    return [transformed];
  };

  const enrollmentColumns: TableColumnsType<any> = [
    ...generateColumns(enrollmentRateStatistics),
    {
      title: "Tổng",
      dataIndex: "total",
      key: "total",
    },
  ];

  const studyStatusColumns: TableColumnsType<any> = [
    ...generateColumns(studyStatusRateStatistics),
    {
      title: "Tổng",
      dataIndex: "total",
      key: "total",
    },
  ];

  const totalEnrollment = enrollmentRateStatistics.reduce(
    (acc, stat) => acc + stat.count,
    0
  );
  const totalStudyStatus = studyStatusRateStatistics.reduce(
    (acc, stat) => acc + stat.count,
    0
  );
  const enrollmentData = transformData(enrollmentRateStatistics).map(
    (item) => ({
      ...item,
      total: totalEnrollment,
    })
  );
  const studyStatusData = transformData(studyStatusRateStatistics).map(
    (item) => ({
      ...item,
      total: totalStudyStatus,
    })
  );

  const handleExportRegistrationForm = async () => {
    try {
      const res = await exportExcelRegistrationForm(Number(id));
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Thống kê theo hình thức ghi danh.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleExportExcelLearningStatus = async () => {
    try {
      const res = await exportExcelLearningStatus(Number(id));
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Thống kê theo trạng thái học tập.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  return (
    <div className="quantity-statistics">
      <div className="content-card flex-1">
        <div className="content-card-name">
          <div>
            <div className="title">Hình thức ghi danh</div>
            <div className="description">
              Thống kê cập nhật đến {moment(new Date()).format("HH:mm")} ngày{" "}
              {moment(new Date()).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="action" onClick={handleExportRegistrationForm}>
            Xuất báo cáo
          </div>
        </div>
        <Table
          columns={enrollmentColumns}
          dataSource={enrollmentData}
          pagination={false}
          bordered
          loading={enrollLoading}
        />
      </div>
      <div className="content-card flex-1">
        <div className="content-card-name">
          <div>
            <div className="title">Trạng thái học tập</div>
            <div className="description">
              Thống kê cập nhật đến {moment(new Date()).format("HH:mm")} ngày{" "}
              {moment(new Date()).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className="action" onClick={handleExportExcelLearningStatus}>
            Xuất báo cáo
          </div>
        </div>
        <Table
          columns={studyStatusColumns}
          dataSource={studyStatusData}
          pagination={false}
          bordered
          loading={studyStatus}
        />
      </div>
    </div>
  );
};

export default QuantityStatistics;
