import React, { useEffect, useRef, useState, createRef } from "react";
import { getCourseDetail } from "../../../../service/course-construct";

interface LecturerBlockCustomProps {
  courseid: string;
  userid: string;
  uuid?: string;
  tab?: string;
}
export const LecturerBlockCustom: React.FC<LecturerBlockCustomProps> = ({ courseid, userid, uuid, tab }) => {
  const channel = new BroadcastChannel('lecturer_channel');
  const [data, setData] = useState<any>([]);
  const [assigners, setAssigners] = useState<any>([]);
  const [courseId, setCourseId] = useState<any>(courseid);
  const [userIds, setUserIds] = useState<any>(userid ? userid.split(",") : []);

  interface User {
    assignUserId: number;
    assignUserName: string;
    image: string | null;
    imageCourseAssign: string | null;
    instruct: boolean;
    construct: boolean;
  }


  const handleGetSelectedLecturer = () => {
    const arr1 = assigners;
    const userIdsNumber = userIds.map(Number);
    const filteredUsers = arr1.filter((user: User) =>
      userIdsNumber.length == 0 || userIdsNumber.includes(Number(user.assignUserId))
    );
    setData(filteredUsers);
  };

  const handleGetLecturer = async () => {
    const res = await getCourseDetail(courseId ? courseId : "0");
    const dataNew = res.data.data.assigners.flatMap(
      (obj: { assigned: any }) => obj.assigned
    );
    await setAssigners(dataNew);
  };

  useEffect(() => {
    channel.onmessage = (event) => {
      const message = event.data;
      if (message.uuid !== uuid) {
        return;
      }
      const userId = message.payload;
      setUserIds(userId ? userId.split(",") : []);
    };
    handleGetLecturer();
  }, []);

  useEffect(() => {
    handleGetSelectedLecturer();
  }, [userIds]);

  useEffect(() => {
    handleGetSelectedLecturer();
  }, [assigners]);

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 mx-auto py-8">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="text-2xl font-medium title-font mb-4 text-purple-700 sm:text-3xl">
            Người hướng dẫn
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Danh sách giảng viên hàng đầu tham gia hướng dẫn tại tại khoá học
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {data?.map((item: any, index: number) => {
            return (
              <div className="p-4 lg:w-1/2" key={index + 1}>
                <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                  <img alt="team" src={item.imageCourseAssign ? item.imageCourseAssign : "https://dummyimage.com/200x200"} className="flex-shrink-0 w-48 h-48 object-cover object-center sm:mb-0 mb-4 rounded-full" />
                  <div className="flex-grow sm:pl-8">
                    <h2 className="title-font font-medium text-lg text-gray-900">
                      {item.assignUserName}
                    </h2>
                    <h3 className="text-gray-500 mb-3">
                      {item.instruct ? item.instruct : 'Không có'}
                    </h3>
                    <p className="mb-4">
                      {item.construct ? item.construct : 'Không có'}
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
};
