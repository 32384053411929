import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface DonutChartProps {
  data: { type: number; criteria: string; count: number; percentage: number }[];
  title: string;
  height?: number | string;
  innerSize?: string;
  size?: string;
  colors?: string[];
  totalName?: string;
}

const DonutChart = forwardRef((props: DonutChartProps, ref) => {
  const {
    data,
    title,
    height,
    innerSize = "74%",
    size = "100%",
    colors,
    totalName = "Tổng số",
  } = props;

  const total = data.reduce((sum, point) => sum + point.count, 0);
  const formattedData = data.map((item) => ({
    name: item.criteria,
    y: item.count,
  }));

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
      height: height,
      width: null,
      events: {
        load: function () {
          const chartDivs = document.querySelectorAll(
            "div[data-highcharts-chart]"
          );
          chartDivs.forEach((div) => {
            div.classList.add("donut-chart-container");
          });
        },
      },
    },
    title: {
      text: title,
      align: "center",
      verticalAlign: "middle",
      y: -10,
      style: {
        fontSize: "20px",
      },
    },
    subtitle: {
      text: `<span class="total-name" style="color: #000; font-size: 14px; font-weight: 500;opacity: 0.45;">${totalName}</span><br><span class="total-value" style="color: #000000; font-size: 20px; font-weight: 500;">${total.toLocaleString()}</span>`,
      align: "center",
      verticalAlign: "middle",
      y: 10,
      style: {
        fontSize: "16px",
        color: "#000",
      },
    },
    tooltip: {
      formatter: function () {
        if (this.point) {
          const colorDot = `<span style="display:inline-block;width:10px;height:10px;border-radius:50%;background-color:${this.point.color};margin-right:5px;"></span>`;
          return `<b style="color: #000; font-size: 12px; font-weight: 500;opacity: 0.45;">${
            this.point.name
          }</b>
           <br/>
          <div style="color: #000; font-size: 14px; font-weight: 500;margin-top:6px;">
          ${colorDot}${this.point.y} (${this.point.percentage?.toFixed(
            2
          )}%)</div>`;
        }
        return "";
      },
      useHTML: true,
    },
    plotOptions: {
      pie: {
        innerSize: innerSize,
        size: size,
        dataLabels: {
          enabled: true,
          format: "<span>{point.name}</span>",
          distance: 10,
          style: {
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "right",
            color: "#000",
            textOutline: "none",
          },
          connectorWidth: 1,
        },
      },
    },
    series: [
      {
        type: "pie",
        name: "Share",
        data: formattedData,
        borderRadius: 6,
      },
    ],
    credits: {
      enabled: false,
    },
    colors: colors,
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
      },
    },
  };

  useImperativeHandle(ref, () => ({
    getChart: () => chartRef.current?.chart,
  }));

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
});

export default DonutChart;
