// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-synthesis .list-chart {
  width: 82vw;
  margin-top: 20px; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-synthesis/components/result-chart/index.scss"],"names":[],"mappings":"AAAA;EAEQ,WAAgB;EAChB,gBAAgB,EAAA","sourcesContent":[".survey-managment-synthesis {\n    .list-chart {\n        width     : 82vw;\n        margin-top: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
