import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getStudentFavoriteSuggest: (
  param: any
) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfig.get(
    `/student-favorite/get-suggest-course-by-user/${param?.id}?skip=${param?.skip}&take=${param?.take}&search=${param?.name}`
  );
};

export const getPopularCourse: (param: any) => Promise<AxiosResponse<any>> = (
  param
) => {
  return axiosConfig.get(
    `/recommendation/get-popular-course?skip=${param?.skip}&take=${param?.take}&name=${param?.name}`
  );
};

export const getLastestCourse: (param: any) => Promise<AxiosResponse<any>> = (
  param
) => {
  return axiosConfig.get(
    `/recommendation/get-lastest-course?skip=${param?.skip}&take=${param?.take}&name=${param?.name}`
  );
};

export const getSystemSuggestCourseAll: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/recommendation/get-all`, dataReq);
};

export const getAllTagSystemSuggestCourse: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.post(`/recommendation-tag/get-all-tag`, {
    take: 1000,
  });
};

export const getAllStudentFavorite: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/student-favorite/get-all`, dataReq);
};

export const getListTagFavorite: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(
    `/student-favorite/search-student-same-favorite`,
    dataReq
  );
};

export const getStudentFavoriteById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/student-favorite/get/${id}`);
};

export const getSystemSuggestCourseById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/recommendation/get/${id}`);
};

export const updateSystemSuggestCourse: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/recommendation/update-by-course/${id}`, dataReq);
};
