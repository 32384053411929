import React from "react";
import { QuestionProps } from "../../../../../types/course";
import { Form, Select } from "antd";

function Dropdown({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <Form.Item
      name="dropdown"
      initialValue={1}
      rules={[{ required: true, message: "Vui lòng chọn đáp án" }]}
      className={`${isCorrect ? "select-quiz-correct" : ""} ${isIncorrect ? "select-quiz-incorrect choose-incorrect" : ""}`}
    >
      <Select
        disabled
        placeholder="Chọn đáp án của bạn"
        options={[
          { label: "Đáp án 01", value: 1 },
          { label: "Đáp án 02", value: 2 },
          { label: "Đáp án 03", value: 3 },
          { label: "Đáp án 04", value: 4 },
        ]}
      />
    </Form.Item>
  );
}

export default Dropdown;
