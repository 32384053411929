import { CheckCircleOutlined } from "@ant-design/icons";
import { Button, Layout, Modal } from "antd";
import { FunctionComponent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../config/routes";
import ExamStudentContent from "./exam-student-content";
import { useAutomaticScoreStore } from "../../../stores/stores";

const { Content } = Layout;
const imgPath = "/images/";

const ListExamStudent: FunctionComponent = () => {
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { dataExamScore } = useAutomaticScoreStore(state => state)
  const params = useParams();

  const handleSubmitExam = () => {
    setIsOpen(true);
  };
  return (
    <>
      <Content className="add-lesson">
        <PageHeaderCommon
          title={"Chấm điểm tự động"}
          subTitle=""
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={true}
          childrenGroupButton={
            <div className="button-container">
              <Button type="primary" onClick={handleSubmitExam}>
                Hoàn thành tiến trình
              </Button>{" "}
            </div>
          }
          handleBack={() => navigation(`/${routesConfig.autoMarkActionDetail}/${params?.id}`)}
        />
        <ExamStudentContent />
        <Modal
          title={
            <div>
              <CheckCircleOutlined style={{ color: "#52C41A" }} />
              <span className="pl-2">Hoàn tất chấm điểm tự động</span>
            </div>
          }
          open={isOpen}
          closeIcon={false}
          width={400}
          centered
          footer={
            <Button type="primary" onClick={() => setIsOpen(false)}>
              Đóng
            </Button>
          }
        >
          <div className="px-4">
            Tiến trình chấm điểm tự động đã hoàn tất. Vui lòng xem kết quả bảng
            điểm sinh viên
            <span
              onClick={() =>
                navigation(`/${routesConfig.managementListScore}/${params?.id}`)
              }
              className="pl-1 text-[#1677FF] cursor-pointer	"
            >
              tại đây
            </span>
          </div>
        </Modal>
      </Content>
    </>
  );
};

export default ListExamStudent;
