import {
  DownOutlined,
  PlusOutlined,
  SettingOutlined,
  FilterOutlined,
  DeleteOutlined,
  SearchOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Form,
  DatePicker,
  FormInstance,
} from "antd";
import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
  KeyboardEvent
} from "react";
import PageHeader from "../../../../../components/page-header/PageHeader";
import FormItemInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { FilterParticipant } from "../../../../../types/survey-managment/listSurvey";
import { getUniversity } from "../../../../../service/common";
import {
  DataType,
  DataSchool,
} from "../../../../../types/survey-managment/listSurvey";
import * as FileSaver from "file-saver";
import XLSX from "xlsx-js-style";
type PageHeaderType = {
  handleOpen: () => void;
  title?: string | null;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  filterData: FilterParticipant;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  handleSubmitSearch: () => void;
  clearFilter: () => void;
  form: FormInstance;
  dataExport?: any;
  handleSearchEnter: (e: KeyboardEvent<HTMLSpanElement>) => void,
};
const FilterTable: FunctionComponent<PageHeaderType> = ({
  handleSearchEnter,
  title,
  searchValue,
  handleChangeInput,
  filterData,
  setFilterData,
  handleSubmitSearch,
  clearFilter,
  form,
  dataExport,
}) => {
  const { RangePicker } = DatePicker;
  const [showFilter, setShowFilter] = useState(false);
  const [dataSchool, setDataSchool] = useState<DataSchool[]>([]);
  const [dataOptions, setDataOptions] = useState({
    dataStatus: [
      {
        id: 1,
        name: "Tự triển khai",
      },
      {
        id: 2,
        name: "Hợp tác xây dựng",
      },
    ],
    dataTypes: [
      {
        id: 5,
        name: "Đã xuất bản",
      },
      {
        id: 6,
        name: "Kết thúc",
      },
    ],
  });

  const openFilter = () => {
    setShowFilter(!showFilter);
  };
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
    );
  };
  const exportToCSV = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    console.log(dataExport);
    let dataFormat: any[] = [];
    dataExport.forEach((item: any, key: any) => {
      dataFormat.push({
        STT: key + 1,
        "Tên người trả lời khảo sát": item.name,
        "Trường đại học": item.university,
        Email: item.email,
        "Ngày trả lời": item.participationEnd ? item.participationEnd : "",
      });
    });
    console.log(dataFormat);

    const wscols = [
      { wch: 10 }, // Độ rộng cột cho "STT"
      { wch: 40 }, // Độ rộng cột cho "Tên người"
      { wch: 30 }, // Độ rộng cột cho "Trường đại học"
      { wch: 20 }, // Độ rộng cột cho "Email"
      { wch: 30 }, // Độ rộng cột cho "Ngày trả lời"
    ];

    const headerStyle = {
      fill: {
        patternType: "solid",
        fgColor: { rgb: "ADD8E6" }, // Màu nền màu vàng
      },
      font: {
        bold: true, // Chữ in đậm
      },
      alignment: {
        horizontal: "center", // Căn giữa
      },
      border: {
        // Định dạng border
        top: { style: "thin", color: { rgb: "000000" } }, // Border trên
        bottom: { style: "thin", color: { rgb: "000000" } }, // Border dưới
        left: { style: "thin", color: { rgb: "000000" } }, // Border trái
        right: { style: "thin", color: { rgb: "000000" } }, // Border phải
      },
    };

    const ws = XLSX.utils.json_to_sheet(dataFormat);
    // Định nghĩa phong cách căn giữa
    const centerAlignment = {
      horizontal: "center",
      vertical: "center",
    };

    // Áp dụng căn giữa cho cột thứ 5
    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        const cell_ref = XLSX.utils.encode_cell({ c: 4, r: R }); // Cột E là cột thứ 5, đánh số từ 0
        if (!ws[cell_ref]) continue;
        ws[cell_ref].s = ws[cell_ref].s || {};
        ws[cell_ref].s.alignment = centerAlignment;
      }
    }

    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]); // lấy phạm vi của bảng
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_col(C) + "1"; // tạo địa chỉ A1, B1, C1, v.v...
        if (!ws[address]) continue; // nếu ô không tồn tại, bỏ qua
        ws[address].s = headerStyle; // áp dụng định dạng
        console.log(ws[address].s);
      }
    }
    const borderStyle = {
      top: { style: "thin", color: { rgb: "000000" } },
      bottom: { style: "thin", color: { rgb: "000000" } },
      left: { style: "thin", color: { rgb: "000000" } },
      right: { style: "thin", color: { rgb: "000000" } },
    };

    // Áp dụng viền cho tất cả các ô
    if (ws["!ref"]) {
      const range = XLSX.utils.decode_range(ws["!ref"]);
      for (let R = range.s.r; R <= range.e.r; ++R) {
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });
          if (!ws[cell_ref]) continue;
          ws[cell_ref].s = ws[cell_ref].s || {};
          ws[cell_ref].s.border = borderStyle;
        }
      }
    }

    ws["!cols"] = wscols;

    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "data");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "export" + fileExtension);
  };

  const getUniversityList = async () => {
    await getUniversity().then((res: any) => {
      const universityFormat = res.data.data.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      setDataSchool(res.data.data);
    });
  };
  useEffect(() => {
    getUniversityList();
  }, []);
  return (
    <Layout className="page-header-group">
      <div
        className={`page-header-group--layout ${
          showFilter ? "open-filter" : ""
        }`}
      >
        <div className="table-header--wrapper">
          <div className="table-header--left">
            <PageHeader title={title} />
          </div>
          <div className="heading-extra">
            <Row gutter={[{ md: 24, xl: 24, xs: 24 }, 8]}>
              <Col
                md={{ span: 5 }}
                lg={{ span: 5 }}
                xl={{ span: 5 }}
                xs={{ span: 8 }}
              >
                <div className="heading-filter">
                  <Button className="filter-button" onClick={openFilter}>
                    <Typography.Text>Bộ lọc</Typography.Text>
                    <DownOutlined />
                  </Button>
                </div>
              </Col>
              <Col
                md={{ span: 10 }}
                lg={{ span: 10 }}
                xl={{ span: 10 }}
                xs={{ span: 12 }}
              >
                <div className="heading-search">
                  <FormItemInput
                    placeholder="Nhập từ khoá cần tìm"
                    value={searchValue}
                    onChange={handleChangeInput}
                    onKeyPress={handleSearchEnter}
                    afterPrefixIcon={
                      <SearchOutlined onClick={handleSubmitSearch} />
                    }
                  />
                </div>
              </Col>
              <Col
                md={{ span: 6 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
                xs={{ span: 7 }}
              >
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={exportToCSV}
                >
                  <DownloadOutlined />
                  <Typography.Text>Kết xuất</Typography.Text>
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
          <Form form={form} name="filterForm">
            <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
              <Col
                xl={{ span: 5 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="repDate" className="">
                  <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={["Ngày trả lời", "Ngày trả lời"]}
                    value={filterData.repDate}
                    onChange={(_value, dateString: any) => {
                      setFilterData(dateString, "repDate");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 6 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="school">
                  <FormItemDropdown
                    placeholder="Trường đại học"
                    modeDropdown="multiple"
                    value={filterData.school}
                    options={dataSchool}
                    onChange={(option) => {
                      setFilterData(option, "school");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 6 }}
                md={{ span: 9 }}
                sm={{ span: 9 }}
                xs={{ span: 24 }}
              >
                <div className="group-btn--filter">
                  <Button
                    className="btn btn-primary filter-btn"
                    // htmlType="submit"
                    onClick={handleSubmitSearch}
                    // onClick={() => form.submit()}
                  >
                    <FilterOutlined />
                    <Typography.Text>Lọc</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-outlined clear-filter-btn"
                    onClick={clearFilter}
                  >
                    <DeleteOutlined />
                    <Typography.Text>Xóa tất cả</Typography.Text>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default FilterTable;
