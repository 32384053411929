// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chapter-header .chapter-title .lesson-name {
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/list-course-construction/lesson-card/lesson-card.scss"],"names":[],"mappings":"AAAA;EAGY,YAAY;EACZ,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB,EAAA","sourcesContent":[".chapter-header{\n    .chapter-title{\n        .lesson-name{\n            width: 300px; \n            overflow: hidden; \n            text-overflow: ellipsis; \n            white-space: nowrap; \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
