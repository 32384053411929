import React, { useState } from "react";
import "./detail.scss";

import {
  CloudDownloadOutlined,
  FontColorsOutlined,
  LayoutOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Card, ConfigProvider, MenuProps, Space, Tooltip } from "antd";
import { Dropdown } from "antd/lib";
interface ThemeProps {
  theme: string;
  onChangeTheme?: (value: string) => void;
  toggel?: boolean;
  onToggel?: (value: boolean) => void;
}
const RightControl = ({
  theme,
  onChangeTheme,
  onToggel,
  toggel,
}: ThemeProps) => {
  const [disabled, setDisabled] = useState(true);
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <a href="javascript:void(0)">Theme 1</a>,
    },
    {
      key: "2",
      label: <a href="javascript:void(0)">Theme 2</a>,
    },
    {
      key: "3",
      label: <a href="javascript:void(0)">Theme 3</a>,
    },
    {
      key: "4",
      label: <a href="javascript:void(0)">Theme 4</a>,
    },
  ];
  const handelToggle = () => {
    onToggel ? onToggel(!toggel) : null;
  };
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    onChangeTheme ? onChangeTheme(e.key) : null;
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="survey-managment-respond-right-control">
      <ConfigProvider
        theme={{
          components: {
            Tooltip: {
              colorTextLightSolid: "black",
              colorBgSpotlight: "white",
            },
          },
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            // flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          {/* <Dropdown menu={menuProps}  > */}
          <div
            style={{
              marginRight: "-120px",
              position: "absolute",
              top: "50px",
              zIndex: 10,
            }}
          >
            <Tooltip placement="top">
              <Space size={"small"}>
                <Button
                  onClick={handelToggle}
                  icon={<SettingOutlined />}
                ></Button>
                <span>Thiết lập</span>
              </Space>
            </Tooltip>
          </div>

          {/* </Dropdown> */}
        </div>
      </ConfigProvider>
    </div>
  );
};

export default RightControl;
