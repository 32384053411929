import { CheckCircleFilled, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Menu, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import "./ModuleList.scss";
import { useNavigate } from "react-router-dom";

const { SubMenu, Item } = Menu;
const ModuleList: React.FC<Props> = ({
  modules,
  blockId,
  selectLesson,
  selectSequence,
  defaultKeys,
}) => {
  const navigate = useNavigate()
  const [isToggle, setIsToggle] = useState<Boolean>(true);
  const [unitId, setUnitId] = useState<number>(0);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const defaultSelectedKeys = defaultKeys?.selectedKeys;
  const defaultOpenKeys = defaultKeys?.openKeys;

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsToggle(window.innerWidth > 1100);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const setSelect = (sequence: any, lessonId: Lesson) => {
    selectSequence(sequence);
    selectLesson(lessonId);
  }

  return (
    <div className={`module-list ${isToggle ? "" : "module-list-hide"}`}>
      <div className="module-list-hide-title">
        {/* <img
          className="view-list-img"
          src={"/images/view-list.svg"}
          alt="view-list-img"
          onClick={handleToggle}
        /> */}
        {/* {isToggle && <span>Ẩn cửa sổ menu</span>} */}
        <Button
          className={`btn-action-menu ${isToggle ? "btn-toggle-menu" : "btn-hide"}`}
          icon={
            <UnorderedListOutlined className="font-weight-6 font-size-16" />
          }
          onClick={handleToggle}
        >
          {isToggle ? " Ẩn cửa sổ menu" : ""}
        </Button>
      </div>
      {isToggle && (
        <Menu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys.map((it: any) =>
            it?.toString()
          )}
          defaultOpenKeys={defaultOpenKeys.map((it: number) => it?.toString())}
        >
          {modules?.length === 0 ? (
            <SubMenu title="Empty SubMenu"></SubMenu>
          ) : (
            modules?.map((section: any) => {
              return (
                <SubMenu
                  key={section.id}
                  title={
                    <Tooltip overlay={section?.name} mouseEnterDelay={0.5}>
                      {section?.name}
                    </Tooltip>
                  }
                >
                  {section?.sequences?.length === 0 ? (
                    <Item disabled>Chưa có bài giàng cho chương học này</Item>
                  ) : (
                    section?.sequences?.map((squence: any) => {
                      return (
                        <SubMenu
                          key={squence.id}
                          title={
                            <Tooltip
                              overlay={squence?.name}
                              mouseEnterDelay={0.5}
                            >
                              {squence?.name}
                            </Tooltip>
                          }
                        >
                          {squence?.units?.length === 0 ? (
                            <Item disabled>
                              Chưa có học liệu cho bài giảng này
                            </Item>
                          ) : (
                            squence.units?.map((it: any) => {
                              return (
                                <Item
                                  key={it.id}
                                  onClick={() => setSelect(squence, it)}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="module-list-item">
                                      {it.watched ? (
                                        <CheckCircleFilled
                                          style={{
                                            fontSize: "24px",
                                            color: "#52C41A",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          className="book-open-img"
                                          src={"/images/book-open.svg"}
                                          alt="book-open-img"
                                        />
                                      )}
                                      <Tooltip
                                        overlay={it?.name}
                                        mouseEnterDelay={0.5}
                                      >
                                        {it?.name}
                                      </Tooltip>
                                    </div>
                                    {/* <div>05:05</div> */}
                                  </div>
                                </Item>
                              );
                            })
                          )}
                        </SubMenu>
                      );
                    })
                  )}
                  <hr className="hr-center" />
                </SubMenu>
              );
            })
          )}
        </Menu>
      )}
    </div>
  );
};

export default ModuleList;

interface Lesson {
  id: number;
  title: string;
  watched: boolean;
  videoUrl: string;
  moduleId: string;
  moduleTitle: string;
}

// interface Module {
//   id: number;
//   title: string;
//   lessons: Lesson[];
//   name: string;
//   children: any;
//   length: any;
// }

interface Props {
  modules: any;
  blockId?: number;
  selectLesson: (lesson: Lesson) => void;
  selectSequence: (sequence: any) => void;
  defaultKeys: any;
}
