import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  GetProp,
  Modal,
  Typography,
  message
} from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonModal from "../../../../components/modal/common";
import { DEFAULT_ROLE_AUTO_MARK } from "../../../../constants/automatic-mark";
import {
  DEFAULT_GROUP
} from "../../../../constants/setting-identity";
import {
  getPermissionAutoScore,
  updatePermissionAutoScore,
} from "../../../../service/auto-score";
import {
  getSettingAuthority
} from "../../../../service/setting-authority";

const { Title, Text } = Typography;

const AutoMarkPermissionContent = () => {
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [dataRole, setDataRole] = useState<any>([]);
  const [optionRole, setOptionRole] = useState<any>([]);

  const [formModal, setFormModal] = useState({
    title: "",
    type: "",
    id: "",
    defaultValue: [] as any,
  });
  const [messageApi, contextHolder] = message.useMessage();

  const fetchPermissionData = async () => {
    try {
      const res = await getPermissionAutoScore(params?.id || "");
      const { data } = res.data;
      setDataRole(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPermissionData();
  }, []);
  const CommonPopupRole = () => {
    const onChange: GetProp<typeof Checkbox.Group, "onChange"> = (
      checkedValues
    ) => {
      setFormModal({
        ...formModal,
        defaultValue: checkedValues,
      });
    };

    return (
      <div>
        <Checkbox.Group
          options={optionRole}
          onChange={onChange}
          defaultValue={formModal.defaultValue}
          disabled={isView}
          className="grid grid-cols-1 gap-2"
        />
      </div>
    );
  };
  const onCloseModal = () => {
    setIsOpen(false);
    setIsView(false);
  };
  const handleEdit = (role: any) => {
    const convertDefaultPermission =
      role?.mooc_setting_score_report_permission?.map(
        (value: any) => value?.auth_permission?.id
      );
    setIsOpen(true);
    setFormModal({
      title: DEFAULT_ROLE_AUTO_MARK[role?.type],
      type: role?.type,
      id: role?.id,
      defaultValue: convertDefaultPermission,
    });
  };
  const handleView = (role: any) => {
    const convertDefaultPermission =
      role?.mooc_setting_score_report_permission?.map(
        (value: any) => value?.auth_permission?.id
      );
    setIsOpen(true);
    setIsView(true);
    setFormModal({
      title: DEFAULT_ROLE_AUTO_MARK[role?.type],
      type: role?.type,
      id: role?.id,
      defaultValue: convertDefaultPermission,
    });
  };
  const fetchSettingAuthority = async () => {
    try {
      const response = await getSettingAuthority();
      const { data } = response.data;
      setOptionRole(
        data?.map((option: any) => ({
          label: DEFAULT_GROUP[option?.codename],
          value: option.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingAuthority();
  }, []);

  const handleSaveRole = async (role: any) => {
    const paramRole = {
      permission_ids: formModal.defaultValue,
      course_block_id: Number(params?.id),
      type: role?.type,
    };

    if (isEmpty(paramRole?.permission_ids)) {
      messageApi.open({
        type: "error",
        content: `phải chọn ít nhất 1 đối tượng ở ${role?.title.toLowerCase()}`,
      });
    } else {
      try {
        await updatePermissionAutoScore(role?.id, paramRole);
        setIsOpen(false);
        messageApi.open({
          type: "success",
          content: `Lưu thay đổi ${role?.title.toLowerCase()} thành công`,
        });
        fetchPermissionData();
      } catch (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: `Lưu thay đổi ${role?.title.toLowerCase()} thất bại`,
        });
      }
    }
  };
  return (
    <div className="w-full lg:w-2/3 xl:w-1/2 m-auto my-12">
      {contextHolder}
      {dataRole?.map((role: any, index: number) => (
        <Card className="mt-4" key={index}>
          <Flex justify="space-between">
            <div>
              <Title level={4} className="!leading-3">
                {DEFAULT_ROLE_AUTO_MARK[role?.type]}
              </Title>
              <Text className="text-[#00000073]">
                Chọn đối tượng áp dụng cho phân quyền này
              </Text>
            </div>
            <Flex align="center">
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => handleEdit(role)}
              >
                <EditOutlined />
              </Button>
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => handleView(role)}
              >
                <EyeOutlined />
              </Button>
            </Flex>
          </Flex>
        </Card>
      ))}
      {!isView ? (
        <CommonModal
          open={isOpen}
          title={formModal.title}
          desc={<CommonPopupRole />}
          closeIcon={true}
          okText={"Lưu"}
          cancelText={"Huỷ"}
          icon={""}
          confirm={() => handleSaveRole(formModal)}
          closeModal={onCloseModal}
          className={"modal-rule"}
        />
      ) : (
        <Modal
          open={isOpen}
          className={"modal-rule"}
          title={formModal.title}
          closeIcon={true}
          centered
          onCancel={onCloseModal}
          footer=""
          okButtonProps={{ disabled: true }}
        >
          <CommonPopupRole />
        </Modal>
      )}
    </div>
  );
};

export default AutoMarkPermissionContent;
