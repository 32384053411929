// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-course .card-heading--info h5, .detail-course .card-heading--info .sub-title {
  margin-bottom: unset !important; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/assign-course-construction/plan-information/index.scss"],"names":[],"mappings":"AAAA;EAGM,+BAA+B,EAAA","sourcesContent":[".detail-course {\n  .card-heading--info {\n    h5, .sub-title {\n      margin-bottom: unset !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
