import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Space,
  UploadProps,
} from "antd";
import React, { useEffect, useState } from "react";

import { FileType, getBase64 } from "../../../utils/uploadImage";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import FileUploader from "../../../components/file-uploader/file-uploader.component";
import { toNumber } from "lodash";

const { TextArea } = Input;

interface TransactionUpdateForm {
  onSubmit?: (value?: any) => void;
}
function UpdateForm({ onSubmit = () => {} }: TransactionUpdateForm) {
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [form] = Form.useForm();

  const receivedAmountWatch = Form.useWatch("receivedAmount", form);
  const refundAmountWatch = Form.useWatch("refundAmount", form);
  const additionalAmountWatch = Form.useWatch("additionalAmount", form);

  useEffect(() => {
    const total =
      toNumber(receivedAmountWatch) +
      toNumber(additionalAmountWatch) -
      toNumber(refundAmountWatch);
    if (!isNaN(total)) form.setFieldValue("totalPrice", total);
  }, [receivedAmountWatch, additionalAmountWatch, refundAmountWatch]);

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoadingUpload(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoadingUpload(false);
        setImageUrl(url);
        console.log(info);
      });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loadingUpload ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const beforeUpload = (file: FileType) => {
    setLoadingUpload(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error("Ảnh yêu cầu định dạng jpeg/png!");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300);
    }
    if (!isLt2M) {
      message.error("Ảnh phải có dung lượng nhỏ hơn 2M!");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300);
    }

    return isJpgOrPng && isLt2M;
  };

  const handleUpdate = (fieldsValue: any) => {
    onSubmit(fieldsValue);
  };

  const validateNumber = (_: any, value: any) => {
    if (value === undefined || value === null || value === "") {
      return Promise.resolve();
    }
    if (isNaN(value)) {
      return Promise.reject(new Error("Vui lòng nhập số tiền hợp lệ!"));
    }
    return Promise.resolve();
  };

  return (
    <div className="transaction-form-update">
      <p className="form-title text-16 font-weight-5 mb-1">
        Cập nhật điều chỉnh
      </p>
      <Form form={form} onFinish={handleUpdate}>
        <Form.Item
          name={"status"}
          rules={[
            {
              required: true,
              message: "Vui lòng chọn trạng thái giao dịch",
            },
          ]}
        >
          <Radio.Group>
            <Space direction="vertical">
              <Radio value={true}> Giao dịch thành công </Radio>
              <Radio value={false}>Giao dịch không thành công </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="mt-1 mb-3"
          name={"reason"}
          rules={[
            {
              required: true,
              message: "Vui lòng nhập lý do",
            },
          ]}
        >
          <TextArea
            placeholder="Nhập lý do"
            maxLength={1000}
            showCount
            rows={3}
          />
        </Form.Item>
        <div
          className="break-line"
          style={{ marginTop: 24, marginBottom: 16 }}
        ></div>

        <Row gutter={[16, 16]}>
          <Col md={6}>
            <Flex align="center" gap={2} justify="flex-end">
              <span className="text-danger">*</span>{" "}
              <div className="text-14 font-weight-4">Hoá đơn:</div>
            </Flex>
          </Col>
          <Col md={18}>
            <Flex gap={8}>
              <Form.Item
                name="billImage"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng tải lên ảnh chụp hoá đơn/ sao kê",
                  },
                ]}
              >
                <FileUploader
                  listType="picture-card"
                  multiple
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </FileUploader>
              </Form.Item>

              <span className="text-info text-14">
                Tải lên ảnh chụp hoá đơn/ sao kê cập nhật điều chỉnh
              </span>
            </Flex>
          </Col>
        </Row>

        <Row gutter={[16, 16]} align={"middle"} className="mt-2">
          <Col md={6}>
            <Flex align="center" gap={2} justify="flex-end">
              <span className="text-danger">*</span>{" "}
              <div className="text-14 font-weight-4">Số tiền đã nhận:</div>
            </Flex>
          </Col>
          <Col md={18}>
            <Flex gap={8}>
              <Form.Item
                name="receivedAmount"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số tiền đã nhận",
                  },
                  {
                    validator: validateNumber,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    value
                      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : ""
                  }
                  parser={(value: any) => value.replace(/\./g, "")}
                  min={0}
                  addonAfter={<span>VND</span>}
                  className="custom-input-number"
                />
              </Form.Item>
            </Flex>
          </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" className="mt-2">
          <Col md={6}>
            <Flex align="center" gap={2} justify="flex-end">
              <span className="text-danger">*</span>{" "}
              <div className="text-14 font-weight-4">Số tiền bổ sung:</div>
            </Flex>
          </Col>
          <Col md={18}>
            <Flex gap={8}>
              <Form.Item
                name="additionalAmount"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số tiền bổ sung",
                  },
                  {
                    validator: validateNumber,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    value
                      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : ""
                  }
                  parser={(value: any) => value.replace(/\./g, "")}
                  min={0}
                  addonAfter={<span>VND</span>}
                  className="custom-input-number"
                />
              </Form.Item>
            </Flex>
          </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" className="mt-2">
          <Col md={6}>
            <Flex align="center" gap={2} justify="flex-end">
              <span className="text-danger">*</span>{" "}
              <div className="text-14 font-weight-4">Số tiền hoàn trả:</div>
            </Flex>
          </Col>
          <Col md={18}>
            <Flex gap={8}>
              <Form.Item
                name="refundAmount"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập số tiền hoàn trả",
                  },
                  {
                    validator: validateNumber,
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    value
                      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : ""
                  }
                  parser={(value: any) => value.replace(/\./g, "")}
                  min={0}
                  addonAfter={<span>VND</span>}
                  className="custom-input-number"
                />
              </Form.Item>
            </Flex>
          </Col>
        </Row>

        <Row gutter={[16, 16]} align="middle" className="mt-2 w-full-100">
          <Col md={6}>
            <Flex align="center" gap={2} justify="flex-end">
              <div className="text-14 font-weight-4">Tổng đã nhận:</div>
            </Flex>
          </Col>
          <Col md={18}>
            <Flex gap={8}>
              <Form.Item name="totalPrice">
                <InputNumber
                  disabled
                  style={{ width: "100%" }}
                  formatter={(value) =>
                    value
                      ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                      : ""
                  }
                  parser={(value: any) => value.replace(/\./g, "")}
                  min={0}
                  addonAfter={<span>VND</span>}
                  className="custom-input-number"
                />
              </Form.Item>
            </Flex>
          </Col>
        </Row>

        <Flex justify="flex-end" style={{ marginTop: 30 }}>
          <Button htmlType="submit" className="btn-primary">
            Gửi cập nhật
          </Button>
        </Flex>
      </Form>
    </div>
  );
}

export default UpdateForm;
