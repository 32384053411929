import "./detail.scss";
import { Input, Row, Space } from "antd";
import { useState } from "react";
import { ListAnswer } from "../../../../../../../types/survey-managment/survey";

const QuestionType8 = (props: any) => {
  const [answerText, setAnswerText] = useState<string>("");
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  // console.log({ props })
  const handleFocus = () => {
    setShowPlaceholder(false);
  };

  const handleBlur = () => {
    setShowPlaceholder(true);
  };
  const handleChangeValue = (e: any) => {
    // console.log(e.target.value);
    setAnswerText(e.target.value);
  };

  // console.log(`props.listOptions::`, props.listOptions);

  if (props.listOptions && props.listOptions.length === 0) {
    const addedOption = {
      answerText: `Nhập câu trả lời của bạn`,
      answerOrderNumber: 1,
    };
    props.setUpdateListAnswer([addedOption]);
  }

  return (
    <div className="survey-managment-construction">
      <div>
        <br />
        <Space direction="vertical" size="small" style={{ display: "flex" }}>
          <Row
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Input
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
              }}
              value={answerText}
              placeholder="Nhập câu trả lời"
              onChange={(e) => handleChangeValue(e)}
            />
          </Row>
        </Space>
        <br />
      </div>
    </div>
  );
};

export default QuestionType8;
