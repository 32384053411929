// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1px 8px;
  line-height: 22px; }

.tag-green {
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  color: #52c41a; }

.tag-gray {
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  color: #000; }

.tag-blue {
  background-color: #f0f5ff;
  border: 1px solid #adc6ff;
  color: #2F54EB; }

.span {
  position: relative;
  line-height: 22px; }

.tags-wrapper {
  position: relative;
  width: 100%;
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #52c41a; }

.filter-class--register {
  padding: 0 15px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/class-register/index.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,iBAAiB,EAAA;;AAGnB;EACE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc,EAAA;;AAIhB;EACE,yBAAyB;EACzB,yBAAyB;EACzB,WAAW,EAAA;;AAGb;EACE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc,EAAA;;AAGhB;EACE,kBAAkB;EAClB,iBAAiB,EAAA;;AAGnB;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,eAAe;EACf,cAAc,EAAA;;AAGhB;EACE,eAAe,EAAA","sourcesContent":[".tag {\n  align-self: stretch;\n  border-radius: 4px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  padding: 1px 8px;\n  line-height: 22px;\n}\n\n.tag-green {\n  background-color: #f6ffed;\n  border: 1px solid #b7eb8f;\n  color: #52c41a;\n\n}\n\n.tag-gray {\n  background-color: #fafafa;\n  border: 1px solid #d9d9d9;\n  color: #000;\n}\n\n.tag-blue {\n  background-color: #f0f5ff;\n  border: 1px solid #adc6ff;\n  color: #2F54EB;\n}\n\n.span {\n  position: relative;\n  line-height: 22px;\n}\n\n.tags-wrapper {\n  position: relative;\n  width: 100%;\n  height: 22px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  text-align: left;\n  font-size: 14px;\n  color: #52c41a;\n}\n\n.filter-class--register {\n  padding: 0 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
