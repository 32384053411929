// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-info {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px; }
  .avatar-info span {
    margin-bottom: 16px;
    text-align: center; }
  .avatar-info .avatar-title {
    display: flex;
    align-items: center; }
    .avatar-info .avatar-title h5 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0;
      margin-right: 12px; }
  .avatar-info .avatar-uploader .ant-upload-select {
    width: 168px !important;
    height: 168px !important; }
  .avatar-info .avatar-uploader .ant-upload {
    position: relative; }
    .avatar-info .avatar-uploader .ant-upload .btn-upload {
      position: absolute;
      background: #ffffff;
      border: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      padding: 10px;
      bottom: 4px;
      right: 15px; }
      .avatar-info .avatar-uploader .ant-upload .btn-upload span {
        margin-bottom: 0; }
    .avatar-info .avatar-uploader .ant-upload img {
      border-radius: 50%; }
`, "",{"version":3,"sources":["webpack://./src/pages/detail/components/user-document/user-document.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB,EAAA;EAPrB;IAUI,mBAAmB;IACnB,kBAAkB,EAAA;EAXtB;IAeI,aAAa;IACb,mBAAmB,EAAA;IAhBvB;MAmBM,eAAe;MACf,iBAAiB;MACjB,gBAAgB;MAChB,kBAAkB,EAAA;EAtBxB;IA6BM,uBAAuB;IACvB,wBAAwB,EAAA;EA9B9B;IAkCM,kBAAkB,EAAA;IAlCxB;MAqCQ,kBAAkB;MAClB,mBAAmB;MACnB,SAAS;MACT,kBAAkB;MAClB,aAAa;MACb,mBAAmB;MACnB,aAAa;MACb,WAAW;MACX,WAAW,EAAA;MA7CnB;QAgDU,gBAAgB,EAAA;IAhD1B;MAqDQ,kBAAkB,EAAA","sourcesContent":[".avatar-info {\n  width: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin-bottom: 24px;\n\n  span {\n    margin-bottom: 16px;\n    text-align: center;\n  }\n\n  .avatar-title {\n    display: flex;\n    align-items: center;\n\n    h5 {\n      font-size: 24px;\n      line-height: 32px;\n      margin-bottom: 0;\n      margin-right: 12px;\n    }\n  }\n\n  .avatar-uploader {\n    \n    .ant-upload-select {\n      width: 168px !important;\n      height: 168px !important;\n    }\n\n    .ant-upload {\n      position: relative;\n\n      .btn-upload {\n        position: absolute;\n        background: #ffffff;\n        border: 0;\n        border-radius: 50%;\n        display: flex;\n        align-items: center;\n        padding: 10px;\n        bottom: 4px;\n        right: 15px;\n\n        span {\n          margin-bottom: 0;\n        }\n      }\n\n      img {\n        border-radius: 50%;\n      }\n    }\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
