import React, { MouseEvent, useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  message,
  Modal,
  Radio,
  Typography,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { DraggerProps } from "antd/lib/upload";
import TextArea from "antd/es/input/TextArea";
import { getDownloadCsvManagementData, getDownloadExcelManagementData, getDownloadPdfManagementData, saveHistoryManagementData } from "../../../service/management-data";
import moment from "moment";
import { viLocale } from "../../../utils/pickerType";

type ModalUploadProps = {
  getListData: () => void;
  title?: string;
  okText?: string;
  cancelText?: string;
  open?: boolean;
  onOk?: () => void;
  onCancel: () => void;
  dataUpdate: any;
};

const DownloadHistoryManagementDataModal = ({
  title,
  open,
  onOk,
  onCancel,
  cancelText,
  dataUpdate,
  okText,
  getListData,
}: ModalUploadProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldValue("downloadOption", 1)
  }, [dataUpdate])

  const onSave = async (values: any) => {
    let response: any = null
    if (values.downloadOption === 1) {
      response = await getDownloadExcelManagementData(dataUpdate?.id)
    } else if (values.downloadOption === 2) {
      response = await getDownloadCsvManagementData(dataUpdate?.id)
    } else {
      response = await getDownloadPdfManagementData(dataUpdate?.id)
    }
    const fileData = response?.data;

    const blob = new Blob([fileData],
      values.downloadOption === 1 ?
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        } : (
          values.downloadOption === 2 ? { type: 'text/csv;charset=utf-8' } : {}
        )
    );

    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);

    downloadLink.download = `${dataUpdate.name}.${values.downloadOption === 1 ? 'xlsx' : (values.downloadOption === 2 ? 'csv' : 'pdf')}`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "form--download-history-management-data") {
            onSave(values);
          }
        }}
      >
        <Modal
          centered
          title={"Kết xuất lịch sử kết nối, chia sẻ hồ sơ học tập"}
          open={open}
          onOk={onSave}
          onCancel={onCancel}
          cancelText={cancelText}
          okText={okText}
          footer={[
            <Button onClick={onCancel}>Hủy</Button>,
            <Button type="primary" onClick={() => form.submit()}>
              Kết xuất
            </Button>,
          ]}
        >
          <Form
            layout={"vertical"}
            form={form}
            name="form--download-history-management-data"
          >
            <div className="history-management-data-modal">
              <Form.Item name="downloadOption">
                <Radio.Group defaultValue={1}
                >
                  <div className="wrapper-radio flex flex-col">
                    <Radio value={1}>Excel</Radio>
                    <Radio value={2} className="mt-1">CSV</Radio>
                    <Radio value={3} className="mt-1">PDF</Radio>
                  </div>
                </Radio.Group>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </Form.Provider>
    </>
  );
};

export default DownloadHistoryManagementDataModal;
