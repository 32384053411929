import {AxiosResponse} from "axios";
import {axiosConfig} from "../../config/api/configApi";
import {IBodyRequestDataPost} from "../../types/roleManagement";
import { Key } from "react";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getDetailQuestionBank: (id: any) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/question-bank/get-detail-unit-bank/${id}`)
}

export const getDetailQuestionBankMinistry: (id: any) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/question-bank/censor/${id}`)
}

export const putApproveQuestionBank: (param: any) => Promise<AxiosResponse<any>> = (param) => {
  return axiosConfig.put(`question-bank/censor/approve`,{param})
}


//

// export const deleteRole: (id: string) => Promise<AxiosResponse<any>> = (id) => {
//   return axiosConfig.delete(`/roles/delete/${id}`)
// }

// export const updatePermissionsRoles: (id?: string, data?: {
//   isAdd: boolean,
//   quyens: Key[]
// }) => Promise<AxiosResponse<any>> = (id, data) => {
//   return axiosConfig.put(`/roles/update-role-permission/${id}`, data)
// }
