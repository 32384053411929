import { Button, Spin } from "antd";
import FormInput from "../../../../components/form-input-login/FormInput";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { FormEvent, useEffect, useState } from "react";
import { getMailOtp } from "../login-page/services";
import { getOtpForgotPassword } from "../../../../service/OtpForgotPassWord/OtpForgotPassWord";
import { isNumber, toNumber } from "lodash";

export const VerifyOTP = (
  verifyMailStep: boolean,
  loadingLogin: boolean,
  setVerifyMailStep: React.Dispatch<React.SetStateAction<boolean>>,
  handleAddClassHide: (id: string) => void,
  handleAddClassShow: (id: string) => void,
  setLoadingLogin: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCountTimeLeft: React.Dispatch<React.SetStateAction<boolean>>,
  verifyMail: string,
  OTPCode: string,
  setOTPCode: React.Dispatch<React.SetStateAction<string>>,
  timeLeft: number,
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>
) => {
  const [countOfLoginFailed, setCountOfLoginFailed] = useState<number>(5);

  useEffect(() => {
    if (countOfLoginFailed === 0) {
      setTimeout(() => {
        setIsCountTimeLeft(false);
        setVerifyMailStep(false);
        handleAddClassShow("login-card");
        handleAddClassHide("verify-otp");
        setLoadingLogin(false);
      }, 5000);
    }
  }, [countOfLoginFailed]);

  const handleVerifyEmail = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!OTPCode) {
      event.preventDefault();
      return;
    }
    setLoadingLogin(true);
    await getOtpForgotPassword(verifyMail, OTPCode)
      .then((result) => {
        setTimeout(() => {
          setIsCountTimeLeft(false);
          setVerifyMailStep(false);
          handleAddClassHide("verify-otp");
          handleAddClassShow("update-password");
          setLoadingLogin(false);
        }, 500);
      })
      .catch((err) => {
        setLoadingLogin(false);
        const message = err?.response?.data?.message || "";
        const countOfFailed = toNumber(message.charAt(message.length - 1));
        if (isNumber(countOfFailed) && countOfFailed >= 0)
          setCountOfLoginFailed(countOfFailed);
      });
  };

  const getMailOTP = async () => {
    try {
      const response = await getMailOtp(verifyMail);
      if (!verifyMailStep) {
        setTimeout(() => {
          handleAddClassHide("forgot-password");
          handleAddClassShow("verify-otp");
          setVerifyMailStep(true);
          setLoadingLogin(false);
          setIsCountTimeLeft(true);
          setTimeLeft(60);
        }, 500);
      }
    } catch (error) {
      setLoadingLogin(false);
    }
  };

  return (
    <div
      id="verify-otp"
      className={`login-card__item ${verifyMailStep ? "active" : ""}`}
    >
      <Spin tip="Loading" size="large" spinning={loadingLogin}>
        <div className="header-container">
          <div
            className="back-button"
            onClick={() => {
              setVerifyMailStep(false);
              handleAddClassHide("verify-otp");
              handleAddClassShow("forgot-password");
            }}
          >
            <ArrowLeftOutlined style={{ marginRight: "8px" }} />
            Quay lại
          </div>
          <h2 className="ng-nhp1">Nhập mã xác thực</h2>
        </div>
        <form
          className="form-and-button"
          id="verify-mail-otp"
          onSubmit={handleVerifyEmail}
        >
          <div className="otp-message">
            Mã OTP đã được gửi đến email:{" "}
            <span className="otp-email">{verifyMail}</span>
          </div>
          <div className="inputs">
            <FormInput
              required={true}
              title="Mã OTP"
              inputPrefix="/inputprefix@2x.png"
              placeholder="Nhập mã OTP"
              errorInvalid="Mã OTP không chính xác."
              inputSuffix="/inputsuffix@2x.png"
              inputSuffixIcon={false}
              propMinWidth="7.56rem"
              value={OTPCode}
              onChange={setOTPCode}
            />
          </div>
          <Button
            htmlType="submit"
            className={`button11 ${OTPCode ? "" : "disable-button"}`}
            type="primary"
            loading={loadingLogin}
          >
            Xác nhận
          </Button>
          <div
            onClick={() => {
              if (timeLeft > 0) {
                return;
              }
              if (!timeLeft) {
                setTimeLeft(60);
                setIsCountTimeLeft(true);
                getMailOTP();
              }
            }}
            className={timeLeft ? `resend-mail` : `resend-mail cursor`}
          >
            Không nhận được mã ? Gửi lại {timeLeft ? `(${timeLeft}s)` : ""}
          </div>
        </form>
      </Spin>
    </div>
  );
};
