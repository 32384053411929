import React from "react";
import { QuestionProps } from "../../../../../types/course";
import { Form, Radio } from "antd";

function ChooseText({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <Form.Item
      name="ChooseText"
      initialValue={"apple"}
      className={`${isCorrect ? "choose-correct" : ""} ${isIncorrect ? "choose-incorrect" : ""}`}
    >
      <Radio.Group className="flex flex-column gap-12" disabled>
        <Radio value="apple">
          <span className="text-result font-size-16 line-height-24 font-weight-5">
            Figma ipsum component, Figma ipsum component
          </span>
        </Radio>
        <Radio value="pear">
          <span className="font-size-16 line-height-24 font-weight-5">
            Figma ipsum component, Figma ipsum component
          </span>
        </Radio>
        <Radio value="pear2">
          <span className="font-size-16 line-height-24 font-weight-5">
            Figma ipsum component, Figma ipsum component
          </span>
        </Radio>
        <Radio value="pear3">
          <span className="font-size-16 line-height-24 font-weight-5">
            Figma ipsum component, Figma ipsum component
          </span>
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default ChooseText;
