import React, { useEffect, useRef, useState } from "react";
import { Breadcrumb, Button, message, Modal, Spin } from "antd";
import {
  ArrowLeftOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  SaveOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import Overview from "./overview";
import Content, { ContentRef, ScoringFormValues } from "./content";
import {
  deleteDraft,
  markSubmit,
  resultOfTeacher,
  saveDraft,
} from "../../../service/scoring";
import { routesConfig } from "../../../config/routes";
import {
  GroupsExam,
  Draft,
  QuizProps,
  UserInfoProps,
} from "../../../types/scoring-course";
import "./style.scss";
import { addOrUpdateQuizMarkRequest } from "../../../utils/arrays";
import { isEmpty, isNumber, toNumber, toString } from "lodash";

function ScoringDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const sessionId = params?.id;
  const contentRef = useRef<ContentRef>(null);

  // breadcrumb info
  const courseId = location.state?.courseId;
  const courseName = location.state?.courseName;
  const unitName = location.state?.unitName;
  const blockId = location.state?.blockId;
  const studentName = location?.state?.studentName || "Không có tên";
  const isEdit = location?.state?.edit;

  // data get from api
  const [groups, setGroups] = useState<GroupsExam[]>([]);
  const [quizzes, setQuizzes] = useState<QuizProps[]>([]);
  const [currentQuiz, setCurrentQuiz] = useState<QuizProps>();
  const [userInfo, setUserInfo] = useState<UserInfoProps>();

  // control
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<"view" | "edit">("view");
  const [quizzesCompleted, setQuizzesCompleted] = useState<Draft[]>([]);
  const [commentOverview, setCommentOverview] = useState<string>("");
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const isDisableAll = mode === "view";
  const isDisableSendBtn = quizzesCompleted?.length < quizzes?.length;
  const isShowBtnRemoveDaft = quizzes?.some(
    (item: QuizProps) => item?.result?.draftInfo || item?.result?.isDraft
  );

  const points =
    quizzesCompleted?.reduce((total: number, item: Draft) => {
      return total + item.points;
    }, 0) || 0;

  const totalPoints =
    quizzes?.reduce((total: number, item: QuizProps) => {
      return total + item.totalPoints;
    }, 0) || 0;

  useEffect(() => {
    if (isEdit) setMode("edit");
  }, [isEdit]);

  useEffect(() => {
    const getResults = async () => {
      if (sessionId) {
        try {
          setLoading(true);
          const res = await resultOfTeacher(sessionId);
          setUserInfo(res.data?.data?.userInfo);
          const groupsExam: GroupsExam[] = res.data?.data?.groups;
          if (groupsExam) {
            setGroups(groupsExam);
            let quizzesFLat: QuizProps[] = [];
            for (let i = 0; i < groupsExam?.length; i++) {
              setQuizzes((prevState: QuizProps[]) => [
                ...prevState,
                ...groupsExam[i]?.quiz,
              ]);
              quizzesFLat = [...quizzesFLat, ...groupsExam[i]?.quiz];
            }

            const quizzesFLatCompleted = quizzesFLat.filter(
              (item: QuizProps) =>
                (!isEmpty(item.result?.draftInfo) ||
                  !isEmpty(item.result?.markedTeacherId) ||
                  !isEmpty(item.result?.results)) &&
                item?.result?.isTrue !== null
            );
            setQuizzesCompleted(
              quizzesFLatCompleted?.map((item: QuizProps) => ({
                quizId: item.id,
                comment: item.result?.draftInfo?.comment || item.result?.comment || "",
                points: item.result?.draftInfo?.points || item.result?.points,
                isConfigPointForTeacher:
                  item.result?.markedTeacherId ||
                  !isEmpty(item.result?.draftInfo)
                    ? true
                    : false,
              }))
            );
          }
          setCommentOverview(res.data?.data?.result?.commentByTeacher);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      }
    };
    getResults();

    return () => setQuizzes([]);
  }, [reload]);
  const breadcrumb = [
    {
      title: (
        <Link to={routesConfig.scoringListCourse}>Danh sách khoá học</Link>
      ),
    },
    {
      title: (
        <Link
          to={`${routesConfig.scoringListCourse}/${courseId}`}
          state={{
            name: courseName,
          }}
        >
          {courseName}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`${routesConfig.scoringListCourse}/${courseId}/exercise?blockId=${blockId}`}
          state={{
            courseName: courseName,
            unitName: unitName,
          }}
        >
          {unitName}
        </Link>
      ),
    },
    {
      title: studentName,
    },
  ];

  const handleChangeValues = (values?: ScoringFormValues) => {
    if (
      isNumber(values?.points) &&
      isNumber(currentQuiz?.id) &&
      values?.isConfigPointForTeacher
    )
      setQuizzesCompleted((prevQuizzes: Draft[]) =>
        addOrUpdateQuizMarkRequest(prevQuizzes, {
          quizId: toNumber(currentQuiz?.id),
          comment: toString(values?.comment),
          points: toNumber(values?.points),
          isConfigPointForTeacher: values?.isConfigPointForTeacher,
        })
      );
  };

  const handleClickMenu = (quiz: any) => {
    setCurrentQuiz(quiz);
  };

  const handleSendResult = () => {
    Modal.confirm({
      type: "info",
      icon: <InfoCircleOutlined className="text-primary" />,
      centered: true,
      title: "Xác nhận gửi chấm điểm",
      content: (
        <>
          <p className="text-14">
            Đã chấm: {quizzesCompleted?.length || 0}/{quizzes?.length || 0}
          </p>
          <p className="text-14">
            Tổng điểm: {points}/{totalPoints}
          </p>
          <p className="text-orange text-14">
            Chấm điểm đã gửi sẽ không thể thu hồi. Vui lòng kiểm tra và cập nhập
            đầy đủ trước khi gửi
          </p>
        </>
      ),
      okText: "Gửi chấm điểm",
      cancelText: "Huỷ",
      onOk: async () => {
        if (sessionId) {
          try {
            setLoadingSubmit(true);
            await markSubmit({
              sessionId: sessionId as string,
              commentByTeacher: commentOverview,
              data: quizzesCompleted?.map((quiz: Draft) => ({
                quizId: quiz.quizId,
                points: quiz.points,
                comment: quiz.comment,
              })),
            });
            setReload(!reload);
            message.success("Gửi chấm điểm thành công");
            setMode("view");
          } catch (err) {
            console.error(err);
          } finally {
            setLoadingSubmit(false);
          }
        }
      },
    });
  };

  const handleDeleteDaft = () => {
    Modal.confirm({
      type: "warning",
      icon: <InfoCircleOutlined style={{ color: "#FFC53D" }} />,
      centered: true,
      title: "Xác nhận đặt lại kết quả chấm",
      content:
        "Toàn bộ thông tin đã xoá bao gồm điểm và nhận xét sẽ không thể khôi phục. Bạn có chắc chắn muốn Đặt lại kết quả chấm?",
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: async () => {
        if (sessionId) {
          await deleteDraft(sessionId);
          setReload(!reload);
          message.success("Đặt lại kết quả thành công");
          setQuizzesCompleted([]);
          contentRef?.current?.resetFields();
        }
      },
    });
  };

  const handleSaveDaft = async () => {
    if (!isEmpty(quizzesCompleted)) {
      Modal.confirm({
        type: "info",
        icon: <InfoCircleOutlined className="text-primary" />,
        centered: true,
        title: "Xác nhận lưu chấm điểm",
        content: (
          <>
            <p className="text-14">
              Đã chấm: {quizzesCompleted?.length || 0}/{quizzes?.length || 0}
            </p>
            <p className="text-14">
              Tổng điểm: {points}/{totalPoints}
            </p>
            <p className="text-orange text-14">
              Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập
              nhập đầy đủ trước khi lưu
            </p>
          </>
        ),
        okText: "Lưu chấm điểm",
        cancelText: "Tiếp tục chấm",
        onOk: async () => {
          if (sessionId) {
            try {
              setLoadingSubmit(true);
              await saveDraft({
                sessionId: sessionId as string,
                commentByTeacher: commentOverview,
                data: quizzesCompleted?.map((quiz: Draft) => ({
                  quizId: quiz.quizId,
                  points: quiz.points,
                  comment: quiz.comment,
                })),
              });
              setReload(!reload);
              message.success("Lưu chấm điểm thành công");
              setMode("view");
            } catch (err) {
              console.error(err);
            } finally {
              setLoadingSubmit(false);
            }
          }
        },
      });
    }
  };

  const renderButtonByMode = () => {
    switch (mode) {
      case "view":
        return (
          <Button
            className="btn-primary"
            icon={<SaveOutlined />}
            onClick={() => setMode("edit")}
          >
            Chấm điểm
          </Button>
        );
      case "edit":
        return (
          <Button
            className="btn-primary"
            icon={<SaveOutlined />}
            onClick={handleSaveDaft}
            loading={loadingSubmit}
          >
            Lưu chấm điểm
          </Button>
        );
      default:
        break;
    }
  };

  return (
    <div className="scoring-detail-page">
      <div className="scoring-detail-page-header">
        <Breadcrumb items={breadcrumb} className="mb-1" />
        <div className="flex justify-between align-center gap-8">
          <div className="flex gap-8 align-center flex-1">
            <ArrowLeftOutlined
              className="arrowleft-icon"
              onClick={() => navigate(-1)}
            />
            <h3 className="page-title">{studentName}</h3>
          </div>
          {isShowBtnRemoveDaft && (
            <Button danger icon={<CloseOutlined />} onClick={handleDeleteDaft}>
              Đặt lại kết quả chấm
            </Button>
          )}
          {renderButtonByMode()}
          <Button
            disabled={isDisableSendBtn}
            icon={<SendOutlined />}
            onClick={handleSendResult}
          >
            Gửi kết quả
          </Button>
        </div>
      </div>

      <Spin spinning={loading}>
        <div className="scoring-detail-content-page w-full">
          <Sidebar
            groups={groups}
            quizzes={quizzes}
            onClick={handleClickMenu}
          />
          <Content
            ref={contentRef}
            quizzesCompleted={quizzesCompleted}
            disabled={isDisableAll}
            currentQuiz={currentQuiz as QuizProps}
            onChange={handleChangeValues}
            commentOverview={commentOverview}
            onChangeOverviewComment={setCommentOverview}
          />
          <Overview
            groups={groups}
            userInfo={userInfo}
            quizzes={quizzes}
            quizzesCompleted={quizzesCompleted}
          />
        </div>
      </Spin>
    </div>
  );
}

export default ScoringDetail;
