import {FunctionComponent, useEffect, useState} from "react";
import "./index.scss";
import {Button, Dropdown, Form, Layout, MenuProps, message, Space, Spin, Tabs, TabsProps,} from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {routesConfig} from "../../../config/routes";
import {ConstructCourseDetailType, Section,} from "../../../types/course-construction";
import {browseSource, getCourseDetail, publishCourse,} from "../../../service/course-construct";
import {useAppStore} from "../../../stores/stores";
import {
  createSettingCourse,
  getAllStatusReviewCourse,
  getDataGeneral,
  updateImageTeacher,
} from "../../../service/content-course/contenCourseApi";
import {get, isNull} from "lodash";
import PageBuilder from "../../page-builder/PageBuilder";
import CourseDetailView
  from "../../../components/course-construction/list-course-construction/course-constructor/CourseConstructView";
import CourseConstruct from "../../../components/course-construction/list-course-construction/course-constructor";
import BrowseCourseModal from "../../../components/modal/browse-course";
import TabsSettingCommon from "../../../components/course-construction/content-course-construction/tabs-setting-common";
import {EllipsisOutlined} from "@ant-design/icons";
import StatisticCourse from "../../../components/course-construction/content-course-construction/statistic-course";
import SafeLink from "../../../components/link/SafeLink";
import {useDirty} from "../../../contexts/DirtyProvider";
import {usePreventRouteChange} from "../../../hooks/usePreventRouteChange";
import ClassRegister from "../../../components/course-construction/content-course-construction/class-register";
import {CheckRole, getPermissions} from "../../../utils/checkRole";
import {Permission} from "../../../layout/sidebar/permission.enum";
import BrowsingResults from "../../../components/course-construction/assign-course-construction/browsing-results";
import dayjs from "dayjs";

const {Content} = Layout;
const imgPath = "/images/";

const ContentCourseDetail: FunctionComponent = () => {
  const {setDirty, isDirty} = useDirty();
  const {confirmNavigation} = usePreventRouteChange();
  const location = useLocation();
  const [detailData, setDetailData] = useState<ConstructCourseDetailType>({});
  const [sections, setSections] = useState<Section[]>([]);
  const [listPermission, setListPermission] = useState([]);
  const {accountInfo, setAccountDetail} = useAppStore((state) => {
    return {
      accountInfo: state?.accountInfo,
      setAccountDetail: state?.setAccountDetail,
    };
  });
  const params = useParams();
  const navigate = useNavigate();
  const [isShowSubmitButton, setIsShowSubmitButton] = useState(true);
  const [dataConfig, setDataConfig] = useState(null);
  const [sourceTreeSection, setSourceTreeSection] = useState<any>([]);
  const [initialValues, setInitialValues] = useState({
    layoutName: "",
    assignersRequest: [],
    isHasCertification: false,
    isScheduleByTeacher: false,
    isCostFree: false,
    isDefault: false,
    courseFormatId: 0,
  });
  const [loading, setLoading] = useState(false);
  const [dataForm, setDataForm] = useState<any>({});
  const [form] = Form.useForm();
  const [isCertificate, setIsCertificate] = useState(false);
  const [displayRole, setDisplayRole] = useState(false);
  const [displayRoleSettingPage, setDisplayRoleSettingPage] = useState(false);
  const [displayConstructionPage, setDisplayConstructionPage] = useState(false);
  const [displayRegisterPage, setDisplayRegisterPage] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [certificateId, setCertificateId] = useState<number>(0);
  const [tab, setTab] = useState<string>('2');
  const [dataAssinger, setDataAssinger] = useState([]);
  const [browseData, setBrowseData] = useState<ConstructCourseDetailType>();
  const [isOpenBrowseCourseModal, setIsOpenBrowseCourseModal] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [listImages, setListImages] = useState<any>([]);
  const [isReadyNextTab, setIsReadyNextTab] = useState<boolean>(false);
  const [tabForm, setTabForm] = useState<string>("1");
  const [detailDataBrowsingResults, setDetailDataBrowsingResults] = useState<any>();
  const [modePage, setModePage] = useState<string>('');

  useEffect(() => {
    getDetailCourseData();
    getConfigCourseData();
    getRoles();
    getDetailDataBrowsingResults();
  }, []);

  useEffect(() => {
    const roleIntroductionPage = [
      Permission.SetWebCourseActivityUpdate,
      Permission.SetCourseTagsUpdate,
      Permission.EditCourseTagsUpdate,
      Permission.PreviewCoursePresentationInterfaceUpdate,
      Permission.SelectPresentationScreenUpdate,
      Permission.SaveCoursePresentationInterfaceUpdate,
      Permission.CreateDraftPresentationInterfaceUpdate,
      Permission.OperatePresentationPageUpdate,
    ]
    const roleSettingPage = [
      Permission.SetWebCourseActivityUpdate,
      Permission.SetCourseTagsUpdate,
      Permission.EditCourseTagsUpdate,
      Permission.PreviewCoursePresentationInterfaceUpdate,
      Permission.SelectPresentationScreenUpdate,
      Permission.SaveCoursePresentationInterfaceUpdate,
      Permission.CreateDraftPresentationInterfaceUpdate,
    ]

    const roleConstructionPage = [
      Permission.CreateLectureUpdate,
      Permission.CreateChapterUpdate,
      Permission.CreateLearningMaterialUpdate,
      Permission.CreateAssignmentTestExamUpdate,
      Permission.CreateAssignmentTestExamQuestionUpdate,
      Permission.CreateCourseSurveyPlanUpdate,
      Permission.CreateCourseSurveyQuestionUpdate,
    ]

    const roleRegisterPage = [
      Permission.OperatePresentationPageUpdate,
    ]

    const checkRole = CheckRole(roleIntroductionPage, listPermission)
    const checkRoleSettingPage = CheckRole(roleSettingPage, listPermission)
    const checkRoleConstructionPage = CheckRole(roleConstructionPage, listPermission)
    const checkRoleRegisterPage = CheckRole(roleRegisterPage, listPermission)

    if (checkRole.includes(true)) {
      setDisplayRole(true)
    }
    if (checkRoleSettingPage.includes(true)) {
      setDisplayRoleSettingPage(true)
    }
    if (checkRoleConstructionPage.includes(true)) {
      setDisplayConstructionPage(true)
    }
    if (checkRoleRegisterPage.includes(true)) {
      setDisplayRegisterPage(true)
    }
  }, [listPermission]);


  useEffect(() => {
    if (!isNull(dataConfig)) {
      fillDetailForm();
    }
  }, [dataConfig]);

  useEffect(() => {
    if (Object.keys(dataForm).length > 0) {
      submitData(dataForm, listImages);
    }
  }, [dataForm]);

  useEffect(() => {
    setModePage(location.state?.mode);
  }, [location]);

  useEffect(() => {
    if (modePage === 'share') {
      setTab('4');
      setTabForm('4');
    } else if (modePage === 'view') {
      setTab('1');
    }
    setIsReadyNextTab(true);
    if (modePage === 'create') {
    } else if (modePage === 'edit') {
      setEdit(true);
    } else if (modePage === 'view') {
    }
  }, [location.pathname, modePage]);

  const handleChangeSection = (data: Section[]) => {
    let newSections = [];
    newSections = [...sections];
    setSections([]);
    newSections = [...data];
    setSections(data);
  };
  const convertDataSection = (data: any) => {
    const arrSection = data.sections;
    const arrConvert = arrSection.map((item: any) => {
      const units = item.children?.map((itemChild: any) => {
        const unitsLastChildren = itemChild?.children?.map(
          (itemLastChild: any) => {
            return {
              id: itemLastChild.id,
              orderNumber: itemLastChild.orderNumber,
            };
          }
        );
        return {
          id: itemChild.id,
          orderNumber: itemChild.orderNumber,
          units: unitsLastChildren,
        };
      });

      return {
        id: item.id,
        orderNumber: item.orderNumber,
        sequences: units,
      };
    });
    setSourceTreeSection(arrConvert);
  };

  const getDetailCourseData = async () => {
    try {
      setLoading(true);
      const response = await getCourseDetail(params?.id);
      const {data} = response.data;
      if (response.status === 200) {
        setLoading(false)
      }
      setDetailData(data);
      convertDataSection(data);
      handleChangeSection(data?.sections);
    } catch (error) {
      console.error(error);
    }
  };

  const getConfigCourseData = async () => {
    setLoading(true);
    try {
      const response = await getDataGeneral(Number(params?.id))
      const {data, success} = response.data;
      if (success) {
        setLoading(false)
        setDataConfig(data);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const fillDetailForm = () => {
    form.setFieldsValue({
      url: !isNull(get(dataConfig, "url", "")) ? get(dataConfig, "url", "").slice(8) : "",
      layoutName: get(dataConfig, "layoutName"),
      enrollStatedDate: get(dataConfig, "enrollStatedDate"),
      startTime: get(dataConfig, "enrollStatedDate"),
      enrollClosedDate: get(dataConfig, "enrollClosedDate"),
      endTime: get(dataConfig, "enrollClosedDate"),
      isHasCertification: get(dataConfig, "isHasCertification"),
      scheduleCourse: get(dataConfig, "schedule"),
      courseFormatId: get(dataConfig, "courseFormatId"),
      preCourses: get(dataConfig, "preCourses"),
      preSkills: get(dataConfig, "preSkills"),
      assignersRequest: get(dataConfig, "assigner"),
    });

    // @ts-ignore
    setDataAssinger(...get(dataConfig, 'assigner', []).map((assigner: any) => assigner.assigned))
    if (get(dataConfig, "courseCertificationId")) {
      setCertificateId(get(dataConfig, "courseCertificationId", 0));
    }
  }
  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params))
    setListPermission(listPermissionConvert)
  }

  const onFinish = (name: string, {forms, values}: any) => {
    const valueDate = values.enrollClosedDate ? (
      !isNull(values.endTime) ? (
        dayjs(values.enrollClosedDate)
          .set('hour', dayjs(values.endTime).hour())
          .set('minute', dayjs(values.endTime).minute())
          .set('second', dayjs(values.endTime).second())
      ) : dayjs(values.enrollClosedDate)
    ) : null;

    setListImages({
      ...listImages,
      assignersRequest: values.assignerAvatar,
    });
    // @ts-ignore
    setDataForm({
      ...dataForm,
      courseId: Number(params.id),
      layoutName: values.layoutName,
      url: values.url,
      image: values.imageAvatar,
      isHasCertification: values.isHasCertification,
      preCourses: values.preCourses,
      preSkills: values.preSkills,
      enrollStatedDate: (dayjs(values.enrollStatedDate).set('hour', dayjs(values.startTime).hour())
        .set('minute', dayjs(values.startTime).minute())
        .set('second', dayjs(values.startTime).second())).toISOString(),
      enrollClosedDate: valueDate
      ,
    });
  };


  // @ts-ignore
  const submitData = async (dataReq: any, images: any) => {
    setLoading(true)
    try {
      if (
        images.assignersRequest &&
        Array.isArray(images.assignersRequest) &&
        images.assignersRequest.length > 0 && dataAssinger.length > 0
      ) {
        const dataImage = images.assignersRequest.map((item: any) => ({
          teacherId: item.id,
          imageTeacher: item.image ? item.image : null
        }))
        const dataImageReq = {
          courseId: Number(params?.id),
          courseTeacherUpdateRequest: dataImage
        }
        const res = await updateImageTeacher(dataImageReq)
        setDirty(false)
      }
      const response = await createSettingCourse(dataReq);
      setDirty(false)
      if (response.status === 200) {
        message.success("Cài đặt thông tin thành công");
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const resetForm = () => {
    if (isEdit) {
      fillDetailForm()

    } else {
      setInitialValues({
        ...initialValues,
        layoutName: "",
        assignersRequest: [],
        isHasCertification: false,
        isScheduleByTeacher: false,
        isCostFree: false,
        isDefault: false,
        courseFormatId: 1,
      })
    }
  }

  const handleSendToCheck = () => {
    if (detailData.status === "3") {
      message.error("Khoá học đang trong quá trình chờ duyệt");
    } else {
      setIsOpenBrowseCourseModal(true);
      setBrowseData(detailData);
    }
  };

  const handleUpdateStatusPublish = async () => {
    try {
      const data = {
        courseIds: [detailData?.id],
      };
      await publishCourse(data).then((result) => {
        message.success("Khoá học đã xuất bản thành công");
      });
    } catch (error) {
    }
  };

  const handlePublish = () => {
    if (detailData.status === "13") {
      message.error("Khoá học đã xuất bản");
    } else {
      handleUpdateStatusPublish();
    }
  };
  const saveBrowseCourse = async (approveUserId: number) => {
    const data = {
      approveUserId: approveUserId,
    };
    await browseSource(browseData?.id, data).then((res) => {
      if (res.status === 200) {
        message.success("Yêu cầu duyệt khoá học được gửi thành công");
        setIsOpenBrowseCourseModal(false);
        setClearForm(true);
      }
    });
  };

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const getDetailDataBrowsingResults = async () => {
    await getAllStatusReviewCourse(params?.id).then(res => {
      setDetailDataBrowsingResults(res.data.data)
    });
  }

  const tabItems: TabsProps["items"] = [
    ...(displayRole ? [{
      disabled: !isReadyNextTab,
      key: '1',
      label: 'Trang giới thiệu',
      children: (
        <PageBuilder
          planInfo={location.state}
          detailData={detailData}
          setIsReadyNextTab={(val) => {
            setIsReadyNextTab(val)
          }}
          tab={tab}
          mode={modePage}
        />
      ),
    },] : []),
    ...(displayConstructionPage ? [{
      disabled: !isReadyNextTab,
      key: '2',
      label: 'Cấu trúc khóa học',
      children:
      // change children here
        location.state?.mode !== 'view' ? (
          <div className='wrapper-course-construct'>
            <section className='detail-account'>
              <div className='form-detail'>
                <CourseConstruct
                  sections={sections}
                  detailData={detailData}
                  handleGetDetail={getDetailCourseData}
                  setSections={handleChangeSection}
                  paramId={params.id}
                />
              </div>
            </section>
          </div>
        ) : (
          <div className='detail-course-construction'>
            <CourseDetailView detailData={detailData}/>
          </div>
        ),
    },] : []),

    ...(displayRegisterPage && get(dataConfig, 'isScheduleByTeacher') ? [{
      key: '3',
      disabled: !isReadyNextTab,
      label: (<span>Lớp ghi danh</span>),
      destroyInactiveTabPane: true,
      children: (
        <ClassRegister paramId={params.id}/>
      ),
    }] : []),

    ...(displayRoleSettingPage ? [{
      disabled: !isReadyNextTab,
      key: '4',
      // icon: <LockOutlined />,
      label: 'Cài đặt',
      children: (
        <TabsSettingCommon
          form={form}
          initialValues={initialValues}
          isCertificate={isCertificate}
          isDirty={isDirty}
          tabForm={tabForm}
          setTabForm={setTabForm}
          setDirty={setDirty}
          certificateId={certificateId}
          setCertificateId={setCertificateId}
          setIsCertificate={setIsCertificate}
          resetForm={resetForm}
          onFinish={onFinish}
          detailData={dataConfig}
          courseStatus={location.state?.record?.status}
          courseDetail={location.state?.record}
        />
      ),
    }] : []),
    {
      disabled: !isReadyNextTab,
      key: '5',
      label: 'Kết quả duyệt',
      children: (
        <div className='wrapper-course-construct course-browse'>
          <section className='detail-account course-browse'>
            <div className='form-detail'>
              <BrowsingResults planInfo={location.state} detailData={detailDataBrowsingResults}/>
            </div>
          </section>
        </div>
      ),
    },
    {
      disabled: !isReadyNextTab,
      key: '6',
      label: 'Thảo luận',
      children: <StatisticCourse/>,
    },
  ];

  const listBreadcrumb = [
    {
      title: (<span>Xây dựng khóa học</span>),
    },
    {
      title: (
        <SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}`}>
          <span>Xây dựng nội dung</span>
        </SafeLink>
      ),
    },
    {
      title: (
        <span style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '1000px',
          display: 'inline-block'
        }}
        >{detailData?.name || ""}</span>
      ),
    },
  ];


  const handleChange = (value: string) => {
    if (isReadyNextTab) {
      confirmNavigation({link: ""}, false, () => {
        setTab(value);
        setIsShowSubmitButton(true);
      });
    }
  };

  const handleNavigateReview = () => {
    navigate(
      `/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.coursePreview}/${detailData.id}`,
      {
        state: {detailData, mode: "view"},
      }
    );
  };

  const handleOpenNewPageReview = () => {
    window.open(
      `/${routesConfig.courseConstruction}/${routesConfig.contentConstruction
      }/${routesConfig.coursePreview}/${detailData.id}${tab === "2" ? "?view=structureCourse" : ""
      }`,
      "_blank"
    );
  };

  const options1: MenuProps["items"] = [
    {
      key: "0",
      label: "Cấu trúc khoá học",
      // icon: <BookOutlined />,
    },
    {
      key: "1",
      label: "Danh sách nội dung",
      // icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Danh sách tài liệu tham khảo",
      // icon: <CopyOutlined />,
    },
  ];

  return (
    <>
      <Content>
        <PageHeader
          title={
            `${location.state?.mode === "view"
              ? "Xem chi tiết khóa học"
              : location.state?.mode === "edit"
                ? "Sửa khóa học"
                : "Thêm mới khóa học"
            } ${detailData?.name}` || ""
          }
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={isShowSubmitButton}
          childrenGroupButton={
            isShowSubmitButton && (
              <div className="button-container">
                <Button onClick={() => handleOpenNewPageReview()}>
                  Xem trước
                </Button>
                <Button onClick={() => handleSendToCheck()}>
                  Yêu cầu duyệt
                </Button>
                <Button
                  onClick={() => handlePublish()}
                  className="button-publish"
                >
                  Xuất bản
                </Button>
                <Space>
                  <Dropdown
                    menu={{
                      items: options1,
                      onClick: ({key}) => {
                        if (key === "1") {
                          confirmNavigation({link: ""}, false, () => {
                            navigate(
                              `/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.contentListCourse}/${params?.id}`,
                              {
                                state: {
                                  courseName: detailData?.name ?? "",
                                  modeTab: "1",
                                  modeback: "backState",
                                  mode: "edit",
                                  backLink: `/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/${params?.id}`,
                                  tab: location.state.tab,
                                },
                              }
                            );
                          });
                        }

                        if (key === "2") {
                          confirmNavigation({link: ""}, false, () => {
                            navigate(
                              `/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.contentListCourse}/${params?.id}`,
                              {
                                state: {
                                  courseName: detailData?.name ?? "",
                                  mode: "view",
                                  modeTab: "4",
                                  modeback: "backState",
                                  backLink: `/${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/${params?.id}`,
                                  tab: location.state.tab,
                                },
                              }
                            );
                          });
                        }
                      },
                    }}
                    placement="bottomRight"
                  >
                    <Button>
                      <EllipsisOutlined/>
                    </Button>
                  </Dropdown>
                </Space>
              </div>
            )
          }
        />
        <div className="tabs-heading">
          <Tabs
            // defaultActiveKey="1"
            activeKey={tab}
            onChange={handleChange}
            items={tabItems}
            // tabBarExtraContent={rightContent()}
          />
        </div>
        <Form.Provider
          onFormFinish={(name, {values, forms}) => {
            if (name === "create-browse-course-modal") {
              saveBrowseCourse(values.lecturerName);
            }
          }}
        >
          <BrowseCourseModal
            clearForm={clearForm}
            title={`Gửi duyệt khoá học ${browseData?.name}`}
            open={isOpenBrowseCourseModal}
            handleOk={confirmModal}
            handleCancel={() => {
              setIsOpenBrowseCourseModal(!isOpenBrowseCourseModal);
            }}
            browseData={browseData}
          />

          {/* <SyncCourseModal
            clearForm={clearForm}
            setSyncCourseCheckbox={setSyncCourseCheckbox}
            title={`Đồng bộ khoá học ${browseData?.name}`}
            open={isOpenSyncCourseModal}
            handleOk={confirmModal}
            handleCancel={() => {
              setIsOpenSyncCourseModal(!isOpenSyncCourseModal);
            }}
            browseData={browseData}
          /> */}
        </Form.Provider>
        <Spin spinning={loading} fullscreen/>
      </Content>
    </>
  );
};

export default ContentCourseDetail;
