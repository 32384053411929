import { Col, Row, Spin, Empty, Select } from "antd";
import TableWithTotals from "../../../../../../../components/table-with-totals/TableWithTotals";
import StatisticalReportCard from "../../../StatisticalReportCard";
import {
  BarStackedChartProps,
  LineChartProps,
  PieChart,
} from "../../../../../../../types/static-report";
import { createTableData } from "../../tableConvertData";
import { staticReportStore } from "../../../../../../../stores/stores";
import {
  exportSearchHistory,
  exportSearchKeywordCount,
  exportStaticUnitAction,
} from "../../../../../../../service/statistical-report";
import {
  chartContentTypes,
  handleExportReport,
  renderCourseStructureType,
} from "../../../../../../../utils/staticReport";
import {
  DOWNLOAD_UNIT,
  SHARE_UNIT,
  VIEW_UNIT,
} from "../../../../../../../constants/static-report";
import dayjs from "dayjs";

type Props = {
  loading: Record<string, boolean>;
  staticDownloadUnit: LineChartProps[];
  staticViewUnit: LineChartProps[];
  staticShareUnit: LineChartProps[];
  searchHistory: BarStackedChartProps[];
  searchKeywordCount: PieChart[];
};

const UserBehaviorTableTab = (props: Props) => {
  const {
    loading,
    staticDownloadUnit,
    staticViewUnit,
    staticShareUnit,
    searchHistory,
    searchKeywordCount,
  } = props;
  const { filterValues, setFilterValues } = staticReportStore();

  const columns = [
    { title: "Thời gian", dataIndex: "criteria", key: "criteria" },
    { title: "Số lượng", dataIndex: "total", key: "total" },
  ];

  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues?.timeUnit,
  };

  const handleParamsChange = (
    value: string,
    courseTypeKey: string,
    moduleGroupKey: string
  ) => {
    let courseStructureType = "";
    let moduleGroup = null;

    switch (value) {
      case "5":
        courseStructureType = value;
        moduleGroup = null;
        break;
      case "6":
        courseStructureType = value;
        moduleGroup = null;
        break;
      case "1":
      case "2":
      case "3":
      case "4":
        courseStructureType = value;
        moduleGroup = Number(value);
        break;
      default:
        break;
    }

    setFilterValues({
      [courseTypeKey]: courseStructureType,
      [moduleGroupKey]: moduleGroup,
    });
  };

  console.log("filter========", filterValues);

  return (
    <div>
      <div className="chart-title">Thống kê số lượng tìm kiếm</div>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng tìm kiếm theo từ khoá"
            content={
              <Spin spinning={loading.searchKeywordCount}>
                {searchKeywordCount?.length > 0 ? (
                  <TableWithTotals
                    data={searchKeywordCount?.map((it) => ({
                      ...it,
                      total: it.count,
                    }))}
                    columns={[
                      {
                        title: "Từ khoá",
                        dataIndex: "criteria",
                        key: "criteria",
                      },
                      { title: "Số lượng", dataIndex: "count", key: "count" },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            moreOption={
              <div>
                <Select
                  style={{ width: 180 }}
                  value={filterValues?.searchKeywordCourseStructureType}
                  onChange={(value) =>
                    handleParamsChange(
                      value,
                      "searchKeywordCourseStructureType",
                      "searchKeywordModuleGroup"
                    )
                  }
                  options={chartContentTypes}
                />
              </div>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportSearchKeywordCount,
                    "Số lượng tìm kiếm theo từ khoá.xlsx",
                    "excel",
                    {
                      ...exportPayload,
                      courseStructureType: renderCourseStructureType(
                        filterValues?.searchKeywordCourseStructureType
                      ),
                      moduleGroup: filterValues?.searchKeywordModuleGroup,
                    }
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportSearchKeywordCount,
                    "Số lượng tìm kiếm theo từ khoá.pdf",
                    "pdf",
                    {
                      ...exportPayload,
                      courseStructureType: renderCourseStructureType(
                        filterValues?.searchKeywordCourseStructureType
                      ),
                      moduleGroup: filterValues?.searchKeywordModuleGroup,
                    }
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 16 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian"
            content={
              <Spin spinning={loading.searchHistory}>
                {searchHistory?.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(searchHistory).dataSource}
                    columns={[
                      {
                        title: "Thời gian tạo",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(searchHistory).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportSearchHistory,
                    "Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportSearchHistory,
                    "Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>

      <div className="chart-title">Thống kê số lượng tải về</div>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê lượng tải về theo thời gian"
            content={
              <Spin spinning={loading.staticDownloadUnit}>
                {staticDownloadUnit?.length > 0 ? (
                  <TableWithTotals
                    data={staticDownloadUnit?.map((it) => ({
                      ...it,
                      total: it.value,
                    }))}
                    columns={columns}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            moreOption={
              <div>
                <Select
                  value={filterValues?.downloadCourseStructureType}
                  style={{ width: 180 }}
                  onChange={(value) =>
                    handleParamsChange(
                      value,
                      "downloadCourseStructureType",
                      "downloadModuleGroup"
                    )
                  }
                  options={chartContentTypes}
                />
              </div>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportStaticUnitAction,
                    "Thống kê lượng tải về theo thời gian.xlsx",
                    "excel",
                    {
                      ...exportPayload,
                      unitActionType: DOWNLOAD_UNIT,
                      courseStructureType: renderCourseStructureType(
                        filterValues?.downloadCourseStructureType
                      ),
                      moduleGroup: filterValues?.downloadModuleGroup,
                    }
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportStaticUnitAction,
                    "Thống kê lượng tải về theo thời gian.pdf",
                    "pdf",
                    {
                      ...exportPayload,
                      unitActionType: DOWNLOAD_UNIT,
                      courseStructureType: renderCourseStructureType(
                        filterValues?.downloadCourseStructureType
                      ),
                      moduleGroup: filterValues?.downloadModuleGroup,
                    }
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê lượng xem theo thời gian"
            content={
              <Spin spinning={loading.staticViewUnit}>
                {staticViewUnit?.length > 0 ? (
                  <TableWithTotals
                    data={staticViewUnit?.map((it) => ({
                      ...it,
                      total: it.value,
                    }))}
                    columns={columns}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            moreOption={
              <div>
                <Select
                  value={filterValues?.viewCourseStructureType}
                  style={{ width: 180 }}
                  onChange={(value) =>
                    handleParamsChange(
                      value,
                      "viewCourseStructureType",
                      "viewModuleGroup"
                    )
                  }
                  options={chartContentTypes}
                />
              </div>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportStaticUnitAction,
                    "Thống kê lượng xem theo thời gian.xlsx",
                    "excel",
                    {
                      ...exportPayload,
                      unitActionType: VIEW_UNIT,
                      courseStructureType: renderCourseStructureType(
                        filterValues?.viewCourseStructureType
                      ),
                      moduleGroup: filterValues?.viewModuleGroup,
                    }
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportStaticUnitAction,
                    "Thống kê lượng xem theo thời gian.pdf",
                    "pdf",
                    {
                      ...exportPayload,
                      unitActionType: VIEW_UNIT,
                      courseStructureType: renderCourseStructureType(
                        filterValues?.viewCourseStructureType
                      ),
                      moduleGroup: filterValues?.viewModuleGroup,
                    }
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{ span: 8 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê lượng chia sẻ theo thời gian"
            content={
              <Spin spinning={loading.staticShareUnit}>
                {staticShareUnit?.length > 0 ? (
                  <TableWithTotals
                    data={staticShareUnit?.map((it) => ({
                      ...it,
                      total: it.value,
                    }))}
                    columns={columns}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            moreOption={
              <div>
                <Select
                  value={filterValues?.shareCourseStructureType}
                  style={{ width: 180 }}
                  onChange={(value) =>
                    handleParamsChange(
                      value,
                      "shareCourseStructureType",
                      "shareModuleGroup"
                    )
                  }
                  options={chartContentTypes}
                />
              </div>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportStaticUnitAction,
                    "Thống kê lượng chia sẻ theo thời gian.xlsx",
                    "excel",
                    {
                      ...exportPayload,
                      unitActionType: SHARE_UNIT,
                      courseStructureType: renderCourseStructureType(
                        filterValues?.shareCourseStructureType
                      ),
                      moduleGroup: filterValues?.shareModuleGroup,
                    }
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportStaticUnitAction,
                    "Thống kê lượng chia sẻ theo thời gian.pdf",
                    "pdf",
                    {
                      ...exportPayload,
                      unitActionType: SHARE_UNIT,
                      courseStructureType: renderCourseStructureType(
                        filterValues?.shareCourseStructureType
                      ),
                      moduleGroup: filterValues?.shareModuleGroup,
                    }
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};

export default UserBehaviorTableTab;
