import { SearchOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { FunctionComponent } from "react";
import CustomCard from "../../../../components/custom-card/CustomCard";
import CustomList from "../../../../components/custom-list/CustomList";

type DetailSettingsProps = {
  openModal?: () => void;
};

const DetailSettings: FunctionComponent<DetailSettingsProps> = ({
  openModal,
}) => {
  return (
    <div className="wrapper-settings--info mt-3">
      <CustomCard
        title={
          <>
            <div className="card-heading--info mt-2">
              <Typography.Title level={5}>Cài đặt bảo mật</Typography.Title>
            </div>
            <Typography.Text className="sub-title">
              Giữ bí mật thông tin bảo mật của bạn để đảm bảo an toàn cho tài
              khoản của bạn{" "}
            </Typography.Text>
          </>
        }
        className="card-info--wrapper"
      >
        <CustomList
          title="Mật khẩu"
          actions={
            <Button className="btn btn-outlined" onClick={openModal}>
              <SearchOutlined />
              <Typography.Text>Đổi mật khẩu</Typography.Text>
            </Button>
          }
        />
        {/* <CustomList title="Đặt lại mật khẩu" actions={<Button className="btn btn-outlined">
                    <SyncOutlined/>
                    <Typography.Text>
                        Đặt lại mật khẩu
                    </Typography.Text>
                </Button>}/> */}
        {/* <CustomList title="Bảo mật 2 lớp" actions={<Button className="btn btn-outlined">
                    <SettingOutlined />
                    <Typography.Text>
                        Thiết lập
                    </Typography.Text>
                </Button>}/>
                <CustomList title="Mã bảo vệ" actions={<Button className="btn btn-outlined">
                    <SettingOutlined />
                    <Typography.Text>
                        Thiết lập
                    </Typography.Text>
                </Button>}/> */}
      </CustomCard>
    </div>
  );
};

export default DetailSettings;
