import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { useDirty } from "../contexts/DirtyProvider";

export interface ObjLink {
  link: string;
  path?: any;
};

interface UsePreventRouteChange {
  confirmNavigation: (path: ObjLink, isBlank?: boolean, cb?: any) => void;
};

export const usePreventRouteChange = (): UsePreventRouteChange => {
  const { isDirty, setDirty } = useDirty();
  const navigate = useNavigate();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  const confirmNavigation = (obj: ObjLink, isBlank?: boolean, cb?: any) => {
    if (isDirty) {
      confirmModal(obj, isBlank, cb);
    } else {
      finalAction(obj, isBlank, cb);
    }
  };

  const confirmModal = (obj: ObjLink, isBlank?: boolean, cb?: any) => {
    Modal.confirm({
      title: "Rời khỏi trang?",
      content: "Các thay đổi bạn đã thực hiện có thể không được lưu.",
      okText: "Rời khỏi",
      cancelText: "Huỷ",
      onOk: () => {
        setDirty(false);
        finalAction(obj, isBlank, cb);
      },
      onCancel() { },
    });
  }


  const finalAction = (obj: ObjLink, isBlank?: boolean, cb?: any) => {
    if (obj.link === '') {
      cb && cb();
    } else {
      if (isBlank) {
        const newWindow = window.open(obj.link, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null
        };
      } else {
        navigate(obj.link, obj.path);
      }
    }
  }

  return { confirmNavigation };
};
