import {
  ContactsOutlined,
  FileTextOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Layout, Tabs, TabsProps } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../../config/routes";
import { useStudyManagementStore } from "../../../../stores/stores";
import RemindCheckInDetailForm from "./RemindCheckInDetailForm";
import ReportCheckIn from "./report-checkin";
import { useEffect, useState } from "react";
import { DEFAULT_PERMISSION_ATTENDANCE } from "../../../../constants/setting-identity";
import { getPermissionsAuthority } from "../../../../service/setting-authority";
import MyCourseDetail from "../../course/my-course/my-course-detail";
import { getSettingAttendanceById } from "../../../../service/setting-attendance";
const imgPath = "/images/";

const { Content } = Layout;
const { ATTENDANCE, MODE } = DEFAULT_PERMISSION_ATTENDANCE;

const RemindCheckInDetail = () => {
  const navigate = useNavigate();
  const [permissionAttendance, setPermissionAttendance] = useState<string[]>(
    []
  );
  const [assignCourse, setAssignCourse] = useState<boolean>(false);
  const params = useParams();
  const { numberTab } = useStudyManagementStore((state) => state);
  const [attendanceData, setAttendanceData] = useState({
    title: "",
    isActive: false,
  });
  const fetchDataAttendanceById = async () => {
    try {
      const response = await getSettingAttendanceById(params.id);
      const { data } = response.data;
      setAttendanceData({
        title: data?.mooc_course?.name,
        isActive: data?.is_active,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPermissionData = async () => {
    try {
      const res = await getPermissionsAuthority(`${params.id}`);
      const { data } = res.data;
      setPermissionAttendance(data?.attendancePermission);
      setAssignCourse(data?.assignCourse)
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchPermissionData();
    fetchDataAttendanceById();
  }, []);

  const listBreadcrumb = [
    {
      title: (
        <Link to={`/management-study/${routesConfig.settingRemindCheckIn}`}>
          Quản lý điểm danh
        </Link>
      ),
    },
    {
      title: attendanceData.title,
    },
  ];
  const items: TabsProps["items"] = [
    ...([ATTENDANCE, MODE].some((element) =>
      permissionAttendance.includes(element)
    ) && attendanceData.isActive
      ? [
          {
            key: "1",
            icon: <ContactsOutlined />,
            label: "Cài đặt nhắc nhở",
            children: <RemindCheckInDetailForm id={params.id as string} />,
          },
        ]
      : []),
    ...(!attendanceData.isActive
      ? [
          {
            label: "Thực hiện điểm danh",
            key: "2",
            icon: <UserAddOutlined />,
            children: <MyCourseDetail titleCourse={""} />,
          },
        ]
      : []),
    {
      key: "3",
      icon: <FileTextOutlined />,
      label: "Báo cáo",
      children: (
        <ReportCheckIn title="" permissionAttendance={permissionAttendance} assignCourse={assignCourse} />
      ),
    },
  ];

  return (
    <Content>
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title={attendanceData.title}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() =>
          navigate(`/management-study/${routesConfig.settingRemindCheckIn}`)
        }
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey={numberTab} items={items} />
      </div>
    </Content>
  );
};

export default RemindCheckInDetail;
