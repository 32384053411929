import { Flex, Form, Layout, TableProps, Tabs, TabsProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormInput from "../../../components/form-input/FormInput";
import PageHeader from "../../../components/page-header/PageHeader";
import TableData from "../../../components/table-data/TableData";
import { getTranscriptList } from "../../../service/course-management";
import TranscriptManagement from "./components/transcript-management/TranscriptManagement";
import "./TranscriptList.scss";
import { SearchOutlined } from "@ant-design/icons";

type Props = {
  title?: string;
};

interface Attempt {
  orderNumber: number;
  result: number;
}

interface Exam {
  blockId: number;
  userId: number;
  name: string;
  scoringFactor: number;
  data: Attempt[];
  result: number;
}

interface User {
  userId: number;
  username: string;
  email: string;
  exams: Exam[];
  result: number;
}

interface ProcessedUser {
  key: number;
  stt: number;
  name: string;
  email: string;
  finalScore: number;
  [key: string]: string | number;
}

const TranscriptList = (props: Props) => {
  const { id } = useParams();
  const [tab, setTab] = useState("1");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [transcriptList, setTranscriptList] = useState<User[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  // Hàm lấy danh sách dữ liệu từ server
  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      courseId: Number(id),
    };

    await getTranscriptList(data)
      .then((res) => {
        const { data } = res.data;

        setTranscriptList(data.data);
        setTotalRecords(data.total);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  // Hàm tạo các cột động dựa trên dữ liệu của các bài thi
  const getDynamicColumns = (exams: Exam[]): ColumnsType<ProcessedUser> => {
    const examColumns = exams.map((exam, examIndex) => {
      const attemptColumns = exam.data.map((attempt) => ({
        title: `Lần ${attempt.orderNumber}`,
        dataIndex: `attempt${attempt.orderNumber}_exam${examIndex + 1}`,
        key: `attempt${attempt.orderNumber}_exam${examIndex + 1}`,
        width: 80,
      }));

      return {
        title: `${exam.name} (Hệ số ${exam.scoringFactor})`,
        children: [
          ...attemptColumns,
          {
            title: "Kết quả",
            dataIndex: `result_exam${examIndex + 1}`,
            key: `result_exam${examIndex + 1}`,
            width: 80,
          },
        ],
      };
    });

    return examColumns;
  };

  // Kiểm tra nếu transcriptList không rỗng và có exams thì mới tạo các cột động
  const dynamicColumns: ColumnsType<ProcessedUser> =
    transcriptList.length > 0 && transcriptList[0].exams.length > 0
      ? getDynamicColumns(transcriptList[0].exams)
      : [];

  const columns: ColumnsType<ProcessedUser> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 60,
      render: (_: any, record, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Tên học viên",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 220,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      width: 220,
    },
    ...dynamicColumns,
    {
      title: "Điểm tổng kết",
      dataIndex: "finalScore",
      key: "finalScore",
      sorter: true,
      width: 140,
    },
  ];

  // Hàm xử lý dữ liệu thô thành dữ liệu có cấu trúc cho bảng
  const processData = (rawData: User[]): ProcessedUser[] => {
    return rawData.map((user, index) => {
      const processedExams = user.exams.reduce((acc, exam, examIndex) => {
        const attempts = exam.data.reduce((attemptAcc, attempt) => {
          attemptAcc[`attempt${attempt.orderNumber}_exam${examIndex + 1}`] =
            attempt.result;
          return attemptAcc;
        }, {} as { [key: string]: number });

        return {
          ...acc,
          ...attempts,
          [`result_exam${examIndex + 1}`]: exam.result,
        };
      }, {} as { [key: string]: number });

      return {
        key: index + 1,
        stt: index + 1,
        name: user.username,
        email: user.email,
        ...processedExams,
        finalScore: user.result,
      };
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };
  const data: ProcessedUser[] = processData(transcriptList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const order =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(order);
    setFieldSortOrder(sorter.field);
  };

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: <div>Danh sách</div>,
      children: (
        <div className="waiting-assignment">
          <TableData
            dataColumns={columns}
            loadingTable={loading}
            dataTable={data}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <div>Thống kê</div>,
      children: (
        <div className="assigned">
          <TranscriptManagement />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setPageNumber(1);
    form.resetFields();
  };

  return (
    <Layout className="page-header-group transcript-list">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "filterForm") {
            getListData();
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={"Bảng điểm"} listBreadcrumb={listBreadcrumb} />
            </div>
            <Flex>
              <FormInput
                placeholder="Nhập mã hoặc tên học viên"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
                style={{ width: "400px" }}
              />
            </Flex>
          </div>
        </div>
        <div className="tabs-heading">
          <div className="course-student-list">
            <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
          </div>
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default TranscriptList;
