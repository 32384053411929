import { IDataCreateUser } from "./../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const postMoocApprovalConfigurationSearch: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-approval-configuration/search`, data);
};

export const getMoocApprovalConfigurationById: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-approval-configuration/get-by-id/${id}`);
};
export const getHirachyId: (approvalType: number) => Promise<AxiosResponse<any>> = (approvalType) => {
  return axiosConfig.get(`/mooc-approval-configuration/get-hirachy/${approvalType}`);
};
export const getHirachyById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-approval-configuration/get-hirachy-id/${id}`);
};
export const getAllHirachy: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-approval-configuration/get-all-hirachy`);
};
export const putUpdateMoocApprovalConfiguration: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/mooc-approval-configuration/update/${id}`, data)
}

export const postCreateMoocApprovalConfiguration: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-approval-configuration/create`, data)
}

export const deleteMoocApprovalConfiguration: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/mooc-approval-configuration/delete/${id}`)
}

// Categories 

export const getLookupAuthGroup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-group`)
}
export const getLookupMoocOrganizationGroup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-organization-group/lookup`)
}
