// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button.open {
  border-color: #1677ff; }
  .filter-button.open .filter-text {
    color: #1677ff; }
  .filter-button.open svg {
    color: #1677ff; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/survey-plan/survey-detail/survey-question/components/filter-table-survey-question/index.scss"],"names":[],"mappings":"AACA;EACE,qBAAqB,EAAA;EADvB;IAII,cAAc,EAAA;EAJlB;IAQI,cAAc,EAAA","sourcesContent":["\n.filter-button.open {\n  border-color: #1677ff;\n\n  .filter-text {\n    color: #1677ff;\n  }\n\n  svg {\n    color: #1677ff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
