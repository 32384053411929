// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-class-route {
  padding-top: 24px;
  padding-bottom: 24px; }
  .registration-class-route .ant-card {
    padding-top: 0;
    border: 1px solid #d9d9d9; }
  .registration-class-route__item__section .title {
    font-weight: bold;
    border: 1px solid #f0f0f0;
    border-top: 0;
    padding: 15px; }
  .registration-class-route__item__sequence {
    padding-left: 50px; }
    .registration-class-route__item__sequence .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #f0f0f0;
      border-top: 0;
      padding: 10px 15px; }
      .registration-class-route__item__sequence .top .title {
        font-weight: normal;
        border: 0;
        padding: 0; }
        .registration-class-route__item__sequence .top .title span {
          margin-right: 5px; }
    .registration-class-route__item__sequence .bottom {
      padding-left: 50px; }
  .registration-class-route__item__unit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f0f0f0;
    border-top: 0;
    padding: 10px 15px; }
    .registration-class-route__item__unit .title {
      font-weight: normal;
      border: 0;
      padding: 0; }
      .registration-class-route__item__unit .title span {
        margin-right: 5px; }
`, "",{"version":3,"sources":["webpack://./src/pages/content-construction/registration-class/route/route.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB,EAAA;EAFtB;IAKI,cAAc;IACd,yBAAyB,EAAA;EAIxB;IAEG,iBAAiB;IACjB,yBAAyB;IACzB,aAAa;IACb,aAAa,EAAA;EAIjB;IACE,kBAAkB,EAAA;IADnB;MAIG,aAAa;MACb,mBAAmB;MACnB,8BAA8B;MAC9B,yBAAyB;MACzB,aAAa;MACb,kBAAkB,EAAA;MATrB;QAYK,mBAAmB;QACnB,SAAS;QACT,UAAU,EAAA;QAdf;UAiBO,iBAAiB,EAAA;IAjBxB;MAuBG,kBAAkB,EAAA;EAKtB;IACE,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,yBAAyB;IACzB,aAAa;IACb,kBAAkB,EAAA;IANnB;MASG,mBAAmB;MACnB,SAAS;MACT,UAAU,EAAA;MAXb;QAcK,iBAAiB,EAAA","sourcesContent":[".registration-class-route {\n  padding-top: 24px;\n  padding-bottom: 24px;\n\n  .ant-card {\n    padding-top: 0;\n    border: 1px solid #d9d9d9;\n  }\n\n  &__item {\n    &__section {\n      .title {\n        font-weight: bold;\n        border: 1px solid #f0f0f0;\n        border-top: 0;\n        padding: 15px;\n      }\n    }\n\n    &__sequence {\n      padding-left: 50px;\n\n      .top {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        border: 1px solid #f0f0f0;\n        border-top: 0;\n        padding: 10px 15px;\n\n        .title {\n          font-weight: normal;\n          border: 0;\n          padding: 0;\n\n          span {\n            margin-right: 5px;\n          }\n        }\n      }\n\n      .bottom {\n        padding-left: 50px;\n      }\n\n    }\n\n    &__unit {\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      border: 1px solid #f0f0f0;\n      border-top: 0;\n      padding: 10px 15px;\n\n      .title {\n        font-weight: normal;\n        border: 0;\n        padding: 0;\n\n        span {\n          margin-right: 5px;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
