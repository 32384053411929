import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const postCoursePlanSearchApprove: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(
    `/mooc-survey-approval/search-course-plan-approval`,
    data
  );
};
export const postCoursePlanSearchAllApprove: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(
    `/mooc-survey-approval/search-all-course-plan-approval`,
    data
  );
};
export const postCoursePlanSearchAllAppraisal: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(
    `/mooc-survey-approval/search-all-course-plan-appraisal`,
    data
  );
};
export const postCoursePlanSearchAppraisal: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(
    `/mooc-survey-approval/search-course-plan-appraisal`,
    data
  );
};
export const getInfoPlanApproval: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(
    `/mooc-survey-approval/get-info-course-plan-approval/${id}`
  );
};
export const approveCoursePlan: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-survey-approval/survey-approve`, data);
};
export const postHistoryApproval: (
  id: String,
  data: object
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.post(
    `/mooc-survey-approval/get-history-approval/${id}`,
    data
  );
};
export const approveManySurvey: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(
    `/mooc-survey-approval/course-contruction-plan-approve-many`,
    data
  );
};
export const postSurveySearchApprove: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-survey-approval/search-survey-approve`, data);
};
