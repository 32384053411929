import React, { Fragment, FunctionComponent, useEffect, useState } from "react";
import { Form, message, Typography, Upload, UploadProps } from "antd";
import CustomCard from "../../../../components/custom-card/CustomCard";
import CustomList from "../../../../components/custom-list/CustomList";
import { DataType } from "../../../../types/listUsers";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { FileType, getBase64 } from "../../../../utils/uploadImage";
import "./user-document.scss";
import { VerifiedIcon } from "../../../../components/icons";
import { get, isEmpty } from "lodash";
import {
  dataDocumentCommon,
  dataDocumentContact,
} from "../../constants/dataInfo";
import { useForm } from "antd/es/form/Form";
import {
  getOffices,
  getRoles,
  getUnits,
} from "../../../../service/list-account-users/listAccountUsersApi";
import {
  getDistricts,
  getProvinces,
  getWards,
  updateInfoAccount,
} from "../../../../service/info-detail/infoDetailApi";
import { messageResponse } from "../../../role-management/constants/message";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { convertArrayToNumber } from "../../../../utils/arrays";

type UserDocumentProps = {
  dataUser?: DataType | null;
  refreshData: () => void;
};

const UserDocument: FunctionComponent<UserDocumentProps> = ({
  dataUser,
  refreshData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editingField, setEditingField] = useState<string>("");
  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: [],
    dataProvinces: [],
  });
  const [location, setLocation] = useState({
    provinceValue: "",
    districtValue: "",
    wardValue: "",
  });

  const [imgUrl, setImgUrl] = useState<string>();
  const [userInfo, setUserInfo] = useState({});

  const [form] = useForm();
  const params = useParams();
  const provinceCode = Form.useWatch("thanhPho", form);
  const districtCode = Form.useWatch("quan", form);
  const wardCode = Form.useWatch("phuong", form);
  const address = Form.useWatch("diaChiCuThe", form);
  const id = get(params, "id", "");

  useEffect(() => {
    if (provinceCode) {
      getProvinces(`?provinceCode=${provinceCode}`).then((res) => {
        const { data } = res.data;
        if (setLocation) {
          setLocation({
            ...location,
            provinceValue: data[0].full_name,
          });
        }
      });
    }
    if (districtCode) {
      getDistricts(provinceCode, `&?districtCode=${districtCode}`).then(
        (res) => {
          const { data } = res.data;
          if (setLocation) {
            setLocation({
              ...location,
              districtValue: data[0].nameEn,
            });
          }
        }
      );
    }
    if (wardCode) {
      getWards(districtCode, `&wardCode=${wardCode}`).then((res) => {
        const { data } = res.data;
        if (setLocation) {
          setLocation({
            ...location,
            wardValue: data[0].name,
          });
        }
      });
    }
  }, [provinceCode, districtCode, wardCode]);

  const uploadButton = (
    <button type="button" className="btn-upload">
      {loading ? <LoadingOutlined /> : <EditOutlined />}
    </button>
  );

  useEffect(() => {
    if (dataUser) {
      const ngaySinh = get(dataUser, "ngaySinh", "");
      form.setFieldsValue({
        hoVaTen: get(dataUser, "hoVaTen", ""),
        ngaySinh: !isEmpty(ngaySinh) ? dayjs(ngaySinh) : undefined,
        // 'ngaySinh': dayjs(get(dataUser, 'ngaySinh', '')),
        gioiTinh: get(dataUser, "gioiTinh", ""),
        diaChi: get(dataUser, "diaChi", ""),
        dienThoai: get(dataUser, "dienThoai", ""),
        donViCongTac: get(dataUser, "donViCongTac", ""),
        chucVus: convertArrayToNumber(dataUser.chucVus),
        vaiTros: convertArrayToNumber(get(dataUser, "vaiTros", [])),
        chuyenNganh: get(dataUser, "chuyenNganh", ""),
        trinhDo: get(dataUser, "trinhDo", ""),
      });
    }
  }, [dataUser]);

  const fetchDataOptions = async () => {
    try {
      Promise.all([
        getRoles(),
        getUnits(),
        getOffices(),
        getProvinces(""),
      ]).then((res) => {
        const dataRole = res[0].data.data;
        const dataUnit = res[1].data.data;
        const dataOffices = res[2].data.data;
        const dataProvinces = res[3].data.data;
        setDataOptions({
          ...dataOptions,
          dataOffices: dataOffices,
          dataUnits: dataUnit,
          dataRoles: dataRole,
          dataProvinces: dataProvinces,
        });
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateProfile = async (id: string, userInfo: any) => {
    try {
      updateInfoAccount(id, userInfo).then((res) => {
        const { data, statusCode } = res.data;
        if (statusCode === 200) {
          message.success(get(data, "message", messageResponse.successUpdate));
          refreshData();
          setEditingField("");
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDataOptions();
  }, []);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      updateProfile(id, userInfo);
    }
  }, [userInfo]);

  const handleCloseEdit = () => {
    setEditingField("");
  };

  const handleEditField = (fieldName: string) => {
    setEditingField(fieldName);
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    const id = get(params, "id", "");
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as FileType, (url) => {
        setLoading(false);
        setImgUrl(url);
        updateInfoAccount(id, { image: url }).then((res) => {
          const { data, statusCode } = res.data;
          if (statusCode === 200) {
            message.success(
              get(data, "message", messageResponse.successUpdate)
            );
            refreshData();
          }
        });
      });
    }
  };

  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleFinish = (valuesUpdate: any) => {
    setUserInfo({
      ...valuesUpdate,
      dienThoai: valuesUpdate.dienThoai,
      diaChi:
        location.districtValue &&
        `${address}, ${location.districtValue}, ${location.wardValue}, ${location.provinceValue}`,
      hoVaTen: valuesUpdate.hoVaTen,
      ngaySinh: valuesUpdate.ngaySinh,
      gioiTinh: valuesUpdate.gioiTinh,
      donViCongTacId: valuesUpdate.donViCongTac,
      chucVus: valuesUpdate.chucVus,
      vaiTros: valuesUpdate.vaiTros,
      chuyenNganh: valuesUpdate.chuyenNganh,
      trinhDo: valuesUpdate.trinhDo,
    });
  };

  return (
    <>
      <div className="avatar-info">
        <Upload
          name="avatar"
          listType="picture-circle"
          className="avatar-uploader"
          showUploadList={false}
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {/* {!isEmpty(dataUser?.anhDaiDien) ? (
            <img src={dataUser?.anhDaiDien ?? undefined} alt="avatar" style={{width: "100%", height: "100%", borderRadius: "50%"}}/>
          ) : (
            uploadButton
          )} */}
          <img
            src={dataUser?.anhDaiDien ?? undefined}
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
          {uploadButton}
        </Upload>
        <div className="avatar-title">
          <Typography.Title level={5}>{dataUser?.hoVaTen}</Typography.Title>
          <VerifiedIcon width={"25"} height={"24"} />
        </div>
      </div>
      <Form form={form} onFinish={handleFinish} layout="vertical">
        <CustomCard
          title={
            <div className="card-heading--info">
              <Typography.Title level={5}>Thông tin chung</Typography.Title>
              <Typography.Text className="sub-title">
                Thay đổi thông tin chung hồ sơ cá nhân của người dùng
              </Typography.Text>
            </div>
          }
          className="card-info--wrapper"
        >
          {dataDocumentCommon.map(({ fieldName, title, fieldValue }) => {
            return (
              <Fragment key={fieldName}>
                <CustomList
                  title={title}
                  key={fieldName}
                  name={fieldName}
                  description={get(dataUser, fieldName, "")}
                  data={dataUser}
                  fieldValue={fieldValue}
                  options={dataOptions}
                  editFieldName={editingField}
                  closeActions={handleCloseEdit}
                  actions={
                    editingField !== fieldName && (
                      <a
                        className="item-action"
                        onClick={() => handleEditField(fieldName)}
                      >
                        <EditOutlined />
                      </a>
                    )
                  }
                />
              </Fragment>
            );
          })}
        </CustomCard>
        <CustomCard
          title={
            <div className="card-heading--info">
              <Typography.Title level={5}>Thông tin liên hệ</Typography.Title>
              <Typography.Text className="sub-title">
                Thay đổi thông tin liên hệ của người dùng.
              </Typography.Text>
            </div>
          }
          className="card-info--wrapper"
        >
          {dataDocumentContact.map(({ fieldName, title, fieldValue }) => {
            return (
              <Fragment key={fieldName}>
                <CustomList
                  title={title}
                  key={fieldName}
                  name={fieldName}
                  description={get(dataUser, fieldName, "")}
                  options={dataOptions}
                  fieldValue={fieldValue}
                  form={form}
                  setLocation={setLocation}
                  editFieldName={editingField}
                  closeActions={handleCloseEdit}
                  actions={
                    editingField !== fieldName && (
                      <a
                        className="item-action"
                        onClick={() => handleEditField(fieldName)}
                      >
                        <EditOutlined />
                      </a>
                    )
                  }
                />
              </Fragment>
            );
          })}
        </CustomCard>
      </Form>
    </>
  );
};

export default UserDocument;
