import {
  FilePdfOutlined,
  MenuFoldOutlined,
  SortAscendingOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Flex,
  Input,
  Progress,
  Row,
  Typography,
} from "antd";
import { SearchProps } from "antd/es/input";
import { useEffect, useRef, useState } from "react";
import {
  getCourseQualityAssessment,
  updateRateResult,
} from "../../../service/control-quality-course";
import { getListCourseAttendancePage } from "../../../service/setting-attendance";

const { Title, Text } = Typography;
const { Search } = Input;

interface IProps {
  isOpen: boolean;
  isView: boolean;
  handleNotice: (type: any, message: string) => void;
}

const RateQuality = ({ isOpen, handleNotice, isView }: IProps) => {
  const ulRef = useRef(null);
  const [isShow, setIsShow] = useState(true);
  const [isStopScroll, setIsStopScroll] = useState(false);

  const [idCourse, setIdCourse] = useState("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [sort, setSort] = useState<string>("desc");

  const [searchText, setSearchText] = useState<string>("");
  const [dataCourse, setDataCourse] = useState<any>([]);
  const [dataRate, setDataRate] = useState<any>([]);
  const [pageSize, setPageSize] = useState<number>(20);
  const [statisticsTotal, setStatisticsTotal] = useState(0);

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };
  const fetchListStandard = async () => {
    if (idCourse !== "") {
      try {
        const response = await getCourseQualityAssessment(idCourse);
        const { data } = response.data;
        setDataRate(data);
        const total = data?.reduce(
          (init: any, item: { total: number }) => init + item?.total,
          0
        );

        setStatisticsTotal(total);
      } catch (error) {}
    }
  };

  const handleScroll = () => {
    const ulElement: any = ulRef.current;
    if (
      ulElement?.scrollTop + ulElement?.clientHeight ===
        ulElement?.scrollHeight &&
      !isStopScroll
    ) {
      setPageSize((prevItems) => prevItems + 10);
    }
  };

  // Gắn sự kiện scroll vào window
  useEffect(() => {
    if (isOpen) {
      const ulElement: any = ulRef.current;
      ulElement.addEventListener("scroll", handleScroll);
      return () => ulElement.removeEventListener("scroll", handleScroll);
    }
  }, [pageSize, isOpen]);

  const getListDataFilter = async () => {
    const param = {
      name: searchValue,
      skip: 0,
      take: pageSize,
      sort: sort,
    };

    try {
      const res = await getListCourseAttendancePage(param);
      const { data } = res.data;
      setDataCourse(data?.data);
      setIdCourse(data?.data[0]?.id);
      if (pageSize >= data?.total?._count?.id) {
        setIsStopScroll(true);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getListDataFilter();
  }, [pageSize, searchValue, sort]);

  useEffect(() => {
    fetchListStandard();
  }, [idCourse]);

  const handleViewCourse = (listCourse: any) => {
    setIdCourse(listCourse?.id);
  };

  const handleCheckBox = (e: any, index: number, indexItem?: any) => {
    const { checked, name } = e.target;

    setDataRate((prevRate: any) => {
      const newData = [...prevRate];
      if (name === "rateAll") {
        newData[index].field = newData[index].field?.map((item: any) => ({
          ...item,
          is_active: checked,
        }));
      } else if (name === "rateItem") {
        newData[index].field = newData[index].field?.map(
          (itemData: any, i: number) =>
            i === indexItem ? { ...itemData, is_active: checked } : itemData
        );
      }
      return newData;
    });
  };
  const handleSave = async () => {
    let convertParam = dataRate?.map((value: any) => ({
      [value?.id]: value?.field
        .filter((item: any) => item.is_active)
        .map((item: any) => item.id),
    }));
    const param = {
      data: convertParam.reduce((acc: any, obj: any) => {
        const key = Object.keys(obj)[0];
        acc[key] = obj[key];
        return acc;
      }, {}),
      is_save: dataRate[0]?.is_save,
    };

    try {
      await updateRateResult(idCourse, param);
      handleNotice("success", "Lưu thông tin thành công");
    } catch (error) {}
  };

  const handleSort = () => {
    if (sort === "desc") {
      setSort("asc");
    } else {
      setSort("desc");
    }
  };

  let activeCount = 0;

  dataRate?.forEach((item: any) => {
    item?.field.forEach((moocItem: { is_active: boolean }) => {
      if (moocItem?.is_active) {
        activeCount++;
      }
    });
  });
  const CommonListRate = ({ rate, index }: any) => {
    return (
      <Card className="w-full lg:w-2/3 m-auto mr-[55px] mt-6" key={index}>
        <div className="w-full">
          <Flex className="border-b pb-2" justify="space-between">
            <Flex align="center" className="w-full">
              <Checkbox
                name="rateAll"
                onChange={(e) => handleCheckBox(e, index)}
                checked={rate?.field?.every((item: any) => item.is_active)}
                disabled={isView}
                className="flex items-center !w-[initial]"
              />
              <Title level={5} className="pl-2 !mb-0">
                {rate?.title}
                <span className="text-[#00000073] text-sm pl-1">
                  (Đã chọn{" "}
                  {rate?.field?.filter((item: any) => item?.is_active).length}/
                  {rate?.field?.length})
                </span>
              </Title>
            </Flex>
          </Flex>
          <div className="pl-8 border-b">
            {rate?.field?.map((itemData: any, indexItem: number) => (
              <Flex className="p-4 border-l border-b" align="center">
                <Checkbox
                  name="rateItem"
                  onChange={(e) => handleCheckBox(e, index, indexItem)}
                  disabled={isView}
                  checked={itemData?.is_active}
                  className="flex items-center !w-[initial]"
                />
                <Text className="pl-4">{itemData?.title}</Text>
              </Flex>
            ))}
          </div>
        </div>
      </Card>
    );
  };
  return (
    <div className={`exam-student ${isOpen ? "!h-full" : "!h-0"}`}>
      <Row className="flex" style={{ height: "100%" }}>
        {isOpen && (
          <div
            className={`tabs-heading exam-student-editing ${
              isShow ? "" : "tab-closed w-50px"
            }`}
          >
            <div className="border-b">
              <div className="flex">
                <div
                  className="setting-question-course__header__title justify-center"
                  style={{ visibility: isShow ? "visible" : "hidden" }}
                >
                  Danh sách khoá học
                </div>
                <MenuFoldOutlined
                  className="exam-student-editing-move-btn"
                  rotate={isShow ? 0 : 180}
                  onClick={() => setIsShow(!isShow)}
                />
              </div>
              <p
                className={`${
                  isShow ? "block" : "hidden"
                } text-[#00000073] text-center`}
              >
                Lựa chọn khoá học cần đánh giá
              </p>
            </div>

            <div
              className={`p-4 text-center ${isShow ? "visible" : "invisible"}`}
            >
              <Flex className="pt-3">
                <Search
                  placeholder="Nhập tên khoá học cần tìm"
                  onSearch={onSearch}
                  value={searchText}
                  onChange={handleChangeSearch}
                />
                <Button className="ml-2" onClick={handleSort}>
                  <SortAscendingOutlined />
                </Button>
              </Flex>
            </div>
            <div
              id="scrollList"
              ref={ulRef}
              className={`${
                isShow ? "visible" : "invisible"
              } overflow-auto max-h-[615px]`}
            >
              {dataCourse?.map((listCourse: any, index: number) => (
                <Flex
                  key={index}
                  className={`hover:bg-[#E6F7FF] p-4 ${
                    listCourse?.id === idCourse
                      ? "bg-[#E6F7FF] border-r-4 border-r-[#1890FF]"
                      : ""
                  }`}
                  onClick={() => handleViewCourse(listCourse)}
                  align="center"
                >
                  <Typography.Text className="pr-3 min-w-[45px]">
                    {Number(index + 1)}
                  </Typography.Text>
                  <img
                    className="pr-2"
                    loading="eager"
                    alt=""
                    src={"/images/ic_school_placeholder.svg"}
                  />
                  <Typography.Text>{listCourse?.name}</Typography.Text>
                </Flex>
              ))}
            </div>
          </div>
        )}
        <div className="w-full min-h-[100vh]">
          <Card
            className={`w-full lg:w-2/3 m-auto mt-12 ${
              isOpen ? "mr-[55px]" : ""
            }`}
          >
            <Flex align="center" justify="space-between" className="border-b">
              <div className="pb-3">
                <Flex align="center">
                  <Title level={4} className="pr-4">
                    Thực hiện đánh giá chất lượng khoá học
                  </Title>
                  {!isOpen && (
                    <Badge status="default" text="Đang tắt" className="pb-2" />
                  )}
                </Flex>
                <Text className="text-[#00000073]">
                  {isOpen
                    ? "Chọn tích các tiêu chí khoá học đạt"
                    : `Vui lòng chọn “Bật chế độ" để sử dụng tính năng`}
                </Text>
              </div>
              {isOpen && !isView && (
                <Button type="primary" onClick={handleSave}>
                  <FilePdfOutlined /> Lưu kết quả đánh giá
                </Button>
              )}
            </Flex>
            {isOpen && (
              <div>
                <Flex align="end" className="py-4">
                  <Title level={2} className="!mb-0">
                    {activeCount}
                  </Title>
                  <h4>
                    {" "}
                    / {statisticsTotal}{" "}
                    <span className="text-[#00000073]">{`(tiêu chí)`}</span>
                  </h4>
                </Flex>
                <Progress
                  percent={Number(
                    ((activeCount / statisticsTotal) * 100).toFixed(2)
                  )}
                />
                <span className="text-[#00000073]">
                  Tỉ lệ phần trăm (%) tiêu chí đánh giá đã đạt
                </span>
              </div>
            )}
          </Card>
          {isOpen && (
            <div className="pb-8">
              {dataRate?.map((value: any, index: number) => (
                <CommonListRate rate={value} index={index} />
              ))}
            </div>
          )}
        </div>
      </Row>
    </div>
  );
};

export default RateQuality;
