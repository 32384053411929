import { Checkbox, Col, Form, Modal, Row } from 'antd'
import { useEffect, useState } from 'react'
import { DataType } from "../../../src/types/course-construction"
import { getCoursePlanByUser } from '../../service/course-construct'
import { AssignedSponsor } from '../../types/assign-course-construction'
import FormItemDropdown from '../form-dropdown/FormItemDropdown'

type Props = {
  open: boolean
  clearForm: boolean
  title: string
  handleOk: () => void
  handleCancel: () => void
  browseData?: DataType
  setSyncCourseCheckbox: (value: boolean) => void
}

const validations = {
  lecturerName: {
    required: true,
    message: "Vui lòng chọn cán bộ duyệt khoá học",
  },
}

export default function SyncCourseModal(props: Props) {
  const { open, title, handleOk, handleCancel, clearForm, browseData, setSyncCourseCheckbox } = props
  const [form] = Form.useForm()
  const [coursePlan, setCoursePlan] = useState<AssignedSponsor[]>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)

  const onOk = () => {
    form.submit()
  }

  const getDataLecturer = async () => {
    const params = {
      keyword: "",
      pageNumber: pageNumber,
      pageSize: pageSize,

    }
    await getCoursePlanByUser(params).then((res) => {
      setCoursePlan([...coursePlan, ...res.data.data.data])
    })
  }

  useEffect(() => {
    if (clearForm) {
      form.resetFields()
    }
  }, [clearForm])

  useEffect(() => {
    getDataLecturer()
  }, [pageNumber])

  const onPopupScroll = (e: any) => {
    const { target } = e
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageNumber(pageNumber + 1)
    }
  }

  return (
    <Modal title={title} open={open} onOk={onOk} onCancel={handleCancel} centered okText="Lưu" cancelText="Huỷ">
      <Form layout={"vertical"} form={form} name="create-sync-course-modal">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="lecturerName" label="Tên kế hoạch" rules={[validations.lecturerName]} hasFeedback>
              <FormItemDropdown options={coursePlan} allowClear={true} onPopupScroll={onPopupScroll}
                placeholder="Nhập tên kế hoạch muốn đồng bộ"
              />
            </Form.Item>
            <Checkbox onChange={(e) => setSyncCourseCheckbox(e.target.checked)}>Đồng bộ cấu trúc cho khoá học</Checkbox>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
