import "../login-page/login-page.scss";
import { Button, Checkbox, Modal, Spin } from "antd";
import {
  CustomCaptcha,
  CustomCaptchaElement,
} from "../../../../components/captcha/CustomCaptcha";
import FormInput from "../../../../components/form-input-login/FormInput";
import { FormEvent, useEffect, useRef, useState } from "react";
import { postLogin } from "../login-page/services";
import { useAuthStore } from "../../../../stores/stores";
import { useLocation, useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import { getInfoLoginUser } from "../../../user-info/account/services";
import LoginAlert from "../../../../components/alert/LoginAlert";
import Countdown from "../../../../components/countdown/CountDown";
import { useDirty } from "../../../../contexts/DirtyProvider";
import { toString } from "lodash";
const Cookies = require("js-cookie");
export const LoginCard = (
  forgotPasswordStep: boolean,
  verifyMailStep: boolean,
  loadingLogin: boolean,
  setLoadingLogin: React.Dispatch<React.SetStateAction<boolean>>,
  setForgotPasswordStep: React.Dispatch<React.SetStateAction<boolean>>,
  handleAddClassHide: (id: string) => void,
  handleAddClassShow: (id: string) => void
) => {
  const { login } = useAuthStore((state) => {
    return {
      login: state.login,
    };
  });
  const accountInfo = JSON.parse(
    localStorage.getItem("auth-storage") as string
  );
  const isRemembered = accountInfo?.state?.isSaveInfo;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUrl = toString(queryParams.get("redirect"));

  // Decode giá trị redirect
  const decodedRedirectUrl = decodeURIComponent(redirectUrl);

  const rememberInfo = useAuthStore((state) => state.rememberInfo);
  const saveUserInfo = useAuthStore((state) => state.saveUserInfo);
  const setExpiresIn = useAuthStore((state) => state.setExpiresIn);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [saveLogin, setSaveLogin] = useState(false);
  const [checkedCaptcha, setCheckedCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [countLoginFailed, setCountLoginFailed] = useState<number>(0);
  const [remainingLockSeconds, setRemainingLockSeconds] = useState<number>(0);
  let captchaRef = useRef<CustomCaptchaElement>(null);
  const navigate = useNavigate();
  const { setDirty } = useDirty();

  useEffect(() => {
    if (isRemembered) {
      setUserName(accountInfo?.state?.username);
      setSaveLogin(true);
    }
  }, []);

  const fetchData = async () => {
    await postLogin({
      username: userName,
      password: password,
      captchaResponse: captchaToken,
      moocSource: "qt",
    })
      .then(async (res) => {
        setDirty(false);
        Cookies.set("accessToken", res?.data?.data?.accessToken);
        login(
          res?.data?.data?.accessToken,
          res?.data?.data?.refreshToken,
          res?.data?.data?.edxToken
        );
        setExpiresIn(res?.data?.data?.expiresIn);
        await getInfoLoginUser().then((res) => {
          saveUserInfo(res.data.data);
        });
        rememberInfo(saveLogin, userName);

        if (decodedRedirectUrl) window.location.replace(decodedRedirectUrl);

        if (countLoginFailed >= 3 && countLoginFailed <= 5) {
          Modal.confirm({
            title: <div className="mt-1 ml-1">Cảnh báo đăng nhập!</div>,
            centered: true,
            content: (
              <div className="mt-5">
                Hệ thống ghi nhận bạn đã đăng nhập sai quá 03 lần. Để bảo vệ tài
                khoản, bạn có muốn <b>đặt lại mật khẩu</b> của tài khoản không?
              </div>
            ),
            okText: "Đặt lại mật khẩu",
            cancelText: "Đến trang chủ",
            type: "warning",
            icon: (
              <img
                src={`${process.env.PUBLIC_URL}/assets/icons/warning-orange-icon.svg`}
              />
            ),
            onOk: () => {
              navigate(routesConfig.account);
            },
            onCancel: () => {
              if (decodedRedirectUrl)
                window.location.replace(decodedRedirectUrl);
              else navigate(routesConfig.listUsers);
            },
          });
        }
      })
      .catch((err: any) => {
        setErrorMessage(
          err?.response?.data?.retryTime < 2
            ? `${err?.response?.data?.message}`
            : `${err?.response?.data?.message}, Đăng nhập thất bại ${
                err?.response?.data?.retryTime || 0
              }/5 lần`
        );
        setCheckedCaptcha(false);
        captchaRef.current?.forceUpdate();
        setLoadingLogin(false);
        setCountLoginFailed(err?.response?.data?.retryTime);
        setRemainingLockSeconds(err?.response?.data?.remainingLockSeconds);
      });
  };
  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userName && password) {
      setLoadingLogin(true);
      fetchData();
    }
  };
  return (
    <div
      id="login-card"
      className={`login-card__item ${
        !forgotPasswordStep && !verifyMailStep ? "active" : "inactive"
      }`}
    >
      <div className="header-container">
        <h2 className="ng-nhp">Đăng nhập</h2>
      </div>
      {errorMessage && (
        <div className="mb-5 mt-4">
          <LoginAlert
            message={errorMessage}
            onClose={() => setErrorMessage("")}
          />
        </div>
      )}
      <Spin tip="Loading" size="large" spinning={loadingLogin}>
        <form
          id="login-form"
          className="form-and-button"
          onSubmit={handleLogin}
          autoComplete="off"
        >
          <div className="inputs">
            <FormInput
              required={true}
              title="Email hoặc Số điện thoại"
              inputPrefix="/inputprefix@2x.png"
              placeholder="Email hoặc số điện thoại"
              errorInvalid="Email hoặc số điện thoại không hợp lệ."
              inputSuffix="/inputsuffix@2x.png"
              inputSuffixIcon={false}
              propMinWidth="7.56rem"
              regexValue={
                /^(?:\d{10,11}|[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,})$/
              }
              value={userName}
              onChange={setUserName}
              id="email"
              autoComplete="off"
            />
            <FormInput
              required={true}
              title="Mật khẩu"
              inputPrefix="/inputprefix-1@2x.png"
              placeholder="Mật khẩu"
              inputSuffix="/inputsuffix-1@2x.png"
              inputSuffixIcon
              propMinWidth="2.81rem"
              propMinWidth1="4.19rem"
              isPassword={true}
              errorInvalid="Sai mật khẩu."
              value={password}
              onChange={setPassword}
              id="password"
              autoComplete="off"
            />
            <div className="checkbox-and-button1">
              <div className="checkbox">
                <Checkbox
                  checked={saveLogin}
                  onChange={() => setSaveLogin(!saveLogin)}
                >
                  Ghi nhớ đăng nhập
                </Checkbox>
              </div>
              <div className="button10">
                <div className="icon-wrapper24">
                  <img
                    className="wrapper-icon40"
                    alt=""
                    src="/wrapper@2x.png"
                  />
                </div>
                <div
                  onClick={() => {
                    // handleToggleForgotPasswordStep();
                    setForgotPasswordStep(true);
                    handleAddClassHide("login-card");
                    handleAddClassShow("forgot-password");
                  }}
                  className="text112"
                >
                  Quên mật khẩu
                </div>
              </div>
            </div>
          </div>

          <div className="captcha-container">
            <CustomCaptcha
              ref={captchaRef}
              setToken={setCaptchaToken}
              checked={checkedCaptcha}
              setChecked={setCheckedCaptcha}
            />
          </div>
          <Button
            disabled={countLoginFailed >= 5}
            htmlType="submit"
            className={`button11 btn-login`}
            type="primary"
            loading={loadingLogin}
          >
            {countLoginFailed < 5 && remainingLockSeconds === 0
              ? "Đăng nhập"
              : null}
            {remainingLockSeconds !== 0 ? (
              <span className="font-size-16 text-white font-weight-5">
                Đăng nhập lại sau{" "}
                <Countdown initialTime={remainingLockSeconds} />
              </span>
            ) : null}
          </Button>
        </form>
      </Spin>
    </div>
  );
};
