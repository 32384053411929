import { HeartOutlined, StarOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Image, Popover } from "antd";
import { useEffect, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { routesConfig } from "../../../config/routes";
import "./educationalProgramCard.scss";
import { notifyWarning } from "../../../utils/notification";
// import { useAuthStore } from "../../../stores/stores";

interface EducationalProgramCardProps {
  id?: number;
  image?: string;
  enterpriseName?: string;
  hours?: number;
  name?: string;
  price?: number;
  teachers?: string[];
  isPopular?: boolean;
  star?: number;
  studentCount?: number;
  isSubject?: boolean;
  description?: string;
  courseCount?: number;
  upDateTime?: string;
  isTrending?: number;
  slug?: string;
}

function EducationalProgramCard(props: EducationalProgramCardProps) {
  const {
    image,
    enterpriseName,
    hours,
    name,
    price,
    teachers = [],
    isPopular,
    star,
    studentCount,
    isSubject,
    description,
    courseCount,
    upDateTime,
    isTrending,
    slug,
    id,
  } = props;
  // const navigate = useNavigate();
  // const accessToken = useAuthStore((state) => state.accessToken);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [popoverWidth, setPopoverWidth] = useState<number | undefined>(
    undefined
  );
  const [popoverHeight, setPopoverHeight] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (cardRef.current) {
      setPopoverWidth(cardRef.current.offsetWidth);
      setPopoverHeight(cardRef.current.offsetHeight);
    }
  }, [cardRef.current]);

  const introText = () => {
    return (
      <div className="educational-program-card-introduce">
        <div>
          <span className="font-weight-6">
            {enterpriseName} ({slug})
          </span>
          <div>Cập nhật lần cuối {upDateTime}</div>
        </div>
        <div className="total-course">
          <div className="dot"></div>
          {courseCount && courseCount < 10
            ? "0" + courseCount
            : courseCount}{" "}
          khoá học
        </div>
      </div>
    );
  };

  const handleViewDetail = (id: number | undefined) => {
    //navigate to sv page
    // navigate(`${routesConfig.curriculumPrograms}/detail/${id}`, {
    //   state: slug,
    // });
  };

  const handleClickWriteCourse = () => {
    // have no token
    // if (accessToken) {
    //   notifyWarning("Chức năng đang được phát triển");
    // } else notifyWarning("Bạn phải đăng nhập mới có thể ghi danh khóa học");
  };

  const content = (
    <div
      className="course-introduction"
      style={{ width: popoverWidth, height: popoverHeight }}
    >
      <div className="course-introduction-content">
        <div className="font-size-24 font-weight-6">{name}</div>
        <div className="course-introduction-scholl">
          {enterpriseName} ({slug})
          <div className="total-course">
            <div className="dot-primary"></div>&nbsp;
            {courseCount && courseCount < 10
              ? "0" + courseCount
              : courseCount}{" "}
            khoá học
          </div>
        </div>
        <div>
          Những kỹ năng nền tảng và cơ bản nhất từ giảng viên có kinh nghiệm
        </div>
        <ul className="course-introduction-list">
          <li className="flex">
            <img
              className="course-introduction-list__icon"
              alt="icon"
              src="/assets/icons/check.svg"
            />
            Cung cấp những kiến thức cơ bản về ngôn ngữ Anh (ngữ pháp, kỹ năng
            nghe/nói, ...)
          </li>
          <li className="flex">
            <img
              className="course-introduction-list__icon"
              alt="icon"
              src="/assets/icons/check.svg"
            />
            Another Step
          </li>
          <li className="flex">
            <img
              className="course-introduction-list__icon"
              alt="icon"
              src="/assets/icons/check.svg"
            />
            Another Step
          </li>
        </ul>
      </div>

      <div className="course-introduction-btn">
        <Button className="enroll-course-btn" onClick={handleClickWriteCourse}>
          Ghi danh khoá học
        </Button>
        <Button
          className="rate-btn"
          onClick={() => notifyWarning("Chức năng đang được phát triển")}
        >
          <HeartOutlined className="heart-icon" />
        </Button>
      </div>
    </div>
  );

  return (
    <div className="educational-program-card" ref={cardRef}>
      <Popover
        content={content}
        placement="right"
        trigger="hover"
        overlayStyle={{
          width: popoverWidth ? `${popoverWidth}px` : "auto",
          height: popoverHeight ? `${popoverHeight}px` : "auto",
        }}
      >
        <Card
          style={{ width: "100%" }}
          className="transition-3"
          onClick={() => handleViewDetail(id)}
        >
          <div className="card-content" id="parent-card">
            <div className="card-img-container">
              <Image
                preview={false}
                className="card-img"
                src={image || `${process.env.PUBLIC_URL}/assets/img/card.png`}
                alt="card-img"
              />
              {introText()}
            </div>

            <h3 className="type-2 educational-program-card-title">{name}</h3>
            <p className="type-4 ">{description}</p>

            <div className="card-solution">
              {isTrending ? (
                <div className="popular">
                  {isTrending === 1 ? "Thịnh hành" : ""}
                </div>
              ) : (
                ""
              )}
              {star ? (
                <div className="rate-star flex align-center">
                  <StarOutlined style={{ color: "#EF6820" }} />
                  {Number(star?.toFixed(1))}
                </div>
              ) : (
                ""
              )}
              {studentCount ? (
                <div className="total-student">
                  <UserOutlined className="mr-1" />
                  {studentCount} học viên
                </div>
              ) : (
                ""
              )}

              {isSubject ? <div className="subject">Luật pháp</div> : null}
            </div>
          </div>
        </Card>
      </Popover>
    </div>
  );
}

export default EducationalProgramCard;
