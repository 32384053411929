// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration-class-info {
  padding-top: 24px;
  padding-bottom: 24px; }
  .registration-class-info .ant-card {
    padding-top: 0;
    border: 1px solid #d9d9d9; }
  .registration-class-info .ant-form-item-label {
    font-weight: bold; }
  .registration-class-info .setting-item {
    margin-bottom: 0px; }
`, "",{"version":3,"sources":["webpack://./src/pages/content-construction/registration-class/info/info.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,oBAAoB,EAAA;EAFtB;IAKI,cAAc;IACd,yBAAyB,EAAA;EAN7B;IAUI,iBAAiB,EAAA;EAVrB;IAaI,kBAAkB,EAAA","sourcesContent":[".registration-class-info {\n  padding-top: 24px;\n  padding-bottom: 24px;\n\n  .ant-card {\n    padding-top: 0;\n    border: 1px solid #d9d9d9;\n  }\n\n  .ant-form-item-label {\n    font-weight: bold;\n  }\n  .setting-item{\n    margin-bottom: 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
