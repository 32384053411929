import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  GetProp,
  Input,
  Modal,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import { SearchProps } from "antd/es/input";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonModal from "../../../components/modal/common";
import { DEFAULT_ROLE_AUTO_MARK } from "../../../constants/automatic-mark";
import {
  getPermissionAutoScore,
  updatePermissionAutoScore,
} from "../../../service/auto-score";
import { getSettingAuthorityGroup } from "../../../service/setting-authority";
import { CheckboxProps } from "antd/lib";
import { useAddPermission } from "../../../stores/stores";
import PermissionExamDrawer from "./PermissionExamDrawer";
import DrawerCustom from "../../../components/drawer/Drawer";

const { Title, Text } = Typography;
const { Search } = Input;

const PermissionExam = () => {
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [dataRole, setDataRole] = useState<any>([]);
  const [optionRole, setOptionRole] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const { permission, setPermission } = useAddPermission((state) => state);

  const [formModal, setFormModal] = useState({
    title: "",
    type: "",
    id: "",
    defaultValue: [] as any,
  });
  const [messageApi, contextHolder] = message.useMessage();

  const fetchPermissionData = async () => {
    try {
      const res = await getPermissionAutoScore(params?.id || "");
      const { data } = res.data;
      setDataRole(data);
    } catch (error) {
      console.log(error);
    }
  };

  const CommonPopupRole = () => {
    const [searchText, setSearchText] = useState<string>("");
    const onChange: CheckboxProps["onChange"] = (e) => {
      if (!e.target.checked) {
        let newPermission = [...permission];
        newPermission = newPermission.filter(
          (item: number) => item !== e.target.value
        );

        setPermission(newPermission);
      } else {
        setPermission([...permission, e.target.value]);
      }
    };

    const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
      setSearchValue(value);
      setSearchText(value);
    };
    const handleChangeSearch = (e: any) => {
      setSearchText(e?.target?.value);
    };
    return (
      <div>
        <Search
          className="mb-2"
          placeholder="Nhập từ khoá cần tìm "
          onSearch={onSearch}
          value={searchText}
          onChange={handleChangeSearch}
        />
        {optionRole?.map((option: any) => (
          <Checkbox
            onChange={onChange}
            name={option.label}
            value={option.value}
            checked={permission.includes(option.value)}
            className="w-full item-role"
            disabled={isView}
          >
            <Tooltip title={option.label}>{option?.label}</Tooltip>
          </Checkbox>
        ))}
      </div>
    );
  };
  const onCloseModal = () => {
    setIsOpen(false);
    setIsView(false);
  };
  const handleEdit = (role: any) => {
    const convertDefaultPermission = role?.mooc_setting_score_report_group?.map(
      (value: any) => value?.auth_group?.id
    );
    setIsOpen(true);
    setIsView(false);
    setFormModal({
      title: DEFAULT_ROLE_AUTO_MARK[role?.type],
      type: role?.type,
      id: role?.id,
      defaultValue: convertDefaultPermission,
    });
    setPermission(convertDefaultPermission);
  };
  const handleView = (role: any) => {
    const convertDefaultPermission = role?.mooc_setting_score_report_group?.map(
      (value: any) => value?.auth_group?.id
    );
    setIsOpen(true);
    setIsView(true);
    setFormModal({
      title: DEFAULT_ROLE_AUTO_MARK[role?.type],
      type: role?.type,
      id: role?.id,
      defaultValue: convertDefaultPermission,
    });
    setPermission(convertDefaultPermission);
  };
  const fetchSettingAuthority = async () => {
    const filterParam = {
      search: searchValue,
      take: 10,
      skip: 0,
    };
    try {
      const response = await getSettingAuthorityGroup(params?.id, filterParam);
      const { data } = response.data;
      setOptionRole(
        data?.map((option: any) => ({
          label: option?.name,
          value: option?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingAuthority();
    fetchPermissionData();
  }, [searchValue]);

  const handleCancel = () => {
    setIsOpen(false);

    // handleClose();
  };
  const handleSaveRole = async (role: any) => {
    const paramRole = {
      group_ids: permission,
      course_block_id: Number(params?.id),
      type: role?.type,
    };

    if (isEmpty(paramRole?.group_ids)) {
      messageApi.open({
        type: "error",
        content: `phải chọn ít nhất 1 đối tượng ở ${role?.title.toLowerCase()}`,
      });
    } else {
      try {
        await updatePermissionAutoScore(role?.id, paramRole);
        setIsOpen(false);
        messageApi.open({
          type: "success",
          content: `Lưu thay đổi ${role?.title.toLowerCase()} thành công`,
        });
        await fetchPermissionData();
      } catch (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: `Lưu thay đổi ${role?.title.toLowerCase()} thất bại`,
        });
      }
    }
  };
  return (
    <div className="w-full lg:w-2/3 xl:w-1/2 m-auto my-12">
      {contextHolder}
      {dataRole?.map((role: any, index: number) => (
        <Card className="mt-4" key={index}>
          <Flex justify="space-between">
            <div>
              <Title level={4} className="!leading-3">
                {DEFAULT_ROLE_AUTO_MARK[role?.type]}
              </Title>
              <Text className="text-[#00000073]">
                Chọn đối tượng áp dụng cho phân quyền này
              </Text>
            </div>
            <Flex align="center">
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => handleEdit(role)}
              >
                <EditOutlined />
              </Button>
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => handleView(role)}
              >
                <EyeOutlined />
              </Button>
            </Flex>
          </Flex>
        </Card>
      ))}
      <DrawerCustom
        title={formModal?.title}
        open={isOpen}
        onClose={handleCancel}
        extraNode={
          <Space>
            {!isView && (
              <>
                <Button onClick={handleCancel}>
                  <Typography.Text>Hủy</Typography.Text>
                </Button>
                <Button
                  className="btn btn-primary"
                  htmlType="submit"
                  // onClick={handleOpenSave}
                >
                  <Typography.Text>Lưu</Typography.Text>
                </Button>
              </>
            )}
          </Space>
        }
        widthDrawer={730}
      >
        <PermissionExamDrawer formModal={formModal} isView={isView} />
      </DrawerCustom>
      {/* {!isView ? (
        <CommonModal
          open={isOpen}
          title={formModal.title}
          desc={<CommonPopupRole />}
          closeIcon={true}
          okText={"Lưu"}
          cancelText={"Huỷ"}
          icon={""}
          confirm={() => handleSaveRole(formModal)}
          closeModal={onCloseModal}
          className={"modal-rule"}
        />
      ) : (
        <Modal
          open={isOpen}
          className={"modal-rule"}
          title={formModal.title}
          closeIcon={true}
          centered
          onCancel={onCloseModal}
          footer=""
          okButtonProps={{ disabled: true }}
        >
          <CommonPopupRole />
        </Modal>
      )} */}
    </div>
  );
};

export default PermissionExam;
