// import "./detail.scss";
import { Button, Input, Row, Space, ConfigProvider, Col, Typography } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ListAnswer } from "../../../../../../types/survey-managment/survey";

const QuestionType7 = (props: any) => {
    const filteredOptionsA: ListAnswer[] = props.listOptions.filter((option: ListAnswer) => {
        return option.answerText && (!option.linkedAnswerText || option.linkedAnswerText === "");
    });

    const filteredOptionsB: ListAnswer[] = props.listOptions.filter((option: ListAnswer) => {
        return !option.answerText || (option.answerText === "" && option.linkedAnswerText);
    });

    const [optionsA, setOptionsA] = useState<ListAnswer[]>(filteredOptionsA || []);
    const [optionsB, setOptionsB] = useState<ListAnswer[]>(filteredOptionsB || []);

    const handleChangeAnswerText = (
        event: React.ChangeEvent<HTMLInputElement>,
        index: number,
        column: string
    ) => {
        if (column === "A") {
            const newOptions = optionsA.map((option, idx) => {
                if (idx === index) {
                    return {
                        ...option,
                        answerText: event.target.value,
                    };
                }
                return option;
            });
            setOptionsA(newOptions);
            props.setUpdateListAnswer([...newOptions, ...optionsB]);
        } else if (column === "B") {
            const newOptions = optionsB.map((option, idx) => {
                if (idx === index) {
                    return {
                        ...option,
                        linkedAnswerText: event.target.value,
                    };
                }
                return option;
            });
            setOptionsB(newOptions);
            props.setUpdateListAnswer([...newOptions, ...optionsA]);
        }
    };

    return (
        <div>
            <div>
                <br />
                <ConfigProvider
                    theme={{
                        components: {
                            Input: {
                                colorTextPlaceholder: "black",
                                paddingInline: 0,
                                paddingBlock: 0,
                            },
                            Button: {
                                paddingInline: 2,
                                paddingBlock: 0,
                            },
                        },
                    }}
                >
                    <Row gutter={32} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Col>
                            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                                <Typography.Title level={5}>Cột A</Typography.Title>

                                {optionsA.map((option, index) => (
                                    <Row
                                        style={{
                                            margin: "0 auto",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        key={index}
                                    >
                                        <Input
                                            prefix={<>{index + 1}.</>}
                                            // suffix={
                                            //     <Button
                                            //         style={{ width: 20, height: 25 }}
                                            //         icon={<CloseOutlined />}
                                            //         // onClick={() => onHandleDeleteOption(index, "A")}
                                            //     />
                                            // }
                                            variant="borderless"
                                            value={option.answerText}
                                            // onChange={(event) => handleChangeAnswerText(event, index, "A")}
                                        />
                                    </Row>
                                ))}
                            </Space>
                        </Col>

                        <Col>
                            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                                <Typography.Title level={5}>Cột B</Typography.Title>

                                {optionsB.map((option, index) => (
                                    <Row
                                        style={{
                                            margin: "0 auto",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        key={index}
                                    >
                                        <Input
                                            prefix={<>{String.fromCharCode(97 + index)}.</>}
                                            // suffix={
                                            //     <Button
                                            //         style={{ width: 20, height: 25 }}
                                            //         icon={<CloseOutlined />}
                                            //         // onClick={() => onHandleDeleteOption(index, "B")}
                                            //     />
                                            // }
                                            variant="borderless"
                                            value={option.linkedAnswerText}
                                            // onChange={(event) => handleChangeAnswerText(event, index, "B")}
                                        />
                                    </Row>
                                ))}
                            </Space>
                        </Col>
                    </Row>
                </ConfigProvider>
                <br />
            </div>
        </div>
    );
};

export default QuestionType7;
