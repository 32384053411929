import React, { useEffect, useRef, useState } from 'react';
import { Button, Layout, Tabs, TabsProps, Typography } from 'antd';
import CourseStructure from './structure';
import './style.scss';
import MoocBreadcrumb from '../../components/breadcrumb';
import PageHeader from '../../components/page-header/PageHeader';
import { routesConfig } from '../../config/routes';
import {
  PlayCircleOutlined,
  SaveOutlined,
  SettingOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

const indexDB = require('idbcache').default;

function PreviewQuiz() {
  const backAnchorRef = useRef<HTMLAnchorElement>(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const unitId = searchParams.get('unitId');
  const [title, setTitle] = useState('');
  const [backUrl, setBackUrl] = useState('');
  const [backBreadCrumb, setBackBreadCrumb] = useState('');

  const navigate = useNavigate();
  const navigateBack = () => {
    navigate(backUrl, {
      state: {
        id: unitId
      }
    });
  };

  const { Content } = Layout;
  const imgPath = '/images/';

  const listBreadcrumb = [
    {
      title: (
        <a ref={backAnchorRef} onClick={navigateBack} href={`${backUrl}`}>
          {backBreadCrumb}
        </a>
      ),
    },
    {
      title: title || '',
    },
  ];

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Cấu trúc khoá học',
      children: <CourseStructure />,
    },
    {
      key: '2',
      label: 'Bài tập/bài kiểm tra/bài thi',
      children: 'Content of Tab Pane 2',
    },
    {
      key: '3',
      label: 'Tài liệu tải về',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '4',
      label: 'Ghi chú',
      children: 'Content of Tab Pane 3',
    },
    {
      key: '5',
      label: 'Thảo luận',
      children: 'Content of Tab Pane 3',
    },
  ];

  useEffect(() => {
    indexDB.get('current-course').then((val: any) => {
      setTitle(JSON.parse(val).name);
      setBackUrl(JSON.parse(val).backUrl);
      setBackBreadCrumb(JSON.parse(val).backBreadCrumb);

    });
  }, []);

  return (
    <Content>
      <PageHeader
        title={title || ''}
        listBreadcrumb={listBreadcrumb}
        arrowLeft={imgPath + 'arrowLeft.svg'}
        positionItem='column'
        breadcrumb={true}
        headerGroupButton={true}
      />

      <div className='content-layout'>
        <div className='practice-layout'>
          <CourseStructure />
        </div>
      </div>
    </Content>
  );
}

export default PreviewQuiz;
