import { Button, Dropdown, Form, Layout, MenuProps, Modal, Popconfirm, Space, Spin, Switch, TableColumnsType, TableProps, Typography, message, } from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState, } from "react";
import DrawerCustom from "../../../components/drawer/Drawer";
import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, LoadingOutlined, MoreOutlined, SaveOutlined, } from "@ant-design/icons";
import { DataType, validateFieldCreate } from "./constants/data";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTable from "./components/filter-table/FilterTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormInstance } from "antd/lib";
import { HelpService } from "../../../service/helper.service";
import FormFilter from "./components/filter-group/FormFilter";
import { get, isEmpty } from "lodash";
import TableData from "../../../components/table-data/TableData";
import FormCreateCategory from "./components/form-create-category/FormCreateCategory";
import { routesConfig } from "../../../config/routes";
import { addMoocPortfolio, changeStatusPortfolio, deleteMoocPortfolio, downloadPortfolioTemplateFile, exportExcelMoocPortfolio, getAllByFilter, getAllPortfolioByName, getMoocPortfolioById, importExcelMoocPortfolio, updateMoocPortfolio, } from "../../../service/category-management/shared-categories-management";
import { formatDateV2 } from "../../../utils/converDate";
import "./shared-categories-management.scss";

type SharedCategoriesManagementProps = {
  title?: string;
};

const SharedCategoriesManagement = ({ title }: SharedCategoriesManagementProps) => {
  const [form] = Form.useForm();
  const [params, setParams] = useSearchParams();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedEdit, setIsOpenedEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const navigate = useNavigate();
  const helpService = new HelpService();
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [editId, setEditId] = useState<number>(0);
  const [dataDetail, setDataDetail] = useState<any>({});
  const [dataPortfolio, setDataPortfolio] = useState<any>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isRendering, setIsRendering] = useState<boolean>(false);

  const [dataOptions, setDataOptions] = useState({
    dataCategories: [],
  });

  const [filterData, setFilterData] = useState<any>({
    keyword: null,
    status: null,
    createdDateFrom: null,
    createdDateTo: null,
  });

  const cateOptions: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa danh mục",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Xóa danh mục",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên danh mục",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 40,
      render: (_: any, record) => {
        return (
          <div className="table-category cursor-pointer">
            <div>
              <a
                className="category-name"
                onClick={() =>
                  navigate(
                    `${routesConfig.sharedCategoriesManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                        idEdit: record?.id,
                        dataPortfolio: dataPortfolio,
                      },
                    }
                  )
                }
              >
                {record.name}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mã danh mục",
      dataIndex: "code",
      key: "code",
      width: 30,
    },
    {
      title: "Danh mục cha",
      dataIndex: "parentName",
      key: "parentName",
      width: 40,
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 40,
      render: (_: any, record, index: number) => {
        return record.createdDate ? formatDateV2(record.createdDate) : null;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: { status: boolean; id: number }) => {
        const handleChange = (checked: boolean) => {
          record.status = checked;
        };

        const confirm = (id: number) => {
          setLoading(true);
          changeStatusPortfolio({
            id: id, 
            status: record?.status,
          }).then((res) => {
            if (res.status === 200) {
              setTimeout(() => {
                setLoading(false);
              }, 333);
              helpService.successMessage("Thay đổi trạng thái thành công!");
              getAllPortfolio();
            }
          }).catch(err => {
            setLoading(false);
            console.error(err);
          })
        };

        const cancel = () => {
          helpService.errorMessage("Hủy thao tác thành công!");
        };

        return (
          <Popconfirm
            rootClassName="popconfirm-change-status"
            description={`Xác nhận đổi trạng thái?`}
            onConfirm={() => confirm(get(record, 'id'))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title="">
            <Switch defaultChecked={record.status}
                    checked={record.status}
                    onChange={handleChange}/>
          </Popconfirm>
        );
      },
      width: 20,
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.sharedCategoriesManagementDetail}/${record?.id}`,
                    {
                      state: {
                        mode: "view",
                        record,
                        name: record.name,
                        idEdit: record?.id,
                        dataPortfolio: dataPortfolio,
                      },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: cateOptions,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      getMoocPortfolioUpdate(record.id);
                      handleOpenEdit(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        title: `Xoá danh mục`,
                        content: `Dữ liệu đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá?`,
                        onOk: () => deletePortfolio(record.id),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị danh mục",
    },
  ];

  // Get all data portfolio
  const getAllDataPortfolio = async () => {
    try {
      await getAllPortfolioByName("").then((res) => {
        if(res.status === 200) {
          setDataOptions({
            ...dataOptions,
            dataCategories: res.data.data,
          });
        }
      })
    } catch (err) {
      console.error(err);
    }
  }

  // Get data Portfolio by filter
  const getAllPortfolio = (data?: any) => {
    setLoading(true);
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      status: filterData.status,
      createdDateFrom: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdDateTo: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`]
    };
    getAllByFilter(dataReq)
      .then((res) => {
        setDataPortfolio(res.data.moocPortfolioData);
        setTotalRecords(res.data.total);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      }
    );
    getAllDataPortfolio();
  };

  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      createdDateFrom: null,
      createdDateTo: null,
      status: null,
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      getAllPortfolio();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    getAllPortfolio();
  };

  const handleFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      status: values.status,
      createdDateFrom: values?.time ? (values?.time[0]).add(1, 'day').toISOString() : null,
      createdDateTo: values?.time ? (values?.time[1]).add(1, 'day').toISOString() : null,
    });
  };

  // Get Portfolio to edit
  const getMoocPortfolioUpdate = async (id: number) => {
    setLoadingForm(true);
    try {
      await getMoocPortfolioById(id).then((res) => {
        const { data } = res.data;
        setDataDetail(data);
        setTimeout(() => {
          setLoadingForm(false);
        }, 333);
      })
    } catch (err) {
      setLoadingForm(false);
      console.error();
    }
  };

  // Modal create | edit
  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenEdit = (id: number) => {
    setEditId(id);
    setIsOpened(true);
    setIsOpenedEdit(true);
  };

  const handleClose = () => {
    setIsOpened(false);
    setIsOpenedEdit(false);
    form.resetFields();
  };

  const handleCreateAndEdit = () => {
    Modal.confirm({
      title: !isOpenedEdit ? `Thêm mới danh mục dùng chung` : `Chỉnh sửa danh mục dùng chung` ,
      content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu`,
      onOk: () => form.submit(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  const onFinishCreate = async (values: any) => {
    if (Object.keys(values).length > 0) {
      if (isOpenedEdit) {
        const dataPayload = {
          id: editId,
          name: values.name,
          code: values.code,
          parentId: values.cateDad,
          status: values.status,
        };
        setIsSaving(true);
        try {
          await updateMoocPortfolio(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Câp nhật danh mục thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllPortfolio();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      } else {
        const dataPayload = {
          name: values.name,
          code: values.code,
          parentId: values.cateDad,
          status: values.status,
        };
        setIsSaving(true);
        try {
          await addMoocPortfolio(dataPayload).then((res) => {
            const { success } = res.data;
            if (success) {
              message.success("Thêm mới danh mục thành công!");
            } else {
              message.error("Có lỗi xảy ra vui lòng thử lại!");
            }
            handleClose();
            getAllPortfolio();
          })
        } catch (err) {
          console.error(err);
        } finally {
          setIsSaving(false);
        }
      }
    }
  };

  // Delete Portfolio
  const deletePortfolio = async (id: number) => {
    try {
      await deleteMoocPortfolio(id).then((res) => {
        if (res.status === 200) {
          message.success("Xóa danh mục thành công!");
          getAllPortfolio();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  // Import file Excel to add new
  const importExcel = async (file: any) => {
    setLoading(true);
    try {
      await importExcelMoocPortfolio(file).then((res) => {
        if (res.status === 200) {
          message.success("Thêm mới danh mục thành công!");
          setLoading(false);
          setTimeout(() => {
            setLoading(false);
          }, 300);
          getAllPortfolio();
        }
      })
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  // Export file Excel
  const exportExcel = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      status: filterData.status,
      createdDateFrom: filterData.createdDateFrom ? filterData.createdDateFrom : null,
      createdDateTo: filterData.createdDateTo ? filterData.createdDateTo : null,
      sort: isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ['created_date, desc'] : [`${fieldSortOrder},${statusOrder}`],
    };
    setIsRendering(true);
    try {
      await exportExcelMoocPortfolio(dataReq).then((res) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Danh sách danh mục dùng chung.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!");
        setIsRendering(false);
      })
    } catch (err) {
      console.error("Error downloading Excel file:", err);
    }
  }

  // Download file template excel
  const handleDownloadFile = async () => {
    try {
      await downloadPortfolioTemplateFile().then((res) => {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Mẫu thêm mới danh mục dùng chung.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!")
      })
    } catch (err) {
      console.error("Error downloading Excel file:", err);
    }
  }

  // Sort
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // useEffect
  useEffect(() => {
    if (Object.keys(dataDetail).length > 0) {
      form.setFieldsValue({
        name: dataDetail.name,
        code: dataDetail.code,
        cateDad: dataDetail.parentId ? dataDetail.parentId : null,
        status: dataDetail.status,
      });
    }
  }, [dataDetail]);

  useEffect(() => {
    getAllPortfolio();
  }, [pageNumber, pageSize, filterData, statusOrder]);

  return (
    <Layout className="page-header-group">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createPortfolio") {
            const { createPortfolio } = forms;
            const data = createPortfolio.getFieldsValue(validateFieldCreate);
            onFinishCreate(data);
          }
        }}
      >
        <div className={`page-header-group--layout ${ showFilter ? "open-filter" : "" }`} >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              exportExcel={exportExcel}
              handleDownloadFile={handleDownloadFile}
              importExcel={importExcel}
              isRendering={isRendering}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataPortfolio}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>
        
          <TableData
            dataTable={dataPortfolio}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
          />
          
        <DrawerCustom
          title={`${isOpenedEdit ? "Chỉnh sửa danh mục dùng chung" : "Thêm mới danh mục dùng chung" }`}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button className="btn btn-primary" htmlType="submit" onClick={handleCreateAndEdit} disabled={isSaving} >
                {isSaving ? <LoadingOutlined /> : <SaveOutlined />}
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateCategory form={form} dataOptions={dataOptions} loadingForm={loadingForm}/>
        </DrawerCustom>
      </Form.Provider>
    </Layout>
  );
};

export default SharedCategoriesManagement;
