import { Button, Modal } from "antd";
import "./ConfirmModal.scss";

type Props = {
  open: boolean;
  handleOk: () => void;
  handleCancel?: () => void;
  title?: string;
  content?: string;
  confirmIcon?: string;
  btnCancel?: boolean;
  confirmBtntitle?: string;
};

const ConfirmModal = (props: Props) => {
  const {
    open,
    handleOk,
    handleCancel,
    title,
    content,
    btnCancel = true,
    confirmIcon = `${process.env.PUBLIC_URL}/assets/icons/book-open-border.svg`,
    confirmBtntitle = "Xác nhận",
  } = props;
  return (
    <Modal
      open={open}
      title={
        <div className="title-container">
          <img
            className="bg-icon-img"
            loading="eager"
            alt=""
            src={`${process.env.PUBLIC_URL}/assets/img/bg_log-out-popup.png`}
          />

          <div className="bg-icon-circle">
            <img
              className="confirm-modal-icon"
              src={confirmIcon}
              alt={"icon"}
            />
          </div>
        </div>
      }
      className="confirm-modal"
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      footer={
        <div className="footer-action">
          {btnCancel && (
            <Button onClick={handleCancel} className="footer-action-cancel">
              Huỷ
            </Button>
          )}

          <Button
            onClick={handleOk}
            className={
              !btnCancel
                ? "footer-action-confirm-full"
                : "footer-action-confirm"
            }
          >
            {confirmBtntitle}
          </Button>
        </div>
      }
    >
      <div className="confirm-modal-title">{title}</div>
      <div className="confirm-modal-content">{content}</div>
    </Modal>
  );
};

export default ConfirmModal;
