import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  message,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../../components/page-header/PageHeader";
import { isEmpty } from "lodash";
import TableData from "../../../../components/table-data/TableData";
import "./index.scss";

import { DetailTypeAuthGroup } from "../../../../types/mooc-share-all";
import { DetailTypeMoocOrganizationGroup } from "../../../../types/course-plan-managment/mooc-organization-group";
import {
  DetailTypeMoocApprovalConfiguration,
  DataTypeMoocApprovalConfiguration,
  FilterDataMoocApprovalConfiguration,
} from "../../../../types/course-plan-managment/mooc-approval-configuration";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";
import { FormCreateMoocApprovalConfiguration } from "./components/form-create";
import {
  postMoocApprovalConfigurationSearch,
  deleteMoocApprovalConfiguration,
  getMoocApprovalConfigurationById,
  postCreateMoocApprovalConfiguration,
  putUpdateMoocApprovalConfiguration,
  getLookupAuthGroup,
  getLookupMoocOrganizationGroup,
  getAllHirachy,
} from "../../../../service/course-plan-managment/mooc-approval-configuration/mooc-approval-configuration-api";

type ListMoocApprovalConfigurationProps = {
  title?: string;
};

const ListApprovalConfiguration = (
  props: ListMoocApprovalConfigurationProps
) => {
  // data option, columns is changing
  const idField = "id";
  const nameField = "id";
  const [listMoocApprovalConfiguration, setListMoocApprovalConfiguration] =
    useState<DataTypeMoocApprovalConfiguration[]>([]);
  const [browseData, setBrowseData] =
    useState<DataTypeMoocApprovalConfiguration>();
  const [detailData, setDetailData] =
    useState<DetailTypeMoocApprovalConfiguration>();
  const [filterData, setFilterData] =
    useState<FilterDataMoocApprovalConfiguration>({});

  // forgein key function call apis
  const [lstIndividualGroupId, setLstIndividualGroupId] = useState<
    DetailTypeAuthGroup[]
  >([]);
  const [lstOrganizationalGroupId, setLstOrganizationalGroupId] = useState<
    DetailTypeMoocOrganizationGroup[]
  >([]);
  const [lstHierachy, setLstHierachy] = useState<any>([]);

  const getListLstIndividualGroupId = async () => {
    try {
      setLoading(true);
      await getLookupAuthGroup()
        .then((res) => {
          const { data } = res.data;
          setLstIndividualGroupId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return item;
            })
          );
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListHierachy = async () => {
    try {
      setLoading(true);
      await getAllHirachy()
        .then((res) => {
          const { data } = res.data;
          const dataFormat = data.map((item: any) => {
            return {
              id: item.id,
              name: item.approvalLevelName,
            };
          });

          setLstHierachy(dataFormat);
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListLstOrganizationalGroupId = async () => {
    try {
      setLoading(true);
      await getLookupMoocOrganizationGroup()
        .then((res) => {
          const { data } = res.data;
          setLstOrganizationalGroupId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return {
                id: item.id,
                name: item.group_name,
              };
            })
          );
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    getListLstIndividualGroupId();
    getListLstOrganizationalGroupId();
    getListHierachy();
  }, []);

  const [dataOptions, setDataOptions] = useState<any>([]);
  useEffect(() => {
    setDataOptions([
      {
        title: "Loại cấu hình phê duyệt",
        type: "FormItemDropdown",
        key: "approvalType",
        size: 4,
        data: [
          {
            id: 1,
            name: "Khảo sát",
          },
          {
            id: 2,
            name: "Đăng ký kế hoạch xây dựng khóa học",
          },
          {
            id: 3,
            name: "Kế hoạch xây dựng khóa học",
          },
        ],
      },
      {
        title: "Cấp phê duyệt trước đó",
        type: "FormItemDropdown",
        key: "hierarchyId",
        size: 4,
        data: lstHierachy,
      },
      {
        title: "Nhóm cán bộ phê duyệt",
        type: "FormItemDropdown",
        key: "individualGroupId",
        size: 4,
        data: lstIndividualGroupId,
      },
      {
        title: "Nhóm tổ chức phê duyệt",
        type: "FormItemDropdown",
        key: "organizationalGroupId",
        size: 4,
        data: lstOrganizationalGroupId,
      },
    ]);
  }, [lstHierachy, lstIndividualGroupId, lstOrganizationalGroupId]);

  const [dataFilter, setDataFilter] = useState({});
  const optionsDropdownMenu: MenuProps["items"] = [
    {
      key: "edit",
      label: "Sửa Cấu hình phê duyệt",
      icon: <EditOutlined />,
    },
    {
      key: "delete",
      label: "Xóa Cấu hình phê duyệt",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];
  const returnTypeApproval = (type: number) => {
    switch (type) {
      case 1:
        return "Khảo sát";
      case 2:
        return "Đăng ký kế hoạch xây dựng khóa học";
      case 3:
        return "Kế hoạch xây dựng khóa học";
      default:
        return "";
    }
  };
  const dataColumns: TableColumnsType<DataTypeMoocApprovalConfiguration> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên cấu hình phê duyệt", //text
      dataIndex: "approval_level_name",
      key: "approvalLevelName",
      width: 5,
      sorter: {
        compare: (a, b) => 0, // compare: (a, b) => a.approvalLevelName.length - b.approvalLevelName.length
      },
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {record?.approvalLevelName}
          </span>
        );
      },
    },
    {
      title: "Loại cấu hình duyệt", //text
      dataIndex: "approval_type",
      key: "approvalType",
      width: 4,

      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {" "}
            {returnTypeApproval(record?.approvalType)}
          </span>
        );
      },
    },
    {
      title: "Cấp phê duyệt trước đó", //text
      dataIndex: "nameLevelApproval",
      key: "nameLevelApproval",
      width: 5,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">
            {record?.nameLevelApproval}
          </span>
        );
      },
    },
    {
      title: "Nhóm cán bộ phê duyệt", //dropdown
      dataIndex: "nameAuth",
      key: "nameAuth",
      width: 3,
      render: (_: any, record, index: number) => {
        return <span className="table-order-header">{record?.nameAuth}</span>;
      },
    },
    {
      title: "Nhóm tổ chức phê duyệt", //dropdown
      dataIndex: "nameOrganization",
      key: "nameOrganization",
      width: 3,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.nameOrganization}</span>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record[idField]}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(`${location.id}`, {
                    state: { record, mode: "view" },
                  })
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: optionsDropdownMenu,
                  onClick: ({ key }) => {
                    if (key === "edit") {
                      setIdDetail(record[idField]);
                      setIsOpened(true);
                      getDetailData(record[idField]);
                    }
                    if (key === "delete") {
                      Modal.confirm({
                        title: `Xoá cấu hình phê duyệt "${record.approvalLevelName}"`,
                        content: `Cấu hình phê duyệt đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá cấu hình phê duyệt?`,
                        onOk: () => deleteData(record),
                        centered: true,
                        okText: "Đồng ý",
                        cancelText: "Huỷ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 3,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  // function call apis, process data
  const getListData = () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      id: !isEmpty(filterData.id) ? filterData.id : null,
      approvalLevelName: !isEmpty(filterData.approvalLevelName)
        ? filterData.approvalLevelName
        : null,
      approvalType: !isEmpty(filterData.approvalType)
        ? filterData.approvalType
        : null,
      isOrganization: !isEmpty(filterData.isOrganization)
        ? filterData.isOrganization
        : null,
      hierarchyId: !isEmpty(filterData.hierarchyId)
        ? filterData.hierarchyId
        : null,
      individualGroupId: !isEmpty(filterData.individualGroupId)
        ? filterData.individualGroupId
        : null,
      organizationalGroupId: !isEmpty(filterData.organizationalGroupId)
        ? filterData.organizationalGroupId
        : null,
      createdDate: !isEmpty(filterData.createdDate)
        ? filterData.createdDate
        : null,
      createdDateFrom: !isEmpty(filterData.createdDateFromTo?.[0])
        ? filterData.createdDateFromTo?.[0].toString()
        : null,
      createdDateTo: !isEmpty(filterData.createdDateFromTo?.[1])
        ? filterData.createdDateFromTo?.[1].toString()
        : null,
      updatedDate: !isEmpty(filterData.updatedDate)
        ? filterData.updatedDate
        : null,
      updatedDateFrom: !isEmpty(filterData.updatedDateFromTo?.[0])
        ? filterData.updatedDateFromTo?.[0].toString()
        : null,
      updatedDateTo: !isEmpty(filterData.updatedDateFromTo?.[1])
        ? filterData.updatedDateFromTo?.[1].toString()
        : null,
      moocApprovalConfiguration: !isEmpty(filterData.moocApprovalConfiguration)
        ? filterData.moocApprovalConfiguration
        : null,
      otherMoocApprovalConfiguration: !isEmpty(
        filterData.otherMoocApprovalConfiguration
      )
        ? filterData.otherMoocApprovalConfiguration
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };

    postMoocApprovalConfigurationSearch(data)
      .then((res) => {
        const { data } = res.data;
        setListMoocApprovalConfiguration(data.data);
        setTotalRecords(data.totalRecords);
        setPageNumber(data.pageNumber);
        setPageSize(data.pageSize);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Có lỗi chưa xác định");
      });
  };

  const getDetailData = async (id: number) => {
    setLoading(true);
    try {
      const response = await getMoocApprovalConfigurationById(
        (Number(id) || 0).toString()
      )
        .then((res) => {
          const { data } = res.data;
          setDetailData(data);
          setTimeout(() => {
            setLoading(false);
            setIsOpened(true);
          }, 500);
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };

  const deleteData = async (record: any) => {
    try {
      if (false) {
        // check deleteable?
        //message.error("Không thể xoá Cấu hình phê duyệt đã xuất bản");
      } else {
        setLoading(true);
        await deleteMoocApprovalConfiguration(record[idField])
          .then((res) => {
            //if (res.data.statusCode === 200) {
            message.success("Cấu hình phê duyệt đã được xoá thành công");
            getListData();
            setLoading(false);
            //}
          })
          .catch((err) => {
            setLoading(false);
            message.error("Có lỗi chưa xác định");
          });
      }
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };

  const handleCreate = async (value: any) => {
    try {
      setLoading(true);
      const data = value;
      const response = await postCreateMoocApprovalConfiguration(data)
        .then((result) => {
          setLoading(false);
          message.success("Cấu hình phê duyệt đã được thêm thành công");
          setIsOpened(false);
          getListData();
          // navigate(
          //   `${routesConfig.configuration}/${routesConfig.approvalConfiguration}`
          // );
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };

  const handleEdit = async (value: any) => {
    try {
      const data = value;
      const response = await putUpdateMoocApprovalConfiguration(idDetail, data)
        .then((result) => {
          setLoading(false);
          // navigate success
          message.success(`Sửa cấu hình phê duyệt thành công`);
          setIsOpened(false);
          getListData();
        })
        .catch((err: any) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };

  const clearFilter = () => {
    setLoading(true);
    form.resetFields();
    setFilterData({
      //createDate: null,
      //publicDate: null,
      //courseType: null,
      //status: null,
    });

    setDataFilter({ ...dataFilter });
    getListData();
  };

  // common state variables, no change
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenBrowseModal, setIsOpenBrowseModal] = useState<boolean>(false);
  const [isOpenSyncModal, setIsOpenSyncModal] = useState<boolean>(false);
  const [syncCheckbox, setSyncCheckbox] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [idDetail, setIdDetail] = useState(0);

  // common functions
  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleOpenCreate = () => {
    // add course here
    setIdDetail(0);
    setIsOpened(true);
  };

  const handleSave = (value: any) => {
    if (idDetail && idDetail !== 0) {
      handleEdit(value);
    } else {
      handleCreate(value);
    }
  };

  return (
    <Layout className="page-header-group course-plan-managment-configuration">
      <Form.Provider
        onFormFinish={(formName, { values, forms }) => {
          if (formName === "create-edit-mooc-approval-configuration-detail") {
            const dataFormat = {
              ...values,
              isOrganization:
                Number(values.isOrganization) === 0 ? false : true,
            };
            handleSave(dataFormat);
          }
        }}
      >
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
              showFilter={dataOptions != null && dataOptions.length > 0}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            onFinish={handleSubmitSearch}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
          />
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listMoocApprovalConfiguration}
            dataColumns={newColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <FormCreateMoocApprovalConfiguration
          // truyen danh sach cac bien state list
          dataOptions={dataOptions}
          id={idDetail}
          detailData={detailData}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListApprovalConfiguration;
