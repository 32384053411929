

import { RouterProvider, } from "react-router-dom";
import { router } from "./routes/router";
import { ConfigProvider } from "antd";
import { DirtyProvider } from "./contexts/DirtyProvider";
import katex from 'katex';
import 'katex/dist/katex.min.css';
import WebSocketComponent from "./components/websocket/WebSocketComponent";
// @ts-ignore */
window.katex = katex;

function App() {
  return (
    <>
      <ConfigProvider theme={{
        token: {
          fontFamily: 'Roboto'
        },
      }}>
        <DirtyProvider>
            <WebSocketComponent>
                <RouterProvider router={router} />
            </WebSocketComponent>
        </DirtyProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
