import { Editor, Plugin } from "grapesjs";
import { commonMedia } from "../../grapesjs-config";
import { ConditionBlockCustom } from "../../components/condition-block";
import { nameConditionBlocks } from "../../grapesjs-ui";
import { ConstructCourseDetailType } from "../../../../types/course-construction";

export type PluginOptions = {
  detailData?: ConstructCourseDetailType;
};

const ConditionPlugin: Plugin<PluginOptions> | any = (editor: Editor, optionsPlugin: PluginOptions = {}) => {
  const currentUrl = window.location.href;

  // Create a URL object
  const url = new URL(currentUrl);

  // Get the pathname from the URL
  const pathname = url.pathname;

  // Split the pathname into segments
  const segments = pathname.split("/");

  // Find the index of the segment that contains 'content-course-detail'
  const detailIndex = segments.indexOf("content-course-detail");

  // The courseId should be the next segment after 'content-course-detail'
  const courseId = segments[detailIndex + 1];

  const defaultOptions = {
    courseId: courseId,
  };

  const options = { ...defaultOptions, ...optionsPlugin };

  editor.Components.addType("ConditionBlockCustom", {
    isComponent: (el) => el.tagName === "ConditionBlockCustom".toUpperCase(),
    model: {
      defaults: {
        component: ConditionBlockCustom,
        stylable: true,
        resizable: true,
        editable: true,
        draggable: true,
        droppable: true,
      },
    },
    extend: "react-component",
  });

  editor.BlockManager.add("ConditionBlockCustom", {
    media: commonMedia,
    label: 'Giao diện',
    category: nameConditionBlocks,
    content: '<ConditionBlockCustom component-class="ConditionBlockCustom" courseid="' + options.courseId + '" userid="" data-gjs-editable="true"></ConditionBlockCustom>',
  });
};
export default ConditionPlugin;
