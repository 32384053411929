import React, { useEffect, useState } from "react";
import { Button, Radio, RadioChangeEvent, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAddLesson } from "../../../../stores/stores";
import { QuestionData, QuestionItemDetail, } from "../../../../types/course-construction";
import { initialChooseTextQuestionValue } from "../../../../constants";
import { SortableList } from "../../../SortableList";
import QuestionTextItem from "./QuestionDropDownItem";
import TextEditor from "../../../text-editor/TextEditor";
import { findMaxIndexOfArray, mapAnsBEtoFE } from "../../../../utils/arrays";
import { renderQuestionNameByType, renderQuestionDescriptionByType } from "../../../../utils/functionUltils";

function DropDown({ data = {} as QuestionData }: QuestionItemDetail) {
  const question = useAddLesson((state: any) => state.question);
  const [questionName, setQuestionName] = useState<string>("");
  const [answers, setAnswers] = useState(initialChooseTextQuestionValue);
  const [radioValue, setRadioValue] = useState(0);
  const setCurrentQuestionInfo = useAddLesson(
    (state) => state.setCurrentQuestionInfo
  );

  const handleChangeRadio = (e: RadioChangeEvent) => {
    const currentChangeRadioId = e.target.value;

    if (currentChangeRadioId) {
      const distAnswer = [...answers];
      distAnswer.forEach((item) => (item.isTrue = false));

      distAnswer[currentChangeRadioId - 1] = {
        ...distAnswer[currentChangeRadioId - 1],
        isTrue: true,
      };
      setAnswers(distAnswer);
      setRadioValue(currentChangeRadioId);
    }
  };

  const handleChangeText = (current: { id: number; value: string }) => {
    const distAnswer = [...answers];
    const indexOfChangeItem = distAnswer.findIndex(
      (item) => item.id === current.id
    );
    if (indexOfChangeItem !== -1) {
      distAnswer[indexOfChangeItem] = {
        ...distAnswer[indexOfChangeItem],
        value: current.value,
      };
      setAnswers(distAnswer);
    }
  };

  const handleRemoveOneChoose = (id: number) => {
    if (id) {
      const newList = [...answers];
      setAnswers(newList.filter((item) => item.id !== id));
    } else console.error("Cannot find id");
  };

  const handleAddMoreChoose = () => {
    if (answers.length > 0) {
      const newItem = {
        id: findMaxIndexOfArray(answers) + 1,
        value: "",
        key: findMaxIndexOfArray(answers) + 1,
        isTrue: false,
        point: 0,
        response: '',
      };
      const newList = [...answers];
      setAnswers(newList.concat([newItem]));
    } else setAnswers(initialChooseTextQuestionValue);
  };

  useEffect(() => {
    setCurrentQuestionInfo({
      ...answers,
      questionName: questionName,
    });
  }, [answers, questionName]);

  useEffect(() => {
    setQuestionName(question.question);
    if (data.answers && data.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(data.answers);
      setAnswers(feAnswer);

      const correctAns = feAnswer.find((quiz: any) => quiz.isTrue);
      if (correctAns) setRadioValue(correctAns.id);
    } else {
      setAnswers([]);
      setRadioValue(0);
    }
  }, [question, data.answers]);

  const handleChangeQuestionName = (qName: string) => {
    setQuestionName(qName);
  };

  const handleDragChange = (value: any) => {
    setAnswers(value);
  };

  return (
    <section className='question-wrapper'>
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={questionName} onChange={handleChangeQuestionName} />
      <div className="tree-course-construct list-answers choose-text">
        <Radio.Group
          name="question-text"
          onChange={handleChangeRadio}
          value={radioValue}
          className="w-full"
        >
          <SortableList
            items={answers}
            onChange={handleDragChange}
            renderItem={(record: any) => (
              <SortableList.Item id={record.id}>
                <SortableList.DragHandle />
                <QuestionTextItem
                  index={record.id}
                  value={record.value}
                  valueRadio={record.id}
                  onChange={handleChangeText}
                  onDelete={handleRemoveOneChoose}
                />
              </SortableList.Item>
            )}
          />
        </Radio.Group>
        <Button
          icon={<PlusOutlined />}
          className='btn-add-more mt-2'
          onClick={handleAddMoreChoose}
        >
          Thêm lựa chọn
        </Button>
      </div>
    </section>
  );
}

export default DropDown;
