import React, {FunctionComponent} from 'react';
import {Button, Col, DatePicker, Form, Row, Typography} from "antd";
import {DeleteOutlined, FilterOutlined} from "@ant-design/icons";
import {get} from "lodash";
import {useForm} from "antd/es/form/Form";
import {FormInstance} from "antd/lib";
import FormItemDropdown from '../../../../../components/form-dropdown/FormItemDropdown';
import { dataStatus } from '../../constants/data';
import "./filter-group.scss";

type FormFilterProps = {
  showFilter?: boolean
  clearFilter: (form: FormInstance) => void,
  onFinish?: any,
  dataOptions?: any
}

const FormFilter: FunctionComponent<FormFilterProps> = ({ onFinish, showFilter, clearFilter, dataOptions }) => {
  const [form] = useForm();
  const { RangePicker } = DatePicker;
  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form name="filterForm" onFinish={onFinish} form={form}>
          <Row gutter={[{xs: 8, xl: 24, md: 16}, 12]}>
            <Col xl={{span: 6}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Thời gian</Typography.Title>
              <Form.Item name="time">
                <DatePicker.RangePicker allowEmpty={[true, true]} placeholder={["Bắt đầu", "Kết thúc"]} format='DD/MM/YYYY' />
              </Form.Item>
            </Col>
            <Col xl={{span: 6}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Đơn vị công tác</Typography.Title>
              <Form.Item name="university">
                <FormItemDropdown placeholder="Chọn đơn vị công tác" options={get(dataOptions, 'dataUniversity', '')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 5}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Chức vụ</Typography.Title>
              <Form.Item name="regency">
                <FormItemDropdown placeholder="Chọn chức vụ" options={get(dataOptions, 'dataRegency', '')}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 3}} md={{span: 5}} sm={{span: 5}} xs={{span: 24}}>
              <Typography.Title level={5}>Trạng thái</Typography.Title>
              <Form.Item name="status">
                <FormItemDropdown placeholder="Chọn trạng thái" options={dataStatus}/>
              </Form.Item>
            </Col>
            <Col xl={{span: 3}} md={{span: 9}} sm={{span: 9}} xs={{span: 24}} className="flex align-end">
              <div className="group-btn--filter">
                <Button className="btn btn-primary filter-btn" htmlType="submit">
                  <FilterOutlined/>
                  <Typography.Text> Lọc </Typography.Text>
                </Button>
                <Button className="btn btn-outlined clear-filter-btn" onClick={() => clearFilter(form)}>
                  <DeleteOutlined/>
                  <Typography.Text> Xóa bộ lọc </Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
