import { PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useEffect, useState } from "react";
import TextEditor from "../../../text-editor/TextEditor";
import { useAddLesson } from "../../../../stores/stores";
import { QuestionData, QuestionItemDetail, VideoAnswerInfo, } from "../../../../types/course-construction";
import { initialChooseMultiVideoQuestionValue, ModeQuestion } from "../../../../constants";
import { SortableList } from "../../../SortableList";
import QuestionMultiVideos from "./QuestionMultiVideos";
import { convertObjectToArray, mapAnsBEtoFE } from "../../../../utils/arrays";
import { renderQuestionNameByType, renderQuestionDescriptionByType } from "../../../../utils/functionUltils";

const ChooseMultiVideos = ({
  data = {} as QuestionData,
}: QuestionItemDetail) => {

  const question = useAddLesson((state: any) => state.question);
  const modeQuestion = useAddLesson((state) => state.modeQuestion);
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);

  const [questionName, setQuestionName] = useState<string>("");
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const [answers, setAnswers] = useState<VideoAnswerInfo[]>(convertObjectToArray(currentQuestionInfo, "questionName") || []);

  const resetAnswers = () => {
    const newAnswers = convertObjectToArray(currentQuestionInfo, "questionName");
    return newAnswers;
  }

  useEffect(() => {
    setQuestionName(question.question);
    if (modeQuestion === ModeQuestion.UPDATE && data.answers && data.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(data.answers);
      setAnswers(feAnswer);
    } else {
      setAnswers([]);
    }
  }, [question, data.answers, modeQuestion]);


  // 0: delete
  // 1: upload image
  // 2: change text
  // 3: change display content
  const handelUpdateAnswer = (data: VideoAnswerInfo, typeAction: number) => {
    let newList = resetAnswers();
    if (typeAction === 0) {
      newList = newList.filter((item: VideoAnswerInfo) => {
        return item.id !== data.id;
      });
    } else {
      const indexOfItem = newList.findIndex((item: VideoAnswerInfo) => {
        return item.id === data.id;
      });
      if (indexOfItem !== -1) {
        if (typeAction === 1) {
          newList[indexOfItem] = {
            ...newList[indexOfItem],
            filePath: data.filePath as string,
            fileName: data.fileName as string,
          };
        }
        if (typeAction === 2) {
          newList[indexOfItem] = {
            ...newList[indexOfItem],
            value: data.value,
          };
        }
        if (typeAction === 3) {
          if (data?.isDisplayContent)
            newList[indexOfItem] = {
              ...newList[indexOfItem],
              isDisplayContent: data.isDisplayContent,
            };
        }
        if (typeAction === 4) {
          newList[indexOfItem] = {
            ...newList[indexOfItem],
            isTrue: data.isTrue,
          };
        }
      }
    }
    setAnswers(newList);
  };

  const handleDragChange = (value: any) => {
    setAnswers(value);
  };

  const handleChangeQuestionName = (qName: string) => {
    setQuestionName(qName);
  };

  const handleAddMoreAnswer = () => {
    if (answers.length > 0) {
      const newItem = {
        id: answers.length + 1,
        isTrue: false,
        filePath: "",
        fileName: "",
        isDisplayContent: false,
        value: "",
        point: 0,
      };
      const newList = [...answers].concat([newItem]);
      setAnswers(newList);
    } else {
      const initNewItems = initialChooseMultiVideoQuestionValue.map((item) => {
        return {
          ...item,
          fileName: '',
          filePath: '',
        };
      });
      setAnswers(initNewItems);
    }
  };

  useEffect(() => {
    if (settingQuestion.canEnterAnswer === undefined) {
      settingQuestion.canEnterAnswer = true;
    }
    setCurrentQuestionInfo({ ...answers, questionName: questionName });
  }, [answers, questionName]);


  return (
    <section className="question-wrapper">
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={questionName} onChange={handleChangeQuestionName} />
      <div className="tree-course-construct list-answers">
        <SortableList
          items={answers}
          onChange={handleDragChange}
          renderItem={(record: any) => (
            <SortableList.Item id={record.id}>
              <SortableList.DragHandle />
              <QuestionMultiVideos
                data={record}
                handelUpdateAnswer={handelUpdateAnswer}
              />
            </SortableList.Item>
          )}
        />
        <Button
          className="btn-add-more mt-2"
          type="default"
          icon={<PlusOutlined />}
          onClick={handleAddMoreAnswer}
        >
          Thêm lựa chọn
        </Button>
      </div>
    </section>
  );
};

export default ChooseMultiVideos;
