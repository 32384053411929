// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-managment-plan-construction {
  width: 100%;
  height: 100%; }
  .course-managment-plan-construction .read-only .form-detail {
    pointer-events: none; }
  .course-managment-plan-construction .ant-tree-treenode:hover .ant-tree-node-content-wrapper {
    background: none; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-course-construction-plan/detail-course-plan/detail.scss"],"names":[],"mappings":"AAAA;EACE,WAAY;EACZ,YAAY,EAAA;EAFd;IAKI,oBAAoB,EAAA;EALxB;IASI,gBAAgB,EAAA","sourcesContent":[".course-managment-plan-construction {\n  width : 100%;\n  height: 100%;\n\n  .read-only .form-detail {\n    pointer-events: none;\n  }\n\n  .ant-tree-treenode:hover .ant-tree-node-content-wrapper {\n    background: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
