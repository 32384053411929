import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getCourseManagementList: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/course-student-register/get-course-list`, payload);
};

export const getCourseStudentList: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/course-student-register/get-by-course`, payload);
};

export const getEnrollmentRateStatistics: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfigV2.get(`/course-student-register/get-ratio-by-registration-form/${id}`);
};

export const getStudyStatusRateStatistics: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfigV2.get(`/course-student-register/get-ratio-by-learning-status/${id}`);
};

export const getStatisticByWeek: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfigV2.get(`/course-student-register/get-statistic-by-week/${id}`);
};

export const getStatisticByMonth: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfigV2.get(`/course-student-register/get-statistic-by-month/${id}`);
};

export const getStatisticByYear: (
  id: number
) => Promise<AxiosResponse<any>> = (id: number) => {
  return axiosConfigV2.get(`/course-student-register/get-statistic-by-year/${id}`);
};
export const getStatisticByDate: (
  params: any
) => Promise<AxiosResponse<any>> = (params: any) => {
  return axiosConfigV2.get(`/course-student-register/get-statistic-by-date/${params}`);
};

export const getTranscriptList: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/course/scoreboard/get-scoreboard`, payload);
};

export const getQuizByCourse: (
  id: any
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-course-unit/get-quiz-by-course/${id}`);
};

export const getTranscriptStatistic: (
  params: any
) => Promise<AxiosResponse<any>> = (params: any) => {
  return axiosConfigV2.get(`/manage-result/get-statistic-result/${params}`);
};

export const getGradeRatio: (
  id: any
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/manage-result/get-grade-ratio/${id}`);
};

//export
//Xuất dữ liệu excel thống kê đếm số lượng học viên ghi danh

export const exportExcelCount: (params: any) => Promise<
  AxiosResponse<any>
> = (params) => {
  return axiosConfigV2.get(`/course-student-register/export-excel-count/${params}`,
    {
      responseType: "arraybuffer",
    }
  );
};

//Xuất dữ liệu excel thống kê tỉ lệ hình thức ghi danh
export const exportExcelRegistrationForm: (id: number) => Promise<
  AxiosResponse<any>
> = (id) => {
  return axiosConfigV2.get(`/course-student-register/export-excel-registration-form/${id}`,
    {
      responseType: "arraybuffer",
    }
  );
};
//Xuất dữ liệu excel thống kê tỉ lệ trạng thái học tập
export const exportExcelLearningStatus: (id: number) => Promise<
  AxiosResponse<any>
> = (id) => {
  return axiosConfigV2.get(`/course-student-register/export-excel-learning-status/${id}`,
    {
      responseType: "arraybuffer",
    }
  );
};
//Xuất excel thống kê tỉ lệ điểm
export const exportExcelGradeRatio: (id: number) => Promise<
  AxiosResponse<any>
> = (id) => {
  return axiosConfigV2.get(`/manage-result/export-excel-grade-ratio/${id}`,
    {
      responseType: "arraybuffer",
    }
  );
};
//Xuất excel data biểu đồ điểm
export const exportExcelGradeCount: (params: any) => Promise<
  AxiosResponse<any>
> = (params) => {
  return axiosConfigV2.get(`/manage-result/export-excel-grade-count/${params}`,
    {
      responseType: "arraybuffer",
    }
  );
};