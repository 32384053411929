import React, { FunctionComponent } from "react";
import { Button, Col, Flex, Form, Row, Select, Typography } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import "./filter-group.scss";
import { get } from "lodash";
import { FormInstance } from "antd/lib";
import { useForm } from "antd/es/form/Form";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { dataStatus } from "../../../../list-ministries/constants/data";
import FormItemInput from "../../../../../components/form-input/FormInput";

type FormFilterProps = {
  showFilter?: boolean;
  clearFilter: (form: FormInstance) => void;
  onFinish?: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  showFilter,
  clearFilter,
  onFinish,
}) => {
  const [form] = useForm();
  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""} !pl-0`}>
        <Form
          name="formFilterMinistries"
          onFinish={onFinish}
          form={form}
          className="flex-wrap flex"
        >
          <Flex
            className="w-full lg:w-4/5 box-border"
            justify="space-between"
            wrap="wrap"
          >
            <Form.Item
              name="course_code"
              className="w-full sm:w-1/2 md:w-1/3 sm:pr-3 py-2 lg:py-0"
            >
              <FormItemInput placeholder="Mã khóa học" />
            </Form.Item>

            <Form.Item
              name="username"
              className="w-full sm:w-1/2 md:w-1/3 sm:pr-3 py-2 lg:py-0"
            >
              <FormItemInput placeholder="Người tạo" />
            </Form.Item>
            <Form.Item
              name="is_active"
              className="w-full sm:w-1/2 md:w-1/3 sm:pr-3 py-2 lg:py-0"
            >
              <Select
                placeholder="Chọn trạng thái"
                defaultValue='all'
                options={[
                  {
                    value: 'all',
                    label: "Tất cả",
                  },
                  ...dataStatus,
                ]}
              />
            </Form.Item>
          </Flex>

          <Flex className="w-full lg:w-1/5">
            <Button
              className="btn btn-primary filter-btn mr-2 lg:mr-0"
              htmlType="submit"
            >
              <FilterOutlined />
              <Typography.Text>Lọc</Typography.Text>
            </Button>
            <Button
              className="btn btn-outlined clear-filter-btn"
              onClick={() => clearFilter(form)}
            >
              <DeleteOutlined />
              <Typography.Text>Xóa bộ lọc</Typography.Text>
            </Button>
          </Flex>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
