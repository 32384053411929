import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  message,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import {
  CloseOutlined,
  CloudSyncOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SendOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import PageHeader from "../../../../components/page-header/PageHeader";
import { filter, isEmpty, set } from "lodash";
import DrawerCustom from "../../../../components/drawer/Drawer";
import TableData from "../../../../components/table-data/TableData";
import { FormCreateMoocCourseConstructionPlan } from "./components/form-create";
import { DetailTypeAuthUser } from "../../../../types/mooc-share-all";
import { DetailTypeMoocUniversity } from "../../../../types/mooc-share-all";
import {
  DetailTypeMoocCourseConstructionPlan,
  DataTypeMoocCourseConstructionPlan,
  FilterDataMoocCourseConstructionPlan,
} from "../../../../types/course-plan-managment/mooc-course-construction-plan";
//import { ItemType } from "antd/es/menu/hooks/useItems";
import FormItemDropdown from "../../../../components/form-dropdown/FormItemDropdown";
import dayjs from "dayjs";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import {
  postPlanningCourseConstructionSearch,
  getLookupMoocUniversity,
  getLookupAuthUser,
  putUpdateMoocCourseConstructionPlan,
  deleteMoocCourseConstructionPlan,
  createIndustry,
  createIndustryGroup,
  getMoocCourseConstructionPlanById,
  postCreatePlanningCourseConstruction,
  deleteStructurePlanningCourseConstruction,
  revokeCourseConstructPlan,
} from "../../../../service/course-plan-managment/planning-course-construction/PlanningCourseConstructionApi";
import {
  CoursePlanStatus,
  StatusEum,
} from "../../mooc-course-construction-plan/list-course-plan/constants/data";
import { ApprovalStatusEnum } from "../../mooc-course-construction-plan/list-course-plan/constants/data";
type ListPlanningCourseConstructionProps = {
  title?: string;
};
import ApprovePlanCouresConstruction from "./components/approve-coures-constuction/approveCouresConstruction";
import { revokeSurvey } from "../../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";
import { useAuthStore } from "../../../../stores/stores";
import { getApprovalConfiguration } from "../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import TabPane from "antd/es/tabs/TabPane";
import { revokeCourseAssignment } from "../../../../service/course-plan-managment/mooc-course-planning-assignment";
import moment from "moment";
const PlanningCourseConstructionList = (
  props: ListPlanningCourseConstructionProps
) => {
  // common state variables, no change
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenBrowseModal, setIsOpenBrowseModal] = useState<boolean>(false);
  const [isOpenSyncModal, setIsOpenSyncModal] = useState<boolean>(false);
  const [syncCheckbox, setSyncCheckbox] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [idDetail, setIdDetail] = useState(0);
  const [pageDeleteRevoke, setPageDeleteRevoke] = useState<number>(1);
  const [isShow, setIsShow] = useState<boolean>(false)
  // common functions
  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const [modeView, setModeView] = useState("");
  const idField = "id";
  const nameField = "id";
  const [approval, setApproval] = useState<any[]>([]);

  const [isOpenModalApprove, setIsOpenModalApprove] = useState<boolean>(false);
  const [listMoocCourseConstructionPlan, setListMoocCourseConstructionPlan] =
    useState<DataTypeMoocCourseConstructionPlan[]>([]);
  const [browseData, setBrowseData] =
    useState<DataTypeMoocCourseConstructionPlan>();
  const [detailData, setDetailData] = useState<any>();
  const [filterData, setFilterData] =
    useState<FilterDataMoocCourseConstructionPlan>({});
  const [openModel, setOpenModel] = useState(false);
  // forgein key function call apis
  const [lstRegistrationSubmitterId, setLstRegistrationSubmitterId] = useState<
    DetailTypeAuthUser[]
  >([]);
  const [lstUniversityId, setLstUniversityId] = useState<
    DetailTypeMoocUniversity[]
  >([]);

  const [dataIndustry, setDataIndustry] = useState<any[]>([]);
  const [dataIndustryGroup, setDataIndustryGroup] = useState<any[]>([]);
  const userId = useAuthStore((states) => states.user?.id);
  let levelRevoked: number;
  const getListLstRegistrationSubmitterId = async () => {
    try {
      setLoading(true);
      await getLookupAuthUser()
        .then((res) => {
          const { data } = res.data;
          setLstRegistrationSubmitterId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return item;
            })
          );
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListLstUniversityId = async () => {
    try {
      setLoading(true);
      await getLookupMoocUniversity()
        .then((res) => {
          const { data } = res.data;
          setLstUniversityId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return item;
            })
          );
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  useEffect(() => {
    getListLstRegistrationSubmitterId();
    getListLstUniversityId();
    getApprovalConfigurationList();
  }, []);
  const handleReturnStatus = (record: any) => {
    switch (record.constructionPlanStatus) {
      case StatusEum.SAVE_DRAFT:
        return "Lưu bản nháp";
      case StatusEum.WAIT_APPROVE:
        if (record.approvalStatus && record.approvalLevelName) {
          switch (record.approvalStatus) {
            case ApprovalStatusEnum.WAIT_APPROVE:
              return `Chờ ${record.approvalLevelName} duyệt`;
            case ApprovalStatusEnum.APPROVED:
              return `${record.approvalLevelName} đã duyệt`;
            case ApprovalStatusEnum.REFUSE:
              return `${record.approvalLevelName} từ chối`;
            default:
              return "Chờ duyệt";
          }
        }
        return "Chờ duyệt";
      case StatusEum.APPROVED:
        return "Hội đồng thẩm định đã duyệt";
      case StatusEum.REFUSE:
        return "Quản trị khóa học từ chối";

      default:
        return "";
    }
  };
  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự triển khai";
      case 2:
        return "Hợp tác xây dựng";
      case 3:
        return "Dùng chung";
      default:
        return "";
    }
  };
  const closeApproveModal = () => {
    setIsOpenModalApprove(false);
    getListData();
  };
  const getApprovalConfigurationList = async () => {
    const data = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      approvalType: 3,
    };
    await getApprovalConfiguration(data).then((res: any) => {
      const approvalData = res.data.data.data;
      approvalData.map((i: any) => {
        if (i.hierarchyId == null) {
          levelRevoked = i.id;
        }
      });
      const modifiedApproval = approvalData
        .map((item: any) => ({
          id: `${item.id}-1`,
          name: `Chờ ${item.approvalLevelName} duyệt`,
        }))
        .concat(
          approvalData.map((item: any) => ({
            id: `${item.id}-2`,
            name: `${item.approvalLevelName} đã duyệt`,
          }))
        )
        .concat(
          approvalData.map((item: any) => ({
            id: `${item.id}-3`,
            name: `${item.approvalLevelName} từ chối`,
          }))
        );
      // .concat(
      //   approvalData.map((item: any) => ({
      //     id: `${item.id}-4`,
      //     name: `${item.approvalLevelName} đã thu hồi`,
      //   }))
      // );

      setApproval(modifiedApproval);
    });
  };
  const [dataOptions, setDataOptions] = useState<any>([]);
  useEffect(() => {
    setDataOptions([
      {
        title: "Ngày tạo",
        type: "CreateDatePicker",
        key: "createdDate",
        size: 5,
        data: [],
      },
      {
        title: "Ngày duyệt",
        type: "ApprovalDatePicker",
        key: "approvalDate",
        size: 5,
        data: [],
      },
      {
        title: "Trạng thái",
        type: "FormItemDropdownStatus",
        key: "constructionPlanStatus",
        size: 5,
        data: [
          {
            id: 1,
            name: "Lưu bản nháp",
          },
          ...approval.map((item) => ({
            id: item.id,
            name: item.name,
          })),
        ],
      },
      {
        title: "Loại khóa học",
        type: "FormItemDropdownType",
        key: "courseType",
        size: 4,
        data: [
          {
            id: 1,
            name: "Tự triển khai",
          },
          {
            id: 2,
            name: "Hợp tác xây dựng",
          },
          {
            id: 3,
            name: "Dùng chung",
          },
        ],
      },
    ]);
  }, [approval]);
  const revoke = (id: any) => {
    const data = {
      courseConstructionPlanId: id,
      submitterId: userId,
    };
    revokeCourseConstructPlan(data).then((res) => {
      const { statusCode } = res.data;
      if (statusCode === 200) {
        message.success("Yêu cầu thu hồi duyệt kế hoạch khoá học thành công");
        getListData();
      }
    });
  };
  const formatDateV2 = (date: string) => {
    const [year, month, day] = date.split("T")[0].split("-");
    return `${day}/${month}/${year}`;
  };
  const handleOptionMenu = (record: any): MenuProps["items"] => {
    let optionsDropdownMenu: MenuProps["items"] = [
      {
        key: "edit",
        label: "Sửa kế hoạch",
        icon: <EditOutlined />,
      },
      {
        key: "sendOrRedeem",
        label: "Gửi duyệt kế hoạch",
        icon: <SendOutlined />,
      },
      {
        key: "history",
        label: "Lịch sử hoạt động",
        icon: <HistoryOutlined />,
      },
      {
        key: "historyapproval",
        label: "Lịch sử duyệt",
        icon: <HistoryOutlined />,
      },
      {
        key: "assignmenthistory",
        label: "Lịch sử phân công",
        icon: <HistoryOutlined />,
      },
      {
        key: "revoke",
        label: "Thu hồi gửi duyệt",
        icon: <UndoOutlined />,
      },
      {
        key: "delete",
        label: "Xóa kế hoạch",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
      {
        key: "deleteStructure",
        label: "Xóa cấu trúc khóa học",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
    ];
    if (
      record.moocApproval.constructionPlanStatus ===
      ApprovalStatusEnum.WAIT_APPROVE
    ) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "sendOrRedeem" && option?.key !== "edit"
      );
    }

    if (
      record.moocApproval.constructionPlanStatus === ApprovalStatusEnum.APPROVED
    ) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) =>
          option?.key === "history" ||
          option?.key === "assignmenthistory" ||
          option?.key === "historyapproval"
      );
    }
    if (
      record.moocApproval.constructionPlanStatus ===
      ApprovalStatusEnum.REFUSE &&
      record.approvalLevelName !== "Quản trị khóa học"
    ) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "edit" && option?.key !== "sendOrRedeem"
      );
    }
    ////////////////////////////////////////////////////////////////////////////
    // if (record.constructionPlanStatus !== CoursePlanStatus.SAVE_DRAFT) {
    //   optionsDropdownMenu = optionsDropdownMenu.filter(
    //     (option) => {
    //       if (option?.key === 'edit' || option?.key === 'delete' || option?.key === 'deleteStructure') {
    //         return false
    //       }
    //       return true
    //     }
    //   );
    // }
    if (record.constructionPlanStatus === CoursePlanStatus.SAVE_DRAFT) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "revoke"
      );
    }
    if (record.constructionPlanStatus !== CoursePlanStatus.SAVE_DRAFT) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "delete"
      );
    }
    if (record.constructionPlanStatus !== CoursePlanStatus.SAVE_DRAFT) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "deleteStructure"
      );
    }
    // if (record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE) {
    //   optionsDropdownMenu = optionsDropdownMenu.filter(
    //     (option) => option?.key !== "sendOrRedeem" && option?.key !== 'edit'
    //   );
    // }
    // if (record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE && record.approvalStatus === null) {
    //   optionsDropdownMenu = optionsDropdownMenu.filter(
    //     (option) => option?.key !== "sendOrRedeem" && option?.key !== 'edit'
    //   );
    // }
    if (record.constructionPlanStatus === CoursePlanStatus.APPROVED) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) =>
          option?.key === "history" ||
          option?.key === "assignmenthistory" ||
          option?.key === "historyapproval"
      );
    }
    // // if(record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE && )
    if (
      (levelRevoked &&
        record.moocApproval.approvalConfigurationId != levelRevoked) ||
      record.approvalStatus == ApprovalStatusEnum.APPROVED
    ) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "revoke"
      );
    }

    if (record.hierarchyId != null) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "revoke"
      );
    }
    return optionsDropdownMenu;
  };
  const validations = {
    user: {
      required: true,
      message: "Nhập tên cán bộ duyệt đăng ký",
      validator: (_: unknown, value: string) => {
        value = "x";
        if (!value) {
          return Promise.reject(new Error("Nhập tên cán bộ duyệt đăng ký"));
        }
        return Promise.resolve();
      },
    },
  };
  const dataColumns: TableColumnsType<DataTypeMoocCourseConstructionPlan> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record: any, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên khóa học",
      dataIndex: "registration_name",
      key: "registration_name",
      width: 14,
      sorter: true,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record.registrationName}</span>
              <span>{record.hostUniveristyName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "constructionPlanStatus",
      key: "constructionPlanStatus",
      width: 14,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.constructionPlanStatus === 1
                    ? "#D9D9D9"
                    : record.constructionPlanStatus === 2
                      ? "#FAAD14"
                      : record.constructionPlanStatus === 3
                        ? "#52C41A"
                        : record.constructionPlanStatus === 4
                          ? "#FF4D4F"
                          : "white",
              }}
            ></div>
            {handleReturnStatus(record)}
          </div>
        );
      },
    },
    {
      title: "Loại khóa học",
      dataIndex: "courseType",
      key: "courseType",
      width: 11,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            {record.courseType === 2 ? (
              <div className="table-course-type-2">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : record.courseType === 1 ? (
              <div className="table-course-type-1">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : record.courseType == 3 ? (
              <div className="table-course-type-3">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 10,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <>
            {record.createdDate
              ? formatDateV2(record.createdDate.toString())
              : null}
          </>
        );
      },
    },
    {
      title: "Ngày duyệt",
      dataIndex: "moocApproval",
      key: "moocApproval",
      width: 10,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <>
            {record.moocApproval != null &&
              record.moocApproval.approvalDate != null
              ? formatDateV2(record.moocApproval.approvalDate.toString())
              : ""}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: "7%",
      fixed: "right",
      render: (_, record) => {
        const location = {
          id: `${record[idField]}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.planningCourseConstructionDetail}/${location.id}`,
                    {
                      state: { record, mode: "view", numberTab: "1" },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: handleOptionMenu(record),
                  onClick: ({ key }) => {
                    // handleOptionMenu(1);
                    if (key === "edit") {
                      setModeView("edit");
                      setIdDetail(record[idField]);
                      setIsOpened(true);
                      getDetailData(record[idField]);
                    }
                    if (key === "sync") {
                      setIsOpenSyncModal(true);
                      setBrowseData(record);
                    }
                    if (key === "historyapproval") {
                      navigate(
                        `/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.planningCourseConstructionDetail}/${location.id}`,
                        {
                          state: { record, mode: "view", numberTab: "4" },
                        }
                      );
                    }
                    if (key === "assignmenthistory") {
                      navigate(
                        `/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.planningAssignmentHistory}/${record.registrationStageId}`,
                        {
                          state: record,
                        }
                      );
                    }
                    if (key === "publish") {
                      setIsOpenBrowseModal(true);
                      setBrowseData(record);
                    }
                    if (key === "sendOrRedeem") {
                      setIsOpenModalApprove(true);
                      setBrowseData(record);
                    }
                    if (key === "revoke") {
                      Modal.confirm({
                        title: `Thu hồi gửi duyệt kế hoạch khoá học ${record.registrationName}`,
                        content: `Bạn có chắc chắn muốn thu hồi gửi duyệt kế hoạch khoá học ${record.registrationName}?`,
                        onOk: () => revoke(record.id),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "history") {
                      navigate(
                        `${routesConfig.ApproveCouresConsrtuctionHistory}/${record.id}`,
                        {
                          state: record,
                        }
                      );
                    }
                    if (key === "delete") {
                      Modal.confirm({
                        title: `Xoá ${record.registrationName}`,
                        content: (
                          <div>
                            <div>Kế hoạch đã xóa sẽ không thể khôi phục.</div>
                            <div>Bạn có chắc muốn xóa kế hoạch khóa học?</div>
                          </div>
                        ),
                        onOk: () => {
                          deleteData(record);
                        },
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "deleteStructure") {
                      Modal.confirm({
                        title: `Xoá cấu trúc khóa học ${record.registrationName}`,
                        content: (
                          <div>
                            <div>
                              Cấu trúc khóa học đã xóa sẽ không thể khôi phục.
                            </div>
                            <div>Bạn có chắc muốn xóa cấu trúc khóa học?</div>
                          </div>
                        ),
                        onOk: () => deleteStructure(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  // function call apis, process data
  const getListData = () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      registrationName: !isEmpty(filterData.registrationName)
        ? filterData.registrationName
        : null,
      constructionPlanStatus: !isEmpty(filterData.constructionPlanStatus)
        ? filterData.constructionPlanStatus
        : null,
      courseType: !isEmpty(filterData.courseType)
        ? filterData.courseType
        : null,
      createdDate: !isEmpty(filterData.createdDate)
        ? filterData.createdDate
        : null,
      approvalDate: !isEmpty(filterData.approvalDate)
        ? filterData.approvalDate
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };
    postPlanningCourseConstructionSearch(data)
      .then((res) => {
        const { data } = res.data;
        setListMoocCourseConstructionPlan(data.data);
        setTotalRecords(data.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
  };

  const getDetailData = async (id: number) => {
    setLoading(true);
    try {
      const response = await getMoocCourseConstructionPlanById(
        (Number(id) || 0).toString()
      )
        .then((res) => {
          const { data } = res.data;
          setDetailData({
            id: data?.id,
            registrationCode: data?.registration_code,
            registrationName: data?.registration_name,
            courseStart: dayjs(data?.course_start),
            courseDuration: data?.course_duration || 0,
            constructionPlanStatus: data?.construction_plan_status,
            courseType: data?.course_type,
            registrationSubmissionDate: dayjs(
              data?.registration_submission_date
            ),
            registrationSubmitterId: data?.registration_submitter_id,
            universityId: data?.university_id,
            registrationStageId: data?.registration_stage_id,
            createdDate: dayjs(data?.created_date),
            updatedDate: dayjs(data?.updated_date),
            isDeleted: data?.is_deleted,
            moocApproval: data?.moocApproval,
            moocConstructionPlanCollaborator:
              data?.mooc_construction_plan_collaborator,
            moocConstructionPlanIndustry: data?.mooc_construction_plan_industry,
            moocConstructionPlanIndustryGroup:
              data?.mooc_construction_plan_industry_group,
          });
          setTimeout(() => {
            setLoading(false);
            setIsOpened(true);
          }, 500);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const deleteData = async (record: any) => {
    try {
      console.log("3", pageNumber);
      if (
        record.constructionPlanStatus === 2 ||
        record.constructionPlanStatus === 3
      ) {
        message.error(
          `Không thể xóa kế hoạch ${handleReturnStatus(
            record.constructionPlanStatus
          )}`
        );
      } else {
        await deleteMoocCourseConstructionPlan(record.id)
          .then((res) => {
            // debugger;
            if (res.data.statusCode === 200) {
              message.success("Kế hoạch khóa học đã được xóa trên hệ thống");
              getListData();
            }
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi xảy ra");
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi xảy ra");
    }
  };

  const deleteStructure = async (record: any) => {
    try {
      if (
        record.constructionPlanStatus === 2 ||
        record.constructionPlanStatus === 3
      ) {
        message.error(
          `Không thể xóa cấu trúc kế hoạch ${handleReturnStatus(
            record.constructionPlanStatus
          )}`
        );
      } else {
        setLoading(true);
        await deleteStructurePlanningCourseConstruction(record.id)
          .then((res) => {
            // debugger;
            //if (res.data.statusCode === 200) {
            message.success("Cấu trúc khóa học đã được xóa trên hệ thống");
            getListData();
            setLoading(false);
            //}
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            message.error("Có lỗi xảy ra");
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi xảy ra");
    }
  };

  const copyData = async (record: any) => {
    alert("copyData");
  };

  const revokeData = async (record: any) => {
    try {
      if (record.constructionPlanStatus === 3) {
        record.constructionPlanStatus = 1;
        const data = record;
        const response = await putUpdateMoocCourseConstructionPlan(
          record.id,
          data
        )
          .then((result) => {
            message.success(
              `Yêu cầu thu hồi duyệt đăng ký kế hoạch được gửi thành công`
            );
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            message.error("Yêu cầu thu hồi duyệt đăng ký kế hoạch thất bại");
          });
      } else {
        record.constructionPlanStatus = 3;
        const data = record;
        const response = await putUpdateMoocCourseConstructionPlan(
          record.id,
          data
        )
          .then((result) => {
            message.success(
              `Yêu cầu duyệt đăng ký kế hoạch được gửi thành công`
            );
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            message.error("Yêu cầu duyệt đăng ký kế hoạch được gửi thất bại");
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const handleCreate = async (value: any) => {
    try {
      setLoading(true);
      const data = value;
      const response = await postCreatePlanningCourseConstruction(data)
        .then(async (result) => {
          setLoading(false);
          if (result.data.data.id !== undefined) {
            message.success(
              "Kế hoạch xây dựng khoá học đã được thêm thành công"
            );
            navigate(
              `/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.planningCourseConstructionDetail}/${result.data.data.id}`,
              {
                state: { mode: "edit", numberTab: "2" },
              }
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const handleEdit = async (value: any) => {
    try {
      const data = value;
      const response = await putUpdateMoocCourseConstructionPlan(idDetail, data)
        .then((result) => {
          message.success(`Sửa Kế hoạch xây dựng khoá học thành công`);
          navigate(
            `/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.planningCourseConstructionDetail}/${idDetail}`,
            {
              state: { mode: "edit", numberTab: "2" },
            }
          );
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          // message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      // message.error("Có lỗi chưa xác định");
    }
  };

  const clearFilter = () => {
    setLoading(true);
    form.resetFields();
    setFilterData({
      ...filterData,
      createdDate: null,
      approvalDate: null,
      courseType: null,
      constructionPlanStatus: null,
    });
    postPlanningCourseConstructionSearch({})
      .then((res) => {
        const { data } = res.data;
        setListMoocCourseConstructionPlan(data.data);
        setTotalRecords(data.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
  };

  const getNumericalNumber = (index: number, page: number, size: number) => {
    return (page - 1) * size + index + 1;
  };

  const randomCode = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16).replace("-", "");
      }
    );
  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
    setIsShow((isShow) => !isShow);

  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    setPageSize(10);

    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      setPageSize(10);
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleOpenCreate = () => {
    setIdDetail(0);
    form.resetFields();
    setModeView("create");
    setIsOpened(true);
  };

  const handleSave = (value: any) => {
    if (idDetail && idDetail !== 0) {
      handleEdit(value);
    } else {
      handleCreate(value);
    }
  };

  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider
        onFormFinish={(formName, { values, forms }) => {
          if (formName === "create-edit-mooc-course-construction-plan-detail") {
            handleSave(values);
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} breadcrumb={true} />
            </div>
            <FilterTable
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
              showFilter={dataOptions != null && dataOptions.length > 0}
              isShow={isShow}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            onFinish={handleSubmitSearch}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
          />
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listMoocCourseConstructionPlan}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            searchValue={searchValue}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <FormCreateMoocCourseConstructionPlan
          // truyen danh sach cac bien state list
          dataOptions={dataOptions}
          id={idDetail}
          detailData={detailData}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
          modeOpen={modeView}
        //setDetailData={setDetailData}
        //setDataUpdate={setDataUpdate}
        //setDataSponsorUpdate={setDataSponsorUpdate}
        />
        <ApprovePlanCouresConstruction
          open={isOpenModalApprove}
          handleCancel={() => {
            closeApproveModal();
          }}
          browseData={browseData}
        />
      </Form.Provider>
    </Layout>
  );
};

export default PlanningCourseConstructionList;
