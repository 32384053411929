import React from "react";
import { Checkbox } from "antd";

function ChooseMultiText({ data }: any) {
  return (
    <div>
      {data?.questions &&
        data?.questions?.length > 0 &&
        data?.questions?.map((quiz: any, index: any) => (
          <div
            key={quiz?.uuid + index}
            className={`mb-12 ${quiz?.isTrue && "choose-correct"}`}
          >
            <Checkbox disabled={true} checked={quiz?.isTrue}>
              <span
                className={`font-size-16 line-height-24 font-weight-5 ${
                  quiz?.isTrue && "text-result"
                }`}
              >
                {quiz?.content}
              </span>
            </Checkbox>
          </div>
        ))}
    </div>
  );
}

export default ChooseMultiText;
