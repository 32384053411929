export const nameCategoryBlocks = 'Khối giao diện';
export const nameCategoryTemplates = 'Mẫu hoàn chỉnh';
export const nameContactBlock = 'Liên hệ';
export const nameLecturersBlock = 'Giảng viên';
export const nameCountBlocks = 'Thống kê mẫu';
export const nameRoadMapBlocks = 'Lộ trình khóa học';
export const nameHeaderBlocks = 'Tiêu đề';
export const nameRegisterBlocks = 'Ghi danh';
export const nameRegisterPolicyBlocks = 'Chính sách ghi danh';
export const nameConditionBlocks = 'Điều kiện về kỹ năng và kiến thức của khóa học';
export const nameAffiliatedBlocks = 'Liên kết';
export const nameOpinionBlocks = 'Ý kiến nhận xét';
export const nameQuestionBlocks = 'Câu hỏi';
export const nameBasicBlock = 'Tiện ích';

export const countBlockGenerete = (dataReview?: any, dataStudent?: any) => {
  const blockUI = {
    id: 'statistical-block-id',
    options: {
      category: nameCountBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'CountWrapperComponent',
        },
        components: `
        <div component-class='CountComponent' class="text-gray-600 body-font bg-purple-700">
          <div class="container px-5 mx-auto">
            <div class="flex flex-wrap">
              <div class="xl:w-1/4 lg:w-1/2 px-8 py-6 border-gray-200 border-opacity-60 flex">
              <img data-gjs-type="image" draggable="true" src="https://s3.moooc.xyz/dev-stable/page-builder/cc48746b-1a10-403f-8884-40e7c212590cplay-circle.svg" class="mr-2 gjs-selected">
              <p data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="leading-relaxed text-base text-white">${dataReview?.count} giờ video theo yêu cầu</p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 px-8 py-6 border-gray-200 border-opacity-60 flex">
              <img data-gjs-type="image" draggable="true" src="https://s3.moooc.xyz/dev-stable/page-builder/1844bf06-85c1-4587-b330-4919ae963cacdownload-01.svg" class="mr-2">
              <p data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="leading-relaxed text-base text-white">107 nguồn tài nguyên có thể tải xuống</p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 px-8 py-6 border-gray-200 border-opacity-60 flex">
              <img data-gjs-type="image" draggable="true" src="https://s3.moooc.xyz/dev-stable/page-builder/f25aac1c-e691-4157-9355-7f5045c6755fuser-circle.svg" class="mr-2">
              <p data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="leading-relaxed text-base text-white">${dataStudent?.count} học viên đã đăng ký</p>
            </div>
            <div class="xl:w-1/4 lg:w-1/2 px-8 py-6 border-gray-200 border-opacity-60 flex">
              <img data-gjs-type="image" draggable="true" src="https://s3.moooc.xyz/dev-stable/page-builder/83bfc8b9-48f7-412b-a2c7-6885992c152eStar%20icon.svg" class="mr-2">
              <span data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-white">${dataReview?.rate === null ? 0 : dataReview?.rate} (58 bình luận)</span>
            </div>
          </div>
        </section>
        `,
      }
    },
  }
  return blockUI;
}

export const roadMapBlockGenerete = (data?: any) => {
  const blockUI = {
    id: 'roadmap-course-block-id',
    options: {
      category: nameRoadMapBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RoadMapWrapperComponent',
        },
        components: '',
      }
    }
  }
  blockUI.options.content.components = `
    <div component-class='RoadMapComponent' data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="text-gray-600 body-font gjs-selected">
    <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="flex justify-center my-8">
    <div data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-3xl font-medium text-purple-700">Lộ trình khoá học</div>
    </div>
    <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="container mx-auto p-4">
    <div data-gjs-highlightable="true" title="roadmap" data-gjs-title="roadmap" data-gjs-type="default" draggable="true" class="grid grid-cols-1 md:grid-cols-2 gap-8">
  `
  data.forEach((chapter: any) => {
    blockUI.options.content.components += `
      <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" name="section-unit" class="bg-white rounded-lg p-4 border-2">
      <h2 data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-xl font-bold text-purple-700 mb-4">${chapter.title}</h2>
      <ul data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="list-none p-0">
    `
    chapter.children.forEach((lecture: any) => {
      blockUI.options.content.components += `
        <li data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="mb-4 flex items-center">
          <a data-gjs-highlightable="true" data-gjs-type="link" draggable="true" href="#">
            <img data-gjs-type="image" draggable="true" src="https://via.placeholder.com/200x120" alt="Thumbnail bài giảng" class="w-40 rounded-md">
          </a>
          <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="ml-4">
            <h3 data-gjs-highlightable="true" title="roadmap-lesson" data-gjs-type="text" draggable="true" name='title-lesson' class="text-lg font-medium">${lecture.title}</h3>
            <p data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-gray-700">${lecture.description ? lecture.description : "Chưa có mô tả"}</p>
            <span data-gjs-highlightable="true" data-gjs-type="text" draggable="true" class="text-sm text-gray-300">10 phút</span>
          </div>
        </li>
      `
    })
    blockUI.options.content.components += `</ul></div>`
  });
  blockUI.options.content.components += `
    </div>
    </div>
    <div data-gjs-highlightable="true" data-gjs-type="default" draggable="true" class="flex justify-center my-8">
    <button data-gjs-highlightable="true" data-gjs-type="text" draggable="true" type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl">Xem thêm</button>
    </div>
    </div>
  `
  return blockUI;
}

export const contactBlockCustom = (data?: any) => {
  const blockUI = {
    id: 'contact-block-id',
    options: {
      category: nameContactBlock,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'ContactWrapperComponent',
        },
        components: `
          <div component-class="ContactComponent" class="container mx-auto py-5 w-full flex">
            <iframe
              frameborder="0"
              width="100%"
              height="100%"
              title="map"
              marginheight="0"
              marginwidth="0"
              scrolling="no"
              src="https://maps.google.com/maps?&q=6 P. Lê Văn Thiêm, Thanh Xuân Trung, Thanh Xuân, Hà Nội&z=14&t=q&output=embed"
              class="lg:w-2/3 md:w-1/2 sm:mr-5 h-auto"
            ></iframe>
            <div class="lg:w-1/3 md:w-1/2 flex flex-col">
              <form>
                <h1 class="text-gray-900 text-lg mb-1 font-medium title-font">Liên hệ với chúng tôi</h1>
                <p class="leading-relaxed mb-5 text-gray-600">Mọi góp ý của bạn giúp chúng tôi hoàn thiện khoá học hơn</p>
                <div class="relative mb-4">
                  <label for="Name" title="Tên" class="leading-7 text-sm text-gray-600">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div class="relative mb-4">
                  <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                  />
                </div>
                <div class="relative mb-4">
                  <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                  <textarea
                    id="message"
                    name="message"
                    required
                    class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                  ></textarea>
                </div>
                <button type="submit" class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Gửi</button>
              </form>
            </div>
          </div>
        `,
      },
    },
  }
  return blockUI;
};

export const headerBlockLogo = (data?: any) => {
  const blockUI = {
    id: 'header-block-logo',
    options: {
      category: nameHeaderBlocks,
      label: 'Logo',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'HeaderWrapperComponent',
        },
        components: `
          <header component-class="HeaderComponent" class="text-gray-600 body-font shadow-md">
            <div class="container mx-auto flex flex-wrap flex-col md:flex-row items-center p-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/5771d778-a594-4c68-b654-c2022f77db47FTU_logo_2020.png" class="w-10 h-10 mx-auto ml-0"/>
              <div class="ml-0 text-xl">Get new skills risk-free.
              </div>
            </div>
          </header>
        `,
      },
    },
  }
  return blockUI;
}

export const headerBlockName = (data?: any) => {
  const blockUI = {
    id: 'header-block-name',
    options: {
      category: nameHeaderBlocks,
      label: 'Tên',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'HeaderWrapperComponent',
        },
        components: `
          <header component-class="HeaderComponent" class="text-gray-600 body-font shadow-md">
            <div class="container mx-auto flex flex-wrap flex-col md:flex-row items-center p-4">
              <div class="ml-0 text-xl mx-auto text-bold font-bold text-red-800">Logo Trường Đại học Ngoại Thương</div>
              <div class="ml-0 text-xl">Get new skills risk-free.
              </div>
            </div>
          </header>
        `,
      },
    },
  }
  return blockUI;
}

export const registerBlock1 = (data?: any) => {
  const blockUI = {
    id: 'register-block-1-id',
    options: {
      category: nameRegisterBlocks,
      label: 'Giao diện 1',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RegisterWrapperComponent',
        },
        components: `
          <div component-class="RegisterComponent" class="text-gray-600 body-font">
            <div class="container px-5 mx-auto flex flex-wrap py-8">
              <div class="flex flex-col flex-wrap lg:w-1/2 lg:text-left  border-2 mr-auto rounded-lg lg:pt-12 lg:pl-12 lg:pr-12">
                <div class="flex flex-col mb-10 lg:items-start items-center">
                  <div class="flex-grow w-full">
                    <h2 class="text-black title-font font-medium text-2xl mb-4">What you learn
                    </h2>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will learn how to leverage the power of Python to solve tasks.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >You will build games and programs that use Python libraries.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will be able to use Python for your own work problems or personal projects.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will create a portfolio of Python based projects you can share.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >Learn to use Python professionally, learning both Python 2 and Python 3!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Create games with Python, like Tic Tac Toe and Blackjack!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn advanced Python features, like the collections module and how to work with timestamps!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn to use Object Oriented Programming with classes!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Understand complex topics, like decorators.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Understand how to use both the Jupyter Notebook and create .py files</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg"class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >Get an understanding of how to create GUIs in the Jupyter Notebook system!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Build a complete understanding of Python from the ground up!</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:w-1/3 shadow-lg">
                <div class="bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 lg:w-full">
                  <form>
                    <iframe allowfullscreen="allowfullscreen" src="https://www.youtube.com/embed/?" controls="" class="mb-2 leading-relaxed w-full h-full">
                    </iframe>
                    <p class="leading-relaxed text-gray-600 mb-4">Xem trước khoá học này
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span class="text-3xl">1.700.000</span>
                      </p>
                    </div>
                    <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl mb-8">Ghi danh ngay</button>
                    <p class="leading-relaxed text-gray-600 mb-4">Khoá học này bao gồm:
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/ae1cd3cd-020b-4b2a-b411-a69d0ea733f3play-circle.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>24 giờ video theo yêu cầu</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img  src="https://s3.moooc.xyz/dev-stable/page-builder/c9768dd1-6262-4763-954f-651113dd82efdownload-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>07 nguồn tài nguyên có thể tải xuống</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/a4204056-4ac0-4e3e-bda6-9694d2b6dd66hourglass-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Quyền truy cập đầy đủ vĩnh viễn</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/1daf4632-0a4b-44d2-b932-8d2baf12fd54certificate-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Chứng chỉ khi hoàn thành</span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        `,
      },
    },
  }

  return blockUI;
}

export const registerBlock2 = (data?: any) => {
  const blockUI = {
    id: 'register-block-2-id',
    options: {
      category: nameRegisterBlocks,
      label: 'Giao diện 2',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RegisterWrapperComponent',
        },
        components: `
          <div component-class="RegisterComponent" class="text-gray-600 body-font">
            <div class="container px-5 mx-auto flex flex-wrap py-8">
              <div class="flex flex-col flex-wrap lg:w-1/2 lg:text-left  border-2 mr-auto rounded-lg lg:pt-12 lg:pl-12 lg:pr-12">
                <div class="flex flex-col mb-10 lg:items-start items-center">
                  <div class="flex-grow w-full">
                    <h2 class="text-black title-font font-medium text-2xl mb-4">What you learn
                    </h2>
                    <div  class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >You will learn how to leverage the power of Python to solve tasks.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will build games and programs that use Python libraries.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will be able to use Python for your own work problems or personal projects.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>You will create a portfolio of Python based projects you can share.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn to use Python professionally, learning both Python 2 and Python 3!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Create games with Python, like Tic Tac Toe and Blackjack!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn advanced Python features, like the collections module and how to work with timestamps!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Learn to use Object Oriented Programming with classes!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Understand complex topics, like decorators.</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg"class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Understand how to use both the Jupyter Notebook and create .py files</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Get an understanding of how to create GUIs in the Jupyter Notebook system!</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >Build a complete understanding of Python from the ground up!</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:w-1/3 shadow-lg">
                <div  class="bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 lg:w-full">
                  <form>
                    <iframe allowfullscreen="allowfullscreen" src="https://www.youtube.com/embed/?" controls="" class="mb-2 leading-relaxed w-full h-full">
                    </iframe>
                    <p class="leading-relaxed text-gray-600 mb-4">Xem trước khoá học này
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span class="text-3xl">1.700.000</span>
                      </p>
                    </div>
                    <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl mb-8">Ghi danh ngay</button>
                    <p class="leading-relaxed text-gray-600 mb-4">Khoá học này bao gồm:
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/ae1cd3cd-020b-4b2a-b411-a69d0ea733f3play-circle.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>24 giờ video theo yêu cầu</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/c9768dd1-6262-4763-954f-651113dd82efdownload-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>07 nguồn tài nguyên có thể tải xuống</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/a4204056-4ac0-4e3e-bda6-9694d2b6dd66hourglass-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Quyền truy cập đầy đủ vĩnh viễn</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/1daf4632-0a4b-44d2-b932-8d2baf12fd54certificate-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Chứng chỉ khi hoàn thành</span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        `,
      },
    },
  }

  return blockUI;
}

export const registerBlock3 = (data?: any) => {
  const blockUI = {
    id: 'register-block-3-id',
    options: {
      category: nameRegisterBlocks,
      label: 'Giao diện 3',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RegisterWrapperComponent',
        },
        components: `
         <div component-class="RegisterComponent" class="text-gray-600 body-font">
            <div  class="container px-5 mx-auto flex flex-wrap py-8">
              <div class="flex flex-col flex-wrap lg:w-1/2 lg:text-left  border-2 mr-auto rounded-lg lg:pt-12 lg:pl-12 lg:pr-12">
                <div class="flex flex-col mb-10 lg:items-start items-center">
                  <div class="flex-grow w-full">
                    <h2 class="text-black title-font font-medium text-2xl mb-2">Mục tiêu chung
                    </h2>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Những kiến thức, kỹ năng để học tập hiệu quả</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Sẵn sàng tiếp nhận và thích nghi với công nghệ mới</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Biết phản biện với những tương tác trên mạng xã hội để sử dụng trong cuộc sống, trong công việc tương lai </span>
                      </p>
                    </div>
                    <h2 class="text-black title-font font-medium text-2xl mb-2">Mục tiêu về kiến thức
                    </h2>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Kiến thức cơ bản về thông tin và truyền thông</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Kiến thức cơ bản về công cụ xử lý thông tin (máy tính, thiết bị, các loại phần mềm…)</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Hiểu biết về các công nghệ hiện đại có vai trò quan trọng</span>
                      </p>
                    </div>
                    <h2 class="text-black title-font font-medium text-2xl mb-2">Mục tiêu về kỹ năng
                    </h2>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Tổ chức lưu trữ thông tin trên máy tính một cách an toàn và hiệu quả; sử dụng máy tính để giải quyết vấn đề thông dụng </span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Soạn thảo văn bản, tài liệu</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span >Làm việc, quản lý dữ liệu hiệu quả qua bảng tính</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Chuẩn bị bài thuyết trình và trình chiếu</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Thiết lập và sử dụng các công cụ cộng tác trực tuyến để nâng cao hiệu quả công việc</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-2">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4"/>
                      <p class="leading-relaxed text-base">
                        <span>Build a complete understanding of Python from the ground up!</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mb-10 lg:mb-0 rounded-lg lg:w-1/3">
                <div class="bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 lg:w-full shadow-lg">
                  <form>
                    <iframe allowfullscreen="allowfullscreen" src="https://www.youtube.com/embed/?" controls="" class="mb-2 leading-relaxed w-full h-full">
                    </iframe>
                    <p class="leading-relaxed text-gray-600 mb-4">Video xem trước học phần này
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span class="text-3xl">Miễn phí</span>
                      </p>
                    </div>
                    <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl mb-8 w-full">Ghi danh ngay</button>
                    <p class="leading-relaxed text-gray-600 mb-4">Học phần này yêu cầu:
                    </p>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-04-16T07%3A25%3A30.871170892Z/1b99052c-b320-4fc3-a6c5-0891c1d21a73book-open-01.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Phòng học lý thuyết</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-04-16T07%3A25%3A30.871170892Z/8d8b84eb-abaf-47d4-94b7-b84d165c29balaptop-02.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Phòng máy tính</span>
                      </p>
                    </div>
                    <div class="border-gray-200 border-opacity-60 flex mb-4">
                      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-04-16T07%3A26%3A44.451816467Z/535e8641-ceb6-4d0b-b1b7-6048fca6983fwifi.svg" class="mr-2"/>
                      <p class="leading-relaxed text-base">
                        <span>Đầy đủ phần mềm</span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        `,
      },
    },
  }

  return blockUI;
}

export const registerPolicy = (data?: any) => {
  const blockUI = {
    id: 'register-policy-block-id',
    options: {
      category: nameRegisterPolicyBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'RegisterPolicyWrapperComponent',
        },
        components: `
         <section component-class="RegisterPolicyComponent" class="text-gray-600 body-font">
            <div class="container mx-auto px-5 py-12">
              <!-- Title -->
              <div class="text-center mb-12">
                <h1 class="text-4xl font-bold text-purple-600">Chính sách ghi danh
                </h1>
                <p class="text-gray-600 mt-4">Lựa chọn ghi danh khoá học phù hợp với bạn
                </p>
              </div>
              <!-- Pricing Comparison Table -->
              <div class="overflow-x-auto">
                <div class="min-w-full bg-white shadow-lg rounded-lg overflow-hidden">
                  <div class="p-4">
                    <div class="flex justify-between items-center border-b border-gray-200">
                      <div class="w-1/3 text-gray-600 font-bold">
                      </div>
                      <div class="w-1/3 text-center text-gray-600 font-bold py-4 bg-gray-100">Miễn phí
                      </div>
                      <div class="w-1/3 text-center font-bold py-4 bg-purple-600 text-white">Trả phí
                      </div>
                    </div>
                    <div class="divide-y divide-gray-200">
                      <!-- Feature 1 -->
                      <div class="flex">
                        <div class="w-1/3 text-gray-800 py-4">Truy cập vào nội dung học liệu
                        </div>
                        <div class="w-1/3 text-center bg-gray-50 py-4">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                        <div class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <!-- Feature 2 -->
                      <div class="flex">
                        <div class="w-1/3 text-gray-800 py-4">Được hỗ trợ từ hệ thống MOOC
                        </div>
                        <div class="w-1/3 text-center py-4 bg-gray-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                        <div class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-1/3 text-gray-800 py-4">Được hỗ trợ từ người hướng dẫn
                        </div>
                        <div class="w-1/3 text-center py-4 bg-gray-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-red-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                            </path>
                          </svg>
                        </div>
                        <div class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <!-- Feature 3 -->
                      <!-- Feature 4 -->
                      <div class="flex">
                        <div class="w-1/3 text-gray-800 py-4">Được chấm điểm bài kiểm tra, bài thi
                        </div>
                        <div class="w-1/3 text-center py-4 bg-gray-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-red-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                            </path>
                          </svg>
                        </div>
                        <div class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <!-- Feature 5 -->
                      <div class="flex">
                        <div class="w-1/3 text-gray-800 py-4">Được cấp chứng chỉ, chứng nhận sau khi hoàn thành khoá học
                        </div>
                        <div class="w-1/3 text-center py-4 bg-gray-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-red-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                            </path>
                          </svg>
                        </div>
                        <div class="w-1/3 text-center py-4 bg-purple-50">
                          <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" class="h-6 w-6 text-green-500 mx-auto">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7">
                            </path>
                          </svg>
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-1/3 text-gray-800 py-4">
                        </div>
                        <div class="w-1/3 text-center py-4 bg-gray-50">
                          <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl">Ghi danh</button>
                        </div>
                        <div class="w-1/3 text-center py-4 bg-purple-50">
                          <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl">Ghi danh</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        `,
      },
    },
  }

  return blockUI;
}

export const conditionBlockGenerete = (dataCourses: any[] = [], dataSkills: any[] = []) => {
  const blockUI = {
    id: 'condition-block-id',
    options: {
      category: nameConditionBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'ConditionWrapperComponent',
        },
        components: `
          <div component-class="ConditionComponent" class="text-gray-600 body-font">
            <div class="container px-5 mx-auto flex flex-wrap py-8">
              <div class="flex flex-col flex-wrap lg:text-left border-2 rounded-lg lg:w-1/3 lg:pt-6 lg:pr-6 lg:pb-6 lg:pl-6">
                <div class="flex flex-col mb-10 lg:items-start items-center">
                  <div class="flex-grow w-full">
                    <h2 class="text-black title-font font-medium text-2xl mb-2">Khóa học tiên quyết</h2>    
        `,
      },
    },
  }
  if (dataCourses.length > 0) {
    dataCourses.forEach((item: any) => {
      blockUI.options.content.components += `<div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-1 mb-1"><span >${item}</span></div>`;
    });
  } else {
    blockUI.options.content.components += `<div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-1 mb-1"><span >Mẫu khóa học</span></div>`;
  }

  blockUI.options.content.components += `</div></div></div>
    <div class="flex flex-col flex-wrap lg:text-left  border-2 rounded-lg lg:w-1/3 lg:pt-6 lg:pr-6 lg:pb-6 lg:pl-6">
      <div class="flex flex-col mb-10 lg:items-start items-center">
        <div class="flex-grow w-full">
          <h2 class="text-black title-font font-medium text-2xl mb-4">
            <span >Điều kiện kỹ năng</span>
          </h2>`;

  if (dataSkills.length > 0) {
    dataSkills.forEach((item: any) => {
      blockUI.options.content.components += `<div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-1 mb-1"><span>${item}</span></div>`;
    });
  } else {
    blockUI.options.content.components += `<div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-1 mb-1"><span>Mẫu kĩ năng</span></div>`;
  }

  blockUI.options.content.components += `</div></div></div>
    <div class="lg:w-1/3">
      <img resize-none src="https://s3.moooc.xyz/dev-stable/page-builder/23921849-e7c2-4991-ae55-c2fe7338fffclewis-keegan-oB2aEeE8s4A-unsplash%201.jpg"/>
    </div>
  </div></div>`;
  return blockUI;
}

export const affiliatedBlock = (data?: any) => {
  const blockUI = {
    id: 'affiliated-block-id',
    options: {
      category: nameAffiliatedBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'LinkedCourseComponent',
        },
        components: `
         <div component-class="AffiliatedComponent" class="text-gray-600 body-font">
            <div class="container px-5 mx-auto py-8">
              <div class="flex flex-col  w-full mb-20">
                <h1 class="text-2xl font-medium leading-relaxed title-font mb-4 text-purple-700 sm:text-3xl">Khóa học liên kết
                </h1>
                <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Danh sách những khoá học chúng tôi khuyến nghị người học đạt được.
                </p>
              </div>
              <div class="flex flex-wrap -m-4">
                <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
                  <div class="mt-4">
                    <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">DANH MỤC
                    </h3>
                    <h2 class="text-gray-900 title-font text-lg font-medium">Tên khoá học liên kết 1
                    </h2>
                    <p class="mt-1">Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành ...
                    </p>
                  </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
                  <div class="mt-4">
                    <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
                    </h3>
                    <h3  draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">DANH MỤC
                    </h3>
                    <h2 class="text-gray-900 title-font text-lg font-medium">Tên khoá học liên kết 2
                    </h2>
                    <p class="mt-1">Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành ...
                    </p>
                  </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
                  <div class="mt-4">
                    <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
                    </h3>
                    <h3  draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">DANH MỤC
                    </h3>
                    <h2 class="text-gray-900 title-font text-lg font-medium">Tên khoá học liên kết 3
                    </h2>
                    <p class="mt-1">Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành ...
                    </p>
                  </div>
                </div>
                <div class="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
                  <div class="mt-4">
                    <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
                    </h3>
                    <h3  draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">DANH MỤC
                    </h3>
                    <h2 class="text-gray-900 title-font text-lg font-medium">Tên khoá học liên kết 4
                    </h2>
                    <p class="mt-1">Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành ...
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `,
      },
    },
  }
  return blockUI;
}

export const opinionBlockCustom = (data?: any) => {
  const blockUI = {
    id: 'opinion-block-id',
    options: {
      category: nameOpinionBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'OpinionWrapperComponent',
        },
        components: `
          <div component-class="OpinionComponent" class="text-gray-600 body-font">
            <div class="container px-5 mx-auto py-8">
              <h1 class="text-3xl font-medium title-font mb-12  text-purple-700">Ý kiến nhận xét
              </h1>
              <div class="flex flex-wrap -m-4">
                <div class="p-4 md:w-1/2 w-full">
                  <div class="h-full bg-gray-100 p-8 rounded">
                    <svg fill="currentColor" viewBox="0 0 975.036 975.036" class="block w-5 h-5 text-gray-400 mb-4">
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                      </path>
                    </svg>
                    <p class="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.
                    </p>
                    <a class="inline-flex items-center">
                      <img alt="testimonial" src="https://dummyimage.com/106x106" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/>
                      <span class="flex-grow flex flex-col pl-4">
                        <span class="title-font font-medium text-gray-900">Nguyễn Ngọc Tú</span>
                        <span class="text-gray-500 text-sm">Học viên</span>
                      </span>
                    </a>
                  </div>
                </div>
                <div class="p-4 md:w-1/2 w-full">
                  <div class="h-full bg-gray-100 p-8 rounded">
                    <svg fill="currentColor" viewBox="0 0 975.036 975.036" class="block w-5 h-5 text-gray-400 mb-4">
                      <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
                      </path>
                    </svg>
                    <p class="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.
                    </p>
                    <a class="inline-flex items-center">
                      <img alt="testimonial" src="https://dummyimage.com/107x107" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/>
                      <span class="flex-grow flex flex-col pl-4">
                        <span class="title-font font-medium text-gray-900">Trần Thị Lan Anh</span>
                        <span class="text-gray-500 text-sm">Học viên</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      `,
      },
    },
  }
  return blockUI;
};

export const questionBlockCustom = (data?: any) => {
  const blockUI = {
    id: 'question-block-id',
    options: {
      category: nameQuestionBlocks,
      label: 'Giao diện',
      content: {
        tagName: 'section',
        attributes: {
          'component-class': 'QuestionWrapperComponent',
        },
        components: `
          <div component-class="QuestionComponent" class="dark:text-gray-800">
            <div class="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
              <h2 class="text-2xl font-semibold sm:text-4xl text-purple-700">Những câu hỏi thường gặp
              </h2>
              <p class="mt-4 mb-6 dark:text-gray-600">
                Mô tả
              </p>
              <div class="space-y-4">
                <details class="w-full border rounded-lg" open>
                  <summary class="px-2 py-2 focus:outline-none">
                    Khóa học này dành cho ai?
                  </summary>
                  <p class="px-2 py-2 ml-4">
                    Nội dung
                  </p>
                </details>
                <details class="w-full border rounded-lg" open>
                  <summary class="px-2 py-2 focus:outline-none">
                    Khóa học này bao gồm những gì?
                  </summary>
                  <p class="px-2 py-2 ml-4">
                    Nội dung
                  </p>
                </details>
                <details class="w-full border rounded-lg" open>
                  <summary class="px-2 py-2 focus:outline-none">
                    Tôi có thể học khóa học này ở đâu?
                  </summary>
                  <p class="px-2 py-2 ml-4">
                     Nội dung
                  </p>
                </details>
              </div>
            </div>
          </div>
      `,
      },
    },
  }
  return blockUI;
};

export const uiDefault = {
  html: `
  <body>
  <section class="text-gray-600 body-font">
    <div class="container px-5 mx-auto flex flex-wrap py-12">
      <div class="flex flex-col flex-wrap lg:w-1/2 lg:pl-12 lg:text-left lg:pt-24 lg:pr-12">
        <div class="flex flex-col mb-10 lg:items-start items-center">
          <div class="flex-grow">
            <h2 class="text-black text-5xl title-font font-medium mb-8">English Vocabulary Launch: Upgrade your speaking
            </h2>
            <p class="leading-relaxed text-base mb-8">Learn English vocabulary, speaking and listening skills and become more fluent learning from a native English teacher
            </p>
          </div>
          <div class="container mx-auto flex flex-wrap flex-col md:flex-row items-center">
            <div class="ml-0 mr-2">Khoá học tạo bởi
            </div>
            <img src="https://s3.moooc.xyz/dev-stable/page-builder/c2565ae6-0556-44da-b77d-a2df6134eec4FTU_logo_2020.png" class="w-10 mr-auto"/>
            <div class="ml-0 mr-2">Tác giả:
            </div>
            <img src="https://s3.moooc.xyz/dev-stable/page-builder/39da7095-85ec-473e-b80b-33be4622ee4cavatar.jpg" class="w-10 mr-2 rounded-full"/>
            <div class="ml-0 mr-2 text-purple-500">Đặng Bảo Chung
            </div>
          </div>
        </div>
      </div>
      <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
        <img alt="feature" src="https://s3.moooc.xyz/dev-stable/page-builder/23921849-e7c2-4991-ae55-c2fe7338fffclewis-keegan-oB2aEeE8s4A-unsplash%201.jpg" id="isccx" class="object-center h-full w-full ml-auto mr-auto rounded-2xl"/>
      </div>
    </div>
  </section>
  <section class="text-gray-600 body-font">
    <div class="container px-5 mx-auto flex flex-wrap py-8">
      <div class="flex flex-col flex-wrap lg:w-1/2 lg:text-left border-2 mr-auto rounded-lg lg:pt-12 lg:pl-12 lg:pr-12">
        <div class="flex flex-col mb-10 lg:items-start items-center">
          <div class="flex-grow w-full">
            <h2 class="text-black title-font font-medium text-2xl mb-4">What you learn
            </h2>
            <div class="border-gray-200 border-opacity-60 flex">
              <div class="border-gray-200 border-opacity-60 flex mb-4">
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>You will learn how to leverage the power of Python to solve tasks.</span>
                  </p>
                </div>
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>You will build games and programs that use Python libraries.</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="border-gray-200 border-opacity-60 flex">
              <div class="border-gray-200 border-opacity-60 flex mb-4">
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>You will be able to use Python for your own work problems or personal projects.</span>
                  </p>
                </div>
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>You will create a portfolio of Python based projects you can share.</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="border-gray-200 border-opacity-60 flex">
              <div class="border-gray-200 border-opacity-60 flex mb-4">
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>Learn to use Python professionally, learning both Python 2 and Python 3!</span>
                  </p>
                </div>
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>Create games with Python, like Tic Tac Toe and Blackjack!</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="border-gray-200 border-opacity-60 flex">
              <div class="border-gray-200 border-opacity-60 flex mb-4">
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>Learn advanced Python features, like the collections module and how to work with timestamps!</span>
                  </p>
                </div>
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>Learn to use Object Oriented Programming with classes!</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="border-gray-200 border-opacity-60 flex">
              <div class="border-gray-200 border-opacity-60 flex mb-4">
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>Understand complex topics, like decorators.</span>
                  </p>
                </div>
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>Understand how to use both the Jupyter Notebook and create .py files</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="border-gray-200 border-opacity-60 flex">
              <div class="border-gray-200 border-opacity-60 flex mb-4">
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>Get an understanding of how to create GUIs in the Jupyter Notebook system!</span>
                  </p>
                </div>
                <div class="border-gray-200 border-opacity-60 flex mb-4 w-1/2 mr-2 mr-4">
                  <img src="https://s3.moooc.xyz/dev-stable/page-builder/3c597520-77c1-45e2-a130-bd412082a685Check.svg" class="mr-4 w-6 h-6"/>
                  <p class="leading-relaxed text-base">
                    <span>Build a complete understanding of Python from the ground up!</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full mb-10 lg:mb-0 rounded-lg overflow-hidden lg:w-1/3 shadow-lg">
        <div class="bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 lg:w-full">
          <form>
            <iframe allowfullscreen="allowfullscreen" src="https://www.youtube.com/embed/?" controls="" class="mb-2 leading-relaxed w-full h-full">
            </iframe>
            <p class="leading-relaxed text-gray-600 mb-4">Xem trước khoá học này
            </p>
            <div class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span class="text-3xl">1.700.000</span>
              </p>
            </div>
            <button type="submit" class="text-white bg-purple-500 border-0 py-2 px-6 focus:outline-none hover:bg-purple-600 text-lg rounded-xl mb-8">Ghi danh ngay</button>
            <p class="leading-relaxed text-gray-600 mb-4">Khoá học này bao gồm:
            </p>
            <div class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/ae1cd3cd-020b-4b2a-b411-a69d0ea733f3play-circle.svg" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span>24 giờ video theo yêu cầu</span>
              </p>
            </div>
            <div class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/c9768dd1-6262-4763-954f-651113dd82efdownload-01.svg" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span>07 nguồn tài nguyên có thể tải xuống</span>
              </p>
            </div>
            <div class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/a4204056-4ac0-4e3e-bda6-9694d2b6dd66hourglass-01.svg" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span>Quyền truy cập đầy đủ vĩnh viễn</span>
              </p>
            </div>
            <div class="border-gray-200 border-opacity-60 flex mb-4">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/1daf4632-0a4b-44d2-b932-8d2baf12fd54certificate-01.svg" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span>Chứng chỉ khi hoàn thành</span>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section class="text-gray-600 body-font">
    <div class="container px-5 mx-auto flex flex-wrap py-8">
      <div class="flex flex-col flex-wrap lg:text-left border-2 rounded-lg lg:pt-12 lg:pl-12 lg:pr-12 mr-4 lg:w-1/3">
        <div class="flex flex-col mb-10 lg:items-start items-center">
          <div class="flex-grow w-full">
            <h2 class="text-black title-font font-medium text-2xl mb-4">Khóa học tiên quyết
            </h2>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>English Basic</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>Grammar</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>Vocab General</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>English Pronunciation</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>English for Beginner</span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col flex-wrap lg:text-left border-2 rounded-lg lg:pt-12 lg:pl-12 lg:pr-12 mr-4 lg:w-1/3">
        <div class="flex flex-col mb-10 lg:items-start items-center">
          <div class="flex-grow w-full">
            <h2 class="text-black title-font font-medium text-2xl mb-4">
              <span>Điều kiện kỹ năng</span>
            </h2>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>UI/UX</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>Website</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>Mobile Application</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>Java</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>English</span>
            </div>
            <div class="bg-purple-100 text-purple-700 border border-purple-600 rounded-lg inline-block p-2 mr-2 mb-2">
              <span>C#</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="text-gray-600 body-font">
    <div class="container px-5 mx-auto py-8">
      <div class="flex flex-col w-full mb-20">
        <h1 class="sm:text-3xl text-2xl font-medium title-font text-purple-700">Người hướng dẫn
        </h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">Danh sách giảng viên hàng đầu tham gia hướng dẫn tại tại khoá học
        </p>
      </div>
      <div class="flex flex-wrap -m-2">
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/80x80" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2  class="text-gray-900 title-font font-medium">Bùi Ngọc Châu
              </h2>
              <p  class="text-gray-500">Hiệu phó
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/84x84" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2  class="text-gray-900 title-font font-medium">Huân Trần
              </h2>
              <p  class="text-gray-500">Hiệu phó
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/88x88" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2  class="text-gray-900 title-font font-medium">Quý Kiên
              </h2>
              <p  class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/90x90" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Quân Trần
              </h2>
              <p class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/94x94" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Đăng Định
              </h2>
              <p class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/98x98" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Nguyễn Trần Chỉnh
              </h2>
              <p class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/100x90" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Minh Nghĩa
              </h2>
              <p class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/104x94" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Phạm Thị Duyên
              </h2>
              <p class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" src="https://dummyimage.com/108x98" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Thái Phạm
              </h2>
              <p class="text-gray-500">Giảng viên bộ môn
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="text-gray-600 body-font">
    <div class="container px-5 mx-auto py-8">
      <h1 class="text-3xl font-medium title-font mb-12 text-purple-700">Ý kiến nhận xét
      </h1>
      <div class="flex flex-wrap -m-4">
        <div class="p-4 md:w-1/2 w-full">
          <div class="h-full bg-gray-100 p-8 rounded">
            <svg fill="currentColor" viewBox="0 0 975.036 975.036" class="block w-5 h-5 text-gray-400 mb-4">
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
              </path>
            </svg>
            <p class="leading-relaxed mb-6">
              Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.
            </p>
            <a class="inline-flex items-center">
              <img alt="testimonial" src="https://dummyimage.com/106x106" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/>
              <span class="flex-grow flex flex-col pl-4">
                <span class="title-font font-medium text-gray-900">Nguyễn Ngọc Tú</span>
                <span class="text-gray-500 text-sm">Học viên</span>
              </span>
            </a>
          </div>
        </div>
        <div class="p-4 md:w-1/2 w-full">
          <div class="h-full bg-gray-100 p-8 rounded">
            <svg fill="currentColor" viewBox="0 0 975.036 975.036" class="block w-5 h-5 text-gray-400 mb-4">
              <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z">
              </path>
            </svg>
            <p class="leading-relaxed mb-6">Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90's microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.
            </p>
            <a class="inline-flex items-center">
              <img alt="testimonial" src="https://dummyimage.com/107x107" class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"/>
              <span class="flex-grow flex flex-col pl-4">
                <span class="title-font font-medium text-gray-900">Trần Thị Lan Anh</span>
                <span class="text-gray-500 text-sm">Học viên</span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="text-gray-600 body-font relative">
    <div class="container px-5 mx-auto flex sm:flex-nowrap flex-wrap py-8">
      <div class="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
        <iframe frameborder="0" width="100%" height="100%" title="map" marginheight="0" marginwidth="0" scrolling="no" src="https://maps.google.com/maps?&q=Lê văn thiêm hà nội&z=15&t=q&output=embed" class="absolute inset-0"></iframe>
        <div class="bg-white relative flex flex-wrap py-6 rounded shadow-md">
          <div class="lg:w-1/2 px-6">
            <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">ĐỊA CHỈ
            </h2>
            <p class="mt-1">6 P. Lê Văn Thiêm, Thanh Xuân Trung, Thanh Xuân, Hà Nội
            </p>
          </div>
          <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
            <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">
              EMAIL
            </h2>
            <a class="text-indigo-500 leading-relaxed">example@email.com</a>
            <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
              SỐ ĐIỆN THOẠI
            </h2>
            <p class="leading-relaxed">
              123-456-7890
            </p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
        <form>
          <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Liên hệ với chúng tôi
          </h2>
          <p class="leading-relaxed mb-5 text-gray-600">Mọi góp ý của bạn giúp chúng tôi hoàn thiện khoá học hơn
          </p>
          <div class="relative mb-4">
            <label for="Name"  title="Tên" class="leading-7 text-sm text-gray-600">Name</label>
            <input type="text" id="name-2" name="name" required class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
          </div>
          <div class="relative mb-4">
            <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email-2" name="email" required class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
          </div>
          <div class="relative mb-4">
            <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message-2" name="message" required class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
          <button type="submit" class="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">Gửi</button>
        </form>
      </div>
    </div>
  </section>
  <section class="dark:text-gray-800">
    <div class="container flex flex-col justify-center px-4 py-8 mx-auto md:p-8">
      <h2 class="text-2xl font-semibold sm:text-4xl text-purple-700">Những câu hỏi thường gặp
      </h2>
      <p class="mt-4 mb-6 dark:text-gray-600">
        Mô tả
      </p>
      <div class="space-y-4">
        <details class="w-full border rounded-lg" open>
          <summary class="px-2 py-2 focus:outline-none">
            Khóa học này dành cho ai?
          </summary>
          <p class="px-2 py-2 ml-4">
            Nội dung
          </p>
        </details>
        <details class="w-full border rounded-lg" open>
          <summary  class="px-2 py-2 focus:outline-none">
            Khóa học này bao gồm những gì?
          </summary>
          <p class="px-2 py-2 ml-4">
            Nội dung
          </p>
        </details>
        <details class="w-full border rounded-lg" open>
          <summary  class="px-2 py-2 focus:outline-none">
            Tôi có thể học khóa học này ở đâu?
          </summary>
          <p class="px-2 py-2 ml-4">
            Nội dung
          </p>
        </details>
      </div>
    </div>
  </section>
</body>
  `,
  css: `
  * { box-sizing: border-box; } 
  body {margin: 0;}
  `,
}
