import { Spin } from "antd";

export const LoadingComponent = () => {
  return (
    <div>
      <Spin tip="Loading" size="large" spinning fullscreen>
        <div className="content" />
      </Spin>
    </div>
  );
};
