import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, Row, Space, TableColumnsType, TableProps, TimePicker, Tooltip, Typography, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import { CheckCircleOutlined, CloseOutlined, DownloadOutlined, PlusOutlined, ReloadOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
import moment from 'moment'
import { saveSequenceTemplateCourse } from '../../../service/content-list-course/contentListCourseApi'

type Props = {
  open: boolean
  codeLesson?: string
  handleClose: () => void
  sequenceId: string
}

export default function SaveTemplateLessonModal(props: Props) {
  const { open, handleClose, codeLesson, sequenceId } = props

  const [form] = Form.useForm()

  const validation = {
    codeTemplateLesson: {
      required: true,
      message: "Vui lòng nhập mã mẫu bài giảng",
    },
    nameTemplateLesson: {
      required: true,
      message: "Vui lòng nhập tên mẫu bài giảng",
    },
  };

  const randomCodeTemplateLesson = () => {
    const date = moment().format('YYMMDD HHmmss')
    const leftDate = date.split(" ")
    const codeLessonRandom = `${codeLesson}_${leftDate[0]}_${leftDate[1]}`
    form.setFieldValue("codeTemplateLesson", codeLessonRandom)
  }

  const saveData = async (values: any) => {
    const data = {
      ids: [sequenceId] ?? [],
      key: values.codeTemplateLesson ?? null,
      name: values.nameTemplateLesson ?? null
    }
    await saveSequenceTemplateCourse(data).then((res) => {
      if (res.status === 200) {
        message.success("Lưu mẫu bài giảng thành công")
        handleClose()
        form.resetFields()
      }
    })
  }

  useEffect(() => {
    if (open) {
      randomCodeTemplateLesson()
    }
  }, [open])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === "save-template-lesson-modal") {

        Modal.confirm({
          title: `Xác nhận lưu mẫu bài giảng`,
          content: `Xin vui lòng xác nhận lưu mẫu bài giảng.`,
          onOk: () => {
            saveData(values);
          },
          okText: "Xác nhận",
          cancelText: "Đóng",
          centered: true,
          icon: <CheckCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>
      <Modal
        className="save-template-lesson-modal"
        title={"Lưu mẫu bài giảng"}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"450px"}
      >
        <Form
          form={form}
          layout="vertical"
          name="save-template-lesson-modal"
        >
          <Row gutter={30} className='p-6'>
            <Col md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }} xs={{ span: 22 }}>
              <Form.Item name="codeTemplateLesson" label="Mã mẫu bài giảng" rules={[validation.codeTemplateLesson]}>
                <FormInput placeholder='Nhập mã mẫu hoặc tự sinh mã tự động' />
              </Form.Item>
            </Col>
            <Col md={{ span: 2 }} lg={{ span: 2 }} xl={{ span: 2 }} xs={{ span: 2 }} className='flex'>
              <Tooltip title="Tạo mã mẫu bài giảng ngẫu nhiên">
                <ReloadOutlined className='mt-3 cursor-pointer' onClick={randomCodeTemplateLesson} />
              </Tooltip>
            </Col>

            <Col md={{ span: 22 }} lg={{ span: 22 }} xl={{ span: 22 }} xs={{ span: 22 }} className='mt-2'>
              <Form.Item name="nameTemplateLesson" label="Tên mẫu bài giảng" rules={[validation.nameTemplateLesson]}>
                <FormInput placeholder='Nhập tên mẫu bài giảng' />
              </Form.Item>
            </Col>

            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Lưu
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider >
  )
}
