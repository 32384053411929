import React from "react";
import { Button } from "antd";
import moment from "moment";

interface PauseScreenProps {
  dateTimePause: number;
  timeDeadline?: number | string;
  onContinue?: (v?: any) => void;
}
function PauseScreen({ dateTimePause = 0 , onContinue }: PauseScreenProps) {
  return (
    <div className="pause-screen">
      <div className="center flex-column content-center">
        <span className="font-weight-6 font-size-16 mb-1">
          Bạn đang tạm dừng làm bài
        </span>
        <p className="font-size-16 font-weight-5">
          Thời gian tạm dừng:{" "}
          <span className="text-primary">
            {moment(dateTimePause).format("HH:mm:ss - DD/MM/YYYY")}
          </span>
        </p>
        <p className="font-size-16 mt-1 font-weight-5">
          Hạn nộp bài:{" "}
          <span className="text-primary">00:00:00 - 15/05/2024</span>
        </p>
      </div>
      <Button className="btn-primary h-40 mt-2 btn-start" onClick={onContinue}>
        Tiếp tục
      </Button>
    </div>
  );
}

export default PauseScreen;