// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mooc-breadcrumb {
  padding: 48px 0; }
  .mooc-breadcrumb .ant-breadcrumb-separator {
    margin-inline: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/breadcrumb/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAe,EAAA;EADjB;IAGI,mBAAmB,EAAA","sourcesContent":[".mooc-breadcrumb {\n  padding: 48px 0;\n  .ant-breadcrumb-separator {\n    margin-inline: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
