import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const postSettingManagementData: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-api-credential`, data);
};

export const getSettingManagementData: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-api-credential/search`, data);
};

export const putSettingManagementData: (
  id: string | undefined,
  data: any
) => Promise<AxiosResponse<any>> = (id: string | undefined, data: any) => {
  return axiosConfigV2.put(`/mooc-api-credential/${id}`, data);
};

export const deleteSettingManagementData: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id: string | undefined) => {
  return axiosConfigV2.delete(`/mooc-api-credential/${id}`);
};

//history
export const getHistoryManagementData: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-api-credential-history/search`, data);
};

export const saveHistoryManagementData: (
  id: string | undefined,
  data: any
) => Promise<AxiosResponse<any>> = (id, data: any) => {
  return axiosConfigV2.put(`/mooc-api-credential-history/${id}`, data);
};

export const deleteHistoryManagement: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-api-credential-history/${id}`);
};

export const getDownloadExcelManagementData: (id: string | undefined) => Promise<
  AxiosResponse<any>
> = (id) => {
  return axiosConfigV2.get(`/mooc-api-credential-history/export-excel/${id}`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getDownloadCsvManagementData: (id: string | undefined) => Promise<
  AxiosResponse<any>
> = (id) => {
  return axiosConfigV2.get(`/mooc-api-credential-history/export-csv/${id}`,
    {
      responseType: "arraybuffer",
    }
  );
};

export const getDownloadPdfManagementData: (id: string | undefined) => Promise<
  AxiosResponse<any>
> = (id) => {
  return axiosConfigV2.get(`/mooc-api-credential-history/export-pdf/${id}`,
    {
      responseType: "arraybuffer",
    }
  );
};
