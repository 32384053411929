import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import "./filter-group.scss";

import { FilterData } from "./filter";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  clearFilter: () => void;
  onFinish?: () => void;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  dataOptions?: any;
  filterData: FilterData;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  onFinish,
  form,
  showFilter,
  clearFilter,
  filterData,
  setFilterData,
}) => {
  const { RangePicker } = DatePicker;

  const dataOptionsFilter = [
    { id: 3, name: "Chờ quản trị khoá học duyệt" },
    { id: 4, name: "Quản trị khoá học trả lại" },
    { id: 5, name: "Chờ hội đồng thẩm định duyệt" },
    { id: 12, name: "Chờ xuất bản" },
    { id: 13, name: "Đã xuất bản" },
  ];

  const dataOptionsFilterContent = [
    { id: 3, name: "Chờ quản trị khoá học duyệt" },
    { id: 4, name: "Quản trị khoá học trả lại" },
    { id: 5, name: "Chờ hội đồng thẩm định duyệt" },
    { id: 6, name: "Hội đồng thẩm định trả lại" },
    { id: 11, name: "Tổ chuyên môn trả lại" },
    { id: 12, name: "Chờ xuất bản" },
    { id: 13, name: "Đã xuất bản" },
  ];

  const courseType = [
    { id: 1, name: "Tự triển khai" },
    { id: 2, name: "Hợp tác xây dựng" },
  ];

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
            <Col
              xl={{ span: 5 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Trạng thái duyệt trang trình bày</Typography.Title>
              <Form.Item name="approveSlideStatus">
                <FormItemDropdown
                  placeholder="Chọn trạng thái"
                  onChange={(option) => setFilterData(option, "approveSlideStatus")}
                  options={dataOptionsFilter}
                  modeDropdown="multiple"
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 5 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Trạng thái duyệt nội dung</Typography.Title>
              <Form.Item name="approveContentStatus">
                <FormItemDropdown
                  placeholder="Chọn trạng thái"
                  onChange={(option) => setFilterData(option, "approveContentStatus")}
                  options={dataOptionsFilterContent}
                  modeDropdown="multiple"
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 4 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Loại khoá học</Typography.Title>
              <Form.Item name="courseType">
                <FormItemDropdown
                  placeholder="Chọn trạng thái"
                  onChange={(option) => setFilterData(option, "courseType")}
                  options={courseType}
                  modeDropdown="multiple"
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 4 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Ngày duyệt</Typography.Title>
              <Form.Item name="approvedDate">
                <RangePicker
                  placeholder={["Từ ngày", "Đến ngày"]}
                  format="DD-MM-YYYY"
                  value={filterData.approvedDate}
                  onChange={(_value, dateString: any) =>
                    setFilterData(dateString, "approvedDate")
                  }
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 3 }}
              md={{ span: 9 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa bộ lọc</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
