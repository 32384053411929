import {Collapse, Drawer, Flex, Radio, Spin, TableColumnsType, TableProps} from 'antd'
import React, {useEffect, useState, useTransition} from 'react'
import DOMPurify from "dompurify";
import './index.scss'
import {get, isEmpty} from 'lodash';
import {formatDateV2} from "../../../../../utils/converDate";
import TableData from "../../../../table-data/TableData";
import {getHistoryQuiz} from '../../../../../service/content-list-course/contentListCourseApi';

type Props = {
  open: boolean
  onClose: () => void
  detailData?: any

}

export default function PreviewDetail({open, onClose, detailData}: Props) {

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [countTrue, setCountTrue] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataPanel, setDataPanel] = useState<any>([])
  const [isPending, startTransition] = useTransition()

  const [tab, setTab] = useState<string | string[]>("")
  const [dataHistory, setDataHistory] = useState([])

  const getDataQuiz = async (id: number) => {

    try {
      const response = await getHistoryQuiz(id)
      startTransition(() => {
        setDataHistory(response.data.data)
      })
    } catch (err) {
      console.error(err)
    }

  }


  const {question, id} = detailData

  const handleChangeRadio = () => {
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {

  };

  const handleChangeCollapse = (key: string | string[]) => {
    startTransition(() => {
      setTab(key)
    })
  };


  useEffect(() => {
    if (id) {
      getDataQuiz(id)
    }
  }, [id])


  const columns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder ?? 1}</div>;
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "action",
      width: 20
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 15,
      render: (_: any, record) => {
        return (
          <>{record?.createdDate ? formatDateV2(record?.createdDate) : null}</>
        );
      },
    },
    {
      title: "Người cập nhật",
      dataIndex: "userName",
      key: "userName",
      width: 15,
    },
  ]


  const items = [
    {
      key: '1',
      label: "Lịch sử câu hỏi",
      children: <>
        <TableData dataTable={dataHistory} dataColumns={columns} loadingTable={isPending} pageSize={pageSize}
                   setPageSize={setPageSize} setPageNumber={setPageNumber}
                   pagination={true}
                   pageNumber={pageNumber} handleTableChangeProps={handleTableChange}/>
      </>
    }
  ]


  return (
    <Drawer
      title={"Xem chi tiết câu hỏi"}
      open={open}
      onClose={onClose}
      className='content-detail-course-drawer'
      width={736}
      bodyStyle={{padding: "0px"}}
    >
      <div className="preview-quiz--wrapper">
        {loading ? <Spin/> :
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(question)}}/>
        }

        <div className='preview-quiz--wrapper'>
          {
            get(detailData, 'answers', []).length > 0 ? detailData.answers.map((answer: any, index: number) => {
              return (
                <section key={index}>
                  <Radio.Group
                    onChange={handleChangeRadio}
                    className="w-full"
                    disabled={true}
                  >
                    <section className='answer'>
                      <Radio className="mt-2" value={answer.isTrue}>
                        {(isEmpty(answer.filePath) ?
                            <span>{isEmpty(answer.content)
                              ? 'Chưa có câu trả lời'
                              : typeof answer.content === 'object'
                                ? `${JSON.stringify(answer.content.left.content)}-${JSON.stringify(answer.content.left.content)}`
                                : answer.content}</span> :
                            <Flex align={'center'} gap="middle">
                              {!answer.fileName.includes('mp4') ?
                                <div className="answer-quiz--image">
                                  <img src={answer.filePath} alt="image-answer" style={{width: '100%'}}/>
                                </div>
                                : (<video src={answer.filePath} width="320" height="240" controls>
                                  <source src={answer.filePath} type="video/mp4"/>
                                  <source src={answer.filePath} type="video/ogg"/>
                                </video>)
                              }
                              <span>{isEmpty(answer.content) ? "Chưa có câu trả lời" :
                                typeof answer.content === 'object' &&
                                answer.content.hasOwnProperty('left') &&
                                answer.content.hasOwnProperty('right')
                                  ? `${JSON.stringify(answer.content.left.content)}-${JSON.stringify(answer.content.left.content)}`
                                  : answer.content}</span>
                            </Flex>
                        )}
                      </Radio>
                    </section>
                  </Radio.Group>
                </section>
              )
            }) : (
              <>
                <div>
                  Không có dữ liệu câu hỏi
                </div>
              </>
            )
          }
        </div>
        <Collapse onChange={handleChangeCollapse} ghost defaultActiveKey={["1"]} items={items} activeKey={tab}/>

      </div>
    </Drawer>
  )
}
