import {
  Dropdown,
  Flex,
  Form,
  Layout,
  MenuProps,
  Space,
  TableColumnsType,
  TableProps,
  Typography
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";

import { EyeOutlined, SettingOutlined } from "@ant-design/icons";

import { FormInstance } from "antd/lib";
import { isEmpty } from "lodash";
import PageHeader from "../../components/page-header/PageHeader";
import TableData from "../../components/table-data/TableData";
import { routesConfig } from "../../config/routes";
import {
  getAllTagSystemSuggestCourse,
  getSystemSuggestCourseAll,
} from "../../service/system-suggest-course";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";

type ManagementPermissionCourseProps = {
  title?: string;
};

const ManagementPermissionCourse = ({
  title,
}: ManagementPermissionCourseProps) => {
  const navigate = useNavigate();

  const [documentSystem, setDocumentSystem] = useState<any[]>([]);
  const [dataFilter, setDataFilter] = useState({
    name: "",
    tags: []
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [optionSelectTag, setOptionSelectTag] = useState([]);

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getSystemSuggestCourseAll(searchParam);
      const { data } = response.data;
      setDocumentSystem(data?.data);
      setTotalPage(data?.count?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchListTag = async () => {
    try {
      const response = await getAllTagSystemSuggestCourse();
      const { data } = response.data;
      setOptionSelectTag(
        data?.data?.map((tag: any) => ({
          label: tag?.name,
          value: tag?.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      name: searchValue,
      tags: [],
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
    fetchListTag();
  }, [pageSize, pageNumber]);

  const dataColumnsReportCheckIn: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "code",
      key: "code",
      width: 15,
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      key: "name",
      width: 25,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">{record?.name}</span>
            </div>
          </Flex>
        );
      },
      sorter: {
        compare: (a, b) => a.name.length - b.name.length,
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const onLink = () => {
          navigate(`/setting-high-level/${routesConfig.roleCourse}/${record?.id}`);
        };
        return (
          <Space>
            <span className="action-table cursor-pointer" onClick={onLink}>
              <EyeOutlined />
            </span>
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckIn.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsReportCheckIn.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        ...dataFilter,
        take: 10,
        skip: 0,
        name: searchValue,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      ...dataFilter,
      take: 10,
      skip: 0,
      name: searchValue,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const clearFilter = async (formInstance: FormInstance) => {
    setLoading(true);
    setDataFilter({
      name: "",
      tags: [],
    });
    const searchParam = {
      tags: [],
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      name: searchValue,
    };
    fetchData(searchParam);
    formInstance.resetFields();
  };

  const handleFinish = (values: any) => {
    setLoading(true);

    const searchParam = {
      ...values,
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      name: searchValue,
    };
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper justify-between">
            <div className="table-header--left">
              <PageHeader title={"Danh sách khoá học"} />
            </div>
            <FilterTable
              searchValue={searchValue}
              dataColumns={dataColumnsReportCheckIn}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            onFinish={handleFinish}
            showFilter={showFilter}
            optionSelectTag={optionSelectTag}
            setDataFilter={setDataFilter}
          />
        </div>
        <TableData
          dataTable={documentSystem}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1200, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ManagementPermissionCourse;
