import {ChangeEvent, memo, useEffect, useState} from "react";
import {Form, Input} from "antd";
import {DEFAULT_MAX_LENGTH_COURSE_TEXTAREA} from "../../constants";
import "./style.scss";
import {SizeType} from "antd/es/config-provider/SizeContext";

interface FormInputProps {
  label: string;
  name?: string;
  value?: string;
  max?: number;
  min?: number;
  type?: string; // text, textarea
  className?: string;
  showCount?: boolean;
  containerClassName?: string;
  placeholder?: string;
  autoSize?: { minRows: number; maxRows: number };
  size?: SizeType;
  onChange?: (val?: any) => void;
}

function FormInputText({
                         label,
                         name,
                         value,
                         type = "text",
                         max = DEFAULT_MAX_LENGTH_COURSE_TEXTAREA,
                         min,
                         className = "",
                         containerClassName = "",
                         autoSize,
                         size,
                         showCount = true,
                         placeholder = "",
                         onChange = () => {
                         },
                         ...rest
                       }: FormInputProps) {
  const [currentValue, setCurrentValue] = useState<string>("");

  useEffect(() => {
    if (value) setCurrentValue(value);
  }, [value]);

  const handleChange = (value: string) => {
    setCurrentValue(value);
    onChange(value);
  };

  const renderInputWithType = () => {
    switch (type) {
      case "text":
        return (
          <Input
            min={min}
            value={currentValue}
            max={max}
            placeholder={placeholder}
            className={`base-input-text ${className}`}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value)
            }
            {...rest}
          />
        );
      case "textarea":
        return (
          <div className="text-area-wrap">
            <Input.TextArea
              value={currentValue}
              maxLength={max}
              showCount={showCount}
              className={`base-input-text ${className}`}
              autoSize={autoSize}
              placeholder={placeholder}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                handleChange(e.target.value)
              }
              {...rest}
            />
          </div>
        );
      default:
        return (
          <Input
            min={min}
            value={currentValue}
            max={max}
            className={`base-input-text ${className}`}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.value)
            }
            {...rest}
          />
        );
    }
  };
  return (
    <div className={`setting-question-row ${containerClassName}`}>
      <div className="row-item">
        <span>{label}</span>
        <div className="flex-1 w-full">
          <Form.Item name={name} {...rest}>
            {renderInputWithType()}
          </Form.Item>
        </div>
      </div>
    </div>
  );
}

export default memo(FormInputText);
