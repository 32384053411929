import {
  CheckCircleOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  MoreOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FormFilter from "../../../../components/course-browse/course-approval/filter-group/FormFilter";
import FilterDataCourseApproval from "../../../../components/course-browse/course-approval/filter-table/FilterTable";
import { FilterData } from "../../../../components/course-construction/assign-course-construction/filter-group/filter";
import CourseAssignmentModal from "../../../../components/modal/course-assignment";
import PageHeader from "../../../../components/page-header/PageHeader";
import TableData from "../../../../components/table-data/TableData";
import { routesConfig } from "../../../../config/routes";
import {
  assignMultiCourse,
  revokeAssign,
} from "../../../../service/assign-course-construction";
import { getCourseApprovalList } from "../../../../service/course-construct";
import { DataType } from "../../../../types/assign-course-construction";
import { formatDate, formatOriginTime } from "../../../../utils/converDate";
import "./index.scss";
import ApprovalMultipleContent from "../../../../components/modal/approval-multiple-content";

type Props = {
  title?: string;
};

const CourseApprovalPage = (props: Props) => {
  const [tab, setTab] = useState("1");
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [approvedTotal, setApprovedTotal] = useState<number>(0);
  const [pendingApprovalTotal, setPendingApprovalTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [openApprovalContentModal, setOpenApprovalContentModal] = useState<boolean>(false);
  const [quickReview, setQuickReview] = useState(false)
  const [quickReviewId, setQuickReviewId] = useState<string>("");
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);

  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [courseApprovalList, setCourseApprovalList] = useState([]);
  const [filterData, setFilterData] = useState<any>({
    status: null,
    courseType: null,
    approvedDate: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const handleOpenCreate = () => {
    if (isEmpty(selectedRowKeys)) {
      message.error("Vui lòng chọn khoá học phê duyệt");
      return;
    }
    setOpenApprovalContentModal(true);
  };
  const formatDateWithTime = (dateString: string | null): string | null => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    return `${formattedDate}T00:00:00.000Z`;
  }
  const getListData = () => {
    setLoading(true);
    const approvedDate = filterData.approvedDate?.filter((item: any) => !!item);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      sort:isEmpty(fieldSortOrder) || isEmpty(statusOrder) ? ["name,asc"] : [`${fieldSortOrder},${statusOrder}`],
      apprisedSendFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? formatDateWithTime(approvedDate[0]) : null,
      apprisedSendTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? formatDateWithTime(approvedDate[1]) : null,
      courseType: filterData.courseType ? filterData.courseType : [],
      status: filterData.status === 1 || tab === '2' ? true : false
    };
    getCourseApprovalList(data)
      .then((res) => {
        const { data } = res;
        setCourseApprovalList(data?.page?.content);
        setTotalRecords(data.totalElements);
        setPendingApprovalTotal(data.countFalseRecords);
        setApprovedTotal(data.countTrueRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, statusOrder, pageSize]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      status: null,
      courseType: null,
      approvedDate: null,
    });
  };

  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự triển khai";
      case 2:
        return "Hợp tác xây dựng";
      default:
        return "";
    }
  };

  const mockData = [
    {
      id: 57,
      name: "Toán cao cấp 01",
    },
    {
      id: 57,
      name: "Toán cao cấp 01",
    },
    {
      id: 57,
      name: "Toán cao cấp 01",
    },
    {
      id: 57,
      name: "Toán cao cấp 01",
    },
  ];

  const optionsMenu: MenuProps["items"] = [
    {
      key: "1",
      label: "Phê duyệt khoá học",
      icon: <FileDoneOutlined />,
    },
    {
      key: "2",
      label: "Phê duyệt nhanh khoá học",
      icon: <ThunderboltOutlined />,
    },
    {
      key: "3",
      label: "Kết quả duyệt",
      icon: <HistoryOutlined />,
    },
  ];

  const optionsAppraisedMenu: MenuProps["items"] = [
    {
      key: "5",
      label: "Lịch sử phê duyệt",
      icon: <HistoryOutlined />,
    },
    {
      key: "6",
      label: "Tải nội dung khoá học",
      icon: <DownloadOutlined />,
    },
  ];

  const dataColumns: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "code",
      key: "code",
      sorter: false,
      width: 20,
    },
    {
      title: "Tên khoá học",
      dataIndex: "courseName",
      key: "courseName",
      sorter: (a,b) => a.nameCourse.localeCompare(b.nameCourse),
      width: 30,
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record?.nameCourse}</span>
              <span>{record?.enterpriseName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Loại khoá học",
      dataIndex: "courseName",
      key: "courseName",
      sorter: false,
      width: 20,
      render: (_: any, record: any) => {
        return (
          <div className="table-course-type">
            {record?.courseType === 2 ? (
              <div className="table-course-type-2">
                {handleReturnCourseType(record?.courseType)}
              </div>
            ) : record?.courseType === 1 ? (
              <div className="table-course-type-1">
                {handleReturnCourseType(record?.courseType)}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "courseName",
      key: "courseName",
      sorter: false,
      shouldCellUpdate(record, prevRecord) {
        return true
      },
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            {tab === "1" ? "Chờ tổ chuyên môn duyệt" : "Chờ xuất bản"}
          </div>
        );
      },
    },

    {
      title: "Ngày gửi phê duyệt",
      dataIndex: "valuationdSendDate",
      key: "valuationdSendDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            {record?.createdDate !== null
              ? formatOriginTime(record?.createdDate)
              : ""}
          </>
        );
      },
    },
    {
      title: "Ngày phê duyệt",
      dataIndex: "valuationdDate",
      key: "approvedDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>
            {" "}
            {record?.updateDate !== null
              ? formatOriginTime(record?.updateDate)
              : ""}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            {tab === "1" ?
              <Space>
                <a
                  className="action-table"
                  onClick={() =>
                    navigate(
                      `${routesConfig.detailCourseApproval}/${location.id}`,
                      {
                        state: {
                          ...record,
                          isCourseEndorse: true,
                          mode: 'view'
                        },
                      }
                    )
                  }
                >
                  <EyeOutlined />
                </a>

                <Dropdown
                  menu={{
                    items: tab === "1" ? optionsMenu : optionsAppraisedMenu,
                    onClick: ({ key }) => {
                      if (key === "3" || key === "5") {
                        navigate(
                          `${routesConfig.detailCourseApproval}/${location.id}`,
                          {
                            state: {
                              ...record,
                              isCourseEndorse: true,
                              tab: 3
                            },
                          }
                        );
                      }
                      if (key === "1") {
                        navigate(
                          `${routesConfig.detailCourseApproval}/${location.id}`,
                          {
                            state: {
                              ...record,
                              isCourseEndorse: true,
                              tab: 2,
                              mode: 'edit'
                            },
                          }
                        )
                      }
                      if (key === "2") {
                        setOpenApprovalContentModal(true)
                        setQuickReviewId(record?.id)
                      }
                    },
                  }}
                  placement="bottomRight"
                >
                  <Button>
                    <MoreOutlined />
                  </Button>
                </Dropdown>
              </Space> : <Space>
                <a
                  className="action-table"
                  onClick={() =>
                    navigate(
                      `${routesConfig.detailCourseApproval}/${location.id}`,
                      {
                        state: {
                          ...record,
                          isCourseEndorse: true,
                          mode: 'view'
                        },
                      }
                    )
                  }
                >
                  <EyeOutlined />
                </a>
              </Space>
            }
          </>
        );
      },
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Chờ phê duyệt
          <span
            className={`number-record ${tab === "1" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {pendingApprovalTotal || 0}
          </span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            dataTable={courseApprovalList}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            hideSelection={true}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={pendingApprovalTotal}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
            clearRowkey={clearRowkey}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          Đã phê duyệt
          <span
            className={`number-record ${tab === "2" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {approvedTotal || 0}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataTable={courseApprovalList}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={approvedTotal}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const saveMultiAssign = async (data: object) => {
    await assignMultiCourse(data).then((res) => {
      if (res.status === 200) {
        message.success("Phân công thành công khoá học");
        setIsOpened(!isOpened);
        getListData();
      }
    });
  };

  const statusList = [
    {
      value: "1",
      label: "Đã xuất bản",
    },
  ];

  return (
    <Layout className="page-header-group course-approval">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "create-course-plan-modal") {
            const listRowKey = selectedRowKeys.map((item: any) => {
              return item.id;
            });
            const data = {
              courseIds: listRowKey,
              assigners: values.lecturerName,
            };
            saveMultiAssign(data);
            getListData();
            setClearForm(true);
          }
          if (name === "filterForm") {
            getListData();
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterDataCourseApproval
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>

        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
        </div>
        <ApprovalMultipleContent
          title={`Xác nhận`}
          open={openApprovalContentModal}
          handleClose={() => setOpenApprovalContentModal(false)}
          quickReview={quickReview}
          selectedRowKeys={selectedRowKeys}
          getListDataProps={getListData}
          setSelectedRowKeys={() => setSelectedRowKeys([])}
          quickReviewId={quickReviewId}
          setClearRowkey={setClearRowkey}
        />
      </Form.Provider>
    </Layout>
  );
};

export default CourseApprovalPage;