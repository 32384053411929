import React, {FunctionComponent, ReactNode} from 'react';
import {Card} from "antd";
import "./custom-card.scss"


type CustomCardProps = {
  title?: string | ReactNode,
  children?: ReactNode,
  extra?: ReactNode,
  className?: string
}

const CustomCard: FunctionComponent<CustomCardProps> = ({title, children, className, extra}) => {
  return (
    <>
      <Card title={title} extra={extra} className={`wrapper-card ${className}`}>
        {children}
      </Card>
    </>
  );
};

export default CustomCard;
