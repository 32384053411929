import {
  Avatar,
  Badge,
  Flex,
  Form,
  Layout,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import moment from "moment";
import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import TableData from "../../../components/table-data/TableData";

type AutoMarkTableProps = {
  listStudent: any;
  setPageNumber: any;
  setPageSize: any;
  pageNumber: number;
  pageSize: number;
  loading: boolean;
  totalPage: number;
};

const AutoMarkTable = ({
  listStudent,
  setPageNumber,
  setPageSize,
  pageNumber,
  pageSize,
  loading,
  totalPage,
}: AutoMarkTableProps) => {
  const location = useLocation();
  const pathName = location.pathname;

  const dataColumnsReportCheckInDetail: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 5,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Tên sinh viên",
      dataIndex: "fullName",
      key: "fullName",
      width: 15,
      render: (_: any, record) => {
        return (
          <Flex align="center">
            <Avatar style={{ backgroundColor: "#f56a00" }}>
              {record?.auth_user?.first_name.charAt(0)}
            </Avatar>
            <Typography.Text className="pl-2">
              {record?.auth_user?.first_name +
                " " +
                record?.auth_user?.last_name}
            </Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Thời gian nộp bài",
      dataIndex: "created_at",
      key: "created_at",
      width: 10,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.created_at && (
              <Flex className="gap-1">
                <p>{moment(record?.created_at).format("DD/MM/yyyy")}</p>
                <p>{moment(record?.created_at).format("HH:mm:ss")}</p>
              </Flex>
            )}
          </Typography.Text>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 10,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.status ? (
              <Badge status="success" text="Đã chấm" />
            ) : (
              <Badge status="default" text="Chưa chấm" />
            )}
          </Typography.Text>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckInDetail.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsReportCheckInDetail.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => { };

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <TableData
          dataTable={listStudent}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default AutoMarkTable;
