import {StateCreator} from "zustand";
import {createRole, deleteRole, editRole, searchRoles, viewInfoRole} from "./rolesApi";
import {IDataListRoles} from "../../types/roleManagement";

export interface IRolesSlice {
  getRole: (dataSearch: any) => void;
  searchRole: (dataSearch: any) => void;
  createRole: (data: any) => void;
  editRole: (id: string, dataEdit: any) => void;
  viewDetailRole: (id: string) => void;
  deleteRole: (id: string) => void;
  roles: Array<IDataListRoles>;
  detailInfoRole?: IDataListRoles;
  loading: boolean,
  error: boolean,
  success: boolean,
  result: null,
  resultResponse: null,
}


export const rolesSlices: StateCreator<IRolesSlice> = (set) => ({
  roles: [],
  loading: false,
  error: false,
  success: false,
  result: null,
  resultResponse: null,
  detailInfoRole: undefined,
  getRole: async (dataSearch) => {
    set({loading: true,})
    try {
      const response = await searchRoles(dataSearch)
      const {data} = response.data
      set({roles: data.data, loading: false, error: false, success: true})
    } catch (error) {
      set({loading: false, error: true, success: false})
    }
  },
  searchRole: async (dataSearch) => {
    set({loading: true, error: false})
    try {
      const response = await searchRoles(dataSearch)
      const {data} = response.data.data
      set({roles: data, loading: false, error: false, success: true})
    } catch (error) {
      set({loading: false, error: true, success: false})
    }
  },
  createRole: async (dataCreate) => {
    set({resultResponse: null, loading: true, error: false})
    try {
      const response = await createRole(dataCreate)
      const {data} = response
      set({resultResponse: data, loading: false, error: false, success: true})
    } catch (error) {
      set({loading: false, error: true, success: false})
    }
  },
  editRole: async (id, dataEdit) => {
    set({resultResponse: null, loading: true, error: false})
    try {
      const response = await editRole(id, dataEdit)
      const {data} = response
      set({resultResponse: data, loading: false, error: false})
    } catch (error) {
      set({loading: false, error: true})
    }
  },
  viewDetailRole: async (id) => {
    set({detailInfoRole: undefined, loading: true, error: false})
    try {
      const response = await viewInfoRole(id)
      const {data} = response.data
      set({detailInfoRole: data, loading: false, error: false})
    } catch (error) {
      set({loading: false, error: true})
    }
  },
  deleteRole: async (id) => {
    set({loading: true, error: false})
    try {
      const response = await deleteRole(id)
      const {data} = response
      set({resultResponse: data, loading: false, error: false})
    } catch (error) {
      set({loading: false, error: true})
    }
  }
})
