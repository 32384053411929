import { MenuFoldOutlined, SortAscendingOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import { useAddLesson, useAutomaticScoreStore } from "../../../../stores/stores";
import "./index.scss";

import { SettingQuestionProps } from "../../../../types/course-construction";
import ResultExam from "./ResultExam";
import CustomCard from "../../../../components/custom-card/CustomCard";
import { SearchProps } from "antd/es/input";
import { useState } from "react";

const { Search } = Input;

const mockData = [
  {
    id: 1,
    name: "Nguyễn Văn A",
    list: [
      {
        mark: 0.5,
        name: "question1",
        question: "Câu hỏi 01: Figma ipsum component variant main layer?",
        answer: [
          {
            value: 0,
            label: "Figma ipsum component, Figma ipsum 123",
          },
          {
            value: 1,
            label: "Figma ipsum component, Figma ipsum 3",
          },
          {
            value: 2,
            label: "Figma ipsum component, Figma ipsum 1",
          },
        ],
      },
      {
        mark: 0.1,
        name: "question2",
        question: "Câu hỏi 02: Figma ipsum component variant main layer?",
        answer: [
          {
            value: 0,
            label: "Figma ipsum component, Figma ipsum 6",
          },
          {
            value: 1,
            label: "Figma ipsum component, Figma ipsum comp55onent",
          },
          {
            value: 2,
            label: "Figma ipsum component, Figma ipsum component",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Nguyễn Văn A",
    list: [
      {
        mark: 0.5,
        name: "question1",
        question: "Câu hỏi 01: Figma ipsum component variant main layer?",
        answer: [
          {
            value: 0,
            label: "Figma ipsum component, Figma ipsum component",
          },
          {
            value: 1,
            label: "Figma ipsum component, Figma ipsum component",
          },
          {
            value: 2,
            label: "Figma ipsum component, Figma ipsum component",
          },
        ],
      },
      {
        mark: 0.1,
        name: "question2",
        question: "Câu hỏi 02: Figma ipsum component variant main layer?",
        answer: [
          {
            value: 0,
            label: "Figma ipsum component, Figma ipsum component",
          },
          {
            value: 1,
            label: "Figma ipsum component, Figma ipsum component",
          },
          {
            value: 2,
            label: "Figma ipsum component, Figma ipsum component",
          },
        ],
      },
    ],
  },
];
const ExamStudentContent = () => {
  const navigate = useNavigate();
  const { flagCourseEditing, toggleFlagCourseEditing } = useAddLesson(
    (state) => state
  );
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [dataList, setDataList] = useState<any>(mockData);

  const [dataExam, setDataExam] = useState<any>(mockData[0].list);
  const [idStudent, setIdStudent] = useState(1);
  const { setScoreQuestion, scoreQuestion } =
  useAutomaticScoreStore((state) => state);

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };
  const handleViewExam = (listExam: any) => {
    setDataExam(listExam?.list);
    setIdStudent(listExam?.id);
  };

  const CommonListExam = ({ listQuestion, index }: any) => {
    const [value, setValue] = useState(1);

    return (
      <div key={index} className="mb-3">
        <Typography.Title level={5} className="bg-[#F9FAFB] p-3 rounded-lg">
          {listQuestion?.question}
        </Typography.Title>
        <Radio.Group value={value} className="py-2">
          <Space direction="vertical" className="flex flex-wrap">
            {listQuestion?.answer?.map((listAnswer: any, index: number) => (
              <Radio className="w-full" key={index} value={listAnswer?.value} >
                {listAnswer.label}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
        <Typography.Title level={5} className="bg-[#DCFAE6] p-3 rounded-lg">
          Điểm: {scoreQuestion[listQuestion?.name]}
        </Typography.Title>
      </div>
    );
  };
  return (
    <div className="exam-student">
      <Row className="flex" style={{ height: "100%" }}>
        <div
          className={`tabs-heading exam-student-editing ${
            flagCourseEditing ? "" : "tab-closed w-50px"
          }`}
        >
          <div className="border-b flex">
            <div
              className="setting-question-course__header__title justify-center"
              style={{ visibility: flagCourseEditing ? "visible" : "hidden" }}
            >
              Bài thi 01
            </div>
            <MenuFoldOutlined
              className="exam-student-editing-move-btn"
              rotate={flagCourseEditing ? 0 : 180}
              onClick={toggleFlagCourseEditing}
            />
          </div>
          <div
            className={`border-b p-4 text-center ${
              flagCourseEditing ? "visible" : "invisible"
            }`}
          >
            <Typography.Text>Mã bảng điểm: BD_001</Typography.Text>
          </div>
          <div
            className={`p-4 text-center ${
              flagCourseEditing ? "visible" : "invisible"
            }`}
          >
            <Typography.Text>Danh sách sinh viên</Typography.Text>
            <Flex className="pt-3">
              <Search
                placeholder="Tìm tên sinh viên"
                onSearch={onSearch}
                value={searchText}
                onChange={handleChangeSearch}
              />
              <Button className="ml-2">
                <SortAscendingOutlined />
              </Button>
            </Flex>
          </div>
          <div className={`${flagCourseEditing ? "visible" : "invisible"}`}>
            {dataList?.map((listExam: any, index: number) => (
              <Flex
                key={index}
                className={`hover:bg-[#E6F7FF] p-4 ${
                  listExam?.id === idStudent
                    ? "bg-[#E6F7FF] border-r-4 border-r-[#1890FF]"
                    : ""
                }`}
                onClick={() => handleViewExam(listExam)}
              >
                <Typography.Text className="pr-3">
                  {Number(index + 1)}
                </Typography.Text>
                <Typography.Text>{listExam?.name}</Typography.Text>
              </Flex>
            ))}
          </div>
        </div>
        <CustomCard className="card-info--wrapper flex-1">
          <div className="list-wrapper">
            <Card>
              <div className="border-b p-4">
                <Typography.Title level={4} className="!mb-0">
                  Bài làm của sinh viên
                </Typography.Title>
                <Typography.Text>
                  Chi tiết câu hỏi và đáp án trả lời của sinh viên
                </Typography.Text>
              </div>
              <div className="p-4">
                {dataExam.map((listQuestion: any, index: number) => (
                  <CommonListExam listQuestion={listQuestion} index={index} />
                ))}
              </div>
            </Card>
          </div>
        </CustomCard>
        <ResultExam dataExam={dataExam} setDataExam={setDataExam} />
      </Row>
    </div>
  );
};

export default ExamStudentContent;
