// ProtectRoute.tsx
import React, { useEffect, useState, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { checkPermission } from '../../utils/checkPermission';
import { LoadingComponent } from '../../components/loading/loading';

interface ProtectedRouteProps {
  permissions: string[];
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ permissions, children }) => {
  const [isAllowed, setIsAllowed] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchPermission = async () => {
      const result = await checkPermission(permissions);
      setIsAllowed(result);
    };

    fetchPermission();
  }, [permissions]);

  if (isAllowed === null) {
    return <LoadingComponent/>; // or a loading spinner
  }
  return <>{children}</>; // Phongnh
  return isAllowed ? <>{children}</> : <Navigate to="/error/403" />;
};

export default ProtectedRoute;
