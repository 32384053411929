import { Button, Form, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomCard from "../../custom-card/CustomCard";
import "./index.scss";
import {
  EyeOutlined,
  HeartOutlined,
  LikeOutlined,
  WechatWorkOutlined,
} from "@ant-design/icons";
import {
  followActionTopic,
  getAllCommentTopic,
  getDetailTopic,
  likeActionTopic,
  postTopicView,
} from "../../../service/blog-and-forum";
import { useAuthStore } from "../../../stores/stores";
import Discuss from "../../course-construction/content-course-construction/statistic-course/Discuss";
import ReactQuill from "react-quill";
import { FORMAT_REACT_QUILL } from "../../../constants/app";
import { DISLIKE_REACTION, LIKE_REACTION } from "../../../constants";

type Props = {
  detailData?: any;
};

export default function DetailTopic(props: Props) {
  const { detailData } = props;
  const [isComment, setIsComment] = useState(false);
  const [allRefetch, setAllRefetch] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allLoading, setAllLoading] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [listDiscussions, setListDiscussions] = useState<any>([]);
  const [detailTopic, setDetailTopic] = useState<any>({});

  const getDetailData = async (value: string) => {
    await getDetailTopic(value).then((res) => {
      if (res.status === 200) {
        setDetailTopic(res.data?.data);
      }
    });
  };

  const likeAction = async () => {
    setIsLoading(true);
    const data = {
      id: detailData?.id,
      reactionType: LIKE_REACTION,
    };
    await likeActionTopic(data).then((res) => {
      setIsLoading(false);
      getDetailData(detailData?.id);
    });
  };

  const followAction = async () => {
    setIsLoading(true);
    await followActionTopic(detailData?.id).then((res) => {
      setIsLoading(false);
      getDetailData(detailData?.id);
    });
  };

  const likeDebounce = () => {
    if (!isLoading) {
      likeAction();
    }
  };

  const followDebounce = () => {
    if (!isLoading) {
      followAction();
    }
  };

  useEffect(() => {
    if (detailData) {
      getDetailData(detailData?.id)
    }
  }, [detailData])

  const handleViewTopic = async () => {
    try {
      postTopicView(detailData?.id).then((result) => {
        getDetailData(detailData?.id);
      });
    } catch (error) { }
  };

  useEffect(() => {
    handleViewTopic();
  }, []);

  return (
    <>
      <Form.Provider onFormFinish={(name, { values, forms }) => { }}>
        <CustomCard className="card-info--wrapper block-card-detail-1 card-detail-topic">
          <div className="list-wrapper detail-topic-content">
            <div className="flex justify-between mt-3">
              <div
                className="text-3xl"
                dangerouslySetInnerHTML={{ __html: detailTopic?.name }}
              ></div>
              <Button onClick={followDebounce}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill={`${detailTopic?.isFollow ? "red" : "gray"}`}
                  width="13"
                  height="13"
                  viewBox="0 0 24 24"
                  className="-mt-[1px] mr-2"
                >
                  <path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" />
                </svg>
                Theo dõi
              </Button>
            </div>
            <div className="flex justify-start mt-2 gap-4">
              <img
                src={detailTopic?.authorAvatar}
                alt="create-people"
                className="rounded-full w-7 h-7"
              />
              <p className="mt-0.5">{detailTopic?.authorName}</p>
            </div>
            <div className="flex justify-start mt-2 gap-4">
              <div
                className="mt-0.5"
                dangerouslySetInnerHTML={{ __html: detailTopic?.description }}
              ></div>
            </div>
            <div className="flex justify-start gap-10 mt-1">
              <div className="flex">
                <LikeOutlined
                  className={`w-8 h-10 text-[23px] cursor-pointer ${detailTopic?.myReaction ? "text-blue-500" : ""
                    }`}
                  onClick={likeDebounce}
                />
                <div className="text-[12px] bg-gray-200 text-gray-400 rounded-xl h-[20px] px-3 py-2 flex items-center mt-1">
                  {detailTopic?.react}
                </div>
              </div>
              <div className="flex" onClick={() => setIsComment(true)}>
                <WechatWorkOutlined className="w-8 h-10 text-[23px] cursor-pointer" />
                <div className="text-[12px] bg-gray-200 text-gray-400 rounded-xl h-[20px] px-3 py-2 flex items-center mt-1">
                  {detailTopic?.comment}
                </div>
              </div>
              <div className="flex">
                <EyeOutlined className="w-8 h-10 text-[23px] cursor-pointer" />
                <div className="text-[12px] bg-gray-200 text-gray-400 rounded-xl h-[20px] px-3 py-2 flex items-center mt-1">
                  {detailTopic?.follow}
                </div>
              </div>
            </div>
          </div>
        </CustomCard>

        {isComment ? (
          <CustomCard className="card-info--wrapper block-card-detail-2 card-detail-topic mt-3">
            <div className="list-wrapper detail-comment-topic-content mt-3">
              <Discuss
                onRefetch={setAllRefetch}
                setAllLoading={setAllLoading}
                isSidebarCollapsed={isCollapsed}
                isCreated={detailTopic?.isCreated}
                idTopic={detailTopic?.id}
              />
            </div>
          </CustomCard>
        ) : null}
      </Form.Provider>
    </>
  );
}
