import { Form, Layout, TableColumnsType, TableProps, Typography } from "antd";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import TableData from "../../../../../../components/table-data/TableData";
import { getSettingAttendanceReportByID } from "../../../../../../service/setting-attendance";
import { DataType } from "../../../../../../types/listUsers";
import { formatDate } from "../../../../../../utils/converDate";

type TableQualityEvaluationProps = {
  idDocument: string;
  setRate: any;
  setHeadValue: any;
};

const TableQualityEvaluation = ({
  idDocument,
  setRate,
  setHeadValue,
}: TableQualityEvaluationProps) => {
  const location = useLocation();

  const [params, setParams] = useSearchParams();

  const [accountQualityEvaluation, setAccountQualityEvaluation] = useState<
    DataType[]
  >([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const pathName = location.pathname;

  const fetchData = async () => {
    try {
      const response = await getSettingAttendanceReportByID(idDocument);
      const { data } = response.data;
      setAccountQualityEvaluation(data.data.mooc_attendance);
      setTotalPage(data.data.total);
      console.log({ data });

      setLoading(false);
      setRate({
        title: data?.data?.name || "",
        idRate: data?.data?.id,
        total: data.data?.total,
        attendance: data?.data?.attendance,
        rating: data?.data?.rating || 0,
        idReport: data?.data?.mooc_course?.id,
      });
      setHeadValue([
        {
          label: "Tên khoá học",
          value: data?.data.mooc_course.name,
        },
        {
          label: "Chương",
          value:
            data?.data.mooc_course_block?.mooc_course_unit?.mooc_course_sequence
              .mooc_course_section.name,
        },
        {
          label: "Bài giảng",
          value:
            data?.data.mooc_course_block?.mooc_course_unit?.mooc_course_sequence
              .name,
        },
        {
          label: "Học liệu",
          value: data?.data?.mooc_course_block?.mooc_course_unit?.name,
        },
        {
          label: "Ngày học",
          value: data?.data.mooc_course_block?.mooc_course_unit?.published_date
            ? formatDate(
                data?.data.mooc_course_block?.mooc_course_unit?.published_date
              )
            : "Không có ngày học",
        },
        {
          label: "Thời gian học",
          value: data?.data.mooc_course_block?.mooc_course_unit
            ?.schedule_start_date
            ? formatDate(
                data?.data.mooc_course_block?.mooc_course_unit
                  ?.schedule_start_date
              )
            : "Không có thời gian học",
        },
        {
          label: "Sĩ số",
          value: `${data?.data.attendance}/${data.data.total}`,
        },
        {
          label: "Tính chất điểm danh",
          value: "Tiết học",
        },
      ]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageSize, pageNumber]);

  const dataColumnsQualityEvaluation: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Họ và tên sinh viên",
      dataIndex: "fullName",
      key: "fullName",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user
              ?.first_name +
              " " +
              record?.auth_user_mooc_attendance_student_idToauth_user
                ?.last_name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Ngày tháng năm sinh",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user
              ?.auth_userprofile?.date_of_birth &&
              formatDate(
                record?.auth_user_mooc_attendance_student_idToauth_user
                  ?.auth_userprofile?.date_of_birth
              )}
          </Typography.Text>
        );
      },
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone_number",
      key: "phone_number",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {
              record?.auth_user_mooc_attendance_student_idToauth_user
                ?.auth_userprofile?.phone_number
            }
          </Typography.Text>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user_mooc_attendance_student_idToauth_user?.email}
          </Typography.Text>
        );
      },
    },
    {
      title: "Điểm danh",
      dataIndex: "courseType",
      key: "courseType",
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            <div
              className={`${
                record?.result ? "table-checkin-1" : "table-checkin-2"
              }`}
            >
              {record?.result ? "Có mặt" : "Vắng mặt"}
            </div>
          </div>
        );
      },
    },
  ];

  const defaultCheckedList = dataColumnsQualityEvaluation.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsQualityEvaluation.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {};

  const handleSubmitSearch = () => {};

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <TableData
          dataTable={accountQualityEvaluation}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default TableQualityEvaluation;
