import { Button, Checkbox, Col, Collapse, Drawer, Form, message, Modal, Radio, Row, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import TableData from '../../../table-data/TableData'
import "./index.scss"
import { isEmpty, size } from 'lodash'
import { getTableDataDetailSequence } from '../../../../service/content-list-course/contentListCourseApi'
import { formatDate } from '../../../../utils/converDate'
import FormItemDropdown from '../../../form-dropdown/FormItemDropdown'
import TextArea from 'antd/es/input/TextArea'
import { DEFAULT_MAX_LENGTH_COURSE_TEXTAREA } from '../../../../constants'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { getAllEnterpriseCustomer } from '../../../../service/list-account-users/listAccountUsersApi'
import { approveRequestJoinMooc, getAccountByUniversity, getAllEnterpriseUniversity, getAllEnterpriseUniversityVer2 } from '../../../../service/management-participate-platform'

type Props = {
  open: boolean
  onClose: () => void
  getListData: () => void
  detailData: any
}

export default function ConfirmRequestParticipateDrawer(props: Props) {
  const { open, onClose, detailData, getListData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [listAccount, setListAccount] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [listEnterPrise, setListEnterPrise] = useState<any>([])
  const [listCheckbox, setListCheckbox] = useState<any>({
    createSchoolInfo: false,
    createAccountInfo: false,
    createCourseInfo: false
  })

  const getListDataDetail = async () => {
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder,
      sequenceId: detailData?.id
    }
    await getTableDataDetailSequence(data).then((res) => {
      setListTableDataDetail(res.data?.data?.content)
    })
  }

  const onSave = async (values: any) => {
    const data = {
      id: detailData?.id,
      universityId: listCheckbox.createSchoolInfo ? values.enterpriseId : null,
      userIds: listCheckbox.createAccountInfo && values.accountId ? [values.accountId] : null,
      isCreatedPage: listCheckbox.createCourseInfo,
      description: listCheckbox.createCourseInfo ? values.createAccount : ""
    }
    await approveRequestJoinMooc(data).then((res) => {
      if (res.status === 200) {
        onClose()
        message.success("Lưu thông tin thành công")
        getListData()
      }
    })
  }

  const getAllEnterprise = async () => {
    const data = {
      page: 1,
      size: 10000
    }
    // await getAllEnterpriseUniversity(data).then((res) => {
    //   if (res.status === 200) {
    //     const arr = res.data?.data?.content?.map((item: any) => ({
    //       id: item.universityId,
    //       name: item.name
    //     }))
    //     setListEnterPrise(arr)
    //   }
    // })
    await getAllEnterpriseUniversityVer2({}).then((res) => {
      if (res.status === 200) {
        const arr = res.data?.data?.map((item: any) => ({
          id: item.universityId,
          name: item.name
        }))
        setListEnterPrise(arr)
      }
    })
  }

  const getListAccount = async () => {
    await getAccountByUniversity(detailData?.universityId).then((res) => {
      if (res.status === 200) {
        setListAccount(res.data?.data)
      }
    })
  }

  useEffect(() => {
    if (open) {
      getListDataDetail();
      getAllEnterprise()
      getListAccount()
    }
  }, [open]);

  return (
    <Form.Provider onFormFinish={(name, { values, forms }) => {
      if (name === "confirm-participate-account") {
        Modal.confirm({
          title: `Xác nhận xử lý đăng ký`,
          content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra trước khi gửi`,
          onOk: () => {
            onSave(values)
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>

      <Drawer
        title={"Xử lý yêu cầu tham gia nền tảng"}
        open={open}
        onClose={onClose}
        className='management-participate-detail-drawer'
        width={"60%"}
        bodyStyle={{ padding: "0px" }}
        extra={
          <Space>
            <Button onClick={onClose}>Đóng</Button>
            <Button type="primary" onClick={() => form.submit()}>Lưu</Button>
          </Space>
        }
      >
        <Form
          className="active-settings-chapter"
          layout={"vertical"}
          form={form}
          name="confirm-participate-account"
        >
          <div className="m-5">
            <Row gutter={24}>
              <Col span={12}>
                <div className='border rounded-lg p-3 '>
                  <Typography.Title level={5}>Chi tiết thông tin của cơ sở yêu cầu</Typography.Title>
                  <Collapse defaultActiveKey={["1", "2", "3"]}>
                    <Panel
                      header="Thông tin cơ sở đào tạo"
                      key="1"
                      className="setting-chapter-title">
                      <div className='m-5'>
                        <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Cơ quan quản lý</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.organizationName}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Tên viết tắt</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.acronym}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Tên cơ sở đào tạo</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.name}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Loại trường</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.trainingTypeName}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Loại hình</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.universityTypeName}</p></span>
                      </div>
                    </Panel>

                    <Panel
                      header="Địa chỉ cơ sở đào tạo"
                      key="2"
                      className="setting-chapter-title">
                      <div className='m-5'>
                        <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Thành phố/Tỉnh</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.provinceName}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Quận/Huyện</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.districtName}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Phường/Xã</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.wardName}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Địa chỉ chi tiết</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.address}</p></span>
                      </div>
                    </Panel>

                    <Panel
                      header="Địa chỉ cơ sở đào tạo"
                      key="3"
                      className="setting-chapter-title">
                      <div className='m-5'>
                        <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Chức vụ</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.userContactPositionName}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Số điện thoại</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.userContactPhone}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Email</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.userContactEmail}</p></span>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
              <Col span={12}>
                <div className='p-3 '>
                  <Typography.Title level={5}>Xử lý yêu cầu tham gia nền tảng</Typography.Title>
                  <hr className='mt-2 mb-2' />

                  <Checkbox value={listCheckbox.createSchoolInfo} onChange={(e) => setListCheckbox({ ...listCheckbox, createSchoolInfo: e.target.checked })}>
                    Tạo thông tin Trường đại học
                  </Checkbox>
                  <Form.Item name="enterpriseId" className='mt-2'>
                    <FormItemDropdown
                      placeholder="Chọn trường đại học"
                      options={listEnterPrise}
                      disabled={!listCheckbox.createSchoolInfo}
                    />
                  </Form.Item>
                  <hr className='mb-2' />

                  <Checkbox value={listCheckbox.createAccountInfo} onChange={(e) => setListCheckbox({ ...listCheckbox, createAccountInfo: e.target.checked })}>
                    Tạo tài khoản Quản trị cơ sở
                  </Checkbox>
                  <Form.Item name="accountId" className='mt-2'>
                    <FormItemDropdown
                      placeholder="Chọn tài khoản"
                      options={listAccount}
                      disabled={!listCheckbox.createAccountInfo}
                    />
                  </Form.Item>
                  <hr className='mb-2' />

                  <Checkbox value={listCheckbox.createCourseInfo} onChange={(e) => setListCheckbox({ ...listCheckbox, createCourseInfo: e.target.checked })}>
                    Khởi tạo trang danh sách khóa học cho trường đại học
                  </Checkbox>
                  <Form.Item name="createAccount" className='mt-2'>
                    <TextArea
                      className="text-area-answer"
                      showCount
                      disabled={!listCheckbox.createCourseInfo}
                      placeholder="Nhập mô tả"
                      maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
                      autoSize={{ minRows: 5, maxRows: 10 }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Drawer>
    </Form.Provider>
  )
}
