// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.survey-managment-question-view .input-header-title::placeholder {
  color: black; }

.survey-managment-question-view .containerImage {
  width: 325px;
  height: 210px;
  margin: 12px 0;
  display: flex;
  /* Đảm bảo nội dung con được căn giữa */
  justify-content: center;
  /* Căn giữa theo chiều ngang */
  align-items: center;
  /* Căn giữa theo chiều dọc */ }

.survey-managment-question-view .containerImage img {
  max-width: 100%;
  /* Giới hạn chiều rộng tối đa của ảnh không vượt quá thẻ cha */
  max-height: 100%;
  /* Giới hạn chiều cao tối đa của ảnh không vượt quá thẻ cha */
  object-fit: contain;
  /* Đảm bảo ảnh vừa vặn mà không mất tỉ lệ */ }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-respond/component/detail-question/question-type-2/detail.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EAEI,YAAY,EAAA;;AAFhB;EAMI,YAAsB;EACtB,aAAsB;EACtB,cAAuB;EACvB,aAAqB;EACrB,uCAAA;EACA,uBAAuB;EACvB,8BAAA;EACA,mBAAuB;EACvB,4BAAA,EAAA;;AAdJ;EAkBI,eAAgB;EAChB,8DAAA;EACA,gBAAgB;EAChB,6DAAA;EACA,mBAAmB;EACnB,2CAAA,EAAA","sourcesContent":[".survey-managment-question-view {\n  .input-header-title::placeholder {\n    color: black;\n  }\n\n  .containerImage {\n    width          : 325px;\n    height         : 210px;\n    margin         : 12px 0;\n    display        : flex;\n    /* Đảm bảo nội dung con được căn giữa */\n    justify-content: center;\n    /* Căn giữa theo chiều ngang */\n    align-items    : center;\n    /* Căn giữa theo chiều dọc */\n  }\n\n  .containerImage img {\n    max-width : 100%;\n    /* Giới hạn chiều rộng tối đa của ảnh không vượt quá thẻ cha */\n    max-height: 100%;\n    /* Giới hạn chiều cao tối đa của ảnh không vượt quá thẻ cha */\n    object-fit: contain;\n    /* Đảm bảo ảnh vừa vặn mà không mất tỉ lệ */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
