import {
  ClearOutlined,
  DownOutlined,
  FilterOutlined,
  SearchOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, DatePicker, Form, Input, Select } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
const { RangePicker } = DatePicker;

interface NotificationFilterProps {
  onChange?: (value?: any) => void;
}

function NotificationFilter({ onChange = () => {} }: NotificationFilterProps) {
  const [values, setValues] = useState<any>();
  const [dates, setDates] = useState<any>();
  const [dateString, setDateString] = useState<string[]>([]);
  const [filterToggle, setFilterToggle] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleFilterDate = () => {
    setValues((prevValue: any) => ({ ...prevValue, date: dates }));
  };
  // useEffect(() => {
  //   onChange(values);
  // }, [values]);

  const handleRangePickerChange = (
    dates: any,
    dateStrings: [string, string]
  ) => {
    setDates(dates);
    setDateString(dateStrings);
  };

  const renderPrevDate = () => {
    if (isEmpty(dates)) return "";

    return (
      <div className="flex flex-1">
        <div className="date-preview">
          <span className="font-size-16">
            {moment(dateString[0]).format("MMM DD, YYYY")}
          </span>
        </div>
        <span className="ml-1 mr-1">-</span>
        <div className="date-preview">
          <span className="font-size-16">
            {moment(dateString[1]).format("MMM DD, YYYY")}
          </span>
        </div>
      </div>
    );
  };

  const filterSeenOptions = [
    {
      value: 0,
      label: "Tất cả",
    },
    {
      value: 1,
      label: "Thông báo chưa xem",
    },
    {
      value: 2,
      label: "Thông báo đã xem",
    },
  ];

  const onFinish = (values: any) => {
    onChange(values);
  };

  const handleDelField = () => {
    form.resetFields();
    onFinish({});
  };

  return (
    <>
      <Form
        // onValuesChange={(value: any, values: any) => {
        //   setValues((preValue: any) => ({ ...preValue, ...values }));
        // }}
        onFinish={onFinish}
        form={form}
      >
        <div className="flex align-center justify-between">
          <h3 className="my-notufy-title">Thông báo của tôi</h3>
          <div className="flex align-center gap-16">
            <span
              style={{ marginLeft: 6 }}
              className="filter-btn"
              onClick={() => setFilterToggle(!filterToggle)}
            >
              Bộ lọc
              {filterToggle ? <UpOutlined /> : <DownOutlined />}
            </span>
            <Form.Item name="keyword">
              <Input
                prefix={
                  <SearchOutlined style={{ color: "#667085", fontSize: 20 }} />
                }
                className="height-44 w-250"
                placeholder="Nhập từ khoá cần tìm"
              />
            </Form.Item>
          </div>
        </div>
        {filterToggle ? (
          <div className="group-filter flex align-center gap-24">
            <Form.Item name="seen" initialValue={0}>
              <Select
                className="font-weight-6 w-200"
                options={filterSeenOptions}
              />
            </Form.Item>
            <Form.Item name="date">
              <RangePicker
                value={dates}
                id="range-picker"
                className="range-picker"
                popupClassName="custom-date-picker"
                onChange={(_value, dateString: any) => setDates(_value)}
                placeholder={["Ngày bắt đầu", "Ngày kết thúc"]}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Button className="search-btn" htmlType="submit">
              <FilterOutlined />
              Lọc
            </Button>
            <Button className="del-field-search" onClick={handleDelField}>
              <ClearOutlined />
              Xoá tất cả
            </Button>
          </div>
        ) : null}
      </Form>
    </>
  );
}

export default NotificationFilter;
