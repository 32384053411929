import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { Button, Dropdown, Form, Layout, message, Popconfirm, Space, Switch, TableColumnsType, TableProps } from "antd";
import PageHeader from "../../components/page-header/PageHeader";
import FilterTable from "../list-ministries/components/filter-table/FilterTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataType } from "../../types/listUsers";
import { get, isEmpty } from "lodash";
import {
  changeStatusAccount,
  getOffices,
  getRoles,
  getUnits
} from "../../service/list-account-users/listAccountUsersApi";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, KeyOutlined, MoreOutlined } from "@ant-design/icons";
import TableData from "../../components/table-data/TableData";
import { changeStatusAccountMinistry, createAccountMinistry, deleteAccountMinistry, getAllMinistryAccount, getDataMinistry } from "../../service/list-acoount-ministry/listAccountMinistry";
import { validateFormCreate } from "./constants/data";
import FormCreateMinistry from "./components/form-create-ministry/FormCreateMinistry";
import FormFilter from "./components/filter-group/FormFilter";
import { HelpService } from '../../service/helper.service';
import { getCurrentPageFromLink } from '../../utils/getPageFromLink';
import { combineArray } from "../../utils/arrays";
import { useAppStore } from "../../stores/stores";
import { formatOriginTime } from '../../utils/converDate';
import DetailDepartmentalUserAccountDrawer from '../../components/drawer/departmental-account';
import ChangePassDepartmentDrawer from '../../components/drawer/departmental-account/change-password';
import CommonModal from '../../components/modal/common';


type ListMinistriesProps = {
  title?: string
}


const ListMinistries = ({ title }: ListMinistriesProps) => {
  const helpService = new HelpService();
  const [params, setParams] = useSearchParams()
  const { setDataMinistries } = useAppStore()

  const [isOpenedCreateMinistries, setIsCreateMinistries] = useState<boolean>(false)
  const [accountMinistries, setAccountMinistries] = useState<DataType[]>([])
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [isOpenLecturerAccountAdmin, setIsOpenLecturerAccountAdmin] = useState<boolean>(false)
  const [isOpenChangePass, setIsOpenChangePass] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("desc");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("dateJoined")
  const [accountUsers, setAccountUsers] = useState<DataType[]>([])
  const [totalRecords, setTotalRecords] = useState<number>(0)

  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: []
  })
  const [filterData, setFilterData] = useState<any>({
    universityId: null,
    status: null,
    approvedDate: null,
  });
  const [loading, setLoading] = useState<boolean>(true)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [isOpenBasicAccountAdmin, setIsOpenBasicAccountAdmin] = useState<boolean>(false)
  const [isOpenedConfirmDelete, setIsOpenedConfirmDelete] = useState<boolean>(false)

  const [detailData, setDetailData] = useState<any>()

  const options = [
    {
      key: "0",
      label: "Sửa tài khoản",
      icon: <EditOutlined />,
    },
    {
      key: "1",
      label: "Đổi mật khẩu",
      icon: <KeyOutlined />,
    },
    {
      key: "2",
      label: "Xoá tài khoản",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ]

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      keyword: searchValue.trim() || undefined,
      enterpriseId: null,
      organizationId: filterData.universityId ?? null,
      createdFromDate: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      createdToDate: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      status: !filterData.status ? null : (filterData.status === 1 ? false : true),
    };

    await getAllMinistryAccount(data)
      .then((res) => {
        const { data } = res.data
        setAccountUsers(data.data)
        setTotalRecords(data.total);

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getListData()
  }, [pageSize, pageNumber, statusOrder]);


  const dataColumnsMinistries: TableColumnsType<DataType> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record: any, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      key: 'username',
      sorter: true,
      width: 30
    },
    {
      title: 'Họ và tên người dùng',
      dataIndex: 'name',
      key: 'name',
      width: 30
    },
    {
      title: 'Đơn vị công tác',
      dataIndex: 'unitName',
      key: 'unitName',
      width: 20
    },
    {
      title: 'Vai trò',
      dataIndex: 'vaiTros',
      key: 'vaiTros',
      render: (_: any, record: any) => {
        return (
          record?.roles?.map((item: any, index: number) => (
            <p key={index} className={`border rounded-md w-fit p-1 mt-1 bg-gray-100 break-all`}>{item?.name}</p>
          ))
        )
      },
      width: 30
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record: any) => {
        return (
          <>{record?.dateJoined ? formatOriginTime(record?.dateJoined) : null}</>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      // shouldCellUpdate(record, prevRecord) {
      //   return true
      // },
      render: (_: any, record: any) => {
        const handleChange = (checked: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
          record.isActive = checked
        }

        const confirm = (id: number) => {
          const data = {
            usedId: record?.id,
            isActive: record?.isActive
          }
          changeStatusAccountMinistry(data).then((res) => {
            if (res.status === 200) {
              // record.isActive = !record?.isActive;
              helpService.successMessage(`${record?.isActive ? 'Mở khóa' : 'Khóa'} tài khoản thành công`)
              getListData()
            }
          })
        }

        const cancel = () => {
        }

        return (
          <Popconfirm
            description={`Bạn có chắc chắn muốn ${record.isActive ? 'khóa' : 'mở khóa'} tài khoản?`}
            onConfirm={() => confirm(get(record, 'id'))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title="">
            <Switch defaultChecked={record.isActive}
              checked={record.isActive}
              onChange={handleChange} />
          </Popconfirm>
        )
      },
      width: 20
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'operation',
      render: (_, record) => {
        const location = {
          id: `${record.id}`
        }

        return (
          (
            <Space className="flex justify-center">
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenLecturerAccountAdmin(true)
                  setDetailData(record)
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: options,
                  onClick: ({ key }) => {
                    if (key === '0') {
                      setIsOpened(true)
                      setDetailData(record)
                    }
                    if (key === '1') {
                      setIsOpenChangePass(true)
                      setDetailData(record)
                    }
                    if (key === '2') {
                      setIsOpenedConfirmDelete(true)
                      setDetailData(record)
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          )
        )
      },
      fixed: 'right',
      width: 15,
    }
  ]

  const defaultCheckedList = dataColumnsMinistries.map((item) => item.key as string)

  const [checkedList, setCheckedList] = useState(defaultCheckedList)

  useEffect(() => {
    const pageLink = getCurrentPageFromLink(location.search)
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink))
    } else {
      const fetchDataOptions = async () => {
        const response = await Promise.all([getRoles(), getUnits(), getOffices()])
        const dataRoles = response[0].data.data
        const dataUnits = response[1].data.data
        const dataOffices = response[2].data.data
        setDataOptions({
          ...dataOptions,
          dataOffices: dataOffices,
          dataUnits: dataUnits,
          dataRoles: dataRoles
        })

      }
      fetchDataOptions()
    }
  }, []);


  const columnChecked = useMemo(() => {
    return dataColumnsMinistries.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string)
    }))
  }, [checkedList])


  const handleOpenCreate = () => {
    setIsOpened(true);
  }

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter)
  }


  const handleClose = () => {
    setIsOpened(false)
    form.resetFields()
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setParams({ [name]: value })
    setSearchValue(value)
    if (value.length === 0) {
      setSearchValue("")
      setParams()
    }
  }

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      getListData()
    }
  }

  const handleSubmitSearch = () => {
    getListData()
  }

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      universityId: null,
      status: null,
      approvedDate: null,
    });
  }

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const onFinishCreate = (values: any) => {
    createAccountMinistry(values).then(res => {
      const { data, statusCode } = res.data
      if (statusCode === 200) {
        helpService.successMessage(get(data, "message", "Tạo tài khoản thành công"))
        form.resetFields()
        getListData()
        handleClose()
      }
    }).catch(err => {
    })
  }

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const deleteAccount = async () => {
    await deleteAccountMinistry(detailData?.id).then((res) => {
      if (res.status === 200) {
        setIsOpenedConfirmDelete(false)
        message.success("Xóa tài khoản thành công")
        getListData()
      }
    })
  }

  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === 'createMinistry') {
          const { createMinistry } = forms
          const data = createMinistry.getFieldsValue(validateFormCreate)
          onFinishCreate(data)
        }
        if (name === "filterForm") {
          getListData()
        }
      }}>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterTable handleOpen={handleOpenCreate} searchValue={searchValue}
              dataColumns={dataColumnsMinistries}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput} />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>
        <TableData dataTable={accountUsers} dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          handleTableChangeProps={handleTableChange} />

        <FormCreateMinistry open={isOpened} handleClose={handleClose} fetchData={getListData} detailData={detailData}/>
        <DetailDepartmentalUserAccountDrawer
          open={isOpenLecturerAccountAdmin}
          onClose={() => setIsOpenLecturerAccountAdmin(false)}
          detailData={detailData}
        />

        <ChangePassDepartmentDrawer
          open={isOpenChangePass}
          onClose={() => setIsOpenChangePass(false)}
          detailData={detailData}
          fetchData={getListData}
        />

        <CommonModal
          open={isOpenedConfirmDelete}
          title={'Xác nhận xóa tài khoản'}
          desc={`Vui lòng xác nhận xóa tài khoản Người dùng cấp bộ`}
          closeIcon={true}
          okText={'Xóa tài khoản'}
          cancelText={"Huỷ"}
          icon={<ExclamationCircleOutlined style={{ color: "orange" }} />}
          confirm={deleteAccount}
          closeModal={() => setIsOpenedConfirmDelete(false)}
          className="modal-delete-common"
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListMinistries;
