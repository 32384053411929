import { Button, Flex, Layout, message } from "antd";
import PageHeaderCommon from "../../../../../components/page-header-common/PageHeaderCommon";
import TableReportCheckInDetail from "./TableReportCheckInDetail";
import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "../../../../../config/routes";
import {
  getListReportDocumentAttendance,
  updateReportSettingAttendance,
} from "../../../../../service/setting-attendance";
import { useStudyManagementStore } from "../../../../../stores/stores";

const { Content } = Layout;
const imgPath = "/images/";

const ReportCourseDocument = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [report, setReport] = useState({
    title: "",
    idReport: ""
  });
  const [paramStatus, setParamStatus] = useState<any>([]);
  const [idCourse, setIdCourse] = useState("");

  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();
  const { setTabAttendance } = useStudyManagementStore((state) => state);

  const listBreadcrumb = [
    {
      title: "Thực hiện điểm danh",
    },
    {
      title: report?.title,
    },
  ];

  const handleBack = () => {
      setTabAttendance("3")
      navigate(-1)
  }
  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title={report?.title}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={handleBack}
      />
      <TableReportCheckInDetail
        idDocument={params?.id}
        setReport={setReport}
        paramStatus={paramStatus}
        setParamStatus={setParamStatus}
        setIdCourse={setIdCourse}
      />
    </Content>
  );
};

export default ReportCourseDocument;
