import {
  Button,
  Pagination,
  PaginationProps,
  Space,
  Table,
  TableColumnsType,
} from "antd";
import React, { FunctionComponent, useState } from "react";
import "./TableApproved.scss";
import { ApprovalType } from "../../../../../types/survey-browse";
import moment from "moment";
import {
  EyeOutlined,
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

type tableDataProps = {
  activeTabKey: string;
  listSurveyApprove: ApprovalType[];
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  setPageNumber?: (pageNumber: number) => void;
  setPageSize?: (pageNumber: number) => void;
  locale: object;
  loading: boolean | false;
};
const TableApproved: FunctionComponent<tableDataProps> = ({
  activeTabKey,
  listSurveyApprove,
  totalRecords,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  locale,
  loading,
}) => {
  const navigation = useNavigate();
  const [totalPage, setTotalPage] = useState(totalRecords);
  const formatDateV2 = (date: string) => {
    return moment(date).format("DD/MM/yyyy");
  };
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên đợt khảo sát",
      sorter: (a, b) => a.surveyName.localeCompare(b.surveyName),
      width: 14,
      dataIndex: "surveyName",
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record.surveyName}</span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Ngày gửi phê duyệt",
      width: 6,
      dataIndex: "submission_date",
      render: (_: any, record) => {
        return (
          <>
            {record.submissionDate ? formatDateV2(record.submissionDate) : null}
          </>
        );
      },
    },
    {
      title: "Ngày phê duyệt",
      width: 6,
      dataIndex: "approvalDate",
      render: (_: any, record) => {
        return (
          <>{record.approvalDate ? formatDateV2(record.approvalDate) : null}</>
        );
      },
    },
    {
      title: "",
      width: 1,
      fixed: "right",
      render: (_: any, record) => {
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigation(
                    `/survey-training/survey-detail/${record.surveyId}`,
                    {
                      state: {
                        mode: "view",
                        keyTab: activeTabKey,
                        modeback: "backState",
                        backLink: `/survey-training/browse-survey`,
                      },
                    }
                  );
                }}
              >
                <EyeOutlined />
              </a>
            </Space>
          </>
        );
      },
    },
  ];
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
      setTotalPage(totalRecords);
    }
  };

  return (
    <div className="table-wrapper">
      <Table
        size="middle"
        tableLayout="fixed"
        pagination={false}
        columns={dataColumns}
        dataSource={listSurveyApprove}
        rowKey={"id"}
        scroll={{ x: 1500, y: 600 }}
        locale={locale}
        loading={loading}
      />
      <Pagination
        total={totalRecords}
        showSizeChanger
        pageSize={pageSize}
        current={pageNumber}
        className="pagination-table"
        onChange={onChange}
        itemRender={itemRender}
        locale={{
          items_per_page: "/ trang",
          jump_to: "Đi đến trang",
          page: "",
        }}
        showQuickJumper
        showTotal={(total) => `Tổng số ${total} bản ghi`}
      />
    </div>
  );
};

export default TableApproved;
