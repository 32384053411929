import React, { FunctionComponent, useState } from "react";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import "./filter-group.scss";
import { FormInstance } from "antd/lib";
// import { dataStatus } from '../../../pages/list-user/constants/data';
import { get } from "lodash";

import type { DatePickerProps } from "antd";
import { Dayjs } from "dayjs";
import { FilterData } from "../../../../types/course-construction";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  clearFilter: () => void;
  onFinish?: () => void;
  dataOptions?: any;
  tab: Number;
  filterData: FilterData;
};

type RangeValue = any | null;

const FormFilter: FunctionComponent<FormFilterProps> = ({
  handleCloseFilter,
  onFinish,
  form,
  showFilter,
  clearFilter,
  dataOptions,
  tab,
  filterData,
  setFilterData,
}) => {
  const { RangePicker } = DatePicker;
  // const [createAt, setCreateAt] = useState<RangeValue>(null);
  // const [publishAt, setPublishAt] = useState<RangeValue>(null);

  const dataOptionsType = [
    {
      id: 1,
      name: "Tự triển khai",
    },
    {
      id: 2,
      name: "Hợp tác xây dựng",
    },
  ];
  const dataOptionsStatus = [
    {
      id: 1,
      name: "Bản nháp",
    },
    {
      id: 2,
      name: "Riêng tư",
    },
    {
      id: 3,
      name: "Chờ duyệt",
    },
    {
      id: 4,
      name: "Bị trả lại",
    },
    {
      id: 5,
      name: "Chờ thẩm định",
    },
    {
      id: 6,
      name: "Thẩm định trả lại",
    },
    {
      id: 7,
      name: "Đã thẩm định",
    },
    {
      id: 8,
      name: "Đã duyệt",
    },
    {
      id: 9,
      name: "Chờ phê duyệt",
    },
    {
      id: 10,
      name: "Đã phê duyệt",
    },
    {
      id: 11,
      name: "Phê duyệt trả lại",
    },
    {
      id: 12,
      name: "Chờ xuất bản",
    },
    {
      id: 13,
      name: "Đã xuất bản",
    },
  ];

  return (
    <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
      <Form form={form} name="filterForm" onFinish={onFinish}>
        <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
          <Col
            xl={{ span: 4 }}
            md={{ span: 5 }}
            sm={{ span: 5 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Ngày tạo</Typography.Title>
            <Form.Item name="createDate">
              <RangePicker
                placeholder={["Bắt đầu", "Kết thúc"]}
                format="DD-MM-YYYY"
                value={filterData.createDate}
                onChange={(_value, dateString: any) => {
                  setFilterData(dateString, "createDate");
                }}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 4 }}
            md={{ span: 5 }}
            sm={{ span: 5 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Ngày xuất bản</Typography.Title>
            <Form.Item name="publicDate">
              <RangePicker
                placeholder={["Bắt đầu", "Kết thúc"]}
                format="DD-MM-YYYY"
                value={filterData.publicDate}
                onChange={(_value, dateString: any) =>
                  setFilterData(dateString, "publicDate")
                }
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 4 }}
            md={{ span: 5 }}
            sm={{ span: 5 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Trạng thái</Typography.Title>
            <Form.Item name="status">
              <FormItemDropdown
                modeDropdown="multiple"
                placeholder="Chọn trạng thái"
                options={dataOptionsStatus}
                onChange={(option) => setFilterData(option, "status")}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 4 }}
            md={{ span: 5 }}
            sm={{ span: 5 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Loại khóa học</Typography.Title>
            <Form.Item name="courseType">
              <FormItemDropdown
                modeDropdown="multiple"
                placeholder="Chọn loại khóa học"
                options={dataOptionsType}
                onChange={(option) => setFilterData(option, "courseType")}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 3 }}
            md={{ span: 9 }}
            sm={{ span: 9 }}
            xs={{ span: 24 }}
            className="flex align-end"
          >
            <div className="group-btn--filter">
              <Button
                className="btn btn-primary filter-btn"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <FilterOutlined />
                <Typography.Text>Lọc</Typography.Text>
              </Button>
              <Button
                className="btn btn-outlined clear-filter-btn"
                onClick={clearFilter}
              >
                <DeleteOutlined />
                <Typography.Text>Xóa bộ lọc</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormFilter;
