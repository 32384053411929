// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-management-list .page-header-group--layout {
  z-index: 1; }

.course-management-list .course-name-link {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1677FF;
  cursor: pointer; }
  .course-management-list .course-name-link:hover {
    text-decoration: underline; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-management/course-list/CourseList.scss"],"names":[],"mappings":"AAAA;EAEM,UAAU,EAAA;;AAFhB;EAMI,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EAEd,eAAe,EAAA;EAZnB;IAeM,0BAA0B,EAAA","sourcesContent":[".course-management-list {\n  .page-header-group--layout {\n      z-index: 1;\n    }\n\n  .course-name-link {\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 22px;\n    text-align: left;\n    color: #1677FF;\n\n    cursor: pointer;\n\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
