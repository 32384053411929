import React from 'react';
import {Col, Row, Select, Spin, TableColumnsType} from "antd";
import {BarStackedChartProps, LineChartProps, PieChart} from "../../../../../../types/static-report";
import StatisticalReportCard from '../../StatisticalReportCard';
import TableWithTotals from "../../../../../../components/table-with-totals/TableWithTotals";
import Empty from "../../../../../../components/empty";
import {handleExportReport} from "../../../../../../utils/staticReport";
import {
  exportTableUniversityDownloadResources,
  exportTableUniversitySearchHistory,
  exportTableUniversitySearchKeywordCount
} from "../../../../../../service/statistical-report";
import {staticReportStore} from "../../../../../../stores/stores";
import dayjs from "dayjs";
import {createTableData} from "../../../../for-instructors/components/table/tableConvertData";


type BehaviorTableProps = {
  loading: boolean,
  searchKeywordUniversity: PieChart[],
  searchHistoryUniversity: BarStackedChartProps[],
  unitDownloadResource: LineChartProps[],
  unitPreviewResource: LineChartProps[],
  unitShareResource: LineChartProps[],
  setActionModuleMapping: (value: any) => void,
}


const BehaviorTable = ({
                         loading,
                         searchKeywordUniversity,
                         searchHistoryUniversity,
                         unitDownloadResource,
                         unitPreviewResource,
                         unitShareResource,
                         setActionModuleMapping
                       }: BehaviorTableProps) => {

  const {filterValues} = staticReportStore()
  const tableQuantitySearchColumn: TableColumnsType<any> = [
    {
      title: "Từ khoá",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {title: "Số lượng", dataIndex: "count", key: "count"},
  ]


  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
    courseStructureType: filterValues?.courseStructureType,
  };

  const tableColumnResource: TableColumnsType<any> = [
    {
      title: "Thời gian",
      dataIndex: "criteria",
      key: "criteria"
    },
    {
      title: "Số lượng",
      dataIndex: "value",
      key: "value"
    },
  ]

  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng tìm kiếm
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt tìm kiếm theo từ khoá"
            content={
              <Spin spinning={loading}>
                {searchKeywordUniversity.length > 0 ? (
                  <TableWithTotals
                    data={searchKeywordUniversity}
                    columns={tableQuantitySearchColumn}
                    keyColumn="type"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversitySearchKeywordCount,
                    "Số lượt tìm kiếm theo từ khoá.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversitySearchKeywordCount,
                    "Số lượt tìm kiếm theo từ khoá.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 16}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt tìm kiếm tài nguyên theo thời gian"
            content={
              <Spin spinning={loading}>
                {searchHistoryUniversity.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(searchHistoryUniversity).dataSource}
                    columns={[
                      {
                        title: "Thời gian",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(searchHistoryUniversity).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                      },
                    ]}
                    keyColumn="criteria"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversitySearchHistory,
                    "Số lượt tìm kiếm tài nguyên theo thời gian.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversitySearchHistory,
                    "Số lượt tìm kiếm tài nguyên theo thời gian.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượt xem, tải về, chia sẻ
        </p>
      </div>
      <Row gutter={[{xs: 8, sm: 8, md: 16, lg: 16}, {xs: 8, sm: 8, md: 16, lg: 16}]}>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt tải về tài nguyên"
            content={
              <Spin spinning={loading}>
                {unitDownloadResource.length > 0 ? (
                  <TableWithTotals
                    data={unitDownloadResource}
                    columns={tableColumnResource}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            moreOption={
              <Select
                className="mr-2"
                defaultValue={1}
                style={{minWidth: 160}}
                onSelect={(value) => {
                  let type;
                  switch (value) {
                    case 1:
                      type = "unit";
                      break;
                    case 2:
                      type = "unit";
                      break;
                    case 3:
                      type = "unit";
                      break;
                    case 4:
                      type = "sequence";
                      break;
                    case 5:
                      type = "course";
                      break;
                    default:
                      type = "course";
                  }
                  setActionModuleMapping({
                    courseStructureType: type,
                    actionType: 3,
                    moduleGroup: value
                  })

                }}
                options={[
                  {value: 1, label: 'Bài kiểm tra'},
                  {value: 2, label: 'Tài liệu tham khảo'},
                  {value: 3, label: 'Đa phương tiện'},
                  {value: 4, label: 'Bài giảng'},
                  {value: 5, label: 'Khoá học'},
                ]}
              />
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityDownloadResources,
                    "Số lượt tải về tài nguyên.xlsx",
                    "excel",
                    {...exportPayload, unitActionType: 3}
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityDownloadResources,
                    "Số lượt tải về tài nguyên.pdf",
                    "pdf",
                    {...exportPayload, unitActionType: 3}
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt xem tài nguyên"
            content={
              <Spin spinning={loading}>
                {unitPreviewResource.length > 0 ? (
                  <TableWithTotals
                    data={unitPreviewResource}
                    columns={tableColumnResource}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            moreOption={
              <Select
                className="mr-2"
                defaultValue={1}
                style={{minWidth: 160}}
                onSelect={(value) => {
                  let type;
                  switch (value) {
                    case 1:
                      type = "unit";
                      break;
                    case 2:
                      type = "unit";
                      break;
                    case 3:
                      type = "unit";
                      break;
                    case 4:
                      type = "sequence";
                      break;
                    case 5:
                      type = "course";
                      break;
                    default:
                      type = "course";
                  }
                  setActionModuleMapping({
                    courseStructureType: type,
                    actionType: 3,
                    moduleGroup: value
                  })
                }}
                options={[
                  {value: 1, label: 'Bài kiểm tra'},
                  {value: 2, label: 'Tài liệu tham khảo'},
                  {value: 3, label: 'Đa phương tiện'},
                  {value: 4, label: 'Bài giảng'},
                  {value: 5, label: 'Khoá học'},
                ]}
              />
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityDownloadResources,
                    "Số lượt xem tài nguyên.xlsx",
                    "excel",
                    {...exportPayload, unitActionType: 2}
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityDownloadResources,
                    "Số lượt xem tài nguyên.pdf",
                    "pdf",
                    {...exportPayload, unitActionType: 2}
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 8}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt chia sẻ tài nguyên"
            content={
              <Spin spinning={loading}>
                {unitShareResource.length > 0 ? (
                  <TableWithTotals
                    data={unitShareResource}
                    columns={tableColumnResource}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            moreOption={
              <Select
                className="mr-2"
                defaultValue={1}
                style={{minWidth: 160}}
                onSelect={(value) => {
                  let type;
                  switch (value) {
                    case 1:
                      type = "unit";
                      break;
                    case 2:
                      type = "unit";
                      break;
                    case 3:
                      type = "unit";
                      break;
                    case 4:
                      type = "sequence";
                      break;
                    case 5:
                      type = "course";
                      break;
                    default:
                      type = "course";
                  }
                  setActionModuleMapping({
                    courseStructureType: type,
                    actionType: 3,
                    moduleGroup: value
                  })
                }}
                options={[
                  {value: 1, label: 'Bài kiểm tra'},
                  {value: 2, label: 'Tài liệu tham khảo'},
                  {value: 3, label: 'Đa phương tiện'},
                  {value: 4, label: 'Bài giảng'},
                  {value: 5, label: 'Khoá học'},
                ]}
              />
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityDownloadResources,
                    "Số lượt chia sẻ tài nguyên.xlsx",
                    "excel",
                    {...exportPayload, unitActionType: 1}
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityDownloadResources,
                    "Số lượt chia sẻ tài nguyên.pdf",
                    "pdf",
                    {...exportPayload, unitActionType: 1}
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default BehaviorTable;
