import { ReactNode } from "react"

export interface DataType {
    id: number,
    name: string,
    code: string,
    categoryId: number,
    cateDad: string,
    status: boolean,
    createdDate: string,
    numericalOrder: ReactNode,
}

export const dataStatus = [
    {
      value: true,
      label: "Hoạt động"
    },
    {
      value: false,
      label: "Không hoạt động"
    }
  ]

export const validateFieldCreate = ["name", "code", "portfolioId", "cateDad", "status"]

export const validateFieldFilter = []
