import React from "react";
import { Form, Radio } from "antd";
import { QuestionProps } from "../../../../../types/course";

function ChooseVideo({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <Form.Item name="ChooseImage">
      <Radio.Group disabled>
        <div className="flex layout-two-col">
          <div
            className={`image-quiz-wrap ${isCorrect ? "active-quiz-correct choose-correct" : ""} ${isIncorrect ? "active-quiz-wrong choose-incorrect" : ""}`}
          >
            <Radio id="1" value={1} className="text-result mb-1">
              Mô tả câu hỏi{" "}
            </Radio>
            <video className="quest-video" controls>
              <source
                src={`${process.env.PUBLIC_URL}/assets/video-test.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="image-quiz-wrap">
            <Radio id="2" value={2} className="mb-1">
              Mô tả câu hỏi{" "}
            </Radio>
            <video className="quest-video" controls>
              <source
                src={`${process.env.PUBLIC_URL}/assets/video-test.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="image-quiz-wrap">
            <Radio id="3" value={3} className="mb-1">
              Mô tả câu hỏi{" "}
            </Radio>
            <video className="quest-video" controls>
              <source
                src={`${process.env.PUBLIC_URL}/assets/video-test.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="image-quiz-wrap">
            <Radio id="4" value={4} className="mb-1">
              Mô tả câu hỏi{" "}
            </Radio>
            <video className="quest-video" controls>
              <source
                src={`${process.env.PUBLIC_URL}/assets/video-test.mp4`}
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Radio.Group>
    </Form.Item>
  );
}

export default ChooseVideo;
