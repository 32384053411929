import { Col, Form, Row, Spin, Switch, Typography } from "antd";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import FormInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { get } from "lodash";
import { useWatch } from "antd/es/form/Form";

const validations = {
	name: {
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Tên danh mục không vượt quá 512 ký tự."))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập tên danh mục."))
			}
			return Promise.resolve()
		}
	},
	code:
	{
		required: true,
		validator: (_: unknown, value: string) => {
			if (value && value.length > 512) {
				return Promise.reject(new Error("Mã danh mục không vượt quá 512 ký tự."))
			}
			if (!value) {
				return Promise.reject(new Error("Vui lòng nhập mã danh mục."))
			}
			return Promise.resolve()
		}
	}
}

type FormCreateCategoryProps = {
	form: FormInstance,
	onFinish?: any,
	dataOptions?: any,
	loadingForm: boolean,
}

const FormCreateCategory: FunctionComponent<FormCreateCategoryProps> = ({ form, onFinish, dataOptions, loadingForm }) => {

    return (
        <>	
			<Form layout={"vertical"} form={form} onFinish={onFinish} name="createPortfolio" initialValues={{ status : true}}>
				{loadingForm ? 
					<div className="statistic-course-content statistic-course-loading">
						<Spin size="large" />
					</div> 
					: 
					<Row gutter={[16,16]}>
					<Col span={12}>
						<Form.Item name="name" rules={[validations.name]} label="Tên danh mục">
							<FormInput placeholder="Nhập tên danh mục" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item name="code" label="Mã danh mục" rules={[validations.code]}>
							<FormInput placeholder="Nhập mã danh mục" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item name="cateDad" label="Danh mục cha">
							<FormItemDropdown options={get(dataOptions, 'dataCategories', '')} allowClear={true} placeholder="Chọn danh mục cha" />
						</Form.Item>
					</Col>
					<Col span={24} style={{display:"flex", justifyContent:"space-between"}}>
						<span>Trạng thái</span>
						<Form.Item name="status">
							<Switch defaultChecked />
						</Form.Item>
					</Col>
				</Row>
				}
			</Form>
        </>
    )
}

export default FormCreateCategory;