import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getLogsAudit: (dataReq: any) => Promise<AxiosResponse<any>> = (
  dataReq
) => {
  return axiosConfig.get(
    `/logs/get?name=${dataReq?.name}&skip=${dataReq?.skip}&take=${dataReq?.take}`
  );
};
