import React from "react";
import { Form, Switch } from "antd";

interface FormSwitchProp {
  label: string;
  name: string;
  defaultValue?: boolean;
  onChange?: (val: boolean, name: string) => void;
}

function FormSwitch(props: FormSwitchProp) {
  const { label, name, defaultValue, onChange } = props;
  const handleSwitch = (checked: boolean) => {
    onChange && onChange(checked, name);
  }
  return (
    <div className="setting-question-row">
      <div className="row-item">
        <span className="row-item">{label}</span>
        <Form.Item name={name} valuePropName="checked" >
          <Switch onChange={handleSwitch} defaultValue={defaultValue} />
        </Form.Item>
      </div>
    </div>
  );
}

export default FormSwitch;
