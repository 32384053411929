import React from "react";
import { QuestionProps } from "../../../../../types/course";
import { Checkbox, Form, Radio } from "antd";

function ChooseMultiText({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <div>
      <Form.Item
        className={`mb-12 ${isCorrect ? "choose-correct" : ""} ${isIncorrect ? "choose-incorrect" : ""}`}
        name="choose_one"
        valuePropName="checked"
        initialValue={{ choose_one: true }}
      >
        <Checkbox disabled>
          <span className="font-size-16 line-height-24 font-weight-5 text-result">
            Figma ipsum component, Figma ipsum component
          </span>
        </Checkbox>
      </Form.Item>
      <Form.Item
      className={`mb-12 ${isCorrect ? "choose-correct" : ""} ${isIncorrect ? "choose-incorrect" : ""}`}
        name="choose_one"
        valuePropName="checked"
      >
        <Checkbox disabled>
          <span className="font-size-16 line-height-24 font-weight-5 text-result">
            Figma ipsum component, Figma ipsum component
          </span>
        </Checkbox>
      </Form.Item>
      <Form.Item
        className="mb-12"
        name="ChooseMultiText-3"
        valuePropName="checked"
        initialValue={false}
      >
        <Checkbox disabled>
          <span className="font-size-16 line-height-24 font-weight-5">
            Figma ipsum component, Figma ipsum component
          </span>
        </Checkbox>
      </Form.Item>
      <Form.Item
        className="mb-12"
        name="ChooseMultiText-4"
        valuePropName="checked"
        initialValue={false}
      >
        <Checkbox disabled>
          <span className="font-size-16 line-height-24 font-weight-5">
            Figma ipsum component, Figma ipsum component
          </span>
        </Checkbox>
      </Form.Item>
    </div>
  );
}

export default ChooseMultiText;
