import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  message,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import {
  CloseOutlined,
  CloudSyncOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import PageHeader from "../../../components/page-header/PageHeader";
import { isEmpty } from "lodash";
import DrawerCustom from "../../../components/drawer/Drawer";
import {
  formatDateToIsoStringHHss,
  formatDateV2,
} from "../../../utils/converDate";
import TableData from "../../../components/table-data/TableData";
import "../../../styles/_variables.scss";

import { DetailTypeAuthUser } from "../../../types/mooc-share-all";
import {
  DetailTypeMoocActionHistory,
  DataTypeMoocActionHistory,
  FilterDataMoocActionHistory,
} from "../../../types/course-plan-managment/mooc-action-history";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";
import { FormCreateMoocActionHistory } from "./components/form-create";
import {
  postMoocActionHistorySearch,
  deleteMoocActionHistory,
  getMoocActionHistoryById,
  postCreateMoocActionHistory,
  putUpdateMoocActionHistory,
  getLookupAuthUser,
} from "../../../service/course-plan-managment/mooc-action-history/mooc-action-history-api";

type ListMoocActionHistoryProps = {
  title?: string;
};

const ListMoocActionHistory = (props: ListMoocActionHistoryProps) => {
  // data option, columns is changing
  const imgPath = "/images/";
  const idField = "id";
  const nameField = "id";
  const params = useParams();
  const [listMoocActionHistory, setListMoocActionHistory] = useState<
    DataTypeMoocActionHistory[]
  >([]);
  const [browseData, setBrowseData] = useState<DataTypeMoocActionHistory>();
  const [detailData, setDetailData] = useState<DetailTypeMoocActionHistory>();
  const [filterData, setFilterData] = useState<FilterDataMoocActionHistory>({});
  const navigation = useNavigate();
  // forgein key function call apis
  const [lstUserId, setLstUserId] = useState<DetailTypeAuthUser[]>([]);
  const getListLstUserId = async () => {
    try {
      setLoading(true);
      await getLookupAuthUser()
        .then((res) => {
          const { data } = res.data;
          setLstUserId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return item;
            })
          );
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    getListLstUserId();
  }, []);

  const [dataOptions, setDataOptions] = useState<any>([]);
  useEffect(() => {
    setDataOptions([
      {
        title: "Ngày tạo",
        type: "RangePicker",
        key: "createdDateFromTo",
        size: 4,
        data: [],
      },
      {
        title: "Ngày sửa",
        type: "RangePicker",
        key: "updatedDateFromTo",
        size: 4,
        data: [],
      },
      {
        title: "Trạng thái",
        type: "FormItemDropdown",
        key: "organizationGroupId",
        size: 4,
        data: [
          {
            id: 1,
            name: "Công nghệ thông tin",
          },
          {
            id: 2,
            name: "Quản trị kinh doanh",
          },
        ],
      },
    ]);
  }, []);

  const [dataFilter, setDataFilter] = useState({});

  const dataColumns: TableColumnsType<DataTypeMoocActionHistory> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: "5%",
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2);
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Người thao tác", //dropdown
      dataIndex: "user_id",
      key: "userId",
      width: "20%",
      sorter: {
        compare: (a, b) => 0, // compare: (a, b) => a.userId.length - b.userId.length
      },
      render: (_: any, record, index: number) => {
        return <span className="table-order-header">{record?.username}</span>;
      },
    },
    {
      title: "Thời gian", //clendar
      dataIndex: "logged_time",
      key: "loggedTime",
      width: "10%",
      sorter: {
        compare: (a, b) => 0, // compare: (a, b) => a.loggedTime.length - b.loggedTime.length
      },
      render: (_: any, record, index: number) => {
        const dateTimeConvert = record?.loggerTime
          ? formatDateToIsoStringHHss(record?.loggerTime.toString())
          : null;
        const parts = dateTimeConvert ? dateTimeConvert.split(" ") : [];
        const time = parts.length > 1 ? parts[0] : null;
        const date = parts.length > 1 ? parts[1] : null;
        return (
          <Space direction="vertical">
            <span>{time}</span>
            <span>{date}</span>
          </Space>
        );
      },
    },
    {
      title: "Thao tác", //text
      dataIndex: "action_detail",
      key: "actionDetail",
      width: "65%",
      sorter: {
        compare: (a, b) => 0, // compare: (a, b) => a.actionDetail.length - b.actionDetail.length
      },
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.actionDetail}</span>
        );
      },
    },
  ];

  // const handleView = async (id: number) => {
  //   navigation(`/survey-training/survey-detail/${id}`);
  // };

  const listBreadcrumb = [
    {
      title: <a href="/survey-training/list-survey">Quản lý đợt khảo sát</a>,
    },
    {
      title: "Lịch sử hoạt động",
    },
  ];

  // function call apis, process data
  const getListData = () => {
    setLoading(true);

    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      id: !isEmpty(filterData.id) ? filterData.id : null,
      userId: !isEmpty(filterData.userId) ? filterData.userId : null,
      loggedTime: !isEmpty(filterData.loggerTime)
        ? filterData.loggerTime
        : null,
      loggedTimeFrom: !isEmpty(filterData.loggedTimeFromTo?.[0])
        ? filterData.loggedTimeFromTo?.[0].toString()
        : null,
      loggedTimeTo: !isEmpty(filterData.loggedTimeFromTo?.[1])
        ? filterData.loggedTimeFromTo?.[1].toString()
        : null,
      userIp: !isEmpty(filterData.userIp) ? filterData.userIp : null,
      actionDetail: !isEmpty(filterData.actionDetail)
        ? filterData.actionDetail
        : null,
      createdDate: !isEmpty(filterData.createdDate)
        ? filterData.createdDate
        : null,
      createdDateFrom: !isEmpty(filterData.createdDateFromTo?.[0])
        ? filterData.createdDateFromTo?.[0].toString()
        : null,
      createdDateTo: !isEmpty(filterData.createdDateFromTo?.[1])
        ? filterData.createdDateFromTo?.[1].toString()
        : null,
      updatedDate: !isEmpty(filterData.updatedDate)
        ? filterData.updatedDate
        : null,
      updatedDateFrom: !isEmpty(filterData.updatedDateFromTo?.[0])
        ? filterData.updatedDateFromTo?.[0].toString()
        : null,
      updatedDateTo: !isEmpty(filterData.updatedDateFromTo?.[1])
        ? filterData.updatedDateFromTo?.[1].toString()
        : null,
      moocActionHistoryTypes: !isEmpty(filterData.moocActionHistoryTypes)
        ? filterData.moocActionHistoryTypes
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
      surveyId: Number(params.id),
    };

    postMoocActionHistorySearch(data)
      .then((res) => {
        // const { data } = res.data;
        setListMoocActionHistory(res.data.data);
        setTotalRecords(res.data.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Có lỗi chưa xác định");
      });
  };

  const getDetailData = async (id: number) => {
    setLoading(true);
    try {
      const response = await getMoocActionHistoryById(
        (Number(id) || 0).toString()
      )
        .then((res) => {
          const { data } = res.data;
          setDetailData(data);
          setTimeout(() => {
            setLoading(false);
            setIsOpened(true);
          }, 500);
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };

  const deleteData = async (record: any) => {
    try {
      if (false) {
        // check deleteable?
        //message.error("Không thể xoá Lịch sử hoạt động đã xuất bản");
      } else {
        setLoading(true);
        await deleteMoocActionHistory(record[idField])
          .then((res) => {
            //if (res.data.statusCode === 200) {
            message.success("Lịch sử hoạt động đã được xoá thành công");
            getListData();
            setLoading(false);
            //}
          })
          .catch((err) => {
            setLoading(false);
            message.error("Có lỗi chưa xác định");
          });
      }
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };

  const copyData = async (record: any) => {
    alert("copyData");
  };

  const revokeData = async (record: any) => {
    alert("revokeData");
  };

  const handleCreate = async (value: any) => {
    try {
      setLoading(true);
      const data = value;
      const response = await postCreateMoocActionHistory(data)
        .then((result) => {
          setLoading(false);
          message.success("Lịch sử hoạt động đã được thêm thành công");
          navigate(
            `${routesConfig.moocActionHistoryDetail}/${result.data.data.id}`,
            {
              state: { mode: "edit" },
            }
          );
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };

  const handleEdit = async (value: any) => {
    try {
      const data = value;
      const response = await putUpdateMoocActionHistory(idDetail, data)
        .then((result) => {
          // navigate success
          message.success(
            `Sửa Lịch sử hoạt động ${data[nameField]} thành công`
          );
          navigate(`${routesConfig.moocActionHistoryDetail}/${idDetail}`, {
            state: { mode: "edit" },
          });
        })
        .catch((err) => {
          setLoading(false);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      message.error("Có lỗi chưa xác định");
    }
  };

  const clearFilter = () => {
    setLoading(true);
    form.resetFields();
    setFilterData({
      //createDate: null,
      //publicDate: null,
      //courseType: null,
      //status: null,
    });

    setDataFilter({ ...dataFilter });
    getListData();
  };

  // common state variables, no change
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenBrowseModal, setIsOpenBrowseModal] = useState<boolean>(false);
  const [isOpenSyncModal, setIsOpenSyncModal] = useState<boolean>(false);
  const [syncCheckbox, setSyncCheckbox] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [idDetail, setIdDetail] = useState(0);

  // common functions
  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const getNumericalNumber = (index: number, page: number, size: number) => {
    return (page - 1) * size + index + 1;
  };

  const randomCode = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16).replace("-", "");
      }
    );
  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleOpenCreate = () => {
    // add course here
    setIdDetail(0);
    setIsOpened(true);
  };

  const handleSave = (value: any) => {
    if (idDetail && idDetail !== 0) {
      handleEdit(value);
    } else {
      handleCreate(value);
    }
  };

  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider
        onFormFinish={(formName, { values, forms }) => {
          if (formName === "create-edit-mooc-action-history-detail") {
            handleSave(values);
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader
                title={title}
                listBreadcrumb={listBreadcrumb}
                arrowLeft={imgPath + "arrowLeft.svg"}
                positionItem="column"
                breadcrumb={true}
              />
            </div>
          </div>
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listMoocActionHistory}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <FormCreateMoocActionHistory
          // truyen danh sach cac bien state list
          dataOptions={dataOptions}
          id={idDetail}
          detailData={detailData}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        //setDetailData={setDetailData}
        //setDataUpdate={setDataUpdate}
        //setDataSponsorUpdate={setDataSponsorUpdate}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListMoocActionHistory;
