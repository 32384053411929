import {AxiosResponse} from "axios";
import {axiosConfig} from "../../config/api/configApi";

export const changePassword: (body: any) => Promise<AxiosResponse<any>> = (
  body
) => {
  return axiosConfig.put(`/accounts/change-password-user-qtcs`, body);
};

export const updateInfoAccount: (
  id: string,
  dataUpdate?: any
) => Promise<AxiosResponse<any>> = (id, dataUpdate) => {
  return axiosConfig.put(`/accounts/update/${id}`, dataUpdate);
};


export const getLanguages: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-language`)
}

export const getTimeZone: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-time-zone`)
}

export const getProvinces: (paramUrl?: string) => Promise<AxiosResponse<any>> = (paramUrl) => {
  return axiosConfig.get(`/location/get-provinces` + `${paramUrl}`)
}

export const getDistricts: (code?: string, districtCode?: string) => Promise<AxiosResponse<any>> = (code, districtCode) => {
  return axiosConfig.get(`/location/get-districts?provinceCode=${code}` + `${districtCode}`)
}
export const getWards: (code?: string, wardCode?: string) => Promise<AxiosResponse<any>> = (code, wardCode) => {
  return axiosConfig.post(`/location/get-wards?districtCode=${code}` + `${wardCode}`)
}
