import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { isEmpty } from "lodash";
import { JSON_SUB_TYPE, QuestionProps, QuizProps } from "../../../../../types/course";
import { FormInstance } from "antd/lib";
import { QuestionTypeEnum } from "../../../../../constants";
import { uploadFileV2 } from "../../../../../service/uploadFile/infoDetailApi";
import { DeleteIcon, ExportIcon, PauseIcon, RecordIcon, RefreshIcon } from "../../../../../components/icons/svg";
import EmptyComponent from "../../../../../components/empty";

interface VideoRecorderProps extends QuestionProps {
  data?: QuizProps;
  form?: FormInstance<any>;
  onChange?: (value: any) => void;
}

const VideoRecorder = ({
  data,
  disabled,
  initialData,
  onChange = () => {},
}: VideoRecorderProps) => {
  const [videoURL, setVideoURL] = useState<string>("");

  // control
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [mode, setMode] = useState<"default" | "recording" | "view" | "error">(
    "default"
  );
  const [isPaused, setIsPaused] = useState(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const startCamera = async () => {
    setMode("recording");
    setIsRecording(true);
    setIsPaused(false);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      const recorder = new MediaRecorder(stream);
      let chunks: BlobPart[] = [];

      recorder.ondataavailable = (e: BlobEvent) => chunks.push(e.data);
      recorder.onstop = () => {
        const completeBlob = new Blob(chunks, { type: "video/webm" });
        uploadVideo(completeBlob);
        setVideoURL(URL.createObjectURL(completeBlob));
      };

      setMediaRecorder(recorder);
      recorder.start();
    } catch (error) {
      console.error("Error accessing the camera", error);
      setMode("error");
    }
  };

  const stopRecording = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      (videoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track) => track.stop());

      videoRef.current.srcObject = null;

      if (mediaRecorder) {
        mediaRecorder.stop();
      }
    }

    setMode("view");
  };

  const pauseRecording = () => {
    if (mediaRecorder?.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder?.state === "paused") {
      mediaRecorder.resume();
    }
    if (videoRef.current) {
      videoRef.current.play();
    }
    setIsPaused(false);
    setMode("recording");
  };

  const handleDeleteRecord = () => {
    setVideoURL("");
    setMode("default");
    onChange({
      [`recorderVideo-${data?.id}-${QuestionTypeEnum.VIDEO}-${JSON_SUB_TYPE.ContentRequest}`]:
        null,
    });
  };

  const uploadVideo = async (videoBlob: Blob | null) => {
    if (!videoBlob) return;

    const formData = new FormData();
    formData.append("file", videoBlob);

    try {
      const response = await uploadFileV2(formData);
      onChange({
        [`recorderVideo-${data?.id}-${QuestionTypeEnum.VIDEO}-${JSON_SUB_TYPE.ContentRequest}`]:
          response?.data?.filePath,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    if (!isEmpty(initialData?.answer[0])) {
      const getFile = async () => {
        setVideoURL(initialData?.answer[0]);
        setMode("view");
      };
      getFile();
    }
  }, []);

  useEffect(() => {
    return () => stopRecording();
  }, []);

  const renderVideoScreen = () => {
    switch (mode) {
      case "default":
        return (
          <div className="start-screen">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
              alt="Snapshot"
              className="screen-video-snapshot"
            />

            <Button
              className="btn-start h-40 center"
              icon={<RecordIcon />}
              onClick={startCamera}
              disabled={disabled}
            >
              Bắt đầu quay
            </Button>
          </div>
        );
      case "view":
        return (
          <div className="view-screen flex gap-16">
            <video src={videoURL} className="screen-video-snapshot" controls />
            <div className="flex flex-column gap-16 btn-action-view-group">
              <Button
                className="btn-action"
                onClick={startCamera}
                disabled={disabled}
              >
                <RefreshIcon />
              </Button>
              <Button
                className="btn-action btn-delete"
                onClick={handleDeleteRecord}
                disabled={disabled}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        );

      case "recording":
        return (
          <div className="recording-screen">
            <video ref={videoRef} className="screen-video-snapshot" />
            <div className="pause-btn-group">
              {isRecording && !isPaused && (
                <Button className="mb-1 btn-pause" onClick={pauseRecording}>
                  Pause
                </Button>
              )}
              {isPaused && (
                <div className="pause-btn-group flex gap-8">
                  <Button
                    className="btn-action center"
                    onClick={resumeRecording}
                  >
                    <PauseIcon />
                  </Button>
                  <Button
                    className="btn-primary btn-action center"
                    onClick={stopRecording}
                  >
                    <ExportIcon />
                  </Button>
                </div>
              )}
            </div>
          </div>
        );
      case "error":
        return (
          <div className="w-full center">
            <EmptyComponent description="Không tìm thấy thiết bị được yêu cầu" />
          </div>
        );
      default:
        return null;
    }
  };

  return <div>{renderVideoScreen()}</div>;
};

export default VideoRecorder;
