import React from "react";

function FileUpload({}: any) {
  const renderContent = () => {
    return <div className="center">Câu hỏi tự luận</div>;
  };

  return <div>{renderContent()}</div>;
}

export default FileUpload;
