// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-form-item .ant-form-item-label {
  width: 33.333333%;
  display: flex;
  justify-self: start; }

@media screen and (max-width: 768px) {
  .card-form-item .ant-form-item-label {
    width: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/components/select-ui/select-ui.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB;EACjB,aAAa;EACb,mBAAmB,EAAA;;AAGvB;EACE;IAEI,WAAW,EAAA,EACZ","sourcesContent":[".card-form-item{\n  .ant-form-item-label{\n    width: 33.333333%;\n    display: flex;\n    justify-self: start;\n  }\n}\n@media screen and (max-width: 768px) {\n  .card-form-item{\n    .ant-form-item-label{\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
