// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-plan-managment-appraisal-group {
  width: 100%; }
  .course-plan-managment-appraisal-group .wrapper-filter {
    width: 100%;
    background: #FFFFFF;
    overflow: hidden;
    padding-top: 16px;
    max-height: 0;
    padding-left: 2%;
    transition: max-height 0.3s ease-in-out, padding-bottom 0.3s linear; }
    .course-plan-managment-appraisal-group .wrapper-filter .ant-form-item {
      margin-bottom: 0; }
  .course-plan-managment-appraisal-group .wrapper-filter.open {
    max-height: 100%;
    padding-left: 2%;
    padding-bottom: 15px; }
  .course-plan-managment-appraisal-group .group-btn--filter {
    display: flex;
    margin-left: 7%; }
  .course-plan-managment-appraisal-group .created_date_filter {
    width: 100%; }
  @media screen and (min-width: 996px) {
    .course-plan-managment-appraisal-group .filter-btn {
      margin-right: 10px; } }
  @media screen and (max-width: 768px) {
    .course-plan-managment-appraisal-group .filter-btn {
      width: 35%; }
    .course-plan-managment-appraisal-group .clear-filter-btn {
      width: 60%; }
    .course-plan-managment-appraisal-group .group-btn--filter {
      justify-content: space-between; } }
  @media screen and (max-width: 480px) {
    .course-plan-managment-appraisal-group .filter-btn,
    .course-plan-managment-appraisal-group .clear-filter-btn {
      width: 50%; }
    .course-plan-managment-appraisal-group .group-btn--filter {
      justify-content: space-around; }
    .course-plan-managment-appraisal-group .wrapper-filter {
      height: 200px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/organize-appraisal-board/organize-appraisal-board-list/components/filter-group/filter-group.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;EADb;IAII,WAAkB;IAClB,mBAAqB;IACrB,gBAAoB;IACpB,iBAAkB;IAClB,aAAe;IACf,gBAAgB;IAChB,mEAAqE,EAAA;IAVzE;MAaM,gBAAgB,EAAA;EAbtB;IAmBI,gBAAoB;IACpB,gBAAkB;IAClB,oBAAoB,EAAA;EArBxB;IAyBI,aAAiB;IACjB,eAAe,EAAA;EA1BnB;IA8BI,WAAW,EAAA;EAGb;IAjCF;MAmCM,kBAAkB,EAAA,EACnB;EAIH;IAxCF;MA0CM,UAAU,EAAA;IA1ChB;MA8CM,UAAU,EAAA;IA9ChB;MAkDM,8BAA8B,EAAA,EAE/B;EAGH;IAvDF;;MA2DM,UAAU,EAAA;IA3DhB;MA+DM,6BAA6B,EAAA;IA/DnC;MAmEM,aAAa,EAAA,EACd","sourcesContent":[".course-plan-managment-appraisal-group {\n  width: 100%;\n\n  .wrapper-filter {\n    width       : 100%;\n    background  : #FFFFFF;\n    overflow    : hidden;\n    padding-top : 16px;\n    max-height  : 0;\n    padding-left: 2%;\n    transition  : max-height 0.3s ease-in-out, padding-bottom 0.3s linear;\n\n    .ant-form-item {\n      margin-bottom: 0;\n    }\n\n  }\n\n  .wrapper-filter.open {\n    max-height    : 100%;\n    padding-left  : 2%;\n    padding-bottom: 15px;\n  }\n\n  .group-btn--filter {\n    display    : flex;\n    margin-left: 7%;\n  }\n\n  .created_date_filter {\n    width: 100%;\n  }\n\n  @media screen and (min-width: 996px) {\n    .filter-btn {\n      margin-right: 10px;\n    }\n  }\n\n\n  @media screen and (max-width: 768px) {\n    .filter-btn {\n      width: 35%;\n    }\n\n    .clear-filter-btn {\n      width: 60%;\n    }\n\n    .group-btn--filter {\n      justify-content: space-between;\n\n    }\n  }\n\n  @media screen and (max-width: 480px) {\n\n    .filter-btn,\n    .clear-filter-btn {\n      width: 50%;\n    }\n\n    .group-btn--filter {\n      justify-content: space-around;\n    }\n\n    .wrapper-filter {\n      height: 200px;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
