import { FunctionComponent, useEffect, useState } from "react";
import { Layout, Tabs, TabsProps } from "antd";
import PageHeader from "../../../../../components/page-header/PageHeader";
import { useLocation, useParams } from "react-router-dom";
import { message } from "antd/lib";
import { Button, Col, Row, Space } from "antd";
import "./detail.scss";
import { useAppStore, useCourseStructure } from "../../../../../stores/stores";
import {
  getListMoocCourseDescriptionDocumentById,
  getListMoocCourseStructureById,
  getListMoocCourseStructureChapterById,
  getMoocCourseConstructionPlanById,
} from "../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import {
  TypeCourseStructure,
  TypeChapterList,
  TypeSubjectList,
  TypeCourseDescriptionDocument,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { DetailContent } from "./component/detail-content/DetailContent";
import CourseStructure from "./component/course-structure/CourseStructure";
import PreviewCourseStructure from "./component/preview-course-structure/PreviewCourseStructure";
import DetailInfo from "./component/detail-info/DetailInfo";
import DescriptionDocument from "./component/description-document/DescriptionDocument";
import IsLoading from "./component/is-loading/IsLoading";
import PreviewDescriptionDocument from "./component/preview-description-document/PreviewDescriptionDocument";
import { routesConfig } from "../../../../../config/routes";
import DetailConfirm from "../detail-confirm/DetailConfirm";

const { Content } = Layout;
const imgPath = "/images/";

const MoocCourseAssignmentDetail: FunctionComponent = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const tab = state && state.tab ? state.tab : null;
  const mode = state && state.mode ? state.mode : null;
  const typeAssignment =
    state && state.typeAssignment ? state.typeAssignment : null;

  const [detailData, setDetailData] = useState<any>({}); //DetailTypeMoocCourseConstructionPlan
  const [ltsIndustry, setLtsIndustry] = useState<any[]>([]); //DetailTypeMoocCourseConstructionPlan
  const [ltsIndustryGroup, setLtsIndustryGroup] = useState<any[]>([]); //DetailTypeMoocCourseConstructionPlan

  const { accountInfo, setAccountDetail } = useAppStore((state) => {
    return {
      accountInfo: state?.accountInfo,
      setAccountDetail: state?.setAccountDetail,
    };
  });

  const [isLoading, setIsLoading] = useState(false);
  const [nameAssigneeId, setNameAssigneeId] = useState("");
  const [dataListCourseStructure, setDataListCourseStructure] = useState<
    TypeChapterList[]
  >([]);

  const [dataDescriptionDocument, setDataDescriptionDocument] = useState<
    TypeCourseDescriptionDocument[]
  >([]);

  const getDetailMoocCourseConstructionPlanData = async (id: any) => {
    try {
      await getMoocCourseConstructionPlanById(id)
        .then((res) => {
          const data = res.data.data;
          const name = data.moocCoursePlanAssignment.find(
            (x: any) => x.courseSturctureItemId === null
          )?.authUserMoocCoursePlanAssignmentAssigneeIdToauthUser
            ?.authUserprofile;
          if (name) {
            setNameAssigneeId(name.name);
          }
          setDetailData(data);
          const allIndustries = data.moocConstructionPlanIndustry.flatMap(
            (e: any) => e.moocIndustry
          );
          setLtsIndustry(allIndustries);
          const allIndustryGroups =
            data.moocConstructionPlanIndustryGroup.flatMap(
              (e: any) => e.moocIndustryGroup
            );
          setLtsIndustryGroup(allIndustryGroups);
        })
        .catch((err) => {
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListMoocCourseStructureByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureById(id)
        .then((res) => {
          const data = res.data.data;
          setDataListCourseStructure(changeToChapterList(data));
        })
        .catch((err) => {
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListMoocCourseStructureChapterByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureChapterById(id)
        .then((res) => {
          const data = res.data.data;
          setListChapter(data);
        })
        .catch((err) => {
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }
  };

  const getListMoocCourseDescriptionDocumentByIData = async (id: any) => {
    {
      try {
        await getListMoocCourseDescriptionDocumentById(id)
          .then((res) => {
            const data = res.data.data;
            setDataDescriptionDocument(data);
          })
          .catch((err) => {
            message.error("Có lỗi chưa xác định");
          });
      } catch (error) {
        message.error("Có lỗi chưa xác định");
      }
    }
  };

  const [activeTab, setActiveTab] = useState<string>(tab ? tab : "1");

  useEffect(() => {
    getDetailMoocCourseConstructionPlanData(id);
    getListMoocCourseStructureByIdData(id);
    getListMoocCourseStructureChapterByIdData(id);
    getListMoocCourseDescriptionDocumentByIData(id);
  }, [id, activeTab]);

  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 1)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level2 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 2)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level3 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 3)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );

    const chapterList: TypeChapterList[] = level1.map((l1, index) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        orderNumber: l1.orderNumber,
        moocCoursePlanAssignment: l1.moocCoursePlanAssignment,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2
        .filter((l2) => l2.parentId === l1.id)
        .sort((a, b) =>
          a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
        );

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject, index) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          orderNumber: subject.orderNumber,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3
          .filter((l3) => l3.parentId === subject.id)
          .sort((a, b) =>
            a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
          );

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson, index) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          orderNumber: lesson.orderNumber,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });
    return chapterList;
  };

  const [listChapter, setListChapter] = useState<TypeCourseStructure[]>([]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <DetailContent isReadOnly={mode === "view" ? true : false}>
          <DetailInfo
            detailData={detailData}
            ltsIndustry={ltsIndustry}
            ltsIndustryGroup={ltsIndustryGroup}
            typeAssignment={typeAssignment}
            universityName={state?.record?.universityName}
          />
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: "Cấu trúc khóa học",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <>
          {mode !== "view" && (
            <DetailContent>
              <CourseStructure
                dataCourseStructure={dataListCourseStructure}
                setDataCourseStructure={setDataListCourseStructure}
                typeAssignment={typeAssignment}
              />
            </DetailContent>
          )}
          {mode === "view" && (
            <DetailContent>
              <PreviewCourseStructure
                nameAssigneeId={nameAssigneeId}
                dataCourseStructure={dataListCourseStructure}
              />
            </DetailContent>
          )}
        </>
      ),
    },
    {
      key: "3",
      label: "Tài liệu mô tả",
      children: isLoading ? (
        <IsLoading />
      ) : (
        <>
          {mode === "view" && (
            <DetailContent>
              <PreviewDescriptionDocument
                listChapter={listChapter}
                dataDescriptionDocument={dataDescriptionDocument}
              />
            </DetailContent>
          )}
          {mode !== "view" && (
            <DetailContent isReadOnly={mode === "view" ? true : false}>
              <DescriptionDocument
                listChapter={listChapter}
                dataDescriptionDocument={dataDescriptionDocument}
                setDataDescriptionDocument={setDataDescriptionDocument}
              />
            </DetailContent>
          )}
        </>
      ),
    },
    {
      key: "4",
      label: "Kết quả duyệt",
      children: isLoading ? <IsLoading /> : <DetailConfirm />,
    },
  ];

  const listBreadcrumb = [
    {
      title: (
        <a
          href={`/${routesConfig.coursePlan}/${routesConfig.registerCoursePlan}/${routesConfig.moocCourseConstructionPlan}`}
        >
          Kế hoạch khóa học
        </a>
      ),
    },
    {
      title: (
        <a
          href={`/${routesConfig.coursePlan}/${routesConfig.planningAssignment}`}
        >
          Phân công lập kế hoạch
        </a>
      ),
    },
    {
      title: `${detailData?.registrationName ?? ""}`,
    },
  ];
  const showMessage = (messageContent: string) => {
    if (messageContent.includes("Thành công")) {
      message.success(messageContent);
    } else {
      message.error(messageContent);
    }
  };

  const { saveDraftCourseStructure, saveDraftDescriptionDocument } =
    useCourseStructure((state) => {
      return {
        saveDraftCourseStructure: state.saveDraftCourseStructure,
        saveDraftDescriptionDocument: state.saveDraftDescriptionDocument,
      };
    });

  const handleSaveDraftDescriptionDocument = async (
    type?: string,
    activeTab?: number
  ) => {
    setIsLoading(true);

    await saveDraftDescriptionDocument(
      showMessage,
      dataDescriptionDocument,
      Number(id),
      type,
      activeTab
    );

    setIsLoading(false);
  };
  return (
    <>
      <Content>
        <PageHeader
          title={`Kế hoạch khóa học ${detailData.registrationName ?? ""}`}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <Tabs
            activeKey={activeTab} // Set active tab
            onChange={(key) => setActiveTab(key)}
            items={items}
            tabBarExtraContent={
              <Row>
                <Space>
                  <Col>
                    {Number(activeTab) === 3 && (
                      <Button
                        type="default"
                        onClick={() =>
                          handleSaveDraftDescriptionDocument(
                            "manualSave",
                            Number(activeTab)
                          )
                        }
                        disabled={mode === "view" ? true : false}
                      >
                        Lưu tài liệu
                      </Button>
                    )}
                  </Col>
                </Space>
              </Row>
            }
          />
        </div>
      </Content>
    </>
  );
};

export default MoocCourseAssignmentDetail;
