import React, { FunctionComponent } from "react";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import "./filter-group.scss";
import { get } from "lodash";
import { useForm } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { status, active } from "../constants/data";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
type FormFilterProps = {
  showFilter?: boolean;
  clearFilter: (form: FormInstance) => void;
  onFinish?: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  showFilter,
  clearFilter,
  onFinish,
}) => {
  const { RangePicker } = DatePicker;
  const [form] = useForm();
  return (
    <div className="course-plan-managment-filter-group-speialized">
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
            <Col
              xl={{ span: 6 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="createdDate">
                <RangePicker
                  placeholder={["Từ ngày", "Đến ngày"]}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 7 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="status">
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Trạng thái"
                  options={status}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 6 }}
              md={{ span: 5 }}
              sm={{ span: 5 }}
              xs={{ span: 24 }}
            >
              <Form.Item name="active">
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Tình trạng hoạt động"
                  options={active}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 4 }}
              md={{ span: 9 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={() => clearFilter(form)}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa tất cả</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default FormFilter;
