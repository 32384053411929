import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { CoursePayload } from "../../types/course";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const savedCourse: (id: number) => Promise<AxiosResponse<any>> = (
  id: number
) => {
  return axiosConfigV2.get(`/course/my-interest/create-saved-course/${id}`);
};

export const unsaveCourse: (id: number) => Promise<AxiosResponse<any>> = (
  id: number
) => {
  return axiosConfigV2.delete(`/course/my-interest/delete-saved-course/${id}`);
};

export const getSavedCourseList: (
  params: any
) => Promise<AxiosResponse<any>> = (params: any) => {
  return axiosConfigV2.post(`/course/my-interest/search-saved-courses`,
    params
  );
};

export const getRecommendForUList: (
  params: any
) => Promise<AxiosResponse<any>> = (params: any) => {
  return axiosConfigV2.post(`/course/my-interest/search-saved-courses`,
    params
  );
};

export const getIndustrySavedCourse: (payload: any) => Promise<AxiosResponse<any>> = (
  payload: any
) => {
  return axiosConfigV2.post(`/course/my-interest/get-saved-industries`, payload);
};

export const getUniversitySavedCourse: (payload: any) => Promise<
  AxiosResponse<any>
> = (payload: any) => {
  return axiosConfigV2.post(`/course/my-interest/get-saved-enterprises`, payload);
};

export const suggestedForYou: (payload: any) => Promise<
  AxiosResponse<any>
> = (payload: any) => {
  return axiosConfigV2.get(`/my-registration/get-recommend-v2?${payload}`);
};
