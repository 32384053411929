import React, { useState, useMemo, useEffect } from "react";
import {
  ListAnswer,
  OptionTypeQuestion1,
} from "../../../../../../../types/survey-managment/survey";
import QuestionType1 from "../question-type-1/QuestionType1";
import QuestionType2 from "../question-type-2/QuestionType2";
import QuestionType3 from "../question-type-3/QuestionType3";
import QuestionType4 from "../question-type-4/QuestionType4";
import QuestionType5 from "../question-type-5/QuestionType5";
import QuestionType6 from "../question-type-6/QuestionType6";
import QuestionType7 from "../question-type-7/QuestionType7";
import QuestionType8 from "../question-type-8/QuestionType8";

interface QuestionViewProps {
  typeQuestionId: number;
  listAnswer?: ListAnswer[];
  handleUpdateAnswerData?: (data: ListAnswer[]) => void;
  isQuestionHasOther?: boolean;
}

const optionsExample: OptionTypeQuestion1[] = [
  { id: 111, content: "vb 01", order: 1 },
  { id: 332, content: "vb 02", order: 2 },
  { id: 223, content: "vb 03", order: 3 },
  { id: 404, content: "vb 04", order: 4 },
];

const QuestionView: React.FC<QuestionViewProps> = ({
  typeQuestionId,
  listAnswer,
  handleUpdateAnswerData,
  isQuestionHasOther,
}) => {

  // console.log(`questionView-listAnswer::`, listAnswer)
  const updateListAnswer = (listAnswer: ListAnswer[]) => {
    handleUpdateAnswerData && handleUpdateAnswerData(listAnswer);
  };

  const listAnswers = useMemo(() => listAnswer, [listAnswer])

  const isHasOther =  useMemo(() => isQuestionHasOther, [isQuestionHasOther])

  switch (typeQuestionId) {
    case 1:
      return (
        <QuestionType1
          listOptions={listAnswers}
          setUpdateListAnswer={updateListAnswer}
          isQuestionHasOther={isHasOther}
        />
      );
    case 2:
      return (
        <QuestionType2
          listOptions={listAnswers}
          setUpdateListAnswer={updateListAnswer}
          isQuestionHasOther={isHasOther}
        />
      );
    case 3:
      return (
        <QuestionType3
          listOptions={listAnswers}
          setUpdateListAnswer={updateListAnswer}
          isQuestionHasOther={isHasOther}
        />
      );
    case 4:
      return (
        <QuestionType4
          listOptions={listAnswers}
          setUpdateListAnswer={updateListAnswer}
          isQuestionHasOther={isHasOther}
        />
      );
    case 5:
      return (
        <QuestionType5
          listOptions={listAnswers}
          setUpdateListAnswer={updateListAnswer}
          isQuestionHasOther={isHasOther}
        />
      );
    case 6:
      return (
        <QuestionType6
          listOptions={listAnswers}
          setUpdateListAnswer={updateListAnswer}
          isQuestionHasOther={isHasOther}
        />
      );
    case 7:
      return (
        <QuestionType7
          listOptions={listAnswers}
          setUpdateListAnswer={updateListAnswer}
          isQuestionHasOther={isHasOther}
        />
      );
    case 8:
      return (
        <QuestionType8
          listOptions={listAnswers}
          setUpdateListAnswer={updateListAnswer}
          isQuestionHasOther={isHasOther}
        />
      );
    default:
      return null;
  }
};

export default QuestionView;
