// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-editor .quill .ql-toolbar {
  overflow: visible;
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .text-editor .quill .ql-toolbar .ql-snow {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #e9ecef; }

.text-editor .quill .ql-container .ql-snow {
  border: 1px solid #e9ecef;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important; }

.text-editor .quill .ql-container .ql-editor .ql-video {
  width: 400px;
  height: 300px !important;
  margin-bottom: 10px; }

.text-editor .ql-formats button {
  width: max-content !important; }
`, "",{"version":3,"sources":["webpack://./src/components/text-editor/style.scss"],"names":[],"mappings":"AAAA;EAKM,iBAAiB;EACjB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB,EAAA;EARzB;IAWQ,2BAA2B;IAC3B,4BAA4B;IAC5B,yBAAyB,EAAA;;AAbjC;EAmBQ,yBAAyB;EACzB,yCAAyC;EACzC,0CAA0C,EAAA;;AArBlD;EA0BU,YAAY;EACZ,wBAAwB;EACxB,mBAAmB,EAAA;;AA5B7B;EAoCM,6BAA6B,EAAA","sourcesContent":[".text-editor {\n  // margin-bottom: 16px !important;\n\n  .quill {\n    .ql-toolbar {\n      overflow: visible;\n      display: flex;\n      justify-content: flex-start;\n      align-items: center;\n\n      .ql-snow {\n        border-top-left-radius: 8px;\n        border-top-right-radius: 8px;\n        border: 1px solid #e9ecef;\n      }\n    }\n\n    .ql-container {\n      .ql-snow {\n        border: 1px solid #e9ecef;\n        border-bottom-left-radius: 8px !important;\n        border-bottom-right-radius: 8px !important;\n      }\n\n      .ql-editor {\n        .ql-video {\n          width: 400px;\n          height: 300px !important;\n          margin-bottom: 10px;\n        }\n      }\n    }\n  }\n\n  .ql-formats {\n    button {\n      width: max-content !important;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
