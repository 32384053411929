// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-detail-topic.block-card-detail-1 {
  margin: 0 auto;
  margin-top: -25px !important; }

.card-detail-topic.block-card-detail-2 {
  margin: 0 auto;
  margin-top: 25px !important; }

.card-detail-topic .statistic-course-discuss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }

.card-detail-topic ol {
  list-style: decimal;
  margin-left: 25px; }
  .card-detail-topic ol li {
    list-style: decimal; }
`, "",{"version":3,"sources":["webpack://./src/components/blog-and-forum/detail-topic/index.scss"],"names":[],"mappings":"AAAA;EAEI,cAAc;EACd,4BAA4B,EAAA;;AAHhC;EAMI,cAAc;EACd,2BAA2B,EAAA;;AAP/B;EAUI,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW,EAAA;;AAbf;EAiBI,mBAAmB;EAInB,iBAAiB,EAAA;EArBrB;IAmBM,mBAAmB,EAAA","sourcesContent":[".card-detail-topic {\n  &.block-card-detail-1 {\n    margin: 0 auto;\n    margin-top: -25px !important;\n  }\n  &.block-card-detail-2 {\n    margin: 0 auto;\n    margin-top: 25px !important;\n  }\n  .statistic-course-discuss {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 100%;\n  }\n\n  ol {\n    list-style: decimal;\n    li {\n      list-style: decimal;\n    }\n    margin-left: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
