import { Button, DatePicker, Modal, Radio, Space } from "antd";
import locale from "antd/es/date-picker/locale/vi_VN";
import { RadioChangeEvent } from "antd/lib/radio";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/vi";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportData from "highcharts/modules/export-data";
import exporting from "highcharts/modules/exporting";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  exportExcelCount,
  getStatisticByDate,
  getStatisticByMonth,
  getStatisticByWeek,
  getStatisticByYear,
} from "../../../../../service/course-management";
import QuantityStatistics from "../quantity-statistics/QuantityStatistics";
import "./StudentDataAnalysis.scss";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);
dayjs.extend(dayOfYear);

const { RangePicker } = DatePicker;

exporting(Highcharts);
exportData(Highcharts);

interface Statistic {
  month?: number;
  week?: number;
  date?: number;
  year: number;
  timeValue: string;
  registrationForm: number;
  description: string;
  count: number;
}

const StudentDataAnalysis: React.FC = () => {
  const { id } = useParams();
  const [size, setSize] = useState<string>("week");
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);
  const [statistics, setStatistics] = useState<Statistic[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [exportOption, setExportOption] = useState("Excel");
  const chartRef = useRef<any>(null);

  const onChange = (e: RadioChangeEvent) => {
    setSize(e.target.value);
    setDateRange(null);
  };

  const handleRangePickerChange = (
    dates: null | [Dayjs | null, Dayjs | null],
    dateStrings: [string, string]
  ) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange([dates[0], dates[1]]);
    }
  };

  const getDataForChart = () => {
    let categories: string[] = [];
    let seriesData: { [key: string]: number[] } = {
      "Học thử": [],
      "Học trước": [],
      "Mua khóa học": [],
    };

    if (size === "config" && dateRange) {
      const [start, end] = dateRange;
      const dates: string[] = [];
      for (
        let date = dayjs(start);
        date.isSameOrBefore(end);
        date = date.add(1, "day")
      ) {
        dates?.push(date.format("DD/MM/YYYY"));
      }
      categories = dates;
      statistics?.forEach((item) => {
        const itemDate = item.date
          ? dayjs().dayOfYear(item.date).format("DD/MM/YYYY")
          : "";
        if (dates.includes(itemDate)) {
          seriesData[item.description]?.push(item.count);
        } else {
          seriesData[item.description]?.push(0);
        }
      });
    } else {
      statistics?.forEach((item) => {
        if (size === "month" && item.month !== undefined) {
          if (!categories.includes(item.timeValue)) {
            categories?.push(item.timeValue);
          }
          seriesData[item.description]?.push(item.count);
        } else if (size === "week" && item.week !== undefined) {
          const weekString = `Tuần ${item.week}/${item.year}`;
          if (!categories.includes(weekString)) {
            categories?.push(weekString);
          }
          seriesData[item.description]?.push(item.count);
        } else if (
          size === "year" &&
          item.date === undefined &&
          item.week === undefined &&
          item.month === undefined
        ) {
          if (!categories.includes(item.timeValue)) {
            categories?.push(item.timeValue);
          }
          seriesData[item.description]?.push(item.count);
        }
      });
    }

    return {
      categories,
      series: [
        { name: "Học thử", data: seriesData["Học thử"], color: "#D9D9D9" },
        { name: "Học trước", data: seriesData["Học trước"], color: "#1677FF" },
        {
          name: "Mua khóa học",
          data: seriesData["Mua khóa học"],
          color: "#52C41A",
        },
      ],
    };
  };

  const fetchStatisticByWeek = async () => {
    try {
      const res = await getStatisticByWeek(Number(id));
      setStatistics(res.data.data.statistics);
    } catch (error) {
      console.error("Error fetching statistics by week", error);
    }
  };

  const fetchStatisticByMonth = async () => {
    try {
      const res = await getStatisticByMonth(Number(id));
      setStatistics(res.data.data.statistics);
    } catch (error) {
      console.error("Error fetching statistics by month", error);
    }
  };

  const fetchStatisticByYear = async () => {
    try {
      const res = await getStatisticByYear(Number(id));
      setStatistics(res.data.data.statistics);
    } catch (error) {
      console.error("Error fetching statistics by year", error);
    }
  };

  const fetchStatisticByDate = async () => {
    if (dateRange) {
      const params = {
        courseId: id,
        from: dateRange[0].endOf("day").toISOString(),
        to: dateRange[1].endOf("day").toISOString(),
      };
      const queryString = `${params.courseId}?from=${params.from}&to=${params.to}`;

      try {
        const res = await getStatisticByDate(queryString);
        setStatistics(res.data.data.statistics);
      } catch (error) {
        console.error("Error fetching statistics by date", error);
      }
    }
  };

  useEffect(() => {
    if (size === "week") {
      fetchStatisticByWeek();
    } else if (size === "month") {
      fetchStatisticByMonth();
    } else if (size === "year") {
      fetchStatisticByYear();
    } else if (size === "config" && dateRange) {
      fetchStatisticByDate();
    }
  }, [size, dateRange]);

  const chartData = getDataForChart();

  const options = {
    chart: {
      type: "line",
      scrollablePlotArea: {
        minWidth: 700,
        scrollPositionX: 1,
      },
    },
    title: {
      text: "Số lượng học viên ghi danh",
    },
    xAxis: {
      categories: chartData.categories,
      plotLines: statistics.map((_, index) => ({
        color: "#cccccc",
        width: 1,
        value: index,
        zIndex: 5,
      })),
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        text: "Số lượng",
      },
      allowDecimals: false,
      scrollbar: {
        enabled: true,
      },
    },
    tooltip: {
      shared: true,
    },
    series: chartData.series,
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
      },
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);

    if (chartRef.current) {
      if (exportOption === "PNG") {
        chartRef.current.chart.exportChart({
          type: "image/png",
          filename: "report",
        });
      } else if (exportOption === "PDF") {
        chartRef.current.chart.exportChart({
          type: "application/pdf",
          filename: "report",
        });
      } else {
        const params = {
          courseId: id,
          from: dateRange ? dateRange[0].endOf("day").toISOString() : undefined,
          to: dateRange ? dateRange[1].endOf("day").toISOString() : undefined,
          timeUnit: size,
        };
        let queryString = `${params.courseId}?timeUnit=${size}`;
        if (size === "config") {
          queryString += `&from=${params.from}&to=${params.to}`;
        }
        try {
          const res = await exportExcelCount(queryString);

          const fileData = res.data;

          const blob = new Blob([fileData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);

          downloadLink.download = "Thống kê theo trạng thái học tập.xlsx";

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          console.error("Error downloading Excel file:", error);
        }
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleExportOptionChange = (e: RadioChangeEvent) => {
    setExportOption(e.target.value);
  };

  return (
    <div className="student-data-analysis">
      <div className="content-card">
        <div className="content-card-name">
          <div>
            <div className="title">Số lượng học viên ghi danh</div>
            <div className="description">Số lượng học viên ghi danh</div>
          </div>
          <div className="action" onClick={showModal}>
            Xuất báo cáo
          </div>
        </div>
        <div className="flex gap-24 mt-2">
          <Radio.Group
            value={size}
            onChange={onChange}
            style={{ marginBottom: 16 }}
          >
            <Radio.Button value="week">Tuần</Radio.Button>
            <Radio.Button value="month">Tháng</Radio.Button>
            <Radio.Button value="year">Năm</Radio.Button>
            <Radio.Button value="config">Tuỳ chỉnh</Radio.Button>
          </Radio.Group>
          {size === "config" ? (
            <RangePicker
              onChange={handleRangePickerChange}
              placeholder={["Từ ngày", "Đến ngày"]}
              style={{ height: "32px" }}
              locale={locale}
            />
          ) : null}
        </div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      </div>
      <QuantityStatistics />
      <Modal
        title="Xuất báo cáo"
        open={isModalVisible}
        onOk={handleOk}
        okText="Xuất báo cáo"
        cancelText="Huỷ"
        onCancel={handleCancel}
        centered
      >
        <div className="hr-tag-item"></div>
        <Radio.Group onChange={handleExportOptionChange} value={exportOption}>
          <Space direction="vertical">
            <Radio value="Excel">Excel</Radio>
            <Radio value="PNG">Ảnh (PNG)</Radio>
            <Radio value="PDF">PDF</Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default StudentDataAnalysis;
