// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-style .wrapper-filter {
  display: flex;
  padding: 15px 15px 0 15px; }
  .filter-style .wrapper-filter .filter-form {
    width: 100%; }
    .filter-style .wrapper-filter .filter-form .row-filter {
      justify-content: space-between; }
      .filter-style .wrapper-filter .filter-form .row-filter .ant-picker-range {
        width: 100%; }
      .filter-style .wrapper-filter .filter-form .row-filter .col-filter {
        padding: 0 !important; }
        .filter-style .wrapper-filter .filter-form .row-filter .col-filter .group-btn--filter {
          width: 100%; }
          .filter-style .wrapper-filter .filter-form .row-filter .col-filter .group-btn--filter .filter-btn {
            width: 50%; }

.filter-style .group-btn--filter {
  margin-top: auto; }
`, "",{"version":3,"sources":["webpack://./src/components/category-management/management-detail/components/filter-group/FormFilter.scss"],"names":[],"mappings":"AAAA;EAEM,aAAa;EACb,yBAAyB,EAAA;EAH/B;IAMQ,WAAW,EAAA;IANnB;MASU,8BAA8B,EAAA;MATxC;QAYY,WAAW,EAAA;MAZvB;QAgBc,qBAAqB,EAAA;QAhBnC;UAmBkB,WAAW,EAAA;UAnB7B;YAsBoB,UAAU,EAAA;;AAtB9B;EA8BM,gBAAgB,EAAA","sourcesContent":[".filter-style {\n    .wrapper-filter {\n      display: flex;\n      padding: 15px 15px 0 15px;\n  \n      .filter-form {\n        width: 100%;\n  \n        .row-filter {\n          justify-content: space-between;\n  \n          .ant-picker-range {\n            width: 100%;\n          }\n          \n          .col-filter {\n              padding: 0 !important;\n  \n              .group-btn--filter {\n                  width: 100%;\n  \n                  .filter-btn {\n                    width: 50%;\n                  }\n              }\n          }\n        }\n      }\n    }\n    .group-btn--filter{\n      margin-top: auto;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
