// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-common .ant-modal-content {
  width: 417px; }
  .modal-common .ant-modal-content .ant-modal-title {
    display: flex;
    align-items: center; }
    .modal-common .ant-modal-content .ant-modal-title svg {
      width: 22px !important;
      height: 22px !important; }
    .modal-common .ant-modal-content .ant-modal-title span:last-child {
      margin-left: 15px;
      font-weight: 500; }
  .modal-common .ant-modal-content .ant-modal-body .description {
    margin: 0px 35px !important; }

.modal-delete-common .ant-modal-content {
  width: 417px; }
  .modal-delete-common .ant-modal-content .ant-modal-title {
    display: flex;
    align-items: center; }
    .modal-delete-common .ant-modal-content .ant-modal-title svg {
      width: 22px !important;
      height: 22px !important; }
    .modal-delete-common .ant-modal-content .ant-modal-title span:last-child {
      margin-left: 15px;
      font-weight: 500; }
  .modal-delete-common .ant-modal-content .ant-modal-body .description {
    margin: 0px 35px !important; }
`, "",{"version":3,"sources":["webpack://./src/components/modal/common.scss"],"names":[],"mappings":"AAAA;EAEI,YAAY,EAAA;EAFhB;IASM,aAAa;IACb,mBAAmB,EAAA;IAVzB;MAMQ,sBAAsB;MACtB,uBAAuB,EAAA;IAP/B;MAYQ,iBAAiB;MACjB,gBAAgB,EAAA;EAbxB;IAmBQ,2BAA2B,EAAA;;AAMnC;EAEI,YAAY,EAAA;EAFhB;IASM,aAAa;IACb,mBAAmB,EAAA;IAVzB;MAMQ,sBAAsB;MACtB,uBAAuB,EAAA;IAP/B;MAYQ,iBAAiB;MACjB,gBAAgB,EAAA;EAbxB;IAmBQ,2BAA2B,EAAA","sourcesContent":[".modal-common {\n  .ant-modal-content {\n    width: 417px;\n\n    .ant-modal-title {\n      svg {\n        width: 22px !important;\n        height: 22px !important;\n      }\n      display: flex;\n      align-items: center;\n      span:last-child {\n        margin-left: 15px;\n        font-weight: 500;\n      }\n    }\n\n    .ant-modal-body {\n      .description {\n        margin: 0px 35px !important;\n      }\n    }\n  }\n}\n\n.modal-delete-common {\n  .ant-modal-content {\n    width: 417px;\n\n    .ant-modal-title {\n      svg {\n        width: 22px !important;\n        height: 22px !important;\n      }\n      display: flex;\n      align-items: center;\n      span:last-child {\n        margin-left: 15px;\n        font-weight: 500;\n      }\n    }\n\n    .ant-modal-body {\n      .description {\n        margin: 0px 35px !important;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
