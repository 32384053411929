import React from "react";
import { NotificationItemProps } from "../../types/notifications";
import { displayStatusNotification } from "../../utils/notification";
import ExpandableText from "../expandable-Text/ExpandableText";
import { scrollToElement } from "../../utils/getPageFromLink";

interface NotificationItm extends NotificationItemProps {
  showBtnMore?: boolean;
  active?: boolean;
  onClick?: (value: number) => void;
}

function NotificationItem({
  active = false,
  senderName,
  message,
  title = "",
  id,
  created = "",
  onClick = () => {},
}: NotificationItm) {
  return (
    <div
      id={`${id}` as string}
      className={`notification-item`}
      onClick={() => {
        scrollToElement(`notify-${id}`);
        onClick(id as number);
      }}
    >
      <div className="item-content-wrap">
        <div className="notify-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="26"
            viewBox="0 0 22 26"
            fill="none"
          >
            <path
              d="M14.4992 21.1693C14.4992 23.1023 12.9322 24.6693 10.9992 24.6693C9.06619 24.6693 7.49919 23.1023 7.49919 21.1693M13.0951 6.28092C13.6032 5.75602 13.9159 5.04083 13.9159 4.2526C13.9159 2.64177 12.61 1.33594 10.9992 1.33594C9.38836 1.33594 8.08252 2.64177 8.08252 4.2526C8.08252 5.04083 8.39519 5.75602 8.90327 6.28092M17.9992 12.0693C17.9992 10.4603 17.2617 8.91721 15.9489 7.77949C14.6362 6.64177 12.8557 6.0026 10.9992 6.0026C9.14267 6.0026 7.36219 6.64177 6.04944 7.77949C4.73668 8.91721 3.99919 10.4603 3.99919 12.0693C3.99919 14.7314 3.33901 16.6783 2.51526 18.0714C1.57641 19.6592 1.10699 20.4531 1.12553 20.6427C1.14674 20.8597 1.18579 20.9281 1.36175 21.0568C1.51555 21.1693 2.28828 21.1693 3.83374 21.1693H18.1646C19.7101 21.1693 20.4828 21.1693 20.6366 21.0568C20.8126 20.9281 20.8516 20.8597 20.8728 20.6427C20.8914 20.4531 20.422 19.6592 19.4831 18.0714C18.6594 16.6783 17.9992 14.7314 17.9992 12.0693Z"
              stroke="#667085"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="item-content">
          <span className="font-size-14 flex justify-between">
            <b>Từ: {senderName}</b>
            <span className="content-time">
              {displayStatusNotification(created)}
              <span>
                {active ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_2580_8088)">
                      <rect
                        x="-0.00244141"
                        y="-0.000732422"
                        width="6.0025"
                        height="6.0015"
                        rx="3.00075"
                        fill="#1890FF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2580_8088">
                        <rect
                          x="-0.00244141"
                          y="-0.000732422"
                          width="6.0025"
                          height="6.0015"
                          rx="3.00075"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                ) : null}
              </span>
            </span>
          </span>
          <p className="font-size-16 font-weight-6">{title}</p>
          <ExpandableText text={message} maxLines={2} isViewMore={false} />
        </div>
      </div>
    </div>
  );
}

export default NotificationItem;
