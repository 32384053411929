import { ContactsOutlined, FileTextOutlined } from "@ant-design/icons";
import { Layout, Tabs, TabsProps } from "antd";
import { useNavigate } from "react-router-dom";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import DocumentIdentifyForm from "./documents-identity";
import SettingIdentifyForm from "./setting-identify-form";
import { Permission } from "../../../layout/sidebar/permission.enum";
import { CheckRole, getPermissions } from "../../../utils/checkRole";
import { useEffect, useState } from "react";

const { Content } = Layout;

const SettingIdentify = () => {
  const [displayRoleSettingIdentify, setDisplayRoleSettingIdentify] =
    useState(false);
  const [displayRoleDocumentIdentify, setDisplayRoleDocumentIdentify] =
    useState(false);

  const [listPermission, setListPermission] = useState([]);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleSettingIdentify = [Permission.TabIdentityCaseSettingUpdate];

    const roleDocumentIdentify = [Permission.TabIdentityDocumentSettingUpdate];

    const checkRoleSettingIdentify = CheckRole(
      roleSettingIdentify,
      listPermission
    );
    const checkRoleDocumentIdentify = CheckRole(
      roleDocumentIdentify,
      listPermission
    );

    if (checkRoleSettingIdentify.includes(true)) {
      setDisplayRoleSettingIdentify(true);
    }
    if (checkRoleDocumentIdentify.includes(true)) {
      setDisplayRoleDocumentIdentify(true);
    }
  }, [listPermission]);

  const listBreadcrumb = [
    {
      title: "Cài đặt nâng cao",
    },
    {
      title: "Cài đặt định danh",
    },
  ];
  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <ContactsOutlined />,
      label: "Trường hợp định danh",
      children: (
        <SettingIdentifyForm
          displayRoleSettingIdentify={displayRoleSettingIdentify}
        />
      ),
    },
    {
      key: "2",
      icon: <FileTextOutlined />,
      label: "Loại giấy tờ tùy thân",
      children: (
        <DocumentIdentifyForm
          displayRoleDocumentIdentify={displayRoleDocumentIdentify}
        />
      ),
    },
  ];
  return (
    <Content>
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Cài đặt định danh"
        // arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        // handleBack={() => navigate('/')}
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </Content>
  );
};

export default SettingIdentify;
