import { Flex, Modal, Typography } from "antd";
import { DEFAULT_ERROR } from "../../../../constants/supervision";
const { Title, Text } = Typography;

interface IPropsModal {
  open: boolean;
  onCancel: () => void;
  dataDetail: any;
}
const ModalVideo = ({ open, onCancel, dataDetail }: IPropsModal) => {
  
  return (
    <Modal
      title={"Video khả nghi"}
      closeIcon={true}
      centered
      open={open}
      onCancel={onCancel}
      footer=""
    >
      <video width="600" className="max-h-[400px]" controls>
        <source src={`${dataDetail?.video_path}`} type="video/webm" />
        Your browser does not support the video tag.
      </video>
      <Title level={5} className="pt-3">
        Chi tiết vi phạm
      </Title>
      <div className="max-h-[210px] overflow-auto">
        <Flex className="bg-[#FAFAFA] p-3 border-b">
          <Text className="w-1/2 px-2 border-r">Mô tả vi phạm</Text>
          <Text className="w-1/2 px-2">Thời gian</Text>
        </Flex>
        {dataDetail?.listSupervision?.map((data: any) => (
          <Flex className="p-3 border-b">
          <Text className="w-1/2 px-2">{DEFAULT_ERROR[data.type]}</Text>
          <Text className="w-1/2 px-2">{data?.time}</Text>
        </Flex>
        ))}
      </div>
    </Modal>
  );
};

export default ModalVideo;
