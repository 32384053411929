import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfig } from "../../config/api/configApi";

export const getListManagementParticipatePlatform: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-university-register/get-all-by-filter`, data);
};

export const getDetailManagementParticipatePlatform: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-university-register/${id}`);
};

export const getAllEnterpriseUniversity: (data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-university/get-all-enterprise-customer`, data);
};

export const getAllEnterpriseUniversityVer2: (data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.get(`/mooc-university/get-all-university`, data);
};

export const getDetailBasicAdminAccount: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-facility-management/account/${id}`);
};

export const createBasicAdminAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-facility-management/account`, data);
};

export const updateBasicAdminAccount: (
  id: string | undefined,
  data: any
) => Promise<AxiosResponse<any>> = (id: string | undefined, data: any) => {
  return axiosConfigV2.put(`/mooc-facility-management/account/${id}`, data);
};

export const getRoleDefaultAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfig.post(`/accounts/get-role-by-condition`, data);
};

export const getAccountByUniversity: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-facility-management/account/get-by-university/${id}`);
};

export const approveRequestJoinMooc: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.put(`/mooc-university-register/approve`, data);
};

