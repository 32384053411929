import { IDataCreateUser } from "../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";


export const getListSurvey: (search: any) => Promise<AxiosResponse<any>> = (search: any) => {
    return axiosConfig.post(`/survey-synthesis/search`, search)
}
export const getListParticipant: (search: any) => Promise<AxiosResponse<any>> = (search: any) => {
    return axiosConfig.post(`/survey-synthesis/list-participants`, search)
}
export const getSurveyById: (id: string) => Promise<AxiosResponse<any>> = (id: string) => {
    return axiosConfig.get(`/survey-synthesis/get-by-id/${id}`)
}
export const getResult: (search: any) => Promise<AxiosResponse<any>> = (search: any) => {
    return axiosConfig.post(`/survey-synthesis/get-result`, search)
}
export const getQuestionById: (id: string) => Promise<AxiosResponse<any>> = (id: string) => {
    return axiosConfig.get(`/survey-synthesis/get-question-by-id/${id}`)
}