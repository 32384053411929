import {
  Card,
  Flex,
  Layout,
  Switch,
  Tabs,
  TabsProps,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import PageHeaderCommon from "../../components/page-header-common/PageHeaderCommon";
import { useNavigate } from "react-router-dom";
import ControlQualityLessonContent from "./ControlLessonContent";
import { ContactsOutlined, FileTextOutlined } from "@ant-design/icons";
import ViewRatingVideo from "./ViewRatingVideo";
import { CheckRole, getPermissions } from "../../utils/checkRole";
import { Permission } from "../../layout/sidebar/permission.enum";
const imgPath = "/images/";

const { Title, Text } = Typography;
const { Content } = Layout;

const ControlQualityLesson = () => {
  const navigate = useNavigate();
  const [numberTab, setNumberTab] = useState("1");

  const [displayRoleUpdate, setDisplayRoleUpdate] = useState(false);
  const [listPermission, setListPermission] = useState([]);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleUpdate = [Permission.TabQualityAssessmentCriteriaSettingUpdate];

    const checkRoleUpdate = CheckRole(roleUpdate, listPermission);
    if (checkRoleUpdate.includes(true)) {
      setDisplayRoleUpdate(true);
      setNumberTab("1");
    } else {
      setNumberTab("2");
    }
  }, [listPermission]);

  const listBreadcrumb = [
    {
      title: "Cài đặt nâng cao",
    },
    {
      title: "Kiểm soát chất lượng tiết học video",
    },
    {
      title: "",
    },
  ];
  const items: TabsProps["items"] = [
    ...(displayRoleUpdate
      ? [
          {
            key: "1",
            icon: <ContactsOutlined />,
            label: "Cài đặt",
            children: <ControlQualityLessonContent />,
          },
        ]
      : []),
    {
      key: "2",
      icon: <FileTextOutlined />,
      label: "Xem đánh giá video khóa học",
      children: <ViewRatingVideo />,
    },
  ];
  const handleChangeTab = (tab: any) => {
    setNumberTab(tab);
  };
  return (
    <Content>
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Kiểm soát chất lượng tiết học video"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(-1)}
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey="1" activeKey={numberTab} items={items} onChange={handleChangeTab}/>
      </div>
    </Content>
  );
};

export default ControlQualityLesson;
