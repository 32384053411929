// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sortableList {
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;
  font-size: 14px; }
`, "",{"version":3,"sources":["webpack://./src/components/SortableList/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EAEtB,UAAU;EACV,gBAAgB;EAChB,eAAe,EAAA","sourcesContent":[".sortableList {\n  display: flex;\n  flex-direction: column;\n  // gap: 16px;\n  padding: 0;\n  list-style: none;\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
