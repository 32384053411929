import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Row, TableColumnType, Typography } from "antd";
import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import FormInput from "../../../../components/form-input/FormInput";

type FilterTableProps = {
  setlastPreview: (value: any) => void;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
  checkedList: Array<string>;
  setCheckedList: (value: string[]) => void;
  dataColumns: TableColumnType<any>[];
  selectedRowKeys: React.Key[];
  tab: string;
};

const FilterStudentList: FunctionComponent<FilterTableProps> = ({
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
}) => {
  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  return (
    <>
      <Row
        gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}
        className="row-filter flex justify-end"
      >
        <Col
          // md={{ span: 5 }}
          // lg={{ span: 5 }}
          // xl={{ span: 5 }}
          // xs={{ span: 7 }}
          className="filter-group-select"
        >
          <div className="heading-filter">
            <Button className="filter-button" onClick={handleOpenFilter}>
              <Typography.Text>Bộ lọc</Typography.Text>
              <DownOutlined />
            </Button>
          </div>
        </Col>
        <Col
        // md={{ span: 8 }}
        // lg={{ span: 8 }}
        // xl={{ span: 8 }}
        // xs={{ span: 8 }}
        >
          <div className="heading-search">
            <FormInput
              placeholder="Nhập tên học viên"
              value={searchValue}
              onChange={handleChangeInput}
              onKeyPress={handleSearch}
              afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FilterStudentList;
