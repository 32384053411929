import React, { useState } from 'react'
import CustomCard from '../../../custom-card/CustomCard'
import { Button, Col, Row, Select, Table, TableColumnType, TableColumnsType, TreeDataNode, Typography } from 'antd'
import DirectoryTree, { DirectoryTreeProps } from 'antd/es/tree/DirectoryTree'
import Icon, { CaretDownOutlined, CaretUpOutlined, FileOutlined, InfoCircleOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import "./index.scss"
import FormItemDropdown from '../../../form-dropdown/FormItemDropdown';
import { isEmpty } from 'lodash';
import { DetailCourseData } from '../../../../types/assign-course-construction';

type Props = {
  detailData: DetailCourseData | undefined
}

export default function DetailedAssignment(props: Props) {
  const { detailData } = props
  const [expandAll, setExpandAll] = useState(false)

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {

  };

  const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {

  };

  interface DataType {
    key: React.ReactNode;
    name: string;
    assignedName: string;
    assignedId: string;
    children?: DataType[];
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '',
      dataIndex: 'assignedName',
      width: '40%',
      key: 'assignedName',
      render: (_: any, record) => {
        return (
          <p>{!isEmpty(record.assignedName) ? `${record.assignedName} (${record.assignedId})` : ""}</p>
        )
      },
    },
  ];

  const data: any = !isEmpty(detailData) && detailData?.sections.map((item: any, index: number) => {
    return {
      key: item.key,
      name: item.name,
      assignedName: item.assignedName,
      assignedId: item.assignedId,
      children: item.children
    }
  })

  return (
    <CustomCard title={
      <div className="card-heading--info">
        <Row gutter={[{ xs: 12, xl: 8, md: 8 }, 8]}>
          <Col xl={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>

            <Typography.Title level={5}>
              Cấu trúc của khoá học
            </Typography.Title>
            <Typography.Text className="sub-title">
              Chi tiết cấu trúc được duyệt của kế hoạch
            </Typography.Text>
          </Col>
          <Col xl={{ span: 4 }} md={{ span: 4 }} sm={{ span: 4 }} xs={{ span: 4 }}></Col>
          <Col xl={{ span: 8 }} md={{ span: 8 }} sm={{ span: 8 }} xs={{ span: 8 }}>
            <Button onClick={() => setExpandAll(!expandAll)} className='float-right'>{expandAll ? "Thu gọn" : "Hiển thị chi tiết"}</Button>
          </Col>

        </Row>
      </div>
    } className="card-info--wrapper">
      <div className="list-wrapper">
        <Table
          size="small"
          indentSize={0}
          columns={columns}
          dataSource={data}
          pagination={false}

          rowClassName={(record, index) => {
            if (!isEmpty(record.children)) {
              return 'expand-record'
            }
            return "";
          }}
        />
      </div>
    </CustomCard>
  )
}
