import { message, Modal, Table, TableColumnsType, TableProps } from "antd";
import { useAuthStore } from "../../../../../stores/stores";
import { ChangeEvent, useEffect, useState } from "react";
import FormItemInput from "../../../../../components/form-input/FormInput";
import { SearchOutlined } from "@ant-design/icons";
import { formatDateV2 } from "../../../../../utils/converDate";
import "./InfoCourseApprovals.scss";
import TableData from "../../../../../components/table-data/TableData";
import { DataType } from "../../../../../types/listUsers";
import { getListAssign } from "../../../../../service/assign-course-construction";

import { ApprovalType } from "../../../../../types/survey-browse";

import { get, isEmpty } from "lodash";
import { HelpService } from "../../../../../service/helper.service";
import {
  approvalStatus,
  approvalStatusEum,
  courseStatusEum,
} from "../../constants/data";
import {
  approveManySurvey,
  postCoursePlanSearchAllApprove,
  postCoursePlanSearchApprove,
  postSurveySearchApprove,
} from "../../../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import { FilterDateCoursePlan } from "../../../../../types/course-plan-approval";
import Item from "antd/es/list/Item";
import {
  CreareCoursePlanArroval,
  CrearteManyCoursePlanArroval,
  DataTypeMoocApproval,
  FilterDataMoocApproval,
} from "../../../../../types/course-plan-managment/mooc-approval";
import {
  getMoocApprovedCourseById,
  postMoocApprovalCourseSearch,
} from "../../../../../service/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-registration-api";
import { DetailContent } from "../detail-content/DetailContent";
import PreviewCourseStructure from "../preview-course-structure/PreviewCourseStructure";
import {
  TypeChapterList,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { getListMoocCourseStructureById } from "../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";

interface RowKeyType {
  id: number;
}
type Props = {
  open: boolean;
  handleCancel: () => void;
  data?: DataTypeMoocApproval;
  handleComfim: () => void;
};
export default function InfoCourseApprovals(props: Props) {
  const { open, handleCancel, data, handleComfim } = props;
  const userId = useAuthStore((states) => states.user?.id);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [listSurveyMany, setListSurveyMany] = useState<ApprovalType[]>([]);
  const helpService = new HelpService();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [dataListCourseStructure, setDataListCourseStructure] = useState<
    TypeChapterList[]
  >([]);
  // const [filterData, setFilterData] = useState<FilterDataMoocApproval>({
  //   submissionDate: null,
  //   approvalDate: null,
  // });

  useEffect(() => {
    if (open && data?.courseConstructionPlanId) {
      getListMoocCourseStructureByIdData(data?.id);
    }
  }, [open]);
  const onOk = () => {
    handleComfim();
  };
  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 1)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level2 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 2)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level3 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 3)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );

    const chapterList: TypeChapterList[] = level1.map((l1, index) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        orderNumber: l1.orderNumber,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2
        .filter((l2) => l2.parentId === l1.id)
        .sort((a, b) =>
          a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
        );

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject, index) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          orderNumber: subject.orderNumber,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3
          .filter((l3) => l3.parentId === subject.id)
          .sort((a, b) =>
            a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
          );

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson, index) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          orderNumber: lesson.orderNumber,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });
    // console.log(`chapterList::`, chapterList);
    return chapterList;
  };
  const getListMoocCourseStructureByIdData = async (id: any) => {
    try {
      await getMoocApprovedCourseById(id)
        .then(async (res) => {
          const dataRes = res.data.data;
          await getListMoocCourseStructureById(
            dataRes?.moocCourseConstructionPlan?.id
          )
            .then((resp) => {
              const data = resp.data.data;
              setDataListCourseStructure(changeToChapterList(data));
            })
            .catch((err) => {
              console.log(err);
              message.error("Có lỗi chưa xác định");
            });
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  return (
    <>
      <Modal
        title={`Phê duyệt nhanh ${data?.courseConstructionPlanName}`}
        centered
        open={open}
        okText="Phê duyệt"
        cancelText="Huỷ"
        onOk={onOk}
        onCancel={handleCancel}
        width={800}
      >
        <div className={`wrapper ${false ? "read-only" : ""}`}>
          <section className="detail-account">
            <div className="form-detail">
              <PreviewCourseStructure
                dataCourseStructure={dataListCourseStructure}
              />
            </div>
          </section>
        </div>
      </Modal>
    </>
  );
}
