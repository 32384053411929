import React from 'react';
import {Col, Empty, Row, Select, Spin} from "antd";
import StatisticalReportCard from '../../StatisticalReportCard';
import TableWithTotals from '../../../../../../components/table-with-totals/TableWithTotals';
import {handleExportReport} from "../../../../../../utils/staticReport";
import {
  exportTableUniversityRateUnitByCourse,
  exportTableUniversityRateUnitByModule,
  exportTableUniversityRateUnitBySequence,
  exportTableUniversityUnitDiscussByTime,
  exportTableUniversityUnitDiscussCourseAction,
  exportTableUniversityUnitReviewAccess
} from "../../../../../../service/statistical-report";
import dayjs from "dayjs";
import {createTableData} from "../../../../for-instructors/components/table/tableConvertData";
import {BarStackedChartProps, DualChart} from "../../../../../../types/static-report";
import {staticReportStore} from "../../../../../../stores/stores";

type FeedbackEvaluationTableProps = {
  loading: boolean,
  unitDiscussUniversityList: BarStackedChartProps[];
  courseDiscussActionUniversity: BarStackedChartProps[];
  unitReviewAccessUniversity: DualChart[];
  rateUnitByModuleUniversity: BarStackedChartProps[];
  rateCourseByModuleUniversity: BarStackedChartProps[];
  rateSequenceByModuleUniversity: BarStackedChartProps[];
  setModuleGroup: (value: any) => void,
}


const FeedbackEvaluationTable = ({
                                   loading,
                                   unitDiscussUniversityList,
                                   courseDiscussActionUniversity,
                                   unitReviewAccessUniversity,
                                   rateUnitByModuleUniversity,
                                   rateCourseByModuleUniversity,
                                   rateSequenceByModuleUniversity,
                                   setModuleGroup
                                 }: FeedbackEvaluationTableProps) => {

  const {filterValues} = staticReportStore()

  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
    courseStructureType: filterValues?.courseStructureType,
  };


  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng phản hồi
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo thời gian"
            content={
              <Spin spinning={loading}>
                {unitDiscussUniversityList.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(unitDiscussUniversityList).dataSource}
                    columns={[
                      {
                        title: "Thời gian tạo",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(unitDiscussUniversityList).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                      },
                    ]}
                    keyColumn="criteria"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityUnitDiscussByTime,
                    "Thống kê số lượng phản hồi theo thời gian.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityUnitDiscussByTime,
                    "Thống kê số lượng phản hồi theo thời gian.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo bài giảng"
            content={
              <Spin spinning={loading}>
                {courseDiscussActionUniversity.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(courseDiscussActionUniversity).dataSource}
                    columns={[
                      {
                        title: "Bài giảng",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(unitDiscussUniversityList).columns,
                      // {
                      //   title: "Tổng",
                      //   dataIndex: "Tổng",
                      //   key: "Tổng",
                      // },
                    ]}
                    keyColumn="criteria"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }

            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityUnitDiscussCourseAction,
                    "Thống kê số lượng phản hồi theo bài giảng.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityUnitDiscussCourseAction,
                    "Thống kê số lượng phản hồi theo bài giảng.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng đánh giá
        </p>
      </div>
      <Row gutter={[{xs: 8, sm: 8, md: 16, lg: 16}, {xs: 8, sm: 8, md: 16, lg: 16}]}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng đánh giá theo thời gian"
            content={
              <Spin spinning={loading}>
                {unitReviewAccessUniversity.length > 0 ? (
                  <TableWithTotals
                    data={unitReviewAccessUniversity}
                    columns={[
                      {
                        title: "Ngày đánh giá",
                        dataIndex: "criteria",
                        key: "criteria",
                      },
                      {
                        title: "Số lượt hoàn thành",
                        dataIndex: "lineValue",
                        key: "lineValue",
                      },
                      {
                        title: "Số lượt đánh giá",
                        dataIndex: "columnValue",
                        key: "columnValue",
                      },
                    ]}
                    keyColumn="criteria"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            moreOption={
              <Select
                className="mr-2"
                defaultValue={3}
                style={{minWidth: 160}}
                onSelect={(value) => {
                  setModuleGroup(value);
                }}
                options={[
                  {value: 3, label: 'Bài kiểm tra'},
                  {value: 2, label: 'Tài liệu tham khảo'},
                  {value: 1, label: 'Đa phương tiện'},
                  {value: 4, label: 'SCORM & xAPI'},
                ]}
              />
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityUnitReviewAccess,
                    "Số lượng đánh giá theo thời gian.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityUnitReviewAccess,
                    "Số lượng đánh giá theo thời gian.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá khóa học"
            content={
              <Spin spinning={loading}>
                {rateUnitByModuleUniversity.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(rateUnitByModuleUniversity).dataSource}
                    columns={[
                      {
                        title: "Khóa học",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(rateUnitByModuleUniversity).columns,
                      // {
                      //   title: "Tổng",
                      //   dataIndex: "Tổng",
                      //   key: "Tổng",
                      // },
                    ]}
                    keyColumn="criteria"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityRateUnitByModule,
                    "Tỷ lệ đánh giá khóa học.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityRateUnitByModule,
                    "Tỷ lệ đánh giá khóa học.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá bài giảng"
            content={
              <Spin spinning={loading}>
                {rateCourseByModuleUniversity.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(rateCourseByModuleUniversity).dataSource}
                    columns={[
                      {
                        title: "Khóa học",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(rateCourseByModuleUniversity).columns,
                      // {
                      //   title: "Tổng",
                      //   dataIndex: "Tổng",
                      //   key: "Tổng",
                      // },
                    ]}
                    keyColumn="criteria"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityRateUnitByCourse,
                    "Tỷ lệ đánh giá bài giảng.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityRateUnitByCourse,
                    "Tỷ lệ đánh giá bài giảng.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá học liệu"
            content={
              <Spin spinning={loading}>
                {rateSequenceByModuleUniversity.length > 0 ? (
                  <TableWithTotals
                    data={createTableData(rateSequenceByModuleUniversity).dataSource}
                    columns={[
                      {
                        title: "Khóa học",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(rateSequenceByModuleUniversity).columns,
                      // {
                      //   title: "Tổng",
                      //   dataIndex: "Tổng",
                      //   key: "Tổng",
                      // },
                    ]}
                    keyColumn="criteria"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityRateUnitBySequence,
                    "Tỷ lệ đánh giá học liệu.xlsx",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityRateUnitBySequence,
                    "Tỷ lệ đánh giá học liệu.pdf",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />

        </Col>
      </Row>
    </section>
  </>;
};

export default FeedbackEvaluationTable;
