import { notification } from "antd";
import { isEmpty } from "lodash";

export const notifySuccess = (message: string, onClose?: () => void) => {
  const iconUrl = "/assets/icons/check-circle.svg";
  notification.success({
    message: message,
    icon: (
      <img src={iconUrl} alt="Success Icon" style={{ width: 24, height: 24 }} />
    ),
    duration: 2,
    onClose: onClose,
  });
};

export const notifyError = (message: string, onClose?: () => void) => {
  const iconUrl = "/assets/icons/error.svg";
  notification.error({
    message: message,
    icon: (
      <img src={iconUrl} alt="Success Icon" style={{ width: 24, height: 24 }} />
    ),
    duration: 2,
    onClose: onClose,
  });
};

export const notifyWarning = (message: string, onClose?: () => void) => {
  const iconUrl = "/assets/icons/waning-notify.png";
  notification.error({
    message: message,
    icon: (
      <img src={iconUrl} alt="Success Icon" style={{ width: 24, height: 24 }} />
    ),
    duration: 2,
    onClose: onClose,
  });
};

export const displayStatusNotification = (created: string) => {
  if (isEmpty(created)) return "";
  const startTime = new Date(created);

  const currentTime = new Date();

  const differenceInMilliseconds = +currentTime - +startTime;
  const minutes = differenceInMilliseconds / (1000 * 60);

  if (minutes < 1) {
    return "Vừa xong";
  } else if (minutes < 60) {
    return `${Math.round(minutes)} phút`;
  } else {
    const hours = minutes / 60;
    if (hours < 24) {
      return `${Math.round(hours)} giờ trước`;
    } else {
      const days = hours / 24;
      if (days > 1) {
        return `${Math.round(days)} ngày trước`;
      } else {
        return `${Math.round(hours)} giờ trước`;
      }
    }
  }
};