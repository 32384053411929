import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, FormInstance, Row, Typography } from "antd";
import { FunctionComponent } from "react";
import "../filter-group/FormFilter.scss";
type FormFilterProps = {
    handleCloseFilter: () => void;
    showFilter?: boolean;
    form: FormInstance;
    clearFilter: () => void;
    onFinish?: (value: any) => void;
    dataOptions?: any;
    filterData: any;
};

const FormFilterLecturerHistory: FunctionComponent<FormFilterProps> = ({
    onFinish,
    dataOptions,
    form,
    showFilter,
    clearFilter,
    filterData,
}) => {
    const { RangePicker } = DatePicker;

    return (
        <div className="filter-style">
            <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
                <Form
                    form={form}
                    name="filterForm"
                    className="filter-form"
                    onFinish={onFinish}
                >
                    <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]} className="row-filter">
                        <Col
                            xl={{ span: 6 }}
                            md={{ span: 4 }}
                            sm={{ span: 4 }}
                            xs={{ span: 24 }}
                        >
                            <Typography.Title level={5}>Thời gian</Typography.Title>
                            <Form.Item name="rangeDate">
                                <RangePicker
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    format="DD/MM/YYYY"
                                    value={filterData.createDate}
                                />
                            </Form.Item>
                        </Col>
                        <div className="group-btn--filter">
                            <Button
                                className="btn btn-primary filter-btn"
                                htmlType="submit"
                                onClick={() => form.submit()}
                            >
                                <FilterOutlined />
                                <Typography.Text>Lọc</Typography.Text>
                            </Button>
                            <Button
                                className="btn btn-outlined clear-filter-btn"
                                onClick={clearFilter}
                            >
                                <DeleteOutlined />
                                <Typography.Text>Xoá tất cả</Typography.Text>
                            </Button>
                        </div>
                    </Row>
                </Form>
            </div>
        </div>
    )
}
export default FormFilterLecturerHistory;