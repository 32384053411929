import React, { useState } from "react";
import "./detail.scss";
import {
  Input,
  Button,
  Space,
  Card,
  Dropdown,
  Modal,
  MenuProps,
  Row,
  Col,
} from "antd";

// import {listSurveySection2 } from "./dataExample";
import { ListSurveySection } from "../../../../../../../../types/survey-respond";
import RightControl from "../right-control/RightControl";
import QuestionUI from "../detail-question/question-ui/QuestionUI";
import themeSurvey from "../../../../../../common/constant/theme-survey.const";

// const listSurveySections = listSurveySection2;

interface DetailSectionProps {
  dataSection: ListSurveySection[];
  theme: string;
}

const DetailSection: React.FC<DetailSectionProps> = ({
  dataSection,
  theme,
}) => {
  return (
    <div className="survey-managment-respond-section">
      <div>
        {dataSection.map((listSurveySection, index) => {
          const {
            surveyId,
            sectionName,
            sectionDescription,
            orderNumber,
            isDeleted,
            moocSurveyQuestion,
          }: ListSurveySection = listSurveySection;
          return (
            <React.Fragment key={surveyId}>
              {" "}
              {/* Added unique key here */}
              {dataSection.length > 1 && (
                <Card
                  key={index}
                  title={
                    dataSection.length < 2
                      ? ``
                      : `Phần ${index + 1} : ${sectionName}`
                  }
                  styles={{
                    header: {
                      color: themeSurvey
                        .filter((item) => {
                          return item.value == theme;
                        })[0]
                        .textColor.toString(),
                      backgroundColor: themeSurvey
                        .filter((item) => {
                          return item.value == theme;
                        })[0]
                        .color.toString(),
                    },
                    body: {},
                  }}
                  className="wrapper-settings--card form-setting--common notPadding"
                >
                  <Row className="surveyContainer-row">
                    <Row>
                      <Col span={24} className="surveyTitle">
                        {sectionName}
                      </Col>
                      <Col span={24} className="surveyDiscription">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ` ${sectionDescription ?? ""}`,
                          }}
                        />
                      </Col>
                    </Row>
                  </Row>
                </Card>
              )}
              <QuestionUI
                key={`${surveyId}-${index}`}
                listSectionQuestions={moocSurveyQuestion ?? []}
                surveyId={surveyId}
              />{" "}
              {/* Added unique key here */}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default DetailSection;
