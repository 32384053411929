import {Checkbox, CheckboxProps, Col, DatePicker, Form, Input, Radio, Row,} from "antd";
import FormInput from "../../../../components/form-input/FormInput";
import {FormInstance} from "antd/lib";
import {FunctionComponent, useEffect, useState} from "react";
import FormItemDropdown from "../../../form-dropdown/FormItemDropdown";
import {get} from "lodash";
import {
  dataSurveyMethod,
  dataSurveyObject,
  dataSurveyTarget,
} from "../../../../pages/course-construction/survey-plan/constants/data";
import {RangePickerProps} from "antd/es/date-picker";
import dayjs from "dayjs";

const validations = {
  planTitle: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tiêu đề."));
      }
      if (value && value.length > 512) {
        return Promise.reject(new Error("Giới hạn tối đa 512 ký tự."));
      }
      return Promise.resolve();
    },
  },
  planCode: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập mã kế hoạch."));
      }
      if (value && value.length > 512) {
        return Promise.reject(new Error("Giới hạn tối đa 512 ký tự."));
      }
      return Promise.resolve();
    },
  },
  surveyTarget: {
    required: true,
    message: "Vui lòng chọn mục tiêu."
  },
  surveyTargetCourse: {
    required: true,
    message: "Vui lòng chọn khóa học."
  },
  surveyTargetProgram: {
    required: true,
    message: "Vui lòng chọn chương trình học."
  },
  surveyTargetDescription: {
    required: true,
    message: "Vui lòng điền mô tả."
  },
  surveyMethod: {
    required: true,
    message: "Vui lòng chọn phương pháp khảo sát.",
  },
  executionTime: {
    required: true,
    message: "Vui lòng chọn thời gian thực hiện.",
  },
  surveyObject: {
    required: true,
    message: "Vui lòng chọn đối tượng.",
  },
  universityObject: {
    required: true,
    message: "Vui lòng chọn trường.",
  },
  universityCourse: {
    required: true,
    message: "Vui lòng chọn khóa học.",
  },
  universitySpecialized: {
    required: true,
    message: "Vui lòng chọn chuyên ngành.",
  },
};
const {TextArea} = Input;

type FormCreateSurveyProps = {
  form: FormInstance;
  dataOptions?: any;
  dataOptionsSurvey?: any;
  checkCloseData: boolean;
  dataDetail?: any;
  onFinish?: (values: any) => any;
  isOpenEdit: boolean;
};

const FormCreateSurvey: FunctionComponent<FormCreateSurveyProps> = ({
                                                                      form,
                                                                      dataOptions,
                                                                      dataOptionsSurvey,
                                                                      checkCloseData,
                                                                      dataDetail,
                                                                      onFinish,
                                                                      isOpenEdit,
                                                                    }) => {
  const [selectedTarget, setSelectedTarget] = useState<number | null>(null);
  const universityCheckboxValue = Form.useWatch('universityCheckbox', form)
  const registerCourseCheckboxValue = Form.useWatch('registerCourseCheckbox', form)
  const completedCourseCheckboxValue = Form.useWatch('completedCourseCheckbox', form)
  const specializedCheckboxValue = Form.useWatch('specializedCheckbox', form)
  const surveyObjectValue = Form.useWatch('surveyObject', form)
  const handleTargetChange = (index: any) => {
    const value = dataSurveyTarget[index - 1];
    setSelectedTarget(value.code);
  };


  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };


  const handleCheckedUniversity: CheckboxProps["onChange"] = (e) => {
    form.setFieldsValue({
      'universityCheckbox': e.target.checked
    })
  };
  const handleCheckedRegisterCourse: CheckboxProps["onChange"] = (e) => {
    form.setFieldsValue({
      'registerCourseCheckbox': e.target.checked
    })
  };
  const handleCheckedCompletedCourse: CheckboxProps["onChange"] = (e) => {
    form.setFieldsValue({
      'completedCourseCheckbox': e.target.checked
    })
  };
  const handleCheckedSpecialized: CheckboxProps["onChange"] = (e) => {
    form.setFieldsValue({
      'specializedCheckbox': e.target.checked
    })
  };


  // True: Đối tượng = Nhóm đối tượng cụ thể
  // False: Đối tượng = Bất kỳ ai có đường dẫn
  const renderAdditionalFieldsByObject = () => {
    if (surveyObjectValue) {
      return (
        <>
          <Col span={8} className="col-table-create">
            <Form.Item name="universityCheckbox">
              <Checkbox
                checked={universityCheckboxValue}
                onChange={handleCheckedUniversity}
              >
                Theo trường
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={16} className="col-table-create">
            <Form.Item
              className="object-form-item"
              name="universityList"
              label="Danh sách trường"
            >
              <FormItemDropdown
                options={get(dataOptions, "dataUniversity", [])}
                allowClear={true}
                placeholder="Chọn trường"
                modeDropdown="multiple"
                disabled={!universityCheckboxValue}
              />
            </Form.Item>
          </Col>
          <Col span={8} className="col-table-create">
            <Form.Item name="registerCourseCheckbox">
              <Checkbox
                checked={registerCourseCheckboxValue}
                onChange={handleCheckedRegisterCourse}
              >
                Theo khóa học đăng ký
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={16} className="col-table-create">
            <Form.Item
              className="object-form-item"
              name="registerCourseList"
              label="Danh sách khóa học đăng ký"
            >
              <FormItemDropdown
                options={dataOptionsSurvey.filter((item: any) => item.status = 13)}
                allowClear={true}
                placeholder="Chọn khóa học"
                modeDropdown="multiple"
                disabled={!registerCourseCheckboxValue}
              />
            </Form.Item>
          </Col>
          <Col span={8} className="col-table-create">
            <Form.Item name="completedCourseCheckbox">
              <Checkbox
                checked={completedCourseCheckboxValue}
                onChange={handleCheckedCompletedCourse}
              >
                Theo khóa học hoàn thành
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={16} className="col-table-create">
            <Form.Item
              className="object-form-item"
              name="completedCourseList"
              label="Danh sách khóa học hoàn thành"
            >
              <FormItemDropdown
                options={dataOptionsSurvey.filter((item: any) => item.status = 13)}
                allowClear={true}
                placeholder="Chọn khóa học"
                modeDropdown="multiple"
                disabled={!completedCourseCheckboxValue}
              />
            </Form.Item>
          </Col>
          <Col span={8} className="col-table-create">
            <Form.Item name="specializedCheckbox">
              <Checkbox
                checked={specializedCheckboxValue}
                onChange={handleCheckedSpecialized}
              >
                Theo chuyên ngành
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={16} className="col-table-create">
            <Form.Item
              className="object-form-item"
              name="specializedList"
              label="Danh sách chuyên ngành"
            >
              <FormItemDropdown
                options={get(dataOptions, "dataSpecialized", [])}
                allowClear={true}
                placeholder="Chọn chuyên ngành"
                modeDropdown="multiple"
                disabled={!specializedCheckboxValue}
              />
            </Form.Item>
          </Col>
        </>
      );
    } else {
      return <></>;
    }
  };

  //   1: Mục tiêu = Khảo sát khóa học
  //   2: Mục tiêu = Khảo sát chương trình học
  //   3: Mục tiêu = khác
  const renderAdditionalFieldsByTarget = () => {
    switch (selectedTarget) {
      case 1:
        return (
          <Form.Item name="course" label="Khóa học" rules={[validations.surveyTargetCourse]}>
            <FormItemDropdown
              modeDropdown="multiple"
              options={get(dataOptions, "dataSurveyCourse", [])}
              allowClear={true}
              placeholder="Chọn khóa học"
              // onChange={checkOnChange}
            />
          </Form.Item>
        );
      case 2:
        return (
          <Form.Item name="program" label="Chương trình học" rules={[validations.surveyTargetProgram]}>
            <FormItemDropdown
              modeDropdown="multiple"
              options={get(dataOptions, "dataSurveyCurriculum", [])}
              allowClear={true}
              placeholder="Chọn chương trình học"
              // onChange={checkOnChange}
            />
          </Form.Item>
        );
      case 3:
        return (
          <Form.Item name="description" label="Mô tả" rules={[]}>
            <TextArea showCount maxLength={512} placeholder="Nhập mô tả"/>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setSelectedTarget(null);
  }, [checkCloseData]);


  useEffect(() => {
    if (dataDetail.targetType) {
      setSelectedTarget(dataDetail.targetType);
    }
  }, [dataDetail]);


  return (
    <>
      <Form
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        name="createSurveyPlan"
        className="form-create-survey"
        initialValues={{
          'surveyMethod': true,
          'surveyObject': true,
        }}
      >
        <Row gutter={16}>
          <Col span={12} className="col-table-create">
            <Form.Item
              name="planTitle"
              rules={[validations.planTitle]}
              label="Tiêu đề"
            >
              <FormInput placeholder="Nhập tiêu đề"/>
            </Form.Item>
          </Col>
          <Col span={12} className="col-table-create">
            <Form.Item
              name="planCode"
              label="Mã kế hoạch"
              rules={[validations.planCode]}
            >
              <FormInput placeholder="Nhập mã kế hoạch"/>
            </Form.Item>
          </Col>
          <Col span={24} className="col-table-create">
            <div className="wrapper-radio">
              <Form.Item
                name="surveyMethod"
                label="Phương pháp khảo sát"
                rules={[validations.surveyMethod]}
              >
                <Radio.Group
                  options={dataSurveyMethod}
                />
              </Form.Item>
            </div>
          </Col>
          <Col span={12} className="col-table-create">
            <Form.Item
              name="executionTime"
              label="Thời gian thực hiện"
              rules={[validations.executionTime]}
            >
              <DatePicker.RangePicker
                allowClear
                format="DD/MM/YYYY"
                disabledDate={disabledDate}
                style={{width: "100%"}}
                placeholder={["Từ ngày", "Đến ngày"]}
              />
            </Form.Item>
          </Col>
          <Col span={12} className="col-table-create">
            <Form.Item name="surveyTarget" label="Mục tiêu"
                       rules={[validations.surveyTarget]}
            >
              <FormItemDropdown
                options={dataSurveyTarget}
                placeholder="Chọn mục tiêu"
                onChange={handleTargetChange}
              />
            </Form.Item>
          </Col>
          <Col span={24} className="col-table-create">
            {renderAdditionalFieldsByTarget()}
          </Col>

          <Col span={24} className="col-table-create">
            <div className="wrapper-radio">
              <Form.Item
                name="surveyObject"
                label="Đối tượng"
                rules={[validations.surveyObject]}
              >
                <Radio.Group
                  options={dataSurveyObject}
                />
              </Form.Item>
            </div>
          </Col>
          {renderAdditionalFieldsByObject()}
        </Row>
      </Form>
    </>
  );
};

export default FormCreateSurvey;
