import { Collapse, Drawer, Form, Radio, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import "./index.scss"
import { get, isEmpty } from 'lodash'
import { getAllQuizByUnit } from '../../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz'

type Props = {
  open: boolean
  onClose: () => void
  detailData: any
}

export default function DetailEvaluateDrawer(props: Props) {
  const { open, onClose, detailData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [countTrue, setCountTrue] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [dataPanel, setDataPanel] = useState<any>([])

  useEffect(() => {
    if (get(detailData, 'id', '') !== '') {
      getListDataSection();
    }
  }, [open]);

  const getListDataSection = async () => {
    const data = {
      id: detailData?.id,
      keyword: ""
    }
    await getAllQuizByUnit(data).then((res) => {
      const listDataFiler = res.data
      const dataSection = listDataFiler?.filter((item: any) => !item.isBegin && !item.isEnd)
      const listResult = dataSection?.map((i: any) => {
        const listChildren = i.quizzes?.map((u: any) => {
          let listAnswer = []
          if (u.answers !== "[]") {
            listAnswer = JSON.parse(u.answers).map((result: any) => {
              if (result.isTrue) {
                let count = countTrue + 1
                setCountTrue(count)
              }
              return result.content
            })
          }
          return {
            title: u?.question,
            answers: listAnswer,
          }
        })
        return {
          title: i?.title,
          children: listChildren
        }
      })
      setDataPanel(listResult)
    })
  }

  const renderPanelLesson = dataPanel?.map((item: any, index: number) => (
    <Panel
      header={<div dangerouslySetInnerHTML={{ __html: item.title }}></div>}
      key={index}
      className="setting-chapter-title mt-2">
      {
        item.children?.map((lesson: any, index: number) => (
          <Collapse>
            <Panel
              header={<div dangerouslySetInnerHTML={{ __html: lesson.title }}></div>}
              key={index + 100}
              className={`setting-chapter-title ${isEmpty(lesson.answers) ? 'no-expand' : ''}`}>
              <div className='m-5'>
                {
                  lesson.answers.map((unit: any, index: number) => (
                    <p key={index + 1000} className='mt-1'>{unit}</p>
                  ))
                }
              </div>
            </Panel>
          </Collapse>
        ))
      }
    </Panel>
  ))

  return (
    <Drawer
      title={"Học liệu"}
      open={open}
      onClose={onClose}
      className='content-detail-course-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
    >
      <Form
        className="active-settings-chapter"
        layout={"vertical"}
        form={form}
        name="active-settings-chapter"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel
              header="Thông tin chung"
              key="1"
              className="setting-chapter-title">
              <div className='m-5'>
                <Typography.Title level={5}>Tên đánh giá:</Typography.Title>
                <p>{detailData?.name}</p>
                <Typography.Title level={5} className='mt-2'>Mô tả:</Typography.Title>
                <p>{detailData?.description}</p>
                <Typography.Title level={5} className='mt-2'>Loại đánh giá:</Typography.Title>
                <Radio checked className='cursor-default'>{detailData?.reviewType === 1 ? 'Bài tập' : (detailData?.reviewType === 2 ? 'Bài kiểm tra' : 'Bài thi')}</Radio>
              </div>
            </Panel>

            <Panel
              header="Cấu hình hoạt động"
              key="2"
              className="setting-chapter-title">
              <div className='m-5'>
                <Typography.Title level={5} className='mt-2'>Thời gian:</Typography.Title>
                <p>3 phút</p>
                <Typography.Title level={5} className='mt-2'>Phản hồi:</Typography.Title>
                <p>HIển thị số câu trả lời đúng: {countTrue}</p>
              </div>
            </Panel>
          </Collapse>

          <div className='m-5'>
            <Typography.Title level={5} className=''>Danh sách</Typography.Title>
            <Collapse defaultActiveKey={["0"]}>
              {renderPanelLesson}
            </Collapse>
          </div>
        </div>
      </Form>
    </Drawer>
  )
}
