import React, { useEffect, useState } from 'react'
import { Button, Radio, RadioChangeEvent, Typography } from "antd";
import 'react-quill/dist/quill.snow.css';
import './index.scss';
import { PlusOutlined } from '@ant-design/icons';
import { initialChooseVideoQuestionValue, ModeQuestion } from "../../../../constants";
import TextEditor from "../../../text-editor/TextEditor";
import { useAddLesson } from "../../../../stores/stores";
import { QuestionData, QuestionItemDetail, VideoAnswerInfo } from '../../../../types/course-construction';
import QuestionsVideo from "./QuestionsVideo";
import { SortableList } from '../../../SortableList';
import { convertObjectToArray, mapAnsBEtoFE } from '../../../../utils/arrays';
import { convertQuillToString } from '../../../../utils/convertQuillToString';
import { renderQuestionNameByType, renderQuestionDescriptionByType } from '../../../../utils/functionUltils';


let locale = {
  emptyText: "Không có dữ liệu",
};


function ChooseVideo({ data = {} as QuestionData }: QuestionItemDetail) {
  const question = useAddLesson((state: any) => state.question);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const modeQuestion = useAddLesson((state) => state.modeQuestion);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);

  const [questionVideoName, setQuestionVideoName] = useState<string>("");
  const [keyVideo, setKeyVideo] = useState(0)
  const [answers, setAnswers] = useState<VideoAnswerInfo[]>(convertObjectToArray(currentQuestionInfo, "questionName") || []);

  const resetAnswers = () => {
    const newAnswers = convertObjectToArray(currentQuestionInfo, "questionName");
    return newAnswers;
  }

  useEffect(() => {
    setQuestionVideoName(question.question);
    if (modeQuestion === ModeQuestion.UPDATE && data.answers && data.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(data.answers);
      setAnswers(feAnswer);
      const correctAns = feAnswer.find((quiz: any) => quiz.isTrue);
      if (correctAns) setKeyVideo(correctAns.id);
    } else {
      setAnswers([]);
      setKeyVideo(0);
    }
  }, [question, data.answers, modeQuestion]);


  const handleUpdateVideoAnswer = (data: VideoAnswerInfo, typeAction: number) => {
    let newList = resetAnswers();
    if (typeAction === 0) {
      newList = newList.filter((item: VideoAnswerInfo) => {
        return item.id !== data.id
      })

    } else {
      const indexOfItem = newList.findIndex((item: VideoAnswerInfo) => {
        return item.id === data.id
      });
      if (indexOfItem !== -1) {
        if (typeAction === 1) {
          newList[indexOfItem] = {
            ...newList[indexOfItem],
            filePath: data.filePath as string,
            fileName: data.fileName as string
          };

        }
        if (typeAction === 2) {
          newList[indexOfItem] = { ...newList[indexOfItem], value: data.value };
        }
        if (typeAction === 3) {
          newList[indexOfItem] = { ...newList[indexOfItem], isDisplayContent: !data.isDisplayContent };
        }
      }
    }
    setAnswers(newList)
  };

  useEffect(() => {
    setCurrentQuestionInfo({ ...answers, questionName: questionVideoName, });
  }, [answers, questionVideoName]);

  const handleChange = (e: RadioChangeEvent) => {
    const currentChangeRadioId = e.target.value;
    if (currentChangeRadioId) {
      let items = [...answers]
      items[currentChangeRadioId - 1] = { ...items[currentChangeRadioId - 1], isTrue: true }
      setAnswers(items)
      setKeyVideo(currentChangeRadioId);
    }
  };


  const handleChangeVideoQuestionName = (name: string) => {
    setQuestionVideoName(name);
  };

  const handeAddSection = () => {
    if (answers.length > 0) {
      const newItem = {
        id: answers.length + 1,
        isTrue: false,
        filePath: '',
        fileName: '',
        isDisplayContent: false,
        value: '',
        point: 0,
      }

      const newList = [...answers].concat([newItem]);
      setAnswers(newList);
    } else {
      const initNewItems = initialChooseVideoQuestionValue.map((item) => {
        return {
          ...item,
          fileName: '',
          filePath: '',
        };
      });
      setAnswers(initNewItems);
    }
  }

  const handleChangeRadio = (e: RadioChangeEvent) => {
    const currentChangeRadioId = e.target.value;

    if (currentChangeRadioId) {
      const distAnswer = resetAnswers();
      distAnswer.forEach((item) => (item.isTrue = false));

      distAnswer[currentChangeRadioId - 1] = {
        ...distAnswer[currentChangeRadioId - 1],
        isTrue: true,
      };
      setAnswers(distAnswer);
      setKeyVideo(currentChangeRadioId);
    }
  };



  const handleDragChange = (value: any) => {
    setAnswers(value);
  };
  return (
    <section className='question-wrapper'>
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <div className="text-editor">
        <TextEditor value={questionVideoName} onChange={handleChangeVideoQuestionName} />
      </div>
      <div className="tree-course-construct list-answers">
        <Radio.Group
          name="question-video"
          onChange={handleChangeRadio}
          value={keyVideo}
          className="w-full"
        >
          <SortableList
            items={answers}
            onChange={handleDragChange}
            renderItem={(record: any) => {
              return (
                <SortableList.Item id={record.id}>
                  <SortableList.DragHandle />
                  <QuestionsVideo
                    data={record}
                    valueRadio={record.id}
                    handleUpdateVideoAnswer={handleUpdateVideoAnswer} />
                </SortableList.Item>
              )
            }} />
        </Radio.Group>
        <Button className='btn-add-more mt-2' onClick={handeAddSection}>
          <PlusOutlined />
          <Typography.Text>
            Thêm lựa chọn
          </Typography.Text>
        </Button>
      </div>
    </section>
  )
}

export default ChooseVideo
