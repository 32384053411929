import {
  InfoCircleOutlined,
  PercentageOutlined,
  UpOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  TableColumnsType,
  TableProps,
  Typography,
  message
} from "antd";
import { useState } from "react";
import FormInputCommon from "../../../../components/form-input-common/FormInputCommon";
import CommonModal from "../../../../components/modal/common";
import TableData from "../../../../components/table-data/TableData";
import { updateRatingScore } from "../../../../service/auto-score";

const HeadRateScore = ({ rateHead, headValue, setRate, idRateScore,listScoreStudent, loading }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isShow, setIsShow] = useState<boolean>(true);


  const onCloseModal = () => {
    setIsOpen(false);
  };
  const handleSaveEvaluation = () => {
    if (rateHead?.rating >= 0) {
      setIsOpen(true);
    } else {
      messageApi.open({
        type: "error",
        content: "Đánh giá báo cáo phải lớn hơn hoặc bằng 0%",
      });
    }
  };
  const handleConfirmEvaluation = async () => {
    try {
      await updateRatingScore(idRateScore, {
        rating: Number(rateHead?.rating),
      });
      setIsOpen(false);
      messageApi.open({
        type: "success",
        content: "Đánh giá chất lượng bảng điểm thành công",
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Đánh giá chất lượng bảng điểm thất bại",
      });
    }
  };
  const handleChangeInput = (e: any) => {
    if (!/^\d*$/.test(e?.target?.value as string)) {
      return;
    }
    if (Number(e?.target?.value) >= 0 && Number(e?.target?.value) <= 100) {
      setRate({
        ...rateHead,
        rating: e?.target?.value,
      });
    }
  };
  const percent = (rateHead?.attendance / rateHead?.total) * 100;
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};
  const dataColumnsScoreStudent: TableColumnsType<any> = [
    {
      title: "Nhóm thang điểm",
      dataIndex: "scoreLevel",
      key: "scoreLevel",
      width: 20,
      render: (_: any, record) => {
        return <Typography.Text>{record?.scoreLevel}</Typography.Text>;
      },
    },
    {
      title: "Số lượng sinh viên",
      dataIndex: "record",
      key: "record",
      width: 10,
      render: (_: any, record) => {
        return <Typography.Text>{record?.student}</Typography.Text>;
      },
    },
    {
      title: "Tỷ lệ (%)",
      dataIndex: "rate",
      key: "rate",
      width: 20,
      render: (_: any, record) => {
        return <Typography.Text>{record?.rate}</Typography.Text>;
      },
    },
  ];
  return (
    <div>
      {contextHolder}
      <div className="px-3 pt-6 mx-3 flex flex-wrap">
        <div className="md:pr-4 w-full md:w-1/2">
          <Card className="h-full">
            <Flex className="w-full" wrap="wrap">
              {headValue?.map((dataReport: any, index: number) => (
                <div
                  className={`${
                    index + 1 === headValue.length ? "" : "sm:border-r"
                  } px-4  mb-2 xl:mb-0 xl:w-fit`}
                  key={index}
                >
                  <p className="w-full">{dataReport.label}</p>
                  <p className="font-medium text-lg">{dataReport?.value}</p>
                </div>
              ))}
            </Flex>
          </Card>
        </div>
        <div className="w-full md:w-1/2">
          <Card className="h-full">
            <Flex
              className="w-full"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Flex className="w-full xl:w-2/3" align="center">
                <Typography.Text>Đánh giá chất lượng báo cáo:</Typography.Text>
                <FormInputCommon
                  className="w-2/3 ml-2"
                  type="number"
                  placeholder="0"
                  value={rateHead?.rating}
                  max={100}
                  onChange={handleChangeInput}
                  addonAfter={<PercentageOutlined />}
                />
              </Flex>
              <Flex className="w-1/3">
                <Button
                  className="btn btn-primary ml-2"
                  onClick={handleSaveEvaluation}
                >
                  <Typography.Text>Lưu</Typography.Text>
                </Button>
              </Flex>
            </Flex>
          </Card>
        </div>
      </div>
      <div className="px-3 pt-6">
        <Card className="mx-3 table-list-score">
          <Flex justify="space-between" align="center" className="p-6 cursor-pointer"  onClick={() => setIsShow(!isShow)}>
            <Typography.Title level={5} className="!mb-0">
              Thống kê bảng điểm sinh viên
            </Typography.Title>
            <UpOutlined className={`${isShow ? "rotate-180" : ""} ease-in-out duration-500`}/>
          </Flex>
          <div className={`${isShow ? "h-fit" : "h-0"} overflow-hidden transition duration-500 ease-in-out`}>
          <TableData
              dataTable={listScoreStudent}
              dataColumns={dataColumnsScoreStudent}
              pageNumber={0}
              pageSize={0}
              pagination={false}
              loadingTable={loading}
              scroll={{ x: 1500, y: 600 }}
              handleTableChangeProps={handleTableChange}
            />
          </div>
        </Card>
      </div>
      <CommonModal
        open={isOpen}
        title={"Xác nhận Đánh giá chất lượng bảng điểm"}
        desc={
          <Typography.Text>
            Đảm bảo rằng tất cả các{" "}
            <span className="text-[#FAAD14]">
              thông tin liên quan đã được kiểm tra và cập nhật đầy đủ
            </span>{" "}
            trước khi xác nhận lưu thông tin này
          </Typography.Text>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmEvaluation}
        closeModal={onCloseModal}
      />
    </div>
  );
};

export default HeadRateScore;
