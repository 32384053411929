import { Badge, Col, Form, Modal, Row, TableColumnsType } from 'antd'
import { useEffect, useState } from 'react'
import { ConstructCourseDetailType, DataType } from "../../../src/types/course-construction"
import { getListLecturerByCourse } from '../../service/course-construct'
import { AssignedSponsor } from '../../types/assign-course-construction'
import FormItemDropdown from '../form-dropdown/FormItemDropdown'

type Props = {
  open: boolean
  clearForm: boolean
  title: string
  handleOk: () => void
  handleCancel: () => void
  browseData?: DataType | ConstructCourseDetailType
}

const validations = {
  lecturerName: {
    required: true,
    message: "Vui lòng chọn cán bộ duyệt khoá học",
  },
}

const dataColumn: TableColumnsType<DataType> = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    width: 3,
    render: (_: any, record, index) => {
      return (
        <div>
          {index + 1}
        </div>
      )
    },
  },
  {
    title: 'Tên khoá học',
    dataIndex: 'name',
    key: 'name',
    width: 10
  },
  {
    title: 'Trạng thái',
    dataIndex: 'dateConfirm',
    key: 'dateConfirm',
    width: 10,
    render: (_: any, record) => {
      return (
        <div>
          <Badge key={"gray"} color={"gray"} className='mr-5' />
          Chờ phân công
        </div>
      )
    },
  },
]


export default function BrowseCourseModal(props: Props) {
  const { open, title, handleOk, handleCancel, clearForm, browseData } = props
  const [form] = Form.useForm()
  const [listLecturers, setListLecturers] = useState<AssignedSponsor[]>([])

  const onOk = () => {
    form.submit()
  }

  const getDataLecturer = async () => {
    await getListLecturerByCourse(browseData?.id ?? 1).then((res) => {
      setListLecturers(res.data.data)
    })
  }

  useEffect(() => {
    if (clearForm) {
      form.resetFields()
    }
  }, [clearForm])

  useEffect(() => {
    getDataLecturer()
  }, [browseData?.id])


  return (
    <Modal title={title} open={open} onOk={onOk} onCancel={handleCancel} centered okText="Lưu" cancelText="Huỷ">
      <Form layout={"vertical"} form={form} name="create-browse-course-modal">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="lecturerName" label="Khoá học của bạn sẽ được gửi tới Quản trị duyệt khoá học" rules={[validations.lecturerName]} hasFeedback>
              <FormItemDropdown  options={listLecturers} allowClear={true}
                placeholder="Nhập tên cán bộ duyệt khoá học"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
