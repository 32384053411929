import React from "react";
import { Radio } from "antd";

function Dropdown({ data }: any) {
  return (
    <div className="choose-correct">
      <div className="flex flex-column gap-12">
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: number) => (
            <Radio key={quiz?.uuid + index} checked={quiz?.isTrue === true}>
              <span
                className={`${
                  quiz?.isTrue && "text-result"
                } font-size-16 line-height-24 font-weight-5`}
              >
                {quiz?.content || "Không có nội dung"}
              </span>
            </Radio>
          ))}
      </div>
    </div>
  );
}

export default Dropdown;
