// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rate-chart .ant-card-body {
  width: 100%; }

.table-list-score .ant-card-body {
  padding: 0; }
  .table-list-score .ant-card-body .table-wrapper {
    padding: 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/automatic-mark-detail/management-table-scores/rate-auto-scores/score-evalution.scss"],"names":[],"mappings":"AAAA;EAEQ,WAAW,EAAA;;AAGnB;EAEQ,UAAU,EAAA;EAFlB;IAIY,UAAU,EAAA","sourcesContent":[".rate-chart {\n    .ant-card-body{\n        width: 100%;\n    }\n}\n.table-list-score {\n    .ant-card-body{\n        padding: 0;\n        .table-wrapper {\n            padding: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
