import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Space,
  Switch,
  Button,
  Dropdown,
  Typography,
  Select,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  FundViewOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import toolbarOptions from "./toolbarOptions";
import {
  ListAnswer,
  ListQuestion,
} from "../../../../../../types/survey-managment/survey";
import { optionsDropDownQuestionType } from "./constant/data";
import QuestionView from "./question-view/QuestionView";
import { MenuProps } from "antd/lib";

const modules = {
  toolbar: toolbarOptions,
};

interface QuestionCardProps {
  index: number;
  sectionQuestion: ListQuestion;
  showDetailSection: boolean;
  onChangeTypeQuestion: (value: string) => void;
  onDeleteQuestion: (index: number) => void;
  onCopyQuestion: (index: number) => void;
  onMoveUp: (index: number) => void;
  onMoveDown: (index: number) => void;
  updateNewSectionQuestion: (data: ListQuestion) => void;
}

const QuestionCardFilter: React.FC<QuestionCardProps> = ({
  index,
  sectionQuestion,
  showDetailSection,
  onChangeTypeQuestion,
  onDeleteQuestion,
  onCopyQuestion,
  onMoveUp,
  onMoveDown,
  updateNewSectionQuestion,
}) => {
  const {
    questionName,
    questionDescription,
    questionType,
    orderNumber,
    isRequired,
    moocSurveyQuestionAnswer,
  } = sectionQuestion;

  const [isRequiredQuestion, setIsRequiredQuestion] = useState<boolean>(
    isRequired || false
  );
  const handleSwitchChange = (checked: boolean) => {
    setIsRequiredQuestion(checked);
    updateNewSectionQuestion({ ...sectionQuestion, isRequired: checked });
  };
  const [valueQuillContainer, setValueQuillContainer] = useState("");
  const [isOpenAddDescription, setIsOpenAddDescription] = useState(false);

  // useEffect(() => { }, [sectionQuestion]);

  const handleOpenAddDescription = () => {
    setIsOpenAddDescription(!isOpenAddDescription);
  };

  const optionsMoreQuestion: MenuProps["items"] = [
    {
      key: "1",
      label:
        valueQuillContainer === ""
          ? isOpenAddDescription
            ? "Ẩn mô tả"
            : "Thêm mô tả"
          : isOpenAddDescription
          ? "Ẩn mô tả"
          : "Hiển thị mô tả",
      icon: <FundViewOutlined onClick={handleOpenAddDescription} />,
    },
    {
      key: "2",
      label: "Di chuyển lên trên",
      icon: <ArrowUpOutlined />,
    },
    {
      key: "3",
      label: "Di chuyển xuống dưới",
      icon: <ArrowDownOutlined />,
    },
  ];

  const [optionDropDownMore, setIsOption] = useState(optionsMoreQuestion);

  const handleOptionDropDownMore = (index: number) => {
    let newOptions = [...optionsMoreQuestion];
    if (index === 0) {
      newOptions.splice(1, 1);
    }
    if (index === index - 1) {
      newOptions.splice(2, 1);
    }
    setIsOption(newOptions);
  };

  // const answerData = useMemo(
  //   () => moocSurveyQuestionAnswer,
  //   [sectionQuestion]
  // );

  // useEffect(() => { }, [moocSurveyQuestionAnswer]);
  // const newAnswerData = useMemo(() => {
  //   return answerData;
  // }, [answerData]);

  const handleUpdateAnswerData = (newAnswerData: ListAnswer[]) => {
    console.log(newAnswerData);
    updateNewSectionQuestion({
      ...sectionQuestion,
      moocSurveyQuestionAnswer: newAnswerData,
    });
  };

  const handleUpdateQuestionType = (typeNumber: number) => {
    updateNewSectionQuestion({ ...sectionQuestion, questionType: typeNumber });
  };

  console.log(`CHILDSectionQuestion::`, sectionQuestion);
  return (
    // <div>
    //   <h1>{sectionQuestion.questionName}</h1>
    //   <h1>{sectionQuestion.isRequired ? 'bat buong' : 'khong bat buoc'}</h1>
    //   <Space direction="vertical" size="small" style={{ display: "flex" }}>
    //     <ReactQuill
    //       modules={modules}
    //       theme="snow"
    //       placeholder="Thêm mô tả tại đây ... "
    //       onChange={setValueQuillContainer}
    //       style={
    //         !isOpenAddDescription
    //           ? { display: "none" }
    //           : {
    //             display: "flex",
    //             flexDirection: "column-reverse",
    //             alignItems: "flex-end",
    //           }
    //       }
    //     />
    //   </Space>
    //   <QuestionView
    //     typeQuestionId={Number(sectionQuestion.questionType)}
    //     listAnswer={sectionQuestion.moocSurveyQuestionAnswer}
    //     handleUpdateAnswerData={handleUpdateAnswerData}
    //   // handleUpdateQuestionType={handleUpdateQuestionType}
    //   />

    // </div>
    <Card
      title={
        !showDetailSection ? (
          <Typography>{`Câu hỏi ${index + 1}`}</Typography>
        ) : (
          <TextArea
            className="input-header-title"
            defaultValue={sectionQuestion.questionName}
            value={sectionQuestion.questionName}
            style={{
              height: "auto",
              width: 450,
              marginTop: 15,
              fontSize: 20,
              fontWeight: "lighter",
              color: "black",
              wordWrap: "break-word",
              resize: "none",
            }}
          />
          // <h1>{sectionQuestion.questionName}</h1>
        )
      }
      styles={{
        header: { border: "none" },
        body: { display: showDetailSection ? "block" : "none" },
        extra: { display: showDetailSection ? "block" : "none" },
      }}
      className="wrapper-settings--card form-setting--common"
      extra={
        <>
          <Select
            value={`${
              optionsDropDownQuestionType.find(
                (opt) => opt.type === sectionQuestion.questionType
              )?.value
            }`}
            onChange={(value: string) => {
              onChangeTypeQuestion(value);
              const foundType = optionsDropDownQuestionType.find(
                (item) => item.value === value
              );
              handleUpdateQuestionType(foundType?.type || 1);
            }}
            options={optionsDropDownQuestionType}
          />
        </>
      }
    >
      {/* <Space direction="vertical" size="small" style={{ display: "flex" }}>
        <ReactQuill
          modules={modules}
          theme="snow"
          placeholder="Thêm mô tả tại đây ... "
          onChange={setValueQuillContainer}
          style={
            !isOpenAddDescription
              ? { display: "none" }
              : {
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "flex-end",
              }
          }
        />
      </Space> */}

      {/* Hiển thị nội dung câu hỏi */}
      <QuestionView
        typeQuestionId={Number(sectionQuestion.questionType)}
        listAnswer={moocSurveyQuestionAnswer}
        handleUpdateAnswerData={handleUpdateAnswerData}
        // handleUpdateQuestionType={handleUpdateQuestionType}
      />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Space size="large">
          <Space size="small">
            <span>Bắt Buộc </span>
            <Switch
              checked={sectionQuestion.isRequired}
              defaultChecked={sectionQuestion.isRequired}
              size="small"
              onChange={handleSwitchChange}
            />
          </Space>
          {/* <Space size="small">
            <Button
              icon={<CopyOutlined />}
              onClick={() => onCopyQuestion(index)}
            />
            <Button
              icon={<DeleteOutlined />}
              onClick={() => onDeleteQuestion(index)}
            />
            <Dropdown
              onOpenChange={() => handleOptionDropDownMore(index)}
              menu={{
                items: optionDropDownMore,
                onClick: ({ key }) => {
                  if (key === "1") {
                    handleOpenAddDescription();
                  }
                  if (key === "2") {
                    onMoveUp(index);
                  }
                  if (key === "3") {
                    onMoveDown(index);
                  }
                },
              }}
              placement="bottomRight"
            >
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </Space> */}
        </Space>
      </div>
    </Card>
  );
};

export default QuestionCardFilter;
