import { FunctionComponent, useEffect, useState } from "react";
import { Layout, Tabs, TabsProps } from "antd";
import PageHeader from "../../../../components/page-header/PageHeader";
import { useParams } from "react-router-dom";
import { message } from "antd/lib";
import dayjs from "dayjs";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Tag,
  Tooltip,
  Badge,
  Typography,
} from "antd";
import CustomCard from "../../../../components/custom-card/CustomCard";
import { useAppStore } from "../../../../stores/stores";
import { get } from "lodash";

import { getAccountDetail } from "../../../../service/list-account-users/listAccountUsersApi";

import { DetailContent } from "./components/detail-content/DetailContent";
import { getMoocApprovalConfigurationById } from "../../../../service/course-plan-managment/mooc-approval-configuration/mooc-approval-configuration-api";
import { DetailTypeMoocApprovalConfiguration } from "../../../../types/course-plan-managment/mooc-approval-configuration";

const { Content } = Layout;
const imgPath = "/images/";

const DetailApprovalConfiguration: FunctionComponent = () => {
  const { id } = useParams();

  const [detailData, setDetailData] = useState<any>({}); //DetailTypeMoocApprovalConfiguration
  const { accountInfo, setAccountDetail } = useAppStore((state) => {
    return {
      accountInfo: state?.accountInfo,
      setAccountDetail: state?.setAccountDetail,
    };
  });

  const params = useParams();
  const returnTypeApproval = (type: number) => {
    switch (type) {
      case 1:
        return "Khảo sát";
      case 2:
        return "Đăng ký kế hoạch xây dựng khóa học";
      case 3:
        return "Kế hoạch xây dựng khóa học";
      default:
        return "";
    }
  };
  const getDetailMoocApprovalConfigurationData = async (id: any) => {
    try {
      await getMoocApprovalConfigurationById(id)
        .then((res) => {
          const detailData = res.data.data;

          setDetailData({
            id: detailData?.id || "",
            approvalLevelName: detailData?.approvalLevelName || "",
            approvalType: detailData?.approvalType || "",
            isOrganization: detailData?.isOrganization || "",
            hierarchyId: detailData?.hierarchyId || "",
            individualGroupId: detailData?.individualGroupId || "",
            organizationalGroupId: detailData?.organizationalGroupId || "",
            createdDate: detailData?.createdDate || "",
            updatedDate: detailData?.updatedDate || "",
            nameAuth: detailData?.nameAuth || "",
            moocApprovalConfiguration:
              detailData?.moocApprovalConfiguration || "",
            otherMoocApprovalConfiguration:
              detailData?.otherMoocApprovalConfiguration || "",
            nameOrganization: detailData?.nameOrganization || "",
            nameLevelApproval: detailData?.nameLevelApproval || "",
          });
        })
        .catch((err: any) => {
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }
  };

  useEffect(() => {
    getDetailMoocApprovalConfigurationData(id);
  }, [id]);

  const renderSwitch = (value: string) => {
    switch (value) {
      case "approvalLevelName":
        return <div>Tên cấu hình</div>;
      case "approvalType":
        return <div>Loại cấu hình</div>;
      case "nameLevelApproval":
        return <div> Cấu hình phê duyệt trước đó</div>;
      case "nameAuth":
        return <div>Nhóm cán bộ phê duyệt</div>;
      case "nameOrganization":
        return <div>Nhóm tổ chức phê duyệt</div>;
      default:
        return;
    }
  };
  const [listInfo, setListInfo] = useState<any[]>();
  useEffect(() => {
    const dataFormat = {
      approvalLevelName: detailData.approvalLevelName,
      approvalType: returnTypeApproval(Number(detailData.approvalType)),
      nameLevelApproval: detailData.nameLevelApproval,
      nameAuth: detailData.nameAuth,
      nameOrganization: detailData.nameOrganization,
    };
    const convertObjectData = Object.entries(dataFormat);
    const listInfo = convertObjectData.map((item: any, index) => (
      <div className="list-item" key={index}>
        <Typography.Title
          level={5}
          style={{ width: 200 }}
          className="list-item--title"
        >
          {renderSwitch(item[0])}
        </Typography.Title>
        <div className="list-item--description">
          <Typography.Text className="content">
            {item[0] === "status" ? (
              <Badge
                key={!detailData.isAssigned ? "lightgray" : "green"}
                color={!detailData.isAssigned ? "lightgray" : "green"}
              />
            ) : null}{" "}
            {item[1]}
          </Typography.Text>
        </div>
      </div>
    ));
    setListInfo(listInfo);
  }, [detailData]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Cấu hình phê duyệt",
      children: (
        <DetailContent>
          <CustomCard
            title={
              <div className="card-heading--info">
                <Typography.Title level={5}>
                  Cấu hình phê duyệt
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Thông tin chi tiết về Cấu hình phê duyệt
                </Typography.Text>
              </div>
            }
            className="card-info--wrapper"
          >
            <div className="list-wrapper">{listInfo}</div>
          </CustomCard>
        </DetailContent>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: <span>Cấu hình</span>,
    },
    {
      title: (
        <a href={`/configuration/approval-configuration`}>
          Cấu hình cấp phê duyệt
        </a>
      ),
    },
    {
      title: <span>{detailData?.approvalLevelName}</span>,
    },
  ];

  return (
    <>
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={detailData?.approvalLevelName}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <Tabs defaultActiveKey="1" items={items} />
        </div>
      </Content>
    </>
  );
};

export default DetailApprovalConfiguration;
