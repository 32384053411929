// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-respond-section-detail .input-header-title::placeholder {
  color: black; }

.survey-managment-respond-section-detail .notPadding div.ant-card-body {
  padding: 0px !important; }

.survey-managment-respond-section-detail .surveyContainer-row {
  padding: 40px 24px; }

.survey-managment-respond-section-detail .surveyTitle {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #000000D9; }

.survey-managment-respond-section-detail .surveyDiscription {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #00000073; }

.survey-managment-respond-section-detail .surveyQuestion {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000D9; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-respond/component/detail-section/detail.scss"],"names":[],"mappings":"AAAA;EAEI,YACF,EAAA;;AAHF;EAMI,uBAAuB,EAAA;;AAN3B;EAUI,kBAAkB,EAAA;;AAVtB;EAcI,mBAAmB;EACnB,eAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAiB;EACjB,gBAAsB,EAAA;;AAnB1B;EAwBI,mBAAmB;EACnB,eAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAiB;EACjB,gBAAsB,EAAA;;AA7B1B;EAkCI,mBAAmB;EACnB,eAAiB;EACjB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAiB;EACjB,gBAAsB,EAAA","sourcesContent":[".survey-managment-respond-section-detail {\n  .input-header-title::placeholder {\n    color: black\n  }\n\n  .notPadding div.ant-card-body {\n    padding: 0px !important;\n  }\n\n  .surveyContainer-row {\n    padding: 40px 24px;\n  }\n\n  .surveyTitle {\n    font-family: Roboto;\n    font-size  : 20px;\n    font-weight: 500;\n    line-height: 28px;\n    text-align : left;\n    color      : #000000D9;\n\n  }\n\n  .surveyDiscription {\n    font-family: Roboto;\n    font-size  : 14px;\n    font-weight: 400;\n    line-height: 22px;\n    text-align : left;\n    color      : #00000073;\n  }\n\n  .surveyQuestion {\n    //styleName: Body/regular;\n    font-family: Roboto;\n    font-size  : 14px;\n    font-weight: 400;\n    line-height: 22px;\n    text-align : left;\n    color      : #000000D9;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
