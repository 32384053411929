export const convertQuillToString = (text: any) => {

  const htmlString = text

  // Create a new div element
  const div = document.createElement("div");

  // Set the innerHTML of the div to the HTML string
  div.innerHTML = htmlString;

  // Use textContent to get the text without any HTML tags
  const textWithoutTags = div.textContent || div.innerText;

  return textWithoutTags;
};

export const detachedTitle = (text: any) => {
  const idAndTitle = text.split('/');
  return idAndTitle[1];
}

export const detachedId = (text: any) => {
  const idAndTitle = text.split('/');
  return idAndTitle[1];
}
