import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";
import { axiosConfigV2OpenAPI } from "../../config/api/configOpenApiV2";

export const getListCertificate: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-certificate-template/search`, data);
};

export const getDetailCertificate: (id: any) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-certificate-template/${id}`);
};

export const putUpdateCertificateTemplate: (
  data: any,
  id: any
) => Promise<AxiosResponse<any>> = (data, id) => {
  return axiosConfigV2.put(`/mooc-certificate-template/${id}`, data);
};

export const postSaveCertificateTemplateComponents: (
  data: any,
  id: any
) => Promise<AxiosResponse<any>> = (data, id) => {
  return axiosConfigV2.post(
    `/mooc-certificate-template/save-component/${id}`,
    data
  );
};

export const postTemplateByUserCertificate: (
  id: any,
  data: any
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.post(`/mooc-certificate-template/get-component-by-user/${id}`, data);
};

export const postCopyCertificateTemplate: (
  id: any,
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.post(`mooc-certificate-template/copy/${id}`);
};

export const deleteCertificateTemplate: (
  id: any,
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`mooc-certificate-template/${id}`);
};

export const postSaveTemplateList: (
  id: any,
  data: any
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfigV2.put(`/mooc-certificate-template/save-sample-template/${id}`, data);
};
