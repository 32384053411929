import { commonMedia } from "./grapesjs-config";
export const nameCategoryBlocks = "Khối giao diện";
export const nameCategoryTemplates = "Mẫu hoàn chỉnh";
export const nameContactBlock = "Khối liên hệ";
export const nameLinkedCourseBlock = "Khối khóa học mẫu";
export const nameLecturersBlock = "Khối giảng viên";
export const nameBasicBlock = "Giao diện cơ bản";
export const nameCountBlocks = "Khối thống kê mẫu";
export const nameRoadMapBlocks = "Khối lộ trình khóa học";
export const nameHeaderBlocks = "Giới thiệu chung";
export const nameRegisterBlocks = "Khối ghi danh";
export const nameConditionBlocks = "Khối điều kiện";
export const nameAffiliatedBlocks = "Khối liên kết";

export const generalHeaderIntroduction = (data?: any) => {
  const blockUI = {
    id: "header-block-id",
    label: "Phần đầu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Hero Section with Background
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <section id="izqjjv" class="text-gray-600 body-font">
    <header id="i1z3t" class="text-gray-600 body-font bg-red-700">
      <div id="ibtwy" class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
      <div component-class="LinkDetailSchool" class="flex">
      <img id="i2fag" src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T08%3A14%3A49.301501154Z/f065a648-df6c-4f8e-8741-df5559902bc4images.png" class="w-8"/>
      <a id="ix94a" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"><span id="itksl" class="ml-3 text-xl text-white">Đại học Bách khoa Hà Nội</span></a>
      </div>
        <nav id="ie4ll" class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400-flex flex-wrap items-center text-base justify-center flex">
          <a id="i067i" class="text-white hover:bg-red-100 hover:text-red-700 p-4" component-class="ExploreSchool">Khám phá</a>
          <a id="i722x" class="text-white hover:bg-red-100 hover:text-red-700 p-4" component-class="BlogHeaderLink" Uuid="${data}">Blog</a>
        </nav>
        <div component-class="HeaderLoginDiv">
        <button id="i37d7-2" class="px-4 py-2 rounded mx-2 bg-white text-red-700 hover:bg-red-100">Đăng nhập</button>
        <button id="ipphw-2" class="border px-4 py-2 rounded hover:bg-red-100 bg-red-700 border-white text-white hover:text-red-700">Đăng ký</button>
        </div>
      </div>
    </header>
  </section>
</body>
  `;

  return blockUI;
};

export const generalContentIntroduction = (data?: any) => {
  const blockUI = {
    id: "content-block-id",
    label: "Phần nội dung",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Hero Section with Background
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <section id="izqjjv" class="text-gray-600 body-font">
    <header id="i1z3t" class="text-gray-600 body-font bg-red-700">
    </header>
    <div id="ii6huh" class="container mx-auto flex px-5 flex-col items-center py-32 hero-bg">
      <div class="flex">
      <div id="ih954f" class="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center p-8 rounded">
        <h1 id="i2x7jy" class="title-font sm:text-4xl text-3xl font-medium mb-16">Nền tảng cung cấp khoá học mở đại chúng
          <br/>cung cấp bởi Đại học Bách Khoa Hà Nội
        </h1>
        <p id="ig3by3" class="mb-8 leading-relaxed text-gray-700">Mooc là hệ thống cung cấp các khoá học được phát triển hợp tác cùng các trường đại học lớn trên toàn quốc.
          <br/>Đại học Bách Khoa Hà Nội là trường đi đầu trong lĩnh vực giáo dục trực tuyến với hơn 200 khoá học cho sinh viên trên cả nước. Sinh viên sẽ được chứng nhận và cấp chứng chỉ bởi Đại học Bách Khoa Hà Nội.
        </p>
        <form id="ife8nm">
          <div id="i32hjp" class="flex w-full md:justify-start justify-center items-end">
            <div class="relative mr-4 md:w-full lg:w-full xl:w-1/2 w-2/4">
              <label for="hero-field" id="ivwxdf" class="leading-7 text-sm">Nhập từ khoá bạn muốn tìm kiếm</label>
              <input type="text" id="hero-field" name="hero-field" required class="w-full bg-gray-100 rounded border bg-opacity-50 border-gray-300 focus:ring-2 focus:ring-indigo-200 focus:bg-transparent focus:border-indigo-500 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
            </div>
            <button type="submit" id="iyhrge" class="inline-flex text-white border-0 py-2 px-6 focus:outline-none hover:bg-red-600 rounded text-lg bg-red-700">Tìm kiếm</button>
          </div>
        </form>
        <p id="iz1wvh" class="text-sm mt-2 mb-8 w-full text-gray-600">Xem thêm Chính sách quyền riêng tư.
        </p>
      </div>
      <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        <img alt="hero" src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T09%3A18%3A27.119639196Z/3a80b404-c39d-4b20-98f6-9a77c99ee3ab360_F_457982937_r91A5qcFo2rUxnodlS2kRO20xkxV4cD7.jpg" id="i6rsrk" class="object-cover object-center rounded w-240"/>
      </div>
      </div>
      <div id="izqjjz" component-class="ReviewSchool" class="container w-full">
        </div>
    </div>
  </section>
</body>
  `;

  return blockUI;
};

export const generalImageIntroduction = (data?: any) => {
  const blockUI = {
    id: "intro-img-block-id",
    label: "Phần ảnh giới thiệu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
    <header id="ikzvv" class="text-gray-600 body-font shadow-md">
      12312312312312313131231212312
    </header>
  `;

  return blockUI;
};

export const generalFooterIntroduction = (data?: any) => {
  const blockUI = {
    id: "footer-block-id",
    label: "Phần cuối",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Hero Section with Background
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <footer id="i5d9ox" class="text-gray-600 body-font bg-gray-100">
    <div id="iy8na6" class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T08%3A14%3A49.301501154Z/f065a648-df6c-4f8e-8741-df5559902bc4images.png" id="i4mk8z" class="w-8"/>
      <div id="ijhjhp" class="w-90 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
        <span id="inz6nz" class="text-xl ml-8">Đại học Bách Khoa Hà Nội</span>
        <p id="ihqko5" class="text-sm text-gray-500 ml-8 mt-8">Bản quyền thuộc về Đại học Bách khoa Hà Nội
          <br/>Địa chỉ: Số 1 Đại Cồ Việt, Hai Bà Trưng, Hà Nội
          <br/>Điện thoại: 024 3869 4242
        </p>
      </div>
      <div id="iozwhq" class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
        <div id="im9se" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
          </nav>
        </div>
        <div id="iw0yh" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="i18zz" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Giới thiệu</a>
            </li>
            <li id="iu5b6" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Khám phá</a>
            </li>
            <li id="ivzynq" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Blog</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="ik4k7l" class="mb-4">
              <a id="iuw93t" class="text-gray-600 hover:text-gray-800">Chính sách</a>
            </li>
            <li id="iw2yl9" class="mb-4">
              <a id="ihhfkr" class="text-gray-600 hover:text-gray-800">Quyền riêng tư</a>
            </li>
            <li id="ipzazs" class="mb-4">
              <a id="i2jcjh" class="text-gray-600 hover:text-gray-800">Liên hệ</a>
            </li>
            <li id="iuvvzm">
            </li>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</body>`;

  return blockUI;
};
