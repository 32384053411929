import React, {ChangeEvent, FunctionComponent, KeyboardEvent} from "react";
import {Checkbox, Col, MenuProps, Row, TableColumnType,} from "antd";

import "./filter-table.scss";
import {SearchOutlined,} from "@ant-design/icons";
import {CheckboxOptionType} from "antd/lib";
import FormInput from "../../../../../components/form-input/FormInput";

type FilterTableProps = {
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
  checkedList: Array<string>;
  setCheckedList: (value: string[]) => void;
  dataColumns: TableColumnType<any>[];
};

const FilterDataCourseBrowse: FunctionComponent<FilterTableProps> = ({
                                                                       searchValue,
                                                                       handleChangeInput,
                                                                       handleSearch,
                                                                       handleSubmitSearch,
                                                                       handleOpenFilter,
                                                                       dataColumns,
                                                                       checkedList,
                                                                       setCheckedList,
                                                                     }) => {
  const options = dataColumns.map(({key, title}) => ({
    label: title,
    value: key,
  }));

  const items: MenuProps["items"] = [
    {
      label: (
        <Checkbox.Group
          options={options as CheckboxOptionType[]}
          value={checkedList}
          onChange={(value) => {
            setCheckedList(value as string[]);
          }}
        />
      ),
      key: "radio",
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <>
      <div className="heading-extra">
        <Row gutter={8} className="row-filter">
          <Col
            md={{span: 15}}
            lg={{span: 15}}
            xl={{span: 15}}
            xs={{span: 15}}
          >
            <div className="heading-search">
              <FormInput
                placeholder="Nhập từ khoá cần tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch}/>
                }
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterDataCourseBrowse;
