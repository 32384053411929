import {
  Card,
  Col,
  Form,
  Layout,
  Row,
  TableColumnsType,
  Typography,
} from "antd";
import TableData from "../../../components/table-data/TableData";
import { useEffect, useMemo, useState } from "react";
import { DataTypeMoocApproval } from "../../../types/course-plan-managment/mooc-approval";
import { TableProps } from "antd/lib";
import PageHeader from "../../../components/page-header/PageHeader";
import { getMoocApprovedHistoryById } from "../../../service/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-registration-api";
import { useParams } from "react-router-dom";
import IsLoading from "../mooc-course-construction-plan/detail-course-plan/component/is-loading/IsLoading";

const MoocApprovalCourseHistory = ({ }) => {
  const { id } = useParams();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [searchValue, setSearchValue] = useState("");
  const [listMoocApprovalHistory, setListMoocApprovalHistory] = useState<any[]>(
    []
  );

  const dataColumns: TableColumnsType<DataTypeMoocApproval> = [
    {
      title: "STT",
      width: 1,
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2, "0");
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên cán bộ",
      width: 2,
      render: (_: any, record, index: number) => {
        return <span className="table-order-header"></span>;
      },
    },
    {
      title: "Thời gian",
      width: 2,
    },
    {
      title: "Trạng thái",
      width: 2,
    },
    {
      title: "Mô tả",
      width: 2,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const newColumns = useMemo(() => {
    return dataColumns.map((item, index: number) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: "danh sách phê duyệt đăng ký kế hoạch",
    },
  ];

  const getMoocApprovalCourseHistory = async (id: any) => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };
    await getMoocApprovedHistoryById(Number(id), data)
      .then((res) => {
        const { data } = res.data;

        setListMoocApprovalHistory(data.data);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const HeaderInfo = () => {
    return (
      <Card>
        <Row>
          <Col span={5}>
            <Row>
              <Typography.Text>Tên đăng ký</Typography.Text>
            </Row>
            <Row>
              {/* {loading === true ? <IsLoading/> : <p>{listMoocApprovalHistory[0].courseConstructionPlanName}</p>} */}
            </Row>
          </Col>

          <Col span={5}>
            <Row>
              <Typography.Text>Trường chủ trì</Typography.Text>
            </Row>
            <Row>
              <p>Trường chủ trì</p>
            </Row>
          </Col>

          <Col span={5}>
            <Row>
              <Typography.Text>Trường phối hợp</Typography.Text>
            </Row>
            <Row>
              <p>Trường phối hợp</p>
            </Row>
          </Col>

          <Col span={5}>
            <Row>
              <Typography.Text>Cán bộ phê duyệt</Typography.Text>
            </Row>
            <Row>
              <p>Cán bộ phê duyệt</p>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  };

  useEffect(() => {
    getMoocApprovalCourseHistory(id);
  }, [id]);

  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider
        onFormFinish={(formName, { values, forms }) => {
          if (formName === "create-edit-mooc-approval-detail") { }
        }}
      >
        <div className={`page-header-group--layout`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader
                title={"lịch sử phê duyệt"}
                listBreadcrumb={listBreadcrumb}
                breadcrumb={true}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            paddingTop: "24px",
            paddingLeft: "24px",
            paddingRight: "24px",
          }}
        >
          {/* <Card >
                <Row>
                    <Col span={5}>
                        <Row>
                            <Typography.Text>
                                Tên đăng ký
                            </Typography.Text>
                        </Row>
                        <Row>
                            {loading === true ? <IsLoading/> : <p>{listMoocApprovalHistory[0].courseConstructionPlanName}</p>}
                        </Row>
                    </Col>

                    <Col span={5}>
                        <Row>
                            <Typography.Text>
                                Trường chủ trì
                            </Typography.Text>
                        </Row>
                        <Row>
                            <p>Trường chủ trì</p>
                        </Row>
                    </Col>

                    <Col span={5}>
                        <Row>
                            <Typography.Text>
                                Trường phối hợp
                            </Typography.Text>
                        </Row>
                        <Row>
                            <p>Trường phối hợp</p>
                        </Row>
                    </Col>

                    <Col span={5}>
                        <Row>
                            <Typography.Text>
                                Cán bộ phê duyệt
                            </Typography.Text>
                        </Row>
                        <Row>
                            <p>Cán bộ phê duyệt</p>
                        </Row>
                    </Col>
                </Row>
            </Card> */}
          {HeaderInfo()}
        </div>

        <TableData
          dataTable={listMoocApprovalHistory}
          dataColumns={newColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          loadingTable={loading}
          totalRecords={totalRecords}
          handleTableChangeProps={handleTableChange}
          pagination={true}
        />
      </Form.Provider>
    </Layout>
  );
};

export default MoocApprovalCourseHistory;
