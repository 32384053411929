import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from 'react';
import { Button, Checkbox, Col, Dropdown, MenuProps, message, Row, TableColumnType, Typography } from 'antd';

import FormInput from "../../../../components/form-input/FormInput";
import './filter-table.scss'
import { DownloadOutlined, DownOutlined, PlusOutlined, SearchOutlined, SettingOutlined } from "@ant-design/icons";
import { DataType } from "../../../../types/listUsers";
import { CheckboxOptionType } from "antd/lib";
import ExportAccountModal from '../../../../components/modal/export-account';
import { isEmpty } from 'lodash';
import { exportExcelData } from '../../../../service/list-account-users/listAccountUsersApi';

type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  checkedList: Array<string>,
  setCheckedList: (value: string[]) => void,
  dataColumns: TableColumnType<DataType>[]
}


const FilterTable: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList
}) => {

  const [openModal, setOpenModal] = useState<boolean>(false)

  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key
  }))

  const [filterData, setFilterData] = useState<any>({
    approvedDate: null,
  });

  const items: MenuProps['items'] = [
    {
      label: (
        <Checkbox.Group options={options as CheckboxOptionType[]} value={checkedList} onChange={(value) => {
          setCheckedList(value as string[])
        }} />

      ),
      key: 'radio',
    }
  ]

  const menuProps = {
    items
  }

  const handleOk = () => {
    message.success("Kết xuất thành công")
    setOpenModal(false)
  }

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const exportExcel = async (value: any) => {
    const data = {
      page: 1,
      size: 10000,
      createdFromDate: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      createdToDate: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      status: value.status === 1 ? false : true,
    };
    await exportExcelData(data).then((res) => {
      if(res.status === 200) {
        const fileData = res.data;

        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = "Kết xuất tài khoản Quản trị cơ sở.xlsx";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!")
      }
    })
  };

  return (
    <>
      <div className="heading-extra">
        <Row gutter={8}>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>
                  Bộ lọc
                </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }} xs={{ span: 9 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập tên, email để tìm kiếm" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          <Col md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 7 }}>

            <div className="heading-resigter">
              <Button onClick={handleOpen} className="btn btn-primary">
                <PlusOutlined />
                <Typography.Text>Tạo tài khoản</Typography.Text>
              </Button>
            </div>
          </Col>
          <Col md={{ span: 3 }} lg={{ span: 3 }} xl={{ span: 3 }} xs={{ span: 3 }}>

            <div className="heading-resigter">
              <Button className="btn" onClick={() => setOpenModal(true)}>
                <DownloadOutlined />
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <ExportAccountModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
        title="Kết xuất tài khoản Quản trị cơ sở" 
        handleOk={handleOk}
        filterData={filterData}
        setFilterData={handleChangeFilter}
        exportExcel={exportExcel}
      />
    </>
  );
};

export default FilterTable;
