import {
  DownloadOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  MenuProps,
  Pagination,
  PaginationProps,
  Space,
  Table,
  TableColumnsType,
} from "antd";
import moment from "moment";
import React, { FunctionComponent, useState } from "react";
import { CourseApprovalType } from "../../../../../types/course-plan-approval";
import { useNavigate } from "react-router-dom";
import CourseConstructionPlan from "../../../mooc-course-construction-plan1/CourseConstructionPlan";
import { routesConfig } from "../../../../../config/routes";

type tableDataProps = {
  locale: object;
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  setPageNumber?: (pageNumber: number) => void;
  setPageSize?: (pageNumber: number) => void;
  listCourseApproval: CourseApprovalType[];
  activeTabKey: string;
};
const TableAppraisalPlan: FunctionComponent<tableDataProps> = ({
  locale,
  totalRecords,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  listCourseApproval,
  activeTabKey,
}) => {
  const [totalPage, setTotalPage] = useState(totalRecords);
  const [isOpenModalDownload, setIsOpenModalDownload] =
    useState<boolean>(false);
  const [courseConstructPlanId, setCourseConstructPlanId] = useState<number>();
  const navigation = useNavigate();
  const getOptionsAction = (record: any): MenuProps["items"] => {
    let options: MenuProps["items"] = [
      // {
      //     key: "1",
      //     label: "Thẩm định kế hoạch",
      //     icon: <FileDoneOutlined />
      // },
      // {
      //     key: "2",
      //     label: "Thẩm định nhanh kế hoạch",
      //     icon: <ThunderboltOutlined />
      // },
      {
        key: "3",
        label: "Lịch sử thẩm định",
        icon: <HistoryOutlined />,
      },
      {
        key: "4",
        label: "Tải nội dung kế hoạch",
        icon: <DownloadOutlined />,
      },
    ];
    if (!record.isLeader) {
      options = options.filter(
        (options) => options?.key !== "1" && options?.key !== "2"
      );
    }
    return options;
  };
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record: any, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Mã kế hoạch",
      width: 4,
      dataIndex: "registrationCode",
    },
    {
      title: "Tên kế hoạch",
      sorter: (a, b) => a.registrationName.localeCompare(b.registrationName),
      width: 12,
      dataIndex: "registrationName",
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record.registrationName}</span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Ngày gửi thẩm định",
      width: 5,
      dataIndex: "submissionDate",
      render: (_: any, record) => {
        return (
          <>
            {record.submissionDate ? formatDateV2(record.submissionDate) : null}
          </>
        );
      },
    },
    {
      title: "Ngày thẩm định",
      width: 5,
      dataIndex: "approvalDate",
      render: (_: any, record) => {
        return (
          <>{record.approvalDate ? formatDateV2(record.approvalDate) : null}</>
        );
      },
    },
    {
      title: "",
      width: 3,
      fixed: "right",
      render: (_: any, record) => {
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigation(
                    `/course-plan/appraisal/appraisal-detail/${record.courseConstructionPlanId}`,
                    {
                      state: {
                        historyCheck: false,
                        view: true,
                        keyTab: activeTabKey,
                        modeback: "backState",
                        backLink: `/${routesConfig.coursePlan}/${routesConfig.appraisal}`,
                      },
                    }
                  );
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: getOptionsAction(record),
                  onClick: ({ key }) => {
                    if (key === "4") {
                      setIsOpenModalDownload(true);
                      setCourseConstructPlanId(record.courseConstructionPlanId);
                    }
                    if (key === "3") {
                      navigation(
                        `/course-plan/appraisal/appraisal-detail/${record.courseConstructionPlanId}`,
                        { state: { historyCheck: true, view: true } }
                      );
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];
  const formatDateV2 = (date: string) => {
    return moment(date).format("DD/MM/yyyy");
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
      setTotalPage(totalRecords);
    }
  };

  return (
    <div className="table-wrapper">
      <Table
        size="middle"
        tableLayout="fixed"
        pagination={false}
        columns={dataColumns}
        dataSource={listCourseApproval}
        rowKey={"id"}
        scroll={{ x: 1000, y: 600 }}
        locale={locale}
      />
      <Pagination
        total={totalRecords}
        showSizeChanger
        pageSize={pageSize}
        current={pageNumber}
        className="pagination-table"
        onChange={onChange}
        itemRender={itemRender}
        locale={{
          items_per_page: "/ trang",
          jump_to: "Đi đến trang",
          page: "",
        }}
        showQuickJumper
        showTotal={(total) => `Tổng số ${total} bản ghi`}
      />
      {isOpenModalDownload && (
        <CourseConstructionPlan
          isOpen={isOpenModalDownload}
          setIsOpen={setIsOpenModalDownload}
          courseConstructPlanId={courseConstructPlanId}
        />
      )}
    </div>
  );
};

export default TableAppraisalPlan;
