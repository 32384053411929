// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-builder-view .flex-wrap {
  flex-wrap: nowrap; }

.page-builder-view .flex.flex-wrap.-m-2 {
  flex-wrap: wrap; }
`, "",{"version":3,"sources":["webpack://./src/pages/page-builder/page-builder-view.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB,EAAA;;AAFrB;EAMI,eAAe,EAAA","sourcesContent":[".page-builder-view {\n  .flex-wrap {\n    flex-wrap: nowrap;\n  }\n\n  .flex.flex-wrap.-m-2 {\n    flex-wrap: wrap;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
