import React from "react";
import { Radio } from "antd";

function ChooseVideo({ data }: any) {
  return (
    <div>
      <div className="flex layout-two-col">
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: any) => (
            <div
              className={`image-quiz-wrap choose-correct ${
                quiz?.isTrue && "active-quiz-correct"
              }`}
              key={quiz.uuid + index}
            >
              <Radio id={quiz.uuid + index} checked={quiz?.isTrue}>
                <span className={`${quiz?.isTrue && 'text-result'}`}>{quiz?.content}</span>
              </Radio>
              <video className="quest-video" controls>
                <source src={quiz?.filePath as string} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ChooseVideo;
