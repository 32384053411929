import {StateCreator} from "zustand";

export interface AutomaticScoreSlice {
	dataExamScore: any,
	scoreQuestion: any,
	setExamScore: (numberTab: number) => void,
	setScoreQuestion: (numberTab: any) => void,
}

export const createAutomaticScoreSlice: StateCreator<AutomaticScoreSlice> = (set, get) => ({
	dataExamScore: [],
	scoreQuestion: {
		question1: 0.2,
		question2: 0.2
	},
	setExamScore: (data: any) => {
		set({dataExamScore: data})
	},
	setScoreQuestion: (data: any) => {
		console.log(data);
		
		set({scoreQuestion: data})
	}
})
