import {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
  type CSSProperties,
} from "react";
import "./pagination.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Pagination } from "antd";

interface CustomPaginationProps {
  totalPage?: number;
  pageSize: number;
  pageNumber: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const CustomPagination: FunctionComponent<CustomPaginationProps> = ({
  pageSize,
  pageNumber,
  setPageSize,
  setPageNumber,
  totalPage,
}) => {
  const handlePageChange = (page: number) => {
    setPageNumber(page);
  };

  const handlePageSizeChange = (current: number, size: number) => {
    setPageSize(size);
  };
  useEffect(() => {
    setPageNumber(1);
  }, [pageSize]);
  return (
    <div className="pagination">
      <Pagination
        itemRender={pagingRender}
        current={pageNumber}
        total={totalPage}
        pageSize={pageSize}
        onChange={handlePageChange}
        onShowSizeChange={handlePageSizeChange}
        showSizeChanger
        pageSizeOptions={["5", "10", "20", "50"]}
        locale={{
          items_per_page: "/ trang",
          jump_to: "Đi đến trang",
          page: "",
        }}
      />
    </div>
  );
};

export default CustomPagination;
const pagingRender = (
  current: number,
  type: string,
  originalElement: React.ReactNode
) => {
  if (type === "prev") {
    return (
      <div className="pagination-arrow">
        <ArrowLeftOutlined className="pagination-arrow-icon" /> Trang trước
      </div>
    );
  }
  if (type === "next") {
    return (
      <div className="pagination-arrow">
        Trang sau
        <ArrowRightOutlined className="pagination-arrow-icon" />
      </div>
    );
  }
  return originalElement;
};
