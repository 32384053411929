import React, { MouseEvent, useEffect, useState } from "react";
import CustomCard from "../../../custom-card/CustomCard";
import {
  Button,
  Form,
  GetProp,
  message,
  Modal,
  Select,
  SelectProps,
  Table,
  TableColumnsType,
  Tooltip,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import "./index.scss";
import FormItemInput from "../../../form-input/FormInput";
import ConditionRegister from "./condition-register";
import InfoAuthor from "./info-author";
import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  LoadingOutlined,
  MailOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { FormInstance } from "antd/lib";
import { getListEmail, sendEmail, } from "../../../../service/content-course/contenCourseApi";
import { regexEmail } from "../../../../utils/regex";
import { postCourseSearch } from "../../../../service/course-construct";
import { useParams } from "react-router-dom";
import { BASE_URL_V2 } from "../../../../config/api/configApiv2";
import { useAuthStore } from "../../../../stores/stores";
import { isEmpty } from "lodash";
import { mapUrlToString } from "../../../../utils/format";

const validate = {
  linkInvite: {
    required: true,
    message: "Vui lòng nhập địa chỉ URL",
  },
  nameLayout: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (isEmpty(value)) {
        return Promise.reject(new Error("Vui lòng nhập tên bố cục"));
      }
      if (value.length > 255) {
        return Promise.reject(new Error("Tên bố cục không vượt quá 255 ký tự"));
      }
      return Promise.resolve();
    },
  },
};

type SettingContentCourseProps = {
  form: FormInstance;
  dataSetting?: any;
  isCertificate?: boolean;
  certificateId?: number;
  courseId?: string;
  setIsCertificate: (value: boolean) => void;
  setCertificateId: (value: number) => void;
  resetForm?: () => void
};

const SettingContentCourse = ({
  form,
  dataSetting,
  isCertificate,
  certificateId,
  setCertificateId,
  courseId,
  resetForm
}: SettingContentCourseProps) => {
  const [dataOptions, setDataOptions] = useState<any[]>([]);
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dataEmail, setDataEmail] = useState({});
  const [valueEmail, setValueEmail] = useState<string>();
  const [isCheckEmail, setIsCheckEmail] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [listEmail, setListEmail] = useState<SelectProps["options"]>([]);
  const [optionCourses, setOptionCourses] = useState([])
  const { id } = useParams()
  const accessToken = useAuthStore((state) => state.accessToken);
  const [dataImages, setDataImages] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [loadingUpload, setLoadingUpload] = useState<any>(false);

  const [uploadedImages, setUploadedImages] = useState<any>(
    {}
  );
  const [dataImagesName, setDataImagesName] = useState<any>(
    {}
  );
  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

  const newCourseRegister = optionCourses.map((i: any) => ({
    label: i.name,
    code: i.code,
  }))
  let timeout: ReturnType<typeof setTimeout> | null;
  let currentValue: string;

  interface IColumns {
    id: number;
    email: string;
    tittle: string;
    dataIndex: string;
    render: any;
  }


  const columns: TableColumnsType<IColumns> = [
    {
      key: "email",
      dataIndex: "email",
      title: "Email",
      width: "80%",
    },
    {
      key: "action",
      dataIndex: "action",
      width: "10%",
      render: (_: any, record: { id: number; email: string }) => {
        return (
          <>
            <span
              className="icon-delete"
              onClick={() => handleDelete(record.id || 0)}
            >
              <DeleteOutlined />
            </span>
          </>
        );
      },
    },
  ];

  const getDataCourse = async (page = 1) => {
    setLoading(true)
    postCourseSearch({ pageSize: 0, pageNumber: pageNumber, status: [13] })
      .then(res => {
        const { data, totalRecords } = res.data.data;
        setOptionCourses(data.filter((data: any) => data.status === 13));
        setTotalRecords(totalRecords);
        setPageNumber(page)
        setLoading(false)
        if (data.length < 10) {
          setHasMore(true)
        } else {
          setHasMore(false)
        }
      }).catch((err) => { })
  }

  useEffect(() => {
    getDataCourse();
  }, []);


  const getListEmailUser = (value: string, callBack: Function) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const getData = async () => {
      try {
        const response = await getListEmail(`?keyword=${value}`);
        const { data, statusCode } = response.data;

        if (statusCode === 200) {
          callBack(data);
        }

        if (currentValue === value) {
          const dataEmail = data?.map((data: any) => ({
            value: data,
            text: data,
          }));
          callBack(dataEmail);
        }
      } catch (err) { }
    };
    if (value) {
      timeout = setTimeout(getData, 300);
    } else {
      callBack([]);
    }
  };


  const handleChange = (value: string) => {
    if (!value.match(regexEmail)) {
      setIsCheckEmail(true);
    }
    if (value.match(regexEmail)) {
      setIsCheckEmail(false);
    }
    setValueEmail(value);
  };


  const handleAddEmail = () => {
    if (dataOptions.every((i) => i.email !== valueEmail)) {
      setDataEmail({
        ...dataEmail,
        email: valueEmail,
      });
    }
  };


  const handleSendEmail = async () => {
    const newArrEmail = dataOptions.map((i) => i.email);
    const dataReq = {
      courseId: id,
      emails: newArrEmail,
      universityIds: []
    }
    try {
      if (newArrEmail.length > 0) {
        const response = await sendEmail(dataReq);
        const { data, statusCode } = response.data;
        if (statusCode === 200) {
          message.success(data.message);
          setDataOptions([]);
          handleClose();
        }
      } else {
        message.error("Không có dữ liệu email được thêm");
      }
    } catch (err) { }
  };

  const handleClose = () => {
    setIsOpen(false);
    setDataOptions([]);
  };

  const handleDelete = (id: number) => {
    setDataOptions((prevState) => {
      return prevState.filter((i) => i.id !== id);
    });
  };

  useEffect(() => {
    if (Object.keys(dataEmail).length > 0) {
      dataOptions.push(dataEmail);
      setDataOptions(
        dataOptions.map((i, index) => ({ id: index + 1, email: i.email })),
      );
    }
  }, [dataEmail]);


  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        imageAvatar: uploadedImages.images
      })
    }
  }, [uploadedImages, form]);


  useEffect(() => {
    if (dataSetting.image) {
      form.setFieldsValue({
        imageAvatar: mapUrlToString(dataSetting.image)
      })
    }
  }, [dataSetting])

  const handleSearch = (value: string) => {
    getListEmailUser(value, setListEmail);
  };

  const beforeUpload = (file: FileType) => {
    setLoadingUpload(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error("Ảnh yêu cầu định dạng jpeg/png!");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300)
    }
    if (!isLt2M) {
      message.error("Ảnh phải có dung lượng nhỏ hơn 2M!");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300)
    }

    return isJpgOrPng && isLt2M;
  };

  const handleChangeImage = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoadingUpload(true);
      return;
    }

    if (info.file.status === 'done') {
      message.success('Tải ảnh lên thành công !');
      setLoadingUpload(false);
      const { response } = info.file;

      if (response && response.filePath && form) {
        const { response } = info.file
        setUploadedImages({
          ...uploadedImages,
          images: response.fileName
        });
        setDataImagesName({
          ...dataImagesName,
          images: response.filePath
        })
      }
    }
  };


  const handleRemoveImage = (e: MouseEvent) => {
    e.preventDefault();
    setDataImagesName({
      ...dataImagesName,
      images: null
    })
    setUploadedImages({
      ...uploadedImages,
      images: null
    })
    if (dataSetting.image) {
      dataSetting.image = null
    }
  };

  return (
    <>
      <div className="general-settings">
        <section className="setting-course">
          <div className="form-settings">
            <CustomCard
              title="Thông tin chung"
              className="wrapper-settings--card form-setting--common"
            >
              <div className="link-invite">
                <Form.Item
                  name="url"
                  rules={[validate.linkInvite]}
                  label="URL"
                >
                  <FormItemInput
                    prefixIcon="https://"
                    readOnly={true}
                    suffix={
                      <Tooltip
                        title={"Sao chép thành công!"}
                        trigger="click"
                      >
                        <CopyOutlined
                          style={{ color: "rgba(0, 0, 0, 0.25)" }}
                          onClick={() => {
                            navigator.clipboard.writeText(dataSetting.url);
                            setCopySuccess(true);
                          }}
                        />{" "}
                      </Tooltip>
                    }
                  />
                </Form.Item>
                <Button
                  className="btn btn-primary"
                  onClick={() => setIsOpen(true)}
                >
                  <MailOutlined />
                  <Typography.Text>Gửi lời mời</Typography.Text>
                </Button>
              </div>
              <Form.Item
                name="layoutName"
                rules={[validate.nameLayout]}
                label="Tên bố cục"
              >
                <FormItemInput placeholder="Nhâp tên bố cục" />
              </Form.Item>
              <Form.Item
                name="imageAvatar"
                label="Ảnh đại diện"
                className="upload--avatar"
              >
                <div className="image-container">
                  <Upload
                    listType="picture-card"
                    className="upload-image--settings"
                    action={
                      BASE_URL_V2 +
                      "/mooc-course-block-quiz/upload-file"
                    }
                    headers={{
                      Authorization:
                        "Bearer " + accessToken,
                    }}
                    beforeUpload={beforeUpload}
                    onChange={(e) =>
                      handleChangeImage(e)
                    }
                    showUploadList={false}
                  >
                    {
                      loadingUpload ? (
                        <div className='upload-content'><LoadingOutlined /></div>
                      ) : (
                        (dataSetting.image || dataImagesName.images) ? (
                          <img className='image-present'
                            src={dataSetting.image ? dataSetting.image : dataImagesName.images}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}

                            alt='image' />

                        ) : (
                          <div className='upload-content'><PlusOutlined /><span>Tải lên</span></div>
                        )
                      )
                    }
                  </Upload>
                  {(dataSetting.image || dataImagesName.images) && <span className="remove-icon">
                    <a className="upload-action" onClick={(e) => handleRemoveImage(e)}>
                      <DeleteOutlined />
                    </a>
                  </span>}
                </div>
                <div className="note-update">
                  <Typography.Text className="sub-title">
                    Độ phân giải đề xuất của ảnh khi tải
                    lên là 640*640, kích thước file tối đa
                    4MB.
                  </Typography.Text>
                </div>
              </Form.Item>
            </CustomCard>
            <ConditionRegister formInstance={form} dataConfig={dataSetting} loading={loading}
              fetchData={getDataCourse}
              pageNumber={pageNumber}
              hasMore={hasMore}
              totalRecords={totalRecords} optionCourses={newCourseRegister} />
            {/*<Certificate setIsCertificate={setIsCertificate}/>*/}
            <InfoAuthor form={form} dataConfig={dataSetting} />
            {/*<NotificationSetting/>*/}
            <Modal
              title="Gửi lời mời"
              okText="Gửi lời mời"
              open={isOpen}
              width={550}
              onCancel={handleClose}
              onOk={handleSendEmail}
              className="modal-send--mail"
              cancelText="Hủy"
            >
              <div className="wrapper-modal--email">
                <div className="input-email">
                  <Typography.Text>Nhập email:</Typography.Text>
                  <Select
                    showSearch
                    placeholder="Nhập email"
                    notFoundContent={null}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    virtual={true}
                    filterOption={false}
                    onSearch={handleSearch}
                    options={listEmail?.map((d) => ({
                      value: d.value,
                      label: d.text,
                    }))}
                    value={valueEmail}
                    onChange={handleChange}
                  />
                </div>
                <div className="btn-add--email">
                  <Button onClick={handleAddEmail} className="btn">
                    <PlusOutlined />
                    <Typography.Text>Thêm</Typography.Text>
                  </Button>
                </div>
              </div>
              <div>
                <Table
                  virtual={true}
                  scroll={{ y: 250 }}
                  pagination={false}
                  loading={loading}
                  columns={columns}
                  rowKey={(obj) => obj.id}
                  dataSource={dataOptions}
                />
              </div>
            </Modal>
            <div className="group-button">
              <Button
                className="ant-btn-primary"
                onClick={() => form?.submit()}
              >
                <SaveOutlined />
                <Typography.Text>Lưu</Typography.Text>
              </Button>
              <Button className="cancel-btn" onClick={resetForm}>
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SettingContentCourse;
