import React from "react";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import "./style.scss";
import {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from "antd/es/breadcrumb/Breadcrumb";

interface MoocBreadcrumbProps {
  items?: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
  className?: string;
}

function MoocBreadcrumb({ items, className }: MoocBreadcrumbProps) {
  return (
    <div className={`${className}`}>
      <Breadcrumb
        items={
          items || [
            {
              href: "",
              title: (
                <img
                  src={`${process.env.PUBLIC_URL}/assets/icons/home-line.svg`}
                />
              ),
            },
            {
              href: "",
              title: (
                <>
                  <UserOutlined />
                  <span>Application List</span>
                </>
              ),
            },
            {
              title: "Application",
            },
          ]
        }
      />
    </div>
  );
}

export default MoocBreadcrumb;
