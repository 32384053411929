import React, { useState } from "react";
import ChooseText from "./choose-text/ChooseText";
import { Button, Form, Select } from "antd";

import { convertMinutesToTimeString } from "../../../../utils/format";
import ChooseImage from "./choose-image";
import ChooseVideo from "./choose-video";
import TrueOrFalse from "./true-or-false";
import DropDown from "./drop-down";
import ChooseMultiText from "./choose-multi-text";
import ChooseMultiImage from "./choose-multi-image";
import ChooseMultiVideo from "./choose-multi-video";
import Connect from "./connect";
import ShortText from "./short-text";
import FileUpload from "./file-upload";
import Video from "./video";
import Mp3 from "./mp3";
import LongText from "./long-text";
import ScoreDetailModal from "../components/ScoreDetailModal";
import ReportModal from "../components/ReportModal";
import { ArrowLeftIcon, ExpandDownIcon, QuestionMarkIcon } from "../../../../components/icons/svg";
import { QuestionTypeEnum } from "../../../../constants";

interface ResultQuizLayoutProps {
  data?: any;
  onPause?: () => void;
  onFinish?: () => void;
  onBack?: () => void;
}

function ResultQuizLayout({ data, onBack = () => { } }: ResultQuizLayoutProps) {
  const [form] = Form.useForm();
  const { type } = data;

  const renderQuestionByType = () => {
    switch (type) {
      case QuestionTypeEnum.CHOOSE_TEXT:
        return <ChooseText isCorrect={true} answer={1} />;
      case 16:
        return <ChooseText isIncorrect={true} answer={2} />;

      case QuestionTypeEnum.CHOOSE_IMAGE:
        return <ChooseImage isCorrect={true} />;
      case 17:
        return <ChooseImage isIncorrect={true} />;

      case QuestionTypeEnum.CHOOSE_VIDEO:
        return <ChooseVideo isCorrect={true} />;
      case 18:
        return <ChooseVideo isIncorrect={true} />;

      case QuestionTypeEnum.TRUE_OR_FALSE:
        return <TrueOrFalse isCorrect={true} />;
      case 19:
        return <TrueOrFalse isIncorrect={true} />;

      case QuestionTypeEnum.DROP_DOWN:
        return <DropDown isCorrect={true} />;
      case 20:
        return <DropDown isIncorrect={true} />;

      case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
        return <ChooseMultiText isCorrect={true} />;
      case 21:
        return <ChooseMultiText isIncorrect={true} />;

      case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
        return <ChooseMultiImage isCorrect={true} />;
      case 22:
        return <ChooseMultiImage isIncorrect={true} />;

      case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
        return <ChooseMultiVideo isCorrect={true} />;
      case 23:
        return <ChooseMultiVideo isIncorrect={true} />;

      case QuestionTypeEnum.CONNECT:
        return <Connect form={form} />;
      case 24:
        return <Connect form={form} />;

      case QuestionTypeEnum.SHORT_TEXT:
        return <ShortText isCorrect={true} />;
      case 25:
        return <ShortText isIncorrect={true} />;

      case QuestionTypeEnum.LONG_TEXT:
        return <LongText isCorrect={true} />;
      case 26:
        return <LongText isIncorrect={true} />;

      case QuestionTypeEnum.FILE_UPLOAD:
        return <FileUpload form={form} />;
      case 27:
        return <FileUpload form={form} />;

      case QuestionTypeEnum.VIDEO:
        return <Video />;
      case 28:
        return <Video />;

      case QuestionTypeEnum.MP3:
        return <Mp3 />;
      case 29:
        return <Mp3 />;
      default:
        break;
    }
  };

  const renderFooter = () => {
    return (
      <div className="report-course flex flex-column flex-1">
        <div className="flex-1">
          {type > 15 ? (
            <div className="report-ans-wrapper">
              <p className="font-size-16 line-height-24 font-weight-6">
                Câu trả lời của bạn: Sai
              </p>
              <p className="font-size-16 line-height-24 mt-1">
                Figma ipsum component variant main layer. Arrow pixel distribute
                pixel effect auto invite underline.{" "}
              </p>
              <span className="expand-icon">
                <ExpandDownIcon />
              </span>
            </div>
          ) : (
            <div className="report-ans-wrapper-correct">
              <p className="font-size-16 line-height-24 font-weight-6">
                Câu trả lời của bạn: Chính xác
              </p>
              <p className="font-size-16 line-height-24 mt-1">
                Figma ipsum component variant main layer. Arrow pixel distribute
                pixel effect auto invite underline.{" "}
              </p>
              <span className="expand-icon">
                <ExpandDownIcon />
              </span>
            </div>
          )}
        </div>

        <div className="report-table mt-2">
          <div className="flex justify-space-between">
            <div className="flex gap-8 align-center">
              <h3 className="font-size-16 line-height-24 font-weight-6">
                Nhận xét bài kiểm tra
              </h3>
              <ScoreDetailModal>
                <Button className="btn-view" disabled>
                  <span className=" text-secondary font-size-16">Xem</span>
                </Button>
              </ScoreDetailModal>
            </div>
            <div className="report-history flex gap-8 align-center">
              <ReportModal>
                <div className="text-primary-violet font-weight-6 font-size-14 pointer">
                  Báo cáo
                </div>
              </ReportModal>

              <Select
                defaultValue={0}
                className="h-40 history-select font-weight-6"
                options={[{ label: "Lịch sử trả lời", value: 0 }]}
              />
            </div>
          </div>

          <div className="flex flex-wrap mt-3">
            <div className="table-item font-size-14 flex-1">
              Câu trả lời đúng:
              <p className="font-size-12 font-weight-6">15/22</p>
            </div>
            <div className="table-item font-size-14 flex-1 border-left">
              Điểm số:
              <p className="font-size-12 font-weight-6">7.7/10</p>
            </div>
            <div className="table-item font-size-14 flex-1 border-left">
              Thời gian làm bài:
              <p className="font-size-12 font-weight-6">00:40:00</p>
            </div>
            <div className="table-item font-size-14 flex-1 border-left">
              Thời gian nộp:
              <p className="font-size-12 font-weight-6">
                08:09:02 - 16/02/2024
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="quiz-layout flex flex-column quiz-view-layout">
      <div className="question-content flex flex-column">
        <div className="question-header">
          <div
            className="btn-back flex align-center gap-8 pointer"
            onClick={onBack}
          >
            <ArrowLeftIcon />{" "}
            <span className="font-size-14 text-primary-violet font-weight-6">
              Quay lại
            </span>
          </div>
          <div className="question-title font-size-16 line-height-24 font-weight-6 flex align-center gap-8 mt-1">
            Câu hỏi 01: Figma ipsum component variant main layer?{" "}
            <span className="quest-icon text-secondary">
              <QuestionMarkIcon />
            </span>
          </div>
          <div className="flex align-center gap-8 mt-1">
            <span
              className={`font-size-14 line-height-20 font-weight-6 ${type > 15 ? "score-incorrect-wrapper text-danger" : "score-correct-wrapper text-correct"}`}
            >
              Điểm: 0.0/1.0
            </span>
          </div>
        </div>
        <div className="question-display-content pt-2">
          <Form form={form} onValuesChange={(v, value) => { }}>
            {renderQuestionByType()}{" "}
          </Form>
        </div>
        {renderFooter()}
      </div>
    </div>
  );
}

export default ResultQuizLayout;
