import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  message,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
} from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  SendOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import PageHeader from "../../../../components/page-header/PageHeader";
import { get, isEmpty } from "lodash";
import { formatDateV2 } from "../../../../utils/converDate";
import "../../../../styles/_variables.scss";

import { DetailTypeAuthUser } from "../../../../types/mooc-share-all";
import { DetailTypeMoocUniversity } from "../../../../types/mooc-share-all";
import {
  DataTypeMoocCourseConstructionPlan,
  FilterDataMoocCourseConstructionPlan,
} from "../../../../types/course-plan-managment/mooc-course-construction-plan";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";
import { FormCreateMoocCourseConstructionPlan } from "./components/form-create";
import {
  postMoocCourseConstructionPlanSearch,
  deleteMoocCourseConstructionPlan,
  getMoocCourseConstructionPlanById,
  postCreateMoocCourseConstructionPlan,
  putUpdateMoocCourseConstructionPlan,
  getLookupAuthUser,
  getLookupMoocUniversity,
  getApprovalConfiguration,
} from "../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import dayjs from "dayjs";
import { useAuthStore } from "../../../../stores/stores";
import { revokeSurvey } from "../../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";
import ApproveCouresConstruction from "./components/approve-coures-constuction/approveCouresConstruction";
import {
  StatusEum,
  ApprovalStatusEnum,
  CoursePlanStatus,
} from "./constants/data";
import { HelpService } from "../../../../service/helper.service";
import TableData from "../../../../components/table-data/TableData";
import { deleteStructurePlanningCourseConstruction } from "../../../../service/course-plan-managment/planning-course-construction/PlanningCourseConstructionApi";
type ListMoocCourseConstructionPlanProps = {
  title?: string;
};

const ListMoocCourseConstructionPlan = (
  props: ListMoocCourseConstructionPlanProps
) => {
  // data option, columns is changing
  const idField = "id";
  const nameField = "id";
  const [modeView, setModeView] = useState("");

  const [listMoocCourseConstructionPlan, setListMoocCourseConstructionPlan] =
    useState<DataTypeMoocCourseConstructionPlan[]>([]);
  const [browseData, setBrowseData] =
    useState<DataTypeMoocCourseConstructionPlan>();
  const [detailData, setDetailData] = useState<any>();
  const [filterData, setFilterData] =
    useState<FilterDataMoocCourseConstructionPlan>({});
  const [isOpenModalApprove, setIsOpenModalApprove] = useState<boolean>(false);
  const [openModel, setOpenModel] = useState(false);
  // forgein key function call apis
  const [lstRegistrationSubmitterId, setLstRegistrationSubmitterId] = useState<
    DetailTypeAuthUser[]
  >([]);
  const [lstUniversityId, setLstUniversityId] = useState<
    DetailTypeMoocUniversity[]
  >([]);
  const [dataIndustry, setDataIndustry] = useState<any[]>([]);
  const [dataIndustryGroup, setDataIndustryGroup] = useState<any[]>([]);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [approval, setApproval] = useState<any[]>([]);
  const userId = useAuthStore((states) => states.user?.id);
  const helpService = new HelpService();
  const getListLstRegistrationSubmitterId = async () => {
    try {
      setLoading(true);
      await getLookupAuthUser()
        .then((res) => {
          const { data } = res.data;
          setLstRegistrationSubmitterId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return item;
            })
          );
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getListLstUniversityId = async () => {
    try {
      setLoading(true);
      await getLookupMoocUniversity()
        .then((res) => {
          const { data } = res.data;
          setLstUniversityId(
            data.map((item: any) => {
              if (!("name" in item)) item["name"] = item["id"];
              return item;
            })
          );
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const getApprovalConfigurationList = async () => {
    const data = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      approvalType: 2,
    };
    await getApprovalConfiguration(data).then((res: any) => {
      //console.log(res.data.data.data);
      const approvalData = res.data.data.data;
      const modifiedApproval = approvalData
        .map((item: any) => ({
          id: `${item.id}-1`,
          name: `Chờ ${item.approvalLevelName} duyệt`,
        }))
        .concat(
          approvalData.map((item: any) => ({
            id: `${item.id}-2`,
            name: `${item.approvalLevelName} đã duyệt`,
          }))
        )
        .concat(
          approvalData.map((item: any) => ({
            id: `${item.id}-3`,
            name: `${item.approvalLevelName} từ chối`,
          }))
        );
      // .concat(
      //   approvalData.map((item: any) => ({
      //     id: `${item.id}-4`,
      //     name: `${item.approvalLevelName} đã thu hồi`,
      //   }))
      // );

      setApproval(modifiedApproval);
    });
  };
  useEffect(() => {
    getListLstRegistrationSubmitterId();
    getListLstUniversityId();
    getApprovalConfigurationList();
  }, []);
  // const handleReturnStatus = (statusCode: number) => {
  //   switch (statusCode) {
  //     case 1:
  //       return "Bản nháp";
  //     case 2:
  //       return "Riêng tư";
  //     case 3:
  //       return "Chờ duyệt";
  //     case 4:
  //       return "Đã duyệt";
  //     default:
  //       return "";
  //   }
  // };
  const handleReturnStatus = (record: any) => {
    switch (record.constructionPlanStatus) {
      case StatusEum.SAVE_DRAFT:
        return "Lưu bản nháp";
      case StatusEum.WAIT_APPROVE:
        if (record.approvalStatus && record.approvalLevelName) {
          switch (record.approvalStatus) {
            case ApprovalStatusEnum.WAIT_APPROVE:
              return `Chờ ${record.approvalLevelName} duyệt`;
            case ApprovalStatusEnum.APPROVED:
              return `${record.approvalLevelName} đã duyệt`;
            case ApprovalStatusEnum.REFUSE:
              return `${record.approvalLevelName} từ chối`;
            default:
              return "Chờ duyệt";
          }
        }
        return "Chờ duyệt";
      case StatusEum.APPROVED:
        if (
          record.approvalStatus == ApprovalStatusEnum.APPROVED &&
          record.approvalLevelName
        ) {
          return `${record.approvalLevelName} đã duyệt`;
        }
        return "Đã duyệt";
      case StatusEum.REFUSE:
        if (
          record.approvalStatus == ApprovalStatusEnum.REFUSE &&
          record.approvalLevelName
        ) {
          return `${record.approvalLevelName} từ chối`;
        }
        return "Từ chối";
      case StatusEum.PUBLISHED:
        return "Đã xuất bản";
      case StatusEum.END:
        return "Kết thúc";
      default:
        return "";
    }
  };
  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự triển khai";
      case 2:
        return "Hợp tác xây dựng";
      case 3:
        return "Dùng chung";
      default:
        return "";
    }
  };

  const [dataOptions, setDataOptions] = useState<any>([]);
  useEffect(() => {
    setDataOptions([
      {
        title: "Ngày tạo",
        type: "RangePicker",
        key: "createdDateFromTo",
        size: 5,
        data: [],
      },
      {
        title: "Ngày duyệt",
        type: "RangePicker",
        key: "approvalDateFromTo",
        size: 5,
        data: [],
      },
      {
        title: "Trạng thái",
        type: "FormItemDropdown",
        key: "constructionPlanStatus",
        placeholder: "Chọn trạng thái",
        size: 4,
        data: [
          {
            id: 1,
            name: "Lưu bản nháp",
          },
          // {
          //   id: 2,
          //   name: "Chờ duyệt",
          // },
          // {
          //   id: 3,
          //   name: "Đã duyệt",
          // },
          // {
          //   id: 4,
          //   name: "Từ chối",
          // },
          ...approval.map((item) => ({
            id: item.id,
            name: item.name,
          })),
        ],
      },
      {
        title: "Loại khóa học",
        type: "FormItemDropdown",
        key: "courseType",
        placeholder: "Chọn khóa học",

        size: 4,
        data: [
          {
            id: 1,
            name: "Tự triển khai",
          },
          {
            id: 2,
            name: "Hợp tác xây dựng",
          },
          {
            id: 3,
            name: "Dùng chung",
          },
        ],
      },
    ]);
  }, [approval]);

  const [dataFilter, setDataFilter] = useState({});
  const getOptionsAction = (record: any): MenuProps["items"] => {
    //console.log({ record });
    let optionsDropdownMenu: MenuProps["items"] = [
      {
        key: "edit",
        label: "Sửa đăng ký",
        icon: <EditOutlined />,
      },
      {
        key: "sendorredeem",
        label: "Gửi duyệt đăng ký",
        icon: <SendOutlined />,
      },
      {
        key: "revoke",
        label: "Thu hồi gửi duyệt",
        icon: <UndoOutlined />,
      },
      // {
      //   key: "publish",
      //   label: "Xuất bản",
      //   icon: <RedoOutlined />,
      // },
      {
        key: "history",
        label: "Lịch sử hoạt động",
        icon: <HistoryOutlined />,
      },
      {
        key: "delete",
        label: "Xóa đăng ký",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
      {
        key: "deleteCourse",
        label: "Xóa cấu trúc khóa học",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
    ];
    if (
      record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE ||
      record.constructionPlanStatus === CoursePlanStatus.APPROVED
    ) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "edit" && option?.key !== "sendorredeem"
      );
    }
    if (
      record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE ||
      record.constructionPlanStatus === CoursePlanStatus.APPROVED
    ) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "edit" && option?.key !== "sendorredeem"
      );
    }
    if (
      record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE &&
      record.approvalStatus === ApprovalStatusEnum.WAIT_APPROVE &&
      record.hierarchyId === null
    ) {
    } else {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key !== "revoke"
      );
    }

    if (record.constructionPlanStatus === CoursePlanStatus.APPROVED) {
      optionsDropdownMenu = optionsDropdownMenu.filter(
        (option) => option?.key === "history"
      );
    }
    // if (
    //   record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE ||
    //   record.constructionPlanStatus === CoursePlanStatus.APPROVED
    // ) {
    //   optionsDropdownMenu = optionsDropdownMenu.filter(
    //     (option) => option?.key !== "edit"
    //   );

    // }

    return optionsDropdownMenu;
  };

  const closeApproveModal = () => {
    setIsOpenModalApprove(false);
    getListData();
  };
  const revoke = (id: any) => {
    const data = {
      courseId: id,
      submitterId: userId,
    };
    revokeSurvey(data).then((res) => {
      const { data, statusCode, message } = res.data;
      if (statusCode === 200) {
        helpService.successMessage(get(data, "message", message));
        getListData();
      }
    });
  };
  // const handleOptionMenu = (id: any) => {
  //   const optionsDropdownMenuChange: MenuProps["items"] = [
  //     ...optionsDropdownMenu,
  //   ];
  //   for (let index = 0; index < optionsDropdownMenu.length; index++) {
  //     const element = optionsDropdownMenu[index];
  //     if (element?.key === "sendorredeem") {
  //       if (id === 3) {
  //         optionsDropdownMenuChange[index] = {
  //           key: "sendorredeem",
  //           label: "Thu hồi gửi duyệt",
  //           icon: <SendOutlined />,
  //         };
  //       } else if (id === 4) {
  //         optionsDropdownMenuChange.splice(index, 1);
  //       }
  //     }
  //   }
  //   return optionsDropdownMenuChange;
  // };
  const validations = {
    user: {
      required: true,
      message: "Nhập tên cán bộ duyệt đăng ký",
      validator: (_: unknown, value: string) => {
        value = "x";
        if (!value) {
          return Promise.reject(new Error("Nhập tên cán bộ duyệt đăng ký"));
        }
        return Promise.resolve();
      },
    },
  };
  const dataColumns: TableColumnsType<DataTypeMoocCourseConstructionPlan> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên đăng ký",
      dataIndex: "registration_name",
      key: "registration_name",
      width: 13,
      sorter: true,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record.registrationName}</span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "construction_plan_status",
      key: "construction_plan_status",
      width: 5,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.constructionPlanStatus === 1
                    ? "#D9D9D9"
                    : record.constructionPlanStatus === 2
                    ? "#FAAD14"
                    : record.constructionPlanStatus === 3
                    ? "#1677FF"
                    : record.constructionPlanStatus === 4
                    ? "#FF0000"
                    : "white",
              }}
            ></div>
            {handleReturnStatus(record)}
          </div>
        );
      },
    },
    {
      title: "Loại khóa học",
      dataIndex: "courseType",
      key: "courseType",
      width: 5,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            {record.courseType === 2 ? (
              <div className="table-course-type-2">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : record.courseType === 1 ? (
              <div className="table-course-type-1">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : record.courseType === 3 ? (
              <div className="table-course-type-1">
                {handleReturnCourseType(record.courseType)}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createDate",
      key: "createDate",
      width: 5,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        return (
          <>
            {moment(record?.createdDate).format("DD/MM/yyyy")}
          </>
        );
      },
    },
    {
      title: "Ngày duyệt",
      dataIndex: "moocApproval",
      key: "moocApproval",
      width: 5,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record) => {
        if (
          record.moocApproval.length == 1 &&
          record.moocApproval[0]?.approval_date != null
        ) {
          return formatDateV2(record.moocApproval[0].approval_date.toString());
        }
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 2.5,
      render: (_, record) => {
        const location = {
          id: `${record[idField]}`,
        };
        return (
          <div style={{ width: 12 }}>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.moocCourseConstructionPlanDetail}/${location.id}`,
                    {
                      state: { record, mode: "view" },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  //items: handleOptionMenu(record.constructionPlanStatus),
                  items: getOptionsAction(record),
                  onClick: ({ key }) => {
                    //handleOptionMenu(1);
                    if (key === "edit") {
                      setIdDetail(record[idField]);
                      setIsOpened(true);
                      getDetailData(record[idField]);
                      setModeView("edit");
                    }
                    if (key === "copy") {
                      Modal.confirm({
                        title: `Bạn có muốn tạo bản sao cho ${record[nameField]}`,
                        content: `Tên của bản sao Kế hoạch xây dựng khoá học (đăng ký và lập) là "${record[nameField]} (bản sao)"`,
                        onOk: () => copyData(record),
                        okText: "Đồng ý",
                        cancelText: "Huỷ",
                        centered: true,
                        icon: <CopyOutlined style={{ color: "#1677FF" }} />,
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "sync") {
                      setIsOpenSyncModal(true);
                      setBrowseData(record);
                    }
                    if (key === "revoke") {
                      // console.log("Hello");
                      Modal.confirm({
                        title: `Thu hồi gửi duyệt đăng ký kế hoạch ${record.registrationName}`,
                        content: `Bạn có chắc chắn muốn thu hồi gửi duyệt đăng ký kế hoạch ${record.registrationName}?`,
                        onOk: () => revoke(record.id),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "publish") {
                      setIsOpenBrowseModal(true);
                      setBrowseData(record);
                    }
                    if (key === "sendorredeem") {
                      setIsOpenModalApprove(true);
                      setBrowseData(record);
                      // if (record.constructionPlanStatus === 3) {
                      //   Modal.confirm({
                      //     title: `Thu hồi gửi duyệt ${record.registrationName}`,
                      //     content: `Bạn có chắc chắn muốn thu hồi gửi duyệt?`,
                      //     onOk: () => revokeData(record),
                      //     centered: true,
                      //     okText: "Thu hồi",
                      //     cancelText: "Huỷ",
                      //     footer: (_, { OkBtn, CancelBtn }) => (
                      //       <>
                      //         <CancelBtn />
                      //         <OkBtn />
                      //       </>
                      //     ),
                      //   });
                      // } else {
                      //   Modal.confirm({
                      //     icon: <div />,
                      //     width: "40%",
                      //     title: `Gửi duyệt ${record.registrationName}`,
                      //     content: (
                      //       <div>
                      //         <Form
                      //           className="form-create-edit"
                      //           layout={"vertical"}
                      //           form={form}
                      //           // onFinish={onFinish}
                      //           name="create-edit-mooc-course-construction-plan-detail"
                      //         >
                      //           <Col span={24}>
                      //             <Form.Item
                      //               name="user"
                      //               label="Đăng ký kế hoạch khóa học của bạn sẽ được gửi tới Quản trị khoá học:"
                      //               rules={[validations.user]}
                      //             >
                      //               <FormItemDropdown
                      //                 modeDropdown="multiple"
                      //                 options={lstRegistrationSubmitterId}
                      //                 allowClear={true}
                      //                 placeholder="Chọn khoa đăng ký kế hoạch áp dụng"
                      //               />
                      //             </Form.Item>
                      //           </Col>
                      //         </Form>
                      //       </div>
                      //     ),
                      //     onOk: () => revokeData(record),
                      //     centered: true,
                      //     okText: "Gửi yêu cầu",

                      //     cancelText: "Hủy",
                      //     footer: (_, { OkBtn, CancelBtn }) => (
                      //       <>
                      //         <CancelBtn />
                      //         <OkBtn />
                      //       </>
                      //     ),
                      //   });
                      // }
                    }
                    if (key === "history") {
                      navigate(
                        `${routesConfig.historyMoocCourseConstructionPlan}/${record.id}`,
                        {
                          state: {
                            ...record,
                          },
                        }
                      );
                    }
                    if (key === "delete") {
                      Modal.confirm({
                        title: `Xoá ${record.registrationName}`,
                        content: (
                          <div>
                            <div>
                              Đăng ký kế hoạch đã xóa sẽ không thể khôi phục.
                            </div>
                            <div>Bạn có chắc muốn xóa đăng ký?</div>
                          </div>
                        ),
                        onOk: () => deleteData(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "deleteCourse") {
                      Modal.confirm({
                        title: `Xoá cấu trúc khóa học ${record.registrationName}`,
                        content: (
                          <div>
                            <div>
                              Cấu trúc khóa học đã xóa sẽ không thể khôi phục.
                            </div>
                            <div>Bạn có chắc muốn xóa cấu trúc khóa học?</div>
                          </div>
                        ),
                        onOk: () => deleteStructure(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </div>
        );
      },
      fixed: "right",
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const listBreadcrumb = [
    {
      title: "Kế hoạch khoá học",
    },
    {
      title: "Đăng kí kế hoạch khóa học",
    },
  ];

  // function call apis, process data
  const getListData = () => {
    setLoading(true);
    // console.log(filterData);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      id: !isEmpty(filterData.id) ? filterData.id : null,
      registrationCode: !isEmpty(filterData.registrationCode)
        ? filterData.registrationCode
        : null,
      registrationName: !isEmpty(filterData.registrationName)
        ? filterData.registrationName
        : null,
      courseStart: !isEmpty(filterData.courseStart)
        ? filterData.courseStart
        : null,
      courseStartFrom: !isEmpty(filterData.courseStartFromTo?.[0])
        ? filterData.courseStartFromTo?.[0].toString()
        : null,
      courseStartTo: !isEmpty(filterData.courseStartFromTo?.[1])
        ? filterData.courseStartFromTo?.[1].toString()
        : null,
      courseDuration: !isEmpty(filterData.courseDuration)
        ? filterData.courseDuration
        : null,

      constructionPlanStatus: !isEmpty(filterData.constructionPlanStatus)
        ? filterData.constructionPlanStatus
        : null,
      courseType: !isEmpty(filterData.courseType)
        ? filterData.courseType
        : null,
      registrationSubmissionDate: !isEmpty(
        filterData.registrationSubmissionDate
      )
        ? filterData.registrationSubmissionDate
        : null,
      registrationSubmissionDateFrom: !isEmpty(
        filterData.registrationSubmissionDateFromTo?.[0]
      )
        ? filterData.registrationSubmissionDateFromTo?.[0].toString()
        : null,
      registrationSubmissionDateTo: !isEmpty(
        filterData.registrationSubmissionDateFromTo?.[1]
      )
        ? filterData.registrationSubmissionDateFromTo?.[1].toString()
        : null,
      registrationSubmitterId: !isEmpty(filterData.registrationSubmitterId)
        ? filterData.registrationSubmitterId
        : null,
      universityId: !isEmpty(filterData.universityId)
        ? filterData.universityId
        : null,
      registrationStageId: !isEmpty(filterData.registrationStageId)
        ? filterData.registrationStageId
        : null,
      createdDate: !isEmpty(filterData.createdDate)
        ? filterData.createdDate
        : null,
      createdDateFrom: !isEmpty(filterData.createdDateFromTo?.[0])
        ? filterData.createdDateFromTo?.[0].toString()
        : null,
      createdDateTo: !isEmpty(filterData.createdDateFromTo?.[1])
        ? filterData.createdDateFromTo?.[1].toString()
        : null,
      updatedDate: !isEmpty(filterData.updatedDate)
        ? filterData.updatedDate
        : null,
      updatedDateFrom: !isEmpty(filterData.updatedDateFromTo?.[0])
        ? filterData.updatedDateFromTo?.[0].toString()
        : null,
      updatedDateTo: !isEmpty(filterData.updatedDateFromTo?.[1])
        ? filterData.updatedDateFromTo?.[1].toString()
        : null,
      approvalDateFrom: !isEmpty(filterData.approvalDateFromTo?.[0])
        ? filterData.approvalDateFromTo?.[0].toString()
        : null,
      approvalDateTo: !isEmpty(filterData.approvalDateFromTo?.[1])
        ? filterData.approvalDateFromTo?.[1].toString()
        : null,
      isDeleted: !isEmpty(filterData.isDeleted) ? filterData.isDeleted : null,
      moocApproval: !isEmpty(filterData.moocApproval)
        ? filterData.moocApproval
        : null,
      moocConstructionPlanCollaborator: !isEmpty(
        filterData.moocConstructionPlanCollaborator
      )
        ? filterData.moocConstructionPlanCollaborator
        : null,
      moocConstructionPlanIndustry: !isEmpty(
        filterData.moocConstructionPlanIndustry
      )
        ? filterData.moocConstructionPlanIndustry
        : null,
      moocConstructionPlanIndustryGroup: !isEmpty(
        filterData.moocConstructionPlanIndustryGroup
      )
        ? filterData.moocConstructionPlanIndustryGroup
        : null,
      moocCourseConstructionPlan: !isEmpty(
        filterData.moocCourseConstructionPlan
      )
        ? filterData.moocCourseConstructionPlan
        : null,
      otherMoocCourseConstructionPlan: !isEmpty(
        filterData.otherMoocCourseConstructionPlan
      )
        ? filterData.otherMoocCourseConstructionPlan
        : null,
      moocCoursePlanAssignment: !isEmpty(filterData.moocCoursePlanAssignment)
        ? filterData.moocCoursePlanAssignment
        : null,
      moocCourseStructure: !isEmpty(filterData.moocCourseStructure)
        ? filterData.moocCourseStructure
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };
    postMoocCourseConstructionPlanSearch(data)
      .then((res) => {
        const { data } = res.data;
        setListMoocCourseConstructionPlan(data.data);
        setTotalRecords(data.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
  };

  const getDetailData = async (id: number) => {
    setLoading(true);
    try {
      const response = await getMoocCourseConstructionPlanById(
        (Number(id) || 0).toString()
      )
        .then((res) => {
          const { data } = res.data;
          // console.log("mamamamamamamma");
          // console.log(data);
          setDetailData({
            id: data?.id,
            registrationCode: data?.registrationCode,
            registrationName: data?.registrationName,
            courseStart: dayjs(data?.courseStart),
            courseDuration: data?.courseDuration || 0,
            constructionPlanStatus: data?.constructionPlanStatus,
            courseType: data?.courseType,
            registrationSubmissionDate: dayjs(data?.registrationSubmissionDate),
            registrationSubmitterId: data?.registrationSubmitterId,
            universityId: data?.universityId,
            registrationStageId: data?.registrationStageId,
            createdDate: dayjs(data?.createdDate),
            updatedDate: dayjs(data?.updatedDate),
            isDeleted: data?.isDeleted,
            // moocApproval: data?.moocApproval,
            moocConstructionPlanCollaborator:
              data?.moocConstructionPlanCollaborator,
            moocConstructionPlanIndustry: data?.moocConstructionPlanIndustry,
            moocConstructionPlanIndustryGroup:
              data?.moocConstructionPlanIndustryGroup,
            moocUniversity: data?.moocUniversity,
          });
          setTimeout(() => {
            setLoading(false);
            setIsOpened(true);
          }, 500);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const deleteData = async (record: any) => {
    try {
      if (
        record.constructionPlanStatus === 2 ||
        record.constructionPlanStatus === 3
      ) {
        message.error(
          `Không thể xóa đăng ký kế hoạch ${handleReturnStatus(
            record.constructionPlanStatus
          )}`
        );
      } else {
        setLoading(true);
        await deleteMoocCourseConstructionPlan(record.id)
          .then((res) => {
            message.success(
              "Đăng ký kế hoạch khóa học đã được xóa trên hệ thống"
            );
            setPageNumber(1);
            getListData();
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
      // message.error("Có lỗi xảy ra");
    }
  };

  const deleteStructure = async (record: any) => {
    try {
      if (
        record.constructionPlanStatus === 2 ||
        record.constructionPlanStatus === 3
      ) {
        message.error(
          `Không thể xóa cấu trúc kế hoạch ${handleReturnStatus(
            record.constructionPlanStatus
          )}`
        );
      } else {
        setLoading(true);
        await deleteStructurePlanningCourseConstruction(record.id)
          .then((res) => {
            // debugger;
            //if (res.data.statusCode === 200) {
            message.success("Cấu trúc khóa học đã được xóa trên hệ thống");
            getListData();
            setLoading(false);
            //}
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            message.error("Có lỗi xảy ra");
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi xảy ra");
    }
  };

  const copyData = async (record: any) => {
    alert("copyData");
  };

  const revokeData = async (record: any) => {
    try {
      if (record.constructionPlanStatus === 3) {
        record.constructionPlanStatus = 1;
        const data = record;
        const response = await putUpdateMoocCourseConstructionPlan(
          record.id,
          data
        )
          .then((result) => {
            message.success(
              `Yêu cầu thu hồi duyệt đăng ký kế hoạch được gửi thành công`
            );
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            message.error("Yêu cầu thu hồi duyệt đăng ký kế hoạch thất bại");
          });
      } else {
        record.constructionPlanStatus = 3;
        const data = record;
        const response = await putUpdateMoocCourseConstructionPlan(
          record.id,
          data
        )
          .then((result) => {
            message.success(
              `Yêu cầu duyệt đăng ký kế hoạch được gửi thành công`
            );
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            message.error("Yêu cầu duyệt đăng ký kế hoạch được gửi thất bại");
          });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const handleCreate = async (value: any) => {
    // console.log("value", value);
    try {
      setLoading(true);
      const data = value;
      // console.log("datata");
      // console.log(data);
      const response = await postCreateMoocCourseConstructionPlan(data)
        .then(async (result) => {
          // console.log(result.data.data.id);
          setLoading(false);
          if (result.data.data.id !== undefined) {
            message.success(
              "Kế hoạch xây dựng khoá học đã được thêm thành công"
            );
            navigate(
              `${routesConfig.moocCourseConstructionPlanDetail}/${result.data.data.id}`,
              {
                state: { mode: "edit" },
              }
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const handleEdit = async (value: any) => {
    try {
      const data = value;
      const response = await putUpdateMoocCourseConstructionPlan(idDetail, data)
        .then((result) => {
          // navigate success
          // console.log("data nha");
          // console.log(data);
          message.success(
            `Sửa Kế hoạch xây dựng khoá học (đăng ký và lập) ${data.registrationName} thành công`
          );
          navigate(
            `${routesConfig.moocCourseConstructionPlanDetail}/${idDetail}`,
            {
              state: { mode: "edit" },
            }
          );
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          // message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      // message.error("Có lỗi chưa xác định");
    }
  };

  const clearFilter = () => {
    setLoading(true);
    console.log("xoa");
    form.resetFields();
    setFilterData((e) => ({
      ...e,
      constructionPlanStatus: null,
      courseType: null,
      createdDateFromTo: null,
      approvalDateFromTo: null,
    }));
    setShouldFetchData(true);
  };
  useEffect(() => {
    if (shouldFetchData) {
      getListData();
      setShouldFetchData(false);
    }
  }, [shouldFetchData]);
  // common state variables, no change
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [tab, setTab] = useState(1);
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpenBrowseModal, setIsOpenBrowseModal] = useState<boolean>(false);
  const [isOpenSyncModal, setIsOpenSyncModal] = useState<boolean>(false);
  const [syncCheckbox, setSyncCheckbox] = useState<boolean>(false);
  const [clearForm, setClearForm] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [idDetail, setIdDetail] = useState(0);
  const [isShow, setIsShow] = useState<boolean>(false);

  // common functions
  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
    setIsShow((isShow) => !isShow);
  };
  

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    setPageSize(10);
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      setPageSize(10);
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleOpenCreate = (e: any) => {
    // add course here
    // if (e === "create") {
    setIdDetail(0);
    form.resetFields();
    setModeView("create");
    setIsOpened(true);
    // }
  };

  const handleSave = (value: any) => {
    if (idDetail && idDetail !== 0) {
      handleEdit(value);
    } else {
      handleCreate(value);
    }
  };

  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider
        onFormFinish={(formName, { values, forms }) => {
          if (formName === "create-edit-mooc-course-construction-plan-detail") {
            console.log(values);
            handleSave(values);
          }
        }}
      >
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader
                title={title}
                listBreadcrumb={listBreadcrumb}
                breadcrumb={true}
              />
            </div>
            <FilterTable
              handleOpen={(e) => handleOpenCreate(e)}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
              showFilter={dataOptions != null && dataOptions.length > 0}
              isShow={isShow}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            onFinish={handleSubmitSearch}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
          />
        </div>

        <div className="tabs-heading">
          <TableData
            dataTable={listMoocCourseConstructionPlan}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
        <FormCreateMoocCourseConstructionPlan
          // truyen danh sach cac bien state list
          dataOptions={dataOptions}
          id={idDetail}
          detailData={detailData}
          isOpened={isOpened}
          modeOpen={modeView}
          setIsOpened={setIsOpened}
          //setDetailData={setDetailData}
          //setDataUpdate={setDataUpdate}
          //setDataSponsorUpdate={setDataSponsorUpdate}
        />
        <ApproveCouresConstruction
          open={isOpenModalApprove}
          handleCancel={() => {
            closeApproveModal();
          }}
          browseData={browseData}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListMoocCourseConstructionPlan;
