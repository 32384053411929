// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.default-alert {
  display: flex;
  align-items: center;
  padding: 4px;
  background: #fffaeb;
  border: 1px solid #fedf89;
  color: #b54708;
  border-radius: 9999px;
  gap: 8px; }
  .default-alert .war-not {
    border-radius: 9999px;
    border: 1px solid #fedf89;
    background: #fff;
    padding: 0 10px; }
  .default-alert .flex-1 {
    flex: 1 1; }
  .default-alert .pointer {
    cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/alert/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,qBAAqB;EACrB,QAAQ,EAAA;EARV;IAUI,qBAAqB;IACrB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe,EAAA;EAbnB;IAgBI,SAAO,EAAA;EAhBX;IAmBI,eAAe,EAAA","sourcesContent":[".default-alert {\n  display: flex;\n  align-items: center;\n  padding: 4px;\n  background: #fffaeb;\n  border: 1px solid #fedf89;\n  color: #b54708;\n  border-radius: 9999px;\n  gap: 8px;\n  .war-not {\n    border-radius: 9999px;\n    border: 1px solid #fedf89;\n    background: #fff;\n    padding: 0 10px;\n  }\n  .flex-1 {\n    flex: 1;\n  }\n  .pointer {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
