import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { routesConfig } from "../../../config/routes";
import PageHeader from "../../../components/page-header/PageHeader";
import { useEffect, useState } from "react";
import { getDetailAdminInterface } from "../../../service/admin-interface";

const imgPath = "/images/";
const { Content } = Layout;

type Props = {
  isCourseApprove?: string;
};
export default function DetailAdminInterface(props: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");

  const listBreadcrumb = [
    {
      title: (
        <a
          href={`/${routesConfig.adminInterface}`}
        >
          Thiết lập giao diện quản trị
        </a>
      ),
    },
    {
      title: name
    },
  ];

  const getColorDetail = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id: any = urlParams.get('interfaceId')
    await getDetailAdminInterface(id).then((res) => {
      if (res.status === 200) {
        setName(res.data?.data?.name)
      }
    });
  };

  useEffect(() => {
    getColorDetail();
  }, [navigate]);

  return (
    <Layout>
      <Content className="detail-topic-page">
        <PageHeader
          title={`Giao diện quản trị: ${name}`}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <div className="wrapper-course-construct">
            <section className="detail-topic-page">
              <div className="form-detail">
                {/* <DetailTopic detailData={location.state?.detailData}/> */}
              </div>
            </section>
          </div>
        </div>
      </Content>
    </Layout>
  );
}
