// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden-text {
  display: none; }

.no-border {
  border-width: 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/page-builder/plugins/lecturer/styles.scss"],"names":[],"mappings":"AAAA;EACI,aACJ,EAAA;;AAEA;EACI,eAAe,EAAA","sourcesContent":[".hidden-text{\n    display:none\n}\n\n.no-border{\n    border-width: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
