import {SaveOutlined, WarningOutlined} from "@ant-design/icons";
import {Button, Checkbox, Modal} from "antd";
import {Editor} from "grapesjs";

export const confirmMessage = (
  title: string,
  typePopup: number,
  content: string,
  ok?: any,
  cancel?: any,
  customFooter: boolean = false,
  customFooterFN?: any,
  customBodyFN?: any,
) => {
  Modal.confirm({
    title: title,
    icon: typePopup === 0 ? <WarningOutlined/> : <SaveOutlined/>,
    content: customFooter ? (
      <>
        <Checkbox
          onChange={() => {
            customBodyFN && customBodyFN();
          }}
        >
          Lưu làm mẫu
        </Checkbox>
        <br/>
        <span className="description">Khối giao diện sẽ được lưu vào mục "Mẫu hoàn chỉnh"</span>
      </>
    ) : (
      content
    ),
    className: typePopup === 0 ? "modal-custom danger" : "modal-custom success",
    okButtonProps: {
      className: typePopup === 0 ? "btn btn-filled--danger" : "btn",
    },
    cancelButtonProps: {
      className: "btn btn-outlined",
    },
    okText: customFooter ? "Lưu" : "Xác nhận",
    cancelText: "Huỷ",
    onOk: () => {
      ok && ok();
    },
    onCancel: () => {
      cancel && cancel();
    },
    footer: (_, {OkBtn, CancelBtn}) => (
      <>
        <CancelBtn/>
        {customFooter ? (
          <Button
            onClick={() => {
              customFooterFN && customFooterFN();
            }}
          >
            Lưu nháp
          </Button>
        ) : (
          ""
        )}
        <OkBtn/>
      </>
    ),
  });
};

export const reloadAllTemplate = (editor: Editor, objTemplate: string) => {
  if (objTemplate) {
    const parseCourseSavedTempalte = JSON.parse(objTemplate);
    editor.setComponents(parseCourseSavedTempalte.html);
    editor.setStyle(parseCourseSavedTempalte.css);
  }
};

export const isValidJSON = (jsonString: string) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
};

export const checkTemplateIsNull = (editor: Editor) => {
  const components = editor.getComponents();
  if (components.length === 0) {
    return true;
  } else {
    return false;
  }
};

export const loadDefaultUI = (editor: Editor) => {
  // editor.setComponents(uiDefault.html);
  // editor.setStyle(uiDefault.css);
};

export const stringCssObj = (cssObj: any) => {
  let idStyleString = "";
  Object.entries(cssObj).forEach(([name, value]) => {
    idStyleString += `${name}:${value};`;
  });
  return idStyleString;
};

export const addBlockToEditor = (blockManager: any, blocks: any, cb?: any) => {
  blocks.forEach((b: any, i: number) => {
    blockManager.add(b.id, {
      label: b.label ? b.label : 'Mẫu ' + (i + 1),
      content: b.html ? b.html : b.content,
      category: b.category,
      media: b.media,
    });
    if (i === blocks.length - 1) {
      cb && cb();
    }
  });
};
