import { Form, Input, message, Modal, Select, Spin, Tooltip, Tree, TreeDataNode, TreeProps, } from "antd";
import "./page-builder.scss";
import "./grapesjs.min.css";
import grapesjs, { Block, Editor } from "grapesjs";
import GjsEditor, { useEditor, WithEditor } from "@grapesjs/react";
import gjsBlocksBasic from "grapesjs-blocks-basic";
import gjsTailWind from "grapesjs-tailwind";
import gjsPresetWeb from "grapesjs-preset-webpage";
import gjsStyleBg from "grapesjs-style-bg";
import gjsStyleFilter from "grapesjs-style-filter";
import gjsStyleGradient from "grapesjs-style-gradient";
import gjsTabs from "grapesjs-tabs";
import grapesjsTyped from "grapesjs-typed";
import baseReactComponent from "./plugins/base-react-component";
import gjsLecturerComponent from "./plugins/lecturer";
import gjsConditionComponent from "./plugins/condition";
import CommonAdvanceSettingsPlugin from "./plugins/common-advance-settings";
import { imagesAssests, styleManagerConfig } from "./grapesjs-config";
import { LoadingOutlined, RetweetOutlined } from "@ant-design/icons";
import { HelpService } from "../../service/helper.service";
import { useParams } from "react-router-dom";
import {
  getReviewCourse,
  getStudentRegisterCourse,
  getTemplateByCourseId,
  getTemplateCategory,
  saveTemplateBlock,
  saveTemplates,
} from "../../service/page-builder/page-builder";
import { PlanInfo } from "../../types/assign-course-construction";
import { IBodyBlock, IBodyTemplates } from "../../types/grapesjs";
import { routesConfig } from "../../config/routes";
import { get, isArray, isEmpty } from "lodash";
import { forwardRef, Key, Ref, useEffect, useImperativeHandle, useRef, useState, } from "react";
import { useAuthStore } from "../../stores/stores";
import { postCourseSearch } from "../../service/course-construct";
import { createRoot } from "react-dom/client";
import {
  affiliatedBlock,
  conditionBlockGenerete,
  contactBlockCustom,
  countBlockGenerete,
  headerBlockLogo,
  headerBlockName,
  nameAffiliatedBlocks,
  nameBasicBlock,
  nameCategoryBlocks,
  nameCategoryTemplates,
  nameConditionBlocks,
  nameContactBlock,
  nameCountBlocks,
  nameHeaderBlocks,
  nameLecturersBlock,
  nameOpinionBlocks,
  nameQuestionBlocks,
  nameRegisterBlocks,
  nameRegisterPolicyBlocks,
  nameRoadMapBlocks,
  opinionBlockCustom,
  questionBlockCustom,
  registerBlock1,
  registerBlock2,
  registerBlock3,
  registerPolicy,
  roadMapBlockGenerete,
} from "./grapesjs-ui";
import {
  addBlockToEditor,
  checkTemplateIsNull,
  confirmMessage,
  convertItemToBlock,
  CustomDisplayPros,
  getIdIndexDB,
  isValidJSON,
  loadDefaultUI,
  reloadAllTemplate,
  saveToIndexDB,
  stringCssObj,
} from "./grapesjs-service";
import { extractKeys } from "../../utils/arrays";
import { useDirty } from "../../contexts/DirtyProvider";
import { usePreventRouteChange } from "../../hooks/usePreventRouteChange";
import { BASE_URL_V2 } from "../../config/api/configApiv2";
import { useStompClient, useSubscription } from "react-stomp-hooks";
import { getDataGeneral } from "../../service/content-course/contenCourseApi";
import {
  optionsSkills
} from "../../components/course-construction/content-course-construction/setting-content-course/condition-register/constants/data";
import FontSizeConfig from "./plugins/font-size";
import { ConnectService } from "../../service/observable/connect";

type Prop = {
  planInfo: PlanInfo;
  detailData: any;
  setIsReadyNextTab: (val: any) => void;
  tab: string;
  mode: string;
};
let saveDraft = false;
declare global {
  interface Window {
    antd: any;
  }
}

interface RefComponentClass {
  updateComponentClass: (value: string) => void;
}

const PageBuilder = ({ planInfo, detailData, setIsReadyNextTab, tab, mode }: Prop) => {
  const replaceComponentClassRef = useRef<RefComponentClass>(null);
  const helpService = new HelpService();
  const connectService = new ConnectService();
  const params = useParams();
  const { setDirty } = useDirty();
  const stompClient = useStompClient();
  const [nameBlock, setNameBlock] = useState('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [contentSummry, setContentSummry] = useState('');
  const [openContentSummryModal, setOpenContentSummryModal] = useState<boolean>(false);
  const [dataTree, setDataTree] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editorCustom, setEditorCustom] = useState<Editor>();
  const [blockUISave, setBlockUISave] = useState<string>('');
  const accessToken = useAuthStore((state) => state.accessToken);
  const [iconVisible, setIconVisible] = useState(false);
  const [isOpenSync, setIsOpenSync] = useState<boolean>(false);
  const [updateTemplateTime, setUpdateTemplateTime] = useState(0);
  const [currentUpdateTemplateTime, setCurrentUpdateTemplateTime] = useState(0);
  const [pageBuilderTemplate, setPageBuilderTemplate] = useState('');
  const [allCourseBlocksUI, setAllCourseBlocksUI] = useState<any>([]);
  const [remainBlocksUI, setRemainBlocksUI] = useState<any>([]);
  const [totalAfterApiDone, setTotalAfterApiDone] = useState<number>(4);
  const [countAfterApiDone, setCountAfterApiDone] = useState<number>(0);
  const [isFirstTimeCallAPiDone, setIsFirstTimeCallAPiDone] = useState(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [isClearToSave, setIsClearToSave] = useState<boolean>(true);

  const treeData: TreeDataNode[] = get(detailData, "sections", []).map(
    (item: {
      name: string;
      key: string;
      orderNumber: number;
      description: string;
      children: { description: string; name: any; key: any; children: any[] }[];
    }) => {
      return {
        title: item.name,
        key: item.key,
        orderNumber: item.orderNumber,
        isUnit: true,
        description: item.description,
        children: item.children.map(
          (child) => {
            return {
              title: child.name,
              key: child.key,
              description: child.description,
            };
          }
        ),
      };
    }
  );

  useEffect(() => {
    if (mode && mode === 'view') {
      setIsView(true);
    } else {
      setIsView(false);
    }
  }, [mode]);

  useEffect(() => {
    connectService.getDataFluent.subscribe((res) => {
      if (!isEmpty(res)) {
        console.log('get', res);
      }
    });
  }, []);

  useEffect(() => {
    setCurrentUpdateTemplateTime(updateTemplateTime);
    setTimeout(function () {
      stompClient?.publish({
        destination: "/topic/course.template." + params?.id,
        body: JSON.stringify({
          action: 'update',
          time: updateTemplateTime
        }),
      });
    }, 100);
  }, [updateTemplateTime]);

  useEffect(() => {
    editorCustom && reloadAllTemplate(editorCustom, pageBuilderTemplate);
  }, [pageBuilderTemplate]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, [treeData]);

  useEffect(() => {
    if (tab === '1' && isFirstTimeCallAPiDone) {
      let getAllRemainBlocks = editorCustom?.BlockManager?.getAll()?.filter((x: Block) => {
        return x.getCategoryLabel() === nameBasicBlock || x.getCategoryLabel() === nameLecturersBlock;
      });
      let finalGetAllRemainBasicBlocks = getAllRemainBlocks?.map((x: Block) => {
        return {
          id: x.getId(),
          label: x.getLabel(),
          content: x.getContent(),
          category: x.getCategoryLabel(),
          media: x.getMedia(),
        };
      });
      setRemainBlocksUI(finalGetAllRemainBasicBlocks);
      editorCustom?.BlockManager.clear();
      addMoocCustomDisplayTypePlugin(editorCustom);
      addDetailTemplatesByCoueseId(editorCustom);
      addMyBlocksToCategories(editorCustom);
    }
  }, [tab]);

  useEffect(() => {
    if (countAfterApiDone === totalAfterApiDone) {
      let finalAllCourseBlocksUI: any[] = allCourseBlocksUI;
      if (remainBlocksUI.length > 0) {
        finalAllCourseBlocksUI = finalAllCourseBlocksUI.concat(remainBlocksUI);
      }
      saveToIndexDB('All_Course_Blocks_UI', finalAllCourseBlocksUI);
      const blockManager = editorCustom?.BlockManager;
      const blockManagerAll = blockManager?.getAll();
      let getAllBlocks: any[] = [];

      addBlockToEditor(blockManager, finalAllCourseBlocksUI, () => {
        blockManagerAll?.forEach((x: Block, indexBlock: Number) => {
          getAllBlocks.push({
            id: x.getId(),
            label: x.getLabel(),
            content: x.getContent(),
            category: x.getCategoryLabel(),
            media: x.getMedia(),
          });
          if (indexBlock === blockManagerAll.length - 1) {
            const isBasicBlock = getAllBlocks.filter((x) => {
              return x.category === nameBasicBlock;
            });
            const isMyBlocks = getAllBlocks.filter((x) => {
              return x.category === nameCategoryBlocks;
            });
            const isMyTemplates = getAllBlocks.filter((x) => {
              return x.category === nameCategoryTemplates;
            });
            const isMyLecturers = getAllBlocks.filter((x) => {
              return x.category === nameLecturersBlock;
            });
            const isContactBlock = getAllBlocks.filter((x) => {
              return x.category === nameContactBlock;
            });
            const isCountBlock = getAllBlocks.filter((x) => {
              return x.category === nameCountBlocks;
            });
            const isRoadMapBlock = getAllBlocks.filter((x) => {
              return x.category === nameRoadMapBlocks;
            });
            const isHeaderBlock = getAllBlocks.filter((x) => {
              return x.category === nameHeaderBlocks;
            });
            const isRegisterBlock = getAllBlocks.filter((x) => {
              return x.category === nameRegisterBlocks;
            });
            const isRegisterPolicyBlock = getAllBlocks.filter((x) => {
              return x.category === nameRegisterPolicyBlocks;
            });
            const isConditionBlock = getAllBlocks.filter((x) => {
              return x.category === nameConditionBlocks;
            });
            const isAffiliatedBlock = getAllBlocks.filter((x) => {
              return x.category === nameAffiliatedBlocks;
            });
            const isOpinionBlock = getAllBlocks.filter((x) => {
              return x.category === nameOpinionBlocks;
            });
            const isQuestionBlock = getAllBlocks.filter((x) => {
              return x.category === nameQuestionBlocks;
            });
            const isOtherBlocks = getAllBlocks.filter((x) => {
              return (
                x.category !== "Basic" &&
                x.category !== nameBasicBlock &&
                x.category !== nameCategoryBlocks &&
                x.category !== nameCategoryTemplates &&
                x.category !== nameContactBlock &&
                x.category !== nameLecturersBlock &&
                x.category !== nameCountBlocks &&
                x.category !== nameRoadMapBlocks &&
                x.category !== nameHeaderBlocks &&
                x.category !== nameRegisterBlocks &&
                x.category !== nameRegisterPolicyBlocks &&
                x.category !== nameConditionBlocks &&
                x.category !== nameAffiliatedBlocks &&
                x.category !== nameOpinionBlocks &&
                x.category !== nameQuestionBlocks
              );
            });
            const isMyUI = [
              ...isMyBlocks,
              ...isMyTemplates,
              ...isContactBlock,
              ...isMyLecturers,
              ...isCountBlock,
              ...isRoadMapBlock,
              ...isHeaderBlock,
              ...isRegisterBlock,
              ...isRegisterPolicyBlock,
              ...isConditionBlock,
              ...isAffiliatedBlock,
              ...isOpinionBlock,
              ...isQuestionBlock,
              ...isBasicBlock,
            ];
            const finalAllBlocks = [
              ...isMyUI,
              // ...isOtherBlocks,
            ];
            blockManager?.clear();
            addBlockToEditor(blockManager, finalAllBlocks);
            setCountAfterApiDone(0);
            setAllCourseBlocksUI([]);
            if (!isFirstTimeCallAPiDone) {
              setIsFirstTimeCallAPiDone(true);
            }

            const categories = blockManager?.getCategories();
            categories?.each(category => {
              category.set('open', false);
            });
          }
        });
      });
    }
  }, [countAfterApiDone]);

  useSubscription("/topic/course.template." + params?.id, (message: any) => {
    let payload = JSON.parse(message.body);
    switch (payload.action) {
      case 'update': {
        const time = payload.time;
        if (time > currentUpdateTemplateTime) {
          helpService.waringMessage('Có bản mới được cập nhật', 5, function () {
            getDetailTemplatesByCoueseId((res: any) => {
              if (!isEmpty(res)) {
                setPageBuilderTemplate(res.template);
              }
            });
          })
          setCurrentUpdateTemplateTime(time);
        }
        break;
      }
    }
  });


  const handleClickSuffix = () => {
    setIconVisible(true);
    setTimeout(() => {
      let random;
      let nameRandom = '';
      while (nameRandom.length < 6) {
        random = Math.floor(Math.random() * 10).toString();
        if (!nameRandom.includes(random)) {
          nameRandom += random;
        }
      }
      setNameBlock("block_" + nameRandom);
    }, 1499);
    setTimeout(() => {
      setIconVisible(false);
    }, 1500);
  };

  const onChangeName = (e: any) => {
    setNameBlock(e.target.value);
  };

  const onChangeContentSummry = (e: any) => {
    setContentSummry(e.target.value);
  };

  const changeName = async () => {
    if (nameBlock !== '') {
      setIconVisible(true);
      const detailDatablock: IBodyBlock = {
        label: nameBlock,
        content: blockUISave,
        courseId: Number(params.id),
      };
      try {
        const apiSaveTemplateBlock = await saveTemplateBlock(detailDatablock);
        const { data } = apiSaveTemplateBlock;
        setIconVisible(false);
        setOpenModal(false);
        setNameBlock('');
        addMyBlocksToCategories(editorCustom);
        helpService.successMessage("Lưu mẫu thành công");
      } catch (error: any) {
        setIconVisible(false);
      }
    } else {
      helpService.errorMessage("Chưa nhập tên mẫu");
    }
  };

  const changeContentSummry = async () => {

  };

  const closeModal = () => {
    setNameBlock('');
    setOpenModal(false);
  };

  const closeContentSummryModal = () => {
    setContentSummry('');
    setOpenContentSummryModal(false);
  };

  const warningSavedDraft = (ed: Editor | undefined, res: any) => {
    Modal.confirm({
      title: "Xác nhận",
      content:
        "Bạn có một bản lưu nháp. Bạn có muốn tiếp tục chỉnh sửa lưu nháp ?",
      className: "modal-custom",
      okButtonProps: {
        className: "btn",
      },
      cancelButtonProps: {
        className: "btn btn-outlined",
      },
      okText: "Tiếp tục chỉnh sửa",
      cancelText: "Không",
      onOk: () => {
        setIsReadyNextTab(true);
        ed?.Components.clear();
        reloadAllTemplate(ed, res.template_draft);
      },
      onCancel: () => {
        setIsReadyNextTab(true);
        if (res.template !== '') {
          ed?.Components.clear();
          reloadAllTemplate(ed, res.template);
        }
      },
    });
  };

  const saveDetailTemplatesByCoueseId = (type: string, ed: Editor) => {
    getIdIndexDB('isClearToSave', (check: any) => {
      if (check) {
        helpService.loadingProcess();
        getIdIndexDB("courseSavedTemplete-" + params?.id, (val: any) => {
          if (val) {
            const detailDataTemplates: IBodyTemplates = {
              template: JSON.stringify(val),
              templateStatusCode: type,
              isTemplateSample: saveDraft,
            };
            saveTemplates(params?.id, detailDataTemplates).then((res) => {
              helpService.closeProcess();
              setDirty(false);
              setUpdateTemplateTime(new Date().getTime());
              helpService.successMessage(res.data.data);
              if (saveDraft) {
                addMyBlocksToCategories(ed);
              }
            });
          }
        });
      } else {
        helpService.errorMessage('Đang bị lỗi trùng tên thẻ định danh !!!');
      }
    });
  };

  const getDetailTemplatesByCoueseId = (cb?: any) => {
    getTemplateByCourseId(params?.id).then((res) => {
      const { data } = res.data;
      cb && cb(data);
    });
  };

  const AdvancedSettingReplace = forwardRef(({ change }: any, ref: Ref<RefComponentClass>) => {
    const [componentClass, setComponentClass] = useState('');
    useImperativeHandle(ref, () => ({ updateComponentClass: updateComponentClass }));

    function updateComponentClass(value: string) {
      setComponentClass(value);
    }

    return (
      <div className="config-view-ctn">
        <div className="gjs-traits-cs gjs-one-bg gjs-two-color">
          <div className="gjs-trait-categories" data-categories=''></div>
          <div className="gjs-traits-empty-c gjs-traits-c" data-no-categories=''>
            <Select
              //showSearch
              value={componentClass}
              style={{
                width: 200,
              }}
              onChange={(event) => {
                change({ event });
                setComponentClass(event);
              }}
              options={[
                {
                  options: [
                    {
                      label: <span>Không sử dụng</span>,
                      value: '',
                    },
                  ],
                },

                {
                  label: <span>Khóa học</span>,
                  title: 'Khóa học',
                  options: [
                    {
                      label: <span>Giá của khóa học</span>,
                      value: 'CoursePriceText',
                    },
                    {
                      label: <span>Nút bấm thẻ ghi danh</span>,
                      value: 'CourseTagAction',
                    },
                    {
                      label: <span>Thời gian</span>,
                      value: 'CourseTime',
                    },
                  ],
                },
                {
                  label: <span>Giảng viên</span>,
                  title: 'Giảng viên',
                  options: [
                    {
                      label: <span>Tên tác giả</span>,
                      value: 'TeacherName',
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  });

  //cài đặt hoạt động custom
  const addMoocCustomDisplayTypePlugin = (editor: Editor | undefined) => {

    editor?.StyleManager.addType("advanced-setting-replace", {
      create: function ({ props, createdEl, change }) {
        let p = props as CustomDisplayPros;
        const el = document.createElement("div");
        el.classList.add("config-view");
        const root = createRoot(el);
        root.render(<AdvancedSettingReplace change={change} ref={replaceComponentClassRef} />);
        return el;
      },
      emit({ }, { event }) {
        const selectedComponent = editor.getSelected();
        if (!event) {
          selectedComponent?.removeAttributes('component-class');
        } else {
          selectedComponent?.addAttributes({ 'component-class': event });
        }
      },
      update() {
      },
      destroy() {
      },
    });
    editor?.StyleManager.addType("advanced-setting-route", {
      create({ props, createdEl, change }) {
        const el = document.createElement("div");
        el.classList.add("tree-select-container");
        el.setAttribute("id", "tree-select-container");
        const root = createRoot(el);
        root.render(<MyDataTree editor={editor} change={change} />);
        return el;
      },
      emit({ props, updateStyle }, { event }, info) {
        const { node } = info;
        const selectedComponent = editor.getSelected();
        let componentText = selectedComponent?.getEl();

        const findItemByKey: any = (dataArray: string | any[], key: any) => {
          // Iterate over the array to find the item with the matching key
          for (let i = 0; i < dataArray.length; i++) {
            const item = dataArray[i];
            if (item.key === key) {
              return item;
            }
            // If the item has children, recursively search through them
            if (item.children) {
              // @ts-ignore
              const foundItem = findItemByKey(item.children, key);
              if (foundItem) {
                return foundItem;
              }
            }
          }
          return null;
        };

        // @ts-ignore
        function containsTitles(obj: TreeDataNode, title: any) {
          // Base case: If obj is null or undefined, return false
          if (obj.title === title) {
            return obj;
          }
          if (obj.children && obj.children.length) {
            for (let i = 0; i < obj.children.length; i++) {
              // @ts-ignore
              const result = containsTitles(obj.children[i], title);
              if (result) {
                return result;
              }
            }
          }
          return null;
        }

        // @ts-ignore
        if (
          selectedComponent &&
          componentText &&
          selectedComponent?.attributes.type === "text"
        ) {
          const titleData: TreeDataNode | undefined = treeData.find((item) =>
            event.includes(item.key)
          );

          if (titleData && titleData.title) {
            componentText.innerText = titleData.title as string;
          }
          if (!titleData) {
            componentText.innerText = '';
          }
        }
        if (
          selectedComponent &&
          selectedComponent.components().length >= 2 &&
          event.length > 1
        ) {
          selectedComponent.getChildAt(1).forEachChild((child) => {
            let elementTitleStyle = selectedComponent
              ?.getChildAt(0)
              .getEl()?.style;
            let elementChildStyle = selectedComponent
              ?.getChildAt(1)
              .getEl()?.style;
            const elementTitle = selectedComponent?.getChildAt(0).getEl();
            let element = child.getEl();
            if (elementTitleStyle && elementChildStyle) {
              elementTitleStyle.display = "block";
              elementChildStyle.display = "block";
            }
            if (element && child.attributes.type === "text") {
              event.forEach((key: any, index: number) => {
                if (elementTitle?.tagName === "H2") {
                  if (info.checked && node.orderNumber) {
                    elementTitle.style.display = "block";
                    elementTitle.textContent = node.title;
                  }
                  if (!info.checked && node.orderNumber) {
                    elementTitle.style.display = "none";
                  }
                }
                if (element?.tagName === "H3") {
                  const el = selectedComponent?.getChildAt(1).find("h3");
                  el?.forEach((elt: any, num: any) => {
                    if (info.checked) {
                      elt.getEl().style.display = "block";
                    }
                    if (
                      info.checked &&
                      !node.orderNumber &&
                      elt.getEl().textContent === node.title
                    ) {
                      elt.getEl().style.display = "block";
                    }
                    if (
                      !info.checked &&
                      !node.orderNumber &&
                      elt.getEl().textContent === node.title
                    ) {
                      elt.getEl().style.display = "none";
                    }
                  });
                }
              });
            }
          });
        }
        if (
          selectedComponent &&
          selectedComponent.components().length >= 2 &&
          event.length === 0
        ) {
          selectedComponent.getChildAt(0).forEachChild((child) => {
            let elementTitleStyle = selectedComponent
              ?.getChildAt(0)
              .getEl()?.style;
            let elementStyle = selectedComponent?.getChildAt(1).getEl()?.style;
            if (elementStyle && elementTitleStyle) {
              elementTitleStyle.display = "none";
              elementStyle.display = "none";
            }
          });
        }
      },
      update({ value, el }) {
      },
      destroy() {
      },
    });
  };

  const MyDataTree = ({ editor, change }: { editor: Editor; change: any }) => {
    const initialData = extractKeys(treeData);
    const checkValueData = (event: any, info: any) => {
      change({ event }, info);
    };

    return (
      <>
        {treeData.length > 0 ? (
          <Tree
            checkable
            treeData={treeData}
            defaultCheckedKeys={initialData}
            onCheck={(event, info) => checkValueData(event, info)}
            onSelect={(event) => change({ event })}
          />
        ) : (
          <>
            <span>Khóa học chưa có lộ trình</span>
          </>
        )}
      </>
    );
  };

  const addDetailTemplatesByCoueseId = (editor: Editor | undefined) => {
    setDirty(false);
    getDetailTemplatesByCoueseId((res: any) => {
      if (!isEmpty(res)) {
        const checkUIIsNull = checkTemplateIsNull(editor);
        if (checkUIIsNull && res.template === null && res.template_draft === null && res.template_status_code === null) {
          loadDefaultUI(editor);
          setIsReadyNextTab(true);
        } else if (res.template_draft !== null && res.template_status_code === "published") {
          warningSavedDraft(editor, res);
        } else {
          reloadAllTemplate(editor, res.template);
          setIsReadyNextTab(true);
        }
      }
    });
  };
  const addMyBlocksToCategories = async (editor: Editor | undefined) => {
    try {
      let customCourseBlocksUI: any[] = [];
      saveToIndexDB('All_Course_Blocks_UI', []);

      if (treeData.length > 0) {
        const data = roadMapBlockGenerete(treeData);
        convertItemToBlock(customCourseBlocksUI, data);
        afterCallApiDone(editor, customCourseBlocksUI);
      } else {
        const data = roadMapBlockGenerete([]);
        convertItemToBlock(customCourseBlocksUI, data);
        afterCallApiDone(editor, customCourseBlocksUI);
      }

      const [apiGetTemplateCategory, apiGetStudentRegisterCourse, apiGetReviewCourse, apiPostCourseSearch, apiGetDataGeneral] = await Promise.all([
        getTemplateCategory(),
        getStudentRegisterCourse(get(detailData, "id", 0)),
        getReviewCourse(get(detailData, "id", 0)),
        postCourseSearch({ pageSize: 0 }),
        getDataGeneral(get(detailData, "id", 0))
      ]);
      const dataBlocks = apiGetTemplateCategory.data;
      if (dataBlocks) {
        let customCourseBlocksUI: any[] = [];
        dataBlocks.data.forEach((x: any) => {
          if (x.is_template === 0) {
            x.category = nameCategoryBlocks;
          }
          if (x.is_template === 1) {
            x.category = nameCategoryTemplates;
          }
          const templateContent = x.content;
          if (templateContent !== null && isValidJSON(templateContent)) {
            const blockUI = JSON.parse(templateContent);
            x.html = blockUI.html;
            x.css = blockUI.css;
          }
          convertItemToBlock(customCourseBlocksUI, x);
        });
        convertItemToBlock(customCourseBlocksUI, questionBlockCustom());
        convertItemToBlock(customCourseBlocksUI, opinionBlockCustom());
        convertItemToBlock(customCourseBlocksUI, contactBlockCustom());
        convertItemToBlock(customCourseBlocksUI, headerBlockLogo());
        convertItemToBlock(customCourseBlocksUI, headerBlockName());
        convertItemToBlock(customCourseBlocksUI, registerBlock1());
        convertItemToBlock(customCourseBlocksUI, registerBlock2());
        convertItemToBlock(customCourseBlocksUI, registerBlock3());
        convertItemToBlock(customCourseBlocksUI, registerPolicy());
        convertItemToBlock(customCourseBlocksUI, affiliatedBlock());
        afterCallApiDone(editor, customCourseBlocksUI);
      }

      const dataCountStudents = apiGetStudentRegisterCourse.data;
      const dataCountReviews = apiGetReviewCourse.data;
      if (dataCountStudents && dataCountReviews) {
        convertItemToBlock(customCourseBlocksUI, countBlockGenerete(dataCountReviews, dataCountStudents));
        afterCallApiDone(editor, customCourseBlocksUI);
      } else {
        convertItemToBlock(customCourseBlocksUI, countBlockGenerete({}, {}));
        afterCallApiDone(editor, customCourseBlocksUI);
      }

      const dataAllOptionCourse = apiPostCourseSearch.data.data.data.filter((data: any) => data.status === 13);
      const preCourses = apiGetDataGeneral.data.data.preCourses;
      const preSkills = apiGetDataGeneral.data.data.preSkills;
      let preRenderSkills: any = [];
      let preRenderCourse: any = [];
      if (preCourses && preSkills) {
        optionsSkills.forEach((x: any) => {
          preSkills.forEach((y: any) => {
            if (y === x.code) {
              preRenderSkills.push(x.label);
            }
          });
        });
        dataAllOptionCourse.forEach((x: any) => {
          preCourses.forEach((y: any) => {
            if (y === x.code) {
              preRenderCourse.push(x.name);
            }
          });
        });
        convertItemToBlock(customCourseBlocksUI, conditionBlockGenerete(preRenderCourse, preRenderSkills));
        afterCallApiDone(editor, customCourseBlocksUI);
      } else {
        convertItemToBlock(customCourseBlocksUI, conditionBlockGenerete([], []));
        afterCallApiDone(editor, customCourseBlocksUI);
      }
    } catch {
    }
  };

  const afterCallApiDone = (editor: Editor | undefined, data: any[]) => {
    setCountAfterApiDone(x => x + 1);
    setAllCourseBlocksUI((x: any) => x.concat(data));
  };

  const syncData = () => {
    setIsOpenSync(true);
  };

  const handleCancel = () => {
    setIsOpenSync(false);
  };

  function MyComponentWithUseEditor() {
    const editor = useEditor();
    const defaultValue = extractKeys(treeData);
    const [selectValue, setSelectValue] = useState<Key[] | { checked: Key[]; halfChecked: Key[] }>(defaultValue);
    const [checkValue, setCheckValue] = useState<Key[] | { checked: Key[]; halfChecked: Key[] }>([]);
    const [valueNodes, setValueNodes] = useState<any[]>([]);

    const [infoSelect, setInfoSelect] = useState({});
    const [isDisabledSync, setIsDisabledSync] = useState(false);

    // Lọc ra checkedNodes dưới dạng treeData
    function filterTreeData(treeData: any, checkedNodes: any) {
      const checkedKeysInCheckNodes = new Set(
        checkedNodes.map((node: any) => node.key)
      );

      function filterNodes(nodes: any) {
        return nodes.map((node: any) => {
          if (node.children) {
            const filteredChildren = filterNodes(node.children).filter(
              (child: any) => checkedKeysInCheckNodes.has(child.key)
            );
            return {
              ...node,
              children: filteredChildren,
            };
          }
          return node;
        });
      }

      return filterNodes(treeData);
    }

    const handleSync = () => {
      const component = editor.Components.getComponent();
      const roadmap = component?.getEl()?.querySelector("#roadmap-1");
      const sectionUnitOld = component?.getEl()?.querySelectorAll('div[name="section-unit"]');

      const sectionUnitEl = component?.getEl()?.querySelector('div[name="section-unit"]') as HTMLElement;

      if (infoSelect && valueNodes.length > 0) {
        // Thêm chương khi đồng bộ
        if (roadmap && sectionUnitOld && sectionUnitOld.length < valueNodes.length) {
          // Chênh lệch số lượng của khối hiện tại và số lượng bài chương đồng bộ
          const checkDiff = valueNodes.length - sectionUnitOld.length;
          for (let i = 0; i < checkDiff; i++) {
            if (sectionUnitEl) {
              // Sao chép nội dung và các thuộc tính từ elLi
              const contentHtml = sectionUnitEl.innerHTML;
              const newComponent = editor.addComponents(`<div>${contentHtml}</div>`);

              if (newComponent && newComponent.length > 0) {
                const component = newComponent[0];
                const componentEl = component.getEl();

                if (componentEl instanceof HTMLElement) {
                  // Sao chép lớp CSS và thuộc tính inline từ elLi
                  if (sectionUnitEl.className) {
                    componentEl.className = sectionUnitEl.className;
                  }
                  componentEl.style.cssText = sectionUnitEl.style.cssText;

                  // Thêm thuộc tính name cho div được clone
                  componentEl.setAttribute('name', 'section-unit');

                  // Thêm vào <div> trong DOM
                  if (roadmap) {
                    roadmap.appendChild(componentEl);
                  } else {
                    console.error('No <div> found in DOM');
                  }
                } else {
                  console.error('Component element is undefined');
                }
              }
            }
          }
        }

        const titleEl = component?.getEl()?.querySelectorAll(".bg-white > .text-xl");
        const titleUnit = component?.getEl()?.querySelectorAll("#roadmap-1 h2");
        const sectionUnit = component?.getEl()?.querySelectorAll('div[name="section-unit"]');
        const sectionContent = component?.getEl()?.querySelectorAll("h3[name='roadmap-lesson']");
        const sectionChildUnit = component?.getEl()?.querySelectorAll('div[name="section-unit"]');
        let saveLi: any; // Biến lưu trữ thẻ Li trường hợp không có bài giảng

        // @ts-ignore
        titleEl.forEach((el, i) => {
          valueNodes.map((elChild: any, index: number) => {
            if (index === i) {
              el.textContent = elChild.title;
            }
          });
        });
        titleUnit &&
          titleUnit.forEach((el1, i) => {
            valueNodes.map((elChild: any, index: number) => {
              if (index === i) {
                el1.textContent = elChild.title;
              }
            });
          });
        // @ts-ignore
        sectionUnit.forEach((el, i0) => {
          if (treeData[i0]) {
            // @ts-ignore
            el["style"].display = 'block'
          } else {
            // @ts-ignore
            el["style"].display = 'none'
          }
          // @ts-ignore
          sectionChildUnit.forEach((el1, i1) => {
            if (i0 === i1) {
              const elUl = el1.querySelector("div[name='section-unit'] ul");
              if (el1.querySelector("li")) {
                saveLi = el1.querySelector("li");
              }
              const elLi = saveLi;

              valueNodes.map((item: any, indexTree) => {
                // Thêm bài giảng khi đồng bộ
                if (elUl && elUl.querySelectorAll("li").length < item.children.length) {
                  // Chênh lệch số lượng của khối hiện tại và số lượng bài giảng đồng bộ
                  const checkDiff = item.children.length - elUl.querySelectorAll("li").length;
                  for (let i = 0; i < checkDiff; i++) {
                    if (elLi) {
                      // Sao chép nội dung và các thuộc tính từ elLi
                      const contentHtml = elLi.innerHTML;
                      const newComponent = editor.addComponents(`<li>${contentHtml}</li>`);

                      if (newComponent && newComponent.length > 0) {
                        const component = newComponent[0];
                        const componentEl = component.getEl();

                        if (componentEl) {
                          // Sao chép lớp CSS và thuộc tính inline từ elLi
                          if (elLi.className) {
                            componentEl.className = elLi.className;
                          }
                          componentEl.style.cssText = elLi.style.cssText;

                          // Thêm vào <ul> trong DOM
                          if (elUl) {
                            elUl.appendChild(componentEl);
                          } else {
                            console.error('No <ul> found in DOM');
                          }
                        } else {
                          console.error('Component element is undefined');
                        }
                      } else {
                        console.error('Failed to create new component');
                      }
                    }
                  }
                }

                // Cài hiển thị những khối không được đồng bộ
                if (i1 + 1 > valueNodes.length) {
                  if (el1 instanceof HTMLElement) {  // Kiểm tra nếu el1 là một phần tử HTML
                    el1.style.display = "none";
                  } else if (el1 instanceof HTMLElement) {
                    el1.style.display = "flex";
                  }
                }

                const listEl = el1.querySelectorAll("li");
                if (indexTree === i1) {
                  // Loại bỏ những li không được đồng bộ
                  listEl.forEach((el, i) => {
                    if (i + 1 > item.children.length) {
                      el["style"].display = "none";
                    } else {
                      el["style"].display = "flex";
                    }
                  })

                  const headingEl = el1.querySelectorAll("h3[name='title-lesson']");
                  const contentEl = el1.querySelectorAll("[class='text-gray-700']");

                  headingEl.forEach((el2, i2) => {
                    item.children?.map((child: any, index: number) => {
                      if (i2 === index) {
                        el2.textContent = child.title;
                      }
                    });
                    if (item.children?.length === 0) {
                      el2.textContent = '';
                    }
                  });
                  contentEl.forEach((el2, i2) => {
                    item.children?.map((child: any, index: number) => {
                      if (i2 === index) {
                        el2.textContent = child.description ? child.description : 'Chưa có mô tả';
                      }
                    });
                    if (item.children?.length === 0) {
                      el2.textContent = '';
                    }
                  });
                }
              });
            }
          });
        });
        sectionContent?.forEach((el2, i) => {
          if (valueNodes.length > 0) {
            valueNodes.map((el: any, index: number) => {
              if (index === i) {
                el2.textContent = el.title;
              }
            });
          }
        });
        message.success("Đồng bộ thành công");
        setIsOpenSync(false);
      } else {
        // Thêm chương khi đồng bộ
        if (roadmap && sectionUnitOld && sectionUnitOld.length < treeData.length) {
          // Chênh lệch số lượng của khối hiện tại và số lượng bài chương đồng bộ
          const checkDiff = treeData.length - sectionUnitOld.length;
          for (let i = 0; i < checkDiff; i++) {
            if (sectionUnitEl) {
              // Sao chép nội dung và các thuộc tính từ elLi
              const contentHtml = sectionUnitEl.innerHTML;
              const newComponent = editor.addComponents(`<div>${contentHtml}</div>`);

              if (newComponent && newComponent.length > 0) {
                const component = newComponent[0];
                const componentEl = component.getEl();

                if (componentEl) {
                  // Sao chép lớp CSS và thuộc tính inline từ elLi
                  if (sectionUnitEl.className) {
                    componentEl.className = sectionUnitEl.className;
                  }
                  componentEl.style.cssText = sectionUnitEl.style.cssText;

                  // Thêm thuộc tính name cho div được clone
                  componentEl.setAttribute('name', 'section-unit');

                  // Thêm vào <div> trong DOM
                  if (roadmap) {
                    roadmap.appendChild(componentEl);
                  } else {
                    console.error('No <div> found in DOM');
                  }
                } else {
                  console.error('Component element is undefined');
                }
              }
            }
          }
        }

        const titleEl = component?.getEl()?.querySelectorAll(".bg-white > .text-xl");
        const titleUnit = component?.getEl()?.querySelectorAll("#roadmap-1 h2");
        const sectionUnit = component?.getEl()?.querySelectorAll('div[name="section-unit"]');
        const sectionContent = component?.getEl()?.querySelectorAll("h3[name='roadmap-lesson']");
        const sectionChildUnit = component?.getEl()?.querySelectorAll('div[name="section-unit"]');
        let saveLi: any; // Biến lưu trữ thẻ Li trường hợp không có bài giảng

        // @ts-ignore
        titleEl.forEach((el, i) => {
          treeData.map((elChild: any, index: number) => {
            if (index === i) {
              el.textContent = elChild.title;
            }
          });
        });
        titleUnit &&
          titleUnit.forEach((el1, i) => {
            treeData.map((elChild: any, index: number) => {
              if (index === i) {
                el1.textContent = elChild.title;
              }
            });
          });
        // @ts-ignore
        sectionUnit.forEach((el, i0) => {
          if (treeData[i0]) {
            // @ts-ignore
            el["style"].display = 'block'
          } else {
            // @ts-ignore
            el["style"].display = 'none'
          }
          // @ts-ignore
          sectionChildUnit.forEach((el1, i1) => {
            if (i0 === i1 && treeData[i0]) {
              const elUl = el1.querySelector("div[name='section-unit'] ul");
              if (el1.querySelector("li")) {
                saveLi = el1.querySelector("li");
              }
              const elLi = saveLi;

              treeData.map((item: any, indexTree) => {
                // Thêm bài giảng khi đồng bộ
                if (elUl && elUl.querySelectorAll("li").length < item.children.length) {
                  // Chênh lệch số lượng của khối hiện tại và số lượng bài giảng đồng bộ
                  const checkDiff = item.children.length - elUl.querySelectorAll("li").length;
                  for (let i = 0; i < checkDiff; i++) {
                    if (elLi) {
                      // Sao chép nội dung và các thuộc tính từ elLi
                      const contentHtml = elLi.innerHTML;
                      const newComponent = editor.addComponents(`<li>${contentHtml}</li>`);

                      if (newComponent && newComponent.length > 0) {
                        const component = newComponent[0];
                        const componentEl = component.getEl();

                        if (componentEl) {
                          // Sao chép lớp CSS và thuộc tính inline từ elLi
                          if (elLi.className) {
                            componentEl.className = elLi.className;
                          }
                          componentEl.style.cssText = elLi.style.cssText;

                          // Thêm vào <ul> trong DOM
                          if (elUl) {
                            elUl.appendChild(componentEl);
                          } else {
                            console.error('No <ul> found in DOM');
                          }
                        } else {
                          console.error('Component element is undefined');
                        }
                      } else {
                        console.error('Failed to create new component');
                      }
                    }
                  }
                }

                const listEl = el1.querySelectorAll("li");

                if (indexTree === i0) {
                  // Loại bỏ những li không được đồng bộ
                  listEl.forEach((el, i) => {
                    if (i + 1 > item.children.length) {
                      el["style"].display = "none";
                    } else {
                      el["style"].display = "flex";
                    }
                  })

                  const headingEl = el1.querySelectorAll("h3[name='title-lesson']");
                  const contentEl = el1.querySelectorAll("p.text-gray-700");

                  headingEl.forEach((el2, i2) => {
                    item.children.map((child: any, index: number) => {
                      if (i2 === index) {
                        el2.textContent = child.title;
                      }
                    });
                    if (item.children.length === 0) {
                      el2.textContent = '';
                    }
                  });
                  contentEl.forEach((el3, i3) => {
                    item.children.map((child: any, index: number) => {
                      if (i3 === index) {
                        el3.textContent = child.description ? child.description : 'Chưa có mô tả';
                      }
                    });
                    if (item.children.length === 0) {
                      el3.textContent = '';
                    }
                  })
                }
              });
            }
          });
        });
        sectionContent?.forEach((el2, i) => {
          if (valueNodes.length > 0) {
            dataTree.map((el: any, index: number) => {
              if (index === i) {
                return (el2.textContent = el.title);
              } else {
                return (el2.textContent = '');
              }
            });
          }
        });
        message.success("Đồng bộ thành công");
        setIsOpenSync(false);
      }
    };

    const handleCheck: TreeProps["onCheck"] = (checkedKeys, info) => {
      const { checkedNodes } = info;
      setSelectValue(checkedKeys);
      setInfoSelect(info);
      setIsDisabledSync(false);
      // @ts-ignore
      setValueNodes(checkedNodes.filter((node) => node.isUnit).sort((a, b) => a.orderNumber - b.orderNumber));
      if (isArray(checkedKeys) && checkedKeys.length === 0) {
        setIsDisabledSync(true);
      }
      const result = filterTreeData(treeData, checkedNodes);
      setValueNodes(result.filter((node: any) => node.children.length > 0));
    };

    const onCheckAll = (checked: boolean) => {
      if (checked) {
        // Collect all keys and info when selecting all
        const allKeys:
          | any[]
          | { checked: Key[]; halfChecked: Key[] }
          | ((
            prevState:
              | Key[]
              | {
                checked: Key[];
                halfChecked: Key[];
              }
          ) => Key[] | { checked: Key[]; halfChecked: Key[] }) = [];
        const allInfo: any[] = [];

        const collectKeysAndInfo = (nodes: any[]) => {
          nodes.forEach((node) => {
            allKeys.push(node.key);
            allInfo.push(node); // Assuming you want all node info
            if (node.children) {
              collectKeysAndInfo(node.children);
            }
          });
        };

        collectKeysAndInfo(treeData);
        setValueNodes(allInfo.filter((node) => node.isUnit));
        setSelectValue(allKeys);
        setIsDisabledSync(false);
      } else {
        setSelectValue([]);
        setIsDisabledSync(true);
      }
    };
    return (
      <>
        <Modal
          open={isOpenSync}
          title="Đồng bộ"
          okText="Đồng bộ"
          cancelText="Huỷ"
          onCancel={handleCancel}
          onOk={handleSync}
          okButtonProps={{
            disabled: isDisabledSync || treeData.length <= 0,
          }}
        >
          <>
            {treeData.length > 0 ? (
              <>
                <div className="select-data">
                  {isArray(selectValue) && selectValue?.length > 0 ? (
                    <a onClick={() => onCheckAll(false)}>Bỏ chọn tất cả</a>
                  ) : (
                    <a onClick={() => onCheckAll(true)}>Chọn tất cả</a>
                  )}
                </div>
                <div className="sync-data--tree">
                  <Tree
                    checkable
                    treeData={treeData}
                    defaultSelectedKeys={defaultValue}
                    defaultCheckedKeys={defaultValue}
                    defaultExpandedKeys={defaultValue}
                    height={233}
                    onCheck={handleCheck}
                    checkedKeys={selectValue}
                  />
                </div>
              </>
            ) : (
              <span>Khóa học chưa có lộ trình để đồng bộ</span>
            )}
          </>
        </Modal>
      </>
    );
  }

  const onEditor = (editor: Editor) => {
    setEditorCustom(editor);
    const panelManager = editor.Panels;
    const panelDevicesResponsiveId = "devices-c";
    const panelOpntionsId = "options";
    const panelViewsId = "views";
    const commandToAdd = "tlb-save-block";
    // panelManager.removeButton(panelOpntionsId, 'export-template');
    // panelManager.removeButton(panelOpntionsId, 'gjs-open-import-webpage');
    // panelManager.removeButton(panelViewsId, 'open-layers');
    let titles = document.querySelectorAll("*[title]");
    for (let i = 0; i < titles.length; i++) {
      let el = titles[i];
      let title = el.getAttribute("title");
      title = title ? title.trim() : '';
      if (!title) break;
      el.setAttribute("data-tooltip", title);
      el.setAttribute("title", '');
      el.setAttribute("data-tooltip", '');
    }
    panelManager.getButton(panelOpntionsId, "sw-visibility")?.set("active", 1);
    const optionBtns = [
      ["sw-visibility", "Xem thành phần"],
      ["preview", "Xem trước"],
      ["fullscreen", "Toàn màn hình"],
      ["export-template", "Xem mã nguồn"],
      ["undo", "Hoàn tác"],
      ["redo", "Làm lại"],
      ["gjs-open-import-webpage", "Nhập mã nguồn"],
      ["canvas-clear", "Xóa"],
    ];

    const viewBtns = [
      ["open-sm", "Quản lý định dạng"],
      ["open-tm", "Cài đặt"],
      ["open-layers", "Quản lý lớp"],
      ["open-blocks", "Chọn mẫu"],
    ];

    optionBtns.forEach((item, i) => {
      panelManager.getButton(panelOpntionsId, item[0])?.set("attributes", {
        title: item[1],
        "data-tooltip": item[1],
        "data-tooltip-pos": "top",
      });
    });

    viewBtns.forEach((item, i) => {
      if (i === (viewBtns.length - 1)) {
        panelManager.getButton(panelViewsId, item[0])?.set("attributes", {
          title: item[1],
          "data-tooltip": item[1],
          "data-tooltip-pos": "left",
        });
      } else {
        panelManager.getButton(panelViewsId, item[0])?.set("attributes", {
          title: item[1],
          "data-tooltip": item[1],
          "data-tooltip-pos": "top",
        });
      }

    });

    panelManager.addButton(panelOpntionsId, {
      id: "save-all-template-draft",
      className: "gjs-btn-custom gjs-pn-btn-save-draft",
      active: false,
      attributes: {
        title: '',
        "data-tooltip": "Lưu nháp",
        "data-tooltip-pos": "top",
      },
      command() {
        confirmMessage(
          "Lưu nháp",
          1,
          "Bạn có chắc muốn lưu nháp không?",
          () => {
            saveDetailTemplatesByCoueseId("draft", editor);
          }
        );
      },
    });

    panelManager.addButton(panelOpntionsId, {
      id: "save-all-template",
      className: "gjs-btn-custom gjs-pn-btn-save",
      active: false,
      attributes: {
        title: '',
        "data-tooltip": "Lưu",
        "data-tooltip-pos": "top",
      },
      command() {
        saveDraft = false;
        confirmMessage("Xác nhận lưu giao diện trình bày?",
          1,
          '',
          () => {
            saveDetailTemplatesByCoueseId("published", editor);
          },
          () => {
          },
          true,
          () => {
            saveDetailTemplatesByCoueseId("draft", editor);
            Modal.destroyAll();
          },
          () => {
            saveDraft = !saveDraft;
          }
        );
      },
    });

    editor.on("component:selected", (res) => {
      if (res.attributes.tagName === 'summary') {
        setOpenContentSummryModal(true);
      }
      if (mode === 'view') {
        res.set({
          editable: false,
          resizable: false
        });

        // Prevent deletion
        // Override the remove method to prevent deletion
        res.remove = function () {
          return this;
        }
      }
      const componentClass = res?.getAttributes()['component-class'];
      replaceComponentClassRef.current?.updateComponentClass(componentClass);
      const settingsRoute = document.querySelector('.gjs-sm-property__settings-route');
      if (componentClass === 'RoadMapWrapperComponent' || componentClass === 'RoadMapComponent') {
        settingsRoute?.classList.remove('h-0');
        settingsRoute?.classList.remove('overflow-hidden');
      } else {
        settingsRoute?.classList.add('h-0');
        settingsRoute?.classList.add('overflow-hidden');
      }
      const toolTipTextAction = [
        'In đậm', 'In nghiêng', 'Gạch chân', 'Gạch ngang', 'Link', 'Chỉnh sửa'
      ]
      const getAllActionIcon = document.querySelectorAll('.gjs-rte-action')
      getAllActionIcon.forEach((item, i) => {
        const itemHTML = (item as HTMLInputElement);
        itemHTML.setAttribute('data-tooltip', toolTipTextAction[i])
      })

      const selectedComponent = editor.getSelected();
      if (selectedComponent) {
        let displayIdElm = document.getElementById("display-for-id");
        if (displayIdElm) {
          (displayIdElm as HTMLInputElement).value = res?.ccid;
        }
        let displayIdNameElm = document.getElementById("display-for-name");
        if (displayIdNameElm && res.attributes.name) {
          (displayIdElm as HTMLInputElement).value = res?.attributes?.name;
        }
      }
      const defaultToolbar = selectedComponent?.get("toolbar") || [];
      const commandExists = defaultToolbar?.some((item) => item.command === commandToAdd);
      if (res.attributes.tagName !== "body") {
        if (!commandExists) {
          res?.set({
            toolbar: [
              ...defaultToolbar,
              {
                label: '<svg viewBox="0 0 24 24"><path fill="currentColor" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"></path></svg>',
                attributes: { title: "Lưu khối giao diện" },
                command: commandToAdd,
              },
            ],
          });
        }
      }
      let displaySettingEl = document.querySelector<HTMLDivElement>(".gjs-sm-sector__settings_operations");
      if (res.attributes.tagName === "div" && res.attributes.attributes.title === "roadmap") {
        if (!commandExists) {
          res?.set({
            toolbar: [
              ...defaultToolbar,
              {
                label: `<svg id="Layer_1" style="enable-background:new 0 0 128 128;" version="1.1" viewBox="0 0 128 128" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g>
                    <path d="M96.1,103.6c-10.4,8.4-23.5,12.4-36.8,11.1c-10.5-1-20.3-5.1-28.2-11.8H44v-8H18v26h8v-11.9c9.1,7.7,20.4,12.5,32.6,13.6   c1.9,0.2,3.7,0.3,5.5,0.3c13.5,0,26.5-4.6,37-13.2c19.1-15.4,26.6-40.5,19.1-63.9l-7.6,2.4C119,68.6,112.6,90.3,96.1,103.6z"/><path d="M103,19.7c-21.2-18.7-53.5-20-76.1-1.6C7.9,33.5,0.4,58.4,7.7,81.7l7.6-2.4C9,59.2,15.5,37.6,31.9,24.4   C51.6,8.4,79.7,9.6,98,26H85v8h26V8h-8V19.7z"/>
                  </g>
                </svg>`,
                attributes: { title: "Đồng bộ" },
                command: syncData,
              },
              {
                label: `<svg viewBox="0 0 24 24">
                  <path fill="currentColor" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z"></path>
                </svg>`,
                attributes: { title: "Lưu" },
                command: commandToAdd,
              },
            ],
          });
        }
        if (displaySettingEl && res.getChildAt(1)) {
          displaySettingEl.style.display = "block";
        }
      }
      if (
        res.attributes.tagName === "section" &&
        res.attributes.attributes.name !== "roadmap"
      ) {
        if (displaySettingEl) {
          displaySettingEl.style.display = "none";
        }
      }
    });

    editor.on("update", (e) => {
      setTimeout(() => {
        const courseSavedTempalte = {
          id: params?.id,
          html: editor.getHtml(),
          css: editor.getCss(),
        };
        saveToIndexDB("courseSavedTemplete-" + params?.id, courseSavedTempalte);
      }, 333);
    });

    editor.on("block:drag:start", (block) => {
      getIdIndexDB('All_Course_Blocks_UI', (val: any[]) => {
        const blockSelected = val.find((x) => x.id === block.id);
        if (blockSelected) {
          editor.addStyle(blockSelected.css);
        }
      });
    });

    editor.on("block:drag:stop", (component, block) => {
      if (component !== null) {
        editor.select(component);
        const btnStyleManger = panelManager.getButton(panelViewsId, "open-sm");
        btnStyleManger && btnStyleManger.set("active", 1);
      }
    });

    editor.on("component:drag:end", (component) => {
      if (mode === 'view') {
        editor.UndoManager.undo();
        return;
      }
    });

    editor.on('component:update', (e) => {
      setDirty(true);
    });

    editor.Commands.add("canvas-clear", (ed: Editor) => {
      confirmMessage(
        "Xóa toàn bộ",
        0,
        "Bạn có chắc muốn xóa toàn bộ thành phần không?",
        () => {
          ed.Css.clear();
          ed.Components.clear();
        }
      );
    });

    editor.Commands.add("tlb-delete", (ed: Editor) => {
      confirmMessage(
        "Xóa thành phần",
        0,
        "Bạn có chắc muốn xóa thành phần này không?",
        () => {
          helpService.successMessage("Thao tác thành công");
          ed.getSelected()?.destroy();
        }
      );
    });

    editor.Commands.add(commandToAdd, (ed: Editor) => {
      const elementSelected = ed.getSelected();
      const blockHTML = elementSelected?.toHTML();
      const itSelfStyle = elementSelected?.getStyle();
      const itSelfId = elementSelected?.getId();
      let blockCss = '';
      if (!isEmpty(itSelfStyle)) {
        blockCss += `#${itSelfId}{${stringCssObj(itSelfStyle)}}`;
      }
      elementSelected?.forEachChild((x) => {
        const idChildEle = x.ccid;
        const styleChildEle = x.getStyle();
        if (!isEmpty(styleChildEle)) {
          blockCss += `#${idChildEle}{${stringCssObj(styleChildEle)}}`;
        }
      });
      const blockUI = JSON.stringify({ html: blockHTML, css: blockCss });
      setEditorCustom(ed);
      setBlockUISave(blockUI);
      setOpenModal(true);
    });

    editor.Commands.add("preview", (ed: Editor) => {
      window.open(routesConfig.pageBuilderView + "/" + params?.id, "_blank", "noreferrer");
    });

    editor.on('asset:upload:response', (response) => {
      helpService.successMessage('Thao tác thành công');
    });

    editor.on('asset:upload:error', (error) => {
      helpService.errorMessage('Thao tác không thành công, có lỗi xảy ra !!!');
    });

    editor.on('component:add', (model) => {
      const attrs = model.getAttributes();
      if (attrs.id && !attrs.id.includes('rd')) {
        const uniqueId = `${attrs.id}${generateRandomIdTail()}`;
        model.addAttributes({ id: uniqueId });
      }
      if (!attrs.id) {
        const randomId = generateRandomId() + generateRandomIdTail();
        model.addAttributes({ id: randomId });
      }
    });

    editor.on('asset:open', () => {
      const titleModal = document.querySelector('.gjs-mdl-title');
      if (titleModal) {
        titleModal.textContent = 'Lựa chọn ảnh';
      }
      const assetsListImage = document.querySelector('.gjs-am-assets-header');
      if (assetsListImage) {
        assetsListImage.innerHTML = '<span>Danh sách ảnh đã tải lên</span>';
      }
      const titleUploadFile = document.getElementById('gjs-am-title');
      if (titleUploadFile) {
        titleUploadFile.textContent = 'Kéo thả hoặc click để tải ảnh lên';
      }
    });

    editor.on('load', () => {
      setTimeout(() => {
        const html = editor.getHtml();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const elements = doc.querySelectorAll('[advanced-setting-identification-card]');
        const values: any[] = [];
        elements.forEach(element => {
          values.push(element.getAttribute('advanced-setting-identification-card'));
        });
        saveToIndexDB('ASICArrays', values);
      }, 333);
    });
  };

  const generateRandomIdTail = () => {
    return `-rd${Math.random().toString(36).substr(2, 6)}`;
  }

  const generateRandomId = () => {
    const letters = 'abcdefghijklmnopqrstuvw';
    const randomChars = Array.from({ length: 4 }, () => letters.charAt(Math.floor(Math.random() * letters.length)));
    const randomNumber = Math.floor(Math.random() * 10);
    const randomPosition = Math.floor(Math.random() * 5);
    randomChars.splice(randomPosition, 0, randomNumber.toString());
    return `i${randomChars.join('')}`;
  }

  return (
    <>
      {loading ? (
        <div className='loading-editor'>
          <Spin />
        </div>
      ) : (
        <>
          <GjsEditor
            className={`main-page-builder gjs-page-builder ${isView ? 'view' : ''}`}
            grapesjs={grapesjs}
            grapesjsCss='../../../src/pages/page-builder/grapesjs.min.css'
            waitReady={true}
            options={{
              height: 'calc(100vh - 186px)',
              storageManager: false,
              fromElement: true,
              showOffsets: true,
              selectorManager: { componentFirst: true },
              assetManager: {
                embedAsBase64: false,
                assets: imagesAssests,
                upload: BASE_URL_V2 + '/file-manager/page-builder-upload',
                uploadName: 'files',
                headers: { Authorization: 'Bearer ' + accessToken },
                beforeUpload: (files) => {
                  const typeFile = files[0].type.includes('image/');
                  if (!typeFile) {
                    helpService.errorMessage('File không hợp lệ !!!');
                  }
                  return typeFile;
                },
              },
              canvas: {
                styles: [],
                scripts: [],
              },
              plugins: [
                gjsPresetWeb,
                gjsStyleBg,
                gjsStyleFilter,
                gjsStyleGradient,
                gjsTailWind,
                gjsBlocksBasic,
                gjsTabs,
                baseReactComponent,
                gjsLecturerComponent,
                CommonAdvanceSettingsPlugin,
                FontSizeConfig,
                addMoocCustomDisplayTypePlugin,
                addDetailTemplatesByCoueseId,
                addMyBlocksToCategories,
              ],
              pluginsOpts: {
                [gjsBlocksBasic]: {
                  flexGrid: true,
                  category: nameBasicBlock,
                  blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'video', 'image', 'map'],
                  labelColumn1: '1 cột',
                  labelColumn2: '2 cột',
                  labelColumn3: '3 cột',
                  labelColumn37: '3 phần 7 cột',
                  labelText: 'Chữ',
                  labelLink: 'Link',
                  labelImage: 'Ảnh',
                  labelVideo: 'Video',
                  labelMap: 'Bàn đồ',
                },
                [gjsPresetWeb]: {
                  modalImportTitle: 'Thêm giao diện',
                  modalImportLabel: '<div style="margin-bottom: 10px; font-size: 13px;">Hãy dán HTML/CSS của bạn vào và bấm Import</div>',
                  modalImportContent: (editor: Editor) => {
                    return (editor.getHtml() + '<style>' + editor.getCss() + '</style>');
                  },
                },
                [gjsTailWind]: {
                  cover: '',
                },
                [gjsTabs]: {
                  tabsBlock: { category: nameBasicBlock },
                },
                [grapesjsTyped]: {
                  block: {
                    category: nameBasicBlock,
                    content: {
                      type: 'typed',
                      'type-speed': 40,
                      strings: [
                        'Text row one',
                        'Text row two',
                        'Text row three',
                      ],
                    },
                  },
                },
                [gjsLecturerComponent]: {
                  courseId: params.id,
                  tab: tab,
                },
                [CommonAdvanceSettingsPlugin]: {
                  detailData: detailData,
                },
                [gjsConditionComponent]: {
                  detailData: detailData,
                },
                [FontSizeConfig]: {
                  name: 'Font Size',
                  type: 'font-size-custom',
                  property: 'font-size',
                  default: '',
                  units: ['px', '%', 'rem', 'em'],
                  min: 0,
                  max: 1000,
                },
              },
              styleManager: styleManagerConfig,
            }}
            onEditor={onEditor}
          >
            <WithEditor>
              <MyComponentWithUseEditor />
            </WithEditor>
          </GjsEditor>
          <Modal
            title='Lưu vào mẫu'
            open={openModal}
            okText='Xác nhận'
            cancelText='Hủy'
            onOk={changeName}
            onCancel={closeModal}
            okButtonProps={
              iconVisible ? { disabled: true } : { disabled: false }
            }
            cancelButtonProps={
              iconVisible ? { disabled: true } : { disabled: false }
            }
          >
            <Form.Item required={true} label='Tên mẫu'>
              <Input
                disabled={iconVisible}
                value={nameBlock}
                onChange={onChangeName}
                suffix={
                  <>
                    <Tooltip title='Sinh mã tự động'>
                      {iconVisible ? (
                        <LoadingOutlined />
                      ) : (
                        <RetweetOutlined onClick={handleClickSuffix} />
                      )}
                    </Tooltip>
                  </>
                }
              />
            </Form.Item>
          </Modal>
          <Modal
            title='Nội dung'
            open={openContentSummryModal}
            okText='Nhập'
            cancelText='Hủy'
            onOk={changeContentSummry}
            onCancel={closeContentSummryModal}
            okButtonProps={
              iconVisible ? { disabled: true } : { disabled: false }
            }
            cancelButtonProps={
              iconVisible ? { disabled: true } : { disabled: false }
            }
          >
            <Form.Item required={true} label=''>
              <Input
                disabled={iconVisible}
                value={contentSummry}
                onChange={onChangeContentSummry}
              />
            </Form.Item>
          </Modal>
        </>
      )}
    </>
  );
};

export default PageBuilder;
