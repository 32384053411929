import React, { useState } from "react";
import "./style.scss";
import QuizPanel from "./QuizPanel";
import Countdown from "./Countdown";
import { Button, Pagination } from "antd";
import { GroupsExam, QuizProps, QuizRequest } from "../../../../types/course";
import {
  ClockIcon,
  NextIcon,
  PreviousIcon,
} from "../../../../components/icons/svg";
import EmptyComponent from "../../../../components/empty";

interface ControlPanelProps {
  correct?: number[];
  currentQuestion?: QuizProps;
  inCorrect?: number[];
  pause?: boolean;
  quizzesCompleted?: QuizRequest[];
  totalQuiz?: number;
  showTime?: boolean;
  showComplete?: boolean;
  quizzes?: QuizProps[];
  groups?: GroupsExam[];
  timeToCompleted?: number;
  disabled?: boolean;

  mode?: string;
  onChange?: (v: QuizProps) => void;
  onTimeEnd?: () => void;
}
const ControlPanel = ({
  currentQuestion,
  timeToCompleted = 0,
  totalQuiz = 0,
  quizzesCompleted = [],
  showTime = true,
  showComplete = true,
  pause = false,
  groups,
  disabled = false,
  mode = "",
  onChange = () => {},
  onTimeEnd = () => {},
}: ControlPanelProps) => {
  const active = currentQuestion?.id;
  const [pageNum, setPageNum] = useState<number>(1);
  const itemsPerPage = 2;

  const handleClickQuizPanel = (value: QuizProps) => {
    onChange(value);
  };

  const getItemsForPage = () => {
    if (groups && groups?.length > 0) {
      const startIndex = (pageNum - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      return groups.slice(startIndex, endIndex);
    }
    return groups;
  };

  const itemsRender = getItemsForPage() as GroupsExam[];

  return (
    <div className="control-panel">
      {showTime && (
        <div className="count-down flex gap-8">
          <ClockIcon />
          <span className="font-size-14 text-primary-violet">
            <b className="flex gap-8">
              Thời gian:{" "}
              {
                <Countdown
                  pause={pause}
                  initialTime={timeToCompleted}
                  format="hh:mm:ss"
                  onFinish={onTimeEnd}
                />
              }
            </b>
          </span>
        </div>
      )}
      {showComplete && (
        <div className="total-finish mb-1">
          <span className="font-size-14 text-primary-violet">
            <b>
              Đã hoàn thành: {quizzesCompleted?.length}/{totalQuiz}
            </b>
          </span>
        </div>
      )}

      <div className="course-quiz-panel">
        {itemsRender?.length > 0 ? (
          itemsRender
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((group: GroupsExam, index: number) => (
              <div key={index}>
                <QuizPanel
                  key={group?.id}
                  disabled={disabled}
                  quizzesCompleted={quizzesCompleted}
                  active={active}
                  title={group?.title}
                  totalQuizzes={group?.quizzes?.length}
                  quizzes={group?.quizzes}
                  onClick={handleClickQuizPanel}
                />
              </div>
            ))
        ) : (
          <EmptyComponent description="Không có dữ liệu" />
        )}
      </div>
      <div className="control-panel-footer flex align-center justify-space-between mt-2">
        <Pagination
          pageSize={2}
          current={pageNum}
          showSizeChanger={false}
          onChange={setPageNum}
          total={groups?.length}
          className="quiz-paginate"
          prevIcon={
            <Button className="ctr-panel-btn center h-36">
              <PreviousIcon />
            </Button>
          }
          nextIcon={
            <Button className="ctr-panel-btn center h-36">
              <NextIcon />
            </Button>
          }
        />
        <div className="font-size-14 line-height-20 paginate-list-item">
          Trang {pageNum < 10 ? `0${pageNum}` : pageNum}/
          {Math.ceil((groups?.length as number) / 2) < 10
            ? `0${Math.ceil((groups?.length as number) / 2)}`
            : Math.ceil((groups?.length as number) / 2)}
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;
