// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-chart .result-shot {
  background-color: white;
  padding: 10px; }

.block-chart .result-shot-title {
  padding: 10px;
  font-size: 20px;
  font-weight: 600; }

.block-chart .result-answer {
  padding: 10px; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-synthesis/components/result-shot/index.scss"],"names":[],"mappings":"AAAA;EAEQ,uBAAyB;EAEzB,aAAwB,EAAA;;AAJhC;EAUQ,aAAiB;EACjB,eAAiB;EACjB,gBAAgB,EAAA;;AAZxB;EAgBQ,aAAa,EAAA","sourcesContent":[".block-chart {\n    .result-shot {\n        background-color  : white;\n        //margin-bottom   : 50px;\n        padding           : 10px;\n\n\n    }\n\n    .result-shot-title {\n        padding    : 10px;\n        font-size  : 20px;\n        font-weight: 600;\n    }\n\n    .result-answer {\n        padding: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
