import { Col, Form, Input, Modal, Row } from "antd";
import { DataType } from "../../../../../types/survey-managment/listSurvey";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";

type Props = {
  open: boolean;
  handleCancel: () => void;
  browseData?: DataType;
  options?: Array<unknown> | any;
};
const validations = {
  organization_member_id: {
    required: true,
    message: "Vui lòng chọn cán bộ duyệt khoá học",
  },
};
export default function ApproveSurvey(props: Props) {
  const { open, handleCancel, browseData, options } = props;

  const [form] = Form.useForm();
  const onOk = () => {
    form.submit();
  };

  return (
    <>
      <Modal
        title={`Phê duyệt khảo sát ${browseData?.surveyName}`}
        open={open}
        onOk={onOk}
        onCancel={handleCancel}
        centered
        okText="Gửi yêu cầu"
        cancelText="Huỷ"
      >
        <Form
          layout={"vertical"}
          form={form}
          name="approve-modal"
          initialValues={{
            survey_id: browseData?.id,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="organization_member_id"
                label="Khảo sát của bản sẽ được gửi tới Quản trị khóa học:"
                rules={[validations.organization_member_id]}
                hasFeedback
              >
                <FormItemDropdown
                  options={options}
                  allowClear={true}
                  placeholder="Nhập tên cán bộ duyệt khảo sát"
                />
              </Form.Item>
              <Form.Item name="survey_id" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
