import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Row,
  TableColumnType,
  Typography,
} from "antd";

import "./filter-table.scss";
import {
  DeleteOutlined,
  DownOutlined,
  FilterOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  UpOutlined,
} from "@ant-design/icons";
import PageHeader from "../../../../../../components/page-header/PageHeader";
import FormInput from "../../../../../../components/form-input/FormInput";

type FilterTableProps = {
  handleOpen: () => void;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
  showFilter: boolean;
};

const FilterTable: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter, showFilter
}) => {
  const { RangePicker } = DatePicker;
  return (
    <Layout className="page-header-group">
      <div className={`page-header-group--layout`}>
        <div className="table-header--wrapper">
          <div className="table-header--left">
            <PageHeader title="Danh sách tổ chuyên môn" />
          </div>
          <div className="course-plan-managment-filter-speialized">
            <div className="heading-extra">
              <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
                <Col
                  md={{ span: 5 }}
                  lg={{ span: 5 }}
                  xl={{ span: 5 }}
                  xs={{ span: 7 }}
                >
                  <div className="heading-filter">
                    <Button
                      className="filter-button"
                      onClick={handleOpenFilter}
                    >
                      <Typography.Text>Bộ lọc</Typography.Text>
                      {showFilter ? (
                        <UpOutlined />
                      ) : (
                        <DownOutlined />
                      )}
                    </Button>
                  </div>
                </Col>
                <Col
                  md={{ span: 13 }}
                  lg={{ span: 13 }}
                  xl={{ span: 11 }}
                  xs={{ span: 17 }}
                >
                  <div className="heading-search">
                    <FormInput
                      placeholder="Nhập từ khóa cần tìm"
                      value={searchValue}
                      onChange={handleChangeInput}
                      onKeyPress={handleSearch}
                      afterPrefixIcon={
                        <SearchOutlined onClick={handleSubmitSearch} />
                      }
                    />
                  </div>
                </Col>
                <Col
                  md={{ span: 11, offset: 0 }}
                  lg={{ span: 8, offset: 0 }}
                  xl={{ span: 8, offset: 0 }}
                  xs={{ span: 21 }}
                >
                  <div className="heading-resigter">
                    <Button className="btn btn-primary" onClick={handleOpen}>
                      <PlusOutlined />
                      <Typography.Text>Thêm tổ chuyên môn</Typography.Text>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FilterTable;
