import React, { useEffect, useRef, useState } from "react";
import { Button, Spin, Tooltip } from "antd";

import "react-h5-audio-player/lib/styles.css";
import "./style.scss";
import { isEmpty, isNumber } from "lodash";
import H5AudioPlayer from "react-h5-audio-player";
import { JSON_SUB_TYPE, QuestionProps, QuizProps } from "../../../../../types/course";
import { FormInstance } from "antd/lib";
import { uploadFileV2 } from "../../../../../service/uploadFile/infoDetailApi";
import { QuestionTypeEnum } from "../../../../../constants";
import { ExportIcon, MicroIcon, PausePrimaryIcon, PlayIcon } from "../../../../../components/icons/svg";
import EmptyComponent from "../../../../../components/empty";


interface Mp3Props extends QuestionProps {
  data?: QuizProps;
  form?: FormInstance<any>;
  onChange?: (value: any) => void;
}

function Mp3({ data, disabled, initialData, onChange = () => {} }: Mp3Props) {
  // audio file data
  const [audioURL, setAudioURL] = useState<string>("");

  // control
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [mode, setMode] = useState<"begin" | "recording" | "view" | "error">(
    "begin"
  );
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);

  const startRecording = async () => {
    setMode("recording");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        uploadAudio(audioBlob);
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);
        audioChunksRef.current = [];
      };

      mediaRecorder.start();
    } catch (err) {
      console.error("Error accessing the microphone", err);
      setMode("error");
    }
  };

  const uploadAudio = async (audioBlob: Blob | null) => {
    if (!audioBlob) return;

    const formData = new FormData();
    formData.append("file", audioBlob);

    try {
      const response = await uploadFileV2(formData);

      onChange({
        [`recorderMp3-${data?.id}-${QuestionTypeEnum.MP3}-${JSON_SUB_TYPE.ContentRequest}`]:
          response?.data?.filePath,
      });
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();

      if (mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    }
    setMode("view");
  };

  const handleDeleteRecording = () => {
    setAudioURL("");
    setMode("begin");
    onChange({
      [`recorderMp3-${data?.id}-${QuestionTypeEnum.MP3}-${JSON_SUB_TYPE.ContentRequest}`]:
        null,
    });
  };

  const handlePlay = (e: any) => {
    if (!isEmpty(e.target)) {
      const { duration, currentTime } = e.target;

      if (duration === Infinity) {
        setLoading(true);
      } else if (
        isNumber(duration) &&
        isNumber(currentTime) &&
        duration !== Infinity
      ) {
        setTime(Math.floor(duration - currentTime));
        setLoading(false);
      }
    }
  };

  const displayTime = () => {
    if (time > 0) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes > 10 ? minutes : `0${minutes}`}:${seconds < 10 ? "0" + seconds : seconds}`;
    } else return "00:00";
  };

  useEffect(() => {
    if (!isEmpty(initialData?.answer[0])) {
      const getFile = async () => {
        setAudioURL(initialData?.answer[0]);
        setMode("view");
      };
      getFile();
    }
  }, []);

  useEffect(() => {
    return () => stopRecording();
  }, []);

  const renderContent = () => {
    switch (mode) {
      case "begin":
        return (
          <div className="begin-screen">
            <Button
              disabled={disabled}
              className="record-item mt-2"
              onClick={startRecording}
            >
              <div className="center">
                <MicroIcon />
                <span className="font-size-14 font-weight-6 ml-1">
                  Bắt đầu ghi âm câu trả lời của bạn
                </span>
              </div>
            </Button>
          </div>
        );
      case "recording":
        return (
          <div className="micro-recording-screen center flex-column mt-3">
            <div className="record-micro micro-recording-animation">
              <MicroIcon />
            </div>

            <Tooltip title="Kết thúc">
              <Button
                className="mt-2 btn-primary btn-action"
                onClick={stopRecording}
              >
                <ExportIcon />
              </Button>
            </Tooltip>
          </div>
        );
      case "view":
        return (
          <div className="view-screen flex gap-32 align-center">
            <Spin spinning={loading}>
              <div className="audio-control">
                <H5AudioPlayer
                  src={audioURL}
                  className="audio-play"
                  autoPlay={false}
                  autoPlayAfterSrcChange={false}
                  loop={false}
                  showFilledVolume={false}
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  customIcons={{
                    play: <PlayIcon />,
                    pause: <PausePrimaryIcon />,
                  }}
                  onListen={handlePlay}
                />
                <div className="dispay-time text-secondary">
                  {displayTime()}
                </div>
              </div>
            </Spin>

            <Button
              disabled={disabled}
              className="font-size-14 text-primary font-weight-6 pointer"
              onClick={startRecording}
            >
              Ghi âm lại
            </Button>
            <Button
              disabled={disabled}
              className="text-danger font-size-14 font-weight-6 pointer"
              onClick={handleDeleteRecording}
            >
              Xoá
            </Button>
          </div>
        );
      case "error":
        return (
          <div className="w-full center">
            <EmptyComponent description="Không tìm thấy thiết bị được yêu cầu" />
          </div>
        );
      default:
        break;
    }
  };

  return <div>{renderContent()}</div>;
}

export default Mp3;
