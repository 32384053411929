import React from "react";

function LongText({ data }: any) {
  return (
    <div className="choose-correct">
      {data?.questions &&
        data?.questions?.length > 0 &&
        data?.questions?.map((quiz: any) => (
          <p
            key={quiz?.id}
            className={`font-size-16 line-height-24 text-result`}
          >
            {quiz?.content || "Không có nội dung"}
          </p>
        ))}
    </div>
  );
}

export default LongText;
