export const DropdownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M12.8125 3H11.6406C11.561 3 11.486 3.03906 11.4391 3.10313L7.00001 9.22188L2.56095 3.10313C2.51408 3.03906 2.43908 3 2.35939 3H1.18751C1.08595 3 1.02658 3.11563 1.08595 3.19844L6.59533 10.7937C6.79533 11.0687 7.2047 11.0687 7.40314 10.7937L12.9125 3.19844C12.9735 3.11563 12.9141 3 12.8125 3Z"
      fill="#1677FF"
    />
  </svg>
);

export const LikeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5.83334 18.3327V9.16602M1.66667 10.8327V16.666C1.66667 17.5865 2.41286 18.3327 3.33334 18.3327H14.5219C15.7558 18.3327 16.8052 17.4324 16.9928 16.2128L17.8902 10.3795C18.1232 8.8651 16.9515 7.49935 15.4193 7.49935H12.5C12.0398 7.49935 11.6667 7.12625 11.6667 6.66602V3.72088C11.6667 2.58601 10.7467 1.66602 9.61181 1.66602C9.34112 1.66602 9.09582 1.82543 8.98589 2.07278L6.05329 8.67113C5.91954 8.97207 5.6211 9.16602 5.29178 9.16602H3.33334C2.41286 9.16602 1.66667 9.91221 1.66667 10.8327Z"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DislikeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M14.1667 1.66602V10.8327M18.3333 8.16602V4.33268C18.3333 3.39926 18.3333 2.93255 18.1517 2.57603C17.9919 2.26243 17.7369 2.00746 17.4233 1.84767C17.0668 1.66602 16.6001 1.66602 15.6667 1.66602H6.76501C5.54711 1.66602 4.93816 1.66602 4.44632 1.88888C4.01284 2.0853 3.64442 2.40137 3.38437 2.79995C3.08931 3.25218 2.99672 3.85405 2.81153 5.05779L2.37563 7.89112C2.13137 9.47878 2.00925 10.2726 2.24484 10.8903C2.45162 11.4324 2.84054 11.8858 3.34494 12.1726C3.91961 12.4993 4.72278 12.4993 6.32912 12.4993H7C7.46671 12.4993 7.70007 12.4993 7.87833 12.5902C8.03513 12.6701 8.16261 12.7976 8.24251 12.9544C8.33334 13.1326 8.33334 13.366 8.33334 13.8327V16.2778C8.33334 17.4127 9.25333 18.3327 10.3882 18.3327C10.6589 18.3327 10.9042 18.1733 11.0141 17.9259L13.8148 11.6245C13.9421 11.3379 14.0058 11.1946 14.1065 11.0895C14.1955 10.9966 14.3048 10.9256 14.4258 10.882C14.5627 10.8327 14.7195 10.8327 15.0332 10.8327H15.6667C16.6001 10.8327 17.0668 10.8327 17.4233 10.651C17.7369 10.4912 17.9919 10.2363 18.1517 9.92267C18.3333 9.56615 18.3333 9.09944 18.3333 8.16602Z"
      stroke="#475467"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FeedbackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clipPath="url(#clip0_492_866)">
      <path
        d="M10.2259 7.17183C9.76186 7.17183 9.40227 7.53143 9.40227 7.9754C9.40227 8.41937 9.76186 8.77897 10.2259 8.77897C10.6498 8.77897 11.0094 8.41937 11.0094 7.9754C11.0094 7.53143 10.6498 7.17183 10.2259 7.17183ZM4.60093 7.17183C4.13686 7.17183 3.77727 7.53143 3.77727 7.9754C3.77727 8.41937 4.13686 8.77897 4.60093 8.77897C5.02481 8.77897 5.38441 8.41937 5.38441 7.9754C5.38441 7.53143 5.02481 7.17183 4.60093 7.17183Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M16.674 5.64348C15.7446 4.36407 14.4044 3.44223 12.8771 3.03187V3.03388C12.5336 2.65218 12.1459 2.30062 11.712 1.98723C8.42335 -0.403398 3.80683 0.325843 1.40617 3.61446C-0.528433 6.28633 -0.446067 9.88031 1.5267 12.4337L1.54277 15.0975C1.54277 15.1618 1.55282 15.2261 1.57291 15.2863C1.59817 15.3668 1.63907 15.4416 1.69324 15.5062C1.74742 15.5709 1.81382 15.6243 1.88864 15.6633C1.96346 15.7023 2.04524 15.7262 2.12929 15.7335C2.21335 15.7409 2.29802 15.7316 2.37849 15.7062L4.92179 14.9046C5.59478 15.1437 6.28987 15.2803 6.98094 15.3185L6.9709 15.3265C8.76085 16.6303 11.1073 17.022 13.2588 16.3109L15.8122 17.1426C15.8765 17.1627 15.9428 17.1747 16.0111 17.1747C16.3667 17.1747 16.6539 16.8874 16.6539 16.5319V13.8399C18.4238 11.4372 18.47 8.11647 16.674 5.64348ZM5.20304 13.4783L4.96197 13.3778L2.97313 14.0006L2.95304 11.9113L2.79233 11.7305C1.09277 9.65732 0.980272 6.67004 2.57134 4.4783C4.50795 1.82249 8.22045 1.23589 10.8682 3.15241C13.524 5.08299 14.1126 8.78946 12.1941 11.4292C10.585 13.637 7.70416 14.4526 5.20304 13.4783ZM15.3481 13.1368L15.1874 13.3377L15.2075 15.427L13.2388 14.764L12.9977 14.8645C11.8727 15.2823 10.6734 15.3165 9.56242 15.0051L9.5584 15.0031C11.0852 14.5348 12.419 13.5839 13.3593 12.293C14.8941 10.1776 15.1432 7.51982 14.2513 5.25374L14.2633 5.26178C14.7254 5.59325 15.1492 6.00709 15.5088 6.50732C16.9673 8.50821 16.885 11.2323 15.3481 13.1368Z"
        fill="black"
        fillOpacity="0.45"
      />
      <path
        d="M7.41343 7.17183C6.94937 7.17183 6.58977 7.53143 6.58977 7.9754C6.58977 8.41937 6.94937 8.77897 7.41343 8.77897C7.83731 8.77897 8.19691 8.41937 8.19691 7.9754C8.19691 7.53143 7.83731 7.17183 7.41343 7.17183Z"
        fill="black"
        fillOpacity="0.45"
      />
    </g>
    <defs>
      <clipPath id="clip0_492_866">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const LikeActiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M16.9928 16.2128C16.8051 17.4324 15.7558 18.3327 14.5218 18.3327H3.33329C2.41282 18.3327 1.66663 17.5865 1.66663 16.666V10.8327C1.66663 9.91221 2.41282 9.16602 3.33329 9.16602H5.29173C5.62106 9.16602 5.91949 8.97207 6.05324 8.67113L8.98584 2.07278C9.09578 1.82543 9.34107 1.66602 9.61176 1.66602C10.7466 1.66602 11.6666 2.58601 11.6666 3.72088V6.66602C11.6666 7.12625 12.0397 7.49935 12.5 7.49935H15.4193C16.9515 7.49935 18.1232 8.8651 17.8902 10.3795L16.9928 16.2128Z"
      fill="#F4EBFF"
    />
    <path
      d="M5.83329 18.3327V9.16602M1.66663 10.8327V16.666C1.66663 17.5865 2.41282 18.3327 3.33329 18.3327H14.5218C15.7558 18.3327 16.8051 17.4324 16.9928 16.2128L17.8902 10.3795C18.1232 8.8651 16.9515 7.49935 15.4193 7.49935H12.5C12.0397 7.49935 11.6666 7.12625 11.6666 6.66602V3.72088C11.6666 2.58601 10.7466 1.66602 9.61176 1.66602C9.34107 1.66602 9.09578 1.82543 8.98584 2.07278L6.05324 8.67113C5.91949 8.97207 5.62106 9.16602 5.29173 9.16602H3.33329C2.41282 9.16602 1.66663 9.91221 1.66663 10.8327Z"
      stroke="#7F56D9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DislikeActiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.81153 5.05779C2.99672 3.85405 3.08931 3.25218 3.38437 2.79995C3.64442 2.40137 4.01284 2.0853 4.44632 1.88888C4.93816 1.66602 5.54711 1.66602 6.76501 1.66602H15.6667C16.6001 1.66602 17.0668 1.66602 17.4233 1.84767C17.7369 2.00746 17.9919 2.26243 18.1517 2.57603C18.3333 2.93255 18.3333 3.39926 18.3333 4.33268V8.16602C18.3333 9.09944 18.3333 9.56615 18.1517 9.92267C17.9919 10.2363 17.7369 10.4912 17.4233 10.651C17.0668 10.8327 16.6001 10.8327 15.6667 10.8327H15.0332C14.7195 10.8327 14.5627 10.8327 14.4258 10.882C14.3048 10.9256 14.1955 10.9966 14.1065 11.0895C14.0058 11.1946 13.9421 11.3379 13.8148 11.6245L11.0141 17.9259C10.9042 18.1733 10.6589 18.3327 10.3882 18.3327C9.25333 18.3327 8.33334 17.4127 8.33334 16.2778V13.8327C8.33334 13.366 8.33334 13.1326 8.24251 12.9544C8.16261 12.7976 8.03513 12.6701 7.87833 12.5902C7.70007 12.4993 7.46671 12.4993 7 12.4993H6.32912C4.72278 12.4993 3.91961 12.4993 3.34494 12.1726C2.84054 11.8858 2.45162 11.4324 2.24484 10.8903C2.00925 10.2726 2.13137 9.47878 2.37563 7.89112L2.81153 5.05779Z"
      fill="#F4EBFF"
    />
    <path
      d="M14.1667 1.66602V10.8327M18.3333 8.16602V4.33268C18.3333 3.39926 18.3333 2.93255 18.1517 2.57603C17.9919 2.26243 17.7369 2.00746 17.4233 1.84767C17.0668 1.66602 16.6001 1.66602 15.6667 1.66602H6.76501C5.54711 1.66602 4.93816 1.66602 4.44632 1.88888C4.01284 2.0853 3.64442 2.40137 3.38437 2.79995C3.08931 3.25218 2.99672 3.85405 2.81153 5.05779L2.37563 7.89112C2.13137 9.47878 2.00925 10.2726 2.24484 10.8903C2.45162 11.4324 2.84054 11.8858 3.34494 12.1726C3.91961 12.4993 4.72278 12.4993 6.32912 12.4993H7C7.46671 12.4993 7.70007 12.4993 7.87833 12.5902C8.03513 12.6701 8.16261 12.7976 8.24251 12.9544C8.33334 13.1326 8.33334 13.366 8.33334 13.8327V16.2778C8.33334 17.4127 9.25333 18.3327 10.3882 18.3327C10.6589 18.3327 10.9042 18.1733 11.0141 17.9259L13.8148 11.6245C13.9421 11.3379 14.0058 11.1946 14.1065 11.0895C14.1955 10.9966 14.3048 10.9256 14.4258 10.882C14.5627 10.8327 14.7195 10.8327 15.0332 10.8327H15.6667C16.6001 10.8327 17.0668 10.8327 17.4233 10.651C17.7369 10.4912 17.9919 10.2363 18.1517 9.92267C18.3333 9.56615 18.3333 9.09944 18.3333 8.16602Z"
      stroke="#7F56D9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
