import { FunctionComponent, ReactNode } from "react";
import "./Drawer.scss";
import { Drawer } from "antd";

type DrawerProps = {
  title: string;
  open?: boolean;
  onClose?: () => void;
  children: ReactNode;
  widthDrawer?: number;
  extraNode?: ReactNode;
  bodyStyle?: any;
};

const DrawerCustom: FunctionComponent<DrawerProps> = ({
  title,
  open,
  onClose,
  children,
  widthDrawer,
  extraNode,
  bodyStyle,
}) => {
  return (
    <>
      <Drawer
        title={title}
        open={open}
        onClose={onClose}
        width={widthDrawer}
        placement="right"
        extra={extraNode}
        bodyStyle={bodyStyle}
      >
        {children}
      </Drawer>
    </>
  );
};

export default DrawerCustom;
