import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import "../filter-table/FilterTable.scss";
import FormInput from "../../../../form-input/FormInput";

type FilterTableProps = {
    showFilter: boolean
    searchValue: string
    handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
    handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void
    handleSubmitSearch: () => void
    handleOpenFilter: () => void
};

const FilterTableLecturerHistory: FunctionComponent<FilterTableProps> = ({
    showFilter,
    handleOpenFilter,
    searchValue,
    handleChangeInput,
    handleSearch,
    handleSubmitSearch
}: FilterTableProps) => {
    return (
        <div className="history-table-style">
            <div className="heading-table">
                <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
                    <Col>
                        <div className="heading-table-filter">
                            <Button className={`filter-button ${showFilter ? 'open' : ''}`} onClick={handleOpenFilter}>
                                <Typography.Text className="filter-text">Bộ lọc</Typography.Text>
                                {showFilter ? <UpOutlined /> : <DownOutlined />}
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <div className="heading-table-search">
                            <FormInput
                                placeholder="Nhập từ khoá cần tìm"
                                value={searchValue}
                                onChange={handleChangeInput}
                                onKeyPress={handleSearch}
                                afterPrefixIcon={
                                    <SearchOutlined onClick={handleSubmitSearch} />
                                }
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default FilterTableLecturerHistory;