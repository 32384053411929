// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-record button {
  width: 100% !important;
  margin: 15px 0;
  pointer-events: none; }

.criteria {
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/question-item/mp3/style.scss"],"names":[],"mappings":"AAAA;EAEI,sBAAsB;EACtB,cAAc;EACd,oBAAoB,EAAA;;AAKxB;EACE,WAAW,EAAA","sourcesContent":[".upload-record {\n  button {\n    width: 100% !important;\n    margin: 15px 0;\n    pointer-events: none;\n  }\n}\n\n\n.criteria {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
