import React, { useEffect, useState } from "react";
import CustomCard from "../../../../../../components/custom-card/CustomCard";
import FormInput from "../../../../../../components/form-input/FormInput";
import { Button, Checkbox, Col, Form, message, Row, Select, Space } from "antd";
import "./index.scss";
import {
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import { DataTypeMoocSurveyObjectsFilter } from "../../../../../../types/survey-managment/mooc-survey-objects-filter";
import {
  createFilterObject,
  getFilterPersonTotal,
} from "../../../../../../service/survey-managment/list-survey";
import TimeSurvey from "./time-survey";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { isArray } from "lodash";
import { useLocation } from "react-router-dom";
type SettingContentCourseProps = {
  dataOptions?: any;
  isVisibleButton?: boolean | false;
};
const SettingSurvey = ({
  dataOptions,
  isVisibleButton,
}: SettingContentCourseProps) => {
  const location = useLocation();
  const { mode } = location.state || {}; // Truy cập state
  //console.log("Hello mode", mode);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [form] = useForm();
  const handleDrop = (ind: number) => {
    setDataFilter(dataFilter.filter((x, index) => index != ind));
  };

  const handlClickPlus = () => {
    const data: DataTypeMoocSurveyObjectsFilter = {
      id: 0,
      filterValue: undefined,
      filterCondition: undefined,
      filterCriteria: undefined,
      filterOperator: true,
    };
    setDataFilter([...dataFilter, data]);
  };
  const [total, setTotal] = useState(0);
  const [listFilter, setListFilter] = useState<any[]>();
  const [dataFilter, setDataFilter] = useState<
    DataTypeMoocSurveyObjectsFilter[]
  >(
    dataOptions.dataFilter.filters.length > 0
      ? dataOptions.dataFilter.filters
      : [
          {
            id: 0,
            surveyId: id,
            filterValue: undefined,
            filterCondition: undefined,
            filterCriteria: undefined,
            filterOperator: true,
          },
        ]
  );

  useEffect(() => {
    // earch();handleS
    if (dataOptions.dataFilter) {
      form.setFieldsValue({
        disabled: dataOptions.dataFilter.isAllowedHiddenSurveyObjects ?? false,
        endTime:
          dataOptions.dataFilter.surveyClose != null
            ? dayjs(dataOptions.dataFilter.surveyClose)
                .set(
                  "hour",
                  dayjs(dataOptions.dataFilter.surveyClose).hour() - 7
                )
                .set(
                  "minute",
                  dayjs(dataOptions.dataFilter.surveyClose).minute()
                )
                .set(
                  "second",
                  dayjs(dataOptions.dataFilter.surveyClose).second()
                )
            : null,
        enrollClosedDate: dataOptions.dataFilter.surveyClose ?? null,
        enrollStatedDate: dataOptions.dataFilter.surveyOpen ?? null,
        startTime:
          dataOptions.dataFilter.surveyOpen != null
            ? dayjs(dataOptions.dataFilter.surveyOpen)
                .set(
                  "hour",
                  dayjs(dataOptions.dataFilter.surveyOpen).hour() - 7
                )
                .set(
                  "minute",
                  dayjs(dataOptions.dataFilter.surveyOpen).minute()
                )
                .set(
                  "second",
                  dayjs(dataOptions.dataFilter.surveyOpen).second()
                )
            : null,
      });
    }
  }, [dataOptions]);
  useEffect(() => {
    handleSearch();
  }, [dataFilter]);
  const handleSearch = async () => {
    try {
      //clone array
      const dataFilterClone = JSON.parse(JSON.stringify(dataFilter));
      const data = dataFilterClone.filter((item: any) => {
        return item.filterValue != undefined && item.filterValue != "";
      });
      const datapost = data.map((item: any) => {
        if (item.filterCondition == 2) {
          if (item.filterCriteria == "university_id") {
            item.filterCriteria = "u.name";
          }
          if (item.filterCriteria == "industry_id") {
            item.filterCriteria = "i.name";
          }
          if (item.filterCriteria == "industry_group_id") {
            item.filterCriteria = "g.name";
          }
        }
        return {
          id: item.id,
          surveyId: item.surveyId,
          filterOperator: item.filterOperator,
          filterCriteria: item.filterCriteria,
          filterCondition: item.filterCondition,
          filterFromValue: item.filterFromValue,
          filterToValue: item.filterToValue,
          filterValue: item.filterValue,
          isDeleted: item.isDeleted,
        };
      });
      await getFilterPersonTotal({ filters: [...datapost] }).then(
        (res: any) => {
          const { data } = res.data;
          if (isArray(data)) {
            setTotal(0);
          } else {
            setTotal(data);
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const listFilters = dataFilter.map((item, index) => (
      <Row gutter={12} key={index}>
        <Col span={3}>
          {index == 0 ? null : (
            <Form.Item>
              <Select
                value={dataFilter[index].filterOperator}
                defaultValue={true}
                onChange={(value) => {
                  const datatest = dataFilter;
                  datatest[index].filterOperator = value;
                  setDataFilter([...datatest]);
                }}
              >
                <Select.Option value={true}>Và</Select.Option>
                <Select.Option value={false}>Hoặc</Select.Option>
              </Select>
            </Form.Item>
          )}
        </Col>
        <Col span={6}>
          <Form.Item>
            <Select
              placeholder="Chọn tiêu chí lọc"
              value={dataFilter[index].filterCriteria}
              onChange={(value) => {
                const datatest = dataFilter;
                datatest[index].filterCriteria = value;
                setDataFilter([...datatest]);
                dataFilter[index].filterCondition = undefined;
                dataFilter[index].filterValue = undefined;
                // handleSearch();
              }}
            >
              <Select.Option value="university_id">
                Trường đại học
              </Select.Option>
              <Select.Option value="industry_group_id">Khoa</Select.Option>
              <Select.Option value="industry_id">chuyên ngành</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item>
            <Select
              placeholder="Điều kiện lọc"
              value={dataFilter[index].filterCondition}
              onChange={(value) => {
                const datatest = dataFilter;
                datatest[index].filterCondition = value;
                setDataFilter([...datatest]);
                dataFilter[index].filterValue = undefined;
                // handleSearch();
              }}
            >
              <Select.Option value={1}>Bằng</Select.Option>
              <Select.Option value={2}>Gần đúng</Select.Option>
              <Select.Option value={3}>Một trong những</Select.Option>
              <Select.Option value={4}>Khác</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          {dataFilter[index].filterCondition == 2 ? (
            <Form.Item>
              <FormInput
                placeholder="Nhập từ khóa so sánh "
                value={dataFilter[index].filterValue}
                onBlur={handleSearch}
                onChange={(e) => {
                  const datatest = dataFilter;
                  datatest[index].filterValue = e.target.value;
                  setDataFilter([...datatest]);
                }}
              />
            </Form.Item>
          ) : dataFilter[index].filterCriteria == "university_id" ? (
            <Form.Item>
              <FormItemDropdown
                modeDropdown="multiple"
                value={
                  dataFilter[index].filterValue != ""
                    ? dataFilter[index].filterValue
                        ?.split(",")
                        .map((x) => Number(x))
                    : null
                }
                options={dataOptions.dataUniversity}
                allowClear={true}
                placeholder="Chọn giá trị so sánh"
                onChange={(value: string[]) => {
                  const datatest = dataFilter;
                  datatest[index].filterValue = value.toString();
                  setDataFilter([...datatest]);
                }}
                // onBlur={handleSearch}
              />
            </Form.Item>
          ) : dataFilter[index].filterCriteria == "industry_group_id" ? (
            <Form.Item>
              <FormItemDropdown
                modeDropdown="multiple"
                value={
                  dataFilter[index].filterValue != ""
                    ? dataFilter[index].filterValue
                        ?.split(",")
                        .map((x) => Number(x))
                    : null
                }
                options={dataOptions.dataIndustryGroup}
                allowClear={true}
                placeholder="Chọn giá trị so sánh"
                onChange={(value: string[]) => {
                  const datatest = dataFilter;
                  datatest[index].filterValue = value.toString();
                  setDataFilter([...datatest]);
                }}
                // onBlur={handleSearch}
              />
            </Form.Item>
          ) : dataFilter[index].filterCriteria == "industry_id" ? (
            <Form.Item>
              <FormItemDropdown
                modeDropdown="multiple"
                value={
                  dataFilter[index].filterValue != ""
                    ? dataFilter[index].filterValue
                        ?.split(",")
                        .map((x) => Number(x))
                    : null
                }
                options={dataOptions.dataIndustry}
                allowClear={true}
                placeholder="Chọn giá trị so sánh"
                onChange={(value: string[]) => {
                  const datatest = dataFilter;
                  datatest[index].filterValue = value.toString();
                  setDataFilter([...datatest]);
                }}
                // onBlur={handleSearch}
              />
            </Form.Item>
          ) : (
            <Form.Item>
              <FormItemDropdown
                options={[]}
                allowClear={true}
                placeholder="Chọn giá trị so sánh"
                value={dataFilter[index].filterValue?.split(",")}
                onChange={(value: string[]) => {
                  const datatest = dataFilter;
                  datatest[index].filterValue = value.toString();
                  setDataFilter([...datatest]);
                }}
                // onBlur={handleSearch}
              />
            </Form.Item>
          )}
        </Col>
        <Col span={1}>
          <Space>
            <Button icon={<PlusOutlined />} onClick={handlClickPlus}></Button>
            {index == 0 ? null : (
              <Button
                icon={<CloseOutlined />}
                onClick={() => handleDrop(index)}
              ></Button>
            )}
          </Space>
        </Col>
      </Row>
    ));
    setListFilter(listFilters);
    setLoading(false);
  }, [dataFilter]);

  //  Lưu cài đặt
  const onFinish = async (name: string, { forms, values }: any) => {
    const dataFil = dataFilter.filter((item) => {
      return item.filterValue != undefined && item.filterValue != "";
    });
    const data = [...dataFil];
    for (let i = 0; i < data.length; i++) {
      if (data[i].filterCondition == 2) {
        if (data[i].filterCriteria == "university_id") {
          data[i].filterCriteria = "u.name";
        }
        if (data[i].filterCriteria == "industry_id") {
          data[i].filterCriteria = "i.name";
        }
        if (data[i].filterCriteria == "industry_group_id") {
          data[i].filterCriteria = "g.name";
        }
      }
    }
    const dataPost = {
      id: 1,
      surveyId: id,
      isAllowedHiddenSurveyObjects: values.disabled ?? false,
      surveyOpen: values.startTime
        ? dayjs(values.enrollStatedDate)
            .set("hour", dayjs(values.startTime).hour() + 7)
            .set("minute", dayjs(values.startTime).minute())
            .set("second", dayjs(values.startTime).second())
        : null,
      surveyClose: values.endTime
        ? dayjs(values.enrollClosedDate)
            .set("hour", dayjs(values.endTime).hour() + 7)
            .set("minute", dayjs(values.endTime).minute())
            .set("second", dayjs(values.endTime).second())
        : null,
      filters: [...dataFil],
    };
    try {
      await createFilterObject(dataPost)
        .then((res: any) => {
          if (res.data.statusCode == 200) {
            message.success(res.data.data.message);
          } else {
            message.error(res.data.message);
          }
        })
        .catch(() => {});
    } catch (err) {
      console.log(err);
    }
  };
  // reset field
  const initialValues = () => {
    form.resetFields();
    setDataFilter([
      {
        id: 0,
        filterValue: undefined,
        filterCondition: undefined,
        filterCriteria: undefined,
        filterOperator: true,
      },
    ]);
  };
  return (
    <div className="survey-managment-setting">
      <Form.Provider onFormFinish={onFinish}>
        <Form
          form={form}
          initialValues={initialValues}
          disabled={isVisibleButton}
        >
          <div className="wrapper-settings">
            {!loading && (
              <section className="setting-course">
                <div className="form-settings">
                  <CustomCard
                    title="Đối tượng khảo sát"
                    className="wrapper-settings--card form-setting--common card-setting"
                  >
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ display: "flex" }}
                    >
                      <Row>
                        <span
                          className="individual"
                          style={{ marginBottom: "1rem" }}
                        >
                          {mode === "view"
                            ? "Đối tượng tham gia"
                            : "Tùy chọn đối tượng sinh viên học viên tham gia khảo sát"}
                        </span>
                      </Row>
                      {/* Render cài đặt */}
                      {listFilter}
                      <Row>
                        <span className="individual">
                          Đối tượng dự kiến khảo sát (
                          <span
                            style={{
                              color: "#1677FFD9",
                              fontWeight: 500,
                              fontSize: "16px",
                            }}
                          >
                            {total != undefined || total == null ? total : 0}
                          </span>
                          )
                        </span>
                      </Row>
                      <Row gutter={12}>
                        <Col span={3}></Col>
                        <Col span={10}>
                          <Form.Item name="disabled" valuePropName="checked">
                            <Checkbox>Cho phép ẩn danh người khảo sát</Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Space>
                  </CustomCard>
                  {/* Thời gian mở khảo sát */}
                  <TimeSurvey formInstance={form} />
                  {!isVisibleButton && (
                    <div className="group-button">
                      <Button type="primary" onClick={() => form?.submit()}>
                        <SaveOutlined />
                        Lưu
                      </Button>
                      <Button
                        type="default"
                        danger
                        onClick={() => initialValues()}
                      >
                        <CloseOutlined />
                        Hủy
                      </Button>
                    </div>
                  )}
                </div>
              </section>
            )}
            {/* Loading  */}
            {loading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <LoadingOutlined style={{ fontSize: "32px" }} />
              </div>
            )}
          </div>
        </Form>
      </Form.Provider>
    </div>
  );
};

export default SettingSurvey;
