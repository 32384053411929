// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assign-course-construction .ant-dropdown-trigger {
  padding: 5px 12px; }

.assign-course-construction .waiting-assignment .avatar-name {
  margin: 3px 0px 0px 10px; }

.assign-course-construction .assigned .avatar-name {
  margin: 3px 0px 0px 10px; }

.assign-course-construction .tabs-heading {
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-construction/assign-course-construction/index.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB,EAAA;;AAFrB;EAQM,wBAAwB,EAAA;;AAR9B;EAaM,wBAAwB,EAAA;;AAb9B;EAiBI,WAAW,EAAA","sourcesContent":[".assign-course-construction {\n  .ant-dropdown-trigger {\n    padding: 5px 12px;\n  }\n\n  // chờ phần công\n  .waiting-assignment {\n    .avatar-name {\n      margin: 3px 0px 0px 10px;\n    }\n  }\n  .assigned {\n    .avatar-name {\n      margin: 3px 0px 0px 10px;\n    }\n  }\n  .tabs-heading {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
