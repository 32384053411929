import { Layout, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../../config/routes";
import {
  getStudentFavoriteById
} from "../../../../service/system-suggest-course";
import HeadFavoriteStudent from "./HeadFavoriteStudent";
import TableFavoriteStudent from "./TableFavoriteStudent";
const { Content } = Layout;
const imgPath = "/images/";


const FavoriteStudent = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();
  const [infoFavoriteStudent, setInfoFavoriteStudent] = useState<any>({});
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const fetchStudentInfo = async () => {
    try {
      const res = await getStudentFavoriteById(params?.id || "");
      const { data } = res.data;
      setInfoFavoriteStudent(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchStudentInfo();
  }, []);

  const listBreadcrumb = [
    {
      title: "Hệ thống gợi ý khoá học",
    },
    {
      title: "Danh sách sinh viên",
    },
    {
      title: "Sở thích của sinh viên",
    },
  ];

  const handleBack = () => {
    navigate(`/system-suggest-course/${routesConfig.listStudentSystem}`);
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Sở thích sinh viên"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={handleBack}
      />
      <HeadFavoriteStudent infoFavoriteStudent={infoFavoriteStudent} />
      <div className="px-6 pt-6">
        <div className="px-6 py-3 bg-white rounded-t-lg">
          <Typography.Title level={5} className="pt-2">
            Danh sách thẻ tag theo sở thích
          </Typography.Title>
        </div>
        <TableFavoriteStudent
          infoFavoriteStudent={infoFavoriteStudent}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
        />
      </div>
    </Content>
  );
};

export default FavoriteStudent;
