import { Layout } from "antd";
import { useLocation } from "react-router-dom";

import "./index.scss";
import { routesConfig } from "../../../config/routes";
import PageHeader from "../../../components/page-header/PageHeader";
import DetailTopic from "../../../components/blog-and-forum/detail-topic";


const imgPath = "/images/";
const { Content } = Layout;

type Props = {
  isCourseApprove?: string;
};
export default function DetailTopicPage(props: Props) {
  const location = useLocation();

  const listBreadcrumb = [
    {
      title: "Danh sách chủ đề",
    },
    {
      title: (
        <a
          href={`/${routesConfig.blogAndForum}/${routesConfig.manageContentForum}`}
        >
          Chủ đề của tôi
        </a>
      ),
    },
    {
      title: <div contentEditable='true' dangerouslySetInnerHTML={{ __html: location.state.name }}></div>,
    },
  ];

  return (
    <Layout>
      <Content className="detail-topic-page">
        <PageHeader
          title={`Chủ đề: ${location.state.name}`}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <div className="wrapper-course-construct">
            <section className="detail-topic-page">
              <div className="form-detail">
                <DetailTopic detailData={location.state?.detailData}/>
              </div>
            </section>
          </div>
        </div>
      </Content>
    </Layout>
  );
}
