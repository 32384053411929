import React, { ChangeEvent, useEffect, useState } from "react";
import { MAX_FILE_UPLOAD_SIZE } from "../constants";
import { notifyWarning } from "../utils/notification";

function useUploadFile() {
  const [filesPreview, setFilesPreview] = useState<any[]>([]);
  const [discussionPayloadFiles, setDiscussionPayloadFiles] = useState<any[]>(
    []
  );
  const [isDirtyFile, setIsDirtyFile] = useState<boolean>(false);

  useEffect(() => {
    if (isDirtyFile)
      notifyWarning(
        "Bạn chỉ có thể tải lên các dạng tài liệu: doc, docx, pdf, pptx, xlsx, jpeg, jpg, png",
        () => {
          setIsDirtyFile(false);
        }
      );
  }, [isDirtyFile]);

  const handleChangeFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    let correctFiles = [];

    if (files?.length) {
      for (let i = 0; i < files?.length; i++) {
        if (isCorrectFile(files[i])) {
          correctFiles.push(files[i]);
        }
      }

      const fileArray = Array.from(correctFiles).map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      setDiscussionPayloadFiles(correctFiles);
      setFilesPreview(fileArray);
    }
  };

  const handleDeleteFile = (fileName: string) => {
    setFilesPreview((prevFiles) =>
      prevFiles?.filter((file: any) => file?.name !== fileName)
    );
    setDiscussionPayloadFiles((prevFiles) =>
      prevFiles?.filter((file: any) => file?.name !== fileName)
    );
  };

  const isCorrectFile = (file: any) => {
    const acceptFile = [
      //image
      "image/jpeg",
      "image/jpg",
      "image/png",
      // pdf
      "application/pdf",
      // excel
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // word
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // powerpoint
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    const isJpgOrPng = acceptFile.includes(file.type);
    const isCorrectFile = file.size < MAX_FILE_UPLOAD_SIZE;
    if (isJpgOrPng && isCorrectFile) {
      return true;
    } else {
      setIsDirtyFile(true);
      return false;
    }
  };

  const resetFiles = () => {
    setFilesPreview([]);
    setDiscussionPayloadFiles([]);
  };

  return {
    filesPreview,
    discussionPayloadFiles,
    handleChangeFiles,
    handleDeleteFile,
    resetFiles,
    isCorrectFile,
  };
}

export default useUploadFile;
