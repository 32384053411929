import { Dayjs } from "dayjs";
import { isEmpty } from "lodash";
import moment from 'moment-timezone';

export const formatDate = (date: any) => {
  return moment(date).format("DD-MM-yyyy");
};

export const formatDateToIsoString = (date: string) => {
  return moment(date).format("yyyy-MM-DD");
};

export const formatDateToIsoStringHHss = (date: string) => {
  return moment(date).format('HH:mm:ss DD/MM/YYYY')
};

export const formatDateV2 = (date: string) => {
  return moment(date).tz(moment.tz.guess()).format("DD/MM/yyyy");
};

export const formatTimeHm = (date: string) => {
  return moment.utc(date).format("HH:mm");
};

export const formatDateTime = (
  date: Dayjs | null,
  time: Dayjs | null
): string => {
  if (date && time) {
    return date.format("YYYY-MM-DD") + "T" + time.format("HH:mm:ss") + "Z";
  }
  return "";
};

export const createDisabledTime = (start: Dayjs | null): any => {
  if (!start) {
    return {}; // Trả về một đối tượng rỗng nếu không có startTime
  }
  const startHour = start.hour();
  const startMinute = start.minute();
  return {
    disabledHours: () => Array.from(Array(startHour).keys()),
    disabledMinutes: () => Array.from(Array(startMinute).keys()),
  };
};

export const convertSecondsToMinutesAndSeconds = (seconds: number) => {
  const minutes: number = Math.floor(seconds / 60); // Chia số giây cho 60 để tính số phút
  const remainingSeconds: number = seconds % 60; // Số dư của phép chia đó chính là số giây còn lại sau khi tính số phút
  return {
    minutes: minutes,
    seconds: remainingSeconds,
  };
};

export const displayDateComment = (created: string) => {
  if (isEmpty(created)) return "";
  const startTime = new Date(created);

  const currentTime = new Date();

  const differenceInMilliseconds = +currentTime - +startTime;
  const minutes = differenceInMilliseconds / (1000 * 60);

  if (minutes < 1) {
    return "Vừa xong";
  } else if (minutes < 60) {
    return `${Math.round(minutes)} phút`;
  } else {
    const hours = minutes / 60;
    if (hours < 24) {
      return `${Math.round(hours)} giờ trước`;
    } else {
      const days = hours / 24;
      if (days > 1) {
        return `${Math.round(days)} ngày trước`;
      } else {
        return `${Math.round(hours)} giờ trước`;
      }
    }
  }
};


export const formatOriginTime = (date: string) => {
  const time = moment(date).format("DD-MM-yyyy");
  return time
}

export const formatOriginTimev2 = (date: string) => {
  const time = moment(date).subtract(7, "h").format("yyyy-MM-DD");
  return time
}
