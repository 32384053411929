// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.active-settings-chapter .radio-item {
  padding: 10px 24px; }
  .active-settings-chapter .radio-item .setting-chapter-title {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500; }
  .active-settings-chapter .radio-item .setting-chapter-description {
    margin-left: 8px;
    font-size: 14px;
    opacity: 0.7;
    font-weight: normal; }
  .active-settings-chapter .radio-item span.setting-chapter-description {
    color: #000000;
    opacity: 1;
    font-weight: 500; }
  .active-settings-chapter .radio-item .radio-item {
    opacity: 0.7; }

.active-settings-chapter .ant-collapse-item-active:last-child {
  border-bottom: 0; }

.active-settings-chapter .time-picker {
  display: flex;
  padding: 16px 8px; }
  .active-settings-chapter .time-picker .time-picker-content {
    display: flex;
    flex-direction: column;
    padding: 0 12px; }

.active-settings-chapter .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px; }

.active-settings-chapter .ant-collapse {
  border: none;
  border-radius: 0px; }

.active-settings-chapter .btn-save-setting {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  margin-top: 24px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/active-settings-chapters-drawer/activeSettingsChaptersDrawer.scss"],"names":[],"mappings":"AACA;EAGI,kBAAkB,EAAA;EAHtB;IAMM,gBAAgB;IAChB,eAAe;IACf,gBAAgB,EAAA;EARtB;IAYM,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,mBAAmB,EAAA;EAfzB;IAmBM,cAAc;IACd,UAAU;IACV,gBAAgB,EAAA;EArBtB;IAyBM,YAAY,EAAA;;AAzBlB;EA+BM,gBAAgB,EAAA;;AA/BtB;EAqCI,aAAa;EACb,iBAAiB,EAAA;EAtCrB;IAyCM,aAAa;IACb,sBAAsB;IACtB,eAAe,EAAA;;AA3CrB;EAsDI,YAAY,EAAA;;AAtDhB;EA0DI,YAAY;EACZ,kBAAkB,EAAA;;AA3DtB;EA+DI,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB,EAAA","sourcesContent":["\n.active-settings-chapter {\n\n  .radio-item {\n    padding: 10px 24px;\n\n    .setting-chapter-title {\n      margin-bottom: 0;\n      font-size: 16px;\n      font-weight: 500;\n    }\n\n    .setting-chapter-description {\n      margin-left: 8px;\n      font-size: 14px;\n      opacity: 0.7;\n      font-weight: normal;\n    }\n\n    span.setting-chapter-description {\n      color: #000000;\n      opacity: 1;\n      font-weight: 500;\n    }\n\n    .radio-item {\n      opacity: 0.7;\n    }\n  }\n\n  .ant-collapse-item-active {\n    &:last-child {\n      border-bottom: 0;\n    }\n  }\n\n\n  .time-picker {\n    display: flex;\n    padding: 16px 8px;\n\n    .time-picker-content {\n      display: flex;\n      flex-direction: column;\n      padding: 0 12px;\n    }\n  }\n\n  // .ant-collapse .ant-collapse-content{\n  //   border-bottom: none;\n  // }\n  // .ant-collapse>.ant-collapse-item{\n  //   border-bottom: none;\n  // }\n  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {\n    padding: 0px;\n  }\n\n  .ant-collapse {\n    border: none;\n    border-radius: 0px;\n  }\n\n  .btn-save-setting {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    padding-right: 20px;\n    margin-top: 24px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
