// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-registration-report .page-header-group--layout {
  height: 80px !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/account-registration-report/index.scss"],"names":[],"mappings":"AAAA;EAEI,uBAAuB,EAAA","sourcesContent":[".account-registration-report {\n  .page-header-group--layout {\n    height: 80px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
