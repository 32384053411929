import { Col, Row } from "antd";
import React from "react";
import { Draft, GroupsExam, QuizProps } from "../../../../types/scoring-course";

interface ScoreByGroupItemProps {
  group: GroupsExam;
  quizzesCompleted?: Draft[];
}

function ScoreByGroupItem({
  group,
  quizzesCompleted = [],
}: ScoreByGroupItemProps) {
  const name = group?.title;
  const points =
    group?.quiz?.reduce((total: number, item: QuizProps) => {
      const currentQuiz = quizzesCompleted?.find(
        (draft: Draft) => draft.quizId === item.id
      );
      return currentQuiz ? total + currentQuiz?.points : total;
    }, 0) || 0;
  return (
    <Row className="pl-4">
      <Col span={12}>
        <span className="secondary-color">{name}</span>
      </Col>
      <Col span={12}>
        <span className="secondary-color">{points} điểm</span>
      </Col>
    </Row>
  );
}

export default ScoreByGroupItem;
