import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getOtpForgotPassword: (
  email: string,
  otp: string
) => Promise<AxiosResponse<any>> = (email, otp) => {
  return axiosConfig.post(`/accounts/check-otp-forgot-password`, {
    email: email,
    otp: otp,
  });
};
