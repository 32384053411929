import "./detail.scss";
import { Button, Input, Radio, Row, Space, ConfigProvider, Tooltip } from "antd";
import { CloseOutlined, FileImageOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ListAnswer } from "../../../../../../../types/survey-managment/survey";
import { upLoadFileSurvey } from "../../../../../../../service/survey-managment/list-survey";
import { downloadFile } from "../../../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import TextArea from "antd/es/input/TextArea";

const QuestionType2 = (props: any) => {
  const [options, setOptions] = useState<ListAnswer[]>(props.listOptions || []);

  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);

  const [imageShowHtmls, setImageShowHtmls] = useState<string[]>([]);

  useEffect(() => {
    const fetchImageShowHtmls = async () => {
      if (props.listOptions.length > 0) {
        const imagePromises = props.listOptions.map(
          async (item: ListAnswer) => {
            if (item.answerImage) {
              return await changePathToShow(item.answerImage);
            }
            return "";
          }
        );
        const resolvedImageShowHtmls = await Promise.all(imagePromises);
        setImageShowHtmls(resolvedImageShowHtmls);
      }
    };

    fetchImageShowHtmls();
  }, [props.listOptions]);

  const changePathToShow = async (input: string) => {
    try {
      const res = await downloadFile({
        filePath: input,
        destination: "",
      });
      return res.data; // Assuming res.data is the string you want
    } catch (error) {
      console.error("Error downloading file:", error);
      return ""; // Or some default value to handle the error
    }
  };

  const onHandleDeleteOption = (index: number) => {
    const newOptions = options.filter((_, i) => i !== index);
    setOptions(newOptions);

    const newImageShowHtmls = imageShowHtmls.filter((_, i) => i !== index);
    setImageShowHtmls(newImageShowHtmls);

    props.setUpdateListAnswer(newOptions);
  };

  const onHandleAddOption = () => {
    let newOptions = [...options];
    const addedOption = {
      answerText: `Lựa chọn ảnh ${options.length + 1} `,
      orderNumber: options.length + 1,
    };
    newOptions.push(addedOption);
    setOptions(newOptions);
    props.setUpdateListAnswer(newOptions);
    setImageShowHtmls([...imageShowHtmls, ""]);
  };

  const handleFocus = () => {
    setShowPlaceholder(false);
  };

  const handleBlur = () => {
    setShowPlaceholder(true);
  };

  function selectLocalImage(index: number) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    // Listen upload local image and save to state
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;

      if (file) {
        // Kiểm tra file có phải là file hình ảnh không
        if (/^image\//.test(file.type)) {
          try {
            // Tạo FormData để gửi file
            let formData = new FormData();
            formData.append("file", file);

            // Gửi file đến server sử dụng hàm upLoadFileSurvey
            const response = await upLoadFileSurvey(formData);
            const fileData = response.data.data;

            if (
              response &&
              (response.status === 200 || response.status === 201)
            ) {
              // Cập nhật imageShowHtmls và options
              const newImageShowHtmls = [...imageShowHtmls];
              newImageShowHtmls[index] = await changePathToShow(
                fileData.fileName
              ); // Sử dụng đường dẫn của file thay vì base64String
              setImageShowHtmls(newImageShowHtmls);

              const newOptions = options.map((option, idx) => {
                if (idx === index) {
                  return {
                    ...option,
                    answerImage: fileData.fileName, // Cập nhật answerImage của option
                  };
                }
                return option;
              });
              setOptions(newOptions);

              // Gọi props.setUpdateListAnswer với newOptions
              props.setUpdateListAnswer(newOptions);

              console.log("File uploaded successfully");
            } else {
              console.error("File upload failed");
            }
          } catch (error) {
            console.error("File upload failed:", error);
          }
        } else {
          console.warn("You could only upload images.");
        }
      } else {
        console.warn("No file selected.");
      }
    };
  }

  const handleChangeAnswerText = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newOptions = [...options];
    newOptions[index].answerText = event.target.value;
    setOptions(newOptions);
    props.setUpdateListAnswer(newOptions);
  };

  useEffect(() => {
    setOptions(props.listOptions);
  }, [props.listOptions]);

  return (
    <div className="survey-managment-construction">
      <div>
        <br />
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "black",
                paddingInline: 0,
                paddingBlock: 0,
              },
              Button: {
                paddingInline: 2,
                paddingBlock: 0,
              },
            },
          }}
        >
          <Space direction="vertical" size="small" style={{ display: "flex" }}>
            {options.map((option, index) => {
              return (
                <div key={index}>
                  {!option.isOther && (
                    <Row
                      style={{
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Radio checked={false}></Radio>
                      <TextArea
                        style={{
                          flex: 1,
                          marginLeft: "10px",
                          marginRight: "10px",
                          padding: "10px 0",
                          display: "flex",
                          alignItems: "center",
                          lineHeight: "1.5",
                        }}
                        autoSize
                        variant="borderless"
                        value={
                          option.answerText || option.answerText === ""
                            ? option.answerText
                            : `Lựa chọn văn bản ${options.length}`
                        }
                        onChange={(event) =>
                          handleChangeAnswerText(event, index)
                        }
                      />
                      <>
                        {imageShowHtmls[index] && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<p><img src="${imageShowHtmls[index]}" style="width: 20px; height: 20px;"></p>`,
                            }}
                          />
                        )}
                        <Tooltip placement="top" title="Chọn ảnh">
                          <Button
                            style={{ width: 20, height: 25, marginLeft: "5px" }}
                            icon={<FileImageOutlined />}
                            onClick={() => {
                              selectLocalImage(index);
                            }}
                          />
                        </Tooltip>
                        <Tooltip placement="top" title="Xóa lựa chọn">
                          <Button
                            style={{ width: 20, height: 25, marginLeft: "5px" }}
                            icon={<CloseOutlined />}
                            onClick={() => {
                              onHandleDeleteOption(index);
                            }}
                          />
                        </Tooltip>
                      </>
                    </Row>
                  )}
                </div>
              );
            })}
            <Row
              style={{
                margin: "0 auto",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Input
                prefix={
                  <>
                    <Radio checked={false}></Radio>
                    <Button
                      type="link"
                      onClick={onHandleAddOption}
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Thêm lựa chọn
                    </Button>
                  </>
                }
                variant="borderless"
                type="submit"
              />
            </Row>

            {props.isQuestionHasOther && (
              <Row
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Input
                  prefix={
                    <>
                      <Radio checked={false}></Radio>
                      <span>
                        <i>
                          {" "}
                          Lựa chọn nhập <u>"Khác"</u>
                        </i>
                      </span>
                    </>
                  }
                  variant="borderless"
                />
              </Row>
            )}
          </Space>
        </ConfigProvider>
        <br />
      </div>
    </div>
  );
};

export default QuestionType2;

/*
  function selectLocalImage(index: number) {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    // Listen upload local image and save to state
    input.onchange = () => {
      const file = input.files ? input.files[0] : null;
      // console.log('file upload fe')
      // console.log(file)
      if (file) {
        // file type is only image.
        if (/^image\//.test(file.type)) {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            const result = reader.result;

            if (typeof result === "string") {
              const base64String = result;
              const newImageShowHtmls = [...imageShowHtmls];
              newImageShowHtmls[index] = base64String;
              const newOptions = options.map((option, idx) => {
                return {
                  ...option,
                  answerImage: newImageShowHtmls[idx] || "", // Update answerImage based on imageShowHtmls
                };
              });
              setOptions(newOptions);
              props.setUpdateListAnswer(newOptions);

              setImageShowHtmls(newImageShowHtmls);
            } else {
              console.warn("Invalid result type.");
            }
          };

          reader.onerror = (error) => {
            console.error("Error reading file:", error);
          };
        } else {
          console.warn("You could only upload images.");
        }
      } else {
        console.warn("No file selected.");
      }
    };
  }*/
