// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-list-program-drawer .dragandsort {
  width: 100%; }
  .add-list-program-drawer .dragandsort .ant-pro-table-list-toolbar {
    display: none; }
  .add-list-program-drawer .dragandsort table .ant-table-thead {
    display: none; }
`, "",{"version":3,"sources":["webpack://./src/components/drawer/add-list-program/index.scss"],"names":[],"mappings":"AAAA;EAEI,WAAW,EAAA;EAFf;IAIM,aAAa,EAAA;EAJnB;IAQQ,aAAa,EAAA","sourcesContent":[".add-list-program-drawer {\n  .dragandsort {\n    width: 100%;\n    .ant-pro-table-list-toolbar {\n      display: none;\n    }\n    table {\n      .ant-table-thead {\n        display: none;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
