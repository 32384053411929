import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { FormEvent } from "react";

export const UpdateSuccess = (
  timeLeftLocate: number,
  loadingLogin: boolean,
  backToLogin: (id: string) => void,
  handleAddClassShow: (id: string) => void,
  setVerifyMail: React.Dispatch<React.SetStateAction<string>>,
  setOTPCode: React.Dispatch<React.SetStateAction<string>>,
  setTimeLeftLocate: React.Dispatch<React.SetStateAction<number>>
) => {
  const handleNavigate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    backToLogin("update-success");
    handleAddClassShow("login-card");
    setVerifyMail("");
    setOTPCode("");
    setTimeLeftLocate(-1)
  };

  return (
    <div id="update-success" className="login-card__item">
      <form
        className="form-and-button abomination-view"
        onSubmit={handleNavigate}
      >
        <div className="success-message-container">
          <CheckCircleFilled className="icon-success" />
          <div className="success-message">Cập nhật mật khẩu thành công</div>
          <Typography.Paragraph className="redirect-message">
            Bạn sẽ được chuyển hướng đến màn đăng nhập trong{" "}
            <span style={{ color: "#1890ff" }}>{timeLeftLocate}</span> giây
          </Typography.Paragraph>
        </div>
        <Button
          htmlType="submit"
          className="button11"
          type="primary"
          loading={loadingLogin}
        >
          Đi đến đăng nhập
        </Button>
      </form>
    </div>
  );
};
