import {
  CloudSyncOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Pagination,
  PaginationProps,
  Popconfirm,
  Space,
  Switch,
  Table,
  TableColumnsType,
  message,
} from "antd";
import React, { FunctionComponent, useState } from "react";
import "./TableOrganizeAppraisalBoard.scss";
import { get } from "lodash";
import { HelpService } from "../../../../../../service/helper.service";
import { changeActive } from "../../../../../../service/course-plan-managment/specialized-group/specializedGroupApi";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../../../../../config/routes";
import moment from "moment";
type TableData = {
  organization_code: string;
  organization_name: string;
  created_date: string;
  active: boolean;
  status: string;
  users: Array<{
    id: number;
    name: string;
    image_path: string | null;
  }>;
};
type TableSpecializedGroupProps = {
  data?: TableData[];
  totalRecords?: any;
  pageNumber?: number;
  pageSize?: number;
  setPageNumber?: (pageNumber: number) => void;
  setPageSize?: (pageNumber: number) => void;
  searchValue?: string;
  handleFinish?: any;
  setIdDetail?: any;
  setIsOpened?: any;
  setTitleForm?: any;
  setTitleConfirm?: any;
  setCreateOrUpdateText?: any;
  getDetailSpecializedGroupData?: any;
  deleteSpecializedGroup?: any;
};
const TableOrganizeAppraisalBoard: FunctionComponent<
  TableSpecializedGroupProps
> = ({
  data,
  totalRecords,
  pageSize,
  pageNumber,
  setPageNumber,
  setPageSize,
  searchValue,
  handleFinish,
  setIsOpened,
  setIdDetail,
  setTitleForm,
  setTitleConfirm,
  setCreateOrUpdateText,
  getDetailSpecializedGroupData,
  deleteSpecializedGroup,
}) => {
  const navigate = useNavigate();

  const helpService = new HelpService();
  const [totalPage, setTotalPage] = useState(totalRecords);
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
      setTotalPage(totalRecords);
    }
  };
  const pageNumberProps = pageNumber ?? 10;
  const pageSizeProps = pageSize ?? 1;
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  const options1: MenuProps["items"] = [
    {
      key: "1",
      label: "Sửa hội đồng",
      icon: <EditOutlined />,
    },
    {
      key: "2",
      label: "Quản lý thành viên",
      icon: <SendOutlined />,
    },
    {
      key: "3",
      label: "Xóa hội đồng",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];
  const locale = {
    emptyText: (
      <span>
        <p>
          <img
            className="image-empty-data"
            src="/images/empty-img-gray.png"
            alt="empty-img"
          ></img>
        </p>
        <p className="nodata-text">
          Không tìm kết quả với từ khoá "{searchValue}"
        </p>
      </span>
    ),
    triggerDesc: "Sắp xếp theo thứ tự Z-A",
    triggerAsc: "Sắp xếp thứ tự A-Z",
    cancelSort: "Huỷ sắp xếp",
  };
  const formatDateV2 = (date: string) => {
    return moment(date, "YYYY-MM-DDTHH:mm:ss").format("DD/MM/YYYY");
  };
  const newColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record: any, index: number) => {
        const orderNumber = (pageNumberProps - 1) * pageSizeProps + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Mã hội đồng",
      width: "9%",
      dataIndex: "organization_code",
    },
    {
      title: "Tên hội đồng",
      width: "25%",
      dataIndex: "organization_name",
      key: "organization_name",
      sorter: {
        compare: (a, b) =>
          a.organization_name.localeCompare(b.organization_name),
      },
    },
    {
      title: "Trạng thái",
      width: "15%",
      dataIndex: "status",
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.status === "Chưa có thành viên"
                    ? "#D9D9D9"
                    : record.status === "Đã có thành viên"
                    ? "#52C41A"
                    : "white",
              }}
            ></div>
            {record.status}
          </div>
        );
      },
    },

    {
      title: "Ngày tạo",
      width: "10%",
      dataIndex: "created_date",
      render: (_: any, record: { created_date: Date }) => {
        return (
          <>
            <span>
            {moment(record?.created_date).format('DD/MM/YYYY')}
            </span>
          </>
        );
      },
    },
    {
      title: "Thành viên",
      dataIndex: "members",
      key: "members",
      width: "15%",
      render: (_: any, record) => {
        const members = record.members;
        if (members.length === 0) {
          return null;
        } else if (members.length === 1) {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                style={{ backgroundColor: "orange", marginRight: "6px" }}
                src={members[0].image_path}
              >
                {members[0].name
                  .split(" ")
                  .map((word: string) => word[0])
                  .join("")}
              </Avatar>
              <span className="avatar-name">{members[0].name}</span>
            </div>
          );
        } else if (members.length === 2 || members.length === 3) {
          return (
            <Avatar.Group
              maxCount={3}
              maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
            >
              {members.map((member: any) => (
                <Avatar
                  key={member.user_id}
                  style={{ backgroundColor: "orange" }}
                  src={member.image_path}
                >
                  {member.name
                    .split(" ")
                    .map((word: string) => word[0])
                    .join("")}
                </Avatar>
              ))}
            </Avatar.Group>
          );
        } else {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar.Group
                maxCount={3}
                maxStyle={{
                  color: "#f56a00",
                  backgroundColor: "#fde3cf",
                  cursor: "pointer",
                }}
              >
                {members.map((member: any) => (
                  <Avatar
                    key={member.user_id}
                    style={{ backgroundColor: "orange" }}
                    src={member.image_path}
                  >
                    {member.name
                      .split(" ")
                      .map((word: string) => word[0])
                      .join("")}
                  </Avatar>
                ))}
              </Avatar.Group>
            </div>
          );
        }
      },
    },

    {
      title: "Tình trạng",
      dataIndex: "active",
      key: "active",
      render: (_: any, record: { id: number; active: boolean }) => {
        const handleChange = (checked: boolean) => {
          record.active = checked;
        };

        const confirm = (id: number) => {
          changeActive(id.toString(), { active: record.active }).then((res) => {
            console.log(res);
            const statusCode = res.status;

            if (statusCode === 200) {
              message.success("Thay đổi trạng thái thành công");
              handleFinish();
            }
          });
        };
        const cancel = () => {};
        return (
          <Popconfirm
            description={`Xác nhận đổi tình trạng thành ${
              record.active ? "“Không hoạt động”?" : "“Hoạt động“?"
            }`}
            onConfirm={() => confirm(get(record, "id"))}
            onCancel={cancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            title=""
          >
            <Switch
              defaultChecked={record.active}
              checked={record.active}
              onChange={handleChange}
            />
          </Popconfirm>
        );
      },
      width: "12%",
    },

    {
      title: "",
      width: "5%",
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(
                    `${routesConfig.detailOrganizeAppraisalBoard}/${record.id}`,
                    {
                      state: { record, mode: "view" },
                    }
                  )
                }
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: options1,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      setIdDetail(record.id);
                      setIsOpened(true);
                      setTitleForm("Sửa hội đồng thẩm định");
                      setTitleConfirm("Xác nhận Sửa hội đồng thẩm định");
                      setCreateOrUpdateText("Sửa hội đồng thẩm định");
                      getDetailSpecializedGroupData(record.id);
                    }
                    if (key === "2") {
                      navigate(
                        `${routesConfig.detailOrganizeAppraisalBoard}/${record.id}`,
                        {
                          state: { record, mode: "edit", numberTab: "2" },
                        }
                      );
                    }
                    if (key === "3") {
                      Modal.confirm({
                        title: `Xoá hội đồng thẩm định ${record.organization_name}`,
                        content: `Hội đồng thẩm định đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá hội đồng thẩm định?`,
                        onOk: () => deleteSpecializedGroup(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];
  return (
    <div className="course-plan-managment-table-organize">
      <div className="table-wrapper">
        <Table
          size="middle"
          tableLayout="auto"
          pagination={false}
          columns={newColumns}
          dataSource={data}
          rowKey={(obj) => obj.id}
          scroll={{ x: 1500, y: 600 }}
          locale={locale}
        />
        <Pagination
          total={totalRecords}
          showSizeChanger
          pageSize={pageSize}
          current={pageNumber}
          className="pagination-table"
          onChange={onChange}
          itemRender={itemRender}
          locale={{
            items_per_page: "/ trang",
            jump_to: "Đi đến trang",
            page: "",
          }}
          showQuickJumper
          showTotal={(total) => `Tổng số ${total} bản ghi`}
        />
      </div>
    </div>
  );
};

export default TableOrganizeAppraisalBoard;
