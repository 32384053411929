import { Layout, message } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../../../../components/page-header-common/PageHeaderCommon";
import HeadQualityEvaluation from "./HeadQualityEvaluation";
import TableQualityEvaluation from "./TableQualityEvaluation";
import "./quality-evalution.scss";
import { useState } from "react";
import { routesConfig } from "../../../../../../config/routes";
import { useStudyManagementStore } from "../../../../../../stores/stores";
const { Content } = Layout;
const imgPath = "/images/";

const QualityEvaluation = () => {
  const navigate = useNavigate();
  const [rate, setRate] = useState({
    title: "",
    idRate: "",
    total: 0,
    attendance: 0,
    rating: 0,
    idReport: "",
  });
  const [headValue, setHeadValue] = useState<any>([]);
  const { setTabAttendance } = useStudyManagementStore((state) => state);
  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();

  const listBreadcrumb = [
    {
      title: "Quản lý điểm danh",
    },
    {
      title: "Danh sách báo cáo",
    },
    {
      title: rate?.title,
    },
    {
      title: "Đánh giá chất lượng",
    },
  ];

  const handleBack = () => {
    setTabAttendance("3")
    navigate(`/management-study/${routesConfig.settingRemindCheckInDetail}/${rate?.idReport}`)
  }

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Đánh giá chất lượng"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={handleBack}
      />
      <HeadQualityEvaluation rateHead={rate} headValue={headValue} setRate={setRate} />
      <TableQualityEvaluation
        setRate={setRate}
        idDocument={params?.id as string}
        setHeadValue={setHeadValue}
      />
    </Content>
  );
};

export default QualityEvaluation;
