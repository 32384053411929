import React, { useEffect, useMemo, useState } from "react";
import DetailCoursePlanAssign from "./detail-course-plan-assign/DetailCoursePlanAssign";

import {
  getListMoocCourseDescriptionDocumentById,
  getListMoocCourseStructureById,
  getListMoocCourseStructureChapterById,
  getMoocCourseConstructionPlanById,
} from "../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { message } from "antd";
import {
  TypeCourseStructure,
  TypeChapterList,
  TypeSubjectList,
  TypeCourseConstructionPlan,
  TypeCoursePlanAssignment,
  DataTreeNodePlanAssignment,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { getListCoursePlanAssignById } from "../../../../../service/course-plan-managment/mooc-course-planning-assignment";
import DetailCoursePlanAssignV2 from "./detail-course-plan-assign/DetailCoursePlanAssignV2";

interface PlanAssignmentManyProps {
  listCoursePlan: Number[];
  dataCoursePlanAssignment: TypeCoursePlanAssignment[];
  setDataCoursePlanAssignment: (data: TypeCoursePlanAssignment[]) => void;
}

const PlanAssignmentMany: React.FC<PlanAssignmentManyProps> = ({
  listCoursePlan,
  dataCoursePlanAssignment,
  setDataCoursePlanAssignment,
}) => {

  const [dataCoursePlan, setDataCoursePlan] = useState<
    TypeCourseConstructionPlan[]
  >([]); //DetailTypeMoocCourseConstructionPlan
  const [dataListCourseStructure, setDataListCourseStructure] = useState<
    TypeChapterList[]
  >([]);
  const [dataListChapter, setDataListChapter] = useState<TypeChapterList[]>([]);
  const [dataTreeNode, setDataTreeNode] = useState<
    DataTreeNodePlanAssignment[]
  >([]);

  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 1)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level2 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 2)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level3 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 3)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );

    const chapterList: TypeChapterList[] = level1.map((l1, index) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        orderNumber: l1.orderNumber,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2
        .filter((l2) => l2.parentId === l1.id)
        .sort((a, b) =>
          a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
        );

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject, index) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          orderNumber: subject.orderNumber,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3
          .filter((l3) => l3.parentId === subject.id)
          .sort((a, b) =>
            a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
          );

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson, index) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          orderNumber: lesson.orderNumber,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });
    return chapterList;
  };

  // console.log(`dataCoursePlanAssignment:::`, dataCoursePlanAssignment)

  // useEffect(()=>{}, [dataCoursePlanAssignment])

  const getDetailMoocCourseConstructionPlanData = async (id: any) => {
    try {
      const res = await getListCoursePlanAssignById(id);
      const getAllData = res.data.data;
      /*
        courseConstructionPlan,
        listCourseStructure,
        listCourseStructureChapter,
        listCoursePlanAssignment,
      */

      const addNewDataTree = {
        plan: getAllData.courseConstructionPlan,
        courseStructure: changeToChapterList(getAllData.listCourseStructure),
        courseChapter: getAllData.listCourseStructureChapter,
        courseAssignment: getAllData.listCoursePlanAssignment,
      };

      let neww =  getAllData.listCoursePlanAssignment;
      // console.log(`newww:::`, neww)
      dataCoursePlanAssignment=dataCoursePlanAssignment.concat(neww)

      setDataCoursePlanAssignment(dataCoursePlanAssignment);

      setDataTreeNode((prev) => [...prev, addNewDataTree]);
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const getAllCoursePlanDetail = (listCoursePlan: Number[]) => {
    setDataTreeNode([]);
    setDataCoursePlanAssignment([]);
    listCoursePlan.forEach((id) => {
      getDetailMoocCourseConstructionPlanData(id);
    });
  };

  useEffect(() => {
    getAllCoursePlanDetail(listCoursePlan);
  }, [listCoursePlan]);

  return (
    <div>
      <DetailCoursePlanAssignV2
        dataCoursePlan={dataCoursePlan}
        dataListCourseStructure={dataListCourseStructure}
        dataListChapter={dataListChapter}
        dataCourseAssignment={dataCoursePlanAssignment}
        setDataCoursePlanAssignment={setDataCoursePlanAssignment}
        dataTreeNode={dataTreeNode}
      />
    </div>
  );
};

export default PlanAssignmentMany;
