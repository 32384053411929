import { Layout, message } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import { getListStudentExam } from "../../../../service/auto-score";
import HeadRateScore from "./HeadRateScore";
import TableRateScore from "./TableRateScore";
import "./score-evalution.scss";
import { isEmpty } from "lodash";

const { Content } = Layout;
const imgPath = "/images/";

const RateAutoScore = () => {
  const navigate = useNavigate();
  const [rate, setRate] = useState({
    title: "",
    idRate: "",
    total: 0,
    attendance: 0,
    rating: 0,
    idReport: "",
  });
  const [headValue, setHeadValue] = useState<any>([]);
  const [titleBreadcrumb, setTitleBreadcrumb] = useState<any>("");

  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [listScoreStudent, setListScoreStudent] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [qualityAutoScore, setQualityAutoScore] = useState<any[]>([]);

  const fetchRateData = async (searchParam: any) => {
    try {
      const res = await getListStudentExam(searchParam, Number(params?.id));
      const { data } = res.data;

      const studentLv1 = !isEmpty(data?.mooc_score_statistics) ? data?.mooc_score_statistics[0].type_a : 0
      const studentLv2 = !isEmpty(data?.mooc_score_statistics) ? data?.mooc_score_statistics[0].type_b : 0
      const studentLv3 = !isEmpty(data?.mooc_score_statistics) ? data?.mooc_score_statistics[0].type_c : 0
      const studentLv4 = !isEmpty(data?.mooc_score_statistics) ? data?.mooc_score_statistics[0].type_d : 0
      const step = !isEmpty(data?.mooc_score) ? data?.mooc_score[0].total_point / 4 : 0

      const rate1 = !isEmpty(data?.mooc_score_statistics) ? data?.mooc_score_statistics[0].type_a / data?.mooc_score.length : 0
      const rate2 = !isEmpty(data?.mooc_score_statistics) ? data?.mooc_score_statistics[0].type_b / data?.mooc_score.length : 0
      const rate3 = !isEmpty(data?.mooc_score_statistics) ? data?.mooc_score_statistics[0].type_c / data?.mooc_score.length : 0
      const rate4 = !isEmpty(data?.mooc_score_statistics) ? data?.mooc_score_statistics[0].type_d / data?.mooc_score.length : 0


      setListScoreStudent([
        {scoreLevel: `0 - ${step}`, student: studentLv4, rate:  Math.round(rate4 * 100)},
        {scoreLevel: `${step} - ${step * 2}`, student: studentLv3, rate: Math.round(rate3 * 100) },
        {scoreLevel: `${step * 2} - ${step * 3}`, student: studentLv2, rate: Math.round(rate2 * 100)},
        {scoreLevel: `${step * 3} - ${step * 4}`, student: studentLv1, rate: Math.round(rate1 * 100)},
      ]);
      setTotalPage(data?.total?._count?.id);
      setQualityAutoScore(data?.mooc_score);
      setRate({
        title: data?.name || "",
        idRate: data?.id,
        total: data.data?.total,
        attendance: data?.attendance,
        rating: data?.rating || 0,
        idReport: data?.mooc_course?.id,
      });
      setTitleBreadcrumb(
        data?.mooc_course_block?.mooc_course_unit?.mooc_course_sequence?.name
      );
      setHeadValue([
        {
          label: "Tên khoá học",
          value: data?.mooc_course?.name,
        },
        {
          label: "Bài thi",
          value:
            data?.mooc_course_block?.mooc_course_unit?.mooc_course_sequence
              ?.name,
        },
        {
          label: "Mã bài thi",
          value: data?.code,
        },
        {
          label: "Số lượng sinh viên",
          value: `${data?.mooc_score?.length || 0}`,
        },
        {
          label: "Thang điểm",
          value: !isEmpty(data?.mooc_score) ? data?.mooc_score[0]?.total_point : 0,
        },
      ]);
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    fetchRateData({
      search: "",
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      sort: "desc",
    });
  }, [pageSize, pageNumber]);

  const listBreadcrumb = [
    {
      title: "Quản lý chấm điểm tự động",
    },
    {
      title: titleBreadcrumb,
    },
    {
      title: "Quản lý bảng điểm",
    },
    {
      title: "Đánh giá chất lượng",
    },
  ];

  const handleBack = () => {
    // navigate(`/${routesConfig.managementListScore}/75`);
    navigate(-1);
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Đánh giá chất lượng chấm điểm tự động"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={handleBack}
      />
      <HeadRateScore
        rateHead={rate}
        headValue={headValue}
        setRate={setRate}
        idRateScore={params?.id}
        listScoreStudent={listScoreStudent}
        loading={loading}
      />
      <TableRateScore
        qualityAutoScore={qualityAutoScore}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        pageSize={pageSize}
        loading={loading}
        totalPage={totalPage}
      />
    </Content>
  );
};

export default RateAutoScore;
