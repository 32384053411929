export const dataStatus = [
  {
    value: true,
    label: "Hoạt động"
  },
  {
    value: false,
    label: "Không hoạt động"
  }
]


export const validateFieldCreate = ['fullName', 'email', 'donViCongTacId', 'vaiTroIds', 'trangThai', 'chucVuIds']

export const validateFieldFilter = ['donVis', 'vaiTros', 'trangThai']
