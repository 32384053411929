import {
  Badge,
  Form,
  Layout,
  Tabs,
  TabsProps,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/page-header/PageHeader";
import CourseApproval from "./components/detail-approval-plan";

const { Content } = Layout;
import { get } from "lodash";
import CustomCard from "../../../components/custom-card/CustomCard";
import PlanInformation from "../../../components/course-construction/assign-course-construction/plan-information";
import { DetailContent } from "../../survey-managment/survey-construction/detail-survey/component/detail-content/DetailContent";
import {
  getCourseApprovalInfomationById,
  getListMoocCourseDescriptionDocumentById,
  getListMoocCourseStructureById,
  getListMoocCourseStructureChapterById,
} from "../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { useLocation, useParams } from "react-router-dom";
import {
  courseStatus,
  courseStatusApprasal,
  courseType,
} from "./constants/data";
import DetailConfirm from "./components/detail-confirm/DetailConfirm";
import { postHistoryApproval } from "../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import {
  TypeChapterList,
  TypeCourseDescriptionDocument,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../types/course-plan-managment/mooc-course-construction-plan";
import { formatDateV2 } from "../../../utils/converDate";
import PreviewCourseStructure from "../mooc-course-construction-plan/detail-course-plan/component/preview-course-structure/PreviewCourseStructure";
import PreviewDescriptionDocument from "../mooc-course-construction-plan/detail-course-plan/component/preview-description-document/PreviewDescriptionDocument";
type Props = {
  title: string;
  contentConfirm: string;
  messageSuccess: string;
  navigationUrl: string;
};
type InforUser = {
  userName: string;
  approvalDate: string;
};
const imgPath = "../../../../public/images/arrowleft.svg";
const ApprovalPlanDetail = (props: Props) => {
  const { title, contentConfirm, messageSuccess, navigationUrl } = props;
  const params = useParams();
  const [activeKey, setActiveKey] = useState("1");
  const [listInfo, setListInfo] = useState<any[]>();
  const [detailData, setDetailData] = useState<any>({});
  const [checkAppraisal, setCheckAppraisal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const { state } = location;
  const [dataListCourseStructure, setDataListCourseStructure] = useState<
    TypeChapterList[]
  >([]);
  const [listChapter, setListChapter] = useState<TypeCourseStructure[]>([]);
  const [dataDescriptionDocument, setDataDescriptionDocument] = useState<
    TypeCourseDescriptionDocument[]
  >([]);
  const [infoQTKH, setInfoQTKH] = useState<InforUser>({
    userName: "",
    approvalDate: "",
  });
  const handleTabChange = (key: any) => {
    setActiveKey(key);
  };
  const getlistData = () => {
    const data = {
      pageNumber: 1,
      pageSize: 10,
    };
    postHistoryApproval(get(params, "id", ""), data)
      .then((res) => {
        const { data } = res.data.data;

        const result = data
          .filter((item: any) => {
            const key = Object.keys(item)[0];
            return item[key].groupName === null;
          })
          .map((item: any) => {
            const key = Object.keys(item)[0];
            return item[key];
          });
        const itemSet = {
          userName: result[0]?.userName,
          approvalDate: formatDateV2(result[0]?.approvalDate),
        };
        setInfoQTKH(itemSet);
        console.log("Item", result[0].userName);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setCheckAppraisal(location.pathname.includes("appraisal"));
    getDetailMoocSurveyData(params.id);
    getListMoocCourseStructureByIdData(params.id);
    getListMoocCourseDescriptionDocumentByIData(params.id);
    getListMoocCourseStructureChapterByIdData(params.id);
    getlistData();
  }, []);

  const getDetailMoocSurveyData = async (id: any) => {
    await getCourseApprovalInfomationById(id)
      .then((res) => {
        const data = res.data.data;
        setDetailData(data);
      })
      .catch((err) => {
        console.log(err);
        message.error("Có lỗi chưa xác định");
      });
  };
  const renderSwitch = (value: string) => {
    switch (value) {
      case "registrationName":
        return <div>Tên kế hoạch</div>;
      case "registrationCode":
        return <div>Mã kế hoạch</div>;
      case "constructionPlanStatus":
        return <div>Trạng thái</div>;
      case "industryName":
        return <div>Khoa</div>;
      case "industryGroupName":
        return <div>Chuyên ngành</div>;
      case "hostUniversityName":
        return <div>Trường chủ trì</div>;
      case "cooperativeUniversityName":
        return <div>Trường hợp tác</div>;
      case "courseType":
        return <div>Loại khoá học</div>;
      default:
        return "";
    }
  };
  const getListMoocCourseStructureByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureById(id)
        .then((res) => {
          const data = res.data.data;
          setDataListCourseStructure(changeToChapterList(data));
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 1)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level2 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 2)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );
    const level3 = dataCourseStructure
      .filter((item) => item.structureItemLevel === 3)
      .sort((a, b) =>
        a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
      );

    const chapterList: TypeChapterList[] = level1.map((l1, index) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        orderNumber: l1.orderNumber,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2
        .filter((l2) => l2.parentId === l1.id)
        .sort((a, b) =>
          a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
        );

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject, index) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          orderNumber: subject.orderNumber,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3
          .filter((l3) => l3.parentId === subject.id)
          .sort((a, b) =>
            a.orderNumber && b.orderNumber ? a.orderNumber - b.orderNumber : 0
          );

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson, index) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          orderNumber: lesson.orderNumber,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });

    return chapterList;
  };

  const getListMoocCourseDescriptionDocumentByIData = async (id: any) => {
    {
      try {
        await getListMoocCourseDescriptionDocumentById(id)
          .then((res) => {
            const data = res.data.data;

            setDataDescriptionDocument(data);
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });
      } catch (error) {
        console.log(error);
        message.error("Có lỗi chưa xác định");
      }
    }
  };
  const getListMoocCourseStructureChapterByIdData = async (id: any) => {
    try {
      await getListMoocCourseStructureChapterById(id)
        .then((res) => {
          const data = res.data.data;

          setListChapter(data);
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    const convertObjectData = Object.entries(detailData);
    const listInfo = convertObjectData.slice(1).map((item: any, index) => (
      <div className="list-item" key={index}>
        <Typography.Title level={5} className="list-item--title">
          {renderSwitch(item[0])}
        </Typography.Title>
        <div className="list-item--description">
          <Typography.Text className="content">
            {item[0] === "constructionPlanStatus" ? (
              <>
                <Badge
                  key={courseStatus.filter((x) => x.value == item[1])[0].color}
                  color={
                    courseStatus.filter((x) => x.value == item[1])[0].color
                  }
                />
                {checkAppraisal ? (
                  <span>
                    {" "}
                    {
                      courseStatusApprasal.filter((x) => x.value == item[1])[0]
                        .text
                    }
                  </span>
                ) : (
                  <span>
                    {" "}
                    {courseStatus.filter((x) => x.value == item[1])[0].text}
                  </span>
                )}

                {/* <span>
                    {" "}
                    {state?.typeApproval === "2" ? "Tổ chuyên môn đã phê duyệt" : "Chờ duyệt"}
                  </span> */}
              </>
            ) : item[0] === "courseType" ? (
              courseType.filter((x) => x.value == item[1])[0].text
            ) : (
              item[1]
            )}
          </Typography.Text>
        </div>
      </div>
    ));
    setListInfo(listInfo);
  }, [detailData]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: (
        <DetailContent>
          <CustomCard
            title={
              <div className="card-heading--info">
                <Typography.Title level={5}>
                  Thông tin kế hoạch khoá học
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Thông tin chung về kế hoạch xây dựng khoá học cần phê duyệt
                </Typography.Text>
              </div>
            }
            className="card-info--wrapper"
          >
            <div className="list-wrapper">{listInfo}</div>
          </CustomCard>
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: "Cấu trúc khoá học",
      children: (
        <DetailContent>
          <PreviewCourseStructure
            dataCourseStructure={dataListCourseStructure}
          />
        </DetailContent>
      ),
    },
    {
      key: "3",
      label: "Tài liệu mô tả",
      children: (
        <DetailContent>
          <PreviewDescriptionDocument
            listChapter={listChapter}
            dataDescriptionDocument={dataDescriptionDocument}
          />
        </DetailContent>
      ),
    },
    {
      key: "4",
      label: "Kết quả duyệt",
      children: <DetailConfirm />,
    },
  ];
  const listBreadcrumb = [
    {
      title: "Kế hoạch khoá học",
    },
    {
      title: "Phê duyệt khoá học",
    },
    {
      title: `${detailData.registrationName ?? ""}`,
    },
  ];
  const listBreadcrumbAppraisal = [
    {
      title: "Kế hoạch khoá học",
    },
    {
      title: "Thẩm định khoá học",
    },
    {
      title: `${detailData.registrationName ?? ""}`,
    },
  ];

  return (
    <>
      <Content>
        <PageHeader
          listBreadcrumb={
            checkAppraisal ? listBreadcrumbAppraisal : listBreadcrumb
          }
          title={`${
            state.historyCheck ? "Lịch sử phê duyệt" : "Xem chi tiết"
          } ${detailData.registrationName ?? ""}`}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        {state.view ? (
          <div className="tabs-heading ">
            <Tabs
              defaultActiveKey={state.historyCheck ? "4" : "1"}
              items={items}
            />
          </div>
        ) : (
          <div className="tabs-heading approval-tabs-heading">
            <div className="approval-tabs-container">
              <Tabs
                defaultActiveKey={state.historyCheck ? "4" : "1"}
                items={items}
              />
            </div>
            <CourseApproval
              title={title}
              contentConfirm={contentConfirm}
              messageSuccess={messageSuccess}
              navigationUrl={navigationUrl}
              listInfor={infoQTKH}
            />
          </div>
        )}
      </Content>
    </>
  );
};

export default ApprovalPlanDetail;
