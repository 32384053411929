import { FunctionComponent, useEffect, useState } from "react";
import "../../../../common/style/theme/index.scss";
import "./index.scss";
import {
  Button,
  Col,
  Layout,
  Row,
  Modal,
  message,
  Checkbox,
  Space,
  Image,
  Radio,
  Card,
} from "antd";
import PageHeader from "../../../../../../components/page-header/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAppStore,
  useAuthStore,
  useSurveyDetail,
  useSurveyRespond,
} from "../../../../../../stores/stores";
import DetailSection from "./component/detail-section/DetailSection";

import { getQuestionSurveyPreview } from "../../../../../../service/survey-managment/mooc-survey-respond/mooc-survey-respond-api";
import { FileDoneOutlined } from "@ant-design/icons";
import RightControl from "./component/right-control/RightControl";
import themeSurvey from "../../../../common/constant/theme-survey.const";
import { RadioChangeEvent } from "antd/lib";
import { saveThemeSurvey } from "../../../../../../service/survey-managment/list-survey";
import SurveyDescription from "./component/survey-description/DetailSection";
import { downloadFile } from "../../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import CustomCardSurveyResponse from "./component/custom-card/CustomCardSurveyResponse";
interface DetailSectionProps {
  dataTheme: string;
  handeChangeTheme?: (value: string) => void;
  mode: boolean;
}

const SurveyRespond: React.FC<DetailSectionProps> = ({
  dataTheme,
  handeChangeTheme,
  mode,
}) => {
  const navigate = useNavigate();
  const { id: surveyId } = useParams();
  const { setSurveyData } = useSurveyRespond();
  const surveyData = useSurveyRespond((state) => state.surveyData);
  const [surveyName, setSurveyName] = useState<string>("");
  const [surveyDescription, setSurveyDescription] = useState<string>("");
  const [theme, setThemme] = useState<string>(dataTheme);
  const [toggel, setToggel] = useState<boolean>(true);
  const imagePattern = /\.(jpg|jpeg|png)(\?.*)?$/i;
  const videoPattern = /\.(mp4|mov|wmv|flv|avi|mkv|webm)(\?.*)?$/i;
  const isImage = (url: any) => {
    return imagePattern.test(url);
  };

  const isVideo = (url: any) => {
    return videoPattern.test(url);
  };
  const getAllSrcValues = (html: string) => {
    const srcPattern = /src="([^"]*)"/g;
    const filePattern = /bucket\/(.*?)\?X-Amz-Algorithm/;
    const matches = [];
    let match;
    while ((match = srcPattern.exec(html)) !== null) {
      const srcValue = match[1];
      const fileMatch = filePattern.exec(srcValue);
      if (fileMatch) {
        matches.push({
          file: fileMatch[1],
        });
      }
    }
    return matches;
  };
  const fetchUrl = async (url: string) => {
    const res = await downloadFile({ filePath: url });
    return res;
  };
  const descripttionFinal = (surveyInfo: any) => {
    let arr: any = [];
    if (surveyInfo && surveyInfo?.surveyDescription) {
      arr = getAllSrcValues(surveyInfo.surveyDescription);
      const fetchAllUrls = async () => {
        try {
          const promises = arr.map((item: any) => fetchUrl(item.file));
          const results = await Promise.all(promises);
          let parser: any = new DOMParser();
          let doc: any = parser.parseFromString(
            surveyInfo?.surveyDescription,
            "text/html"
          );
          let elements: any = doc.querySelectorAll("iframe, img");
          results.forEach((newDesc, index) => {
            let element = elements[index];
            if (element) {
              if (
                element.tagName.toLowerCase() === "img" &&
                isImage(newDesc.data)
              ) {
                element.src = newDesc.data;
              } else if (
                element.tagName.toLowerCase() === "iframe" &&
                isVideo(newDesc.data)
              ) {
                element.src = newDesc.data;
              }
            }
          });
          let updatedDescription = doc.body.innerHTML;
          console.log("Update", updatedDescription);
          setSurveyDescription(updatedDescription);
          // setListUrl(arr);
        } catch (error) {
          console.error("Error: ", error);
        }
      };
      fetchAllUrls();
    }
  };
  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        setSurveyData([]);
        const res = await getQuestionSurveyPreview(surveyId);
        const surveyData = res.data.data;
        setSurveyData(surveyData.data);
        setSurveyName(surveyData.surveyName);
        descripttionFinal(surveyData);
        //setSurveyDescription(surveyData.surveyDescription);
      } catch (error) {
        message.error("Đã xảy ra lỗi: " + error);
        setSurveyData([]);
        navigate("/survey-training/list-survey");
      }
    };
    fetchSurveyData();
    handelChangeTheme(theme);
  }, []);

  const handleSubmitSurveyAnswer = async () => {
    try {
      Modal.confirm({
        title: "Xác nhận lưu giao diện xem trước",
        icon: <FileDoneOutlined style={{ color: "#1890FF" }} />,
        onOk: async () => {
          try {
            saveThemeSurvey({
              surveyId: Number(surveyId),
              theme: Number(valueRadio),
            })
              .then((res) => {
                handeChangeTheme != null
                  ? handeChangeTheme(valueRadio.toString())
                  : null;
                setThemme(valueRadio.toString());
                message.success(res.data.data.message);
                setToggel(true);
                handelChangeTheme("1");
              })
              .catch((err) => {
                message.error(err.data.message);
              });
          } catch (error) {
            message.error("Thiết lập thất bại");
          }
        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    } catch (error) {
      message.error("Thiết lập giao diện");
    }
  };
  const handelChangeTheme = (value: string) => {
    let themeData = themeSurvey.filter((item) => {
      return item.value == valueRadio.toString();
    })[0];
    let elementParent = document.getElementById("themeElement");
    while (elementParent?.className != "wrapper " && elementParent) {
      elementParent = elementParent?.parentElement ?? null;
    }
    elementParent != null
      ? (elementParent.style.backgroundColor =
          themeData.WapperBackgroundColor.toString())
      : null;
    const element: HTMLCollectionOf<Element> =
      document.getElementsByClassName("ant-card-head");
    for (let i = 0; i < element.length; i++) {
      element[i].className = `ant-card-head ${themeData.themeName}`;
    }
  };
  const handelToggle = (value: boolean) => {
    setToggel(value);
  };
  const [valueRadio, setValueRadio] = useState(Number(dataTheme));

  const onChange = (value: any) => {
    setValueRadio(value);
  };

  const themes = [
    { id: 2, img: "./asset/theme1.png" },
    { id: 3, img: "./asset/theme2.png" },
    { id: 4, img: "./asset/theme3.png" },
  ];

  useEffect(() => {
    if (toggel == false) {
      setThemme("1");
    } else {
      setThemme(dataTheme);
    }
  }, [toggel]);
  return (
    <div className="survey-managment-respond" style={{ width: "100%" }}>
      {surveyData.length > 0 ? (
        <div
          style={{
            width: "100%",
            // display: "flex",
            // flexDirection: "row",
            // justifyContent: "flex-end",
          }}
        >
          <div id="themeElement">{}</div>
          <div>
            {toggel ? (
              <>
                <SurveyDescription
                  surveyName={surveyName}
                  surveyDescription={surveyDescription}
                />
                <DetailSection dataSection={surveyData} theme={theme} />
              </>
            ) : (
              <div style={{width: "100%"}}>
                <CustomCardSurveyResponse
                  title="Thiết lập xem trước khảo sát"
                  className="custom-card-survey-response"
                >
                  <Row>
                    <span style={{ marginBottom: "1rem" }}>
                      Chọn mẫu hiển thị dưới đây để áp dụng cho đợt khảo sát của
                      bạn
                    </span>
                  </Row>
                </CustomCardSurveyResponse>
                <CustomCardSurveyResponse
                  title="Mẫu mặc định"
                  className="custom-card-survey-response"
                >
                  <Row>
                    <span style={{ marginBottom: "1rem" }}>
                      Hiển thị nền trắng, mặc định cho đợt khảo sát của bạn
                    </span>
                  </Row>
                  <Row>
                    <Radio
                      key={1}
                      checked={valueRadio === 1}
                      onChange={() => onChange(1)}
                    >
                      <div>
                        <div>
                          <img src={require("./asset/image-default.png")} />
                        </div>
                      </div>
                    </Radio>
                  </Row>
                </CustomCardSurveyResponse>
                <CustomCardSurveyResponse
                  title="Mẫu khảo sát nhu cầu"
                  className="custom-card-survey-response"
                >
                  <Row wrap={true}>
                    <Space direction="horizontal">
                      {themes.map((theme) => (
                        <Radio
                          key={theme.id}
                          checked={valueRadio === theme.id} // So sánh giá trị hiện tại với giá trị được chọn
                          onChange={() => onChange(theme.id)} // Cập nhật khi có sự thay đổi
                        >
                          <div>
                            <img
                              src={require(`${theme.img}`)}
                              alt={`Theme ${theme.id}`}
                            />
                          </div>
                        </Radio>
                      ))}
                    </Space>
                    <Space direction="horizontal"></Space>
                  </Row>
                </CustomCardSurveyResponse>
                <Row
                  justify="start"
                  align="middle"
                  style={{ marginTop: 20, width: "100%" }}
                  gutter={8}
                >
                  <Col span={2}>
                    <Button
                      type="primary"
                      onClick={() => handleSubmitSurveyAnswer()}
                    >
                      Lưu
                    </Button>
                  </Col>
                  <Col span={2}>
                    <Button onClick={() => setToggel(true)}>Hủy </Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          {mode !== true && (
            <RightControl
              theme={theme}
              onChangeTheme={handelChangeTheme}
              toggel={toggel}
              onToggel={handelToggle}
            />
          )}
        </div>
      ) : (
        <CustomCardSurveyResponse
          title="Câu hỏi khảo sát"
          className="wrapper-settings--card form-setting--common card-setting"
        >
          <span>Bạn chưa tạo câu hỏi khảo sát nào</span>
        </CustomCardSurveyResponse>
      )}
    </div>
  );
};

export default SurveyRespond;
