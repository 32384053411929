import { FunctionComponent, useEffect } from "react";
import "./detail.scss";
import DetailInfo from "./components/detail-info/DetailInfo";
import { Layout, Tabs, TabsProps } from "antd";
import PageHeader from "../../components/page-header/PageHeader";
import { useLocation, useParams } from "react-router-dom";
import { HistoryOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { DetailContent } from "./components/detail-content/DetailContent";
import UserDocument from "./components/user-document/UserDocument";
import { useAppStore } from "../../stores/stores";
import { get } from "lodash";
import { getAccountDetail } from "../../service/list-account-users/listAccountUsersApi";
import { menuItems } from "../../layout/sidebar/MenuItem";
import { routesConfig } from "../../config/routes";

const { Content } = Layout;
const imgPath = "/images/";

const DetailUser: FunctionComponent = () => {
  const { accountInfo, setAccountDetail } = useAppStore((state) => {
    return {
      accountInfo: state?.accountInfo,
      setAccountDetail: state?.setAccountDetail,
    };
  });
  const location = useLocation()

  const params = useParams();

  const getDetailAccount = () => {
    getAccountDetail(get(params, "id", "")).then((res) => {
      const { data } = res.data;
      setAccountDetail(data);
    });
  };

  useEffect(() => {
    getDetailAccount();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: "Thông tin tài khoản",
      children: (
        <DetailContent>
          <DetailInfo dataUser={accountInfo} refreshData={getDetailAccount} />
        </DetailContent>
      ),
    },
    {
      key: "2",
      icon: <HistoryOutlined />,
      label: "Hồ sơ người dùng",
      children: (
        <DetailContent>
          <UserDocument dataUser={accountInfo} refreshData={getDetailAccount} />
        </DetailContent>
      ),
    },
    {
      key: "3",
      icon: <LockOutlined />,
      label: "Cài đặt",
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản lý người dùng",
    },
    {
      title: location.state?.breadCumTitle === "Quản trị cơ sở" ? <a href={`${routesConfig.listUsers}`}>Quản trị cơ sở</a> : 
	  		location.state?.breadCumTitle === "Giảng viên" ? <a href={`${routesConfig.listTeachers}`}>Giảng viên</a> : 
			location.state?.breadCumTitle === "Cấp bộ" ? <a href={`${routesConfig.listMinistryLevels}`}>Cấp bộ</a> : '',
    },
    {
      title: get(accountInfo, "hoVaTen", ""),
    },
  ];

  return (
    <>
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={get(accountInfo, "hoVaTen", "")}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <Tabs defaultActiveKey="1" items={items} />
        </div>
      </Content>
    </>
  );
};

export default DetailUser;
