import { Checkbox, Form } from "antd";
import { MenuItem } from "../../types/sidebar";
import FilterStar from "../../components/filter/FilterStar";
import { IndustryInfo, UniversityInfo } from "../../types/course";
import useMetaData from "../../hooks/useMetaData";
import FilterPrice from "../../components/filter/FilterPrice";

export interface FilterProps {
  hasPaid?: boolean | undefined;
}
export const useFilters = ({ hasPaid = false }: FilterProps) => {
  const { industries, universities } = useMetaData();

  const filterMenus: MenuItem[] = [
    {
      label: "Chuyên ngành",
      key: "industries",
      children: industries.map((item: IndustryInfo) => ({
        label: (
          <Form.Item name={`industry-${item.code}`} valuePropName="checked">
            <Checkbox>
              {item.name} {"("}
              {item.count}
              {")"}
            </Checkbox>
          </Form.Item>
        ),
        key: `industry-${item.code}`,
      })),
    },
    {
      label: "Xếp hạng",
      key: "rank",
      children: [
        {
          label: (
            <Form.Item name="rank-5" valuePropName="checked">
              <Checkbox>
                <FilterStar star={5} />
              </Checkbox>
            </Form.Item>
          ),
          key: "rank-5",
        },
        {
          label: (
            <Form.Item name="rank-4" valuePropName="checked">
              <Checkbox>
                <FilterStar star={4} />
              </Checkbox>
            </Form.Item>
          ),
          key: "rank-4",
        },
        {
          label: (
            <Form.Item name="rank-3" valuePropName="checked">
              <Checkbox>
                <FilterStar star={3} />
              </Checkbox>
            </Form.Item>
          ),
          key: "rank-3",
        },
        {
          label: (
            <Form.Item name="rank-2" valuePropName="checked">
              <Checkbox>
                <FilterStar star={2} />
              </Checkbox>
            </Form.Item>
          ),
          key: "rank-2",
        },
        {
          label: (
            <Form.Item name="rank-1" valuePropName="checked">
              <Checkbox>
                <FilterStar star={1.5} />
              </Checkbox>
            </Form.Item>
          ),
          key: "rank-1",
        },
      ],
    },
    {
      label: "Chi phí",
      key: "price",
      children: [
        {
          label: (
            <Form.Item name="price-free" valuePropName="checked">
              <Checkbox>Miễn phí</Checkbox>
            </Form.Item>
          ),
          key: "price-free",
        },
        {
          label: (
            <div>
              <Form.Item name="price-paid" valuePropName="checked">
                <Checkbox>
                  <div>Trả phí</div>
                </Checkbox>
              </Form.Item>
              {hasPaid ? (
                <div className="pr-3 pl-1">
                  <FilterPrice />
                </div>
              ) : (
                ""
              )}
            </div>
          ),
          key: "price-paid",
        },
      ],
    },

    {
      label: "Trường",
      key: "university",
      children: universities.map((item: UniversityInfo) => ({
        label: (
          <Form.Item name={`university-${item.uuid}`} valuePropName="checked">
            <Checkbox>
              {item.name} {"("}
              {item.count}
              {")"}
            </Checkbox>
          </Form.Item>
        ),
        key: `university-${item.uuid}`,
      })),
    },

    {
      label: "Lịch trình học",
      key: "schedule",
      children: [
        {
          label: (
            <Form.Item name="schedule-1" valuePropName="checked">
              <Checkbox>Theo giảng viên</Checkbox>
            </Form.Item>
          ),
          key: "schedule-1",
        },
        {
          label: (
            <div>
              <Form.Item name="schedule-2" valuePropName="checked">
                <Checkbox>
                  <div>Theo ngày đăng ký</div>
                </Checkbox>
              </Form.Item>
            </div>
          ),
          key: "schedule-2",
        },
      ],
    },
    {
      label: "Loại khoá học",
      key: "kindOfCourse",
      children: [
        {
          label: (
            <Form.Item name="kindOfCourse-1" valuePropName="checked">
              <Checkbox>Tự do ghi danh</Checkbox>
            </Form.Item>
          ),
          key: "kindOfCourse-1",
        },
        {
          label: (
            <div>
              <Form.Item name="kindOfCourse-2" valuePropName="checked">
                <Checkbox>
                  <div>Thẻ ghi danh</div>
                </Checkbox>
              </Form.Item>
            </div>
          ),
          key: "kindOfCourse-2",
        },
      ],
    },
  ];

  return filterMenus;
};
