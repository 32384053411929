import React from "react";
import { Col, Radio, Row } from "antd";
import {
  QuestionBeProps,
  QuizAnsProps,
} from "../../../../../../types/scoring-course";
import { isArray } from "lodash";

function ChooseImage({ data, showCorrect = true, result }: QuizAnsProps) {
  const currentAnsOfUser = result?.results[0];

  const renderTextQuiz = (isTrueOption: boolean, uuid: string, type = "") => {
    if (showCorrect) {
      // show result in single menu
      if (isTrueOption)
        return type === "wrap" ? "active-quiz-correct" : "text-result";
      return "";
    } else {
      // show answers of current user
      if (isArray(currentAnsOfUser)) {
        if (
          currentAnsOfUser[0]?.uuid === uuid &&
          currentAnsOfUser[0]?.isTrue === true
        )
          return (type = "wrap" ? "active-quiz-correct" : "text-result");
        if (
          currentAnsOfUser[0]?.uuid === uuid &&
          currentAnsOfUser[0]?.isTrue === false
        )
          return type === "wrap" ? "active-quiz-wrong" : "text-danger";
      }
      return "";
    }
  };

  const renderCheckAns = (isTrueOption: boolean, uuid: string) => {
    if (showCorrect) {
      if (isTrueOption) return true;
      return false;
    } else {
      // show answers of current user
      if (isArray(currentAnsOfUser) && currentAnsOfUser[0]?.uuid === uuid)
        return true;

      return false;
    }
  };

  const renderCheckStyle = (uuid: string) => {
    if (showCorrect) return "";
    else {
      // show answers of current user
      if (isArray(currentAnsOfUser)) {
        if (
          currentAnsOfUser[0]?.uuid === uuid &&
          currentAnsOfUser[0]?.isTrue === false
        )
          return "check-danger";
      }
      return "";
    }
  };

  return (
    <div className={showCorrect ? "choose-correct" : ""}>
      <Row gutter={[32, 32]}>
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: QuestionBeProps, index: number) => (
            <Col xs={24} md={12} key={quiz.uuid + index}>
              <div
                className={`image-quiz-wrap ${renderTextQuiz(
                  quiz?.isTrue,
                  quiz?.uuid,
                  "wrap"
                )}`}
              >
                <Radio
                  className={`${renderCheckStyle(quiz?.uuid)}`}
                  id={quiz.uuid + index}
                  checked={renderCheckAns(quiz?.isTrue, quiz?.uuid)}
                  disabled
                >
                  <span
                    className={`${renderTextQuiz(quiz?.isTrue, quiz?.uuid)}`}
                  >
                    {quiz?.content || "N/A"}
                  </span>
                </Radio>
                <label htmlFor={quiz.uuid + index}>
                  <img
                    className="mt-1"
                    src={quiz?.filePath || ""}
                    alt="quiz-card"
                  />
                </label>
              </div>
            </Col>
          ))}
      </Row>
    </div>
  );
}

export default ChooseImage;
