import { PlusOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useEffect, useState } from "react";
import TextEditor from "../../../text-editor/TextEditor";
import { useAddLesson } from "../../../../stores/stores";
import { ImageAnswerInfo, QuestionData, QuestionItemDetail, } from "../../../../types/course-construction";
import QuestionMultiImageItem from "./QuestionMultiImageItem";
import { SortableList } from "../../../SortableList";
import { initialChooseImageQuestionValue } from "../../../../constants";
import { convertObjectToArray, findMaxIndexOfArray, mapAnsBEtoFE } from "../../../../utils/arrays";
import { renderQuestionNameByType, renderQuestionDescriptionByType } from "../../../../utils/functionUltils";


const ChooseMultiImage = ({ data = {} as QuestionData, }: QuestionItemDetail) => {

  const question = useAddLesson((state: any) => state.question);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const [answers, setAnswers] = useState<ImageAnswerInfo[]>(convertObjectToArray(currentQuestionInfo, "questionName") || []);
  const [questionName, setQuestionName] = useState<string>("");

  const resetAnswers = () => {
    const newAnswers = convertObjectToArray(currentQuestionInfo, "questionName");
    return newAnswers;
  }

  // 0: delete
  // 1: upload image
  // 2: change text
  // 3: change display content
  const handelUpdateAnswer = (data: ImageAnswerInfo, typeAction: number) => {
    let newList = resetAnswers();
    if (typeAction === 0) {
      newList = newList.filter((item: ImageAnswerInfo) => {
        return item.id !== data.id;
      });
    } else {
      const indexOfItem = newList.findIndex((item: ImageAnswerInfo) => {
        return item.id === data.id;
      });
      if (indexOfItem !== -1) {
        if (typeAction === 1) {
          newList[indexOfItem] = {
            ...newList[indexOfItem],
            filePath: data.filePath as string,
            fileName: data.fileName as string,
          };
        }
        if (typeAction === 2) {
          newList[indexOfItem] = { ...newList[indexOfItem], value: data.value };
        }
        if (typeAction === 3) {
          if (data?.isDisplayContent)
            newList[indexOfItem] = { ...newList[indexOfItem], isDisplayContent: data?.isDisplayContent, };
        }
        if (typeAction === 4) {
          newList[indexOfItem] = { ...newList[indexOfItem], isTrue: data.isTrue, };
        }
      }
    }
    setAnswers(newList);
  };

  const handleDragChange = (value: any) => {
    setAnswers(value);
  };

  const handleChangeQuestionName = (qName: string) => {
    setQuestionName(qName);
  };

  const handleAddMoreAnswer = () => {
    if (answers.length > 0) {
      const newItem = {
        id: findMaxIndexOfArray(answers) + 1,
        key: findMaxIndexOfArray(answers) + 1,
        value: "",
        isTrue: false,
        filePath: "",
        fileName: "",
        isDisplayContent: false,
        point: 0,
      };
      const newList = [...answers].concat([newItem]);
      setAnswers(newList);
    } else {
      const initNewItems = initialChooseImageQuestionValue.map((item) => {
        return {
          ...item,
          fileName: '',
          filePath: '',
        };
      });
      setAnswers(initNewItems);
    }
  };

  useEffect(() => {

    setCurrentQuestionInfo({ ...answers, questionName: questionName });
  }, [answers, questionName]);


  useEffect(() => {
    setQuestionName(question.question);
    if (data.answers && data.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(data.answers);
      setAnswers(feAnswer);
    } else {
      setAnswers([]);
    }
  }, [question, data.answers]);

  return (
    <section className="question-wrapper">
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={questionName} onChange={handleChangeQuestionName} />
      <div className="tree-course-construct list-answers">
        <SortableList
          items={answers}
          onChange={handleDragChange}
          renderItem={(record: any) => (
            <SortableList.Item id={record.id}>
              <SortableList.DragHandle />
              <QuestionMultiImageItem
                data={record}
                handelUpdateAnswer={handelUpdateAnswer}
              />
            </SortableList.Item>
          )}
        />
        <Button
          className="btn-add-more mt-2"
          type="default"
          icon={<PlusOutlined />}
          onClick={handleAddMoreAnswer}
        >
          Thêm lựa chọn
        </Button>
      </div>
    </section>
  );
};

export default ChooseMultiImage;
