// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-settings {
  display: flex;
  justify-content: center; }
  .wrapper-settings .setting-lecturer {
    width: 50%;
    height: auto;
    gap: 16px;
    border-radius: 8px; }
    .wrapper-settings .setting-lecturer .lecturer-card {
      padding: 24px; }
      .wrapper-settings .setting-lecturer .lecturer-card .ant-card-head .ant-card-head-title {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500; }
      .wrapper-settings .setting-lecturer .lecturer-card .setting-item {
        padding: 12px 0 0 0;
        margin: 0;
        border-top: 1px solid #d9d9d9; }
        .wrapper-settings .setting-lecturer .lecturer-card .setting-item .ant-form-item-label {
          width: 30%;
          display: flex;
          justify-content: start; }
          .wrapper-settings .setting-lecturer .lecturer-card .setting-item .ant-form-item-label label {
            font-weight: 700;
            line-height: 22px; }
        .wrapper-settings .setting-lecturer .lecturer-card .setting-item .ant-form-item-control {
          width: 50%; }
        .wrapper-settings .setting-lecturer .lecturer-card .setting-item .lecturer-content {
          margin-top: 5px;
          word-break: break-all; }
      .wrapper-settings .setting-lecturer .lecturer-card .item-setting:last-child {
        padding-bottom: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/category-management/management-detail/lecturer-info/lecturer-detail/lecturerDetail.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,uBAAuB,EAAA;EAF3B;IAKM,UAAU;IACV,YAAY;IACZ,SAAS;IACT,kBAAkB,EAAA;IARxB;MAWQ,aAAa,EAAA;MAXrB;QAeY,eAAe;QACf,iBAAiB;QACjB,gBAAgB,EAAA;MAjB5B;QAsBU,mBAAmB;QACnB,SAAS;QACT,6BAA6B,EAAA;QAxBvC;UA2BY,UAAU;UACV,aAAa;UACb,sBAAsB,EAAA;UA7BlC;YAgCc,gBAAgB;YAChB,iBAAiB,EAAA;QAjC/B;UAsCY,UAAU,EAAA;QAtCtB;UA0CY,eAAe;UACf,qBAAqB,EAAA;MA3CjC;QAgDU,iBAAiB,EAAA","sourcesContent":[".wrapper-settings {\n    display: flex;\n    justify-content: center;\n  \n    .setting-lecturer {\n      width: 50%;\n      height: auto;\n      gap: 16px;\n      border-radius: 8px;\n  \n      .lecturer-card {\n        padding: 24px;\n  \n        .ant-card-head {\n          .ant-card-head-title {\n            font-size: 20px;\n            line-height: 28px;\n            font-weight: 500;\n          }\n        }\n  \n        .setting-item {\n          padding: 12px 0 0 0;\n          margin: 0;\n          border-top: 1px solid #d9d9d9;\n  \n          .ant-form-item-label {\n            width: 30%;\n            display: flex;\n            justify-content: start;\n  \n            label {\n              font-weight: 700;\n              line-height: 22px;\n            }\n          }\n  \n          .ant-form-item-control {\n            width: 50%;\n          }\n  \n          .lecturer-content {\n            margin-top: 5px;\n            word-break: break-all;\n          }\n        }\n  \n        .item-setting:last-child {\n          padding-bottom: 0;\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
