import { IDataCreateUser } from "./../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";
import { theme } from "antd";

export const postMoocSurveySearch: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-survey/search`, data);
};

export const getMoocSurveyById: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.get(`/mooc-survey/get-by-id/${id}`);
};

export const putUpdateMoocSurvey: (
  id: number,
  data: object
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/mooc-survey/update/${id}`, data);
};

export const postCreateMoocSurvey: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-survey/create`, data);
};

export const deleteMoocSurvey: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.delete(`/mooc-survey/delete/${id}`);
};

export const postCreateMoocSurveyCopy: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.post(`/mooc-survey/create-survey-copy/${id}`);
};

export const getSuggestedSurveyQuestion: (
  userId: Number,
  surveyId: number
) => Promise<AxiosResponse<any>> = (userId, surveyId) => {
  let url = `/mooc-survey-content/get-all-survey-question-by-userId/${userId}/${surveyId}`;
  return axiosConfig.get(url);
};
// Categories
