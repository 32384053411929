// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-course-construction .lesson {
  text-align: right; }

.detail-course-construction .ant-table-row-level-1 .ant-select,
.detail-course-construction .ant-table-row-level-2 .ant-select {
  display: none; }

.detail-course-construction .ant-table-row-level-1 .ant-table-cell-with-append,
.detail-course-construction .ant-table-row-level-2 .ant-table-cell-with-append {
  padding-left: 30px !important; }

.detail-course-construction .ant-form-item {
  margin-bottom: unset !important; }

.detail-course-construction .card-heading--info h5, .detail-course-construction .card-heading--info .sub-title {
  margin-bottom: unset !important; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/assign-course-construction/assigned-detailed-assignment/index.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB,EAAA;;AAFrB;;EAOM,aAAa,EAAA;;AAPnB;;EAUM,6BAA6B,EAAA;;AAVnC;EAcI,+BAA+B,EAAA;;AAdnC;EAkBM,+BAA+B,EAAA","sourcesContent":[".detail-course-construction {\n  .lesson {\n    text-align: right;\n  }\n  .ant-table-row-level-1,\n  .ant-table-row-level-2 {\n    .ant-select {\n      display: none;\n    }\n    .ant-table-cell-with-append {\n      padding-left: 30px !important;\n    }\n  }\n  .ant-form-item {\n    margin-bottom: unset !important;\n  }\n  .card-heading--info {\n    h5, .sub-title {\n      margin-bottom: unset !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
