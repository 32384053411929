// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-carousel {
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  height: 100%; }
  .item-carousel__image {
    width: 55vh;
    height: 55vh;
    overflow: hidden;
    margin-bottom: 1.5rem; }
  .item-carousel__content__text {
    margin: 0;
    font-size: 1.5rem;
    color: #fff;
    line-height: 1; }

@media screen and (max-width: 1025px) {
  .item-carousel__image {
    width: 70%;
    height: 30%; } }
`, "",{"version":3,"sources":["webpack://./src/components/carousel/carousel.scss"],"names":[],"mappings":"AAAA;EACI,wBAAwB;EACxB,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,eAAe;EACf,YAAY,EAAA;EACZ;IAEE,WAAW;IACX,YAAY;IAEZ,gBAAgB;IAChB,qBAAqB,EAAA;EAKrB;IACE,SAAS;IACT,iBAAiB;IACjB,WAAW;IACX,cAAc,EAAA;;AAKpB;EAEI;IACI,UAAU;IACV,WACF,EAAA,EAAC","sourcesContent":[".item-carousel {\n    display: flex !important;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    max-width: 100%;\n    height: 100%;\n    &__image {\n    //   background-color: #fff;\n      width: 55vh;\n      height: 55vh;\n    //   border-radius: 50%;\n      overflow: hidden;\n      margin-bottom: 1.5rem;\n    }\n  \n    &__content {\n  \n      &__text {\n        margin: 0;\n        font-size: 1.5rem;\n        color: #fff;\n        line-height: 1;\n      }\n    }\n  }\n\n  @media screen and (max-width: 1025px) {\n    .item-carousel {\n      &__image {\n          width: 70%;\n          height: 30%\n        }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
