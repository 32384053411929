import { ScanOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Layout, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import AutoScoring from "./auto-scoring";
import ManagementListScore from "./management-table-scores";
import { routesConfig } from "../../../config/routes";

const { Content } = Layout;
const imgPath = "/images/";

const ManagementScoreAuto = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("1");
  const [refreshTag, setRefreshTag] = useState(false);
  const [breadHead, setBreadHead] = useState({
    titleCourse: "",
    titleExam: "",
    idExam: 0,
  });
  const [permissionScore, setPermissionScore] = useState<string[]>([]);

  const listBreadcrumb = [
    {
      title: (
        <Link to={`/${routesConfig.scoringManagement}`}>Danh sách khoá học</Link>
      ),
    },
    {
      title: (
        <Link to={`/${routesConfig.scoringManagement}/${routesConfig.exercises}/${breadHead.idExam}`}>{breadHead.titleCourse}</Link>
      ),
    },
    {
      title: breadHead.titleExam,
    },
    {
      title: "Quản lý chấm bài thi tự động",
    },
  ];
  const handleChange = (key: string) => {
    setKey(key);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <ScanOutlined />,
      label: "Chấm điểm tự động",
      children: (
        <AutoScoring
          handleChange={handleChange}
          setBreadHead={setBreadHead}
          setRefreshTag={setRefreshTag}
          refreshTag={refreshTag}
          permissionScore={permissionScore}
          setPermissionScore={setPermissionScore}
        />
      ),
    },
    {
      key: "2",
      icon: <UnorderedListOutlined />,
      label: "Quản lý bảng điểm",
      children: (
        <ManagementListScore
          refreshTag={refreshTag}
          permissionScore={permissionScore}
        />
      ),
    },
  ];

  return (
    <Content>
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Quản lý chấm bài thi tự động"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(-1)}
      />
      <div className="tabs-heading">
        <Tabs
          defaultActiveKey="1"
          items={items}
          activeKey={key}
          onChange={handleChange}
        />
      </div>
    </Content>
  );
};

export default ManagementScoreAuto;
