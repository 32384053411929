import { Modal, Table, TableColumnsType, TableProps } from "antd";
import { useAuthStore } from "../../../../../stores/stores";
import { ChangeEvent, useEffect, useState } from "react";
import FormItemInput from "../../../../../components/form-input/FormInput";
import { SearchOutlined } from "@ant-design/icons";
import { formatDateV2 } from "../../../../../utils/converDate";
import "./ManyApprovals.scss";
import TableData from "../../../../../components/table-data/TableData";
import { DataType } from "../../../../../types/listUsers";
import { getListAssign } from "../../../../../service/assign-course-construction";
import { postSurveySearchApprove } from "../../../../../service/survey-managment/list-survey";
import { ApprovalType } from "../../../../../types/survey-browse";
import { approveManySurvey } from "../../../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";
import { get } from "lodash";
import { HelpService } from "../../../../../service/helper.service";
import { courseStatusEum } from "../../constants/data";
import { searchCourseConstructionPlanPlanningApproval } from "../../../../../service/course-construction-plan";
interface RowKeyType {
  courseConstructionPlanId: number;
}
type Props = {
  open: boolean;
  handleCancel: () => void;
  handleFinish: () => void;
};
export default function ManyApprovalsCourse(props: Props) {
  const { open, handleCancel, handleFinish } = props;
  const userId = useAuthStore((states) => states.user?.id);
  const [searchValue, setSearchValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [listCourseMany, setListCouresMany] = useState<ApprovalType[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const helpService = new HelpService();
  const [dataList, setDataList] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countTrueRecords, setCountTrueRecords] = useState<number>(0);
  const [countFalseRecords, setcountFalseRecords] = useState<number>(0);
  const handleReturnStatus = (record: any) => {
    switch (record.courseConstructionPlanStatus) {
      case courseStatusEum.SAVE_DRAFT:
        return "Lưu bản nháp";
      case courseStatusEum.WAIT_APPROVE:
        return "Chờ duyệt";
      case courseStatusEum.APPROVED:
        return "Đã duyệt";
      case courseStatusEum.REFUSE:
        return "Từ chối";
      case courseStatusEum.PUBLISHED:
        return "Đã xuất bản";
      case courseStatusEum.END:
        return "Kết thúc";
      default:
        return "";
    }
  };
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên kế hoạch",
      sorter: (a, b) =>
        a.courseConstructionPlanName.localeCompare(
          b.courseConstructionPlanName
        ),
      width: 60,
      dataIndex: "courseConstructionPlanName",
    },
    {
      title: "Ngày gửi",
      width: 20,
      dataIndex: "submissionDate",
      render: (_: any, record) => {
        return (
          <>
            {record.submissionDate ? formatDateV2(record.submissionDate) : null}
          </>
        );
      },
    },
    {
      title: "Trạng thái",
      width: 30,
      dataIndex: "survey_status",
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.courseConstructionPlanStatus === 1
                    ? "#D9D9D9"
                    : record.courseConstructionPlanStatus === 2
                    ? "#FAAD14"
                    : record.courseConstructionPlanStatus === 3
                    ? "#1677FF"
                    : record.courseConstructionPlanStatus === 4
                    ? "#FF0000"
                    : "white",
              }}
            ></div>
            {handleReturnStatus(record)}
          </div>
        );
      },
    },
  ];
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    // setStatusOrder(data);
    // setFieldSortOrder(sorter.field);
  };
  const handleGetListData = () => {
    console.log("Hello");
    setLoading(true);
    const dataSend = {
      keyword: searchValue,
      pageNumber: 1,
      pageSize: pageSize,
      individualId: userId,
      isApproved: false,
    };
    searchCourseConstructionPlanPlanningApproval(dataSend)
      .then((res) => {
        const { data, totalRecords, countTrueRecords, countFalseRecords } =
          res.data.data;
        setListCouresMany(data);
        console.log(res.data.data.data);
        setTotalRecords(totalRecords);
        setCountTrueRecords(countTrueRecords);
        setcountFalseRecords(countFalseRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  // const getListData = () => {
  //   const data = {
  //     keyword: searchValue,
  //     individualId: userId,
  //     type: false,
  //     isPagination: false,
  //   };
  //   postSurveySearchApprove(data)
  //     .then((res) => {
  //       const { data } = res.data;
  //       setListSurveyMany(data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  useEffect(() => {
    if (open) {
      handleGetListData();
    }
  }, [open]);
  const onOk = () => {
    if (selectedRowKeys.length > 0) {
      console.log(selectedRowKeys);
      const dataSend = {
        individualId: userId,
        courseIds: (selectedRowKeys as unknown as RowKeyType[]).map(
          (i) => i.courseConstructionPlanId
        ),
        submitterId: userId,
      };
      approveManySurvey(dataSend)
        .then((res) => {
          const { data, statusCode, message } = res.data;
          if (statusCode === 200) {
            helpService.successMessage('Duyệt thành công […] kế hoạch khóa học');
            handleCancel();
            handleFinish();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  const handleSubmitSearch = () => {
    handleGetListData();
  };

  const disableButton = selectedRowKeys.length === 0 ? true : false;
  return (
    <div className="course-plan-managment-many-approval-planning">
      <Modal
        title="Duyệt nhiều kế hoạch khoá học"
        centered
        open={open}
        okText="Xác nhận duyệt"
        cancelText="Huỷ"
        onOk={onOk}
        onCancel={handleCancel}
        width={800}
        okButtonProps={{ disabled: disableButton }}
      >
        <FormItemInput
          placeholder="Nhập từ khoá cần tìm"
          value={searchValue}
          onChange={handleChangeInput}
          afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
        />
        <div className="title-custom-many-approval">
          <h1 className="title-custom-many-approval-h1">
            Danh sách kế hoạch khoá học
          </h1>
          <span className="title-custom-many-approval-span">
            {` (Đã chọn ${selectedRowKeys.length}/${listCourseMany.length} kế hoạch)`}
          </span>
        </div>
        <TableData
          dataTable={listCourseMany}
          dataColumns={dataColumns}
          hideSelection={true}
          loadingTable={loading}
          setSelectedRowKeysProps={setSelectedRowKeys}
          handleTableChangeProps={handleTableChange}
        />
      </Modal>
    </div>
  );
}
