// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portal-adminstration .ant-table-cell img {
  width: 32px;
  height: 32px; }
`, "",{"version":3,"sources":["webpack://./src/pages/portar-adminstration/index.scss"],"names":[],"mappings":"AAAA;EAGM,WAAW;EACX,YAAY,EAAA","sourcesContent":[".portal-adminstration {\n  .ant-table-cell {\n    img {\n      width: 32px;\n      height: 32px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
