import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const getListCoursePlaningAssignment: (
  data: any,
  id: any,
  tab: any
) => Promise<AxiosResponse<any>> = (data: any, id: any, tab: any) => {
  return axiosConfig.post(
    `/course-planning-assignment/search/${id}/${tab}`,
    data
  );
};

export const getListNumberCoursePlaningAssignment: (id: any) => Promise<AxiosResponse<any>> = (id: any) => {
    return axiosConfig.get(`/course-planning-assignment/getAllCoursePlanningAssignment/${id}`);
};

export const postCoursePlaningAssignment: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(
    `/course-planning-assignment/post-course-planing-assignment`,
    data
) }

export const getCoursePlanAssignById: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfig.get(
    `/course-planning-assignment/get-course-planing-assignment/${id}`
  );
};

export const revokeCourseAssignment: (data: object) => Promise<AxiosResponse<any>> = (
    data
) => {
    return axiosConfig.post(`/course-planning-assignment/revoke-course-planing-assignment`, data);
};

export const getListCoursePlanAssignById: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfig.get(
    `/course-planning-assignment/get-list-course-plan-assign-by-id/${id}`
  );
};

export const getMoocApprovedHistoryByCourseId: (Courseid: number, data: object) => Promise<AxiosResponse<any>> = (Courseid,data) => {
  return axiosConfig.get(`/course-planning-assignment/get-history-by-id/${Courseid}`, data);
};