// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-input {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 0; }

.ql-toolbar {
  width: fit-content;
  border-radius: 10px; }

.ql-container.ql-snow {
  width: 100%;
  border-radius: 10px;
  border-top: 1px solid #e5e7eb !important;
  margin-bottom: 5px;
  margin-top: 5px; }

.ql-container.ql-snow:focus-within .ql-toolbar {
  display: flex; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/survey-respond/component/detail-question/question-type-6/detail.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,SAAW,EAAA;;AAIb;EACE,kBAAkB;EAClB,mBAAmB,EAAA;;AAGrB;EACE,WAAW;EACX,mBAAmB;EACnB,wCAAwC;EACxC,kBAAkB;EAClB,eAAe,EAAA;;AAIjB;EACE,aAAa,EAAA","sourcesContent":[".form-input {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  flex: 1 0 0;\n}\n\n\n.ql-toolbar {\n  width: fit-content;\n  border-radius: 10px;\n}\n\n.ql-container.ql-snow {\n  width: 100%;\n  border-radius: 10px;\n  border-top: 1px solid #e5e7eb !important;\n  margin-bottom: 5px;\n  margin-top: 5px;\n}\n\n\n.ql-container.ql-snow:focus-within .ql-toolbar {\n  display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
