import { FileType } from "./../../utils/uploadImage";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const uploadFile: (
  id: number | undefined | null,
  dataUpdate: object
) => Promise<AxiosResponse<any>> = (id, dataUpdate) => {
  return axiosConfig.put(`/accounts/personal-information`, dataUpdate);
};

export const uploadFileV2: (data: any) => Promise<AxiosResponse<any>> = (
  data: any
) => {
  return axiosConfigV2.post(`/mooc-course-block-quiz/upload-file`, data);
}