// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-browse .list-item {
  align-items: flex-start;
  flex-direction: row; }
  .course-browse .list-item .list-item--title {
    width: 250px; }

.course-browse .card-heading--info {
  flex-direction: column; }
  .course-browse .card-heading--info h5, .course-browse .card-heading--info .sub-title {
    margin-bottom: unset !important; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/assign-course-construction/browsing-results/index.scss"],"names":[],"mappings":"AAAA;EAEI,uBAAuB;EAIvB,mBAAmB,EAAA;EANvB;IAIM,YAAY,EAAA;;AAJlB;EASI,sBAAsB,EAAA;EAT1B;IAWM,+BAA+B,EAAA","sourcesContent":[".course-browse {\n  .list-item {\n    align-items: flex-start;\n    .list-item--title {\n      width: 250px;\n    }\n    flex-direction: row;\n  }\n  .card-heading--info {\n    flex-direction: column;\n    h5, .sub-title {\n      margin-bottom: unset !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
