import { Button, Col, Collapse, Drawer, Form, Radio, Row, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { isEmpty, size } from 'lodash'

type Props = {
  open: boolean
  onClose: () => void
}

export default function HistoryQuestion(props: Props) {
  const { open, onClose } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataDetail, setDataDetail] = useState<any>({})

  useEffect(() => {
    if (open) {
      // getListDataDetail();
    }
  }, [open]);

  return (
    <Drawer
      title={"Lịch sử kiểm duyệt"}
      open={open}
      onClose={onClose}
      className='detail-question-bank-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
      extra={
        <Space>
          <Button onClick={onClose}>Đóng</Button>
        </Space>
      }
    >
      <Form
        className="detail-question-bank"
        layout={"vertical"}
        form={form}
        name="form-detail-question-bank"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel
              header="Duyệt chỉnh sửa"
              key="1"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nội dung gửi duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày gửi duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nội dung duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Người duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày xuất bản</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Tình trạng</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
              </div>
            </Panel>

            <Panel
              header="Duyệt mới"
              key="2"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nội dung gửi duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày gửi duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nội dung duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Người duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày xuất bản</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Tình trạng</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Form>
    </Drawer>
  )
}
