import { forwardRef } from 'react';
import { Action, ActionProps } from '../Action';
import { EditOutlined } from '@ant-design/icons';

export const Edit = forwardRef<HTMLButtonElement, ActionProps>(
  (props, ref) => {
    return (
      <>
        <Action
          ref={ref}
          cursor="grab"
          data-cypress="draggable-handle"
          {...props}
        >
          <EditOutlined />
        </Action>
      </>

    );
  }
);
