import { ListSurveySection } from "../../../../../../types/survey-managment/survey";

export const initialValueListSection: ListSurveySection[] = [
  {
    orderNumber: 1,
    isDeleted: false,
    moocSurveyQuestion: [
      {
        questionType: 1,
        orderNumber: 1,
        isRequired: false,
        isDeleted: false,



        responseValidationValue: "",
        responseValidationCustomErrorText: "",
        moocSurveyQuestionAnswer: [
          {
            answerText: "Lựa chọn văn bản 1",
            answerOrderNumber: 1,
          },
        ],
      },
    ]
  },
]

export const surveyStatus = [
  { value: 1, text: "Bản nháp", color: "lightgray" },
  { value: 2, text: "Chờ duyệt", color: "lightgray" },
  { value: 3, text: "Đã duyệt", color: "green" },
  { value: 4, text: "Từ chối", color: "red" },
  { value: 5, text: "Đã xuất bản", color: "orange" },
  { value: 6, text: "Đã kết thúc", color: "blue" },
];