import {
  CaretDownOutlined,
  CloseOutlined,
  EditOutlined,
  FileWordOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  RobotOutlined,
  SaveOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Flex,
  Input,
  Modal,
  Spin,
  Tooltip,
  Tree,
  TreeDataNode,
  Typography,
} from "antd";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import CommonModal from "../../components/modal/common";
import {
  getSettingStructureCourseById,
  updateSelectStructure,
  updateSettingCourseStructure,
} from "../../service/setting-course-structure";
import "./setting-structure.scss";
import { isEmpty } from "lodash";
import { COURSE_STRUCTURE_TYPE } from "../../utils/courseStructure";
import { TextAreaProps } from "antd/es/input/TextArea";
import axios from "axios";

const { Title, Text } = Typography;
const { TextArea } = Input;
interface IProps {
  handleNotice: (type: any, message: string) => void;
  dataParam: any;
  setDataParam: (item: boolean) => void;
  isView: boolean;
  isRefresh: boolean;
  setIsActive: (item: boolean) => void;
  handleRefresh: () => void;
}

const URL_SUGGEST_COURSE_CONTENT = process.env
  .REACT_APP_SUGGEST_COURSE_CONTENT as string;

const SettingStructureContent = ({
  handleNotice,
  dataParam,
  setDataParam,
  isView,
  isRefresh,
  handleRefresh,
  setIsActive,
}: IProps) => {
  const params = useParams();
  const [dataCourse, setDataCourse] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [idSequence, setIdSequence] = useState(0);
  const [listStructure, setListStructure] = useState<any>({
    references: false,
    assessment: false,
    study: false,
  });

  const fetchData = async () => {
    try {
      const response = await getSettingStructureCourseById(params?.id || "");
      const { data } = response.data;

      setDataCourse(data ? data : []);
      setDataParam(data?.mooc_setting_all_course_structure);
      setIsActive(data?.mooc_setting_all_course_structure?.is_active)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isRefresh]);

  const handleOpen = (id: number) => {
    setIsOpen(true);
    setIdSequence(id);
  };

  const CommonSession = () => {
    const [content, setContent] = useState("");
    const [idStructure, setIdStructure] = useState(NaN);
    const [isOpenLoading, setIsOpenLoading] = useState(false);

    const handleEdit = (id: number, contents: string) => {
      setContent(contents);
      setIdStructure(id);
    };
    const handleChangeText = (e: any) => {
      setContent(e.target.value);
    };
    const handleSuggestAi = async (
      id: number,
      type: string,
      chapterName: string,
      chapterDescription: string,
      lessonName: string,
      lessonDescription: string
    ) => {
      try {
        setIsOpenLoading(true);
        const response = await axios.post(URL_SUGGEST_COURSE_CONTENT, {
          course_name: dataCourse?.name,
          course_industry: !isEmpty(dataCourse?.mooc_course_industry)
            ? dataCourse?.mooc_course_industry[0]?.mooc_industry?.name
            : "",
          course_industry_group: !isEmpty(
            dataCourse?.mooc_course_industry_group
          )
            ? dataCourse?.mooc_course_industry_group[0]?.mooc_industry_group
                ?.name
            : "",
          chapter_name: chapterName ? chapterName : "",
          chapter_description: chapterDescription ? chapterDescription : "",
          lesson_name: lessonName ? lessonName : "",
          lesson_description: lessonDescription ? lessonDescription : "",
          type: type,
        });

        setContent(response?.data);
        setIsOpenLoading(false);
        setIdStructure(id);
      } catch (error) {
        setIsOpenLoading(false);
        console.error(error);
      }
    };
    const handleSaveSequence = async (id: number, type: string) => {
      try {
        await updateSettingCourseStructure(id, {
          type: type,
          content: content,
        });
        setIdStructure(NaN);
        handleRefresh();
        handleNotice("success", "Lưu thông tin thành công");
      } catch (error) {}
    };

    const treeData: TreeDataNode[] = dataCourse?.mooc_course_section?.map(
      (value: any) => ({
        title: (
          <Flex justify="space-between" align="center">
            <div>
              <Title level={5}>{value?.name}</Title>
              <p className="text-[#00000073]">
                Mô tả: {value?.description ? value?.description : "Không có"}
              </p>
            </div>
            {!isView && (
              <Tooltip title="Cài đặt cấu trúc bài giảng mặc định">
                <Button
                  className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                  onClick={() => handleOpen(value?.id)}
                >
                  <SettingOutlined />
                </Button>
              </Tooltip>
            )}
          </Flex>
        ),
        key: value?.id,
        children:
          value?.mooc_course_sequence
            .map((sequenceData: any, index: number) => ({
              title: (
                <Flex
                  justify="space-between"
                  align="center"
                  className="pl-2 py-2"
                >
                  <div className="py-2">
                    <Typography.Text className="pl-2">
                      {sequenceData.name}
                    </Typography.Text>
                    <p className="text-[#00000073]">
                      Mô tả:{" "}
                      {sequenceData?.description
                        ? sequenceData?.description
                        : "Không có"}
                    </p>
                  </div>
                </Flex>
              ),
              key: sequenceData?.id,
              icon: <FileWordOutlined />,
              children: sequenceData?.mooc_setting_course_structure?.map(
                (structureData: any) => ({
                  title: (
                    <div className=" border-l">
                      <Flex
                        justify="space-between"
                        align="center"
                        className="pl-2 py-2"
                      >
                        <div className="py-2">
                          <Typography.Title level={5} className="pl-2 !mb-0">
                            {COURSE_STRUCTURE_TYPE[structureData?.type]}
                          </Typography.Title>
                        </div>

                        {!isView && (
                          <div>
                            {structureData?.id === idStructure ? (
                              <Flex>
                                <Button
                                  className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                                  onClick={() => setIdStructure(NaN)}
                                >
                                  <CloseOutlined />
                                  Hủy
                                </Button>
                                <Button
                                  type="primary"
                                  className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                                  onClick={() =>
                                    handleSaveSequence(
                                      structureData?.id,
                                      structureData?.type
                                    )
                                  }
                                >
                                  <SaveOutlined />
                                  Lưu
                                </Button>
                              </Flex>
                            ) : (
                              <Flex>
                                <Tooltip title="Hệ thống gợi ý cấu trúc bài giảng">
                                  <Button
                                    className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                                    onClick={() =>
                                      handleSuggestAi(
                                        structureData?.id,
                                        structureData?.type,
                                        value?.name,
                                        value?.description,
                                        sequenceData.name,
                                        sequenceData?.description
                                      )
                                    }
                                  >
                                    <RobotOutlined />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Chỉnh sửa nội dung">
                                  <Button
                                    className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer"
                                    onClick={() =>
                                      handleEdit(
                                        structureData?.id,
                                        structureData?.content
                                      )
                                    }
                                  >
                                    <EditOutlined />
                                  </Button>
                                </Tooltip>
                              </Flex>
                            )}
                          </div>
                        )}
                      </Flex>
                      <div>
                        {structureData?.id !== idStructure ? (
                          <div className="pl-6">{structureData?.content}</div>
                        ) : (
                          <TextArea
                            onChange={(e) => handleChangeText(e)}
                            className="ml-2 w-full"
                            defaultValue={content}
                            placeholder="Nhập nôi dung mô tả"
                            autoSize={{ minRows: 4, maxRows: 6 }}
                          />
                        )}
                      </div>
                    </div>
                  ),
                  key: structureData?.id,
                })
              ),
            }))
            .filter((item: any) => item) || [],
      })
    );
    return (
      <Card>
        <Tree
          showIcon
          className="tree-course setting-structure-tree"
          defaultExpandAll
          switcherIcon={<CaretDownOutlined />}
          treeData={treeData}
        />
        <Modal
          title="Đang xử lý . . ."
          open={isOpenLoading}
          footer=""
          centered
          closeIcon={false}
          // onCancel={() => setIsOpenLoading(false)}
          className="processing-checkin-modal"
        >
          <div className="text-center">
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
            />
            <p className="pt-4">
              {" "}
              Đang tiến hành gợi ý cấu trúc bài giảng. Vui lòng chờ
            </p>
          </div>
        </Modal>
      </Card>
    );
  };

  const handleConfirmChoose = async () => {
    const result = Object.keys(listStructure).filter(
      (key) => listStructure[key]
    );
    try {
      await updateSelectStructure(idSequence, { type: result });
      handleRefresh();
      handleNotice("success", "Lưu thông tin thành công");
    } catch (error) {}
    setIsOpen(false);
  };

  const onChangeSelect = (e: any) => {
    setListStructure({
      ...listStructure,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <div className="p-6">
      <Card className="w-full">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Flex align="center">
              <Title level={4} className="!m-0 pr-4">
                Cài đặt cấu trúc bài giảng
              </Title>
              {dataParam?.is_active ? (
                <Badge status="success" text="Đang bật" />
              ) : (
                <Badge status="default" text="Đang tắt" />
              )}
            </Flex>
            {dataParam?.is_active ? (
              <Text className="text-[#00000073]">
                Hệ thống gợi ý cấu trúc bài giảng hỗ trợ người dùng phát triển
                và quản lý nội dung khoá học
              </Text>
            ) : (
              <Text className="text-[#00000073]">
                Vui lòng chọn trạng thái để sử dụng tính năng
              </Text>
            )}
          </div>
        </Flex>
        {dataParam?.is_active && (
          <div>
            <Flex className="py-4 border-b">
              <span className="!font-semibold pr-8 w-1/6">Tên khoá học</span>
              <div className="px-4">{dataCourse?.name}</div>
            </Flex>
            <Flex className="py-4 border-b">
              <span className="!font-semibold pr-8 w-1/6">Khoa</span>
              <div className="px-4">
                {!isEmpty(dataCourse?.mooc_course_industry) &&
                  dataCourse?.mooc_course_industry[0]?.mooc_industry?.name}
              </div>
            </Flex>
            <Flex className="py-4">
              <span className="!font-semibold pr-8 w-1/6">Chuyên ngành</span>
              <div className="px-4">
                {!isEmpty(dataCourse?.mooc_course_industry_group) &&
                  dataCourse?.mooc_course_industry_group[0]?.mooc_industry_group
                    ?.name}
              </div>
            </Flex>
          </div>
        )}
      </Card>
      {dataParam?.is_active && (
        <Card className="w-full mt-6">
          <div className="border-b pb-4">
            <Title level={4} className="!m-0 pr-4">
              Thông tin cấu trúc bài giảng
            </Title>
            <Text className="text-[#00000073]">
              Nội dung cấu trúc bài giảng theo chương
            </Text>
          </div>
          <div className="border rounded-md mt-4">
            <CommonSession />
          </div>
        </Card>
      )}
      <CommonModal
        open={isOpen}
        title={`Cài đặt cấu trúc bài giảng mặc định`}
        desc={
          <div>
            <p>Chọn loại học liệu để hệ thống đưa gợi ý cấu trúc bài giảng</p>
            <Flex>
              <Checkbox
                className="m-0 !w-fit"
                checked={listStructure?.study}
                onChange={onChangeSelect}
                name="study"
              />
              <span className="pl-4">Học liệu kiến thức</span>
            </Flex>
            <Flex>
              <Checkbox
                className="m-0 !w-fit"
                checked={listStructure?.assessment}
                onChange={onChangeSelect}
                name="assessment"
              />
              <span className="pl-4">Học liệu đánh giá</span>
            </Flex>
            <Flex>
              <Checkbox
                className="m-0 !w-fit"
                checked={listStructure?.references}
                onChange={onChangeSelect}
                name="references"
              />
              <span className="pl-4">Học liệu tham khảo</span>
            </Flex>
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={""}
        confirm={handleConfirmChoose}
        closeModal={() => setIsOpen(false)}
        className={""}
      />
    </div>
  );
};

export default SettingStructureContent;
