import { Editor, Plugin } from "grapesjs";
import { CustomDisplayPros, CustomObject, getIdIndexDB, saveToIndexDB } from "../../grapesjs-service";
import { ConstructCourseDetailType } from "../../../../types/course-construction";
import { Input, message } from "antd";
import { styleManagerConfig } from "../../grapesjs-config";

const { TextArea } = Input;

export type PluginOptions = {
  detailData?: ConstructCourseDetailType;
};

export interface RefComponentClass {
  updateComponentClass: (value: string) => void
}

const CommonAdvanceSettingsPlugin: Plugin<PluginOptions> | any = (editor: Editor, optionsPlugin: PluginOptions = {}) => {
  const extractYoutubeVideoId = (url: string) => {
    const regex = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/i;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  editor.on("component:selected", (res) => {
    let defaultDisplayFor: CustomObject = {
      "display-for": "all",
      "display-on-nav": "no",
    };
    styleManagerConfig.sectors
      .filter((s) => s.id == "advanced-setting")
      .map((s) => s.properties)
      .flat()
      .forEach((sector) => {
        const item = sector as CustomDisplayPros;
        if (item.type === "advanced-setting-info") {
          let options = item.options;
          options.forEach((opt) => {
            let inputElm = document.querySelector('[name="' + opt.id + '"]');
            let inputValue = res?.getAttributes()[opt.id?.toLowerCase()];
            if (inputValue) {
              (inputElm as HTMLInputElement).value = inputValue;
            } else {
              (inputElm as HTMLInputElement).value = "";
            }
          });
        }
        if (item.type === "advanced-setting-identification-card") {
          const inputASIC = document.querySelector('input[name="advanced-setting-identification-card"]') as HTMLElement;
          const labelASIC = document.querySelector('.gjs-sm-property__identification-card .gjs-sm-label') as HTMLElement;
          labelASIC.classList.remove('error-error-duplicate');
          labelASIC.innerHTML = `<span class="gjs-sm-icon" title="">Thẻ định danh</span>`;
          inputASIC.classList.remove('input-error-duplicate');
          saveToIndexDB('isClearToSave', true);
          let options = item.options;
          let inputElm = document.querySelector('[name="' + options[0].id + '"]');
          let inputValue = res?.getAttributes()[options[0].id?.toLowerCase()];
          if (inputValue) {
            (inputElm as HTMLInputElement).value = inputValue;
          } else {
            (inputElm as HTMLInputElement).value = "";
          }
        }
        if (item.type === "advanced-setting-display-subject") {
          let key = item?.key;
          let value = res?.getAttributes()[key?.toLowerCase()] || defaultDisplayFor[key];
          let checkBox = document.querySelector('[name="' + key + '"][value="' + value + '"]');
          if (checkBox) {
            (checkBox as HTMLInputElement).checked = true;
          } else {
            let allCheckbox = document.querySelectorAll('[name="' + key + '"]');
            allCheckbox.forEach((checkBox) => {
              (checkBox as HTMLInputElement).checked = false;
            });
          }
        }
      });
  });

  editor.StyleManager.addType("advanced-setting-info", {
    create({ props, change }) {
      let p = props as CustomDisplayPros;
      const options = p.options?.length ? p.options : [];
      const el = document.createElement("div");
      el.classList.add("advanced-setting-info");

      el.innerHTML = `
          <div class="gjs-traits-cs gjs-one-bg gjs-two-color">
            <div class="gjs-trait-categories" data-categories=""></div>
            <div class="gjs-traits-empty-c gjs-traits-c" data-no-categories="">
            ${options.map((opt) => `
              <div class="gjs-trt-trait__wrp gjs-trt-trait__wrp-id">
                  <div class="gjs-trt-trait gjs-trt-trait--text">
                      <div class="gjs-label-wrp" data-label="">
                        <div class="gjs-label" title="${opt.name}">${opt.name}</div>
                      </div>
                      <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                          <div class="gjs-field gjs-field-text" data-input="">
                            <input class="info-input" type="text" value="${opt.value}"  name="${opt.id}" />
                          </div>
                      </div>
                  </div>
              </div>`
      ).join("")}
            </div>
          </div>
        `;
      el.querySelectorAll(".info-input").forEach((elm) => {
        elm?.setAttribute('maxlength', '255');
        elm?.addEventListener("input", (event) => {
          change({ event });
        });
      });

      return el;

    },
    emit({ props }, { event }) {
      let attribute: CustomObject = {};
      attribute[event.target?.getAttribute("name") as string] = (
        event.target as HTMLInputElement
      )?.value;
      const selectedComponent = editor.getSelected();
      selectedComponent?.addAttributes(attribute);
    },
  });

  editor.StyleManager.addType("advanced-setting-display-subject", {
    create: function ({ props, createdEl, change }) {
      let p = props as CustomDisplayPros;
      let traitName = p.key;
      const options = p.options.length ? p.options : [];
      const el = document.createElement("div");
      el.classList.add("settings-display-input");
      el.innerHTML = `
           <div class="gjs-traits-cs gjs-one-bg gjs-two-color">
            <div class="gjs-trait-categories" data-categories=""></div>
            <div class="gjs-traits-empty-c gjs-traits-c" data-no-categories="">
            ${options.map((opt) => `
              <div class="gjs-trt-trait__wrp gjs-trt-trait__wrp-id">
                <div class="gjs-trt-trait gjs-trt-trait--text">
                  <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                    <label class='display-for-wrapper'>
                      <input type='radio' class='radio-button-input' value='${opt.id}' name='${traitName}'} />
                      <span>${opt.name}</span>
                    </label>
                  </div>
                </div>
              </div>`).join("")}
            </div>
          </div>`;
      el.querySelectorAll(".radio-button-input").forEach((elm) => {
        elm?.addEventListener("input", (event) => change({ event }));
      });

      return el;
    },
    emit({ }, { event }) {
      let attribute: CustomObject = {};
      attribute[event.target?.getAttribute("name") as string] = (
        event.target as HTMLInputElement
      )?.value;
      const selectedComponent = editor.getSelected();
      selectedComponent?.addAttributes(attribute);
    },
    update() {
    },
    destroy() {
    },
  });

  editor.StyleManager.addType("advanced-setting-identification-card", {
    create({ props, change }) {
      let p = props as CustomDisplayPros;
      const options = p.options?.length ? p.options : [];
      const el = document.createElement("div");
      el.classList.add("advanced-setting-identification-card");
      el.innerHTML = `
          <div class="gjs-traits-cs gjs-one-bg gjs-two-color">
            <div class="gjs-traits-empty-c gjs-traits-c" data-no-categories="">
            ${options.map((opt) => `
              <div class="gjs-trt-trait__wrp gjs-trt-trait__wrp-id">
                <div class="gjs-field-wrp gjs-field-wrp--text" data-input="">
                  <div class="gjs-field gjs-field-text" data-input="">
                    <input class="info-input" type="text" value="${opt.value}"  name="${opt.id}" />
                  </div>
                </div>
              </div>
              `).join("")}
            </div>
          </div>
        `;
      el.querySelectorAll(".info-input").forEach((elm) => {
        elm?.addEventListener("input", (event) => {
          change({ event });
        });
      });
      return el;
    },
    emit({ props }, { event }) {
      let attribute: CustomObject = {};
      let value = (event.target as HTMLInputElement)?.value;
      let nameAttr = event.target?.getAttribute("name") as string;
      getIdIndexDB('ASICArrays', (val: any[]) => {
        let ASICArrays = val;
        let checkValue = ASICArrays.find((x) => { return x === value });
        const inputASIC = document.querySelector('input[name="advanced-setting-identification-card"]') as HTMLElement;
        const labelASIC = document.querySelector('.gjs-sm-property__identification-card .gjs-sm-label') as HTMLElement;
        if (checkValue) {
          labelASIC.classList.add('error-error-duplicate');
          inputASIC.classList.add('input-error-duplicate');
          labelASIC.innerHTML = `<span class="gjs-sm-icon" title="">Thẻ định danh</span><span class="gjs-sm-icon" title="">Trùng tên</span>`;
          attribute[nameAttr] = '';
          saveToIndexDB('isClearToSave', false);
        } else {
          labelASIC.classList.remove('error-error-duplicate');
          inputASIC.classList.remove('input-error-duplicate');
          labelASIC.innerHTML = `<span class="gjs-sm-icon" title="">Thẻ định danh</span>`;
          attribute[nameAttr] = value;
          saveToIndexDB('isClearToSave', true);

          ASICArrays.push(value);
          saveToIndexDB('ASICArrays', ASICArrays);
        }
        const selectedComponent = editor.getSelected();
        selectedComponent?.addAttributes(attribute);

        setTimeout(() => {
          const html = editor.getHtml();
          const parser = new DOMParser();
          const doc = parser.parseFromString(html, 'text/html');
          const elements = doc.querySelectorAll('[advanced-setting-identification-card]');
          const values: any[] = [];
          elements.forEach(element => {
            values.push(element.getAttribute('advanced-setting-identification-card'));
          });
          saveToIndexDB('ASICArrays', values);
        }, 500);
      });
    },
  });

  editor.DomComponents.addType('video', {
    model: {
      defaults: {
        traits: [
          {
            type: 'text',
            label: 'YouTube Link',
            name: 'videoId',
            placeholder: 'Paste YouTube link here',
            changeProp: true,
          },
        ],
      },

      init() {
        this.initialVideoId = this.get('videoId');
        this.isUpdatingVideoId = false;
        this.logTraits();
        this.on('change:videoId', this.updateVideoId);
        this.on('change:provider', this.logTraits);
      },

      logTraits() {
        const traits = this.get('traits');
        traits?.each(trait => {
          switch (trait.get("name")) {
            case "provider":
              trait.set('label', 'Nhà cung cấp');
              break;
            case "videoId":
              trait.set('label', 'Đường dẫn Youtube');
              trait.set('placeholder', 'Nhập đường dẫn Youtube');
              break;
            case "autoplay":
              trait.set('label', 'Tự động chạy');
              break;
            case "loop":
              trait.set('label', 'Chạy lại');
              break;
            case "controls":
              trait.set('label', 'Hiện các nút điều khiển');
              break;
            case "rel":
              trait.set('label', 'Các video liên quan');
              break;
            case "modestbranding":
              trait.set('label', 'Giao diện khiếm tốn');
              break;
            default:
              break;
          }
        });
      },

      updateVideoId() {
        if (this.isUpdatingVideoId) return;
        const fullUrl = this.get('videoId');
        const videoId = extractYoutubeVideoId(fullUrl);
        if (fullUrl !== this.initialVideoId) {
          if (videoId && (this.get('videoId').includes(videoId))) {
            const newSrc = `https://www.youtube.com/embed/${videoId}`;
            this.isUpdatingVideoId = true;
            this.set('src', newSrc);
            this.set('videoId', videoId);
            setTimeout(() => {
              this.isUpdatingVideoId = false;
            }, 0)
          } else if (!this.get('videoId')) {
            this.set('videoId', '');
            message.error('Không tìm thấy đường dẫn Youtube!');
          }
        }
      },
    },
  });
};
export default CommonAdvanceSettingsPlugin;
