import { isArray } from "lodash";
import { QuizAnsProps } from "../../../../../../types/scoring-course";
import EmptyComponent from "../../../../../../components/empty";

const VideoRecorder = ({ result }: QuizAnsProps) => {
  const currentAnsOfUser = result?.results[0];

  return (
    <div className="center">
      {isArray(currentAnsOfUser) ? (
        <video className="quest-video" controls>
          <source
            src={currentAnsOfUser[0]?.contents[0] || ""}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
      ) : <EmptyComponent description="Không có câu trả lời" />}
    </div>
  );
};

export default VideoRecorder;
