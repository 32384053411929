// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-plan-managment-download {
  width: 50%; }
  .course-plan-managment-download .radio-contain {
    padding: 20px 0;
    margin-top: 20px 0; }
    .course-plan-managment-download .radio-contain .radio-item {
      margin-bottom: 20px; }
  .course-plan-managment-download .infor-plan-none {
    position: absolute;
    right: -10000px;
    top: -10000px; }
  .course-plan-managment-download .construct-plan-none {
    position: absolute;
    right: -10000px;
    top: -10000px; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-course-construction-plan1/detail.scss"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAA;EADZ;IAII,eAAkB;IAClB,kBAAkB,EAAA;IALtB;MAQM,mBAAsB,EAAA;EAR5B;IAeI,kBAAkB;IAClB,eAAkB;IAClB,aAAkB,EAAA;EAjBtB;IAqBI,kBAAkB;IAClB,eAAkB;IAClB,aAAkB,EAAA","sourcesContent":[".course-plan-managment-download {\n  width: 50%;\n\n  .radio-contain {\n    padding   : 20px 0;\n    margin-top: 20px 0;\n\n    .radio-item {\n      margin-bottom   : 20px;\n      // margin-left  : 100px !important;\n    }\n  }\n\n  .infor-plan-none {\n    // transform: translate(0px, 9999px);\n    position: absolute;\n    right   : -10000px;\n    top     : -10000px;\n  }\n\n  .construct-plan-none {\n    position: absolute;\n    right   : -10000px;\n    top     : -10000px;\n  }\n\n  // .container{\n  //   width: 600px;\n  // }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
