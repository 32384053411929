import { Form, InputNumber } from "antd";
import { InputNumberProps } from "antd/lib";
import { ReactNode } from "react";

interface FormInputNumberProps {
  label: string;
  name?: string;
  value?: number;
  max?: number;
  min?: number;
  onChangeButton?: (val?: any) => void;
  onChange?: (val?: any) => void;
  addonBefore?: string | ReactNode
  addonAfter?: string | ReactNode
  controls?: boolean;
}

function FormInputNumber({
  label,
  name,
  value,
  max,
  min,
  addonBefore,
  addonAfter,
  controls,
  onChangeButton,
  onChange,
  ...rest
}: FormInputNumberProps) {
  return (
    <div className="setting-question-row">
      <div className="row-item">
        <span className="row-item">{label}</span>
        <Form.Item name={name} {...rest}>
          <InputNumber
            value={value}
            type="number"
            onStep={onChangeButton}
            onChange={onChange}
            min={min !== undefined ? min : 0}
            max={max !== undefined ? max : 999999999}
            addonBefore={addonBefore}
            addonAfter={addonAfter}
            controls={controls} />
        </Form.Item>
      </div>
    </div>
  );
}

export default FormInputNumber;
