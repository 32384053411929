import { AxiosResponse } from "axios"
import { axiosConfigV2 } from "../../../config/api/configApiv2"
import { axiosConfigToUpload } from "../../../config/api/configApiUpload"

export const getMoocPortfolioValueById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`/mooc-portfolio-value/${id}`)
}

export const downloadPortfolioValueTemplateFile: () => Promise<AxiosResponse<any>> = () => {
    return axiosConfigV2.get(`/mooc-portfolio-value/download-template-excel`, {responseType: "arraybuffer",})
}

export const getAllPortfolioValueByName: (name: string) => Promise<AxiosResponse<any>> = (name) => {
    return axiosConfigV2.get(`/mooc-portfolio-value/find-by-name?name=${name}`)
}

export const getAllValueByFilter: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-portfolio-value/get-all-by-filter`, data)
}

export const addMoocPortfolioValue: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-portfolio-value`, data)
}

export const exportExcelMoocPortfolioValue: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`/mooc-portfolio-value/export-excel`, data, {responseType: "arraybuffer",})
}

export const exportExcelMoocPortfolioValueDetail: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.get(`mooc-portfolio-value/export-excel/${id}`, {
        responseType: 'blob', // Đảm bảo nhận dữ liệu dưới dạng Blob để tạo file
    });
};

export const importExcelMoocPortfolioValue: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigToUpload.post(`/mooc-portfolio-value/import-excel`, data, {responseType: "arraybuffer",})
}

export const updateMoocPortfolioValue: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-portfolio-value`, data)
}

export const changeStatusPortfolioValue: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.put(`/mooc-portfolio-value/change-status`, data)
}

export const deleteMoocPortfolioValue: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfigV2.delete(`/mooc-portfolio-value/${id}`)
}