// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-synthesis .list-participant {
  width: 100%;
  margin-top: 20px; }

.survey-managment-synthesis .table-wrapper {
  width: 100% !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-synthesis/components/list-participants/index.scss"],"names":[],"mappings":"AAAA;EAEI,WAAgB;EAChB,gBAAgB,EAAA;;AAHpB;EAOI,sBACF,EAAA","sourcesContent":[".survey-managment-synthesis {\n  .list-participant {\n    width     : 100%;\n    margin-top: 20px;\n  }\n\n  .table-wrapper {\n    width: 100% !important\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
