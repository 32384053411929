import "./detail.scss";
import { Button, Input, Radio, Row, Space, ConfigProvider, Tooltip } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ListAnswer } from "../../../../../../../types/survey-managment/survey";
import TextArea from "antd/es/input/TextArea";

const QuestionType3 = (props: any) => {
  const [options, setOptions] = useState<ListAnswer[]>(props.listOptions || []);

  const onHandleDeleteOption = (index: number) => {
    const newOptions = options.filter((_, i) => i !== index);

    setOptions(newOptions);
    props.setUpdateListAnswer(newOptions);
  };

  const onHandleAddOption = (text: string) => {
    let newOptions = [...options];
    const addedOption = {
      answerText: ` ${text} `,
      answerOrderNumber: options.length + 1,
    };
    newOptions.push(addedOption);
    setOptions(newOptions);
    props.setUpdateListAnswer(newOptions);
  };

  if (options.length === 0) {
    onHandleAddOption(`Đúng`);
  }
  if (options.length === 1) {
    onHandleAddOption(`Sai`);
  }

  const handleChangeAnswerText = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newOptions = [...options];
    newOptions[index] = {
      ...newOptions[index],
      answerText: event.target.value,
    };
    setOptions(newOptions);
    props.setUpdateListAnswer(newOptions);
  };

  useEffect(() => {
    setOptions(props.listOptions);
  }, [props.listOptions]);

  return (
    <div className="survey-managment-construction">
      <div>
        <br />
        <ConfigProvider
          theme={{
            components: {
              Input: {
                paddingInline: 0,
                paddingBlock: 0,
              },
              Button: {
                paddingInline: 2,
                paddingBlock: 0,
                colorText: "black",
              },
            },
          }}
        >
          <Space direction="vertical" size="small" style={{ display: "flex" }}>
            {options.map((option, index) => {
              return (
                <div key={index}>
                  {!option.isOther && index < 2 && (
                    <Row
                      style={{
                        margin: "0 auto",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Radio checked={false}></Radio>
                      <TextArea
                        style={{
                          flex: 1,
                          marginLeft: "10px",
                          marginRight: "10px",
                          padding: "10px 0",
                          display: "flex",
                          alignItems: "center",
                          lineHeight: "1.5",
                        }}
                        autoSize
                        variant="borderless"
                        value={
                          option.answerText || option.answerText === ""
                            ? option.answerText
                            : `Lựa chọn văn bản ${options.length}`
                        }
                        onChange={(event) =>
                          handleChangeAnswerText(event, index)
                        }
                      />
                      <Tooltip placement="top" title="Xóa lựa chọn">
                        <Button
                          style={{ width: 20, height: 25, marginLeft: "5px" }}
                          icon={<CloseOutlined />}
                          onClick={() => {
                            onHandleDeleteOption(index);
                          }}
                        />
                      </Tooltip>

                    </Row>
                  )}
                </div>
              );
            })}

            {props.isQuestionHasOther && (
              <Row
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Input
                  prefix={
                    <>
                      <Radio checked={false}></Radio>
                      <span>
                        <i>
                          {" "}
                          Lựa chọn nhập <u>"Khác"</u>
                        </i>
                      </span>
                    </>
                  }
                  variant="borderless"
                />
              </Row>
            )}
          </Space>
        </ConfigProvider>
        <br />
      </div>
    </div>
  );
};

export default QuestionType3;

/* import "./detail.scss";
import { Input, Radio, Row, Space, ConfigProvider } from "antd";

const QuestionType3 = (props: any) => {

  return (
    <div>
      <div>
        <br />
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "black",
                paddingInline: 0,
                paddingBlock: 0,
                colorTextDisabled: "black"
              },
              Button: {
                paddingInline: 2,
                paddingBlock: 0,
              },
            },
          }}
        >
        <Space direction="vertical" size="small" style={{ display: "flex" }}>
          <Row
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Input
              prefix={<Radio checked={false}></Radio>}
              variant="borderless"
              value={`Đúng`}
              disabled
            />
          </Row>
          <Row
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Input
              prefix={<Radio checked={false}></Radio>}
              variant="borderless"
              value={`Sai`}
              disabled
            />
          </Row>
          {props.isQuestionHasOther && (
              <Row
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Input
                  prefix={
                    <>
                      <Radio checked={false}></Radio>
                      <span>
                        <i>
                          {" "}
                          Lựa chọn nhập <u>"Khác"</u>
                        </i>
                      </span>
                    </>
                  }
                  variant="borderless"
                />
              </Row>
            )}
        </Space>
        </ConfigProvider>
        <br />
      </div>
    </div>
  );
};

export default QuestionType3;
*/
