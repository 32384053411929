import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Row,
  TreeDataNode,
  Typography,
  Space,
  Card,
  message,
} from "antd";
import { CarryOutOutlined, PlusOutlined } from "@ant-design/icons";

import CustomCard from "../../../../../../components/custom-card/CustomCard";
import DetailChapter from "./detail-chapter/DetailChapter";
import {
  TypeCourseStructure,
  TypeChapterList,
  TypeSubjectList,
  TypeCoursePlanAssignment,
  moocCoursePlanAssignment,
} from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";
import {
  getListMoocCourseDescriptionDocumentById,
  getListMoocCourseStructureById,
} from "../../../../../../service/course-plan-managment/planning-course-construction/PlanningCourseConstructionApi";

interface CourseStructureProps {
  dataCourseStructure: TypeChapterList[];
  setDataCourseStructure: (data: TypeChapterList[]) => void;
  detailData: any;
}

const CourseStructure: React.FC<CourseStructureProps> = ({
  dataCourseStructure,
  setDataCourseStructure,
  detailData,
}) => {
  console.log("dataCourseStructure lập kh", dataCourseStructure);
  const treeData: TreeDataNode[] = [
    {
      title: (
        <Row>
          <div className="card-heading--info">
            <Typography.Title style={{ fontSize: 17 }}>
              Chương 1: Giới thiệu
            </Typography.Title>
            <Typography.Text className="sub-title" style={{ fontSize: 15 }}>
              Lịch trình: 15/12/2023 ; 09:00 - 11:00
            </Typography.Text>
          </div>
        </Row>
      ),
      key: "0-0",
      // icon: <MenuUnfoldOutlined style={{ marginRight: 20 }} />,
      children: [
        {
          title: (
            <div
              // onClick={}
              // className="w-full button-sticky"
              style={{ display: "flex", alignContent: "center" }}
            >
              <PlusOutlined style={{ fontSize: 20, marginRight: 20 }} />
              <Typography.Text className="sub-title" style={{ fontSize: 15 }}>
                Thêm bài tập
              </Typography.Text>
            </div>
            // <div className="card-heading--info">
            //   <Typography.Title className="sub-title">
            //     Chương 1: Giới thiệu
            //   </Typography.Title>
            // </div>
          ),
          // switcherIcon: false,
          style: { marginTop: 10 },
          key: "0-0-0",
          // icon: <CarryOutOutlined />,
          children: [
            {
              title: (
                <div
                  // onClick={}
                  // className="w-full button-sticky"
                  style={{ display: "flex", alignContent: "center" }}
                >
                  <Typography.Text
                    className="sub-title"
                    style={{ fontSize: 15, marginLeft: 20 }}
                  >
                    Thêm học liệu
                  </Typography.Text>
                </div>
              ),
              key: "0-0-0-0",
              icon: <CarryOutOutlined />,
              style: { marginTop: 10 },
            },

            {
              title: (
                <div
                  // onClick={}
                  // className="w-full button-sticky"
                  style={{ display: "flex", alignContent: "center" }}
                >
                  <Typography.Text
                    className="sub-title"
                    style={{ fontSize: 15, marginLeft: 20 }}
                  >
                    Thêm học liệu
                  </Typography.Text>
                </div>
              ),
              key: "0-0-0-2",
              icon: <CarryOutOutlined />,
              style: { marginTop: 10 },
            },
          ],
        },
      ],
    },
  ];
  const [expandAll, setExpandAll] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const handleClickExpand = () => {
    setExpandAll(!expandAll);
  };
  useEffect(() => {
    if (expandAll) {
      setExpandedKeys(["0-0", "0-0-0"]);
    } else {
      setExpandedKeys([]);
    }
  }, [expandAll]);
  const onSelect = (selectedKeys: React.Key[], info: any) => {
    console.log("selected", selectedKeys, info);
  };

  const updateChapterList = (newChapterList: TypeChapterList[]) => {
    setDataCourseStructure(newChapterList);
  };

  interface CourseItem {
    id: number;
    courseConstructionPlanId?: number;
    structureItemName?: string;
    structureItemDescription?: string;
    structureItemLevel?: number;
    coursewareType?: number;
    orderNumber?: number;
    parentId?: number;
    createdDate?: Date;
    updatedDate?: Date;
    isDeleted?: boolean;
    subjectList: TypeSubjectList[];
    moocCoursePlanAssignment?: moocCoursePlanAssignment[];
  }

  const normalizeData = (data: CourseItem[]): TypeChapterList[] => {
    const itemsById: Record<number, CourseItem> = {};

    // Initialize structure with empty subjectList and lessonList
    data.forEach((item) => {
      itemsById[item.id] = {
        ...item,
        subjectList: item.subjectList || [],
      };
    });

    // Create hierarchical structure
    data.forEach((item) => {
      if (item.parentId) {
        const parent = itemsById[item.parentId];
        if (parent) {
          if (item.structureItemLevel === 2) {
            parent.subjectList.push({
              ...item,
              lessonList: [],
            } as TypeSubjectList);
          } else if (item.structureItemLevel === 3) {
            // Here we should find the correct subject and add the lesson to its lessonList
            Object.values(itemsById).forEach((potentialParent) => {
              if (potentialParent.structureItemLevel === 1) {
                const subject = potentialParent.subjectList.find(
                  (sub) => sub.id === item.parentId
                );
                if (subject) {
                  subject.lessonList.push(item as TypeCourseStructure);
                }
              }
            });
          }
        }
      }
    });

    // Filter to return only top-level items (Chapters)
    const topLevelItems: TypeChapterList[] = Object.values(itemsById)
      .filter((item) => item.parentId === null && item.structureItemLevel === 1)
      .map((item) => ({
        ...item,
        moocCoursePlanAssignment: item.moocCoursePlanAssignment
          ? item.moocCoursePlanAssignment.map((assignment) => ({
              ...assignment,
              isDeleted: assignment.isDeleted || false, // Ensure boolean value
            }))
          : [],
      })) as TypeChapterList[];

    return topLevelItems;
  };

  const synchronizeMoocStructure = async () => {
    try {
      const response = await getListMoocCourseStructureById(
        detailData.registration_stage_id
      );
      if (response && response.data) {
        const normalizedData = normalizeData(response.data.data);
        console.log("đây là normalizedData", normalizeData);
        setDataCourseStructure(normalizedData);

        message.success("Đồng bộ cấu trúc thành công!");
      } else {
        throw new Error("Invalid data format received from API");
      }
    } catch (error) {
      console.error("Error in synchronizeMoocStructure: ", error);
      message.error(`Error synchronizing structure: ${error}`);
    }
  };

  const handleAddNewChapter = () => {
    const newChapter: TypeChapterList = {
      structureItemName: "Chương mới",
      structureItemLevel: 1,
      orderNumber: dataCourseStructure ? dataCourseStructure.length + 1 : 1, // Tính toán số thứ tự chương mới
      subjectList: [
        {
          structureItemName: "Tên bài giảng",
          structureItemLevel: 2,
          orderNumber: 1,
          lessonList: [
            {
              structureItemName: "Tên học liệu",
              structureItemLevel: 3,
              orderNumber: 1,
            },
          ],
        },
      ],
    };

    // Thêm chương mới vào danh sách chapterList
    const newChapterList = [...(dataCourseStructure || []), newChapter];

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(newChapterList);
  };

  const [isShowDetailAll, setIsShowDetailAll] = useState<boolean>(true);
  const handleToggleShowDetailAll = () => {
    setIsShowDetailAll(!isShowDetailAll);
  };

  return (
    <div className="course-managment-plan-course-structure">
      <Space
        size="middle"
        direction="vertical"
        style={{ width: "100%", marginTop: "-25px" }}
        className="spaceTag"
      >
        <Card style={{ marginBottom: "20px", minWidth: "680px" }}>
          <Row justify="space-between" style={{ marginTop: "20px" }}>
            <Col>
              <Row>
                <Typography.Title level={4}>
                  Cấu trúc của khóa học
                </Typography.Title>
              </Row>
              <Row>
                <Typography.Text style={{ color: "GrayText" }}>
                  Xây dựng cấu trúc của khoá học
                </Typography.Text>
              </Row>
            </Col>
            <Col>
              <Button onClick={synchronizeMoocStructure}>
                {/* {isShowDetailAll ? "Thu gọn" : "Hiển thị chi tiết"} */}
                Đồng bộ cấu trúc
              </Button>
            </Col>
          </Row>
        </Card>

        <DetailChapter
          // chapterList={chapterList}
          chapterList={dataCourseStructure}
          updateChapterList={updateChapterList}
          isShowDetailAll={isShowDetailAll}
          setIsShowDetailAll={setIsShowDetailAll}
        />

        {/* thêm chương */}
        <>
          <Button
            type="primary"
            onClick={handleAddNewChapter}
            className="w-full button-sticky"
          >
            <PlusOutlined />
            Thêm chương
          </Button>
        </>
      </Space>
    </div>
  );
};

export default CourseStructure;
