// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-form-item .ant-form-item-label {
  width: 33.333333%;
  display: flex;
  justify-self: start;
  font-weight: 500; }

.card-form-item .ant-form-item-explain-error {
  font-weight: 500; }

.tree-course .ant-tree-treenode {
  width: 100%; }
  .tree-course .ant-tree-treenode .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    width: 100%; }
    .tree-course .ant-tree-treenode .ant-tree-node-content-wrapper .ant-tree-title {
      width: 100%; }

.setting-structure-tree .ant-tree-treenode {
  border-bottom-width: 1px; }

.setting-structure-tree .ant-tree-switcher {
  display: flex;
  justify-content: center;
  align-items: center; }

@media screen and (max-width: 768px) {
  .card-form-item .ant-form-item-label {
    width: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/pages/setting-structure-course/setting-structure.scss"],"names":[],"mappings":"AAAA;EAEM,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,gBAAgB,EAAA;;AALtB;EAQM,gBAAgB,EAAA;;AAIpB;EAEM,WAAW,EAAA;EAFjB;IAIU,aAAa;IACb,mBAAmB;IACnB,WAAW,EAAA;IANrB;MAQc,WAAW,EAAA;;AAK3B;EAEI,wBAAwB,EAAA;;AAF5B;EAKI,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;;AAIrB;EACE;IAEI,WAAW,EAAA,EACZ","sourcesContent":[".card-form-item{\n    .ant-form-item-label{\n      width: 33.333333%;\n      display: flex;\n      justify-self: start;\n      font-weight: 500;\n    }\n    .ant-form-item-explain-error{\n      font-weight: 500;\n    }\n  }\n\n  .tree-course {\n    .ant-tree-treenode{\n        width: 100%;\n        .ant-tree-node-content-wrapper {\n            display: flex;\n            align-items: center;\n            width: 100%;\n            .ant-tree-title{\n                width: 100%;\n            }\n        }\n    }\n}\n.setting-structure-tree {\n  .ant-tree-treenode{\n    border-bottom-width: 1px;\n  }\n  .ant-tree-switcher {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n  @media screen and (max-width: 768px) {\n    .card-form-item{\n      .ant-form-item-label{\n        width: 100%;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
