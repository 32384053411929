// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-plan-managment-detail-appraisal .wrapper {
  padding: 50px;
  width: 100%;
  overflow-x: auto; }
  .course-plan-managment-detail-appraisal .wrapper .card-info--wrapper {
    width: 800px;
    margin-bottom: 0; }
    .course-plan-managment-detail-appraisal .wrapper .card-info--wrapper.no-bottom-space {
      margin-bottom: 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/organize-appraisal-board/organize-appraisal-board-detail/DetailOrganizeAppraisalBoard.scss"],"names":[],"mappings":"AAAA;EAEQ,aAAgB;EAChB,WAAgB;EAEhB,gBAAgB,EAAA;EALxB;IAQY,YAAuB;IAEvB,gBAAmB,EAAA;IAV/B;MAagB,gBAAgB,EAAA","sourcesContent":[".course-plan-managment-detail-appraisal {\n    .wrapper {\n        padding   : 50px;\n        width     : 100%;\n        // height: calc(100vh - 168px);\n        overflow-x: auto;\n\n        .card-info--wrapper {\n            width           : 800px;\n            // margin-bottom: 24px;\n            margin-bottom   : 0;\n\n            &.no-bottom-space {\n                margin-bottom: 0;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
