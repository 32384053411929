import {
  Form,
  FormInstance,
  Layout,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
} from "antd";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import TableData from "../../../../components/table-data/TableData";
import { CheckCircleOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import FilterTable from "../../../../components/course-library/university-content-moderation/list-exam/filter-table/FilterTable";
import FormFilter from "../../../../components/course-library/university-content-moderation/list-exam/filter-group/FormFilter";
import { isEmpty } from "lodash";
import { formatOriginTimev3 } from "../../../../utils/converDate";
import {
  getAllUniversityApprove,
  getAllUniversityToMinisterialApprove,
} from "../../../../service/library/university-content-moderation";
import {
  getAllAuthor,
  getAllProductionUnit,
  getTopicFilter,
} from "../../../../service/library";
import { routesConfig } from "../../../../config/routes";

type Props = {
  title?: string;
};

const UniversityExamModeration = (props: Props) => {
  const { title } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tab, setTab] = useState("1");
  const [pageSize, setPageSize] = useState<number>(10);
  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [listDataExam, setListDataExam] = useState<any>();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [typeQuestion, setTypeQuestion] = useState<boolean>(false);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);
  const [countAwaitingMinistryReview, setCountAwaitingMinistryReview] =
    useState<number>(0);

  const [dataOptions, setDataOptions] = useState<any>({
    dataTopic: [],
    dataLevel: [
      {
        id: 3,
        name: "Cấp Bộ",
      },
      {
        id: 2,
        name: "Cấp Trường",
      },
      {
        id: 1,
        name: "Cấp Giảng viên",
      },
    ],
    dataType: [
      {
        id: "Duyệt mới",
        name: "Duyệt mới",
      },
      {
        id: "Duyệt chỉnh sửa",
        name: "Duyệt chỉnh sửa",
      },
    ],
    dataAuthor: [],
  });
  const [filterData, setFilterData] = useState<any>({
    topicIds: [],
    typeApproved: null,
    productionUnitId: null,
    approvedSendDateFrom: null,
    approvedSendDateTo: null,
    approvedDateFrom: null,
    approvedDateTo: null,
    typeProduction: null,
    userIds: [],
    status: false,
  });

  const listBreadcrumb = [
    {
      title: "Thư viện / Kiểm duyệt nội dung cấp Trường",
    },
    {
      title: <span className="style-title">Danh sách đề</span>,
    },
  ];

  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterData({
      ...filterData,
      topicIds: null,
      typeApproved: null,
      productionUnitId: null,
      approvedSendDateFrom: null,
      approvedSendDateTo: null,
      approvedDateFrom: null,
      approvedDateTo: null,
      typeProduction: null,
      userIds: [],
    });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      fetchData();
    }
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    fetchData();
  };

  const handleFinish = (values: any) => {
    setPageNumber(1);
    setFilterData({
      ...filterData,
      topicIds: values.topic,
      typeApproved: values.type,
      typeProduction: values.level,
      userIds: values.author,
      approvedSendDateFrom:
        values?.time && tab != "2"
          ? (values?.time[0]).add(1, "day").toISOString()
          : null,
      approvedSendDateTo:
        values?.time && tab != "2"
          ? (values?.time[1]).add(1, "day").toISOString()
          : null,
      approvedDateFrom:
        values?.time && tab != "1"
          ? (values?.time[0]).add(1, "day").toISOString()
          : null,
      approvedDateTo:
        values?.time && tab != "1"
          ? (values?.time[1]).add(1, "day").toISOString()
          : null,
    });
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "courseName,asc"
        : sorter.order === "descend"
        ? "courseName,desc"
        : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const onChangeTab = (key: string) => {
    setTab(key);
    setFilterData({
      ...filterData,
      status: key === "2",
    });
    setPageNumber(1);
    form.resetFields();
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã đề",
      dataIndex: "code",
      key: "code",
      width: 20,
    },
    {
      title: "Tiêu đề",
      dataIndex: "title",
      key: "title",
      sorter: true,
      width: 40,
    },
    ...(
      !typeQuestion ? [
        {
          title: "Chủ đề",
          dataIndex: "topic",
          key: "topic",
          width: 20,
          render(_: any, record:any) {
            return <p>{record?.topic?.map((item: any) => item.name).join(", ")}</p>;
          },
        },
      ] : []
    ),
    {
      title: "Phân cấp",
      dataIndex: "typeProduction",
      key: "typeProduction",
      width: 20,
      render(_: any, record) {
        return (
          <>
            {record.typeProduction === 1 ? (
              <>Cấp giảng viên</>
            ) : record.typeProduction === 2 ? (
              <>Cấp trường</>
            ) : (
              <>Cấp bộ</>
            )}
          </>
        );
      },
    },
    ...(
      !typeQuestion ? [] : [
        {
          title: "Tác giả",
          dataIndex: "userIds",
          key: "userIds",
          width: 20,
          sorter:true,
          render(_: any, record:any) {
            return <p>{record?.author?.map((item: any) => item.name).join(", ")}</p>;
          },
        },
      ]
    ),
    {
      title: "Phân loại",
      dataIndex: "typeApproved",
      key: "typeApproved",
      width: 20,
    },
    ...(
      !typeQuestion ? [
        {
          title: "Tác giả",
          dataIndex: "userIds",
          key: "userIds",
          width: 20,
          render(_: any, record:any) {
            return <p>{record?.author?.map((item: any) => item.name).join(", ")}</p>;
          },
        },
      ] : [
        {
          title: "Kết quả duyệt",
          dataIndex: "status",
          key: "status",
          width: 20,
        },
      ]
    ),
    tab !== "1" || typeQuestion
      ? {
          title: "Người duyệt",
          dataIndex: "approveByName",
          key: "approveByName",
          width: 20,
        }
      : { width: 0 },

    tab !== "1" || typeQuestion
      ? {
          title: "Ngày duyệt",
          dataIndex: "publicDate",
          key: "publicDate",
          width: 20,
          render: (_: any, record: any) => {
            return (
              <>
                {!isEmpty(record?.publicDate)
                  ? formatOriginTimev3(record?.publicDate)
                  : null}
              </>
            );
          },
        }
      : {
          title: "Ngày gửi duyệt",
          dataIndex: "approvedSendDate",
          key: "approvedSendDate",
          width: 20,
          render: (_: any, record: any) => {
            return (
              <>
                {!isEmpty(record?.approvedSendDate)
                  ? formatOriginTimev3(record?.approvedSendDate)
                  : null}
              </>
            );
          },
        },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  {
                    navigate(
                      `/${routesConfig.universityContentModeration}/${routesConfig.previewExam}?id=${record.id}`,
                      {
                        state: {
                          backUrl: `/${routesConfig.courseLibrary}/${routesConfig.universityContentModeration}/${routesConfig.listExamRepository}`,
                          mode: "1",
                          titleBreadCrumb: "Kiểm duyệt nội dung cấp Trường",
                        },
                      }
                    );
                  }
                }
              >
                <EyeOutlined />
              </a>
              <a
                className="action-table"
                onClick={() => {
                  navigate(
                    `/${routesConfig.universityContentModeration}/${routesConfig.previewExam}?id=${record.id}`,
                    {
                      state: {
                        backUrl: `/${routesConfig.courseLibrary}/${routesConfig.universityContentModeration}/${routesConfig.listExamRepository}`,
                        mode: "2",
                        titleBreadCrumb: "Kiểm duyệt nội dung cấp Trường",
                      },
                    }
                  );
                }}
              >
                <CheckCircleOutlined />
              </a>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 20,
    },
  ];

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Chờ duyệt
          <span
            className={`number-record ${
              tab === "1" ? "number-record-active" : "number-record-disabled"
            }`}
          >
            {countAwaitingReview}
          </span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            pagination={true}
            pageSize={pageSize}
            hideSelection={true}
            dataTable={listDataExam}
            pageNumber={pageNumber}
            dataColumns={dataColumns}
            loadingTable={loading}
            setPageSize={setPageSize}
            scroll={{ x: 1500, y: 800 }}
            setPageNumber={setPageNumber}
            totalRecords={countAwaitingReview}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          Đã duyệt
          <span
            className={`number-record ${
              tab === "2" ? "number-record-active" : "number-record-disabled"
            }`}
          >
            {countReviewed}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listDataExam}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={countReviewed}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div>
          Chờ cấp bộ duyệt
          <span
            className={`number-record ${
              tab === "3" ? "number-record-active" : "number-record-disabled"
            }`}
          >
            {countAwaitingMinistryReview}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listDataExam}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={countReviewed}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={true}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  // fetch data
  const fetchData = async () => {
    setLoading(true);
    const payload = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      topicIds: filterData.topicIds,
      typeApproved: filterData.typeApproved,
      typeProduction: filterData.typeProduction,
      productionUnitId: filterData.productionUnitId,
      approvedSendDateFrom: filterData.approvedSendDateFrom,
      approvedSendDateTo: filterData.approvedSendDateTo,
      approvedDateFrom: tab !== "1" ? filterData.approvedDateFrom : null,
      approvedDateTo: tab !== "1" ? filterData.approvedDateTo : null,
      type: "unit_quiz",
      status: filterData.status,
      userIds: filterData.userIds ? filterData.userIds : [],
      typeQuestion: null,
      sort:
        isEmpty(fieldSortOrder) || isEmpty(statusOrder)
          ? ["approvedSendDate, desc"]
          : [`${fieldSortOrder},${statusOrder}`],
    };

    try {
      tab !== "3"
        ? await getAllUniversityApprove(payload).then((res) => {
            if (res.status === 200) {
              setListDataExam(res.data.data.data);
              setTotalRecords(res.data.data.total);
              setCountReviewed(res.data.data.approvedCount);
              setCountAwaitingReview(res.data.data.approvedWaitCount);
              setCountAwaitingMinistryReview(
                res.data.data.facilityToMinisterialWaitCount
              );

              setTimeout(() => {
                setLoading(false);
              }, 333);
            }
          })
        : await getAllUniversityToMinisterialApprove(payload).then((res) => {
            if (res.status === 200) {
              setListDataExam(res.data.data.data);
              setTotalRecords(res.data.data.total);
              setCountReviewed(res.data.data.approvedCount);
              setCountAwaitingReview(res.data.data.approvedWaitCount);
              setCountAwaitingMinistryReview(
                res.data.data.facilityToMinisterialWaitCount
              );

              setTimeout(() => {
                setLoading(false);
              }, 333);
            }
          });
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  // useEffect
  useEffect(() => {
    fetchData();
  }, [filterData, pageSize, pageNumber, statusOrder, tab]);

  useEffect(() => {
    Promise.all([
      getTopicFilter({ code: "topic" }),
      getAllProductionUnit({ name: null }),
    ]).then((res) => {
      const listTopic = res[0].data.data.moocPortfolioValueData;
      const listAuthor = res[1].data.data;

      setDataOptions({
        ...dataOptions,
        dataTopic: listTopic,
        dataAuthor: listAuthor,
      })
    })
  }, [])
  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, { values, forms }) => {}}>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={!typeQuestion ? title : 'Lịch sử kiểm duyệt'} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterTable
              searchValue={searchValue}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              handleChangeInput={handleChangeInput}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
            typeQuestion={typeQuestion}
          />
        </div>

        <div className="tabs-heading">
          {
            typeQuestion
            ?
              <TableData
                pagination={true}
                pageSize={pageSize}
                dataTable={listDataExam}
                pageNumber={pageNumber}
                dataColumns={dataColumns}
                loadingTable={loading}
                setPageSize={setPageSize}
                scroll={{ x: 1500, y: 800 }}
                setPageNumber={setPageNumber}
                totalRecords={countAwaitingReview}
                handleTableChangeProps={handleTableChange}
              />
            :
            <Tabs items={itemsTab} activeKey={tab} onChange={onChangeTab} />
          }
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default UniversityExamModeration;
