import { Form, Table, Typography } from "antd";
import type { TableColumnsType, TableProps } from "antd";
import "./operation-history.scss";
import FormFilter from "./components/filter-group/FormFilter";
import FilterTable from "./components/filter-table/FilterTable";
import { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import {
  getDataSurveyActionHistory,
  getDataSurveyUserHistory,
  getSurveyPlanHistoryById,
} from "../../../../../service/course-construct/survey-plan";
import { useParams } from "react-router-dom";
import { formatDateV2 } from "../../../../../utils/converDate";
import TableData from "../../../../table-data/TableData";
import { values } from 'lodash';

interface DataType {
  key: React.Key;
  id: number;
  createdDate: string;
  userName: string;
  event: string;
  action: string;
}

type OperationHistoryProps = {
  surveyPlan?: any;
};

const locale = {
  emptyText: (
    <span>
      <p>
        <img
          className="image-empty-data"
          src="/images/empty-img-gray.png"
          alt="empty-img"
        ></img>
      </p>
      <p className="nodata-text">Không tìm thấy</p>
    </span>
  ),
  triggerDesc: "Sắp xếp theo thứ tự Z-A",
  triggerAsc: "Sắp xếp thứ tự A-Z",
  cancelSort: "Huỷ sắp xếp",
};

const OperationHistory = ({ surveyPlan }: OperationHistoryProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataOptions, setDataOptions] = useState({
    dataOperation: [],
    dataUser: [],
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>();
  const [totalElements, setTotalElements] = useState<number>(0);
  const [listSurveyPlanHistory, setListSurveyPlanHistory] = useState([]);
  const [filterData, setFilterData] = useState<any>({
    actions: null,
    timeStart: null,
    timeEnd: null,
    users: null,
    currentDate: null,
  });

  const columns: TableColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      className: "survey-form-id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Thời gian",
      dataIndex: "createdDate",
      className: "survey-form-date",
      sorter: (a, b) => a.createdDate.localeCompare(b.createdDate),
      render: (_: any, record) => {
        return (
          <>{record.createdDate ? formatDateV2(record.createdDate) : null}</>
        );
      },
    },
    {
      title: "Tài khoản",
      dataIndex: "userName",
      className: "survey-form-name",
      sorter: (a, b) => a.userName.localeCompare(b.userName),
    },
    {
      title: "Sự kiện",
      dataIndex: "event",
      className: "survey-form-event",
      sorter: (a, b) => a.event.localeCompare(b.event),
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      className: "survey-form-actions",
      sorter: (a, b) => a.action.localeCompare(b.action),
    },
  ];

  const getDataHistorySurvey = () => {
    setLoading(true);
    const dataReq = {
      page: currentPage,
      size: pageSize,
      keyword: searchValue,
      actions: filterData.actions ? filterData.actions : [],
      timeStart: filterData.timeStart ? filterData.timeStart : "",
      timeEnd: filterData.timeEnd ? filterData.timeEnd : "",
      users: filterData.users ? filterData.users : [],
    };

    getSurveyPlanHistoryById(surveyPlan.id, dataReq)
      .then((res) => {
        const { data } = res.data;
        setListSurveyPlanHistory(data.content);
        setTotalPage(data.totalPages);
        setTotalElements(data.totalElements);
        setPageSize(data.size),
          setTimeout(() => {
            setLoading(false);
          }, 500);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  };

  const getActionsFilter = (value: any) => {
    if (value) {
      const listActions = value.map((action: string) => {
        switch (action) {
          case "create":
            return "Thêm mới";
          case "update":
            return "Chỉnh sửa";
          case "delete":
            return "Xóa";
          case "view":
            return "Xem";
          case "view_detail":
            return "Xem chi tiết";
        }
      });
      return listActions;
    }
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  // FormFilter
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      actions: [],
      timeStart: "",
      timeEnd: "",
      users: [],
    });
  };

  // const handleChangeFilter = (value: any, name: string) => {
  //   setFilterData({ ...filterData, [name]: value });
  // };

  // Handle search FilterTable
  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getDataHistorySurvey();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSubmitSearch = () => {
    getDataHistorySurvey();
  };

  const onFinish = (values: any) => {
    setFilterData({
      ...filterData,
      actions: getActionsFilter(values.operation),
      timeStart: values?.rangeDate ? (values?.rangeDate[0]).add(1, 'day').toISOString() : "",
      timeEnd: values?.rangeDate ? (values?.rangeDate[1]).add(1, 'day').toISOString() : "",
      users: values.user,
    });
  };

  // useEffect
  useEffect(() => {
    getDataHistorySurvey();
  }, [currentPage, pageSize, filterData, surveyPlan]);

  useEffect(() => {
    Promise.all([
      getDataSurveyActionHistory(),
      getDataSurveyUserHistory(surveyPlan.id),
    ])
      .then((res) => {
        const dataActions = res[0].data.data;
        const listUsers = res[1].data.data;
        const dataUsers = listUsers.map((user: any) => {
          return {
            id: user.id,
            name: user.username,
          };
        });
        setDataOptions({
          ...dataOptions,
          dataOperation: dataActions,
          dataUser: dataUsers,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="wrapper-settings-history">
      <section className="history-page">
        <div className="history-header">
          <Typography.Title className="history-title" style={{ margin: 0 }}>
            Lịch sử thao tác
          </Typography.Title>
          <FilterTable
            handleOpenFilter={handleOpenFilter}
            showFilter={showFilter}
            searchValue={searchValue}
            handleSearch={handleSearch}
            handleChangeInput={handleChangeInput}
            handleSubmitSearch={handleSubmitSearch}
          />
        </div>
        <div className="filter-detail">
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            filterData={filterData}
            // setFilterData={handleChangeFilter}
            onFinish={onFinish}
          />
        </div>
        <div className="tabs-heading">
          <TableData
            dataTable={listSurveyPlanHistory}
            dataColumns={columns}
            setPageNumber={setCurrentPage}
            setPageSize={setPageSize}
            pageNumber={currentPage}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalElements}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      </section>
    </div>
  );
};

export default OperationHistory;
