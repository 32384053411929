// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read-only .form-detail {
  pointer-events: none; }

.ant-tree-treenode:hover .ant-tree-node-content-wrapper {
  background: none; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-course-planning-assignment/components/course-assignment-detail/detail.scss"],"names":[],"mappings":"AACA;EACE,oBAAoB,EAAA;;AAGtB;EACE,gBAAgB,EAAA","sourcesContent":["\n.read-only .form-detail {\n  pointer-events: none;\n}\n\n.ant-tree-treenode:hover .ant-tree-node-content-wrapper {\n  background: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
