import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";
import { IDataCreateSpecializedGroup } from "../../../types/course-plan-managment/specialized-group/specialized-group";

export const findOrganizationsWithMembers: (data: any) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfig.post(`/specialized-group/search`, data)
}

export const changeActive: (id: string, dataEdit: any) => Promise<AxiosResponse<any>> = (id, dataEdit) => {
  return axiosConfig.patch(`/specialized-group/lock-unlock/${id}`, dataEdit)
}

export const getDetailSpecializedGroup: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/specialized-group/${id}`)
}
export const getTeacherSpecial: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/specialized-group/get-teacher`)
}
export const createSpecializedGroup: (
  organization: IDataCreateSpecializedGroup
) => Promise<AxiosResponse<any>> = (specializedGroup) => {
  return axiosConfig.post(`/specialized-group/create`, specializedGroup);
};

export const getSpecializedGroupDetail: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/specialized-group/${Number(id)}`);
};

export const specializedGroupDelete: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.patch(`/specialized-group/delete/${id}`);
};