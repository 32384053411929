// import { IDataCreateUser } from "../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";
import { IDataCreateSurvey } from "../../../types/survey-managment/survey";

// export const createUser: (
//   user: IDataCreateUser
// ) => Promise<AxiosResponse<any>> = (user) => {
//   return axiosConfig.post(`/accounts/QTCS`, user);
// };

// export const getAccountDetail: (id: string) => Promise<AxiosResponse<any>> = (
//   id: string
// ) => {
//   return axiosConfig.get(`/accounts/get-by-id/${id}`);
// };

// export const getAccountUsers: (data: any) => Promise<AxiosResponse<any>> = (
//   data: any
// ) => {
//   return axiosConfig.post(`/accounts/QTCS/search`, data);
// };

// export const getCollaborator: () => Promise<AxiosResponse<any>> = () => {
//   return axiosConfig.get(`/survey/get-collaborator`);
// };

export const getUniversity: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-survey/get-unniversity`);
};

export const getIndustry: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-survey/get-industry`);
};
export const getdataIndustryGroup: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-survey/get-industry-group`);
};
