// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browsing-results .list-item {
  align-items: flex-start; }
  .browsing-results .list-item .list-item--title {
    width: 150px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/assign-course-construction/assigned-browsing-results/index.scss"],"names":[],"mappings":"AAAA;EAEI,uBAAuB,EAAA;EAF3B;IAIM,YAAY,EAAA","sourcesContent":[".browsing-results {\n  .list-item {\n    align-items: flex-start;\n    .list-item--title {\n      width: 150px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
