import {DownOutlined, PlusOutlined, SearchOutlined, UpOutlined} from '@ant-design/icons';
import {Button, Row, Typography} from 'antd';
import {ChangeEvent, KeyboardEvent} from 'react';
import FormInput from "../../../form-input/FormInput";
import {Col} from "antd/lib";
import './filter-survey-plans.scss'

type FilterSurveyPlansProps = {
  handleOpenFilter: () => void
  searchValue: string
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void
  handleSubmitSearch: () => void
  handleOpen?: () => void
  showFilter: boolean
}


const FilterSurveyPlans = ({
                             handleOpenFilter,
                             searchValue,
                             handleChangeInput,
                             handleSearch,
                             handleSubmitSearch,
                             handleOpen,
                             showFilter
                           }: FilterSurveyPlansProps) => {
  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{md: 8, xl: 8, xs: 8}, 8]}>
          <Col md={{span: 5}} lg={{span: 5}} xl={{span: 5}} xs={{span: 7}}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>
                  Bộ lọc
                </Typography.Text>
                {showFilter ? <UpOutlined/> : <DownOutlined/>}
              </Button>
            </div>
          </Col>
          <Col md={{span: 10}} lg={{span: 10}} xl={{span: 10}} xs={{span: 19}}>
            <div className="heading-search">
              <FormInput placeholder="Nhập tiêu đề để tìm kiếm" value={searchValue}
                         onChange={handleChangeInput}
                         onKeyPress={handleSearch}
                         afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch}/>}/>
            </div>
          </Col>
          <Col md={{span: 9}} lg={{span: 9}} xl={{span: 9}} xs={{span: 21}}>
            <div className="heading-plans">
              <Button onClick={handleOpen} className="btn btn-primary">
                <PlusOutlined/>
                <Typography.Text>Kế hoạch khảo sát</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

// @ts-ignore
export default FilterSurveyPlans
