import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Row,
  TreeDataNode,
  Typography,
  Space,
  Input,
  Select,
  message,
  Divider,
  Tooltip,
  Card,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import CustomCard from "../../../../../../components/custom-card/CustomCard";
import {
  TypeChapterList,
  TypeCourseDescriptionDocument,
  TypeCourseStructure,
} from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { useParams } from "react-router-dom";
import {
  downloadFile,
  downloadFileDescriptionDocument,
  upLoadFileDescriptionDocument,
} from "../../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import axios from "axios";

const listDocumentExample: TypeCourseDescriptionDocument[] = [
  {
    courseStructureItemId: 130,
    fileName: `Tài liệu 01`,
    filePath: `abc124`,
  },
  {
    courseStructureItemId: 145,
    fileName: `Tài liệu 02`,
    filePath: `abc121`,
  },
  {
    courseStructureItemId: 148,
    fileName: `Tài liệu 03`,
  },
  {
    fileName: `Tài liệu 04`,
  },
  //   {
  //     courseConstructionPlanId: 40,
  //   },
];

interface DescriptionDocumentProps {
  listChapter: TypeCourseStructure[];
  dataDescriptionDocument: TypeCourseDescriptionDocument[];
  setDataDescriptionDocument: (data: TypeCourseDescriptionDocument[]) => void;
}

const DescriptionDocument: React.FC<DescriptionDocumentProps> = ({
  listChapter,
  dataDescriptionDocument,
  setDataDescriptionDocument,
}) => {
  const dataListChapter = useMemo(() => listChapter, [listChapter]);

  const { id } = useParams();

  const dataListDocument = useMemo(
    () => dataDescriptionDocument,
    [dataDescriptionDocument]
  );

  const handleAddNewDocument = () => {
    const newDocument = {
      courseConstructionPlanId: Number(id),
    };
    const updatedDocumentList = [...dataListDocument, newDocument];
    // setDataListDocument((prevList) => [...prevList, newDocument]);
    setDataDescriptionDocument(updatedDocumentList);
  };

  const onChangeDocumentChapter = (
    selectedChapterName: string,
    indexDocument: number
  ) => {
    //kiểm tra "Không chọn"
    if (selectedChapterName === "Không chọn") {
      const updatedDocumentList = [...dataListDocument];
      updatedDocumentList[indexDocument] = {
        ...updatedDocumentList[indexDocument],
        courseStructureItemId: undefined,
      };
      //   setDataListDocument(updatedDocumentList);
      setDataDescriptionDocument(updatedDocumentList);

      return;
    }

    // Tìm chương được chọn trong dataListChapter
    const selectedChapter = dataListChapter.find(
      (chapter) => chapter.structureItemName === selectedChapterName
    );

    // Nếu không tìm thấy chương được chọn, không làm gì cả
    if (!selectedChapter) return;

    // Sao chép danh sách tài liệu hiện tại
    const updatedDocumentList = [...dataListDocument];

    // Cập nhật trường courseStructureItemId cho tài liệu tương ứng
    updatedDocumentList[indexDocument] = {
      ...updatedDocumentList[indexDocument],
      courseStructureItemId: selectedChapter.id,
    };

    // Cập nhật state với danh sách tài liệu đã được cập nhật
    // setDataListDocument(updatedDocumentList);
    setDataDescriptionDocument(updatedDocumentList);
  };

  const handleChangeDocumentName = (
    event: React.ChangeEvent<HTMLInputElement>,
    indexDocument: number
  ) => {
    // Sao chép danh sách tài liệu hiện tại
    const updatedDocumentList = [...dataListDocument];

    // Cập nhật tên tài liệu cho tài liệu tương ứng
    updatedDocumentList[indexDocument] = {
      ...updatedDocumentList[indexDocument],
      fileName: event.target.value,
    };

    // Cập nhật state với danh sách tài liệu đã được cập nhật
    // setDataListDocument(updatedDocumentList);
    setDataDescriptionDocument(updatedDocumentList);
  };

  const handleAddNewDocumentIndex = (indexDocument: number) => {
    const newDocument = {
      courseConstructionPlanId: Number(id),
    };
    // Sao chép danh sách tài liệu hiện tại
    const updatedDocumentList = [...dataListDocument];
    // Thêm mới văn bản vào sau vị trí indexDocument
    updatedDocumentList.splice(indexDocument + 1, 0, newDocument);
    // Cập nhật state với danh sách tài liệu đã được cập nhật
    // setDataListDocument(updatedDocumentList);
    setDataDescriptionDocument(updatedDocumentList);
  };

  const handleDeleteDocumentIndex = (indexDocument: number) => {
    // Tạo danh sách mới chỉ bao gồm các văn bản không nằm ở vị trí indexDocument
    const updatedDocumentList = dataListDocument.filter(
      (_, index) => index !== indexDocument
    );
    // Cập nhật state với danh sách tài liệu đã được cập nhật
    // setDataListDocument(updatedDocumentList);
    setDataDescriptionDocument(updatedDocumentList);
  };

  const uploadFileDescription = async (indexDocument: number) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".pdf,.docx,.xlsx,.jpg,.jpeg,.png,.ppt,.pptx");
    input.click();

    // Listen upload local image and save to state
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        const allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ];

        if (!allowedTypes.includes(file.type)) {
          message.warning('Hãy chọn tài liệu đúng định dạng')
          return;
        } else {
          message.success('Chọn tài liệu thành công')
        }
        try {
          // Create a FormData object to send the file
          let data = new FormData();

          data.append("file", file);

          // Send the file to the server using fetch or axios
          const response = await upLoadFileDescriptionDocument(data);
          const fileData = response.data.data;
          console.log(`res-uploadFile::`, response.data.data);


          // Check if the request was successful
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            // Update document properties with fileData
            const updatedDocumentList = [...dataListDocument];
            updatedDocumentList[indexDocument] = {
              ...updatedDocumentList[indexDocument],
              filePath: fileData.fileName,
              fileName:
                !updatedDocumentList[indexDocument].fileName ||
                  updatedDocumentList[indexDocument].fileName === ""
                  ? fileData.fileName.slice(5, fileData.fileName.length)
                  : updatedDocumentList[indexDocument].fileName,
              fileType: fileData.fileType.replace(".", ""),
            };
            setDataDescriptionDocument(updatedDocumentList);

            console.log("File uploaded successfully");
          } else {
            // Handle errors if the request was not successful
            console.error("File upload failed");
          }
          // reader.onload = async () => {

          // };
        } catch (error) {
          // Handle any errors that occurred during the file upload process
          console.error("An error occurred during file upload:", error);
        }
      } else {
        console.warn("No file selected.");
      }
    };
  };

  const handleShowDocument = async (indexDocument: number) => {
    const document = dataListDocument[indexDocument];
    const res = await downloadFile({
      filePath: document.filePath,
      destination: "",
    })
    const path = res.data
    if (path && path !== "") {
      // Mở một tab mới trên trình duyệt với đường dẫn của filePath
      window.open(path, "_blank");
    } else {
      // Nếu không có đường dẫn filePath, bạn có thể hiển thị thông báo hoặc xử lý tùy ý
      console.log("Không có đường dẫn tài liệu.");
    }
  };

  const handleDownload = async (indexDocument: number) => {
    const descriptionDocument = dataListDocument[indexDocument];
    // console.log(`descriptionDocument.filePath:::`, descriptionDocument);
    if (descriptionDocument.filePath && descriptionDocument.filePath !== "") {
      try {
        downloadFile({
          filePath: descriptionDocument.filePath,
          destination: "",
        }).then(async (res: any) => {
          const response = await axios.get(res.data, {
            responseType: "arraybuffer", // Thay đổi responseType thành "arraybuffer"
          });

          // Tạo Blob từ ArrayBuffer và định dạng file từ đuôi mở rộng của file
          const blob = new Blob([response.data], {
            type: descriptionDocument.fileType,
          });

          // Tạo URL tạm thời cho blob
          const url = URL.createObjectURL(blob);

          // Tạo một thẻ <a> ẩn để tải file
          const downloadLink = document.createElement("a");
          downloadLink.href = url;
          downloadLink.setAttribute(
            "download",
            `${descriptionDocument.fileName}.${descriptionDocument.fileType}` || ""
          );
          message.success(
            `Đang tải về! Vui lòng kiểm tra file trong thư mục trên máy tính!`
          );

          // Thêm thẻ <a> vào body để kích hoạt sự kiện click
          document.body.appendChild(downloadLink);

          // Kích hoạt sự kiện click để tải file
          downloadLink.click();

          // Loại bỏ thẻ <a> sau khi đã hoàn tất tải file và giải phóng URL tạm thời
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(url);
        }).catch((error) => {
          message.error("Lỗi khi tải file:", error);
        });
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    } else {
      console.log("Không có đường dẫn tài liệu.");
    }

    /*
    if (descriptionDocument.filePath && descriptionDocument.filePath !== "") {
      try {
        const response = await axios.get(descriptionDocument.filePath, {
          responseType: "arraybuffer", // Thay đổi responseType thành "arraybuffer"
        });


        // Tạo Blob từ ArrayBuffer và định dạng file từ đuôi mở rộng của file
        const blob = new Blob([response.data], {
          type: descriptionDocument.fileType,
        });

        // Tạo URL tạm thời cho blob
        const url = URL.createObjectURL(blob);

        // Tạo một thẻ <a> ẩn để tải file
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.setAttribute(
          "download",
          descriptionDocument.fileName || ""
        );
        message.success(
          `Đang tải về! Vui lòng kiểm tra file trong thư mục trên máy tính!`
        );

        // Thêm thẻ <a> vào body để kích hoạt sự kiện click
        document.body.appendChild(downloadLink);

        // Kích hoạt sự kiện click để tải file
        downloadLink.click();

        // Loại bỏ thẻ <a> sau khi đã hoàn tất tải file và giải phóng URL tạm thời
        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    } else {
      console.log("Không có đường dẫn tài liệu.");
    }
    */
  };

  //   console.log(`children-dataListDocument::`, dataListDocument);
  return (
    <Space size="middle" direction="vertical" style={{ marginTop: "-25px" }}>
      <Card style={{ marginBottom: "20px", minWidth: "680px" }}>
        <Row justify="space-between" style={{ marginTop: "20px" }}>
          <Col>
            <Row>
              <Typography.Title level={4}>Tài liệu mô tả</Typography.Title>
            </Row>
            <Row>
              <Typography.Text style={{ color: "GrayText" }}>
                Danh sách tài liệu đính kèm mô tả kế hoạch xây dựng khóa học
              </Typography.Text>
            </Row>
          </Col>
        </Row>
        <Divider style={{ marginTop: "5px" }} />
        {dataListDocument &&
          dataListDocument.map((document, indexDocument) => {
            return (
              <div key={indexDocument}>
                <Row justify="space-between" style={{ marginTop: "20px" }}>
                  <Col>
                    <Space direction="horizontal" size="large">
                      <Input
                        style={{ minWidth: "250px" }}
                        variant={!document.fileName ? undefined : "borderless"}
                        value={document.fileName}
                        placeholder="Nhập tên tài liệu"
                        onChange={(event) =>
                          handleChangeDocumentName(event, indexDocument)
                        }
                      ></Input>

                      <Select
                        style={{ minWidth: "200px", marginRight: "20px" }}
                        showSearch
                        placeholder={`Chọn Chương`}
                        value={
                          dataListChapter.find(
                            (chapter) =>
                              chapter.id === document.courseStructureItemId
                          )?.structureItemName
                        }
                        optionFilterProp="children"
                        onChange={(selectedChapterName) =>
                          onChangeDocumentChapter(
                            selectedChapterName,
                            indexDocument
                          )
                        }
                        // onSearch={onSearch}
                        // filterOption={filterOption}
                        options={[
                          ...dataListChapter.map((item) => ({
                            value: item.structureItemName,
                            label: item.structureItemName,
                          })),
                          { value: "Không chọn", label: "Không chọn" },
                        ]}
                      />
                    </Space>
                  </Col>
                  <Col>
                    <Space direction="horizontal" size="small">
                      {document.filePath && document.filePath !== "" && (
                        <>
                          <Button
                            onClick={() => handleShowDocument(indexDocument)}
                          >
                            Xem
                          </Button>
                          <Button onClick={() => handleDownload(indexDocument)}>
                            Tải về
                          </Button>
                        </>
                      )}

                      {(!document.filePath || document.filePath === "") && (
                        <>
                          <Tooltip
                            placement="topLeft"
                            title={`Tải lên tài liệu mô tả`}
                          >
                            <Button
                              icon={<UploadOutlined />}
                              onClick={() => {
                                uploadFileDescription(indexDocument);
                              }}
                            ></Button>
                          </Tooltip>
                        </>
                      )}
                      <Tooltip placement="topLeft" title={`Xóa tài liệu`}>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() =>
                            handleDeleteDocumentIndex(indexDocument)
                          }
                        ></Button>
                      </Tooltip>

                      <Tooltip placement="topLeft" title={`Thêm tài liệu`}>
                        <Button
                          icon={<PlusOutlined />}
                          onClick={() =>
                            handleAddNewDocumentIndex(indexDocument)
                          }
                        ></Button>
                      </Tooltip>
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ marginTop: "5px" }} />
              </div>
            );
          })}
        <Divider />
        {/* thêm tài liệu */}
        <>
          <Button
            type="primary"
            onClick={handleAddNewDocument}
            className="w-full button-sticky"
          >
            <PlusOutlined />
            Thêm tài liệu mô tả
          </Button>
        </>
      </Card>
    </Space>
  );
};

export default DescriptionDocument;

//uploadFileImage

/*

const uploadFileDescription = (indexDocument:number) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.click();

    // Listen upload local image and save to state
    input.onchange = () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        // file type is only image.
        
        if (/^image\//.test(file.type)) {
          const reader = new FileReader();
          reader.readAsDataURL(file);

          reader.onload = () => {
            const result = reader.result;

            if (typeof result === "string") {
              const base64String = result;
              
            //   props.setUpdateListAnswer(newOptions);
            } else {
              console.warn("Invalid result type.");
            }
          };

          reader.onerror = (error) => {
            console.error("Error reading file:", error);
          };
        } else {
          console.warn("You could only upload images.");
        }
      } else {
        console.warn("No file selected.");
      }
    };
  }


*/
