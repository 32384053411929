import { getScormDetail, getVideoDetail, editScormUnit, editVideoUnit, getXapiDetail, editXapiUnit, editPdfUnit, getPdfDetail, getMp3Detail, editMp3Detail, getTextDetail, editTextDetail, getReferenceDetail, editReferenceDetail } from "../../service/course-construct";

export const VIDEO = "video";
export const SCORM = "scorm";
export const XAPI = "xapi";
export const PDF = "pdf";
export const SOUND = "sound";
export const QUIZ = "quiz";

export const multimediaList = [
  {
    title: "Video",
    imgUrl: "/images/play-square.png",
    describe: "Tải video của bạn lên làm tài liệu học tập",
    type: "video",
  },
  {
    title: "Scorm",
    imgUrl: "/images/scorm-icon.png",
    describe: "Tải tệp Scorm của bạn lên làm tài liệu học tập",
    type: "scorm",
  },
  {
    title: "xAPI",
    imgUrl: "/images/xAPI.png",
    describe: "Tải tệp xAPI của bạn lên làm tài liệu học tập",
    type: "xAPI",
  },
  {
    title: "PDF",
    imgUrl: "/images/PDF.png",
    describe: "Tải lên và trình bày các tệp PDF trong khóa học ",
    type: "PDF",
  },
  {
    title: "Âm thanh",
    imgUrl: "/images/audio2.png",
    describe: "Tải tệp âm thanh lên làm tài liệu học tập",
    type: "audio",
  },
];

export const multimediaIconList = [
  {
    iconUrl: "/images/play-square-outline.svg",
    value: 1,
    type: "video",
    apiDetail: getVideoDetail,
    apiEdit: editVideoUnit
  },
  {
    iconUrl: "/images/audio-outline.svg",
    value: 2,
    type: "audio",
    apiDetail: getMp3Detail,
    apiEdit: editMp3Detail
  },
  {
    iconUrl: "/images/xAPI-outline.svg",
    value: 3,
    type: "xAPI",
    apiDetail: getXapiDetail,
    apiEdit: editXapiUnit
  },
  {
    iconUrl: "/images/exercise-outline.svg",
    value: 4,
    type: "exercise",
    apiDetail: undefined,
    apiEdit: undefined

  },
  {
    iconUrl: "/images/PDF-outline.svg",
    value: 5,
    type: "PDF",
    apiDetail: getPdfDetail,
    apiEdit: editPdfUnit

  },
  {
    iconUrl: "/images/scorm-icon-outline.svg",
    value: 6,
    type: "scorm",
    apiDetail: getScormDetail,
    apiEdit: editScormUnit
  },
  {
    iconUrl: "/images/audio-outline.svg",
    value: 7,
    type: "mp3",
    apiDetail: getMp3Detail,
    apiEdit: editMp3Detail
  },
  {
    iconUrl: "/images/Text.png",
    value: 8,
    type: "text",
    apiDetail: getTextDetail,
    apiEdit: editTextDetail
  },
  {
    iconUrl: "/images/PDF-outline.svg",
    value: 9,
    type: "reference",
    apiDetail: getReferenceDetail,
    apiEdit: editReferenceDetail
  },
]