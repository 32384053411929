export const DEFAULT_DRAGABLE_OPACITY = 1;
export const MAX_FILE_UPLOAD_SIZE = 2097152;

export enum TrendsType {
  BOTH = "both",
  BEHAVIOR = "behavior",
  INFO = "info",
}
export const COURSE_TYPE = [{ value: 3 }, { value: 1 }];
export const CURRICULUM_SCHEDULE_TYPE = [{ value: 1 }, { value: 0 }];
export const DEFAULT_PAGE_NUM = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const FILTER_STAR_ARR = [
  { value: 4.5 },
  { value: 4 },
  { value: 3 },
  { value: 2 },
  { value: -2 },
];
export interface KeyValue {
  label: string;
  value: string | number | undefined;
}
export const FILTER_SORT: KeyValue[] = [
  {
    label: "A - Z",
    value: 1,
  },
  {
    label: "Z - A",
    value: 2,
  },
  {
    label: "Mới xuất bản",
    value: 3,
  },
  {
    label: "Cũ đến mới",
    value: 4,
  },
];
export const FREE_OPTIONS = [{ value: 1 }, { value: 0 }];
export const COURSE_SCHEDULE_TYPE = [{ value: true }, { value: false }];

export const PAID_VALUE_FILTER = 0; // option trả phí
export const BUY_COURSE = 2;

export const FORMAT_REACT_QUILL = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "header",
  "blockquote",
  "code-block",
  "indent",
  "list",
  "direction",
  "align",
  "link",
  "image",
  "video",
  "formula",
];

export const DEFAULT_LOADING_TIME = 500;
export const TOOLBAROPTIONS = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  ["formula"],
];

export const SORT_NOTIFICATIONS: KeyValue[] = [
  {
    label: "Mặc định",
    value: 0,
  },
  {
    label: "Mới nhất",
    value: 1,
  },
  {
    label: "Cũ nhất",
    value: 2,
  },
];