import {
  Button,
  Checkbox,
  Flex,
  Form,
  FormInstance,
  InputNumber,
  message,
  Progress,
  Radio,
  Typography,
  UploadFile,
  UploadProps
} from 'antd';
import React, {MouseEvent, ReactElement, useEffect, useState} from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import './index.scss'
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import {
  createCost,
  getPaymentMethods,
  importExcelFileShare
} from "../../../../../service/content-course/contenCourseApi";
import {CloseOutlined, FileExcelOutlined, LoadingOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import {useParams} from "react-router-dom";
import {useWatch} from "antd/lib/form/Form";
import {getDataTeachers} from "../../../../../service/list-account-teachers/listAccountTeachersApi";
import {getDataMinistry} from "../../../../../service/list-acoount-ministry/listAccountMinistry";
import {getAccountUsers} from "../../../../../service/list-account-users/listAccountUsersApi";
import NotificationSetting from '../../setting-content-course/notification-setting';
import {get, isEmpty, isNull} from "lodash";
import {UploadListType} from 'antd/es/upload/interface';
import {ExcelFileIcon} from "../../../../icons/svg";
import {deleteAllShareCourse, getSampleTemplateExcel} from "../../../../../service/course-construct";
import {saveAs} from "file-saver";
import {useAuthStore} from "../../../../../stores/stores";
import ModalUploadFileExcel from "../../../../modal/modal-upload-file-excel";
import SettingSchedule from "../../setting-content-course/setting-schedule";
import SettingFee from "../../setting-content-course/setting-fee";
import {FileType} from "../../../../../utils/uploadImage";
import {convertErrors} from "../../../../../utils/arrays";
import {getUniversities} from "../../../../../service/university";


type TabSettingPublishProps = {
  dataCostCourse?: any,
  dataConfig?: any,
  formWrapper?: FormInstance,
  isDirty: boolean,
  methodFeeValue: boolean,
  courseStatus: number,
  courseDetail: any,
  isRevoke: boolean,
  setDirty: (value: boolean) => void,
  setIsRevoke: (value: boolean) => void,
}


const TabSettingPublish = ({
                             dataCostCourse,
                             dataConfig,
                             formWrapper,
                             methodFeeValue,
                             courseStatus,
                             courseDetail,
                             isRevoke,
                             setDirty,
                             setIsRevoke
                           }: TabSettingPublishProps) => {
  const [form] = Form.useForm();
  const {id} = useParams()
  const costCourseValue = useWatch('costCourse', form)
  const costCreditValue = useWatch('costCredit', form)
  const costCertificationValue = useWatch('costCertification', form)
  const costValue = useWatch('isCostFree', form)
  const scheduleValue = useWatch('isScheduleByTeacher', form)
  const {accessToken} = useAuthStore()
  const [options, setOptions] = useState<any[]>([])
  const [optionsUsers, setOptionsUsers] = useState<any[]>([])
  const [optionsUniversity, setOptionsUniversity] = useState<any[]>([])
  const [newOptionsUsers, setNewOptionsUsers] = useState<any[]>([])
  const [newOptionsUniversity, setNewOptionsUniversity] = useState<any[]>([])
  const [isOpenedImport, setIsOpenedImport] = useState<boolean>(false)
  const [isHasUsers, setIsHasUsers] = useState<boolean>(true)
  const [isCheckFormPublish, setIsCheckFormPublish] = useState<boolean>(false)
  const [checkUploadFile, setCheckUploadFile] = useState<boolean>(false)
  const [isRevoking, setIsRevoking] = useState<number>(0);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [fileList, setFileList] = useState<FileType[]>([]);
  const [checkboxValue, setCheckboxValue] = useState({
    checkCost: false,
    checkCostCourse: false,
    checkCostCertification: false
  })
  const [checkboxDisabled, setCheckboxDisabled] = useState({
    checkCost: false,
    checkCostCourse: false,
    checkCostCertification: false
  })
  const [dataOptionsMethod, setDataOptionsMethod] = useState({
    paymentsMethod: []
  })
  const [initialValues, setInitialValues] = useState({})

  const fetchDataOptionsShare = async () => {
    const data = {
      page: 1,
      size: 1000
    }
    try {
      const response = await Promise.all([getAccountUsers(data), getDataTeachers(data), getDataMinistry(data), getUniversities(data)])
      const dataUsers = response[0].data.data
      const dataTeachers = response[1].data.data
      const dataMinistry = response[2].data.data
      const dataUniversity = response[3].data
      setOptionsUsers([
        ...dataUsers.data,
        ...dataTeachers.data,
        ...dataMinistry.data
      ])
      setOptionsUniversity(dataUniversity.content)

    } catch (err) {
      console.error(err)
    }

  }
  const fetchOptionsPayment = async () => {
    try {
      const response = await getPaymentMethods()
      // @ts-ignore
      const {data} = response
      setDataOptionsMethod({
        ...dataOptionsMethod,
        paymentsMethod: data.data
      })

    } catch (err) {
      console.error(err)
    }
  }

  const newDataUsersOption = optionsUsers.filter((obj, index, self) => index === self.findIndex((o) => o.email === obj.email)).map((user: any) => {
    return {
      name: user.fullName,
      code: user.email
    }
  })


  const newDataUniversityOption = optionsUniversity.filter((obj, index, self) => index === self.findIndex((o) => o.uuid === obj.uuid)).map((university: any) => {
    return {
      name: university.name,
      code: university.uuid,
    }
  })


  const beforeUploadFile = (file: FileType) => {
    setCheckUploadFile(true);
    const isExcel = file.type === "text/csv" || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'

    if (!isExcel) {
      message.error("Chỉ tải lên được file xlsx/csv");
    }
    setFileList([file])
    return isExcel;
  };


  const handleUpload = async (options: any) => {
    const {onSuccess, onError, file, onProgress} = options;
    const fmData = new FormData();
    const config = {
      headers: {"content-type": "multipart/form-data"},
      onUploadProgress: (event: { loaded: number; total: number; }) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        onProgress({percent: (event.loaded / event.total) * 100});
      }
    };
    fmData.append('file', file);
    fmData.append('isEnterprise', isHasUsers ? 'true' : 'false');
    fmData.append('courseId', Number(id).toString());

    try {
      setErrorMessage(null)
      const response = await importExcelFileShare(fmData, config);
      onSuccess(response);
      if (response && response.status === 200 && !isHasUsers) {
        const newOptions = newDataUsersOption.filter(user => response.data.data.includes(user.code)).map(user => user.code)
        setNewOptionsUsers(newOptions)
      }
      if (response && response.status === 200 && isHasUsers) {
        const newOptions = newDataUniversityOption.filter(university => response.data.data.includes(university.code)).map(university => university.code)
        setNewOptionsUniversity(newOptions)
      }

    } catch (error: any) {
      if (error.response.data.codeStatus === 400) {
        setCheckUploadFile(true)
        message.error(`Tệp ${file.name} tải lên thất bại.`);
        setErrorMessage(error.response.data.message)
      }
      console.error(error);
      onError(error);
    }
  }


  const props: UploadProps = {
    name: 'file',
    maxCount: 1,
    multiple: false,
    customRequest: handleUpload,
    beforeUpload: beforeUploadFile,
    fileList: fileList,
    onChange(info) {
      const {status} = info.file;
      if (status !== 'uploading') {
        setCheckUploadFile(true)
      }

      if (status === 'done') {
        message.success(`Tệp ${info.file.name} tải lên thành công.`);

        setCheckUploadFile(false)
      }
    },
    onDrop(e) {
    },
    iconRender: (file: UploadFile<any>, listType?: UploadListType | undefined) => {
      const isExcel = file.type === "text/csv" || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'
      return (
        (<FileExcelOutlined style={{color: `${isExcel ? '#52C41A' : '#FF4D4F'}`}}/>)
      )
    },
    itemRender: (originNode: ReactElement, file: UploadFile, fileList,
                 actions: { download: any, preview: any, remove: any }
    ) => {
      const isExcel = file.type === "text/csv" || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel'

      const errorMessages = errorMessage && convertErrors(errorMessage)
      return (
        <>
          {
            <>
              <Flex justify={'space-between'} className="item-render">
                <p className="status-progress--upload">
                  <span>
                    <ExcelFileIcon/>
                  </span>
                  <span>
                    {file.name}
                  </span></p>
                <Progress percent={progress}
                          status={checkUploadFile ? 'exception' : 'success'}
                          showInfo={true}
                          size={'default'}
                          style={{width: 200}}/>
              </Flex>
              {
                !isExcel ? (
                  <div className="errorUpload">
                    <span>Lỗi định dạng file!</span>
                  </div>
                ) : isExcel && errorMessage ? (
                  <div className="errorUpload">
                    <Flex vertical={true} gap={"small"}>
                      <span>
                        Lỗi dữ liệu file!
                      </span>
                      {errorMessages && errorMessages.map((mess: string, index: number) => (
                        <span key={index}>
                          {mess}
                        </span>
                      ))}
                    </Flex>
                  </div>
                ) : (
                  <></>
                )
              }
            </>
          }
        </>
      )
    }
  };


  const createPublish = async (value: any) => {
    try {
      const response = await createCost(value)
      if (response.data) message.success('Cài đặt thành công')
    } catch (err) {
      if (!value.isScheduleByTeacher && !value.limit && !value.typeLimit) {
        message.error("Vui lòng thiết lập nỗ lực mong chờ")
      }
      console.error(err)
    }
  }
  const fillData = () => {
    form.setFieldsValue({
      users: dataCostCourse.shareUsers,
      university: dataCostCourse.shareEnterprise,
      courseFormatId: dataCostCourse.formatId,
      displayPlace: dataCostCourse.displayPlace,
      costCourse: dataCostCourse.cost,
      costCredit: dataCostCourse.costCredit,
      costCertification: dataCostCourse.costCertification,
      paymentMethodId: dataCostCourse.paymentMethod !== null ? dataCostCourse.paymentMethod : [1],
      isCostFree: dataConfig.isCostFree,
      typeLimit: !dataCostCourse.isScheduleByTeacher ? dataCostCourse.typeLimit : 1,
      limit: !dataCostCourse.isScheduleByTeacher ? dataCostCourse.limit : null,
      isScheduleByTeacher: dataCostCourse.isScheduleByTeacher,
      receivedOverMail: dataCostCourse.receivedOverMail !== null ? get(
        dataCostCourse,
        "receivedOverMail", true
      ) : true,
      receivedOverSystem: get(
        dataCostCourse,
        "receivedOverSystem"
      ),
      isDefault: dataCostCourse.notificationIsDefault !== null ? get(dataCostCourse, 'notificationIsDefault', true) : true,
      notificationOptional: !dataCostCourse.notificationIsDefault ? get(
        dataCostCourse,
        "notificationOptionals",
        [1]
      ) : []
    })
  }


  const dataOptions = [
    {
      label: 'Trên trang của trường',
      code: 0
    },
    {
      label: 'Trên trang chung của bộ',
      code: 1
    }
  ]

  useEffect(() => {
    fetchOptionsPayment()
    fetchDataOptionsShare()
  }, []);


  useEffect(() => {
    if (Object.values(dataCostCourse).length > 0 && Object.values(dataCostCourse).some((i: any) => !isNull(i) && i === false)) {
      fillData()
      if (dataCostCourse.cost || dataCostCourse.costCredit || dataCostCourse.costCertification) {
        setCheckboxValue({
          checkCost: !!dataCostCourse.cost,
          checkCostCourse: !!dataCostCourse.costCredit,
          checkCostCertification: !!dataCostCourse.costCertification
        });
        setCheckboxDisabled({
          ...checkboxDisabled,
          checkCost: !!dataCostCourse.cost,
          checkCostCourse: !!dataCostCourse.costCredit,
          checkCostCertification: !!dataCostCourse.costCertification

        })

      }
    } else {
      setInitialValues({
        courseFormatId: 0,
        displayPlace: 0,
        isScheduleByTeacher: false,
        isCostFree: true,
        isDefault: true,
        paymentMethodId: [1],
        receivedOverMail: true
      })
    }
  }, [dataCostCourse]);


  useEffect(() => {
    if ((dataCostCourse && dataCostCourse.isCostFree)) {
      setCheckboxValue({
        ...checkboxValue,
        checkCost: false,
        checkCostCourse: false,
        checkCostCertification: false

      })
      setCheckboxDisabled({
        ...checkboxDisabled,
        checkCost: false,
        checkCostCourse: false,
        checkCostCertification: false

      })
    } else {
      setCheckboxValue({
        ...checkboxValue,
        checkCost: true,
        checkCostCourse: true,
        checkCostCertification: true

      })
      setCheckboxDisabled({
        ...checkboxDisabled,
        checkCost: true,
        checkCostCourse: true,
        checkCostCertification: true

      })
    }
    if ((dataCostCourse && dataCostCourse.isScheduleByTeacher)) {
      setIsCheckFormPublish(true)
    } else {
      setIsCheckFormPublish(false)
    }
  }, [dataCostCourse]);


  useEffect(() => {
    if (costValue) {
      setCheckboxValue({
        ...checkboxValue,
        checkCost: false,
        checkCostCourse: false,
        checkCostCertification: false

      })
      setCheckboxDisabled({
        ...checkboxDisabled,
        checkCost: false,
        checkCostCourse: false,
        checkCostCertification: false

      })
    } else {
      setCheckboxValue({
        ...checkboxValue,
        checkCost: true,
        checkCostCourse: true,
        checkCostCertification: true

      })
      setCheckboxDisabled({
        ...checkboxDisabled,
        checkCost: true,
        checkCostCourse: true,
        checkCostCertification: true

      })
    }
    if (scheduleValue) {
      form.setFieldsValue({
        courseFormatId: 2
      })
      setIsCheckFormPublish(true)
    } else {
      setIsCheckFormPublish(false)
    }
  }, [costValue, scheduleValue])


  useEffect(() => {
    if (dataOptionsMethod.paymentsMethod.length > 0) {
      const newData = dataOptionsMethod.paymentsMethod.map((item: any) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      setOptions(newData)
    }
  }, [dataOptionsMethod.paymentsMethod]);


  useEffect(() => {
    if (costCourseValue || costCreditValue || costCertificationValue) {
      form.setFieldsValue({
        totalFee: costCourseValue + costCreditValue + costCertificationValue
      })
    } else {
      form.setFieldsValue({
        totalFee: null
      })
    }

  }, [costCourseValue, costCreditValue, costCertificationValue]);

  const handleInputChange = (value: any, name: string) => {
    const isChecked = value > 0;
    setCheckboxValue({
      ...checkboxValue,
      [name]: isChecked,
    });
  };

  // Get share data user, university
  const dataChange = useWatch('users', form);
  const dataCostCourseUser = dataCostCourse?.shareUsers;
  const dataChangeUniversity = useWatch('university', form);
  const dataCostCourseUniversity = dataCostCourse?.shareEnterprise;

  // List deleted user, university
  const dataUsersFilter = dataCostCourseUser?.filter((course: any) => !dataChange?.includes(course));
  const dataUniversityFilter = dataCostCourseUniversity?.filter((university: any) => !dataChangeUniversity?.includes(university));

  const onFinish = (value: any) => {
    setDirty(false)
    const dataReq = {
      idCourse: Number(id),
      courseFormatId: value.courseFormatId,
      displayPlace: Number(value.displayPlace),
      costCourse: value.costCourse != null ? value.costCourse : 0,
      costCredit: value.costCredit != null ? value.costCredit : 0,
      costCertification: value.costCertification != null ? value.costCertification : 0,
      paymentMethodId: value.paymentMethodId != null ? value.paymentMethodId : 0,
      isScheduleByTeacher: value.isScheduleByTeacher,
      isCostFree: value.isCostFree,
      limit: !value.isScheduleByTeacher ? value.scheduleCourse.limit : null,
      typeLimit: !value.isScheduleByTeacher ? value.scheduleCourse.typeLimit : 1,
      notificationConfigId: get(dataConfig, "notificationConfigId", 0),
      notificationConfig: {
        isDefault: value.isDefault,
        isDeleted: value.isDeleted,
        receivedOverMail: value.receivedOverMail !== null ? value.receivedOverMail : false,
        receivedOverSystem: value.receivedOverSystem !== null ? value.receivedOverSystem : false,
        notificationOptionalId: value.notificationOptional,
      },
      uuids: !isEmpty(value.university) ? value.university : [],
      userEmails: !isEmpty(value.users) ? value.users : [],
      userDeleteEmails: !isEmpty(dataUsersFilter) ? dataUsersFilter : [],
      deleteUuids: !isEmpty(dataUniversityFilter) ? dataUniversityFilter : [],
    }
    createPublish(dataReq)
  }

  const handleValuesChange = () => {
    setDirty(true)
  }

  const resetForm = () => {
    fillData()
  }

  const handleCancel = () => {
    setIsOpenedImport(false)
    setErrorMessage(null)
    setFileList([])
  }

  const handleOK = () => {
    setIsOpenedImport(false)
    if (!isHasUsers && newOptionsUsers.length > 0) {
      form.setFieldsValue({
        users: newOptionsUsers
      })
    }
    if (isHasUsers && newOptionsUniversity.length > 0) {
      form.setFieldsValue({
        university: newOptionsUniversity
      })
    }
    setFileList([])
    setErrorMessage(null)
    message.success("Cập nhật danh sách thành công")

  }

  const downloadSample = async (e: MouseEvent) => {
    e.preventDefault()
    try {
      const response = await getSampleTemplateExcel(isHasUsers, {
        responseType: 'arraybuffer',
        headers: {'Content-Type': 'blob'},
      })
      if (response.data) {
        const outputFilename = `sampleTemplate.xlsx`;
        let blobFile = new Blob([response.data], {type: 'application/json'});

        saveAs(blobFile, outputFilename)
      }
    } catch (err) {
      console.error(err);
    }

  }

  const handleOpenImport = (check: boolean, status: boolean) => {
    setIsOpenedImport(check)
    setIsHasUsers(status)
  }

  // 1: user loading
  // 2: university loading
  const handleRevoke = async (isEnterprise: boolean) => {
    if (!isEnterprise) {
      setIsRevoking(1);
    } else {
      setIsRevoking(2);
    }
    const dataReq = {
      courseId: courseDetail.id,
      isEnterprise: isEnterprise,
    }
    try {
      await deleteAllShareCourse(dataReq).then((res) => {
        if (res.status === 200) {
          setIsRevoke(!isRevoke);
          message.success(`Thu hổi ${isEnterprise ? 'trường' : 'người dùng'} thành công!`);
          setTimeout(() => {
            setIsRevoking(0);
          }, 333);
        }
      })
    } catch (err) {
      setIsRevoking(0);
      message.success("Có lỗi, vui lòng thử lại!");
      console.error(err);
    }
  }


  return (
    <>
      <div className="publish-settings">
        <section className="setting-course">
          <div className="form-settings">
            <Form form={form} onFinish={onFinish}
                  initialValues={initialValues}
                  onValuesChange={handleValuesChange}>
              {
                (courseStatus === 12 || courseStatus === 13)
                  ?
                  <CustomCard title="Chia sẻ" className="wrapper-settings--card">
                    <div className="info-publish-wrapper select-display">
                      <div className="title">
                        Người dùng:
                      </div>
                      <div className="form-item--info_publish">
                        <Form.Item name="users">
                          <FormItemDropdown modeDropdown="multiple" options={newDataUsersOption}
                                            placeholder="Chọn người dùng"/>
                        </Form.Item>
                      </div>
                      <div className="form-item--info_import">
                        <Button onClick={() => handleOpenImport(true, false)}><PlusOutlined/>
                          Import
                        </Button>
                      </div>
                      <div className="form-item--revoke">
                        <Button style={{backgroundColor: '#FF9500', color: '#fff', minWidth: 78}}
                                onClick={() => handleRevoke(false)} disabled={(isRevoking === 1)}>
                          {isRevoking === 1 ? <LoadingOutlined/> : 'Thu hồi'}
                        </Button>
                      </div>
                    </div>
                    <div className="info-publish-wrapper select-display">
                      <div className="title">
                        Trường:
                      </div>
                      <div className="form-item--info_publish">
                        <Form.Item name="university">
                          <FormItemDropdown modeDropdown="multiple" options={newDataUniversityOption}
                                            placeholder="Chọn trường"/>
                        </Form.Item>
                      </div>
                      <div className="form-item--info_import">
                        <Button onClick={() => handleOpenImport(true, true)}><PlusOutlined/>
                          Import
                        </Button>
                      </div>
                      <div className="form-item--revoke">
                        <Button style={{backgroundColor: '#FF9500', color: '#fff', minWidth: 78}}
                                onClick={() => handleRevoke(true)} disabled={(isRevoking === 2)}>
                          {isRevoking === 2 ? <LoadingOutlined/> : 'Thu hồi'}
                        </Button>
                      </div>
                    </div>
                  </CustomCard>
                  :
                  <></>
              }

              <SettingSchedule
                dataConfig={dataCostCourse}
                formInstanceWrapper={form}
              />
              <SettingFee formInstance={form} dataConfig={dataCostCourse}/>
              <CustomCard title="Thông tin xuất bản" className="wrapper-settings--card">
                <div className="info-publish-wrapper">
                  <div className="title">
                    Hình thức xuất bản:
                  </div>
                  <div className="form-item--info_publish">
                    <Form.Item name="courseFormatId">
                      <Radio.Group className="options-notification--type">
                        <div className="wrapper-radio">
                          <Radio value={1} disabled={isCheckFormPublish}>
                            Thẻ ghi danh
                          </Radio>
                          <div className="message">
                            <Typography.Text className="sub-title">
                              Khóa học được xây dựng lộ trình trong quá trình giảng dạy tùy theo chất lượng học viên
                              đăng
                              ký. Người dùng có thể thực hiện tự ghi danh sau khi khóa học mở chức năng ghi danh hoặc
                              được
                              ghi danh bởi QTKH hoặc Giảng viên phụ trách
                            </Typography.Text>
                          </div>
                        </div>
                        <div className="wrapper-radio">
                          <Radio value={2}>
                            Riêng tư
                          </Radio>
                          <div className="message">
                            <Typography.Text className="sub-title">Khóa học xuất bản ở chế độ không công khai, chỉ hiển
                              thị
                              với người dùng được ghi danh bởi QTKH hoặc Giảng viên phụ trách
                            </Typography.Text>
                          </div>
                        </div>
                        <div className="wrapper-radio">
                          <Radio value={3} disabled={isCheckFormPublish}>
                            Tự do
                          </Radio>
                          <div className="message">
                            <Typography.Text className="sub-title">Khóa học xuất bản ở chế độ công khai, mọi người dùng
                              đều
                              có
                              thể thực hiện ghi danh sau khi khóa học mở ghi danh
                            </Typography.Text>
                          </div>
                        </div>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
                <div className="info-publish-wrapper select-display">
                  <div className="title">
                    Nơi hiển thị:
                  </div>
                  <div className="form-item--info_publish ">
                    <Form.Item name="displayPlace">
                      <FormItemDropdown options={dataOptions} placeholder="Chọn nơi hiển thị"/>
                    </Form.Item>
                  </div>
                </div>
              </CustomCard>
              <CustomCard title="Trả phí" className="wrapper-settings--card setting-cost">
                <div className="form-item--fee">
                  <label className="form-item--fee_title">
                    Giá khóa học:
                  </label>
                  <Checkbox checked={checkboxValue.checkCost}
                            disabled={!checkboxDisabled.checkCost}
                            onChange={(e) => {
                              setCheckboxValue({...checkboxValue, checkCost: e.target.checked})
                              if (checkboxValue.checkCost) {
                                form.setFieldValue('costCourse', null)
                              }
                            }}/>
                  <Form.Item name="costCourse">
                    <InputNumber addonAfter="VND" className="form-input"
                                 min={"0"}
                                 controls={false}
                                 onChange={(value) => handleInputChange(value, 'checkCost')}
                                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                 disabled={!checkboxValue.checkCost}
                                 parser={(value?: string) => value ? value.replace(/\$\s?|(,*)/g, '') : ""}/>
                  </Form.Item>
                </div>
                <div className="form-item--fee">
                  <label className="form-item--fee_title">
                    Giá tín chỉ:
                  </label>
                  <Checkbox checked={checkboxValue.checkCostCourse}
                            disabled={!checkboxDisabled.checkCostCourse}
                            onChange={(e) => {
                              setCheckboxValue({...checkboxValue, checkCostCourse: e.target.checked})
                              if (checkboxValue.checkCostCourse) {
                                form.setFieldValue('costCredit', null)
                              }
                            }}/>
                  <Form.Item name="costCredit">
                    <InputNumber addonAfter="VND" className="form-input"
                                 min={"0"}
                                 controls={false}
                                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                 onChange={(value) => handleInputChange(value, 'checkCostCourse')}
                                 disabled={!checkboxValue.checkCostCourse}
                                 parser={(value?: string) => value ? value.replace(/\$\s?|(,*)/g, '') : ""}/>
                  </Form.Item>
                </div>
                <div className="form-item--fee">
                  <label className="form-item--fee_title">
                    Giá chứng chỉ:
                  </label>
                  <Checkbox checked={checkboxValue.checkCostCertification}
                            disabled={!checkboxDisabled.checkCostCertification}
                            onChange={(e) => {
                              setCheckboxValue({...checkboxValue, checkCostCertification: e.target.checked})
                              if (checkboxValue.checkCostCertification) {
                                form.setFieldValue('costCertification', null)
                              }
                            }}
                  />
                  <Form.Item name="costCertification">
                    <InputNumber addonAfter="VND" className="form-input"
                                 min={"0"}
                                 controls={false}
                                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                 onChange={(value) => handleInputChange(value, 'checkCostCertification')}
                                 disabled={!checkboxValue.checkCostCertification}
                                 parser={(value?: string) => value ? value.replace(/\$\s?|(,*)/g, '') : ""}/>
                  </Form.Item>
                </div>
                <div className="form-item--fee total-fee">
                  <label className="form-item--fee_title">
                    Tổng chi phí:
                  </label>
                  <Form.Item name="totalFee">
                    <InputNumber addonAfter="VND" className="form-input"
                                 disabled={true}
                                 min={"0"}
                                 controls={false}
                                 formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                 parser={(value?: string) => value ? value.replace(/\$\s?|(,*)/g, '') : ""}/>
                  </Form.Item>
                </div>
              </CustomCard>
              <CustomCard title="Hình thức thanh toán" className="wrapper-settings--card modal-setting--fee">
                <Form.Item name="paymentMethodId" className="form-item--payment">
                  <Checkbox.Group>
                    {
                      options.map((option) => (
                        <Checkbox key={option.value} value={option.value}>
                          {option.label}
                        </Checkbox>
                      ))
                    }
                  </Checkbox.Group>
                </Form.Item>
              </CustomCard>
              <NotificationSetting form={form}/>
              <div className="save-fee--info">
                <div className="group-button">
                  <Button
                    className="ant-btn-primary"
                    htmlType="submit"
                  >
                    <SaveOutlined/>
                    <Typography.Text>Lưu</Typography.Text>
                  </Button>
                  <Button className="cancel-btn" onClick={resetForm}>
                    <CloseOutlined/>
                    <Typography.Text>Hủy</Typography.Text>
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <ModalUploadFileExcel
            title={`Import danh sách ${!isHasUsers ? 'người dùng' : 'trường'}`}
            open={isOpenedImport}
            isHasUsers={!isHasUsers}
            isHasStudent={false}
            checkError={checkUploadFile}
            onOk={handleOK}
            onCancel={handleCancel}
            okText="Xác nhận"
            cancelText="Huỷ"
            propsDragger={props}
            downloadSample={downloadSample}
          />
        </section>

      </div>
    </>
  );
};

export default TabSettingPublish;
