import {IDataCreateUser} from "../../types/listUsers";
import {axiosConfig} from "../../config/api/configApi";
import {AxiosResponse} from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";

export const getAllStudentAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(
    `/mooc-university-student/account/get-all-by-filter`,
    data
  );
};

export const changeStatusStudentAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(
    `/mooc-university-student/account/change-status`,
    data
  );
};

export const getDetailStudentAccount: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-university-student/account/${id}`);
};

export const changePasswordStudent: (data: any) => Promise<AxiosResponse<any>> = (
  data: any
) => {
  return axiosConfigV2.put(
    `/mooc-university-student/account/change-password`,
    data
  );
};

export const deleteAccountStudent: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-university-student/account/${id}`);
};

export const exportExcelDataStudent: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-university-student/account/export-excel`, data, {
    responseType: "arraybuffer",
  });
};

export const VerifyStudent: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.put(`/mooc-university-student/account/verify-information`, data);
};

export const downloadExcelTemplate: (
) => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-university-student/account/export-template-register`,{
    responseType: "arraybuffer",
  });
};

export const importExcelFile: (data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigToUpload.post(`/mooc-university-student/account/upload-excel`, data);
};

export const getListStudentImportExcelFile: (data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-university-student/account/search-upload-excel`, data);
};

export const saveApprovedAccount: (data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.put(`/mooc-university-student/account/approve-register?isApproved=${data.isApproved}`);
};

export const exportExcelFile: (data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-university-student/account/export-statistical-register`, data, {
    responseType: "arraybuffer",
  });
};

export const exportFailAccountStudentExcelFile: (data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-university-student/account/export-fail-upload-excel`, data, {
    responseType: "arraybuffer",
  });
};

export const verifyStudentAgain: (id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-university-student/account/get-identify-information/${id}`);
};