import React, { useState } from "react";
import {
  Card,
  Space,
  Switch,
  Typography,
  Select,
  Input,
  Button,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { ListQuestion } from "../../../../../../types/survey-managment/survey";
import { optionsDropDownQuestionType } from "../detail-question/constant/data";
import QuestionView from "../detail-question/question-view/QuestionView";
import { DownCircleTwoTone } from "@ant-design/icons";

interface ListQuestionProps {
  index: number;
  sectionQuestion: any;
}

const ShowListQuestion: React.FC<ListQuestionProps> = ({
  index,
  sectionQuestion,
}) => {
  const {
    id,
    questionName,
    questionDescription,
    questionType,
    orderNumber,
    isRequired,
    moocSurveyQuestionAnswer,
  } = sectionQuestion;

  // console.log('xxxxxxxxxxchien')
  // console.log({ sectionQuestion })

  const listAnswer = moocSurveyQuestionAnswer

  const [isRequiredAnswer, setIsRequiredAnswer] = useState<boolean>(false);
  const handleSwitchChange = (checked: boolean) => {
    setIsRequiredAnswer(checked);
  };


  return (
    <Card
      key={id}
      title={
        <Typography.Text>{questionName}</Typography.Text>
      }
      styles={{
        header: { borderColor: "#c2c2c2" },

      }}
      style={{ width: "100%", pointerEvents: "none" }}
      className=""
      extra={
        <>
          <Select
            style={{ pointerEvents: "none" }}
            value={`${optionsDropDownQuestionType.find(
              (opt) => opt.type === questionType
            )?.value
              }`}
            options={optionsDropDownQuestionType}

          />
        </>
      }
    >
      {/* <Space direction="vertical" size="small" style={{ display: "flex" }}>
        <Input disabled value={`Mô tả: ${questionDescription}`}></Input>
      </Space> */}

      {/* Hiển thị nội dung câu hỏi */}
      <QuestionView

        typeQuestionId={Number(questionType)}
        listAnswer={listAnswer}
      />

      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Space size="large">
          <Space size="small">
            <span>Bắt Buộc </span>
            <Switch
              checked={isRequiredAnswer}
              size="small"
              onChange={handleSwitchChange}
            />
          </Space>
        </Space>
      </div> */}
    </Card>
  );
};

export default ShowListQuestion;
