import { AxiosResponse } from "axios";
import { axiosConfig, axiosConfigUpload } from "../../../config/api/configApi";
import {
  IDataCreateSurvey,
  ListSurveySection,
} from "../../../types/survey-managment/survey";
import { DataTypeMoocSurveyObjectsFilter } from "../../../types/survey-managment/mooc-survey-objects-filter";
import { SurveyThemeType } from "../../../types/survey-managment/mooc-survey-theme";

export const postSurveySearch: (data: object) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post(`/mooc-survey/search`, data);
};
export const postHistoryApproval: (
  id: String,
  data: object
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.post(`/mooc-survey/get-history-approval/${id}`, data);
};
export const postSurveySearchSubmit: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-survey-approval/search-survey-submit`, data);
};
export const postSurveySearchApprove: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-survey-approval/search-survey-approve`, data);
};
export const createSurvey: (
  survey: IDataCreateSurvey
) => Promise<AxiosResponse<any>> = (survey) => {
  return axiosConfig.post(`/mooc-survey/create`, survey);
};
export const surveyDelete: (id: string) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfig.put(`/mooc-survey/delete/${id}`);
};

export const getSurveyDetail: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-survey/get-survey/${Number(id)}`);
};

export const getSurveyInfo: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-survey/get-survey-info/${id}`);
};

export const getSurveyDetailAll: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-survey-content/get-survey-by-id/${Number(id)}`);
};

export const putUpdateSurveyDescription: (
  id: number | undefined,
  newDescription: string | undefined
) => Promise<AxiosResponse<any>> = (id, newDescription) => {
  return axiosConfig.put(
    `/mooc-survey-content/update-survey-descript-by-id/${id}`,
    { newDescription: newDescription }
  );
};

export const postUpdateSurveyContentSection: (
  data: ListSurveySection[],
  id: number
) => Promise<AxiosResponse<any>> = (data, id) => {
  return axiosConfig.post(
    `/mooc-survey-content/create-mooc-survey-content-section/${Number(id)}`,
    { listSection: data }
    // { listSection: data }
  );
};
export const getFilterPersonTotal: (data: {
  filters: DataTypeMoocSurveyObjectsFilter[];
}) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-survey/fillter-person`, data);
};
export const createFilterObject: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfig.post(`/mooc-survey/create-fillter-person`, data);
};

export const getSurveyObjectsFilterById: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-survey/get-fillter-person/${id}`);
};

export const saveThemeSurvey: (
  data: SurveyThemeType
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-survey/setting-theme`, data);
};

export const upLoadFileSurvey: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigUpload.post(`/s3-client/upload-survey-file`, data);
};