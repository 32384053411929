import {
  CheckOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  MenuProps,
  Modal,
  Pagination,
  PaginationProps,
  Space,
  Table,
  TableColumnsType,
  Typography,
  message,
} from "antd";
import moment from "moment";
import React, { FunctionComponent, useEffect, useState } from "react";
import { CourseApprovalType } from "../../../../../types/course-plan-approval";
import { useNavigate, useParams } from "react-router-dom";
import { CreareCoursePlanArroval } from "../../../../../types/course-plan-managment/mooc-approval";
import { approvalStatusEum } from "../../constants/data";
import { useAuthStore } from "../../../../../stores/stores";
import { HelpService } from "../../../../../service/helper.service";
import { get } from "lodash";
import { approveCoursePlan } from "../../../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import CourseConstructionPlan from "../../../mooc-course-construction-plan1/CourseConstructionPlan";
import {
  TypeChapterList,
  TypeCourseConstructionPlan,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import ModalCourseConstructApproval from "../modal-course-construct-approval/ModalCourseConstructApproval";
import { getListMoocCourseStructureById } from "../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { routesConfig } from "../../../../../config/routes";

type tableDataProps = {
  locale: object;
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  setPageNumber?: (pageNumber: number) => void;
  setPageSize?: (pageNumber: number) => void;
  listCourse: CourseApprovalType[];
  historyNavigation: Boolean;
  setHistoryNavigation?: (historyNavigation: Boolean) => void;
  handleListCourse: () => void;
  loading: boolean;
  activeTabKey: string;
};
const TableWaitApprovalPlan: FunctionComponent<tableDataProps> = ({
  locale,
  totalRecords,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  listCourse,
  historyNavigation,
  setHistoryNavigation,
  handleListCourse,
  loading,
  activeTabKey,
}) => {
  const { Text } = Typography;
  const params = useParams();
  const userId = useAuthStore((states) => states.user?.id);
  const helpService = new HelpService();
  const [totalPage, setTotalPage] = useState(totalRecords);
  const [isOpenModalDownload, setIsOpenModalDownload] =
    useState<boolean>(false);
  const [courseConstructPlanId, setCourseConstructPlanId] = useState<number>();
  const [isShowCourseConstructPlan, setIsShowCourseConstructPlan] =
    useState<boolean>(false);
  const [listCourseConstruct, setListCourseConstruct] = useState<
    TypeChapterList[]
  >([]);
  const [expandAll, setExpandAll] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [courseConstructDetail, setCourseConstructDetail] =
    useState<TypeCourseConstructionPlan>();
  const navigation = useNavigate();

  const getOptionsAction = (record: any): MenuProps["items"] => {
    let options: MenuProps["items"] = [
      {
        key: "1",
        label: "Phê duyệt kế hoạch",
        icon: <FileDoneOutlined />,
      },
      {
        key: "2",
        label: "Phê duyệt nhanh kế hoạch",
        icon: <ThunderboltOutlined />,
      },
      {
        key: "3",
        label: "Lịch sử phê duyệt",
        icon: <HistoryOutlined />,
      },
      {
        key: "4",
        label: "Tải nội dung kế hoạch",
        icon: <DownloadOutlined />,
      },
    ];
    if (!record.isLeader) {
      options = options.filter(
        (options) => options?.key !== "1" && options?.key !== "2"
      );
    }
    return options;
  };
  const handleApproveSurvay = (record: any) => {
    const dataSend: CreareCoursePlanArroval = {
      courseId: Number(record.courseConstructionPlanId),
      organzationId: Number(record.organzationId),
      courseStatus: approvalStatusEum.APPROVED,
    };
    approveCoursePlan(dataSend).then((res) => {
      const { data, statusCode } = res.data;
      if (statusCode === 200) {
        handleListCourse();
        message.success("Phê duyệt nhanh kế hoạch khóa học thành công");
      }
    });
  };

  const changeToChapterList = (dataCourseStructure: TypeCourseStructure[]) => {
    const level1 = dataCourseStructure.filter(
      (item) => item.structureItemLevel === 1
    );
    const level2 = dataCourseStructure.filter(
      (item) => item.structureItemLevel === 2
    );
    const level3 = dataCourseStructure.filter(
      (item) => item.structureItemLevel === 3
    );

    const chapterList: TypeChapterList[] = level1.map((l1) => {
      let chapter: TypeChapterList = {
        id: l1.id,
        courseConstructionPlanId: l1.courseConstructionPlanId,
        structureItemName: l1.structureItemName,
        structureItemDescription: l1.structureItemDescription,
        structureItemLevel: l1.structureItemLevel,
        // structureItemOrder: l1.structureItemOrder,
        isDeleted: l1.isDeleted,
        subjectList: [],
      };

      // Filter subjects for this chapter
      let chapterSubjects = level2.filter((l2) => l2.parentId === l1.id);

      // Map each subject to include its lessons
      chapter.subjectList = chapterSubjects.map((subject) => {
        let subjectItem: TypeSubjectList = {
          id: subject.id,
          courseConstructionPlanId: subject.courseConstructionPlanId,
          structureItemName: subject.structureItemName,
          structureItemDescription: subject.structureItemDescription,
          structureItemLevel: subject.structureItemLevel,
          coursewareType: subject.coursewareType,
          // structureItemOrder: subject.structureItemOrder,
          parentId: subject.parentId,
          createdDate: subject.createdDate,
          updatedDate: subject.updatedDate,
          isDeleted: subject.isDeleted,
          lessonList: [],
        };

        // Filter lessons for this subject
        let subjectLessons = level3.filter((l3) => l3.parentId === subject.id);

        // Include lessons in the subject
        subjectItem.lessonList = subjectLessons.map((lesson) => ({
          id: lesson.id,
          courseConstructionPlanId: lesson.courseConstructionPlanId,
          structureItemName: lesson.structureItemName,
          structureItemDescription: lesson.structureItemDescription,
          structureItemLevel: lesson.structureItemLevel,
          coursewareType: lesson.coursewareType,
          // structureItemOrder: lesson.structureItemOrder,
          parentId: lesson.parentId,
          createdDate: lesson.createdDate,
          updatedDate: lesson.updatedDate,
          isDeleted: lesson.isDeleted,
        }));

        return subjectItem;
      });

      return chapter;
    });
    return chapterList;
  };

  const getListMoocCourseStructureByIdData = async () => {
    try {
      if (courseConstructPlanId)
        await getListMoocCourseStructureById(courseConstructPlanId.toString())
          .then((res) => {
            const data = res.data.data;
            setListCourseConstruct(changeToChapterList(data));
          })
          .catch((err) => {
            console.log(err);
            message.error("Có lỗi chưa xác định");
          });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    getListMoocCourseStructureByIdData();
  }, [courseConstructPlanId]);

  const generateTreeData = (nodes: any) => {
    return nodes.map((node: any, index: number) => {
      const keyPrefix = node.parentId ? `${node.parentId}-` : "";
      const treeDataNode: any = {
        title: node.structureItemName,
        key: `${keyPrefix}${node.id}`,
        children: [],
      };

      if (node.subjectList && node.subjectList.length > 0) {
        treeDataNode.children = generateTreeData(node.subjectList);
      }

      if (node.lessonList && node.lessonList.length > 0) {
        treeDataNode.children = [
          ...treeDataNode.children,
          ...generateTreeData(node.lessonList),
        ];
      }

      return treeDataNode;
    });
  };

  const treeData = generateTreeData(listCourseConstruct);
  const getAllKeys = (data: any, keys: any = []) => {
    data.forEach((item: any) => {
      keys.push(item?.key);
      if (item.children) {
        getAllKeys(item.children, keys);
      }
    });
    return keys;
  };

  useEffect(() => {
    const allKeys = getAllKeys(treeData);
    setExpandedKeys(allKeys);
  }, []);
  const allKeys = getAllKeys(treeData);

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 1.5,
      render: (_: any, record: any, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Mã kế hoạch",
      width: 4,
      dataIndex: "registrationCode",
    },
    {
      title: "Tên kế hoạch",
      width: 12,
      sorter: (a, b) => a.registrationName.localeCompare(b.registrationName),
      dataIndex: "registrationName",
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record.registrationName}</span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Ngày gửi phê duyệt",
      width: 5,
      dataIndex: "submissionDate",
      render: (_: any, record) => {
        return (
          <>
            {record.submissionDate ? formatDateV2(record.submissionDate) : null}
          </>
        );
      },
    },
    {
      title: "",
      width: 3,
      fixed: "right",
      render: (_: any, record) => {
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigation(
                    `/course-plan/approval/approval-detail/${record.courseConstructionPlanId}`,
                    {
                      state: {
                        historyCheck: false,
                        view: true,
                        keyTab: activeTabKey,
                        modeback: "backState",
                        backLink: `/${routesConfig.coursePlan}/${routesConfig.approval}`,
                      },
                    }
                  );
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: getOptionsAction(record),
                  onClick: ({ key }) => {
                    if (key == "1")
                      navigation(
                        `/course-plan/approval/approval-detail/${record.courseConstructionPlanId}`,
                        { state: { historyCheck: false, view: false } }
                      );
                    if (key === "2") {
                      setIsShowCourseConstructPlan(true);
                      setCourseConstructPlanId(record.courseConstructionPlanId);
                      setCourseConstructDetail(record);
                      // Modal.confirm({
                      //   icon: <FileDoneOutlined />,
                      //   title: `Xác nhận phê duyệt ${record?.registrationName}?`,
                      //   content: (
                      //     <>
                      //       <p>
                      //         Đảm bảo rằng tất cả các
                      //         <Text type="warning">
                      //           {" "}
                      //           thông tin liên quan đã được kiểm tra và cặp nhật
                      //           đầy đủ{" "}
                      //         </Text>
                      //         trước khi Xác nhận kết quả phê duyệt khảo sát
                      //       </p>
                      //     </>
                      //   ),
                      //   onOk: () => handleApproveSurvay(record),
                      //   centered: true,
                      //   okText: "Xác nhận",
                      //   cancelText: "Đóng",
                      //   footer: (_, { OkBtn, CancelBtn }) => (
                      //     <>
                      //       <CancelBtn />
                      //       <OkBtn />
                      //     </>
                      //   ),
                      // });
                    }
                    if (key === "3") {
                      navigation(
                        `/course-plan/approval/approval-detail/${record.courseConstructionPlanId}`,
                        { state: { historyCheck: true, view: true } }
                      );
                    }
                    if (key === "4") {
                      setIsOpenModalDownload(true);
                      setCourseConstructPlanId(record.courseConstructionPlanId);
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];
  const formatDateV2 = (date: string) => {
    return moment(date).format("DD/MM/yyyy");
  };

  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
      setTotalPage(totalRecords);
    }
  };
  return (
    <div className="table-wrapper">
      {/* anh Cường yêu cầu xóa checkbox */}
      <Table
        size="middle"
        tableLayout="fixed"
        pagination={false}
        columns={dataColumns}
        rowKey={"id"}
        dataSource={listCourse}
        scroll={{ x: 1000, y: 600 }}
        locale={locale}
        loading={loading}
      />
      <Pagination
        total={totalRecords}
        showSizeChanger
        pageSize={pageSize}
        current={pageNumber}
        className="pagination-table"
        onChange={onChange}
        itemRender={itemRender}
        locale={{
          items_per_page: "/ trang",
          jump_to: "Đi đến trang",
          page: "",
        }}
        showQuickJumper
        showTotal={(total) => `Tổng số ${total} bản ghi`}
      />
      {isOpenModalDownload && (
        <CourseConstructionPlan
          isOpen={isOpenModalDownload}
          setIsOpen={setIsOpenModalDownload}
          courseConstructPlanId={courseConstructPlanId}
        />
      )}
      {isShowCourseConstructPlan && (
        <ModalCourseConstructApproval
          isOpen={isShowCourseConstructPlan}
          setIsOpen={setIsShowCourseConstructPlan}
          dataCourseStructure={listCourseConstruct}
          expandAll={expandAll}
          setExpandAll={setExpandAll}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
          courseConstructDetail={courseConstructDetail}
          handleListCourse={handleListCourse}
        />
      )}
    </div>
  );
};

export default TableWaitApprovalPlan;
