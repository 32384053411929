// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highchart-custom {
  background-color: white;
  padding: 10px; }
  .highchart-custom .title {
    font-size: 20px;
    font-weight: 600; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-synthesis/components/high-chart/Highcharts.scss"],"names":[],"mappings":"AAAA;EACI,uBAAyB;EAEzB,aAAwB,EAAA;EAH5B;IAMQ,eAAiB;IACjB,gBAAgB,EAAA","sourcesContent":[".highchart-custom {\n    background-color  : white;\n    //margin-bottom   : 50px;\n    padding           : 10px;\n\n    .title {\n        font-size  : 20px;\n        font-weight: 600;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
