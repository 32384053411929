import { StateCreator } from "zustand";

export interface Header {
  reload: boolean;

  onReloadHeader: () => void;
}

export const headerSlice: StateCreator<Header> = (set, get) => ({
  reload: true,

  onReloadHeader: () => {
    set((state) => ({ reload: !state.reload }));
  },
});
