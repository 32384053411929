// @ts-nocheck
import { FunctionComponent, useEffect, useState } from "react";
import { Layout, Row, message } from "antd";
import { DataType } from "../../types/listUsers";
import { getCourseRecommend } from "../../service/page-builder/page-builder";
import { useParams } from "react-router-dom";

const { Content } = Layout;
type ProfileInfoProps = {
  dataUser?: DataType;
};

interface RouteParams {
  id: string;
}

const LinkedCourseComponent: FunctionComponent<ProfileInfoProps> = (
  props,
  context
) => {
  const { id } = useParams<RouteParams>();
  const isRegistered = true;
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(4);
  const [dataCourse, setDataCourse] = useState([]);
  const displayFor = props["display-for"];
  let isHidden = displayFor == "hidden";
  if (displayFor == "all") {
    isHidden = false;
  }
  if (displayFor == "registered_only") {
    isHidden = !isRegistered;
  }
  const handleCallRecommendCourse = async () => {
    const params = {
      page: page,
      size: pageSize,
      //   there are more options
    };
    try {
      await getCourseRecommend(id, params).then((result) => {
        const { data } = result;
        setDataCourse(data.content);
      });
    } catch (error) {}
  };

  useEffect(() => {
    handleCallRecommendCourse();
  }, [page, pageSize]);

  return (
    <>
      <section id="i4xe" className="text-gray-600 body-font">
        <div id="ixht" className="container px-5 py-24 mx-auto">
          <div id="ieau9" className="flex flex-col text-center w-full mb-20">
            <h1
              id="i11l"
              className="text-2xl font-medium title-font mb-4 text-purple-700 sm:text-3xl"
            >
              Khoá học liên kết
            </h1>
            <p id="i0m5" className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Danh sách những khoá học chúng tôi khuyến nghị người học đạt được.
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            {dataCourse?.map((item) => {
              return (
                <div key={item.id} className="lg:w-1/4 md:w-1/2 p-4 w-full">
                  <a className="block relative h-48 rounded overflow-hidden">
                    <img
                      alt="ecommerce"
                      src={
                        item.image
                          ? item.image
                          : "https://dummyimage.com/420x260"
                      }
                      className="object-cover object-center w-full h-full block"
                    />
                  </a>
                  <div className="mt-4">
                    <h3
                      id="ibrl"
                      className="text-gray-500 text-xs tracking-widest title-font mb-1"
                    >
                      {item.courseType ? item.courseType : "DANH MỤC"}
                    </h3>
                    <h2
                      id="ics33"
                      className="text-gray-900 title-font text-lg font-medium"
                    >
                      {item.name}
                    </h2>
                    <p id="id6wj" className="mt-1">
                      {item.description
                        ? item.description
                        : "Mô tả ngắn gọn khoá học dài tối đa 2 dòng, nếu dài hơn thì sẽ cắt bớt chỉ hiển thị phần thành..."}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default LinkedCourseComponent;
