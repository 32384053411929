import { Button, Checkbox, Col, Collapse, Drawer, Form, message, Modal, Progress, Radio, Row, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import TableData from '../../../table-data/TableData'
import "./index.scss"
import { isEmpty, size } from 'lodash'
import { getTableDataDetailSequence } from '../../../../service/content-list-course/contentListCourseApi'
import { formatDate, formatOriginTime } from '../../../../utils/converDate'
import FormItemDropdown from '../../../form-dropdown/FormItemDropdown'
import TextArea from 'antd/es/input/TextArea'
import { DEFAULT_MAX_LENGTH_COURSE_TEXTAREA } from '../../../../constants'
import { ExclamationCircleOutlined, ScanOutlined } from '@ant-design/icons'
// @ts-ignore
import ImgsViewer from "react-images-viewer"
import VerifyCCCD from '../../../modal/verify-cccd'
import { getDetailStudentAccount, VerifyStudent } from '../../../../service/list-account-student/listAccountStudent'

type Props = {
  open: boolean
  onClose: () => void
  fetchData: () => void
  detailData: any
}

export default function ConfirmVerifyDrawer(props: Props) {
  const { open, onClose, detailData, fetchData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [dataDetail, setDataDetail] = useState<any>({})

  const [isOpenVerifyCCCD, setIsOpenVerifyCCCD] = useState<boolean>(false)

  const [currImg, setCurrImg] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [typeRadio, setTypeRadio] = useState<any>({
    infoAccount: false,
    personalInformation: false,
    infoVideoAccount: false,
    result: false
  })

  const saveData = async (values: any) => {
    const data = {
      id: detailData?.id,
      isVerifyAccountInformation: values.infoAccount,
      isVerifyPersonalInformation: values.personalInformation,
      isVerifyVideoInformation: values.infoVideoAccount,
      isConfirm: values.result,
      approvedDescription: values.description
    }
    await VerifyStudent(data).then((res) => {
      if (res.status === 200) {
        onClose()
        message.success("Xác thực tài khoản thành công")
        form.resetFields()
        fetchData()
      }
    })
  }

  const scanCCCD = () => {
    setIsOpenVerifyCCCD(true)
  }

  const getListDataDetail = async () => {
    await getDetailStudentAccount(detailData?.id).then((res) => {
      if (res.status === 200) {
        setDataDetail(res.data?.data)
      }
    })
  }

  useEffect(() => {
    if (open) {
      getListDataDetail();
      form.resetFields()
    }
  }, [open]);

  const handleClose = () => {
    form.resetFields();
    setTypeRadio({
      infoAccount: null,
      personalInformation: null,
      infoVideoAccount: null,
      result: null
    })
    onClose()
  }

  return (
    <Form.Provider onFormFinish={(name, { values, forms }) => {
      if (name === "form-management-student-participate") {
        Modal.confirm({
          title: `Xác nhận duyệt xác thực tài khoản`,
          content: `Đảm bảo rằng tất cả các thông tin liên quan đã được kiểm tra trước khi xác nhận`,
          onOk: () => {
            saveData(values)
            onClose()
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>
      <Drawer
        title={"Duyệt xác thực tài khoản"}
        open={open}
        onClose={handleClose}
        className='management-student-participate-drawer'
        width={"80%"}
        bodyStyle={{ padding: "0px" }}
        extra={
          <Space>
            <Button onClick={handleClose}>Đóng</Button>
            <Button type="primary" onClick={() => form.submit()}>Lưu</Button>
          </Space>
        }
      >
        <Form
          className="management-student-participate"
          layout={"vertical"}
          form={form}
          name="form-management-student-participate"
        >
          <div className="m-5">
            <Row gutter={24}>
              <Col span={12}>
                <div className='border rounded-lg p-3 '>
                  <Typography.Title level={5}>Chi tiết thông tin của sinh viên</Typography.Title>

                  <Collapse defaultActiveKey={["1", "2", "3"]}>
                    <Panel
                      header="Thông tin tài khoản"
                      key="1"
                      className="setting-chapter-title">
                      <div className='m-5'>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Email đăng nhập</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Họ và tên</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.name}</p></span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Ngày tạo</Typography.Title>
                          <p className='mt-0.5 ml-2'>{dataDetail?.dateJoined ? formatOriginTime(dataDetail?.dateJoined) : null}</p>
                        </span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Trường đại học đăng ký</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.unitName}</p></span>
                      </div>
                    </Panel>

                    <Panel
                      header="Thông tin xác thực eKYC"
                      key="2"
                      className="setting-chapter-title">
                      <div className='m-5'>
                        <Button onClick={scanCCCD} className="btn btn-primary mt-1">
                          <ScanOutlined />
                          <Typography.Text>Quét lại thông tin</Typography.Text>
                        </Button>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Ngày xác thực</Typography.Title>
                          <p className='mt-0.5 ml-2'>{dataDetail?.verifiedDate ? formatOriginTime(dataDetail?.verifiedDate) : null}</p>
                        </span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Thông tin giấy tờ cá nhân</Typography.Title>
                          <div className='mt-0.5 ml-2 flex'>
                            {
                              dataDetail?.imagePersonalUrls?.[0] ?
                                <div>
                                  <img className='w-[150px] h-[100px] rounded-md cursor-pointer object-cover' onClick={e => {
                                    setIsOpen(true)
                                    setCurrImg(0)
                                  }} src={dataDetail?.imagePersonalUrls?.[0]} alt="mat-truoc" />
                                  <p className='mt-0.5'>Mặt trước</p>
                                </div>
                                : null}
                            {
                              dataDetail?.imagePersonalUrls?.[1] ?
                                <div>
                                  <img onClick={e => {
                                    setIsOpen(true)
                                    setCurrImg(1)
                                  }} className='w-[150px] h-[100px] rounded-md ml-4 cursor-pointer object-cover'
                                    src={dataDetail?.imagePersonalUrls?.[1]} alt="mat-sau" />
                                  <p className='mt-0.5 ml-4'>Mặt sau</p>
                                </div> : null}
                            <ImgsViewer
                              imgs={[
                                {
                                  src:
                                    dataDetail?.imagePersonalUrls?.[0]
                                },
                                {
                                  src:
                                    dataDetail?.imagePersonalUrls?.[1]
                                }
                              ]}
                              currImg={currImg}
                              showThumbnails={true}
                              isOpen={isOpen}
                              onClickPrev={() => setCurrImg(currImg - 1)}
                              onClickNext={() => setCurrImg(currImg + 1)}
                              onClickThumbnail={(index: number) => setCurrImg(index)}
                              onClose={() => setIsOpen(false)}
                            />
                          </div>
                        </span>
                        <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Khuôn mặt</Typography.Title>
                          <div className='mt-0.5 ml-2 flex'>
                            <iframe
                              className='rounded-md w-42 h-30'
                              allow="fullscreen"
                              scrolling="yes"
                              width={"318px"}
                              src={dataDetail?.videoPersonalUrl}
                            />
                          </div>
                        </span>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
              <Col span={12}>
                <div className='p-3 '>
                  <Typography.Title level={5}>Kết quả xác thực</Typography.Title>
                  <hr className='mt-2' />

                  <Form.Item name="infoAccount" valuePropName="checked" label="Thông tin tài khoản" className='mt-2'>
                    <Radio.Group onChange={(e) => setTypeRadio({ ...typeRadio, infoAccount: e.target.value })} value={typeRadio.infoAccount}>
                      <Radio value={1}>Hợp lệ</Radio>
                      <Radio value={2}>Không Hợp lệ</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <hr className='mb-2' />

                  <Form.Item name="personalInformation" valuePropName="checked" label="Thông tin giấy tờ cá nhân" className='mt-2'>
                    <Radio.Group onChange={(e) => setTypeRadio({ ...typeRadio, personalInformation: e.target.value })} value={typeRadio.personalInformation}>
                      <Radio value={1}>Hợp lệ</Radio>
                      <Radio value={2}>Không Hợp lệ</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <hr className='mb-2' />

                  <Form.Item name="infoVideoAccount" valuePropName="checked" label="Thông tin video khuôn mặt" className='mt-2'>
                    <Radio.Group onChange={(e) => setTypeRadio({ ...typeRadio, infoVideoAccount: e.target.value })} value={typeRadio.infoVideoAccount}>
                      <Radio value={1}>Hợp lệ</Radio>
                      <Radio value={2}>Không Hợp lệ</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <hr className='mb-2' />

                  <Form.Item name="result" valuePropName="checked" label="Kết quả xác thực" className='mt-2'>
                    <Radio.Group onChange={(e) => setTypeRadio({ ...typeRadio, result: e.target.value })} value={typeRadio.result}>
                      <Radio value={1}>Hợp lệ</Radio>
                      <Radio value={2}>Không Hợp lệ</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item name="description" className='mt-2'>
                    <TextArea
                      rows={4}
                      placeholder="Nhập nội dung mô tả"
                      showCount
                      maxLength={100}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </Drawer>
      <VerifyCCCD open={isOpenVerifyCCCD} handleClose={() => setIsOpenVerifyCCCD(false)} detailData={detailData} />
    </Form.Provider>
  )
}
