import React, { ReactNode, useState } from "react";
import { App, Input, Modal } from "antd";

interface ReportModalProps {
  children?: ReactNode;
}

function ReportModal({ children }: ReportModalProps) {
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };
  return (
    <App>
      <div onClick={showModal}>{children}</div>
      <Modal
        title={
          <span className="font-size-24 line-height-32 font-weight-6">
            Báo cáo
          </span>
        }
        open={open}
        maskClosable={false}
        centered
        className="report-modal"
        onOk={hideModal}
        onCancel={hideModal}
        okText={"Gửi báo cáo"}
        cancelText="Hủy"
      >
        <p className="font-size-16 mb-3">
          Bạn muốn gửi feedback về bài tập/bài thi/bài kiểm tra? Hay có thắc mắc
          về kết quả bài làm?{" "}
        </p>
        <Input.TextArea placeholder="Nhập mô tả tại đây" className="mb-2" />
      </Modal>
    </App>
  );
}

export default ReportModal;
