import React, { MouseEvent, useEffect, useState } from 'react';
import { Button, DatePicker, DatePickerProps, Form, message, Modal, Typography } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { DraggerProps } from "antd/lib/upload";
import TextArea from 'antd/es/input/TextArea';
import { saveHistoryManagementData } from '../../../service/management-data';
import moment from 'moment';
import { viLocale } from '../../../utils/pickerType';

type ModalUploadProps = {
  propsDragger?: DraggerProps,
  downloadSample?: (e: MouseEvent) => void,
  getListData: () => void,
  title?: string,
  okText?: string,
  cancelText?: string,
  modeSave: string,
  open?: boolean,
  onOk?: () => void,
  onCancel: () => void
  dataUpdate: any,
  mode: string,
}

const HistoryManagementDataModal = ({
  propsDragger,
  downloadSample,
  title,
  open,
  onOk,
  onCancel,
  cancelText,
  dataUpdate,
  mode,
  okText,
  getListData,
  modeSave
}: ModalUploadProps) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (dataUpdate?.id && modeSave !== '1') {
      form.setFieldsValue({ content: dataUpdate?.result })
    } else {
      // form.setFieldValue('dateShare', moment(dataUpdate?.connectedDate) ?? null)
    }
  }, [dataUpdate]);

  const onSave = async (values: any) => {
    const data = {
      connectedDate: form.getFieldValue("dateShare") ?? null,
      result: form.getFieldValue("content") ?? null
    }
    await saveHistoryManagementData(dataUpdate?.id, data).then((res) => {
      if (res.status === 200) {
        message.success("Cập nhật thông tin thành công")
        onCancel()
        getListData()
        form.resetFields()
      }
    })
  }

  return (
    <>
      <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === "form-history-management-data") {
          onSave(values)
        }
      }}>

        <Modal
          centered
          title={modeSave ? 'Sửa Lịch sử' : (mode === 'view' ? 'Xem chi tiết lịch sử' : 'Cập nhật thông tin')}
          open={open}
          onOk={onSave}
          onCancel={onCancel}
          cancelText={cancelText}
          okText={okText}
          footer={
            mode !== 'view' ?
              [
                <Button onClick={onCancel} >
                  Đóng
                </Button>,
                <Button type="primary" onClick={() => form.submit()}
                >
                  Lưu
                </Button>,
              ] : [<Button onClick={onCancel} >
                Đóng
              </Button>,]}
        >
          <Form layout={"vertical"} form={form} name="form-history-management-data">
            <div className="history-management-data-modal">
              {
                modeSave === '1' ?
                  <Form.Item className='mt-2 w-full' name="dateShare" label="Ngày kết nối" rules={[
                    {
                      required: true,
                      message: "Vui lòng chọn ngày kết nối"
                    }
                  ]}>
                    <DatePicker
                      className='w-full'
                      format="DD/MM/YYYY"
                      allowClear={false}
                    />
                  </Form.Item>
                  :
                  <Form.Item name="content">
                    <TextArea disabled={mode === 'view'} autoSize rows={10}  ></TextArea>
                  </Form.Item>
              }
            </div>
          </Form>
        </Modal>
      </Form.Provider>
    </>
  );
};

export default HistoryManagementDataModal;
