import { commonMedia } from "./grapesjs-config";
export const nameCategoryBlocks = "Khối giao diện";
export const nameCategoryTemplates = "Mẫu hoàn chỉnh";
export const nameContactBlock = "Khối liên hệ";
export const nameLinkedCourseBlock = "Khối khóa học mẫu";
export const nameLecturersBlock = "Khối giảng viên";
export const nameBasicBlock = "Giao diện cơ bản";
export const nameCountBlocks = "Khối thống kê mẫu";
export const nameRoadMapBlocks = "Khối lộ trình khóa học";
export const nameHeaderBlocks = "Giới thiệu chung";
export const nameRegisterBlocks = "Khối ghi danh";
export const nameConditionBlocks = "Khối điều kiện";
export const nameAffiliatedBlocks = "Khối liên kết";

export const generalHeaderIntroduction = (data?: any) => {
  const blockUI = {
    id: "header-block-id",
    label: "Phần đầu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body id="i6ll">
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="i1z3t-2" class="text-gray-600 body-font ">
    <div id="ibtwy-2" class="container mx-auto flex flex-wrap justify-between p-5 flex-col md:flex-row items-center">
      <img alt="ecommerce" src="https://dummyimage.com/48x48" class="object-cover object-center w-10 h-full block"/>
      <p id="i37d7-2" class="px-4 py-2 rounded mx-2 ">Nội dung tiêu đề</p>
    </div>
  </header>
</body>
  `;

  return blockUI;
};

export const generalSearchCourseList = (data?: any) => {
  const blockUI = {
    id: "search-block-id",
    label: "Phần thanh tìm kiếm",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body id="i23n">
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <header id="i1z3t-2" class="text-gray-600 body-font ">
  </header>
  <div id="iuph" class="container p-5 mx-auto grid lg:grid-cols-2 lg:max-w-7xl gap-40 text-gray-600 py-24 justify-center items-center">
    <div id="i9fg" class="text-left max-w-2xl">
      <div class="flex justify-center w-full flex-col gap-3">
        <h1 class="text-4xl font-bold">Tiêu đề của bài đăng</h1>
        <p class="mt-2 text-gray-400">Tổng hợp các chủ đề của bài đăng</p>
        <div class="flex gap-4 mt-3" component-class="SearchInputBlock">
        <input type="text" placeholder="Nhập từ khoá cần tìm" id="i8ujj" class="w-full px-4 py-2 text-gray-700 focus:outline-none mr-2 rounded"/>
        <button id="iytk3" class="text-white px-4 py-2 hover:bg-gray-700 rounded bg-blue-400 whitespace-pre">Tìm kiếm</button>
        </div>
      </div>
      <p class="mt-3 text-gray-400">Từ khoá tìm kiếm gợi ý: tiêu đề bài đăng, tiêu đề bài đăng,  . . .</p>
    </div>
    <div>
      <img alt="ecommerce" src="https://dummyimage.com/320x220" class="object-cover object-center w-full h-full block"/>
    </div>
  </div>
</body>
  `;

  return blockUI;
};

export const courseRecommendIntroduction = (data?: any) => {
  const blockUI = {
    id: "recommend-course-block-id",
    label: "Phần khóa học tiêu biểu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Pagination Example
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <section id="i4xe-2" class="text-gray-600 body-font">
    <div id="ixht-2" class="container px-5 mx-auto py-8">
      <div id="ieau9-2" class="flex flex-col w-full mb-8">
        <h1 id="i11l-2" class="text-2xl font-medium title-font mb-4 sm:text-3xl">Khoá học để bạn bắt đầu
        </h1>
      </div>
      <div id="iccjpk" class="flex flex-wrap -m-4">
        <div id="iuf75s" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ibrl-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ics33-2" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
              <br/>
              <br/>
            </h2>
            <div id="iahwd" class="border-gray-200 border-opacity-60 flex">
              <img id="ihxeuh" src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" class="mr-2"/>
              <p id="iut2a5" class="leading-relaxed text-base">
                <span id="ibkvye" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="id6wj-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="iq9zvy" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/420x260" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ih28xq" class="text-gray-500 text-xs tracking-widest title-font mb-1">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i1e1uh" class="text-gray-900 title-font text-lg font-medium mb-2">Làm quen với phần mềm chỉnh sửa ảnh Lightroom
            </h2>
            <div id="inr971" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="iokrd1" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ipqjm2" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="itkivg" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="i15vh" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="i098d-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-6-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ij199-2" class="text-gray-900 title-font text-lg font-medium mb-2">Thiết kế Website bán hàng chuyên nghiệp
              <br/>
              <br/>
            </h2>
            <div id="if7y35" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i4txwx" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iqhw6l" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i3a6y-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="i5qu3n" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/421x261" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="i9va6q" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i6lj8v" class="text-gray-900 title-font text-lg font-medium mb-2">Chỉnh sửa, cắt ghép ảnh với Adobe Photoshop
            </h2>
            <div id="ivfmrv" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="ilvuxk" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iulh7j" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i68u45" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="iheeib" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="ic7gs-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-4-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ib1uf-2" class="text-gray-900 title-font text-lg font-medium mb-2">Làm quen với phần mềm chỉnh sửa ảnh Lightroom
            </h2>
            <div id="iidcz7" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="ixu3b8" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="i1ew2t" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="iv1io-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ix582s" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/422x262" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="ihl1k2" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="ievwtw" class="text-gray-900 title-font text-lg font-medium mb-2">Kỹ năng Canva cơ bản
              <br/>
              <br/>
            </h2>
            <div id="i8gzy9" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="i61mtl" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="iher7n" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="iwwxlh" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ipw3c3" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 id="iy6wp-2" class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 id="ibrl-5-2" draggable="false" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="i7ebm-2" class="text-gray-900 title-font text-lg font-medium mb-2">Nhập môn vẽ kỹ thuật số - Digital Painting
              <br/>
              <br/>
            </h2>
            <div id="iplgk8" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="igf0sj" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="ikt5i5" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="ivug2-2" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <div id="ixb3fi" class="lg:w-1/4 md:w-1/2 p-4 w-full">
          <a class="block relative h-48 rounded overflow-hidden"><img alt="ecommerce" src="https://dummyimage.com/423x263" class="object-cover object-center w-full h-full block"/></a>
          <div class="mt-4">
            <h3 class="text-gray-500 text-xs tracking-widest title-font mb-1">
            </h3>
            <h3 draggable="false" id="ifdssg" class="text-gray-500 text-xs tracking-widest title-font mb-1 gjs-selected">Trường Đại học Hà Nội (HANU) • Tổng 12 giờ 
            </h3>
            <h2 id="io7pp9" class="text-gray-900 title-font text-lg font-medium mb-2">Cấu trúc dữ liệu và giải thuật 
              <br/>
              <br/>
            </h2>
            <div id="inoi9m" class="border-gray-200 border-opacity-60 flex">
              <img src="https://s3.moooc.xyz/dev-stable/page-builder/50bb845a-0aaf-485a-a9a0-ca94472a25dewallet-02.svg" id="it7pp8" class="mr-2"/>
              <p class="leading-relaxed text-base">
                <span id="il1fmf" class="text-xl text-red-700">Miễn phí</span>
              </p>
            </div>
            <p id="i52syh" class="mt-1">Giảng viên: Name01, Name02
            </p>
          </div>
        </div>
        <section id="iayb" class="text-gray-600 body-font">
        </section>
      </div>
    </div>
  </section>
</body>
  `;
  return blockUI;
};

export const generalFooterIntroduction = (data?: any) => {
  const blockUI = {
    id: "footer-block-id",
    label: "Phần cuối",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Hero Section with Background
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <footer id="i5d9ox" class="text-gray-600 body-font bg-gray-100">
    <div id="iy8na6" class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T08%3A14%3A49.301501154Z/f065a648-df6c-4f8e-8741-df5559902bc4images.png" id="i4mk8z" class="w-8"/>
      <div id="ijhjhp" class="w-90 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
        <span id="inz6nz" class="text-xl ml-8">Đại học Bách Khoa Hà Nội</span>
        <p id="ihqko5" class="text-sm text-gray-500 ml-8 mt-8">Bản quyền thuộc về Đại học Bách khoa Hà Nội
          <br/>Địa chỉ: Số 1 Đại Cồ Việt, Hai Bà Trưng, Hà Nội
          <br/>Điện thoại: 024 3869 4242
        </p>
      </div>
      <div id="iozwhq" class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
        <div id="im9se" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
          </nav>
        </div>
        <div id="iw0yh" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="i18zz" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Giới thiệu</a>
            </li>
            <li id="iu5b6" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Khám phá</a>
            </li>
            <li id="ivzynq" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Blog</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="ik4k7l" class="mb-4">
              <a id="iuw93t" class="text-gray-600 hover:text-gray-800">Chính sách</a>
            </li>
            <li id="iw2yl9" class="mb-4">
              <a id="ihhfkr" class="text-gray-600 hover:text-gray-800">Quyền riêng tư</a>
            </li>
            <li id="ipzazs" class="mb-4">
              <a id="i2jcjh" class="text-gray-600 hover:text-gray-800">Liên hệ</a>
            </li>
            <li id="iuvvzm">
            </li>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</body>`;

  return blockUI;
};
