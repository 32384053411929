import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Row,
  Space,
  Tree,
  TreeDataNode,
  Typography,
} from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import {
  TypeChapterList,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import {
  BookOutlined,
  CaretRightOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { get } from "lodash";
import { useAuthStore } from "../../../../../stores/stores";
import {
  getCoursePlanAssignById,
  postCoursePlaningAssignment,
} from "../../../../../service/course-plan-managment/mooc-course-planning-assignment";
import { HelpService } from "../../../../../service/helper.service";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
type Props = {
  dataCourseStructure: TypeChapterList[];
  dataOptions?: any;
  nameAssigneeId?: string;
};
interface Chapter {
  chapterId: number;
  assigneeIds: number[];
}

interface dataSend {
  courseConstructionPlanId: number | null;
  //ng dc phân
  assigneeId: number | null;
  //ng phân
  assignerId: number | null | undefined;
  chapters: Chapter[];
}

const CourseConstructionPlanningAssignment: FunctionComponent<Props> = ({
  dataCourseStructure,
  dataOptions,
  nameAssigneeId,
}) => {
  const location = useLocation();
  const { mode } = location.state || {};
  const userId = useAuthStore((states) => states.user?.id);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const helpService = new HelpService();
  const [expandAll, setExpandAll] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const findIndexByValue = (targetValue: number) => {
    const index = dataOptions.dataTeachers.findIndex(
      (item: { value: number }) => item.value === targetValue
    );
    if (index !== -1) {
      return index.toString();
    }
  };
  const findIndexHostByValues = (targetValue: number) => {
    const index = dataOptions.dataTeachers.find(
      (item: { value: number }) => item.value === targetValue
    );
    return index.label.toString();
  };
  const findIndexByValues = (targetValue: number[]) => {
    const indexes: string[] = [];
    targetValue.forEach((value) => {
      const index = dataOptions.dataTeachers.find(
        (item: { value: number }) => item.value === value
      );
      if (index !== -1) {
        indexes.push(index.label.toString());
      }
    });
    return indexes;
  };

  const getAllNodeKeys = (nodes: TreeDataNode[]): string[] => {
    let keys: string[] = [];
    nodes.forEach((node) => {
      keys.push(node.key as string);
      if (node.children) {
        keys = keys.concat(getAllNodeKeys(node.children));
      }
    });
    return keys;
  };
  const convertChapterToTreeData = (
    chapter: TypeChapterList,
    index: number
  ): TreeDataNode => {
    const chapterNode: TreeDataNode = {
      title: (
        <Space size="small" direction="vertical" style={{ width: "95%" }}>
          <Row gutter={[{ xs: 12, xl: 8, md: 8 }, 8]}>
            <Col xl={{ span: 10 }}>
              <span style={{ fontStyle: "24px" }}>
                {chapter.structureItemName}:{" "}
              </span>
            </Col>
            <Col xl={{ span: 14 }}>
              <Form.Item name={chapter.id}>
                <FormItemDropdown
                  placeholder="Chọn giảng viên"
                  options={get(dataOptions, "dataTeachers", "")}
                  allowClear={true}
                  modeDropdown="multiple"
                  value={chapter.assigneeId}
                />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      ),
      key: `${chapter.id}`,
      children: [],
      style:
        index > 0
          ? { borderBottom: "1px solid #F0F0F0", marginTop: "35px" } //#fff6cc
          : { borderBottom: "1px solid #F0F0F0", marginTop: "5px" }, //f4f4f4
    };

    // Duyệt qua từng môn học của chapter và chuyển đổi thành TreeDataNode
    chapter.subjectList.forEach((subject: TypeSubjectList) => {
      const subjectNode: TreeDataNode = {
        title: (
          <Space direction="vertical">
            <Row>
              <BookOutlined />{" "}
              <span style={{ marginLeft: "5px" }}>
                {subject.structureItemName}
              </span>
            </Row>
          </Space>
        ),
        key: `${subject.id}`,
        switcherIcon: <CaretRightOutlined />,
        style: {
          backgroundColor: "#e6f4ff",
          marginTop: "0",
          paddingTop: "5px",
        },
        children: [],
      };

      // Duyệt qua từng bài học của môn học và chuyển đổi thành TreeDataNode
      subject.lessonList.forEach((lesson: TypeCourseStructure) => {
        const lessonNode: TreeDataNode = {
          title: (
            <Space direction="vertical">
              <Row>
                <BookOutlined />{" "}
                <span style={{ marginLeft: "5px" }}>
                  {lesson.structureItemName}
                </span>
              </Row>
            </Space>
          ),
          switcherIcon: <MinusOutlined />,
          key: `${lesson.id}`,
          isLeaf: true,
          style: { backgroundColor: "#e6f4ff", paddingTop: "5px" }, //#fafafa
        };
        // Thêm node bài học vào node môn học
        if (subjectNode.children) {
          subjectNode.children.push(lessonNode);
        }
      });

      // Thêm node môn học vào node chapter
      if (chapterNode.children) {
        chapterNode.children.push(subjectNode);
      }
    });

    return chapterNode;
  };

  const treeData: TreeDataNode[] = dataCourseStructure.map((chapter, index) =>
    convertChapterToTreeData(chapter, index)
  );

  useEffect(() => {
    if (expandAll) {
      // Tạo mảng chứa tất cả các khóa của nút trong cây
      const allNodeKeys = getAllNodeKeys(treeData);
      setExpandedKeys(allNodeKeys);
    } else {
      setExpandedKeys([]);
    }
  }, [expandAll]);

  useEffect(() => {
    if (params.id && dataOptions.dataTeachers.length > 0) {
      getCoursePlanAssignById(params.id).then((res) => {
        const r = res.data.data;
        if (r.courseConstructionPlanId != 0 && mode === "reassign") {
          form.setFieldValue("host", r.assigneeId.toString());
          r.chapters.forEach(
            (chapter: { chapterId: number; assigneeId: number[] }) => {
              const { chapterId, assigneeId } = chapter;
              const assigneeIds = findIndexByValues(assigneeId);
              const assigneeIdStrings = assigneeId.map((x) => x.toString());
              assigneeId.map((x) => {
                return x.toString();
              });
              form.setFieldValue(chapterId, assigneeIdStrings);
            }
          );
        } else {
          form.setFieldValue("host", r.assigneeId.toString());
        }
      });
    }
  }, [params.id, dataOptions]);

  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        if (name === "assignmentForm") {
          const entries = Object.entries(values);
          let data: dataSend = {
            courseConstructionPlanId: null,
            assigneeId: Number(values.host),
            assignerId: userId,
            chapters: [],
          };
          entries.forEach(([key, value]) => {
            if (key === "host") {
              data.courseConstructionPlanId = Number(params.id);
              //data.assigneeId = dataOptions.dataTeachers[value]?.value;
            } else {
              if (value && value.length > 0) {
                const teachers = value.map((i: number) => {
                  return Number(i);
                });
                const chapter = {
                  chapterId: parseInt(key),
                  assigneeIds: teachers,
                };
                data.chapters.push(chapter);
              }
            }
          });
          postCoursePlaningAssignment(data).then((res) => {
            const { message, statusCode } = res.data.data;
            if (statusCode == 200) {
              navigate(`/course-plan/planning-assignment`);
              helpService.successMessage(message);
            }
          });
        }
      }}
    >
      <Card
        className={`wrapper-card card-info--wrapper preview-course-structure`}
        style={{ paddingTop: "20px", minWidth: "680px", width: "1100px" }}
        title={
          <div className="card-heading--info--construct-view">
            <Row gutter={[{ xs: 12, xl: 8, md: 8 }, 8]}>
              <Col
                xl={{ span: 12 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 12 }}
              >
                <div className="card-heading--info">
                  <Typography.Title level={4}>
                    Cấu trúc khóa học
                  </Typography.Title>
                  <Typography.Text className="sub-title">
                    Chi tiết cấu trúc của khoá học đăng kí
                  </Typography.Text>
                </div>
              </Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 4 }}
                sm={{ span: 4 }}
                xs={{ span: 4 }}
              ></Col>
              <Col
                xl={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 8 }}
                xs={{ span: 8 }}
              >
                <Button
                  onClick={() => setExpandAll(!expandAll)}
                  className="float-right"
                >
                  {expandAll ? "Thu gọn" : "Hiển thị chi tiết"}
                </Button>
              </Col>
            </Row>
          </div>
        }
      >
        <Form form={form} name="assignmentForm">
          <Divider />
          <Row gutter={[{ xs: 12, xl: 8, md: 8 }, 8]}>
            <Col xl={{ span: 10 }}>
              <span style={{ fontStyle: "24px", marginLeft: "28px" }}>
                Giảng viên chủ trì:
              </span>
            </Col>
            <Col
              xl={{ span: 14 }}
              style={{ paddingLeft: "15px", paddingRight: "25px" }}
            >
              <Form.Item name="host">
                <FormItemDropdown
                  placeholder="Chọn giảng viên"
                  options={get(dataOptions, "dataTeachers", "")}
                  // allowClear={true}
                  //modeDropdown="multiple"
                />
              </Form.Item>
              {/* <Form.Item name="host">
                <FormItemDropdown
                  placeholder="Chọn giảng viên"
                  // options={get(dataOptions, "dataTeachers", "")}
                  allowClear={false}
                />
              </Form.Item> */}
            </Col>
          </Row>
          <Divider />
          <Tree
            style={{ fontSize: 15 }}
            showLine={true}
            showIcon={true}
            defaultExpandedKeys={["0-0-0"]}
            expandedKeys={expandedKeys}
            onExpand={(e) => setExpandedKeys(e)}
            treeData={treeData}
            blockNode
          />
          <Button
            type="primary"
            style={{ width: "100%", marginTop: "16px" }}
            htmlType="submit"
          >
            Lưu thông tin
          </Button>
        </Form>
      </Card>
    </Form.Provider>
  );
};
export default CourseConstructionPlanningAssignment;
