import { Button, Collapse, Drawer, Form, Radio, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import TableData from '../../../table-data/TableData'
import "./index.scss"
import { isEmpty, size } from 'lodash'
import { getTableDataDetailSequence } from '../../../../service/content-list-course/contentListCourseApi'
import { formatDate } from '../../../../utils/converDate'

type Props = {
  open: boolean
  onClose: () => void
  detailData: any
}

export default function DetailParticipateDrawer(props: Props) {
  const { open, onClose, detailData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")

  const dataColumnsLesson: TableColumnsType<any> = [
    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      key: "status",
      width: 30,
      render: (_: any, record, index: number) => {
        return <div></div>;
      },
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      width: 30,
      render: (_: any, record, index: number) => {
        return <div>{isEmpty(record.createdDate) ? null : formatDate(record.createdDate ?? '')}</div>;
      },
    },
    {
      title: "Người cập nhật",
      dataIndex: "userprofileName",
      key: "userprofileName",
      sorter: true,
      width: 30,
    },
  ]

  const getListDataDetail = async () => {
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder,
      sequenceId: detailData?.id
    }
    await getTableDataDetailSequence(data).then((res) => {
      setListTableDataDetail(res.data?.data?.content)
    })
  }

  useEffect(() => {
    if (open) {
      getListDataDetail();
    }
  }, [open]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return (
    <Drawer
      title={"Xem yêu cầu tham gia nền tảng"}
      open={open}
      onClose={onClose}
      className='management-participate-detail-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
      extra={
        <Space>
          <Button onClick={onClose}>Đóng</Button>
        </Space>
      }
    >
      <Form
        className="active-settings-chapter"
        layout={"vertical"}
        form={form}
        name="active-settings-chapter"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel
              header="Thông tin cơ sở đào tạo"
              key="1"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Cơ quan quản lý</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.organizationName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Tên viết tắt</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.acronym}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Tên cơ sở đào tạo</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.name}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Loại trường</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.trainingTypeName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Loại hình</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.universityTypeName}</p></span>
              </div>
            </Panel>

            <Panel
              header="Địa chỉ cơ sở đào tạo"
              key="2"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Thành phố/Tỉnh</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.provinceName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Quận/Huyện</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.districtName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Phường/Xã</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.wardName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Địa chỉ chi tiết</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.address}</p></span>
              </div>
            </Panel>

            <Panel
              header="Địa chỉ cơ sở đào tạo"
              key="3"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Chức vụ</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.userContactPositionName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Số điện thoại</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.userContactPhone}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Email</Typography.Title> <p className='mt-0.5 ml-2'>{detailData?.userContactEmail}</p></span>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Form>
    </Drawer>
  )
}
