import "./index.scss";
import {
  Badge,
  Button,
  Col,
  Modal,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
  message,
} from "antd";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import PageHeader from "../../../components/page-header/PageHeader";
import { DownloadOutlined, FileDoneOutlined } from "@ant-design/icons";
import ConditionPublishSurvey from "./components/condition-publish-survey/ConditionPublishSurvey";
import { Content } from "antd/es/layout/layout";
import {
  getSurveyDetailAll,
  getSurveyInfo,
  getSurveyObjectsFilterById,
} from "../../../service/survey-managment/list-survey";
import {
  getIndustry,
  getUniversity,
  getdataIndustryGroup,
} from "../../../service/common";
import { useNavigate, useParams } from "react-router-dom";
import { DetailContent } from "../mooc-survey/components/detail-content/DetailContent";
import CustomCard from "../../../components/custom-card/CustomCard";
import { surveyStatus } from "../mooc-survey/constants/data";
import { useSurveyDetail, useSurveyInfo } from "../../../stores/stores";
import { get } from "lodash";
import DetailSurveySection from "./components/detail-survey-section/DetailSurveySection";
import { ListSurveySection } from "../../../types/survey-managment/survey";
import { initialValueListSection } from "../survey-construction/detail-survey/component/constant/data";
import { routesConfig } from "../../../config/routes";

type ContentConstructionProps = {
  activeKey?: string;
  title?: string;
};

const listBreadcrumb = [
  // {
  //   title: <a href={`survey-training/list-survey`}>Khảo sát nhu cầu đào tạo</a>,
  // },
  {
    title: "Khảo sát nhu cầu đào tạo",
  },
  {
    title: "Quản lý đợt khảo sát",
  },
  {
    title: "Xuất bản đợt khảo sát",
  },
];

type dataFilterRequest = {
  id?: number;
  surveyId?: number;
  filterOperator: boolean;
  filterCondition: number;
  filterFromValue?: string;
  filterToValue?: string;
  filterValue: string;
};

// type typeSubmitRequest = {
//     surveyId: number;
//     surveyOpen: Date;
//     surveyClose: Date;
//     filters: dataFilterRequest[];
// };

const imgPath = "/images/";

const SurveyPublish: FunctionComponent<ContentConstructionProps> = (props) => {
  const { activeKey, title } = props;
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(activeKey);
  const submitRef = useRef<HTMLButtonElement>(null);

  const { Text } = Typography;

  const [status, setStatus] = useState();
  const [detailData, setDetailData] = useState<any>({});
  const [listInfo, setListInfo] = useState<any[]>();
  const [dataOptions, setDataOptions] = useState({
    dataIndustry: [],
    dataIndustryGroup: [],
    dataUniversity: [],
    dataFilter: {},
  });

  useEffect(() => {
    let mounted = true;
    (async () => {
      const response = await Promise.all([
        getUniversity(),
        getIndustry(),
        getdataIndustryGroup(),
        getSurveyObjectsFilterById(Number(params.id)),
      ]);
      if (mounted) {
        // const dataUniversity = response[0].data.data;
        // const dataIndustry = response[1].data.data;
        // const dataIndustryGroup = response[2].data.data;
        const dataFilterOject = response[3].data.data.data;
        setDataOptions({
          ...dataOptions,
          // dataIndustry: dataIndustry,
          // dataUniversity: dataUniversity,
          // dataIndustryGroup: dataIndustryGroup,
          dataFilter: dataFilterOject,
        });
      }
    })();

    return () => {
      mounted = false;
    };
  }, []);

  const { surveyInfo, setSurveyInfo } = useSurveyInfo((state: any) => {
    return {
      surveyInfo: state?.surveyInfo,
      setSurveyInfo: state?.setSurveyInfo,
    };
  });

  const {
    listSurveySection,
    setListSurveySection,
    // updateSurveySection,
    setListSurveySectionInput,
    // saveDraftSurveySection,
  } = useSurveyDetail((state: any) => {
    return {
      listSurveySection: state?.listSurveySections,
      setListSurveySection: state?.setListSurveySections,
      // updateSurveySection: state?.updateSurveySection,
      setListSurveySectionInput: state?.setListSurveySectionsInput,
      // saveDraftSurveySection: state?.saveDraftSurveySections,
    };
  });

  const [dataSection, setDataSection] = useState(listSurveySection);

  const renderSwitch = (value: string) => {
    switch (value) {
      case "surveyName":
        return <div>Tên đợt khảo sát</div>;
      case "surveyStatusName":
        return <div>Trạng thái</div>;
      case "industry":
        return <div>Khoa/nhóm ngành</div>;
      case "industryGroup":
        return <div>Chuyên ngành</div>;
      case "courseTypeName":
        return <div>Loại khóa học</div>;
      case "universityHost":
        return <div>Trường chủ trì</div>;
      case "collaborator":
        return <div>Trường hợp tác</div>;
      default:
        return "";
    }
  };

  useEffect(() => {
    const convertObjectData = Object.entries(detailData);
    const listInfo = convertObjectData.map((item: any, index) => (
      <div className="list-item" key={index}>
        <Typography.Title level={5} className="list-item--title">
          {renderSwitch(item[0])}
        </Typography.Title>
        <div className="list-item--description">
          <Typography.Text className="content">
            {item[0] === "surveyStatusName" ? (
              <Badge
              // key={surveyStatus.filter((x) => x.value == status)[0].color}
              // color={surveyStatus.filter((x) => x.value == status)[0].color}
              />
            ) : null}{" "}
            {item[1]}
          </Typography.Text>
        </div>
      </div>
    ));
    setListInfo(listInfo);
  }, [detailData]);

  const filterDeleteData = (data: ListSurveySection[]) => {
    const filteredData = data
      .map((section) => {
        const filteredQuestions = section.moocSurveyQuestion
          ?.map((question) => {
            const filteredAnswers = question.moocSurveyQuestionAnswer?.filter(
              (answer) => !answer.isDeleted
            );
            return {
              ...question,
              moocSurveyQuestionAnswer: filteredAnswers,
            };
          })
          .filter((question) => !question.isDeleted);
        return {
          ...section,
          moocSurveyQuestion: filteredQuestions,
        };
      })
      .filter((section) => !section.isDeleted);

    return filteredData;
  };

  const sortDataFunction = (data: ListSurveySection[]) => {
    const filteredData = data.filter((item) => !item.isDeleted);

    let sortedData = filteredData.sort((a, b) => {
      if (a.orderNumber && b.orderNumber) {
        return a.orderNumber - b.orderNumber;
      }
      // Xử lý trường hợp orderNumber có thể undefined
      // Ví dụ: Trả về 0, không thay đổi thứ tự
      return 0;
    });

    // Sắp xếp moocSurveyQuestion của mỗi section
    sortedData.forEach((section) => {
      if (section.moocSurveyQuestion) {
        section.moocSurveyQuestion.sort((a, b) => {
          if (a.orderNumber !== undefined && b.orderNumber !== undefined) {
            return a.orderNumber - b.orderNumber;
          }
          return 0;
        });

        // Sắp xếp moocSurveyQuestionAnswer của mỗi câu hỏi
        section.moocSurveyQuestion.forEach((question) => {
          if (question.moocSurveyQuestionAnswer) {
            question.moocSurveyQuestionAnswer.sort((a, b) => {
              if (
                a.answerOrderNumber !== undefined &&
                b.answerOrderNumber !== undefined
              ) {
                return a.answerOrderNumber - b.answerOrderNumber;
              }
              return 0;
            });
          }
        });
      }
    });

    return sortedData;
  };

  const updateDataSection = (newDataSection: ListSurveySection[]) => {
    // console.log(`1st-detailSurvey-updateData::`, newDataSection);
    setListSurveySection(sortDataFunction(newDataSection));
    setDataSection(sortDataFunction(newDataSection));
  };

  const getSurveyDetailInfo = () => {
    try {
      getSurveyDetailAll(get(params, "id", "")).then((res) => {
        const data = res.data;
        setSurveyInfo(data);
        if (
          data?.moocSurveySection?.length &&
          data?.moocSurveySection?.length > 0
        ) {
          setListSurveySection(sortDataFunction(data?.moocSurveySection));
          setListSurveySectionInput(sortDataFunction(data?.moocSurveySection));
          setDataSection(sortDataFunction(data?.moocSurveySection));
        }
        if (data?.moocSurveySection?.length === 0) {
          setListSurveySection(initialValueListSection);
          setListSurveySectionInput(initialValueListSection);
          setDataSection(initialValueListSection);
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };

  const getDetailMoocSurveyData = async (id: any) => {
    try {
      await getSurveyInfo(id)
        .then((res) => {
          const detailData = res.data.data;
          setStatus(res.data.data.surveyStatus);
          setDetailData({
            surveyName: detailData?.surveyName || "",
            surveyStatusName: detailData?.surveyStatusName || "",
            industry: detailData?.industry || "",
            industryGroup: detailData?.industryGroup || "",
            courseTypeName: detailData?.courseTypeName || "",
            universityHost: detailData?.universityHost || "",
            collaborator: detailData?.collaborator || "",
          });
        })
        .catch((err) => {
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }
  };

  useEffect(() => {
    getSurveyDetailInfo();
    getDetailMoocSurveyData(params.id);
  }, []);

  // Tab list
  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: `Thông tin chung`,
      children: (
        <DetailContent>
          <CustomCard
            title={
              <div className="card-heading--info">
                <Typography.Title level={5}>
                  Thông tin khảo sát
                </Typography.Title>
                <Typography.Text className="sub-title">
                  Thông tin chung về đợt khảo sát
                </Typography.Text>
              </div>
            }
            className="card-info--wrapper"
          >
            <div className="list-wrapper">{listInfo}</div>
          </CustomCard>
        </DetailContent>
      ),
    },
    {
      key: "2",
      label: `Nội dung khảo sát`,
      children: (
        <DetailContent>
          <DetailSurveySection
            dataSection={filterDeleteData(dataSection)}
            setDataSection={setDataSection}
            setUpdateDataSection={updateDataSection}
            surveyInfo={surveyInfo}
          />
        </DetailContent>
      ),
    },
    {
      key: "3",
      label: `Điều kiện xuất bản`,
      children: (
        <div className="waiting-assignment">
          <ConditionPublishSurvey
            submitRef={submitRef}
            dataOptions={dataOptions}
            setDataOptions={setDataOptions}
          />
        </div>
      ),
    },
  ];

  // Modal confirm publish survey: start
  const { confirm } = Modal;

  const handlePublishSurvey = () => {
    confirm({
      title: "Xác nhận xuất bản khảo sát",
      icon: <FileDoneOutlined />,
      centered: true,
      content: (
        <>
          <p>
            Đảm bảo rằng tất cả các
            <Text type="warning">
              {" "}
              thông tin liên quan đã được kiểm tra và cặp nhật đầy đủ{" "}
            </Text>
            trước khi Xác nhận xuất bản khảo sát
          </p>
        </>
      ),
      onOk() {
        setInterval(() => {
          navigate(`/survey-training/list-survey`);
        }, 500);

        return submitRef.current?.click();
      },
      onCancel() {
        // console.log("Cancel");
      },
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  };

  return (
    <div className="survey-managment-publish">
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={title}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="tabs-heading">
          <Tabs
            defaultActiveKey={activeTabKey}
            items={itemsTab}
            tabBarExtraContent={
              <Row>
                <Space>
                  <Col>
                    <Button type="primary" onClick={handlePublishSurvey}>
                      <DownloadOutlined />
                      Xuất bản
                    </Button>
                  </Col>
                </Space>
              </Row>
            }
          />
        </div>
        {/* <Spin spinning={isLoading} fullscreen /> */}
      </Content>
    </div>
  );
};

export default SurveyPublish;
