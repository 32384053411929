import React, { FunctionComponent, useEffect } from 'react';
import { Button, Col, Form, Row, Typography, DatePicker, Select } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import "./filter-group.scss"
import { useForm } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
const { RangePicker } = DatePicker;

type FormFilterProps = {
  showFilter?: boolean
  clearFilter: (form: FormInstance) => void,
  onFinish?: any,
  dataOptions?: any,
  tab?: any,
  listTeacher?: any,
}



const FormFilter: FunctionComponent<FormFilterProps> = ({
  onFinish,
  showFilter,
  clearFilter,
  dataOptions,
  tab,
  listTeacher,
}) => {

  const [form] = useForm()

  useEffect(() => {
    form.resetFields();
  }, [tab, form]);

  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form name="filterForm" onFinish={onFinish} form={form}>
          <Row gutter={[{ xs: 12, sm: 16, md: 24, lg: 32 }, 16]} justify={"start"}>
            {tab == "1" ? (
              <>
                <Col xl={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name="registrationDate">
                    <RangePicker format="DD/MM/YYYY" placeholder={['Khoảng ngày đăng ký', ' đăng ký']} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name="approvalDate">
                    <RangePicker format="DD/MM/YYYY" placeholder={['Khoảng ngày phê duyệt', 'phê duyệt']} style={{ width: '100%' }} />
                  </Form.Item>
                </Col></>
            ) : (
              <>
                <Col xl={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name="registrationDate">
                    <RangePicker format="DD/MM/YYYY" placeholder={['Khoảng ngày đăng ký', ' đăng ký']} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name="assignDate">
                    <RangePicker format="DD/MM/YYYY" placeholder={['Khoảng ngày phân công', ' phân công']} style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xl={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                  <Form.Item name="teachersApproval">
                    <Select placeholder="Chọn giảng viên" style={{ width: '100%' }} mode="multiple" allowClear maxTagCount="responsive" showSearch>
                      {listTeacher.map((item: any) => (
                        <Select.Option key={(item.id)} value={parseInt(item.id)}>{`${item.fullName}`}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>

            )}
            <Col xl={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
              <div className="group-btn--filter">
                <Button className="btn btn-primary filter-btn" htmlType="submit">
                  <FilterOutlined />
                  <Typography.Text>
                    Lọc
                  </Typography.Text>
                </Button>
                <Button className="btn btn-outlined clear-filter-btn" onClick={() => clearFilter(form)}>
                  <DeleteOutlined />
                  <Typography.Text>
                    Xóa bộ lọc
                  </Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
