import React, { ReactNode } from "react";
import { List, Spin } from "antd";
import { ListGridType } from "antd/es/list";
import "./style.scss";
import EmptyComponent from "../empty";

const initData = [
  {
    content: "Title 1",
  },
  {
    content: "Title 2",
  },
  {
    content: "Title 3",
  },
];

interface ResponsiveListProps {
  data?: any[];
  title?: string | ReactNode;
  grid?: ListGridType;
  className?: string;
  totalRecords?: number;
  showMenu?: boolean;
  loading?: boolean;
  onClickReport?: (data?: any) => void;
  onClickExportExcel?: (data?: any) => void;
  isViewReport?: boolean;
  textTotal?: string;
  isViewStatistical?: boolean;
}

function ResponsiveList({
  data = initData,
  grid,
  title,
  className = "",
  totalRecords = 0,
  showMenu = false,
  loading = false,
  onClickReport = () => {},
  onClickExportExcel = () => {},
  isViewReport = true,
  textTotal = "Kết quả",
  isViewStatistical = true,
}: ResponsiveListProps) {
  return (
    <div className={className}>
      <Spin spinning={loading}>
        {isViewStatistical && (
          <div className="flex justify-space-between align-center mb-2">
            <h3 className="list-title font-size-24">{title} &nbsp;</h3>
            {showMenu ? (
              <div className="flex gap-16 font-weight-6">
                <span className="text-secondary font-size-14">
                  {totalRecords} {textTotal}
                </span>{" "}
                <div
                  className="text-primary font-size-14 pointer"
                  onClick={onClickExportExcel}
                >
                  Xuất dữ liệu Excel
                </div>
                {isViewReport && (
                  <div
                    onClick={onClickReport}
                    className="text-primary font-size-14 pointer"
                  >
                    Xem thống kê
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        <List
          grid={
            grid || {
              gutter: 16,
              xs: 1,
              sm: 2,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }
          }
          locale={{
            emptyText: <EmptyComponent description="Không có kết quả" />,
          }}
          dataSource={data}
          renderItem={(item) => <List.Item>{item.content}</List.Item>}
        />
      </Spin>
    </div>
  );
}

export default ResponsiveList;
