import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownloadOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Button, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import "./ViewUnitDetails.scss";
import { getUnitDetail } from "../../../../service/course-construct";
import { Lesson, unitDetail } from "../../../../types/course-review";
import { isEmpty } from "lodash";
import ModuleList from "../module-list/ModuleList";
import Scorm from "./Scorm";
import EmptyComponent from "../../../empty";
import QuizScreen from "../../learning-course/components/quiz-screen";
import { useNavigate } from "react-router-dom";

interface Props {
  lesson: Lesson | null;
  onVideoEnd: () => void;
  sectionDetail: any;
  modules: any;
  selectLesson: (lesson: Lesson | any) => void;
  selectSequence: (sequence: any) => void;
  defaultKeys: any;
  sequence: any;
}

const ViewUnitDetails: React.FC<Props> = ({
  lesson,
  onVideoEnd,
  sectionDetail,
  modules,
  selectLesson,
  selectSequence,
  defaultKeys,
  sequence,
}) => {
  const navigate = useNavigate();
  const [unitDetail, setUnitDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [view, setView] = useState<number>(1);

  const [blockId, setBlockId] = useState<number>();

  const getDetailCourseData = async () => {
    try {
      setIsLoading(true)
      setUnitDetail(null);
      await getUnitDetail(Number(lesson?.id||'0')).then((res) => {
        const { data } = res.data;
        setUnitDetail(data);
        setBlockId(data?.quizGroups[0]?.blockId);
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (blockId) navigate(`${location.pathname}?blockId=${blockId}`);
    if(lesson?.id){
      getDetailCourseData();
    }
  }, [lesson, blockId]);

  if (!lesson) {
    return (
      <div className="view-unit-play">
        <h2>Chưa chọn nội dung học liệu</h2>
      </div>
    );
  }

  const videoUrl = unitDetail?.documents;
  const scormUrl = unitDetail?.scorms;
  const attachment = lesson?.unitVideos?.[0]?.urlAttachSource;
  const handleDownload = (urlDowload: string) => {
    const url = process.env.PUBLIC_URL + urlDowload;
    window.open(url, "_blank");
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const renderMaterial = (material: {
    text: string | TrustedHTML;
    attachments: any;
    module: any;
    documents: {
      text: string | TrustedHTML;
      mainKeyUrl: any;
      mainKey: any;
    }[];
    scorms: { mainKeyUrl: any }[];
  }) => {
    switch (material?.module) {
      case "video":
        return (
          <ReactPlayer
            url={material?.documents[0]?.mainKeyUrl}
            controls
            onEnded={onVideoEnd}
            width="100%"
            height="80%"
            className="react-player"
            playing={true}
          />
        );
      case "mp3":
        return (
          <iframe
            allow="fullscreen"
            scrolling="yes"
            width="100%"
            height="900px"
            src={material?.documents[0]?.mainKeyUrl}
          />
        );
      case "pdf":
        return (
          <iframe
            allow="fullscreen"
            scrolling="yes"
            width="100%"
            height="900px"
            src={material?.documents[0]?.mainKeyUrl}
          />
        );
      case "text":
        return <div className="mt-5" dangerouslySetInnerHTML={{ __html: material.documents[0]?.text}}></div>;
      case "scorm":
        return scormUrl.map((item: any) => <Scorm src={item?.mainKeyUrl} />);
      case "xapi":
        return scormUrl.map((item: any) => <Scorm src={item?.mainKeyUrl} />);
      case "reference":
        return  <a
        onClick={() =>
          openInNewTab(
            material?.documents[0]?.mainKeyUrl ||
              material?.documents[0]?.mainKey
          )
        }
        className="link-to-reference"
      >
        {unitDetail?.name}
      </a>;
      case "quiz":
        return (
          <QuizScreen
            groupExams={unitDetail?.quizGroups}
            onChangeMenuMode={() => {}}
          />
        );
      default:
        return <EmptyComponent description="Không có dữ liệu" />;
    }
  };

  const handlePrevNext = (direction: "prev" | "next") => {
    const currentIndex = sequence?.findIndex(
      (item: any) => item.id === Number(lesson?.id)
    );
    if (direction === "prev" && currentIndex > 0) {
      setUnitDetail(sequence[currentIndex - 1]);
    } else if (direction === "next" && currentIndex < sequence.length - 1) {
      setUnitDetail(sequence[currentIndex + 1]);
    }
  };

  return (
    <div className="view-unit-play">
      <Breadcrumb
        items={[
          {
            href: "/course-construction/content-construction",
            title: (
              <HomeOutlined style={{ fontSize: "24px", color: "#266BC0" }} />
            ),
          },
          {
            title: (
              <>
                <span>Khóa học của tôi</span>
              </>
            ),
          },
          {
            title: (
              <>
                <span style={{ color: "#344054", fontWeight: 500 }}>
                  {sectionDetail.name}
                </span>
              </>
            ),
          },
        ]}
      />
      <div className="section-detail-name">
        <span className="section-name"> {sectionDetail.name} </span>
      </div>
      <div className="flex justify-center gap-3">
        <Tooltip title="PC">
          <svg
            style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
            viewBox="0 0 24 24"
            onClick={() => setView(1)}
          >
            <path
              fill="#8b8b8b"
              d="M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z"
            ></path>
          </svg>
        </Tooltip>
        <Tooltip title="Tablet">
          <svg
            style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
            viewBox="0 0 24 24"
            onClick={() => setView(2)}
          >
            <path
              fill="#8b8b8b"
              d="M19,18H5V6H19M21,4H3C1.89,4 1,4.89 1,6V18A2,2 0 0,0 3,20H21A2,2 0 0,0 23,18V6C23,4.89 22.1,4 21,4Z"
            ></path>
          </svg>
        </Tooltip>
        <Tooltip title="Mobile">
          <svg
            style={{ display: "block", maxWidth: "22px", cursor: "pointer" }}
            viewBox="0 0 24 24"
            onClick={() => setView(3)}
          >
            <path
              fill="#8b8b8b"
              d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z"
            ></path>
          </svg>
        </Tooltip>
      </div>

      <div
        className={`view-unit-play-play ${
          view === 1 ? "w-100" : view === 2 ? "w-70" : "w-40"
        }`}
      >
        <ModuleList
          blockId={blockId}
          modules={modules}
          selectLesson={selectLesson}
          selectSequence={selectSequence}
          defaultKeys={defaultKeys}
        />
        <div className="video-preview">
          {videoUrl?.length > 0 || scormUrl?.length > 0 ? (
            <>
              <span className="unit-name">{unitDetail?.name}</span>
              <span className="course-name">
                {unitDetail?.courseSectionName}/{unitDetail?.courseSequenceName}
              </span>
            </>
          ) : (
            <></>
          )}
          {/* {videoUrl
            ? videoUrl.map((it: any) => (
                <>
                  {it.fileName?.includes(".pdf") ||
                  it.fileName?.includes(".mp3") ? (
                    <iframe
                      allow="fullscreen"
                      scrolling="yes"
                      width="100%"
                      height="900px"
                      src={it?.mainKeyUrl}
                    />
                  ) : !isEmpty(it.text) ? (
                    <div dangerouslySetInnerHTML={{ __html: it.text }}></div>
                  ) : (
                    <ReactPlayer
                      url={it?.mainKeyUrl}
                      controls
                      onEnded={onVideoEnd}
                      width="100%"
                      height="80%"
                      className="react-player"
                      playing={true}
                    />
                  )}
                  {it?.attachments?.map((url: any) => (
                    <div
                      onClick={() => handleDownload(url.attachmentKey)}
                      className="attachment"
                    >
                      <DownloadOutlined style={{ fontSize: "24px" }} /> Tệp đính
                      kèm: {url.fileName}
                    </div>
                  ))}
                </>
              ))
            : null} */}
          {/* {scormUrl
            ? scormUrl.map((item: any) => <Scorm src={item?.mainKeyUrl} />)
            : null} */}
          {isLoading ? (
            <div className="learning-course-loading">
              <Spin size="large" />
            </div>
          ) : (
            <>{renderMaterial(unitDetail as any)}</>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewUnitDetails;
