import React, { useEffect, useMemo, useState } from "react";
import "./detail.scss";
import {
  Button,
  Card,
  Input,
  Radio,
  Row,
  Select,
  Space,
  ConfigProvider,
  Switch,
  Dropdown,
  MenuProps,
  Col,
} from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  FundViewOutlined,
  MoreOutlined,
  StarOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import toolbarOptions from "./toolbarOptions";
import QuestionType1 from "../question-type-1/QuestionType1";
import {
  ListAnswer,
  ListQuestion,
  OptionTypeQuestion1,
} from "../../../../../../../../../types/survey-respond";
import QuestionType2 from "../question-type-2/QuestionType2";
import QuestionType3 from "../question-type-3/QuestionType3";
import QuestionType4 from "../question-type-4/QuestionType4";
import QuestionType6 from "../question-type-6/QuestionType6";
import QuestionType5 from "../question-type-5/QuestionType5";
import QuestionType7 from "../question-type-7/QuestionType7";
import QuestionType8 from "../question-type-8/QuestionType8";

interface QuestionUI {
  listSectionQuestions: ListQuestion[];
  surveyId: number;
}

const QuestionUI: React.FC<QuestionUI> = ({ listSectionQuestions, surveyId }) => {


  const questionsUI = (typeQuestionId: number, questionId: number, surveySectionId: number, surveyId: number, questionAnswer: any, list_answer?: ListAnswer | ListAnswer[]) => {

    switch (typeQuestionId) {
      case 1:
        return (
          <QuestionType1
            listOptions={list_answer}
            questionId={questionId} sectionId={surveySectionId}
            surveyId={surveyId}
            questionAnswer={questionAnswer}
          />
        );
      case 2:
        return <QuestionType2 listOptions={list_answer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} />;
      case 3:
        return <QuestionType3 listOptions={list_answer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} />;
      case 4:
        return <QuestionType4 listOptions={list_answer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} />;
      case 5:
        return <QuestionType5 listOptions={list_answer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} />;
      case 6:
        return <QuestionType6 listOptions={list_answer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} />;
      case 7:
        return <QuestionType7 listOptions={list_answer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} />;
      case 8:
        return <QuestionType8 listOptions={list_answer} surveyId={surveyId} questionId={questionId} sectionId={surveySectionId} questionAnswer={questionAnswer} />;
      default:
        return null;
    }
  };
  return (
    <div>
      {listSectionQuestions.map(
        (sectionQuestion: ListQuestion, index: number) => {
          const {
            questionId,
            surveySectionId,
            questionName,
            questionDescription,
            questionType,
            questionAnswer,
            orderNumber,
            isRequired,
            questionsPoints,
            responseValidationType,
            responseValidationOperator,
            responseValidationValue,
            responseValidationCustomErrorText,
            moocSurveyQuestionAnswer,
          }: ListQuestion = sectionQuestion;
          return (
            <Card
              key={index}
              styles={{ header: { border: "none" } }}
              className="wrapper-settings--card form-setting--common"
            >
              <Row>
                <Row>
                  <Row style={{ paddingBottom: "20px" }}>
                    <Col span={24} className="surveyTitle">{isRequired && <StarOutlined style={{ marginRight: "10px", color: "red" }} />}{questionName}</Col>
                    <Col span={24} className="surveyDiscription" dangerouslySetInnerHTML={{ __html: questionDescription ?? "" }}></Col>
                  </Row>
                </Row>
                <Row style={{ width: '100%' }}>{questionsUI(Number(questionType), questionId, surveySectionId, surveyId, questionAnswer, moocSurveyQuestionAnswer)}</Row>
              </Row>
            </Card>
          );
        }
      )}
    </div>
  );
};

export default QuestionUI;
