// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading-resigter-survey {
  width: fit-content; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-appraisal-plan/components/filter-appraisal-plan/FilterAppraisalPlan.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB,EAAA","sourcesContent":[".heading-resigter-survey {\n    width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
