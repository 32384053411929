import { Checkbox, CheckboxProps, Form, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { getListPublicLearningTrend } from "../../../service/prediction-model";

import { SearchProps } from "antd/es/input";
import { useAddPermission } from "../../../stores/stores";

const { Search } = Input;

interface IProps {
  formModal: any;
  isView?: boolean;
}

const mock_data = [
  {
    title: "Vai trò A",
    id: 1,
    items: [
      {
        name: "gv2@gmail.com",
        id: 11,
      },
      {
        name: "gv2@gmail.com",
        id: 12,
      },
      {
        name: "gv2@gmail.com",
        id: 13,
      },
      {
        name: "gv2@gmail.com",
        id: 14,
      },
      {
        name: "gv2@gmail.com",
        id: 15,
      },
      {
        name: "gv2@gmail.com",
        id: 16,
      },
      {
        name: "gv2@gmail.com",
        id: 17,
      },
      {
        name: "gv2@gmail.com",
        id: 18,
      },
    ],
  },
  {
    title: "Vai trò B",
    id: 2,
    items: [
      {
        name: "gv2@gmail.com",
        id: 21,
      },
      {
        name: "gv2@gmail.com",
        id: 22,
      },
      {
        name: "gv2@gmail.com",
        id: 23,
      },
      {
        name: "gv2@gmail.com",
        id: 24,
      },
    ],
  },
];

const PermissionExamDrawer = ({ formModal, isView }: IProps) => {
  const [dataOptionInput, setDataOptionInput] = useState<any>([]);
  const [dataOptionAction, setDataOptionAction] = useState<any>([]);
  const [fieldsRole, setFieldsRole] = useState<any>([]);
  const [fieldsRoleItems, setFieldsRoleItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const { permission, setPermission } = useAddPermission((state) => state);
  const [searchValue, setSearchValue] = useState<string>("");

  const onChangeSelect = (e: any) => {
    if (fieldsRole?.includes(e.target.value)) {
      const newFields = fieldsRole.filter(
        (value: number) => value !== e.target.value
      );
      setFieldsRole(newFields);
    } else {
      setFieldsRole([...fieldsRole, e.target.value]);
    }
  };
  const onChangeSelectInfo = (e: any) => {
    if (!isView) {
      if (fieldsRoleItems?.includes(e.target.value)) {
        const newFieldsItem = fieldsRoleItems.filter(
          (value: number) => value !== e.target.value
        );
        setFieldsRoleItems(newFieldsItem);
      } else {
        setFieldsRoleItems([...fieldsRoleItems, e.target.value]);
      }
    }
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    if (!e.target.checked) {
      let newPermission = [...permission];
      newPermission = newPermission.filter(
        (item: number) => item !== e.target.value
      );

      setPermission(newPermission);
    } else {
      setPermission([...permission, e.target.value]);
    }
  };

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
    setSearchText(value);
  };
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };

  const fetchOptionDataTrend = async (id: any, setDataOption: any) => {
    try {
      const response = await getListPublicLearningTrend({
        group_id: id,
      });
      const { data } = response.data;
      setDataOption(data?.data?.categories);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchOptionDataTrend(1, setDataOptionInput);
    fetchOptionDataTrend(2, setDataOptionAction);
  }, []);

  return (
    <div>
      <Search
        className="mb-2"
        placeholder="Nhập tên vai trò cần tìm"
        onSearch={onSearch}
        value={searchText}
        onChange={handleChangeSearch}
      />
      {mock_data.map((data: any) => (
        <div className="pt-2">
          <p className="bg-[#FAFAFA] p-4 mt-1 rounded-md">
            <Checkbox
              className="w-full m-0"
              checked={fieldsRole.includes(data?.id)}
              name="infoInput"
              value={data?.id}
              onChange={onChangeSelect}
            >
              <p className=" font-medium text-xl">{data?.title}</p>
            </Checkbox>
          </p>

          {fieldsRole.includes(data?.id) && (
            <div className="mt-4 overflow-y-auto max-h-[250px]">
              {data?.items?.map((item: any, index: number) => (
                <div className="py-2" key={index}>
                  <div className="max-h-[200px] overflow-auto">
                    <Checkbox
                      className="w-full m-0"
                      checked={fieldsRoleItems.includes(item?.id)}
                      name={item?.id}
                      value={item?.id}
                      onChange={(e) => onChangeSelectInfo(e)}
                    >
                      {item?.name}
                    </Checkbox>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PermissionExamDrawer;
