import { Button, Col, Form, Layout, message, Modal, Row, Tabs, Tag, Typography } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import "./student-management-detail.scss"
import { exportExcelMoocStudentDetail, getMoocStudentById } from "../../../../service/category-management/student-management";
import { LoadingOutlined } from "@ant-design/icons";
import { TabsProps } from "antd/lib";
import StudentDetail from "../../../../components/category-management/management-detail/student-info/student-detail/studentDetail";
import StudentHistory from "../../../../components/category-management/management-detail/student-info/student-history/studentHistory";

const { Content } = Layout;
const imgPath = "/images/";

const StudentManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [isRendering, setIsRendering] = useState<boolean>(false);
    const idStudent = location.state.record.id;
    const [activeTab, setActiveTab] = useState<string>("1");
    
    const listBreadcrumb = [
        {
          title: "Quản lý danh mục",
        },
        {
          title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.studentManagement}`}>
            <span>Quản lý sinh viên</span>
          </SafeLink>,
        },
        {
          title: location.state.name,
        },
    ];

    // Chuyên ngành
    const specialized = location.state.record?.industryGroupInfo?.children.map((item: any) => {
        return(item.name);
    });

    const items : TabsProps["items"] =[
        {
            key:"1",
            label:"Thông tin chi tiết sinh viên",
            children: (
                <StudentDetail studentRecord={location.state.record} specialized={specialized}/>
            )
        },
        {
            key:"2",
            label:"Lịch sử thao tác",
            children: (
                <StudentHistory idStudent={idStudent}/>
            )
        },
    ]

    const handleTabChange = (key: string) => {
        setActiveTab(key);
    };

    const handleExportExcel = () => {
        Modal.confirm({
          title: `Xác nhận kết xuất danh sách sinh viên`,
          content: ``,
          onOk: () => handleExportStudentDetail(),
          centered: true,
          okText: "Xác nhận",
          cancelText: "Huỷ bỏ",
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }

    const handleExportStudentDetail = async () => {
        const idStudentManagement = location.state.record.id;
        const nameStudentManagement = location.state.record.name;
        setIsRendering(true);
        try{
            await exportExcelMoocStudentDetail(idStudentManagement).then((res) => {
                const fileData = res.data;
                const blob = new Blob([fileData], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
        
                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);
        
                downloadLink.download = `Chi tiết quản lý sinh viên ${nameStudentManagement}.xlsx`;
        
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                message.success("Tải xuống thành công!");
                });
        }
        catch(err){
            setIsRendering(false);
            console.error("Error downloading Excel file:", err);
        }finally{
            setIsRendering(false);
        }      
    };

    const renderExportButton = () => {
        if (activeTab === "1") {
            return (
                <Button 
                    type="primary" 
                    onClick={handleExportExcel} 
                    disabled={isRendering}
                >
                    <Typography.Text>
                        {isRendering ? <LoadingOutlined /> : ''} Kết xuất thông tin chi tiết
                    </Typography.Text>
                </Button>
            );
        }
        return null;
    };
    return (
        <>
            <Content>
                <PageHeader
                    title={location.state?.name ? location.state?.name : ""}
                    listBreadcrumb={listBreadcrumb}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    breadcrumb={true}
                    headerGroupButton={activeTab === "1"}
                    childrenGroupButton={renderExportButton()}
                />
                <div className="tabs-heading">
                    <Tabs 
                    defaultActiveKey="1"
                    items={items}
                    onChange={handleTabChange}
                    />
                </div>
            </Content>
        </>
    )
}

export default StudentManagementDetail;