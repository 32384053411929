import React, {FunctionComponent, ReactNode} from 'react';
import {Card} from "antd";
import "./custom-card-survey-response.scss"


type CustomCardProps = {
  title?: string | ReactNode,
  children?: ReactNode,
  extra?: ReactNode,
  className?: string
}

const CustomCardSurveyResponse: FunctionComponent<CustomCardProps> = ({title, children, className, extra}) => {
  return (
    <>
      <Card title={title} extra={extra} className={`wrapper-card-survey-response ${className}`}>
        {children}
      </Card>
    </>
  );
};

export default CustomCardSurveyResponse;
