// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-filter {
  width: 100%;
  background: #FFFFFF;
  overflow: hidden;
  padding-top: 16px;
  max-height: 0;
  height: auto;
  transition: max-height 0.3s ease-in-out; }
  .wrapper-filter .ant-form-item {
    margin-bottom: 0; }

.wrapper-filter.open {
  max-height: 100%; }

.group-btn--filter {
  display: flex; }

@media screen and (min-width: 996px) {
  .filter-btn {
    margin-right: 10px; } }

@media screen and (max-width: 768px) {
  .filter-btn {
    width: 35%; }
  .clear-filter-btn {
    width: 60%; }
  .group-btn--filter {
    justify-content: space-between; } }

@media screen and (max-width: 480px) {
  .filter-btn,
  .clear-filter-btn {
    width: 50%; }
  .group-btn--filter {
    justify-content: space-around; }
  .wrapper-filter {
    height: 200px; }
  .heading-filter {
    width: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-list-course/filter-group/filter-group.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,YAAY;EACZ,uCAAuC,EAAA;EAPzC;IAUI,gBAAgB,EAAA;;AAKpB;EACE,gBAAgB,EAAA;;AAGlB;EACE,aAAa,EAAA;;AAIf;EACE;IACE,kBAAkB,EAAA,EACnB;;AAIH;EACE;IACE,UAAU,EAAA;EAGZ;IACE,UAAU,EAAA;EAGZ;IACE,8BAA8B,EAAA,EAE/B;;AAGH;EAEE;;IAEE,UAAU,EAAA;EAGZ;IACE,6BAA6B,EAAA;EAG/B;IACE,aAAa,EAAA;EAGf;IACE,WAAW,EAAA,EACZ","sourcesContent":[".wrapper-filter {\n  width: 100%;\n  background: #FFFFFF;\n  overflow: hidden;\n  padding-top: 16px;\n  max-height: 0;\n  height: auto;\n  transition: max-height 0.3s ease-in-out;\n  \n  .ant-form-item {\n    margin-bottom: 0;\n  }\n\n}\n\n.wrapper-filter.open {\n  max-height: 100%;\n}\n\n.group-btn--filter {\n  display: flex;\n}\n\n\n@media screen and (min-width: 996px) {\n  .filter-btn {\n    margin-right: 10px;\n  }\n}\n\n\n@media screen and (max-width: 768px) {\n  .filter-btn {\n    width: 35%;\n  }\n\n  .clear-filter-btn {\n    width: 60%;\n  }\n\n  .group-btn--filter {\n    justify-content: space-between;\n\n  }\n}\n\n@media screen and (max-width: 480px) {\n\n  .filter-btn,\n  .clear-filter-btn {\n    width: 50%;\n  }\n\n  .group-btn--filter {\n    justify-content: space-around;\n  }\n\n  .wrapper-filter {\n    height: 200px;\n  }\n\n  .heading-filter {\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
