import {
  ChangeEvent,
  FunctionComponent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import moment from "moment";

import PageHeader from "../../../components/page-header/PageHeader";
import "./list-user.scss";
import {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Popconfirm,
  Space,
  Switch,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  Typography,
  message,
} from "antd";
import FilterTable from "./components/filter-table/FilterTable";
import { DataType } from "../../../types/listTrainingNeeds";
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  StopOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import FormFilter from "./components/filter-group/FormFilter";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import TableData from "../../../components/table-data/TableData";
import { get, isEmpty, set } from "lodash";
import { validateFieldCreate } from "./constants/data";
import { useAuthStore, useSurveyRespond } from "../../../stores/stores";
import { HelpService } from "../../../service/helper.service";
import { routesConfig } from "../../../config/routes";
import { FormInstance } from "antd/lib";
import { ColumnType } from "antd/es/table";
import {
  getListCoursePlaningAssignment,
  getListNumberCoursePlaningAssignment,
  revokeCourseAssignment,
} from "../../../service/course-plan-managment/mooc-course-planning-assignment";
import { WarningContext } from "antd/es/_util/warning";
import { getAllTeachers } from "../../../service/list-account-teachers/listAccountTeachersApi";

type CoursePlanningAssignmentProps = {
  title?: string;
};

const CoursePlanningAssignment: FunctionComponent<
  CoursePlanningAssignmentProps
> = ({ title }) => {
  const helpService = new HelpService();
  const location = useLocation();
  const initialTabState = location.state?.typeAssignment?.toString() ?? "1";
  const navigate = useNavigate();
  const userInfo = useAuthStore((state) => state.user);
  const [userId, setUserId] = useState<any>(userInfo?.id);
  const [params, setParams] = useSearchParams();
  const [changeTabState, setChangeTabState] = useState<number>(1);
  const [tab, setTab] = useState<string>(initialTabState);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [listTeacher, setListTeacher] = useState<any>([]);
  const [listSurveyStudent, setListSurveyStudent] = useState<DataType[]>([]);
  const [countTrueRecord, setCountTrueRecord] = useState<number>();
  const [countFalseRecord, setCountFalseRecord] = useState<number>();
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isRefreshData, setIsRefreshData] = useState(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [valueFilter, setValueFilter] = useState<any>({});
  const [form] = Form.useForm();

  const fetchData = async () => {
    setLoading(true);

    await getListCoursePlaningAssignment(
      {
        keyword: searchValue,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortField: fieldSortOrder,
        sortOrder: statusOrder,
        registrationDate: addHours(
          get(valueFilter, "registrationDate", null),
          7
        ),
        approvalDate: addHours(get(valueFilter, "approvalDate", null), 7),
        assignDate: addHours(get(valueFilter, "assignDate", null), 7),
        teachersApproval: get(valueFilter, "teachersApproval", null),
      },
      userId,
      tab
    ).then((res) => {
      const { data, totalRecords } = res.data.data;
      setListSurveyStudent(data);
      setTotalRecords(totalRecords);
      setIsRefreshData(false);
      setSelectedRowKeys([]);
      if (tab === "2") {
        setCountTrueRecord(totalRecords);
      } else {
        setCountFalseRecord(totalRecords);
      }
    });

    //tab1
    await getListCoursePlaningAssignment(
      {
        keyword: searchValue,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortField: fieldSortOrder,
        sortOrder: statusOrder,
        registrationDate: addHours(
          get(valueFilter, "registrationDate", null),
          7
        ),
        approvalDate: addHours(get(valueFilter, "approvalDate", null), 7),
        assignDate: addHours(get(valueFilter, "assignDate", null), 7),
        teachersApproval: get(valueFilter, "teachersApproval", null),
      },
      userId,
      1
    ).then((res) => {
      const { totalRecords } = res.data.data;

      setCountFalseRecord(totalRecords);
    });

    //tab2
    await getListCoursePlaningAssignment(
      {
        keyword: searchValue,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortField: fieldSortOrder,
        sortOrder: statusOrder,
        registrationDate: addHours(
          get(valueFilter, "registrationDate", null),
          7
        ),
        approvalDate: addHours(get(valueFilter, "approvalDate", null), 7),
        assignDate: addHours(get(valueFilter, "assignDate", null), 7),
        teachersApproval: get(valueFilter, "teachersApproval", null),
      },
      userId,
      2
    )
      .then((res) => {
        const { totalRecords } = res.data.data;
        setCountTrueRecord(totalRecords);
      })
      .finally(() => setLoading(false));
  };

  const getDataTeacher = async () => {
    await getAllTeachers().then((res) => {
      const { data } = res.data;
      setListTeacher(data);
    });
  };

  const handleCheckAll = () => {
    const newIsCheckAll = !isCheckAll;
    const newSelectedRowKeys = newIsCheckAll
      ? listSurveyStudent.map((record: any) => record.id)
      : [];
    setSelectedRowKeys(newSelectedRowKeys);
    setIsCheckAll(newIsCheckAll);
  };

  const onSelectChange = (recordId: number) => {
    const newSelectedRowKeys = [...selectedRowKeys];
    if (newSelectedRowKeys.includes(recordId)) {
      setSelectedRowKeys(
        newSelectedRowKeys.filter((item) => item !== recordId)
      );
    } else {
      setSelectedRowKeys([...newSelectedRowKeys, recordId]);
    }
  };

  useEffect(() => {
    setIsCheckAll(selectedRowKeys.length === listSurveyStudent.length);
  }, [selectedRowKeys, listSurveyStudent, isRefreshData]);

  useEffect(() => {
    fetchData();
  }, [pageSize, pageNumber, isRefreshData, statusOrder, valueFilter]);

  useEffect(() => {
    fetchData();
    getDataTeacher();
  }, [tab, isRefreshData]);

  // useEffect(() => {
  //   const fetchData = async () =>
  //     await getListNumberCoursePlaningAssignment(userId).then((res) => {
  //       const { countNotIn, countIn } = res.data.data;
  //       setCountFalseRecord(countNotIn);
  //       setCountTrueRecord(countIn);
  //       setIsRefreshData(false);
  //       setSelectedRowKeys([]);
  //     });
  //   fetchData();
  // }, [tab, isRefreshData]);

  const optionsDropdownMenu1: MenuProps["items"] = [
    {
      key: "1.1",
      label: "Phân công",
      icon: <UserAddOutlined />,
    },
    {
      key: "1.2",
      label: "Lịch sử hoạt động",
      icon: <HistoryOutlined />,
    },
    {
      key: "1.3",
      label: "Lịch sử duyệt",
      icon: <HistoryOutlined />,
    },
  ];

  const optionsDropdownMenu2: MenuProps["items"] = [
    {
      key: "2.1",
      label: "Phân công lại",
      icon: <RedoOutlined />,
    },
    {
      key: "2.2",
      label: "Lịch sử phân công",
      icon: <HistoryOutlined />,
    },
    {
      key: "2.4",
      label: "Lịch sử duyệt",
      icon: <HistoryOutlined />,
    },
    {
      key: "2.3",
      label: "Thu hồi",
      icon: <StopOutlined />,
      danger: true,
    },
  ];

  const handleRevokeAssingment = async (nameCourse: any, idCourse: any) => {
    try {
      Modal.confirm({
        title: "Thu hồi phân công",
        icon: <ExclamationCircleOutlined style={{ color: "#FFC53D" }} />,
        content: (
          <div>Bạn có chắc chắn muốn thu hồi giảng viên của {nameCourse}?</div>
        ),
        onOk: async () => {
          try {
            await revokeCourseAssignment({ idCourse: idCourse });
            message.success("Thu hồi phân công giảng viên thành công");
            setTab("1");
          } catch (error) {
            message.error("Thu hồi phân công giảng viên thất bại");
          }
        },
        okText: "Xác nhận",
        cancelText: "Huỷ",
        centered: true,
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        ),
      });
    } catch (error) {
      message.error("Gửi khảo sát thất bại");
    }
  };

  const dataColumns = useMemo(() => {
    return [
      {
        title: "STT",
        key: "numericalOrder",
        width: "5%",
        render: (_: any, record: any, index: any) => (
          <Space style={{ width: "100%", justifyContent: "space-between" }}>
            <Typography.Text>
              {(index + 1).toString().padStart(2, "0")}
            </Typography.Text>
            <Checkbox
              checked={selectedRowKeys.includes(record.id)}
              onChange={() => onSelectChange(record.id)}
            />
          </Space>
        ),
      },
      {
        title: "Mã đăng ký",
        dataIndex: "registrationCode",
        key: "registrationCode",
        width: "10%",
      },
      {
        title: "Tên đăng ký",
        dataIndex: "registrationName",
        key: "registrationName",
        sorter: true,
        width: "50%",
        render: (_: any, record: any) => {
          return (
            <div className="table-order-course-name">
              <img
                className="item-carousel__image"
                loading="eager"
                alt=""
                src={"/images/ic_school_placeholder.svg"}
              />
              <div>
                <span className="course-title">{record.registrationName}</span>
                <span>{record.universityName}</span>
              </div>
            </div>
          );
        },
      },
      {
        title: tab === "1" ? "Ngày phê duyệt" : "Ngày phân công",
        dataIndex: "approvalDate",
        key: "approvalDate",
        width: "10%",
        render: (date: any) => moment(date).format("DD/MM/yyyy")
      },
      {
        title: "Ngày đăng ký",
        dataIndex: "createdDate",
        key: "createdDate",
        width: "10%",
        render: (date: any) => convertDate(date),
      },
      {
        title: tab === "1" ? "Người phê duyệt" : "Người phân công",
        dataIndex: "approver",
        key: "approver",
        width: "20%",
        render: (_: any, record: any) => {
          const members = record.approver;
          if (members.length === 0) {
            return null;
          } else if (members.length === 1) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  style={{ backgroundColor: "orange", marginRight: "6px" }}
                  src={members[0].image_path}
                >
                  {members[0]?.name
                    ?.split(" ")
                    .map((word: string) => word[0])
                    .join("")}
                </Avatar>
                <span className="avatar-name">{members[0].name}</span>
              </div>
            );
          } else if (members.length === 2 || members.length === 3) {
            return (
              <Avatar.Group
                maxCount={3}
                maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              >
                {members.map((member: any, index: number) => (
                  <Avatar
                    key={index}
                    style={{ backgroundColor: "orange" }}
                    src={member.image_path}
                  >
                    {member.name
                      .split(" ")
                      .map((word: string) => word[0])
                      .join("")}
                  </Avatar>
                ))}
              </Avatar.Group>
            );
          } else {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar.Group
                  maxCount={3}
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                    cursor: "pointer",
                  }}
                >
                  {members.map((member: any, index: number) => (
                    <Avatar
                      key={index}
                      style={{ backgroundColor: "orange" }}
                      src={member.image_path}
                    >
                      {member.name
                        .split(" ")
                        .map((word: string) => word[0])
                        .join("")}
                    </Avatar>
                  ))}
                </Avatar.Group>
              </div>
            );
          }
        },
      },
      {
        title: "",
        dataIndex: "action",
        key: "operation",
        width: "8%",
        render: (_: any, record: any) => {
          const location = {
            id: `${record.id}`,
            typeAssignment: tab === "1" ? 1 : 2,
            nameCourse: `${record.registrationName}`,
            themeSurvey: `${record.surveyTheme}`,
          };

          return (
            <Space>
              <a
                className="action-table"
                onClick={() =>
                  navigate(`${routesConfig.detailAssignment}/${location.id}`, {
                    state: {
                      record,
                      mode: "view",
                      typeAssignment: location.typeAssignment,
                      modeback: "backState",
                      backLink: "/course-plan/planning-assignment",
                    },
                  })
                }
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items:
                    tab === "1" ? optionsDropdownMenu1 : optionsDropdownMenu2,
                  onClick: ({ key }) => {
                    if (key === "1.1") {
                      navigate(`${record.id}`, {
                        state: { tab: "2", mode: "assign" },
                      });
                    }
                    if (key === "2.1") {
                      navigate(`${record.id}`, {
                        state: { tab: "2", mode: "reassign" },
                      });
                    }
                    if (key === "1.2") {
                      navigate(
                        `${routesConfig.planningAssignmentHistory}/${record.id}`,
                        {
                          state: record,
                        }
                      );
                    }
                    if (key === "1.3") {
                      navigate(
                        `${routesConfig.detailAssignment}/${location.id}`,
                        {
                          state: {
                            record,
                            tab: "4",
                            mode: "view",
                            typeAssignment: location.typeAssignment,
                            modeback: "backState",
                            backLink: "/course-plan/planning-assignment",
                          },
                        }
                      );
                    }
                    if (key === "2.4") {
                      navigate(
                        `${routesConfig.detailAssignment}/${location.id}`,
                        {
                          state: {
                            record,
                            tab: "4",
                            mode: "view",
                            typeAssignment: location.typeAssignment,
                            modeback: "backState",
                            backLink: "/course-plan/planning-assignment",
                          },
                        }
                      );
                    }
                    if (key === "2.2") {
                      navigate(
                        `${routesConfig.assignmentHistory}/${record.id}`,
                        {
                          state: record,
                        }
                      );
                    }
                    if (key === "2.3") {
                      handleRevokeAssingment(location.nameCourse, location.id);
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          );
        },
        fixed: "right",
      },
    ];
  }, [tab, selectedRowKeys, isCheckAll]);

  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const convertDate = (date: string) => {
    const dateConvert = new Date(date);
    const day = dateConvert.getDate().toString().padStart(2, "0");
    const month = (dateConvert.getMonth() + 1).toString().padStart(2, "0");
    const year = dateConvert.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const newColumns = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleOpenCreate = () => {
    setIsOpened(true);
  };

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleClose = () => {
    setIsOpened(false);
    form.resetFields();
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setParams({ [name]: value });
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
      setParams();
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setLoading(true);
      getListCoursePlaningAssignment(
        {
          keyword: searchValue.trim(),
          sortField: fieldSortOrder,
          sortOrder: statusOrder,
          pageNumber: pageNumber,
          pageSize: pageSize,
        },
        userId,
        tab
      )
        .then((users) => {
          const { data, totalRecords } = users.data.data;
          setListSurveyStudent(data);
          setTotalRecords(totalRecords);
          if (tab === "2") {
            setCountTrueRecord(totalRecords);
          } else {
            setCountFalseRecord(totalRecords);
          }
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  };

  const handleSubmitSearch = () => {
    setLoading(true);
    getListCoursePlaningAssignment(
      {
        keyword: searchValue.trim(),
        sortField: fieldSortOrder,
        sortOrder: statusOrder,
        pageNumber: pageNumber,
        pageSize: pageSize,
      },
      userId,
      tab
    )
      .then((users) => {
        const { data, totalRecords } = users.data.data;
        setListSurveyStudent(data);
        setTotalRecords(totalRecords);
        if (tab === "2") {
          setCountTrueRecord(totalRecords);
        } else {
          setCountFalseRecord(totalRecords);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
    if (searchValue === "") {
      setPageNumber(1);
    }
  };

  const clearFilter = (formInstance: FormInstance) => {
    setLoading(true);
    setValueFilter({});
    getListCoursePlaningAssignment({}, userId, tab)
      .then((users) => {
        const { data, totalRecords } = users.data.data;
        setListSurveyStudent(data);
        setLoading(false);
        formInstance.resetFields();
        setTotalRecords(totalRecords);
        if (tab === "2") {
          setCountTrueRecord(totalRecords);
        } else {
          setCountFalseRecord(totalRecords);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const addHours = (value: any, hour: number) => {
    if (!value) return null;

    if (Array.isArray(value) && value.length > 0) {
      return value.map((single) => {
        const newDate = new Date(single);
        newDate.setHours(newDate.getHours() + hour);
        return newDate;
      });
    } else {
      const newDate = new Date(value);
      newDate.setHours(newDate.getHours() + hour);
      return newDate;
    }
  };

  const handleFinish = (values: any) => {
    setPageNumber(1);
    setValueFilter(values);
    // setLoading(true);
    // getListCoursePlaningAssignment(
    //   {
    //     keyword: searchValue.trim(),
    //     registrationDate: addHours(get(values, "registrationDate", null), 7),
    //     approvalDate: addHours(get(values, "approvalDate", null), 7),
    //     assignDate: addHours(get(values, "assignDate", null), 7),
    //     teachersApproval:get(values, "teachersApproval", null),
    //     sortField: fieldSortOrder,
    //     sortOrder: statusOrder,
    //     pageNumber: "1",
    //     pageSize: pageSize,
    //   },
    //   userId,
    //   tab
    // )
    //   .then((res) => {
    //     const { data, totalRecords } = res.data.data;
    //     setListSurveyStudent(data);
    //     setTotalRecords(totalRecords);
    //     setIsRefreshData(false);
    //     setSelectedRowKeys([]);
    //     if (tab === "2") {
    //       setCountTrueRecord(totalRecords)
    //     }
    //     else {
    //       setCountFalseRecord(totalRecords)
    //     }
    //   })
    //   .finally(() => setLoading(false));
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    if (sorter.field === "registrationName") {
      setFieldSortOrder("registration_name");
    }
  };

  const listBreadcrumb = [
    {
      title: "Khảo sát nhu cầu đào tạo",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    form.resetFields();
  };

  useEffect(() => {
    clearFilter(form);
    setSelectedRowKeys([]);
    setPageNumber(1);
    setChangeTabState((prev) => prev + 1);
  }, [tab]);

  const TabLabel = ({ count, text }: { count: any; text: any }) => (
    <div>
      {text}
      <span id="border-count">{count}</span>
    </div>
  );

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: <TabLabel count={countFalseRecord} text="Chờ phân công" />,
      children: (
        <div className="waiting-assignment">
          <TableData
            dataTable={listSurveyStudent}
            dataColumns={dataColumns as ColumnType<any>[]}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            searchValue={searchValue}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: <TabLabel count={countTrueRecord} text="Đã phân công" />,
      children: (
        <div className="assigned">
          <TableData
            dataTable={listSurveyStudent}
            dataColumns={dataColumns as ColumnType<any>[]}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            searchValue={searchValue}
          />
        </div>
      ),
    },
  ];

  return (
    <Layout className="course-plan-managment-assignment">
      {/* <Form.Provider onFormFinish={(name, { values, forms }) => {
        if (name === 'createUser') {
          const { createUser } = forms
          const data = createUser.getFieldsValue(validateFieldCreate)
          onFinishCreate(data)
        }
      }}> */}
      <div
        className={`course-plan-managment-assignment--layout ${showFilter ? "open-filter" : ""
          }`}
      >
        <div className="table-header--wrapper">
          <div className="table-header--left">
            <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
          </div>
          <FilterTable
            handleOpen={handleOpenCreate}
            searchValue={searchValue}
            dataColumns={dataColumns as ColumnType<any>[]}
            handleOpenFilter={handleOpenFilter}
            handleSubmitSearch={handleSubmitSearch}
            handleSearch={handleSearch}
            checkedList={checkedList}
            setCheckedList={setCheckedList}
            handleChangeInput={handleChangeInput}
            selectedRowKeys={selectedRowKeys}
            setRefreshData={setIsRefreshData}
            showFilter={showFilter}
          />
        </div>
        <FormFilter
          key={changeTabState}
          clearFilter={clearFilter}
          onFinish={handleFinish}
          showFilter={showFilter}
          tab={tab}
          listTeacher={listTeacher}
        />
      </div>

      <div className="tabs-heading">
        <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
      </div>
      {/* </Form.Provider> */}
    </Layout>
  );
};

export default CoursePlanningAssignment;
