import {IDataCreateUser} from "../../types/listUsers";
import {axiosConfig} from "../../config/api/configApi";
import {AxiosResponse} from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const createAccountMinistry: (data: IDataCreateUser) => Promise<AxiosResponse<any>> = (data: IDataCreateUser) => {
	return axiosConfig.post(`/accounts/cap-bo`, data)
}

export const updateAccountMinistry: (id: string | undefined,
  data: any
) => Promise<AxiosResponse<any>> = (id: string | undefined, data: any) => {
  return axiosConfigV2.put(`/mooc-ministerial/account/${id}`, data);
};

export const getDataMinistry: (search: any) => Promise<AxiosResponse<any>> = (search: any) => {
	return axiosConfig.post(`/accounts/cap-bo/search`, search);
}

export const getAllMinistryAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(
    `/mooc-ministerial/account/get-all-by-filter`, data
  );
};

export const changeStatusAccountMinistry: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(
    `/mooc-ministerial/account/change-status`,
    data
  );
};

export const getDetailMinistryAccount: (
  id: any
) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-ministerial/account/${id}`);
};

export const changePasswordMinistry: (data: any) => Promise<AxiosResponse<any>> = (
  data: any
) => {
  return axiosConfigV2.put(
    `/mooc-ministerial/account/change-password`,
    data
  );
};

export const deleteAccountMinistry: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-ministerial/account/${id}`);
};

export const getManagementAgencys: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/v2/open-api/mooc-portfolio/get-organization-by-name`);
};

export const getUserMinistry: (id: any) => Promise<AxiosResponse<any>> = (id: any) => {
  return axiosConfigV2.get(`/mooc-ministerial/user/get-all-by-organizationId/${id}`);
};

export const createMinistryAccount: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-ministerial/account`, data);
};

export const exportExcelDataMinistry: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-ministerial/account/export-excel`, data, {
    responseType: "arraybuffer",
  });
};
