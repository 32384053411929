import { Editor, Plugin } from "grapesjs";
import { LecturerBlockCustom } from "../../components/lecturer-block";
import ChooseLecturerTrait from "./choose-lecturer-trait";
import { createRoot } from "react-dom/client";
import { commonMedia } from "../../grapesjs-config";
import { nameLecturersBlock } from "../../grapesjs-ui";

export type PluginOptions = {
  courseId?: number;
  tab?: string;
};

const LecturerPlugin: Plugin<PluginOptions> | any = (editor: Editor, opts: PluginOptions = {}) => {
  const currentUrl = window.location.href;

  // Create a URL object
  const url = new URL(currentUrl);

  // Get the pathname from the URL
  const pathname = url.pathname;

  // Split the pathname into segments
  const segments = pathname.split("/");

  // Find the index of the segment that contains 'content-course-detail'
  const detailIndex = segments.indexOf("content-course-detail");

  // The courseId should be the next segment after 'content-course-detail'
  const courseId = segments[detailIndex + 1];

  const defaultOptions = {
    courseId: courseId,
  };

  const options = { ...defaultOptions, ...opts };
  editor.Components.addType("LecturerBlockCustom", {
    extend: "react-component",
    model: {
      defaults: {
        component: LecturerBlockCustom,
        stylable: true,
        resizable: true,
        editable: true,
        draggable: true,
        droppable: true,
        attributes: {
          editable: true,
        },
        traits: [
          {
            type: "choose-lecturer-type",
            label: "Giảng viên",
            name: "userid",
          },
        ],
      },
    },
    isComponent: (el) =>
      el.tagName === "LecturerBlockCustom".toUpperCase(),
  });

  editor.TraitManager.addType("choose-lecturer-type", {
    // Define how the trait will be displayed in the Traits panel
    createInput({ trait }) {
      const el = document.createElement("div");
      const root = createRoot(el);
      root.render(
        <ChooseLecturerTrait
          editor={editor}
          value={trait.getInitValue()}
          onChange={(value) => {
            trait.setTargetValue(value);
          }}
        />
      );
      return el;
    },
    // Define how the value is stored
    onEvent({ elInput, component }) {
      component.set({
        [elInput.getAttribute("name") + ""]: elInput.value,
      });
    },
  });

  editor.BlockManager.add("LecturerBlockCustom", {
    media: commonMedia,
    label: 'Giao diện',
    category: nameLecturersBlock,
    content: '<LecturerBlockCustom component-class="LecturerBlockCustom" courseid="' + options.courseId + '" userid="" tab="' + options.tab + '"></LecturerBlockCustom>',
  });
};
export default LecturerPlugin;
