import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const postMoocApprovalCourseSearch: (data: object) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfig.post(`/mooc-approval-course-registration/search`, data);
  };

export const postMoocApprovedCourseSearch: (data: object) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfig.post(`/mooc-approval-course-registration/search-approved`, data);
  };

export const getMoocApprovedCourseById: (id: number) => Promise<AxiosResponse<any>> = (id) => {
    return axiosConfig.get(`/mooc-approval-course-registration/get-by-id/${id}`);
  };
export const getMoocApprovedHistoryById: (Courseid: number, data: object) => Promise<AxiosResponse<any>> = (Courseid,data) => {
    return axiosConfig.get(`/mooc-approval-course-registration/get-history-by-id/${Courseid}`, data);
  };