import React, {useEffect, useMemo, useState, useTransition} from "react";
import OverviewChartTab from "../chart/overview-tab/OverviewChartTab";
import OverviewTable from "../table/overview-table";
import {BarStackedChartProps, DualChart, PieChart} from "../../../../../types/static-report";
import {debounce} from "lodash";
import {staticReportStore} from "../../../../../stores/stores";
import dayjs from "dayjs";
import {
  getListCourseByUniversitySponsor,
  getListStudentByIndustryGroup,
  getListSynthesizeDataUniversityOverview,
  getListTeacherByIndustryGroupOverview,
  getListUnitResources,
  getUniversityVisitors,
  getUniversityVisits,
  getVisitsAndVisitorsByRegisterUniversity,
  getVisitsAndVisitorsUniversity,
} from "../../../../../service/statistical-report";

type Props = {
  dataType: string;
  universityId?: number;
};

const OverviewTab = (props: Props) => {
  const {dataType, universityId} = props;
  const {staticReportType, filterValues, tab} = staticReportStore();
  const [isPending, startTransition] = useTransition();

  const [chartData, setChartData] = useState({
    visitsAndVisitorsUniversity: [] as DualChart[],
    visitsAndVisitorsByRegisterUniversity: [] as PieChart[],
    synthesizeUniversity: [] as Array<any>,
    universityVisits: [] as Array<any>,
    universityVisitors: [] as Array<any>,
    teacherByUniversity: [] as BarStackedChartProps[],
    studentByUniversity: [] as BarStackedChartProps[],
    courseByUniversitySponsor: [] as BarStackedChartProps[],
    unitResources: [] as Array<any>,
  });

  const [loading, setLoading] = useState({
    visitsAndVisitors: false,
    visitsAndVisitorsByRegister: false,
    teacherByUniversity: false,
    studentByUniversity: false,
    courseByUniversitySponsor: false,
    unitResources: false,
  });

  const fetchData = async () => {
    const payload = {
      timeUnit: filterValues.timeUnit,
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: filterValues?.universityIds,
    };
    try {
      const apiCallList = [
        {
          key: "synthesizeUniversity",
          api: getListSynthesizeDataUniversityOverview,
          params: payload,
        },
        {
          key: "universityVisits",
          api: getUniversityVisits,
          params: payload,
        },
        {
          key: "universityVisitors",
          api: getUniversityVisitors,
          params: payload,
        },
        {
          key: "visitsAndVisitorsUniversity",
          api: getVisitsAndVisitorsUniversity,
          params: payload,
        },
        {
          key: "visitsAndVisitorsByRegisterUniversity",
          api: getVisitsAndVisitorsByRegisterUniversity,
          params: payload,
        },
        {
          key: "teacherByUniversity",
          api: getListTeacherByIndustryGroupOverview,
          params: payload,
        },
        {
          key: "studentByUniversity",
          api: getListStudentByIndustryGroup,
          params: payload,
        },
        {
          key: "courseByUniversitySponsor",
          api: getListCourseByUniversitySponsor,
          params: payload,
        },
        {
          key: "unitResources",
          api: getListUnitResources,
          params: payload,
        },
      ]
      await Promise.allSettled(
        apiCallList.map(async ({key, api, params}) => {
          setLoading((prev) => ({...prev, [key]: true}));
          try {
            const response = await api(params);
            startTransition(() => {
              setChartData((prevState) => ({
                ...prevState,
                [key]: response?.data?.data?.statistic || response?.data?.data,
              }));
            })
          } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
            startTransition(() => {
              setChartData(prevState => ({
                ...prevState,
                [key]: [], // Provide fallback empty data
              }));
            });
          } finally {
            setLoading((prev) => ({...prev, [key]: false}));
          }
        })
      );
    } catch (err) {
      console.error(err)
    }
  }

  const debouncedFetchData = useMemo(
    () => debounce(fetchData, 300),
    [filterValues]
  );

  useEffect(() => {
    if (tab === "1" && universityId && universityId !== 0) {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  const memoizedChartData = useMemo(() => {
    return {
      visitsAndVisitors: chartData.visitsAndVisitorsUniversity,
      visitsAndVisitorsByRegister: chartData.visitsAndVisitorsByRegisterUniversity,
      synthesizeUniversityList: chartData.synthesizeUniversity,
      universityVisits: chartData.universityVisits,
      universityVisitors: chartData.universityVisitors,
      teacherByUniversity: chartData.teacherByUniversity,
      studentByUniversity: chartData.studentByUniversity,
      courseByUniversitySponsor: chartData.courseByUniversitySponsor,
      unitResources: chartData.unitResources,
    };
  }, [chartData]);

  return (
    (universityId && universityId !== 0)
      ?
      (dataType === "1") ?
        <OverviewChartTab {...memoizedChartData} loading={loading}/> :
        <OverviewTable {...memoizedChartData} loading={isPending}/>
      :

      <p style={{fontSize: 18, color: "#5f5c5c", textAlign: "center"}}>Không có dữ liệu</p>
  )
};

export default OverviewTab;
