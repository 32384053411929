import { useSurveyRespond } from "../../../../../../../../stores/stores";
import {
  ListQuestion,
  ListSurveySection,
} from "../../../../../../../../types/survey-respond";

const saveToLocalStorage = (key: string, value: any): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const updateStoreAndLocalStorage = (
  updateAnswerFunc: (
    surveyId: number,
    sectionId: number,
    questionId: number,
    answerValue: any
  ) => void,
  userId: number,
  questionType: number,
  surveyId: number,
  sectionId: number,
  questionId: number,
  answerValue: any
): void => {
  updateAnswerFunc(surveyId, sectionId, questionId, answerValue); // Cập nhật store
};
