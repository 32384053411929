import {
  Col,
  Layout,
  Row,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import moment from "moment";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PageHeader from "../../../../../components/page-header/PageHeader";
import TableData from "../../../../../components/table-data/TableData";
import { routesConfig } from "../../../../../config/routes";
import {
  getAppriseResultInformationByCourse,
  getListAppriseResultInformationByCourse,
  getProfessionalApproveResultByCourse,
} from "../../../../../service/course-browse/course-browse";
import { HistoryData } from "../../../../../types/assign-course-construction";
import "./index.scss";
import { objectToQueryString } from "../../../../../utils/getPageFromLink";

const { Content } = Layout;
const imgPath = "/images/";

const AppraisedHistoryApproval: FunctionComponent = () => {
  const location = useLocation();
  const { isCourseEndorse } = location.state;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [history, setHistory] = useState<HistoryData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [commonInfoCourse, setCommonInfoCourse] = useState<any>({});
  const params = useParams();

  const getHistoryCourseData = async () => {
    setLoading(true);
    const data = {
      courseId: params?.id ?? "",
      page: pageNumber,
      size: pageSize,
    };

    const apiUrl = isCourseEndorse
      ? getProfessionalApproveResultByCourse
      : getListAppriseResultInformationByCourse;

    await apiUrl(objectToQueryString(data))
      .then((res) => {
        const { content, totalElements } = res.data;
        setTotalRecords(totalElements)
        setHistory(content);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getHistoryBrowse = async () => {
    await getAppriseResultInformationByCourse(params?.id ?? "").then((res) => {
      setCommonInfoCourse(res.data);
    });
  };

  useEffect(() => {
    // setHistory(data.data)
    setLoading(false);
    getHistoryBrowse();
    getHistoryCourseData();
  }, [pageNumber]);

  const listBreadcrumb = [
    {
      title: "Xây dựng khoá học",
    },
    {
      title: (
        <a
          href={`/course-construction/${routesConfig.courseBrowse}/${routesConfig.courseApproval}`}
        >
          Phê duyệt khoá học
        </a>
      ),
    },
    {
      title: location.state.name,
    },
  ];

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      shouldCellUpdate(record, prevRecord) {
        return true;
      },
      render: (_: any, record, index) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên cán bộ",
      dataIndex: "appriseUserName",
      key: "appriseUserName",
      width: 40,
    },
    {
      title: "Thời gian",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 30,
      render: (_: any, record, index) => {
        return (
          <div>
            <div>{moment(record?.createdDate).format("HH:mm:ss")}</div>
            <div>{moment(record?.createdDate).format("DD/MM/yyyy")}</div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 20,
      render: (_: any, record) => {
        return (
          <>
            <span className="status-content">
              {record?.status === 0 ? (
                <div className="status">
                  <div className="dot color-wait" />
                  Chờ {isCourseEndorse ? "phê duyệt" : "thẩm định"}
                </div>
              ) : record?.status === 1 ? (
                <div className="status">
                  <div className="dot color-done" />
                  Đồng ý
                </div>
              ) : (
                <div className="status">
                  <div className="dot color-cancel" />
                  Không đồng ý
                </div>
              )}
            </span>
          </>
        );
      },
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      width: 20,
      render: (_: any, record) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: record?.description,
            }}
          />
        );
      },
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const newColumnsAsigned = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {

  };

  return (
    <>
      <Content className="appraised-history">
        <PageHeader
          subTitle="Lịch sử phê duyệt"
          title={location.state.nameCourse}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        <div className="table-wrapper-course">
          <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]} className="list-item--row">
            <Col
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 3 }}
              xs={{ span: 3 }}
              className="list-item--col"
            >
              <p>Tên khoá học</p>
              <Typography.Title level={5}>
                {commonInfoCourse?.name}
              </Typography.Title>
            </Col>
            <Col
              md={{ span: 34 }}
              lg={{ span: 3 }}
              xl={{ span: 3 }}
              xs={{ span: 3 }}
              className="list-item--col"
            >
              <p>Trường chủ trì</p>
              <Typography.Title level={5}>
                {commonInfoCourse?.sponsor}
              </Typography.Title>
            </Col>
            <Col
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 3 }}
              xs={{ span: 3 }}
              className="list-item--col"
            >
              <p>Trường phối hợp</p>
              <Typography.Title level={5}>
                {commonInfoCourse?.cooperation}
              </Typography.Title>
            </Col>
            <Col
              md={{ span: 3 }}
              lg={{ span: 3 }}
              xl={{ span: 3 }}
              xs={{ span: 3 }}
              className="list-item--col"
            >
              <p>Hội đồng thẩm định</p>
              <Typography.Title level={5}>
                {commonInfoCourse?.appriseGroupName}
              </Typography.Title>
            </Col>
          </Row>
        </div>
        <TableData
          dataTable={history}
          dataColumns={newColumnsAsigned}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          handleTableChangeProps={handleTableChange}
          hideSelection={false}
          pagination={true}
        />
      </Content>
    </>
  );
};

export default AppraisedHistoryApproval;
