import React, { useEffect, useState } from "react";
import "./detail.scss";
import {
  Input,
  Button,
  Space,
  Card,
  Dropdown,
  Modal,
  MenuProps,
} from "antd";

// import { C_FormInput } from "../../../../components";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import RightControl from "../right-control/RightControl";
import QuestionUI from "./question-ui/QuestionUI";

const DetailQuestion = () => {

  const [theme, setThemme] = useState<string>("1")
  const onFinish = (values: any) => {
    console.log("Form values:", values);
  };

  const options1: MenuProps["items"] = [
    {
      key: "1",
      label: "Tạo bản sao",
      icon: <CopyOutlined />,
    },
    {
      key: "2",
      label: "Chuyển xuống dưới",
      icon: <ArrowDownOutlined />,
    },
    {
      key: "3",
      label: "Xóa khảo sát",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const [items, setItems] = useState(["Item 1", "Item 2", "Item 3"]);

  const moveItemUp = (index: any) => {
    if (index === 0) return; // Already at the top
    const newItems = [...items];
    [newItems[index - 1], newItems[index]] = [
      newItems[index],
      newItems[index - 1],
    ];
    setItems(newItems);
  };

  const moveItemDown = (index: any) => {
    if (index === items.length - 1) return; // Already at the bottom
    const newItems = [...items];
    [newItems[index], newItems[index + 1]] = [
      newItems[index + 1],
      newItems[index],
    ];
    setItems(newItems);
  };

  return (
    <div>
      <QuestionUI listSectionQuestions={[]} surveyId={123}  />
      <RightControl theme={theme} />
      <br />
      <br />
      <br />

      {/* <div>
        <ul>
          {items.map((item, index) => (
            <li key={index}>
              <Card
                title={`Phần ${item}`}
                // headStyle={{backgroundColor:'#e6f7ff'}}
                styles={{ header: { backgroundColor: "#e6f7ff" }, body: {} }}
                className="wrapper-settings--card form-setting--common"
                extra={
                  <>
                    <Dropdown
                      menu={{
                        items: options1,
                        onClick: ({ key }) => {
                          if (key === "1") {
                          }
                          if (key === "2") {
                            Modal.confirm({
                              title: `Bạn có muốn tạo bản sao đợt khảo sát này`,
                              // onOk: () => copyData(record),
                              okText: "Lưu",
                              cancelText: "Huỷ",
                              centered: true,
                              icon: (
                                <CopyOutlined style={{ color: "#1677FF" }} />
                              ),
                              footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                  <CancelBtn />
                                  <OkBtn />
                                </>
                              ),
                            });
                          }
                          if (key === "3") {
                          }
                        },
                      }}
                      placement="bottomRight"
                    >
                      <Button>
                        <MoreOutlined />
                      </Button>
                    </Dropdown>
                  </>
                }
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <Input
                    className="input-header-title"
                    placeholder="Nhập tiêu đề"
                    style={{ fontSize: "20px", color: "black" }}
                  />
                  <C_FormInput placeholder="Nhập mô tả" />
                </Space>

                <button
                  onClick={() => moveItemUp(index)}
                  disabled={index === 0}
                >
                  <ArrowUpOutlined />
                </button>
                <button
                  onClick={() => moveItemDown(index)}
                  disabled={index === items.length - 1}
                >
                  <ArrowDownOutlined />
                </button>
              </Card>
            </li>
          ))}
        </ul>
      </div> */}

    </div>
  );
};

export default DetailQuestion;
