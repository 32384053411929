import {Spin} from "antd";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {ConstructCourseDetailType, Section} from "../../types/course-construction";
import ApprovedInformationTab from "../approved-information-tab/approvedInformationTab";
import ViewUnitDetails from "./components/view-unit-details/ViewUnitDetails";
import "./index.scss";
import {getCourseDetail, getSectionList} from "../../service/course-construct";
import CourseDetailView from "../course-construction/list-course-construction/course-constructor/CourseConstructView";

type Props = {
  setTab?: (value: string) => void;
  tab?: string;
  isHideApprovedInformationTab?: boolean;
  preview?: boolean
  detailDataProps?: any
  getDetailData?: any
};

interface Lesson {
  id: number;
  title: string;
  watched: boolean;
  videoUrl: string;
  moduleId: string;
  moduleTitle: string;
}

interface Module {
  assignedId: number;
  assignedName: string;
  children: any;
  id: number;
  key: string;
  name: string;
  orderNumber: null;
}

export default function CourseContentPage(props: Props) {
  const {setTab, tab, isHideApprovedInformationTab, preview, detailDataProps, getDetailData} = props;
  const [sections, setSections] = useState<Section[]>([]);
  const location = useLocation()
  const params = useParams();

  const [modules, setModules] = useState<any>([[]]);
  const [detailData, setDetailData] = useState<ConstructCourseDetailType>({});

  const [currentLesson, setCurrentLesson] = useState<any | null>();
  const [sectionDetail, setSectionDetail] = useState<ConstructCourseDetailType>(
    {}
  );
  const [sourceTreeSection, setSourceTreeSection] = useState<any>([]);
  const [currentSequence, setCurrentSequences] = useState<any>([]);

  const handleChangeSection = (data: Section[]) => {
    let newSections = [];
    newSections = [...sections];
    setSections([]);
    newSections = [...data];
    setSections(data);
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [menuKeys, setMenuKeys] = useState<any>({
    selectedKeys: [],
    openKeys: [],
  });

  const markAsWatched = (moduleId: string, lessonId: number) => {
    // setModules((prevModules) =>
    //   prevModules.map((module) =>
    //     module.id === moduleId
    //       ? {
    //           ...module,
    //           lessons: module.lessons.map((lesson) =>
    //             lesson.id === lessonId ? { ...lesson, watched: true } : lesson
    //           ),
    //         }
    //       : module
    //   )
    // );
  };

  const convertDataSection = (data: any) => {
    const arrSection = data.sections;
    const arrConvert = arrSection.map((item: any) => {
      const units = item.children?.map((itemChild: any) => {
        const unitsLastChildren = itemChild?.children?.map(
          (itemLastChild: any) => {
            return {
              id: itemLastChild.id,
              orderNumber: itemLastChild.orderNumber,
            };
          }
        );
        return {
          id: itemChild.id,
          orderNumber: itemChild.orderNumber,
          units: unitsLastChildren,
        };
      });

      return {
        id: item.id,
        orderNumber: item.orderNumber,
        sequences: units,
      };
    });
    setSourceTreeSection(arrConvert);
  };

  const selectLesson = (lesson: Lesson) => {
    setCurrentLesson(lesson);
  };

  const selectSequence = (sequence: any) => {
    setCurrentSequences(sequence.units);
  };

  const handleVideoEnd = () => {
    if (currentLesson) {
      markAsWatched(currentLesson.moduleId, currentLesson.id);
    }
  };

  const getDetailCourseData = async () => {
    setLoading(true);
    try {
      const response = await getCourseDetail(params?.id).then((res) => {
        const {data} = res.data;
        handleChangeSection(data?.sections);
        setTimeout(() => {
          setDetailData(data);
          convertDataSection(data);
          setLoading(false);
        });
      });
    } catch (error) {
    }
  };

  const handleGetDetail = () => {
    getDetailCourseData();
  };

  useEffect(() => {
    if (preview) {
      const getDetailCourseData = async () => {
        try {
          const response = await getSectionList(params?.id).then((res) => {
            const {data} = res;
            setSectionDetail(data);
            setMenuKeys({
              selectedKeys: [data?.sections?.[0].sequences[0].units[0].id],
              openKeys: [
                data?.sections?.[0].id,
                data?.sections?.[0]?.sequences?.[0]?.id,
              ],
            });
          });
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      if (params?.id) {
        getDetailCourseData();
      }
    } else {
      handleGetDetail()
    }
  }, [preview]);

  useEffect(() => {
    if (sectionDetail) {
      setCurrentLesson(sectionDetail?.sections?.[0].sequences[0].units[0]);
      // setListSequences(sectionDetail?.sections?.[0].sequences[0]);
      setModules(sectionDetail?.sections);
    }
  }, [sectionDetail]);

  if (loading) {
    return (
      <div>
        <Spin size="large"/>
      </div>
    );
  }
  return (
    <>
      <div className="w-full">
        <div className="course-browse-layout course-wrapper flex">
          {
            preview ?
              <div className="w-full">
                <div className="course-content">
                  {/* <ModuleList
                    modules={modules}
                    selectLesson={selectLesson}
                    defaultKeys={menuKeys}
                  /> */}
                  <ViewUnitDetails
                    lesson={currentLesson}
                    onVideoEnd={handleVideoEnd}
                    sectionDetail={sectionDetail}
                    modules={modules}
                    selectLesson={selectLesson}
                    selectSequence={selectSequence}
                    defaultKeys={menuKeys}
                    sequence={currentSequence}
                  />
                </div>
              </div> :
              <div className="wrapper-course-construct">
                <section className="detail-account detail-course-construct">
                  <div className="form-detail">
                    <section className="detail-account">
                      <div className="detail-course-construction">
                        <CourseDetailView detailData={detailData}/>
                      </div>
                    </section>
                  </div>
                </section>
              </div>
          }
          {!isHideApprovedInformationTab && location.state?.mode !== 'view' && (
            <ApprovedInformationTab setTab={setTab} tab={tab} getDetailDataProps={getDetailData}
                                    detailData={detailDataProps}/>
          )}
        </div>
      </div>
    </>
  );
}
