import {
  Button,
  Card,
  Flex,
  Form,
  RadioChangeEvent,
  Select,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import FormSelect from "../../../../components/form-select/FormSelect";
import SelectUiCard from "../../../../components/select-ui";
import {
  CloseOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import CommonModal from "../../../../components/modal/common";
import {
  createSettingIdentityDocument,
  deleteSettingIdentityDocument,
  getSettingIdentityDocument,
  updateSettingIdentityDocument,
} from "../../../../service/settting-identity";
import _ from "lodash";
import { getSettingAuthority } from "../../../../service/setting-authority";
import { DEFAULT_GROUP } from "../../../../constants/setting-identity";
const { Title, Text } = Typography;

const optionsDocument = [
  { value: "idcard", label: "Căn cước công dân" },
  { value: "passport", label: "Hộ Chiếu " },
];

interface IProps {
  displayRoleDocumentIdentify: boolean;
}
const DocumentIdentifyForm = ({ displayRoleDocumentIdentify }: IProps) => {
  const [documentSelect, setDocumentSelect] = useState<any>([]);
  const [showButton, setShowButton] = useState<number[]>([]);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const [optionSelectDoc, setOptionSelectDoc] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [remoteDoc, setRemoteDoc] = useState({
    isOpen: false,
    indexDoc: 0,
  });

  const handleShowButton = (index: number) => {
    if (!showButton.includes(index)) {
      setShowButton([...showButton, index]);
    }
  };

  const addDocumentFile = () => {
    setDocumentSelect([
      ...documentSelect,
      {
        document: {
          name: "doc",
          label: "Loại giấy tờ",
          value: "",
        },
        member: {
          name: "men",
          label: "Nhóm người dùng",
          value: [],
        },
      },
    ]);
  };

  const onChangeDocument = (val: string, name: string, index: number) => {
    setDocumentSelect((prevValues: any) => {
      const updatedValues = [...prevValues];
      updatedValues[index][name].value = val;
      return updatedValues;
    });
  };

  const handleSaveDocuments = async (index: number) => {
    const paramDocument = {
      type: documentSelect[index].document.value,
      permission_ids: documentSelect[index].member.value,
    };
    try {
      if (documentSelect[index]?.id) {
        const res = await updateSettingIdentityDocument(
          documentSelect[index]?.id,
          paramDocument
        );
        messageApi.open({
          type: "success",
          content: "Lưu loại giấy tờ tuỳ thân hợp lệ thành công",
        });
      } else {
        const res = await createSettingIdentityDocument(paramDocument);
        messageApi.open({
          type: "success",
          content: "Tạo loại giấy tờ tuỳ thân hợp lệ thành công",
        });
        setIsRefresh(!isRefresh);
      }
      setShowButton(showButton.filter((item) => item !== index));
    } catch (error: any) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: error?.message,
      });
    }
  };
  const onCloseModal = () => {
    setRemoteDoc({
      indexDoc: 0,
      isOpen: false,
    });
  };
  const handleOpenModal = (index: number) => {
    setRemoteDoc({
      indexDoc: index,
      isOpen: true,
    });
  };
  const handleConfirmDelete = async (indexToRemove: number) => {
    try {
      if (documentSelect[indexToRemove]?.id) {
        await deleteSettingIdentityDocument(documentSelect[indexToRemove]?.id);
        messageApi.open({
          type: "success",
          content: "Xóa loại giấy tờ tuỳ thân hợp lệ thành công",
        });
      }
      documentSelect.splice(indexToRemove, 1);
      setDocumentSelect([...documentSelect]);
      onCloseModal();
    } catch (error) {}
  };

  const validateMessages = {
    required: "${label} là bắt buộc!",
  };

  const fetchSettingAuthority = async () => {
    try {
      const response = await getSettingAuthority();
      const { data } = response.data;
      setOptionSelectDoc(
        data
          ?.filter(
            (value: { codename: string }) =>
              value.codename.toLowerCase() === "is_sv"
          )
          .map((option: any) => ({
            label: DEFAULT_GROUP[option?.codename],
            value: option.id,
          }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSettingIdentity = async () => {
    try {
      const response = await getSettingIdentityDocument();
      const { data } = response.data;
      setDocumentSelect(
        data.map((docs: any) => ({
          id: docs?.id,
          document: {
            name: "doc",
            label: "Loại giấy tờ",
            value: docs?.type,
          },
          member: {
            name: "men",
            label: "Nhóm người dùng",
            value: [
              ...docs?.mooc_setting_documents_permission?.map(
                (value: any) => value?.auth_permission?.id
              ),
            ] as any,
          },
        }))
      );
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchSettingAuthority();
    fetchSettingIdentity();
  }, [isRefresh]);

  return (
    <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto my-6">
      {contextHolder}

      <div className="border-b pb-3">
        <Title level={4}>Loại giấy tờ tuỳ thân hợp lệ</Title>
        <Text className="text-[#00000073]">
          Chọn loại giấy tờ tuỳ thân và nhóm người dùng tương ứng
        </Text>
      </div>
      <div className="overflow-auto max-h-[420px] px-4">
        {documentSelect?.map((docs: any, index: number) => (
          <Form
            onFinish={() => handleSaveDocuments(index)}
            validateMessages={validateMessages}
            className="border-b pb-3"
            key={index}
            initialValues={{
              [docs?.document?.name]: docs?.document?.value,
              [docs?.member?.name]: docs?.member?.value,
            }}
            onClick={() => handleShowButton(index)}
          >
            {index > 0 ? (
              <Flex justify="end" className="mt-2">
                <Button
                  onClick={() => handleOpenModal(index)}
                  disabled={!displayRoleDocumentIdentify}
                  className="border-white"
                >
                  <CloseOutlined />
                </Button>
              </Flex>
            ) : (
              ""
            )}

            <Flex className="mt-2" justify="space-between" align="center">
              <div className="w-full">
                <SelectUiCard
                  className="font-medium"
                  name={docs?.document?.name}
                  label={docs?.document?.label}
                  options={optionsDocument.filter(
                    (val) =>
                      !documentSelect.some(
                        (docs: any) => docs.document.value === val.value
                      )
                  )}
                  required
                  value={optionsDocument.filter(
                    (item) => item.value === docs?.document?.value
                  )}
                  onChange={(val) => onChangeDocument(val, "document", index)}
                />
              </div>
            </Flex>
            <Flex className="mt-2" justify="space-between" align="center">
              <div className="w-full">
                <SelectUiCard
                  className="font-medium"
                  name={docs?.member?.name}
                  label={docs?.member?.label}
                  options={optionSelectDoc}
                  mode="multiple"
                  required
                  allowClear
                  value={docs?.member?.value}
                  onChange={(val) => onChangeDocument(val, "member", index)}
                />
              </div>
            </Flex>
            {showButton?.includes(index) && (
              <Flex gap="small" justify="flex-end" className="pt-4">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!displayRoleDocumentIdentify}
                >
                  Lưu
                </Button>
              </Flex>
            )}
          </Form>
        ))}
      </div>
      {_.size(optionsDocument) <= _.size(documentSelect) ? (
        ""
      ) : (
        <Flex
          className="mt-2 cursor-pointer"
          align="center"
          onClick={addDocumentFile}
        >
          <PlusOutlined style={{ color: "#1677ff" }} />
          <Text className="pl-2 text-[#1677ff]">
            Thêm thông tin loại giấy tờ tuỳ thân
          </Text>
        </Flex>
      )}
      <CommonModal
        open={remoteDoc.isOpen}
        title={"Xoá thông tin loại giấy tờ tuỳ thân hợp lệ"}
        desc={
          <div>
            Thông tin đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá
            thông tin?
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={() => handleConfirmDelete(remoteDoc.indexDoc)}
        closeModal={onCloseModal}
        className={""}
      />
    </Card>
  );
};

export default DocumentIdentifyForm;
