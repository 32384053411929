// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-content {
  display: flex;
  min-height: calc(100vh - 200px);
  margin-top: 18px;
  background-color: #fff;
  border-radius: 6px;
  overflow: scroll; }
  .course-content .view-unit-play {
    flex: 1 1; }
  .course-content .lesson-list {
    flex: 1 1;
    padding: 20px; }
  .course-content .react-player {
    border-radius: 5px;
    overflow: hidden; }
  .course-content .wrapper-course-construct {
    padding: unset !important; }

.display-none {
  display: none; }

.wrapper-course-construct .detail-account.detail-course-construct .form-detail {
  width: 100%; }
  .wrapper-course-construct .detail-account.detail-course-construct .form-detail .construct-view-ctn {
    width: 60%; }
    .wrapper-course-construct .detail-account.detail-course-construct .form-detail .construct-view-ctn .card-info--wrapper {
      width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/course-content/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EAEb,+BAA+B;EAC/B,gBAAgB;EAEhB,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB,EAAA;EARlB;IAUI,SAAO,EAAA;EAVX;IAaI,SAAO;IACP,aAAa,EAAA;EAdjB;IAkBI,kBAAkB;IAClB,gBAAgB,EAAA;EAnBpB;IAsBI,yBAAyB,EAAA;;AAG7B;EACE,aAAa,EAAA;;AAGf;EAGM,WAAW,EAAA;EAHjB;IAMQ,UAAU,EAAA;IANlB;MAQU,WAAW,EAAA","sourcesContent":[".course-content {\n  display: flex;\n  // margin-right: 12px;\n  min-height: calc(100vh - 200px);\n  margin-top: 18px;\n  // padding: 18px 0px 18px 18px;\n  background-color: #fff;\n  border-radius: 6px;\n  overflow: scroll;\n  .view-unit-play {\n    flex: 1;\n  }\n  .lesson-list {\n    flex: 1;\n    padding: 20px;\n  }\n\n  .react-player {\n    border-radius: 5px;\n    overflow: hidden;\n  }\n  .wrapper-course-construct {\n    padding: unset !important;\n  }\n}\n.display-none {\n  display: none;\n}\n\n.wrapper-course-construct {\n  .detail-account.detail-course-construct {\n    .form-detail {\n      width: 100%;\n\n      .construct-view-ctn {\n        width: 60%;\n        .card-info--wrapper {\n          width: 100%;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
