import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getTemplateUniversity: (
  id: string,
  type: string
) => Promise<AxiosResponse<any>> = (id: string, type: string) => {
  return axiosConfigV2.get(`/university-template/get-template/${id}/${type}`);
};

export const postSaveComponentUniversity: (
  data: any,
  id: string,
  type: string
) => Promise<AxiosResponse<any>> = (data, id, type) => {
  return axiosConfigV2.post(
    `/university-template/save-component/${id}/${type}`,
    data
  );
};

export const postTemplateByUserUniversity: (
  data: any
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/university-template/get-template-by-user`, data);
};

export const putUpdateTemplateUniversity: (
  data: any,
  id: string,
  type: string
) => Promise<AxiosResponse<any>> = (data, id, type) => {
  return axiosConfigV2.put(
    `/university-template/update-template/${id}/${type}`,
    data
  );
};
