import React, { useEffect, useMemo, useRef, useState } from "react";
import "./detailSurveySection.scss";
import {
  Input,
  Button,
  Space,
  Card,
  Dropdown,
  Modal,
  MenuProps,
  Row,
  Col,
  Form,
  Typography,
  Empty,
} from "antd";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ClearOutlined,
  CopyOutlined,
  DeleteOutlined,
  FilterOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import { useForm } from "antd/es/form/Form";
import SectionQuestionFilter from "../../../survey-construction/detail-survey/component/detail-question/section-question-filter/SectionQuestionFilter";
import { MoocSurveyContentQuestion } from "../../../../../types/survey-managment/mooc-survey-content";
import { useParams } from "react-router-dom";
import EditorQuill from "../../../survey-construction/detail-survey/component/editor-quill/EditorQuill";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { useSurveyDetail, useSurveyInfo } from "../../../../../stores/stores";
import {
  ListQuestion,
  ListSurveySection,
  SurveyInfo,
} from "../../../../../types/survey-managment/survey";
import FormItemInput from "../../../../../components/form-input/FormInput";
import RightControl from "../right-control/RightControl";
import DetailQuestion from "../detail-question/DetailQuestion";
import EditorHtml from "../../../survey-construction/detail-survey/component/editor-quill/EditorHtml";

// const { TextArea } = Input;

interface DetailSectionProps {
  dataSection: ListSurveySection[]; // Assuming listSurveySection[] is the correct type
  setUpdateDataSection: (data: ListSurveySection[]) => void;
  setDataSection: (data: ListSurveySection[]) => void;
  surveyInfo?: SurveyInfo;
}

interface questionType {
  value: number;
  label: string;
}

const dataRequired = [
  {
    label: "Bắt buộc",
    value: true,
  },
  {
    label: "Tuỳ chọn",
    value: false,
  },
];

const optionsQuestionType: questionType[] = [
  {
    value: 1,
    label: "Nhiều lựa chọn văn bản",
  },
  {
    value: 2,
    label: "Nhiều lựa chọn ảnh",
  },
  {
    value: 3,
    label: "Lựa chọn đúng sai",
  },
  {
    value: 4,
    label: "Lựa chọn Dropdown",
  },
  {
    value: 5,
    label: "Nhiều trả lời văn bản",
  },
  {
    value: 6,
    label: "Nhiều trả lời ảnh",
  },
  {
    value: 7,
    label: "Câu hỏi kết nối",
  },
  {
    value: 8,
    label: "Câu trả lời ngắn",
  },
];

const options1: MenuProps["items"] = [
  {
    key: "1",
    label: "Tạo bản sao",
    icon: <CopyOutlined />,
  },
  {
    key: "2",
    label: "Chuyển lên trên",
    icon: <ArrowUpOutlined />,
  },
  {
    key: "3",
    label: "Chuyển xuống dưới",
    icon: <ArrowDownOutlined />,
  },
  {
    key: "4",
    label: "Xóa",
    icon: <DeleteOutlined />,
    className: "dropdown-delete",
    danger: true,
  },
];

const DetailSurveySection: React.FC<DetailSectionProps> = ({
  dataSection,
  setUpdateDataSection,
  setDataSection,
  surveyInfo,
}) => {
  const {
    listSurveySections,
    listSurveyFilterSection,
    setListSurveyFilterSection,
  } = useSurveyDetail((state) => {
    return {
      listSurveySections: state.listSurveySections,
      listSurveyFilterSection: state.listSurveyFilterSection,
      setListSurveyFilterSection: state.setListSurveyFilterSection,
    };
  });

  const updateSurveyDescription = useSurveyInfo(
    (state) => state?.updateSurveyDescription
  );

  const [isShowSurveyDescription, setIsShowSurveyDescription] = useState(false);
  const [surveyDescription, setSurveyDescription] = useState<string>(
    surveyInfo?.surveyDescription || ""
  );

  const [listQuestionAddMore, setListQuestionAddMore] = useState<
    MoocSurveyContentQuestion[]
  >([]);
  const listData = useMemo(() => dataSection, [dataSection]);

  // const moveItemUp = (index: number) => {
  //     if (index === 0) return; // Already at the top
  //     let newListSurveySections = [...listData];
  //     // Di chuyển phần tử lên trên
  //     [newListSurveySections[index - 1], newListSurveySections[index]] = [
  //         newListSurveySections[index],
  //         newListSurveySections[index - 1],
  //     ];
  //     // Cập nhật lại orderNumber
  //     newListSurveySections[index - 1].orderNumber = index; // Cập nhật orderNumber của phần tử mới
  //     newListSurveySections[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển

  //     setUpdateDataSection(newListSurveySections); //cập nhật lên page DetailSurvey
  //     setDescriptionList(newListSurveySections.map((item) => item.sectionDescription || ""));
  // };

  // const moveItemDown = (index: number) => {
  //     if (index === listData.length - 1) return; // Already at the bottom
  //     let newListSurveySections = [...listData];
  //     [newListSurveySections[index], newListSurveySections[index + 1]] = [
  //         newListSurveySections[index + 1],
  //         newListSurveySections[index],
  //     ];

  //     // Cập nhật lại orderNumber
  //     newListSurveySections[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển
  //     newListSurveySections[index + 1].orderNumber = index + 2; // Cập nhật orderNumber của phần tử mới

  //     setUpdateDataSection(newListSurveySections); //cập nhật lên page DetailSurvey
  //     setDescriptionList(newListSurveySections.map((item) => item.sectionDescription || ""));
  // };

  const [optionDropDown, setIsOption] = useState(options1);

  // const handleOptionDropDown = (index: number) => {
  //     let newOptions = [...options1];

  //     if (listData.length < 2) {
  //         newOptions.splice(1, 2);
  //     }
  //     if (listData.length > 1) {
  //         if (index === 0) {
  //             newOptions.splice(1, 1);
  //         }
  //         if (index === listData.length - 1) {
  //             newOptions.splice(2, 1);
  //         }
  //     }
  //     setIsOption(newOptions);
  // };

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isFilterQuestion, setIsFilterQuestion] = useState<boolean>(false);

  const handleShowFilterQuestion = () => {
    setIsShowFilter(!isShowFilter);
  };
  const [form] = useForm();

  const handleResetFieldFilter = () => {
    setIsFilterQuestion(false);
    form.resetFields();
    setListSurveyFilterSection([]);
  };

  function filterSectionsAndQuestions(sections: any, criteria: any) {
    const isRequiredCheck = Array.isArray(criteria.isRequired)
      ? criteria.isRequired
      : null;
    const questionName =
      typeof criteria.questionName === "string"
        ? criteria.questionName.toLowerCase()
        : "";
    const questionType = Array.isArray(criteria.questionType)
      ? criteria.questionType
      : null;

    return sections.reduce((filteredSections: any, section: any) => {
      const filteredQuestions = section.moocSurveyQuestion.filter(
        (question: any) => {
          const matchesRequired =
            isRequiredCheck === null ||
            isRequiredCheck.includes(question.isRequired);
          const matchesName = question.questionName
            .toLowerCase()
            .includes(questionName);
          const matchesType =
            questionType === null ||
            questionType.includes(question.questionType);

          return matchesRequired && matchesName && matchesType;
        }
      );

      if (filteredQuestions.length > 0) {
        filteredSections.push({
          ...section,
          moocSurveyQuestion: filteredQuestions,
        });
      }

      return filteredSections;
    }, []);
  }

  const onFinish = async (values: any) => {
    setIsFilterQuestion(true);
    if (values.questionType && values.questionType.length > 0) {
      const questionTypeNumbers = values.questionType.map(
        (item: string) => Number(item) + 1
      );
      values.questionType = questionTypeNumbers;
    }
    if (values && values.isRequired && values.isRequired.length === 0) {
      values.isRequired = undefined;
    }
    if (values && values.questionType && values.questionType.length === 0) {
      values.questionType = undefined;
    }
    if (values && values.questionName && values.questionName.length === 0) {
      values.questionName = undefined;
    }
    if (
      values.isRequired === null &&
      values.questionType === null &&
      values.questionName === ""
    ) {
      setListSurveyFilterSection([]);
    }
    if (listSurveySections && listSurveySections.length > 0) {
      const newList = filterSectionsAndQuestions(listSurveySections, values);
      setListSurveyFilterSection([]);
      if (newList.length > 0) {
        setListSurveyFilterSection(newList);
      } else {
        setListSurveyFilterSection([]);
      }
    }
  };

  // const handleCopySection = (sectionNumber: number) => {
  //     let newSection: ListSurveySection = {
  //         ...listData[sectionNumber],
  //         id: undefined,
  //     };
  //     let updatedListData = [...listData];
  //     updatedListData.splice(sectionNumber, 0, newSection);

  //     // Cập nhật lại orderNumber cho các phần tử sau vị trí được copy
  //     for (let i = sectionNumber; i < updatedListData.length; i++) {
  //         updatedListData[i].orderNumber = i + 1;
  //     }
  //     setUpdateDataSection(updatedListData);
  //     setDescriptionList(updatedListData.map((item) => item.sectionDescription || ""));
  // };

  // const handleDeleteSection = (sectionNumber: number) => {
  //     let updatedListData: ListSurveySection[] = listData.map((item, index) => {
  //         if (index === sectionNumber) {
  //             return {
  //                 ...item,
  //                 isDeleted: true,
  //             };
  //         }
  //         return item;
  //     });

  //     // Cập nhật lại orderNumber cho các phần tử sau vị trí bị xóa
  //     for (let i = sectionNumber + 1; i < updatedListData.length; i++) {
  //         updatedListData[i].orderNumber = i;
  //     }
  //     setUpdateDataSection(updatedListData);
  //     setDescriptionList(updatedListData.map((item) => item.sectionDescription || ""));
  // };

  const [indexQuestionPosition, setIndexQuestionPosition] = useState(0);
  const [indexSectionPosition, setIndexSectionPosition] = useState(0);
  const [isCheckQuestionPosition, setIsCheckQuestionPosition] = useState(false);

  // const checkMiddleElement = () => {
  //     listData.forEach((item, index) => {
  //         const element = document.getElementById(`sectionElement-${index}`);
  //         if (element) {
  //             const rect = element.getBoundingClientRect();
  //             const elementTop = rect.top;
  //             const elementBottom = rect.bottom;
  //             const elementHeight = rect.height;

  //             // Calculate the vertical position of the horizontal line
  //             const horizontalLinePosition = (window.innerHeight / 3) * 2.2;

  //             // Check if the horizontal line passes through the element
  //             if (elementTop <= horizontalLinePosition && horizontalLinePosition <= elementBottom) {
  //                 setIndexSectionPosition(index);
  //             }
  //         }
  //     });
  // };

  // const handleAddNewSection = (sectionNumber: number) => {
  //     checkMiddleElement();

  //     const newSection: ListSurveySection = {
  //         sectionName: `Phần mới`,
  //         isDeleted: false,
  //         orderNumber: indexSectionPosition + 1,
  //         moocSurveyQuestion: [
  //             {
  //                 questionName: `Câu hỏi mới`,
  //                 questionType: 1,
  //                 orderNumber: 1,
  //                 isDeleted: false,
  //                 isRequired: false,
  //                 moocSurveyQuestionAnswer: [],
  //             },
  //         ],
  //     };
  //     const updatedListData = [...listData];
  //     updatedListData.splice(indexSectionPosition + 1, 0, newSection);
  //     for (let i = indexSectionPosition; i < updatedListData.length; i++) {
  //         updatedListData[i].orderNumber = i + 1;
  //     }
  //     setUpdateDataSection(updatedListData);
  //     setDescriptionList(updatedListData.map((item) => item.sectionDescription || ""));
  // };

  // const handleAddNewQuestion = (sectionNumber: number) => {
  //     setIsCheckQuestionPosition(!isCheckQuestionPosition);
  //     checkMiddleElement();

  //     const newQuestion: ListQuestion = {
  //         questionName: `Câu hỏi mới`,
  //         questionType: 1,
  //         isDeleted: false,
  //         isRequired: false,
  //         orderNumber: 1,
  //         moocSurveyQuestionAnswer: [],
  //     };

  //     const updatedListData = [...listData];
  //     // Chèn câu hỏi vào vị trí indexQuestionPosition
  //     updatedListData[indexSectionPosition].moocSurveyQuestion.splice(
  //         indexQuestionPosition + 1,
  //         0,
  //         newQuestion
  //     );

  //     // Cập nhật lại orderNumber cho các câu hỏi sau
  //     updatedListData[indexSectionPosition].moocSurveyQuestion.forEach((question, index) => {
  //         question.orderNumber = index + 1;
  //     });

  //     setUpdateDataSection(updatedListData);
  //     setIsCheckQuestionPosition(!isCheckQuestionPosition);
  //     setDescriptionList(updatedListData.map((item) => item.sectionDescription || ""));
  // };

  const [isShowDetailSection, setIsShowDetailSection] = useState(
    new Array(listData.length).fill(true)
  );

  const [isShowDescriptionList, setIsShowDescriptionList] = useState(
    new Array(listData.length).fill(false)
  );

  const [descriptionList, setDescriptionList] = useState(
    listData.map((item) => item.sectionDescription || "")
  );

  // const handleUpdateSectionName = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
  //     const newValue = e.target.value;
  //     const updatedListData = listData.map((item, idx) => {
  //         if (idx === index) {
  //             return { ...item, sectionName: newValue };
  //         }
  //         return item;
  //     });
  //     setUpdateDataSection(updatedListData);
  //     setDescriptionList(updatedListData.map((item) => item.sectionDescription || ""));
  // };

  const param = useParams();
  const handleUpdateSurveyDescription = async () => {
    console.log({ surveyDescription });
    updateSurveyDescription(Number(param.id), surveyDescription);
  };

  return (
    <div className="survey-managment-section-publish">
      <div>
        {isShowFilter && (
          <div style={{ marginBottom: "20px" }}>
            <Form name="filterForm" onFinish={onFinish} form={form}>
              <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="questionName">
                    <FormItemInput placeholder="Nhập tên câu hỏi" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="questionType">
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Loại câu hỏi"
                      options={optionsQuestionType}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <Form.Item name="isRequired">
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Bắt buộc, tuỳ chọn"
                      options={dataRequired}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                  <div className="group-btn--filter">
                    <Button
                      className="btn btn-primary filter-btn"
                      htmlType="submit"
                    >
                      <FilterOutlined />
                      <Typography.Text>Lọc</Typography.Text>
                    </Button>
                    <Button
                      className="btn btn-outlined clear-filter-btn"
                      onClick={handleResetFieldFilter}
                    >
                      <ClearOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        )}

        {isFilterQuestion ? (
          <div style={{ marginBottom: "20px" }}>
            {listSurveyFilterSection && listSurveyFilterSection.length > 0 ? (
              <SectionQuestionFilter
                setUpdateDataSection={setUpdateDataSection}
              />
            ) : (
              <Card
                className="wrapper-settings--card form-setting--common "
                style={{ marginBottom: "20px" }}
              >
                <Empty
                  description={`Không tìm thấy kết quả với dữ liệu lọc như trên`}
                />
              </Card>
            )}
          </div>
        ) : (
          <div style={{ marginBottom: "200px" }}>
            <div
              style={
                isShowSurveyDescription
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Card
                title="Mô tả đợt khảo sát"
                styles={{
                  header: { backgroundColor: "#e6f7ff" },
                }}
                className="wrapper-settings--card form-setting--common"
                extra={
                  <>
                    <Row></Row>
                  </>
                }
              >
                <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex", marginTop: "35px" }}
                >
                  <EditorQuill
                    valueInitial={surveyDescription}
                    handleOnChange={setSurveyDescription}
                    handleOnBlur={handleUpdateSurveyDescription}
                    sizeContainer={200}
                  />
                </Space>
              </Card>
            </div>

            {listData.map((item, index) => {
              const {
                sectionName,
                sectionDescription,
                moocSurveyQuestion,
              }: ListSurveySection = item;

              const updateNewListSectionQuestions = (
                newList: ListQuestion[]
              ) => {
                const updatedListSurveySections = listData.map(
                  (section, position) => {
                    if (position === index) {
                      return {
                        ...section,
                        moocSurveyQuestion: newList,
                      };
                    }
                    return section;
                  }
                );
                setUpdateDataSection(updatedListSurveySections);
                setDescriptionList(
                  updatedListSurveySections.map(
                    (item) => item.sectionDescription || ""
                  )
                );
              };

              const shortSectionName = sectionName
                ? sectionName.length > 50
                  ? sectionName?.slice(0, 50)
                  : sectionName
                : "";

              // const updateDescription = (newDescription: string) => {
              //     const updatedListData = listData.map((item, idx) => {
              //         if (idx === index) {
              //             return { ...item, sectionDescription: newDescription };
              //         }
              //         return item;
              //     });
              //     setUpdateDataSection(updatedListData);
              //     setDescriptionList(
              //         updatedListData.map((item) => item.sectionDescription || "")
              //     );
              // };

              // const handleToggleDescription = (index: number) => {
              //     if (listData.length > 1) {
              //         const newIsShowDescriptionList = [...isShowDescriptionList];
              //         newIsShowDescriptionList[index] = !newIsShowDescriptionList[index];
              //         setIsShowDescriptionList(newIsShowDescriptionList);
              //     } else {
              //         setIsShowDetailSection([true]);
              //     }
              // };

              const handleToggleShowDetailSection = (index: number) => {
                if (listData.length > 1) {
                  const newIsShowDetailSection = [...isShowDetailSection];
                  newIsShowDetailSection[index] =
                    !newIsShowDetailSection[index];
                  setIsShowDetailSection(newIsShowDetailSection);
                } else {
                  setIsShowDetailSection([true]);
                }
              };

              return (
                <div key={index} id={`sectionElement-${index}`}>
                  <div
                    style={
                      listData.length === 1
                        ? { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <Card
                      title={
                        listData.length < 2
                          ? ``
                          : `Phần ${index + 1}/${
                              listData.length
                            } : ${shortSectionName} . . .`
                      }
                      styles={{
                        header: { backgroundColor: "#e6f7ff" },
                        body: {
                          display: isShowDetailSection ? "block" : "none",
                        },
                      }}
                      className="wrapper-settings--card form-setting--common"
                      extra={
                        <>
                          <Row>
                            <Space>
                              <Col>
                                <Button
                                  onClick={() =>
                                    handleToggleShowDetailSection(index)
                                  }
                                >
                                  {isShowDetailSection[index]
                                    ? `Thu gọn`
                                    : `Hiển thị chi tiết`}
                                </Button>
                              </Col>
                            </Space>
                          </Row>
                        </>
                      }
                    >
                      <Space
                        direction="vertical"
                        size="middle"
                        style={{ display: "flex" }}
                      >
                        <Input
                          placeholder="Nhập tiêu đề"
                          style={{ fontSize: "20px", color: "black" }}
                          // style={isShowDetailSection[index]?({display:'block'}):({display:'none'})}
                          value={sectionName}
                          // onChange={(e) => handleUpdateSectionName(e, index)}
                          disabled
                        />

                        {sectionDescription === "" || !sectionDescription ? (
                          <>
                            {isShowDescriptionList[index] &&
                            isShowDetailSection[index] ? (
                              <EditorQuill
                                valueInitial={descriptionList[index]}
                                // handleOnChange={updateDescription}
                              />
                            ) : (
                              isShowDetailSection[index] && (
                                <Input
                                  placeholder="Nhập mô tả"
                                  // onFocus={() =>
                                  //     handleToggleDescription(index)
                                  // }
                                  disabled
                                />
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {isShowDetailSection[index] && (
                              <EditorQuill
                                valueInitial={descriptionList[index]}
                                // handleOnChange={updateDescription}
                              />
                            )}
                          </>
                        )}
                      </Space>
                    </Card>
                  </div>

                  <DetailQuestion
                    needCheckQuestionPosition={isCheckQuestionPosition}
                    checkIndexQuestionPosition={setIndexQuestionPosition}
                    listSectionQuestions={moocSurveyQuestion}
                    sectionId={Number(item.id)}
                    updateListSectionQuestions={updateNewListSectionQuestions}
                    showDetailSection={isShowDetailSection[index]}
                  />
                </div>
              );
            })}
          </div>
        )}

        <RightControl handleShowFilterQuestion={handleShowFilterQuestion} />
      </div>
    </div>
  );
};

export default DetailSurveySection;
