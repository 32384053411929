import {
  DownOutlined,
  UpOutlined,
  PlusOutlined,
  SettingOutlined,
  FilterOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  Form,
  DatePicker,
  FormInstance,
} from "antd";
import React, { ChangeEvent, FunctionComponent, useState, KeyboardEvent, useEffect } from "react";
import PageHeader from "../../../../../components/page-header/PageHeader";
import FormItemInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { FilterData } from "../../../../../types/course-construction";

interface ApprovalItem {
  id: number;
  name: string;
}
type PageHeaderType = {
  handleOpen: () => void;
  title?: string | null;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  filterData: FilterData;
  setFilterData: (dateString: string[], planCreationDate: string) => void;
  handleSubmitSearch: () => void;
  clearFilter: () => void;
  form: FormInstance;
  handleSearchEnter: (e: KeyboardEvent<HTMLSpanElement>) => void,
  approvalConfiguration: ApprovalItem[];
};
const FilterSurvey: FunctionComponent<PageHeaderType> = ({
  handleOpen,
  title,
  searchValue,
  handleChangeInput,
  filterData,
  setFilterData,
  handleSubmitSearch,
  clearFilter,
  form,
  handleSearchEnter,
  approvalConfiguration,
}) => {
  const { RangePicker } = DatePicker;
  const [showFilter, setShowFilter] = useState(false);
  const [dataOptions, setDataOptions] = useState({
    dataStatus: [
      {
        id: 1,
        name: "Tự triển khai",
      },
      {
        id: 2,
        name: "Hợp tác xây dựng",
      },
      {
        id: 3,
        name: "Dùng chung",
      },
    ],
    dataTypes: [{}],
  });
  useEffect(() => {
    setDataOptions((prevDataOptions) => ({
      ...prevDataOptions,
      dataTypes: [
        {
          id: 1,
          name: "Lưu bản nháp",
        },
        ...approvalConfiguration.map((item) => ({
          id: item.id,
          name: item.name,
        })),
        {
          id: 5,
          name: "Đã xuất bản",
        },
        {
          id: 6,
          name: "Kết thúc",
        },
        {
          id: 7,
          name: "Đã thu hồi",
        },
      ],
    }));
  }, [approvalConfiguration]);
  const openFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <Layout className="page-header-group">
      <div
        className={`page-header-group--layout ${showFilter ? "open-filter" : ""
          }`}
      >
        <div className="table-header--wrapper">
          <div className="table-header--left">
            <PageHeader title={title} />
          </div>
          <div className="heading-extra">
            <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
              <Col
                md={{ span: 5 }}
                lg={{ span: 5 }}
                xl={{ span: 5 }}
                xs={{ span: 7 }}
              >
                <div className="heading-filter">
                  <Button className="filter-button" onClick={openFilter}>
                    <Typography.Text>Bộ lọc</Typography.Text>
                    {showFilter ? (

                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )}
                  </Button>
                </div>
              </Col>
              <Col
                md={{ span: 10 }}
                lg={{ span: 11 }}
                xl={{ span: 11 }}
                xs={{ span: 17 }}
              >
                <div className="heading-search">
                  <FormItemInput
                    placeholder="Nhập từ khoá cần tìm"
                    value={searchValue}
                    onChange={handleChangeInput}
                    onKeyPress={handleSearchEnter}
                    afterPrefixIcon={
                      <SearchOutlined onClick={handleSubmitSearch} />
                    }
                  />
                </div>
              </Col>
              <Col
                md={{ span: 9 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xs={{ span: 21 }}
              >
                <div className="heading-resigter">
                  <Button className="btn btn-primary" onClick={handleOpen}>
                    <PlusOutlined />
                    <Typography.Text>Thêm khảo sát</Typography.Text>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
          <Form form={form} name="filterForm">
            <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
              <Col
                xl={{ span: 4 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="createDate" className="">
                  <RangePicker
                    format="DD-MM-YYYY"
                    placeholder={["Ngày tạo", "Ngày tạo"]}
                    value={filterData.createDate}
                    onChange={(_value, dateString: any) => {
                      const convertedDates = dateString.map((date: String) => {
                        const [day, month, year] = date.split("-");
                        return `${year}-${month}-${day}`;
                      });
                      setFilterData(convertedDates, "createDate");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="publicDate" className="">
                  <RangePicker
                    placeholder={["Ngày xuất bản", "Ngày xuất bản"]}
                    format="DD-MM-YYYY"
                    value={filterData.publicDate}
                    onChange={(_value, dateString: any) => {
                      const convertedDates = dateString.map((date: String) => {
                        const [day, month, year] = date.split("-");
                        return `${year}-${month}-${day}`;
                      });
                      setFilterData(convertedDates, "publicDate");
                    }}
                  />
                  {/* <DatePicker placeholder='Ngày xuất bản'/> */}
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 5 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="status">
                  <FormItemDropdown
                    placeholder="Trạng thái"
                    modeDropdown="multiple"
                    value={filterData.status}
                    options={dataOptions.dataTypes}
                    onChange={(option) => {
                      setFilterData(option, "status");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 5 }}
                md={{ span: 5 }}
                sm={{ span: 5 }}
                xs={{ span: 24 }}
              >
                <Form.Item name="type">
                  <FormItemDropdown
                    placeholder="Loại khoá học"
                    modeDropdown="multiple"
                    value={filterData.courseType}
                    options={dataOptions.dataStatus}
                    onChange={(option) => {
                      setFilterData(option, "courseType");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 6 }}
                md={{ span: 9 }}
                sm={{ span: 9 }}
                xs={{ span: 24 }}
              >
                <div className="group-btn--filter">
                  <Button
                    className="btn btn-primary filter-btn"
                    // htmlType="submit"
                    onClick={handleSubmitSearch}
                  // onClick={() => form.submit()}
                  >
                    <FilterOutlined />
                    <Typography.Text>Lọc</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-outlined clear-filter-btn"
                    onClick={clearFilter}
                  >
                    <DeleteOutlined />
                    <Typography.Text>Xóa tất cả</Typography.Text>
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default FilterSurvey;
