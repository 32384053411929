import { Button, Col, Form, Layout, message, Modal, Row, Spin, Tabs, Tag, Typography } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import "./lecturer-management-detail.scss"
import { exportExcelMoocLecturerDetail, getMoocLecturerById } from "../../../../service/category-management/lecturer-management";
import { LoadingOutlined } from "@ant-design/icons";
import { TabsProps } from "antd/lib";
import LecturerDetail from "../../../../components/category-management/management-detail/lecturer-info/lecturer-detail/lecturerDetail";
import LecturerHistory from "../../../../components/category-management/management-detail/lecturer-info/lecturer-history/lecturerHistory";

const { Content } = Layout;
const imgPath = "/images/";

const LecturerManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
    const [lecturerDetail, setLecturerDetail] = useState<any>();
    const [isRendering, setIsRendering] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>("1");
    const idLecturer = location.state.record.id;
    const listBreadcrumb = [
        {
            title: "Quản lý danh mục",
        },
        {
            title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.lecturerManagement}`}>
                <span>Quản lý giảng viên</span>
            </SafeLink>,
        },
        {
            title: <span className="style-title">{location.state.name}</span> ,
        },
    ];

    // Get detail lecturer
    const getLecturerUpdate = async (lecturerId: number) => {
        setLoadingDetail(true);
        try {
            await getMoocLecturerById(lecturerId).then((res) => {
                if (res.status === 200) {
                    const { data } = res.data;
                    setLecturerDetail(data);
                    setTimeout(() => {
                        setLoadingDetail(false);
                    }, 333);
                }
            })
        } catch (err) {
            setLoadingDetail(false);
            console.error(err);
        }
    };

    // Chức vụ
    const regency = location.state.record?.authPositions.map((item: any) => {
        return (item.name);
    })

    // Trình độ học vấn
    const academicLevel = lecturerDetail?.moocTeacherProfiles.map((item: any) => {
        return (item.levelName);
    });

    // Chuyên ngành
    const specialized = lecturerDetail?.industryGroupInfo?.children.map((item: any) => {
        return (item.name);
    });

    useEffect(() => {
        getLecturerUpdate(location.state.record.id);
    }, [location.state.record])

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "Thông tin chi tiết giảng viên",
            children: (
                <LecturerDetail
                    lecturerDetail={lecturerDetail}
                    specialized={specialized}
                    academicLevel={academicLevel}
                    regency={regency}
                    loadingDetail={loadingDetail}
                />
            )
        },
        {
            key: "2",
            label: "Lịch sử thao tác",
            children: (
                <LecturerHistory idLecturer={idLecturer} />
            )
        },
    ]
    const handleExportExcel = () => {
        Modal.confirm({
            title: `Xác nhận kết xuất danh sách giảng viên`,
            content: ``,
            onOk: () => handleExportLecturerDetail(),
            centered: true,
            okText: "Xác nhận",
            cancelText: "Huỷ bỏ",
            footer: (_, { OkBtn, CancelBtn }) => (
                <>
                    <CancelBtn />
                    <OkBtn />
                </>
            ),
        });
    }
    const handleExportLecturerDetail = async () => {
        const idLecturer = location.state.record.id;
        const nameLecturer = location.state.record.name;
        setIsRendering(true);
        try {
            await exportExcelMoocLecturerDetail(idLecturer).then((res) => {
                const fileData = res.data;

                const blob = new Blob([fileData], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                const downloadLink = document.createElement("a");
                downloadLink.href = URL.createObjectURL(blob);

                downloadLink.download = `Chi tiết giảng viên ${nameLecturer}.xlsx`;

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                message.success("Tải xuống thành công!");
            });
        }
        catch (err) {
            setIsRendering(false);
            console.error("Error downloading Excel file:", err);
        } finally {
            setIsRendering(false);
        }
    };

    const handleTabChange = (key: string) =>{
        setActiveTab(key);
    };

    const renderExportButton = () => {
        if (activeTab === "1") {
            return (
                <Button
                    type="primary"
                    onClick={handleExportExcel}
                    disabled={isRendering}>
                    <Typography.Text>
                        {isRendering ? <LoadingOutlined /> : ''} Kết xuất thông tin chi tiết
                    </Typography.Text>
                </Button>
            )
        }
    }

    return (
        <>
            <Content>
                <PageHeader
                    title={location.state?.name ? location.state?.name : ""}
                    listBreadcrumb={listBreadcrumb}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    breadcrumb={true}
                    headerGroupButton={activeTab === "1"}
                    childrenGroupButton={renderExportButton()}
                />
                <div className="tabs-heading">
                    <Tabs
                        defaultActiveKey="1"
                        items={items}
                        onChange={handleTabChange}
                    />
                    
                </div>
            </Content>
        </>
    )
}

export default LecturerManagementDetail;