import { Modal } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import ConstructionPlan from './ConstructionPlan'
import ModalProgressBar from './ModalProgressBar';
import PreviewCourseStructure from '../../mooc-course-construction-plan/detail-course-plan/component/preview-course-structure/PreviewCourseStructure';

const ModalDownloadCourseConstructionPlan = (props: any) => {
  const { isOpen, setIsOpen, isShowModalDetailDownload, setIsShowModalDetailDownload, listCourseConstruct } = props
  const refFilePdf = useRef<HTMLInputElement>(null)
  const [isOpenModalProgressBar, setIsOpenModalProgressBar] = useState<boolean>(false)
  const [linkDownload, setLinkDownload] = useState<HTMLAnchorElement>()
  const [defaultActive, setDefaultActive] = useState<number[]>(Array.from({ length: listCourseConstruct.length }, (_, index) => index + 1))
  // const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);


  const [expandAll, setExpandAll] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const generateTreeData = (nodes: any) => {
    return nodes.map((node: any, index: number) => {
      const keyPrefix = node.parentId ? `${node.parentId}-` : '';
      const treeDataNode: any = {
        title: node.structureItemName,
        key: `${keyPrefix}${node.id}`,
        children: [],
      };

      if (node.subjectList && node.subjectList.length > 0) {
        treeDataNode.children = generateTreeData(node.subjectList);
      }

      if (node.lessonList && node.lessonList.length > 0) {
        treeDataNode.children = [
          ...treeDataNode.children,
          ...generateTreeData(node.lessonList)
        ];
      }

      return treeDataNode;
    });
  };

  const treeData = generateTreeData(listCourseConstruct);
  console.log({ treeData });
  const handleCancel = () => {
    setIsShowModalDetailDownload(false)
  }

  const getAllKeys = (data: any, keys: any = []) => {
    data.forEach((item: any) => {
      keys.push(item?.key);
      if (item.children) {
        getAllKeys(item.children, keys);
      }
    });
    return keys;
  };

  useEffect(() => {
    const allKeys = getAllKeys(treeData);
    setExpandedKeys(allKeys);
  }, []);

  const handleOk = async () => {
    console.log(refFilePdf.current?.offsetWidth)
    setIsOpenModalProgressBar(true);
    try {
      const allKeys = getAllKeys(treeData);
      setExpandAll(true)

      setTimeout(async () => {
        const input: any = refFilePdf.current;
        const pdf = new jsPDF(
          {
            orientation: 'p',
            unit: 'mm',
            format: 'a4'
          }
        );

        await html2canvas(input, {
          logging: true, useCORS: true, removeContainer: true, scale: 10
        }).then(async function (canvas) {

          // const width = pdf.internal.pageSize.getWidth();
          // const height = (canvas.height * width) / canvas.width;
          const a4Width = 210;
          const a4Height = 297;
          const imgWidth = a4Width - 4;
          const imgHeight = (canvas.height * imgWidth / canvas.width) - 6.5;

          const data = canvas.toDataURL("image/jpeg");
          // pdf.addImage(data, "PNG", 0, 0, width, height);

          let heightLeft = imgHeight;
          let position = 0;
          const bottomPadding = 0;
          pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= (a4Height - bottomPadding);

          while (heightLeft > 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(data, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= (a4Height - bottomPadding);
          }
          const zip = new JSZip();
          const folder = zip.folder('cau-truc');

          if (folder) {
            folder.file('cau-truc-khoa-hoc.pdf', pdf.output('blob'));

            const zipBlob = await zip.generateAsync({ type: 'blob' });

            const link = document.createElement('a');
            link.href = URL.createObjectURL(zipBlob);
            console.log(link.href);
            setLinkDownload(link);
          }
        })
      }, 2500); // Delay for 2.5 seconds
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Modal open={isOpen} width={800}
        title="Xem trước cấu trúc khoá học"

        centered={true}
        onOk={handleOk}
        okText='Tải xuống'
        onCancel={handleCancel}
        cancelText="Huỷ"
      >
        <div ref={refFilePdf}>
          <ConstructionPlan
            dataCourseStructure={listCourseConstruct}
            expandAll={expandAll}
            setExpandAll={setExpandAll}
            expandedKeys={expandedKeys}
            setExpandedKeys={setExpandedKeys}
          // listCourseConstruct={listCourseConstruct}
          // defaultActive={defaultActive}
          // setDefaultActive={setDefaultActive}
          // listCourseConstruct={listCourseConstruct}
          // expandedKeys={expandedKeys}
          // setExpandedKeys={setExpandedKeys}
          // autoExpandParent={autoExpandParent}
          // setAutoExpandParent={setAutoExpandParent}
          // treeData={treeData}
          // getAllKeys={getAllKeys}
          />
          {/* <PreviewCourseStructure dataCourseStructure={listCourseConstruct} /> */}
        </div>
      </Modal>
      {isOpenModalProgressBar && (
        <ModalProgressBar
          isOpen={isOpenModalProgressBar}
          setIsOpen={setIsOpenModalProgressBar}
          isOpenModalFather={isOpen}
          setIsOpenModalFather={setIsOpen}
          refFilePdf={refFilePdf}
          link={linkDownload}


        />
      )}
    </>
  )
}

export default ModalDownloadCourseConstructionPlan