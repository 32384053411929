// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info {
  min-height: calc(100vh - 48px); }
  .user-info .account {
    justify-content: flex-start; }
  .user-info .slider {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    text-align: left;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    z-index: 9; }
  .user-info .ant-layout-sider {
    background: white !important;
    flex: 0 0 260px;
    max-width: 260px !important;
    min-width: 260px !important;
    width: 260px !important; }
    .user-info .ant-layout-sider .ant-layout-sider-trigger {
      background: white !important;
      flex: 0 0 260px;
      max-width: 260px !important;
      min-width: 260px !important;
      width: 260px !important;
      color: #000; }
  .user-info .ant-layout-sider-collapsed {
    flex: 0 0 80px !important;
    max-width: 80px !important;
    min-width: 80px !important;
    width: 80px !important; }
    .user-info .ant-layout-sider-collapsed .ant-layout-sider-trigger {
      background: white !important;
      flex: 0 0 260px;
      max-width: 80px !important;
      min-width: 80px !important;
      width: 80px !important;
      color: #000; }
`, "",{"version":3,"sources":["webpack://./src/pages/user-info/style.scss"],"names":[],"mappings":"AAAA;EACE,8BAA8B,EAAA;EADhC;IAII,2BAA2B,EAAA;EAJ/B;IAQI,yCAAyC;IACzC,gBAAgB;IAChB,eAAe;IACf,0BAA0B;IAC1B,UAAU,EAAA;EAZd;IAgBI,4BAAyC;IACzC,eAAe;IACf,2BAA2B;IAC3B,2BAA2B;IAC3B,uBAAuB,EAAA;IApB3B;MAuBM,4BAAyC;MACzC,eAAe;MACf,2BAA2B;MAC3B,2BAA2B;MAC3B,uBAAuB;MACvB,WAAW,EAAA;EA5BjB;IAiCI,yBAAyB;IACzB,0BAA0B;IAC1B,0BAA0B;IAC1B,sBAAsB,EAAA;IApC1B;MAuCM,4BAAyC;MACzC,eAAe;MACf,0BAA0B;MAC1B,0BAA0B;MAC1B,sBAAsB;MACtB,WAAW,EAAA","sourcesContent":[".user-info {\n  min-height: calc(100vh - 48px);\n\n  .account {\n    justify-content: flex-start;\n  }\n\n  .slider {\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n    text-align: left;\n    font-size: 14px;\n    color: rgba(0, 0, 0, 0.85);\n    z-index: 9;\n  }\n\n  .ant-layout-sider {\n    background: rgb(255, 255, 255) !important;\n    flex: 0 0 260px;\n    max-width: 260px !important;\n    min-width: 260px !important;\n    width: 260px !important;\n\n    .ant-layout-sider-trigger {\n      background: rgb(255, 255, 255) !important;\n      flex: 0 0 260px;\n      max-width: 260px !important;\n      min-width: 260px !important;\n      width: 260px !important;\n      color: #000;\n    }\n  }\n\n  .ant-layout-sider-collapsed {\n    flex: 0 0 80px !important;\n    max-width: 80px !important;\n    min-width: 80px !important;\n    width: 80px !important;\n\n    .ant-layout-sider-trigger {\n      background: rgb(255, 255, 255) !important;\n      flex: 0 0 260px;\n      max-width: 80px !important;\n      min-width: 80px !important;\n      width: 80px !important;\n      color: #000;\n    }\n  }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
