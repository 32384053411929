import React from 'react'
import { Badge, Button, Form, message, Modal, Typography } from "antd";
import CustomCard from '../../../custom-card/CustomCard';
import { Appraisers, BrowsingResuls, DetailCourseData, PlanInfo } from '../../../../types/assign-course-construction';

import "./index.scss"
import { formatDateToIsoString } from '../../../../utils/converDate';
import { isEmpty } from 'lodash';

type Props = {
  planInfo: PlanInfo
  detailData: DetailCourseData
}

const CourseBrowsingResults = (props: Props) => {

  const { planInfo , detailData} = props
  const [form] = Form.useForm()

  const infoData: BrowsingResuls = {
    dateConfirm: isEmpty(detailData?.appraisedDate) ? "" : formatDateToIsoString(detailData?.appraisedDate),
    peopleConfirm: detailData.appraisers,
    approvalDate: formatDateToIsoString(detailData?.approvedDate),
    approvalPeople: detailData?.approveName
  }

  const convertObjectData = Object.entries(infoData);

  const renderListPeopleConfirm = (data: DetailCourseData['appraisers']) => {
    return data?.map((item: Appraisers, index: number) => (
      <div className='flex' key={index}>
      <Badge key={"black"} color={"black"} text={item.appraiserName} />
      </div>
    ))
  }

  const renderSwitch = (value: string) => {
    switch (value) {
      case 'dateConfirm':
        return <div>Ngày thẩm định</div>;
      case 'peopleConfirm':
        return <div>Người thẩm định</div>;
      case 'approvalDate':
        return <div>Ngày phê duyệt</div>;
      case 'approvalPeople':
        return <div>Người phê duyệt</div>;
      default:
        return '';
    }
  }

  const listInfo = convertObjectData.map((item: any, index) => (
    <div className="list-item" key={index}>
      <Typography.Title level={5} className="list-item--title">
        {renderSwitch(item[0])}
      </Typography.Title>
      <div className="list-item--description">
        <Typography.Text className="content">
          {item[0] === 'peopleConfirm' ? renderListPeopleConfirm(detailData?.appraisers) : (item[0] === 'approvalPeople' ? <Badge key={"black"} color={"black"} text={item[1] } /> : item[1])}
        </Typography.Text>
      </div>
    </div>
  ))

  return (
    <>
      <Form.Provider onFormFinish={(name, { values, forms }) => {
      }}>
        <CustomCard title={
          <div className="card-heading--info">
            <Typography.Title level={5}>
              Kết quả duyệt
            </Typography.Title>
            <Typography.Text className="sub-title">
              Chi tiết kết quả duyệt của kế hoạch
            </Typography.Text>
          </div>
        } className="card-info--wrapper">
          <div className="list-wrapper">
            {listInfo}
          </div>
        </CustomCard>
      </Form.Provider>
    </>
  )
}

export default CourseBrowsingResults


