// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-enrolled--class .col-table-create {
  margin-bottom: 15px; }
  .create-enrolled--class .col-table-create .item-time .ant-picker {
    width: 100%; }
  .create-enrolled--class .col-table-create .item-time .ant-form-item {
    width: 100%; }
    .create-enrolled--class .col-table-create .item-time .ant-form-item .ant-picker {
      width: 100%; }
  .create-enrolled--class .col-table-create .ant-input-number {
    width: 100%; }

.create-enrolled--class label {
  margin-bottom: 8px;
  width: auto; }

.create-enrolled--class .form-time--wrapper .label-required:before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*"; }

.title-checkbox {
  font-weight: 400; }

.sub-title {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  font-weight: 300; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/class-register/create-class/index.scss"],"names":[],"mappings":"AAAA;EAEI,mBAAmB,EAAA;EAFvB;IAMQ,WAAW,EAAA;EANnB;IAUQ,WAAW,EAAA;IAVnB;MAaU,WAAW,EAAA;EAbrB;IAmBM,WAAW,EAAA;;AAnBjB;EAwBI,kBAAkB;EAClB,WAAW,EAAA;;AAzBf;EAgCQ,qBAAqB;EACrB,sBAAsB;EACtB,cAAc;EACd,eAAe;EACf,+BAA+B;EAC/B,cAAc;EACd,YAAY,EAAA;;AAMpB;EACE,gBAAgB,EAAA;;AAGlB;EACE,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,gBAAgB;EAChB,gBAAgB,EAAA","sourcesContent":[".create-enrolled--class {\n  .col-table-create {\n    margin-bottom: 15px;\n\n    .item-time {\n      .ant-picker {\n        width: 100%;\n      }\n\n      .ant-form-item {\n        width: 100%;\n\n        .ant-picker {\n          width: 100%;\n        }\n      }\n    }\n\n    .ant-input-number {\n      width: 100%;\n    }\n  }\n\n  label {\n    margin-bottom: 8px;\n    width: auto;\n  }\n\n  .form-time--wrapper {\n    .label-required {\n\n      &:before {\n        display: inline-block;\n        margin-inline-end: 4px;\n        color: #ff4d4f;\n        font-size: 14px;\n        font-family: SimSun, sans-serif;\n        line-height: 1;\n        content: \"*\";\n      }\n    }\n  }\n}\n\n.title-checkbox {\n  font-weight: 400;\n}\n\n.sub-title {\n  font-size: 14px;\n  line-height: 22px;\n  color: rgba(0, 0, 0, 0.45);\n  text-align: left;\n  font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
