import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const getApprover: (data: object) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfig.post(`/mooc-survey-approval/get-approver`, data)
}
export const sendApproval: (data: object) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfig.post(`/mooc-survey-approval/send-approval`, data)
}
export const revokeSurvey: (data: object) => Promise<AxiosResponse<any>> = (
    data
) => {
    return axiosConfig.put(`/mooc-survey-approval/revoke-survey`, data);
};
export const getInfoApproval: (id: string) => Promise<AxiosResponse<any>> = (
    id
) => {
    return axiosConfig.get(`/mooc-survey-approval/get-info-approval/${id}`);
};

export const approveSurvey: (data: object) => Promise<AxiosResponse<any>> = (
    data
) => {
    return axiosConfig.post(`/mooc-survey-approval/approve-survey`, data);
};
export const approveManySurvey: (data: object) => Promise<AxiosResponse<any>> = (
    data
) => {
    return axiosConfig.post(`/mooc-survey-approval/approve-many`, data);
};
