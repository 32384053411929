// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-question-view .ql-toolbar {
  width: fit-content;
  border-radius: 10px; }

.survey-managment-question-view .ql-container.ql-snow {
  width: 100%;
  border-radius: 10px;
  border-top: 1px solid #e5e7eb !important;
  margin-bottom: 5px;
  margin-top: 5px; }

.survey-managment-question-view .ql-container.ql-snow:focus-within .ql-toolbar {
  display: flex; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-respond/component/detail-question/question-type-4/detail.scss"],"names":[],"mappings":"AAAA;EAEI,kBAA0B;EAC1B,mBAAmB,EAAA;;AAHvB;EAOI,WAAmB;EACnB,mBAAmB;EACnB,wCAA2C;EAC3C,kBAAkB;EAClB,eAAkB,EAAA;;AAXtB;EAgBI,aAAa,EAAA","sourcesContent":[".survey-managment-question-view {\n  .ql-toolbar {\n    width        : fit-content;\n    border-radius: 10px;\n  }\n\n  .ql-container.ql-snow {\n    width        : 100%;\n    border-radius: 10px;\n    border-top   : 1px solid #e5e7eb !important;\n    margin-bottom: 5px;\n    margin-top   : 5px;\n  }\n\n\n  .ql-container.ql-snow:focus-within .ql-toolbar {\n    display: flex;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
