// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-plan-managment-many-approval-planning .title-custom-many-approval {
  display: flex;
  align-items: center;
  margin: 12px 0; }

.course-plan-managment-many-approval-planning .title-custom-many-approval-h1 {
  margin-right: 5px;
  font-size: 18px; }

.course-plan-managment-many-approval-planning .title-custom-many-approval-span {
  font-style: italic;
  margin-top: 1px; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-course-plan-planning-approval/components/many-approvals/ManyApprovals.scss"],"names":[],"mappings":"AAAA;EAEQ,aAAiB;EACjB,mBAAmB;EACnB,cAAmB,EAAA;;AAJ3B;EAQQ,iBAAiB;EACjB,eAAkB,EAAA;;AAT1B;EAaQ,kBAAkB;EAClB,eAAe,EAAA","sourcesContent":[".course-plan-managment-many-approval-planning {\n    .title-custom-many-approval {\n        display    : flex;\n        align-items: center;\n        margin     : 12px 0;\n    }\n\n    .title-custom-many-approval-h1 {\n        margin-right: 5px;\n        font-size   : 18px;\n    }\n\n    .title-custom-many-approval-span {\n        font-style: italic;\n        margin-top: 1px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
