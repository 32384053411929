import { StateCreator } from "zustand";

export interface Survey {
  review: number;
  setReview: (data: number) => void;
}

export const AddSurveyStore: StateCreator<Survey> = (set, get) => ({
  review: 0,
  setReview: (data: number) => {
    set({ review: data });
  },
});