import React, { FunctionComponent } from "react";
import { Col, Form, message, Row } from "antd";
import FormInput from "../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import { FormInstance } from "antd/lib";
import { get, isEmpty } from "lodash";
import "./form-create-survey.scss";

const validations = {
  surveyName: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length < 6) {
        return Promise.reject(new Error("Tên đợt khảo sát phải trên 6 ký tự."));
      }
      if (value && value.length > 255) {
        return Promise.reject(
          new Error("Tên đợt khảo sát không vượt quá 255 ký tự.")
        );
      }
      if (!value) {
        return Promise.reject(new Error("Vui lòng nhập tên đợt khảo sát."));
      }
      return Promise.resolve();
    },
  },
  industryId: {
    required: true,
    message: "Vui lòng chọn Khoa/nhóm ngành.",
  },
  industryGroupId: {
    required: true,
    message: "Vui lòng chọn chuyên ngành.",
  },
  courseType: {
    required: true,
    message: "Vui lòng chọn loại khoá học.",
  },
  universityId: {
    required: true,
    message: "Vui lòng chọn trường chủ trì.",
  },
};

type FormCreateSurveyProps = {
  form: FormInstance;
  onFinish?: any;
  dataOptions?: any;
  id?: any;
};

const FormCreateSurvey: FunctionComponent<FormCreateSurveyProps> = ({
  form,
  onFinish,
  dataOptions,
}) => {
  return (
    <>
      <Form
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        name="createSurvey"
        initialValues={{
          ["trangThai"]: true,
        }}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              name="surveyName"
              rules={[validations.surveyName]}
              label="Tên đợt khảo sát"
            >
              <FormInput placeholder="Nhập tên đợt khảo sát" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="industryId"
              label="Khoa/nhóm ngành"
              rules={[validations.industryId]}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                options={get(dataOptions, "dataIndustry", "")}
                allowClear={true}
                placeholder="Chọn khoa/nhóm ngành khảo sát"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="industryGroupId"
              label="Chuyên ngành"
              rules={[validations.industryGroupId]}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                options={get(dataOptions, "dataIndustryGroup", "")}
                allowClear={true}
                placeholder="Chọn chuyên ngành khảo sát"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="courseType"
              label="Loại khóa học"
              rules={[validations.courseType]}
            >
              <FormItemDropdown
                options={get(dataOptions, "dataCourseType", "")}
                allowClear={true}
                placeholder="Chọn loại khóa học"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <span>Trường chủ trì khảo sát</span>
          </Col>
          <Col span={24}>
            <Form.Item
              name="universityId"
              label="Trường chủ trì"
              rules={[validations.universityId]}
            >
              <FormItemDropdown
                options={get(dataOptions, "dataUniversityHost", "")}
                allowClear={true}
                placeholder="Chọn trường đại học chủ trì"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="collaboratorId"
              label={<span>Trường hợp tác</span>}
            >
              <FormItemDropdown
                modeDropdown="multiple"
                options={get(dataOptions, "dataCollaborator", "")}
                allowClear={true}
                placeholder="Chọn trường đại học hợp tác"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FormCreateSurvey;
