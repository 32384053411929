import React, {useEffect, useState} from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import {Flex, Form, GetProp, Typography, Upload, UploadProps} from "antd";
import {DeleteOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import "./index.scss";
import {FormInstance, message} from "antd/lib";
import FormItemInput from "../../../../form-input/FormInput";
import {useAuthStore} from "../../../../../stores/stores";
import {BASE_URL_V2} from "../../../../../config/api/configApiv2";

type InfoAuthorProps = {
  form?: FormInstance;
  dataConfig?: any;
  setValueImage?: (value: any) => void;
};

const InfoAuthor = ({form, dataConfig, setValueImage}: InfoAuthorProps) => {
  const [loading, setLoading] = useState(false);
  const [dataImages, setDataImages] = useState<any[]>([]);
  const [loadingUpload, setLoadingUpload] = useState<any>({});
  const [dataUpload, setDataUpload] = useState({});
  const accessToken = useAuthStore((state) => state.accessToken);
  const [uploadedImages, setUploadedImages] = useState<any>(
    {}
  );
  const [dataImagesName, setDataImagesName] = useState<any>(
    {}
  );
  const [dataImage, setDataImage] = useState<string>("")
  const [idAvatar, setIdAvatar] = useState(0);
  const [assignerData, setAssignerData] = useState(dataConfig.assigner);


  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];


  const beforeUploadImage = (file: FileType) => {
    setLoadingUpload(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error("Ảnh yêu cầu định dạng jpeg/png!");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300)
    }
    if (!isLt2M) {
      message.error("Ảnh phải có dung lượng nhỏ hơn 2M!");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300)
    }

    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info: any, id: number) => {
    if (info.file.status === 'uploading') {
      setLoadingUpload({...loadingUpload, [id]: true});
      return;
    }
    if (info.file.status === 'done') {
      message.success('Tải ảnh lên thành công !');
      setLoadingUpload({...loadingUpload, [id]: false});
      const {response} = info.file;

      if (response && response.filePath && form) {
        const {response} = info.file
        setUploadedImages({
          ...uploadedImages,
          [id]: response.fileName
        });
        setDataImagesName({
          ...dataImagesName,
          [id]: response.filePath
        })
        setIdAvatar(id)
      }
    }
    if (info.file.status === "error") {
      message.error("Tải ảnh lên thất bại")
    }
  };


  const addDataUpload = (id: number, data: any) => {
    const newDataImage = {id, image: data[id]};

    // Loại bỏ phần tử có id = 0
    const filteredDataImages = dataImages.filter((item) => !(item.id === 0));

    // Kiểm tra nếu id đã tồn tại trong filteredDataImages
    const existingImageIndex = filteredDataImages.findIndex(
      (item) => item.id === id
    );

    if (existingImageIndex !== -1) {
      // Nếu id đã tồn tại, chỉ cập nhật image
      const updatedDataImages = filteredDataImages.map((item, index) =>
        index === existingImageIndex ? newDataImage : item
      );
      setDataImages(updatedDataImages);
    } else {
      // Nếu id chưa tồn tại, thêm dữ liệu mới
      setDataImages([...filteredDataImages, newDataImage]);
    }
    if (data[id]) {
      setDataUpload((prevState) => ({
        ...prevState,
        id: id,
        ...prevState,
        image: data[id],
      }));
    }
  }
  useEffect(() => {
    if (uploadedImages) {
      addDataUpload(idAvatar, uploadedImages)
    }
  }, [uploadedImages, idAvatar]);

  useEffect(() => {
    if (dataImages) {
      form?.setFieldsValue({
        assignerAvatar: dataImages
      })

    }
  }, [dataImages]);


  useEffect(() => {
    if (dataConfig) {
      setAssignerData(dataConfig.assigner)
    }
  }, [dataConfig]);


  const uploadButton = (id: number) => (
    <button
      style={{border: 0, background: "none"}}
      type="button"
      onClick={() => setIdAvatar(id)}
    >
      {loading && id === idAvatar ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>Upload</div>
    </button>
  );
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.file;
  };

  const handleRemoveImage = (id: number) => {
    setUploadedImages({
      ...uploadedImages,
      [id]: null
    });
    setDataImagesName({
      ...dataImagesName,
      [id]: null
    })
    setIdAvatar(id)
    assignerData.forEach((assigner: { assigned: any[]; }) => {
      assigner.assigned.forEach((teacher: { assignUserId: any; imageCourseAssign: null; }) => {
        if (teacher.assignUserId === id) {
          teacher.imageCourseAssign = null;
        }
      });
    });
  };


  return (
    <>
      <CustomCard title="Tác giả" className="wrapper-settings--card">
        <div className="form-setting--school">
          {assignerData.map(
            (assigner: any, index: number) => {
              const checkAssignerConstruct = assigner.assigned !== null && assigner.assigned.some((teacher: any) => teacher.construct !== null && teacher.construct)
              return (
                checkAssignerConstruct ? (<section key={index + assigner.enterpriseName}>
                  {assigner.isSponsor ? (
                    <div className="form--main_school" key={assigner.isSponsor}>
                      <div className="form--main_school__title">
                        <Typography.Title level={5}>
                          Trường chủ trì: {assigner.enterpriseName}
                        </Typography.Title>
                      </div>
                      {
                        <>
                          {assigner.assigned.filter((teacher: any) => teacher.construct !== null && teacher.construct).map((el: any, index: number) => {

                            return (
                              <section
                                key={index + el.assignUserName}
                                className="lecturers"
                              >
                                <Flex gap="middle" vertical>
                                  <Form.Item label="Giảng viên chủ trì">
                                    <FormItemInput
                                      value={el.assignUserName}
                                      // disabled={true}
                                      readOnly
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name="assignerAvatar"
                                    label="Ảnh tác giả"
                                    className="author-upload--avatar"
                                  >
                                    <div className="image-container">
                                      <Upload
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action={BASE_URL_V2 + '/mooc-course-block-quiz/upload-file'}
                                        headers={{Authorization: 'Bearer ' + accessToken}}
                                        beforeUpload={beforeUploadImage}
                                        onChange={(e) =>
                                          handleChange(e, el.assignUserId)
                                        }
                                      >
                                        {
                                          loadingUpload[el.assignUserId] ? (
                                            <div className='upload-content'><LoadingOutlined/></div>
                                          ) : (
                                            (el.imageCourseAssign || dataImagesName[el.assignUserId]) ? (
                                              <img className='image-present'
                                                   src={dataImagesName[el.assignUserId] ? dataImagesName[el.assignUserId] : el.imageCourseAssign}
                                                   style={{
                                                     width: "100%",
                                                     height: "100%",
                                                   }}
                                                   alt='image'/>
                                            ) : (
                                              <div className='upload-content'><PlusOutlined/><span>Tải lên</span></div>
                                            )
                                          )
                                        }
                                      </Upload>
                                      {(el.imageCourseAssign || dataImagesName[el.assignUserId]) &&
                                          <span className="remove-icon">
                                          <a className="upload-action"
                                             onClick={(e) => {
                                               e.preventDefault();
                                               handleRemoveImage(el.assignUserId);
                                             }}>
                                            <DeleteOutlined/>
                                          </a>
                                        </span>}
                                    </div>
                                    <div className="note-update">
                                      <Typography.Text className="sub-title">
                                        Độ phân giải đề xuất của ảnh khi tải
                                        lên là 640*640, kích thước file tối đa
                                        4MB.
                                      </Typography.Text>
                                    </div>
                                  </Form.Item>
                                </Flex>
                              </section>
                            );
                          })}
                          {!assigner.isSponsor && (
                            <>
                              {assigner.assigned.map(
                                (el: any, index: number) => {
                                  return (
                                    <section
                                      key={index + el.assignUserName}
                                      className="lecturers"
                                    >
                                      <Form.Item label="Giảng viên hỗ trợ">
                                        <FormItemInput
                                          value={el.assignUserName}
                                          // disabled={true}
                                          readOnly
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name="assistTeacherAvatar"
                                        label="Ảnh tác giả"
                                        valuePropName="fileList"
                                        className="author-upload--avatar"
                                        getValueFromEvent={normFile}
                                      >
                                        <div className="image-container">
                                          <Upload
                                            name="avatar"
                                            listType="picture-card"
                                            className="avatar-uploader"
                                            showUploadList={false}
                                            action={
                                              BASE_URL_V2 +
                                              "/mooc-course-block-quiz/upload-file"
                                            }
                                            headers={{
                                              Authorization:
                                                "Bearer " + accessToken,
                                            }}
                                            beforeUpload={beforeUploadImage}
                                            onChange={(e) =>
                                              handleChange(e, el.assignUserId)
                                            }
                                          >
                                            {el.imageCourseAssign ||
                                            uploadedImages[el.assignUserId] ? (
                                              <img
                                                src={
                                                  uploadedImages[el.assignUserId] ?
                                                    uploadedImages[el.assignUserId] : el.imageCourseAssign
                                                }
                                                alt="avatar"
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              />
                                            ) : (
                                              uploadButton(el.assignUserId)
                                            )}
                                          </Upload>
                                          {(el.imageCourseAssign || uploadedImages[el.assignUserId]) &&
                                              <span className="remove-icon">
                                              <a className="upload-action"
                                                 onClick={(e) => {
                                                   e.preventDefault();
                                                   handleRemoveImage(el.assignUserId);
                                                 }}>
                                                <DeleteOutlined/>
                                              </a>
                                            </span>}
                                        </div>
                                        <div className="note-update">
                                          <Typography.Text className="sub-title">
                                            Độ phân giải đề xuất của ảnh khi tải
                                            lên là 640*640, kích thước file tối
                                            đa 4MB.
                                          </Typography.Text>
                                        </div>
                                      </Form.Item>
                                    </section>)
                                }
                              )}
                            </>
                          )}
                        </>
                      }
                    </div>
                  ) : (
                    <div className="form--secondary_school">
                      <div className="form--secondary_school__title">
                        <Typography.Title level={5}>
                          Trường phối hợp: {assigner.enterpriseName}
                        </Typography.Title>
                      </div>
                      {!assigner.isSponsor &&
                        assigner.assigned.map((el: any, index: number) => {
                          return (
                            <section key={index + el.assignUserName}>
                              <Form.Item label="Giảng viên chủ trì">
                                <FormItemInput
                                  value={el.assignUserName}
                                  disabled={true}
                                />
                              </Form.Item>
                              <Form.Item
                                name="mainTeacherAvatar"
                                label="Ảnh tác giả"
                                valuePropName="fileList"
                                className="author-upload--avatar"
                                getValueFromEvent={normFile}
                              >
                                <div className="image-container">

                                  <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action={
                                      BASE_URL_V2 +
                                      "/mooc-course-block-quiz/upload-file"
                                    }
                                    headers={{
                                      Authorization: "Bearer " + accessToken,
                                    }}
                                    beforeUpload={beforeUploadImage}
                                    onChange={(e) =>
                                      handleChange(e, el.assignUserId)
                                    }
                                  >
                                    {el.imageCourseAssign ||
                                    uploadedImages[el.assignUserId] ? (
                                      <img
                                        // src={uploadedImages.filePath ?? undefined}
                                        src={
                                          el.imageCourseAssign ||
                                          uploadedImages[el.assignUserId]
                                        }
                                        alt="avatar"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      />
                                    ) : (
                                      uploadButton(el.assignUserId)
                                    )}
                                  </Upload>
                                  {(el.imageCourseAssign || uploadedImages[el.assignUserId]) &&
                                      <span className="remove-icon">
                                      <a className="upload-action"
                                         onClick={(e) => {
                                           e.preventDefault();
                                           handleRemoveImage(el.assignUserId);
                                         }}>
                                        <DeleteOutlined/>
                                      </a>
                                    </span>}
                                </div>

                                <div className="note-update">
                                  <Typography.Text className="sub-title">
                                    Độ phân giải đề xuất của ảnh khi tải lên là
                                    640*640, kích thước file tối đa 4MB.
                                  </Typography.Text>
                                </div>
                              </Form.Item>
                            </section>
                          );
                        })}
                    </div>
                  )}
                </section>) : (
                  <></>
                )
              );
            }
          )}
        </div>
      </CustomCard>
    </>
  );
};

export default InfoAuthor;
