import React, { FunctionComponent, useMemo, useState } from "react";
import { get } from "lodash";
import { Dayjs } from "dayjs";
import { FormInstance } from "antd/lib";
import type { DatePickerProps } from "antd";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";

import { FilterDataMoocApproval } from "../../../../../types/course-plan-managment/mooc-approval";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  setFilterData: (value: string[], name: string) => void;
  clearFilter: () => void;
  onFinish?: () => void;
  dataOptions?: any[];
  tab: Number;
  filterData: any; //FilterDataMoocApproval;
};

type RangeValue = any | null;

const FormFilterMoocApproval: FunctionComponent<FormFilterProps> = ({
  handleCloseFilter,
  onFinish,
  form,
  showFilter,
  clearFilter,
  dataOptions,
  tab,
  filterData,
  setFilterData,
}) => {
  const { RangePicker } = DatePicker;
  const styleFilter = {
    margin: '0',
    paddingTop: '0',
    paddingBottom: '0',
  }
  

  return (
    <div className="gc-style">
      <div style={ styleFilter } className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
            <Row gutter={[{ xs: 8, xl: 12, md: 12, lg: 10, sm: 10 }, 12]}>
              {dataOptions?.map((filter) => {
                if(filter.type == 'RangePicker') {
                  return (<Col
                  xl={{ span: filter.size + 1 }}
                  md={{ span: filter.size + 1 }}
                  sm={{ span: filter.size + 1 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5}>{filter.title}</Typography.Title>
                  <Form.Item name={filter.key}>
                    <RangePicker
                      placeholder={["Bắt đầu", "Kết thúc"]}
                      format="DD-MM-YYYY"
                      value={filterData[filter.key]}
                      onChange={(_value, dateString: any) => {
                        const convertedDates = dateString.map(
                          (date: String) => {
                            const [day, month, year] = date.split("-");
                            return `${year}-${month}-${day}`;
                          }
                        );
                        setFilterData(convertedDates, filter.key);
                      }}
                    />
                  </Form.Item>
                </Col>);
                }
                if(filter.type == 'FormItemDropdown') {
                  return (<Col
                  xl={{ span: filter.size }}
                  md={{ span: filter.size + 1 }}
                  sm={{ span: filter.size + 1 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5}>{filter.title}</Typography.Title>
                  <Form.Item name={filter.key}>
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Chọn trạng thái"
                      options={filter.data}
                      onChange={(option) => setFilterData(option, filter.key)}
                    />
                  </Form.Item>
                </Col>);
                }
                return (<></>);
              })}
            <Col
              xl={{ span: 1 }}
              md={{ span: 9 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa tất cả</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>

        {/* {tab === 2 && <Form form={form} name="filterForm" onFinish={onFinish}>
            <Row gutter={[{xs: 8, xl: 24, md: 16}, 12]}>
              {dataOptions?.map((filter) => {
                if(filter.type == 'RangePicker') {
                  return (<Col
                  xl={{ span: filter.size }}
                  md={{ span: filter.size + 1 }}
                  sm={{ span: filter.size + 1 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5}>{filter.title}</Typography.Title>
                  <Form.Item name={filter.key}>
                    <RangePicker
                      placeholder={["Bắt đầu", "Kết thúc"]}
                      format="YYYY-MM-DD"
                      value={filterData[filter.key]}
                      onChange={(_value, dateString: any) => {
                        setFilterData(dateString, filter.key);
                      }}
                    />
                  </Form.Item>
                </Col>);
                }
                if(filter.type == 'FormItemDropdown') {
                  return (<Col
                  xl={{ span: filter.size }}
                  md={{ span: filter.size + 1 }}
                  sm={{ span: filter.size + 1 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5}>{filter.title}</Typography.Title>
                  <Form.Item name={filter.key}>
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Chọn trạng thái"
                      options={filter.data}
                      onChange={(option) => setFilterData(option, filter.key)}
                    />
                  </Form.Item>
                </Col>);
                }
                return (<></>);
              })}
            <Col
              xl={{ span: 3 }}
              md={{ span: 9 }}
              sm={{ span: 9 }}
              xs={{ span: 24 }}
              className="flex align-end"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa tất cả</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>} */}
      </div>
    </div>
  );
};

export default FormFilterMoocApproval;
