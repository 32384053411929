import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSettingQuality: (idCourse:string) => Promise<AxiosResponse<any>> = (idCourse) => {
  return axiosConfig.get(`/setting-quality/get-by-course/${idCourse}`);
};

export const getSettingReportByCourse: (idCourse:string) => Promise<AxiosResponse<any>> = (idCourse) => {
  return axiosConfig.get(`/setting-quality/report-by-course/${idCourse}`);
};

export const getListCourseVideo: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-attendance/course-by-module`, dataReq);
};

export const updateSettingQuality: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-quality/update/${id}`, dataReq);
};