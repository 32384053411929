import { Button, Col, Form, Layout, message, Modal, Row, Tabs, TabsProps, Tag, Typography } from "antd";
import { FunctionComponent, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import "./ministry-users-management-detail.scss"
import { exportExcelMoocMinistryUserDetail } from "../../../../service/category-management/ministry-users-management";
import { LoadingOutlined } from "@ant-design/icons";
import MinistryHistory from "../../../../components/category-management/management-detail/ministry-info/ministry-history/ministryHistory";
import MinistryDetail from "../../../../components/category-management/management-detail/ministry-info/ministry-detail/ministryDetail";

const { Content } = Layout;
const imgPath = "/images/";

const MinistryUsersManagementDetail: FunctionComponent = () => {
  const location = useLocation();
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("1");
  const idMinisty = location.state.record.id;
  const listBreadcrumb = [
    {
      title: "Quản lý danh mục",
    },
    {
      title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.ministryUsersManagement}`}>
        <span>Quản lý người dùng cấp bộ</span>
      </SafeLink>,
    },
    {
      title: location.state.name,
    },
  ];

  // Chức vụ
  const regency = location.state.record?.authPositions.map((item: any) => {
    return (item.name);
  })

  // Trình độ học vấn
  const academicLevel = location.state.record?.moocTeacherProfiles.map((item: any) => {
    return (item.levelName);
  });

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chi tiết nguời dùng cấp bộ",
      children: (
        <MinistryDetail
          ministryRecord={location.state.record}
          academicLevel={academicLevel}
          regency={regency} />
      )
    },
    {
      key: "2",
      label: "Lịch sử thao tác",
      children: (
        <MinistryHistory idMinistry={idMinisty} />
      )
    },
  ];

  const handleExportExcel = () => {
    Modal.confirm({
      title: `Xác nhận kết xuất danh sách người dùng cấp bộ`,
      content: ``,
      onOk: () => handleExportMinistryUsersManagementDetail(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  const handleExportMinistryUsersManagementDetail = async () => {
    const idMinistryUser = location.state.record.id;
    const nameMinistryUser = location.state.record.name;
    setIsRendering(true);
    try {
      await exportExcelMoocMinistryUserDetail(idMinistryUser).then((res) => {
        const fileData = res.data;
        const blob = new Blob([fileData], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);

        downloadLink.download = `Chi tiết quản lý người dùng cấp bộ ${nameMinistryUser}.xlsx`;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        message.success("Tải xuống thành công!");
      });
    }
    catch (err) {
      setIsRendering(false);
      console.error("Error downloading Excel file:", err);
    } finally {
      setIsRendering(false);
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const renderExportButton = () => {
    if (activeTab === "1") {
      return (
        <Button
          type="primary"
          onClick={handleExportExcel}
          disabled={isRendering}>
          <Typography.Text>
            {isRendering ? <LoadingOutlined /> : ''} Kết xuất thông tin chi tiết
          </Typography.Text>
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      <Content>
        <PageHeader
          title={location.state?.name ? location.state?.name : ""}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={activeTab === "1"}
          childrenGroupButton={renderExportButton()}
        />
        <div className="tabs-heading">
          <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={handleTabChange}
          />
        </div>
      </Content>
    </>
  )
}

export default MinistryUsersManagementDetail;