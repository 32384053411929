export const optionsCourse = [
  {
    label: "Khóa học 1",
    code: "KH1"
  },
  {
    label: "Khóa học 2",
    code: "KH2"
  },
  {
    label: "Khóa học 3",
    code: "KH3"
  }
]

export const optionsSkills = [
  {
    label: "Kỹ năng 1",
    code: "KN1"
  },
  {
    label: "Kỹ năng 2",
    code: "KN2"
  },
  {
    label: "Kỹ năng 3",
    code: "KN3"
  }
]
