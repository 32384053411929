import { DownloadOutlined, DownOutlined, PlusOutlined, SearchOutlined, UploadOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React, { ChangeEvent, KeyboardEvent } from 'react';
import FormInput from '../../form-input/FormInput';
import FormItemDropdown from '../../form-dropdown/FormItemDropdown';


type FilterTableProps = {
  showFilter?: boolean
  searchValue?: string
  handleChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void
  handleSearch?: (e: KeyboardEvent<HTMLSpanElement>) => void
  handleSubmitSearch?: () => void
  handleOpenFilter?: () => void
  handleOpen?: () => void
  handleOpenModal?: () => void
  setTypeSelect: (e: any) => void
  setExportMode: (e: boolean) => void
};

const typeAccountStudent = [
  {
    id: 1,
    name: 'Tổng số tài khoản sinh viên'
  },
  {
    id: 2,
    name: 'Tài khoản đăng ký thành công'
  },
  {
    id: 3,
    name: 'Tài khoản đăng ký thất bại'
  },
  {
    id: 4,
    name: 'Tỷ lệ đăng ký thành công và thất bại'
  },
  {
    id: 5,
    name: 'Số lượng và tỷ lệ thành công theo các bước định danh tài khoản'
  },
  {
    id: 6,
    name: 'Thống kê lỗi giấy tờ tùy thân'
  },
  {
    id: 7,
    name: 'Thống kê lỗi đăng ký'
  },
  {
    id: 8,
    name: 'Tỷ lệ định danh tài khoản'
  },
  {
    id: 9,
    name: 'Trạng thái hậu kiểm'
  }
]


const FilterFormTable = ({
  showFilter,
  handleOpenFilter,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpen,
  handleOpenModal,
  setTypeSelect,
  setExportMode
}: FilterTableProps) => {
  return (
    <div className="history-table-style">
      <div className="heading-table">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
          <Col>
            <div className="heading-table-filter">
              <Button className={`filter-button ${showFilter ? 'open' : ''}`} onClick={handleOpenFilter}>
                <Typography.Text className="filter-text">Bộ lọc</Typography.Text>
                {showFilter ? <UpOutlined /> : <DownOutlined />}
              </Button>
            </div>
          </Col>
          <Col>
            <div className="heading-table-filter">
              <FormItemDropdown placeholder="Tổng số tài khoản sinh viên"
                options={typeAccountStudent} onChange={(e) => setTypeSelect(e)}/>
            </div>
          </Col>
          <Col>
            <div className="heading-table-search">
              <FormInput
                placeholder="Nhập từ khoá cần tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
              />
            </div>
          </Col>
          <Col>
            <Button onClick={() => setExportMode(true)}>
              <DownloadOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FilterFormTable;
