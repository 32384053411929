// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sider {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  text-align: left;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  z-index: 9; }
  .sider .menu-wrapper {
    border-inline-end: 0 !important; }
  .sider .sidebar-screen {
    max-height: 850px; }
    @media (max-width: 1366px) {
      .sider .sidebar-screen {
        max-height: 670px; } }

@media (max-width: 900px) {
  .sider {
    display: none; }
  .menu-mobile {
    padding: 0 10px; } }
`, "",{"version":3,"sources":["webpack://./src/layout/sidebar/sidebar.scss","webpack://./src/styles/_mixin.scss"],"names":[],"mappings":"AAEA;EACE,yCAAyC;EACzC,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,UAAU,EAAA;EALZ;IAQI,+BAA+B,EAAA;EARnC;IAWI,iBAAiB,EAAA;IACjB;MAZJ;QAaM,iBAAiB,EAAA,EAEpB;;ACVC;EDeF;IACE,aAAa,EAAA;EAGf;IACE,eAAe,EAAA,EAChB","sourcesContent":["@import \"../../styles/mixin\";\n\n.sider {\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);\n  text-align: left;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.85);\n  z-index: 9;\n\n  .menu-wrapper {\n    border-inline-end: 0 !important;\n  }\n  .sidebar-screen{\n    max-height: 850px;\n    @media (max-width: 1366px) {\n      max-height: 670px;\n    }\n  }\n}\n\n\n@include respond(tab-port) {\n  .sider {\n    display: none;\n  }\n\n  .menu-mobile {\n    padding: 0 10px;\n  }\n}","@mixin respond($breakpoint) {\n  @if $breakpoint == phone {\n    @media (max-width: 600px) {\n      @content\n    }\n  }\n  @if $breakpoint == tab-port {\n    @media (max-width: 900px) {\n      @content\n    }; //900px\n  }\n  @if $breakpoint == tab-land {\n    @media (max-width: 1200px) {\n      @content\n    }; //1200px\n  }\n\n  @if $breakpoint == desktop {\n    @media (min-width: 996px) {\n      @content\n    }; //1800px\n  }\n\n  @if $breakpoint == big-desktop {\n    @media (min-width: 1800px) {\n      @content\n    }; //1800px\n  }\n  @if $breakpoint == scale-screen {\n    @media only screen and(-webkit-min-device-pixel-ratio: 1.25),\n    only screen and(-o-min-device-pixel-ratio: 5/4),\n    only screen and(min-resolution: 120dpi),\n    only screen and(min-resolution: 1.25dppx) {\n      @content\n    }\n    // screen scale > 100%\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
