import React, { FunctionComponent } from "react";
import { Col, Form, message, Row } from "antd";
import { FormInstance } from "antd/lib";
import { get, isEmpty } from "lodash";
import "./FormCreateOrganizeAppraisal.scss";
import FormInput from "../../../../../../components/form-input/FormInput";

const validations = {
  organizationName: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length < 6) {
        return Promise.reject(
          new Error("Tên hội đồng thẩm định phải trên 6 ký tự.")
        );
      }
      if (value && value.length > 255) {
        return Promise.reject(
          new Error("Tên hội đồng thẩm định không vượt quá 255 ký tự.")
        );
      }
      if (!value) {
        return Promise.reject(
          new Error("Vui lòng nhập tên hội đồng thẩm định.")
        );
      }
      return Promise.resolve();
    },
  },
  organizationCode: {
    required: true,
    validator: (_: unknown, value: string) => {
      if (value && value.length < 2) {
        return Promise.reject(
          new Error("Mã hội đồng thẩm định phải trên 2 ký tự.")
        );
      }
      if (value && value.length > 25) {
        return Promise.reject(
          new Error("Mã hội đồng thẩm định không vượt quá 25 ký tự.")
        );
      }
      if (!value) {
        return Promise.reject(
          new Error("Vui lòng nhập mã hội đồng thẩm định.")
        );
      }
      return Promise.resolve();
    },
  },
};

type FormCreateOrganizeAppraisal = {
  form: FormInstance;
  onFinish?: any;
  id?: any;
};

const FormCreateOrganizeAppraisal: FunctionComponent<
  FormCreateOrganizeAppraisal
> = ({ form, onFinish }) => {
  return (
    <div className="course-plan-managment-create-appraisal">
      <Form
        layout={"vertical"}
        form={form}
        onFinish={onFinish}
        name="createSpecializedGroup"
        initialValues={{
          ["active"]: true,
        }}
      >
        <Row gutter={12}>
          <Col span={24} className="colItem">
            <Form.Item
              name="organizationName"
              rules={[validations.organizationName]}
              label="Tên hội đồng"
            >
              <FormInput placeholder="Nhập tên hội đồng thẩm định" />
            </Form.Item>
            <span className="label-down-text">
              Tên hiển thị công khai hội đồng thẩm định
            </span>
          </Col>
          <Col span={24} className="colItem">
            <Form.Item
              name="organizationCode"
              rules={[validations.organizationCode]}
              label="Mã hội đồng"
            >
              <FormInput placeholder="Nhập mã hội đồng thẩm định" />
            </Form.Item>
            <span className="label-down-text">
              Định danh duy nhất xác định hội đồng thẩm định
            </span>
          </Col>
          <Col span={24}>
            <Form.Item name="organizationRemark" label="Ghi chú">
              <FormInput placeholder="Nhập ghi chú (nếu có)" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormCreateOrganizeAppraisal;
