import React, { useEffect, useMemo, useState } from "react";
import "./detail.scss";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { ListQuestion } from "../../../../../../types/survey-managment/survey";
import { optionsDropDownQuestionType } from "./constant/data";
import { useSurveyDetail } from "../../../../../../stores/stores";
import QuestionCard from "./QuestionCard";
import QuestionCardFilter from "./QuestionCardFilter";

interface DetailQuestionProps {
  listSectionQuestions: ListQuestion[];
  sectionId: number;
  updateListSectionQuestions: (data: ListQuestion[]) => void;
  showDetailSection: boolean;
}

const DetailQuestionFilter: React.FC<DetailQuestionProps> = ({
  listSectionQuestions,
  sectionId,
  updateListSectionQuestions,
  showDetailSection,
}) => {
  const [typeQuestionId, setTypeQuestionId] = useState(1);
  const [isOpenAddDescription, setIsOpenAddDescription] = useState(false);
  const [valueQuillContainer, setValueQuillContainer] = useState("");
  const updateSectionQuestion = useSurveyDetail(
    (state) => state.updateSectionListQuestion
  );

  const listData = useMemo(() => listSectionQuestions, [listSectionQuestions]);
  console.log('list question')
  console.log({ listSectionQuestions })
  // Di chuyển câu hỏi lên trên
  const moveQuestionUp = (
    list: ListQuestion[],
    index: number
  ): ListQuestion[] => {
    if (index <= 0 || index >= list.length) return list;

    const newList = [...list];
    [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];

    // Cập nhật lại orderNumber
    newList[index].orderNumber = index; // Cập nhật orderNumber của phần tử bị di chuyển
    newList[index - 1].orderNumber = index + 1; // Cập nhật orderNumber của phần tử mới

    return newList;
  };

  // Di chuyển câu hỏi xuống dưới
  const moveQuestionDown = (
    list: ListQuestion[],
    index: number
  ): ListQuestion[] => {
    if (index < 0 || index >= list.length - 1) return list;

    const newList = [...list];
    [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];

    // Cập nhật lại orderNumber
    newList[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển
    newList[index + 1].orderNumber = index + 2; // Cập nhật orderNumber của phần tử mới

    return newList;
  };

  // Sao chép một câu hỏi
  const copyQuestion = (
    list: ListQuestion[],
    index: number
  ): ListQuestion[] => {
    if (index < 0 || index >= list.length) return list;
    const newList = [...list];
    const copiedQuestion = { ...newList[index], id: undefined }; // Xóa id để tạo bản sao mới
    newList.splice(index + 1, 0, copiedQuestion); // Chèn bản sao sau câu hỏi gốc

    // Đánh số lại orderNumber
    for (let i = index + 1; i < newList.length; i++) {
      newList[i].orderNumber = i + 1; // Gán lại orderNumber mới
    }

    return newList;
  };

  // Xóa câu hỏi
  const deleteQuestion = (
    list: ListQuestion[],
    index: number
  ): ListQuestion[] => {
    if (index < 0 || index >= list.length) return list;
    const newList = [...list];
    newList.splice(index, 1);

    // Đánh số lại orderNumber
    for (let i = index; i < newList.length; i++) {
      newList[i].orderNumber = i + 1; // Gán lại orderNumber mới
    }

    return newList;
  };

  const handleDeleteQuestion = (index: number, orderNumber?: number) => {
    const updatedList = deleteQuestion(listSectionQuestions, index);
    console.log(`updatedList::`, updatedList);

    updateListSectionQuestions(updatedList);
  };

  const handleCopyQuestion = (index: number) => {
    const updatedList = copyQuestion(listSectionQuestions, index);
    console.log(`updatedList::`, updatedList);

    updateListSectionQuestions(updatedList);
  };

  const moveItemUp = (index: number) => {
    const updatedList = moveQuestionUp(listSectionQuestions, index);

    console.log(`updatedList::`, updatedList);
    updateListSectionQuestions(updatedList);
  };

  const moveItemDown = (index: number) => {
    const updatedList = moveQuestionDown(listSectionQuestions, index);
    console.log(`updatedList::`, updatedList);
    updateListSectionQuestions(updatedList);
  };

  const handleChangeTypeQuestion = (value: string) => {
    const objectType = optionsDropDownQuestionType.find(
      (option) => option.value === value
    );
    if (objectType && objectType.type) {
      setTypeQuestionId(objectType.type);
    }
  };

  return (
    <div>
      <div>
        {listSectionQuestions.map((sectionQuestion: ListQuestion, index: number) => {
          const updateSectionQuestionData = (data: ListQuestion) => {

            const updatedList = [...listSectionQuestions];

            const indexToUpdate = updatedList.findIndex(
              (item) => item.orderNumber === data.orderNumber
            );

            if (indexToUpdate !== -1) {
              updatedList[indexToUpdate] = data;
              updateListSectionQuestions(updatedList);
            }
          };

          return (
            <QuestionCardFilter
              key={index}
              index={index}
              sectionQuestion={sectionQuestion}
              updateNewSectionQuestion={updateSectionQuestionData}
              showDetailSection={showDetailSection}
              onChangeTypeQuestion={handleChangeTypeQuestion}
              onDeleteQuestion={handleDeleteQuestion}
              onCopyQuestion={handleCopyQuestion}
              onMoveUp={moveItemUp}
              onMoveDown={moveItemDown}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DetailQuestionFilter;
