import {StateCreator} from "zustand";


export interface AccountTeacherSlice {
  accountTeachers: [],
  error: boolean,
  success: boolean,
  result: null,
  resultResponse: null,
  setAccountTeachers: (data: any) => void,
}


export const createAccountTeachersSlice: StateCreator<AccountTeacherSlice> = (set, get) => ({
  accountTeachers: [],
  loading: false,
  error: false,
  success: false,
  result: null,
  resultResponse: null,
  setAccountTeachers: (data) => {
    set(() => ({accountTeachers: data}))
  }
})
