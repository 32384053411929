import { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.scss";
import { useAuthStore } from "../../stores/stores";
import axios from "axios";
import { BASE_URL_V2 } from "../../config/api/configApiv2";

interface TextEditorProps {
  placeholder?: string;
  value?: any;
  onChange?: (value: string) => void;
}

function TextEditor({
  placeholder = "",
  value = "",
  onChange = () => {},
}: TextEditorProps) {
  const [currentValue, setCurrentValue] = useState("");
  const accessToken = useAuthStore((state) => state.accessToken);
  const reactQuillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const uploadToCloudinary = async (
    file: File,
    type: string
  ): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      BASE_URL_V2 + "/mooc-course-block-quiz/upload-file",
      formData,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const url = response.data.filePath;
    return url;
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const url = await uploadToCloudinary(file, "image");
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          range && quill.getEditor().insertEmbed(range.index, "image", url);
          const img = quill.getEditor().root.querySelector(`img[src="${url}"]`);
          if (img) {
            img.setAttribute(
              "style",
              `width: 150px; height: 150px; margin-bottom: 10px`
            );
            img.classList.add("custom-img-class");
          }
        }
      }
    };
  }, []);

  const videoHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "video/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const url = await uploadToCloudinary(file, "video");
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          range && quill.getEditor().insertEmbed(range.index, "video", url);
        }
      }
    };
  }, []);

  const handleChange = (value: string) => {
    onChange(value);
    setCurrentValue(value);
  };
  const FORMAT_TEXT_EDITOR = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code",
    "list",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];
  const MODULES_TEXT_EDITOR = {
    toolbar: {
      container: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        [{ header: 1 }, { header: 2 }],
        ["blockquote", "code"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "" }, { align: "center" }, { align: "right" }],
        ["link", "image", "video"],
        ["formula"],
      ],
      handlers: {
        image: imageHandler,
        video: videoHandler,
      },
    },
  };

  return (
    <div className="text-editor">
      <ReactQuill
        ref={reactQuillRef}
        theme="snow"
        placeholder={placeholder}
        modules={MODULES_TEXT_EDITOR}
        formats={FORMAT_TEXT_EDITOR}
        value={currentValue}
        onChange={handleChange}
        style={{ minHeight: 65 }}
      />
    </div>
  );
}

export default TextEditor;
