export const dataStatus = [
  {
    value: true,
    label: "Hoạt động"
  },
  {
    value: false,
    label: "Không hoạt động"
  }
]

export enum StatusEum {
  SAVE_DRAFT = 1,
  WAIT_APPROVE = 2,
  APPROVED = 3,
  REFUSE = 4,
  PUBLISHED = 5,
  END = 6,
}
export enum ApprovalStatusEnum {
  WAIT_APPROVE = 1,
  APPROVED = 2,
  REFUSE = 3,
  REVOKE = 4,
}
export enum CoursePlanStatus {
  SAVE_DRAFT = 1,
  WAIT_APPROVE = 2,
  APPROVED = 3,
  REFUSE = 4,
}
export const surveyStatus = [
  {
    value: 'published',
    label: 'Đã xuất bản'
  },
  {
    value: 'draft',
    label: 'Bản nháp'
  },
  {
    value: 'waitConfirm',
    label: 'Chờ duyệt'
  },
  {
    value: 'ended',
    label: 'Kết thúc'
  },
]

export const surveyType = [
  {
    value: 'self',
    label: 'Tự triển khai'
  },
  {
    value: 'coop',
    label: 'Hợp tác xây dựng'
  },
]


export const validateFormCreate = ['surveyName', 'forDepartment', 'surveyStatus', 'surveyType', 'createdAt', 'publishedAt', 'userCreate']

export const validateFieldFilter = ['surveyStatus', 'surveyType', 'createdAt', 'publishedAt']

