// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-settings_tab-survey {
  width: 100%;
  display: flex;
  flex-direction: column; }
  .wrapper-settings_tab-survey .tab-survey-page {
    margin: 15px;
    background-color: #fff;
    border-radius: 4px; }
    .wrapper-settings_tab-survey .tab-survey-page .tab-survey-page_header {
      display: flex;
      justify-content: space-between;
      padding: 15px 15px 0 15px; }
      .wrapper-settings_tab-survey .tab-survey-page .tab-survey-page_header .question-survey_title {
        font-size: 22px; }
    .wrapper-settings_tab-survey .tab-survey-page .filter-detail {
      padding: 0 15px 0 0; }
    .wrapper-settings_tab-survey .tab-survey-page .ant-table-thead th.survey-form-id {
      width: 10%; }
    .wrapper-settings_tab-survey .tab-survey-page .ant-table-thead th.survey-form-date {
      width: 15%; }
    .wrapper-settings_tab-survey .tab-survey-page .ant-table-thead th.survey-form-name {
      width: 15%; }
    .wrapper-settings_tab-survey .tab-survey-page .ant-table-thead th.survey-form-event {
      overflow: hidden; }
    .wrapper-settings_tab-survey .tab-survey-page .ant-table-thead th.survey-form-actions {
      width: 15%; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/survey-plan/survey-detail/survey-question/index.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB,EAAA;EAHxB;IAMI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB,EAAA;IARtB;MAWM,aAAa;MACb,8BAA8B;MAC9B,yBAAyB,EAAA;MAb/B;QAgBQ,eAAe,EAAA;IAhBvB;MAqBM,mBAAmB,EAAA;IArBzB;MA0BQ,UAAU,EAAA;IA1BlB;MA8BQ,UAAU,EAAA;IA9BlB;MAkCQ,UAAU,EAAA;IAlClB;MAsCQ,gBAAgB,EAAA;IAtCxB;MA0CQ,UAAU,EAAA","sourcesContent":[".wrapper-settings_tab-survey {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .tab-survey-page {\n    margin: 15px;\n    background-color: #fff;\n    border-radius: 4px;\n\n    .tab-survey-page_header {\n      display: flex;\n      justify-content: space-between;\n      padding: 15px 15px 0 15px;\n\n      .question-survey_title {\n        font-size: 22px;\n      }\n    }\n\n    .filter-detail {\n      padding: 0 15px 0 0;\n    }\n\n    .ant-table-thead {\n      th.survey-form-id {\n        width: 10%;\n      }\n\n      th.survey-form-date {\n        width: 15%;\n      }\n\n      th.survey-form-name {\n        width: 15%;\n      }\n\n      th.survey-form-event {\n        overflow: hidden;\n      }\n\n      th.survey-form-actions {\n        width: 15%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
