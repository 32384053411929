import { Button, Collapse, Drawer, Form, Radio, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import "./index.scss"
import { isEmpty, size } from 'lodash'
import { getDetailTeacherAccount } from '../../../service/list-account-teachers/listAccountTeachersApi'

type Props = {
  open: boolean
  onClose: () => void
  detailData: any
}

export default function DetailLecturerAccountAdminDrawer(props: Props) {
  const { open, onClose, detailData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataDetail, setDataDetail] = useState<any>({})

  const getListDataDetail = async () => {
    await getDetailTeacherAccount(detailData?.id).then((res) => {
      if (res.status === 200) {
        setDataDetail(res.data?.data)
      }
    })
  }

  useEffect(() => {
    if (open) {
      getListDataDetail();
    }
  }, [open]);

  return (
    <Drawer
      title={"Xem chi tiết tài khoản giảng viên"}
      open={open}
      onClose={onClose}
      className='detail-lecturer-account-admin-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
      extra={
        <Space>
          <Button onClick={onClose}>Đóng</Button>
        </Space>
      }
    >
      <Form
        className="detail-lecturer-account-admin"
        layout={"vertical"}
        form={form}
        name="form-detail-lecturer-account-admin"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel
              header="Thông tin giảng viên"
              key="1"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Cơ sở đào tạo</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.unitName}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Giảng viên</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.name}</p></span>
              </div>
            </Panel>

            <Panel
              header="Thông tin tài khoản"
              key="2"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Email đăng nhập</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Vai trò</Typography.Title>
                  {
                    dataDetail?.roles?.map((item: any) => (
                      <p className='mt-0.5 ml-2'>{item?.name}</p>
                    ))
                  }
                </span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Trạng thái</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.isActive ? 'Mở khóa' : "Khóa"}</p></span>
              </div>
            </Panel>
          </Collapse>
        </div>
      </Form>
    </Drawer>
  )
}
