// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-chapter .add-chapter-btn {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end; }

.add-chapter .add-chapter-confirm {
  color: #1677FF;
  padding-top: 24px;
  display: flex;
  justify-content: space-between; }
  .add-chapter .add-chapter-confirm .add-chapter-back {
    cursor: pointer; }
    .add-chapter .add-chapter-confirm .add-chapter-back:hover .ant-typography {
      color: #4096ff; }
    .add-chapter .add-chapter-confirm .add-chapter-back .ant-typography {
      color: #1677FF; }

.add-chapter .add-chapter-describe {
  padding: 12px 0px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  opacity: 0.7; }

.add-chapter .radio-item {
  padding: 10px 24px; }
  .add-chapter .radio-item .add-chapter-title {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500; }
`, "",{"version":3,"sources":["webpack://./src/components/drawer/add-chapter/addChapter.scss"],"names":[],"mappings":"AAAA;EAEI,iBAAiB;EACjB,aAAa;EACb,yBAAyB,EAAA;;AAJ7B;EAQI,cAAc;EACd,iBAAiB;EACjB,aAAa;EACb,8BAA8B,EAAA;EAXlC;IAcM,eAAe,EAAA;IAdrB;MAkBU,cAAc,EAAA;IAlBxB;MAuBQ,cAAc,EAAA;;AAvBtB;EA8BI,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,YAAY,EAAA;;AAlChB;EAsCI,kBAAkB,EAAA;EAtCtB;IAyCM,kBAAkB;IAClB,eAAe;IACf,gBAAgB,EAAA","sourcesContent":[".add-chapter {\n  .add-chapter-btn {\n    padding-top: 24px;\n    display: flex;\n    justify-content: flex-end;\n  }\n\n  .add-chapter-confirm {\n    color: #1677FF;\n    padding-top: 24px;\n    display: flex;\n    justify-content: space-between;\n\n    .add-chapter-back {\n      cursor: pointer;\n\n      &:hover {\n        .ant-typography {\n          color: #4096ff;\n        }\n      }\n\n      .ant-typography {\n        color: #1677FF;\n\n      }\n    }\n  }\n\n  .add-chapter-describe {\n    padding: 12px 0px;\n    margin-left: 8px;\n    font-size: 14px;\n    font-weight: normal;\n    opacity: 0.7;\n  }\n\n  .radio-item {\n    padding: 10px 24px;\n\n    .add-chapter-title {\n      margin-bottom: 0px;\n      font-size: 16px;\n      font-weight: 500;\n    }\n  }\n\n\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
