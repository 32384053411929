export const ExcelFileIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M16.6654 2H7.93203C7.76536 2 7.5987 2.06667 7.46536 2.18333L2.53203 6.96667C2.3987 7.1 2.33203 7.26667 2.33203 7.45V19.6667C2.33203 21.1667 3.16536 22 4.66536 22H16.6654C18.1654 22 18.9987 21.1667 18.9987 19.6667V4.33333C18.9987 2.83333 18.1654 2 16.6654 2ZM7.2487 4.2V6.75H4.61536L7.2487 4.2ZM17.832 19.6667C17.832 20.2 17.682 20.45 17.5654 20.5667C17.4487 20.6833 17.1987 20.8333 16.6654 20.8333H4.66536C4.13203 20.8333 3.88203 20.6833 3.76536 20.5667C3.6487 20.45 3.4987 20.2 3.4987 19.6667V7.91667H3.58203H7.66536C8.08203 7.91667 8.41536 7.58333 8.41536 7.16667V3.23333C8.41536 3.21667 8.41536 3.2 8.41536 3.16667H16.6654C17.1987 3.16667 17.4487 3.31667 17.5654 3.43333C17.682 3.55 17.832 3.8 17.832 4.33333V19.6667Z"
          fill="#389E0D"/>
    <path
      d="M7.33464 18.6665C6.2513 18.6665 5.66797 18.0832 5.66797 16.9998V12.3332C5.66797 11.2498 6.2513 10.6665 7.33464 10.6665H19.8346C20.918 10.6665 21.5013 11.2498 21.5013 12.3332V16.9998C21.5013 18.0832 20.918 18.6665 19.8346 18.6665H7.33464Z"
      fill="#389E0D"/>
    <path
      d="M9.16484 13.6331C9.29818 13.5331 9.46484 13.4997 9.66484 13.4997C9.76484 13.4997 9.86484 13.5164 9.94818 13.5497C10.0315 13.5831 10.1148 13.6164 10.1815 13.6831C10.2482 13.7331 10.2982 13.7997 10.3482 13.8831C10.3982 13.9664 10.4148 14.0497 10.4315 14.1497H11.2148C11.1982 13.9331 11.1315 13.7497 11.0482 13.5831C10.9648 13.4164 10.8482 13.2831 10.7148 13.1664C10.5815 13.0497 10.4148 12.9664 10.2315 12.8997C10.0482 12.8331 9.84818 12.8164 9.64818 12.8164C9.33151 12.8164 9.04818 12.8831 8.78151 13.0331C8.53151 13.1831 8.33151 13.3831 8.18151 13.6664C8.03151 13.9497 7.96484 14.2831 7.96484 14.6831C7.96484 15.0831 8.03151 15.4164 8.18151 15.6997C8.33151 15.9831 8.53151 16.1831 8.78151 16.3331C9.03151 16.4831 9.33151 16.5497 9.64818 16.5497C9.88151 16.5497 10.0982 16.5164 10.2815 16.4497C10.4648 16.3831 10.6315 16.2831 10.7648 16.1664C10.8982 16.0497 11.0148 15.8997 11.0815 15.7497C11.1648 15.5997 11.2148 15.4331 11.2315 15.2664H10.4482C10.4315 15.3664 10.3982 15.4497 10.3482 15.5331C10.2982 15.6164 10.2482 15.6664 10.1815 15.7331C10.1148 15.7831 10.0315 15.8331 9.94818 15.8497C9.86484 15.8831 9.76484 15.8831 9.66484 15.8831C9.48151 15.8831 9.31484 15.8331 9.18151 15.7497C9.04818 15.6664 8.93151 15.5331 8.84818 15.3497C8.76484 15.1664 8.73151 14.9497 8.73151 14.6997C8.73151 14.4497 8.76484 14.2331 8.84818 14.0497C8.91484 13.8664 9.03151 13.7331 9.16484 13.6331Z"
      fill="white"/>
    <path
      d="M14.7151 14.7498C14.6151 14.6665 14.4984 14.5831 14.3651 14.5331C14.2318 14.4665 14.0818 14.4331 13.9318 14.3831L13.5984 14.2998C13.5151 14.2831 13.4484 14.2665 13.3818 14.2331C13.3151 14.1998 13.2484 14.1831 13.1984 14.1498C13.1484 14.1165 13.0984 14.0665 13.0651 14.0165C13.0318 13.9665 13.0151 13.9165 13.0318 13.8498C13.0318 13.7665 13.0484 13.6998 13.0984 13.6331C13.1484 13.5665 13.2151 13.5165 13.2984 13.4831C13.3818 13.4498 13.4984 13.4331 13.6151 13.4331C13.7984 13.4331 13.9484 13.4665 14.0484 13.5498C14.1484 13.6331 14.2151 13.7331 14.2318 13.8831H14.9651C14.9651 13.6665 14.8984 13.4831 14.7984 13.3165C14.6818 13.1498 14.5318 13.0165 14.3318 12.9331C14.1318 12.8331 13.8984 12.7998 13.6318 12.7998C13.3651 12.7998 13.1318 12.8498 12.9151 12.9331C12.6984 13.0165 12.5484 13.1498 12.4151 13.3165C12.2984 13.4831 12.2318 13.6665 12.2318 13.8998C12.2318 14.1665 12.3151 14.3831 12.4984 14.5331C12.6818 14.6998 12.9151 14.8165 13.2318 14.8831L13.6318 14.9831C13.7651 15.0165 13.8818 15.0498 13.9651 15.0831C14.0651 15.1165 14.1318 15.1665 14.1818 15.2331C14.2318 15.2831 14.2484 15.3498 14.2484 15.4331C14.2484 15.5165 14.2151 15.5998 14.1651 15.6665C14.1151 15.7331 14.0318 15.7831 13.9318 15.8165C13.8318 15.8498 13.7151 15.8665 13.5984 15.8665C13.4651 15.8665 13.3484 15.8498 13.2484 15.7998C13.1484 15.7665 13.0651 15.6998 12.9984 15.6165C12.9318 15.5331 12.8984 15.4331 12.8984 15.3165H12.1484C12.1484 15.5831 12.2151 15.7998 12.3318 15.9831C12.4484 16.1665 12.6151 16.2998 12.8318 16.3831C13.0484 16.4665 13.2984 16.5165 13.5984 16.5165C13.8984 16.5165 14.1484 16.4665 14.3484 16.3831C14.5651 16.2998 14.7151 16.1665 14.8318 15.9998C14.9484 15.8331 14.9984 15.6498 14.9984 15.4165C14.9984 15.2665 14.9651 15.1331 14.9151 15.0165C14.8984 14.9331 14.8151 14.8331 14.7151 14.7498Z"
      fill="white"/>
    <path
      d="M18.4977 12.8667L17.6143 15.6334H17.581L16.6977 12.8667H15.8477L17.0977 16.5H18.0977L19.3477 12.8667H18.4977Z"
      fill="white"/>
  </svg>
)

export const PauseIconPrimary = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M5 4.98951C5 4.01835 5 3.53277 5.20249 3.2651C5.37889 3.03191 5.64852 2.88761 5.9404 2.87018C6.27544 2.85017 6.67946 3.11953 7.48752 3.65823L18.0031 10.6686C18.6708 11.1137 19.0046 11.3363 19.1209 11.6168C19.2227 11.8621 19.2227 12.1377 19.1209 12.383C19.0046 12.6635 18.6708 12.886 18.0031 13.3312L7.48752 20.3415C6.67946 20.8802 6.27544 21.1496 5.9404 21.1296C5.64852 21.1122 5.37889 20.9679 5.20249 20.7347C5 20.467 5 19.9814 5 19.0103V4.98951Z"
      stroke="#7F56D9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const QuestionMarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_572_3377)">
      <path
        d="M7.57484 7.50008C7.77076 6.94314 8.15746 6.4735 8.66647 6.17436C9.17547 5.87521 9.77392 5.76586 10.3558 5.86567C10.9377 5.96549 11.4655 6.26802 11.8457 6.71969C12.226 7.17136 12.4341 7.74302 12.4332 8.33342C12.4332 10.0001 9.93317 10.8334 9.93317 10.8334M9.99984 14.1667H10.0082M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_572_3377">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const HoursGlassIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6 6L3.86356 4.21963C3.54613 3.95511 3.38742 3.82285 3.27332 3.66072C3.17222 3.51707 3.09714 3.35677 3.05151 3.18714C3 2.99569 3 2.7891 3 2.3759V1M6 6L8.13644 4.21963C8.45387 3.95511 8.61258 3.82285 8.72668 3.66072C8.82778 3.51707 8.90286 3.35677 8.94849 3.18714C9 2.99569 9 2.7891 9 2.3759V1M6 6L3.86356 7.78037C3.54613 8.04489 3.38742 8.17715 3.27332 8.33928C3.17222 8.48293 3.09714 8.64323 3.05151 8.81286C3 9.00431 3 9.2109 3 9.6241V11M6 6L8.13644 7.78037C8.45387 8.04489 8.61258 8.17715 8.72668 8.33928C8.82778 8.48293 8.90286 8.64323 8.94849 8.81286C9 9.00431 9 9.2109 9 9.6241V11M2 1H10M2 11H10"
      stroke="#9E77ED"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M10.0003 7.91675V11.2501L12.0837 12.5001M10.0003 4.16675C6.08831 4.16675 2.91699 7.33806 2.91699 11.2501C2.91699 15.1621 6.08831 18.3334 10.0003 18.3334C13.9123 18.3334 17.0837 15.1621 17.0837 11.2501C17.0837 7.33806 13.9123 4.16675 10.0003 4.16675ZM10.0003 4.16675V1.66675M8.33366 1.66675H11.667M16.9411 4.66012L15.6911 3.41012L16.3161 4.03512M3.05951 4.66012L4.30951 3.41012L3.68451 4.03512"
      stroke="#6941C6"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const NextIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M3.33301 10H16.6663M16.6663 10L11.6663 5M16.6663 10L11.6663 15"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PreviousIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.8337 9.99999H4.16699M4.16699 9.99999L10.0003 15.8333M4.16699 9.99999L10.0003 4.16666"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloudIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PdfICon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="40"
    viewBox="0 0 32 40"
    fill="none"
  >
    <path
      d="M0.75 4C0.75 2.20508 2.20508 0.75 4 0.75H20C20.1212 0.75 20.2375 0.798159 20.3232 0.883885L31.1161 11.6768C31.2018 11.7625 31.25 11.8788 31.25 12V36C31.25 37.7949 29.7949 39.25 28 39.25H4C2.20507 39.25 0.75 37.7949 0.75 36V4Z"
      stroke="#D0D5DD"
      strokeWidth="1.5"
    />
    <path
      d="M20 0.5V8C20 10.2091 21.7909 12 24 12H31.5"
      stroke="#D0D5DD"
      strokeWidth="1.5"
    />
  </svg>
);

export const ReloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.4993 8.33333C17.4993 8.33333 15.8285 6.05685 14.4712 4.69854C13.1138 3.34022 11.238 2.5 9.16602 2.5C5.02388 2.5 1.66602 5.85786 1.66602 10C1.66602 14.1421 5.02388 17.5 9.16602 17.5C12.5853 17.5 15.4701 15.2119 16.3729 12.0833M17.4993 8.33333V3.33333M17.4993 8.33333H12.4993"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M7.5 2.5H12.5M2.5 5H17.5M15.8333 5L15.2489 13.7661C15.1612 15.0813 15.1174 15.7389 14.8333 16.2375C14.5833 16.6765 14.206 17.0294 13.7514 17.2497C13.235 17.5 12.5759 17.5 11.2578 17.5H8.74221C7.42409 17.5 6.76503 17.5 6.24861 17.2497C5.79396 17.0294 5.41674 16.6765 5.16665 16.2375C4.88259 15.7389 4.83875 15.0813 4.75107 13.7661L4.16667 5M8.33333 8.75V12.9167M11.6667 8.75V12.9167"
      stroke="#B42318"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MicroIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M16.3327 8.33317V9.99984C16.3327 13.2215 13.721 15.8332 10.4993 15.8332M4.66602 8.33317V9.99984C4.66602 13.2215 7.27769 15.8332 10.4993 15.8332M10.4993 15.8332V18.3332M7.16602 18.3332H13.8327M10.4993 12.4998C9.11864 12.4998 7.99935 11.3805 7.99935 9.99984V4.1665C7.99935 2.78579 9.11864 1.6665 10.4993 1.6665C11.8801 1.6665 12.9993 2.78579 12.9993 4.1665V9.99984C12.9993 11.3805 11.8801 12.4998 10.4993 12.4998Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RecordIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.3327 7.44313C18.3327 6.93829 18.3327 6.68586 18.2329 6.56898C18.1462 6.46756 18.0163 6.41373 17.8833 6.4242C17.7301 6.43626 17.5516 6.61475 17.1946 6.97173L14.166 10.0003L17.1946 13.0289C17.5516 13.3859 17.7301 13.5644 17.8833 13.5765C18.0163 13.5869 18.1462 13.5331 18.2329 13.4317C18.3327 13.3148 18.3327 13.0624 18.3327 12.5575V7.44313Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66602 8.16699C1.66602 6.76686 1.66602 6.0668 1.9385 5.53202C2.17818 5.06161 2.56063 4.67916 3.03104 4.43948C3.56582 4.16699 4.26588 4.16699 5.66602 4.16699H10.166C11.5661 4.16699 12.2662 4.16699 12.801 4.43948C13.2714 4.67916 13.6538 5.06161 13.8935 5.53202C14.166 6.0668 14.166 6.76686 14.166 8.16699V11.8337C14.166 13.2338 14.166 13.9339 13.8935 14.4686C13.6538 14.939 13.2714 15.3215 12.801 15.5612C12.2662 15.8337 11.5661 15.8337 10.166 15.8337H5.66602C4.26588 15.8337 3.56582 15.8337 3.03104 15.5612C2.56063 15.3215 2.17818 14.939 1.9385 14.4686C1.66602 13.9339 1.66602 13.2338 1.66602 11.8337V8.16699Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RefreshIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
  >
    <path
      d="M18.1693 8.33333C18.1693 8.33333 16.4985 6.05685 15.1411 4.69854C13.7837 3.34022 11.9079 2.5 9.83594 2.5C5.6938 2.5 2.33594 5.85786 2.33594 10C2.33594 14.1421 5.6938 17.5 9.83594 17.5C13.2552 17.5 16.14 15.2119 17.0428 12.0833M18.1693 8.33333V3.33333M18.1693 8.33333H13.1693"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RemuseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.16797 4.1576C4.16797 3.3483 4.16797 2.94365 4.33671 2.72059C4.48371 2.52626 4.7084 2.40601 4.95163 2.39149C5.23083 2.37482 5.56752 2.59928 6.2409 3.0482L15.0039 8.89018C15.5603 9.26111 15.8385 9.44658 15.9354 9.68035C16.0202 9.88473 16.0202 10.1144 15.9354 10.3188C15.8385 10.5526 15.5603 10.738 15.0039 11.109L6.2409 16.951C5.56752 17.3999 5.23083 17.6243 4.95163 17.6077C4.7084 17.5931 4.48371 17.4729 4.33671 17.2786C4.16797 17.0555 4.16797 16.6509 4.16797 15.8416V4.1576Z"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PauseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.5 15V9M14.5 15V9M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
      stroke="#344054"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PausePrimaryIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_869_113375)">
      <path
        d="M7.91797 12.4998V7.49984M12.0846 12.4998V7.49984M18.3346 9.99984C18.3346 14.6022 14.6037 18.3332 10.0013 18.3332C5.39893 18.3332 1.66797 14.6022 1.66797 9.99984C1.66797 5.39746 5.39893 1.6665 10.0013 1.6665C14.6037 1.6665 18.3346 5.39746 18.3346 9.99984Z"
        stroke="#7F56D9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_869_113375">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const ExportIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.5 12.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_513_119066)">
      <path
        d="M10.0013 18.3332C14.6037 18.3332 18.3346 14.6022 18.3346 9.99984C18.3346 5.39746 14.6037 1.6665 10.0013 1.6665C5.39893 1.6665 1.66797 5.39746 1.66797 9.99984C1.66797 14.6022 5.39893 18.3332 10.0013 18.3332Z"
        stroke="#7F56D9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91797 7.47095C7.91797 7.07321 7.91797 6.87434 8.00109 6.76332C8.07352 6.66657 8.1844 6.60603 8.30495 6.59742C8.44329 6.58754 8.61057 6.69508 8.94514 6.91016L12.879 9.43905C13.1693 9.62569 13.3145 9.719 13.3646 9.83766C13.4084 9.94134 13.4084 10.0583 13.3646 10.162C13.3145 10.2807 13.1693 10.374 12.879 10.5606L8.94514 13.0895C8.61057 13.3046 8.44329 13.4121 8.30495 13.4023C8.1844 13.3936 8.07352 13.3331 8.00109 13.2364C7.91797 13.1253 7.91797 12.9265 7.91797 12.5287V7.47095Z"
        stroke="#7F56D9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_513_119066">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const ExpandDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M5 7.5L10 12.5L15 7.5"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const QuestionIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_869_110422)">
      <path
        d="M9.99984 13.3327V9.99935M9.99984 6.66602H10.0082M18.3332 9.99935C18.3332 14.6017 14.6022 18.3327 9.99984 18.3327C5.39746 18.3327 1.6665 14.6017 1.6665 9.99935C1.6665 5.39698 5.39746 1.66602 9.99984 1.66602C14.6022 1.66602 18.3332 5.39698 18.3332 9.99935Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_869_110422">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export const ArrowLeftIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.8332 9.99935H4.1665M4.1665 9.99935L9.99984 15.8327M4.1665 9.99935L9.99984 4.16602"
      stroke="#6941C6"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CloseOrangeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      d="M11.3332 5.16669L4.6665 11.8334M4.6665 5.16669L11.3332 11.8334"
      stroke="#F79009"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
