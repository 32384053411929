import { Checkbox, Form, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routesConfig } from "../../../../config/routes";
import {
  getListPublicLearningTrend
} from "../../../../service/prediction-model";

interface IProps {
  element: any;
  setElement: (item: any) => void;
  setFieldsInput: (item: any) => void;
  fieldsInput: any;
  setFieldsAction: (item: any) => void;
  fieldsAction: any;
  isEdit?: boolean;
}

const ElementStatistical = ({
  element,
  setElement,
  setFieldsInput,
  fieldsInput,
  setFieldsAction,
  fieldsAction,
  isEdit,
}: IProps) => {
  const [dataOptionInput, setDataOptionInput] = useState<any>([]);
  const [dataOptionAction, setDataOptionAction] = useState<any>([]);
  const onChangeSelect = (e: any) => {
    setElement({
      ...element,
      [e.target.name]: e.target.checked,
    });

    if (e.target.name === "infoInput" && !e.target.checked && !isEdit) {
      setFieldsInput({});
    } else if (e.target.name === "infoAction" && !e.target.checked && !isEdit) {
      setFieldsAction({});
    }
  };
  const onChangeSelectInfo = (e: any, name: string) => {
    if (name === "input") {
      setFieldsInput({
        ...fieldsInput,
        [e.target.name]: e.target.checked,
      });
    } else {
      setFieldsAction({
        ...fieldsAction,
        [e.target.name]: e.target.checked,
      });
    }
  };

  const handleChangeName = (e: any) => {
    setElement({
      ...element,
      name: e?.target?.value,
    });
  };

  const fetchOptionDataTrend = async (id: any, setDataOption: any) => {
    try {
      const response = await getListPublicLearningTrend({
        group_id: id,
      });
      const { data } = response.data;
      setDataOption(data?.data?.categories);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchOptionDataTrend(1, setDataOptionInput);
    fetchOptionDataTrend(2, setDataOptionAction);
  }, []);

  return (
    <div>
      {!isEdit && (
        <Typography.Text>
          Chọn ít nhất 01 yếu tố thống kê cài đặt mô hình phân tích xu hướng.
          Các yếu tố được thiết lập tại{" "}
          <Link to={`/prediction-model/${routesConfig.managementGroupContent}`}>
            Xây dựng nội dung nghiệp vụ mô hình
          </Link>
        </Typography.Text>
      )}
      <div className="mt-4">
        {!isEdit && (
          <Form
            name="setting"
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            className="border-b pb-2"
          >
            <Form.Item
              label="Tên danh mục cài đặt "
              valuePropName="name"
              rules={[
                { required: true, message: "Tên danh mục cài đặt là bắt buộc" },
              ]}
              required
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                name="name"
                value={element?.name}
                placeholder="Điền tên danh mục cài đặt"
                onChange={handleChangeName}
              />
            </Form.Item>
          </Form>
        )}
        <div className={isEdit ? "" : "pt-6"}>
          <p className="bg-[#FAFAFA] p-4 mt-1 rounded-md">
            <Checkbox
              className="w-full m-0"
              checked={element?.infoInput}
              name="infoInput"
              onChange={onChangeSelect}
            >
              <p className=" font-medium text-xl">
                Yếu tố thuộc nội dung thông tin đầu vào của sinh viên
              </p>
            </Checkbox>
          </p>
          {element?.infoInput && (
            <div className="pt-4">
              {dataOptionInput?.map((data: any, index: number) => (
                <div className="py-4 border-b" key={index}>
                  <Typography.Title level={5}>{data?.category?.name}</Typography.Title>
                  <div className="max-h-[200px] overflow-auto">
                    {data?.category?.fields?.map((value: any) => (
                      <Checkbox
                        className="w-full m-0"
                        checked={fieldsInput[value?.id]}
                        name={value?.id}
                        onChange={(e) => onChangeSelectInfo(e, "input")}
                      >
                        {value?.name}
                      </Checkbox>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
          <p className="bg-[#FAFAFA] p-4 mt-1 rounded-md">
            <Checkbox
              className="w-full"
              checked={element?.infoAction}
              name="infoAction"
              onChange={onChangeSelect}
            >
              <p className=" font-medium text-xl">
                Yếu tố thuộc nội dung thông tin hành vi của sinh viên
              </p>
            </Checkbox>
          </p>
          {element?.infoAction && (
            <div className="pt-4">
              {dataOptionAction?.map((data: any, index: number) => (
                <div className="py-4 border-b" key={index}>
                  <Typography.Title level={5}>{data?.category?.name}</Typography.Title>
                  <div className="max-h-[200px] overflow-auto">
                    {data?.category?.fields?.map((value: any) => (
                      <Checkbox
                        className="w-full m-0"
                        checked={fieldsAction[value?.id]}
                        name={value?.id}
                        onChange={(e) => onChangeSelectInfo(e, "action")}
                      >
                        {value?.name}
                      </Checkbox>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ElementStatistical;
