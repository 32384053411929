import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getSettingCourseStructureById: (
  id?: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/setting-course-structure/get/${id}`);
};

export const getSettingStructureCourseById: (
  id?: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/setting-course-structure/get-course/${id}`);
};

export const getSettingCourseList: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-course-structure/search-course`, dataReq);
};

export const createSettingCourseStructure: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/setting-course-structure/create`, dataReq);
};

export const updateSettingCourseStructure: (
  id: number,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-course-structure/update/${id}`, dataReq);
};

export const updateSettingCourseStructureUnit: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-course-structure/update-by-unit/${id}`, dataReq);
};

export const updateStatusCourseStructure: (
  id: string,
  status: number
) => Promise<AxiosResponse<any>> = (id, status) => {
  return axiosConfig.put(`/setting-course-structure/update-status/${id}/?status=${status}`);
};

export const updateSelectStructure: (
  id: number,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/setting-course-structure/update-active/${id}`,dataReq);
};