import {
  CheckCircleOutlined,
  InboxOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  Layout,
  Modal,
  Spin,
  Switch,
  Typography,
  Upload,
  UploadProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CommonModal from "../../../../components/modal/common";
import {
  getFormExamAutoScore,
  getLastByBlock,
  getStudentAutoScore,
  testResultAutoScore,
} from "../../../../service/auto-score";
import {
  getSettingExamSupervisionById,
  updateSettingExamSupervision,
} from "../../../../service/exam-supervision";
import { getPermissionsAuthority } from "../../../../service/setting-authority";
import { exportData } from "../../../../utils/exportData";
import ExamStudentContent from "./exam-student-content";
import { DEFAULT_PERMISSION_AUTO_SCORE } from "../../../../constants/automatic-mark";

const { Content } = Layout;
const { Title, Text } = Typography;
const { Dragger } = Upload;
const { DECENTRALIZE, AUTOSCORE } = DEFAULT_PERMISSION_AUTO_SCORE;
interface SearchType {
  search?: string;
  course_block_id?: number | string;
  skip: number;
  take: number;
}

interface IProps {
  handleChange: (key: string) => void;
  setBreadHead: any;
  setRefreshTag: (item: boolean) => void;
  refreshTag: boolean;
  permissionScore: string[];
  setPermissionScore: (item: any) => void;
}

const AutoScoring = ({
  handleChange,
  setBreadHead,
  setRefreshTag,
  refreshTag,
  permissionScore,
  setPermissionScore,
}: IProps) => {
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [searchValue, setSearchValue] = useState<string>("");
  const [fileList, setFileList] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const [isConfirm, setIsConfirm] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenLoading, setIsOpenLoading] = useState(false);
  const [formExam, setFormExam] = useState<any>([]);
  const [complete, setComplete] = useState(true);
  const [isRefreshUpload, setIsRefreshUpload] = useState(false);

  const [paramFeature, setParamFeature] = useState<any>({
    id: "",
    is_auto_mark: false,
    title: "",
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [idReport, setIdReport] = useState(NaN);
  const [idCourse, setIdCourse] = useState("");

  const fetchData = async (searchParam: SearchType) => {
    try {
      const response = await getStudentAutoScore(searchParam);
      const { data } = response.data;
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDataExamSupervision = async () => {
    try {
      const res = await getSettingExamSupervisionById(params?.id as string);
      const { data } = res.data;
      setParamFeature({
        ...paramFeature,
        id: data?.id,
        is_auto_mark: data.is_auto_mark,
        title: data?.mooc_course?.name,
      });
      console.log(data);
      
      setBreadHead({
        titleCourse: data?.mooc_course?.name,
        titleExam: data?.mooc_course_block?.mooc_course_unit?.name,
        idExam: data?.mooc_course?.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFormExam = async () => {
    try {
      const response = await getFormExamAutoScore(params?.id || "");
      const { data } = response.data;
      setFormExam(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchIDBlock = async () => {
    try {
      const response = await getLastByBlock(params?.id || "");
      const { data } = response.data;
      setIdReport(data?.id);
      setIdCourse(data?.mooc_course?.id);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPermissionData = async () => {
    try {
      const res = await getPermissionsAuthority(`${idCourse}`);
      const { data } = res.data;
      setPermissionScore(data?.scorePermission);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataExamSupervision();
    fetchFormExam();
    fetchIDBlock();
    fetchData({
      search: searchValue,
      course_block_id: params?.id,
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
    if (idCourse) {
      fetchPermissionData();
    }
  }, [pageSize, pageNumber, searchValue, idCourse]);

  const onChangeActive = async (checked: boolean, name: string) => {
    if (!checked) {
      setIsConfirm(true);
    } else {
      try {
        await updateSettingExamSupervision(paramFeature?.id, {
          is_auto_mark: checked,
        });
        setParamFeature({
          ...paramFeature,
          is_auto_mark: checked,
        });
        messageApi.open({
          type: "success",
          content: "Thay đổi trạng thái thành công",
        });
      } catch (error: any) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: error?.response?.data?.message,
        });
      }
    }
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setIsOpenLoading(false);
    setIsConfirm(false);
  };
  const handleConfirmFile = async () => {
    const formData = new FormData();
    formData.append("file", fileList);
    if (!isEmpty(fileList)) {
      setIsOpenLoading(true);
      try {
        const response = await testResultAutoScore(params?.id || "", formData);
        const { data } = response.data;
        setIdReport(data[0]?.id);

        setIsOpen(false);
        setComplete(false);
        messageApi.open({
          type: "success",
          content: "Chấm điểm tự động thành công",
        });
        setRefreshTag(!refreshTag);
        setIsRefreshUpload(true);
      } catch (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: "file không hợp lệ",
        });
      }
      setIsOpenLoading(false);
    } else {
      messageApi.open({
        type: "error",
        content: "Vui lòng chọn file chấm điểm tự động",
      });
    }
  };
  const handleConfirmNotice = async () => {
    try {
      await updateSettingExamSupervision(paramFeature?.id, {
        is_auto_mark: false,
      });
      setParamFeature({
        ...paramFeature,
        is_auto_mark: false,
      });
      messageApi.open({
        type: "success",
        content: "Thay đổi trạng thái thành công",
      });
      setIsConfirm(false);
    } catch (error) {
      console.log(error);
    }
  };
  const onDownload = () => {
    const convertForm = formExam?.map((data: any) => ({
      "ID câu hỏi (Không được sửa)": data?.id,
      "Câu hỏi (Không được sửa)": data?.question,
      "Điểm đáp án đúng": data?.setting_point?.correct || 0,
      "Điểm đáp án không đúng": data?.setting_point?.incorrect || 0,
    }));
    exportData(convertForm, "Mẫu điền kết quả chấm", ".csv");
  };

  const changeHandler = (event: any) => {
    if (isEmpty(event?.fileList)) {
      setFileList([]);
      return;
    }
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    if (event?.file?.type === "text/csv") {
      setFileList(event?.file);
    }
  };
  const propsFile: UploadProps = {
    name: "file",
    // multiple: true,
    maxCount: 1,
    beforeUpload: (file) => {
      const isCSV = file.type === "text/csv";
      if (!isCSV) {
        message.error(`${file.name} is not a csv file`);
      }
      return false;
    },
    onChange: changeHandler as any,
  };
  const handleChangeTabs = () => {
    handleChange("2");
    setIsComplete(false);
  };
  return (
    <Content>
      {contextHolder}
      <div className="px-6">
        <Card className="w-full mt-6">
          <Flex justify="space-between" align="center">
            <div>
              <Flex align="center">
                <Title level={4} className="!m-0">
                  Thực hiện chấm điểm tự động
                </Title>
                {permissionScore.includes(AUTOSCORE) && (
                  <div className="px-4">
                    <Switch
                      defaultChecked
                      onChange={(checked) =>
                        onChangeActive(checked, "ip_attendance")
                      }
                      checked={paramFeature.is_auto_mark}
                    />
                    <Text className="px-4">
                      {paramFeature.is_auto_mark
                        ? "Đang hoạt động"
                        : "Không hoạt động"}
                    </Text>
                  </div>
                )}
              </Flex>
              <Text className="text-[#00000073]">
                Vui lòng chọn bật để sử dụng tính năng <br /> Tính năng được sử
                dụng trước khi gửi kết quả cho sinh viên
              </Text>
            </div>
            {permissionScore.includes(DECENTRALIZE) && (
              <Flex>
                <Button
                  className="mr-2"
                  disabled={!paramFeature.is_auto_mark}
                  onClick={() => setIsOpen(true)}
                >
                  Nạp file kết quả chấm điểm tự động
                </Button>
                <Button
                  type="primary"
                  onClick={() => setIsComplete(true)}
                  disabled={complete}
                >
                  Hoàn thành tiến trình
                </Button>{" "}
              </Flex>
            )}
          </Flex>
        </Card>
      </div>
      {paramFeature.is_auto_mark && (
        <div className="px-6 auto-mark mt-6">
          <ExamStudentContent
            idReport={idReport}
            isRefreshUpload={isRefreshUpload}
            setIsRefreshUpload={setIsRefreshUpload}
          />
        </div>
      )}
      <CommonModal
        open={isConfirm}
        title={"Xác nhận tắt cài đặt thông báo?"}
        desc={
          "Hệ thống sẽ gỡ toàn bộ các cài đặt. Bạn có chắc chắn muốn tắt cài đặt giám sát thi cử?"
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmNotice}
        closeModal={onCloseModal}
      />
      <CommonModal
        open={isOpen}
        title={"Nạp file kết quả chấm điểm tự động"}
        desc={
          <div className="border-b pb-3">
            <Dragger {...propsFile}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Nhấn hoặc thả tệp lên từ máy tính của bạn{" "}
                <span className="text-[#1677FF]">vào đây</span>
              </p>
              <p className="ant-upload-hint">
                Định dạng tệp khả dụng: CSV (Tối đa 01 tệp - 10 MB)
              </p>
            </Dragger>
            <div className="pt-3">
              <span className="font-bold">
                Hướng dẫn các bước nạp tệp kết quả và xác nhận bắt đầu tiến
                trình:
              </span>
              <ol className="list-decimal px-4">
                <li className="list-decimal">
                  Tải mẫu điền kết quả chấm{" "}
                  <span
                    className="text-[#1677FF] cursor-pointer"
                    onClick={() => setIsOpenForm(true)}
                  >
                    vào đây
                  </span>
                </li>
                <li className="list-decimal">
                  Điền kết quả chấm điểm. Kết quả chấm cần khớp với đề thi bạn
                  đã chọn và lưu file theo định dạng .CSV - Quy định trường hợp
                  điểm lẻ (có phần thập phân) sửa dụng dấu “.”
                </li>
                <li className="list-decimal">
                  Nhấn hoặc thả tệp cần tải lên vào khu vực tải tệp
                </li>
                <li className="list-decimal">
                  Kiểm tra tệp trước khi chọn “Xác nhận"
                </li>
              </ol>
            </div>
          </div>
        }
        closeIcon={true}
        okText={"Bắt đầu"}
        cancelText={"Huỷ"}
        icon=""
        confirm={handleConfirmFile}
        closeModal={onCloseModal}
        className={"!w-full md:!w-[520px]"}
      />
      <Modal
        open={isOpenForm}
        title={"Mẫu điền kết quả chấm"}
        closeIcon={true}
        onCancel={() => setIsOpenForm(false)}
        className={"!w-full md:!w-[700px]"}
        centered
        footer=""
      >
        <div className="border-t pt-4">
          <div className="bg-[#FAFAFA] p-3 rounded-lg border">
            <Flex justify="space-between" align="center">
              <Flex align="center">
                <img
                  className="pr-2"
                  loading="eager"
                  alt=""
                  src={"/images/csv-icon.svg"}
                />
                <Text>Mẫu điền kết quả chấm.csv</Text>
              </Flex>
              <Text
                className="text-[#1677FF] cursor-pointer"
                onClick={onDownload}
              >
                Tải xuống
              </Text>
            </Flex>
          </div>
          <div className="!mt-3">
            <Text>Mẫu điền kết quả chấm xem trước:</Text>
          </div>
          <div className="border-b p-4 bg-[#0000000f] flex mt-4">
            <Text className="w-1/4 border-r text-center">
              <span>ID câu hỏi</span> <br />
              (Không được sửa)
            </Text>
            <Text className="w-1/4 border-r text-center">
              <span>Câu hỏi</span> <br />
              (Không được sửa)
            </Text>
            <Text className="w-1/4 border-r text-center">Điểm đáp án đúng</Text>
            <Text className="w-1/4 text-center">Điểm đáp án không đúng</Text>
          </div>
          <div className="overflow-auto max-h-[300px]">
            {formExam.map((exam: any, index: number) => (
              <Flex className="border-b py-4" key={index}>
                <Text className="w-1/4 px-4">{exam?.id}</Text>
                <div
                  className="w-1/4 px-4 font-medium"
                  dangerouslySetInnerHTML={{
                    __html: exam?.question,
                  }}
                />
                <Text className="w-1/4 px-4">
                  {exam?.setting_point?.correct || 0}
                </Text>
                <Text className="w-1/4 px-4">
                  {exam?.setting_point?.incorrect || 0}
                </Text>
              </Flex>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        title="Đang xử lý . . ."
        open={isOpenLoading}
        footer=""
        centered
        onCancel={onCloseModal}
        className="processing-checkin-modal"
      >
        <div className="text-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} />
          <p className="pt-4">
            {" "}
            Hệ thống đang tiến hành điểm danh. Vui lòng chờ
          </p>
        </div>
      </Modal>
      <Modal
        title={
          <div>
            <CheckCircleOutlined style={{ color: "#52C41A" }} />
            <span className="pl-2">Hoàn tất chấm điểm tự động</span>
          </div>
        }
        open={isComplete}
        closeIcon={false}
        width={400}
        centered
        footer={
          <Button type="primary" onClick={() => setIsComplete(false)}>
            Đóng
          </Button>
        }
      >
        <div className="px-4">
          Tiến trình chấm điểm tự động đã hoàn tất. Vui lòng xem kết quả bảng
          điểm sinh viên
          <span
            onClick={handleChangeTabs}
            className="pl-1 text-[#1677FF] cursor-pointer	"
          >
            tại đây
          </span>
        </div>
      </Modal>
    </Content>
  );
};

export default AutoScoring;
