import React, { useState } from "react";
import { Form, FormInstance } from "antd";
import { QuestionProps } from "../../../../../types/course";
import { SortableList } from "../../../../../components/SortableList";

interface ConnectProps extends QuestionProps {
  form?: FormInstance<any>;
}
function Connect({ form, isCorrect, isIncorrect }: ConnectProps) {
  const [answers, setAnswers] = useState([
    { id: 1, name: "Figma ipsum component" },
    { id: 2, name: "Figma ipsum component" },
    { id: 3, name: "Figma ipsum component" },
    { id: 4, name: "Figma ipsum component" },
  ]);

  const handleDragChange = (data: any) => {
    form?.setFieldValue("connect", data);
    setAnswers(data);
  };
  return (
    <div className="flex">
      <div className="connect-quest w-50 mr-3">
        <div className="connect-quest-item connect-wrong">
          <span className="font-size-16 line-height-24">Câu hỏi 01?</span>
        </div>
        <div className="connect-quest-item connect-correct">
          <span className="font-size-16 line-height-24">Câu hỏi 02?</span>
        </div>
        <div className="connect-quest-item connect-wrong">
          <span className="font-size-16 line-height-24">Câu hỏi 03?</span>
        </div>
        <div className="connect-quest-item connect-correct">
          <span className="font-size-16 line-height-24">Câu hỏi 04?</span>
        </div>
      </div>
      <Form.Item name="connect" className="w-50 ml-3">
        <SortableList
          items={answers}
          disabled
          className="gap-16"
          onChange={handleDragChange}
          renderItem={(record: any) => (
            <SortableList.Item className="connect-item" id={record.id}>
              <SortableList.DragHandle />
              <div>Câu trả lời {record.id}</div>
            </SortableList.Item>
          )}
        />
      </Form.Item>
    </div>
  );
}

export default Connect;
