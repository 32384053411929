import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import { CheckboxOptionType } from "antd/lib";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  MenuProps,
  Row,
  TableColumnType,
  Typography,
} from "antd";
import {
  DownOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  UpOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import FormInput from "../../../../../../components/form-input/FormInput";
import "./filter-table.scss";
import { DataTypeMoocCourseConstructionPlan } from "../../../../../../types/course-plan-managment/mooc-course-construction-plan";

type FilterTableProps = {
  handleOpen: () => void;
  searchValue: string;
  tab: number;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
  checkedList: Array<string>;
  setCheckedList: (value: string[]) => void;
  dataColumns: TableColumnType<DataTypeMoocCourseConstructionPlan>[];
  selectedRowKeys: React.Key[];
  showFilter: boolean;
  isShow: boolean;
};

const FilterTableMoocCourseConstructionPlan: FunctionComponent<
  FilterTableProps
> = ({
  handleOpen,
  searchValue,
  tab,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
  selectedRowKeys,
  showFilter, isShow
}) => {
    const options = dataColumns.map(({ key, title }) => ({
      label: title,
      value: key,
    }));

    const items: MenuProps["items"] = [
      {
        label: (
          <Checkbox.Group
            options={options as CheckboxOptionType[]}
            value={checkedList}
            onChange={(value) => {
              setCheckedList(value as string[]);
            }}
          />
        ),
        key: "radio",
      },
    ];

    const menuProps = {
      items,
    };

    return (
      <div className="gc-style-filter">
        <div className="heading-extra">
          <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
            {showFilter && (
              <Col
                md={{ span: 5 }}
                lg={{ span: 5 }}
                xl={{ span: 5 }}
                xs={{ span: 7 }}
              >
                <div className="heading-filter">
                  <Button className="filter-button" onClick={handleOpenFilter}>
                    <Typography.Text>Bộ lọc</Typography.Text>
                    {isShow ? (
                      <UpOutlined />
                    ) : (
                      <DownOutlined />
                    )}
                  </Button>
                </div>
              </Col>
            )}
            <Col
              md={{ span: 13 }}
              lg={{ span: 13 }}
              xl={{ span: 10 }}
              xs={{ span: 17 }}
            >
              <div className="heading-search">
                <FormInput
                  placeholder="Nhập từ khoá cần tìm"
                  value={searchValue}
                  onChange={handleChangeInput}
                  onKeyPress={handleSearch}
                  afterPrefixIcon={
                    <SearchOutlined onClick={handleSubmitSearch} />
                  }
                />
              </div>
            </Col>
            <Col
              md={{ span: 7 }}
              lg={{ span: 7 }}
              xl={{ span: 9 }}
              xs={{ span: 21 }}
            >
              <div className="heading-resigter">
                <Button
                  onClick={handleOpen}
                  className="btn btn-primary"
                  disabled={tab === 2}
                >
                  <PlusOutlined />
                  <Typography.Text>Thêm kế hoạch</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

export default FilterTableMoocCourseConstructionPlan;
