import { isEmpty, toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import useKeyword from "../../../../hooks/useKeyword";
import { getUnitDetail } from "../../../../service/course-construct";
import { Empty, Spin } from "antd";
import ReactPlayer from "react-player";
import ContentQuizLayout from "./components/content/ContentQuizLayout";

function Content() {
  const unitId = toNumber(useKeyword("unitId"));
  const [unitDetail, setUnitDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const videoUrl = unitDetail?.documents;
  const scormUrl = unitDetail?.scorms;
  const quizGroups = unitDetail?.quizGroups;

  useEffect(() => {
    if (unitId) {
      setIsLoading(true);
    }
    const getDetailCourseData = async () => {
      try {
        await getUnitDetail(Number(unitId)).then((res) => {
          const { data } = res.data;
          setUnitDetail(data);
        });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    if (unitId) {
      getDetailCourseData();
    }
  }, [unitId]);

  return (
    <>
      {isLoading ? (
        <div className="statistic-course-content statistic-course-loading">
          <Spin size="large" />
        </div>
      ) : (
        <div className="statistic-course-content">
          {unitDetail ? (
            <>
              {videoUrl
                ? videoUrl.map((it: any, index: number) => (
                    <div key={it.id || index}>
                      {it.fileName?.includes(".pdf") ||
                      it.fileName?.includes(".mp3") ? (
                        <iframe
                          allow="fullscreen"
                          scrolling="yes"
                          width="100%"
                          height="900px"
                          src={it?.mainKeyUrl}
                        />
                      ) : !isEmpty(it.text) ? (
                        <div
                          contentEditable="true"
                          dangerouslySetInnerHTML={{ __html: it.text }}
                          className="unit-text"
                        ></div>
                      ) : (
                        <ReactPlayer
                          url={it?.mainKeyUrl}
                          controls
                          width="100%"
                          height="80%"
                          className="react-player"
                          playing={true}
                        />
                      )}
                    </div>
                  ))
                : null}

              {scormUrl
                ? scormUrl.map((item: any, index: number) => (
                    <iframe
                      key={item?.id || index}
                      allow="fullscreen"
                      scrolling="yes"
                      width="100%"
                      height="900px"
                      src={item?.mainKeyUrl}
                    />
                  ))
                : null}

              {quizGroups ? <ContentQuizLayout data={quizGroups} /> : ""}

              {isEmpty(videoUrl) &&
                isEmpty(scormUrl) &&
                isEmpty(quizGroups) && <Empty description="Không có dữ liệu" />}
            </>
          ) : (
            <Empty description="Không có dữ liệu" />
          )}
        </div>
      )}
    </>
  );
}

export default Content;
