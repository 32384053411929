// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-area-wrap {
  position: relative;
  transition: 0.2s ease-out; }

.display-max {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 5px; }
`, "",{"version":3,"sources":["webpack://./src/components/form-input-text/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,yBAAyB,EAAA;;AAE7B;EACI,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,kBAAkB,EAAA","sourcesContent":[".text-area-wrap{\n    position: relative;\n    transition: 0.2s ease-out;\n}\n.display-max{\n    position: absolute;\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    padding-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
