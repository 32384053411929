import { Form, Radio } from "antd";
import React from "react";
import { QuestionProps } from "../../../../../types/course";

function ChooseImage({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <Form.Item name="ChooseImage">
      <Radio.Group disabled>
        <div className="flex layout-two-col">
          <div
            className={`image-quiz-wrap ${isCorrect ? "active-quiz-correct choose-correct" : ""} ${isIncorrect ? "active-quiz-wrong choose-incorrect" : ""}`}
          >
            <Radio id="1" value={1} className="text-result">
              Mô tả câu hỏi{" "}
            </Radio>
            <label htmlFor="1">
              <img
                className="mt-1"
                src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
                alt="quiz-card"
              />
            </label>
          </div>
          <div className="image-quiz-wrap">
            <Radio id="2" value={2}>
              Mô tả câu hỏi{" "}
            </Radio>
            <label htmlFor="2">
              <img
                className="mt-1"
                src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
                alt="quiz-card"
              />
            </label>
          </div>
          <div className="image-quiz-wrap">
            <Radio id="3" value={3}>
              Mô tả câu hỏi{" "}
            </Radio>
            <label htmlFor="3">
              <img
                className="mt-1"
                src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
                alt="quiz-card"
              />
            </label>
          </div>
          <div className="image-quiz-wrap">
            <Radio id="4" value={4}>
              Mô tả câu hỏi{" "}
            </Radio>
            <label htmlFor="4">
              <img
                className="mt-1"
                src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
                alt="quiz-card"
              />
            </label>
          </div>
        </div>
      </Radio.Group>
    </Form.Item>
  );
}

export default ChooseImage;
