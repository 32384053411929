import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";
import { axiosConfigV2OpenAPI } from "../../config/api/configOpenApiV2";

export const getListContentBlog: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-university-blog/search`, data)
}

export const createContentBlog: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.post(`/mooc-university-blog`, data)
}

export const updateContentBlog: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.put(`/mooc-university-blog`, data)
}

export const deleteContentBlog: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-university-blog/${id}`)
}

export const publishContentBlog: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.put(`/mooc-university-blog/publish/${id}`)
}

export const getListContentForum: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-university-topic/search`, data)
}

export const createContentForum: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.post(`/mooc-university-topic`, data)
}

export const createTopic: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-university-topic`, data)
}

export const updateContentForum: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigToUpload.put(`/mooc-university-topic`, data)
}

export const updateTopic: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.put(`/mooc-university-topic`, data)
}

export const publishTopic: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.put(`/mooc-university-topic/publish/${id}`)
}

export const getListContentForTopic: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-university-blog/find-by-name`)
}

export const deleteTopic: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.delete(`/mooc-university-topic/${id}`)
}

export const followAll: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-university-topic/follow-alls`, data)
}

export const getAllCommentTopic: (data: any) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2OpenAPI.post(`/mooc-university-topic-discussion/search`, data)
}

export const createDiscussionTopic: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`/mooc-university-topic-discussion/create`, payload);
};

export const discussionReactTopic: (payload: {
  id: number;
  reactionType: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/mooc-university-topic-discussion/react`, payload);
};

export const replyDiscussionTopic: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`/mooc-university-topic-discussion/reply`, payload);
};

export const updateCommentTopic: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.put(`/mooc-university-topic-discussion/update-comment`, payload);
};

export const deleteCommentTopic: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.delete(`/mooc-university-topic-discussion/delete/${id}`);
};

export const likeActionTopic: (data: any) => Promise<AxiosResponse<any>> = (
  data
) => {
  return axiosConfigV2.post(`/mooc-university-topic-discussion/react-topic`, data);
};

export const getDetailTopic: (id: string | undefined) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-university-topic/${id}`);
};

export const followActionTopic: (id: string | undefined) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.post(`/mooc-university-topic-discussion/follow/${id}`);
};

export const postTopicView: (topicId: any) => Promise<AxiosResponse<any>> = (
  topicId
) => {
  return axiosConfigV2.post(`/mooc-university-topic-discussion/view/${topicId}`);
};