// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-traning-filter-group .wrapper-filter {
  width: 100%;
  background: #FFFFFF;
  overflow: hidden;
  padding-top: 16px;
  max-height: 0;
  transition: max-height 0.3s ease-in-out; }
  .survey-managment-traning-filter-group .wrapper-filter .ant-form-item {
    margin-bottom: 0; }

.survey-managment-traning-filter-group .wrapper-filter.open {
  max-height: 100%; }

.survey-managment-traning-filter-group .group-btn--filter {
  display: flex; }

@media screen and (min-width: 996px) {
  .survey-managment-traning-filter-group .filter-btn {
    margin-right: 10px; } }

@media screen and (max-width: 768px) {
  .survey-managment-traning-filter-group .filter-btn {
    width: 35%; }
  .survey-managment-traning-filter-group .clear-filter-btn {
    width: 60%; }
  .survey-managment-traning-filter-group .group-btn--filter {
    justify-content: space-between; } }

@media screen and (max-width: 480px) {
  .survey-managment-traning-filter-group .filter-btn,
  .survey-managment-traning-filter-group .clear-filter-btn {
    width: 50%; }
  .survey-managment-traning-filter-group .group-btn--filter {
    justify-content: space-around; }
  .survey-managment-traning-filter-group .wrapper-filter {
    height: 200px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/training-need/components/filter-group/filter-group.scss"],"names":[],"mappings":"AAAA;EAEI,WAAiB;EACjB,mBAAoB;EACpB,gBAAmB;EACnB,iBAAiB;EACjB,aAAc;EACd,uCAAwC,EAAA;EAP5C;IAUM,gBAAgB,EAAA;;AAVtB;EAgBI,gBAAgB,EAAA;;AAhBpB;EAoBI,aAAa,EAAA;;AAIf;EAxBF;IA0BM,kBAAkB,EAAA,EACnB;;AAIH;EA/BF;IAiCM,UAAU,EAAA;EAjChB;IAqCM,UAAU,EAAA;EArChB;IAyCM,8BAA8B,EAAA,EAE/B;;AAGH;EA9CF;;IAkDM,UAAU,EAAA;EAlDhB;IAsDM,6BAA6B,EAAA;EAtDnC;IA0DM,aAAa,EAAA,EACd","sourcesContent":[".survey-managment-traning-filter-group {\n  .wrapper-filter {\n    width      : 100%;\n    background : #FFFFFF;\n    overflow   : hidden;\n    padding-top: 16px;\n    max-height : 0;\n    transition : max-height 0.3s ease-in-out;\n\n    .ant-form-item {\n      margin-bottom: 0;\n    }\n\n  }\n\n  .wrapper-filter.open {\n    max-height: 100%;\n  }\n\n  .group-btn--filter {\n    display: flex;\n  }\n\n\n  @media screen and (min-width: 996px) {\n    .filter-btn {\n      margin-right: 10px;\n    }\n  }\n\n\n  @media screen and (max-width: 768px) {\n    .filter-btn {\n      width: 35%;\n    }\n\n    .clear-filter-btn {\n      width: 60%;\n    }\n\n    .group-btn--filter {\n      justify-content: space-between;\n\n    }\n  }\n\n  @media screen and (max-width: 480px) {\n\n    .filter-btn,\n    .clear-filter-btn {\n      width: 50%;\n    }\n\n    .group-btn--filter {\n      justify-content: space-around;\n    }\n\n    .wrapper-filter {\n      height: 200px;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
