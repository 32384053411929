import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  CaretDownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RobotOutlined,
} from "@ant-design/icons";
import { Button, Menu } from "antd";
import { useNavigate } from "react-router-dom";
import { isEmpty, toNumber } from "lodash";
import {
  GroupsExam,
  QuizProps,
  ResultQuiz,
} from "../../../../types/scoring-course";

interface SidebarProps {
  groups: GroupsExam[];
  quizzes: QuizProps[];
  onClick?: (v: any) => void;
}

function Sidebar({
  groups = [],
  quizzes = [],
  onClick = () => {},
}: SidebarProps) {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (!isEmpty(quizzes)) {
      onClick({ ...quizzes[0], index: 0 });
      setSelectedKeys([`0`, `${quizzes[0]?.id}`]);
    }
  }, [quizzes]);

  const handleMenuClick = (item: any) => {
    const clickItem = quizzes?.find(
      (itm: any) => itm?.id === toNumber(item?.key)
    );

    const indexOfClickItem = quizzes?.findIndex(
      (itm: any) => itm?.id === toNumber(item?.key)
    );

    if (clickItem) {
      onClick({ ...clickItem, index: indexOfClickItem });
      setSelectedKeys([`${item?.key}`]);
    }
  };

  const displayMark = (result: ResultQuiz, isTeacherMarked: boolean) => {
    if (isEmpty(result) || result?.isTrue === null) return "";

    if (isTeacherMarked && result?.isTrue !== null)
      return (
        <img src={`${process.env.PUBLIC_URL}/assets/icons/usercheck.svg`} />
      );

    if (!isTeacherMarked && result?.isTrue !== null)
      return <RobotOutlined className="text-green" />;

    return "";
  };

  const displayTotalMarkQuest = (quizzes: QuizProps[]) => {
    if (isEmpty(quizzes)) return "";

    let count = 0;
    quizzes.forEach((item: QuizProps) => {
      if (!isEmpty(item.result) && item?.result?.isTrue !== null) count++;
    });

    return `(Đã chấm ${count}/${quizzes.length})`;
  };

  const items: any = groups?.map((item: GroupsExam, pIndex: number) => ({
    key: `${pIndex}`,
    label: (
      <span className="font-weight-5">
        {item.title} {displayTotalMarkQuest(item.quiz)}
      </span>
    ),
    children: item.quiz.map((quiz: QuizProps, index) => ({
      key: `${quiz?.id}`,
      label: (
        <div className="flex align-center gap-8">
          {index + 1}
          <div
            className="one-line"
            dangerouslySetInnerHTML={{ __html: `${quiz?.question}` }}
          />
          {displayMark(
            quiz?.result,
            quiz?.result?.markedTeacherId || quiz?.result?.draftInfo?.teacher_id
          )}
        </div>
      ),
    })),
  }));

  return (
    <div className={`scoring-sidebar ${collapsed ? "sidebar-hidden" : ""} `}>
      <div className="flex justify-space-between sidebar-header align-center">
        {!collapsed && (
          <span className="font-size-16">
            <span className="font-weight-5 ml-2">Danh sách câu hỏi</span>
          </span>
        )}

        <Button className="border-none" onClick={toggleCollapsed}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <Menu
        defaultActiveFirst
        selectedKeys={selectedKeys}
        defaultOpenKeys={["0"]}
        mode="inline"
        onClick={handleMenuClick}
        inlineCollapsed={collapsed}
        expandIcon={({ isOpen }) =>
          isOpen ? (
            <div className="expand-icon open">
              <CaretDownOutlined />
            </div>
          ) : (
            <div className="expand-icon close">
              <CaretDownOutlined />
            </div>
          )
        }
        items={items}
      />
    </div>
  );
}

export default Sidebar;
