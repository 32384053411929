import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getRevokeEdxToken: (
  param: string
) => Promise<AxiosResponse<any>> = (param: string) => {
  return axiosConfig.get(`/authentications/revoke-openedx?token=${param}`);
};

export const getRevokeLogOut: (
  refreshToken: string
) => Promise<AxiosResponse<any>> = (refreshToken: string) => {
  return axiosConfig.get(`/authentications/revoke`, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
};

export const postLogout: (
) => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.post(`/v2/auth/logout`);
};
