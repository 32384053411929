import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { IBodyBlock, IBodyTemplates } from "../../types/grapesjs";
import { axiosConfigV2 } from "../../config/api/configApiv2";

const prefix = '/courses/templates';

export const getTemplateByCourseId: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`${prefix}/get-template-by-course-id/${id}`)
}

export const saveTemplates: (id: string | undefined, data: IBodyTemplates) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`${prefix}/${id}`, data)
}

export const getTemplateCategory: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`${prefix}/block`)
}

export const saveTemplateBlock: (data: IBodyBlock) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`${prefix}/block`, data)
}

export const postCourseUserContactAdvice: (data: IBodyBlock) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-course-user-contact/get-advice`, data)
}

export const getCourseRecommend: (id: number, params: any) => Promise<AxiosResponse<any>> = (id, params) => {
  return axiosConfigV2.get(`/mooc-courses/get-recommend/${id}?page=${params.page}&size=${params.size}`)
}

export const getReviewCourse: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-review/count-review-by-course?courseId=${id}`)
}

export const getStudentRegisterCourse: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/course-student-register/count-student-register-by-course?courseId=${id}`)
}

export const uploadPageBuilder: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.post(`/file-manager/page-builder-upload`)
}
