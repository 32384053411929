import {
  Button,
  Dropdown,
  Form,
  Layout,
  Space,
  TableColumnsType,
  message,
  TableProps,
  Tabs,
  TabsProps,
  Modal,
  Typography,
} from "antd";
import FilterPlanningApproval from "./components/filter-planning-approval/FilterPlanningApproval";
import { useState, KeyboardEvent, useEffect } from "react";
import { useAuthStore } from "../../../stores/stores";
import { searchCourseConstructionPlanPlanningApproval } from "../../../service/course-construction-plan";
import { formatDateV2 } from "../../../utils/converDate";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  SendOutlined,
  UndoOutlined,
  FileDoneOutlined,
  ThunderboltOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import TableData from "../../../components/table-data/TableData";
import {
  DataTypeMoocApproval,
  FormDataFilter,
} from "../../../types/course-plan-managment/mooc-approval";
import { MenuProps } from "antd/lib";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import CourseConstructionPlan from "../mooc-course-construction-plan1/CourseConstructionPlan";
import { routesConfig } from "../../../config/routes";
import { CreareCoursePlanArroval } from "../../../types/course-plan-managment/mooc-approval";
import { approveCoursePlan } from "../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
import { approvalStatusEum } from "../mooc-approval-plan/constants/data";
import { HelpService } from "../../../service/helper.service";
import { get } from "lodash";
import ManyApprovalsCourse from "./components/many-approvals/ManyApprovals";
import InfoCourseApprovals from "../mooc-approval-course-registration/components/info-course-approvals/InfoCourseApprovals";
// import ManyApprovalsCourse from "./components/many-approvals/ManyApprovals";
const CoursePlanPlanningApproval = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeTabKey, setActiveTabKey] = useState<string>(
    location?.state?.keyTab ?? "1"
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const userId = useAuthStore((states) => states.user?.id);
  const [dataFormFilter, setDataFormFilter] = useState<FormDataFilter>({
    submissionDate: null,
    approvalDate: null,
  });
  const { Text } = Typography;
  const [isOpenManyApprovalsSurvey, setIsOpenManyApprovalsSurvey] =
    useState<boolean>(false);
  const helpService = new HelpService();
  const [form] = Form.useForm();
  const [dataList, setDataList] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countTrueRecords, setCountTrueRecords] = useState<number>(0);
  const [countFalseRecords, setcountFalseRecords] = useState<number>(0);
  const [listIdApprove, setListIdApprove] = useState();
  const [isOpenModalDownload, setIsOpenModalDownload] =
    useState<boolean>(false);
  console.log(location.state);
  const [courseConstructPlanId, setCourseConstructPlanId] = useState<number>();
  const handleCoverDate = (dateString: string) => {
    if (dateString == undefined) {
      return null;
    } else {
      const [day, month, year] = dateString.split("-");
      return new Date(`${year}-${month}-${day}`);
    }
  };

  const getOptionsAction = (tab: any): MenuProps["items"] => {
    if (tab == 1) {
      let optionsDropdownMenu: MenuProps["items"] = [
        {
          key: "1",
          label: "Duyệt kế hoạch",
          icon: <FileDoneOutlined />,
        },
        {
          key: "2",
          label: "Duyệt nhanh kế hoạch",
          icon: <ThunderboltOutlined />,
        },
        {
          key: "3",
          label: "Lịch sử duyệt",
          icon: <HistoryOutlined />,
        },
        {
          key: "4",
          label: "Tải nội dung kế hoạch",
          icon: <DownloadOutlined />,
        },
        {
          key: "5",
          label: "Lịch sử hoạt động",
          icon: <HistoryOutlined />,
        },
        {
          key: "6",
          label: "Lịch sử phân công",
          icon: <HistoryOutlined />,
        },
      ];
      // if (
      //   record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE ||
      //   record.constructionPlanStatus === CoursePlanStatus.APPROVED
      // ) {
      //   optionsDropdownMenu = optionsDropdownMenu.filter(
      //     (option) => option?.key !== "edit" && option?.key !== "sendorredeem"
      //   );
      // }
      // if (
      //   record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE ||
      //   record.constructionPlanStatus === CoursePlanStatus.APPROVED
      // ) {
      //   optionsDropdownMenu = optionsDropdownMenu.filter(
      //     (option) => option?.key !== "edit" && option?.key !== "sendorredeem"
      //   );
      // }
      // if (
      //   record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE &&
      //   record.approvalStatus === ApprovalStatusEnum.WAIT_APPROVE &&
      //   record.hierarchyId === null
      // ) {
      // } else {
      //   optionsDropdownMenu = optionsDropdownMenu.filter(
      //     (option) => option?.key !== "revoke"
      //   );
      // }

      return optionsDropdownMenu;
    }
    if (tab == 2) {
      let optionsDropdownMenu: MenuProps["items"] = [
        {
          key: "3",
          label: "Lịch sử duyệt",
          icon: <HistoryOutlined />,
        },
        {
          key: "4",
          label: "Tải nội dung kế hoạch",
          icon: <DownloadOutlined />,
        },
        {
          key: "5",
          label: "Lịch sử hoạt động",
          icon: <HistoryOutlined />,
        },
        {
          key: "6",
          label: "Lịch sử phân công",
          icon: <HistoryOutlined />,
        },
      ];
      // if (
      //   record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE ||
      //   record.constructionPlanStatus === CoursePlanStatus.APPROVED
      // ) {
      //   optionsDropdownMenu = optionsDropdownMenu.filter(
      //     (option) => option?.key !== "edit" && option?.key !== "sendorredeem"
      //   );
      // }
      // if (
      //   record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE ||
      //   record.constructionPlanStatus === CoursePlanStatus.APPROVED
      // ) {
      //   optionsDropdownMenu = optionsDropdownMenu.filter(
      //     (option) => option?.key !== "edit" && option?.key !== "sendorredeem"
      //   );
      // }
      // if (
      //   record.constructionPlanStatus === CoursePlanStatus.WAIT_APPROVE &&
      //   record.approvalStatus === ApprovalStatusEnum.WAIT_APPROVE &&
      //   record.hierarchyId === null
      // ) {
      // } else {
      //   optionsDropdownMenu = optionsDropdownMenu.filter(
      //     (option) => option?.key !== "revoke"
      //   );
      // }

      return optionsDropdownMenu;
    }
  };

  const handleGetListData = () => {
    setLoading(true);
    const dataSend = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      individualId: userId,
      submitedDateFrom: handleCoverDate(dataFormFilter?.submissionDate?.[0]),
      submitedDateTo: handleCoverDate(dataFormFilter?.submissionDate?.[1]),
      approvalDateFrom: handleCoverDate(dataFormFilter?.approvalDate?.[0]),
      approvalDateTo: handleCoverDate(dataFormFilter?.approvalDate?.[1]),
      isApproved: activeTabKey === "1" ? false : true,
    };
    searchCourseConstructionPlanPlanningApproval(dataSend)
      .then((res) => {
        const { data, totalRecords, countTrueRecords, countFalseRecords } =
          res.data.data;
        setDataList(data);
        setTotalRecords(totalRecords);
        setCountTrueRecords(countTrueRecords);
        setcountFalseRecords(countFalseRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleSearchEnter = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      setPageSize(10);
      handleGetListData();
    }
  };
  const handleSearch = () => {
    setPageNumber(1);
    setPageSize(10);
    handleGetListData();
  };
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
  };
  const clearFilter = () => {
    setLoading(true);
    form.resetFields();
    setSearchValue("");
    setDataFormFilter({
      submissionDate: null,
      approvalDate: null,
    });
    const dataSend = {
      keyword: "",
      pageNumber: 1,
      pageSize: pageSize,
      individualId: userId,
      submitedDateFrom: null,
      submitedDateTo: null,
      approvalDateFrom: null,
      approvalDateTo: null,
      isApproved: activeTabKey === "1" ? false : true,
    };
    searchCourseConstructionPlanPlanningApproval(dataSend)
      .then((res) => {
        const { data, totalRecords, countTrueRecords, countFalseRecords } =
          res.data.data;
        setDataList(data);
        setTotalRecords(totalRecords);
        setCountTrueRecords(countTrueRecords);
        setcountFalseRecords(countFalseRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleCloseManyApprovalsSurvey = () => {
    setIsOpenManyApprovalsSurvey(false);
  };
  const handleChangeFilter = (value: any, name: string) => {
    setDataFormFilter({ ...dataFormFilter, [name]: value });
  };
  const multipleApprove = () => {
    console.log("aaaa");
  };
  const handleApproveSurvay = (record: any) => {
    const dataSend: CreareCoursePlanArroval = {
      courseId: Number(record.courseConstructionPlanId),
      submitterId: userId ?? null,
      individualId: record.individualId,
      courseStatus: approvalStatusEum.APPROVED,
    };
    approveCoursePlan(dataSend).then((res) => {
      const { data, statusCode } = res.data;
      if (statusCode === 200) {
        message.success("Duyệt nhanh kế hoạch khóa học thành công");
        clearFilter();
      }
    });
  };
  const [isOpenConfim, setIsOpenConfim] = useState<boolean>(false);
  const [recordAproved, setRecordAproved] = useState<DataTypeMoocApproval>();

  const OpenManyApprovalsSurvey = () => {
    setIsOpenManyApprovalsSurvey(true);
  };
  // useEffect(() => {
  //   clearFilter();
  // }, [activeTabKey]);
  useEffect(() => {
    handleGetListData();
  }, [activeTabKey, pageNumber, pageSize]);

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record: any, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Mã kế hoạch",
      width: 20,
      dataIndex: "courseConstructionPlanCode",
      render: (_: any, record) => {
        return record.courseConstructionPlanCode;
      },
    },
    {
      title: "Tên kế hoạch",
      sorter: (a, b) =>
        a.courseConstructionPlanName.localeCompare(
          b.courseConstructionPlanName
        ),
      width: 70,
      dataIndex: "courseConstructionPlanName",
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">
                {record.courseConstructionPlanName}
              </span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Ngày gửi duyệt",
      width: 20,
      dataIndex: "submissionDate",
      render: (_: any, record) => {
        return (
          <>
            {record.submissionDate ? formatDateV2(record.submissionDate) : null}
          </>
        );
      },
    },
    ...(activeTabKey === "2"
      ? [
          {
            title: "Ngày duyệt",
            width: 20,
            dataIndex: "approvalDate",
            render: (_: any, record: any) => {
              return (
                <>
                  {record.approvalDate
                    ? formatDateV2(record.approvalDate)
                    : null}
                </>
              );
            },
          },
        ]
      : []),
    {
      title: "",
      width: 10,
      fixed: "right",
      render: (_: any, record) => {
        const location = {
          id: record.courseConstructionPlanId,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigate(`detail/${record.courseConstructionPlanId}`, {
                    state: {
                      record,
                      mode: "view",
                      keyTab: activeTabKey,
                      modeback: "backState",
                      backLink: `/${routesConfig.coursePlan}/${routesConfig.planningApproval}`,
                    },
                  });
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: getOptionsAction(activeTabKey),
                  onClick: ({ key }) => {
                    if (key === "1") {
                      navigate(
                        `${routesConfig.approvalDetail}/${location.id}`,
                        {
                          state: { record, mode: "view" },
                        }
                      );
                    }
                    if (key === "2") {
                      setIsOpenConfim(true);
                      setRecordAproved(record);
                      // Modal.confirm({
                      //   icon: <FileDoneOutlined />,
                      //   title: `Xác nhận phê duyệt đợt khảo sát ${record?.courseConstructionPlanName}?`,
                      //   content: (
                      //     <>
                      //       <p>
                      //         Đảm bảo rằng tất cả các
                      //         <Text type="warning">
                      //           {" "}
                      //           thông tin liên quan đã được kiểm tra và cặp
                      //           nhật đầy đủ{" "}
                      //         </Text>
                      //         trước khi Xác nhận kết quả phê duyệt khảo sát
                      //       </p>
                      //     </>
                      //   ),
                      //   onOk: () => handleApproveSurvay(record),
                      //   centered: true,
                      //   okText: "Xác nhận",
                      //   cancelText: "Đóng",
                      //   footer: (_, { OkBtn, CancelBtn }) => (
                      //     <>
                      //       <CancelBtn />
                      //       <OkBtn />
                      //     </>
                      //   ),
                      // });
                    }
                    if (key === "3") {
                      navigate(`detail/${record.courseConstructionPlanId}`, {
                        state: { record, mode: "view", tab: "4" },
                      });
                    }
                    if (key === "4") {
                      console.log({ record });
                      setIsOpenModalDownload(true);
                      setCourseConstructPlanId(record.courseConstructionPlanId);
                    }
                    if (key === "5") {
                      navigate(
                        `${routesConfig.planningAssignmentHistory}/${record.courseConstructionPlanId}`,
                        {
                          state: record,
                        }
                      );
                    }
                    if (key === "6") {
                      navigate(
                        `${routesConfig.assignmentHistory}/${record.registrationStageId}`,
                        {
                          state: record,
                        }
                      );
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          <span>Chờ duyệt</span>
          <span className="border-total-record">{countFalseRecords}</span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            dataTable={dataList}
            dataColumns={dataColumns}
            hideSelection={false}
            loadingTable={loading}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            searchValue={searchValue}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <span>Đã duyệt</span>
          <span className="border-total-record">{countTrueRecords}</span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataTable={dataList}
            dataColumns={dataColumns}
            hideSelection={false}
            loadingTable={loading}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            searchValue={searchValue}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];
  return (
    <Layout className="page-header-group course-construction">
      <div className="table-header--wrapper">
        <FilterPlanningApproval
          form={form}
          searchValue={searchValue}
          dataFormFilter={dataFormFilter}
          setDataFormFilter={handleChangeFilter}
          setSearchValue={setSearchValue}
          handleSearch={handleSearch}
          handleOpenManyApprovalsSurvey={OpenManyApprovalsSurvey}
          handleSearchEnter={handleSearchEnter}
          activeTabKey={activeTabKey}
          multipleApprove={multipleApprove}
          clearFilter={clearFilter}
        />
      </div>
      <div className="tabs-heading">
        <Tabs
          items={itemsTab}
          activeKey={activeTabKey}
          onChange={(key) => setActiveTabKey(key)}
        />
      </div>
      {isOpenModalDownload && (
        <CourseConstructionPlan
          isOpen={isOpenModalDownload}
          setIsOpen={setIsOpenModalDownload}
          courseConstructPlanId={courseConstructPlanId}
        />
      )}
      <ManyApprovalsCourse
        open={isOpenManyApprovalsSurvey}
        handleCancel={() => {
          handleCloseManyApprovalsSurvey();
        }}
        handleFinish={clearFilter}
      />

      <InfoCourseApprovals
        data={recordAproved}
        open={isOpenConfim}
        handleCancel={() => {
          setIsOpenConfim(false);
        }}
        handleComfim={() => {
          setIsOpenConfim(false),
            Modal.confirm({
              icon: <FileDoneOutlined />,
              title: `Xác nhận duyệt ${recordAproved?.courseConstructionPlanName}?`,
              content: (
                <>
                  <p>
                    Đảm bảo rằng tất cả các
                    <Typography.Text type="warning">
                      {" "}
                      thông tin liên quan đã được kiểm tra và cập nhật đầy đủ{" "}
                    </Typography.Text>
                    trước khi Xác nhận kết quả duyệt kế hoạch
                  </p>
                </>
              ),
              onOk: () => handleApproveSurvay(recordAproved),
              centered: true,
              okText: "Xác nhận",
              cancelText: "Đóng",
              footer: (_, { OkBtn, CancelBtn }) => (
                <>
                  <CancelBtn />
                  <OkBtn />
                </>
              ),
            });
        }}
      />
    </Layout>
  );
};

export default CoursePlanPlanningApproval;
