import React, { FunctionComponent, useState } from "react";
import { FormInstance } from "antd/lib";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import FormItemDropdown from "../../../../../../form-dropdown/FormItemDropdown";
import "./form-filter.scss";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  // setFilterData: (value: string[], name: string) => void;
  clearFilter: () => void;
  onFinish?: (values: any) => void;
  dataOptions?: any;
  filterData: any; //FilterDataSurveyOperationHistory;
};

const FormFilterSurveyOperationHistory: FunctionComponent<FormFilterProps> = ({
  onFinish,
  dataOptions,
  form,
  showFilter,
  clearFilter,
  filterData,
  // setFilterData,
}) => {
  const { RangePicker } = DatePicker;
  
  return (
    <div className="filter-style">
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form
          form={form}
          name="filterForm"
          className="filter-form"
          onFinish={onFinish}
        >
          <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]} className="row-filter">
            <Col
              xl={{ span: 6 }}
              md={{ span: 4 }}
              sm={{ span: 4 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Thời gian</Typography.Title>
              <Form.Item name="rangeDate">
                <RangePicker
                  placeholder={["Bắt đầu", "Kết thúc"]}
                  format="DD/MM/YYYY"
                  value={filterData.createDate}
                  // onChange={(_value, dateString: any) => {
                  //   setFilterData(dateString, "rangeDate");
                  // }}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 6 }}
              md={{ span: 4 }}
              sm={{ span: 4 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Thao tác</Typography.Title>
              <Form.Item name="operation">
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Thao tác"
                  value={filterData?.actions}
                  options={dataOptions?.dataOperation}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 6 }}
              md={{ span: 4 }}
              sm={{ span: 4 }}
              xs={{ span: 24 }}
            >
              <Typography.Title level={5}>Tài khoản</Typography.Title>
              <Form.Item name="user">
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Tài khoản"
                  value={filterData?.dataUsers}
                  options={dataOptions?.dataUser}
                />
              </Form.Item>
            </Col>
            <Col
              xl={{ span: 5 }}
              md={{ span: 4 }}
              sm={{ span: 4 }}
              xs={{ span: 24 }}
              className="flex align-end col-filter"
            >
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa tất cả</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default FormFilterSurveyOperationHistory;
