import {StateCreator} from "zustand";


export interface AccountUserSlice {
  accountInfo: null,
  setAccountDetail: (dataInfo: any) => void,
  updateStatus: (status: boolean, id: number[]) => void,
  changePassword: (body: any) => void,
  listAccountUsers: any[],
  setListAccountUsers: (users: any[]) => void
}


export const createAccountUserSlice: StateCreator<AccountUserSlice> = (set, get) => ({
  accountInfo: null,
  listAccountUsers: [],
  setAccountDetail: (dataInfo) => {
    set({accountInfo: dataInfo})
  },
  setListAccountUsers: (users: any[]) => {
    set({listAccountUsers: users})
  },
  updateStatus: async (status, id) => {
    /*try {
      const response = await changeStatusAccount(status, id)
      const {constants} = response
      set(() => ({loading: false, result: constants}))
    } catch (err) {
      set(() => ({loading: false, error: true}))
    }*/
  },
  changePassword: async (body: any) => {
    // 	set(() => ({result: null, loading: true}))
    // 	try {
    // 		const response = await changePassword(body)
    // 		const {constants} = response.constants
    // 		set(() => ({loading: false, result: constants}))
    // 	} catch (err: any) {
    // 		set(() => ({loading: false, error: true, result: err.response ? err.response.constants : null}))
    // 	}
  }
})
