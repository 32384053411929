import {
  FunctionComponent,
  useMemo,
  type CSSProperties,
  useState,
} from "react";
import "./form-input.scss";
import { Input, Form } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

type VerticalFormItemInputType = {
  title?: string;
  inputPrefix?: string;
  placeholder?: string;
  inputSuffix?: string;
  inputSuffixIcon?: boolean;
  value: string;
  onChange: (value: string) => void;
  regexValue?: any;
  errorInvalid?: string;
  type?: string;
  isPassword?: boolean;
  customError?: string;
  required?: boolean;
  autoComplete?: string;
  id?: string;
  /** Style props */
  propMinWidth?: CSSProperties["minWidth"];
  propMinWidth1?: CSSProperties["minWidth"];
  requireMessage?: string;
  style?: any;
};

const FormInput: FunctionComponent<VerticalFormItemInputType> = ({
  title,
  placeholder,
  propMinWidth,
  propMinWidth1,
  type = "text",
  value,
  onChange,
  errorInvalid,
  regexValue,
  isPassword = false,
  required = false,
  customError,
  requireMessage = "Không được bỏ trống trường này",
  id,
  style,
  autoComplete,
}) => {
  const titleStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: propMinWidth,
    };
  }, [propMinWidth]);

  const placeholderStyle: CSSProperties = useMemo(() => {
    return {
      minWidth: propMinWidth1,
    };
  }, [propMinWidth1]);
  const [error, setError] = useState("");
  const [empty, setEmpty] = useState(false);
  const [visible, setVisible] = useState(false);

  const isValidate = (input: string) => {
    if (regexValue) return regexValue?.test(input);
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(newValue);
    if (newValue) {
      e.target.setCustomValidity("");
    }
    if (!isValidate(newValue) && newValue) {
      setError(errorInvalid ?? "Vui lòng nhập đúng định dạng");
    } else {
      setError("");
    }
  };

  const handleInvalid = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (newValue) {
      e.target.setCustomValidity("");
    } else {
      e.target.setCustomValidity(requireMessage);
    }
  };

  return (
    <div className="vertical-form-iteminput1">
      <div className="label14">
        <div className="title98">*</div>
        <div className="title99" style={titleStyle}>
          {title}
        </div>
      </div>
      <div className="field11">
        <div className="copyright-info">
          <div className="placeholder3" style={placeholderStyle}>
            <Form.Item
              className="container-input"
              validateStatus={
                error || customError || (empty && required) ? "error" : ""
              }
              help={empty && required ? requireMessage : error || customError}
            >
              {isPassword ? (
                <Input.Password
                  className="input-style"
                  onInvalid={handleInvalid}
                  placeholder={placeholder}
                  style={style}
                  type={type}
                  value={value}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setEmpty(true);
                    } else {
                      setEmpty(false);
                    }
                    handleChange(event);
                  }}
                  iconRender={(visible: boolean) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                  visibilityToggle={true}
                  id={id}
                />
              ) : (
                <Input
                  className="input-style"
                  onInvalid={handleInvalid}
                  placeholder={placeholder}
                  type={type}
                  value={value}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setEmpty(true);
                    } else {
                      setEmpty(false);
                    }
                    handleChange(event);
                  }}
                  id={id}
                />
              )}
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormInput;
