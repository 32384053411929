import React, { useEffect, useState } from "react";
import Banner from "../banner/Banner";
import { Button, Input } from "antd";
import "./style.scss";
import SimpleTabs from "../tabs/SimpleTabs";
import {
  COURSE_SCHEDULE_TYPE,
  COURSE_TYPE,
  DEFAULT_PAGE_NUM,
  DEFAULT_PAGE_SIZE,
  FILTER_STAR_ARR,
  FREE_OPTIONS,
  PAID_VALUE_FILTER,
} from "../../constants";
import ResponsiveList from "../list/ResponsiveList";
import CourseCard, { CourseCardProps } from "../card/CourseCard";
import Filter from "../filter";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import { exportExcelCourse, getCoursesBlockQuiz } from "../../service/courses";
import {
  convertSomeObjKeyToArray,
  mapCourseInfoBeToFe,
} from "../../utils/format";
import { CourseInfo, CoursePayload, GroupIndustry } from "../../types/course";
import { isEmpty } from "lodash";
import { useFilters } from "./Filters";
import { globalStore, useCoursesStore } from "../../stores/stores";
import { getArrOrUndefined, getArrayFilterChecked } from "../../utils/arrays";
import useMetaData from "../../hooks/useMetaData";
import useKeyword from "../../hooks/useKeyword";
import CustomPagination from "./pagination/Pagination";

function CourseListBlock() {
  // const navigate = useNavigate();
  const { industries, universities } = useCoursesStore((state) => state);
  const { reload } = globalStore();
  const [keySearch, setKeySearch] = useState("");
  const [initialCourses, setInitialCourses] = useState<CourseCardProps[]>([]);
  const [courses, setCourses] = useState<CourseCardProps[]>([]);

  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE_NUM);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchValues, setSearchValues] = useState<CoursePayload>({
    sortByName: "asc",
  });
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const { groupIndustry } = useMetaData();
  const topicId = Number(useKeyword("topic"));
  const currentGroupIndustry = groupIndustry?.find(
    (item: GroupIndustry) => item.id === topicId
  );
  const filterMenus = useFilters({
    hasPaid: searchValues?.isFreeOptions?.includes(PAID_VALUE_FILTER)
      ? true
      : false,
  });

  const renderCard = (courses: any) => {
    if (!courses.length) return [];

    return courses.map((item: CourseCardProps) => ({
      content: (
        <CourseCard
          key={item.id}
          {...item}
          // onClick={() =>
          //   navigate(`${routesConfig.courseInfo}/detail/${item.id}`)
          // }
          isPopular={false}
          teachers={item.teachers?.filter(
            (teacher: string, index) => teacher !== " "
          )}
        />
      ),
    }));
  };

  const handleClickSearch = () => {
    if (keySearch) {
      // navigate(`${routesConfig.searchCourseInfo}?keyword=${keySearch.trim()}`);
    }
  };

  const onKeyBoardEnter = (e: any) => {
    if (e.key === "Enter") {
      // navigate(
      //   `${routesConfig.searchCourseInfo}?keyword=${e.target.value.trim()}`
      // );
    }
  };

  const getCourses = async (payload = {}) => {
    try {
      const res = await getCoursesBlockQuiz(payload);
      const coursesInfo = res.data.data.courses.map((item: CourseInfo) =>
        mapCourseInfoBeToFe(item)
      );
      setTotalRecords(res.data.data.total);
      return coursesInfo;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  useEffect(() => {
    if (topicId) {
      setSearchValues((prevState: any) => ({
        ...prevState,
        industryGroup: topicId,
      }));
    }
  }, [topicId]);

  useEffect(() => {
    const initialCourse = async () => {
      // Lấy danh sách khoá học
      const coursesInfo = await getCourses();
      setInitialCourses(renderCard(coursesInfo));
    };
    initialCourse();
  }, [reload]);

  useEffect(() => {
    if (
      isEmpty(searchValues) &&
      pageNum === DEFAULT_PAGE_NUM &&
      pageSize === DEFAULT_PAGE_SIZE
    )
      setCourses(initialCourses);
    else {
      // Lọc danh sách khoá học
      const searchCourse = async () => {
        try {
          setLoadingSearch(true);
          const values = await getCourses({
            ...searchValues,
            page: pageNum,
            size: pageSize,
          });
          setCourses(renderCard(values));
        } catch (err) {
        } finally {
          setTimeout(() => setLoadingSearch(false), 500);
        }
      };
      searchCourse();
    }
  }, [initialCourses, searchValues, pageNum, pageSize, reload]);

  const handleChangeFilter = (value: any) => {
    // Filter chuyên ngành
    const industriesFilter = getArrayFilterChecked(
      industries,
      convertSomeObjKeyToArray(value, "industry"),
      "id"
    );
    // Xếp hạng
    const stars = getArrayFilterChecked(
      FILTER_STAR_ARR,
      convertSomeObjKeyToArray(value, "rank"),
      "value"
    );
    // Chi phí
    const freeOptions = getArrayFilterChecked(
      FREE_OPTIONS,
      convertSomeObjKeyToArray(value, "price"),
      "value"
    );
    // Filter trường học
    const universitiesFilter = getArrayFilterChecked(
      universities,
      convertSomeObjKeyToArray(value, "university"),
      "uuid"
    );
    // Filter lịch trình học
    const courseScheduleType = getArrayFilterChecked(
      COURSE_SCHEDULE_TYPE,
      convertSomeObjKeyToArray(value, "schedule"),
      "value"
    );
    // Filter loại khoá học
    const courseType = getArrayFilterChecked(
      COURSE_TYPE,
      convertSomeObjKeyToArray(value, "kindOfCourse"),
      "value"
    );
    const payload = {
      industries: getArrOrUndefined(industriesFilter),
      universities: getArrOrUndefined(universitiesFilter),
      stars: getArrOrUndefined(stars),
      courseScheduleType: getArrOrUndefined(courseScheduleType),
      courseType: getArrOrUndefined(courseType),
      isFreeOptions: getArrOrUndefined(freeOptions),
      minCost: freeOptions?.includes(PAID_VALUE_FILTER) ? 0 : undefined,
      maxCost:
        freeOptions?.includes(PAID_VALUE_FILTER) && value?.cost
          ? value?.cost
          : freeOptions?.includes(PAID_VALUE_FILTER) && !value?.cost
            ? 0
            : undefined,
    };

    setSearchValues((prevState: CoursePayload) => ({
      ...prevState,
      ...payload,
    }));
  };

  const handleChangeSort = (value: number) => {
    switch (value) {
      case 1:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "asc", sortByCreatedAt: undefined },
        }));
        break;
      case 2:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByName: "desc", sortByCreatedAt: undefined },
        }));
        break;
      case 3:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "desc", sortByName: undefined },
        }));
        break;
      case 4:
        setSearchValues((prevState: CoursePayload) => ({
          ...prevState,
          ...{ sortByCreatedAt: "asc", sortByName: undefined },
        }));
        break;
      default:
        break;
    }
  };

  const handleExportExcel = async () => {
    try {
      const res = await exportExcelCourse({
        ...searchValues,
        page: pageNum,
        size: pageSize,
        industryGroup: topicId || undefined,
      });
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Danh sách khóa học.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };

  const handleClickGroupIndustry = (group: any) => {
    window.location.href = `${routesConfig.courseInfo}?topic=${group.value}`;
  };

  const handleClickMore = () => {
    // navigate(`${routesConfig.courseTopics}`);
  };

  return (
    <div>
      <section className="content-layout">
        {!!!topicId ? (
          <section className="tabs-ss">
            <SimpleTabs
              tabs={groupIndustry?.map((item: GroupIndustry) => ({
                label: item.name,
                value: item.id,
              }))}
              show={10}
              onClick={handleClickGroupIndustry}
              onClickMore={handleClickMore}
            />
          </section>
        ) : (
          ""
        )}
        {!!!topicId ? (
          <section className="list-ss">
            <ResponsiveList
              title="Khoá học để bạn bắt đầu"
              data={initialCourses.filter((_, index: number) => index < 6)}
            />
          </section>
        ) : (
          ""
        )}

        <section className="filter-content mt-2">
          <Filter
            items={filterMenus}
            onChange={handleChangeFilter}
            onChangeSort={handleChangeSort}
          />
          <div className="content-list w-full-100">
            <ResponsiveList
              loading={loadingSearch}
              className="mt-5"
              showMenu={true}
              totalRecords={totalRecords}
              grid={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 3 }}
              data={courses}
              onClickExportExcel={handleExportExcel}
              // onClickReport={() => navigate(routesConfig.statisticsCourses)}
            />
            {totalRecords > 0 && (
              <CustomPagination
                totalPage={totalRecords}
                pageSize={pageSize}
                pageNumber={pageNum}
                setPageSize={(value) => setPageSize(value)}
                setPageNumber={(value) => setPageNum(value)}
              />
            )}
          </div>
        </section>
      </section>
    </div>
  );
}

export default CourseListBlock;
