// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-block .heading-filter button {
  width: 100%; }

.filter-block .filter-button {
  background-color: #fff;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  border: 1px solid #d9d9d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 15px;
  gap: 10px; }
  .filter-block .filter-button span {
    margin: 0; }
  @media screen and (max-width: 465px) {
    .filter-block .filter-button {
      flex-wrap: nowrap; } }

.filter-block .btn-setting {
  padding: 7px 10px;
  border-radius: 6px; }

.filter-block .heading-search .ant-input-group-addon {
  background: transparent; }

.filter-block .heading-resigter button {
  min-width: 100%; }

.ant-dropdown-menu {
  width: 180px; }
  .ant-dropdown-menu .ant-checkbox-wrapper {
    padding: 5px 0; }

@media screen and (max-width: 480px) {
  .heading-resigter button {
    max-width: 100%;
    width: 100%; } }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-list-course/filter-table/filter-table.scss"],"names":[],"mappings":"AAAA;EAGM,WAAW,EAAA;;AAHjB;EAOI,sBAAsB;EACtB,uCAAuC;EACvC,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;EACjB,SAAS,EAAA;EAfb;IAkBM,SAAS,EAAA;EAGX;IArBJ;MAsBM,iBAAiB,EAAA,EAEpB;;AAxBH;EA2BI,iBAAiB;EACjB,kBAAkB,EAAA;;AA5BtB;EAiCM,uBAAuB,EAAA;;AAjC7B;EAsCM,eAAe,EAAA;;AAKrB;EACE,YAAY,EAAA;EADd;IAII,cAAc,EAAA;;AAIlB;EACE;IAEI,eAAe;IACf,WAAW,EAAA,EACZ","sourcesContent":[".filter-block {\n  .heading-filter {\n    button {\n      width: 100%;\n    }\n  }\n  .filter-button {\n    background-color: #fff;\n    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);\n    border: 1px solid #d9d9d9;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n    padding: 4px 15px;\n    gap: 10px;\n\n    span {\n      margin: 0;\n    }\n\n    @media screen and (max-width: 465px) {\n      flex-wrap: nowrap;\n    }\n  }\n\n  .btn-setting {\n    padding: 7px 10px;\n    border-radius: 6px;\n  }\n\n  .heading-search {\n    .ant-input-group-addon {\n      background: transparent;\n    }\n  }\n  .heading-resigter {\n    button {\n      min-width: 100%;\n    }\n  }\n}\n\n.ant-dropdown-menu {\n  width: 180px;\n\n  .ant-checkbox-wrapper {\n    padding: 5px 0;\n  }\n}\n\n@media screen and (max-width: 480px) {\n  .heading-resigter {\n    button {\n      max-width: 100%;\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
