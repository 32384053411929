// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-management .ant-breadcrumb {
  background-color: #fff; }

.course-management .ant-layout-header {
  background-color: #fff;
  padding: 0px 12px;
  height: 48px; }

.course-management .ant-menu {
  height: 48px; }
  .course-management .ant-menu .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-management/style.scss"],"names":[],"mappings":"AAAA;EAGI,sBAAsB,EAAA;;AAH1B;EAQI,sBAAsB;EACtB,iBAAiB;EACjB,YAAY,EAAA;;AAVhB;EAcI,YAAY,EAAA;EAdhB;IAiBM,aAAa;IACb,mBAAmB;IACnB,uBAAuB,EAAA","sourcesContent":[".course-management {\n\n  .ant-breadcrumb {\n    background-color: #fff;\n\n  }\n\n  .ant-layout-header {\n    background-color: #fff;\n    padding: 0px 12px;\n    height: 48px;\n  }\n\n  .ant-menu {\n    height: 48px;\n\n    .ant-menu-item {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
