import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  Form,
  InputNumber,
  Layout,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import { isEmpty } from "lodash";
import { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TableData from "../../../../components/table-data/TableData";
import {
  getListStudentExam,
  updateScoreById,
} from "../../../../service/auto-score";
import { formatDate } from "../../../../utils/converDate";
import { DEFAULT_PERMISSION_AUTO_SCORE } from "../../../../constants/automatic-mark";

type TableDetailScoreProps = {
  setScore: any;
  setRole: (item: any) => void;
  role: any;
  handleNotice: any;
  permissionScore: string[];
  setIdCourse: (item: any) => void;
};
interface SearchType {
  search: string;
  skip: number;
  take: number;
  sort: string;
}
const { GET } = DEFAULT_PERMISSION_AUTO_SCORE;

const TableDetailScore = ({
  setScore,
  setRole,
  role,
  handleNotice,
  setIdCourse,
  permissionScore,
}: TableDetailScoreProps) => {
  const params = useParams();
  const [accountDetailScore, setAccountDetailScore] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [headValue, setHeadValue] = useState<any>([]);
  const [idStudent, setIdStudent] = useState<number>(NaN);
  const [scoreUpdate, setScoreUpdate] = useState<number>(0);
  const [totalScore, setTotalScore] = useState<number>(0);
  const [isRefresh, setIsRefresh] = useState<boolean>(true);

  const fetchData = async (filterParam: SearchType) => {
    try {
      const response = await getListStudentExam(
        filterParam,
        Number(params?.id)
      );
      const { data } = response.data;
      setAccountDetailScore(data?.mooc_score || []);
      setTotalPage(data?.mooc_score?.length || 0);

      setLoading(false);
      setIdCourse(data?.mooc_course?.id);
      if (!isEmpty(data?.mooc_score)) {
        setTotalScore(data?.mooc_score[0].total_point || 0);
      }
      setScore({
        courseName: data?.mooc_course?.name,
        examName:
          data?.mooc_course_block?.mooc_course_unit?.mooc_course_sequence?.name,
        title: data?.name,
      });
      setRole({
        is_edit: data?.is_edit,
        is_remove: data?.is_remove,
        is_view: data?.is_view,
        is_assign: data?.is_assign,
      });
      setHeadValue([
        {
          label: "Tên khoá học",
          value: data?.mooc_course?.name,
        },
        {
          label: "Tên học liệu",
          value: data?.mooc_course_block?.mooc_course_unit?.name,
        },
        {
          label: "Tên bảng điểm",
          value: data?.name,
        },
        {
          label: "Số lượng sinh viên làm bài",
          value: data?.mooc_score?.length,
        },
      ]);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData({
      search: "",
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      sort: "desc",
    });
  }, [pageSize, pageNumber, isRefresh]);

  const handleIsEdit = (id: number, point: number) => {
    if (idStudent !== id) {
      setIdStudent(id);
      setScoreUpdate(point);
    } else {
      setIdStudent(NaN);
    }
  };
  const handleChangeScore = (e: any) => {
    setScoreUpdate(e);
  };
  const handleUpdateScore = async () => {
    try {
      if (scoreUpdate > totalScore) {
        handleNotice("error", "Điểm sửa phải nhỏ hơn hoặc bằng tổng điểm");
      } else {
        await updateScoreById(`${idStudent}`, {
          score: scoreUpdate,
        });
        handleNotice("success", "Sửa điểm thành công");
        setIsRefresh(!isRefresh);
      }
      setIdStudent(NaN);
    } catch (error) {
      handleNotice("error", "Sửa điểm thất bại");
    }
  };
  const dataColumnsDetailScore: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Họ và tên sinh viên",
      dataIndex: "fullName",
      key: "fullName",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user?.auth_userprofile?.name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Ngày tháng năm sinh",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      width: 20,
      render: (_: any, record) => {
        return (
          <Typography.Text>
            {record?.auth_user?.auth_userprofile?.date_of_birth &&
              formatDate(record?.auth_user?.auth_userprofile?.date_of_birth)}
          </Typography.Text>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 20,
      render: (_: any, record) => {
        return <Typography.Text>{record?.auth_user?.email}</Typography.Text>;
      },
    },
    {
      title: "Điểm số",
      dataIndex: "courseType",
      key: "courseType",
      width: 20,
      render: (_: any, record) => {
        return (
          <div>
            {record?.id === idStudent ? (
              <div>
                <InputNumber
                  defaultValue={record?.point || 0}
                  onChange={(e) => handleChangeScore(e)}
                  type="number"
                  min={0}
                />
              </div>
            ) : (
              <div>{record?.point || 0}</div>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        return (
          <Space>
            {role.is_edit && permissionScore?.includes(GET) && (
              <div>
                {idStudent !== record?.id ? (
                  <Button
                    onClick={() => handleIsEdit(record?.id, record?.point)}
                  >
                    <EditOutlined />
                  </Button>
                ) : (
                  <Flex align="center">
                    <Button onClick={() => setIdStudent(NaN)}>Hủy</Button>
                    <Button
                      type="primary"
                      onClick={() => handleUpdateScore()}
                      className="mx-2"
                    >
                      Lưu
                    </Button>{" "}
                  </Flex>
                )}
              </div>
            )}
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsDetailScore.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsDetailScore.map((item) => ({
      ...item,
    }));
  }, [checkedList, role, idStudent, scoreUpdate, isRefresh]);

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {};

  const handleSubmitSearch = () => {};

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="px-6 pt-6 w-full">
          <Card>
            <Flex className="w-full" wrap="wrap">
              {headValue?.map((dataScore: any, index: number) => (
                <div
                  className={`${
                    index + 1 === headValue.length ? "" : "sm:border-r"
                  } px-4 w-full sm:w-1/2 md:w-1/4 mb-2 lg:!mb-0 xl:w-fit`}
                  key={index}
                >
                  <p className="w-full">{dataScore.label}</p>
                  <p className="font-medium text-lg">{dataScore?.value}</p>
                </div>
              ))}
            </Flex>
          </Card>
        </div>
        <TableData
          dataTable={accountDetailScore}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default TableDetailScore;
