import { ChangeEvent, FunctionComponent, KeyboardEvent, ReactNode } from "react";
import "./form-input.scss";
import { Input } from "antd";
import { InputStatus } from "antd/es/_util/statusUtils";

type FormItemInputProps = {
  prefixIcon?: ReactNode | string;
  afterPrefixIcon?: ReactNode;
  placeholder?: string;
  suffix?: ReactNode | string;
  readOnly?: boolean;
  value?: string | string[];
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  passwordInput?: boolean,
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean,
  style?: any,
  status?: InputStatus,
  type?: string,
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  autoComplete?:string
};

const FormItemInput: FunctionComponent<FormItemInputProps> = ({
  prefixIcon,
  afterPrefixIcon,
  placeholder,
  value,
  onChange,
  passwordInput,
  onKeyPress,
  disabled,
  readOnly,
  suffix,
  style,
  status,
  onBlur,
  autoComplete,
  ...rest
}) => {

  return (
    <>
      {
        passwordInput ? <Input.Password className="form-input"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={autoComplete}
          allowClear={true}
          style={style}
        /> :
          <Input
            onBlur={onBlur}
            disabled={disabled}
            className="form-input"
            addonBefore={prefixIcon}
            addonAfter={afterPrefixIcon}
            autoComplete={autoComplete}
            value={value}
            style={style}
            readOnly={readOnly}
            suffix={suffix}
            status={status}
            onChange={onChange}
            onKeyPress={onKeyPress}
            placeholder={placeholder}
            allowClear={true}
            {...rest}
          />
      }
    </>
  );
};

export default FormItemInput;
