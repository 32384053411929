import React, { ReactNode, useState } from "react";
import "./style.scss";
import { Popover, PopoverProps } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";

interface CustomPopoverProps extends PopoverProps {
  className?: string;
  children?: ReactNode;
  placement?: TooltipPlacement;
  overlayClassName?: string
  openClassName?: string
}

function CustomPopover({
  children,
  className = "",
  placement,
  overlayClassName,
  openClassName,
  ...props
}: CustomPopoverProps) {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      content={<div onClick={hide}>Close</div>}
      title="Title"
      trigger="click"
      open={open}
      placement={placement}
      className={className}
      overlayClassName={overlayClassName}
      openClassName={openClassName}
      onOpenChange={handleOpenChange}
      {...props}
    >
      {children}
    </Popover>
  );
}

export default CustomPopover;
