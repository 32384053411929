import React from "react";
import { QuestionProps } from "../../../../../types/course";
import { Checkbox, Form, Radio } from "antd";

function ChooseMultiVideo({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <div>
      <div className="flex layout-two-col">
        <div
          className={`image-quiz-wrap ${isCorrect ? "active-quiz-correct choose-correct" : ""} ${isIncorrect ? "active-quiz-wrong choose-incorrect" : ""}`}
        >
          <Form.Item
            className="mb-12"
            name="choose_one"
            valuePropName="checked"
            initialValue={{ choose_one: true }}
          >
            <Checkbox id="1" disabled>
              <span className="font-size-16 line-height-24 font-weight-5 text-result">
                Figma ipsum component, Figma ipsum
              </span>
            </Checkbox>
          </Form.Item>
          <video className="quest-video" controls>
            <source
              src={`${process.env.PUBLIC_URL}/assets/video-test.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={`image-quiz-wrap `}>
          <Form.Item
            className="mb-12"
            name="ChooseMultiText-2"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox id="2" disabled>
              <span className="font-size-16 line-height-24 font-weight-5">
                Figma ipsum component, Figma ipsum
              </span>
            </Checkbox>
          </Form.Item>
          <video className="quest-video" controls>
            <source
              src={`${process.env.PUBLIC_URL}/assets/video-test.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div
          className={`image-quiz-wrap ${isCorrect ? "active-quiz-correct choose-correct" : ""} ${isIncorrect ? "active-quiz-wrong choose-incorrect" : ""}`}
        >
          <Form.Item
            className="mb-12"
            name="choose_one"
            valuePropName="checked"
            initialValue={{ choose_one: true }}
          >
            <Checkbox id="3" disabled>
              <span className="font-size-16 line-height-24 font-weight-5 text-result">
                Figma ipsum component,
              </span>
            </Checkbox>
          </Form.Item>
          <video className="quest-video" controls>
            <source
              src={`${process.env.PUBLIC_URL}/assets/video-test.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={`image-quiz-wrap`}>
          <Form.Item
            className="mb-12"
            name="ChooseMultiText-4"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox id="4" disabled>
              <span className="font-size-16 line-height-24 font-weight-5">
                Figma ipsum component,
              </span>
            </Checkbox>
          </Form.Item>
          <video className="quest-video" controls>
            <source
              src={`${process.env.PUBLIC_URL}/assets/video-test.mp4`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  );
}

export default ChooseMultiVideo;
