import {Button, Col, Form, message, Modal, Radio, RadioChangeEvent, Row, Space, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import {getCourseFormatId, publishOneContentCourse} from '../../../service/content-course/contenCourseApi'
import PublishShareModal from '../publish-share-modal'
import {get} from "lodash";

type Props = {
  open: boolean
  quickReviewId: string
  handleClose: () => void
  getListData: () => void
  setClearRowkey: (value: boolean) => void
  dataGeneralConfig: any
  dataGeneralCostConfig: any
}

export default function PublishContentCourseModal(props: Props) {
  const {open, handleClose, getListData, quickReviewId, setClearRowkey, dataGeneralCostConfig} = props
  const [availableStatus, setAvailableStatus] = useState<any>(1);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);


  const [form] = Form.useForm()

  const validation = {
    codeTemplateSequence: {
      required: true,
      message: "Vui lòng nhập mã mẫu chương",
    },
    namTemplateSection: {
      required: true,
      message: "Vui lòng nhập tên mẫu chương",
    },
  };

  const radioItemList = [
    {
      value: 1,
      title: "Thẻ ghi danh",
      describe: "Khóa học được xây dựng lộ trình trong quá trình giảng dạy tùy theo chất lượng học viên đăng ký. Người dùng có thể thực hiện tự ghi danh sau khi khóa học mở chức năng ghi danh hoặc được ghi danh bởi QTKH hoặc Giảng viên phụ trách",
    },
    {
      value: 2,
      title: "Riêng tư",
      describe:
        "Khóa học xuất bản ở chế độ không công khai, chỉ hiển thị với người dùng được ghi danh bởi QTKH hoặc Giảng viên phụ trách",
    },
    {
      value: 3,
      title: "Tự do",
      describe: "Khóa học xuất bản ở chế độ công khai, mọi người dùng đều có thể thực hiện ghi danh sau khi khóa học mở ghi danh",
    },
  ];

  const handleRadioChange = (e: RadioChangeEvent) => {
    setAvailableStatus(e.target.value);
  };

  const getStatusCourseFormatId = async () => {
    await getCourseFormatId(quickReviewId).then((res) => {
      if (res.status === 200) {
        setAvailableStatus(!res?.data ? 1 : res?.data)
      }
    })
  };

  useEffect(() => {
    getStatusCourseFormatId()
  }, [open])
  return (
    <Form.Provider onFormFinish={async (name, {values, forms}) => {
      if (name === "publish-content-course") {
        const data = {
          idCourse: quickReviewId,
          courseFormatId: availableStatus
        }
        await publishOneContentCourse(data).then((res) => {
          if (res.status === 200) {
            message.success("Xuất bản khoá học thành công")
            handleClose()
            setClearRowkey(true)
            getListData()
          }
        })
      }
    }}>
      <Modal
        className="publish-content-course-modal"
        title={"Xác nhận"}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"50%"}
      >
        <Form
          form={form}
          layout="vertical"
          name="publish-content-course"
        >
          <Row gutter={30} className='p-6'>
            <Col md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xs={{span: 24}}>
              <Typography.Title level={5} className='text-center'>Bạn muốn xuất bản khoá học. Bạn có muốn thay đổi hình
                thức xuất bản khoá học không?</Typography.Title>
            </Col>

            <Col md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xs={{span: 24}} className='mt-2'>
              <Radio.Group value={availableStatus}>
                {radioItemList.map((item, index) => (
                  <div key={index} className="radio-item mt-2">
                    <Radio value={item.value} onChange={handleRadioChange}
                           disabled={item.value !== 2 && get(props.dataGeneralConfig, 'isScheduleByTeacher', false)}>
                      <Typography.Title
                        className="setting-chapter-title"
                        level={5}
                      >
                        {item.title}
                      </Typography.Title>
                      <p>{item.describe}</p>
                    </Radio>
                  </div>
                ))}
              </Radio.Group>
            </Col>

            <Col md={{span: 24}} lg={{span: 24}} xl={{span: 24}} xs={{span: 24}}>
              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Xuất bản
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => {
                    form.submit()
                    setOpenShareModal(true)
                  }}
                  >
                    <Typography.Text>
                      Xuất bản và chia sẻ
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <PublishShareModal
        open={openShareModal}
        dataGeneralCostConfig={dataGeneralCostConfig}
        dataGeneralConfig={props.dataGeneralConfig}
        availableStatus={availableStatus}
        handleClose={() => {
          setOpenShareModal(false)
          handleClose()
          getListData()
        }
        }
        quickReviewId={quickReviewId}/>
    </Form.Provider>
  )
}
