import { createBrowserRouter } from "react-router-dom";
import ApprovedInformationTab from "../components/approved-information-tab/approvedInformationTab";
import { routesConfig } from "../config/routes";
import DashboardRootPage from "../layout/dashboard/DashboardRootPage";
import Page403 from "../pages/403";
import Page500 from "../pages/500";
import ContentConstruction from "../pages/content-construction/content-construction-list";
import ContentCourseDetail from "../pages/content-construction/content-course-detail";
import AssignCourseConstruction from "../pages/course-construction/assign-course-construction";
import AssignedDetailCourseConstruction from "../pages/course-construction/assigned-course-construction";
import CourseApprovalPage from "../pages/course-construction/course-browse/course-approval";
import AppraisedHistory from "../pages/course-construction/course-browse/course-approval/appraised-history";
import DetailCourseApproval from "../pages/course-construction/course-browse/course-approval/course-approval";
import CourseBrowserIndex from "../pages/course-construction/course-browse/course-browse";
import DetailCourseBrowse from "../pages/course-construction/course-browse/course-browse/course-browse";
import CourseEvaluation from "../pages/course-construction/course-browse/course-evaluation";
import DetailCourseEvaluation from "../pages/course-construction/course-browse/course-evaluation/course-evaluation";
import DetailCourseConstruction from "../pages/course-construction/detail-course-construction";
import HistoryBuildCourseConstruction from "../pages/course-construction/history-build-course-construction";
import HistoryCourseConstruction from "../pages/course-construction/history-course-construction";
import ContentPreview from "../pages/course-preview/CoursePreview";
import DetailUser from "../pages/detail/Detail";
import ListMinistries from "../pages/list-ministries/ListMinistries";
import ListTeacher from "../pages/list-teachers/ListTeachers";
import ListUser from "../pages/list-user/ListUser";
import Login from "../pages/login/Login";
import PageBuilderView from "../pages/page-builder/PageBuilderView";
import RoleManagement from "../pages/role-management/RoleManagement";
import Account from "../pages/user-info/account/Account";
import Profile from "../pages/user-info/profile/Profile";
//import ContentPreview from "../pages/course-preview/CoursePreview";
//import AddLessonPage from "../pages/content-construction/add-lesson";
//import DetailSurvey from "../pages/survey-managment/survey-construction/detail-survey/DetailSurvey";
import ListMoocActionHistory from "../pages/course-plan-managment/mooc-action-history/mooc-action-history-list";
import SpecializedGroupList
  from "../pages/course-plan-managment/specialized-group/specialized-group-list/SpecializedGroupList";
import ListSurveyResult from "../pages/survey-managment/survey-synthesis/index";
import SurveyDetail from "../pages/survey-managment/survey-synthesis/survey-detail/index";
//import ListMoocSurveySection from "../pages/survey-managment/mooc-survey-section/mooc-survey-section-list";
// import DetailSurvey from "../pages/survey-managment/survey-construction/detail-survey/DetailSurvey";
import ListMoocCourseConstructionPlan
  from "../pages/course-plan-managment/mooc-course-construction-plan/list-course-plan/MoocCourseConstructionPlanList";
import OrganizeAppraisalBoard
  from "../pages/course-plan-managment/organize-appraisal-board/organize-appraisal-board-list/OrganizeAppraisalBoard";
import DetailSpecializedGroup
  from "../pages/course-plan-managment/specialized-group/specialized-group-detail/DetailSpecializedGroup";
import SurveyPublish from "../pages/survey-managment/mooc-survey-publish";
import Survey from "../pages/survey-managment/survey";
import BrowseSurvey from "../pages/survey-managment/survey-browse";
import SurveyRespond from "../pages/survey-managment/survey-respond/SurveyRespond";
import ResultChartRender from "../pages/survey-managment/survey-synthesis/components/result-chart-render";
import ListTrainingNeed from "../pages/survey-managment/training-need/ListTrainingNeed";
// import OrganizeAppraisalBoard from "../pages/course-plan-managment/organize-appraisal-board/OrganizeAppraisalBoard";
// import FormSpecializedGroupMember from "../pages/course-plan-managment/specialized-group-list/components/form-specialized-group-member/FormSpecializedGroupMember";
// import FormOrganizeAppraisalBoardMember from "../pages/course-plan-managment/organize-appraisal-board/components/form-organize-appraisal-board-member/FormOrganizeAppraisalBoardMember";
//import DetailOrganizeAppraisalBoard from "../pages/course-plan-managment/organize-appraisal-board/organize-appraisal-board-detail/DetailOrganizeAppraisalBoard";
import DashboardRootPageNoMenu from "../layout/dashboard/DashboardRootPageNoMenu";
import AutomaticMarkDetail from "../pages/automatic-mark-detail";
import AutoMarkAction from "../pages/automatic-mark-detail/auto-mark-action";
import ListExamStudent from "../pages/automatic-mark-detail/list-exam-studen";
import ManagementListScore from "../pages/automatic-mark-detail/management-table-scores";
import DetailScore from "../pages/automatic-mark-detail/management-table-scores/detail-score";
import RateAutoScore from "../pages/automatic-mark-detail/management-table-scores/rate-auto-scores";
import AddSurvyPage from "../pages/content-construction/add-survy/add-survy";
import ContentListCourse from "../pages/content-construction/content-list-course";
import ControlQualityLesson from "../pages/control-quality-lesson";
import PublishCoursePage from "../pages/course-construction/course-browse/publish-course";
import DetailCoursePublish from "../pages/course-construction/course-browse/publish-course/course-approval";
import SurveyPlan from "../pages/course-construction/survey-plan";
import SurveyPlanDetail from "../pages/course-construction/survey-plan/survey-plan-detail/SurveyPlanDetail";
import ListMoocApprovalCourseRegister
  from "../pages/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-list";
import DetailOrganizeAppraisalBoard
  from "../pages/course-plan-managment/organize-appraisal-board/organize-appraisal-board-detail/DetailOrganizeAppraisalBoard";
import PortalAdministrationPage from "../pages/portar-adminstration";
import ManagementGroupContent from "../pages/prediction-model/management-group-content";
import SettingPrediction from "../pages/prediction-model/setting-prediction";
import InfoCourseList from "../pages/prediction-model/setting-prediction/list-info/info-course";
import InfoProfileStudent from "../pages/prediction-model/setting-prediction/list-info/info-profile-student";
import StudentAfterJoin from "../pages/prediction-model/setting-prediction/list-info/student-after-join";
import StudentBeforeJoin from "../pages/prediction-model/setting-prediction/list-info/student-before-join";
import StudentJoin from "../pages/prediction-model/setting-prediction/list-info/student-join";
import SettingDetailModel from "../pages/prediction-model/setting-prediction/setting-detail-model";
import PreviewQuiz from "../pages/preview-quiz";
import SurveyApproval from "../pages/survey-managment/survey-approval";

import ApprovalPlanList from "../pages/course-plan-managment/mooc-approval-plan/mooc-approval-plan-list";
import DetailCoursePlan
  from "../pages/course-plan-managment/mooc-course-construction-plan/detail-course-plan/DetailCoursePlan";
import SettingSupervisionDetail from "../pages/setting-supervision-detail";
import MyCourseDetail from "../pages/study-management/course/my-course/my-course-detail";
import ReportCourseDocument from "../pages/study-management/course/my-course/report-course-document";
import SettingIdentify from "../pages/study-management/setting-identify";
import SettingRemindCheckIn from "../pages/study-management/setting-remind-checkin";
import RemindCheckInDetail from "../pages/study-management/setting-remind-checkin/remind-checkin-detail";
import QualityEvaluation
  from "../pages/study-management/setting-remind-checkin/remind-checkin-detail/report-checkin/quality-evalution";
import ReportCheckInDetail
  from "../pages/study-management/setting-remind-checkin/remind-checkin-detail/report-checkin/report-checkin-detail";
import LearningDocumentationSystem from "../pages/system-suggest-course/learning-documentation-system";
import InfoCourseSystemDetail from "../pages/system-suggest-course/learning-documentation-system/info-course-detail";
import ListStudentSystem from "../pages/system-suggest-course/list-student-system";
import FavoriteStudent from "../pages/system-suggest-course/list-student-system/favorite-student";
import InfoSystemSuggest from "../pages/system-suggest-course/list-student-system/info-system";
import SynthesisReportSystem from "../pages/system-suggest-course/synthesis-report";

import AppraisalPlanList from "../pages/course-plan-managment/mooc-appraisal-plan/mooc-appraisal-plan-list";
import ApprovalPlanDetail from "../pages/course-plan-managment/mooc-approval-plan/mooc-approval-plan-detail";
import HistoryCourseConstructionPlan
  from "../pages/course-plan-managment/mooc-course-construction-plan/history-course-plan/HistoryCourseConstructionPlan";

import CoursePlanPlanningApproval from "../pages/course-plan-managment/mooc-course-plan-planning-approval";
import CoursePlanSubmitAppraisalPlan from "../pages/course-plan-managment/mooc-course-plan-submit-appraisal-plan";
import CoursePlanSubmitApprovalPlan from "../pages/course-plan-managment/mooc-course-plan-submit-approval-plan";
import ListPlanningCourseConstruction
  from "../pages/course-plan-managment/planning-course-construction/list-planning-course-construction/PlanningCourseConstructionList";
import DetailSurvey from "../pages/survey-managment/survey-construction/detail-survey/DetailSurvey";
// import CoursePlanningAssignment from "../pages/course-plan-managment/mooc-course-planning-assignment/CoursePlanningAssigment";
import MoocCourseApprovalDetail
  from "../pages/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-detail";
import CourseConstructionPlanDetail
  from "../pages/course-plan-managment/mooc-course-plan-planning-approval/components/mooc-course-plan-planning-approval-details";
import DetailPlanningCourseConstruction
  from "../pages/course-plan-managment/planning-course-construction/detail-planning-course-construction/DetailPlanningCourseConstruction";

import { Permission } from "../layout/sidebar/permission.enum";
import ApprovalCouresPlanDetail
  from "../pages/course-plan-managment/mooc-approval-course-plan/mooc-approval-plan-detail";
import DetailApproval
  from "../pages/course-plan-managment/mooc-approval-course-registration/components/approval-course-register/DetailApproval";
import MoocApprovalCourseHistory
  from "../pages/course-plan-managment/mooc-approval-course-registration/mooc-approval-course-history";
import HistorySubmitAppraisalPlan
  from "../pages/course-plan-managment/mooc-course-plan-submit-appraisal-plan/components/history-submit-appraisal-plan/HistorySubmitAppraisalPlan";
import HistorySubmitApprovalPlan
  from "../pages/course-plan-managment/mooc-course-plan-submit-approval-plan/components/history-submit-approval-plan/HistorySubmitApprovalPlan";
import MoocCourseAssignmentDetail
  from "../pages/course-plan-managment/mooc-course-planning-assignment/components/course-assignment-detail/mooc-course-assignment-detail";
import PlanningAssignment
  from "../pages/course-plan-managment/mooc-course-planning-assignment/components/planning-assignment";
import PlanningAssignmentHistory
  from "../pages/course-plan-managment/mooc-course-planning-assignment/components/planning-assignment-history/PlanningAssignmentHistory";
import CoursePlanningAssignment
  from "../pages/course-plan-managment/mooc-course-planning-assignment/CoursePlanningAssigment";
import UserDocumentOrganizeAppraisal
  from "../pages/course-plan-managment/organize-appraisal-board/organize-appraisal-board-detail/detail-info-tab/user-document/UserDocumentOrganizeAppraisal";
import ApproveCouresConsrtuctionHistory
  from "../pages/course-plan-managment/planning-course-construction/list-planning-course-construction/components/approve-coures-history/ApproveCouresHistory";
import UserDocumentSpecializedGroup
  from "../pages/course-plan-managment/specialized-group/specialized-group-detail/detail-info-tab/user-document/UserDocument";
//import { checkPermission } from "../utils/checkPermission";
import ProtectedRoute from "./protect-route/ProtectRoute";

import ForumBlockPageReview from "../components/blog-and-forum/design-theme-blog-content/ForumBlogPreview";
import AdminInterFacePage from "../pages/admin-interface";
import DetailAdminInterface from "../pages/admin-interface/detail-admin-interface";
import BlogContentPage from "../pages/blog-and-forum/blog-content";
import DetailContentPreview from "../pages/blog-and-forum/blog-content/detail-blog-content";
import DesignThemeBlogContentPage from "../pages/blog-and-forum/design-theme-blog-content";
import DetailTopicPage from "../pages/blog-and-forum/detail-topic";
import ManageContentForum from "../pages/blog-and-forum/manage-content-forum";
import LecturerManagementDetail
  from "../pages/category-management/lecturer-management/lecturer-management-detail/LecturerManagementDetail";
import LecturerManagement from "../pages/category-management/lecturer-management/LecturerManagement";
import MinistryUsersManagementDetail
  from "../pages/category-management/ministry-users-management/ministry-users-management-detail/MinistryUsersManagementDetail";
import MinistryUsersManagement from "../pages/category-management/ministry-users-management/MinistryUsersManagement";
import PortfolioValueManagementDetail
  from "../pages/category-management/portfolio-value-management/portfolio-value-management-detail/PortfolioValueManagementDetail";
import PortfolioValueManagement from "../pages/category-management/portfolio-value-management/PortfolioValueManagement";
import SharedCategoriesManagementDetail
  from "../pages/category-management/shared-categories-management/shared-categories-management-detail/SharedCategoriesManagementDetail";
import SharedCategoriesManagement
  from "../pages/category-management/shared-categories-management/SharedCategoriesManagement";
import StudentManagementDetail
  from "../pages/category-management/student-management/student-management-detail/StudentManagementDetail";
import StudentManagement from "../pages/category-management/student-management/StudentManagement";
import UniversityManagementDetail
  from "../pages/category-management/university-management/university-management-detail/UniversityManagementDetail";
import UniversityManagement from "../pages/category-management/university-management/UniversityManagement";
import CertificatePage from "../pages/certificate";
import DetailCertificate from "../pages/certificate/certificate-detail";
import CertificatePageViewer from "../pages/certificate/certificate-template/preview-grape";
import RegistrationClassPage from "../pages/content-construction/registration-class/registration-class";
import ControlQualityCourse from "../pages/control-quality-course";
import CourseManagement from "../pages/course-management";
import CourseList from "../pages/course-management/course-list/CourseList";
import ListApprovalConfiguration from "../pages/course-plan-managment/configuration/approval-configuration";
import DetailApprovalConfiguration
  from "../pages/course-plan-managment/configuration/approval-configuration/mooc-approval-configuration-detail";
import AssignmentHistory
  from "../pages/course-plan-managment/mooc-course-planning-assignment/components/planning-assignment-history/AssginmentHistory";
import HistoryManagementPage from "../pages/management-data/history-management-data";
import SettingManagementDataPage from "../pages/management-data/setting-management-data";
import MyNotificationPage from "../pages/my-notification-page";
import Notifications from "../pages/notifications";
import NotificationDetail from "../pages/notifications/notification-detail";
import EditNotification from "../pages/notifications/notification-detail/EditNotification";
import NotificationHistory from "../pages/notifications/notification-detail/History";
import UniversityPageBuilderViewCourseList from "../pages/portar-adminstration/course-list/UniversityPageViewer";
import DetailPortalAdministration from "../pages/portar-adminstration/detail-portal-administration";
import UniversityPageBuilderView from "../pages/portar-adminstration/general-introduction/UniversityPageViewer";
import UniversityPageBuilderViewCourseListPage
  from "../pages/portar-adminstration/page-course-list/UniversityPageViewer";
import CourseScoringList from "../pages/scoring/course-list";
import ExerciseList from "../pages/scoring/exercise-list";
import ScoringDetail from "../pages/scoring/scoring-detail";
import ScoringList from "../pages/scoring/scoring-list";
import SettingStructureCourse from "../pages/setting-structure-course";
import UserInfo from "../pages/user-info";
import StatisticalReporForInstructors from "../pages/statistical-report/for-instructors/intex";
import SettingIntroPage from "../pages/portar-adminstration/list-program/setting-intro-page/SettingIntroPage";
import PreviewIntroPageCourse from "../pages/portar-adminstration/list-program/setting-intro-page/PreviewIntroPage";
import StatisticalReportForMinistry from "../pages/statistical-report/for-ministry";
import ManagementPermissionCourse from "../pages/management-permission";
import ControlQualityLessonVideo from "../pages/control-quality-lesson-video";
import AutoMarkPermission from "../pages/scoring/management-scoring-auto/auto-mark-permission";
import ManagementScoreAuto from "../pages/scoring/management-scoring-auto";
import ManagementParticipatePlatform from "../pages/management-participate-platform";
import ListStudents from "../pages/list-student/ListStudents";
import AccountRegistrationReport from "../pages/account-registration-report";
import SettingPermissionCourseDetail from "../pages/setting-permission-course";
import LogsAudit from "../pages/logs-audit";
import StatisticalReportForUniversity from "../pages/statistical-report/for-university";
import AutoScoringManagement from "../pages/auto-scoring-management";
import ManagementExerciseList from "../pages/auto-scoring-management/exercise-list";
import { AuthGuard } from "../components/authentication/auth-guard";
import Sso from "../pages/sso";

export const router = createBrowserRouter([
  {
    path: "/error/403",
    element: <Page403 />,
  },
  {
    path: '/sso',
    element: <AuthGuard><Sso /></AuthGuard>
  },
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.detail}/:id`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <DetailUser /> }],
      },
      {
        path: routesConfig.account,
        errorElement: <Page500 />,
        children: [{ index: true, element: <Account /> }],
      },
      {
        path: routesConfig.profile,
        errorElement: <Page500 />,
        children: [{ index: true, element: <Profile /> }],
      },
      {
        path: `${routesConfig.roleManagement}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <RoleManagement title="Danh sách vai trò" />,
            errorElement: <Page403 />,
          },
        ],
      },
      {
        path: `${routesConfig.portalAdministration}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <PortalAdministrationPage title="Danh sách cổng thông tin" />
            ),
            errorElement: <Page403 />,
          },
          {
            path: `${routesConfig.detailPortalAdministration}/:id`,
            element: <DetailPortalAdministration />,
          },
        ],
      },
      {
        path: `${routesConfig.adminInterface}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <AdminInterFacePage title="Thiết lập giao diện quản trị" />
            ),
            errorElement: <Page403 />,
          },
          {
            path: `${routesConfig.detailAdminInterface}`,
            element: <DetailAdminInterface />,
          },
        ],
      },
      {
        path: `${routesConfig.certificate}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <CertificatePage title="Thiết lập chứng chỉ / chứng nhận" />
            ),
            errorElement: <Page403 />,
          },
          {
            path: `${routesConfig.certificate}/:id`,
            element: <DetailCertificate />, //change later
          },
        ],
      },
      {
        path: `${routesConfig.managementData}`,
        errorElement: <Page500 />,
        children: [
          {
            path: routesConfig.settingManagementData,
            element: (
              <SettingManagementDataPage title="Thiết lập kết nối, chia sẻ dữ liệu" />
            ),
          },
          {
            path: `${routesConfig.historyManagementData}`,
            element: (
              <HistoryManagementPage title="Lịch sử kết nối, chia sẻ dữ liệu" />
            ),
          },
        ],
      },
      {
        path: `${routesConfig.settingIntroCourse}/:id`,
        element: <SettingIntroPage />,
      },
    ],
  },
  {
    path: routesConfig.accountSystem,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.schoolManagementParticipates,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ManagementParticipatePlatform title="Quản lý trường đại học đăng ký tham gia nền tảng" />
            ),
          },
        ],
      },
      {
        path: routesConfig.listUsers,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ListUser title="Quản lý tài khoản Quản trị cơ sở" />
            ),
          },
        ],
      },
      {
        path: routesConfig.listTeachers,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ListTeacher title="Quản lý tài khoản giảng viên" />,
          },
        ],
      },
      {
        path: routesConfig.listMinistryLevels,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ListMinistries title="Quản lý tài khoản người dùng cấp Bộ" />,
          },
        ],
      },
      {
        path: routesConfig.listStudents,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ListStudents title="Quản lý tài khoản sinh viên" />,
          },
        ],
      },
      {
        path: routesConfig.accountRegistrationReport,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <AccountRegistrationReport title="Báo cáo đăng ký tài khoản sinh viên" />,
          },
        ],
      },
    ],
  },
  {
    path: `${routesConfig.certificate}${routesConfig.certificatePageViewer}/:id`,
    element: <CertificatePageViewer />,
  },

  // {
  //   path: `${routesConfig.previewSettingIntroCourse}`,
  //   element: <DashboardRootPageNoMenu />,
  //   errorElement: <Page500 />,
  //   children: [
  //     {
  //       index: true,
  //       element: <PreviewIntroPage />,
  //     },
  //   ],
  // },

  {
    path: routesConfig.courseConstruction,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      // Xây dụng khoá học - phân công xây dựng khoá học
      {
        errorElement: <Page500 />,
        children: [
          {
            path: routesConfig.courseConstructionManagement,
            element: <ApprovedInformationTab />,
          },
        ],
      },

      {
        errorElement: <Page500 />,
        children: [
          {
            path: routesConfig.assignCourseConstruction,
            element: <AssignCourseConstruction title="Danh sách kế hoạch" />,
          },
          {
            path: `${routesConfig.assignCourseConstruction}/${routesConfig.detailAssignCourseConstruction}/:id`,
            element: <DetailCourseConstruction />,
          },
          {
            path: `${routesConfig.assignCourseConstruction}/${routesConfig.detailAssignedCourseConstruction}/:id`,
            element: <AssignedDetailCourseConstruction />,
          },
          {
            path: `${routesConfig.assignCourseConstruction}/${routesConfig.historyCourseConstruction}`,
            element: <HistoryCourseConstruction />,
          },
        ],
      },

      // Xây dụng khoá học - xây dựng nội dung
      {
        errorElement: <Page500 />,
        children: [
          {
            path: routesConfig.contentConstruction,
            element: <ContentConstruction title="Danh sách khóa học" />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/:id`,
            element: <ContentCourseDetail />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.contentListCourse}/:id`,
            element: <ContentListCourse />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/:id/${routesConfig.addLesson}`,
            element: <AddSurvyPage />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.historyCourseConstruction}`,
            element: <HistoryBuildCourseConstruction />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.historyCourseConstruction}`,
            element: <HistoryBuildCourseConstruction />,
          },
          {
            path: `${routesConfig.contentConstruction}/${routesConfig.contentCourseDetail}/:id/${routesConfig.registrationClass}`,
            element: <RegistrationClassPage />,
          },
        ],
      },

      // Xây dụng khoá học - duyệt khoá học
      {
        errorElement: <Page500 />,
        children: [
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.contentBrowse}`,
            element: <CourseBrowserIndex title="Danh sách duyệt khoá học" />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseEvaluation}`,
            element: <CourseEvaluation title="Danh sách thẩm định khoá học" />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseApproval}`,
            element: (
              <CourseApprovalPage title="Danh sách phê duyệt khoá học" />
            ),
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.publishCourse}`,
            element: <PublishCoursePage title="Danh sách xuất bản khoá học" />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.contentBrowse}/${routesConfig.detailCourseBrowse}/:id`,
            element: <DetailCourseBrowse />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseEvaluation}/${routesConfig.detailCourseEvaluation}/:id`,
            element: <DetailCourseEvaluation />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseApproval}/${routesConfig.detailCourseApproval}/:id`,
            element: <DetailCourseApproval />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.publishCourse}/${routesConfig.detailPublishCourse}/:id`,
            element: <DetailCoursePublish />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseEvaluation}/${routesConfig.appraisedHistory}/:id`,
            element: <AppraisedHistory />,
          },
          {
            path: `${routesConfig.courseBrowse}/${routesConfig.courseApproval}/${routesConfig.appraisedHistory}/:id`,
            element: <AppraisedHistory />,
          },
        ],
      },
      {
        errorElement: <Page500 />,
        children: [
          {
            path: routesConfig.surveyPlan,
            element: <SurveyPlan title="Danh sách kế hoạch khảo sát" />,
          },
          {
            path: `${routesConfig.surveyPlan}/survey-plan-detail/:id`,
            element: <SurveyPlanDetail />,
          },
          {
            path: `${routesConfig.surveyPlan}/survey-plan-detail/:id/${routesConfig.addSurvey}`,
            element: <AddSurvyPage isDisplaySetting={true} />,
          },
        ],
      },
    ],
  },

  // Quản lý danh mục
  {
    path: routesConfig.categoryManagement,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.sharedCategoriesManagement,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <SharedCategoriesManagement title="Danh sách danh mục dùng chung" />
            ),
          },
        ],
      },
      {
        path: `${routesConfig.sharedCategoriesManagement}/shared-categories-management-detail/:id`,
        errorElement: <Page500 />,
        element: <SharedCategoriesManagementDetail />,
      },
      {
        path: routesConfig.portfolioValueManagement,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <PortfolioValueManagement title="Danh sách giá trị danh mục" />
            ),
          },
        ],
      },
      {
        path: `${routesConfig.portfolioValueManagement}/portfolio-value-management-detail/:id`,
        errorElement: <Page500 />,
        element: <PortfolioValueManagementDetail />,
      },
      {
        path: routesConfig.universityManagement,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <UniversityManagement title="Danh sách cơ sở đào tạo" />,
          },
        ],
      },
      {
        path: `${routesConfig.universityManagement}/university-management-detail/:id`,
        errorElement: <Page500 />,
        element: <UniversityManagementDetail />,
      },
      {
        path: routesConfig.lecturerManagement,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <LecturerManagement title="Danh sách giảng viên" />,
          },
        ],
      },
      {
        path: `${routesConfig.lecturerManagement}/lecturer-management-detail/:id`,
        errorElement: <Page500 />,
        element: <LecturerManagementDetail />,
      },
      {
        path: routesConfig.studentManagement,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <StudentManagement title="Danh sách sinh viên" />,
          },
        ],
      },
      {
        path: `${routesConfig.studentManagement}/student-management-detail/:id`,
        errorElement: <Page500 />,
        element: <StudentManagementDetail />,
      },
      {
        path: routesConfig.ministryUsersManagement,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <MinistryUsersManagement title="Danh sách người dùng cấp bộ" />
            ),
          },
        ],
      },
      {
        path: `${routesConfig.ministryUsersManagement}/ministry-users-management-detail/:id`,
        errorElement: <Page500 />,
        element: <MinistryUsersManagementDetail />,
      },
    ],
  },

  //tham gia khảo sát nhu cầu đào tạo
  {
    path: routesConfig.surveyStudent,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.trainingNeed,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessParticipateSurvey]}
              >
                <ListTrainingNeed title="Danh sách khảo sát nhu cầu đào tạo" />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.surveyRespond}/:id/:type/:theme`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessParticipateSurvey]}
              >
                <SurveyRespond />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },

  //khảo sát nhu cầu đào tạo
  {
    path: "/survey-training",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: "list-survey",
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute permissions={[Permission.CanAccessManageSurvey]}>
                <Survey title="Danh sách khảo sát nhu cầu đào tạo" />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "mooc-action-history-list/:id",
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[
                  Permission.CanAccessManageSurvey,
                  Permission.CanAccessParticipateSurvey,
                ]}
              >
                <ListMoocActionHistory title="Danh sách lịch sử thao tác" />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "browse-survey",
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessApprovalSurvey]}
              >
                <BrowseSurvey title="Danh sách phê duyệt đợt khảo sát" />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "publish-survey",
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute permissions={[Permission.CanAccessManageSurvey]}>
                <div>Chức năng xuất bản đợt khảo sát đang xây dựng</div>
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "publish-survey/:id",
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute permissions={[Permission.CanAccessManageSurvey]}>
                <SurveyPublish activeKey="3" title="Xuất bản đợt khảo sát" />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: `survey-detail/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[
                  Permission.CanAccessManageSurvey,
                  Permission.CanAccessApprovalSurvey,
                ]}
              >
                <DetailSurvey />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: `survey-approval/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessApprovalSurvey]}
              >
                <SurveyApproval />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: routesConfig.surverList,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute permissions={[Permission.CanAccessResultSurvey]}>
                <ListSurveyResult title="Danh sách khảo sát nhu cầu đào tạo" />
              </ProtectedRoute>
            ),
          },
        ],
      },
      {
        path: `${routesConfig.surverList}/${routesConfig.detailSurvey}/:id`,
        element: (
          <ProtectedRoute permissions={[Permission.CanAccessResultSurvey]}>
            <SurveyDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: `${routesConfig.surverList}/${routesConfig.detailSurvey}/:id/:type/:theme/:idPar`,
        element: (
          <ProtectedRoute permissions={[Permission.CanAccessResultSurvey]}>
            <SurveyRespond />
          </ProtectedRoute>
        ),
      },
      {
        path: `${routesConfig.surverList}/survey-detail/:id/:type/:theme`,
        element: (
          <ProtectedRoute permissions={[Permission.CanAccessResultSurvey]}>
            <SurveyRespond />
          </ProtectedRoute>
        ),
      },
      {
        path: `${routesConfig.surverList}/${routesConfig.detailSurvey}/render/:id`,
        element: (
          <ProtectedRoute permissions={[Permission.CanAccessResultSurvey]}>
            <ResultChartRender />
          </ProtectedRoute>
        ),
      },
      // {
      //   path: `${routesConfig.surverList}/${routesConfig.detailSurvey}/render/:id`,
      //   element: <ResultChartRender />,
      // },
    ],
  },

  // Kế hoạch khóa học
  {
    path: routesConfig.coursePlan,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      //Đăng ký kế hoạch khóa học
      {
        path: routesConfig.registerCoursePlan,
        errorElement: <Page500 />,

        children: [
          {
            path: `${routesConfig.moocCourseConstructionPlan}`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanning]}
              >
                <ListMoocCourseConstructionPlan title="Danh sách đăng ký kế hoạch" />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.moocCourseConstructionPlan}/${routesConfig.moocCourseConstructionPlanDetail}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanning]}
              >
                <DetailCoursePlan />
              </ProtectedRoute>
            ),
          },
          //lịch sử thao tác:: /course-plan/register-course-plan/
          {
            path: `${routesConfig.moocCourseConstructionPlan}/${routesConfig.historyMoocCourseConstructionPlan}/:id`,
            element: (
              <ProtectedRoute
                permissions={[
                  Permission.CanAccessCoursePlanning,
                  Permission.CanAccessApprovalCoursePlan,
                  Permission.CanAccessApprovalRegisterCoursePlan,
                  Permission.CanAccessRegisterCoursePlan,
                ]}
              >
                <HistoryCourseConstructionPlan title="Danh sách lịch sử thao tác" />
              </ProtectedRoute>
            ),
          },
        ],
      },
      //danh sach duyệt đăng ký khóa học
      {
        path: routesConfig.browseSubscriptionPlans,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessApprovalRegisterCoursePlan]}
              >
                <ListMoocApprovalCourseRegister
                  title={"Danh sách Phê duyệt đăng ký kế hoạch"}
                />
              </ProtectedRoute>
            ),
          },
        ],
      },
      //chi tiết duyệt đăng ký khóa học
      {
        path: `/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}/${routesConfig.moocApprovalDetail}/:id`,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessApprovalRegisterCoursePlan]}
              >
                <MoocCourseApprovalDetail />
              </ProtectedRoute>
            ),
          },
        ],
      },
      //duyệt đăng ký khóa học
      {
        path: `/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}/${routesConfig.approvalCourseRegister}/:id`,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessApprovalRegisterCoursePlan]}
              >
                <DetailApproval />
              </ProtectedRoute>
            ),
          },
        ],
      },
      //lịch sử duyệt đăng ký khóa học
      {
        path: `/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}/${routesConfig.moocApprovalCourseHistory}/:id`,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessApprovalRegisterCoursePlan]}
              >
                <MoocApprovalCourseHistory />
              </ProtectedRoute>
            ),
          },
        ],
      },
      // phân công lập kế hoạch
      {
        path: routesConfig.planningAssignment,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanAssignment]}
              >
                <CoursePlanningAssignment
                  title={"Danh sách đăng ký kế hoạch"}
                />
              </ProtectedRoute>
            ),
          },
          {
            path: ":id",
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanAssignment]}
              >
                <PlanningAssignment />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.detailAssignment}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanAssignment]}
              >
                <MoocCourseAssignmentDetail />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.planningAssignmentHistory}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanAssignment]}
              >
                <PlanningAssignmentHistory />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.assignmentHistory}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanAssignment]}
              >
                <AssignmentHistory />
              </ProtectedRoute>
            ),
          },
        ],
      },

      //Lập kế hoạch khóa học
      {
        path: routesConfig.organizeCoursePlan,
        errorElement: <Page500 />,

        children: [
          {
            path: `${routesConfig.planningCourseConstruction}`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanning]}
              >
                <ListPlanningCourseConstruction title="Danh sách kế hoạch xây dựng khoá học" />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.planningAssignmentHistory}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanning]}
              >
                <PlanningAssignmentHistory />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.planningCourseConstructionDetail}/:id`,
            element: (
              <ProtectedRoute
                permissions={[
                  Permission.CanAccessPlanningApproval,
                  Permission.CanAccessCoursePlanning,
                ]}
              >
                <DetailPlanningCourseConstruction />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.planningCourseConstruction}/${routesConfig.ApproveCouresConsrtuctionHistory}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanning]}
              >
                <ApproveCouresConsrtuctionHistory />
              </ProtectedRoute>
            ),
          },
        ],
      },
      // Duyệt lập kế hoạch
      {
        path: routesConfig.planningApproval,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessPlanningApproval]}
              >
                <CoursePlanPlanningApproval />
              </ProtectedRoute>
            ),
          },
          {
            path: `detail/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessPlanningApproval]}
              >
                <CourseConstructionPlanDetail title="Duyệt lập kế hoạch" />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.approvalDetail}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessPlanningApproval]}
              >
                <ApprovalCouresPlanDetail />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.assignmentHistory}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessPlanningApproval]}
              >
                <AssignmentHistory />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.planningAssignmentHistory}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessCoursePlanAssignment]}
              >
                <PlanningAssignmentHistory />
              </ProtectedRoute>
            ),
          },
        ],
      },
      //   Lập tổ chuyên môn
      {
        path: routesConfig.specializedGroupList,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessSpecializedGroup]}
              >
                <SpecializedGroupList title={"Danh sách tổ chuyên môn"} />
              </ProtectedRoute>
            ),
          },
          // {
          //   path: `${routesConfig.specializedGroupMemberList}/:id`,
          //   element: <FormSpecializedGroupMember  />,
          // },
          {
            path: `${routesConfig.detailSpecializedGroup}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessSpecializedGroup]}
              >
                <DetailSpecializedGroup />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.detailInfoMemberSpecializedGroup}/:userId`,
            errorElement: <Page500 />,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessSpecializedGroup]}
              >
                <UserDocumentSpecializedGroup />
              </ProtectedRoute>
            ),
          },
          // {
          //   path: "organization-history-list/:id",
          //   errorElement: <Page500 />,
          //   children: [
          //     {
          //       index: true,
          //       element: <OrganizationActionHistoryList title="Danh sách lịch sử thao tác" />,
          //     },
          //   ],
          // },
        ],
      },
      //Lập hội đồng thẩm định
      {
        path: routesConfig.organizeAppraisalBoard,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessAppraisalBoard]}
              >
                <OrganizeAppraisalBoard
                  title={"Danh sách hội đồng thẩm định"}
                />
              </ProtectedRoute>
            ),
          },
          // {
          //   path: `${routesConfig.organizeAppraisalBoardMemberList}/:id`,
          //   element: <FormOrganizeAppraisalBoardMember />,
          // },
          {
            path: `${routesConfig.detailOrganizeAppraisalBoard}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessAppraisalBoard]}
              >
                <DetailOrganizeAppraisalBoard />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.detailInfoMemberAppraisalBoard}/:userId`,
            errorElement: <Page500 />,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessAppraisalBoard]}
              >
                <UserDocumentOrganizeAppraisal />
              </ProtectedRoute>
            ),
          },
        ],
      },
      // Gửi phê duyệt kế hoạch
      {
        path: routesConfig.submitApprovalPlan,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[
                  Permission.CanAccessApprovalCoursePlanningSending,
                ]}
              >
                <CoursePlanSubmitApprovalPlan />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.detailSpecializedGroup}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessSpecializedGroup]}
              >
                <DetailSpecializedGroup />
              </ProtectedRoute>
            ),
          },
          {
            path: `detail/:id`,
            element: (
              <ProtectedRoute
                permissions={[
                  Permission.CanAccessApprovalCoursePlanningSending,
                ]}
              >
                <CourseConstructionPlanDetail title="Gửi phê duyệt kế hoạch" />
              </ProtectedRoute>
            ),
          },
          //lịch sử thao tác:
          {
            path: `${routesConfig.submitApprovalPlan}/${routesConfig.historySubmitApprovalPlan}/:id`,
            element: (
              <ProtectedRoute
                permissions={[
                  Permission.CanAccessApprovalCoursePlanningSending,
                  Permission.CanAccessCoursePlanning,
                ]}
              >
                <HistorySubmitApprovalPlan />
              </ProtectedRoute>
            ),
          },
        ],
      },
      // Gửi thẩm định kế hoạch
      {
        path: routesConfig.submitAppraisalPlan,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessSubmitPlanAppraisal]}
              >
                <CoursePlanSubmitAppraisalPlan />
              </ProtectedRoute>
            ),
          },
          {
            path: `${routesConfig.detailOrganizeAppraisalBoard}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessAppraisalBoard]}
              >
                <DetailOrganizeAppraisalBoard />
              </ProtectedRoute>
            ),
          },
          {
            path: `detail/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessSubmitPlanAppraisal]}
              >
                <CourseConstructionPlanDetail title="Gửi thẩm định kế hoạch" />
              </ProtectedRoute>
            ),
          },
          //lịch sử thao tác:
          {
            path: `${routesConfig.submitAppraisalPlan}/${routesConfig.historySubmitAppraisalPlan}/:id`,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessSubmitPlanAppraisal]}
              >
                <HistorySubmitAppraisalPlan />
              </ProtectedRoute>
            ),
          },
        ],
      },
      // Phê duyệt kế hoạch
      {
        path: routesConfig.approval,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[Permission.CanAccessApprovalCoursePlan]}
              >
                <ApprovalPlanList title="Danh sách Phê duyệt kế hoạch khoá học" />
              </ProtectedRoute>
            ),
          },
          {
            path: "approval-detail/:id",
            errorElement: <Page500 />,
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute
                    permissions={[Permission.CanAccessApprovalCoursePlan]}
                  >
                    <ApprovalPlanDetail
                      title="Phê duyệt"
                      contentConfirm="phê duyệt"
                      messageSuccess="Phê duyệt kế hoạch khóa học thành công"
                      navigationUrl={`/${routesConfig.coursePlan}/${routesConfig.approval}`}
                    />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },

      // Thẩm định kế hoạch
      {
        path: routesConfig.appraisal,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute permissions={[Permission.CanAccessPlanAppraisal]}>
                <AppraisalPlanList title="Danh sách Thẩm định kế hoạch khoá học" />
              </ProtectedRoute>
            ),
          },
          {
            path: "appraisal-detail/:id",
            errorElement: <Page500 />,
            children: [
              {
                index: true,
                element: (
                  <ProtectedRoute
                    permissions={[Permission.CanAccessPlanAppraisal]}
                  >
                    <ApprovalPlanDetail
                      title="Thẩm định"
                      contentConfirm="thẩm định"
                      messageSuccess="Thẩm định kế hoạch khóa học thành công"
                      navigationUrl={`/${routesConfig.coursePlan}/${routesConfig.appraisal}`}
                    />
                  </ProtectedRoute>
                ),
              },
            ],
          },
        ],
      },
    ],
  },

  //Quản lý khóa học
  {
    path: "/management-study",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.settingRemindCheckIn,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingRemindCheckIn title="Danh sách khoá học" />,
          },
        ],
      },
      {
        path: `${routesConfig.settingRemindCheckIn}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <RemindCheckInDetail />,
          },
        ],
      },
      {
        path: `${routesConfig.settingRemindCheckInDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <RemindCheckInDetail />,
          },
        ],
      },
      {
        path: `${routesConfig.course}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <MyCourseDetail />,
          },
        ],
      },
      {
        path: `${routesConfig.reportCheckIn}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ReportCheckInDetail />,
          },
        ],
      },
      {
        path: `${routesConfig.reportCheckInDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ReportCheckInDetail />,
          },
        ],
      },
      {
        path: `${routesConfig.reportQualityEvaluation}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <QualityEvaluation />,
          },
        ],
      },
      {
        path: `${routesConfig.reportCourseDocument}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ReportCourseDocument />,
          },
        ],
      },
    ],
  },

  //Cài đặt giám sát thi cử
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.settingSupervisionDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingSupervisionDetail />,
          },
        ],
      },
    ],
  },
  //Chấm bài thi tự động
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `setting-high-level/${routesConfig.settingIdentify}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingIdentify />,
          },
        ],
      },
      {
        path: `setting-high-level/${routesConfig.controlQualityCourse}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ControlQualityCourse />,
          },
        ],
      },
      {
        path: `setting-high-level/${routesConfig.managementRoleCourse}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ManagementPermissionCourse />,
          },
        ],
      },
      {

        path: `setting-high-level/${routesConfig.controlQualityCourse}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ControlQualityCourse />,
          },
        ],
      },
      {
        path: `setting-high-level/${routesConfig.logsAudit}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <LogsAudit title="Nhật ký hoạt động của người dùng" />,
          },
        ],
      },
      {
        path: `setting-high-level/${routesConfig.roleCourse}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingPermissionCourseDetail />,
          },
        ],
      },
      {
        path: `${routesConfig.automaticMarkDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <AutomaticMarkDetail />,
          },
        ],
      },
      {
        path: `${routesConfig.autoMarkActionDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <AutoMarkAction />,
          },
        ],
      },
      {
        path: `${routesConfig.autoMarkPermissionDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <AutoMarkPermission />,
          },
        ],
      },
      {
        path: `${routesConfig.managementListScore}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ManagementListScore />,
          },
        ],
      },
      {
        path: `${routesConfig.managementScoreDetail}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <DetailScore />,
          },
        ],
      },
      {
        path: `${routesConfig.rateAutoScore}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <RateAutoScore />,
          },
        ],
      },
    ],
  },
  //Kiểm soát chất lượng tiết học video
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.controlQualityLesson}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ControlQualityLesson />,
          },
        ],
      },
      {
        path: `/setting-high-level/${routesConfig.controlQualityVideo}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ControlQualityLessonVideo title="Danh sách khoá học" />,
          },
        ],
      },
      //Cài đặt cấu trúc bài giảng
      {
        errorElement: <Page500 />,
        children: [
          {
            path: `${routesConfig.settingStructureCourse}/:id`,
            element: <SettingStructureCourse />,
          },
        ],
      },
    ],
  },
  //Hệ thống gợi khóa học
  {
    path: "/system-suggest-course",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.learningDocumentationSystem,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <LearningDocumentationSystem />,
          },
        ],
      },
      {
        path: `${routesConfig.infoCourseDetailSystem}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <InfoCourseSystemDetail />,
          },
        ],
      },
      {
        path: routesConfig.synthesisReportSystem,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SynthesisReportSystem />,
          },
        ],
      },
      {
        path: routesConfig.listStudentSystem,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ListStudentSystem title="Danh sách sinh viên có cùng sở thích" />
            ),
          },
        ],
      },
      {
        path: `${routesConfig.favoriteStudentSystem}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <FavoriteStudent />,
          },
        ],
      },
      {
        path: `${routesConfig.infoSuggestSystem}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <InfoSystemSuggest />,
          },
        ],
      },
    ],
  },

  // Mô hình dự đoán, phân tích xu hướng học tập
  {
    path: "/prediction-model",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.managementGroupContent,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <ManagementGroupContent />,
          },
        ],
      },
      {
        path: routesConfig.settingPrediction,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingPrediction />,
          },
        ],
      },
      {
        path: `${routesConfig.settingDetailModel}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingDetailModel />,
          },
        ],
      },
      {
        path: `${routesConfig.viewSettingDetailModel}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <SettingDetailModel />,
          },
        ],
      },
      {
        path: `${routesConfig.profileStudentModel}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <InfoProfileStudent />,
          },
        ],
      },
      {
        path: `${routesConfig.infoCourseListModel}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <InfoCourseList />,
          },
        ],
      },
      {
        path: `${routesConfig.studentBeforeJoin}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <StudentBeforeJoin />,
          },
        ],
      },
      {
        path: `${routesConfig.studentAfterJoin}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <StudentAfterJoin />,
          },
        ],
      },
      {
        path: routesConfig.studentJoin,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <StudentJoin />,
          },
        ],
      },
    ],
  },
  {
    path: `${routesConfig.examStudentList}/:id`,
    element: <ListExamStudent />,
  },
  {
    path: routesConfig.blogAndForum,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.blogContent,
        errorElement: <Page500 />,
        element: <BlogContentPage title="Danh sách nội dung blog" />,
      },
      // {
      //   path: `${routesConfig.blogContent}/${routesConfig.detailContentBlog}/:id`,
      //   errorElement: <Page500 />,
      //   element: <DetailContentPreview />,
      // },
      {
        path: routesConfig.designThemeBlogContent,
        errorElement: <Page500 />,
        element: (
          <DesignThemeBlogContentPage title="Thiết lập giao diện trang blog" />
        ),
      },
      {
        path: routesConfig.manageContentForum,
        errorElement: <Page500 />,
        element: <ManageContentForum title="Danh sách chủ đề" />,
      },
      {
        path: `${routesConfig.manageContentForum}/:id`,
        errorElement: <Page500 />,
        element: <DetailTopicPage />,
      },
    ],
  },

  //Cấu hình
  {
    path: routesConfig.configuration,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.approvalConfiguration,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ListApprovalConfiguration title="Danh sách các cấp phê duyệt" />
            ),
          },
        ],
      },
      {
        path: `${routesConfig.approvalConfiguration}/:id`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <DetailApprovalConfiguration />,
          },
        ],
      },
      {
        path: "mooc-action-history-list/:id",
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: (
              <ProtectedRoute
                permissions={[
                  Permission.CanAccessManageSurvey,
                  Permission.CanAccessParticipateSurvey,
                ]}
              >
                <ListMoocActionHistory title="Danh sách lịch sử thao tác" />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },

  {
    path: routesConfig.blogAndForum,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.blogContent,
        errorElement: <Page500 />,
        element: <BlogContentPage title="Danh sách nội dung blog" />,
      },
      // {
      //   path: `${routesConfig.blogContent}/${routesConfig.detailContentBlog}/:id`,
      //   errorElement: <Page500 />,
      //   element: <DetailContentPreview />,
      // },
      {
        path: routesConfig.designThemeBlogContent,
        errorElement: <Page500 />,
        element: (
          <DesignThemeBlogContentPage title="Thiết lập giao diện trang blog" />
        ),
      },
      {
        path: routesConfig.manageContentForum,
        errorElement: <Page500 />,
        element: <ManageContentForum title="Danh sách chủ đề" />,
      },
      {
        path: `${routesConfig.manageContentForum}/:id`,
        errorElement: <Page500 />,
        element: <DetailTopicPage />,
      },
    ],
  },

  {
    path: routesConfig.login,
    element: <Login />,
  },

  {
    path: `${routesConfig.pageBuilderView}/:id`,
    element: <PageBuilderView />,
  },
  {
    path: `${routesConfig.universityPageBuilderView}/:id`,
    element: <UniversityPageBuilderView />,
  },
  {
    path: `${routesConfig.universityPageBuilderViewCourseList}/:id`,
    element: <UniversityPageBuilderViewCourseList />,
  },
  {
    path: `${routesConfig.universityPageBuilderViewCourseListPage}/:id`,
    element: <UniversityPageBuilderViewCourseListPage />,
  },
  {
    path: `${routesConfig.courseConstruction}/${routesConfig.contentConstruction}/${routesConfig.coursePreview}/:id`,
    element: <ContentPreview />,
  },
  {
    path: `${routesConfig.blogAndForum}/${routesConfig.blogContent}/${routesConfig.detailContentBlog}/:id`,
    element: <DetailContentPreview />,
  },
  {
    path: `${routesConfig.courseConstruction}/${routesConfig.previewPlan}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <PreviewQuiz />,
      },
    ],
  },
  {
    path: `${routesConfig.blogAndForum}/${routesConfig.blogPreview}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <ForumBlockPageReview />,
      },
    ],
  },

  {
    path: `${routesConfig.settingIntroCourse}/${routesConfig.previewSettingIntroCourse}/:id`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <PreviewIntroPageCourse />,
      },
    ],
  },

  {
    path: routesConfig.scoringManagement,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        errorElement: <Page500 />,
        element: <AutoScoringManagement title="Danh sách khoá học" />,
      },
      {
        path: `${routesConfig.exercises}/:id`,
        errorElement: <Page500 />,
        element: <ManagementExerciseList title="Danh sách bài tập" />,
      },
    ],
  },
  {
    path: routesConfig.scoringListCourse,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        errorElement: <Page500 />,
        element: <CourseScoringList title="Danh sách khoá học" />,
      },
      {
        path: `${routesConfig.scoringListCourse}/:id`,
        errorElement: <Page500 />,
        element: <ExerciseList title="Danh sách bài tập" />,
      },
      {
        path: `${routesConfig.scoringListCourse}/:id/exercise`,
        errorElement: <Page500 />,
        element: <ScoringList title="Danh sách bài chấm" />,
      },
      {
        path: `${routesConfig.scoringListCourse}/${routesConfig.managementScoreAuto}/:id`,
        errorElement: <Page500 />,
        element: <ManagementScoreAuto />,
      },
    ],
  },
  {
    path: `${routesConfig.scoring}/:id`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <ScoringDetail />,
      },
    ],
  },
  {
    path: `${routesConfig.myNotification}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <MyNotificationPage />,
      },
    ],
  },
  {
    path: `${routesConfig.userInfo}`,
    element: <DashboardRootPageNoMenu />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        element: <UserInfo />,
      },
    ],
  },

  {
    path: routesConfig.notifications,
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        index: true,
        errorElement: <Page500 />,
        element: <Notifications title="Danh sách thông báo" />,
      },
      {
        path: `${routesConfig.notifications}/:id`,
        errorElement: <Page500 />,
        element: <NotificationDetail />,
      },
      {
        path: `${routesConfig.notifications}/:id/history`,
        errorElement: <Page500 />,
        element: <NotificationHistory />,
      },
      {
        path: `${routesConfig.notifications}/:id/edit`,
        errorElement: <Page500 />,
        element: <EditNotification />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.courseManagement}/${routesConfig.courseList}`,
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <CourseList title="Danh sách khoá học" />,
          },
          {
            path: `${routesConfig.courseManagement}/${routesConfig.courseList}/:id`,
            errorElement: <Page500 />,
            element: <CourseManagement />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: `${routesConfig.statisticalReportForInstructors}`,
        errorElement: <Page500 />,
        index: true,
        element: (
          <StatisticalReporForInstructors title="Báo cáo thống kê cho giảng viên" />
        ),
      },
      {
        path: `${routesConfig.statisticalReportForMinistry}`,
        errorElement: <Page500 />,
        index: true,
        element: (
          <StatisticalReportForMinistry title="Báo cáo thống kê dành cho cấp Bộ" />
        ),
      },
      {
        path: `${routesConfig.statisticalReportForUniversity}`,
        errorElement: <Page500 />,
        index: true,
        element: (
          <StatisticalReportForUniversity title="Báo cáo thống kê dành cho trường" />
        ),
      },
    ],
  },
]);