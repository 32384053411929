import {
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HeartOutlined,
  HistoryOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SettingOutlined,
  StopOutlined,
  ThunderboltOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { isEmpty } from "lodash";
import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PageHeader from "../../components/page-header/PageHeader";
import FilterAdminInterface from "../../components/admin-interface/filter-table/FilterTable";
import { formatDateV2, formatOriginTime } from "../../utils/converDate";
import { routesConfig } from "../../config/routes";
import TableData from "../../components/table-data/TableData";
import FormFilter from "../../components/admin-interface/filter-group/FormFilter";
import { copyTemplateAdminInterface, deleteAdminInterface, getAdminInterface } from "../../service/admin-interface";
import SettingAdminInterfaceDrawer from "../../components/drawer/setting-activity-admin-interface";
import CommonModal from "../../components/modal/common";
import { colorStore, globalStore, useAuthStore } from "../../stores/stores";
import SaveTemplateAdminInterfaceDrawer from "../../components/drawer/save-setting-admin-interface";

type Props = {
  title?: string;
};

const AdminInterFacePage = (props: Props) => {
  const [tab, setTab] = useState("1");
  const { title } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedSaveTemplate, setIsOpenedSaveTemplate] = useState<boolean>(false);
  const [listAdminInterface, setListAdminInterface] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);

  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataUpdate, setDataUpdate] = useState<any>();
  const [lastPreview, setlastPreview] = useState<any>(null);
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const { reloadAdminInterface, setReloadAdminInterface } = globalStore();

  const { setBackgroundColor, setTextColor, setSidebarColor } = colorStore()

  const [filterData, setFilterData] = useState<any>({
    status: null,
    approvedDate: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const handleOpenCreate = () => {
    setIsOpened(true);
    setDataUpdate({})
  };

  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });

  const optionsContentForum: MenuProps["items"] = [
    {
      key: "1",
      label: "Xem chi tiết",
      icon: <FileDoneOutlined />,
    },
    {
      key: "2",
      label: "Lưu mẫu",
      icon: <SaveOutlined />,
    },
    {
      key: "3",
      label: "Tạo bản nháp",
      icon: <EditOutlined />,
    },
    {
      key: "4",
      label: "Sao chép/Dán",
      icon: <CopyOutlined />,
    },
    {
      key: "5",
      label: "Cài đặt hoạt động",
      icon: <SettingOutlined />,
    },
    {
      key: "6",
      label: "Xóa",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue.trim() || undefined,
      uuid: getUserInfo?.enterpriseUuid ?? null,
      createdDateFrom: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
      createdDateTo: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
      status: filterData.status ?? null,
      isMinistry: tab === "1" ? true : false,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
    };

    await getAdminInterface(data)
      .then((res) => {
        const { data } = res.data
        setListAdminInterface(data.interfaceData)
        setTotalRecords(data.total);
        setCountAwaitingReview(data?.allMinistry)
        setCountReviewed(data?.allSchool)

        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder, lastPreview]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const renderStatus = (value: number) => {
    switch (value) {
      case 0:
        return <p>Nháp</p>
        break;
      case 1:
        return <p>Áp dụng</p>
        break;
      default:
        break;
    }
  }

  const copyTemplate = async (id: string) => {
    await copyTemplateAdminInterface(id).then((res) => {
      if (res.status === 200) {
        message.success("Tạo bản nháp thành công")
        getListData()
      }
    })
  }

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      status: null,
      courseType: null,
      approvedDate: null,
    });
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Trang quản trị",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
      render: (_: any, record, index: number) => {
        return <div dangerouslySetInnerHTML={{ __html: record?.name }}></div>;
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record) => {
        return (
          <>{record?.createdDate ? formatDateV2(record?.createdDate) : null}</>
        );
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      shouldCellUpdate(record, prevRecord) {
        return true
      },
      width: 20,
      render: (_: any, record) => {
        return (
          <div className="flex items-center gap-2">
            <div className="table-status">
              <div
                className="color-icon"
                style={{
                  backgroundColor:
                    record.status === 0
                      ? "#1677FF"
                      : record.status === 1
                        ? "#24b524" : "white"
                }}
              ></div></div>
            {renderStatus(record?.status)}
          </div>
        );
      },
    },
    {
      title: "Mã mẫu",
      dataIndex: "sampleCode",
      key: "sampleCode",
      width: 20,
    },
    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  window.open(
                    `${routesConfig.adminInterface}/${routesConfig.detailAdminInterface}?interfaceId=${record?.id}`,
                  )
                }
                }
              >
                <EyeOutlined />
              </a>

              <Dropdown
                menu={{
                  items: optionsContentForum,
                  onClick: ({ key }) => {
                    if (key === '1') {
                      setIsOpened(true)
                      setMode("view")
                      setDataUpdate(record)
                    }

                    if (key === '2') {
                      setIsOpenedSaveTemplate(true);
                      setDataUpdate(record)
                      setMode("")
                    }

                    if (key === '3') {
                      Modal.confirm({
                        title: `Xác nhận tạo bản nháp giao diện quản trị ${tab === "1" ? "chung" : "trường"}`,
                        content: `Vui lòng xác nhận tạo bản nháp mẫu giao diện quản trị ${tab === "1" ? "chung" : "trường"}`,
                        onOk: () => {
                          copyTemplate(record?.id)
                        },
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        centered: true,
                        icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }

                    if (key === "4") {
                      Modal.confirm({
                        title: `Xác nhận tạo bản sao`,
                        content: `Vui lòng xác nhận tạo bản sao giao diện quản trị ${tab === "1" ? "chung" : "trường"}`,
                        onOk: () => {
                          copyTemplate(record?.id)
                        },
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        centered: true,
                        icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }

                    if (key === "5") {
                      setIsOpened(true);
                      setDataUpdate(record)
                      setMode("")
                    }
                    if (key === "6") {
                      setIsOpenedConfirm(true);
                      setDeleteId(record?.id)
                      setMode("")
                    }
                  },
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space >
          </>
        );
      },
      width: 10,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Quản trị chung
          <span
            className={`number-record ${tab === "1" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countAwaitingReview}
          </span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listAdminInterface}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
            clearRowkey={clearRowkey}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          Quản trị trường
          <span
            className={`number-record ${tab === "2" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countReviewed}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listAdminInterface}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const deletePortalAdmin = async () => {
    await deleteAdminInterface(deleteId).then((res) => {
      if (res.status === 200) {
        message.success("Xoá giao diện quản trị thành công")
        setIsOpenedConfirm(false)
        getListData()
        setReloadAdminInterface(!reloadAdminInterface)
      }
    })
  };

  const statusList = [
    {
      value: "1",
      label: "Đã xuất bản",
    },
  ];

  return (
    <Layout className="page-header-group course-evaluation">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "filterForm") {
            getListData()
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
            }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} listBreadcrumb={listBreadcrumb} />
            </div>
            <FilterAdminInterface
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              setlastPreview={setlastPreview}
              tab={tab}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
            filterData={filterData}
            setFilterData={handleChangeFilter}
          />
        </div>
        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChange} />
        </div>
      </Form.Provider>

      <CommonModal
        open={isOpenedConfirm}
        title={'Xoá giao diện quản trị'}
        desc={`Chủ đề đã xoá sẽ không thể khôi phục và giao diện sẽ chuyển về mặc đinh. Bạn có chắc muốn xóa giao diện quản trị?`}
        closeIcon={true}
        okText={'Xác nhận'}
        cancelText={"Huỷ"}
        icon={<WarningOutlined style={{ color: "red" }} />}
        confirm={deletePortalAdmin}
        closeModal={() => setIsOpenedConfirm(false)}
        className="modal-delete-common"
      />

      <SettingAdminInterfaceDrawer
        open={isOpened}
        handleClose={() => setIsOpened(false)}
        getListData={getListData}
        tab={tab}
        mode={mode}
        dataUpdate={dataUpdate}
      />

      <SaveTemplateAdminInterfaceDrawer
        open={isOpenedSaveTemplate}
        handleClose={() => setIsOpenedSaveTemplate(false)}
        getListData={getListData}
        tab={tab}
        mode={mode}
        dataUpdate={dataUpdate}
      />
    </Layout>
  );
};

export default AdminInterFacePage;
