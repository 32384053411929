import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
  useRef,
} from "react";
import "./index.scss";
import { Layout, Tabs, TabsProps, Form } from "antd";
import { useParams } from "react-router-dom";
import { useLocation, useSearchParams } from "react-router-dom";
import ResultShot from "../result-shot/index";
import FilterChart from "../filter-chart/FilterTable";
import {
  getSurveyById,
  getResult,
} from "../../../../../service/survey-managment/survey-synthesis/survey-systhesis";
import { isEmpty } from "lodash";
import { DataResult } from "../../../../../types/survey-managment/listSurvey";
import HighCharts from "../high-chart/Highcharts";
import { FilterResultChart } from "../../../../../types/survey-managment/listSurvey";
const { Content } = Layout;
const imgPath = "/images/";
type ResultChartProps = {
  id?: number;
  refChild?: any;
  repDateFrom?: string;
  repDateTo?: string;
  typeQuestion?: number[] | null | undefined;
  nameQuestion?: number[] | null | undefined;
};
const ResultChartRender: FunctionComponent<ResultChartProps> = ({
  id,
  refChild,
  repDateFrom,
  repDateTo,
  typeQuestion,
  nameQuestion,
}) => {
  const location = useLocation();
  const [dataFilter, setDataFilter] = useState<FilterResultChart>({
    repDate: null,
    typeQuestion: null,
    nameQuestion: null,
  });
  const childRef = useRef(null);
  const [params, setParams] = useSearchParams();
  const paramsId = useParams();
  const [dataResult, setDataResult] = useState<DataResult[]>([]);
  const [isOpened, setIsOpened] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>("");
  const [form] = Form.useForm();
  const getResultSurvey = async () => {
    const dataFilterResult = {
      id: id,
      repDateFrom: repDateFrom,
      repDateTo: repDateTo,
      typeQuestion: typeQuestion,
      nameQuestion: nameQuestion,
    };
    await getResult(dataFilterResult).then((res) => {
      const { data } = res.data.data;
      const dataResultFormat = data.map((item: any) => {
        // Ensure answerOther exists before accessing its length
        if (item.answerOther?.length > 0) {
          item.questionData = [
            ...item.questionData,
            ["Khác", item.answerOther.length],
          ];
        }
        // Return the item to make sure map creates a new array of items
        return item;
      });
      console.log(dataResult);
      setDataResult(dataResultFormat);
      setDataResult(data);
    });
  };

  useEffect(() => {
    getResultSurvey();
  }, [id, repDateFrom, repDateTo, typeQuestion, nameQuestion]);

  const [showFilter, setShowFilter] = useState<boolean>(true);

  const handleSubmitSearch = () => {
    // if (searchValue === "") {
    //   setPageNumber(1);
    // }
    getResultSurvey();
  };
  return (
    <div className="survey-managment-synthesis">
      <Layout
        className="page-header-group"
        style={{ backgroundColor: "white" }}
      >
        <Form.Provider>
          <div
            className={`page-header-group--layout ${
              showFilter ? "open-filter" : ""
            }`}
            style={{ backgroundColor: "white" }}
          >
            <div className="table-header--wrapper"></div>
          </div>
          <div className="list-chart" ref={refChild}>
            <div id="target-element" style={{ display: "none" }}>
              {dataResult &&
                dataResult.map((data, index) => {
                  if (data?.type !== 8) {
                    const optionstest = {
                      chart: {
                        type: "pie", // Loại biểu đồ hình tròn
                      },
                      title: {
                        text: null,
                      },
                      // series: [{
                      //   data: [1, 2, 3, 4, 5]
                      // }]
                      series: [
                        {
                          name: "Dữ liệu", // Tên của loại dữ liệu
                          data: data?.questionData,
                        },
                      ],
                    };
                    return (
                      <div className="block-chart">
                        <HighCharts
                          key={index}
                          title={data?.question}
                          options={optionstest}
                          numberQuestion={data?.ordernumber}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <ResultShot
                        key={index}
                        numberQuestion={data?.ordernumber}
                        title={data?.question}
                        data={data?.answerText}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </Form.Provider>
      </Layout>
    </div>
  );
};

export default ResultChartRender;
