import { commonMedia } from "./grapesjs-config";
export const nameCategoryBlocks = "Khối giao diện";
export const nameCategoryTemplates = "Mẫu hoàn chỉnh";
export const nameContactBlock = "Khối liên hệ";
export const nameLinkedCourseBlock = "Khối khóa học mẫu";
export const nameLecturersBlock = "Khối giảng viên";
export const nameBasicBlock = "Giao diện cơ bản";
export const nameCountBlocks = "Khối thống kê mẫu";
export const nameRoadMapBlocks = "Khối lộ trình khóa học";
export const nameHeaderBlocks = "Giới thiệu chung";
export const nameRegisterBlocks = "Khối ghi danh";
export const nameConditionBlocks = "Khối điều kiện";
export const nameAffiliatedBlocks = "Khối liên kết";

export const generalHeaderIntroduction = (data?: any) => {
  const blockUI = {
    id: "header-block-id",
    label: "Mẫu chứng chỉ 1",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
  <body>
  <div id="iz98" class="relative bg-white rounded-lg shadow-lg overflow-hidden">
    <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-07-26T04%3A11%3A40.391259226Z/91dba49a-6e61-4bb1-b94e-33c3b57d2386Certificate%20Template01.png" alt="Certificate Background" id="i3lz" class="inset-0 w-full object-cover absolute"/>
    <div id="iqce" class="relative text-center px-80 py-40">
      <h1 id="iilc" class="font-bold text-primary mt-16 tracking-widest text-8xl text-gray-700">CERTIFICATE
      </h1>
      <h2 id="i2ak" class="text-primary font-light tracking-widest mt-4 text-orange-600 text-6xl">OF EXCELLENCE
      </h2>
      <p id="i76x1" class="text-muted-foreground mt-16 text-2xl tracking-widest">THIS CERTIFICATE IS PROUDLY PRESENTED TO
      </p>
      <h3 id="ir0ml" class="font-extrabold text-primary text-9xl mt-16">Sarah J. Miller
      </h3>
      <p id="i1c4b" class="text-muted-foreground mt-24 text-2xl">LEARN ENGLISH VOCABULARY, SPEAKING AND LISTENING SKILLS AND BECOME MORE FLUENT LEARNING FROM A NATIVE ENGLISH TEACHER
      </p>
      <div id="iauuq" class="flex justify-between mb-40 mt-72 px-80">
        <div id="i27mb" class="text-center">
          <p id="i2ifr" class="text-muted-foreground text-3xl">
          </p>
        </div>
        <div class="text-center">
          <p id="i781e" class="text-muted-foreground text-3xl">
          </p>
        </div>
      </div>
    </div>
  </div>
</body>
  `;

  return blockUI;
};

export const generalContentIntroduction = (data?: any) => {
  const blockUI = {
    id: "content-block-id",
    label: "Mẫu chứng chỉ 2",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <div id="iz98" class="relative bg-white rounded-lg shadow-lg overflow-hidden">
    <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-07-26T03%3A49%3A12.782981362Z/75d4e243-2bb9-4915-afd5-0a4c238b8994Certificate%20Template02.png" alt="Certificate Background" id="i3lz" class="inset-0 w-full object-cover absolute"/>
    <div id="iqce" class="relative p-8 text-center px-80">
      <h1 id="iilc" class="font-bold text-primary text-7xl text-blue-900 mt-16 tracking-widest">CERTIFICATE
      </h1>
      <h2 id="i2ak" class="text-primary font-light text-4xl tracking-widest mt-4">OF EXCELLENCE
      </h2>
      <p id="i76x1" class="text-muted-foreground mt-16 text-2xl tracking-widest text-blue-900">PROUDLY PRESENTED TO
      </p>
      <h3 id="ir0ml" class="font-extrabold text-primary text-blue-900 text-9xl">Sarah J. Miller
      </h3>
      <p id="i1c4b" class="text-muted-foreground text-2xl text-blue-900 mt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        <br/>Donec eget risus vitae orci eleifend ultricies. Nunc vitae erat vel odio luctus auctor.
      </p>
      <div class="mt-8">
        <p id="ibil6" class="text-muted-foreground text-3xl text-blue-900 text-bold mt-24">MEDIA MONITORING
        </p>
        <p id="i1do7" class="text-muted-foreground text-blue-900 text-xl mt-2">JUNE 27
        </p>
      </div>
      <div id="iauuq" class="flex justify-between mb-40 px-64 mt-24">
        <div id="i27mb" class="text-center">
          <p id="i2ifr" class="text-muted-foreground text-3xl text-blue-900">Jessica Li
          </p>
        </div>
        <div class="text-center">
          <p id="i781e" class="text-muted-foreground text-3xl text-blue-900">Amanda Miller
          </p>
        </div>
      </div>
    </div>
  </div>
</body>
  `;

  return blockUI;
};

export const generalImageIntroduction = (data?: any) => {
  const blockUI = {
    id: "intro-img-block-id",
    label: "Phần ảnh giới thiệu",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
    <header id="ikzvv" class="text-gray-600 body-font shadow-md">
      12312312312312313131231212312
    </header>
  `;

  return blockUI;
};

export const generalFooterIntroduction = (data?: any) => {
  const blockUI = {
    id: "footer-block-id",
    label: "Mẫu chứng chỉ 3",
    css: "",
    content: "",
    media: commonMedia,
    category: nameHeaderBlocks,
  };

  blockUI.content = `
<body>
  <meta charset="UTF-8"/>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <title>Hero Section with Background
  </title>
  <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet"/>
  <footer id="i5d9ox" class="text-gray-600 body-font bg-gray-100">
    <div id="iy8na6" class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <img src="https://s3.moooc.xyz/dev-stable/page-builder/4/2024-06-13T08%3A14%3A49.301501154Z/f065a648-df6c-4f8e-8741-df5559902bc4images.png" id="i4mk8z" class="w-8"/>
      <div id="ijhjhp" class="w-90 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
        <span id="inz6nz" class="text-xl ml-8">Đại học Bách Khoa Hà Nội</span>
        <p id="ihqko5" class="text-sm text-gray-500 ml-8 mt-8">Bản quyền thuộc về Đại học Bách khoa Hà Nội
          <br/>Địa chỉ: Số 1 Đại Cồ Việt, Hai Bà Trưng, Hà Nội
          <br/>Điện thoại: 024 3869 4242
        </p>
      </div>
      <div id="iozwhq" class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
        <div id="im9se" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
          </nav>
        </div>
        <div id="iw0yh" class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="i18zz" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Giới thiệu</a>
            </li>
            <li id="iu5b6" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Khám phá</a>
            </li>
            <li id="ivzynq" class="mb-4">
              <a class="text-gray-600 hover:text-gray-800">Blog</a>
            </li>
          </nav>
        </div>
        <div class="lg:w-1/4 md:w-1/2 w-full px-4">
          <nav class="list-none mb-10">
            <li id="ik4k7l" class="mb-4">
              <a id="iuw93t" class="text-gray-600 hover:text-gray-800">Chính sách</a>
            </li>
            <li id="iw2yl9" class="mb-4">
              <a id="ihhfkr" class="text-gray-600 hover:text-gray-800">Quyền riêng tư</a>
            </li>
            <li id="ipzazs" class="mb-4">
              <a id="i2jcjh" class="text-gray-600 hover:text-gray-800">Liên hệ</a>
            </li>
            <li id="iuvvzm">
            </li>
          </nav>
        </div>
      </div>
    </div>
  </footer>
</body>`;

  return blockUI;
};
