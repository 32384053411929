import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Input,
  Modal,
  Row,
  Space,
  Tree,
  TreeDataNode,
} from "antd";
import React, { Dispatch, useEffect, useMemo, useState } from "react";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyOutlined,
  DeleteOutlined,
  FormOutlined,
  HolderOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd/lib";
import EditorQuill from "../editor-quill/EditorQuill";
import {
  TypeCourseStructure,
  TypeChapterList,
  TypeSubjectList,
} from "../../../../../../../../types/course-plan-managment/mooc-course-construction-plan";
import InputInfo from "../input-info/InputInfo";

interface DetailChapterProps {
  chapterList?: TypeChapterList[];
  updateChapterList?: (data: TypeChapterList[]) => void;
  isShowDetailAll?: boolean;
  setIsShowDetailAll?: Dispatch<boolean>;
}

const DetailChapter: React.FC<DetailChapterProps> = ({
  chapterList,
  updateChapterList,
  isShowDetailAll,
  setIsShowDetailAll,
}) => {
  const optionsChapter: MenuProps["items"] = [
    {
      key: "1",
      label: "Tạo bản sao",
      icon: <CopyOutlined />,
    },
    {
      key: "2",
      label: "Chuyển lên trên",
      icon: <ArrowUpOutlined />,
    },
    {
      key: "3",
      label: "Chuyển xuống dưới",
      icon: <ArrowDownOutlined />,
    },
    {
      key: "4",
      label: "Xóa",
      icon: <DeleteOutlined />,
      className: "dropdown-delete",
      danger: true,
    },
  ];

  const listDataChapter = useMemo(() => {
    return chapterList;
  }, [chapterList]);
  useEffect(() => { }, [chapterList]);

  const [optionDropDown, setIsOption] = useState(optionsChapter);
  const handleOptionDropDown = (index: number) => {
    let newOptions = [...optionsChapter];

    if (listDataChapter && listDataChapter.length < 2) {
      newOptions.splice(1, 2);
    }
    if (listDataChapter && listDataChapter.length > 1) {
      if (index === 0) {
        newOptions.splice(1, 1);
      }
      if (index === listDataChapter.length - 1) {
        newOptions.splice(2, 1);
      }
    }
    setIsOption(newOptions);
  };

  // console.log(`detailChapter::`, listDataChapter);

  const moveItemDown = (indexChapter: number) => {
    if (
      !chapterList ||
      indexChapter < 0 ||
      indexChapter >= chapterList.length - 1
    )
      return;

    // Sao chép chương hiện tại
    let currentChapter = chapterList[indexChapter];

    // Di chuyển chương xuống bằng cách đổi chỗ với chương dưới đó
    let newChapterList = [...chapterList];
    newChapterList[indexChapter] = newChapterList[indexChapter + 1];
    newChapterList[indexChapter + 1] = currentChapter;

    // newChapterList[indexChapter].orderNumber = indexChapter + 1;
    // newChapterList[indexChapter + 1].orderNumber = indexChapter + 2;

    // Cập nhật lại orderNumber của tất cả các chương
    for (let i = 0; i < newChapterList.length; i++) {
      newChapterList[i].orderNumber = i + 1;
    }

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(newChapterList);
  };

  const moveItemUp = (indexChapter: number) => {
    if (!chapterList || indexChapter <= 0) return;

    // Sao chép chương hiện tại
    let currentChapter = chapterList[indexChapter];

    // Di chuyển chương lên bằng cách đổi chỗ với chương phía trên đó
    let newChapterList = [...chapterList];
    newChapterList[indexChapter] = newChapterList[indexChapter - 1];
    newChapterList[indexChapter - 1] = currentChapter;

    // newChapterList[indexChapter].orderNumber = indexChapter + 1;
    // newChapterList[indexChapter - 1].orderNumber = indexChapter;

    // Cập nhật lại orderNumber của tất cả các chương
    for (let i = 0; i < newChapterList.length; i++) {
      newChapterList[i].orderNumber = i + 1;
    }

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(newChapterList);
  };

  const handleCopyChapter = (indexChapter: number) => {
    if (!chapterList || indexChapter < 0 || indexChapter >= chapterList.length)
      return;

    // Sao chép chương hiện tại
    let newChapter = {
      structureItemName: chapterList[indexChapter].structureItemName,
      structureItemLevel: chapterList[indexChapter].structureItemLevel,
      orderNumber: chapterList[indexChapter].orderNumber,
      subjectList: chapterList[indexChapter].subjectList.map(
        (subject: TypeSubjectList, index :any) => ({
          structureItemName: subject.structureItemName,
          structureItemLevel: subject.structureItemLevel,
          orderNumber: subject.orderNumber || index,
          lessonList: subject.lessonList.map((lesson: TypeCourseStructure) => ({
            structureItemName: lesson.structureItemName,
            structureItemLevel: lesson.structureItemLevel,
            orderNumber: lesson.orderNumber || index,
          })),
        })
      ),
    };

    let copiedChapter = JSON.parse(JSON.stringify(newChapter));
    // Thêm chương đã sao chép vào danh sách chapterList
    let newChapterList = [
      ...chapterList.slice(0, indexChapter + 1),
      copiedChapter,
      ...chapterList.slice(indexChapter + 1),
    ];


    // Update the structureItemOrder of the copied chapter and subsequent chapters


    // Update the orderNumber of the copied chapter and subsequent chapters

    for (let i = indexChapter + 1; i < newChapterList.length; i++) {
      newChapterList[i].orderNumber = i + 1;
    }

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(newChapterList);
  };


  const handleAddNewSubject = (indexChapter: number, indexSubject: number) => {
    if (indexChapter === undefined) return;

    const newSubject: TypeSubjectList = {
      structureItemName: `Bài giảng mới`,
      structureItemLevel: 2,
      orderNumber: 0, // Initialize the order
      lessonList: [
        {
          structureItemName: `Học liệu mới`,
          structureItemLevel: 3,
          orderNumber: 1,
        },
      ],
    };

    // Thêm môn mới vào danh sách môn của chương tương ứng
    if (chapterList) {
      const updatedChapterList = [...chapterList];
      const chapter = updatedChapterList[indexChapter];

      // Thêm vào trước môn có vị trí indexSubject+1
      chapter.subjectList.splice(indexSubject + 1, 0, newSubject);

      // Cập nhật orderNumber cho môn mới và các môn sau đó
      for (let i = indexSubject + 1; i < chapter.subjectList.length; i++) {
        chapter.subjectList[i].orderNumber = i + 1;
      }

      // Gọi hàm cập nhật chapterList mới
      updateChapterList && updateChapterList(updatedChapterList);
    }
  };

  const handleAddNewLesson = (
    indexChapter: number,
    indexSubject: number,
    indexLesson: number
  ) => {
    if (
      !chapterList ||
      indexChapter < 0 ||
      indexChapter >= chapterList.length ||
      indexSubject < 0 ||
      indexSubject >= chapterList[indexChapter].subjectList.length
    )
      return;

    const newLesson: TypeCourseStructure = {
      structureItemName: `Học liệu mới`,
      structureItemLevel: 3,
      orderNumber: 0, // Initialize the order
    };

    // Thêm bài học mới vào danh sách bài học của môn tương ứng
    const updatedChapterList = [...chapterList];
    const subject = updatedChapterList[indexChapter].subjectList[indexSubject];

    // Thêm vào cuối danh sách bài học của môn
    subject.lessonList = subject.lessonList || [];
    subject.lessonList.splice(indexLesson + 1, 0, newLesson);

    // Cập nhật orderNumber cho bài học mới và các bài học sau đó
    for (let i = indexLesson + 1; i < subject.lessonList.length; i++) {
      subject.lessonList[i].orderNumber = i + 1;
    }

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(updatedChapterList);
  };

  const handleDeleteChapter = (indexChapter: number) => {
    if (!chapterList || indexChapter < 0 || indexChapter >= chapterList.length)
      return;

    // Tạo một bản sao của chapterList và loại bỏ chương tại indexChapter
    const updatedChapterList = chapterList
      .slice(0, indexChapter)
      .concat(chapterList.slice(indexChapter + 1));

    // Cập nhật orderNumber cho các chương còn lại
    for (let i = indexChapter; i < updatedChapterList.length; i++) {
      updatedChapterList[i].orderNumber = i + 1;
    }

    // Gọi hàm cập nhật chapterList mới
    if (updateChapterList) {
      updateChapterList(updatedChapterList);
    }
  };

  const handleDeleteSubject = (indexChapter: number, indexSubject: number) => {
    // console.log(indexChapter);
    // console.log(`delete:: chapter`, indexChapter, ` subject:: `, indexSubject);
    if (!chapterList || indexChapter < 0 || indexChapter >= chapterList.length)
      return;

    // Tạo một bản sao của danh sách môn của chương tại indexChapter và loại bỏ môn tại indexSubject
    var updatedSubjectList = [
      ...chapterList[indexChapter].subjectList.slice(0, indexSubject),
      ...chapterList[indexChapter].subjectList.slice(indexSubject + 1),
    ];

    // Cập nhật orderNumber cho các môn còn lại trong chương
    for (let i = indexSubject; i < updatedSubjectList.length; i++) {
      updatedSubjectList[i].orderNumber = i + 1;
    }

    // Tạo một bản sao của chapterList và cập nhật danh sách môn của chương tại indexChapter
    const updatedChapterList = [...chapterList];
    updatedChapterList[indexChapter].subjectList = updatedSubjectList;

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(updatedChapterList);
  };

  const handleDeleteLesson = (
    indexChapter: number,
    indexSubject: number,
    indexLesson: number
  ) => {
    if (
      !chapterList ||
      indexChapter < 0 ||
      indexChapter >= chapterList.length ||
      indexSubject < 0 ||
      indexSubject >= chapterList[indexChapter].subjectList.length
    )
      return;

    // Tạo một bản sao của danh sách bài học của môn tại indexSubject và loại bỏ bài học tại indexLesson
    var updatedLessonList = [
      ...chapterList[indexChapter].subjectList[indexSubject].lessonList.slice(
        0,
        indexLesson
      ),
      ...chapterList[indexChapter].subjectList[indexSubject].lessonList.slice(
        indexLesson + 1
      ),
    ];

    // Cập nhật orderNumber cho các bài học còn lại trong môn
    for (let i = indexLesson; i < updatedLessonList.length; i++) {
      updatedLessonList[i].orderNumber = i + 1;
    }

    // Tạo một bản sao của danh sách môn của chương và cập nhật danh sách bài học của môn tại indexSubject
    var updatedSubjectList = [...chapterList[indexChapter].subjectList];
    updatedSubjectList[indexSubject].lessonList = updatedLessonList;

    // Tạo một bản sao của chapterList và cập nhật danh sách môn của chương tại indexChapter
    const updatedChapterList = [...chapterList];
    updatedChapterList[indexChapter].subjectList = updatedSubjectList;

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(updatedChapterList);
  };

  const handleUpdateChapterName = (newName: string, indexChapter: number) => {
    if (!chapterList) return;
    var newChapterList = [...chapterList];
    newChapterList[indexChapter].structureItemName = newName;

    console.log(`newChapterList::`, newChapterList);
    if (newChapterList) {
      updateChapterList && updateChapterList(newChapterList);
    }
  };

  const handleUpdateChapterDescription = (
    description: string,
    indexChapter: number
  ) => {
    if (!chapterList) return;
    var newChapterList = [...chapterList];
    newChapterList[indexChapter].structureItemDescription = description;

    console.log(`newChapterList::`, newChapterList);
    if (newChapterList) {
      updateChapterList && updateChapterList(newChapterList);
    }
  };

  const handleUpdateSubjectName = (
    name: string,
    indexChapter?: number,
    indexSubject?: number
  ) => {
    if (
      indexChapter === undefined ||
      indexSubject === undefined ||
      !chapterList
    )
      return;

    var updatedChapterList = [...chapterList];

    updatedChapterList[indexChapter].subjectList[
      indexSubject
    ].structureItemName = name;

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(updatedChapterList);
  };
  const handleUpdateSubjectDescription = (
    description: string,
    indexChapter?: number,
    indexSubject?: number
  ) => {
    if (
      indexChapter === undefined ||
      indexSubject === undefined ||
      !chapterList
    )
      return;

    var updatedChapterList = [...chapterList];
    updatedChapterList[indexChapter].subjectList[
      indexSubject
    ].structureItemDescription = description;

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(updatedChapterList);
  };

  const handleUpdateLessonName = (
    name: string,
    indexChapter: number,
    indexSubject: number,
    indexLesson: number
  ) => {
    if (
      !chapterList ||
      indexChapter < 0 ||
      indexChapter >= chapterList.length ||
      indexSubject < 0 ||
      indexSubject >= chapterList[indexChapter].subjectList.length
    )
      return;

    var updatedChapterList = [...chapterList];
    updatedChapterList[indexChapter].subjectList[indexSubject].lessonList[
      indexLesson
    ].structureItemName = name;

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(updatedChapterList);
  };
  const handleUpdateLessonDescription = (
    description: string,
    indexChapter: number,
    indexSubject: number,
    indexLesson: number
  ) => {
    if (
      !chapterList ||
      indexChapter < 0 ||
      indexChapter >= chapterList.length ||
      indexSubject < 0 ||
      indexSubject >= chapterList[indexChapter].subjectList.length
    )
      return;

    var updatedChapterList = [...chapterList];
    updatedChapterList[indexChapter].subjectList[indexSubject].lessonList[
      indexLesson
    ].structureItemDescription = description;

    // Gọi hàm cập nhật chapterList mới
    updateChapterList && updateChapterList(updatedChapterList);
  };

  // const [expandedChapters, setExpandedChapters] = useState<{
  //   [key: number]: boolean;
  // }>({});

  const [expandedChapters, setExpandedChapters] = useState<{
    [key: number]: boolean;
  }>(() => {
    var initialState: { [key: number]: boolean } = {};
    if (isShowDetailAll) {
      chapterList?.forEach((_, index) => {
        initialState[index] = true;
      });
    }
    return initialState;
  });

  useEffect(() => {
    if (isShowDetailAll && chapterList) {
      const newState: { [key: number]: boolean } = {};
      chapterList.forEach((_, index) => {
        newState[index] = true;
      });
      setExpandedChapters(newState);
    } else {
      setExpandedChapters({});
    }
  }, [isShowDetailAll, chapterList]);

  const toggleChapterExpansion = (indexChapter: number) => {
    setExpandedChapters((prevState) => ({
      ...prevState,
      [indexChapter]: !prevState[indexChapter],
    }));
  };
  const [isOpenModalCopy, setIsOpenModalCopy] = useState(false);

  return (
    <div>
      {listDataChapter &&
        listDataChapter.map((chapter, indexChapter) => {
          return (
            <div key={indexChapter} className="CMH">
              <Card
                style={{ marginBottom: "20px", minWidth:"680px" }}
                title={
                  <>
                    <Row
                      justify="start"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <Col
                        style={{
                          textAlign: "center",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ flex: "1 0 auto" }} />
                        <HolderOutlined
                          style={{ fontSize: "50px", color: "#91d5ff" }}
                        />
                      </Col>
                      <Col>
                        {/* #2881ff */}
                        <InputInfo
                          structureItemName={chapter.structureItemName || ""}
                          structureItemDescription={
                            chapter.structureItemDescription || ""
                          }
                          isChapter={true}
                          // onAddNewCourseStructure={handleAddNewChapter(indexChapter)}
                          onChangeName={(name) =>
                            handleUpdateChapterName(name, indexChapter)
                          }
                          onChangeDescription={(content) =>
                            handleUpdateChapterDescription(
                              content,
                              indexChapter
                            )
                          }
                          onDeleteCourseStructure={() =>
                            handleDeleteChapter(indexChapter)
                          }
                        // colorTextDescription={`#2881ff`}
                        />
                      </Col>
                    </Row>
                  </>
                }
                extra={
                  <div>
                    <Row>
                      <Space>
                        <Col>
                          <Button
                            onClick={() => toggleChapterExpansion(indexChapter)}
                          >
                            {expandedChapters[indexChapter]
                              ? "Thu gọn"
                              : "Hiển thị chi tiết"}
                          </Button>
                        </Col>
                        <Col>
                          <Dropdown
                            onOpenChange={() =>
                              handleOptionDropDown(indexChapter)
                            }
                            menu={{
                              items: optionDropDown,
                              onClick: ({ key }) => {
                                if (key === "1") {
                                  Modal.confirm({
                                    title: `Tạo bản sao của chương ${indexChapter + 1
                                      }`,
                                    okText: "Đồng ý",
                                    cancelText: "Huỷ",
                                    centered: true,
                                    onOk: () => handleCopyChapter(indexChapter),
                                  });

                                  // Open the modal when the dropdown item is clicked
                                  // setIsOpenModalCopy(true);
                                }

                                if (key === "2") {
                                  moveItemUp(indexChapter);
                                }
                                if (key === "3") {
                                  moveItemDown(indexChapter);
                                }
                                if (key === "4") {
                                  Modal.confirm({
                                    title: `Xóa chương ${indexChapter + 1}`,
                                    content: (
                                      <>
                                        Chương này (chương thứ{" "}
                                        {indexChapter + 1}) sẽ được xóa khỏi cấu
                                        trúc khóa học. <br /> Bạn có chắc chắn
                                        muốn xóa?
                                      </>
                                    ),
                                    okText: "Xác nhận",
                                    cancelText: "Huỷ bỏ",
                                    centered: true,
                                    onOk: () => {
                                      handleDeleteChapter(indexChapter);
                                    },
                                  });
                                }
                              },
                            }}
                            placement="bottomRight"
                          >
                            <Button>
                              <MoreOutlined />
                            </Button>
                          </Dropdown>

                          {/* Modal for copying chapter */}
                          {/* <Modal
                            title={`Tạo bản sao của chương ${indexChapter + 1}`}
                            okText="Đồng ý"
                            cancelText="Huỷ"
                            centered={true}
                            open={isOpenModalCopy}
                            onCancel={() => setIsOpenModalCopy(false)}
                            onOk={() => {
                              handleCopyChapter(indexChapter);
                              setIsOpenModalCopy(false);
                            }}
                          /> */}
                        </Col>
                      </Space>
                    </Row>
                  </div>
                }
                styles={{
                  body: {
                    display: expandedChapters[indexChapter] ? "block" : "none",
                  },
                }}
              >
                {chapter.subjectList.map((subject: any, indexSubject: any) => {
                  const childrenNodes = subject.lessonList.map(
                    (lesson: any, indexLesson: any) => ({
                      title: (
                        <>
                          <InputInfo
                            structureItemName={lesson.structureItemName || ""}
                            structureItemDescription={
                              lesson.structureItemDescription || ""
                            }
                            isLesson={true}
                            onAddNewCourseStructure={() =>
                              handleAddNewLesson(
                                indexChapter,
                                indexSubject,
                                indexLesson
                              )
                            }
                            onChangeName={(name) =>
                              handleUpdateLessonName(
                                name,
                                indexChapter,
                                indexSubject,
                                indexLesson
                              )
                            }
                            onChangeDescription={(content) =>
                              handleUpdateLessonDescription(
                                content,
                                indexChapter,
                                indexSubject,
                                indexLesson
                              )
                            }
                            onDeleteCourseStructure={() =>
                              handleDeleteLesson(
                                indexChapter,
                                indexSubject,
                                indexLesson
                              )
                            }
                          />
                          <Divider
                            style={{ marginTop: "5px", marginBottom: 0 }}
                          />
                        </>
                      ),
                      key: `lesson-${indexLesson}`,
                    })
                  );

                  const treeData: TreeDataNode[] = [
                    {
                      title: (
                        <>
                          <InputInfo
                            structureItemName={subject.structureItemName || ""}
                            structureItemDescription={
                              subject.structureItemDescription || ""
                            }
                            isSubject={true}
                            onAddNewCourseStructure={() =>
                              handleAddNewSubject(indexChapter, indexSubject)
                            }
                            onChangeName={(name) =>
                              handleUpdateSubjectName(
                                name,
                                indexChapter,
                                indexSubject
                              )
                            }
                            onChangeDescription={(content) =>
                              handleUpdateSubjectDescription(
                                content,
                                indexChapter,
                                indexSubject
                              )
                            }
                            onDeleteCourseStructure={() =>
                              handleDeleteSubject(indexChapter, indexSubject)
                            }
                          />
                          <Divider
                            style={{ marginTop: "5px", marginBottom: 0 }}
                          />
                        </>
                      ),
                      key: "0-0",
                      children: childrenNodes,
                    },
                  ];

                  return (
                    <div key={indexSubject} style={{ width: "100%" }}>
                      <Tree
                        blockNode
                        showIcon
                        defaultExpandAll
                        defaultSelectedKeys={["0-0-0"]}
                        switcherIcon={<ArrowDownOutlined />}
                        treeData={treeData}
                        showLine={true}
                        selectable={false}
                      />
                    </div>
                  );
                })}
              </Card>
            </div>
          );
        })}
    </div>
  );
};

export default DetailChapter;

/*
  //gửi lên trạng thái

  const toggleChapterExpansion = (indexChapter: number) => {
    setExpandedChapters((prevState) => {
      const newState = {
        ...prevState,
        [indexChapter]: !prevState[indexChapter],
      };
  
      // Kiểm tra xem tất cả expandedChapters có cùng giá trị true hoặc false không
      const allExpanded = Object.values(newState).every((value) => value);
      const allCollapsed = Object.values(newState).every((value) => !value);
  
      // Nếu tất cả đều mở hoặc đều đóng, cập nhật isShowDetailAll tương ứng
      if (allExpanded) {
        setIsShowDetailAll && setIsShowDetailAll(true);
      } else if (allCollapsed) {
        setIsShowDetailAll && setIsShowDetailAll(false);
      }
  
      return newState;
    });
  };

  
*/
