import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Input, Layout, message } from "antd";
import { SearchProps } from "antd/es/input";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageHeaderCommon from "../../../components/page-header-common/PageHeaderCommon";
import SettingPredictionTable from "./SettingPredictionTable";
import FormCreateStatistical from "./form-create-statistical";
import "./setting-prediction.scss";
const { Content } = Layout;
const { Search } = Input;

const imgPath = "/images/";

const FormHead = ({ onSearch, setIsOpenForm }: any) => {
  const [searchText, setSearchText] = useState<string>("");

  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };

  return (
    <div className="heading-search">
      <Flex>
        <Button
          className="mr-2"
          type="primary"
          onClick={() => setIsOpenForm(true)}
        >
          <PlusOutlined />
          Tạo mới
        </Button>
        <Search
          placeholder="Nhập tên hoặc mã mô hình"
          onSearch={onSearch}
          value={searchText}
          onChange={handleChangeSearch}
        />
      </Flex>
    </div>
  );
};

const SettingPrediction = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const [messageApi, contextHolder] = message.useMessage();

  const listBreadcrumb = [
    {
      title: "Hệ thống gợi ý khoá học",
    },
    {
      title: "Cài đặt Dự đoán, phân tích xu hướng",
    },
  ];

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };
  const handleNotice = (type: any, message: string) => {
    messageApi.open({
      type: type,
      content: message,
    });
  };
  const handleClose = () => {
    setIsOpenForm(false);
  };

  const handleRefresh = () => {
    setIsRefresh(!isRefresh);
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Cài đặt Dự đoán, phân tích xu hướng"
        // arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        // handleBack={() => navigate("/")}
        headerGroupButton
        childrenGroupButton={
          <FormHead onSearch={onSearch} setIsOpenForm={setIsOpenForm} />
        }
      />
      <SettingPredictionTable
        searchValue={searchValue}
        handleNotice={handleNotice}
        isRefresh={isRefresh}
        handleRefresh={handleRefresh}
      />
        <FormCreateStatistical
          isOpenForm={isOpenForm}
          handleClose={handleClose}
          handleNotice={handleNotice}
          isEdit={false}
          handleRefresh={handleRefresh}
        />
    </Content>
  );
};

export default SettingPrediction;
