import React from "react";
import { Checkbox, Form } from "antd";

function ChooseMultiImage({ data }: any) {
  const placeholders =
    2 - (data?.questions?.length % 2 === 0 ? 2 : data.questions.length % 2);
  return (
    <div>
      <div className="flex layout-two-col">
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: number) => (
            <div
              className={`image-quiz-wrap ${
                quiz?.isTrue && "active-quiz-correct choose-correct"
              }`}
              key={quiz?.uuid + index}
            >
              <div>
                <Checkbox
                  id={`${index}`}
                  disabled={true}
                  checked={quiz?.isTrue}
                >
                  <span
                    className={`font-size-16 line-height-24 font-weight-5 ${
                      quiz?.isTrue && "text-result"
                    }`}
                  >
                    {quiz?.content || "Không có nội dung"}
                  </span>
                </Checkbox>
              </div>
              <label htmlFor={`${index}`}>
                <img
                  className="mt-1"
                  src={quiz?.filePath || ""}
                  alt="quiz-card"
                />
              </label>
            </div>
          ))}
        {Array.from({ length: placeholders }).map((_, index) => (
          <div
            key={`placeholder-${index}`}
            className={`image-quiz-wrap choose-correct placeholder`}
          />
        ))}
      </div>
    </div>
  );
}

export default ChooseMultiImage;
