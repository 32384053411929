import create from 'zustand';

interface State {
  flag: boolean;
  toggleFlag: () => void;
}

export const approvedInformationTabStore = create<State>((set) => ({
  flag: true,
  toggleFlag: () => set((state) => ({ flag: !state.flag })),
}));