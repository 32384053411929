import React, { FunctionComponent } from "react";
import { TimePicker } from "antd";
import dayjs from "dayjs";

type TimePickerProps = {
  value?: Date | null;
  format?: string;
  onChange?: any;
  name?: string;
  disabled?: boolean;
};

const TimePickerCustom: FunctionComponent<TimePickerProps> = ({
  value,
  onChange,
  format,
  disabled,
  name,
}) => {
  return (
    <>
      <TimePicker
        name={name}
        format={format}
        onChange={onChange}
        value={dayjs(value, "HH:mm:ss")}
        needConfirm={false}
        changeOnScroll
        disabled={disabled}
      />
    </>
  );
};

export default TimePickerCustom;
