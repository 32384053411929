import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";

export const getLearningTrendAll: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/learning-trends-fields/get-all`);
};

export const getLearningTrendByGroup: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/learning-trends-fields/get-by-group/${id}`);
};

export const getListPublicLearningTrend: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/learning-trends/search-public`, dataReq);
};

export const getLearningTrendReportById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/learning-trends-report/get/${id}`);
};

export const getLearningTrendAllBod: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/learning-trends-report/get-all-dob/${id}`);
};

export const getLearningTrendReportByIdAllGender: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/learning-trends-report/get-all-gender/${id}`);
};

export const getLearningTrendReportAllUniversity: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/learning-trends-report/get-all-university/${id}`);
};

export const getLearningTrendReportAllAddress: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/learning-trends-report/get-all-address/${id}`);
};

export const getLearningTrendAllFavorite: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/learning-trends-report/get-favorite/${id}`);
};

export const getLearningTrendAllIndustry: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/learning-trends-report/get-industry/${id}`);
};

export const getLearningTrends: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/learning-trends/search`, dataReq);
};

export const getLearningTrendsReport: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/learning-trends-report/search`, dataReq);
};

export const getAllStudentInfo: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(
    `/learning-trends-report/search-all-student-info`,
    dataReq
  );
};

export const getAllCourseInfo: (dataReq: any) => Promise<AxiosResponse<any>> = (
  dataReq
) => {
  return axiosConfig.post(
    `/learning-trends-report/search-course-info`,
    dataReq
  );
};

export const getCourseBehaviorBefore: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(
    `/learning-trends-report/search-course-behavior-before`,
    dataReq
  );
};

export const getCourseBehaviorAfter: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(
    `/learning-trends-report/search-course-behavior-after`,
    dataReq
  );
};

export const createLearningTrendsReport: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/learning-trends-report/create`, dataReq);
};

export const createLearningTrends: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/learning-trends/create`, dataReq);
};

export const updateLearningTrends: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/learning-trends/update/${id}`, dataReq);
};

export const updateLearningTrendsDescription: (
  dataReq?: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.put(`/learning-trends/update-many`, dataReq);
};

export const updateLearningTrendsReport: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(`/learning-trends-report/update/${id}`, dataReq);
};

export const updateIsTraining: (
  id: string,
  dataReq?: any
) => Promise<AxiosResponse<any>> = (id, dataReq) => {
  return axiosConfig.put(
    `/learning-trends-report/update-training/${id}`,
    dataReq
  );
};

export const publishLearningTrends: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.put(`/learning-trends/publish/${id}`);
};

export const deleteLearningTrend: (
  dataReq: any
) => Promise<AxiosResponse<any>> = (dataReq) => {
  return axiosConfig.post(`/learning-trends/delete-many`, dataReq);
};

export const deleteLearningTrendReport: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/learning-trends-report/delete/${id}`);
};
