// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-header {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0 15px !important; }

.filter-button.open {
  border-color: #1677ff; }
  .filter-button.open .filter-text {
    color: #1677ff; }
  .filter-button.open svg {
    color: #1677ff; }
`, "",{"version":3,"sources":["webpack://./src/components/category-management/management-detail/components/filter-table/FilterTable.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,8BAA8B;EAC9B,oCAAoC,EAAA;;AAGtC;EACE,qBAAqB,EAAA;EADvB;IAII,cAAc,EAAA;EAJlB;IAQI,cAAc,EAAA","sourcesContent":[".history-header {\n    display: flex;\n    justify-content: space-between;\n    padding: 15px 15px 0 15px !important;\n  }\n  \n  .filter-button.open {\n    border-color: #1677ff;\n    \n    .filter-text {\n      color: #1677ff;\n    }\n  \n    svg {\n      color: #1677ff;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
