// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.survey-managment-synthesis .form-detail-chart {
  display: flex;
  flex-direction: column;
  height: fit-content;
  /* Hiển thị các thành phần theo chiều dọc */
  flex-grow: 1; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-synthesis/survey-detail/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EAEQ,aAAoB;EACpB,sBAAsB;EACtB,mBAA2B;EAC3B,2CAAA;EACA,YAAiB,EAAA","sourcesContent":[".survey-managment-synthesis {\n    .form-detail-chart {\n        display       : flex;\n        flex-direction: column;\n        height        : fit-content;\n        /* Hiển thị các thành phần theo chiều dọc */\n        flex-grow     : 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
