import { Flex } from "antd";
import { useState } from "react";
import CourseTag from "./StatiscalItems/CourseTag";
import InteractStudent from "./StatiscalItems/InteractStudent";

interface IProps {
  infoTag: any;
  behaviourTag: any;
  setDateInfo: (item: any) => void;
  setDateBehavior: (item: any) => void;
}
const StatisticalCourseTag = ({
  infoTag,
  behaviourTag,
  setDateBehavior,
  setDateInfo,
}: IProps) => {
  return (
    <div className="px-3 pt-6 mx-3">
      <Flex className="flex-wrap lg:!flex-nowrap" justify="space-between">
        <CourseTag infoTag={infoTag} setDateInfo={setDateInfo}/>
        <InteractStudent behaviourTag={behaviourTag} setDateBehavior={setDateBehavior}/>
      </Flex>
    </div>
  );
};

export default StatisticalCourseTag;
