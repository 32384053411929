import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, Row, Space, TableColumnsType, TableProps, TimePicker, Tooltip, Typography, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import { CheckCircleOutlined, CloseOutlined, DownloadOutlined, PlusOutlined, SaveOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
import { useParams } from 'react-router-dom'
import { assignCourseLecturer, getListLecturer } from '../../../service/content-list-course/contentListCourseApi'

type Props = {
  open: boolean
  sequenceId: string
  handleClose: () => void
}

export default function AssignContentCreationLesson(props: Props) {
  const { open, handleClose, sequenceId } = props

  const [listOptionLeecturer, setListOptionLeecturer] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const CheckboxGroup = Checkbox.Group;
  const [listTeacher, setListTeacher] = useState<any>([
    {
      name: '',
      display: true
    },
  ])
  const [form] = Form.useForm()
  const params = useParams()

  const addTeacher = () => {
    const newArr = [...listTeacher]
    newArr.push({
      name: '',
      display: true
    })
    setListTeacher(newArr)
  }

  const removeRowData = (item: any, index: number) => {
    form.resetFields([`listLecturer${index}`])
    const newArr = [...listTeacher]
    newArr[Number(index)].display = false
    setListTeacher(newArr)
  }

  const setListData = (e: any) => {
    const arr = listOptionLeecturer.filter((item: any) => item.id !== e)
    setListOptionLeecturer(arr)
  }

  const listTeacherRender = listTeacher.map((item: any, index: number) => (
    <div key={index + 1}>
      {item.display ? <Row gutter={20} key={index + 2}>
        <Col md={22}>
          <Form.Item label="Giảng viên" name={`Lecturer${index}`} rules={[{ required: true, message: 'Giảng viên không được bỏ trống' }]}>
            <FormItemDropdown options={listOptionLeecturer} onChange={(e: any) => setListData(e)}
            />
          </Form.Item>
        </Col>
        {
          listTeacher.filter((item: any) => item.display).length > 1 ?
            <Col md={2}>
              <CloseOutlined className='mt-9' onClick={() => removeRowData(item, index)} />
            </Col> : null
        }
      </Row> : null}
    </div>
  ))

  const getListLecturerByCourse = async () => {
    await getListLecturer(params?.id).then((res) => {
      setListOptionLeecturer(res.data)
    })
  }

  useEffect(() => {
    if (open) {
      getListLecturerByCourse()
    }
  }, [open])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === 'assign-content-creation-lesson') {

        const handleBulkCheckCourse = async () => {
          const data = Object.keys(values).map((key) => values[key]);
          const param = {
            sequenceId: sequenceId ?? 0,
            teacherIds: data,
          }
          await assignCourseLecturer(param).then((res) => {
            if (res.status === 200) {
              message.success("Phân công bài giảng thành công")
              handleClose()
              form.resetFields()
            }
          })
        }

        Modal.confirm({
          title: `Xác nhận phân công giảng viên xây dựng nội dung khóa học`,
          content: `Xin vui lòng xác nhận phân công giảng viên xây dựng nội dung khóa học.`,
          onOk: () => {
            handleBulkCheckCourse();
          },
          okText: "Xác nhận",
          cancelText: "Đóng",
          centered: true,
          icon: <CheckCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });

      }
    }}>
      <Modal
        className="assign-content-creation-lesson"
        title={"Phân công giảng viên xây dựng nội dung khoá học"}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"500px"}
      >
        <Form
          form={form}
          layout="vertical"
          name="assign-content-creation-lesson"
        >
          <Row gutter={0} className='p-6'>

            <Col md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }} xs={{ span: 24 }}>
              {listTeacherRender}
              <Tooltip placement="top" title="Thêm câu hỏi" className='mt-2'>
                <Button icon={<PlusOutlined />} onClick={addTeacher}>Thêm giảng viên</Button>
              </Tooltip>

              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Lưu
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider >
  )
}
