import { Col, Form, Input, Modal, Row, TreeSelect } from "antd";
import { DataType } from "../../../../../types/survey-managment/listSurvey";
import {
  DetailTypeMoocCourseConstructionPlan,
  DataTypeMoocCourseConstructionPlan,
  FilterDataMoocCourseConstructionPlan,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import { useEffect, useState } from "react";
import { useAuthStore } from "../../../../../stores/stores";
import { HelpService } from "../../../../../service/helper.service";
import { get } from "lodash";
import { getApprover } from "../../../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";
import { sendApproval } from "../../../../../service/course-plan-managment/planning-course-construction/PlanningCourseConstructionApi";

type Props = {
  open: boolean;
  handleCancel: () => void;
  browseData?: DataTypeMoocCourseConstructionPlan;
  handleFinish: () => void;
};

export default function ApproveCouresConstructionPlan(props: Props) {
  const { open, handleCancel, browseData, handleFinish } = props;
  const userId = useAuthStore((states) => states.user?.id);
  const [listApprover, setListApprover] = useState<[]>([]);
  const [approvalLevelName, setApprovalLevelName] = useState<string | null>(
    null
  );
  const [isOrganization, setIsOrganization] = useState<boolean | null>(null);
  const [configurationId, setConfigurationId] = useState<number | null>(null);
  const [organizationGroupId, setOrganizationGroupId] = useState(null);
  const [form] = Form.useForm();
  const helpService = new HelpService();
  const handleStatusLevel = (approvalLevelName: any) => {
    switch (approvalLevelName) {
      case "Quản trị khóa học":
        return 2;
      case "Tổ chuyên môn":
        return 5;
      case "Hội đồng thẩm định test":
        return 8;
      default:
        return 2;
    }
  };
  const onOk = () => {
    form
      .validateFields()
      .then((values) => {
        const selectedValue = form.getFieldValue("individual_id");
        const data = {
          courseId: browseData?.courseConstructionPlanId,
          submitterId: userId,
          configurationId: configurationId,
          constructionStatus: 1,
          organizationId: selectedValue,
          individualId: null,
        };
        // if (isOrganization) {
        //   data.organizationId = values.individual_id;
        // } else {
        //   data.individualId = values.individual_id;
        // }
        sendApproval(data)
          .then((res) => {
            const { data, statusCode, message } = res.data;
            if (statusCode === 200) {
              helpService.successMessage(
                "Yêu cầu phê duyệt kế hoạch khóa học được gửi thành công"
              );
            }
            form.resetFields();
            handleFinish();
            handleCancel();
          })
          .catch((error) => {
            form.resetFields();
            handleCancel();
          });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    } else {
      getListApprover();
    }
  }, [open]);

  const getListApprover = async () => {
    try {
      const data = {
        courseId: browseData?.courseConstructionPlanId,
        approvalType: 3,
      };
      const response = await getApprover(data).then((res) => {
        const {
          result,
          configurationId,
          approvalLevelName,
          isOrganization,
          organizationalGroupId,
        } = res.data.data;
        setListApprover(result);
        setApprovalLevelName(approvalLevelName);
        setIsOrganization(isOrganization);
        setOrganizationGroupId(organizationalGroupId);
        setConfigurationId(configurationId);
      });
    } catch (error) {}
  };
  const validations = {
    organization_member_id: {
      required: true,
      message: `Vui lòng chọn tên tổ chuyên môn`,
    },
  };

  return (
    <>
      <Modal
        title={`Gửi phê duyệt kế hoạch ${browseData?.courseConstructionPlanName}`}
        open={open}
        onOk={onOk}
        onCancel={handleCancel}
        centered
        okText="Gửi yêu cầu"
        cancelText="Huỷ"
      >
        <Form
          layout="vertical"
          form={form}
          name="approveModal"
          initialValues={{
            individual_id: null,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="individual_id"
                label={`Kế hoạch của bạn sẽ được gửi tới ${
                  approvalLevelName ?? ""
                }:`}
                rules={[validations.organization_member_id]}
                hasFeedback
              >
                <TreeSelect
                  showSearch
                  placeholder={`Nhập tên tổ chuyên môn`}
                  allowClear
                  treeData={listApprover}
                  notFoundContent={
                    <span style={{ textAlign: "center" }}>
                      <p>
                        <img
                          className="image-empty-data"
                          src="/images/empty-img-gray.png"
                          alt="empty-img"
                        ></img>
                      </p>
                      <p className="nodata-text">Không tìm thấy</p>
                    </span>
                  }
                  treeNodeFilterProp="title"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
