import { Button, Input, Row, Space, ConfigProvider, Checkbox } from "antd";
import { CloseOutlined, FileImageOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ListAnswer } from "../../../../../../types/survey-managment/survey";

const QuestionType6 = (props: any) => {
    const [options, setOptions] = useState<ListAnswer[]>(props.listOptions || []);

    const [imageShowHtmls, setImageShowHtmls] = useState<string[]>([]);

    const onHandleDeleteOption = (index: number) => {
        const newOptions = options.filter((_, i) => i !== index);

        setOptions(newOptions);
        props.setUpdateListAnswer(newOptions);
    };

    function selectLocalImage(index: number) {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.click();

        // Listen upload local image and save to state
        input.onchange = () => {
            const file = input.files ? input.files[0] : null;
            if (file) {
                // file type is only image.
                if (/^image\//.test(file.type)) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = () => {
                        const result = reader.result;
                        if (typeof result === "string") {
                            const base64String = result;
                            const newImageShowHtmls = [...imageShowHtmls];
                            newImageShowHtmls[index] = base64String;
                            const newOptions = options.map((option, idx) => {
                                return {
                                    ...option,
                                    answerImage: newImageShowHtmls[idx] || "", // Update answerImage based on imageShowHtmls
                                };
                            });
                            setOptions(newOptions);
                            props.setUpdateListAnswer(newOptions);

                            setImageShowHtmls(newImageShowHtmls);
                        } else {
                            console.warn("Invalid result type.");
                        }
                    };

                    reader.onerror = (error) => {
                        console.error("Error reading file:", error);
                    };
                } else {
                    console.warn("You could only upload images.");
                }
            } else {
                console.warn("No file selected.");
            }
        };
    }

    const handleChangeAnswerText = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newOptions = [...options];
        const newImageShowHtmls = [...imageShowHtmls];
        newOptions[index] = {
            ...newOptions[index],
            answerText: event.target.value,
            answerImage: newImageShowHtmls[index], // Lưu image vào answerImage
        };
        console.log(newOptions);
        setOptions(newOptions);
        props.setUpdateListAnswer(newOptions);
    };

    return (
        <div>
            <div>
                <br />
                <ConfigProvider
                    theme={{
                        components: {
                            Input: {
                                colorTextPlaceholder: "black",
                                paddingInline: 0,
                                paddingBlock: 0,
                            },
                            Button: {
                                paddingInline: 2,
                                paddingBlock: 0,
                            },
                        },
                    }}
                >
                    <Space direction="vertical" size="small" style={{ display: "flex" }}>
                        {options.map((option, index) => {
                            return (
                                <Row
                                    style={{
                                        margin: "0 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    key={index}
                                >
                                    <Input
                                        prefix={<Checkbox checked={false}></Checkbox>}
                                        suffix={
                                            <>
                                                {imageShowHtmls[index] && (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: `<p><img src="${imageShowHtmls[index]}" style="width: 20px; height: 20px;"></p>`,
                                                        }}
                                                    />
                                                )}

                                                <Button
                                                    style={{ width: 20, height: 25 }}
                                                    icon={<FileImageOutlined />}
                                                    onClick={() => {
                                                        selectLocalImage(index);
                                                    }}
                                                />

                                                {/* <Button
                                                    style={{ width: 20, height: 25 }}
                                                    icon={<CloseOutlined />}
                                                    // onClick={() => {
                                                    //     onHandleDeleteOption(index);
                                                    // }}
                                                    disabled
                                                /> */}
                                            </>
                                        }
                                        variant="borderless"
                                        value={
                                            option.answerText || option.answerText === ""
                                                ? option.answerText
                                                : `Lựa chọn văn bản ${options.length}`
                                        }
                                        // onChange={(event) => handleChangeAnswerText(event, index)}
                                    />
                                </Row>
                            );
                        })}
                    </Space>
                </ConfigProvider>
                <br />
            </div>
        </div>
    );
};

export default QuestionType6;
