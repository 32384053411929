import { Form, Radio, RadioChangeEvent, Typography } from 'antd'
import React, { useEffect, useState } from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import './index.scss'
import { getPaymentMethods } from "../../../../../service/content-course/contenCourseApi";
import { useForm } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { get } from "lodash";
import { useWatch } from "antd/lib/form/Form";


type SettingFeeProps = {
  formInstance?: FormInstance,
  dataConfig?: any,
}

const validateRule = [
  {
    required: true,
    message: "Vui long chon hinh thuc thanh toan"
  }
]


const SettingFee = ({ formInstance, dataConfig }: SettingFeeProps) => {
  const [form] = useForm()
  const fee = useWatch('fee', form)
  const [isCostFree, setIsCostFee] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [dataOptionsMethod, setDataOptionsMethod] = useState({
    paymentsMethod: []
  })
  const [dataFee, setDataFee] = useState({})

  const [options, setOptions] = useState<any[]>([])

  const fetchOptionsPayment = async () => {
    try {
      const response = await getPaymentMethods()
      // @ts-ignore
      const { data } = response
      setDataOptionsMethod({
        ...dataOptionsMethod,
        paymentsMethod: data.data
      })
    } catch (err) { }
  }

  const updateForm = (status: boolean, data: any) => {
    if (formInstance && !status) {
      formInstance?.setFieldsValue({
        'costs': {
          cost: data.cost,
          paymentMethodId: data.paymentMethod
        }
      })
    }
    if (formInstance && status) {
      formInstance?.setFieldsValue({
        'costs': {}
      })
    }
  }

  const addOnValues = (values: any) => {
    setDataFee({
      ...dataFee,
      cost: values.fee,
      paymentMethod: values.paymentMethod?.map((i: number, index: number) => i)
    })
  }


  const handleChangeOptions = (e: RadioChangeEvent) => {
    setIsCostFee(e.target.value)
  }

  const handleClose = () => {
    setIsOpenModal(false)
  }

  const onFinish = () => {
    const values = form.getFieldsValue(['fee', 'paymentMethod'])
    addOnValues(values)
    handleClose()
  }

  useEffect(() => {
    fetchOptionsPayment()
  }, []);

  useEffect(() => {
    if (dataOptionsMethod.paymentsMethod.length > 0) {
      const newData = dataOptionsMethod.paymentsMethod.map((item: any) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      setOptions(newData)
    }
  }, [dataOptionsMethod.paymentsMethod]);

  useEffect(() => {
    if (formInstance) {
      updateForm(isCostFree, dataFee)
    }
  }, [dataFee, formInstance, isCostFree]);


  useEffect(() => {
    if (dataConfig) {
      setIsCostFee(dataConfig.isCostFree)
      formInstance?.setFieldsValue({
        costs: {
          cost: fee,
          paymentMethodId: get(dataConfig, 'paymentMethod', [])
        }
      })
      form.setFieldsValue({
        'fee': get(dataConfig, 'paymentMethod.cost'),
        'paymentMethod': get(dataConfig, 'paymentMethod', [])
      })
    }
  }, [dataConfig]);


  return (
    <>
      <CustomCard title="Chi phí" className="wrapper-settings--card ">
        <Form.Item name="costs">
          <Form.Item className="form-item-setting" name="isCostFree">
            <Radio.Group onChange={handleChangeOptions}>
              <div className="wrapper-radio">
                <Radio value={true}>
                  Miễn phí
                </Radio>
                <div className="message">
                  <Typography.Text className="sub-title">
                    Tất cả mọi người đều có thể tham gia khóa học miễn phí

                  </Typography.Text>
                </div>
              </div>
              <div className="wrapper-radio">
                <Radio value={false}>
                  Trả phí
                </Radio>
                <div className="message">
                  <Typography.Text className="sub-title">
                    Người dùng cần phải trả phí để được tham gia khóa học hoặc được thêm vào bởi QTKH hoặc Giảng viên.
                  </Typography.Text>
                </div>
              </div>
            </Radio.Group>
          </Form.Item>
        </Form.Item>
      </CustomCard>
      {/* <Modal forceRender title="Thiết lập chi phí" open={isOpenModal} onCancel={handleClose} onOk={onFinish}
             okText="Lưu" cancelText="Hủy"
             className="modal-setting--fee">
        <Form name="settingFee" form={form} id="settingFee">
          <Form.Item name="fee" label="Chi phí" className="form-item--fee">
            <InputNumber addonAfter="VND" className="form-input"
                         min={"0"}
                         controls={false}
                         formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                         parser={(value?: string) => value ? value.replace(/\$\s?|(,*)/g, '') : ""}/>
          </Form.Item>
          <Form.Item name="paymentMethod" label="Hình thức thanh toán" className="form-item--payment">
            <Checkbox.Group options={options}/>
          </Form.Item>
        </Form>
      </Modal>*/}
    </>
  );
};

export default SettingFee;
