// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auto-mark .ant-card-body {
  padding: 0; }
  .auto-mark .ant-card-body .table-wrapper {
    padding: 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/automatic-mark-detail/auto-mark-action/auto-mark.scss"],"names":[],"mappings":"AAAA;EAEQ,UAAU,EAAA;EAFlB;IAIY,UAAU,EAAA","sourcesContent":[".auto-mark{\n    .ant-card-body{\n        padding: 0;\n        .table-wrapper {\n            padding: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
