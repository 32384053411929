import React, { useRef, useState } from "react";
import { Button, Flex, Popover, Tooltip } from "antd";
import {
  ExportIcon,
  MicroIcon,
  QuestionMarkIcon,
} from "../../../../../../../components/icons";
import { QuestionProps } from "../../../../../../../types/course";
import { isEmpty, toNumber } from "lodash";
import SingleMenu from "../../../../../../../components/menu/SingleMenu";


function Mp3({ data, configs }: QuestionProps) {
  const isTrue = data?.result?.isTrue === true;
  const result = data?.result?.results[0];
  const [audioURL, setAudioURL] = useState<string>("");
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [mode, setMode] = useState<"begin" | "recording" | "view">("begin");

  const startRecording = async () => {
    setMode("recording");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);
        audioChunksRef.current = [];
      };

      mediaRecorder.start();
    } catch (err) {
      console.error("Error accessing the microphone", err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setMode("view");
  };

  const handleDeleteRecording = () => {
    setAudioURL("");
    setMode("begin");
  };

  const renderContent = () => {
    switch (mode) {
      case "begin":
        return (
          <div className="begin-screen">
            <Button className="record-item mt-2" onClick={startRecording}>
              <div className="center">
                <MicroIcon />
                <span className="font-size-14 font-weight-6 ml-1">
                  Bắt đầu ghi âm câu trả lời của bạn
                </span>
              </div>
            </Button>
          </div>
        );
      case "recording":
        return (
          <div className="micro-recording-screen center flex-column mt-3">
            <div className="record-micro micro-recording-animation">
              <MicroIcon />
            </div>

            <Tooltip title="Kết thúc">
              <Button
                className="mt-2 btn-primary btn-action"
                onClick={stopRecording}
              >
                <ExportIcon />
              </Button>
            </Tooltip>
          </div>
        );
      case "view":
        return (
          <div className="view-screen flex gap-32 align-center">
            <audio src={audioURL} controls />
            <div className="flex gap-16">
              <span className="font-size-14 text-primary font-weight-6 pointer">
                Ghi âm lại
              </span>
              <span
                className="text-danger font-size-14 font-weight-6 pointer"
                onClick={handleDeleteRecording}
              >
                Xoá
              </span>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const renderFeedback = () => {
    if (isEmpty(data?.result) && !isTrue)
      return data?.settingResponse?.notYet || "Không có phải hồi";

    if (isTrue) return data?.settingResponse?.correct || "Không có phải hồi";

    return data?.settingResponse?.incorrect || "Không có phải hồi";
  };

  return (
    <div>
      <div className="question-header mb-3">
        <div className="question-title">
          <Flex
            justify="space-between"
            className="font-size-16 line-height-24 font-weight-6"
          >
            Câu hỏi {toNumber(data?.index) + 1}:{" "}
            {configs?.generalConfig?.showQuizScore && (
              <span className="total-point font-size-14">
                {`(${data?.totalPoints || 0} điểm)`}
              </span>
            )}
          </Flex>
          <Flex align="flex-start" gap={8}>
            <div
              className="font-size-16 line-height-24 font-weight-6"
              dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
            ></div>
            {data?.settingHint?.isDisplayInstruction || data?.isShowHint ? (
              <Popover
                content={
                  <div style={{ padding: "5px 16px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${data?.settingHint?.content || "Không có gợi ý"}`,
                      }}
                    />
                  </div>
                }
              >
                <span className="quest-icon text-secondary w-max-content">
                  <QuestionMarkIcon />
                </span>
              </Popover>
            ) : (
              ""
            )}
          </Flex>
          <div className="flex align-center gap-8 mt-1">
            {result ? (
              <span
                className={`font-size-14 line-height-20 font-weight-6 ${data?.result?.isTrue ? "score-correct-wrapper text-correct" : "score-incorrect-wrapper text-danger"}`}
              >
                Điểm: {data?.result?.points || 0}/
                {data?.result?.totalPoints || 0}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {renderContent()}
      <div className="flex-1 mt-2">
       {configs?.generalConfig?.showFeedbackContent &&
          !data?.settingResponse?.isDisplayPerResponse && !isEmpty(data?.settingResponse) && (
            <SingleMenu
              titleClassName={`comment-menu ${isTrue ? "report-ans-wrapper-correct" : "report-ans-wrapper"} `}
              contentClassName={`${isTrue ? "bg-green" : "bg-purple"}`}
              className={`${isTrue ? "bg-green" : "bg-purple"}`}
              title={
                <p className="font-size-16 line-height-24 font-weight-6">
                  Phản hồi: {renderFeedback()}
                </p>
              }
              content={
                <p className="font-size-16 line-height-24 mt-1 font-weight-5">
                  {data?.result?.message || "Không có nhận xét"}
                </p>
              }
            />
          )}
      </div>
    </div>
  );
}

export default Mp3;
