// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.detail-course-plan-assign {
  /* Ẩn đường kẻ dọc */
  /* Điều chỉnh khoảng cách giữa tiêu đề và biểu tượng */ }
  .detail-course-plan-assign .ant-tree-treenode:hover .ant-tree-node-content-wrapper {
    background: none; }
  .detail-course-plan-assign .ant-tree-treenode .ant-tree-node-content-wrapper {
    background: none; }
  .detail-course-plan-assign .ant-tree-treenode {
    display: block !important; }
  .detail-course-plan-assign .ant-tree-title {
    margin-left: 15px;
    /* Khoảng cách bên phải của tiêu đề */ }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-course-planning-assignment/components/plan-assignment-many/detail-course-plan-assign/detail.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EASE,oBAAA;EAKA,sDAAA,EAAA;EAdF;IAEI,gBAAgB,EAAA;EAFpB;IAMI,gBAAgB,EAAA;EANpB;IAWI,yBAAyB,EAAA;EAX7B;IAgBI,iBAAiB;IAAE,qCAAA,EAAA","sourcesContent":[".detail-course-plan-assign {\n  .ant-tree-treenode:hover .ant-tree-node-content-wrapper {\n    background: none;\n  }\n\n  .ant-tree-treenode .ant-tree-node-content-wrapper {\n    background: none;\n  }\n\n  /* Ẩn đường kẻ dọc */\n  .ant-tree-treenode {\n    display: block !important;\n  }\n\n  /* Điều chỉnh khoảng cách giữa tiêu đề và biểu tượng */\n  .ant-tree-title {\n    margin-left: 15px; /* Khoảng cách bên phải của tiêu đề */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
