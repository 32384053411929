import { Collapse, Drawer, Form, Radio, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import TableData from '../../../table-data/TableData'
import "./index.scss"
import { isEmpty, size } from 'lodash'
import { getTableDataDetailSequence } from '../../../../service/content-list-course/contentListCourseApi'
import { formatDate } from '../../../../utils/converDate'

type Props = {
  open: boolean
  onClose: () => void
  detailData: any
}

export default function DetailLessonDrawer(props: Props) {
  const { open, onClose, detailData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")

  const renderStatus = (value: string) => {
    switch (Number(value)) {
      case 0:
        return "Bản nháp"
        break;
      case 1:
        return "Riêng tư"
        break;
      case 2:
        return "Công khai"
        break;
      default:
        return "Bản nháp"
        break;
    }
  }

  const dataColumnsLesson: TableColumnsType<any> = [
    {
      title: "Trạng thái hoạt động",
      dataIndex: "status",
      key: "status",
      width: 30,
      render: (_: any, record, index: number) => {
        return <div>{renderStatus(record?.status)}</div>;
      },
    },
    {
      title: "Ngày cập nhật",
      dataIndex: "createdDate",
      key: "createdDate",
      sorter: true,
      width: 30,
      render: (_: any, record, index: number) => {
        return <div>{isEmpty(record.createdDate) ? null : formatDate(record.createdDate ?? '')}</div>;
      },
    },
    {
      title: "Người cập nhật",
      dataIndex: "userprofileName",
      key: "userprofileName",
      sorter: true,
      width: 30,
    },
  ]

  const getListDataDetail = async () => {
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder,
      sequenceId: detailData?.id
    }
    await getTableDataDetailSequence(data).then((res) => {
      setListTableDataDetail(res.data?.data?.content)
    })
  }

  useEffect(() => {
    if (open) {
      getListDataDetail();
    }
  }, [open]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  return (
    <Drawer
      title={"Bài giảng"}
      open={open}
      onClose={onClose}
      className='content-detail-course-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
    >
      <Form
        className="active-settings-chapter"
        layout={"vertical"}
        form={form}
        name="active-settings-chapter"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel
              header="Thông tin chung"
              key="1"
              className="setting-chapter-title">
              <div className='m-5'>
                <Typography.Title level={5}>Tên bài giảng:</Typography.Title>
                <p>{detailData?.name}</p>
                <Typography.Title level={5} className='mt-2'>Mô tả:</Typography.Title>
                <p>{detailData?.description}</p>
              </div>
            </Panel>

            <Panel
              header="Trạng thái hoạt động"
              key="2"
              className="setting-chapter-title">
              <div className='m-5'>
                <Radio checked>{renderStatus(detailData?.availableStatus)}</Radio>
                <Typography.Title level={5} className='mt-2'>Phần này sẽ không được người dùng nhìn thấy</Typography.Title>
              </div>
            </Panel>

            <Panel
              header="Lịch sử cập nhật"
              key="3"
              className="setting-chapter-title">
              <div className='m-5'>
                <TableData
                  dataTable={listTableDataDetail}
                  dataColumns={dataColumnsLesson}
                  setPageNumber={setPageNumber}
                  setPageSize={setPageSize}
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                  loadingTable={loading}
                  scroll={{ x: 1500, y: 600 }}
                  totalRecords={totalRecords}
                  handleTableChangeProps={handleTableChange}
                  pagination={true}
                />
              </div>
            </Panel>
          </Collapse>
        </div>
      </Form>
    </Drawer>
  )
}
