import {
  Button,
  Col,
  Drawer,
  Form,
  InputNumber,
  message,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import FormInput from "../../form-input/FormInput";
import {
  CloseOutlined,
  CopyOutlined,
  HeatMapOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PicCenterOutlined,
  PlayCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";
import CommonModal from "../../modal/common";
import { editReviewCourse, getDetailQuiz, getQuestionBank, } from "../../../service/course-construct";
import TextArea from "antd/es/input/TextArea";

type Props = {
  open: boolean;
  onClose: () => void;
  setLoading?: (value: boolean) => void;
  id?: string;
  parentName?: string;
  handleDetail?: any;
  itemDetail?: any;
};

export default function EditExerciseDrawer(props: Props) {
  const { handleDetail, onClose } = props;
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [listQuestionBank, setListQuestionBank] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const dataSearch = {
    page: 1,
    size: 10,
    keyword: "",
    types: ["survey-form-quiz"],
    status: [
      true
    ]
  }
  const [detailData, setDetailData] = useState({
    name: "",
    description: "",
    iconType: 0,
    reviewType: 0,
    formOfReview: 0,
    expectedTime: 0,
    expectedTimeType: 0,
  });

  const getDetailDataQuizEdit = async (id: number) => {
    try {
      const response = await getDetailQuiz(id);
      const { data, status } = response;
      if (status === 200) {
        setDetailData(data);
        setLoading(false)
      }
    } catch (err) {
      console.error(err);
    }
  };


  const validation = {
    title: {
      required: true,
      message: "Vui lòng nhập tiêu đề.",
    },
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const OPTIONS = [
    {
      value: 1,
      label: <PlayCircleOutlined />,
    },
    {
      value: 2,
      label: <PicCenterOutlined />,
    },
    {
      value: 3,
      label: <CopyOutlined />,
    },
    {
      value: 4,
      label: <HeatMapOutlined />,
    },
  ];

  const optionTime = [
    {
      id: 10,
      name: "Phút",
    },
    {
      id: 20,
      name: "Giờ",
    },
  ];

  // 1 = 10
  // 2 = 20
  // 1 + 2 = 9999
  const listAssessments = [
    {
      label: "Trực tuyến",
      value: 1,
      description: "Người học tham gia đánh giá trên nền tảng MOOC",
    },
    {
      label: "Trực tiếp",
      value: 2,
      description: "Người học tham gia đánh giá trực tiếp tại Cơ sở đào tạo",
    },
  ];

  // Get list evaluation form
  const listVideo = listQuestionBank.map((item: any) => ({
    id: item.id,
    name: item.code,
  }));

  const getEvaluationForm = async (data: any) => {
    try {
      await getQuestionBank(data).then((res) => {
        if (res.status === 200 && res.data.data) {
          setListQuestionBank(res.data.data.content);
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  const gotoAddLessonPage = () => {
    form.submit();
  };

  const renderAssessmentsRadioGroup = (option: any) => {
    return (
      <Radio.Group>
        <Row>
          {option.map((op: any) => (
            <Col span={12} key={op.value} style={{ marginBottom: "16px" }}>
              <Radio value={op.value}>{op.label}</Radio>
              <div
                style={{
                  marginLeft: "24px",
                  paddingRight: "10px",
                  color: "#888",
                  fontSize: "12px",
                }}
              >
                {op.description}
              </div>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    );
  };

  const onFinish = async (name: string, { values, forms }: any) => {
    if (props.id && name === "form-edit-review-course") {
      try {
        const dataEdit = {
          unitId: props.id,
          title: values.title,
          description: values.description,
          iconType: values.icon,
          reviewType: values.typeStatus,
          formOfReview: values.assessments,
          expectedTime: values.expectedTime,
          expectedTimeType: values.expectedTimeType,
        };
        const response = await editReviewCourse(dataEdit);
        const { status } = response;
        if (status === 200) {
          message.success("Chỉnh sửa học liệu thành công");
          setOpenConfirmModal(false);
          getDetailDataQuizEdit(Number(props.id));
          handleDetail();
          form.resetFields();
          onClose();
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (props.open) {
      setLoading(true)
      getDetailDataQuizEdit(Number(props.id));
      getEvaluationForm(dataSearch);
    }
  }, [props.open])

  useEffect(() => {
    if (detailData && Object.keys(detailData).length > 0) {
      form.setFieldsValue({
        title: detailData.name,
        description: detailData.description,
        icon: detailData.iconType,
        typeStatus: detailData.reviewType,
        assessments: detailData.formOfReview,
        expectedTime: detailData.expectedTime,
        expectedTimeType: detailData.expectedTimeType,
      });
    }
  }, [detailData]);

  return (
    <Form.Provider onFormFinish={onFinish}>
      <Drawer
        title="Chỉnh sửa học liệu"
        placement={"right"}
        className="add-lession-drawer"
        size={'large'}
        onClose={props.onClose}
        open={props.open}
      >
        <>
          {loading ?
            (
              <div className="loading-content">
                <LoadingOutlined />
              </div>
            )
            :
            (
              <Form
                layout={"vertical"}
                form={form}
                name="form-edit-review-course"
                initialValues={{
                  ["trangThai"]: true,
                }}
              >
                <Row gutter={[24, 12]}>
                  <Typography.Title level={5}>Thông tin chung</Typography.Title>

                  <Col span={24}>
                    <Form.Item
                      name="title"
                      rules={[validation.title]}
                      label="Tiêu đề"
                    >
                      <FormInput placeholder="Tiêu đề" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="description" label="Mô tả">
                      <TextArea showCount maxLength={512} placeholder="Mô tả" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="icon" label="Icon">
                      <Select
                        placeholder="Chọn icon"
                        value={selectedItems}
                        onChange={setSelectedItems}
                        style={{ width: "100%" }}
                        options={OPTIONS.map((item, index) => ({
                          ...item,
                          label: <div>{item.label}</div>,
                        }))}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={18}>
                    <Form.Item
                      name="videoTemplate"
                      label="Chọn mẫu"
                      className="last-form-item"
                    >
                      <FormItemDropdown
                        options={listVideo}
                        allowClear={true}
                        placeholder="Chọn mẫu"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="typeStatus" label="Loại đánh giá">
                      <Radio.Group onChange={onChange} value={value}>
                        <Radio value={1}>Bài tập</Radio>
                        <Radio value={2}>Bài kiểm tra</Radio>
                        <Radio value={3}>Bài thi</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item name="assessments" label="Hình thức đánh giá">
                      {renderAssessmentsRadioGroup(listAssessments)}
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="expectedTime" label="Nỗ lực mong chờ">
                      <InputNumber style={{ width: "100%" }} min={1} />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item name="expectedTimeType" label=" ">
                      <FormItemDropdown options={optionTime} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="mt-3 float-right">
                  <Space>
                    <Button onClick={props.onClose} className="btn btn-outlined">
                      <CloseOutlined />
                      <Typography.Text>Hủy</Typography.Text>
                    </Button>
                    <Button
                      className="btn btn-primary"
                      onClick={() => setOpenConfirmModal(true)}
                    >
                      <SaveOutlined />
                      <Typography.Text>Lưu</Typography.Text>
                    </Button>
                  </Space>
                </Row>

                <CommonModal
                  open={openConfirmModal}
                  title={"Thông báo xác nhận lưu"}
                  desc={`Vui lòng xác nhận lưu học liệu trong khoá học`}
                  closeIcon={true}
                  okText={"Xác nhận"}
                  cancelText={"Huỷ"}
                  icon={
                    <InfoCircleOutlined
                      style={{ color: "#FFC53D", marginRight: "10px" }}
                    />
                  }
                  confirm={gotoAddLessonPage}
                  closeModal={() => setOpenConfirmModal(false)}
                />
              </Form>
            )
          }

        </>
      </Drawer>
    </Form.Provider>
  );
}
