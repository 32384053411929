// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-item--instructor {
  padding-bottom: 12px; }
  .form-item--instructor:last-child {
    padding-bottom: 0; }

.form-item--instructor.item-university .ant-row {
  justify-content: flex-end; }

.form-item--instructor.item-university .ant-col:last-child {
  max-width: 83.33333333333334%; }

.section-instructor {
  max-width: 850px; }

.info-instructor {
  max-width: 650px;
  width: 100%; }
  .info-instructor .ant-form-item {
    width: 94.33333333333334%;
    margin-left: auto; }
    .info-instructor .ant-form-item .ant-form-item-label {
      max-width: 77px;
      width: 100%;
      text-align: right; }

.add-instructor--info {
  display: flex;
  justify-content: center; }
  .add-instructor--info button {
    padding: 7px 15px;
    align-items: center; }
    .add-instructor--info button span {
      font-weight: normal !important; }

.save-instructor--info {
  margin-top: 24p; }
  .save-instructor--info .group-button {
    justify-content: flex-start; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/tabs-setting-common/tab-instructor/index.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB,EAAA;EADtB;IAII,iBAAiB,EAAA;;AAIrB;EAEI,yBAAyB,EAAA;;AAF7B;EAOM,6BAA6B,EAAA;;AAKnC;EACE,gBAAgB,EAAA;;AAGlB;EACE,gBAAgB;EAChB,WAAW,EAAA;EAFb;IAKI,yBAAyB;IACzB,iBAAiB,EAAA;IANrB;MASM,eAAe;MACf,WAAW;MACX,iBAAiB,EAAA;;AAOvB;EACE,aAAa;EACb,uBAAuB,EAAA;EAFzB;IAKI,iBAAiB;IACjB,mBAAmB,EAAA;IANvB;MASM,8BAA8B,EAAA;;AAKpC;EACE,eAAe,EAAA;EADjB;IAII,2BAA2B,EAAA","sourcesContent":[".form-item--instructor {\n  padding-bottom: 12px;\n\n  &:last-child {\n    padding-bottom: 0;\n  }\n}\n\n.form-item--instructor.item-university {\n  .ant-row {\n    justify-content: flex-end;\n  }\n\n  .ant-col {\n    &:last-child {\n      max-width: 83.33333333333334%;\n    }\n  }\n}\n\n.section-instructor {\n  max-width: 850px;\n}\n\n.info-instructor {\n  max-width: 650px;\n  width: 100%;\n\n  .ant-form-item {\n    width: 94.33333333333334%;\n    margin-left: auto;\n\n    .ant-form-item-label {\n      max-width: 77px;\n      width: 100%;\n      text-align: right;\n    }\n\n  }\n}\n\n\n.add-instructor--info {\n  display: flex;\n  justify-content: center;\n\n  button {\n    padding: 7px 15px;\n    align-items: center;\n\n    span {\n      font-weight: normal !important;\n    }\n  }\n}\n\n.save-instructor--info {\n  margin-top: 24p;\n\n  .group-button {\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
