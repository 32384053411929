import React, { useRef, useState } from "react";
import { Button, Tooltip } from "antd";
import { ExportIcon, MicroIcon } from "../../../../../components/icons/svg";

function Mp3() {
  const [audioURL, setAudioURL] = useState<string>("");
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [mode, setMode] = useState<"begin" | "recording" | "view">("begin");

  const startRecording = async () => {
    setMode("recording");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: "audio/wav",
        });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioURL(audioUrl);
        audioChunksRef.current = [];
      };

      mediaRecorder.start();
    } catch (err) {
      console.error("Error accessing the microphone", err);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setMode("view");
  };

  const handleDeleteRecording = () => {
    setAudioURL("");
    setMode("begin");
  };

  const renderContent = () => {
    switch (mode) {
      case "begin":
        return (
          <div className="begin-screen">
            <Button className="record-item mt-2" onClick={startRecording}>
              <div className="center">
                <MicroIcon />
                <span className="font-size-14 font-weight-6 ml-1">
                  Bắt đầu ghi âm câu trả lời của bạn
                </span>
              </div>
            </Button>
          </div>
        );
      case "recording":
        return (
          <div className="micro-recording-screen center flex-column mt-3">
            <div className="record-micro micro-recording-animation">
              <MicroIcon />
            </div>

            <Tooltip title="Kết thúc">
              <Button
                className="mt-2 btn-primary btn-action"
                onClick={stopRecording}
              >
                <ExportIcon />
              </Button>
            </Tooltip>
          </div>
        );
      case "view":
        return (
          <div className="view-screen flex gap-32 align-center">
            <audio src={audioURL} controls />
            <span className="font-size-14 text-primary-violet font-weight-6 pointer">
              Ghi âm lại
            </span>
            <span
              className="text-danger font-size-14 font-weight-6 pointer"
              onClick={handleDeleteRecording}
            >
              Xoá
            </span>
          </div>
        );
      default:
        break;
    }
  };

  return <div>{renderContent()}</div>;
}

export default Mp3;
