import React, {Key, useEffect, useState} from 'react';
import {Button, Col, Form, FormInstance, Row, Transfer, TransferProps, Typography} from "antd";
import FormItemDropdown from "../../../../../components/form-dropdown/FormItemDropdown";
import {getListStudentAddEnrolled} from "../../../../../service/course-construct";
import './index.scss'


type CreateClassProps = {
  formProps: FormInstance,
  dataOptions?: any,
  classId?: number,
  checkIsFormEdit?: any
  isOpened?: any
  resetPermissions?: any
  isOpenDrawer?: boolean
}

interface RecordType {
  key: string;
  title: string;
  description: string;
  chosen: boolean;
}


const CreateStudent = ({
                         formProps, dataOptions,
                         isOpened,
                         classId,
                         isOpenDrawer
                       }: CreateClassProps) => {
  const [targetKeys, setTargetKeys] = useState<Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [dataTransfer, setDataTransfer] = useState<RecordType[]>([])
  const [valueSearchLeft, setValueSearchLeft] = useState('');
  const [valueSearchRight, setValueSearchRight] = useState('');
  const [valueDir, setValueDir] = useState('');
  const [pageSize, setPageSize] = useState(30)
  const [dataStudents, setDataStudents] = useState<any[]>([])
  const [dataSelect, setDataSelect] = useState<any[]>([])
  const [dataFilter, setDataFilter] = useState<any>({

    universityIds: [],
    industryIds: []

  })

  const [form] = Form.useForm()
  const valueUniversity = Form.useWatch('universityIds', form)
  const valueIndustryIds = Form.useWatch('industryIds', form)

  const newDataUniversity = dataOptions.dataUniversity && dataOptions.dataUniversity.map((data: {
    id: any;
    name: any;
  }) => ({
    id: data.id,
    label: data.name
  }))

  const newDataIndustry = dataOptions.dataIndustry && dataOptions.dataIndustry.map((data: {
    id: any;
    name: any;
  }) => ({
    id: data.id,
    label: data.name
  }))

  const fetchData = (dataSearch?: any) => {
    getListStudentAddEnrolled(dataSearch).then(res => {
      const {data} = res.data
      setPageSize(data.length)
      setDataStudents([...data])
    })
  }
  const getNewData = () => {
    if (dataStudents.length > 0) {
      const newData: RecordType[] = dataStudents.map((role: any, i: number) => {
        return {
          key: role.id.toString(),
          title: role.name,
          description: role.name,
          chosen: false,
        }
      })
      setDataTransfer(newData)
    }
  }


  useEffect(() => {
    fetchData({
      enrollClassId: classId,
      keyword: valueSearchLeft.length > 0 && valueDir === 'left' ? valueSearchLeft : valueSearchRight.length > 0 && valueDir === 'right' ? valueSearchRight : ""
    })
  }, [classId, valueSearchLeft, valueSearchRight]);

  useEffect(() => {
    setPageSize(30);
  }, [isOpened]);

  useEffect(() => {
    if (!isOpenDrawer) {
      setTargetKeys([])
      formProps.resetFields()
      form.resetFields()
      fetchData({
        enrollClassId: classId,
        universityIds: [],
        industryIds: []
      })
    }
  }, [isOpenDrawer]);


  useEffect(() => {
    getNewData()
  }, [dataStudents]);

  const handleSearch: TransferProps['onSearch'] = (dir, value) => {
    if (dir === 'left') {
      setValueSearchLeft(value.trim());
      setValueDir(dir);
    } else {
      setValueSearchRight(value.trim());
      setValueDir(dir);
    }
  };

  const loadMore = () => {
    setPageSize(pageSize + 10)
  }

  const filterOption = (inputValue: string, option: RecordType) => {
    return option.description.toLowerCase().indexOf(inputValue.toLowerCase().trim()) > -1;
  }

  const handleChange: TransferProps['onChange'] = (newTargetKeys, direction) => {
    setTargetKeys(newTargetKeys);
    if (direction === 'right') {

    }
    if (direction === 'left') {

    }
  };


  const renderItem = (item: RecordType) => {
    const customLabel = (
      <span className="custom-item">
          {item.title}
        </span>
    );


    return {
      label: customLabel, // for displayed item
      value: item.title, // for title and filter matching
    };
  };

  const renderFooter: TransferProps['footer'] = (_, info) => {
    if (info?.direction === 'left') {
      return (
        <Button size="small" style={{float: 'left', margin: 5}} onClick={loadMore}>
          Tải thêm
        </Button>
      );
    }
  };

  const handleFilter = (values: any) => {
    setDataFilter({
      ...dataFilter,
      universityIds: values.universityIds !== null ? [Number(values.universityIds)] : [],
      industryIds: values.industryIds ! !== null ? [Number(values.industryIds)] : []
    })
    const dataReqFilter = {
      enrollClassId: classId,
      universityIds: dataFilter.universityIds,
      industryIds: dataFilter.industryIds
    }
    fetchData(dataReqFilter)
  }


  return (
    <>
      <Form form={form} onFinish={handleFilter} className="form-create--student">
        <Row gutter={16}>
          <Col className="col-table-create" span={24}>
            <Form.Item
              name="universityIds"
              labelCol={{span: 6}}
              wrapperCol={{span: 18}}
              labelAlign="left"
              label="Trường"
            >
              <FormItemDropdown options={newDataUniversity} placeholder="Chọn trường"/>
            </Form.Item>
          </Col>
          <Col className="col-table-create" span={24}>
            <Form.Item
              name="industryIds"
              labelCol={{span: 6}}
              wrapperCol={{span: 18}}
              labelAlign="left"
              label="Chuyên ngành"
            >
              <FormItemDropdown options={newDataIndustry} placeholder="Chọn chuyên ngành"/>
            </Form.Item>
          </Col>
          {/*TODO*/}
          {/*<Col className="col-table-create" span={24}>
            <Form.Item
              name="numberStudent"
              labelCol={{span: 6}}
              wrapperCol={{span: 18}}
              labelAlign="left"
              label="Khóa học tiên quyết"
            >
              <FormItemDropdown placeholder="Chọn khóa học"/>
            </Form.Item>
          </Col>*/}
        </Row>
        <div className="group-button">

          <Button className="btn btn-primary" htmlType="submit" onClick={() => form.submit()}>
            <Typography.Text>
              Lọc
            </Typography.Text>
          </Button>
        </div>
      </Form>
      <Form
        form={formProps}
        name="createStudent"
        className="create-student--enroll"
      >
        <div className="student-transfer">
          <Form.Item name="listStudent">
            <Transfer listStyle={{width: "47%", height: 550}}
                      dataSource={dataTransfer}
                      showSearch
                      selectAllLabels={[
                        ({selectedCount, totalCount}) => (
                          <span>
															{totalCount} học viên
														</span>
                        )
                        , ({selectedCount, totalCount}) => (
                          <span>
                            {totalCount} học viên
                          </span>
                        )
                      ]}
                      footer={renderFooter}
                      selectedKeys={selectedKeys as any}
                      locale={{searchPlaceholder: "Tìm học viên"}}
                      filterOption={filterOption}
                      onSearch={handleSearch}
                      titles={["Tất cả học viên", "Học viên đã lựa chọn"]}
                      onChange={handleChange}
                      onSelectChange={(sourceSelectedKeys: Key[], targetSelectedKeys: Key[]) => {
                        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
                        const res = dataStudents.filter((item: any) => {
                          return sourceSelectedKeys.indexOf(item.id.toString()) >= 0
                        })
                        setDataSelect(res)
                      }}
                      render={renderItem}
                      targetKeys={targetKeys as string[]}/>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default CreateStudent;
