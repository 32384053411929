import React, { useEffect, useState } from 'react'
import CustomCard from '../../../custom-card/CustomCard'
import { Col, Form, Input, Row, Tag, Typography } from 'antd'
import FormInput from '../../../form-input/FormInput'
import FormItemDropdown from '../../../form-dropdown/FormItemDropdown'
import { DetailCourseData } from '../../../../types/assign-course-construction'
import { isEmpty } from 'lodash'
import "./index.scss"
import { getDetailCourse } from '../../../../service/assign-course-construction'
import { useParams } from 'react-router-dom'

type Prop = {
  dataOptions1: any
  dataOptions2: any
  detailData: any
}

export default function AssignmentInformation(props: Prop) {
  const { dataOptions1, dataOptions2, detailData } = props
  const [form] = Form.useForm()

  const [dataSponsor, setDataSponsor] = useState<any>([])
  const [dataCooperation, setDataCooperation] = useState<any>([])

  const renderListAssigned = !isEmpty(dataSponsor) && dataSponsor?.[0]?.assigned.map((item: any, index: number) => (
    <div key={index} className='flex'>
      <Tag bordered={false} color="success" key={index} className='mr-1'>{item.assignUserName}</Tag>
    </div>
  ))

  const renderListCooperation = !isEmpty(dataCooperation) && dataCooperation?.map((item: any, index: number) => (
    <Row gutter={16} key={index} className=''>
      <Col span={24}>
        <Typography>Tên trường đại học</Typography>
      </Col>
      <Col span={24}>
        <Input disabled value={item.enterpriseName ?? ''} />
      </Col>
      {!isEmpty(item.assigned) ?
        <><Col span={24} className='mt-1'>
          <Typography>Tên Giảng viên</Typography>
        </Col><Col span={24}>
            {item.assigned.map((item: any, index: number) => (
              <Tag bordered={false} color="success" key={index}>{item.assignUserName}</Tag>
            ))}
          </Col></> : null
      }
    </Row>
  ))

  const params = useParams()

  const getDetailCourseData = async () => {
    await getDetailCourse(params?.id).then(res => {
      const { data } = res.data
      const dataSponsorResult: any = data.assigners.filter((item: any) => item.isSponsor)
      const dataCooperationResult: any = data.assigners.filter((item: any) => !item.isSponsor)
      setDataSponsor(dataSponsorResult)
      setDataCooperation(dataCooperationResult)
    })
  }

  useEffect(() => {
    getDetailCourseData()
  }, [])

  return (
    <div className="assignment-infomation--wrapper">
      <CustomCard title={
        <div className="card-heading--info">
          <Typography.Title level={5}>
            Thông tin phân công
          </Typography.Title>
          <Typography.Text className="sub-title">
            Chi tiết thông tin trường - giảng viên được phân công
          </Typography.Text>
        </div>
      } className="card-info--wrapper">
        <Row gutter={16}>
          <Col span={24}>
            <Typography.Title level={5}> Trường chủ trì khoá học</Typography.Title>
            <Typography><span style={{ color: 'red' }}>*</span> Tên trường đại học</Typography>
          </Col>
          <Col span={24}>
            <Input disabled value={dataSponsor?.[0]?.enterpriseName ?? '123'} />
          </Col>
          <Col span={24}>
            <Row gutter={16} className='mt-2'>
              {
                !isEmpty(dataSponsor?.[0]?.assigned) ?
                  <Col span={24}>
                    <Typography><span style={{ color: 'red' }}>*</span> Tên Giảng viên</Typography>
                  </Col> : null
              }
              {renderListAssigned}
            </Row>
          </Col>
        </Row>

        {
          !isEmpty(dataCooperation) ?
            <Row gutter={16}>
              <Typography.Title level={5} className='mt-2 -mb-2'> Trường hợp tác xây dựng</Typography.Title>
              {renderListCooperation}
            </Row> : null
        }

      </CustomCard>
    </div>
  )
}
