//import "./detail.scss";
import { Button, Input, Radio, Row, Space, ConfigProvider } from "antd";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useEffect, useState } from "react";
import { Divider } from "antd";
import { useSurveyRespond } from "../../../../../../stores/stores";

const QuestionType1 = (props: any) => {
  const questionAnswer = props.questionAnswer;
  const { updateAnswer } = useSurveyRespond();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const handleRadioChange = (optionId: number) => {
    setSelectedOption(optionId);
    setIsOtherSelected(false);
  };

  const handleOtherSelected = () => {
    setIsOtherSelected(true);
    setSelectedOption(null);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (typeof questionAnswer === "number") {
      setSelectedOption(questionAnswer);
    } else if (typeof questionAnswer === "string") {
      setIsOtherSelected(true);
      setInputValue(questionAnswer);
    }
  }, []);

  useEffect(() => {
    updateAnswer(
      props.surveyId,
      props.sectionId,
      props.questionId,
      selectedOption
    );
  }, [selectedOption]);

  useEffect(() => {
    updateAnswer(props.surveyId, props.sectionId, props.questionId, inputValue);
  }, [inputValue]);

  return (
    <div className="survey-managment-question-view" style={{ width: "100%" }}>
      <Space
        direction="vertical"
        size="small"
        className="surveyQuestion"
        style={{ width: "100%" }}
      >
        <>
          {props.listOptions.map(
            (Option: any) =>
              !Option.isOther && (
                <Radio
                  key={Option.answerId}
                  checked={selectedOption === Option.answerId}
                  onChange={() => handleRadioChange(Option.answerId)}
                  disabled={props.typeSurvey === "2"}
                >
                  {Option.answerText}
                </Radio>
              )
          )}
          {props.listOptions.map(
            (Option: any) =>
              Option.isOther && (
                <>
                  <Radio
                    checked={isOtherSelected}
                    onChange={handleOtherSelected}
                    disabled={props.typeSurvey === "2"}
                  >
                    Khác
                  </Radio>
                  {isOtherSelected && (
                    <>
                      <Input
                        placeholder="Vui lòng nhập..."
                        value={inputValue}
                        onChange={handleChangeInput}
                        style={{ marginTop: "12px", marginBottom: "12px" }}
                        disabled={props.typeSurvey === "2"}
                      />
                    </>
                  )}
                  {!isOtherSelected && <Divider style={{ margin: "12px 0" }} />}
                </>
              )
          )}
        </>
      </Space>
    </div>
  );
};

export default QuestionType1;
