// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-construction .form-input {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 0; }

.survey-managment-construction .input-header-title::placeholder {
  color: black; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/detail-question/question-type-2/detail.scss"],"names":[],"mappings":"AAAA;EAEI,aAAiB;EACjB,mBAAmB;EACnB,WAAiB;EACjB,SAAkB,EAAA;;AALtB;EASI,YAAY,EAAA","sourcesContent":[".survey-managment-construction {\n  .form-input {\n    display    : flex;\n    align-items: center;\n    width      : 100%;\n    flex       : 1 0 0;\n  }\n\n  .input-header-title::placeholder {\n    color: black;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
