import { Form, Layout, TableColumnsType, TableProps } from "antd";
import React, { KeyboardEvent, useEffect, useState } from "react";
import FormItemInput from "../../../components/form-input/FormInput";
import { SearchOutlined } from "@ant-design/icons";
import "../style.scss";
import TableData from "../../../components/table-data/TableData";
import { CourseBaseInfo } from "../../../types/scoring-course";
import { Link } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import { getCourseOfTeacher } from "../../../service/scoring";
import { DEFAULT_PAGE_SIZE } from "../../../constants";

interface CourseScoringListProps {
  title?: string;
}

function CourseScoringList({ title }: CourseScoringListProps) {
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [courses, setCourses] = useState<CourseBaseInfo[]>([]);

  useEffect(() => {
    getCourses();
  }, [pageSize, currentPage]);

  const getCourses = async () => {
    try {
      setLoading(true);
      const res = await getCourseOfTeacher({
        keyword: searchValue,
        page: currentPage,
        size: pageSize,
      });
      setTotalElements(res.data.data.total);
      setCourses(res.data.data.data);
    } catch (err) {
      console.error;
    } finally {
      setLoading(false);
    }
  };

  const handleChangeInput = (e: any) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSubmitSearch = () => {
    getCourses();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      handleSubmitSearch();
    }
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    // setStatusOrder(data);
    // setFieldSortOrder(sorter.field);
  };

  const dataColumns: TableColumnsType<CourseBaseInfo> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      align: "center",
      width: "10%",
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Mã khoá học",
      dataIndex: "id",
      key: "id",
      width: "20%",
    },
    {
      title: "Tên khoá học",
      dataIndex: "name",
      sorter: true,
      key: "name",
      width: "70%",
      render: (_: any, record) => {
        return (
          <Link
            className="text-primary"
            to={`${routesConfig.scoringListCourse}/${record.id}`}
            state={{
              name: record.name,
            }}
          >
            {record.name}
          </Link>
        );
      },
    },
  ];

  const data = [
    {
      id: 1,
      name: "Lập trình C++",
    },
    {
      id: 2,
      name: "Lập trình Java",
    },
    {
      id: 3,
      name: "Lập trình cơ bản",
    },
  ];

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className="scoring-course w-full">
          <div className="scoring-page-header flex justify-between align-center">
            <h3 className="page-title">{title}</h3>
            <div className="search-input">
              <FormItemInput
                style={{ width: 280 }}
                placeholder="Nhập mã hoặc tên khoá học"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
              />
            </div>
          </div>
          <div className="scoring-content">
            <TableData
              dataTable={courses}
              dataColumns={dataColumns}
              scroll={{ x: 1000, y: 600 }}
              pagination
              handleTableChangeProps={handleTableChange}
              setPageNumber={setCurrentPage}
              setPageSize={setPageSize}
              pageNumber={currentPage}
              pageSize={pageSize}
              loadingTable={loading}
              totalRecords={totalElements}
            />
          </div>
        </div>
      </Form.Provider>
    </Layout>
  );
}

export default CourseScoringList;
