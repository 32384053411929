import { Button, Modal, Space } from 'antd'
import React, { ReactNode } from 'react'
import "./common.scss"
import { isEmpty } from 'lodash'

type Props = {
  open: boolean
  closeIcon: boolean
  loading?: boolean
  title: string
  className?: string
  width?: string
  desc: any
  okText: string
  cancelText: string
  closeModal: (value: boolean) => void
  confirm: () => void
  icon: ReactNode
}

export default function CommonModal(props: Props) {
  const { open, title, closeIcon, okText, cancelText, closeModal, icon, confirm, desc, width, className, loading } = props

  const onOk = () => {
    confirm()
  }

  return (
    <Modal
      className={!isEmpty(className) ? className : 'modal-common'}
      title={<>{icon} <span>{title}</span></>}
      style={{ width: `${width}` }}
      closeIcon={closeIcon}
      centered
      onCancel={() => closeModal(false)}
      open={open}
      footer={(_, {}) => (
        <div className="modal-btn-center">
          <Space size="small">
            <Button onClick={() => closeModal(false)}>{cancelText}</Button>
            <Button loading={loading !== undefined ? !loading : false} onClick={onOk} className='btn btn-primary'>{okText}</Button>
          </Space>
        </div>
      )}
    >
      <div className='description'>
        {desc}
      </div>
    </Modal>
  )
}
