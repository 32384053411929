import React from "react";
import { QuizAnsProps } from "../../../../../../types/scoring-course";
import { isArray } from "lodash";
import { renderFileType } from "../../../../../../utils/format";
import { Flex } from "antd";
import EmptyComponent from "../../../../../../components/empty";

function FileUpload({ result }: QuizAnsProps) {
  const currentAnsOfUser = result?.results[0];
  const fileUrl = isArray(currentAnsOfUser)
    ? currentAnsOfUser[0]?.contents[0]
    : "";

  const getFileName = (url: string): string => {
    const urlParts = url.split("/");
    const fileNameWithParams = urlParts[urlParts.length - 1];
    const fileName = fileNameWithParams.split("?")[0];
    return fileName;
  };

  // Hàm để lấy kiểu file từ tên file
  const getFileType = (fileName: string): string => {
    const fileParts = fileName.split(".");
    return fileParts[fileParts.length - 1];
  };

  const fileName = getFileName(fileUrl);

  const fileType = getFileType(fileName);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", fileName); // Chỉ định tên file tải về
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {currentAnsOfUser ? (
        <Flex className="file-wrap" align="center" gap={8}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/icons/${renderFileType(
              fileType
            )}`}
            alt="pdf-icon"
          />
          <div onClick={handleDownload} className="text-primary pointer">
            {fileName}
          </div>
        </Flex>
      ) : (
        <EmptyComponent description="Không có câu trả lời" />
      )}
    </div>
  );
}

export default FileUpload;
