import React, { useEffect, useState } from "react";
import { Pagination, PaginationProps, Table, TableColumnsType, Space, TableProps } from "antd";
import { useParams } from "react-router-dom";
import TableData from "../../../../../components/table-data/TableData";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./DetailConfirm.scss";
import { formatDateToIsoStringHHss } from "../../../../../utils/converDate";
import { getMoocApprovedHistoryByCourseId } from "../../../../../service/course-plan-managment/mooc-course-planning-assignment";
const DetailConfirm = () => {
  const [listApproval, setListApproval] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");

  const { id } = useParams();
  const getlistData = async () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };
    await getMoocApprovedHistoryByCourseId(Number(id), data)
      .then(async (res) => {
        const { data } = res.data;
        setListApproval(data.data);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getlistData();
  }, [pageNumber, pageSize]);
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      width: "5%",
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2, "0");
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên cán bộ duyệt",
      width: "20%",
      render: (_, record: Record<string, any>) => {
        return <div>{record?.individualName}</div>;
      },
    },
    {
      title: "Thời gian",
      width: "10%",
      render: (_: any, record, index: number) => {
        const dateTimeConvert = record?.createdDate
          ? formatDateToIsoStringHHss(record?.createdDate.toString())
          : null;
        const parts = dateTimeConvert ? dateTimeConvert.split(" ") : [];
        const time = parts.length > 1 ? parts[0] : null;
        const date = parts.length > 1 ? parts[1] : null;
        return (
          <Space direction="vertical">
            <span>{time}</span>
            <span>{date}</span>
          </Space>
        );
      },
    },
    {
      title: "Trạng thái",
      width: "10%",
      render: (_: any, record: Record<string, any>) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.constructionPlanStatus === 1
                    ? "#D9D9D9"
                    : record.constructionPlanStatus === 2
                      ? "#52C41A"
                      : record.constructionPlanStatus === 3
                        ? "#FF0000"
                        : record.constructionPlanStatus === 4
                          ? "#FAAD14"
                          : "white",
              }}
            ></div>
            {record.constructionPlanStatus === 1
              ? "Chờ duyệt"
              : record.constructionPlanStatus === 2
                ? "Đã duyệt"
                : record.constructionPlanStatus === 3
                  ? "Từ chối"
                  : record.constructionPlanStatus === 4
                    ? "Thu hồi"
                    : "white"}
          </div>
        );
      },
    },
    {
      title: "Mô tả",
      width: "55%",
      render: (_, record) => {
        return (
          <div>
            {record.approvalRemark === "" || record.approvalRemark == null ? (
              "Không có ý kiến nhận xét "
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: record.approvalRemark }}
              ></div>
            )}
          </div>
        );
      },
    },
  ];
  const handleTableChange: TableProps["onChange"] = (sorter: any) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
  };
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
    }
  };
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  return (
    <>
      <div className="table-wrapper">
        <TableData dataTable={listApproval}
          dataColumns={dataColumns}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalRecords}
          handleTableChangeProps={handleTableChange}
          hideSelection={false}
          pagination={true}
        />
      </div>
    </>
  );
};

export default DetailConfirm;
