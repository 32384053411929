// import "./detail.scss";
import { Input, Row, Space } from "antd";
import { useState } from "react";

const QuestionType8 = (props: any) => {
    const [showPlaceholder, setShowPlaceholder] = useState(true);

    const handleFocus = () => {
        setShowPlaceholder(false);
    };

    const handleBlur = () => {
        setShowPlaceholder(true);
    };

    return (
        <div>
            <div>
                <br />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                    <Row
                        style={{
                            margin: "0 auto",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Input
                            style={{ borderTop: "none", borderLeft: "none", borderRight: "none" }}
                            value="Nhập câu trả lời của bạn"
                        />
                    </Row>
                </Space>
                <br />
            </div>
        </div>
    );
};

export default QuestionType8;
