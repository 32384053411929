// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.condition-skill--form {
  margin-bottom: 24px; }
  .condition-skill--form .ant-form-item .ant-form-item-control-input-content {
    gap: 16px;
    display: flex;
    justify-content: flex-end; }
  .condition-skill--form .select-skill .ant-row {
    margin-left: auto;
    max-width: 750px; }
  .condition-skill--form .ant-select {
    max-width: 630px; }

.course-prerequisite--form .ant-form-item {
  margin-bottom: 0; }
  .course-prerequisite--form .ant-form-item .ant-form-item-control-input-content {
    gap: 16px;
    display: flex;
    justify-content: flex-end; }

.course-prerequisite--form .ant-row {
  margin-left: auto;
  max-width: 750px; }

.course-prerequisite--form .ant-select {
  max-width: 630px; }

.form-select--time {
  display: flex;
  gap: 16px; }
  .form-select--time .form-select-item--date {
    width: 80%;
    margin-bottom: 16px; }
  .form-select--time .form-select-item--time {
    width: 20%; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/setting-content-course/condition-register/index.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;EADrB;IAKM,SAAS;IACT,aAAa;IACb,yBAAyB,EAAA;EAP/B;IAcI,iBAAiB;IACjB,gBAAgB,EAAA;EAfpB;IAmBI,gBAAgB,EAAA;;AAIpB;EAEI,gBAAgB,EAAA;EAFpB;IAKM,SAAS;IACT,aAAa;IACb,yBAAyB,EAAA;;AAP/B;EAYI,iBAAiB;EACjB,gBAAgB,EAAA;;AAbpB;EAiBI,gBAAgB,EAAA;;AAIpB;EACE,aAAa;EACb,SAAS,EAAA;EAFX;IAKI,UAAU;IACV,mBAAmB,EAAA;EANvB;IAUI,UAAU,EAAA","sourcesContent":[".condition-skill--form {\n  margin-bottom: 24px;\n\n  .ant-form-item {\n    .ant-form-item-control-input-content {\n      gap: 16px;\n      display: flex;\n      justify-content: flex-end;\n    }\n\n\n  }\n\n  .select-skill .ant-row {\n    margin-left: auto;\n    max-width: 750px;\n  }\n\n  .ant-select {\n    max-width: 630px;\n  }\n}\n\n.course-prerequisite--form {\n  .ant-form-item {\n    margin-bottom: 0;\n\n    .ant-form-item-control-input-content {\n      gap: 16px;\n      display: flex;\n      justify-content: flex-end;\n    }\n  }\n\n  .ant-row {\n    margin-left: auto;\n    max-width: 750px;\n  }\n\n  .ant-select {\n    max-width: 630px;\n  }\n}\n\n.form-select--time {\n  display: flex;\n  gap: 16px;\n\n  .form-select-item--date {\n    width: 80%;\n    margin-bottom: 16px;\n  }\n\n  .form-select-item--time {\n    width: 20%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
