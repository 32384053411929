import { useRef } from "react";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { staticReportStore } from "../../../../../stores/stores";

const useChartExport = () => {
  const chartRefs = useRef<{ [tabKey: string]: { [chartKey: string]: any } }>({});

  const createChartRef = (tabKey: string, chartKey: string) => (ref: any) => {
    if (ref && ref.getChart) {
      if (!chartRefs.current[tabKey]) {
        chartRefs.current[tabKey] = {};
      }
      chartRefs.current[tabKey][chartKey] = ref.getChart();
    }
  };

  const handleExportImage = (tabKey: string, chartKey: string, format: string) => {
    const chart = chartRefs.current[tabKey]?.[chartKey];
    if (chart) {
      chart.exportChart({
        type: format,
        filename: `${chartKey}`,
      });
    } else {
      console.warn(`No chart found for tab: ${tabKey}, key: ${chartKey}`);
    }
  };

  const handleExportAllImagesAsZip = async (tabKey: string) => {
    const { exportType } = staticReportStore.getState(); // Lấy exportType trực tiếp từ Zustand

    const zip = new JSZip();
    const exportUrl = "https://export.highcharts.com/";

    const tabCharts = chartRefs.current[tabKey] || {};

    const promises = Object.keys(tabCharts).map(async (chartKey, index) => {
      const chart = tabCharts[chartKey];
      if (chart) {
        const svgData = chart.getSVG();

        const formData = new FormData();
        formData.append("filename", `${chartKey}`);
        formData.append("type", exportType === "pdf" ? "application/pdf" : "image/png");
        formData.append("svg", svgData);

        try {
          await new Promise((resolve) => setTimeout(resolve, 2000 * index));

          const response = await fetch(exportUrl, {
            method: "POST",
            body: formData,
          });

          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }

          const blob = await response.blob();
          zip.file(`${chartKey}.${exportType === "pdf" ? "pdf" : "png"}`, blob);
        } catch (error) {
          console.error(`Failed to export chart: ${chartKey}`, error);
        }
      } else {
        console.warn(`No chart found for tab: ${tabKey}, key: ${chartKey}`);
      }
    });

    await Promise.all(promises);

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `charts.zip`);
    });
  };

  return { createChartRef, handleExportImage, handleExportAllImagesAsZip };
};

export default useChartExport;
