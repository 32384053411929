import React, { useCallback, useEffect, useRef, useState } from "react";
import toolbarOptions from "./toolbarOptions";
import ReactQuill, { Quill } from "react-quill";
import { FORMAT_REACT_QUILL } from "../../../../../../constants";
import { upLoadFileSurvey } from "../../../../../../service/survey-managment/list-survey";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";

Quill.register("modules/imageActions", ImageActions);
Quill.register("modules/imageFormats", ImageFormats);
interface EditorQuillProps {
  valueInitial?: string;
  setUpdateValue?: (data: string) => void;
  handleOnChangeSelection?: (data: string) => void;
  handleOnChange?: (data: string) => void;
  handleOnBlur?: (data: string) => void;
  sizeContainer?: number;
}

const EditorQuill: React.FC<EditorQuillProps> = ({
  valueInitial,
  setUpdateValue,
  handleOnChangeSelection,
  handleOnChange,
  handleOnBlur,
  sizeContainer,
}) => {
  const handleLink = useCallback(() => {
    const message = "Điền link vào bên dưới (Ví dụ: https://example.com)";
    const url = prompt(message, "https://");

    if (url !== null) {
      const quill = reactQuillRef.current;
      if (quill) {
        const range = quill.getEditorSelection();
        if (range) {
          const linkText = prompt("Enter the link text", "Link text");
          if (linkText !== null) {
            quill.getEditor().insertText(range.index, linkText, "link", url); // Insert link with text
          }
        } else {
          console.warn("No selection range found.");
        }
      }
    }
  }, []);

  // const uploadVideoToS3 = useCallback(() => {
  //   const input = document.createElement("input");
  //   input.setAttribute("type", "file");
  //   input.setAttribute("accept", "video/*");
  //   input.click();

  //   input.onchange = async () => {
  //     const file = input.files ? input.files[0] : null;
  //     if (file) {
  //       try {
  //         let data = new FormData();
  //         data.append("file", file);
  //         const response = await upLoadFileSurvey(data);
  //         const fileData = response.data.data;
  //         if (
  //           response &&
  //           (response.status === 200 || response.status === 201)
  //         ) {
  //           const quill = reactQuillRef.current;
  //           if (quill) {
  //             const range = quill.getEditorSelection();
  //             const url = `${fileData.filePath}`;
  //             range && quill.getEditor().insertEmbed(range.index, "video", url);
  //           }

  //           console.log("Video uploaded and embedded successfully");
  //         } else {
  //           console.error("Video upload failed");
  //         }
  //       } catch (error) {
  //         console.error("An error occurred during video upload:", error);
  //       }
  //     } else {
  //       console.warn("No video selected.");
  //     }
  //   };
  // }, []);
  const uploadImageToS3 = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".jpg,.jpeg,.png");
    input.click();
    // Listen upload local image and save to state
    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      console.log({ file });
      if (file) {
        try {
          // Create a FormData object to send the file
          let data = new FormData();
          data.append("file", file);

          // Send the file to the server using fetch or axios
          const response = await upLoadFileSurvey(data);
          const fileData = response.data.data;
          // console.log(`res-uploadFile::`, response.data.data);

          // Check if the request was successful
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const quill = reactQuillRef.current;
            if (quill) {
              const range = quill.getEditorSelection();
              const quillEditor = quill.getEditor();
              const url = `${fileData.filePath}`;
              // range && quill.getEditor().insertEmbed(range.index, "image", url);

              const p = document.createElement("p");
              p.classList.add("list-media");
              const img = document.createElement("img");
              img.src = url;

              p.appendChild(img);

              // Insert the P element at the current selection
              range &&
                quillEditor.clipboard.dangerouslyPasteHTML(
                  range.index,
                  p.outerHTML
                );

              console.log({ url });
              console.log({ range });
            }

            console.log("File uploaded successfully");
          } else {
            // Handle errors if the request was not successful
            console.error("File upload failed");
          }
          // reader.onload = async () => {

          // };
        } catch (error) {
          // Handle any errors that occurred during the file upload process
          console.error("An error occurred during file upload:", error);
        }
      } else {
        console.warn("No file selected.");
      }
    };
  }, []);

  const uploadVideoToS3 = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".mp4,.mov,.wmv,.flv,.avi,.mkv,.webm");
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        try {
          let data = new FormData();
          data.append("file", file);
          const response = await upLoadFileSurvey(data);
          const fileData = response.data.data;
          if (
            response &&
            (response.status === 200 || response.status === 201)
          ) {
            const quill = reactQuillRef.current;
            if (quill) {
              const range = quill.getEditorSelection();
              const url = `${fileData.filePath}`;
              range && quill.getEditor().insertEmbed(range.index, "video", url);
              console.log({ url });
            }

            console.log("Video uploaded and embedded successfully");
          } else {
            console.error("Video upload failed");
          }
        } catch (error) {
          console.error("An error occurred during video upload:", error);
        }
      } else {
        console.warn("No video selected.");
      }
    };
  }, []);

  const modules = {
    imageActions: {},
    imageFormats: {},
    toolbar: {
      container: toolbarOptions,
      handlers: {
        link: handleLink,
        video: uploadVideoToS3,
        image: uploadImageToS3,
      },
    },
  };
  const formats = [
    "align",
    "background",
    "blockquote",
    "bold",
    "code-block",
    "color",
    "float",
    "font",
    "header",
    "height",
    "image",
    "italic",
    "link",
    "script",
    "strike",
    "size",
    "underline",
    "width",
    "video",
  ];

  useEffect(() => {}, [valueInitial]);

  const reactQuillRef = useRef<ReactQuill>(null);

  return (
    <div>
      <ReactQuill
        formats={FORMAT_REACT_QUILL}
        modules={modules}
        theme="snow"
        onBlur={(range, source, editor) => {
          const content = editor.getHTML();
          if (source === "user") {
            handleOnBlur && handleOnBlur(content);
          }
        }}
        onChangeSelection={(range, source, editor) => {
          const content = editor.getHTML();
          handleOnChangeSelection && handleOnChangeSelection(content);
        }}
        onChange={handleOnChange}
        value={valueInitial ? valueInitial : undefined}
        placeholder={!valueInitial ? "Thêm mô tả tại đây ... " : undefined}
        style={
          sizeContainer
            ? {
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "flex-end",
                height: `${sizeContainer}px`,
              }
            : {
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "flex-end",
              }
        }
        preserveWhitespace={true}
        ref={reactQuillRef}
      />
    </div>
  );
};

export default EditorQuill;

/*

import { Modal } from "antd";
import React, { FunctionComponent, useRef, useState } from "react";
import ReactQuill from "react-quill";

const EditorVideo: FunctionComponent<any> = ({ title }) => {


  const [isOpenModalVideo, setIsOpenModalVideo] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleInputVideo = () => {
    setIsOpenModalVideo(true);
    if (fileInputRef.current) {
        fileInputRef.current.click(); // Kích hoạt sự kiện click của input file
      }
  };

  const handleModalOk = () => {

  }

  const handleModalCancel = () => {setIsOpenModalVideo(false)}

const modules = {
    
}
  return (
    <div>
      <ReactQuill
        modules={{
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote", "code-block"],

              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown

              ["link", "image", "video"],
              ["clean"],
            ],
            handlers: {
              video: handleInputVideo(),
            },
          },
        }}
        theme="snow"
      />
      <Modal
        title="Basic Modal"
        open={isOpenModalVideo}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>
    </div>
  );
};

export default EditorVideo;

*/
