import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Flex,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "./style.scss";
import {
  createNotification,
  findHavingSchoolForStudent,
  getAllEnterprise,
} from "../../service/notifications";
import { EnterpriseProps, SchoolProps } from "../../types/notifications";
import { isArray, isEmpty } from "lodash";
import useDebounce from "../../hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../config/routes";
import TextEditor from "./TextEditor";

function AddNotification() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [schools, setSchools] = useState<EnterpriseProps[]>([]);
  const [schoolsForSv, setSchoolForSv] = useState<SchoolProps[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [keywordSv, setKeywordSv] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const keyDebounce = useDebounce(keyword, 100);
  const keywordSvDebounce = useDebounce(keywordSv, 100);

  const [studentOption, setStudentOption] =
    useState<number>(0); /* 0: tat ca sinh vien, 1: tuy chon sinh vien */

  const systemChecked = Form.useWatch("systemChecked", form); // check qtht
  const isForQtcs = Form.useWatch("isForQtcs", form); // check qtcs
  const isForSv = Form.useWatch("isForSv", form); // check sinh vien
  const isForGv = Form.useWatch("isForGv", form); // check giang vien
  const isChooseOptional = Form.useWatch("optional", form); // check optional
  const isSendToAll = Form.useWatch("sendToAll", form); // check sendToAll

  const schoolsOpt = [
    {
      label: "Tất cả cơ sở đào tạo",
      value: 0,
    },
  ].concat(
    schools?.map((school: any) => ({
      label: school?.name,
      value: school?.uuid,
    }))
  );

  const schoolsOptForSv = [
    {
      label: "Tất cả cơ sở đào tạo",
      value: 0,
    },
  ].concat(
    schoolsForSv?.map((school: any) => ({
      label: school?.name,
      value: school?.id,
    }))
  );

  useEffect(() => {
    const getEnterprise = async () => {
      const res = await getAllEnterprise({
        page: 1,
        size: 100,
        keyword: keyDebounce?.trim(),
      });
      setSchools(res.data.data?.content);
    };
    getEnterprise();
  }, [keyDebounce]);

  useEffect(() => {
    const getHavingSchool = async () => {
      const res = await findHavingSchoolForStudent(keywordSvDebounce?.trim());
      setSchoolForSv(res.data.data);
    };
    getHavingSchool();
  }, [keywordSvDebounce]);

  const handleCreateNotification = async (fieldsValue: any) => {
    Modal.confirm({
      type: "info",
      icon: <InfoCircleOutlined className="text-primary" />,
      title: <span className="font-weight-5">Xác nhận lưu thông báo</span>,
      content: (
        <span>
          Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhập
          đầy đủ trước khi lưu
        </span>
      ),
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: async () => {
        try {
          setLoading(true);
          const res = await createNotification(
            getNotificationPayload(fieldsValue)
          );
          message.success("Tạo thông báo thành công!");
          form.resetFields();
          navigate(`${routesConfig.notifications}/${res.data.data.id}`);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const getNotificationPayload = (fieldsValue: any) => {
    if (fieldsValue?.sendToAll) {
      return {
        title: fieldsValue?.title,
        isForQtht: fieldsValue?.systemChecked,
        sendToAll: fieldsValue?.sendToAll,
        message: content,
      };
    }
    return {
      title: fieldsValue?.title,
      isForQtht: fieldsValue?.systemChecked,
      sendToAll: false,
      message: content,
      forQtcs: fieldsValue?.isForQtcs
        ? getEnterprisePayload(fieldsValue?.forQtcs)
        : [],
      forGv: fieldsValue?.isForGv
        ? getEnterprisePayload(fieldsValue?.forGv)
        : [],
      forSv: fieldsValue?.isForSv
        ? studentOption === 1
          ? getSchoolForSvPayload(fieldsValue?.forSv)
          : [
              {
                uuid: "ALL",
                name: "ALL",
              },
            ]
        : [],
    };
  };

  const getEnterprisePayload = (uuids: string[] | 0) => {
    if (isArray(uuids) && isEmpty(uuids)) return [];

    if (uuids === 0)
      return [
        {
          uuid: "ALL ENTERPRISES",
          name: "ALL ENTERPRISES",
        },
      ];

    return uuids?.map((uuid: string) => {
      const enterprise = schools?.find(
        (item: EnterpriseProps) => item?.uuid === uuid
      );
      if (enterprise) return enterprise;

      return {
        uuid: "ALL ENTERPRISES",
        name: "ALL ENTERPRISES",
      };
    });
  };

  const getSchoolForSvPayload = (ids: string[] | 0) => {
    if (isArray(ids) && isEmpty(ids)) return [];

    if (ids === 0)
      return [
        {
          uuid: "ALL ENTERPRISES",
          name: "ALL ENTERPRISES",
        },
      ];

    return ids?.map((id: string) => {
      const school = schoolsForSv?.find((item: SchoolProps) => item?.id === id);
      if (school)
        return {
          uuid: school.id,
          name: school.name,
        };

      return {
        uuid: "ALL ENTERPRISES",
        name: "ALL ENTERPRISES",
      };
    });
  };

  const handleValuesChange = (value: any) => {
    const validateChange = ["isForQtcs", "isForSv", "isForGv"];
    if (validateChange.includes(Object.keys(value)[0]))
      form.setFields([
        {
          name: "forQtcs",
          errors: [],
        },
        {
          name: "forGv",
          errors: [],
        },
        {
          name: "forSv",
          errors: [],
        },
      ]);
  };

  const isDisableSubmit = () => {
    if (!isSendToAll && !isChooseOptional) return true;

    if (isSendToAll) return false;

    if (
      isChooseOptional &&
      !isForQtcs &&
      !isForGv &&
      !isForSv &&
      !systemChecked
    )
      return true;
    return false;
  };

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        className="btn-primary"
        icon={<PlusOutlined />}
      >
        Thêm thông báo
      </Button>

      <Drawer
        maskClosable={false}
        width={700}
        afterOpenChange={() => {
          form.resetFields();
          setContent("");
        }}
        title={<span className="font-weight-5">Thêm mới thông báo</span>}
        open={open}
        onClose={() => setOpen(false)}
        className="notifications-page notification-drawer"
      >
        <Form
          onFinish={handleCreateNotification}
          form={form}
          onValuesChange={handleValuesChange}
        >
          <label htmlFor="" className="font-weight-5">
            <span className="text-danger mr-1">*</span>Tiêu đề của thông báo
          </label>
          <Form.Item
            rules={[{ required: true, message: "Vui lòng nhập tiêu đề" }]}
            name="title"
          >
            <Input.TextArea
              className="mt-1"
              placeholder="Nhập tiêu đề"
              showCount
              maxLength={500}
            ></Input.TextArea>
          </Form.Item>
          <div className="break-line mt-3" />
          <div className="mt-2">
            <label htmlFor="" className="font-weight-5">
              <span className="text-danger mr-1">*</span>Đối tượng nhận thông
              báo
            </label>
            <Flex gap={8} align="center" className="mt-1">
              <Form.Item name="sendToAll">
                <Switch
                  onChange={() => form.setFieldValue("optional", undefined)}
                />
              </Form.Item>
              <span className="text-14">Toàn bộ người dùng trên hệ thống</span>{" "}
            </Flex>
          </div>

          <Flex gap={8} align="center" className="mt-1">
            <Form.Item name="optional" valuePropName="checked">
              <Switch
                onChange={() => form.setFieldValue("sendToAll", undefined)}
              />
            </Form.Item>
            <span className="text-14">Tuỳ chọn</span>{" "}
          </Flex>
          <Flex gap={8} className="drawer-btn-group">
            <Button
              onClick={() => {
                setOpen(false);
                setContent("");
              }}
            >
              Huỷ
            </Button>
            <Button
              className="btn-primary"
              htmlType="submit"
              loading={loading}
              disabled={isDisableSubmit()}
            >
              Lưu
            </Button>
          </Flex>
          {isChooseOptional && (
            <div className="optional">
              <Row>
                <Col span={6}>
                  <Flex gap={8} align="center">
                    <Form.Item name="systemChecked" valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <span> Quản trị hệ thống</span>
                  </Flex>
                </Col>
              </Row>
              <div className="break-line mt-1 mb-1" />
              <Row>
                <Col span={6}>
                  <Flex gap={8} align="center">
                    <Form.Item name="isForQtcs" valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <span> Quản trị cơ sở</span>
                  </Flex>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name="forQtcs"
                    initialValue={0}
                    rules={[
                      {
                        required: isForQtcs,
                        message: "Vui lòng chọn đối tượng",
                      },
                    ]}
                  >
                    <Select
                      disabled={!isForQtcs}
                      placeholder="Chọn đối tượng nhận thông báo"
                      options={schoolsOpt}
                      mode="multiple"
                      maxTagCount="responsive"
                      onSearch={(value: string) => setKeyword(value)}
                      filterOption={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="break-line mt-1 mb-1" />
              <Row>
                <Col span={6}>
                  <Flex gap={8} align="center">
                    <Form.Item name="isForGv" valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <span> Giảng viên</span>
                  </Flex>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name="forGv"
                    initialValue={0}
                    rules={[
                      {
                        required: isForGv,
                        message: "Vui lòng chọn đối tượng",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Chọn đối tượng nhận thông báo"
                      disabled={!isForGv}
                      options={schoolsOpt}
                      mode="multiple"
                      maxTagCount="responsive"
                      onSearch={(value: string) => setKeyword(value)}
                      filterOption={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="break-line mt-1 mb-1" />
              <Row>
                <Col span={6}>
                  <Flex gap={8} align="center">
                    <Form.Item name="isForSv" valuePropName="checked">
                      <Checkbox />
                    </Form.Item>
                    <span> Sinh viên</span>
                  </Flex>
                </Col>
                <Col span={18}>
                  <Select
                    disabled={!isForSv}
                    className="w-full"
                    defaultValue={0}
                    onChange={setStudentOption}
                    options={[
                      { label: "Tất cả sinh viên", value: 0 },
                      { label: "Tuỳ chọn", value: 1 },
                    ]}
                  />
                </Col>
              </Row>
              {studentOption !== 0 && isForSv && (
                <Row className="mt-1">
                  <Col span={6}></Col>
                  <Col span={18}>
                    <Form.Item
                      name="forSv"
                      initialValue={0}
                      rules={[
                        {
                          required: isForSv,
                          message: "Vui lòng chọn đối tượng",
                        },
                      ]}
                    >
                      <Select
                        disabled={!isForSv}
                        options={schoolsOptForSv}
                        placeholder="Chọn đối tượng nhận thông báo"
                        mode="multiple"
                        maxTagCount="responsive"
                        onSearch={(value: string) => setKeywordSv(value)}
                        filterOption={false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          )}
          <div className="break-line mt-1 mb-2" />
          <label htmlFor="" className="font-weight-5 ">
            <span className="text-danger mr-1"></span>Nội dung của thông báo
          </label>
          <Row className="mt-1">
            <TextEditor
              value={content}
              onChange={setContent}
              placeholder="Nhập nội dung thông báo"
            />
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}

export default AddNotification;
