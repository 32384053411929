// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-prediction .ant-drawer-body {
  padding: 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/prediction-model/setting-prediction/setting-prediction.scss"],"names":[],"mappings":"AAAA;EAEQ,UAAU,EAAA","sourcesContent":[".setting-prediction {\n    .ant-drawer-body{\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
