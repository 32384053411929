import { FunctionComponent, useEffect, useMemo, useState } from "react";
import "./detail.scss";
import {
  Badge,
  Button,
  Col,
  Divider,
  Form,
  Layout,
  message,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from "antd";
import PageHeader from "../../../../components/page-header/PageHeader";
import { useLocation, useParams } from "react-router-dom";

import { DetailContent } from "./component/detail-content/DetailContent";

import { useSurveyDetail, useSurveyInfo } from "../../../../stores/stores";
import { get, isEmpty, set } from "lodash";
import PreviewSurvey from "./component/preview/PreviewSurvey";
import DetailConfirm from "./component/detail-confirm/DetailConfirm";
import DetailComment from "./component/detail-comment/DetailComment";
import DetailSection from "./component/detail-section/DetailSection";
import { useForm } from "antd/es/form/Form";
import {
  getSurveyDetailAll,
  getSurveyInfo,
  getSurveyObjectsFilterById,
} from "../../../../service/survey-managment/list-survey";
import { LoadingOutlined } from "@ant-design/icons";
import { ListSurveySection } from "../../../../types/survey-managment/survey";
import { initialValueListSection } from "./component/constant/data";
import { surveyStatus } from "./constants/data";
import {
  getdataIndustryGroup,
  getIndustry,
  getUniversity,
} from "../../../../service/survey-managment/common";
import { getCurrentPageFromLink } from "../../../../utils/getPageFromLink";
import { getMoocSurveyObjectsFilterById } from "../../../../service/survey-managment/mooc-survey-objects-filter/mooc-survey-objects-filter-api";
import SurveyRespond from "./component/survey-respond/SurveyRespond";
import SettingSurvey from "./component/setting-survey";
import ApproveSurvey from "../../survey/components/approve-survey/approveSurvey";
import { locale } from "moment";
import CustomCard from "./component/custom-card/CustomCard";

const { Content } = Layout;
const imgPath = "/images/";

interface DetailD {
  surveyName?: string;
  surveyStatusName?: string;
  industry?: string;
  industryGroup?: string;
  courseTypeName?: string;
  universityHost?: string;
  collaborator?: string;
}

const DetailSurvey: FunctionComponent = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSurveyRespond, setIsLoadingSurveyRespond] = useState(false);
  const [dataForm, setDataForm] = useState<any>({});
  const [form] = useForm();
  const [status, setStatus] = useState();
  const [isOpenModalApprove, setIsOpenModalApprove] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState("1");
  const [surveyRespondKey, setSurveyRespondKey] = useState(0);
  const [loadSetting, setLoadSetting] = useState(false);
  const [keyCurrent, setKeyCurrent] = useState("1");
  const [initialValues, setInitialValues] = useState({
    isHasCertification: true,
    isScheduleByTeacher: true,
    isCostFree: true,
  });
  const getSurveyDetailInfo = () => {
    try {
      getSurveyDetailAll(get(params, "id", "")).then((res) => {
        const data = res.data.data;
        setSurveyInfo(data);
        if (
          data?.moocSurveySection?.length &&
          data?.moocSurveySection?.length > 0
        ) {
          setListSurveySection(sortDataFunction(data?.moocSurveySection));
          setListSurveySectionInput(sortDataFunction(data?.moocSurveySection));
          setDataSection(sortDataFunction(data?.moocSurveySection));
        }
        if (data?.moocSurveySection?.length === 0) {
          setListSurveySection(initialValueListSection);
          setListSurveySectionInput(initialValueListSection);
          setDataSection(initialValueListSection);
        }
        setIsLoading(false);
      });
    } catch (error) {
      message.error("Có lỗi chưa xác định");
    }

    // setListSurveySection(sortDataFunction(listSurveySection3));
    // setDataSection(sortDataFunction(listSurveySection3));
    // setIsLoading(false);
  };
  useEffect(() => {
    getSurveyDetailInfo();
    getDetailMoocSurveyData(params.id);
  }, []);
  const { surveyInfo, setSurveyInfo } = useSurveyInfo((state) => {
    return {
      surveyInfo: state?.surveyInfo,
      setSurveyInfo: state?.setSurveyInfo,
    };
  });

  const {
    listSurveySection,
    setListSurveySection,
    updateSurveySection,
    setListSurveySectionInput,
    saveDraftSurveySection,
  } = useSurveyDetail((state) => {
    return {
      listSurveySection: state?.listSurveySections,
      setListSurveySection: state?.setListSurveySections,
      updateSurveySection: state?.updateSurveySection,
      setListSurveySectionInput: state?.setListSurveySectionsInput,
      saveDraftSurveySection: state?.saveDraftSurveySections,
    };
  });

  const onFinish = (name: string, { forms, values }: any) => {
    setDataForm(values);
  };

  const [dataSection, setDataSection] = useState(listSurveySection);

  const filterDeleteData = (data: ListSurveySection[]) => {
    console.log("Hello data", data);
    const filteredData = data
      .map((section) => {
        const filteredQuestions = section.moocSurveyQuestion
          ?.map((question) => {
            const filteredAnswers = question.moocSurveyQuestionAnswer?.filter(
              (answer) => !answer.isDeleted
            );
            return {
              ...question,
              moocSurveyQuestionAnswer: filteredAnswers,
            };
          })
          .filter((question) => !question.isDeleted);

        return {
          ...section,
          sectionName:
            section?.orderNumber != 1 ||
            (section?.orderNumber == 1 && section?.sectionName != null)
              ? section?.sectionName
              : "",
          moocSurveyQuestion: filteredQuestions,
        };
      })
      .filter((section) => !section.isDeleted);

    return filteredData;
  };

  const sortDataFunction = (data: ListSurveySection[]) => {
    const filteredData = data.filter((item) => !item.isDeleted);

    let sortedData = filteredData.sort((a, b) => {
      if (a.orderNumber && b.orderNumber) {
        return a.orderNumber - b.orderNumber;
      }
      // Xử lý trường hợp orderNumber có thể undefined
      // Ví dụ: Trả về 0, không thay đổi thứ tự
      return 0;
    });

    // Sắp xếp moocSurveyQuestion của mỗi section
    sortedData.forEach((section) => {
      if (section.moocSurveyQuestion) {
        section.moocSurveyQuestion.sort((a, b) => {
          if (a.orderNumber !== undefined && b.orderNumber !== undefined) {
            return a.orderNumber - b.orderNumber;
          }
          return 0;
        });

        // Sắp xếp moocSurveyQuestionAnswer của mỗi câu hỏi
        section.moocSurveyQuestion.forEach((question) => {
          if (question.moocSurveyQuestionAnswer) {
            question.moocSurveyQuestionAnswer.sort((a, b) => {
              if (
                a.answerOrderNumber !== undefined &&
                b.answerOrderNumber !== undefined
              ) {
                return a.answerOrderNumber - b.answerOrderNumber;
              }
              return 0;
            });
          }
        });
      }
    });

    return sortedData;
  };
  /// ---hoàng--- start
  const [dataTheme, setDataTheme] = useState<string>("1");
  const [detailData, setDetailData] = useState<any>({}); //DetailTypeMoocSurvey
  const [listInfo, setListInfo] = useState<any[]>();
  const [dataOptions, setDataOptions] = useState({
    dataIndustry: [],
    dataIndustryGroup: [],
    dataUniversity: [],
    dataFilter: {},
  });
  const renderSwitch = (value: string) => {
    switch (value) {
      case "surveyName":
        return <div>Tên đợt khảo sát</div>;
      case "surveyStatusName":
        return <div>Trạng thái</div>;
      case "industry":
        return <div>Khoa/nhóm ngành</div>;
      case "industryGroup":
        return <div>Chuyên ngành</div>;
      case "courseTypeName":
        return <div>Loại khóa học</div>;
      case "universityHost":
        return <div>Trường chủ trì</div>;
      case "collaborator":
        return <div>Trường hợp tác</div>;
      default:
        return "";
    }
  };

  useEffect(() => {
    const convertObjectData = Object.entries(detailData);
    delete convertObjectData[0];
    const listInfo = convertObjectData.map((item: any, index) => (
      <div className="list-item" key={index}>
        <Typography.Title level={5} className="list-item--title">
          {renderSwitch(item[0])}
        </Typography.Title>
        <div className="list-item--description">
          <Typography.Text className="content">
            {item[0] === "surveyStatusName" ? (
              <Badge
                key={surveyStatus.filter((x) => x.value == status)[0].color}
                color={surveyStatus.filter((x) => x.value == status)[0].color}
              />
            ) : null}{" "}
            {item[1]}
          </Typography.Text>
        </div>
      </div>
    ));
    setListInfo(listInfo);
  }, [detailData]);

  const getDetailMoocSurveyData = async (id: any) => {
    try {
      await getSurveyInfo(id)
        .then((res) => {
          const data = res.data.data;
          setDataTheme(data?.themeType.toString());
          setStatus(res.data.data.surveyStatus);
          setDetailData({
            id: data?.id || "",
            surveyName: data?.surveyName || "",
            surveyStatusName: data?.surveyStatusName || "",
            industry: data?.industry || "",
            industryGroup: data?.industryGroup || "",
            courseTypeName: data?.courseTypeName || "",
            universityHost: data?.universityHost || "",
            collaborator: data?.collaborator || "",
          });
        })
        .catch((err) => {
          console.log(err);
          message.error("Có lỗi chưa xác định");
        });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  const updateDataSection = (newDataSection: ListSurveySection[]) => {
    setListSurveySection(sortDataFunction(newDataSection));
    setDataSection(sortDataFunction(newDataSection));
  };

  const [pageNumber, setPageNumber] = useState<number>(1);
  useEffect(() => {
    const pageLink = getCurrentPageFromLink(location.search);
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink));
    } else {
      const fetchDataOptions = async () => {
        const response = await Promise.all([
          getUniversity(),
          getIndustry(),
          getdataIndustryGroup(),
          getSurveyObjectsFilterById(Number(params.id)),
        ]);
        const dataUniversity = response[0].data.data;
        const dataIndustry = response[1].data.data;
        const dataIndustryGroup = response[2].data.data;
        const datafilterOject = response[3].data.data.data;
        if (datafilterOject.filters.length > 0) {
          for (let i = 0; i < datafilterOject.filters.length; i++) {
            if (datafilterOject.filters[i].filterCondition == 2) {
              if (datafilterOject.filters[i].filterCriteria == "u.name") {
                datafilterOject.filters[i].filterCriteria = "university_id";
              }
              if (datafilterOject.filters[i].filterCriteria == "i.name") {
                datafilterOject.filters[i].filterCriteria = "industry_id";
              }
              if (datafilterOject.filters[i].filterCriteria == "g.name") {
                datafilterOject.filters[i].filterCriteria = "industry_group_id";
              }
            }
          }
        }
        setDataOptions({
          ...dataOptions,
          dataIndustry: dataIndustry,
          dataUniversity: dataUniversity,
          dataIndustryGroup: dataIndustryGroup,
          dataFilter: datafilterOject,
        });
      };
      fetchDataOptions();
    }
  }, [loadSetting]);
  const onchangeTheme = (value: string) => {
    setDataTheme(value);
  };
  const handleTabChange = (key: any) => {
    setKeyCurrent(key);
    setActiveKey(key);
    if (key === "3") {
      // Thay đổi key khi tab "Xem trước" được chọn
      setSurveyRespondKey((prevKey) => prevKey + 1);
    }
  };

  const location = useLocation();
  const state = location.state || null;
  const mode = state && state.mode ? state.mode : null;

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chung",
      children: (
        <div className="previewContent">
          <DetailContent isReadOnly={mode === "view" ? true : false}>
            <CustomCard
              title={
                <div className="card-heading--info">
                  <Typography.Title level={5}>
                    Thông tin khảo sát
                  </Typography.Title>
                  <Typography.Text className="sub-title">
                    Thông tin chung về đợt khảo sát
                  </Typography.Text>
                </div>
              }
              className="card-info--wrapper"
            >
              <div className="list-wrapper">{listInfo}</div>
            </CustomCard>
          </DetailContent>
        </div>
      ),
    },
    {
      key: "2",
      label: "Nội dung khảo sát",
      children: (
        <div className="previewContent">
          <DetailContent isReadOnly={mode === "view" ? true : false}>
            <DetailSection
              dataSection={filterDeleteData(dataSection)}
              setDataSection={setDataSection}
              setUpdateDataSection={updateDataSection}
              surveyInfo={surveyInfo}
            />
          </DetailContent>
        </div>
      ),
    },
    {
      key: "3",
      label: "Xem trước",
      children: (
        <div className="previewContent">
          <DetailContent isReadOnly={mode === "view" ? true : false}>
            {!isLoadingSurveyRespond ? (
              <SurveyRespond
                dataTheme={dataTheme}
                handeChangeTheme={onchangeTheme}
                key={surveyRespondKey}
                mode={mode === "view" ? true : false}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100vw",
                  height: "70vh",
                }}
              >
                <LoadingOutlined style={{ fontSize: "32px" }} />
              </div>
            )}
          </DetailContent>
        </div>
      ),
    },
    {
      key: "4",
      label: "Cài đặt",
      children: (
        <div className="previewContent">
          <DetailContent>
            <SettingSurvey
              dataOptions={dataOptions}
              isVisibleButton={mode === "view" ? true : false}
            />
          </DetailContent>
        </div>
      ),
    },
    {
      key: "5",
      label: "Kết quả duyệt",
      children: <DetailConfirm />,
    },
    // {
    //   key: "6",
    //   label: "Phản hồi",
    //   children: (
    //     <DetailContent>
    //       <DetailComment />
    //     </DetailContent>
    //   ),
    // },
  ];

  const listBreadcrumb = [
    {
      title: "Khảo sát nhu cầu đào tạo",
    },
    {
      title: (
        <a href={`${surveyInfo?.id}`}>
          {surveyInfo.surveyName || "Tên khảo sát"}
        </a>
      ),
      // title: <a href={`/course-construction/${routesConfig.courseBrowse}/${routesConfig.contentBrowse}`}>Duyệt khoá học</a>,
    },
    {
      title:
        location.state && location.state.name
          ? location.state.name
          : "Nội dung khảo sát",
    },
  ];

  const { id } = useParams();

  const handleSaveDraftSurveyTab = async (key: any) => {
    try {
      if (key == 3) {
        setIsLoadingSurveyRespond(true);
        const res = await saveDraftSurveySection(dataSection, Number(id));
        setIsLoadingSurveyRespond(false);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(`Gặp vấn đề trong quá trình lưu, vui lòng thao tác lại`);
    }
  };
  const handleSaveDraftSurvey = async () => {
    try {
      setLoadSetting(() => !loadSetting);
      setIsLoading(true);
      const res = await saveDraftSurveySection(dataSection, Number(id));
      message.success(`Lưu thành công`);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(`Gặp vấn đề trong quá trình lưu, vui lòng thao tác lại`);
    }
  };

  const openApproveModal = async () => {
    try {
      setIsLoading(true);
      const res = await saveDraftSurveySection(dataSection, Number(id));
      setIsOpenModalApprove(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(`Gặp vấn đề trong quá trình lưu, vui lòng thao tác lại`);
    }
  };

  return (
    <>
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={surveyInfo?.surveyName}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        {!isLoading && (
          <div className="tabs-heading wrapper-survey-managment">
            <Tabs
              defaultActiveKey={
                location &&
                location.state &&
                location.state.mode &&
                location.state.mode == "history"
                  ? "5"
                  : keyCurrent
              }
              items={items}
              onChange={(key: any) => {
                handleTabChange(key);
                handleSaveDraftSurveyTab(key);
              }}
              tabBarExtraContent={
                <Row>
                  <Space>
                    <Col>
                      <Button
                        type="default"
                        disabled={
                          mode === "view"
                            ? true
                            : surveyInfo.surveyStatus
                            ? surveyInfo.surveyStatus !== 1
                              ? true
                              : false
                            : true
                        }
                        onClick={handleSaveDraftSurvey}
                      >
                        Lưu bản nháp
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        type="primary"
                        disabled={
                          mode === "view"
                            ? true
                            : surveyInfo.surveyStatus
                            ? surveyInfo.surveyStatus !== 1
                              ? true
                              : false
                            : true
                        }
                        onClick={() => openApproveModal()}
                      >
                        Gửi duyệt
                      </Button>
                    </Col>
                  </Space>
                </Row>
              }
            />
          </div>
        )}

        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <LoadingOutlined style={{ fontSize: "32px" }} />
          </div>
        )}
        <ApproveSurvey
          open={isOpenModalApprove}
          handleCancel={() => {
            setIsOpenModalApprove(false);
          }}
          browseData={detailData}
          isNavigation={true}
        />
      </Content>
    </>
  );
};

export default DetailSurvey;
