import {Col, Empty, Row, Spin, TableColumnsType} from "antd";
import TableWithTotals from "../../../../../../../components/table-with-totals/TableWithTotals";
import {
  exportCourseAssignTeacher,
  exportCoursesByFormat,
  exportMultimaterialsByType,
  exportNumberSCORMxAPI,
  exportReferenceSourceByType,
  exportSequenceByAvailableStatus,
  exportSequenceByDepartment,
  exportTestByType,
  exportUnitByCourse,
} from "../../../../../../../service/statistical-report";
import {staticReportStore} from "../../../../../../../stores/stores";
import {BarStackedChartProps, PieChart,} from "../../../../../../../types/static-report";
import StatisticalReportCard from "../../../StatisticalReportCard";
import {createTableData} from "../../tableConvertData";
import {handleExportReport} from "../../../../../../../utils/staticReport";
import dayjs from "dayjs";

type Props = {
  loading: Record<string, boolean>;
  coursesByFormatList: PieChart[];
  sequenceByAvailableStatus: PieChart[];
  sequenceByDepartment: PieChart[];
  scormAndXAPI: PieChart[];
  multiMaterialsByType: PieChart[];
  unitByCourse: BarStackedChartProps[];
  testByType: PieChart[];
  referenceSourceByType: PieChart[];
  courseAssignTeacher: BarStackedChartProps[];
};

const ResourcesTableTab = (props: Props) => {
  const {
    loading,
    coursesByFormatList,
    sequenceByAvailableStatus,
    sequenceByDepartment,
    scormAndXAPI,
    multiMaterialsByType,
    testByType,
    referenceSourceByType,
    unitByCourse,
    courseAssignTeacher,
  } = props;
  const {filterValues} = staticReportStore();

  const columns: TableColumnsType<any> = [
    {
      title: "Hình thức",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {title: "Số lượng", dataIndex: "count", key: "count", align: "right"},
  ];

  const sequenceByDepartmentColumns: TableColumnsType<any> = [
    {
      title: "Khoa",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng bài giảng",
      dataIndex: "count",
      key: "count",
      align: "right",
    },
  ];

  const statisticsLearningResourcesColumns: TableColumnsType<any> = [
    {
      title: "Thông tin",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {title: "Số lượng", dataIndex: "count", key: "count"},
  ];

  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
    universityIds: filterValues?.universityIds,
  };

  return (
    <div>
      <div className="chart-title">Thống kê số lượng khoá học</div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khoá học theo phân công"
            content={
              <Spin spinning={loading.courseAssignTeacher}>
                {courseAssignTeacher.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(courseAssignTeacher).dataSource}
                    columns={[
                      {
                        title: "Ngày tải lên khoá học",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(courseAssignTeacher).columns,
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              courseAssignTeacher.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportCourseAssignTeacher,
                        "Số lượng khoá học theo phân công",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportCourseAssignTeacher,
                        "Số lượng khoá học theo phân công",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khoá học theo hình thức xuất bản"
            content={
              <Spin spinning={loading.coursesByFormatList}>
                {coursesByFormatList.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={coursesByFormatList}
                    columns={columns}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              courseAssignTeacher.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportCoursesByFormat,
                        "Số lượng khoá học theo hình thức xuất bản",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportCoursesByFormat,
                        "Số lượng khoá học theo hình thức xuất bản",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
      </Row>

      <div className="chart-title">Thống kê số lượng bài giảng</div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng bài giảng theo khoa"
            content={
              <Spin spinning={loading.sequenceByDepartment}>
                {sequenceByDepartment.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={sequenceByDepartment}
                    columns={sequenceByDepartmentColumns}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              sequenceByDepartment.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportSequenceByDepartment,
                        "Số lượng bài giảng theo khoa",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportSequenceByDepartment,
                        "Số lượng bài giảng theo khoa",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng bài giảng theo trạng thái xuất bản"
            content={
              <Spin spinning={loading.sequenceByAvailableStatus}>
                {sequenceByAvailableStatus.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={sequenceByAvailableStatus}
                    columns={columns}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              sequenceByAvailableStatus.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportSequenceByAvailableStatus,
                        "Số lượng bài giảng theo trạng thái xuất bản",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportSequenceByAvailableStatus,
                        "Số lượng bài giảng theo trạng thái xuất bản",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
      </Row>

      <div className="chart-title">Thống kê số lượng học liệu</div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col xl={{span: 6}} lg={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng bài kiểm tra theo loại"
            content={
              <Spin spinning={loading.testByType}>
                {testByType.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={testByType}
                    columns={statisticsLearningResourcesColumns}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              testByType.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportTestByType,
                        "Số lượng bài kiểm tra theo loại",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportTestByType,
                        "Số lượng bài kiểm tra theo loại",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
        <Col xl={{span: 6}} lg={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng tài liệu tham khảo theo loại"
            content={
              <Spin spinning={loading.referenceSourceByType}>
                {referenceSourceByType.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={referenceSourceByType}
                    columns={statisticsLearningResourcesColumns}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              referenceSourceByType.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportReferenceSourceByType,
                        "Số lượng tài liệu tham khảo theo loại",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportReferenceSourceByType,
                        "Số lượng tài liệu tham khảo theo loại",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
        <Col xl={{span: 6}} lg={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng Scorm & xAPI"
            content={
              <Spin spinning={loading.scormAndXAPI}>
                {scormAndXAPI.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={scormAndXAPI}
                    columns={statisticsLearningResourcesColumns}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              scormAndXAPI.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportNumberSCORMxAPI,
                        "Số lượng Scorm & xAPI",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportNumberSCORMxAPI,
                        "Số lượng Scorm & xAPI",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
        <Col xl={{span: 6}} lg={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng học liệu đa phương tiện theo loại"
            content={
              <Spin spinning={loading.multiMaterialsByType}>
                {multiMaterialsByType.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={multiMaterialsByType}
                    columns={statisticsLearningResourcesColumns}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              multiMaterialsByType.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportMultimaterialsByType,
                        "Số lượng học liệu đa phương tiện theo loại",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportMultimaterialsByType,
                        "Số lượng học liệu đa phương tiện theo loại",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
      </Row>

      <div className="mt-2">
        <StatisticalReportCard
          chartName="Số lượng học liệu theo khoá học"
          content={
            <Spin spinning={loading.unitByCourse}>
              {unitByCourse.length > 0 ? (
                <TableWithTotals
                  totalLabel={"Tổng"}
                  data={createTableData(unitByCourse).dataSource}
                  columns={[
                    {
                      title: "Tên khoá học",
                      dataIndex: "name",
                      key: "name",
                    },
                    ...createTableData(unitByCourse).columns,
                    {
                      title: "Tổng",
                      dataIndex: "Tổng",
                      key: "Tổng",

                    },
                  ]}
                  keyColumn="criteria"
                  height={275}
                />
              ) : (
                <Empty description="Không có dữ liệu"/>
              )}
            </Spin>
          }
          exportOptions={
            unitByCourse.length > 0
              ? [
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportUnitByCourse,
                      "Số lượng học liệu theo khoá học",
                      "excel",
                      exportPayload
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportUnitByCourse,
                      "Số lượng học liệu theo khoá học",
                      "pdf",
                      exportPayload
                    ),
                },
              ]
              : []
          }
        />
      </div>
    </div>
  );
};

export default ResourcesTableTab;
