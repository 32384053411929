// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.option-time-modal .ant-modal-content {
  padding: 0px; }

.option-time-modal .ant-modal-header {
  padding: 16px 24px 0px 24px; }
  .option-time-modal .ant-modal-header .modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    opacity: 0.85; }

.option-time-modal .ant-modal-body .content {
  padding: 0px 24px; }
  .option-time-modal .ant-modal-body .content .item {
    padding: 8px 24px;
    display: flex;
    align-items: center; }
    .option-time-modal .ant-modal-body .content .item .label {
      min-width: 120px; }
    .option-time-modal .ant-modal-body .content .item .ant-form-item {
      margin-bottom: 0px;
      width: 100%; }

.option-time-modal .option-time-modal-footer {
  padding: 10px 24px;
  display: flex;
  gap: 8px; }
`, "",{"version":3,"sources":["webpack://./src/pages/statistical-report/for-instructors/components/modal/OptionTimeModal.scss"],"names":[],"mappings":"AAAA;EAEI,YAAY,EAAA;;AAFhB;EAMI,2BAA2B,EAAA;EAN/B;IASM,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,aAAa,EAAA;;AAbnB;EAmBM,iBAAiB,EAAA;EAnBvB;IAsBQ,iBAAiB;IACjB,aAAa;IACb,mBAAmB,EAAA;IAxB3B;MA2BU,gBAAgB,EAAA;IA3B1B;MA+BU,kBAAkB;MAClB,WAAW,EAAA;;AAhCrB;EAuCI,kBAAkB;EAClB,aAAa;EACb,QAAQ,EAAA","sourcesContent":[".option-time-modal {\n  .ant-modal-content {\n    padding: 0px;\n  }\n\n  .ant-modal-header {\n    padding: 16px 24px 0px 24px;\n\n    .modal-title {\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 24px;\n      text-align: left;\n      opacity: 0.85;\n    }\n  }\n\n  .ant-modal-body {\n    .content {\n      padding: 0px 24px;\n\n      .item {\n        padding: 8px 24px;\n        display: flex;\n        align-items: center;\n\n        .label {\n          min-width: 120px;\n        }\n\n        .ant-form-item {\n          margin-bottom: 0px;\n          width: 100%;\n        }\n      }\n    }\n  }\n\n  .option-time-modal-footer {\n    padding: 10px 24px;\n    display: flex;\n    gap: 8px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
