import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Radio,
  RadioChangeEvent,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import {
  createSettingIdentity,
  getSettingIdentity,
  updateSettingIdentity,
} from "../../../../service/settting-identity";
import { IdentityMethod } from "../../../../constants/setting-identity";
import { isEmpty } from "lodash";
const { Title, Text } = Typography;

interface IProps {
  displayRoleSettingIdentify: boolean
}
const SettingIdentifyForm = ({displayRoleSettingIdentify}:IProps) => {
  const [openSave, setOpenSave] = useState(false);
  const [idIdentity, setIdIdentity] = useState("");
  const [paramIdentity, setParamIdentity] = useState<any>({
    facePayment: true,
    exam: true,
  });

  const [messageApi, contextHolder] = message.useMessage();

  const fetchSettingIdentity = async () => {
    try {
      const response = await getSettingIdentity();
      const { data } = response.data;
      if (isEmpty(data)) {
        await createSettingIdentity({
          payment: IdentityMethod.FACE,
          exam: IdentityMethod.FACE,
          register_course: IdentityMethod.NONE,
          attendance: IdentityMethod.FACE,
          login: IdentityMethod.BOTH,
        });
      } else {
        setIdIdentity(data?.id);
        setParamIdentity({
          facePayment: data?.payment === IdentityMethod.FACE,
          exam: data?.exam === IdentityMethod.FACE,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchSettingIdentity();
  }, []);

  const onChangeSelect = (e: any) => {
    setParamIdentity({
      ...paramIdentity,
      [e.target.name]: e.target.checked,
    });
    setOpenSave(true)
  };

  const handleSave = async () => {
    const param = {
      payment: paramIdentity.facePayment ? "face" : null,
      exam: paramIdentity.exam ? "face" : null,
      // register_course: handleParam(
      //   paramIdentity.faceRegisterCourse,
      //   paramIdentity.passwordRegisterCourse
      // ),
      // attendance: handleParam(
      //   paramIdentity.faceAttendance,
      //   paramIdentity.passwordAttendance
      // ),
      // login: IdentityMethod.BOTH,
    };
    try {
      const response = await updateSettingIdentity(idIdentity, param);
      if (response?.status === 200) {
        messageApi.open({
          type: "success",
          content: "Trường hợp cần định danh lưu thành công",
        });
        setOpenSave(false);
      }
    } catch (err) {
      console.error(err);
      messageApi.open({
        type: "error",
        content: "Lỗi thay đổi",
      });
    }
  };
  return (
    <Card className="w-full lg:w-2/3 m-auto mt-12">
      {contextHolder}
      <div>
        <div className="border-b pb-3">
          <Title level={4}>Trường hợp cần định danh</Title>
          <Text className="text-[#00000073]">
            Chọn giao diện cài đặt các trường hợp cần định danh bằng khuôn mặt
          </Text>
        </div>
        <div className="border-b p-4 bg-[#0000000f] flex mt-4">
          <Text className="w-1/2 border-r">Giao diện cài đặt</Text>
          <Text className="w-1/5 border-r text-center">Khuôn mặt</Text>
        </div>
        <div className="border-b p-4 flex">
          <Text className="w-1/2">Xác thực thanh toán</Text>
          <Flex className="w-1/2" align="center">
            <Checkbox
              name="facePayment"
              className="!w-2/5 flex justify-center m-0"
              checked={paramIdentity?.facePayment}
              onChange={onChangeSelect}
              disabled={!displayRoleSettingIdentify}
            />
          </Flex>
        </div>
        <div className="border-b p-4 flex">
          <Text className="w-1/2">Thi cử</Text>
          <Flex className="w-1/2" align="center">
            <Checkbox
              name="exam"
              className="!w-2/5 flex justify-center m-0"
              checked={paramIdentity?.exam}
              onChange={onChangeSelect}
              disabled={!displayRoleSettingIdentify}
            />
            <Tooltip
              placement="rightTop"
              title={
                "Khuyến nghị chọn khuôn mặt để đảm bảo chất lượng giám sát thi cử"
              }
              className="w-1/5 flex justify-center"
            >
              <InfoCircleOutlined style={{ color: "#00000073" }} />
            </Tooltip>
          </Flex>
        </div>
      </div>
      {openSave && (
        <Flex gap="small" justify="flex-end" className="pt-4">
          <Button type="primary" onClick={handleSave} disabled={!displayRoleSettingIdentify}>
            Lưu
          </Button>
        </Flex>
      )}
    </Card>
  );
};

export default SettingIdentifyForm;
