import React from "react";
import { Checkbox, Form } from "antd";
import { JSON_SUB_TYPE, QuestionBeProps, QuestionProps, QuestionPropsV2 } from "../../../../../types/course";
import { QuestionTypeEnum } from "../../../../../constants";
import { convertNumberingTypeToText } from "../../../../../utils/format";
import EmptyComponent from "../../../../../components/empty";


function ChooseMultiText({ data, initialData, disabled }: QuestionPropsV2) {
  return (
    <div>
      {data?.questions && data?.questions?.length > 0 ? (
        data?.questions?.map((quiz: QuestionBeProps, index) => (
          <Form.Item
            key={quiz?.uuid + index}
            className="mb-12"
            name={`chooseMultiText-${data?.id}-${QuestionTypeEnum.CHOOSE_MULTI_TEXT}-${JSON_SUB_TYPE.ChoosingRequest}-${index}`}
            valuePropName="checked"
            initialValue={initialData?.answer?.find((item: string) => item === quiz?.uuid)}
          >
            <Checkbox disabled={disabled}>
              <span className="font-size-16 line-height-24 font-weight-5">
                {convertNumberingTypeToText(
                  index + 1,
                  Number(JSON.parse(data?.settingGeneral).numberingType)
                )}
                . {quiz?.content}
              </span>
            </Checkbox>
          </Form.Item>
        ))
      ) : (
        <div className="center">
          <EmptyComponent description="Không có nội dung" />
        </div>
      )}
    </div>
  );
}

export default ChooseMultiText;
