import { ApartmentOutlined } from "@ant-design/icons";
import { Layout, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../components/page-header-common/PageHeaderCommon";
import { getSettingCourseById, getSettingExamSupervisionById } from "../../service/exam-supervision";
import RoleCheckIn from "./permission-checkin";
import PermissionExam from "./permission-exam";
import { Permission } from "../../layout/sidebar/permission.enum";
import { CheckRole, getPermissions } from "../../utils/checkRole";
import "./style.scss"

const imgPath = "/images/";

const { Content } = Layout;

const SettingPermissionCourseDetail = () => {
  const params = useParams();

  const [title, setTitle] = useState("");
  const [displayRoleEdit, setDisplayRoleEdit] = useState(false);
  const [displayRoleAutoScore, setDisplayRoleAutoScore] = useState(false);
  const [listPermission, setListPermission] = useState([]);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleEdit = [Permission.TabAttendancePermissionSettingUpdate];
    const roleAutoScore = [Permission.AutomatedGradingPermissionsUpdate];

    const checkRoleEdit = CheckRole(roleEdit, listPermission);
    const checkRoleAutoScore = CheckRole(roleAutoScore, listPermission);

    if (checkRoleEdit.includes(true)) {
      setDisplayRoleEdit(true);
    }
    if (checkRoleAutoScore.includes(true)) {
      setDisplayRoleAutoScore(true);
    }
  }, [listPermission]);

  const fetchDataExamSupervision = async () => {
    try {
      const res = await getSettingCourseById(params?.id as string);
      const { data } = res.data;
      setTitle(data?.name);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchDataExamSupervision();
  }, []);
  const navigate = useNavigate();
  const listBreadcrumb = [
    {
      title: "Cài đặt nâng cao",
    },
    {
      title: "Phân quyền theo khóa học",
    },
    {
      title: title,
    },
  ];
  const items: TabsProps["items"] = [
    {
      key: "1",
      icon: <ApartmentOutlined />,
      label: "Cài đặt phân quyền điểm danh",
      children: <RoleCheckIn displayRoleEdit={displayRoleEdit}/>,
    },
    ...(displayRoleAutoScore ? [{
      key: "2",
      icon: <ApartmentOutlined />,
      label: "Cài đặt phân quyền bài thi",
      children: <PermissionExam />,
    },] : []),
  ];

  return (
    <Content>
      <PageHeaderCommon
        title={title}
        listBreadcrumb={listBreadcrumb}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() => navigate(-1)}
      />
      <div className="tabs-heading">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </Content>
  );
};

export default SettingPermissionCourseDetail;
