import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, DatePicker, Flex, Form, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { FormInstance } from "antd/lib";
import { FunctionComponent } from "react";
import SelectUiCard from "../../../../../../../components/select-ui";
import "./filter-group.scss";

type FormFilterProps = {
  showFilter?: boolean;
  clearFilter: (form: FormInstance) => void;
  onFinish?: any;
  optionSelectSchool: any;
  optionSelectAddress: any;
  setDataFilter: any;
  dataFilter: any;
  optionSelectYear: any;
  optionSelectFavorite: any;
};

const FormFilter: FunctionComponent<FormFilterProps> = ({
  showFilter,
  clearFilter,
  onFinish,
  optionSelectSchool,
  optionSelectAddress,
  setDataFilter,
  dataFilter,
  optionSelectYear,
  optionSelectFavorite,
}) => {
  const [form] = useForm();
  const onChangeShareReport = (val: string) => {
    setDataFilter(val);
  };

  const onChangeDate = (value: any, name: string) => {
    setDataFilter({ ...dataFilter, [name]: value });
  };
  return (
    <>
      <div className={`wrapper-filter ${showFilter ? "open" : ""} !pl-0`}>
        <Form
          name="formFilterMinistries"
          onFinish={onFinish}
          form={form}
          className="flex-wrap flex"
        >
          <Flex justify="end" className="w-full lg:w-4/5 pr-3">
            <SelectUiCard
              className="font-medium w-full lg:w-1/4 px-2"
              name="year"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectYear}
              mode="multiple"
              placeholder="Năm sinh"
              allowClear
              onChange={(val) => onChangeShareReport(val)}
            />
            <SelectUiCard
              className="font-medium w-full lg:w-1/4 px-2"
              name="school"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectSchool}
              mode="multiple"
              placeholder="Trường"
              allowClear
              onChange={(val) => onChangeShareReport(val)}
            />
            <SelectUiCard
              className="font-medium w-full lg:w-1/4 pr-2"
              name="address"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectAddress}
              mode="multiple"
              placeholder="Địa chỉ"
              allowClear
              onChange={(val) => onChangeShareReport(val)}
            />
            <SelectUiCard
              className="font-medium w-full lg:w-1/4 px-2"
              name="hobby"
              label=""
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().includes(input.toLowerCase())
              }
              options={optionSelectFavorite}
              mode="multiple"
              placeholder="Sở thích"
              allowClear
              onChange={(val) => onChangeShareReport(val)}
            />
          </Flex>
          <Flex className="w-full lg:w-1/5">
            <Button
              className="btn btn-primary filter-btn mr-2 lg:mr-0"
              htmlType="submit"
            >
              <FilterOutlined />
              <Typography.Text>Lọc</Typography.Text>
            </Button>
            <Button
              className="btn btn-outlined clear-filter-btn"
              onClick={() => clearFilter(form)}
            >
              <DeleteOutlined />
              <Typography.Text>Xóa bộ lọc</Typography.Text>
            </Button>
          </Flex>
        </Form>
      </div>
    </>
  );
};

export default FormFilter;
