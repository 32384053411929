import { Button, Collapse, Drawer, Form, Radio, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import "./index.scss"
import { isEmpty, size } from 'lodash'
// @ts-ignore
import ImgsViewer from "react-images-viewer"
import { getDetailStudentAccount } from '../../../service/list-account-student/listAccountStudent'
import { formatOriginTime } from '../../../utils/converDate'

type Props = {
  open: boolean
  onClose: () => void
  detailData: any
}

export default function DetailStudentsAccountDrawer(props: Props) {
  const { open, onClose, detailData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currImg, setCurrImg] = useState<number>(0)
  const [dataDetail, setDataDetail] = useState<any>({})

  const getListDataDetail = async () => {
    await getDetailStudentAccount(detailData?.id).then((res) => {
      console.log('res123', res)
      if (res.status === 200) {
        setDataDetail(res.data?.data)
      }
    })
  }

  useEffect(() => {
    if (open) {
      getListDataDetail();
    }
  }, [open]);

  const renderStatus = (value: number) => {
    switch (value) {
      case 0:
        return <p>Chưa xác thực</p>
        break;
      case 1:
        return <p>Chờ duyệt</p>
        break;
      case 2:
        return <p>Hợp lệ</p>
        break;
      case 3:
        return <p>Không hợp lệ</p>
        break;
      default:
        break;
    }
  }

  return (
    <Drawer
      title={"Xem chi tiết tài khoản"}
      open={open}
      onClose={onClose}
      className='detail-students-account-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
      extra={
        <Space>
          <Button onClick={onClose}>Đóng</Button>
        </Space>
      }
    >
      <Form
        className="detail-students-account-admin"
        layout={"vertical"}
        form={form}
        name="form-detail-students-account-admin"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel
              header="Thông tin tài khoản"
              key="1"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex'><Typography.Title level={5} className='min-w-[210px]'>Email đăng nhập</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Họ và tên</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.name}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Ngày tạo</Typography.Title>
                  <p className='mt-0.5 ml-2'>{dataDetail?.dateJoined ? formatOriginTime(dataDetail?.dateJoined) : null}</p>
                </span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Trường đại học đăng ký</Typography.Title>
                  <p className='mt-0.5 ml-2'>{dataDetail?.unitName}</p>
                </span>
              </div>
            </Panel>

            <Panel
              header="Thông tin xác thực eKYC"
              key="2"
              className="setting-chapter-title">
              <div className='m-5'>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Ngày xác thực</Typography.Title>
                  <p className='mt-0.5 ml-2'>{dataDetail?.verifiedDate ? formatOriginTime(dataDetail?.verifiedDate) : null}</p>
                </span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Ngày duyệt</Typography.Title>
                  <p className='mt-0.5 ml-2'>{dataDetail?.approvedDate ? formatOriginTime(dataDetail?.approvedDate) : null}</p>
                </span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Kết quả</Typography.Title> <p className='mt-0.5 ml-2'>{renderStatus(dataDetail?.ekycStatus)}</p></span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Thông tin giấy tờ cá nhân</Typography.Title>
                  <div className='mt-0.5 ml-2 flex'>
                    {
                      dataDetail?.imagePersonalUrls?.[0] ?
                        <div>
                          <img className='w-[150px] h-[100px] rounded-md cursor-pointer object-cover' onClick={e => {
                            setIsOpen(true)
                            setCurrImg(0)
                          }} src={dataDetail?.imagePersonalUrls?.[0]} alt="mat-truoc"></img>
                          <p className='mt-0.5'>Mặt trước</p>
                        </div>
                        : null
                    }
                    {
                      dataDetail?.imagePersonalUrls?.[1] ?
                        <div>
                          <img onClick={e => {
                            setIsOpen(true)
                            setCurrImg(1)
                          }} className='w-[150px] h-[100px] rounded-md ml-4 cursor-pointer object-cover'
                            src={dataDetail?.imagePersonalUrls?.[1]} alt="mat-sau"></img>
                          <p className='mt-0.5 ml-4'>Mặt sau</p>
                        </div>
                        : null
                    }
                    <ImgsViewer
                      imgs={[
                        {
                          src:
                            dataDetail?.imagePersonalUrls?.[0]
                        },
                        {
                          src:
                            dataDetail?.imagePersonalUrls?.[1]
                        }
                      ]}
                      currImg={currImg}
                      showThumbnails={true}
                      isOpen={isOpen}
                      onClickPrev={() => setCurrImg(currImg - 1)}
                      onClickNext={() => setCurrImg(currImg + 1)}
                      onClickThumbnail={(index: number) => setCurrImg(index)}
                      onClose={() => setIsOpen(false)}
                    />
                  </div>
                </span>
                <span className='flex mt-2'><Typography.Title level={5} className='min-w-[210px]'>Khuôn mặt</Typography.Title>
                  <div className='mt-0.5 ml-2 flex'>
                    <iframe
                      className='rounded-md w-42 h-30'
                      allow="fullscreen"
                      scrolling="yes"
                      width={"318px"}
                      src={dataDetail?.videoPersonalUrl}
                    />
                  </div>
                </span>
              </div>
            </Panel>
          </Collapse>
          <div className='mb-4'></div>
        </div>
      </Form>
    </Drawer>
  )
}
