import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Menu, Tooltip } from "antd";
import "./style.scss";
import {
  PartBlock,
  Quiz,
} from "../../types/course";
import { convertQuillToString } from "../../utils/convertQuillToString";
import { TooltipProps } from "antd/lib";

interface MoocMenuProps {
  data: PartBlock[];
  className?: string;
  mode?: "default" | "small";
  display?: boolean;
  onClick?: (v: any) => void;
  setQuestion?: (v: any) => void;
  active: number;
  activeParent?: string[];
  setActiveParent?: (parentKey: string) => void
  setActive: React.Dispatch<React.SetStateAction<number>>;
}

type TooltipRef = {
  tooltip: {
    setVisible: (visible: boolean) => void;
  };
} & TooltipProps;

function useTooltipVisibility() {
  const [visible, setVisible] = useState(false);

  const handleMouseEnter = () => {
    setVisible(false); // Hide the tooltip when hovering over it
  };

  const handleMouseLeave = () => {
    setVisible(true); // Show the tooltip when mouse leaves
  };

  return {
    visible,
    handleMouseEnter,
    handleMouseLeave,
  };
}

function MoocMenu({
  data = [],
  className = "",
  mode = "default",
  onClick = () => { },
  setQuestion = () => { },
  display = true,
  active,
  activeParent,
  setActiveParent,
  setActive,
}: MoocMenuProps) {
  type MenuItem = Required<MenuProps>["items"][number];


  const { visible, handleMouseEnter, handleMouseLeave } =
    useTooltipVisibility();

  const items: MenuItem[] = data.map((item: PartBlock) => ({
    key: `parent-${item.groupId}`,
    className: "mt-3",
    label: (
      <div
        className="flex menu-item-responsive"
        onClick={() => {

          setQuestion(item)
          if (setActiveParent) setActiveParent(`${item.groupId}`)

        }}
      >
        <Tooltip
          placement="right" destroyTooltipOnHide title={item.title}>
          <p className="block-title font-size-14 line-height-18 flex-1 one-line w-max-content mr-3">
            {item.title}
          </p>
        </Tooltip>

        <div className="progress-info flex gap-24">
          {/* <div>
            <Progress percent={item?.progress||0%} size="small" />
          </div> */}
          {/* <span className="font-size-14">
            {item?.totalVideo || 0} video -{" "}
            {convertMinutesToTimeString(item?.time)}
          </span> */}
        </div>
      </div>
    ),
    children: item.quizzes?.map((itm: Quiz, index) => ({
      key: `${itm.id}`,
      label: (
        <div
          className="font-size-14 flex align-center gap-8"
          onClick={() => {
            onClick(itm);
            setActive(itm.id);
            setQuestion(item);
          }}
        >
          <div />
          <Tooltip
            placement="right"
            destroyTooltipOnHide
            title={convertQuillToString(itm.question)}
          >
            <span className="one-line">
              {index + 1}.{convertQuillToString(itm.question)}
            </span>
          </Tooltip>
        </div>
      ),
    })),
  }));

  return (
    <div
      className={`mooc-menu ${className} ${mode === "default" ? "" : "mooc-menu-small"
        }`}
    >
      <Menu
        defaultSelectedKeys={["1"]}
        selectedKeys={[`${active}`]}
        defaultOpenKeys={[`1`]}
        openKeys={[`${activeParent}`]}
        // set key initial here
        mode="inline"
        items={items}
      />
    </div>
  );
}

export default MoocMenu;
