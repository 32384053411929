import {
  Button,
  Form,
  Layout,
  MenuProps,
  message,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  Upload,
  UploadFile,
} from "antd";
import React, {ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState, useTransition,} from "react";
import PageHeader from "../../../components/page-header/PageHeader";
import FilterTableCourseConstruction
  from "../../../components/course-construction/content-list-course/filter-table/FilterTable";

import {useLocation, useNavigate, useParams} from "react-router-dom";
import {CloudDownloadOutlined, EyeOutlined, HistoryOutlined, UploadOutlined, UserAddOutlined,} from "@ant-design/icons";
import FormFilter from "../../../components/course-construction/content-list-course/filter-group/FormFilter";
import TableData from "../../../components/table-data/TableData";

import "./index.scss";
import {isEmpty, isNull} from "lodash";

import {formatDate} from "../../../utils/converDate";
import DetailLessonDrawer from "../../../components/drawer/content-list-course/detail-lesson-drawer";
import SyncContentLesson from "../../../components/modal/sync-content-lesson";
import DetailSequenceDrawer from "../../../components/drawer/content-list-course/detail-sequence-drawer";
import DetailUnitDrawer from "../../../components/drawer/content-list-course/detail-unit-drawer";
import DetailEvaluateDrawer from "../../../components/drawer/content-list-course/detail-evaluate-drawer";

import {
  getListSequenceById,
  getSearchQuizCourse,
  updateSettingUnit
} from "../../../service/content-list-course/contentListCourseApi";
import {useAuthStore} from "../../../stores/stores";
import DetailPreviewQuestion from "../../../components/drawer/content-list-course/detail-preview-question";
import {getQuizById} from "../../../service/course-construct";
import {convertQuillToString} from "../../../utils/convertQuillToString";

type CourseConstruction = {
  title?: string;
};
const ContentListCourse = (props: CourseConstruction) => {
  const [tab, setTab] = useState("1")

  const {title} = props
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [listPlan, setListPlan] = useState<any>([])
  const [listQuestion, setListQuestion] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [countFalseRecords, setCountFalseRecords] = useState<number>(0)
  const [countTrueRecords, setCountTrueRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [clearForm, setClearForm] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [sortByOrderNumber, setSortByOrderNumber] = useState<string>("asc")
  const [isSubmitSearch, setIsSubmitSearch] = useState(false);
  const [filterData, setFilterData] = useState<any>({
    sectionId: null,
    sequenceId: null,
    availableStatus: null,
    planCreationDate: null,
    planApprovalDate: null,
    assignmentDate: null,
    assignedInstructors: null,
    unitIds: [],
    isShow: null
  });
  const [loadingUpload, setLoadingUpload] = useState<any>(false);
  const params = useParams();

  const [isOpenSettingDetailLesson, setIsOpenSettingDetailLesson] = useState(false)
  const [isOpenSettingDetailSequence, setIsOpenSettingDetailSequence] = useState(false)
  const [isOpenSettingDetailUnit, setIsOpenSettingDetailUnit] = useState(false)
  const [isOpenSettingDetailEvaluate, setIsOpenSettingDetailEvaluate] = useState(false)
  const [isOpenSyncDetailLesson, setIsOpenSyncDetailLesson] = useState(false)
  const [isOpenPreviewDetail, setIsOpenPreviewDetail] = useState(false)
  const [clearedFilter, setClearedFilter] = useState(false)
  const [detailDataChoose, setDetailDataChoose] = useState<any>({})
  const [detailDataQuiz, setDetailDataQuiz] = useState({})
  const accessToken = useAuthStore((state) => state.accessToken);

  const uploadRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [isPending, startTransition] = useTransition()
  const [fileList, setFileList] = useState<UploadFile[]>([]);


  const [dataOptions, setDataOptions] = useState({
    dataUnits: [],
    dataRoles: [],
    dataOffices: [],
    optionUnitName: []
  });

  const [form] = Form.useForm();

  const handleOpenCreate = () => {
    setIsOpenSyncDetailLesson(true);
  };

  const renderTypeData = () => {
    switch (tab) {
      case "1":
        return 1
        break;
      case "2":
        return 2
        break;
      case "3":
        return 3
        break;
      case "4":
        return 4
        break;
      case "5":
        return 5
        break;
      case "6":
        return 5
        break;
      default:
        return 1
        break;
    }
  }

  const formatDateWithTime = (dateString: string | null): string | null => {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    return `${formattedDate}T00:00:00.000Z`;
  }
  const getDataSequenceByid = async () => {
    const creationDate = filterData.planCreationDate?.filter((item: any) => !!item);
    const publishedDate = filterData.planApprovalDate?.filter((item: any) => !!item);
    const data = {
      courseId: Number(params?.id) ?? 0,
      type: renderTypeData(),
      keyword: searchValue,
      sectionId: tab === "1" ? null : (filterData.sectionId !== 0 && filterData.sectionId !== null && filterData.sectionId !== undefined ? Number(filterData.sectionId) : null),
      sequenceId: tab !== "3" ? null : (filterData.sequenceId !== 0 && filterData.sequenceId !== null && filterData.sequenceId !== undefined ? Number(filterData.sequenceId) : null),
      startedDateFrom: !isEmpty(creationDate) ? formatDateWithTime(creationDate[0]) : null,
      startedDateTo: !isEmpty(creationDate) ? formatDateWithTime(creationDate[1]) : null,
      publishedDateFrom: !isEmpty(publishedDate) ? formatDateWithTime(publishedDate[0]) : null,
      publishedDateTo: !isEmpty(publishedDate) ? formatDateWithTime(publishedDate[1]) : null,
      availableStatus: !isEmpty(filterData.availableStatus) ? Number(filterData.availableStatus) : null,
      sortByName: statusOrder,
      page: pageNumber,
      size: pageSize
    }
    await getListSequenceById(data).then((res: any) => {
      setListPlan(res.data?.body?.content)
      setTotalRecords(res.data?.body?.totalElements)
    })
  }


  const getListQuiz = async () => {
    const dataReq = {
      page: pageNumber,
      size: pageSize,
      keyword: searchValue,
      courseId: Number(params?.id),
      publishDateFrom: !isEmpty(filterData.planApprovalDate) ? `${filterData.planApprovalDate?.[0]}T00:00:00.000Z` : null,
      publishDateTo: !isEmpty(filterData.planApprovalDate) ? `${filterData.planApprovalDate?.[1]}T00:00:00.000Z` : null,
      startedDateFrom: !isEmpty(filterData.planCreationDate) ? `${filterData.planCreationDate?.[0]}T00:00:00.000Z` : null,
      startedDateTo: !isEmpty(filterData.planCreationDate) ? `${filterData.planCreationDate?.[1]}T00:00:00.000Z` : null,
      unitIds: !isEmpty(filterData.unitIds) ? filterData.unitIds : [],
      isShow: filterData.isShow,
      status: !isEmpty(filterData.availableStatus) ? [filterData.availableStatus] : [],
      sortBy: statusOrder
    }
    try {
      const response = await getSearchQuizCourse(dataReq)
      const {data, success} = response.data
      if (success) {
        startTransition(() => {
          setTotalRecords(data.totalElements)
          setPageSize(data.size)
          setIsSubmitSearch(false);
          setListQuestion(isNull(data) ? [] : data.content)
        })
      }

    } catch (err) {
      console.error(err)
    }
  }
  const getDetailQuiz = async (idQuiz?: number) => {
    setLoading(true)
    try {
      if (idQuiz) {
        const response = await getQuizById(idQuiz.toString())
        const {data, status} = response
        if (status === 200) {
          setDetailDataQuiz(data.body)
          setLoading(false)
        }
      }
    } catch (err) {
      console.error(err)
    }

  }


  const getListData = async () => {
    getDataSequenceByid()
  };


  const triggerUpload = () => {
    if (!uploadRef.current) return
    uploadRef?.current?.click()
  }

  useEffect(() => {
    if (!isEmpty(location.state?.modeTab)) {
      setTab(location.state?.modeTab)
    }
  }, [navigate])

  useEffect(() => {
    if (tab === "6") {
      getListQuiz()
    }
    if (tab === "1") {
      getDataSequenceByid()
    }
  }, [tab, pageNumber, pageSize, clearedFilter, statusOrder, isSubmitSearch]);


  useEffect(() => {
    if (listPlan) {
      setDataOptions({
        ...dataOptions,
        optionUnitName: listPlan.map((content: any) => ({
          value: content.id,
          label: content.name
        }))
      })
    }
  }, [listPlan])


  const renderUnitType = (value: string) => {
    switch (value) {
      case "MULTIMEDIA":
        return "Đa phương tiện"
        break;
      case "EVALUATION":
        return "Đánh giá"
        break;
      case "reference":
        return "Tài liệu tham khảo"
        break;
      default:
        break;
    }
  }

  const renderStatus = (value: string) => {
    switch (Number(value)) {
      case 0:
        return "Bản nháp"
        break;
      case 1:
        return "Riêng tư"
        break;
      case 2:
        return "Công khai"
        break;
      default:
        return "Bản nháp"
        break;
    }
  }

  const renderType = (value: string) => {
    switch (Number(value)) {
      case 1:
        return "Nhiều lựa chọn văn bản"
      case 2:
        return "Nhiều lựa chọn ảnh"
      case 3:
        return "Nhiều lựa chọn video"
      case 4:
        return "Nhiều lựa chọn đúng sai"
      case 5:
        return "Danh sách"
      case 6:
        return "Nhiều đáp án văn bản"
      case 7:
        return "Nhiều đáp án hình ảnh"
      case 9:
        return "Câu trả lời ngắn"
      case 10:
        return "Điền vào chỗ trống"
      case 11:
        return "Trình soạn thảo"
      case 12:
        return "Kết nối"
      case 13:
        return "Sắp xếp"
      case 14:
        return "Ghi âm"
      case 15:
        return "Ghi hình"
      case 16:
        return "Đính kèm"
      default:
        return "Bản nháp"
    }
  }


  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên chương",
      dataIndex: "name",
      key: "name",
      className: "sequence-name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 80,
    },
    {
      title: "Trạng thái",
      dataIndex: "availableStatus",
      key: "availableStatus",
      width: 30,
      render: (_: any, record, index: number) => {
        return <div>{renderStatus(record?.availableStatus)}</div>;
      },
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "publishDate",
      key: "publishDate",
      width: 30,
      render: (_: any, record) => {
        return <>{isEmpty(record.publishedDate) ? null : formatDate(record.publishedDate ?? '')}</>;
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      width: 30,
      render: (_: any, record) => {
        return <>{isEmpty(record.startedDate) ? null : formatDate(record.startedDate ?? '')}</>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setDetailDataChoose(record)
                  setIsOpenSettingDetailSequence(true)
                }
                }
              >
                <EyeOutlined/>
              </a>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];

  const dataColumnsLesson: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{record?.numericalOrder ?? 1}</div>;
      },
    },
    {
      title: "Tên bài giảng",
      dataIndex: "name",
      key: "name",
      className: "sequence-name",
      sorter: true,
      width: 35,
    },
    {
      title: "Tên chương",
      dataIndex: "sectionName",
      className: "sequence-name",
      key: "sectionName",
      width: 30,
    },
    {
      title: "Trạng thái",
      dataIndex: "availableStatus",
      key: "availableStatus",
      width: 20,
      render: (_: any, record) => {
        return <div>{renderStatus(record?.availableStatus)}</div>;
      },
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "publishedDate",
      key: "publishedDate",
      width: 20,
      render: (_: any, record) => {
        return <>{isEmpty(record.publishedDate) ? null : formatDate(record.publishedDate ?? '')}</>;
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startedDate",
      key: "startedDate",
      width: 20,
      render: (_: any, record) => {
        return <>{isEmpty(record.startedDate) ? null : formatDate(record.startedDate ?? '')}</>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <Space>
            <a
              className="action-table"
              onClick={() => {
                setDetailDataChoose(record)
                setIsOpenSettingDetailLesson(true)
              }
              }
            >
              <EyeOutlined/>
            </a>
            {/* <Dropdown
              menu={{
                items: options2,
                onClick: ({ key }) => {
                  if(key === '1') {
                    alert("xuất bài giảng")
                  }
                },
              }}
              placement="bottomRight"
            >
              <Button>
                <MoreOutlined />
              </Button>
            </Dropdown> */}
          </Space>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];

  const beforeUpload = (file: any) => {
    setLoadingUpload(true);
    const isJpgOrPng = file.type.includes("image/");
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      message.error("Không đúng định dạng!");
      setLoadingUpload(false);
      return Upload.LIST_IGNORE;
    }

    if (!isLt2M) {
      message.error("Ảnh phải có dung lượng nhỏ hơn 2M!");
      setLoadingUpload(false);
      return Upload.LIST_IGNORE;
    }

    return true;
  }

  const dataColumnsUnit: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{record?.numericalOrder ?? 1}</div>;
      },
    },
    {
      title: "Tên học liệu",
      dataIndex: "name",
      key: "name",
      className: "sequence-name",
      sorter: true,
      width: 35,
    },
    {
      title: "Tên chương",
      dataIndex: "sectionName",
      key: "sectionName",
      className: "sequence-name",
      width: 20,
    },
    {
      title: "Tên bài giảng",
      dataIndex: "sequenceName",
      key: "sequenceName",
      className: "sequence-name",
      width: 25,
    },
    {
      title: "Loại học liệu",
      dataIndex: "sequence",
      key: "sequence",
      width: 20,
      render: (_: any, record) => {
        return <>{renderUnitType(record.type)}</>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "availableStatus",
      key: "availableStatus",
      width: 20,
      render: (_: any, record) => {
        return <div>{renderStatus(record?.availableStatus)}</div>;
      },
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "publishedDate",
      key: "publishedDate",
      width: 20,
      render: (_: any, record) => {
        return <>{isEmpty(record.publishedDate) ? null : formatDate(record.publishedDate ?? '')}</>;
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startedDate",
      key: "startedDate",
      width: 20,
      render: (_: any, record) => {
        return <>{isEmpty(record.startedDate) ? null : formatDate(record.startedDate ?? '')}</>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 10,
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <Space>
            <a
              className="action-table"
              onClick={() => {
                setDetailDataChoose(record)
                setIsOpenSettingDetailUnit(true)
              }
              }
            >
              <EyeOutlined/>
            </a>
            <Upload
              name="imagePath"
              showUploadList={false}
              customRequest={(options) => {
                const {onSuccess, onError, file, onProgress} = options;
                const checkTypeBeforeUpload = beforeUpload(file) === true;
                if (checkTypeBeforeUpload) {
                  handleChangeUpload(file, record?.id);
                }
              }}
            >
              <Button icon={<UploadOutlined/>}></Button>
            </Upload>
          </Space>
        );
      },
      fixed: "right",
    },
  ];

  const dataColumnsReference: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{record?.numericalOrder ?? 1}</div>;
      },
    },
    {
      title: "Tên học liệu",
      dataIndex: "name",
      key: "name",
      className: "sequence-name",
      sorter: true,
      width: 35,
    },
    {
      title: "Tên chương",
      dataIndex: "sectionName",
      key: "sectionName",
      className: "sequence-name",
      width: 20,
      render: (_: any, record) => {
        return <>Chương 1</>;
      },
    },
    {
      title: "Tên bài giảng",
      dataIndex: "sequenceName",
      key: "sequenceName",
      className: "sequence-name",
      width: 25,
    },
    {
      title: "Loại học liệu",
      dataIndex: "sequence",
      key: "sequence",
      width: 20,
      render: (_: any, record) => {
        return <>{renderUnitType(record.type)}</>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "availableStatus",
      key: "availableStatus",
      width: 20,
      render: (_: any, record) => {
        return <div>{renderStatus(record?.availableStatus)}</div>;
      },
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "publishedDate",
      key: "publishedDate",
      width: 20,
      render: (_: any, record) => {
        return <>{isEmpty(record.publishedDate) ? null : formatDate(record.publishedDate ?? '')}</>;
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startedDate",
      key: "startedDate",
      width: 20,
      render: (_: any, record) => {
        return <>{isEmpty(record.startedDate) ? null : formatDate(record.startedDate ?? '')}</>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 10,
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <Space>
            <a
              className="action-table"
              onClick={() => {
                setDetailDataChoose(record)
                setIsOpenSettingDetailUnit(true)
              }
              }
            >
              <EyeOutlined/>
            </a>
            <Upload
              name="imagePath"
              accept=".png, .jpg, .svg"
              showUploadList={false}
              headers={{Authorization: 'Bearer ' + accessToken}}
              onChange={(file) => {
                handleChangeUpload(file, record?.id)
              }}
            >
              <Button icon={<UploadOutlined/>}>
              </Button>
            </Upload>
            {/* <Dropdown
              menu={{
                items: optionsUnit,
                onClick: ({ key }) => {
                  if (key === '1') {
                    triggerUpload()
                  }
                },
              }}
              placement="bottomRight"
            >
              <Button>
                <MoreOutlined />
              </Button>
            </Dropdown> */}
          </Space>
        );
      },
      fixed: "right",
    },
  ];

  const dataColumnsEvaluate: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{record?.numericalOrder ?? 1}</div>;
      },
    },
    {
      title: "Đánh giá",
      dataIndex: "name",
      key: "name",
      className: "sequence-name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 40,
    },
    {
      title: "Loại đánh giá",
      dataIndex: "sequence",
      key: "sequence",
      width: 20,
      render: (_: any, record) => {
        return <>{record.reviewType === 1 ? 'Bài tập' : (record.reviewType === 2 ? 'Bài kiểm tra' : 'Bài thi')}</>;
      },
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: 20,
      render: (_: any, record) => {
        return <>Công khai</>;
      },
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "publishDate",
      key: "publishDate",
      width: 20,
      render: (_: any, record) => {
        return <>{isEmpty(record.publishedDate) ? null : formatDate(record.publishedDate ?? '')}</>;
      },
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      width: 20,
      render: (_: any, record) => {
        return <>{isEmpty(record.startedDate) ? null : formatDate(record.startedDate ?? '')}</>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <Space>
            <a
              className="action-table"
              onClick={() => {
                setDetailDataChoose(record)
                setIsOpenSettingDetailEvaluate(true)
              }
              }
            >
              <EyeOutlined/>
            </a>
            {/* <Dropdown
              menu={{
                items: options2,
                onClick: ({ key }) => {
                  if(key === '1') {
                    alert("xuất bài giảng")
                  }
                },
              }}
              placement="bottomRight"
            >
              <Button>
                <MoreOutlined />
              </Button>
            </Dropdown> */}
          </Space>
        );
      },
      fixed: "right",
      width: 10,
    },
  ];

  const dataColumnQuestions: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder ?? 1}</div>;
      },
    },
    {
      title: "Câu hỏi",
      dataIndex: "question",
      key: "question",
      className: "sequence-name",
      sorter: true,
      render: (_: any, record, index: number) => {
        return convertQuillToString(record?.question);
      },
      width: 100,
    },
    {
      title: "Loại câu hỏi",
      dataIndex: "quizType",
      key: "quizType",
      width: 35,
      render: (_: any, record, index: number) => {
        return <div>{renderType(record?.quizType)}</div>;
      },
    },
    {
      title: "Đánh giá",
      dataIndex: "unitName",
      key: "unitName",
      className: "sequence-name",
      width: 20,
    },
    {
      title: "Hiển thị",
      dataIndex: "isShow",
      key: "isShow",
      width: 20,
      render: (_: any, record, index: number) => {
        return <span>{record.isShow ? 'Hiển thị' : 'Ẩn'}</span>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenPreviewDetail(true)
                  getDetailQuiz(record.quizId)
                }
                }
              >
                <EyeOutlined/>
              </a>
            </Space>
          </>
        );
      },
      fixed: "right",
      width: 10,
    },
  ]

  useEffect(() => {
    setLoading(false)
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    if (tab !== '6') {
      getListData();
    }
  }, [tab, pageNumber, statusOrder, pageSize]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    setIsSubmitSearch(true);
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      setIsSubmitSearch(true);
    }
  };
  const onFinish = (values: any) => {
    setFilterData({
      ...filterData,
      isShow: values.isShow
    });
    setPageNumber(1);
    setIsSubmitSearch(true)
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      ...filterData,
      sectionId: null,
      sequenceId: null,
      availableStatus: null,
      planCreationDate: null,
      planApprovalDate: null,
      assignmentDate: null,
      assignedInstructors: null,
      isShow: null,
      unitIds: []
    });
    setClearedFilter(prev => !prev)
  };

  const handleChangeUpload = async (file: any, id: string) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("imagePath", file);
    await updateSettingUnit(formData).then((res) => {
      if (res.status === 200) {
        message.success("Cập nhật ảnh đại diện thành công");
      }
      setLoading(false);
    });
  }

  const options1: MenuProps["items"] = [
    {
      key: "1",
      label: "Phân công",
      icon: <UserAddOutlined/>,
    },
    {
      key: "2",
      label: "Lịch sử hoạt động",
      icon: <HistoryOutlined/>,
    },
  ];

  const options2: MenuProps["items"] = [
    {
      key: "1",
      label: "Xuất bài giảng",
      icon: <CloudDownloadOutlined/>,
    },
  ];

  // const optionsUnit: MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: <Upload {...props} listType="picture" accept=".png, .jpg, .svg" onChange={handleChange} ref={uploadRef} >
  //       Đính kèm ảnh đại diện
  //     </Upload>,
  //     icon: <LinkOutlined />,
  //   },
  // ];


  const defaultCheckedList = dataColumns.map((item) => item.key as string);
  const defaultColumnsLesson = dataColumnsLesson.map((item) => item.key as string);
  const defaultColumnsUnit = dataColumnsUnit.map((item) => item.key as string);
  const defaultColumnsReference = dataColumnsReference.map((item) => item.key as string);
  const defaultColumnsEvaluate = dataColumnsEvaluate.map((item) => item.key as string);
  const defaultColumnsQuestion = dataColumnQuestions.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [checkedList2, setCheckedList2] = useState(defaultColumnsLesson);
  const [checkedList3, setCheckedList3] = useState(defaultColumnsUnit);
  const [checkedList4, setCheckedList4] = useState(defaultColumnsReference);
  const [checkedList5, setCheckedList5] = useState(defaultColumnsEvaluate);
  const [checkedList6, setCheckedList6] = useState(defaultColumnsQuestion);


  const newColumns = useMemo(() => {
    return dataColumns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
    }));
  }, [checkedList]);

  const newColumnsLesson = useMemo(() => {
    return dataColumnsLesson.map((item) => ({
      ...item,
      hidden: !checkedList2.includes(item.key as string),
    }));
  }, [checkedList2]);

  const newColumnsUnit = useMemo(() => {
    return dataColumnsUnit.map((item) => ({
      ...item,
      hidden: !checkedList3.includes(item.key as string),
    }));
  }, [checkedList3]);

  const newColumnsReference = useMemo(() => {
    return dataColumnsReference.map((item) => ({
      ...item,
      hidden: !checkedList4.includes(item.key as string),
    }));
  }, [checkedList4]);

  const newColumnsEvaluate = useMemo(() => {
    return dataColumnsEvaluate.map((item) => ({
      ...item,
      hidden: !checkedList5.includes(item.key as string),
    }));
  }, [checkedList5]);

  const newColumnsQuestion = useMemo(() => {
    return dataColumnQuestions.map((item) => ({
      ...item,
      hidden: !checkedList6.includes(item.key as string),
    }));
  }, [checkedList6]);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend" && tab === '6'
        ? "question,asc"
        : sorter.order === "descend" && tab === '6'
          ? "question,desc"
          : sorter.order === "ascend" && tab !== '6' ? "asc" : sorter.order === "descend" && tab !== '6' ? "desc" : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };
  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: `Chương`,
      children: (
        <div className="waiting-assignment">
          <TableData
            dataTable={listPlan}
            dataColumns={newColumns}
            setPageNumber={setPageNumber}
            hideSelection={true}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{x: 1500, y: 600}}
            totalRecords={totalRecords}
            setSelectedRowKeysProps={setSelectedRowKeys}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: `Bài giảng`,
      children: (
        <div className="assigned">
          <TableData
            dataTable={listPlan}
            dataColumns={newColumnsLesson}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{x: 1500, y: 600}}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: `Học liệu`,
      children: (
        <div className="assigned">
          <TableData
            dataTable={listPlan}
            dataColumns={newColumnsUnit}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{x: 1500, y: 600}}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      ),
    },
    {
      key: "4",
      label: `Tài liệu tham khảo`,
      children: (
        <div className="assigned">
          <TableData
            dataTable={listPlan}
            dataColumns={newColumnsReference}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{x: 1500, y: 600}}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      ),
    },
    {
      key: "5",
      label: `Đánh giá`,
      children: (
        <div className="assigned">
          <TableData
            dataTable={listPlan}
            dataColumns={newColumnsEvaluate}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{x: 1500, y: 600}}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
          />
        </div>
      ),
    },
    {
      key: "6",
      label: "Câu hỏi",
      children: (
        <div className="assigned">
          <TableData
            dataColumns={newColumnsQuestion}
            dataTable={listQuestion}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={isPending}
            scroll={{x: 1500, y: 600}}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}/>
        </div>
      )
    }
  ];

  const listBreadcrumb = [
    {
      title:
        "Xây dựng Nội dung khoá học"
      ,
    },
    {
      title: "Danh sách nội dung",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const confirmModal = () => {
    message.success("Phân công khoá học thành công");
    setIsOpened(!isOpened);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({...filterData, [name]: value});
  };

  const saveMultiAssign = async (data: object) => {

  };

  return (
    <Layout className="page-header-group content-list-course">
      <Form.Provider
        onFormFinish={(name, {values, forms}) => {
          if (name === "create-course-plan-modal") {
            const listRowKey = selectedRowKeys.map((item: any) => {
              return item.id;
            });

            const data = {
              courseIds: listRowKey,
              assigners: values.lecturerName,
            };

            saveMultiAssign(data);
            getListData();
            setClearForm(true);
          }
          if (name === "filterForm") {
            setPageNumber(1)
            getListData()
            if (tab === '6') {
              getListQuiz()
            }
          }
        }}
      >
        <div
          className={`page-header-group--layout ${showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader

                title={location.state?.courseName ?? title}
                listBreadcrumb={listBreadcrumb}
                arrowLeft={'/images/arrowLeft.svg'}
                positionItem="column"
                breadcrumb={true}/>
            </div>
            <FilterTableCourseConstruction
              handleOpen={handleOpenCreate}
              searchValue={searchValue}
              dataColumns={dataColumns}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
              selectedRowKeys={selectedRowKeys}
              tab={tab}
              getListData={getListData}
              filterData={filterData}
            />
          </div>
          <FormFilter
            handleCloseFilter={handleCloseFilter}
            dataOptions={dataOptions}
            clearFilter={clearFilter}
            form={form}
            showFilter={showFilter}
            tab={tab}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            onFinish={onFinish}
          />
        </div>

        <div className="tabs-heading">
          <Tabs items={itemsTab} activeKey={tab} onChange={onChange}/>
        </div>

        <DetailLessonDrawer
          open={isOpenSettingDetailLesson}
          onClose={() => setIsOpenSettingDetailLesson(false)}
          detailData={detailDataChoose}
        />
        <SyncContentLesson
          open={isOpenSyncDetailLesson}
          filterData={filterData}
          tab={tab}
          searchValue={searchValue}
          handleClose={() => setIsOpenSyncDetailLesson(false)}
        />

        <DetailSequenceDrawer
          open={isOpenSettingDetailSequence}
          onClose={() => setIsOpenSettingDetailSequence(false)}
          detailData={detailDataChoose}
        />

        <DetailUnitDrawer
          open={isOpenSettingDetailUnit}
          onClose={() => setIsOpenSettingDetailUnit(false)}
          detailData={detailDataChoose}
        />

        <DetailEvaluateDrawer
          open={isOpenSettingDetailEvaluate}
          onClose={() => setIsOpenSettingDetailEvaluate(false)}
          detailData={detailDataChoose}
        />
        <DetailPreviewQuestion
          open={isOpenPreviewDetail}
          loading={loading}
          onClose={() => setIsOpenPreviewDetail(false)}
          detailData={detailDataQuiz}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ContentListCourse;
