import { StateCreator } from "zustand";

export interface Permission {
  permission: any;
  setPermission: (data: any) => void;
}

export const AddPermissionStore: StateCreator<Permission> = (set, get) => ({
  permission: [],
  setPermission: (data: any) => {
    set({ permission: data });
  },
});