import {ColumnsType} from "antd/es/table";

interface Value {
  count: number;
  percentage: number | null;
  criteria: string;
}

interface CourseData {
  type: number;
  criteria: string;
  values: Value[];
}

interface Column {
  title: string;
  dataIndex: string;
  key: string;
}

interface TableData {
  key: string;

  [key: string]: any;
}

export const createTableData = (data: CourseData[]): { columns: Column[], dataSource: TableData[] } => {
  const columnsSet = new Set<string>();
  data.forEach(course => {
    if (course.values && course.values.length > 0) {
      course.values.forEach(value => columnsSet.add(value.criteria));
    } else {
      columnsSet.add(course.criteria)
    }

  });
  const columns = Array.from(columnsSet);
  const dynamicColumns: Column[] = [

    ...columns.map(criteria => ({
      title: criteria,
      dataIndex: criteria,
      key: criteria,
    })),

  ];

  const dataSource: TableData[] = data.map((course, index) => {
    const row: TableData = {key: index.toString(), "name": course.criteria, "Tổng": 0};
    columns.forEach(column => {
      const value = course.values && course.values.length > 0 ? course.values.find(v => v.criteria === column) : course;
      if (value) {
        if ("count" in value) {
          row[column] = value ? value.count : 0;
          row["Tổng"] += value ? value.count : 0;
        }
      }
    });

    return row;
  });

  return {columns: dynamicColumns, dataSource};
};


interface Rating {
  count: number;
  percentage: number | null;
  criteria: string;
}

interface DataItem {
  type: number;
  criteria: string;
  values: Rating[];
}

type TransformedData = {
  content: string;
  [key: string]: string;
}

export const rateTransformData = (data: DataItem[]): TransformedData[] => {
  const result: TransformedData[] = [];

  data.forEach((item) => {
    const row: TransformedData = {
      content: item.criteria,
      "1 sao": "0% (0)",
      "2 sao": "0% (0)",
      "3 sao": "0% (0)",
      "4 sao": "0% (0)",
      "5 sao": "0% (0)",
      Tổng: "0",
    };

    let rowTotalCount = 0;

    item.values.forEach((value) => {
      const percentage = value.percentage !== null ? value.percentage : 0;
      const count = value.count || 0;

      row[value.criteria as keyof TransformedData] = `${percentage}% (${count})`;
      rowTotalCount += count;
    });

    row.Tổng = `${rowTotalCount}`;
    result.push(row);
  });

  return result;
};


export const createDynamicColumns = (data: TableData[]): ColumnsType<TableData> => {
  if (data.length === 0) return [];

  const dynamicKeys = Object.keys(data[0].values);

  const dynamicColumns = dynamicKeys.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' '),
    dataIndex: key,
    key: key,
  }));

  return dynamicColumns;
};
