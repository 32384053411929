import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormInstance,
  Input,
  Progress,
  Upload,
  UploadProps,
} from "antd";
import { notifyWarning } from "../../../../../utils/notification";
import { isEmpty } from "lodash";
import { bytesToMegabytesBinary } from "../../../../../utils/format";
import { MAX_FILE_UPLOAD_SIZE } from "../../../../../constants";
import {
  CloudIcon,
  DeleteIcon,
  ReloadIcon,
} from "../../../../../components/icons/svg";
import { uploadFileV2 } from "../../../../../service/uploadFile/infoDetailApi";

interface FileUploadProps {
  form?: FormInstance<any>;
}

const { Dragger } = Upload;

function FileUpload({ form }: FileUploadProps) {
  const [fileInfo, setFileInfo] = useState<any>({});
  const [progress, setProgress] = useState<number>(0);
  const [isUploadDone, setIsUploadDone] = useState<boolean>(true);

  const handleChangeFile: UploadProps["onChange"] = async (info) => {
    const file = info.file.originFileObj;

    if (file && isCorrectFile(file)) {
      setFileInfo(file);
      setIsUploadDone(false);
      const formData = new FormData();
      formData.append("file", file);

      try {
        const res = await uploadFileV2(formData);
        setProgress(100);
        form?.setFieldValue("fileUpload", res?.data.filePath);
        setTimeout(() => setIsUploadDone(true), 500);
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    const initFile = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/test-file.doc`);
        const blob = await response.blob();
        setFileInfo(blob);
        setIsUploadDone(true);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };
    initFile();
  }, []);

  const isCorrectFile = (file: any) => {
    const acceptFile = [
      // pdf
      "application/pdf",
      // excel
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // word
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      // powerpoint
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ];

    const isJpgOrPng = acceptFile.includes(file.type);
    const isCorrectFile = file.size < MAX_FILE_UPLOAD_SIZE;
    if (isJpgOrPng && isCorrectFile) {
      return true;
    }
    return false;
  };

  const handleDeleteFile = () => {
    setFileInfo({});
    setProgress(0);
  };

  const beforeUpload = (file: any) => {
    if (!isCorrectFile(file)) {
      notifyWarning(
        "Bạn phải tải lên file PDF, Word, excel hoặc PowperPoint (Tối đa 2 MB)!"
      );
      setFileInfo({});
      setProgress(0);
    }
  };

  const renderFileType = () => {
    const type = fileInfo?.type;
    switch (type) {
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "xlxs-icon.svg";
      case "application/vnd.ms-excel":
        return "xls-icon.svg";
      case "application/vnd.ms-powerpoint":
        return "ppt-icon.svg";
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        return "pptx-icon.svg";
      case "application/msword":
        return "doc-icon.svg";
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "docx-icon.svg";
      case "application/pdf":
        return "pdf-icon.svg";
      default:
        break;
    }
  };

  const renderContent = () => {
    if (isEmpty(fileInfo)) {
      return (
        <Dragger
          className="dragger-record"
          name="file"
          multiple={false}
          showUploadList={false}
          onChange={handleChangeFile}
          beforeUpload={beforeUpload}
        >
          <div className="center">
            <div className="icon-file-upload">
              <CloudIcon />
            </div>
          </div>
          <p>
            <span className="font-weight-6 text-primary-violet">Nhấn để tải tệp</span>{" "}
            hoặc kéo thả tệp vào đây
          </p>
          <p className="font-size-12 mt-1">
            PDF,word, excel hoặc PowperPoint (Tối đa 2 MB)
          </p>
        </Dragger>
      );
    }

    return (
      <div className="flex mt-2">
        <Form.Item name="fileUpload">
          <Input style={{ display: "none" }} />
        </Form.Item>
        <div className="file-uploaded">
          <div className="file-info flex gap-8">
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/${renderFileType()}`}
              alt="pdf-icon"
            />

            <div className="file-detail w-full">
              <p className="font-size-14">{fileInfo?.name}</p>
              <p className="font-size-14">
                {bytesToMegabytesBinary(fileInfo?.size)}MB
              </p>
              {!isUploadDone && (
                <Progress strokeColor="#7F56D9" percent={progress} />
              )}
            </div>
          </div>
        </div>

        <div className="file-upload-btn-group flex flex-column justify-space-between ml-2">
          <Button className="file-upload-btn">
            <ReloadIcon />
          </Button>
          <Button
            className="file-upload-btn btn-delete"
            onClick={handleDeleteFile}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
    );
  };

  return <div>{renderContent()}</div>;
}

export default FileUpload;
