import { ReactNode } from "react";

export interface DataType {
    id: number,
    name: string,
    codeCate: string,
    cateDad?: string,
    status: boolean,
    createdDate: string,
    numericalOrder: ReactNode,
}

export const validateFieldCreate = [ "name", "code", "cateDad", "status" ]

export const dataStatus = [
    {
      value: true,
      label: "Hoạt động",
    },
    {
      value: false,
      label: "Không hoạt động",
    },
  ];

export const validateFieldFilter = []
