import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigToUpload } from "../../config/api/configApiUpload";

export const getCourse: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.get(`/mooc-courses/${id}`);
};

export const getAllComment: (payload: {
  unitId: number;
  discussionType: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/unit-discussion/get-all-by-unit`, payload);
};

export const discussionReact: (payload: {
  id: number;
  reactionType: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/unit-discussion/react`, payload);
};

export const deleteComment: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.delete(`/unit-discussion/delete/${id}`);
};

export const createDiscussion: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`/unit-discussion/create`, payload);
};

export const replyDiscussion: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.post(`/unit-discussion/reply`, payload);
};

export const updateComment: (payload: any) => Promise<AxiosResponse<any>> = (
  payload
) => {
  return axiosConfigToUpload.put(`/unit-discussion/update-comment`, payload);
};
