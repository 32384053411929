import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const getListAnswerStatusNotIn: (idUser: any, idSurvey: any) => Promise<AxiosResponse<any>> = (idUser: any, idSurvey: any) => {
    return axiosConfig.get(`/survey-student/list-QA/${idUser}/${idSurvey}`);
};

export const submitDraftAnswer: (idUser: any, idSurvey: any, data: any) => Promise<AxiosResponse<any>> = (idUser: any, idSurvey: any, data: any) => {
    return axiosConfig.post(`/survey-student/submitDraft/${idUser}/${idSurvey}`, data);
};

export const getQuestionSurveyDraft: (idUser: any, idSurvey: any) => Promise<AxiosResponse<any>> = (idUser: any, idSurvey: any) => {
    return axiosConfig.get(`/survey-student/list-QA-draft/${idUser}/${idSurvey}`);
};

export const submitSurveyAnswer: (idUser: any, idSurvey: any, data: any) => Promise<AxiosResponse<any>> = (idUser: any, idSurvey: any, data: any) => {
    return axiosConfig.post(`/survey-student/submitSurvey/${idUser}/${idSurvey}`, data);
};

export const getQuestionSurvey: (idUser: any, idSurvey: any) => Promise<AxiosResponse<any>> = (idUser: any, idSurvey: any) => {
    return axiosConfig.get(`/survey-student/list-QA-survey/${idUser}/${idSurvey}`);
};

export const getQuestionSurveyPreview: (idSurvey: any) => Promise<AxiosResponse<any>> = (idSurvey: any) => {
    return axiosConfig.get(`/survey-student/list-QA-Preview/${idSurvey}`);
};

export const getQuestionSurveyByUserId: (idUser: any, idSurvey: any) => Promise<AxiosResponse<any>> = (idUser: any, idSurvey: any) => {
    return axiosConfig.get(`/survey-student/list-QA-survey-by-userId/${idUser}/${idSurvey}`);
};

