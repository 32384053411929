import {
  Button,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Tabs,
  TabsProps,
  Typography,
  message,
} from "antd";
import PageHeader from "../../../../components/page-header/PageHeader";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./DetailOrganizeAppraisalBoard.scss";
import FormOrganizeAppraisalBoardMember from "./form-organize-appraisal-board-member/FormOrganizeAppraisalBoardMember";
import { getDetailOrganizeAppraisalBoard } from "../../../../service/course-plan-managment/organize-appraisal-board/organizeAppraisalBoardApi";
import DetailInfoTab from "./detail-info-tab/DetailInfoTab";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import OrganizationActionHistoryList from "../../organization-action-history/OrganizationActionHistoryList";
const { Content } = Layout;
const imgPath = "/images/";

const DetailOrganizeAppraisalBoard: FunctionComponent = () => {
  type DataGroupMemeber = {
    name?: string;
    image_path?: Boolean;
    user_id?: Number;
  };
  const [listDataMember, setListDataMember] = useState<DataGroupMemeber[]>([]);
  const params = useParams();
  const location = useLocation();
  const ref = useRef<HTMLButtonElement>(null);
  const [numberTab, setNumberTab] = useState("");
  const [isDisable, setIsDisable] = useState<boolean>(false);

  const [detailData, setDetailData] = useState<any>({});
  const getOrganizeAppraisalBoardById = async () => {
    try {
      await getDetailOrganizeAppraisalBoard(params.id).then((res) => {
        const data = res.data;
        setDetailData(data);
        setListDataMember(data.member);
      });
    } catch (error) {
      console.log(error);
      message.error("Có lỗi chưa xác định");
    }
  };
  useEffect(() => {
    getOrganizeAppraisalBoardById();
    if (location.state?.mode === "view") {
      setIsDisable(true);
    }
  }, [location.state?.mode]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin chi tiết",
      children: (
        <DetailInfoTab detailData={detailData} memberList={listDataMember} />
      ),
    },
    {
      key: "2",
      label: "Quản lý thành viên",
      children: (
        <FormOrganizeAppraisalBoardMember
          detailData={detailData}
          memberList={listDataMember}
          isDisble={isDisable}
          ref={ref}
        />
      ),
    },
    {
      key: "3",
      label: "Lịch sử hội đồng thẩm định",
      children: <OrganizationActionHistoryList organizationId={params.id} />,
    },
  ];
  const listBreadcrumb = [
    {
      title: "Kế hoạch khóa học",
    },
    {
      title: (
        <a href={`/course-plan/organize-appraisal-board`}>
          Lập hội đồng thẩm định
        </a>
      ),
    },
    {
      title: detailData?.organizationName || "",
    },
  ];
  const handleSave = () => {
    Modal.confirm({
      title: `Lưu dữ liệu đã thay đổi.`,
      content: `Bạn có chắc chắn muốn lưu dữ liệu đã thay đổi?`,
      onOk: () => {
        if (ref.current) {
          ref.current.click();
        }
      },
      okText: "Lưu",
      cancelText: "Huỷ",
      centered: true,
      icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  };
  return (
    <>
      <Content>
        <PageHeader
          listBreadcrumb={listBreadcrumb}
          title={"Chi tiết " + detailData?.organizationName}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
        />
        {location.state.numberTab === "2" || numberTab === "2" ? (
          <div
            style={{ position: "absolute", top: 130, right: 20, zIndex: 100 }}
          >
            <Button type="primary" onClick={handleSave} disabled={isDisable}>
              Lưu
            </Button>
          </div>
        ) : (
          <div></div>
        )}
        <div className="course-plan-managment-detail-appraisal">
          <div className="tabs-heading">
            <Tabs
              defaultActiveKey={location.state.numberTab}
              items={items}
              onChange={(e) => {
                setNumberTab(e);
              }}
            />
          </div>
        </div>
      </Content>
    </>
  );
};
export default DetailOrganizeAppraisalBoard;
