import {
  Button,
  Dropdown,
  Form,
  Layout,
  Space,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
} from "antd";
import FilterSubmitAppraisalPlan from "./components/filter-submit-appraisal-plan/FilterSubmitAppraisalPlan";
import { useState, KeyboardEvent, useEffect } from "react";
import { formatDateV2 } from "../../../utils/converDate";
import {
  EyeOutlined,
  HistoryOutlined,
  MoreOutlined,
  SendOutlined,
  UndoOutlined,
  UserOutlined,
} from "@ant-design/icons";
import TableData from "../../../components/table-data/TableData";
import { useAuthStore } from "../../../stores/stores";
import { FormDataFilter } from "../../../types/course-plan-managment/mooc-approval";
import { searchCourseConstructionPlanSubmitAppraisalPlan } from "../../../service/course-construction-plan";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuProps } from "antd/lib";
import { DataTypeMoocCourseConstructionPlan } from "../../../types/course-plan-managment/mooc-course-construction-plan";
import ApproveCouresConstructionPlan from "./components/approve-coures-constuction/approveCouresConstruction";
import { routesConfig } from "../../../config/routes";
const CoursePlanSubmitAppraisalPlan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeTabKey, setActiveTabKey] = useState<string>(
    location?.state?.keyTab ?? "1"
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const userId = useAuthStore((states) => states.user?.id);
  const [dataFormFilter, setDataFormFilter] = useState<FormDataFilter>({
    submissionDate: null,
    approvalDate: null,
  });
  const [browseData, setBrowseData] =
    useState<DataTypeMoocCourseConstructionPlan>();
  const [form] = Form.useForm();
  const [dataList, setDataList] = useState([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countTrueRecords, setCountTrueRecords] = useState<number>(0);
  const [countFalseRecords, setcountFalseRecords] = useState<number>(0);
  const [isOpenModalApprove, setIsOpenModalApprove] = useState<boolean>(false);
  const handleCoverDate = (dateString: string) => {
    if (dateString == undefined) {
      return null;
    } else {
      const [day, month, year] = dateString.split("-");
      return new Date(`${year}-${month}-${day}`);
    }
  };

  const handleGetListData = () => {
    setLoading(true);
    const dataSend = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      submitterId: userId,
      submitedDateFrom: handleCoverDate(dataFormFilter?.submissionDate?.[0]),
      submitedDateTo: handleCoverDate(dataFormFilter?.submissionDate?.[1]),
      approvalDateFrom: handleCoverDate(dataFormFilter?.approvalDate?.[0]),
      approvalDateTo: handleCoverDate(dataFormFilter?.approvalDate?.[1]),
      isSentAppraisal: activeTabKey === "1" ? false : true,
    };
    searchCourseConstructionPlanSubmitAppraisalPlan(dataSend)
      .then((res) => {
        const { data, totalRecords, countTrueRecords, countFalseRecords } =
          res.data.data;
        setDataList(data);
        setTotalRecords(totalRecords);
        setCountTrueRecords(countTrueRecords);
        setcountFalseRecords(countFalseRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleSearchEnter = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      setPageNumber(1);
      setPageSize(10);
      handleGetListData();
    }
  };
  const getOptionsAction = (tab: any): MenuProps["items"] => {
    if (tab == 1) {
      const optionsDropdownMenu: MenuProps["items"] = [
        {
          key: "1",
          label: "Gửi thẩm định kế hoạch",
          icon: <SendOutlined />,
        },
        {
          key: "history",
          label: "Lịch sử hoạt động",
          icon: <HistoryOutlined />,
        },
        {
          key: "3",
          label: "Lịch sử duyệt",
          icon: <UndoOutlined />,
        },
      ];
      return optionsDropdownMenu;
    }
    if (tab == 2) {
      const optionsDropdownMenu: MenuProps["items"] = [
        {
          key: "history",
          label: "Lịch sử hoạt động",
          icon: <HistoryOutlined />,
        },
        {
          key: "3",
          label: "Lịch sử duyệt",
          icon: <UndoOutlined />,
        },
        {
          key: "4",
          label: "DS thành viên Hội đồng gửi thẩm định",
          icon: <UserOutlined />,
        },
      ];
      return optionsDropdownMenu;
    }
  };
  const handleSearch = () => {
    setPageNumber(1);
    setPageSize(10);
    handleGetListData();
  };
  const clearFilter = () => {
    setLoading(true);
    form.resetFields();
    setSearchValue("");
    setDataFormFilter({
      submissionDate: null,
      approvalDate: null,
    });
    const dataSend = {
      keyword: "",
      pageNumber: 1,
      pageSize: pageSize,
      submitterId: userId,
      submitedDateFrom: null,
      submitedDateTo: null,
      approvalDateFrom: null,
      approvalDateTo: null,
      isSentAppraisal: activeTabKey === "1" ? false : true,
    };
    searchCourseConstructionPlanSubmitAppraisalPlan(dataSend)
      .then((res) => {
        const { data, totalRecords, countTrueRecords, countFalseRecords } =
          res.data.data;
        setDataList(data);
        setTotalRecords(totalRecords);
        setCountTrueRecords(countTrueRecords);
        setcountFalseRecords(countFalseRecords);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const handleChangeFilter = (value: any, name: string) => {
    setDataFormFilter({ ...dataFormFilter, [name]: value });
  };
  const closeApproveModal = () => {
    setIsOpenModalApprove(false);
  };
  // useEffect(() => {
  //   clearFilter();
  // }, [activeTabKey]);
  useEffect(() => {
    handleGetListData();
  }, [activeTabKey, pageNumber, pageSize]);
  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
  };
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 5,
      render: (_: any, record: any, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Mã kế hoạch",
      width: 20,
      dataIndex: "courseConstructionPlanCode",
      render: (_: any, record) => {
        return record.courseConstructionPlanCode;
      },
    },
    {
      title: "Tên kế hoạch",
      sorter: (a, b) =>
        a.courseConstructionPlanName.localeCompare(
          b.courseConstructionPlanName
        ),
      width: 70,
      dataIndex: "courseConstructionPlanName",
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">
                {record.courseConstructionPlanName}
              </span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Ngày gửi thẩm định",
      width: 20,
      dataIndex: "submissionDate",
      render: (_: any, record) => {
        return (
          <>
            {record.submissionDate ? formatDateV2(record.submissionDate) : null}
          </>
        );
      },
    },
    ...(activeTabKey === "2"
      ? [
          {
            title: "Ngày thẩm định",
            width: 20,
            dataIndex: "approvalDate",
            render: (_: any, record: any) => {
              return (
                <>
                  {record.approvalDate
                    ? formatDateV2(record.approvalDate)
                    : null}
                </>
              );
            },
          },
        ]
      : []),
    {
      title: "",
      width: 10,
      fixed: "right",
      render: (_: any, record) => {
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigate(`detail/${record.courseConstructionPlanId}`, {
                    state: {
                      record,
                      mode: "view",
                      keyTab: activeTabKey,
                      modeback: "backState",
                      backLink: `/${routesConfig.coursePlan}/${routesConfig.submitAppraisalPlan}`,
                    },
                  });
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: getOptionsAction(activeTabKey),
                  onClick: ({ key }) => {
                    if (key === "1") {
                      setIsOpenModalApprove(true);
                      setBrowseData(record);
                    }
                    if (key === "history") {
                      navigate(
                        `${routesConfig.submitAppraisalPlan}/${routesConfig.historySubmitAppraisalPlan}/${record.id}`,
                        {
                          state: record,
                        }
                      );
                    }
                    if (key === "4") {
                      navigate(
                        `${routesConfig.detailOrganizeAppraisalBoard}/${record.organzationId}`,
                        {
                          state: { record, mode: "view" },
                        }
                      );
                    }
                    if (key === "3") {
                      navigate(`detail/${record.courseConstructionPlanId}`, {
                        state: { record, mode: "view", tab: "4" },
                      });
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];
  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          <span>Chờ gửi thẩm định </span>
          <span className="border-total-record">{countFalseRecords}</span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            dataTable={dataList}
            dataColumns={dataColumns}
            hideSelection={false}
            loadingTable={loading}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            searchValue={searchValue}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          <span>Đã gửi thẩm định </span>
          <span className="border-total-record">{countTrueRecords}</span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataTable={dataList}
            dataColumns={dataColumns}
            hideSelection={false}
            loadingTable={loading}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            pagination={true}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            searchValue={searchValue}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];
  return (
    <Layout className="page-header-group course-construction">
      <div className="table-header--wrapper">
        <FilterSubmitAppraisalPlan
          form={form}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          dataFormFilter={dataFormFilter}
          setDataFormFilter={handleChangeFilter}
          handleSearch={handleSearch}
          handleSearchEnter={handleSearchEnter}
          activeTabKey={activeTabKey}
          clearFilter={clearFilter}
        />
      </div>
      <div className="tabs-heading">
        <Tabs
          items={itemsTab}
          activeKey={activeTabKey}
          onChange={(key) => setActiveTabKey(key)}
        />
      </div>
      <ApproveCouresConstructionPlan
        open={isOpenModalApprove}
        handleCancel={() => {
          closeApproveModal();
        }}
        browseData={browseData}
        handleFinish={clearFilter}
      />
    </Layout>
  );
};
export default CoursePlanSubmitAppraisalPlan;
