import React, { useEffect, useState } from "react";
import "./detail.scss";
import ShowListQuestion from "./ShowListQuestion";
import { Card, Checkbox, Col, Row, Space } from "antd";
import { MoocSurveyContentQuestion } from "../../../../../../types/survey-managment/mooc-survey-content";

const ShowListSection = (props: any) => {
  const {
    index,
    listQuestion,
    setSelectedItems,
    selectedItems,
    isChecked,
    setIsChecked,
    setDataSection,
    handleExitAddMoreQuestionExsit,
  } = props;
  const [selectedQuestion, setSelectedQuestion] = useState<
    MoocSurveyContentQuestion[]
  >([]);
  console.log(listQuestion);
  // console.log('xxxchien')
  // console.log({ listQuestion })
  // console.log({ selectedItems })

  // console.log({ isChecked })
  // console.log({ selectedItems })
  const handleCheckboxChange = (item: any) => {
    const selectedIndex = selectedItems.findIndex(
      (selectedItem: any) => selectedItem.id === item.id
    );

    if (selectedIndex === -1) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const updatedSelectedItems = [...selectedItems];
      updatedSelectedItems.splice(selectedIndex, 1);
      setSelectedItems(updatedSelectedItems);
    }
  };
  return (
    <Space
      className="survey-managment-right-control"
      style={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      {listQuestion &&
        listQuestion.length > 0 &&
        listQuestion.map((item: any, index: any) => (
          <div
            key={item.id}
            style={{
              width: "100%",
              marginTop: 18,
              display: "flex",
              padding: "0 20px 0 0",
            }}
          >
            <Checkbox
              style={{ width: "5%" }}
              checked={selectedItems.some(
                (selectedItem: any) => selectedItem.id === item.id
              )}
              onChange={() => handleCheckboxChange(item)}
            ></Checkbox>
            <div style={{ width: "95%" }}>
              <ShowListQuestion index={index} sectionQuestion={item} />
            </div>
          </div>
        ))}
    </Space>
  );
};

export default ShowListSection;
