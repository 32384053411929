import {
  Button,
  Checkbox,
  Col,
  Form,
  message,
  Modal,
  Radio,
  Row,
  Space,
  TableColumnsType,
  TableProps,
  Typography
} from 'antd'
import React, {useEffect, useState} from 'react'
import FormInput from '../../form-input/FormInput'
import {ExclamationCircleOutlined, SearchOutlined} from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import {isEmpty} from 'lodash'
import {useParams} from 'react-router-dom'
import {createTemplateSection, getListSectionTemplate} from '../../../service/content-list-course/contentListCourseApi'

type Props = {
  open: boolean
  handleClose: () => void
  filterData: any;
  tab: string
  getListDataProps: () => void
}

export default function AddTemplateSectionCourse(props: Props) {
  const {open, handleClose, filterData, tab, getListDataProps} = props

  const [listData, setListData] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const [searchValueSynPopup, setSearchValueSynPopup] = useState<string>('')
  const CheckboxGroup = Checkbox.Group;
  const [form] = Form.useForm()
  const params = useParams()

  const validations = {
    sectionName: {
      required: true,
      message: "Vui lòng chọn chương",
    },
  };

  const renderStatus = (value: string) => {
    switch (Number(value)) {
      case 0:
        return "Bản nháp"
        break;
      case 1:
        return "Sắp ra mắt"
        break;
      case 2:
        return "Miễn phí"
        break;
      case 3:
        return "Trả phí"
        break;
      default:
        return "Bản nháp"
        break;
    }
  }

  const dataColumnsLesson: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên khoá học",
      dataIndex: "courseName",
      key: "courseName",
      sorter: true,
      width: 30,
    },
    {
      title: "Tên chương mẫu",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
    },
    {
      title: "Mô tả",
      dataIndex: "description",
      key: "description",
      width: 30,
    },
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
  };

  const getDataSectionByid = async () => {
    const data = {
      keyword: searchValueSynPopup.trim() ?? null,
      page: pageNumber,
      pageSize: pageSize
    }
    await getListSectionTemplate(data).then((res: any) => {
      setListData(res.data?.content)
      setTotalRecords(res.data?.totalElements)
    })

  }

  const getListData = async () => {
    getDataSectionByid()
  };

  const addNewTemplate = async (values: any) => {
    const arrIdSelectedRowkey = selectedRowKeys.map((item: any) => item.id)

    const data = {
      key: arrIdSelectedRowkey[0],
      courseId: Number(params?.id),
      name: values.sectionName,
      description: values.description,
      status: 0
    }

    await createTemplateSection(data).then((res) => {
      if (res.status === 200) {
        message.success("Tạo chương từ mẫu thành công")
        form.resetFields()
        handleClose()
        setSelectedRowKeys([])
        getListDataProps()
      }
    })
  }

  useEffect(() => {
    getListData();
    // if (!isEmpty(searchValue)) {
    //   setSearchValueSynPopup(searchValue)
    // }
    if (open) {
      setSearchValueSynPopup("")
      setSelectedRowKeys([])
      form.resetFields()
    }
  }, [open, pageNumber, statusOrder]);


  return (
    <Form.Provider onFormFinish={async (name, {values, forms}) => {
      if (name === "add-template-sequence-course-modal") {
        if (isEmpty(selectedRowKeys)) {
          message.error("Bạn chưa chọn chương để tạo mẫu")
          return
        }

        Modal.confirm({
          title: `Xác nhận thêm chương từ mẫu`,
          content: `Bạn có chắc chắn muốn thêm chương từ mẫu?`,
          onOk: () => {
            addNewTemplate(values);
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{color: "#1677FF"}}/>,
          footer: (_, {OkBtn, CancelBtn}) => (
            <>
              <CancelBtn/>
              <OkBtn/>
            </>
          ),
        });
      }
    }}>
      <Modal
        className="add-template-sequence-course-modal"
        title={"Thêm chương từ chương mẫu"}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"80%"}
      >
        <Form
          form={form}
          name="add-template-sequence-course-modal"
          layout="vertical"
        >
          <Row gutter={40} className='p-6'>
            <Col md={{span: 16}} lg={{span: 16}} xl={{span: 16}} xs={{span: 16}}>
              <Form.Item name="search">
                <FormInput
                  placeholder="Nhập từ khoá cần tìm"
                  value={searchValueSynPopup}
                  onChange={(e: any) => setSearchValueSynPopup(e.target?.value)}
                  onKeyPress={(e: any) => setSearchValueSynPopup(e.target?.value)}
                  afterPrefixIcon={<SearchOutlined className='cursor-pointer' onClick={getListData}/>}
                />
              </Form.Item>

              <TableData
                dataTable={listData}
                dataColumns={dataColumnsLesson}
                setPageNumber={setPageNumber}
                selectOneRow={true}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                pageSize={pageSize}
                loadingTable={loading}
                scroll={{x: 1500, y: 600}}
                totalRecords={totalRecords}
                handleTableChangeProps={handleTableChange}
                rowSelected={selectedRowKeys}
                setSelectedRowKeysProps={setSelectedRowKeys}
                pagination={true}
                hideSelection={true}
              />
            </Col>
            <Col md={{span: 8}} lg={{span: 8}} xl={{span: 8}} xs={{span: 8}}>
              <Typography.Title level={5}>Thông tin chương</Typography.Title>
              <div>
                <Form.Item name={"sectionName"} label="Tên chương" rules={[validations.sectionName]}>
                  <FormInput placeholder="Nhập tên chương"/>
                </Form.Item>

                <Form.Item name={"description"} label="Mô tả">
                  <FormInput placeholder="Nhập mô tả"/>
                </Form.Item>

                <Typography.Title level={5}>Trạng thái</Typography.Title>
                <Form.Item>
                  <Radio checked>Bản nháp</Radio>
                </Form.Item>
                <p>Phần này sẽ không được người dùng nhìn thấy</p>
              </div>

              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Lưu
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider>
  )
}
