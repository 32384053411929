import React, { useEffect, useMemo, useRef, useState } from "react";
import './detail.scss';
import {
  AppstoreOutlined,
  ClearOutlined,
  CloseOutlined,
  CloudDownloadOutlined,
  EditOutlined,
  FilterOutlined,
  FontColorsOutlined,
  LayoutOutlined,
  MailOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  ConfigProvider,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { ListQuestion, ListSurveySection } from '../../../../../../types/survey-managment/survey';
import DetailQuestion from "../detail-question/DetailQuestion";
import EditorQuill from "../editor-quill/EditorQuill";
import DrawerCustom from "../../../../../../components/drawer/Drawer";
import { CollapseProps, MenuProps } from "antd/lib";
import ShowListSection from "../right-control/ShowListSection";
import FormItemInput from "../../../../../../components/form-input/FormInput";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import { getSuggestedSurveyQuestion } from "../../../../../../service/survey-managment/mooc-survey/mooc-survey-api";
import { useForm } from "antd/es/form/Form";
import { useAuthStore } from "../../../../../../stores/stores";
import { MoocSurveyContentAnswer, MoocSurveyContentQuestion, MoocSurveyContentSection, MoocSurveyContentSurvey } from "../../../../../../types/survey-managment/mooc-survey-content";
import { useParams } from "react-router-dom";

const { Panel } = Collapse;
interface CustomCollapseProps extends CollapseProps {
  id?: number;
  surveyName?: string;
  courseType?: number;
  surveyStatus?: number;
  isSurveyDescriptionAdded?: boolean;
  surveyDescription?: string | null;
  isAllowedHiddenSurveyObjects?: boolean;
  surveyOpen?: Date | null;
  surveyClose?: Date | null;
  submissionDate?: Date | null;
  submitterId?: Date | null;
  universityId?: number;
  publishedDate?: Date | null;
  publisherId?: number | null;
  publishedRemark?: number | null;
  createdDate?: Date;
  updatedDate?: Date;
  isDeleted?: boolean;
  moocSurveySection?: MoocSurveyContentSection[];
}

interface questionType {
  value: number;
  label: string;
}


const SurveyQuestionSuggestion = (props: any) => {
  const { isOpenCloudQuestion, setIsOpenCloudQuestion, setSelectedItems, listDataExample, selectedItems, isChecked, setIsChecked, setDataSection, dataSection, handleExitAddMoreQuestionExsit } = props
  const [listSurveyQuestionSuggestion, setListSurveyQuestionSuggestion] = useState<MoocSurveyContentSurvey[]>([])
  const [listQuestion, setListQuestion] = useState<MoocSurveyContentSurvey[]>(listSurveyQuestionSuggestion)

  const [isShowQuestion, setIsShowQuestion] = useState<boolean>(false)
  const user = useAuthStore((state) => state.user);
  const param = useParams()

  const optionsQuestionType: questionType[] = [
    {
      value: 1,
      label: "Nhiều lựa chọn văn bản",
    },
    {
      value: 2,
      label: "Nhiều lựa chọn ảnh",
    },
    {
      value: 3,
      label: "Lựa chọn đúng sai",
    },
    {
      value: 4,
      label: "Lựa chọn Dropdown",
    },
    {
      value: 5,
      label: "Nhiều trả lời văn bản",
    },
    {
      value: 6,
      label: "Nhiều trả lời ảnh",
    },
    {
      value: 7,
      label: "Câu hỏi kết nối",
    },
    {
      value: 8,
      label: "Câu trả lời ngắn",
    },
  ];

  const dataRequired = [
    {
      label: "Bắt buộc",
      value: true,
    },
    {
      label: "Tuỳ chọn",
      value: false,
    },
  ];

  const [form] = useForm()
  console.log({ dataSection })

  const fetchSuggestedQuestion = async () => {
    if (user && user.id) {
      const res = await getSuggestedSurveyQuestion(user?.id, Number(param?.id))
      console.log('list survey Suggested')
      console.log({ res })
      const newList = res.data.data.data.filter((survey: MoocSurveyContentSurvey) => {
        return survey &&
          survey.moocSurveySection &&
          survey?.moocSurveySection.length > 0 &&
          survey.moocSurveySection.filter((section: MoocSurveyContentSection) => {
            return section && section.moocSurveyQuestion && section.moocSurveyQuestion.length > 0
              &&
              section.moocSurveyQuestion.filter((question: MoocSurveyContentQuestion) => {
                return question &&
                  question.moocSurveyQuestionAnswer &&
                  question.moocSurveyQuestionAnswer.length > 0
              })
          })
      })
      console.log({ newList })
      // const newList = res?.data.filter((survey: MoocSurveyContentSurvey) => {
      //   return survey.moocSurveySection
      //     && survey.moocSurveySection.length > 0
      //     && survey.moocSurveySection.filter((section) =>
      //       section.moocSurveyQuestion && section.moocSurveyQuestion.length > 0
      //     )
      // })
      // console.log({ newList })
      setListSurveyQuestionSuggestion(res.data.data.data)

    }
  }
  useEffect(() => {
    fetchSuggestedQuestion()
  }, [user?.id, param?.id])


  const handleShowQuestion = () => {
    setIsShowQuestion(!isShowQuestion);
  }
  const handleResetFieldFilter = () => {
    form.resetFields()
    fetchSuggestedQuestion()

  }

  const onFinish = async (values: any) => {
    console.log({ values });
    const newList = listSurveyQuestionSuggestion.filter((survey) => {
      return survey.surveyName?.toLocaleLowerCase().includes(values?.surveyName?.toLocaleLowerCase())
    })
    console.log({ newList });
    setListSurveyQuestionSuggestion(newList);

  };

  console.log('listSurveyQuestionSuggestion......')
  console.log({ listSurveyQuestionSuggestion })

  return (
    <div className="survey-managment-question-suggestion">
      <div style={{ marginBottom: "30px", paddingBottom: '15px', borderBottom: "1px solid #c2c2c2" }}>
        <Form name="filterForm"
          onFinish={onFinish}
          form={form}
        >
          <Row gutter={16}>
            <Col className="gutter-row" span={18}>
              <Form.Item name="surveyName">
                <FormItemInput placeholder="Nhập tên đợt khảo sát" />
              </Form.Item>
            </Col>
            {/* <Col className="gutter-row" span={6}>
              <Form.Item name="questionName">
                <FormItemInput placeholder="Nhập tên câu hỏi" />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={6}>
              <Form.Item name="questionType">
                <FormItemDropdown
                  modeDropdown="multiple"
                  placeholder="Loại câu hỏi"
                  options={optionsQuestionType}
                />
              </Form.Item>
            </Col> */}
            <Col className="gutter-row" span={6}>
              <div className="group-btn--filter">
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>
                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={handleResetFieldFilter}
                >
                  <ClearOutlined />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      {listSurveyQuestionSuggestion.length > 0 && listSurveyQuestionSuggestion.map((item: MoocSurveyContentSurvey, index: any) => {
        return (
          <>
            {item.moocSurveySection && item.moocSurveySection.length > 0 && (
              <Collapse
                key={item.id}
                activeKey={isShowQuestion ? ['1'] : undefined}
                style={{ marginBottom: 18 }}
                expandIcon={({ isActive }) =>
                  <PlusOutlined rotate={isActive ? 90 : 0} />}
              >
                <Panel
                  header={item.surveyName}
                  className="header" key="1"
                >
                  {item.moocSurveySection &&
                    item.moocSurveySection.length > 0 &&
                    item.moocSurveySection?.map((section) => {
                      // console.log('csssshein')
                      // console.log({ section })
                      return (
                        <div key={section.id} className="container-section">
                          <span
                            className="section-name"
                          >
                            {/* {`${section.moocSurveyQuestion &&
                              section.moocSurveyQuestion.length > 0 ?
                              section.sectionName :
                              ''}`} */}
                            {section.sectionName}
                          </span>
                          {section.moocSurveyQuestion && section.moocSurveyQuestion?.length > 0 && (
                            <ShowListSection
                              index={index}
                              listQuestion={section.moocSurveyQuestion}
                              setSelectedItems={setSelectedItems}
                              selectedItems={selectedItems}
                              isChecked={isChecked}
                              setIsChecked={setIsChecked}
                              setDataSection={setDataSection}
                              handleExitAddMoreQuestionExsit={handleExitAddMoreQuestionExsit}
                            />
                          )}
                        </div>
                      )
                    })}
                </Panel>
              </Collapse>
              // </div>

            )}
          </>
        );
      })}
    </div>
  )
}

export default SurveyQuestionSuggestion