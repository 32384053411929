import React, {useEffect, useRef} from "react";
import "scorm-again/dist/scorm2004.js";

const Scorm = (props: any) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const s3BaseUrl = `${process.env.REACT_APP_S3_BASE_URL}`;
    let src = props.src || '';
    src = src.replace(s3BaseUrl, '/s3').split('?')[0];

    useEffect(() => {
        const iframe = iframeRef.current;

        if (iframe) {
            iframe.src = 'about:blank';

            const onLoadHandler = () => {
                const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

                if (iframeDocument) {
                    const settings = {};
                    // @ts-ignore
                    window.API_1484_11 = new Scorm2004API(settings);
                    iframe.src = src;
                }

                iframe.removeEventListener('load', onLoadHandler);
            };

            iframe.addEventListener('load', onLoadHandler);
        }
    }, []);
    return (
        <div>
            <iframe
                ref={iframeRef}
                src=""
                allow="fullscreen"
                scrolling="yes"
                width="100%"
                height="900px"
            />
            <br />
        </div>
    );
}

export default Scorm;