// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-question-suggestion .survey-name {
  font-size: 20px;
  margin-bottom: 15px; }

.survey-managment-question-suggestion .ant-space-item > span {
  width: 100%; }

.survey-managment-question-suggestion .container-section {
  margin-left: 20px;
  margin-top: 10px; }

.survey-managment-question-suggestion .section-name {
  font-size: 15px; }

.survey-managment-question-suggestion .question {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.survey-managment-question-suggestion .section-survey {
  margin-bottom: 18px; }

.survey-managment-question-suggestion .header {
  font-size: 18px;
  font-weight: 500; }

.survey-managment-question-suggestion .collapse {
  display: flex;
  align-items: center; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/survey-question-suggestion/detail.scss"],"names":[],"mappings":"AAAA;EAEI,eAAmB;EACnB,mBAAmB,EAAA;;AAHvB;EAOI,WAAW,EAAA;;AAPf;EAWI,iBAAiB;EACjB,gBAAiB,EAAA;;AAZrB;EAgBI,eAAe,EAAA;;AAhBnB;EAsBI,aAAqB;EACrB,mBAAuB;EACvB,8BAA8B,EAAA;;AAxBlC;EA4BI,mBAAmB,EAAA;;AA5BvB;EAgCI,eAAiB;EACjB,gBAAgB,EAAA;;AAjCpB;EAqCI,aAAiB;EACjB,mBAAmB,EAAA","sourcesContent":[".survey-managment-question-suggestion {\n  .survey-name {\n    font-size    : 20px;\n    margin-bottom: 15px;\n  }\n\n  .ant-space-item>span {\n    width: 100%;\n  }\n\n  .container-section {\n    margin-left: 20px;\n    margin-top : 10px;\n  }\n\n  .section-name {\n    font-size: 15px;\n\n    // margin-left: 20px;\n  }\n\n  .question {\n    display        : flex;\n    align-items    : center;\n    justify-content: space-between;\n  }\n\n  .section-survey {\n    margin-bottom: 18px;\n  }\n\n  .header {\n    font-size  : 18px;\n    font-weight: 500;\n  }\n\n  .collapse {\n    display    : flex;\n    align-items: center;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
