import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Dropdown, MenuProps, Row, TableColumnType, Typography } from 'antd';

import './filter-table.scss'
import { DownOutlined, PlusOutlined, SearchOutlined, SettingOutlined, UserAddOutlined } from "@ant-design/icons";

import FormInput from '../../form-input/FormInput';
import { Permission } from '../../../layout/sidebar/permission.enum';
import { CheckRole, getPermissions } from '../../../utils/checkRole';


type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  tab: number,
  handleChangeInput: (e: any) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  dataColumns: TableColumnType<any>[]
  selectedRowKeys: React.Key[]
  displayAddPortalPage: boolean
}


const FilterTableCourseConstruction: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  tab,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  displayAddPortalPage,
}) => {

  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]}>
          <Col md={{ span: displayAddPortalPage ? 12 : 24 }} lg={{ span: displayAddPortalPage ? 12 : 24 }} xl={{ span: displayAddPortalPage ? 12 : 24 }} xs={{ span: displayAddPortalPage ? 12 : 24 }}>
            <div className="heading-search">
              <FormInput placeholder="Tìm cổng thông tin" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          {
            displayAddPortalPage ?
              <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 12 }}>
                <div className="heading-resigter">
                  <Button onClick={handleOpen} className="btn btn-primary" disabled={tab === 2}>
                    <PlusOutlined />
                    <Typography.Text>Thêm cổng thông tin</Typography.Text>
                  </Button>
                </div>
              </Col> : null
          }
        </Row>
      </div >
    </>
  );
};

export default FilterTableCourseConstruction;
