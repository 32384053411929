import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getAdminInterface: (
  data: any
) => Promise<AxiosResponse<any>> = (data: any) => {
  return axiosConfigV2.post(`/mooc-admin-interface/search`, data);
};

export const getDetailAdminInterface: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id: string | undefined) => {
  return axiosConfigV2.get(`/mooc-admin-interface/${id}`);
};

export const deleteAdminInterface: (
  id: string | undefined
) => Promise<AxiosResponse<any>> = (id: string | undefined) => {
  return axiosConfigV2.delete(`/mooc-admin-interface/${id}`);
};

export const putSettingAdminInterface: (id: string,
  data: any
) => Promise<AxiosResponse<any>> = (id: string, data: any) => {
  return axiosConfigV2.put(`/mooc-admin-interface/${id}`, data);
};

export const saveTemplateAdminInterface: (id: string,
  data: any
) => Promise<AxiosResponse<any>> = (id: string, data: any) => {
  return axiosConfigV2.put(`/mooc-admin-interface/save-sample-template/${id}`, data);
};

export const copyTemplateAdminInterface: (id: string
) => Promise<AxiosResponse<any>> = (id: string) => {
  return axiosConfigV2.post(`/mooc-admin-interface/copy/${id}`);
};

