import { Card, Flex } from "antd";
import React from "react";

const HeadSynthesisReportSystem = ({ headValue }: any) => {
  return (
    <div className="px-3 pt-6 mx-3">
      <Card className="">
        <Flex className="w-full" justify="center" wrap="wrap">
          {headValue?.map((dataReport: any, index: number) => (
            <div
              className={`${
                index + 1 === headValue.length ? "" : "sm:border-r"
              } px-6 w-full mb-2 xl:mb-0 sm:w-fit`}
              key={index}
            >
              <p className="w-full">{dataReport.label}</p>
              <p className="font-medium text-lg">{dataReport?.value}</p>
            </div>
          ))}
        </Flex>
      </Card>
    </div>
  );
};

export default HeadSynthesisReportSystem;
