import {DownOutlined, PlusOutlined, SearchOutlined, UploadOutlined, UpOutlined} from '@ant-design/icons';
import {Button, Col, Row, Typography} from 'antd';
import React, {ChangeEvent, KeyboardEvent} from 'react';
import FormInput from "../../../../../components/form-input/FormInput";


type FilterTableProps = {
  showFilter?: boolean
  searchValue?: string
  handleChangeInput?: (e: ChangeEvent<HTMLInputElement>) => void
  handleSearch?: (e: KeyboardEvent<HTMLSpanElement>) => void
  handleSubmitSearch?: () => void
  handleOpenFilter?: () => void
  handleOpen?: () => void
  handleOpenModal?: () => void
};


const FilterFormTable = ({
                           showFilter,
                           handleOpenFilter,
                           searchValue,
                           handleChangeInput,
                           handleSearch,
                           handleSubmitSearch,
                           handleOpen,
                           handleOpenModal
                         }: FilterTableProps) => {
  return (
    <div className="history-table-style">
      <div className="heading-table">
        <Row gutter={[{md: 8, xl: 8, xs: 8}, 8]}>
          <Col>
            <div className="heading-table-filter">
              <Button className={`filter-button ${showFilter ? 'open' : ''}`} onClick={handleOpenFilter}>
                <Typography.Text className="filter-text">Bộ lọc</Typography.Text>
                {showFilter ? <UpOutlined/> : <DownOutlined/>}
              </Button>
            </div>
          </Col>
          <Col>
            <div className="heading-table-search">
              <FormInput
                placeholder="Nhập từ khoá cần tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch}/>
                }
              />
            </div>
          </Col>
          <Col>
            <Button onClick={handleOpen}>
              <PlusOutlined/>
              <Typography.Text>Thêm</Typography.Text>
            </Button>
          </Col>
          <Col>
            <Button onClick={handleOpenModal}>
              <UploadOutlined/>
              <Typography.Text>Import</Typography.Text>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FilterFormTable;
