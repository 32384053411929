import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  MenuProps,
  Row,
  TableColumnType,
  Typography,
} from "antd";

import "./filter-table.scss";
import {
  DownOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { CheckboxOptionType } from "antd/lib";
import FormItemDropdown from "../../form-dropdown/FormItemDropdown";
import FormInput from "../../form-input/FormInput";

type FilterTableProps = {
  handleOpen: () => void;
  setlastPreview: (value: any) => void;
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
  checkedList: Array<string>;
  setCheckedList: (value: string[]) => void;
  dataColumns: TableColumnType<any>[];
  selectedRowKeys: React.Key[];
  tab: string
};

const FilterAdminInterface: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  dataColumns,
  checkedList,
  setCheckedList,
  selectedRowKeys,
  setlastPreview,
  tab
}) => {
  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]} className="row-filter">
          <Col
            md={{ span: 3 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
            xs={{ span: 3 }}
            className="filter-group-select"
          >
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>Bộ lọc</Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col
            md={{ span: 8 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xs={{ span: 8 }}
          >
            <div className="heading-search">
              <FormInput
                placeholder="Nhập từ khoá cần tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterAdminInterface;
