import React, { useEffect, useState } from 'react';
import StartScreen from './components/StartScreen';
import ControlPanel from './components/ControlPanel';
import PauseScreen from './components/PauseScreen';
import FinishScreen from './components/FinishScreen';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ResultQuizLayout from './view-results';
import './style.scss';
import { isEmpty, toNumber } from 'lodash';
import { useDirty } from '../../../contexts/DirtyProvider';
import { useViewport } from '../../../hooks/useViewport';
import useModalConfirm from '../../../hooks/useModalConfirm';
import useKeyword from '../../../hooks/useKeyword';
import {
  ExamType,
  FinishResult,
  GroupsExam,
  PartBlock,
  QuizProps,
  QuizRequest,
  ResultExam,
  UnitInfoProps,
} from '../../../types/course';
import { notifySuccess, notifyWarning } from '../../../utils/notification';
import { DEFAULT_LOADING_TIME, TypeCreateQuestion } from '../../../constants';
import QuizLayout from './quiz';
import { useAddLesson } from '../../../stores/stores';
import {
  getAllQuizBySurvey,
  getAllQuizByUnit,
} from '../../../service/course-plan-managment/mooc-course-block-quiz/mooc-course-block-quiz';
import { Button } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import MoocMenu from '../../../components/menu/MoocMenu';
import { getQuizById } from '../../../service/course-construct';

interface QuizScreenProps {
  onChangeMenuMode?: (v?: any) => void;
}

function QuizScreen({ onChangeMenuMode = () => { } }: QuizScreenProps) {
  const { setDirty } = useDirty();
  const { width } = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const showConfirm = useModalConfirm();

  var indexDB = require('idbcache').default;
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const searchParams = new URLSearchParams(location.search);
  // data of exam
  const blockId = useKeyword('unitId');
  const [blockInfo, setBlockInfo] = useState<UnitInfoProps>();
  const [currentQuestion, setCurrentQuestion] = useState<QuizProps>();
  const [groups, setGroups] = useState<GroupsExam[]>();
  const [quizzesFlat, setQuizzesFlat] = useState<QuizProps[]>([]);
  const [quizzesCompleted, setQuizzesCompleted] = useState<QuizRequest[]>([]);
  const [quizzesHasTimeCompleted, setQuizzesHasTimeCompleted] = useState<
    number[]
  >([]);
  const [results, setResult] = useState<ResultExam>();
  const [menuBlock, setMenuBlock] = useState<any[]>([]);

  // control
  const currentScreen = useKeyword('screen') || 'train';
  const [isPausing, setIsPausing] = useState<boolean>(true);
  const [finishResult, setFinishResult] = useState<FinishResult>();
  const [sessionId, setSessionId] = useState<string>('');
  const [dateTimePause, setDateTimePause] = useState<number>(0);
  const [allTimeToCompleted, setAllTimeToCompleted] = useState<number>(0);
  const [timeDeadline, setTimeDeadline] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [typeOfFinish, setTypeOfFinish] = useState<
    'userFinish' | 'timeFinish' | ''
  >('');
  const [mode, setMode] = useState<
    'start' | 'learn' | 'end' | 'pause' | 'finish' | 'result'
  >('start');
  const [questions, setQuestion] = useState<PartBlock>(); //no need

  // Xử lý cho câu hỏi có thời gian riêng
  const [isTrainingQuizHasTime, setIsTrainingQuizHasTime] = useState(false);
  const [timeOfCurrentQuiz, setTimeOfCurrentQuiz] = useState<number>(0);

  const [active, setActive] = useState<number>(0);
  const [activeParent, setActiveParent] = useState<string[]>([]);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);

  useEffect(() => {
    indexDB.get('current-course').then((val: any) => {
      const idActive = JSON.parse(val).selectedQuizz.id;
      if (idActive) {
        handleStart();
      }
    });
  }, []);

  const getDetailQuizSurveyPlan = async (id?: number, quizz?: any) => {
    try {
      const res = await getQuizById(id?.toString());
      const { data, status } = res;
      if (status === 200) {
        console.log('res', data);
        console.log('rez', quizz);

        // setCurrentQuestion
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getSelectedQuiz = (
    selectedId: number,
    dataGroup: GroupsExam[]
  ): QuizProps => {
    return dataGroup
      .flatMap((group: { quizzes: any }) => group.quizzes)
      .find((quiz: { id: number }) => quiz.id === selectedId);
  };

  const handleSetActiveParent = (parentKey: string) => {
    setActiveParent([`parent-${parentKey}`]);
  };

  const handleStart = async (type?: 'start' | 'retrain') => {
    if (type === 'retrain') {
      setQuizzesCompleted([]);
    }
    try {
      let groups: GroupsExam[] = [];
      if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
        const res = await getAllQuizByUnit({ id: Number(blockId) });
        setMenuBlock(res.data);
        groups = res.data;
        indexDB.get('current-course').then((val: any) => {
          handleSetActiveParent(JSON.parse(val).selectedQuizz?.groupId);
          setActive(JSON.parse(val).selectedQuizz?.id);
        });
      }
      if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
        const res = await getAllQuizBySurvey({ id: Number(blockId) });
        setMenuBlock(groups);
        groups = res.data;
        indexDB.get('current-course').then((val: any) => {
          handleSetActiveParent(JSON.parse(val).selectedQuizz?.groupId);
          setActive(JSON.parse(val).selectedQuizz?.id);
        });
      }
      if (groups && groups.length !== 0) {
        initialQuiz(groups);
        setGroups(groups);

        for (let i = 0; i < groups?.length; i++) {
          setQuizzesFlat((prevState: QuizProps[]) => [
            ...prevState,
            ...groups[i]?.quizzes,
          ]);
        }

        setIsPausing(false);
        setMode('learn');
      }

      // bật mode full screen đối với các dạng bài kiểm tra & bài thi
      if (blockInfo?.examType && blockInfo?.examType !== ExamType.EXERCISE) {
        // setIsFullscreenQuiz(true);
        onChangeMenuMode(false);
        document.documentElement.requestFullscreen();
      }
    } catch (err: any) {
      console.error(err);
      notifyWarning(err?.response?.data?.message);
    }
    // }
  };

  const initialQuiz = (groups: GroupsExam[]) => {
    if (groups && groups?.length > 0) {
      for (let i = 0; i < groups?.length; i++) {
        if (groups[i]?.quizzes && groups[i]?.quizzes?.length > 0) {
          indexDB.get('current-course').then((val: any) => {
            if (JSON.parse(val).selectedQuizz) {
              setCurrentQuestion(
                getSelectedQuiz(JSON.parse(val).selectedQuizz.id, groups)
              );
            } else {
              setCurrentQuestion(groups[i]?.quizzes[0]);
            }
          });
          break;
        }
      }
    }
  };

  const handlePause = () => {
    showConfirm({
      title: 'Xác nhận tạm dừng làm bài',
      description: 'Bạn có chắc chắn muốn tạm dừng làm bài?',
      okText: 'Tạm dừng',
      cancelText: 'Huỷ',
      type: 'confirm',
      icon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/pause-primary-icon.svg`}
        />
      ),
      onOk: () => pause(),
    });
  };

  const handleFinish = () => {
    showConfirm({
      title: 'Xác nhận nộp bài',
      description: 'Bạn có chắc chắn muốn nộp bài không?',
      okText: 'Nộp bài',
      cancelText: 'Huỷ',
      type: 'confirm',
      icon: (
        <img
          src={`${process.env.PUBLIC_URL}/assets/icons/pause-primary-icon.svg`}
        />
      ),
      onOk: async () => {
        await finishExam('userFinish');
      },
    });
  };

  const finishExam = async (type: 'userFinish' | 'timeFinish') => {
    const payload = {
      blockId: blockId,
      quizRequests: quizzesCompleted,
    };
    try {
      // const res = await submitExam(payload);
      // setFinishResult(res.data.data);
      setMode('finish');
      setIsPausing(true);
      setTypeOfFinish(type);
      setCurrentQuestion(undefined);
      setQuizzesCompleted([]);
      setQuizzesFlat([]);
      setGroups([]);
      setDirty(false);
      setQuizzesHasTimeCompleted([]);
      if (type === 'userFinish') {
        notifySuccess('Nộp bài thành công');
      }
      setTimeout(() => handleMarkExam(), 1000);
    } catch (err: any) {
      if (
        err?.response?.data?.message ===
        'Bạn cần phải trả lời tất cả các câu hỏi yêu cầu của bài kiểm tra này'
      ) {
        showConfirm({
          title: 'Nộp bài không thành công!',
          description:
            'Vui lòng hoàn thiện các câu hỏi bắt buộc trước khi tiến hành nộp bài',
          okText: 'Tiếp tục làm bài',
          type: 'notify',
          icon: (
            <img
              src={`${process.env.PUBLIC_URL}/assets/icons/x-icon-red.svg`}
            />
          ),
        });
      }
      if (
        err?.response?.data?.message === 'Đã hết thời gian làm bài kiểm tra này'
      ) {
        setMode('finish');
        setIsPausing(true);
        setTypeOfFinish('timeFinish');
        setCurrentQuestion(undefined);
        setQuizzesCompleted([]);
        setQuizzesFlat([]);
        setGroups([]);
        setDirty(false);
      }
    }
  };

  const handleMarkExam = async () => {
    // await examMark({
    //   blockId: blockId,
    //   sessionId: sessionId,
    // });
  };

  const pause = async () => {
    setMode('pause');
    setIsPausing(true);
    setDateTimePause(Date.now());
  };

  const handleContinue = async () => {
    setMode('learn');
    if (sessionId && blockId) {
      setIsPausing(false);
      setMode('learn');
      // const res = await continueExam({
      //   sessionId: sessionId,
      //   blockId: blockId,
      // });
      // setAllTimeToCompleted(res?.data?.data?.timeToCompleted);
      // const continueGroup = res.data?.data?.groups;

      let currentQuiz = null;
      // if (continueGroup && continueGroup?.length > 0) {
      //   for (let i = 0; i < continueGroup?.length; i++) {
      //     const currQuizzes = continueGroup[i]?.quiz;
      //     if (currQuizzes && currQuizzes?.length > 0) {
      //       for (let j = 0; j < currQuizzes?.length; j++) {
      //         if (currentQuestion?.id === currQuizzes[j]?.id) {
      //           currentQuiz = currQuizzes[j];
      //           break;
      //         }
      //       }
      //     }
      //   }
      // }
      if (currentQuiz) {
        // setTimeOfCurrentQuiz(currentQuiz?.timeToCompleted);
      }
    }
  };

  const handleNextQuiz = () => {
    if (quizzesFlat && currentQuestion) {
      // save quiz has time is completed
      if (
        quizzesCompleted?.find(
          (item: QuizRequest) => item.quizId === currentQuestion?.id
        )
      ) {
        setQuizzesHasTimeCompleted((prevState: number[]) => [
          ...prevState,
          currentQuestion?.id,
        ]);
      }

      // next quiz
      const currentIndexQuiz = quizzesFlat?.findIndex(
        (quiz: QuizProps) => quiz.id === currentQuestion?.id
      );
      if (currentIndexQuiz !== -1) {
        const nextQuiz = quizzesFlat[currentIndexQuiz + 1];
        if (nextQuiz) setCurrentQuestion(nextQuiz);
      }
    }
  };

  const handlePreviousQuiz = () => {
    if (quizzesFlat && currentQuestion) {
      // save quiz has time is completed
      if (
        quizzesCompleted?.find(
          (item: QuizRequest) => item.quizId === currentQuestion?.id
        )
      ) {
        setQuizzesHasTimeCompleted((prevState: number[]) => [
          ...prevState,
          currentQuestion?.id,
        ]);
      }
      // prev quiz
      const currentIndexQuiz = quizzesFlat?.findIndex(
        (quiz: QuizProps) => quiz.id === currentQuestion?.id
      );
      if (currentIndexQuiz !== -1) {
        const prevQuiz = quizzesFlat[currentIndexQuiz - 1];
        if (prevQuiz) setCurrentQuestion(prevQuiz);
      }
    }
  };

  const disableNextBtn = () => {
    return currentQuestion?.id === quizzesFlat[quizzesFlat?.length - 1]?.id;
  };

  const disablePrevBtn = () => {
    return currentQuestion?.id === quizzesFlat[0]?.id;
  };

  const renderTrainLayout = () => {
    switch (mode) {
      case 'start':
        return (
          <StartScreen
            // loading={loading}
            // blockInfo={blockInfo}
            // getMaxDate={setTimeDeadline}
            onStart={() => handleStart('start')}
          />
        );
      case 'learn':
        return (
          <QuizLayout
            loading={loading}
            quizzesHasTimeCompleted={quizzesHasTimeCompleted}
            isPausing={isPausing}
            quizzesCompleted={quizzesCompleted}
            quizzes={quizzesFlat}
            disableNextBtn={disableNextBtn()}
            disablePrevBtn={disablePrevBtn()}
            quiz={currentQuestion}
            isTrainingQuizHasTime={isTrainingQuizHasTime}
            timeOfCurrentQuiz={timeOfCurrentQuiz}
            onNext={handleNextQuiz}
            onPrevious={handlePreviousQuiz}
            onPause={handlePause}
            onFinish={handleFinish}
            onChange={(data: QuizRequest[]) => {
              setQuizzesCompleted(data);
              setDirty(true);
            }}
          />
        );
      case 'result':
        return (
          <ResultQuizLayout
            // groups={groups}
            // results={results}
            // loading={loading}
            data={currentQuestion}
            // quizzes={quizzesFlat}
            // sessionId={sessionId}
            onBack={() => {
              setMode('finish');
              navigate(`${location.pathname}?blockId=${blockId}&screen=train`);
            }}
          // onChangeHistory={(ssId: string) => handleViewResult(ssId)}
          />
        );
      case 'pause':
        return (
          <PauseScreen
            timeDeadline={timeDeadline}
            dateTimePause={dateTimePause}
            onContinue={handleContinue}
          />
        );
      case 'finish':
        return (
          <FinishScreen
            // type={typeOfFinish}
            timeTraining={
              finishResult?.totalTrainTime || blockInfo?.totalTimeTrain
            }
            // isAbleToRetry={
            //   finishResult?.isAbleToRetry || blockInfo?.isAbleToRetry
            // }
            // isShowResult={finishResult?.isShowResult || blockInfo?.isShowResult}
            // answerSendTime={
            //   finishResult?.answerSendTime || blockInfo?.answerSendTime
            // }
            onRetrain={() => handleStart('retrain')}
          // onViewResult={() => handleViewResult(sessionId)}
          />
        );
      default:
        break;
    }
  };

  const handleChangeQuizPanel = (quiz: QuizProps) => {
    setCurrentQuestion(quiz);
  };

  const renderContainer = () => {
    return (
      <div className='structure-content flex'>
        <div
          className={`structure-sidebar ${showSidebar ? 'sidebar-open' : 'sidebar-hidden'
            }`}
        >
          <MoocMenu
            // change later
            data={groups as any}
            mode='small'
            active={active}
            activeParent={activeParent}
            setActive={setActive}
            setActiveParent={handleSetActiveParent}
            onClick={handleChangeQuizPanel}
          />
        </div>
        <div className='train-layout flex-1'>{renderTrainLayout()}</div>

        {(mode === 'learn' || mode === 'result' || mode === 'pause') && (
          <ControlPanel
            disabled={isTrainingQuizHasTime}
            quizzesCompleted={quizzesCompleted}
            totalQuiz={quizzesFlat?.length}
            timeToCompleted={allTimeToCompleted}
            currentQuestion={currentQuestion}
            groups={groups}
            pause={isPausing}
            onTimeEnd={() => finishExam('timeFinish')}
            onChange={handleChangeQuizPanel}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        // setIsFullscreenQuiz(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    // Xử lý câu hỏi có đếm thời gian riêng
    if (currentScreen === 'train') {
      if (!isEmpty(currentQuestion)) {
        const timeToCompleted = currentQuestion?.timeToCompleted;
        if (
          timeToCompleted &&
          !quizzesCompleted?.find(
            (item: QuizRequest) => item.quizId === currentQuestion?.id
          )
        ) {
          const startSingle = async () => {
            try {
              setLoading(true);
              // await startSingleQuiz({
              //   blockId: blockId,
              //   quizId: currentQuestion?.id,
              // });
              setTimeOfCurrentQuiz(timeToCompleted);
            } catch (err: any) {
            } finally {
              setLoading(false);
            }
          };
          startSingle();
        }
      }
    }
  }, [currentQuestion]);

  useEffect(() => {
    // Get unit info trước khi start
    const getUnitInfo = async () => {
      setLoading(true);
      setDirty(false);
      if (blockId && isEmpty(blockInfo)) {
        try {
          // const res = await getUnitDetails(blockId);
          // const blockIf = res.data.data;
          // setBlockInfo(blockIf);
          // setSessionId(blockIf?.sessionId);
          // if (blockIf?.complete) {
          //   setMode('finish');
          //   setTypeOfFinish(
          //     blockIf?.submitTime === 0 ? 'userFinish' : 'timeFinish'
          //   );
          // }
          // nếu là bài tập mở và không giới hạn thời gian => view
          // if (
          //   blockIf?.examType === ExamType.EXERCISE &&
          //   !blockIf?.isLimitTimeToDo
          // ) {
          //   handleStart('start');
          // }
        } catch (err) {
          console.error(err);
        } finally {
          setTimeout(() => setLoading(false), DEFAULT_LOADING_TIME);
        }
      } else setLoading(false);
    };
    getUnitInfo();

    return () => setDirty(false);
  }, [blockId]);

  useEffect(() => {
    if (width < 1200) {
      onChangeMenuMode(false);
    }
  }, [width]);

  return (
    <div className='course-structure mb-5'>
      <div className='structure-header w-full flex gap-24'>
        <Button
          className={`h-40 mb-2 w-full text-center font-weight-6 ${showSidebar ? 'btn-toggle-menu' : 'btn-hide'
            }`}
          icon={
            <UnorderedListOutlined className='font-weight-6 font-size-16' />
          }
          onClick={() => setShowSidebar(!showSidebar)}
        >
          {showSidebar ? ' Ẩn cửa số menu' : ''}
        </Button>
        <h3 className='font-size-24 line-height-32 mb-2'>
          {/* <b>Bài tập 02: Khoá học Figma cơ bản</b> change later */}
        </h3>
      </div>

      {renderContainer()}
    </div>
  );
}

export default QuizScreen;
