import { Modal, Radio, Select, Space, Table, TableColumnsType } from "antd";
import { RadioChangeEvent } from "antd/lib";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  exportExcelGradeCount,
  exportExcelGradeRatio,
  getGradeRatio,
  getQuizByCourse,
  getTranscriptStatistic,
} from "../../../../../service/course-management";
import "./TranscriptManagement.scss";

type Props = {};

interface Select {
  value: number;
  label: string;
}

interface DataType {
  key: string;
  category: string;
  passCount: number;
  successPercentage: number;
  failCount: number;
  failPercentage: number;
  total: number;
}
const TranscriptManagement = (props: Props) => {
  const { id } = useParams();
  const [quizList, setQuizList] = useState<Select[]>([]);
  const [quizId, setQuizId] = useState<number>(0);
  const [chartData, setChartData] = useState<any[]>([]);
  const [gradeRatioList, setGradeRationList] = useState<any[]>([]);
  const [loadingRatio, setLoadingRatio] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [exportOption, setExportOption] = useState("Excel");
  const chartRef = useRef<any>(null);

  const fetchChartData = async () => {
    let queryString = id;

    if (quizId) {
      queryString += `?blockId=${quizId}`;
    }
    const res = await getTranscriptStatistic(queryString);
    setChartData(res?.data.data.grades);
  };

  const fetchQuizByCourse = async () => {
    const res = await getQuizByCourse(id);
    setQuizList(
      res.data.data.map((it: any) => ({
        value: it.blockId,
        label: it.name,
      }))
    );
  };

  const fetchGradeRatio = async () => {
    setLoadingRatio(true);
    try {
      const res = await getGradeRatio(id);
      setGradeRationList(res.data.data);
      setLoadingRatio(false);
    } catch (error) {
      setLoadingRatio(false);
    }
  };

  const formatScore = (score: number): string => {
    return score < 10 ? `0${score}` : `${score}`;
  };

  const processData = (
    chartData: {
      points: number;
      scoreText: string | null;
      isSuccess: boolean;
      count: number;
    }[]
  ) => {
    const fullData = [];
    for (let i = 0; i <= 10; i++) {
      const dataPoint = chartData.find((item) => item.points === i);
      fullData.push({
        points: i,
        count: dataPoint ? dataPoint.count : 0,
      });
    }
    return fullData;
  };

  const data = processData(chartData);

  const chartOptions: Highcharts.Options = {
    chart: {
      type: "area",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: data.map((item) => formatScore(item.points)),
      title: {
        text: "Phổ điểm",
      },
      labels: {
        formatter: function () {
          return formatScore(Number(this.value));
        },
      },
      min: 0,
    },
    yAxis: {
      title: {
        text: "Số lượng",
      },
      min: 0,
      allowDecimals: false,
      labels: {
        formatter: function () {
          return this.value.toString();
        },
      },
    },
    tooltip: {
      formatter: function (
        this: Highcharts.TooltipFormatterContextObject
      ): string {
        return `<b>Điểm ${this.x}: </b><b>${this.y}</b> học viên`;
      },
    },
    plotOptions: {
      series: {
        pointStart: 0,
        marker: {
          enabled: false,
        },
      },

      area: {
        pointStart: 0,
        stacking: "normal",
        lineColor: "#1677FF",
        lineWidth: 1,
        color: "#91D5FF",
      },
    },
    series: [
      {
        type: "area",
        name: "Điểm",
        data: data.map((item) => item.count),
      },
    ],
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
      },
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setIsModalVisible(false);

    if (chartRef.current) {
      if (exportOption === "PNG") {
        chartRef.current.chart.exportChart({
          type: "image/png",
          filename: "report",
        });
      } else if (exportOption === "PDF") {
        chartRef.current.chart.exportChart({
          type: "application/pdf",
          filename: "report",
        });
      } else {
        let queryString = id;

        if (quizId) {
          queryString += `?blockId=${quizId}`;
        }
        try {
          const res = await exportExcelGradeCount(queryString);

          const fileData = res.data;

          const blob = new Blob([fileData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);

          downloadLink.download = "Thống kê theo trạng thái học tập.xlsx";

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (error) {
          console.error("Error downloading Excel file:", error);
        }
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleExportOptionChange = (e: RadioChangeEvent) => {
    setExportOption(e.target.value);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Đạt",
      children: [
        {
          title: "Số lượng",
          dataIndex: "successCount",
          key: "successCount",
        },
        {
          title: "Tỉ lệ",
          dataIndex: "successPercentage",
          key: "successPercentage",
          render: (_: any, record, index: number) => {
            return <div>{record?.successPercentage}%</div>;
          },
        },
      ],
    },
    {
      title: "Chưa đạt",
      children: [
        {
          title: "Số lượng",
          dataIndex: "failCount",
          key: "failCount",
        },
        {
          title: "Tỉ lệ",
          dataIndex: "failPercentage",
          key: "failPercentage",
          render: (_: any, record, index: number) => {
            return <div>{record?.failPercentage}%</div>;
          },
        },
      ],
    },
    {
      title: "Tổng",
      dataIndex: "totalCount",
      key: "totalCount",
    },
  ];
  useEffect(() => {
    fetchQuizByCourse();
    fetchGradeRatio();
  }, []);

  useEffect(() => {
    fetchChartData();
  }, [quizId]);

  const onSecondCityChange = (value: number) => {
    setQuizId(value);
  };
  const handleExportExcelGradeRatio = async () => {
    try {
      const res = await exportExcelGradeRatio(Number(id));
      const fileData = res.data;

      const blob = new Blob([fileData], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      downloadLink.download = "Thống kê theo trạng thái học tập.xlsx";

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error downloading Excel file:", error);
    }
  };
  return (
    <div className="transcript-management">
      <div className="content-card">
        <div className="content-card-name">
          <div>
            <div className="title">Thống kê phổ điểm của học viên</div>
            <div className="description">Tổng số học viên đã hoàn thành</div>
          </div>
          <div className="action" onClick={showModal}>
            Xuất báo cáo
          </div>
        </div>
        <div className="chart">
          <Select
            style={{ minWidth: 320, marginTop: "16px" }}
            value={quizId}
            onChange={onSecondCityChange}
            options={[
              {
                label: "Cả môn",
                value: 0,
              },
              ...quizList,
            ]}
          />
          <div className="hr-tag"></div>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            ref={chartRef}
          />
        </div>
      </div>
      <div className="content-card">
        <div className="content-card-name">
          <div>
            <div className="title">Thống kê tỉ lệ hoàn thành</div>
            <div className="description">
              Điều kiện đạt môn: Điểm tổng kết {">"}= 5
            </div>
          </div>
          <div className="action" onClick={handleExportExcelGradeRatio}>
            Xuất báo cáo
          </div>
        </div>
        <Table
          dataSource={gradeRatioList}
          columns={columns}
          bordered
          pagination={false}
          loading={loadingRatio}
        />
      </div>
      <Modal
        title="Xuất báo cáo"
        open={isModalVisible}
        onOk={handleOk}
        okText="Xuất báo cáo"
        cancelText="Huỷ"
        onCancel={handleCancel}
        centered
      >
        <div className="hr-tag-item"></div>
        <Radio.Group onChange={handleExportOptionChange} value={exportOption}>
          <Space direction="vertical">
            <Radio value="Excel">Excel</Radio>
            <Radio value="PNG">Ảnh (PNG)</Radio>
            <Radio value="PDF">PDF</Radio>
          </Space>
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default TranscriptManagement;
