import { ContactsOutlined, FileTextOutlined } from "@ant-design/icons";
import { Layout, Tabs, TabsProps, message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ElementSetting from "./element-setting";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import ResultTraining from "./result-training";
import { routesConfig } from "../../../../config/routes";
import { useEffect, useState } from "react";
import { getLearningTrendReportById } from "../../../../service/prediction-model";
import dayjs from "dayjs";
import { CheckRole, getPermissions } from "../../../../utils/checkRole";
import { Permission } from "../../../../layout/sidebar/permission.enum";

const { Content } = Layout;
const imgPath = "/images/";

const SettingDetailModel = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();
  const [dataDetail, setDataDetail] = useState<any>({});
  const [fieldsInput, setFieldsInput] = useState<any>({});
  const [fieldsAction, setFieldsAction] = useState<any>({});
  const [timeData, setTimeData] = useState<any>({
    input: "",
    action: "",
  });
  const [displayRoleFactors, setDisplayRoleFactors] = useState(false);
  const [displayRoleTraining, setDisplayRoleTraining] = useState(false);

  const [listPermission, setListPermission] = useState([]);
  const [tag, setTag] = useState("1");
  const [isReFresh, setIsReFresh] = useState(false);
  const location = useLocation();
  const isView = location?.pathname?.includes("view");

  const handleNotice = (type: any, message: string, duration?: number) => {
    messageApi.open({
      type: type,
      content: message,
      duration: duration,
    });
  };

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleFactors = [Permission.TabTrendAnalysisModelFactorsSettingUpdate];
    const roleTraining = [Permission.TabTrainingResultUpdate];

    const checkRoleFactors = CheckRole(roleFactors, listPermission);
    const checkRoleTraining = CheckRole(roleTraining, listPermission);

    if (checkRoleFactors.includes(true)) {
      setDisplayRoleFactors(true);
      setTag("1")
    }
    if (checkRoleTraining.includes(true)) {
      setDisplayRoleTraining(true);
    }
    if (!checkRoleFactors.includes(true)) {
      setTag("2")
    }    
  }, [listPermission]);

  const fetchData = async () => {
    try {
      const response = await getLearningTrendReportById(params?.id || "");
      const { data } = response?.data;
      setDataDetail(data);
      const startedTimeInput = data?.mooc_learning_trends[0]?.started_date
        ? dayjs(data?.mooc_learning_trends[0]?.started_date).format(
            "DD/MM/YYYY"
          )
        : "";
      const endTimeInput = data?.mooc_learning_trends[0]?.ended_date
        ? dayjs(data?.mooc_learning_trends[0]?.ended_date).format("DD/MM/YYYY")
        : "";
      const startedTimeACtion = data?.mooc_learning_trends[1]?.started_date
        ? dayjs(data?.mooc_learning_trends[1]?.started_date).format(
            "DD/MM/YYYY"
          )
        : "";
      const endTimeACtion = data?.mooc_learning_trends[1]?.ended_date
        ? dayjs(data?.mooc_learning_trends[1]?.ended_date).format("DD/MM/YYYY")
        : "";
      setTimeData({
        input: startedTimeInput + `- ${endTimeInput}`,
        action: startedTimeACtion + `- ${endTimeACtion}`,
      });

      const filesInput = data?.mooc_learning_trends[0]?.fields
        ?.map((value: any) => ({
          [value]: true,
        }))
        .reduce((acc: any, obj: any) => {
          return { ...acc, ...obj };
        }, {});
      const filesAction = data?.mooc_learning_trends[1]?.fields
        ?.map((value: any) => ({
          [value]: true,
        }))
        .reduce((acc: any, obj: any) => {
          return { ...acc, ...obj };
        }, {});
      setFieldsInput({ ...filesInput });
      setFieldsAction({ ...filesAction });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [isReFresh]);

  const handleRefresh = () => setIsReFresh(!isReFresh);
  const listBreadcrumb = [
    {
      title: "Hệ thống gợi ý khoá học",
    },
    {
      title: "Cài đặt Dự đoán, phân tích xu hướng",
    },
    {
      title: dataDetail?.name,
    },
  ];
  const items: TabsProps["items"] = [
    ...(displayRoleFactors
      ? [
          {
            key: "1",
            icon: <ContactsOutlined />,
            label: "Cài đặt yếu tố",
            children: (
              <ElementSetting
                handleNotice={handleNotice}
                setTag={setTag}
                isView={isView}
                dataDetail={dataDetail}
                handleRefresh={handleRefresh}
                timeData={timeData}
                fieldsInput={fieldsInput}
                fieldsAction={fieldsAction}
                setFieldsInput={setFieldsInput}
                setFieldsAction={setFieldsAction}
                displayRoleTraining={displayRoleTraining}
              />
            ),
          },
        ]
      : []),
    ...(displayRoleTraining
      ? [
          {
            key: "2",
            icon: <FileTextOutlined />,
            label: "Kết quả huấn luyện",
            children: (
              <ResultTraining dataDetail={dataDetail} timeData={timeData} />
            ),
          },
        ]
      : []),
  ];
  const onChangeTag = (key: string) => {
    setTag(key);
  };

  return (
    <Content>
      {contextHolder}
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title={dataDetail?.name || ""}
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={() =>
          navigate(`/prediction-model/${routesConfig.settingPrediction}`)
        }
      />
      <div className="tabs-heading">
        <Tabs
          defaultActiveKey="1"
          activeKey={tag}
          items={items}
          onChange={onChangeTag}
        />
      </div>
    </Content>
  );
};

export default SettingDetailModel;
