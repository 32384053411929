import vi from "antd/es/date-picker/locale/vi_VN"

export const viLocale: typeof vi = {
  ...vi,
  lang: {
    ...vi.lang,
    dateSelect: "Chọn ngày",
    placeholder: "Chọn ngày",
    timeSelect: "Chọn giờ",
    ok: "Xác nhận"
  },
  timePickerLocale: {
    placeholder: "Chọn giờ"
  },
}
export const options = [
  {label: "Ngày", id: 1},
  {label: "Tuần", id: 2},
  {label: "Tháng", id: 3},
]
