import { Button, Form, FormInstance, Layout, Modal, Space, TableColumnsType, Typography, message } from 'antd';
import React, { useEffect, KeyboardEvent, useState, ChangeEvent } from 'react';
import FilterTable from './components/filter-table/FilterTable';
import FormFilter from './components/filter-group/FormFilter';
import { get, isEmpty } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CloseOutlined, ExclamationCircleOutlined, FileProtectOutlined, SaveOutlined } from '@ant-design/icons';
import { validateFieldCreateSpecializedGroup } from './components/constants/data';
import DrawerCustom from '../../../../components/drawer/Drawer';
import { HelpService } from '../../../../service/helper.service';
import { FilterData, IDataCreateSpecializedGroup } from '../../../../types/course-plan-managment/specialized-group/specialized-group';
import TableOrganizeAppraisalBoard from './components/table-organize-appraisal-board/TableOrganizeAppraisalBoard';
import FormCreateOrganizeAppraisal from './components/form-create-organize-appraisal/FormCreateOrganizeAppraisal';
import { OrganizeAppraisalBoardDelete, createOrganizeAppraisalBoard, findOrganizationsWithMembers, getOrganizeAppraisalBoardDetail } from '../../../../service/course-plan-managment/organize-appraisal-board/organizeAppraisalBoardApi';
import { routesConfig } from '../../../../config/routes';

const OrganizeAppraisalBoard = (title: any) => {
  const [params, setParams] = useSearchParams()
  const helpService = new HelpService();
  const [detailData, setDetailData] = useState<IDataCreateSpecializedGroup>({});
  const [tab, setTab] = useState(1);
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [idDetail, setIdDetail] = useState(0);
  const [isOpened, setIsOpened] = useState<boolean>(false)
  const [titleForm, setTitleForm] = useState<string>('Thêm mới hội đồng thẩm định')
  const [titleConfirm, setTitleConfirm] = useState<string>('Xác nhận Lập hội đồng thẩm định')
  const [createOrUpdateText, setCreateOrUpdateText] = useState<string>('Lập tổ hội đồng thẩm định')
  const [dataOptions, setDataOptions] = useState({
    dataActive: [],
    dataCreatedDate: null,
    dataStatus: []
  })
  const [filterData, setFilterData] = useState<FilterData>({
    active: [],
    createdDate: null,
    status: [],
  });
  const navigate = useNavigate();
  const fetchData = async () => {
    await findOrganizationsWithMembers({
      ...filterData,
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize
    })
      .then((res) => {
        const data = res.data;
        console.log(data.result);
        setData(data.result);
        setTotalRecords(data.totalRecords);
      })
  };
  useEffect(() => {
    fetchData();
  }, [pageSize, pageNumber, filterData]);

  useEffect(() => {
    form.resetFields();
    if (detailData) {
      form.setFieldsValue({
        id: detailData?.id || 0,
        organizationCode: detailData?.organizationCode || "",
        organizationName: detailData?.organizationName || "",
        organizationRemark: detailData?.organizationRemark || "",
      });
    }
  }, [detailData]);
  const getDetailSpecializedGroupData = async (id: number) => {
    setLoading(true);
    try {
      const response = await getOrganizeAppraisalBoardDetail(JSON.stringify(id) || "0").then(
        (res) => {
          const data = res.data;
          setDetailData(data);
          setTimeout(() => {
            setLoading(false);
            setIsOpened(true);
          }, 500);
        }
      );
    } catch (error) { }
    // console.log(detailData);
  };
  const handleFinish = (values: any) => {
    setLoading(true)
    const data = {
      createdDate: get(values, 'createdDate', null),
      status: get(values, 'status', []),
      active: get(values, 'active', []),
    }
    // console.log(data);
    setFilterData(data);
  }
  const handleSubmitSearch = () => {
    findOrganizationsWithMembers({
      keyword: searchValue
    }).then(res => {
      const data = res.data;
      setData(data.result)
      setTotalRecords(data.totalRecords)
    }).catch(err => console.error(err))

    if (searchValue === '') {
      setPageNumber(1)
    }
  }
  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      findOrganizationsWithMembers({
        keyword: searchValue
      }).then(res => {
        const data = res.data;
        setData(data.result)
        setTotalRecords(data.totalRecords)
      }).catch(err => console.error(err))
    }
  }
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setParams({ [name]: value })
    setSearchValue(value)
    if (value.length === 0) {
      setSearchValue("")
      setParams()
    }
  }
  const onFinishCreate = (values: any) => {
    Modal.confirm({
      title: (
        <div>
          {titleConfirm}
        </div>
      ),
      content: (
        <div>
          Đảm bảo rằng tất cả các <span style={{ color: 'orange' }}>thông tin liên quan đã được kiểm tra và cập nhật đầy đủ</span> trước khi Xác nhận {createOrUpdateText}
        </div>
      ), cancelText: "Đóng",
      okText: "Xác nhận",
      centered: true,
      icon: <FileProtectOutlined style={{ color: "#1677FF" }} />,

      onOk() {
        setLoading(true);
        if (idDetail) {
          values = { id: idDetail, ...values, active: true, organizationGroupId: 2 };
        }
        values = { ...values, active: true, organizationGroupId: 2 };
        createOrganizeAppraisalBoard(values)
          .then(res => {
            const { data, statusCode, message } = res.data;
            if (statusCode === 200) {
              helpService.successMessage(get(data, "message", message));
              form.resetFields();
              fetchData();
              handleClose();
              if (values.id) {
                navigate(
                  `${routesConfig.detailOrganizeAppraisalBoard}/${values.id}`,
                  {
                    state: { mode: "edit", numberTab: "2" },
                  }
                );
              } else {
                if (res.data.id) {
                  navigate(
                    `${routesConfig.detailOrganizeAppraisalBoard}/${res.data.id}`,
                    {
                      state: { mode: "edit", numberTab: "2" },
                    }
                  );
                }
              }
            }
          })
          .catch(err => {
          });
      },
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  };
  const handleOpenCreate = () => {
    setIdDetail(0);
    setIsOpened(true)
    setDetailData({})
    setTitleConfirm('Xác nhận Lập hội đồng thẩm định')
    setTitleForm('Thêm mới hội đồng thẩm định')
  }
  const handleClose = () => {
    setIsOpened(false);
    form.resetFields();
  };
  const openFilter = () => {
    setShowFilter((showFilter) => !showFilter)
  }
  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };
  const clearFilter = (formInstance: FormInstance) => {
    setLoading(true)
    setFilterData({
      ...filterData,
      createdDate: null,
      status: [],
      active: [],
    });
    findOrganizationsWithMembers({})
      .then((res) => {
        const data = res.data;
        setData(data.result);
        setLoading(false);
        formInstance.resetFields()
        setTotalRecords(data.totalRecords);
      })
  }
  const deleteSpecializedGroup = async (value: any) => {
    if (value.mooc_approval_id.length > 0) {
      message.error("Không thể xóa hội đồng đã gán thẩm định kế hoạch");
    } else {
      await OrganizeAppraisalBoardDelete(value.id).then((res) => {
        const { data, statusCode, message } = res.data;
        if (statusCode === 200) {
          helpService.successMessage(get(data, "message", message));
          fetchData();
        }
      });
    }
  };
  return (
    <Layout className="page-header-group course-construction">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === 'createSpecializedGroup') {
            const { createSpecializedGroup } = forms
            const data = createSpecializedGroup.getFieldsValue(validateFieldCreateSpecializedGroup)
            onFinishCreate(data)
          }
          if (name === 'approve-modal') {
            console.log(values)
          }
        }}>

        <div className="table-header--wrapper">
          <FilterTable
            handleOpen={handleOpenCreate}
            searchValue={searchValue}
            handleOpenFilter={openFilter}
            handleSubmitSearch={handleSubmitSearch}
            handleSearch={handleSearch}
            handleChangeInput={handleChangeInput}
            showFilter={showFilter}

          />
        </div>
        <FormFilter
          clearFilter={clearFilter}
          onFinish={handleFinish}
          showFilter={showFilter}
        />
        <div className="tabs-heading">
          <TableOrganizeAppraisalBoard
            data={data || []}
            totalRecords={totalRecords}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            searchValue={searchValue}
            pageNumber={pageNumber}
            pageSize={pageSize}
            handleFinish={fetchData}
            setIdDetail={setIdDetail}
            setIsOpened={setIsOpened}
            setTitleForm={setTitleForm}
            setTitleConfirm={setTitleConfirm}
            setCreateOrUpdateText={setCreateOrUpdateText}
            getDetailSpecializedGroupData={getDetailSpecializedGroupData}
            deleteSpecializedGroup={deleteSpecializedGroup}
          />
        </div>
        <DrawerCustom title={titleForm} open={isOpened} onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>
                  Hủy
                </Typography.Text>
              </Button>
              <Button className="btn btn-primary" htmlType="submit"
                onClick={() => form.submit()}>
                <SaveOutlined />
                <Typography.Text>
                  Lưu
                </Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}>

          <FormCreateOrganizeAppraisal form={form} id={idDetail} />
        </DrawerCustom>
      </Form.Provider>
    </Layout >
  )
}

export default OrganizeAppraisalBoard;
