import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import "./style.scss";
import {
  DislikeActiveIcon,
  DislikeIcon,
  DropdownIcon,
  FeedbackIcon,
  LikeActiveIcon,
  LikeIcon,
} from "../icons";
import { Button, Input } from "antd";
import {
  AttachmentProps,
  CommentsBeProps,
  ReactionCount,
} from "../../../../../../types/course-construction";
import { displayDateComment } from "../../../../../../utils/converDate";
import { DISLIKE_REACTION, LIKE_REACTION } from "../../../../../../constants";
import UploadItem from "./UploadItem";
import { FileTextOutlined } from "@ant-design/icons";
import { getCleanText } from "../../../../../../utils/format";
import { useAuthStore } from "../../../../../../stores/stores";
import useUploadFile from "../../../../../../hooks/useUploadFile";

export interface CommentProps extends CommentsBeProps {
  id: number;
  level: number;
  isReplying?: boolean;
  parent?: number;
  content?: string;
  replies: CommentProps[];
}

export interface ReplyValue {
  replyText: string;
  file: any;
  comment: CommentProps;
  attachmentRemoved?: number[];
}

interface ReplyProps {
  comment: CommentProps;
  replies?: CommentProps[];
  commentEditing?: CommentProps;
  onLike?: (value: CommentProps) => void;
  onDelete?: (value: CommentProps) => void;
  onDislike?: (value: CommentProps) => void;
  onSaveReply?: (value: ReplyValue) => void;
  onClickReply?: (value: CommentProps) => void;
  onSaveEditComment?: (value: ReplyValue) => void;
  onClickEdit?: (value: CommentProps | undefined) => void;
}

const Reply = ({
  comment,
  replies,
  commentEditing,
  onLike = () => {},
  onDelete = () => {},
  onDislike = () => {},
  onSaveReply = () => {},
  onClickEdit = () => {},
  onClickReply = () => {},
  onSaveEditComment = () => {},
}: ReplyProps) => {
  const userInfo = useAuthStore((state) => state.user);
  const [isViewFull, setIsViewFull] = useState<boolean>(false);
  //edit
  const [editText, setEditText] = useState<string>("");
  const [editAttachments, setEditAttachments] = useState<any>([]);
  const [addAttachments, setAddAttachments] = useState<any>([]);
  const [addAttachmentsPreview, setAddAttachmentsPreview] = useState<any>([]);
  const [attachmentsRemoved, setAttachmentsRemoved] = useState<number[]>([]);

  //reply comment
  const textAreaRef = useRef<any>(null);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [replyText, setReplyText] = useState<string>("");
  const {
    filesPreview,
    discussionPayloadFiles,
    handleChangeFiles,
    handleDeleteFile,
    resetFiles,
    isCorrectFile,
  } = useUploadFile();

  // trạng thái edit discussion, comment
  const isEditing =
    commentEditing?.id === comment.id && commentEditing !== undefined;
  // số lượng like & dislike
  const countLike =
    comment.reactionCount?.find(
      (react: ReactionCount) => react.reactionType === LIKE_REACTION
    )?.count || 0;

  const countDislike =
    comment.reactionCount?.find(
      (react: ReactionCount) => react.reactionType === DISLIKE_REACTION
    )?.count || 0;

  // trạng thái like & dislike
  const isLike = comment?.myReaction === LIKE_REACTION;
  const isDislike = comment?.myReaction === DISLIKE_REACTION;

  // các file đính kèm
  const attachments = comment?.attachments;

  const handleClickReply = (comment: CommentProps) => {
    onClickReply(comment);
    setIsReplying(!isReplying);
  };

  const handleClickEdit = (comment: CommentProps | undefined) => {
    setEditAttachments(comment?.attachments);
    setEditText(comment?.content as string);
    onClickEdit(comment);
  };

  const handleSaveReply = (reply: ReplyValue) => {
    onSaveReply(reply);
    setIsReplying(false);
    setReplyText("");
    resetFiles();
  };

  const handleSaveEditComment = (reply: ReplyValue) => {
    onSaveEditComment(reply);
    setEditText("");
    setEditAttachments([]);
    setAddAttachments([]);
    setAddAttachmentsPreview([]);
    setAttachmentsRemoved([]);
    onClickEdit(undefined);
  };
  const handleLike = (comment: CommentProps) => {
    onLike(comment);
  };

  const handleDislike = (comment: CommentProps) => {
    onDislike(comment);
  };
  const handleDelete = (comment: CommentProps) => {
    onDelete(comment);
  };

  const removeDuplicate = (inputText: string, replaceString: string) => {
    const firstIndex = inputText.indexOf(replaceString);
    const beforeFirst = inputText.slice(0, firstIndex + replaceString.length);
    const afterFirst = inputText
      .slice(firstIndex + replaceString.length)
      .replace(new RegExp(replaceString, "g"), "");

    return beforeFirst + afterFirst;
  };
  const renderHighlightedText = (text: string, username: string) => {
    if (text?.includes(username)) {
      const parts = text.split(username);
      return (
        <span>
          {parts[0].endsWith("@") ? parts[0].slice(0, -1) : parts[0]}
          <span className="font-weight-6">{username}</span>
          {parts[1]}
        </span>
      );
    }
    return text;
  };

  // Hiển thị full với các thảo luận ngắn
  useEffect(() => {
    if (comment?.content && comment?.content?.length < 200) {
      setIsViewFull(true);
      if (attachments && attachments?.length > 6) {
        setIsViewFull(false);
      }
    }
    if (
      !comment?.content &&
      attachments &&
      attachments?.length < 6 &&
      attachments?.length > 0
    ) {
      setIsViewFull(true);
    }

  }, [comment?.content, attachments]);

  // Thêm giá trị @username vào reply input
  useEffect(() => {
    setReplyText(`@${comment?.author?.username || comment?.author?.name}`);
  }, [comment?.author?.username, isReplying]);

  // forcus vào reply input phía sau @username
  useEffect(() => {
    if (textAreaRef.current) {
      const textarea = textAreaRef.current.resizableTextArea
        .textArea as HTMLTextAreaElement;

      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
      textarea.focus();
    }
    const targetElement = document.getElementById(`new-reply-${comment.id}`);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isReplying, textAreaRef.current]);

  const handleChangeEditFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    let correctFiles = [];

    if (files?.length) {
      for (let i = 0; i < files?.length; i++) {
        if (isCorrectFile(files[i])) {
          correctFiles.push(files[i]);
        }
      }

      const fileArray = Array.from(correctFiles).map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      setAddAttachments(correctFiles);
      setAddAttachmentsPreview(fileArray);
    }
  };

  const getFullName = (firstName: string, lastName: string) => {
    if (firstName || lastName) return `${firstName} ${lastName}`;
    return false;
  };

  return (
    <div className="comment pl-2">
      <div className="flex gap-16 w-full">
        <div className="root-comment">
          <img
            className="avt-comment"
            src={
              comment.avatar ||
              `${process.env.PUBLIC_URL}/images/comment-avatar.png`
            }
            alt="avt"
          />
          {replies && replies?.length > 0 && (
            <div className="tree-line-horizontal-wrap">
              <div className="tree-line-horizontal"></div>
            </div>
          )}
        </div>
        {isEditing ? (
          <div className="edit-comment w-full">
            <div className="reply-input-text">
              <Input.TextArea
                value={editText}
                placeholder="Nội dung thảo luận của bạn"
                autoSize={{ minRows: 3 }}
                onChange={(e) => setEditText(getCleanText(e.target.value))}
              />
              <label htmlFor="reply-upload" className="reply-icon-upload">
                <FileTextOutlined className="pointer" />
              </label>
              <input
                onChange={handleChangeEditFiles}
                type="file"
                multiple
                id="reply-upload"
                style={{ display: "none" }}
              />
            </div>
            {(editAttachments?.length > 0 ||
              addAttachmentsPreview?.length > 0) && (
              <div className="files-reply-list">
                {editAttachments.map((file: any) => (
                  <UploadItem
                    key={file?.id}
                    file={{
                      name: file.fileName,
                      preview: file.mainKey,
                      type: file.attachmentType,
                    }}
                    hasDelete={true}
                    onDelete={(fileName: string) => {
                      setEditAttachments((prevState: any) =>
                        prevState?.filter(
                          (item: any) => item?.fileName !== fileName
                        )
                      );
                      setAttachmentsRemoved((prevState: number[]) => [
                        ...prevState,
                        file?.id,
                      ]);
                    }}
                  />
                ))}
                {addAttachmentsPreview.map((file: any, index: number) => (
                  <UploadItem
                    key={`edit-${file?.name}`}
                    file={file}
                    hasDelete={true}
                    onDelete={(fileName: string) =>
                      setAddAttachmentsPreview((prevState: any) =>
                        prevState?.filter(
                          (item: any) => item?.name !== fileName
                        )
                      )
                    }
                  />
                ))}
              </div>
            )}
            <div className="flex gap-16">
              <Button
                className="btn-primary mt-1"
                onClick={() => {
                  handleSaveEditComment({
                    file: addAttachments,
                    replyText: editText,
                    comment: comment,
                    attachmentRemoved: attachmentsRemoved,
                  });
                }}
              >
                Lưu
              </Button>
              <Button
                className="btn-danger mt-1"
                onClick={() => {
                  setEditText("");
                  onClickEdit(undefined);
                  setAddAttachmentsPreview([]);
                  setAttachmentsRemoved([]);
                }}
              >
                Huỷ
              </Button>
            </div>
          </div>
        ) : (
          <div className="pb-4 w-full reply-content">
            <div className="cmt-head flex align-center justify-space-between mb-1">
              <div>
                <span className="text-user-name">
                  {getFullName(
                    comment?.author?.firstName as string,
                    comment?.author?.lastName as string
                  ) ||
                    comment.author?.username ||
                    comment.author?.name ||
                    comment.author?.email ||
                    "Không có tên"}
                </span>
                <span className="text-time ml-1">
                  {displayDateComment(comment.createdDate || "")}
                </span>
              </div>
              {comment?.isMine ? (
                <div className="flex align-center">
                  <span
                    className="text-primary text-14 pointer"
                    onClick={() => handleClickEdit(comment)}
                  >
                    Chỉnh sửa
                  </span>
                  <span
                    className="text-danger text-14 ml-5 mr-2 pointer"
                    onClick={() => handleDelete(comment)}
                  >
                    Xóa
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className={`comment-content flex-1 ${isViewFull ? "h-full" : ""}`}
            >
              <span className="text-content">
                {renderHighlightedText(
                  comment?.content as string,
                  comment?.author?.username as string
                )}
              </span>
              <div className="comment-attachments">
                {attachments?.map((item: AttachmentProps) => (
                  <UploadItem
                    canDownload
                    key={item.id}
                    file={{
                      name: item.fileName,
                      preview: item.mainKey,
                      type: item.attachmentType,
                    }}
                  />
                ))}
              </div>
              {!isViewFull && (
                <span
                  className={`text-primary flex align-center pointer pl-3 btn-loadmore`}
                  onClick={() => setIsViewFull(!isViewFull)}
                >
                  <DropdownIcon />
                  <span className="ml-1"> Chi tiết</span>
                </span>
              )}
            </div>
            <div className="comment-action flex gap-8 mt-1 align-center">
              <span className="pointer" onClick={() => handleLike(comment)}>
                {isLike ? <LikeActiveIcon /> : <LikeIcon />}
              </span>
              {countLike}
              <span className="pointer" onClick={() => handleDislike(comment)}>
                {isDislike ? <DislikeActiveIcon /> : <DislikeIcon />}
              </span>
              {countDislike} <FeedbackIcon />
              <span className="text-12">
                {
                  comment?.replies?.filter((reply: any) => !reply?.isReplying)
                    ?.length
                }
              </span>
              <span
                className="text-primary text-14 pointer ml-2"
                onClick={() => handleClickReply(comment)}
              >
                Phản hồi
              </span>
            </div>
          </div>
        )}
      </div>
      {replies && replies.length > 0 && (
        <div className="comment-replies">
          {replies.map((reply: CommentProps, index: number) => {
            if (reply.isReplying) {
              return (
                <div className="comment-replies" key={reply.id}>
                  <div className="comment-reply-new">
                    <img
                      className="avt-reply"
                      src={userInfo?.anhDaiDien || ""}
                      alt="avt"
                    />
                    <div className="reply-input-text">
                      <Input.TextArea
                        ref={textAreaRef}
                        value={replyText}
                        autoSize={{ minRows: 2 }}
                        onChange={(e) =>
                          setReplyText(
                            removeDuplicate(
                              e.target.value,
                              `@${comment?.author?.username} `
                            )
                          )
                        }
                        id={`new-reply-${comment.id}`}
                      />
                      <label
                        htmlFor="reply-upload"
                        className="reply-icon-upload"
                      >
                        <FileTextOutlined className="pointer" />
                      </label>
                      <input
                        onChange={handleChangeFiles}
                        type="file"
                        multiple
                        id="reply-upload"
                        style={{ display: "none" }}
                      />
                    </div>
                    {filesPreview?.length > 0 && (
                      <div className="files-reply-list">
                        {filesPreview.map((file, index) => (
                          <UploadItem
                            key={index}
                            file={file}
                            hasDelete={true}
                            onDelete={handleDeleteFile}
                          />
                        ))}
                      </div>
                    )}
                    <Button
                      className="btn-primary mt-1"
                      onClick={() =>
                        handleSaveReply({
                          file: discussionPayloadFiles,
                          replyText: replyText,
                          comment: comment,
                        })
                      }
                    >
                      Trả lời
                    </Button>
                  </div>
                </div>
              );
            }

            return (
              <div className="comment-reply" key={reply.id}>
                <Reply
                  comment={reply}
                  replies={reply.replies}
                  onClickReply={(reply: CommentProps) =>
                    handleClickReply(reply)
                  }
                  onSaveReply={(reply: ReplyValue) => handleSaveReply(reply)}
                  onSaveEditComment={(reply: ReplyValue) =>
                    handleSaveEditComment(reply)
                  }
                  commentEditing={commentEditing}
                  onClickEdit={(value: CommentProps | undefined) =>
                    handleClickEdit(value)
                  }
                  onLike={(reply: CommentProps) => handleLike(reply)}
                  onDislike={(reply: CommentProps) => handleDislike(reply)}
                  onDelete={(reply: CommentProps) => handleDelete(reply)}
                />
                {index !== replies?.length - 1 && (
                  <div className="reply-line-horizontal"></div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Reply;
