import React from "react";
import { Radio } from "antd";
import { QuizAnsProps } from "../../../../../../types/scoring-course";
import { isArray } from "lodash";

function TrueOrFalse({ data, showCorrect = true, result }: QuizAnsProps) {
  const currentAnsOfUser = result?.results[0];

  const renderTextQuiz = (isTrueOption: boolean, uuid: string) => {
    if (showCorrect) {
      // show result in single menu
      if (isTrueOption) return "text-result";
      return "";
    } else {
      // show answers of current user
      if (isArray(currentAnsOfUser)) {
        if (
          currentAnsOfUser[0]?.uuid === uuid &&
          currentAnsOfUser[0]?.isTrue === true
        )
          return "text-result";
        if (
          currentAnsOfUser[0]?.uuid === uuid &&
          currentAnsOfUser[0]?.isTrue === false
        )
          return "text-danger";
      }
      return "";
    }
  };

  const renderCheckAns = (isTrueOption: boolean, uuid: string) => {
    if (showCorrect) {
      if (isTrueOption) return true;
      return false;
    } else {
      // show answers of current user
      if (isArray(currentAnsOfUser) && currentAnsOfUser[0]?.uuid === uuid)
        return true;

      return false;
    }
  };

  const renderCheckStyle = (uuid: string) => {
    if (showCorrect) return "";
    else {
      // show answers of current user
      if (isArray(currentAnsOfUser)) {
        if (
          currentAnsOfUser[0]?.uuid === uuid &&
          currentAnsOfUser[0]?.isTrue === false
        )
          return "check-danger";
      }
      return "";
    }
  };
  return (
    <div className={showCorrect ? "choose-correct" : ""}>
      <div className="flex flex-column gap-12">
        {data?.questions &&
          data?.questions?.length > 0 &&
          data?.questions?.map((quiz: any, index: number) => (
            <Radio
              disabled
              className={`${renderCheckStyle(quiz?.uuid)}`}
              key={quiz?.uuid + index}
              checked={renderCheckAns(quiz?.isTrue, quiz?.uuid)}
            >
              <span
                className={`${renderTextQuiz(
                  quiz?.isTrue,
                  quiz?.uuid
                )} font-size-16 line-height-24`}
              >
                {quiz?.content || "Không có nội dung"}
              </span>
            </Radio>
          ))}
      </div>
    </div>
  );
}

export default TrueOrFalse;
