import {
  Button,
  Checkbox,
  Dropdown,
  MenuProps,
  Modal,
  Pagination,
  PaginationProps,
  Space,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import React, { FunctionComponent, Key, useEffect, useState } from "react";
//import "./TableWaitApprove.scss";
import {
  CheckOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HistoryOutlined,
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { SurveyApproveType } from "../../../../../types/survey-managment/survey";
import { get, isEmpty } from "lodash";
import { postSurveySearchSubmit } from "../../../../../service/survey-managment/list-survey";
import {
  ApprovalType,
  FilterDateSubmitSurvey,
} from "../../../../../types/survey-browse";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../../../../stores/stores";
import { HelpService } from "../../../../../service/helper.service";
import { approveSurvey } from "../../../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";
import { routesConfig } from "../../../../../config/routes";

type tableDataProps = {
  activeTabKey: string;
  listSurveyApprove: ApprovalType[];
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  setPageNumber?: (pageNumber: number) => void;
  setPageSize?: (pageNumber: number) => void;
  setListIdApprove: (listId: any) => void;
  locale: object;
  getListData: () => void;
  loading: boolean | false;
};
const TableWaitApprove: FunctionComponent<tableDataProps> = ({
  activeTabKey,
  listSurveyApprove,
  totalRecords,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
  setListIdApprove,
  locale,
  getListData,
  loading,
}) => {
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [totalPage, setTotalPage] = useState(totalRecords);
  const navigation = useNavigate();
  const { Text } = Typography;
  const userId = useAuthStore((states) => states.user?.id);
  const helpService = new HelpService();
  const formatDateV2 = (date: string) => {
    return moment(date).format("DD/MM/yyyy");
  };
  const handleSort = () => {
    setSortOrder(sortOrder === "ascend" ? "descend" : "ascend");
  };
  const handleApproveSurvay = (surveyId: number) => {
    const dataSend = {
      surveyId: surveyId,
      individualId: userId,
      surveyStatus: 2,
    };
    approveSurvey(dataSend).then((res) => {
      const { data, statusCode, message } = res.data;
      if (statusCode === 200) {
        helpService.successMessage(get(data, "message", message));
        getListData();
      }
    });
  };
  const option: MenuProps["items"] = [
    {
      key: "1",
      label: "Phê duyệt khảo sát",
      icon: <FileDoneOutlined />,
    },
    {
      key: "2",
      label: "Phê duyệt nhanh khảo sát",
      icon: <ThunderboltOutlined />,
    },
    {
      key: "3",
      label: "Lịch sử phê duyệt",
      icon: <HistoryOutlined />,
    },
  ];
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên đợt khảo sát",
      sorter: (a, b) => a.surveyName.localeCompare(b.surveyName),
      width: 14,
      dataIndex: "surveyName",
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record.surveyName}</span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Ngày gửi phê duyệt",
      width: 7,
      dataIndex: "submissionDate",
      render: (_: any, record) => {
        return (
          <>
            {record.submissionDate ? formatDateV2(record.submissionDate) : null}
          </>
        );
      },
    },
    {
      title: "",
      width: 2,
      fixed: "right",
      render: (_: any, record) => {
        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  navigation(
                    `/survey-training/survey-detail/${record.surveyId}`,
                    {
                      state: {
                        mode: "view",
                        keyTab: activeTabKey,
                        modeback: "backState",
                        backLink: `/survey-training/browse-survey`,
                      },
                    }
                  );
                }}
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: option,
                  onClick: ({ key }) => {
                    if (key === "1") {
                      navigation(
                        `/survey-training/survey-approval/${record.surveyId}`
                      );
                    }
                    if (key === "2") {
                      Modal.confirm({
                        icon: <FileDoneOutlined />,
                        title: `Xác nhận phê duyệt đợt khảo sát ${record?.surveyName}?`,
                        content: (
                          <>
                            <p>
                              Đảm bảo rằng tất cả các
                              <Text type="warning">
                                {" "}
                                thông tin liên quan đã được kiểm tra và cặp nhật
                                đầy đủ{" "}
                              </Text>
                              trước khi Xác nhận kết quả phê duyệt khảo sát
                            </p>
                          </>
                        ),
                        onOk: () => handleApproveSurvay(record.surveyId),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Đóng",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "3") {
                      navigation(
                        `/survey-training/survey-detail/${record.surveyId}`,
                        {
                          state: {
                            mode: "history",
                          },
                        }
                      );
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
      setTotalPage(totalRecords);
    }
  };

  return (
    <div className="table-wrapper">
      <Table
        size="middle"
        tableLayout="fixed"
        pagination={false}
        columns={dataColumns}
        dataSource={listSurveyApprove}
        rowKey={"id"}
        scroll={{ x: 1500, y: 600 }}
        locale={locale}
        loading={loading}
      />
      <Pagination
        total={totalRecords}
        showSizeChanger
        pageSize={pageSize}
        current={pageNumber}
        className="pagination-table"
        onChange={onChange}
        itemRender={itemRender}
        locale={{
          items_per_page: "/ trang",
          jump_to: "Đi đến trang",
          page: "",
        }}
        showQuickJumper
        showTotal={(total) => `Tổng số ${total} bản ghi`}
      />
    </div>
  );
};

export default TableWaitApprove;
