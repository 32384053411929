import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import FormInput from "../../../../components/form-input-login/FormInput";
import { FormEvent, useState } from "react";
import { Button } from "antd";
import { putChangeForgotPassword, putChangePassword } from "../../../user-info/account/services";

export const UpdatePassword = (
  loadingLogin: boolean,
  setLoadingLogin: (value: React.SetStateAction<boolean>) => void,
  OTPCode: string,
  verifyMail: string,
  setOTPCode: React.Dispatch<React.SetStateAction<string>>,
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>,
  setIsCountTimeLeft: React.Dispatch<React.SetStateAction<boolean>>,
  setTimeLeftLocate: React.Dispatch<React.SetStateAction<number>>,
  handleAddClassHide: (id: string) => void,
  handleAddClassShow: (id: string) => void
) => {
  const [lengthCheck, setLengthCheck] = useState(false);
  const [syntaxCheck, setSyntaxCheck] = useState(false);
  const [customError, setCustomError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleComplete = () => {
    setLoadingLogin(true);
    setTimeout(() => {
      setLoadingLogin(false);
      handleAddClassHide("update-password");
      handleAddClassShow("update-success");
      setTimeLeftLocate(5);
    }, 500);
  };

  const handlePutChangePassword = async () => {
    try {
      await putChangeForgotPassword({
        otp: OTPCode,
        email: verifyMail,
        newPassword: newPassword,
        reWriteNewPassword: confirmPassword,
      });
      setTimeout(() => {
        handleComplete();
      }, 500);
    } catch (error) {
      setTimeout(() => {
        setLoadingLogin(false);
        setOTPCode("");
        setNewPassword("");
        setConfirmPassword("");
        setTimeLeft(60);
        setIsCountTimeLeft(true);
        setLengthCheck(false);
        setSyntaxCheck(false);
      }, 500);
    }
  };

  const handleUpdatePassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // action update password here
    if (newPassword !== confirmPassword) {
      setCustomError("Mật khẩu không khớp");
    } else setCustomError("");

    if (lengthCheck && syntaxCheck && newPassword === confirmPassword) {
      // handleComplete();
      setLoadingLogin(true);
      handlePutChangePassword();
    }
  };

  const handleOnChangePassword = (newPasswordChange: string) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]+$/;
    if (newPasswordChange.length >= 8 && newPasswordChange.length <= 25) {
      setLengthCheck(true);
    } else setLengthCheck(false);
    const isValidSyntax = regex.test(newPasswordChange);
    if (isValidSyntax) {
      setSyntaxCheck(true);
    } else {
      setSyntaxCheck(false);
    }

    setNewPassword(newPasswordChange);
  };
  return (
    <div id="update-password" className="login-card__item">
      <div className="header-container">
        <h2 className="ng-nhp">Nhập mật khẩu mới</h2>
      </div>
      <form
        className="form-and-button"
        id="update-password"
        onSubmit={handleUpdatePassword}
      >
        <div className="inputs">
          <FormInput
            title="Mật khẩu mới"
            inputPrefix="/inputprefix-1@2x.png"
            placeholder="Mật khẩu"
            inputSuffix="/inputsuffix-1@2x.png"
            inputSuffixIcon
            propMinWidth="2.81rem"
            propMinWidth1="4.19rem"
            customError={customError}
            isPassword={true}
            errorInvalid="Sai mật khẩu."
            value={newPassword}
            onChange={(text) => handleOnChangePassword(text)}
          />
          <FormInput
            title="Xác nhận mật khẩu mới"
            inputPrefix="/inputprefix-1@2x.png"
            placeholder="Mật khẩu"
            inputSuffix="/inputsuffix-1@2x.png"
            inputSuffixIcon
            propMinWidth="2.81rem"
            propMinWidth1="4.19rem"
            customError={customError}
            isPassword={true}
            // errorInvalid="Sai mật khẩu."
            value={confirmPassword}
            onChange={setConfirmPassword}
          />
        </div>
        <div className="condition-container">
          <div
            className={`password-check-message ${
              lengthCheck ? "valid" : "invalid"
            }`}
          >
            {lengthCheck ? <CheckCircleFilled /> : <CloseCircleFilled />}
            Độ dài mật khẩu từ 8-25 ký tự
          </div>
          <div
            className={`password-check-message ${
              syntaxCheck ? "valid" : "invalid"
            }`}
          >
            {syntaxCheck ? <CheckCircleFilled /> : <CloseCircleFilled />}
            Bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt
          </div>
        </div>
        <Button
          htmlType="submit"
          className={`button11 ${
            lengthCheck && syntaxCheck ? "" : "disable-button"
          }`}
          type="primary"
          loading={loadingLogin}
        >
          Cập nhật
        </Button>
      </form>
    </div>
  );
};
