// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-confirm-global .bg-icon-title {
  position: absolute;
  top: -7px;
  left: -110px;
  z-index: 0; }

.modal-confirm-global .anticon {
  display: none; }

.modal-confirm-global .close-modal-icon {
  display: block;
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
  font-size: 20px;
  opacity: 0.6; }

.modal-confirm-global .footer-modal-confirm {
  padding: 10px; }

.modal-confirm-global .d-none {
  display: none; }
`, "",{"version":3,"sources":["webpack://./src/hooks/modal-confirm.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAkB;EAClB,SAAS;EACT,YAAY;EACZ,UAAU,EAAA;;AALd;EAQI,aAAa,EAAA;;AARjB;EAYI,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,eAAe;EACf,eAAe;EACf,YAAY,EAAA;;AAlBhB;EAqBI,aAAa,EAAA;;AArBjB;EAwBI,aAAa,EAAA","sourcesContent":[".modal-confirm-global {\n  .bg-icon-title {\n    position: absolute;\n    top: -7px;\n    left: -110px;\n    z-index: 0;\n  }\n  .anticon {\n    display: none;\n  }\n\n  .close-modal-icon {\n    display: block;\n    position: absolute;\n    right: 25px;\n    top: 25px;\n    cursor: pointer;\n    font-size: 20px;\n    opacity: 0.6;\n  }\n  .footer-modal-confirm {\n    padding: 10px;\n  }\n  .d-none {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
