// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  margin: 64px 0px 96px 0px;
  display: flex;
  justify-content: center;
  text-align: center; }
  .pagination .pagination-arrow {
    border: 1px solid #d9d9d9;
    padding: 0px 8px;
    border-radius: 6px;
    -webkit-user-select: none;
            user-select: none; }
  .pagination .pagination-arrow-icon {
    padding: 0px 8px; }
  .pagination .ant-pagination {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center; }
  .pagination .ant-pagination-prev {
    position: absolute;
    left: 0; }
  .pagination .ant-pagination-next {
    position: absolute;
    right: 118px; }
  .pagination .ant-pagination-options {
    right: 0;
    position: absolute; }
    .pagination .ant-pagination-options .ant-select-selector {
      height: 35px; }
  .pagination li:nth-last-child(3) {
    margin-right: 100px; }
`, "",{"version":3,"sources":["webpack://./src/components/list-program/pagination/pagination.scss"],"names":[],"mappings":"AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,kBAAkB,EAAA;EAJpB;IAOI,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB,EAAA;EAVrB;IAcI,gBAAgB,EAAA;EAdpB;IAkBI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,uBAAuB,EAAA;EArB3B;IA0BI,kBAAkB;IAClB,OAAO,EAAA;EA3BX;IA+BI,kBAAkB;IAClB,YAAY,EAAA;EAhChB;IAqCI,QAAQ;IAIR,kBAAkB,EAJV;IArCZ;MA2CM,YAAY,EAAA;EA3ClB;IA+CI,mBAAmB,EAAA","sourcesContent":["@import \"../../../styles/variables\";\n\n.pagination {\n  margin: 64px 0px 96px 0px;\n  display: flex;\n  justify-content: center;\n  text-align: center;\n\n  .pagination-arrow {\n    border: 1px solid #d9d9d9;\n    padding: 0px 8px;\n    border-radius: 6px;\n    user-select: none;\n  }\n\n  .pagination-arrow-icon {\n    padding: 0px 8px;\n  }\n\n  .ant-pagination {\n    width: 100%;\n    display: flex;\n    position: relative;\n    justify-content: center;\n    // justify-content: space-around;\n  }\n\n  .ant-pagination-prev {\n    position: absolute;\n    left: 0;\n  }\n\n  .ant-pagination-next {\n    position: absolute;\n    right: 118px;\n  }\n\n  .ant-pagination-options {\n    position: absolute;\n    right: 0;\n  }\n\n  .ant-pagination-options {\n    position: absolute;\n    .ant-select-selector {\n      height: 35px;\n    }\n  }\n  li:nth-last-child(3) {\n    margin-right: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
