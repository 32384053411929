import React, { useEffect, useState } from "react";
import { Pagination, PaginationProps, Table, TableColumnsType } from "antd";

import { get, set } from "lodash";
import { useParams } from "react-router-dom";
import moment from "moment";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./DetailConfirm.scss";
import { postHistoryApproval } from "../../../../../service/course-plan-managment/mooc-approval-plan/mooc-approval-plan-api";
const DetailConfirm = () => {
  const [listApproval, setListApproval] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();
  const getlistData = () => {
    setLoading(true);
    const data = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    postHistoryApproval(get(params, "id", ""), data)
      .then((res) => {
        const { data } = res.data;
        setListApproval(data.data);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getlistData();
  }, [pageNumber, pageSize]);
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (index + 1).toString().padStart(2, "0");
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên cán bộ duyệt",
      width: 10,
      render: (_, record: Record<string, any>) => {
        const values = Object.values(record) as {
          userName: string;
          groupName: string;
        }[];
        return values[0].userName ?? values[0].groupName;
      },
    },
    {
      title: "Thời gian",
      width: 6,
      render: (_, record) => {
        const formattedTime = (time: string) => moment(time).format("HH:mm:ss");
        const formattedDate = (date: string) =>
          moment(date).format("DD/MM/YYYY");
        const times = Object.values<any>(record).map((item) => (
          <div key={item.id} style={{ marginBottom: "8px" }}>
            {formattedTime(item.approvalDate)} <br />
            {formattedDate(item.approvalDate)}
          </div>
        ));
        return times;
      },
    },
    {
      title: "Kết quả",
      width: 6,
      render: (_: any, record) => {
        const times = Object.values<any>(record).map((item, index, array) => (
          <div
            key={item.id}
            className="table-status"
            style={{
              marginBottom: index !== array.length - 1 ? "28px" : "0",
              marginTop: index !== 0 ? "28px" : "0",
            }}
          >
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  item.constructionPlanStatus === 2 ? "#52C41A" : "red",
              }}
            ></div>
            {item.constructionPlanStatus === 2 ? "Đồng ý" : "Không đồng ý"}
          </div>
        ));
        return times;
      },
    },
    {
      title: "Mô tả",
      width: 9,
      render: (_, record) => {
        const times = Object.values<any>(record).map((item, index, array) => (
          <div
            key={item.id}
            style={{
              marginBottom: index !== array.length - 1 ? "28px" : "0",
              marginTop: index !== 0 ? "28px" : "0",
            }}
          >
            {item.approvalRemark === "" || item.approvalRemark == null ? (
              "Không có ý kiến nhận xét "
            ) : (
              <div dangerouslySetInnerHTML={{ __html: item.approvalRemark }} />
            )}
          </div>
        ));
        return times;
      },
    },
  ];
  const onChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize);
    }
  };
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <LeftOutlined />;
    }
    if (type === "next") {
      return <RightOutlined />;
    }
    return originalElement;
  };
  return (
    <>
      <div className="table-wrapper">
        <Table
          size="middle"
          tableLayout="fixed"
          pagination={false}
          columns={dataColumns}
          dataSource={listApproval}
          scroll={{ y: 600 }}
          loading={loading}
        />
        <Pagination
          total={totalRecords}
          showSizeChanger
          pageSize={pageSize}
          current={pageNumber}
          className="pagination-table"
          onChange={onChange}
          itemRender={itemRender}
          locale={{
            items_per_page: "/ trang",
            jump_to: "Đi đến trang",
            page: "",
          }}
          showQuickJumper
          showTotal={(total) => `Tổng số ${total} bản ghi`}
        />
      </div>
    </>
  );
};

export default DetailConfirm;
