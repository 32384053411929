// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#resize-container {
  margin: 0 auto;
  transition: width 0.2s ease-in-out; }
`, "",{"version":3,"sources":["webpack://./src/pages/certificate/certificate-template/page-builder-view.scss"],"names":[],"mappings":"AAGA;EACE,cAAc;EACd,kCAAkC,EAAA","sourcesContent":[".page-builder-view {\n  img {}\n}\n#resize-container {\n  margin: 0 auto;\n  transition: width 0.2s ease-in-out; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
