export const DEFAULT_ROLE_AUTO_MARK: any = {
  decentralize: "Quyền sử dụng tính năng",
  share: "Quyền chia sẻ bảng điểm",
  get: "Quyền nhận bảng điểm",
  autoscore: "Quyền cài đặt chấm bài thi",
};

export const DEFAULT_PERMISSION_AUTO_SCORE: any = {
  DECENTRALIZE: "decentralize",
  SHARE: "share",
  GET: "get",
  AUTOSCORE: "autoscore",
};

export const DEFAULT_EXPORT_SCORE: any = {
  nameSv: "Họ và tên sinh viên",
  date_birthday: "Ngày tháng năm sinh",
  phone: "Số điện thoại",
  email: "Email",
  status: "Điểm danh",
  score: "Điểm số"
};


export const DEFAULT_TYPE: any = {
  IMG: 2,
  VIDEO: 3,
};