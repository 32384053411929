import { Button, Flex, Popover } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  RecordIcon,
  DeleteIcon,
  ExportIcon,
  PauseIcon,
  RefreshIcon,
  QuestionMarkIcon,
} from "../../../../../../../components/icons";
import { QuestionProps } from "../../../../../../../types/course";
import { isEmpty, toNumber } from "lodash";
import SingleMenu from "../../../../../../../components/menu/SingleMenu";


const VideoRecorder = ({ data, configs }: QuestionProps) => {
  const isTrue = data?.result?.isTrue === true;
  const result = data?.result?.results[0];
  const videoRef = useRef<HTMLVideoElement>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [mode, setMode] = useState<"default" | "recording" | "pause" | "view">(
    "default"
  );
  const [videoURL, setVideoURL] = useState<string>("");

  const [isPaused, setIsPaused] = useState(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);

  const [snapshot, setSnapshot] = useState<string>("");
  const snapshotRef = useRef<HTMLImageElement>(null);

  const captureSnapshot = () => {
    if (videoRef.current) {
      const canvas = document.createElement("canvas");
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        setSnapshot(canvas.toDataURL("image/png"));
      }
    }
  };

  const startCamera = async () => {
    setMode("recording");
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }

      const recorder = new MediaRecorder(stream);
      let chunks: BlobPart[] = [];

      recorder.ondataavailable = (e: BlobEvent) => chunks.push(e.data);
      recorder.onstop = () => {
        const completeBlob = new Blob(chunks, { type: "video/webm" });
        setVideoURL(URL.createObjectURL(completeBlob));
      };

      setMediaRecorder(recorder);
      recorder.start();
      setIsRecording(true);
      setIsPaused(false);
    } catch (error) {
      console.error("Error accessing the camera", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    stopCameraStream();
    setMode("view");
  };

  const pauseRecording = () => {
    if (mediaRecorder?.state === "recording") {
      mediaRecorder.pause();
      setIsPaused(true);
      captureSnapshot();
      setMode("pause");
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder?.state === "paused") {
      mediaRecorder.resume();
    }
    setIsPaused(false);
    setSnapshot("");
    setMode("recording");
  };

  const handleDeleteRecord = () => {
    setVideoURL("");
    setMode("default");
  };

  const stopCameraStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    return () => {
      stopCameraStream();
    };
  }, []);

  const renderFeedback = () => {
    if (isEmpty(data?.result) && !isTrue)
      return data?.settingResponse?.notYet || "Không có phải hồi";

    if (isTrue) return data?.settingResponse?.correct || "Không có phải hồi";

    return data?.settingResponse?.incorrect || "Không có phải hồi";
  };

  const renderVideoScreen = () => {
    switch (mode) {
      case "default":
        return (
          <div className="start-screen">
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/card.png`}
              alt="Snapshot"
              className="screen-video-snapshot"
            />

            <Button
              className="btn-start h-40 center"
              icon={<RecordIcon />}
              onClick={startCamera}
            >
              Bắt đầu quay
            </Button>
          </div>
        );
      case "view":
        return (
          <div className="view-screen flex gap-16">
            <video src={videoURL} className="screen-video-snapshot" controls />
            <div className="flex flex-column gap-16 btn-action-view-group">
              <Button className="btn-action" onClick={startCamera}>
                <RefreshIcon />
              </Button>
              <Button
                className="btn-action btn-delete"
                onClick={handleDeleteRecord}
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        );

      case "recording":
        return (
          <div className="recording-screen">
            <video
              ref={videoRef}
              className="screen-video-snapshot"
              style={{ display: isPaused ? "none" : "block" }}
            />
            <div className="pause-btn-group">
              {isRecording && (
                <Button className="mb-1 btn-pause" onClick={pauseRecording}>
                  Pause
                </Button>
              )}
            </div>
          </div>
        );
      case "pause":
        return (
          <div className="pause-screen">
            <img
              ref={snapshotRef}
              src={snapshot}
              alt="Snapshot"
              className="screen-video-snapshot pause-screen-img"
              style={{
                display: isPaused ? "block" : "none",
              }}
            />

            <div className="pause-btn-group flex gap-8">
              <Button className="btn-action center" onClick={resumeRecording}>
                <PauseIcon />
              </Button>
              <Button
                className="btn-primary btn-action center"
                onClick={stopRecording}
              >
                <ExportIcon />
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="question-header mb-3">
        <div className="question-title">
          <Flex
            justify="space-between"
            className="font-size-16 line-height-24 font-weight-6"
          >
            Câu hỏi {toNumber(data?.index) + 1}:{" "}
            {configs?.generalConfig?.showQuizScore && (
              <span className="total-point font-size-14">
                {`(${data?.totalPoints || 0} điểm)`}
              </span>
            )}
          </Flex>
          <Flex align="flex-start" gap={8}>
            <div
              className="font-size-16 line-height-24 font-weight-6"
              dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
            ></div>
            {data?.settingHint?.isDisplayInstruction || data?.isShowHint ? (
              <Popover
                content={
                  <div style={{ padding: "5px 16px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${data?.settingHint?.content || "Không có gợi ý"}`,
                      }}
                    />
                  </div>
                }
              >
                <span className="quest-icon text-secondary w-max-content">
                  <QuestionMarkIcon />
                </span>
              </Popover>
            ) : (
              ""
            )}
          </Flex>
          <div className="flex align-center gap-8 mt-1">
            {result ? (
              <span
                className={`font-size-14 line-height-20 font-weight-6 ${data?.result?.isTrue ? "score-correct-wrapper text-correct" : "score-incorrect-wrapper text-danger"}`}
              >
                Điểm: {data?.result?.points || 0}/
                {data?.result?.totalPoints || 0}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {renderVideoScreen()}
      <div className="flex-1 mt-2">
        {configs?.generalConfig?.showFeedbackContent &&
          !data?.settingResponse?.isDisplayPerResponse &&
          !isEmpty(data?.settingResponse) && (
            <SingleMenu
              titleClassName={`comment-menu ${isTrue ? "report-ans-wrapper-correct" : "report-ans-wrapper"} `}
              contentClassName={`${isTrue ? "bg-green" : "bg-purple"}`}
              className={`${isTrue ? "bg-green" : "bg-purple"}`}
              title={
                <p className="font-size-16 line-height-24 font-weight-6">
                  Phản hồi: {renderFeedback()}
                </p>
              }
              content={
                <p className="font-size-16 line-height-24 mt-1 font-weight-5">
                  {data?.result?.message || "Không có nhận xét"}
                </p>
              }
            />
          )}
      </div>
    </div>
  );
};

export default VideoRecorder;
