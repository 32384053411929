import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Switch,
  Tooltip,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormInputCommon from "../../../../components/form-input-common/FormInputCommon";
import {
  getSettingAttendanceById,
  updateSettingAttendance,
} from "../../../../service/setting-attendance";
import { useStudyManagementStore } from "../../../../stores/stores";
import { CheckRole, getPermissions } from "../../../../utils/checkRole";
import { Permission } from "../../../../layout/sidebar/permission.enum";

const { Title, Text } = Typography;
interface IProps {
  id: string;
}
const RemindCheckInDetailForm = ({ id }: IProps) => {
  const navigate = useNavigate();
  const { setTitleAttendance } = useStudyManagementStore((state) => state);

  const [remindNumber, setRemindNumber] = useState<any>([]);
  const [idRemind, setIdRemind] = useState("");

  const [isEditQuantity, setIsEditQuantity] = useState<boolean>(true);
  const [isEditStatus, setIsEditStatus] = useState<boolean>(true);
  const [isEditMethod, setIsEditMethod] = useState<boolean>(true);
  const [isEditNature, setIsEditNature] = useState<boolean>(true);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);

  const [paramForm, setParamForm] = useState({
    face_attendance: false,
    ip_attendance: true,
    lesson: true,
    min_times: 1,
  });

  const [messageApi, contextHolder] = message.useMessage();

  const [displayRoleRemindCheckIn, setDisplayRoleRemindCheckIn] =
    useState(false);

  const [listPermission, setListPermission] = useState([]);

  const getRoles = async () => {
    const params = await getPermissions();
    const listPermissionConvert = JSON.parse(JSON.stringify(params));
    setListPermission(listPermissionConvert);
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    const roleRemindCheckIn = [Permission.TabAttendanceReminderSettingUpdate];

    const checkRoleRemindCheckIn = CheckRole(roleRemindCheckIn, listPermission);

    if (checkRoleRemindCheckIn.includes(true)) {
      setDisplayRoleRemindCheckIn(true);
    }
  }, [listPermission]);

  const onChangeFormCheckIn = (checked: any, name: string) => {
    if (name === "face_attendance") {
      setParamForm({
        ...paramForm,
        face_attendance: checked,
        ip_attendance: !checked,
      });
    } else {
      setParamForm({
        ...paramForm,
        face_attendance: !checked,
        ip_attendance: checked,
      });
    }
  };
  const handleChangeNatureCheckIn = (e: any) => {
    setParamForm({
      ...paramForm,
      [e.target.name]: e.target.checked,
    });
  };
  const handleChangeRemindNumber = (val: string | number, index: number) => {
    if (!/^\d*$/.test(val as string)) {
      return;
    }
    if (Number(val) >= 1 && Number(val) <= 1000) {
      setRemindNumber((prevValues: any) => {
        const updatedValues = [...prevValues];
        updatedValues[index].minutes = val;
        return updatedValues;
      });
    }
  };

  const handleChangeStatus = (val: number) => {
    if (!/^\d*$/.test(`${val}`)) {
      return;
    }
    if (val >= 0 && val <= remindNumber.length) {
      setParamForm({
        ...paramForm,
        min_times: Number(val),
      });
    }
  };

  const addRemindNumber = () => {
    setRemindNumber([...remindNumber, { minutes: 1 }]);
  };

  const handleConfirmDelete = (indexToRemove: number) => {
    remindNumber.splice(indexToRemove, 1);
    setRemindNumber([...remindNumber]);
    if (remindNumber?.length < paramForm.min_times) {
      setParamForm({
        ...paramForm,
        min_times: remindNumber?.length,
      });
    }
  };

  const fetchDataAttendanceById = async () => {
    try {
      const response = await getSettingAttendanceById(id);
      const { data } = response.data;
      setTitleAttendance(data?.mooc_course?.name);
      setIdRemind(data?.id);
      setParamForm({
        face_attendance: data?.face_attendance,
        ip_attendance: data?.ip_attendance,
        lesson: data?.lesson,
        min_times: data?.min_times || 1,
      });
      setRemindNumber(data?.mooc_setting_times);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchDataAttendanceById();
  }, [isRefresh]);

  const handleSaveRemind = async () => {
    const count = remindNumber?.map((val: { minutes: any }) => val?.minutes);
    const paramData = {
      count,
      ...paramForm,
      min_times:
        (paramForm?.min_times as any) === "" ? 0 : paramForm?.min_times,
    };
    if (paramData?.count?.includes("")) {
      let messageIndex = "";
      paramData?.count?.map((value: string | number, index: number) => {
        if (value === "") {
          messageIndex += `lần ${index + 1}, `;
        }
      });
      messageApi.open({
        type: "error",
        content: `Nhắc nhở điểm danh ${messageIndex} không được để trống`,
      });
    } else {
      try {
        await updateSettingAttendance(idRemind, paramData);
        setIsEditQuantity(true);
        setIsEditMethod(true);
        setIsEditNature(true);
        setIsEditStatus(true);
        setIsRefresh(!isRefresh);
        messageApi.open({
          type: "success",
          content: `Lưu thông tin thành công`,
        });
      } catch (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: `Lưu thông tin thất bại`,
        });
      }
    }
  };

  const handleExit = () => {
    setIsEditQuantity(true);
    setIsEditMethod(true);
    setIsEditNature(true);
    setIsEditStatus(true);
    setIsRefresh(!isRefresh);
  };

  return (
    <div>
      {contextHolder}
      <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-12">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Title level={4} className="!m-0">
              Số lần và thời điểm nhắc nhở điểm danh
            </Title>
            <Text className="text-[#00000073]">
              Chọn số lần và thời điểm nhắc nhở tương ứng
            </Text>
          </div>
          <Button
            className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
            onClick={() => setIsEditQuantity(false)}
            disabled={!displayRoleRemindCheckIn}
          >
            <EditOutlined />
          </Button>
        </Flex>
        <div className="border-b p-4 bg-[#0000000f] flex mt-4">
          <Text className="w-1/3 border-r flex items-center">Số lần </Text>
          <Text className="w-2/3 px-4">
            Thời điểm nhắc nhở điểm danh
            <p className="text-[#00000073] text-xs !mb-0">{`(Tính từ phút thứ)`}</p>
          </Text>
        </div>
        <div className="max-h-[300px] overflow-auto">
          {remindNumber?.map((reminds: any, index: number) => (
            <Flex className="border-b p-4 mt-4" key={index}>
              <Text className="w-1/3 border-r flex items-center">
                <span>Lần thứ {index + 1}</span>
                {index === 0 && (
                  <Tooltip
                    placement="rightTop"
                    title={
                      "Điểm danh lần đầu sẽ được thực hiện sau ít nhất 1 phút để đảm bảo hệ thống ổn định."
                    }
                    className="pl-2"
                  >
                    <InfoCircleOutlined style={{ color: "#00000073" }} />
                  </Tooltip>
                )}
              </Text>
              <Flex className="w-2/3 px-4">
                <FormInputCommon
                  type="number"
                  min={1}
                  max={500}
                  placeholder="Nhập thời điểm nhắc nhở"
                  value={reminds?.minutes}
                  onChange={(e) =>
                    handleChangeRemindNumber(e?.target?.value, index)
                  }
                  disabled={isEditQuantity}
                />
                {index !== 0 && !isEditQuantity && (
                  <DeleteOutlined
                    className="px-4"
                    onClick={() => handleConfirmDelete(index)}
                  />
                )}
              </Flex>
            </Flex>
          ))}
        </div>
        {!isEditQuantity && (
          <Flex
            className="mt-2 cursor-pointer"
            align="center"
            onClick={addRemindNumber}
          >
            <Button className="border-none !bg-white">
              <PlusOutlined style={{ color: "#1677ff" }} />
              <Text className="pl-2 text-[#1677ff]">Thêm số lần điểm danh</Text>
            </Button>
          </Flex>
        )}
      </Card>

      <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-12">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Title level={4} className="!m-0">
              Trạng thái kết quả điểm danh
            </Title>
            <Text className="text-[#00000073]">
              Chọn số lần tối thiểu chấp nhận có mặt trong báo cáo điểm danh
            </Text>
          </div>
          <Button
            className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
            onClick={() => setIsEditStatus(false)}
            disabled={!displayRoleRemindCheckIn}
          >
            <EditOutlined />
          </Button>
        </Flex>
        <Flex justify="space-between" className="border-b p-4 mt-4">
          <Text className="flex items-center">
            Số lần tối thiểu chấp nhận có mặt
          </Text>
          <Flex className="px-4">
            <FormInputCommon
              type="number"
              placeholder="Số lần tối thiểu chấp nhận có mặt"
              value={paramForm.min_times}
              max={remindNumber?.length}
              min={1}
              onChange={(e) => handleChangeStatus(e?.target?.value)}
              disabled={isEditStatus}
            />
          </Flex>
        </Flex>
        <Flex justify="space-between" className="border-b p-4 mt-4">
          <Text className="flex items-center">Tổng số lần điểm danh</Text>
          <Text className="flex items-center pr-4">{remindNumber?.length}</Text>
        </Flex>
        <div className="bg-[#FAFAFA] p-4 mt-2">
          <Text>
            Tỷ lệ tối thiểu chấp nhận sinh viên có mặt trong báo cáo điểm danh :{" "}
            {paramForm.min_times | 0}/{remindNumber?.length}
          </Text>
        </div>
      </Card>
      <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-6">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Title level={4} className="!m-0">
              Hình thức điểm danh
            </Title>
            <Text className="text-[#00000073]">
              Chọn cài đặt hình thức cho điểm danh
            </Text>
          </div>
          <Button
            className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
            onClick={() => setIsEditMethod(false)}
            disabled={!displayRoleRemindCheckIn}
          >
            <EditOutlined />
          </Button>
        </Flex>
        <Flex className="border-b p-4">
          <Text className="w-2/5 flex items-center">Sử dụng khuôn mặt</Text>
          <div className="w-3/5 px-4">
            <Switch
              onChange={(checked) =>
                onChangeFormCheckIn(checked, "face_attendance")
              }
              disabled={isEditMethod}
              checked={paramForm?.face_attendance}
            />
          </div>
        </Flex>
        <Flex className="border-b p-4">
          <Text className="w-2/5 flex items-center">
            <span>Địa chỉ tham gia video</span>
            <Tooltip
              placement="rightTop"
              title={
                "Địa chỉ tham gia video: Chế độ điểm danh ngầm. Không yêu cầu sinh viên mở camera trong quá trình học."
              }
              className="pl-2"
            >
              <InfoCircleOutlined style={{ color: "#00000073" }} />
            </Tooltip>
          </Text>
          <div className="w-3/5 px-4">
            <Switch
              defaultChecked
              onChange={(checked) =>
                onChangeFormCheckIn(checked, "ip_attendance")
              }
              checked={paramForm?.ip_attendance}
              disabled={isEditMethod}
            />
          </div>
        </Flex>
      </Card>
      <Card className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-6">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Title level={4} className="!m-0">
              Tính chất bắt buộc điểm danh
            </Title>
            <Text className="text-[#00000073]">
              Chọn cài đặt tính chất video cho điểm danh
            </Text>
          </div>
          <Button
            className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
            onClick={() => setIsEditNature(false)}
            disabled={!displayRoleRemindCheckIn}
          >
            <EditOutlined />
          </Button>
        </Flex>
        <Flex className="border-b p-4">
          <Text className="w-1/3 flex items-center">
            <span>Tiết học video</span>
            <Tooltip
              placement="rightTop"
              title={
                "Hệ thống sẽ tự động điểm danh khi sinh viên học học liệu video."
              }
              className="pl-2"
            >
              <InfoCircleOutlined style={{ color: "#00000073" }} />
            </Tooltip>
          </Text>
          <Checkbox
            className="w-2/3 m-0"
            defaultChecked
            disabled={isEditNature}
            checked={paramForm?.lesson}
            onChange={handleChangeNatureCheckIn}
            name="lesson"
          />
        </Flex>
      </Card>
      <div className="w-full lg:w-2/3 xl:w-1/2 m-auto mt-6">
        {(!isEditQuantity ||
          !isEditStatus ||
          !isEditMethod ||
          !isEditNature) && (
          <Flex
            gap="small"
            justify="space-between"
            className="w-full m-auto my-4"
          >
            <Button className="w-1/2 mr-1" onClick={handleExit}>
              Thoát khỏi chế độ
            </Button>
            <Button
              type="primary"
              className="w-1/2 ml-1"
              onClick={handleSaveRemind}
              disabled={!displayRoleRemindCheckIn}
            >
              Xác nhận lưu thông tin
            </Button>
          </Flex>
        )}
      </div>
    </div>
  );
};

export default RemindCheckInDetailForm;
