import React from "react";
import { Quiz } from "../../../../../../types/course";
import ResultQuizLayout from "./quiz";

interface QuizComponentProps {
  quizzes?: Quiz[];
}
function QuizComponent({ quizzes }: QuizComponentProps) {
  return (
    <div className="quiz-group">
      {quizzes?.map((quiz: Quiz, index: number) => (
        <div key={quiz?.id}>
          <p
            className="font-size-16 flex gap-8 mt-2 mb-1"
            dangerouslySetInnerHTML={{
              __html: `${index + 1}.${quiz?.question}`,
            }}
          />
          <ResultQuizLayout data={quiz} />
        </div>
      ))}
    </div>
  );
}

export default QuizComponent;
