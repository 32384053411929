import { FunctionComponent, useEffect, useState } from "react";
// import "./index.scss";
import "./style.scss";
import { Button, Form, Layout, Tabs, TabsProps } from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import { useParams } from "react-router-dom";
// import { DetailContent } from "../components/detail-content/DetailContent";
import { useLocation } from "react-router-dom";
import SettingThemeBlogContent from "../../../components/blog-and-forum/design-theme-blog-content";


type Props = {
  title?: string;
};

const DesignThemeBlogContentPage = (props: Props) => {
  const { title } = props
  const location = useLocation();
  const [tab, setTab] = useState("1");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const params = useParams();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const getDetailCourseData = async () => { };

  useEffect(() => {
    getDetailCourseData();
  }, []);

  return (
    <>
      <Layout className="page-header-group portal-adminstration">
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {

          }}
        >
          <div
            className={`page-header-group--layout z-10 ${showFilter ? "open-filter" : ""
              }`}
          >
            <div className="table-header--wrapper">
              <div className="table-header--left">
                <PageHeader title={title} />
              </div>
            </div>
            <div className={`wrapper-filter`}>
            </div>
          </div>

          <div className="tabs-heading z-[49]">
            <SettingThemeBlogContent
            />
          </div>
        </Form.Provider>
      </Layout>
    </>
  );
};

export default DesignThemeBlogContentPage;
