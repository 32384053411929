import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { ObjLink, usePreventRouteChange } from "../../hooks/usePreventRouteChange";

interface SafeLinkProps extends LinkProps {
  to: string;
  isBlank?: boolean;
}

const SafeLink = ({ to, children, state, isBlank, ...props }: SafeLinkProps) => {
  const { confirmNavigation } = usePreventRouteChange();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    confirmNavigation({ link: to, path: { state } }, isBlank);
  };

  return (
    <Link to={to} state={state} onClick={handleClick} {...props}>
      {children}
    </Link>
  );
};

export default SafeLink;
