// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rate-chart .ant-card-body {
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/study-management/setting-remind-checkin/remind-checkin-detail/report-checkin/quality-evalution/quality-evalution.scss"],"names":[],"mappings":"AAAA;EAEQ,WAAW,EAAA","sourcesContent":[".rate-chart {\n    .ant-card-body{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
