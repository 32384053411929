import { useEffect, useMemo, useState } from "react";
import { Button } from "antd";
import { DeleteOutlined, LinkOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { confirmMessage } from "../../../../pages/page-builder/grapesjs-service";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
import { TextAreaProps } from "antd/lib/input";
import { DEFAULT_MAX_LENGTH_COURSE_TEXTAREA } from "../../../../constants";
import { useAddLesson } from "../../../../stores/stores";

interface QuestionConnectTextProps {
  index: number;
  content: {
    left: { content?: string };
    right: { content?: string };
  };
  onChange?: (val: {
    id: number;
    content: {
      left: { content: string };
      right: { content: string };
    };
  }) => void;
  onDelete?: (id: number) => void;
}

function QuestionConnect({
  index,
  content: {
    left: { content: leftValue },
    right: { content: rightValue },
  },
  onChange = () => { },
  onDelete = () => { },
}: QuestionConnectTextProps) {
  const [leftTextValue, setLeftTextValue] = useState<string>("");
  const [rightTextValue, setRightTextValue] = useState<string>("");
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const handleChangeLeftText: TextAreaProps["onChange"] = (event) => {
    const changeLeftValue = event.target.value;
    setLeftTextValue(changeLeftValue);
    onChange({
      id: index,
      content: {
        left: { content: changeLeftValue },
        right: { content: rightTextValue },
      },
    });
  };

  const handleChangeRightText: TextAreaProps["onChange"] = (event) => {
    const changeRightValue = event.target.value;
    setRightTextValue(changeRightValue);
    onChange({
      id: index,
      content: {
        left: { content: leftTextValue },
        right: { content: changeRightValue },
      },
    });
  };

  const handleDeleteSection = (index: number) => {
    confirmMessage(
      "Xóa cặp lựa chọn",
      0,
      "Bạn có chắc muốn xóa cặp lựa chọn này ?",
      () => {
        onDelete(index);
      }
    );
  };

  useEffect(() => {
    if (leftValue) setLeftTextValue(leftValue);
    if (rightValue) setRightTextValue(rightValue);
  }, [leftValue, rightValue]);

  const alphabet1 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const alphabet2 = "abcdefghijklmnopqrstuvwxyz";

  function convertToRoman(num: number) {
    const romanNumerals = [
      "", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
      "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
      "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
    ];
    return romanNumerals[num];
  }

  const markNumberLeftRow =
    settingQuestion.markNumber === 1
      ? alphabet1.charAt(index - 1)
      : settingQuestion.markNumber === 2
        ? alphabet1.charAt(index - 1)
        : settingQuestion.markNumber === 3
          ? index
          : settingQuestion.markNumber === 4
            ? convertToRoman(index)
            : '\u00A0'.repeat(3);
  const markNumberRightRow =
    settingQuestion.markNumber2 === 1
      ? alphabet1.charAt(index - 1)
      : settingQuestion.markNumber2 === 2
        ? alphabet2.charAt(index - 1)
        : settingQuestion.markNumber2 === 3
          ? index
          : settingQuestion.markNumber2 === 4
            ? convertToRoman(index)
            : '\u00A0'.repeat(3);
  return (
    <div className="question-by-type flex-1 mb-2">
      <div className="flex align-center">
        <div className="answer-pair">
          <div className="connect-answer">
            <p className="current-letter">{markNumberLeftRow}</p>
            <TextArea
              onChange={handleChangeLeftText}
              value={leftTextValue}
              className="text-area-answer"
              showCount
              placeholder="Nhập nôi dung mô tả"
              maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
              autoSize={{ minRows: 2, maxRows: 10 }}
            />
          </div>

          <LinkOutlined />

          <div className="connect-answer">
            <p className="current-letter">{markNumberRightRow}</p>
            <TextArea
              onChange={handleChangeRightText}
              value={rightTextValue}
              className="text-area-answer"
              showCount
              placeholder="Nhập nôi dung mô tả"
              maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
              autoSize={{ minRows: 2, maxRows: 10 }}
            />
          </div>
        </div>
        <div className="ml-2 mt-3">
          <Button
            className="delete-btn"
            onClick={() => handleDeleteSection(index)}
            icon={<DeleteOutlined />}
          ></Button>
        </div>
      </div>
    </div>
  );
}

export default QuestionConnect;
