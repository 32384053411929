import {
  Avatar,
  Flex,
  Form,
  Layout,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
  message,
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";

import { PlaySquareOutlined } from "@ant-design/icons";

import moment from "moment";
import TableData from "../../../components/table-data/TableData";
import { getResultExamSupervision } from "../../../service/exam-supervision";
import FilterTable from "./filter-table/FilterTable";
import ModalVideo from "./modal-video";

type ViewResultSupervisionProps = {
  courseId?: string;
  isActive: boolean;
};

const ViewResultSupervision = ({
  courseId,
  isActive,
}: ViewResultSupervisionProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const [accountReportCheckIn, setAccountReportCheckIn] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");
  const [dataDetail, setDataDetail] = useState({
    video_path: "",
    listSupervision: [],
  });

  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getResultExamSupervision(searchParam);
      const { data } = response.data;
      if (isActive) {
        setAccountReportCheckIn(data.data);
      }
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData({
      course_block_id: Number(courseId),
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
  }, [pageSize, pageNumber]);

  const handleNotice = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };

  const dataColumnsReportCheckIn: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 10,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Họ và tên sinh viên",
      dataIndex: "fullName",
      key: "fullName",
      sorter: {
        compare: (a, b) =>
          a?.auth_user?.auth_userprofile?.name?.length -
          b?.auth_user?.auth_userprofile?.name?.length,
      },
      width: 30,
      render: (_, data: any) => {
        return (
          <Flex align="center">
            <Avatar style={{ backgroundColor: "#f56a00" }}>
              {data?.auth_user?.auth_userprofile?.name.charAt(0)}
            </Avatar>
            <Typography.Text className="pl-2">
              {data?.auth_user?.auth_userprofile?.name}
            </Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Thời gian",
      dataIndex: "created_date",
      key: "created_date",
      width: 20,
      render: (_: any, record) =>
        record?.created_at && (
          <Flex className="gap-1">
            <p>{moment(record?.created_at).format("DD/MM/yyyy")}</p>
            <p>{moment(record?.created_at).format("HH:mm:ss")}</p>
          </Flex>
        ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        const onOpenModalVideo = () => {
          setIsOpen(true);
          setDataDetail({
            video_path: record?.video_path,
            listSupervision: record?.mooc_exam_supervision_detail,
          });
        };
        return (
          <Space>
            <span
              className="action-table cursor-pointer"
              onClick={onOpenModalVideo}
            >
              <PlaySquareOutlined />
            </span>
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckIn.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsReportCheckIn.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        course_block_id: Number(courseId),
        take: pageSize,
        skip: (pageNumber - 1) * 10,
        search: searchValue,
      };
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      course_block_id: Number(courseId),
      take: pageSize,
      skip: (pageNumber - 1) * 10,
      search: searchValue,
    };
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      {contextHolder}
      <Form.Provider>
        <div className="table-header--wrapper !justify-between p-6 bg-white">
          <Typography.Title level={4} className="!mb-0">
            Danh sách sinh viên
          </Typography.Title>
          <FilterTable
            searchValue={searchValue}
            handleSubmitSearch={handleSubmitSearch}
            handleSearch={handleSearch}
            handleChangeInput={handleChangeInput}
          />
        </div>
        <TableData
          dataTable={accountReportCheckIn}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
      <ModalVideo
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        dataDetail={dataDetail}
      />
    </Layout>
  );
};

export default ViewResultSupervision;
