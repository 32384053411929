import { useState, useEffect } from "react";
import FormInputNumber from "../../../../../../components/form-input-number/FormInputNumber";
import FormSwitch from "../../../../../../components/form-switch/FormSwitch";
import { ModeQuestion, DEFAULT_SCORE_QUESTION, QuestionTypeEnum } from "../../../../../../constants";
import { useAddLesson } from "../../../../../../stores/stores";
import { convertObjectToArray } from "../../../../../../utils/arrays";
import { UpdateSettingsProps } from "../setting-question-course";
import { InputNumber } from "antd";
import { InputNumberProps } from "antd/lib";
import { QuestionAnswerInfo } from "../../../../../../types/course-construction";

function ScoreSetting(props: UpdateSettingsProps) {
  const { handelChangeForm, handelUpdateSettings } = props;
  const currentQuestionInfo = useAddLesson((state) => state.currentQuestionInfo);
  const setCurrentQuestionInfo = useAddLesson((state) => state.setCurrentQuestionInfo);
  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const question = useAddLesson((state) => state.question);
  const answers = convertObjectToArray(currentQuestionInfo, "questionName") || [];

  const [isMultiAnswers, setisMultiAnswers] = useState<boolean>(false);
  const [isDisplayCriteria, setisDisplayCriteria] = useState<boolean>(false);

  const [scoreOfCorrect, setScoreOfCorrect] = useState<number>(0);
  const [checkChangeTotal, setCheckChangeTotal] = useState<number>(0);

  useEffect(() => {
    handelUpdateSettings(scoreOfCorrect, 'totalScore');
  }, [scoreOfCorrect]);


  useEffect(() => {
    if (question.type === QuestionTypeEnum.CHOOSE_MULTI_TEXT || question.type === QuestionTypeEnum.CHOOSE_MULTI_IMAGE || question.type === QuestionTypeEnum.CHOOSE_MULTI_VIDEO) {
      setisMultiAnswers(true);
    }

    if (question.type === QuestionTypeEnum.SHORT_TEXT || question.type === QuestionTypeEnum.VIDEO || question.type === QuestionTypeEnum.MP3 || question.type === QuestionTypeEnum.FILE_UPLOAD) {
      setisDisplayCriteria(true);
    } else {
      setisDisplayCriteria(false);
    }

    if (settingQuestion.isSetScore4EachAns) {
      handleChangeTotalScore();
    } else {
      handelUpdateSettings(settingQuestion.scoreOfCorrect, 'totalScore');
    }
  }, [currentQuestionInfo, settingQuestion.isSetScore4EachAns]);

  // Change score when total score changes
  useEffect(() => {
    if (answers) {
      handleChangePointByCheckedAnswer();
    }
  }, [checkChangeTotal])

  // Change score when correct answer changes
  const handleChangePointByCheckedAnswer = () => {
    const newAnswers = answers;
    const correctAnswers = newAnswers?.filter((ans) => ans.isTrue)
    let minScoreOfCorrectAnswer = 0;
    if (correctAnswers.length > 0) {
      minScoreOfCorrectAnswer = correctAnswers.reduce((min, ans) => ans.point < min.point ? ans : min).point;
    }

    if (question.type !== QuestionTypeEnum.SHORT_TEXT) {
      newAnswers?.forEach((x, i) => {
        if (!x.isTrue) {
          if (minScoreOfCorrectAnswer > x.point) {
            newAnswers[i] = { ...newAnswers[i], point: x.point };
          } else {
            newAnswers[i] = { ...newAnswers[i], point: minScoreOfCorrectAnswer > 0 ? (minScoreOfCorrectAnswer - 1) : 0 };
          };
        }
      });
      
      setCurrentQuestionInfo({
        ...newAnswers,
        questionName: currentQuestionInfo.questionName,
      });
    }
  }
 
  const handleChangeTotalScore = () => {
    let totalPointAnswers = 0;
    if (question.type === QuestionTypeEnum.SHORT_TEXT) {
      answers.forEach((x, i) => {
        totalPointAnswers += x.point;
        if (i === (answers.length - 1)) {
          handelUpdateSettings(totalPointAnswers, 'totalScore');
          setCheckChangeTotal(totalPointAnswers);
        }
      });
    } else {
      answers.forEach((x, i) => {
        if (x.isTrue) {
          if (isMultiAnswers) {
            totalPointAnswers += x.point;
            handelUpdateSettings(totalPointAnswers, 'totalScore');
            setCheckChangeTotal(totalPointAnswers);
          } else {
            handelUpdateSettings(x.point, 'totalScore');
            setCheckChangeTotal(x.point);
          }
        }
      });
    }
  }

  const handleChangePoint = (e: number, index: number) => {
    const newAnswers = answers;
    const correctAnswers = newAnswers.filter((ans) => ans.isTrue)
    let minScoreOfCorrectAnswer = 0;
    if (correctAnswers.length > 0) {
      minScoreOfCorrectAnswer = correctAnswers.reduce((min, ans) => ans.point < min.point ? ans : min).point;
    }
    
    if(newAnswers[index].isTrue || correctAnswers.length === 0) {
      newAnswers[index] = { ...newAnswers[index], point: e };
    } else {
      if (minScoreOfCorrectAnswer > e) {
        newAnswers[index] = { ...newAnswers[index], point: e };
      } else {
        newAnswers[index] = { ...newAnswers[index], point: minScoreOfCorrectAnswer > 0 ? (minScoreOfCorrectAnswer - 1) : 0 };
      }
    }
    
    setCurrentQuestionInfo({
      ...newAnswers,
      questionName: currentQuestionInfo.questionName,
    });
  };

  const handleCheckScoreCorrect = (e: number) => {
    const inputValue = Number(e);
    const scoreOfCorrect = Number(settingQuestion.scoreOfCorrect);
    if (inputValue >= scoreOfCorrect) {
      handelUpdateSettings(scoreOfCorrect - 1, 'scoreOfInCorrect');
      handelChangeForm(scoreOfCorrect - 1, 'scoreOfInCorrect');
    } else {
      handelUpdateSettings(e, 'scoreOfInCorrect');
    }
  }

  const renderSettingCourseDetail = () => {
    if (settingQuestion.isSetScore4EachAns) {
      if (answers.length) {
        return (
          <>
            {answers.map((item: QuestionAnswerInfo, index: number) => (
              <FormInputNumber
                key={index + 1}
                label={isDisplayCriteria ? `Tiêu chí ${item.id}` : `Lựa chọn ${item.id}`}
                value={item.point}
                onChange={(e: any) => {
                  handleChangePoint(e, index);
                }}
              />
            ))}
          </>
        );
      } else {
        return (
          <div className="center cm-em">
            {isDisplayCriteria ? (<span>Câu hỏi này chưa có tiêu chí.</span>) : (<span>Câu hỏi này chưa có đáp án.</span>)}
          </div>
        );
      }
    } else {
      return;
    }
  };

  return (
    <>
      <FormSwitch name="isSetScore4EachAns" label="Thiết lập điểm cho từng câu trả lời" onChange={handelUpdateSettings} defaultValue={settingQuestion.isSetScore4EachAns} />
      <div className="setting-question-row">
        <span className="row-item secondary-color">Tổng điểm: {settingQuestion.totalScore}</span>
      </div>
      {settingQuestion.isSetScore4EachAns ? (
        renderSettingCourseDetail()
      ) : (
        <>
          <FormInputNumber
            name="scoreOfCorrect"
            label="Trả lời đúng"
            value={settingQuestion.scoreOfCorrect}
            onChange={(e: any) => {
              handelUpdateSettings(e, 'scoreOfCorrect', () => {
                setScoreOfCorrect(e);
              });
            }}
          />
          <FormInputNumber
            name="scoreOfInCorrect"
            label="Trả lời không đúng"
            value={settingQuestion.scoreOfInCorrect}
            onChange={(e: any) => {
              handleCheckScoreCorrect(e);
            }}
          />
        </>
      )}
    </>
  );
}

export default ScoreSetting;