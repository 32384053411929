import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Flex, Switch, Typography, message } from "antd";
import { useEffect, useState } from "react";
import SelectUiCard from "../../../components/select-ui";
import CommonModal from "../../../components/modal/common";
import { updateSettingExamSupervision } from "../../../service/exam-supervision";
import { getSettingAuthority } from "../../../service/setting-authority";
import { DEFAULT_GROUP } from "../../../constants/setting-identity";
const { Title, Text } = Typography;

const optionsUser = [
  { value: "idcard", label: "Căn cước công dân" },
  { value: "passport", label: "Hộ Chiếu " },
];
interface IProps {
  paramFeature: any;
  setParamFeature: (param: any) => void;
}

const SettingNotice = ({ paramFeature, setParamFeature }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditSetting, setIsEditSetting] = useState(false);
  const [user, setUser] = useState([]);
  const [authority, setAuthority] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();

  const fetchSettingAuthority = async () => {
    try {
      const response = await getSettingAuthority();
      const { data } = response.data;
      setAuthority(
        data?.map((option: any) => ({
          label: DEFAULT_GROUP[option?.codename],
          value: option.id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSettingAuthority();
  }, []);

  const onChangeActive = async (checked: boolean) => {
    if (!checked) {
      setIsOpen(true);
    } else {
      await updateSettingExamSupervision(paramFeature?.id, {
        is_notification: checked,
      });
      setParamFeature({
        ...paramFeature,
        is_notification: checked,
      });
      messageApi.open({
        type: "success",
        content: "Thay đổi trạng thái thành công",
      });
    }
  };
  const onChangeUser = (value: any, name: string) => {
    setParamFeature({
      ...paramFeature,
      notification_ids: value,
    });
  };
  const onCloseModal = () => {
    setIsOpen(false);
  };
  const handleConfirmNotice = async () => {
    try {
      await updateSettingExamSupervision(paramFeature?.id, {
        is_notification: false,
      });
      messageApi.open({
        type: "success",
        content: "Thay đổi trạng thái thành công",
      });
      setParamFeature({
        ...paramFeature,
        is_notification: false,
      });
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async () => {
    try {
      await updateSettingExamSupervision(paramFeature?.id, {
        notification_ids: paramFeature?.notification_ids,
      });
      messageApi.open({
        type: "success",
        content: "Lưu thông tin thành công",
      });
      setIsEditSetting(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {contextHolder}
      <Card className="w-full lg:w-2/3 m-auto mt-6">
        <Flex justify="space-between" align="center" className="border-b pb-3">
          <div>
            <Title level={4} className="!m-0">
              Cài đặt thông báo hành vi vi phạm thi cử trong video
            </Title>
            <Text className="text-[#00000073]">
              Chọn cài đặt tính năng nhận thông báo giám sát thi cử trong video
            </Text>
          </div>
        </Flex>
        <Flex className="py-4">
          <span className="!font-semibold pr-8">Trạng thái</span>
          <div className="px-4">
            <Switch
              defaultChecked
              disabled={!paramFeature?.is_active}
              onChange={(checked) => onChangeActive(checked)}
              checked={paramFeature?.is_notification}
            />
            <Text className="px-4">
              {paramFeature?.is_notification
                ? "Đang hoạt động"
                : "Không hoạt động"}
            </Text>
          </div>
        </Flex>
      </Card>
      {paramFeature?.is_notification && (
        <Card className="w-full lg:w-2/3 m-auto mt-6">
          <Flex
            justify="space-between"
            align="center"
            className="border-b pb-3"
          >
            <Flex className="w-4/5" align="center" wrap="wrap">
              <Title level={5} className="w-full lg:w-1/3 !mb-0 pr-2">
                Chọn người nhận thông báo
              </Title>
              <SelectUiCard
                className="font-medium w-full lg:w-2/3"
                name="notification_ids"
                label=""
                options={authority}
                mode="multiple"
                allowClear
                disabled={!isEditSetting}
                value={paramFeature?.notification_ids}
                onChange={(val) => onChangeUser(val, "notification_ids")}
              />
            </Flex>
            {isEditSetting ? (
              <Flex justify="end" className="mb-[5px]">
                <Button type="primary" onClick={handleSubmit}>
                  Lưu
                </Button>
              </Flex>
            ) : (
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind mb-[5px]"
                onClick={() => setIsEditSetting(true)}
              >
                <EditOutlined />
              </Button>
            )}
          </Flex>
          <CommonModal
            open={isOpen}
            title={"Xác nhận tắt cài đặt thông báo?"}
            desc={
              "Bạn có chắc chắn muốn tắt cài đặt Cài đặt thông báo hành vi vi phạm thi cử trong video?"
            }
            closeIcon={true}
            okText={"Xác nhận"}
            cancelText={"Huỷ"}
            icon={
              <InfoCircleOutlined
                style={{ color: "#FFC53D", marginRight: "10px" }}
              />
            }
            confirm={handleConfirmNotice}
            closeModal={onCloseModal}
          />
        </Card>
      )}
    </div>
  );
};

export default SettingNotice;
