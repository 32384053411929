import {Card, Col, Empty, Row, Spin, Statistic} from "antd";
import React, {useEffect, useState} from "react";
import StatisticalReportCard from "../../StatisticalReportCard";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import {get} from "lodash";
import {CaretUpOutlined} from "@ant-design/icons";
import "./resource-chart-tab.scss";
import {BarStackedChartProps, PieChart} from "../../../../../../types/static-report";
import useChartExport from "../../../../for-instructors/components/chart/useChartExport";
import {staticReportStore} from "../../../../../../stores/stores";

type Props = {
  loading: Record<string, boolean>,
  dataListSynthesizeCourseUniversity: Array<any>,
  dataListCourseByFormatUniversity: PieChart[],
  dataListCourseByIndustryGroup: BarStackedChartProps[],
  dataListCourseByTeacherUniversity: BarStackedChartProps[],
  dataListUnitByIndustryGroup: PieChart[],
  dataListUniversityTestByType: PieChart[],
  dataListUniversityReferenceSourceByType: PieChart[],
  dataListUniversityScormXapiUnit: PieChart[],
  dataListUniversityMultiMediaUnit: PieChart[]
};

const ResourceChartTab = (props: Props) => {
  const {
    loading,
    dataListSynthesizeCourseUniversity,
    dataListCourseByFormatUniversity,
    dataListCourseByIndustryGroup,
    dataListCourseByTeacherUniversity,
    dataListUnitByIndustryGroup,
    dataListUniversityTestByType,
    dataListUniversityReferenceSourceByType,
    dataListUniversityScormXapiUnit,
    dataListUniversityMultiMediaUnit
  } = props;

  const [dataStatistical, setDataStatistical] = useState({
    totalCourse: 0,
    rateCourse: 0,
    totalResource: 0,
    rateResource: 0,
    totalTest: 0,
    rateTest: 0,
    totalScormXAPI: 0,
    rateScormXAPI: 0,
    totalMultimedia: 0,
    rateMultimedia: 0,
    totalReferences: 0,
    rateReferences: 0,
  });

  // Hook for managing chart exports
  const {createChartRef, handleExportImage, handleExportAllImagesAsZip} =
    useChartExport();

  const {tab, setHandleExportAllImagesAsZip, exportType} =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  useEffect(() => {
    const course: any = dataListSynthesizeCourseUniversity.find((item: any) => item?.criteria === "Khóa học");
    const resource: any = dataListSynthesizeCourseUniversity.find((item: any) => item?.criteria === "Tài nguyên");
    const test: any = dataListSynthesizeCourseUniversity.find((item: any) => item?.criteria === "Bài kiểm tra");
    const scormXAPI: any = dataListSynthesizeCourseUniversity.find((item: any) => item?.criteria === "SCORM & xAPI");
    const multimedia: any = dataListSynthesizeCourseUniversity.find((item: any) => item?.criteria === "Đa phương tiện");
    const references: any = dataListSynthesizeCourseUniversity.find((item: any) => item?.criteria === "Tài liệu tham khảo");

    setDataStatistical({
      totalCourse: course?.count,
      rateCourse: typeof course?.percentage === 'number' ? course.percentage.toFixed(2) : '0',
      totalResource: resource?.count,
      rateResource: typeof resource?.percentage === 'number' ? resource.percentage.toFixed(2) : '0',
      totalTest: test?.count,
      rateTest: typeof test?.percentage === 'number' ? test.percentage.toFixed(2) : '0',
      totalScormXAPI: scormXAPI?.count,
      rateScormXAPI: typeof scormXAPI?.percentage === 'number' ? scormXAPI.percentage.toFixed(2) : '0',
      totalMultimedia: multimedia?.count,
      rateMultimedia: typeof multimedia?.percentage === 'number' ? multimedia.percentage.toFixed(2) : '0',
      totalReferences: references?.count,
      rateReferences: typeof references?.percentage === 'number' ? references.percentage.toFixed(2) : '0',
    });

  }, [dataListSynthesizeCourseUniversity])

  const renderStatistical = () => {
    return (
      <>
        <Col sm={{span: 4}} xs={{span: 24}}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Khóa học"
              value={get(dataStatistical, 'totalCourse', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateCourse', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col sm={{span: 4}} xs={{span: 24}}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Bài giảng"
              value={get(dataStatistical, 'totalResource', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateResource', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col sm={{span: 4}} xs={{span: 24}}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Bài kiểm tra"
              value={get(dataStatistical, 'totalTest', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span className="percentage-difference">{get(dataStatistical, 'rateTest', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col sm={{span: 4}} xs={{span: 24}}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="SCORM/x API"
              value={get(dataStatistical, 'totalScormXAPI', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateScormXAPI', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col sm={{span: 4}} xs={{span: 24}}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Đa phương tiện"
              value={get(dataStatistical, 'totalMultimedia', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateMultimedia', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
        <Col sm={{span: 4}} xs={{span: 24}}>
          <Card bordered={false}>
            <Statistic
              className="card-statistical"
              title="Tài liệu tham khảo"
              value={get(dataStatistical, 'totalReferences', 0)}
              precision={2}
              valueStyle={{fontWeight: 500, fontSize: 30}}
              formatter={(value) => (
                <>
                  {value}<span
                  className="percentage-difference">{get(dataStatistical, 'rateReferences', 0)}%<CaretUpOutlined
                  style={{color: "#52C41A"}}/></span>
                </>
              )}
            />
          </Card>
        </Col>
      </>
    )
  }

  return (
    <>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        {renderStatistical()}
        <Col sm={{span: 12}} xs={{span: 24}} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng khóa học theo hình thức xuất bản"
            content={
              <Spin spinning={loading.dataListCourseByFormatUniversity}>
                {dataListCourseByFormatUniversity.length > 0 ? (
                  <DonutChart
                    data={dataListCourseByFormatUniversity}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    totalName="Tổng số"
                    ref={createChartRef(tab, "dataListCourseByFormatUniversity")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "dataListCourseByFormatUniversity", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "dataListCourseByFormatUniversity",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khóa học theo khoa"
            content={
              <Spin spinning={loading.dataListCourseByIndustryGroup}>
                {dataListCourseByIndustryGroup.length > 0 ? (
                  <BarStackedChart
                    data={dataListCourseByIndustryGroup}
                    colors={["#FFC069", "#5CDBD3", "#69C0FF"]}
                    hasTotal={true}
                    ref={createChartRef(tab, "dataListCourseByIndustryGroup")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "dataListCourseByIndustryGroup", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "dataListCourseByIndustryGroup",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khóa học theo giảng viên"
            content={
              <Spin spinning={loading.dataListCourseByTeacherUniversity}>
                {dataListCourseByTeacherUniversity.length > 0 ? (
                  <BarStackedChart
                    data={dataListCourseByTeacherUniversity}
                    colors={["#5CDBD3", "#69C0FF"]}
                    hasTotal={true}
                    ref={createChartRef(tab, "dataListCourseByTeacherUniversity")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "dataListCourseByTeacherUniversity", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "dataListCourseByTeacherUniversity",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng bài giảng theo khoa"
            content={
              <Spin spinning={loading.dataListUnitByIndustryGroup}>
                {dataListUnitByIndustryGroup.length > 0 ? (
                  <BarChart
                    data={dataListUnitByIndustryGroup}
                    colors={["#69C0FF", "#FFC069"]}
                    showLegend={false}
                    hasTotal={true}
                    ref={createChartRef(tab, "dataListUnitByIndustryGroup")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "dataListUnitByIndustryGroup", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "dataListUnitByIndustryGroup",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng bài kiểm tra"
            content={
              <Spin spinning={loading.dataListUniversityTestByType}>
                {dataListUniversityTestByType.length > 0 ? (
                  <DonutChart
                    data={dataListUniversityTestByType}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    ref={createChartRef(tab, "dataListUniversityTestByType")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "dataListUniversityTestByType", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "dataListUniversityTestByType",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng tài liệu tham khảo"
            content={
              <Spin spinning={loading.dataListUniversityReferenceSourceByType}>
                {dataListUniversityReferenceSourceByType.length > 0 ? (
                  <DonutChart
                    data={dataListUniversityReferenceSourceByType}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    totalName="Tổng số"
                    ref={createChartRef(tab, "dataListUniversityReferenceSourceByType")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "dataListUniversityReferenceSourceByType", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "dataListUniversityReferenceSourceByType",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng SCORM & xAPI"
            content={
              <Spin spinning={loading.dataListUniversityScormXapiUnit}>
                {dataListUniversityScormXapiUnit.length > 0 ? (
                  <DonutChart
                    data={dataListUniversityScormXapiUnit}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    totalName="Tổng số"
                    ref={createChartRef(tab, "dataListUniversityScormXapiUnit")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "dataListUniversityScormXapiUnit", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "dataListUniversityScormXapiUnit",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}} className="col-donut-chart">
          <StatisticalReportCard
            chartName="Số lượng học liệu đa phương tiện"
            content={
              <Spin spinning={loading.dataListUniversityMultiMediaUnit}>
                {dataListUniversityMultiMediaUnit.length > 0 ? (
                  <DonutChart
                    data={dataListUniversityMultiMediaUnit}
                    title=""
                    height={300}
                    innerSize="70%"
                    size="80%"
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    totalName="Tổng số"
                    ref={createChartRef(tab, "dataListUniversityMultiMediaUnit")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "dataListUniversityMultiMediaUnit", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(
                    tab,
                    "dataListUniversityMultiMediaUnit",
                    "application/pdf"
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default ResourceChartTab;
