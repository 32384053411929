// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answer-pair {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .answer-pair .connect-answer {
    width: 47%; }
    .answer-pair .connect-answer .text-area-answer {
      border-radius: 6px;
      min-height: 32px; }
    .answer-pair .connect-answer .current-letter {
      margin-bottom: 5px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/question-item/connect/style.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,WAAW;EACX,8BAA8B,EAAA;EAHlC;IAMQ,UAAU,EAAA;IANlB;MASY,kBAAkB;MAClB,gBAAgB,EAAA;IAV5B;MAcY,kBAAkB,EAAA","sourcesContent":[".answer-pair {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n\n    .connect-answer {\n        width: 47%;\n\n        .text-area-answer {\n            border-radius: 6px;\n            min-height: 32px;\n        }\n\n        .current-letter {\n            margin-bottom: 5px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
