// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multimedia .multimedia-img {
  width: 60px; }

.multimedia .ant-col {
  padding: 8px 0px !important; }

.multimedia .multimedia-item {
  cursor: pointer;
  padding: 16px; }
  .multimedia .multimedia-item .ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 0px; }
  .multimedia .multimedia-item:hover {
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: 8px; }
  .multimedia .multimedia-item .ant-row {
    padding: 0px 12px; }
`, "",{"version":3,"sources":["webpack://./src/components/modal/add-lession/references/style.scss"],"names":[],"mappings":"AAEA;EAEI,WAAW,EAAA;;AAFf;EAKI,2BAA2B,EAAA;;AAL/B;EAWI,eAAe;EACf,aAAa,EAAA;EAZjB;IASM,iBAAiB,EAAA;EATvB;IAcM,qCAAqC;IACrC,kBAAkB,EAAA;EAfxB;IAkBM,iBAAiB,EAAA","sourcesContent":["\n\n.multimedia{\n  .multimedia-img{\n    width: 60px;\n  }\n  .ant-col{\n    padding: 8px 0px !important;\n  }\n  .multimedia-item{\n    .ant-tabs-left >.ant-tabs-content-holder >.ant-tabs-content>.ant-tabs-tabpane{\n      padding-left: 0px;\n    }\n    cursor: pointer;\n    padding: 16px;\n    &:hover{\n      background-color: rgba(0, 0, 0, 0.06);\n      border-radius: 8px;\n    }   \n    .ant-row{\n      padding: 0px 12px;\n    } \n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
