export const dataOptionTime = [
  {
    value: 'progress', label: 'Hiển thị thời gian thực hiện'
  },
  {
    value: 'finish', label: 'Hiển thị thời gian hoàn tất'
  }
]

export const dataTimeLimited = [
  {
    value: false, label: 'Không giới hạn', description: 'Không giới hạn thời gian thực hiện'

  },
  {
    value: true, label: 'Giới hạn thời gian thực hiện', description: 'Cần thực hiện trước thời gian giới hạn'
  }
]


export const dataTimeSettings = [
  {
    id: 1,
    value: 'limitTimeFinish',
    label: 'Giới hạn thời gian hoàn thành tất cả câu hỏi',
    inputValue: {
      label: 'phút'
    }
  },

  {
    id: 2, value: 'limitTimeProgress', label: 'Giới hạn thời gian thực hiện mỗi câu hỏi', inputValue: {
      label: 'phút'
    }
  },
  {
    id: 3, value: 'limitPersonProgress', label: 'Giới hạn số người thực hiện', inputValue: {
      label: 'người'
    }
  },
  {
    id: 4, value: 'limitTurnProgress', label: 'Giới hạn số lượt thực hiện của một người', inputValue: {
      label: 'lượt/người'
    }
  },
]

export const dataLayoutSetting = [
  {
    code: 'default', label: 'Cơ bản', description: 'Hiển thị tất cả câu hỏi trên một trang'
  },
  {
    code: 'by_group',
    label: 'Theo từng phần',
    description: 'Hiển thị mỗi trang một phần',
    displayDropdown: true,
    dataDropdown: [{code: 'in_turn', label: 'Lần lượt'}, {code: 'optional', label: 'Tùy chọn'}]
  },
  {
    code: 'by_quiz',
    label: 'Theo từng câu hỏi',
    description: 'Hiển thị mỗi trang một câu hỏi',
    displayDropdown: true,
    dataDropdown: [{code: 'in_turn', label: 'Lần lượt'}, {code: 'optional', label: 'Tùy chọn'}]
  }
]
