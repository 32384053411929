// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tree-course .ant-tree-treenode {
  width: 100%; }
  .tree-course .ant-tree-treenode .ant-tree-node-content-wrapper {
    display: flex;
    align-items: center;
    width: 100%; }
    .tree-course .ant-tree-treenode .ant-tree-node-content-wrapper .ant-tree-title {
      width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/automatic-mark-detail/automatic-mark.scss"],"names":[],"mappings":"AAAA;EAEQ,WAAW,EAAA;EAFnB;IAIY,aAAa;IACb,mBAAmB;IACnB,WAAW,EAAA;IANvB;MAQgB,WAAW,EAAA","sourcesContent":[".tree-course {\n    .ant-tree-treenode{\n        width: 100%;\n        .ant-tree-node-content-wrapper {\n            display: flex;\n            align-items: center;\n            width: 100%;\n            .ant-tree-title{\n                width: 100%;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
