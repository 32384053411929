import {
  Button,
  Checkbox,
  Col,
  Form,
  message,
  Modal,
  Radio,
  Row,
  Space,
  TableColumnsType,
  TableProps,
  Typography
} from 'antd'
import React, {useEffect, useState} from 'react'
import FormInput from '../../form-input/FormInput'
import {ExclamationCircleOutlined, SearchOutlined} from '@ant-design/icons'
import TableData from '../../table-data/TableData'
import {useParams} from 'react-router-dom'
import {isEmpty} from 'lodash'
import {copyTemplateSection, getListSequenceById} from '../../../service/content-list-course/contentListCourseApi'

type Props = {
  open: boolean
  handleClose: () => void
  getListDataProps: () => void
  searchValue: string;
  filterData: any;
  tab: string
}

export default function CopyTemplatesSequenceCourse(props: Props) {
  const {open, handleClose, searchValue, tab, filterData, getListDataProps} = props

  const [listData, setListData] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [searchValueSynPopup, setSearchValueSynPopup] = useState<string>('')
  const params = useParams()
  const CheckboxGroup = Checkbox.Group;
  const [form] = Form.useForm()

  const renderStatus = (value: string) => {
    switch (Number(value)) {
      case 0:
        return "Bản nháp"
        break;
      case 1:
        return "Riêng tư"
        break;
      case 2:
        return "Công khai"
        break;
      default:
        return "Bản nháp"
        break;
    }
  }

  const validations = {
    sectionName: {
      required: true,
      message: "Vui lòng chọn chương",
    },
  };

  const dataColumnsLesson: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Tên chương",
      dataIndex: "name",
      key: "name",
      sorter: true,
      width: 30,
    },
    {
      title: "Trạng thái",
      dataIndex: "availableStatus",
      key: "availableStatus",
      width: 30,
      render: (_: any, record, index: number) => {
        return <div>{renderStatus(record?.availableStatus)}</div>;
      },
    },
    {
      title: "Ngày xuất bản",
      dataIndex: "publishDate",
      key: "publishDate",
      width: 30,
    },
    {
      title: "Ngày bắt đầu",
      dataIndex: "startDate",
      key: "startDate",
      width: 30,
    },
  ]

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
  };

  const getDataSectionByid = async () => {
    const data = {
      courseId: Number(params?.id) ?? 0,
      type: 1,
      keyword: searchValueSynPopup.trim(),
      sectionId: tab === "1" ? null : Number(filterData.sectionId),
      sequenceId: tab === "1" || tab === '2' ? null : Number(filterData.sequenceId),
      startedDateFrom: isEmpty(filterData.planCreationDate) ? filterData.planCreationDate?.[0] : null,
      startedDateTo: isEmpty(filterData.planCreationDate) ? filterData.planCreationDate?.[0] : null,
      publishedDateFrom: isEmpty(filterData.planApprovalDate) ? filterData.planApprovalDate?.[1] : null,
      publishedDateTo: isEmpty(filterData.planApprovalDate) ? filterData.planApprovalDate?.[1] : null,
      availableStatus: filterData.availableStatus ?? null,
      sortByName: statusOrder,
      page: pageNumber,
      size: pageSize
    }
    await getListSequenceById(data).then((res: any) => {
      setListData(res.data?.body?.content)
      setTotalRecords(res.data?.body?.totalElements)
    })

  }

  const getListData = async () => {
    getDataSectionByid()
  };

  const saveData = async (values: any) => {
    const arrIdSelectedRowkey = selectedRowKeys.map((item: any) => item.id)

    const data = {
      ids: arrIdSelectedRowkey,
      name: values.sectionName,
      description: values.description,
      status: 0
    }

    await copyTemplateSection(data).then((res) => {
      if (res.status === 200) {
        message.success("Tạo bản sao chương thành công")
        form.resetFields()
        handleClose()
        getListDataProps()
      }
    })
  }

  useEffect(() => {
    getListData();
    // if (!isEmpty(searchValue)) {
    //   setSearchValueSynPopup(searchValue)
    // }
    if (!open) {
      setSearchValueSynPopup("")
      setSelectedRowKeys([])
      form.resetFields()
    }
  }, [open, pageNumber, statusOrder]);

  return (
    <Form.Provider onFormFinish={async (name, {values, forms}) => {
      if (name === 'copy-template-course-modal') {

        if (isEmpty(selectedRowKeys)) {
          message.error("Bạn chưa chọn chương để tạo bản sao")
          return
        }

        Modal.confirm({
          title: `Xác nhận tạo bản sao chương`,
          content: `Bạn có chắc chắn muốn tạo bản sao chương?`,
          onOk: () => {
            saveData(values);
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{color: "#1677FF"}}/>,
          footer: (_, {OkBtn, CancelBtn}) => (
            <>
              <CancelBtn/>
              <OkBtn/>
            </>
          ),
        });
      }
    }}>
      <Modal
        className="copy-template-content-course-modal"
        title={"Tạo bản sao chương"}
        closeIcon={true}
        centered
        open={open}
        onCancel={handleClose}
        footer={null}
        width={"80%"}
      >
        <Form
          form={form}
          name="copy-template-course-modal"
          layout="vertical"
        >
          <Row gutter={40} className='p-6'>
            <Col md={{span: 16}} lg={{span: 16}} xl={{span: 16}} xs={{span: 16}}>
              <Form.Item name="search">
                <FormInput
                  placeholder="Nhập từ khoá cần tìm"
                  value={searchValueSynPopup}
                  onChange={(e: any) => setSearchValueSynPopup(e.target?.value)}
                  onKeyPress={(e: any) => setSearchValueSynPopup(e.target?.value)}
                  afterPrefixIcon={<SearchOutlined className='cursor-pointer' onClick={getListData}/>}
                />
              </Form.Item>

              <TableData
                dataTable={listData}
                dataColumns={dataColumnsLesson}
                setPageNumber={setPageNumber}
                selectOneRow={true}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                pageSize={pageSize}
                loadingTable={loading}
                scroll={{x: 1500, y: 600}}
                totalRecords={totalRecords}
                handleTableChangeProps={handleTableChange}
                setSelectedRowKeysProps={setSelectedRowKeys}
                rowSelected={selectedRowKeys}
                pagination={true}
                hideSelection={true}
              />
            </Col>
            <Col md={{span: 8}} lg={{span: 8}} xl={{span: 8}} xs={{span: 8}}>
              <Typography.Title level={5}>Thông tin chương</Typography.Title>
              <div>
                <Form.Item name={"sectionName"} label="Tên chương" rules={[validations.sectionName]}>
                  <FormInput placeholder="Nhập tên bài giảng"/>
                </Form.Item>

                <Form.Item name={"description"} label="Mô tả" className='mt-2'>
                  <FormInput placeholder="Nhập mô tả"/>
                </Form.Item>

                <Typography.Title level={5} className='mt-3'>Trạng thái</Typography.Title>
                <Form.Item>
                  <Radio checked>Bản nháp</Radio>
                </Form.Item>
                <p>Phần này sẽ không được người dùng nhìn thấy</p>
              </div>

              <div className='mt-9'>
                <Space className='float-right'>
                  <Button onClick={handleClose} className="btn btn-outlined">
                    <Typography.Text>
                      Hủy
                    </Typography.Text>
                  </Button>
                  <Button className="btn btn-primary" onClick={() => form.submit()}
                  >
                    <Typography.Text>
                      Lưu
                    </Typography.Text>
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Provider>
  )
}
