export interface IDataInfo {
  fieldName: string;
  title: string;
  fieldValue: string | Array<never> | boolean;
}


export const dataInfo: IDataInfo[] = [
  { fieldName: 'email', title: 'Email', fieldValue: 'text' },
  { fieldName: 'vaiTros', title: 'Vai trò', fieldValue: [] },
  { fieldName: 'trangThai', title: 'Trạng thái', fieldValue: false },
  { fieldName: 'ngonNgu', title: 'Ngôn ngữ', fieldValue: [] },
  { fieldName: 'muiGio', title: 'Múi giờ', fieldValue: [] },
]

export const dataInfoUser: IDataInfo[] = [
  { fieldName: 'email', title: 'Email', fieldValue: 'text' },
  { fieldName: 'vaiTros', title: 'Vai trò', fieldValue: [] },
  { fieldName: 'ngonNgu', title: 'Ngôn ngữ', fieldValue: [] },
  { fieldName: 'muiGio', title: 'Múi giờ', fieldValue: [] },
]


export const dataDocumentCommon = [
  { fieldName: 'hoVaTen', title: 'Họ và tên', fieldValue: 'text' },
  { fieldName: 'ngaySinh', title: 'Ngày Sinh', fieldValue: Date },
  { fieldName: 'gioiTinh', title: 'Giới tính', fieldValue: 'text' },
  { fieldName: 'donViCongTac', title: 'Đơn vị công tác', fieldValue: [] },
  { fieldName: 'chucVus', title: 'Chức vụ', fieldValue: [] },
  // {fieldName: 'vaiTros', title: 'Vai trò', fieldValue: []},
  { fieldName: 'chuyenNganh', title: 'Chuyên ngành', fieldValue: 'text' },
  { fieldName: 'trinhDo', title: 'Trình độ học vấn', fieldValue: 'text' },
]

export const dataDocumentContact = [
  { fieldName: 'dienThoai', title: 'Số điện thoại', fieldValue: 'text' },
  { fieldName: 'diaChi', title: 'Địa chỉ', fieldValue: 'text' },
]


export const changePasswordField = ['oldPassword', 'newPassword', 'reWriteNewPassword']


export const dataLanguages = [
  {
    label: 'Việt nam',
    ten: 'Việt nam'
  },
  {
    label: 'Tiếng Anh',
    ten: 'Tiếng Anh'
  }
]

export const dataTimes = [
  {
    label: 'GTM +07',
    ten: 'GTM+7'
  },
  {
    label: 'GTM +08',
    ten: 'GTM+8'
  }
]
