// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-responed {
  padding: 50px;
  width: 100%;
  overflow-x: auto; }
  .wrapper-responed .detail-account {
    display: flex;
    width: 100%;
    justify-content: center; }
    .wrapper-responed .detail-account .form-detail {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      width: 100% !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/detail-content/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAgB;EAChB,WAAgB;EAEhB,gBAAgB,EAAA;EAJpB;IAOQ,aAAqB;IACrB,WAAqB;IACrB,uBAAuB,EAAA;IAT/B;MAYY,aAAoB;MACpB,sBAAsB;MACtB,mBAAsB;MACtB,YAAoB;MACpB,sBAA+B,EAAA","sourcesContent":[".wrapper-responed {\n    padding   : 50px;\n    width     : 100%;\n    // height: calc(100vh - 168px);\n    overflow-x: auto;\n\n    .detail-account {\n        display        : flex;\n        width          : 100%;\n        justify-content: center;\n\n        .form-detail {\n            display       : flex;\n            flex-direction: column;\n            align-items   : center;\n            height        : auto;\n            width         : 100% !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
