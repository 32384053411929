import { ArrowLeftOutlined, CloseOutlined, InfoCircleOutlined, } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, message, Radio, RadioChangeEvent, Row, Space, Typography, } from "antd";
import React, { useEffect, useState } from "react";
import {
  getSectionDetail,
  getSquenceDetail,
  postCreateSection,
  postCreateSequence,
  putCourseUpdateSection,
  putCourseUpdateSequence,
} from "../../../service/course-construct";
import FormInput from "../../form-input/FormInput";
import CommonModal from "../../modal/common";
import "./addChapter.scss";

type Props = {
  open?: boolean;
  isLesson?: boolean;
  title?: string;
  setIsOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  id?: number;
  handleDetail?: any;
  itemDetail?: ItemDetail;
  isEdit?: boolean;
  sectionDetail?: any;
};

const AddChapter = (props: Props) => {
  const {
    open,
    setIsOpenDrawer,
    id,
    handleDetail,
    isLesson,
    isEdit,
    itemDetail,
  } = props;
  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string>("");
  const [describe, setDescribe] = useState<string>("");
  const [availableStatus, setAvailableStatus] = useState(0);
  const [sectionDetail, setSectionDetail] = useState<any>(null);

  const getSectionDetailApi = async () => {
    const apiParams = isLesson ? getSquenceDetail : getSectionDetail;
    await apiParams(Number(itemDetail?.id)).then((res) => {
      if(res.status === 200) {
        setSectionDetail(res.data);
      }
    });
  };

  useEffect(() => {
    if (isEdit) {
      getSectionDetailApi();
    }
  }, [open]);

  useEffect(() => {
    form.setFieldValue("roleNames", sectionDetail?.name)
    form.setFieldValue("describe", sectionDetail?.description)
    setTitle(sectionDetail?.name);
    setDescribe(sectionDetail?.description);
    setAvailableStatus(sectionDetail?.availableStatus ?? 0);
  }, [sectionDetail]);

  const onCloseDraw = () => {
    setStep(1);
    form.resetFields();
    setIsOpenDrawer(false);
    setTitle("");
    setDescribe("");
  };
  const onCloseModal = () => {
    setIsOpen(false);
  };
  const handleRadioChange = (e: RadioChangeEvent) => {
    setAvailableStatus(e.target.value);
  };
  const renderNameDraw = () => {
    if (isLesson) {
      return isEdit ? "Sửa bài giảng" : "Thêm bài giảng";
    } else return isEdit ? "Sửa chương" : "Thêm chương";
  };
  const handleAddSection = async () => {
    const params = {
      name: title,
      description: describe,
      availableStatus: availableStatus,
    };
    const submitApiUrl = () => {
      if (isLesson) {
        if (isEdit) {
          return putCourseUpdateSequence({
            ...params,
            sectionId: id,
            id: Number(itemDetail?.id),
          });
        } else
          return postCreateSequence({
            ...params,
            sectionId: itemDetail?.id,
          });
      } else {
        if (isEdit) {
          return putCourseUpdateSection({
            ...params,
            sectionId: itemDetail?.id,
          });
        } else
          return postCreateSection({
            ...params,
            courseId: id,
          });
      }
    };
    try {
      const response = await submitApiUrl().then((result: any) => {
        handleDetail();
        onCloseModal();
        onCloseDraw();
        message.success(renderNameDraw() + " thành công");
      });
    } catch (error) {
    }
  };

  const radioItemList = [
    {
      value: 0,
      title: "Bản nháp",
      describe: "Mặc định khi khởi tạo. Người học sẽ không thể tìm kếm hoặc nhìn thấy nội dung này ở trên nền tảng.",
    },
    {
      value: 1,
      title: "Riêng tư",
      describe: "Nội dung chỉ hiển thị với những Người học được Giảng viên đăng ký hoặc Người học đã trả phí.",
    },
    {
      value: 2,
      title: "Công khai",
      describe: "Nội dung được hiển thị công khai trên danh sách nội dung học và Người học có thể tìm kiếm, học nội dung.",
    }
  ];
  const renderAddChapterS1 = () => {
    return (
      <>
        <Form.Item
          label={`${"Tiêu đề " + (isLesson ? "bài giảng" : "chương")}`}
          name="roleNames"
          rules={[
            {
              required: true,
              message: "Thông tin này bắt buộc",
            },
          ]}
        >
          <FormInput
            placeholder="Nhập tiêu đề"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>
        <div className="add-chapter-describe">
          Hãy đặt tên ngắn gọn, cung cấp thông tin và hấp dẫn
        </div>
        <Form.Item label="Mô tả" name="describe">
          <Input.TextArea
            placeholder="Nhập mô tả"
            autoSize={{ minRows: 6 }}
            value={describe}
            onChange={(e) => setDescribe(e.target.value)}
          />
        </Form.Item>
        <div className="add-chapter-describe">
          Cung cấp mô tả ngắn gọn cho phần này
        </div>
        <div className="add-chapter-btn">
          <Space>
            <Button className="btn btn-primary" onClick={() => form.submit()}>
              <Typography.Text>Tiếp tục</Typography.Text>
            </Button>
            <Button onClick={onCloseDraw} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>Đóng</Typography.Text>
            </Button>
          </Space>
        </div>
      </>
    );
  };
  const renderAddChapterS2 = () => {
    return (
      <>
        <Row>
          <Col span={12}>
            <Typography.Title className="add-chapter-title" level={5}>
              Truy cập
            </Typography.Title>
            <span className="add-chapter-describe">
              Thiết lập tính khả dụng của phần này. Bản nháp là chế độ mặc định
              (chưa xuất bản và không hiển thị) để có thời gian phát triển nội
              dung của bạn
            </span>
          </Col>
          <Col span={12}>
            <Radio.Group value={availableStatus} defaultValue={availableStatus} onChange={handleRadioChange}>
              {radioItemList.map((item, index) => (
                <div key={index} className="radio-item wrapper-radio">
                  <Radio value={item.value}>
                    <Typography.Title className="add-chapter-title" level={5}>
                      {item.title}
                    </Typography.Title>
                  </Radio>
                  <div className="add-chapter-describe">{item.describe}</div>
                </div>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <div className="add-chapter-confirm">
          <Space>
            <ArrowLeftOutlined />
            <div className="add-chapter-back" onClick={() => setStep(1)}>
              <Typography.Text>Bước trước</Typography.Text>
            </div>
          </Space>

          <Space>
            <Button className="btn btn-primary" onClick={() => setIsOpen(true)}>
              <Typography.Text>Lưu</Typography.Text>
            </Button>
            <Button onClick={onCloseDraw} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>Đóng</Typography.Text>
            </Button>
          </Space>
        </div>
      </>
    );
  };
  return (
    <Form.Provider onFormFinish={(name, { values, forms }) => {
      if (name === 'add-chapter') {
        setStep(2)
      }
    }}>
      <Drawer
        title={`${renderNameDraw()} ${isEdit ? sectionDetail?.name : ""}`}
        onClose={onCloseDraw}
        open={open}
        width={736}
      >
        <Form
          className="add-chapter"
          layout={"vertical"}
          form={form}
          name="add-chapter"
        >
          {step === 1 ? renderAddChapterS1() : renderAddChapterS2()}
        </Form>
        <CommonModal
          open={isOpen}
          title={`Thông báo xác nhận lưu ${isLesson ? "bài giảng" : "chương"}`}
          desc={
            <><p>Xin vui lòng xác nhận việc lưu {`${isLesson ? "bài giảng" : "chương"}`} trong khoá học</p><div>
              Đảm bảo rằng tất cả các{" "}
              <span className="text-browse">
                thông tin liên quan đã được kiểm tra và cập nhật đầy đủ{" "}
              </span>
              trước khi lưu khoá học
            </div></>
          }
          closeIcon={true}
          okText={"Xác nhận"}
          cancelText={"Huỷ"}
          icon={
            <InfoCircleOutlined
              style={{ color: "#FFC53D", marginRight: "10px" }}
            />
          }
          confirm={handleAddSection}
          closeModal={onCloseModal}
          className={""}
        />
      </Drawer>
    </Form.Provider>
  );
};

export default AddChapter;

interface ItemDetail {
  name: string;
  describe: string;
  id: number;
}
