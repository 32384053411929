import React, {useEffect, useMemo, useState, useTransition} from "react";
import {staticReportStore} from "../../../../../stores/stores";
import {debounce} from "lodash";
import dayjs from "dayjs";
import {BarStackedChartProps, DualChart} from "../../../../../types/static-report";
import {
  getCourseDiscussActionUniversity,
  getListRateUnitByCourse,
  getListRateUnitByModule,
  getListRateUnitBySequence,
  getListUnitReviewAndAccess,
  getUnitDiscussUniversity,
} from "../../../../../service/statistical-report";
import FeedbackAndEvaluationChartTab from "../chart/feedback-and-evaluation-tab/FeedbackAndEvaluationChartTab";
import FeedbackEvaluationTable from "../table/feedback-evaluation";

type Props = {
  dataType: string;
  universityId?: number;
};

const FeedbackAndEvaluationTab = (props: Props) => {
  const {dataType, universityId} = props;
  const {filterValues, tab} = staticReportStore();
  const [moduleGroup, setModuleGroup] = useState(3);
  const [isPending, startTransition] = useTransition();

  const [chartData, setChartData] = useState({
    unitDiscussUniversity: [] as BarStackedChartProps[],
    courseDiscussActionUniversity: [] as BarStackedChartProps[],
    unitReviewAccessUniversity: [] as DualChart[],
    rateUnitByModuleUniversity: [] as BarStackedChartProps[],
    rateCourseByModuleUniversity: [] as BarStackedChartProps[],
    rateSequenceByModuleUniversity: [] as BarStackedChartProps[],
    unitResources: [] as Array<any>,
  });

  const [loading, setLoading] = useState({
    unitDiscussUniversityList: false,
    courseDiscussActionUniversity: false,
    unitReviewAccessUniversity: false,
    rateUnitByModuleUniversity: false,
    rateCourseByModuleUniversity: false,
    rateSequenceByModuleUniversity: false,
  });

  const payload = useMemo(
    () => ({
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: filterValues?.universityIds,
      timeUnit: filterValues.timeUnit
    }),
    [
      filterValues.dateFrom,
      filterValues.dateTo,
      filterValues?.courseLevelIds,
      filterValues?.industryGroupIds,
      filterValues?.courseIds,
      filterValues?.universityIds,
      filterValues.timeUnit,
    ]
  );

  const fetchData = async () => {
    try {
      const apiCallList = [
        {
          key: "unitDiscussUniversity",
          api: getUnitDiscussUniversity,
          params: payload,
        },
        {
          key: "courseDiscussActionUniversity",
          api: getCourseDiscussActionUniversity,
          params: payload,
        },
        {
          key: "rateUnitByModuleUniversity",
          api: getListRateUnitByModule,
          params: payload,
        },
        {
          key: "rateCourseByModuleUniversity",
          api: getListRateUnitByCourse,
          params: payload,
        },
        {
          key: "rateSequenceByModuleUniversity",
          api: getListRateUnitBySequence,
          params: payload,
        },
      ]
      await Promise.allSettled(
        apiCallList.map(async ({key, api, params}) => {
          setLoading((prev) => ({...prev, [key]: true}));
          try {
            const response = await api(params);
            startTransition(() => {
              setChartData((prevState) => ({
                ...prevState,
                [key]: response?.data?.data?.statistic || response?.data?.data,
              }));
            })
          } catch (error) {
            console.error(`Error fetching data for ${key}:`, error);
            startTransition(() => {
              setChartData(prevState => ({
                ...prevState,
                [key]: [], // Provide fallback empty data
              }));
            });
          } finally {
            setLoading((prev) => ({...prev, [key]: false}));
          }
        })
      );
    } catch (err) {
      console.error(err)
    }
  }

  const fetchUnitReviewAccessUniversity = async () => {
    setLoading((prev) => ({...prev, unitReviewAccessUniversity: true}));
    try {
      const data = await getListUnitReviewAndAccess({
        ...payload,
        courseStructureType: "unit",
        moduleGroup: moduleGroup ? moduleGroup : 3,
      });
      setChartData((prevState) => ({
        ...prevState,
        unitReviewAccessUniversity: data?.data?.data,
      }));
    } catch (error) {
      console.error("Error fetching unitReviewAccessUniversity data:", error);
    } finally {
      setLoading((prev) => ({...prev, unitReviewAccessUniversity: false}));
    }
  };

  const debouncedFetchData = useMemo(
    () => debounce(fetchData, 300),
    [filterValues]
  );

  const debouncedFetchUnitReviewAndAccess = useMemo(
    () => debounce(fetchUnitReviewAccessUniversity, 300),
    [filterValues]
  );

  useEffect(() => {
    if (tab === "3" && universityId && universityId !== 0) {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  useEffect(() => {
    if (tab === "3" && universityId && universityId !== 0) {
      debouncedFetchUnitReviewAndAccess();
    }
    return () => {
      debouncedFetchUnitReviewAndAccess.cancel();
    };
  }, [filterValues, tab, moduleGroup]);

  const memoizedChartData = useMemo(() => {
    return {
      unitDiscussUniversityList: chartData.unitDiscussUniversity,
      courseDiscussActionUniversity: chartData.courseDiscussActionUniversity,
      unitReviewAccessUniversity: chartData.unitReviewAccessUniversity,
      rateUnitByModuleUniversity: chartData.rateUnitByModuleUniversity,
      rateCourseByModuleUniversity: chartData.rateCourseByModuleUniversity,
      rateSequenceByModuleUniversity: chartData.rateSequenceByModuleUniversity,
    };
  }, [chartData]);

  return (
    (universityId && universityId !== 0)
      ?
      (dataType === "1") ?
        <FeedbackAndEvaluationChartTab {...memoizedChartData} loading={loading} setModuleGroup={setModuleGroup}/> :
        <FeedbackEvaluationTable {...memoizedChartData} loading={isPending} setModuleGroup={setModuleGroup}/>
      :
      <p style={{fontSize: 18, color: "#5f5c5c", textAlign: "center"}}>Không có dữ liệu</p>
  )
};

export default FeedbackAndEvaluationTab;
