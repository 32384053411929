import { StateCreator } from "zustand";
export interface ThemeSurvey {
  theme: string;
  setTheme: (data: string) => void;
}

export const ThemeSurveyStore: StateCreator<ThemeSurvey> = (set, get) => ({
  theme: "",
  setTheme: (value: string) => {
    set({ theme: value });
  },
});
