import React, { useEffect, useState } from "react";
import { ModeQuestion, QuestionTypeEnum } from "../../../constants";
import ChooseText from "./choose-text";
import ChooseImage from "./choose-image";
import ChooseVideo from "./choose-video";
import TrueOrFalse from "./true-or-false";
import ChooseMultiText from "./choose-multi-text";
import ChooseMultiImage from "./choose-multi-image";
import ChooseMultiVideo from "./choose-multi-video";
import Connect from "./connect";
import ShortText from "./short-text";
import FileUpload from "./file-upload";
import Video from "./video";
import Mp3 from "./mp3";
import "./style.scss";
import { Spin } from "antd";
import { useAddLesson } from "../../../stores/stores";
import { ConnectQuestionData, QuestionData } from "../../../types/course-construction";
import DropDown from "./drop-down";
import { get } from "lodash";
import { getQuizById } from "../../../service/course-construct";

function QuestionItem() {
  const question = useAddLesson((state: any) => state.question);
  const setQuestion = useAddLesson((state: any) => state.setQuestion);
  const modeQuestion = useAddLesson((state) => state.modeQuestion);
  const setQuestionSettingsBe = useAddLesson((state) => state.setQuestionSettingsBe);

  const [loadingQuestion, setLoadingQuestion] = useState<boolean>(false);
  const [questionData, setQuestionData] = useState<QuestionData>({} as QuestionData);
  const [connectQuestionData, setConnectQuestionData] = useState<ConnectQuestionData>({} as ConnectQuestionData);

  useEffect(() => {
    if (modeQuestion === '') {
      setQuestion({});
      setQuestionData({} as QuestionData);
    }
  }, [modeQuestion])

  useEffect(() => {
    setQuestionData({} as QuestionData);
    const getDetailQuestion = async () => {
      try {
        if (question.id && modeQuestion === ModeQuestion.UPDATE) {
          setLoadingQuestion(true);
          const quizDetail = await getQuizById(question.id);
          if (question.type === QuestionTypeEnum.CONNECT) {
            setConnectQuestionData(quizDetail.data.body);
          } else {
            setQuestionData(quizDetail.data.body);
          }
          setQuestionSettingsBe({ ...quizDetail.data })
        } else {
          if (question.type === QuestionTypeEnum.CONNECT) {
            setConnectQuestionData(question);
          } else {
            setQuestionData(question);
          }
          setQuestionSettingsBe({})
        }
      } catch (err: any) { } finally { setTimeout(() => setLoadingQuestion(false), 500); }
    };
    getDetailQuestion();

  }, [question]);

  const renderQuestionByType = () => {
    if (question.type)
      switch (question.type) {
        case QuestionTypeEnum.CHOOSE_TEXT:
          return <ChooseText dataFromApi={questionData} />;
        case QuestionTypeEnum.CHOOSE_IMAGE:
          return <ChooseImage data={questionData} />;
        case QuestionTypeEnum.CHOOSE_VIDEO:
          return <ChooseVideo data={questionData} />;
        case QuestionTypeEnum.TRUE_OR_FALSE:
          return <TrueOrFalse data={questionData} />;
        case QuestionTypeEnum.DROP_DOWN:
          return <DropDown data={questionData} />;
        case QuestionTypeEnum.CHOOSE_MULTI_TEXT:
          return <ChooseMultiText data={questionData} />;
        case QuestionTypeEnum.CHOOSE_MULTI_IMAGE:
          return <ChooseMultiImage data={questionData} />;
        case QuestionTypeEnum.CHOOSE_MULTI_VIDEO:
          return <ChooseMultiVideo data={questionData} />;
        case QuestionTypeEnum.CONNECT:
          return <Connect dataFromApi={connectQuestionData} />;
        case QuestionTypeEnum.SHORT_TEXT:
          return <ShortText data={questionData} />;
        case QuestionTypeEnum.FILE_UPLOAD:
          return <FileUpload dataFromApi={questionData} />;
        case QuestionTypeEnum.VIDEO:
          return <Video data={questionData} />;
        case QuestionTypeEnum.MP3:
          return <Mp3 data={questionData} />;
        default:
          return <></>;
      }
  };
  return (
    <div className="question-content-box">
      <Spin spinning={loadingQuestion}>{renderQuestionByType()}</Spin>
    </div>
  );
}

export default QuestionItem;
