// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.course-managment-plan-preview-structureP .preview-course-structure {
  /* Ẩn đường kẻ dọc */
  /* Điều chỉnh khoảng cách giữa tiêu đề và biểu tượng */ }
  .course-managment-plan-preview-structureP .preview-course-structure .ant-tree-treenode:hover .ant-tree-node-content-wrapper {
    background: none; }
  .course-managment-plan-preview-structureP .preview-course-structure .ant-tree-treenode .ant-tree-node-content-wrapper {
    background: none; }
  .course-managment-plan-preview-structureP .preview-course-structure .ant-tree-treenode {
    display: block !important; }
  .course-managment-plan-preview-structureP .preview-course-structure .ant-tree-title {
    margin-left: 15px;
    /* Khoảng cách bên phải của tiêu đề */ }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/mooc-course-construction-plan/detail-course-plan/component/preview-course-structure/detail.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EAUI,oBAAA;EAKA,sDAAA,EAAA;EAfJ;IAGM,gBAAgB,EAAA;EAHtB;IAOM,gBAAgB,EAAA;EAPtB;IAYM,yBAAyB,EAAA;EAZ/B;IAiBM,iBAAiB;IACjB,qCAAA,EAAA","sourcesContent":[".course-managment-plan-preview-structureP {\n  .preview-course-structure {\n    .ant-tree-treenode:hover .ant-tree-node-content-wrapper {\n      background: none;\n    }\n\n    .ant-tree-treenode .ant-tree-node-content-wrapper {\n      background: none;\n    }\n\n    /* Ẩn đường kẻ dọc */\n    .ant-tree-treenode {\n      display: block !important;\n    }\n\n    /* Điều chỉnh khoảng cách giữa tiêu đề và biểu tượng */\n    .ant-tree-title {\n      margin-left: 15px;\n      /* Khoảng cách bên phải của tiêu đề */\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
