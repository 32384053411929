import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

const IsLoading = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <LoadingOutlined style={{ fontSize: "32px" }} />
      </div>
    </div>
  );
};

export default IsLoading;
