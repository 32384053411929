import { Button, Col, Form, Layout, Modal, Row, Spin, Tabs, TabsProps, Typography, message } from "antd";
import { FunctionComponent, useEffect, useState } from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import { routesConfig } from "../../../../config/routes";
import SafeLink from "../../../../components/link/SafeLink";
import { useLocation } from "react-router-dom";
import "./university-management-detail.scss"
import { exportExcelMoocUniversityDetail, getMoocUniversityById } from "../../../../service/category-management/university-management";
import { LoadingOutlined } from "@ant-design/icons";
import UniversityDetail from "../../../../components/category-management/management-detail/university-info/university-detail/UniversityDetail";
import UniversityHistory from "../../../../components/category-management/management-detail/university-info/university-history/UniversityHistory";


const { Content } = Layout;
const imgPath = "/images/";

const UniversityManagementDetail: FunctionComponent = () => {
    const location = useLocation();
    const [universityDetail, setUniversityDetail] = useState<any>();
    const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
    const [isRendering, setIsRendering] = useState(false);
    const [activeTab, setActiveTab] = useState<string>("1");

    const listBreadcrumb = [
        {
          title: "Quản lý danh mục",
        },
        {
          title: <SafeLink to={`/${routesConfig.categoryManagement}/${routesConfig.universityManagement}`}>
            <span>Quản lý trường đại học</span>
          </SafeLink>,
        },
        {
          title: location.state.name,
        },
    ];

    // Get detail university
    const getUniversityUpdate = async (universityId: number) => {
        setLoadingDetail(true);
        try {
            await getMoocUniversityById(universityId).then((res) => {
                if(res.status === 200) {
                    const { data } = res.data;
                    setUniversityDetail(data);
                    setTimeout(() => {
                        setLoadingDetail(false);
                    }, 333);
                }
            })
        } catch (err) {
            setLoadingDetail(false);
            console.error(err);
        }
    };

    useEffect(() => {
        getUniversityUpdate(location.state.universityId);
    }, [location.state.universityId]);


    const handleExportExcel = () => {
        Modal.confirm({
          title: `Xác nhận kết xuất danh sách trường đại học`,
          content: ``,
          onOk: () => exportExcel(),
          centered: true,
          okText: "Xác nhận",
          cancelText: "Huỷ bỏ",
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    
    const handleTabChange = (key:string) => {
        setActiveTab(key);
    }


    const exportExcel = async () => {
        setIsRendering(true); // Bắt đầu quá trình xử lý

        try {
            // Gọi API với ID của trường đại học hiện tại
            const res = await exportExcelMoocUniversityDetail(location.state?.universityId);

            // Tạo Blob từ dữ liệu nhận được
            const blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            // Tạo một liên kết tạm thời để tải xuống tệp
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = `${location.state?.name}_Thong_tin_chi_tiet.xlsx`; // Đặt tên file tải xuống
            downloadLink.click(); // Kích hoạt việc tải file

            message.success("Tải xuống thành công!"); // Thông báo thành công
        } catch (error) {
            console.error("Lỗi khi tải xuống:", error); // Xử lý lỗi nếu có
            message.error("Có lỗi xảy ra, vui lòng thử lại!"); // Thông báo lỗi
        } finally {
            setIsRendering(false); // Kết thúc quá trình xử lý
        }
    };

    const items : TabsProps["items"] =[
        {
            key:"1",
            label:"Thông tin chi tiết Trường đại học",
            children: (
                <div>
                    <UniversityDetail
                        loadingDetail={loadingDetail}
                        universityDetail={universityDetail}
                    />
                </div>
            )
        },
        {
            key:"2",
            label:"Lịch sử thao tác",
            children: (
                <UniversityHistory 
                    idUniversity={location.state.record.id}
                />
            )
        },
    ];
    const renderExportButton = () => {
        if(activeTab === "1"){
            return (
                <Button 
            type="primary"
            onClick={handleExportExcel}
            disabled={isRendering}>
                <Typography.Text>
                {isRendering ? <LoadingOutlined /> : ''} Kết xuất thông tin chi tiết
                </Typography.Text> 
            </Button>
            );
        }
        return null;
    }

    return (
        <>
            <Content>
                <PageHeader
                    title={location.state?.name ? location.state?.name : ""}
                    listBreadcrumb={listBreadcrumb}
                    arrowLeft={imgPath + "arrowLeft.svg"}
                    positionItem="column"
                    breadcrumb={true}
                    headerGroupButton={activeTab ==="1"}
                    childrenGroupButton={renderExportButton()}
                />
                 <div className="tabs-heading">
                    <Tabs 
                    defaultActiveKey="1"
                    items={items}
                    onChange={handleTabChange}
                    />
                </div>
            </Content>
        </>
    )
}

export default UniversityManagementDetail;
