import {
  Button,
  Col,
  message,
  Row,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from "antd";
import React, { useEffect, useState } from "react";
import { useAddLesson, useAuthStore } from "../../../../stores/stores";
import {
  initialChooseFileUploadCriteriaValue,
  initialChooseFileUploadQuestionValue,
  initialChooseVideoCriteriaValue,
} from "../../../../constants";
import TextEditor from "../../../text-editor/TextEditor";
import { AudioOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { SortableList } from "../../../SortableList";
import {
  QuestionData,
  QuestionItemDetail,
} from "../../../../types/course-construction";
import { findMaxIndexOfArray, mapAnsBEtoFE } from "../../../../utils/arrays";
import Mp3Criteria from "./Mp3Criteria";
import "./style.scss";
import { get } from "lodash";
import { DraggableUploadListItem } from "../video";
import { BASE_URL_V2 } from "../../../../config/api/configApiv2";
import { renderQuestionNameByType, renderQuestionDescriptionByType } from "../../../../utils/functionUltils";

const { Dragger } = Upload;

let locale = {
  emptyText: "Không có dữ liệu",
};

function Mp3({ data = {} as QuestionData }: QuestionItemDetail) {
  const questionList = useAddLesson((state: any) => state.questionList);
  const [questionName, setQuestionName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const question = useAddLesson((state: any) => state.question);
  // const [criteria, setCriteria] = useState<string[]>([]);
  let [dataAnswer, setDataAnswer] = useState(
    initialChooseFileUploadCriteriaValue
  );
  const [dataUpload, setDataUpload] = useState<UploadFile[]>([
    {
      uid: "",
      name: "",
      url: "",
    },
  ]);

  const accessToken = useAuthStore((state) => state.accessToken);
  const setCurrentQuestionInfo = useAddLesson(
    (state) => state.setCurrentQuestionInfo
  );

  const settingQuestion = useAddLesson((state) => state.settingQuestion);

  // const handelUpdateAnswer = (data: CriteriaMp3Info) => {

  //   let newList = [...dataAnswer];
  //   const indexOfItem = newList.findIndex((item: CriteriaMp3Info) => {
  //     return item.id === data.id;
  //   });

  //   if (indexOfItem !== -1) {
  //     newList[indexOfItem] = {
  //       ...newList[indexOfItem],
  //       filePath: data.filePath as string,
  //       fileName: data.fileName as string
  //     };

  //   }
  //   setDataAnswer(newList);
  // };

  const handleChangeText = (current: { id: number; value: string }) => {
    const distAnswer = [...dataAnswer];
    // distCriteria[current.id] = current.value;
    const indexOfChangeItem = distAnswer.findIndex(
      (item) => item.id === current.id
    );
    if (indexOfChangeItem !== -1) {
      distAnswer[indexOfChangeItem] = {
        ...distAnswer[indexOfChangeItem],
        value: current.value,
      };
      setDataAnswer(distAnswer);
    }
  };

  const handleRemoveOneChoose = (id: number) => {
    if (id) {
      const newList = [...dataAnswer];
      setDataAnswer(newList.filter((item) => item.id !== id));
    } else console.error("Cannot find id");
  };

  const handleAddMoreChoose = () => {
    if (dataAnswer.length > 0) {
      const newItem = {
        id: findMaxIndexOfArray(dataAnswer) + 1,
        value: "",
        key: findMaxIndexOfArray(dataAnswer) + 1,
        isTrue: true,
        isDisplayContent: false,
        point: 0,
        filePath: "",
        fileName: "",
      };
      const newList = [...dataAnswer];
      setDataAnswer(newList.concat([newItem]));
    } else setDataAnswer(initialChooseFileUploadQuestionValue);
  };

  useEffect(() => {
    if (settingQuestion.displayScoreCriteria === undefined) {
      settingQuestion.displayScoreCriteria = true;
    }
    setCurrentQuestionInfo({
      ...dataAnswer,
      questionName: questionName,
    });
  }, [dataAnswer, questionName]);

  useEffect(() => {
    setQuestionName(question.question);
    if (data.answers && data.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(data.answers);
      // const dataUploadAnswer = data.answers.map((i: BeQuizAnswer) => ({
      //   uid: get(i, "orderNumber", "").toString(),
      //   url: i.filePath,
      //   name: i.fileName,
      // }));
      // setDataUpload(dataUploadAnswer);
      setDataAnswer(feAnswer);
    } else {
      setDataAnswer([]);
      setDataUpload([]);
    }
  }, [question, data.answers]);

  const handleChangeQuestionName = (qName: string) => {
    setQuestionName(qName);
  };

  const beforeUpload = (fileUpload: { type: string }) => {
    setIsLoading(true);
    const isSound = fileUpload.type === "audio/mpeg";
    if (!isSound) {
      message.error("Bạn phải tải lên file audio/mp3");
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
    return isSound;
  };

  const handleUpload: UploadProps["onChange"] = (infoFile) => {
    const { status } = infoFile.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      message.success(`${infoFile.file.name} tải lên thành công.`);
      const saveAnswer = {
        id: findMaxIndexOfArray(dataAnswer) + 1,
        key: findMaxIndexOfArray(dataAnswer) + 1,
        value: "",
        isTrue: false,
        fileName: infoFile.file.response?.fileName,
        filePath: infoFile.file.response?.filePath,
        isDisplayContent: false,
        point: 0,
      };

      if (dataAnswer.length > 0) {
        setDataAnswer((prevData) => [...prevData, saveAnswer]);
        dataAnswer = [...dataAnswer].concat(saveAnswer);
      } else {
        ([initialChooseVideoCriteriaValue[0].fileName] =
          infoFile.file.response?.fileName),
          ([initialChooseVideoCriteriaValue[0].filePath] =
            infoFile.file.response?.filePath),
          (dataAnswer = [initialChooseVideoCriteriaValue[0]]);
      }
      // handelUpdateAnswer(saveAnswer)
    } else if (status === "error") {
      message.error(`${infoFile.file.name} tải lên thất bại.`);
    }

    setDataUpload(infoFile.fileList);
    if (status === "removed") {
      const newListAnswers = [...dataAnswer].filter((item, index) =>
        [...dataUpload]
          .filter((item) => item.status !== "removed")
          .some((ans) => item.id === parseInt(ans.uid))
      );
      setDataAnswer(newListAnswers);
    }
  };

  const handleDragChange = (value: any) => {
    // setCriteria(value);
  };

  return (
    <section className="question-wrapper">
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={questionName} onChange={handleChangeQuestionName} />
      <Dragger
        className="dragger-record"
        action={BASE_URL_V2 + "/mooc-course-block-quiz/upload-file"}
        headers={{ Authorization: "Bearer " + accessToken }}
        name="file"
        accept=".mp3"
        beforeUpload={beforeUpload}
        fileList={dataUpload}
        itemRender={(originNode, item) => {
          return (
            <DraggableUploadListItem originNode={originNode} file={item} />
          );
        }}
        onChange={handleUpload}
      >
        <p className="ant-upload-drag-icon">
          <AudioOutlined />
        </p>
        <p className="ant-upload-text">Ghi âm</p>
      </Dragger>
      <div className="upload-record">
        <Button icon={<UploadOutlined />} disabled>
          Tải lên
        </Button>
      </div>

      {settingQuestion.displayScoreCriteria && (
        <div className="tree-course-construct list-answers">
          <Typography.Text>Tiêu chí tính điểm</Typography.Text>
          <SortableList
            items={dataAnswer}
            onChange={handleDragChange}
            renderItem={(record: any, index: number) => (
              <SortableList.Item id={record.id}>
                {/* <SortableList.DragHandle /> */}
                <Mp3Criteria
                  index={record.id}
                  value={record.value}
                  onChange={handleChangeText}
                  onDelete={handleRemoveOneChoose}
                />
              </SortableList.Item>
            )}
          />

          <Row>
            <Col span={24}>
              <Button
                className="btn-add-more mt-1"
                type="default"
                icon={<PlusOutlined />}
                onClick={handleAddMoreChoose}
              >
                Thêm tiêu chí
              </Button>
            </Col>
          </Row>
          {/* <div style={{ maxWidth: 400, margin: "30px auto" }}></div> */}
        </div>
      )}
    </section>
  );
}

export default Mp3;
