import { AxiosResponse } from "axios";
import {
  NotificationPayload,
  NotificationsPayload,
} from "../../types/notifications";
import { axiosConfigV2 } from "../../config/api/configApiv2";
import { axiosConfigV2OpenAPI } from "../../config/api/configOpenApiV2";

export const updateNotification: (
  payload: NotificationPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.put(`/mooc-notifications/update-notification`, payload);
};

export const createNotification: (
  payload: NotificationPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/mooc-notifications/create-notification`, payload);
};

export const searchCreatedNotification: (
  payload: NotificationsPayload
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(
    `/mooc-notifications/search-created-notification`,
    payload
  );
};

export const getAllEnterprise: (payload: {
  page: number;
  size: number;
  keyword?: string;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2OpenAPI.get(
    `/enterprise/get-all-enterprise?page=${payload.page}&size=${payload.size}${
      payload.keyword ? `&keyword=${payload.keyword}` : ""
    }`
  );
};

export const getNotification: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.get(`/mooc-notifications/${id}`);
};

export const getNotificationHistory: (payload: {
  id: number;
  page: number;
  size: number;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.get(
    `/mooc-notifications/history/${payload.id}?page=${payload.page}&size=${payload.size}`
  );
};

export const deleteNotifications: (payload: {
  ids: number[];
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.delete(`/mooc-notifications/delete-notification`, {
    data: payload,
  });
};

export const sendNotification: (
  payload: number
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.get(`/mooc-notifications/send/${payload}`);
};

export const revokeNotification: (
  payload: number
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.put(`/mooc-notifications/revoke/${payload}`);
};

export const scheduleSendNotification: (payload: {
  id: number;
  scheduledSendDate: string;
}) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.put(`/mooc-notifications/schedule-send`, payload);
};

export const unScheduleNotification: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.put(`/mooc-notifications/unschedule-send/${id}`);
};

export const getCreatorsEmail: (
  keyword: string
) => Promise<AxiosResponse<any>> = (keyword) => {
  return axiosConfigV2.get(
    `/mooc-notifications/search-creator-email${
      keyword ? `?keyword=${keyword}` : ""
    }`
  );
};

export const reInit: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfigV2.put(`/mooc-notifications/reinit/${id}`);
};

export const countUnviewed: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.get(`/mooc-notifications/count-unviewed`);
};

export const findHavingSchoolForStudent: (
  name?: string
) => Promise<AxiosResponse<any>> = (name) => {
  return axiosConfigV2OpenAPI.get(
    `/university/find-by-name-having-student${name ? `?name=${name}` : ""}`
  );
};

export const findSchoolForStudent: (
  name?: string
) => Promise<AxiosResponse<any>> = (name) => {
  return axiosConfigV2OpenAPI.get(
    `/university/find-by-name${name ? `?name=${name}` : ""}`
  );
};

export const searchNotifications: (
  payload: any
) => Promise<AxiosResponse<any>> = (payload) => {
  return axiosConfigV2.post(`/mooc-notifications/search-notification`, payload);
};

export const deleteAllNotifications: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfigV2.delete(`/mooc-notifications/delete-all-notifications`);
};

export const editAllNotificationsViewed: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfigV2.put(`/mooc-notifications/edit-all-notifications-viewed`);
};
export const editNotificationViewed: (
  id: number,
  isViewed: boolean
) => Promise<AxiosResponse<any>> = (id, isViewed) => {
  return axiosConfigV2.put(
    `/mooc-notifications/edit-notification-viewed/${id}?isViewed=${isViewed}`
  );
};

export const deleteNotifyOfMe: (id: number) => Promise<AxiosResponse<any>> = (
  id
) => {
  return axiosConfigV2.delete(`/mooc-notifications/delete-notification/${id}`);
};
