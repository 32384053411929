import { Button, DatePicker, DatePickerProps, Form, Modal, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'

type Props = {
  title: string
  open: boolean
  handleClose: () => void
  handleOk: () => void
  exportExcel: (value: any) => void
  filterData: any
  setFilterData: (dateString: string[], planCreationDate: string) => void;
}

const ExportAccountModal = (props: Props) => {
  const { title, open, handleClose, handleOk, setFilterData, filterData, exportExcel } = props
  const [form] = Form.useForm()
  const { RangePicker } = DatePicker;

  const onChange: DatePickerProps['onChange'] = (date, dateString) => { };

  const options = [
    {
      id: 1,
      name: 'Khóa'
    },
    {
      id: 2,
      name: 'Mở khóa'
    }
  ]

  const clearFilter = () => {
    form.resetFields()
  }

  useEffect(() => {
    clearFilter()
  }, [open])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === "form-export-account") {
        exportExcel(values)
      }
    }}>
      <Modal
        title={title}
        open={open}
        centered
        onCancel={handleClose}
        cancelText="Hủy"
        okText="Kết xuất"
        footer={(_, { }) => (
          <div className="modal-btn-center">
            <Space size="middle">
              <Button onClick={handleClose}>Hủy</Button>
              <Button className='btn btn-primary' onClick={() => {
                handleClose()
                form.submit()
              }}>Kết xuất</Button>
            </Space>
          </div>
        )}
      >
        <Form
          form={form}
          name="form-export-account"
          layout="vertical"
        >
          <Form.Item name="status" label="Trạng thái">
            <FormItemDropdown placeholder="Chọn trạng thái"
              options={options} />
          </Form.Item>
          <Form.Item name="approvedDate" label="Ngày tạo" className='w-full'>
            <RangePicker
              placeholder={["Từ ngày", "Đến ngày"]}
              format="YYYY-MM-DD"
              className='w-full'
              onChange={(_value, dateString: any) =>
                setFilterData(dateString, "approvedDate")
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </Form.Provider>
  )
}

export default ExportAccountModal;
