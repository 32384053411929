import {
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Tree,
  TreeDataNode,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
  BookOutlined,
  CaretRightOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import "./detail.scss";
import {
  TypeChapterList,
  TypeCourseStructure,
  TypeSubjectList,
} from "../../../../types/course-plan-managment/mooc-course-construction-plan";

interface ConstructionPlanProps {
  dataCourseStructure: any[];
  expandAll?: any;
  setExpandAll?: any;
  expandedKeys?: any;
  setExpandedKeys?: any;
}

const ConstructionPlan2: React.FC<ConstructionPlanProps> = ({
  dataCourseStructure,
  expandAll,
  setExpandAll,
  expandedKeys,
  setExpandedKeys,
}) => {
  const convertChapterToTreeData = (
    chapter: TypeChapterList,
    index: number
  ): TreeDataNode => {
    const chapterNode: TreeDataNode = {
      isLeaf: false,
      title: (
        <Space size="small" direction="vertical">
          <span style={{ fontSize: 17, fontWeight: 400 }}>
            {chapter?.structureItemName}{" "}
          </span>
          <Space size="small" direction="horizontal">
            <div
              style={{ fontSize: 15, fontWeight: 400 }}
              dangerouslySetInnerHTML={{
                __html: chapter.structureItemDescription || "",
              }}
            />
          </Space>
        </Space>
      ),
      key: `${chapter.id}`,
      children: [],
      style:
        index > 0
          ? { borderBottom: "1px solid #5e87ff" } //#fff6cc
          : { borderBottom: "1px solid #5e87ff" }, //f4f4f4
    };

    // Duyệt qua từng môn học của chapter và chuyển đổi thành TreeDataNode
    chapter?.subjectList?.forEach((subject: TypeSubjectList) => {
      const subjectNode: TreeDataNode = {
        title: (
          <Space direction="vertical">
            <Row>
              <Space direction="horizontal" size={4}>
                {/* <BookOutlined />  */}
                <span style={{ fontSize: 15, fontWeight: 400 }}>
                  {subject.structureItemName}
                </span>
              </Space>
            </Row>
            <Row>
              <Space size="small" direction="horizontal">
                <div
                  style={{ fontSize: 15, fontWeight: 400 }}
                  dangerouslySetInnerHTML={{
                    __html: subject.structureItemDescription || "",
                  }}
                />
              </Space>
            </Row>
          </Space>
        ),
        key: `${subject.id}`,
        switcherIcon: <CaretRightOutlined />,
        style: {
          backgroundColor: "#e6f4ff", //#fafafa
          marginTop: "0",
          paddingTop: "5px",
        },
        children: [],
      };

      // Duyệt qua từng bài học của môn học và chuyển đổi thành TreeDataNode
      subject.lessonList.forEach((lesson: TypeCourseStructure) => {
        const lessonNode: TreeDataNode = {
          title: (
            <Space direction="vertical">
              <Row>
                <Space direction="horizontal" size={4}>
                  {/* <BookOutlined /> */}
                  <span style={{ fontSize: 14, fontWeight: 400 }}>
                    {lesson.structureItemName}
                  </span>
                </Space>
              </Row>
              <Row>
                <Space size="small" direction="horizontal">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: lesson.structureItemDescription || "",
                    }}
                  />
                </Space>
              </Row>
            </Space>
          ),
          switcherIcon: <MinusOutlined />,
          key: `${lesson.id}`,
          isLeaf: true,
          style: { backgroundColor: "#e6f4ff" }, //#fafafa
        };

        // Thêm node bài học vào node môn học
        if (subjectNode.children) {
          subjectNode.children.push(lessonNode);
        }
      });

      // Thêm node môn học vào node chapter
      if (chapterNode.children) {
        chapterNode.children.push(subjectNode);
      }
    });

    return chapterNode;
  };

  const treeData: TreeDataNode[] = dataCourseStructure.map((chapter, index) =>
    convertChapterToTreeData(chapter, index)
  );

  // const [expandAll, setExpandAll] = useState(false);
  // const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const handleClickExpand = () => {
    setExpandAll(!expandAll);
  };
  useEffect(() => {
    if (expandAll) {
      // Tạo mảng chứa tất cả các khóa của nút trong cây
      const allNodeKeys = getAllNodeKeys(treeData);
      setExpandedKeys(allNodeKeys);
    } else {
      setExpandedKeys([]);
    }
  }, [expandAll]);

  // Hàm đệ quy để lấy tất cả các khóa của nút trong cây
  const getAllNodeKeys = (nodes: TreeDataNode[]): string[] => {
    let keys: string[] = [];
    nodes.forEach((node) => {
      keys.push(node.key as string);
      if (node.children) {
        keys = keys.concat(getAllNodeKeys(node.children));
      }
    });
    return keys;
  };

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    // console.log("selected", selectedKeys, info);
  };

  return (
    <>
      <Card
        className={`wrapper-card card-info--wrapper preview-course-structure`}
        style={{ paddingTop: "10px", minWidth: "680px", marginTop: "15px" }}
        title={
          <div className="card-heading--info--construct-view">
            <Row gutter={[{ xs: 12, xl: 8, md: 8 }, 8]}>
              <Col
                xl={{ span: 12 }}
                md={{ span: 12 }}
                sm={{ span: 12 }}
                xs={{ span: 12 }}
              >
                <div className="card-heading--info">
                  <Typography.Title level={4}>
                    Cấu trúc khóa học
                  </Typography.Title>
                  <Typography.Text className="sub-title">
                    Chi tiết cấu trúc của khoá học đăng kíii
                  </Typography.Text>
                </div>
              </Col>
              <Col
                xl={{ span: 4 }}
                md={{ span: 4 }}
                sm={{ span: 4 }}
                xs={{ span: 4 }}
              ></Col>
              <Col
                xl={{ span: 8 }}
                md={{ span: 8 }}
                sm={{ span: 8 }}
                xs={{ span: 8 }}
              >
                <Button
                  onClick={() => handleClickExpand()}
                  className="float-right"
                >
                  {expandAll ? "Thu gọn" : "Hiển thị chi tiết"}
                </Button>
              </Col>
            </Row>
          </div>
        }
      >
        <Divider />
        {/* <div style={{ height: '400px', overflow: 'auto' }}> */}
        <Tree
          style={{ fontSize: 15 }}
          showLine={true}
          showIcon={true}
          defaultExpandedKeys={["0-0-0"]}
          expandedKeys={expandedKeys}
          onExpand={(e) => setExpandedKeys(e)}
          onSelect={onSelect}
          treeData={treeData}
          blockNode
          virtual={false}
        />
        {/* </div> */}
      </Card>
    </>
  );
};

export default ConstructionPlan2;
