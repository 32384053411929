import {Col, Empty, Row, Select, Spin} from "antd";
import React, { useEffect } from "react";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import LineChart from "../../../../../../components/types-of-charts/LineChart";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import StatisticalReportCard from "../../StatisticalReportCard";
import { BarStackedChartProps, LineChartProps, PieChart } from "../../../../../../types/static-report";
import useChartExport from "../../../../for-instructors/components/chart/useChartExport";
import { staticReportStore } from "../../../../../../stores/stores";

type Props = {
  loading: Record<string, boolean>;
  searchKeywordCountForMinistry: PieChart[],
  searchHistoryForMinistry: BarStackedChartProps[],
  unitDownloadResource: LineChartProps[],
  unitPreviewResource: LineChartProps[],
  unitShareResource: LineChartProps[],
  setActionModuleMapping: (value: any) => void,
};

const UserBehaviorChartTab = (props: Props) => {
  const {
    loading,
    searchKeywordCountForMinistry,
    searchHistoryForMinistry,
    unitDownloadResource,
    unitPreviewResource,
    unitShareResource,
    setActionModuleMapping,
  } = props;

  // Hook for managing chart exports
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  return (
    <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
      <Col sm={{span: 12}} xs={{span: 24}}>
        <StatisticalReportCard
          chartName="Số lượng tìm kiếm theo từ khoá"
          content={
            <Spin spinning={loading.searchKeywordCountForMinistry}>
              {searchKeywordCountForMinistry.length > 0 ? (
                <BarChart
                  data={searchKeywordCountForMinistry}
                  colors={["#69C0FF"]}
                  xAxisTitle="Từ khoá"
                  ref={createChartRef(tab, "searchKeywordCountForMinistry")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
              handleExportImage(tab, "searchKeywordCountForMinistry", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
              handleExportImage(
                tab,
                "searchKeywordCountForMinistry",
                "application/pdf"
              ),
            },
          ]}
        />
      </Col>
      <Col sm={{span: 12}} xs={{span: 24}}>
        <StatisticalReportCard
          chartName="Số lượt tìm kiếm tài nguyên"
          content={
            <Spin spinning={loading.searchHistoryForMinistry}>
              {searchHistoryForMinistry.length > 0 ? (
                <AreaStackedChart
                  data={searchHistoryForMinistry}
                  colors={["#8FD0FF", " #85E4DE", "#FFD08F", "#FF9A98", "#C69FF0"]}
                  yAxisTitle="Số lượng phản hồi"
                  ref={createChartRef(tab, "searchHistoryForMinistry")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
              handleExportImage(tab, "searchHistoryForMinistry", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
              handleExportImage(
                tab,
                "searchHistoryForMinistry",
                "application/pdf"
              ),
            },
          ]}
        />
      </Col>
      <Col sm={{span: 8}} xs={{span: 24}}>
        <StatisticalReportCard
          chartName="Số lượt tải về tài nguyên"
          content={
            <Spin spinning={loading.unitDownloadResource}>
              {unitDownloadResource.length > 0 ? (
                <LineChart
                  yAxisTitle="Số lượng"
                  data={unitDownloadResource}
                  lineColor="#FF7875"
                  lineName="Lượt tải về"
                  ref={createChartRef(tab, "unitDownloadResource")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
              handleExportImage(tab, "unitDownloadResource", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
              handleExportImage(
                tab,
                "unitDownloadResource",
                "application/pdf"
              ),
            },
          ]}
          moreOption={
            <Select
              className="mr-2"
              defaultValue={3}
              style={{minWidth: 160}}
              onSelect={(value) => {
                let type;
                switch (value) {
                  case 1:
                    type = "unit";
                    break;
                  case 2:
                    type = "unit";
                    break;
                  case 3:
                    type = "unit";
                    break;
                  case 4:
                    type = "sequence";
                    break;
                  case 5:
                    type = "course";
                    break;
                  default:
                    type = "course";
                }
                setActionModuleMapping({
                  courseStructureType: type,
                  actionType: 3,
                  moduleGroup: value
                })
              }}
              options={[
                {value: 3, label: 'Bài kiểm tra'},
                {value: 2, label: 'Tài liệu tham khảo'},
                {value: 1, label: 'Đa phương tiện'},
                {value: 4, label: 'Bài giảng'},
                {value: 5, label: 'Khoá học'},
              ]}
            />
          }
        />
      </Col>
      <Col sm={{span: 8}} xs={{span: 24}}>
        <StatisticalReportCard
          chartName="Số lượt xem tài nguyên"
          content={
            <Spin spinning={loading.unitPreviewResource}>
              {unitPreviewResource.length > 0 ? (
                <LineChart
                  yAxisTitle="Số lượng"
                  data={unitPreviewResource}
                  lineColor="#69C0FF"
                  lineName="Lượt xem"
                  ref={createChartRef(tab, "unitPreviewResource")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
              handleExportImage(tab, "unitPreviewResource", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
              handleExportImage(
                tab,
                "unitPreviewResource",
                "application/pdf"
              ),
            },
          ]}
          moreOption={
            <Select
              className="mr-2"
              defaultValue={3}
              style={{minWidth: 160}}
              onSelect={(value) => {
                let type;
                switch (value) {
                  case 1:
                    type = "unit";
                    break;
                  case 2:
                    type = "unit";
                    break;
                  case 3:
                    type = "unit";
                    break;
                  case 4:
                    type = "sequence";
                    break;
                  case 5:
                    type = "course";
                    break;
                  default:
                    type = "course";
                }
                setActionModuleMapping({
                  courseStructureType: type,
                  actionType: 1,
                  moduleGroup: value
                })
              }}
              options={[
                {value: 3, label: 'Bài kiểm tra'},
                {value: 2, label: 'Tài liệu tham khảo'},
                {value: 1, label: 'Đa phương tiện'},
                {value: 4, label: 'Bài giảng'},
                {value: 5, label: 'Khoá học'},
              ]}
            />
          }
        />
      </Col>
      <Col sm={{span: 8}} xs={{span: 24}}>
        <StatisticalReportCard
          chartName="Số lượt chia sẻ tài nguyên"
          content={
            <Spin spinning={loading.unitShareResource}>
              {unitShareResource.length > 0 ? (
                <LineChart
                  yAxisTitle="Số lượng"
                  data={unitShareResource}
                  lineColor="#5CDBD3"
                  lineName="Lượt chia sẻ"
                  ref={createChartRef(tab, "unitShareResource")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
              handleExportImage(tab, "unitShareResource", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
              handleExportImage(
                tab,
                "unitShareResource",
                "application/pdf"
              ),
            },
          ]}
          moreOption={
            <Select
              className="mr-2"
              defaultValue={3}
              style={{minWidth: 160}}
              onSelect={(value) => {
                let type;
                switch (value) {
                  case 1:
                    type = "unit";
                    break;
                  case 2:
                    type = "unit";
                    break;
                  case 3:
                    type = "unit";
                    break;
                  case 4:
                    type = "sequence";
                    break;
                  case 5:
                    type = "course";
                    break;
                  default:
                    type = "course";
                }
                setActionModuleMapping({
                  courseStructureType: type,
                  actionType: 2,
                  moduleGroup: value
                })
              }}
              options={[
                {value: 3, label: 'Bài kiểm tra'},
                {value: 2, label: 'Tài liệu tham khảo'},
                {value: 1, label: 'Đa phương tiện'},
                {value: 4, label: 'Bài giảng'},
                {value: 5, label: 'Khoá học'},
              ]}
            />
          }
        />
      </Col>
    </Row>
  );
};

export default UserBehaviorChartTab;
