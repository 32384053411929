// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs-active-setting {
  padding: 24px; }
  .tabs-active-setting .ant-tabs-nav {
    background: transparent !important;
    padding: 0 !important; }
  .tabs-active-setting .ant-tabs-content {
    padding: 0; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/tabs-setting-common/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAII,kCAAkC;IAClC,qBAAqB,EAAA;EALzB;IASI,UAAU,EAAA","sourcesContent":[".tabs-active-setting {\n  padding: 24px;\n\n  .ant-tabs-nav {\n    background: transparent !important;\n    padding: 0 !important;\n  }\n\n  .ant-tabs-content {\n    padding: 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
