// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-heading--info .header--wrapper .header--ctn {
  align-items: center; }
  .card-heading--info .header--wrapper .header--ctn .header-course .text-header-title {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/list-course-construction/chapter-card/chapter-card.scss"],"names":[],"mappings":"AAAA;EAGY,mBAAmB,EAAA;EAH/B;IAOoB,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB,EAAA","sourcesContent":[".card-heading--info{\n    .header--wrapper{\n        .header--ctn{\n            align-items: center;\n\n            .header-course{\n                .text-header-title{\n                    width: 300px; \n                    overflow: hidden; \n                    text-overflow: ellipsis; \n                    white-space: nowrap; \n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
