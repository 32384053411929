import React from 'react';
import { Button, Col, DatePicker, Form, FormInstance, Row, Typography } from "antd";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  // setFilterData: (value: string[], name: string) => void;
  clearFilter: (form: FormInstance) => void;
  onFinish?: (values: any) => void;
  dataOptions?: any;
  filterData: any; //FilterDataSurveyOperationHistory;
};

const FormTable = ({
  onFinish,
  dataOptions,
  showFilter,
  clearFilter,
  filterData,
}: FormFilterProps) => {
  const { RangePicker } = DatePicker;
  const [form] = useForm()
  return (
    <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
      <Form
        form={form}
        name="filterForm"
        className="filter-form"
        onFinish={onFinish}
      >
        <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]} className="row-filter">
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 4 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Thời hạn</Typography.Title>
            <Form.Item name="closedDate">
              <DatePicker
                style={{ width: '100%' }}
                placeholder={"Chọn ngày"}
                format="DD/MM/YYYY"
                value={filterData.createDate}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 4 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Trạng thái</Typography.Title>
            <Form.Item name="status">
              <FormItemDropdown
                placeholder="Trạng thái"
                value={filterData?.status}
                options={dataOptions?.dataStatusOptions}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 4 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Hình thức</Typography.Title>
            <Form.Item name="form">
              <FormItemDropdown
                placeholder="Hình thức"
                value={filterData?.form}
                options={dataOptions?.dataOptionsForm}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 6 }}
            md={{ span: 4 }}
            sm={{ span: 4 }}
            xs={{ span: 24 }}
            className="flex align-end col-filter"
          >
            <div className="group-btn--filter">
              <Button
                className="btn btn-primary filter-btn"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <FilterOutlined />
                <Typography.Text>Lọc</Typography.Text>
              </Button>
              <Button
                className="btn btn-outlined clear-filter-btn"
                onClick={() => clearFilter(form)}
              >
                <DeleteOutlined />
                <Typography.Text>Xóa tất cả</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormTable;
