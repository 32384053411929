import {
  Button,
  Dropdown,
  Flex,
  Form,
  Layout,
  MenuProps,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
  message,
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import {
  CommentOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MoreOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

import { isEmpty } from "lodash";
import moment from "moment";
import DrawerCustom from "../../../../../components/drawer/Drawer";
import CommonModal from "../../../../../components/modal/common";
import PageHeader from "../../../../../components/page-header/PageHeader";
import TableData from "../../../../../components/table-data/TableData";
import { routesConfig } from "../../../../../config/routes";
import {
  DEFAULT_EXPORT_REPORT,
  DEFAULT_PERMISSION_ATTENDANCE,
} from "../../../../../constants/setting-identity";
import {
  getListReportAttendance,
  getSettingAttendanceReportByID,
} from "../../../../../service/setting-attendance";
import { formatDate } from "../../../../../utils/converDate";
import { exportData } from "../../../../../utils/exportData";
import { generateDocx } from "../../../../../utils/exportDataDocx";
import FilterTable from "./components/filter-table/FilterTable";
import ReportCheckInModal from "./components/report-modal";
import ReportCheckInShare from "./components/report-share";

type ReportCheckInProps = {
  title?: string;
  permissionAttendance: string[];
  assignCourse: boolean;
};

const { EXPORT_REPORT, SHARE_REPORT, GET_REPORT } =
  DEFAULT_PERMISSION_ATTENDANCE;

const ReportCheckIn = ({
  title,
  permissionAttendance,
  assignCourse,
}: ReportCheckInProps) => {
  const params = useParams();

  const [accountReportCheckIn, setAccountReportCheckIn] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [isExport, setIsExport] = useState(false);
  const [report, setReport] = useState({
    idReport: "",
    title: "",
  });
  const [isOpenShare, setIsOpenShare] = useState<boolean>(false);
  const [isOpenConfirmShare, setIsOpenConfirmShare] = useState<boolean>(false);
  const [usersShare, setUsersShare] = useState({
    group_ids: [],
    user_ids: [],
  });
  const [isNoticeValidate, setIsNoticeValidate] = useState<boolean>(false);

  const [valueTypeExport, setValueTypeExport] = useState<string>(".csv");

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getListReportAttendance(searchParam);
      const { data } = response.data;
      setAccountReportCheckIn(data?.data);
      setTotalPage(data?.total?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData({
      search: searchValue,
      course_id: params?.id,
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
  }, [pageSize, pageNumber, isConfirmDelete]);

  const onCloseModal = () => {
    setIsOpenDelete(false);
    setIsConfirmDelete(false);
    setIsExport(false);
    setIsOpenConfirmShare(false);
    setIsOpenShare(false);
  };
  const handleNotice = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };
  const handleOpenDelete = (idDocument: string, title: string) => {
    setIsOpenDelete(true);
    setReport({
      idReport: idDocument,
      title: title,
    });
  };
  const handleOpenShareDraw = (idDocument: string, title: string) => {
    setIsOpenShare(true);
    setReport({
      idReport: idDocument,
      title: title,
    });
  };

  const handleConfirmDelete = () => {
    setIsOpenDelete(false);
    setIsConfirmDelete(true);
  };
  const handleOpenShare = () => {
    if (isEmpty(usersShare?.group_ids) && isEmpty(usersShare?.user_ids)) {
      setIsNoticeValidate(true);
      setIsOpenConfirmShare(false);
    } else {
      setIsOpenConfirmShare(true);
    }
  };
  const onChangeExport = (e: RadioChangeEvent) => {
    setValueTypeExport(e.target.value);
  };

  const handleExport = (idDocument: string, title: string) => {
    setIsExport(true);
    setReport({
      idReport: idDocument,
      title: title,
    });
  };
  const handleConfirmExport = async () => {
    let reportExport = [];
    let fileName = "";
    try {
      const response = await getSettingAttendanceReportByID(report?.idReport);
      const { data } = response.data;
      fileName = data.data.name;
      reportExport = data?.data?.mooc_attendance?.map(
        (data: any, index: number) => ({
          STT: index + 1,
          [DEFAULT_EXPORT_REPORT.nameSv]:
            data?.auth_user_mooc_attendance_student_idToauth_user.first_name +
            " " +
            data?.auth_user_mooc_attendance_student_idToauth_user.last_name,
          [DEFAULT_EXPORT_REPORT.date_birthday]:
            moment(
              data?.auth_user_mooc_attendance_student_idToauth_user
                .auth_userprofile.date_of_birth
            ).format("DD/MM/yyyy") || "",
          [DEFAULT_EXPORT_REPORT.phone]:
            data?.auth_user_mooc_attendance_student_idToauth_user
              .auth_userprofile.phone_number || "",
          [DEFAULT_EXPORT_REPORT.email]:
            data?.auth_user_mooc_attendance_student_idToauth_user.email,
          [DEFAULT_EXPORT_REPORT.status]: data?.result ? "Có mặt" : "Vắng mặt",
        })
      );
      if (valueTypeExport === ".csv") {
        exportData(reportExport, fileName, valueTypeExport);
      } else {
        const headers = [
          "STT",
          "Họ và tên sinh viên",
          "Ngày tháng năm sinh",
          "Số điện thoại",
          "Email",
          "Điểm danh",
        ];

        generateDocx(reportExport, headers, fileName.replaceAll(".", ""));
      }

      if (!isEmpty(reportExport)) {
        messageApi.open({
          type: "success",
          content: "Xuất báo cáo thành công",
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Báo cáo không có dữ liệu",
        });
      }
      setIsExport(false);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Xuất báo cáo thất bại",
      });
    }
  };

  const dataColumnsReportCheckIn: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 5,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Mã báo cáo",
      dataIndex: "code",
      key: "code",
      width: 10,
    },
    {
      title: "Tên báo cáo",
      dataIndex: "name",
      key: "reportName",
      width: 15,
    },
    {
      title: "Chương",
      dataIndex: "course_section",
      key: "course_section",
      width: 15,
      render: (_, data: any) => {
        return (
          <Typography.Text>
            {
              data?.mooc_course_block?.mooc_course_unit?.mooc_course_sequence
                ?.mooc_course_section?.name
            }
          </Typography.Text>
        );
      },
    },
    {
      title: "Bài giảng",
      dataIndex: "course_sequence",
      key: "course_sequence",
      width: 20,
      render: (_, data: any) => {
        return (
          <Typography.Text>
            {
              data?.mooc_course_block?.mooc_course_unit?.mooc_course_sequence
                ?.name
            }
          </Typography.Text>
        );
      },
    },
    {
      title: "Học liệu",
      dataIndex: "course_unit",
      key: "course_unit",
      width: 20,
      render: (_, data: any) => {
        return (
          <Typography.Text>
            {data?.mooc_course_block?.mooc_course_unit?.name}
          </Typography.Text>
        );
      },
    },
    {
      title: "Thời gian tạo",
      dataIndex: "surveyOpen",
      key: "surveyOpen",
      width: 10,
      render: (date: any) => formatDate(date),
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const idDocument = `${record?.id}`;

        const items: MenuProps["items"] = [
          ...(permissionAttendance.includes(GET_REPORT)
            ? [
                {
                  label: (
                    <Link
                      to={`/management-study/${routesConfig.reportCheckIn}/${idDocument}`}
                    >
                      <Flex>
                        <EditOutlined />
                        <Typography.Text className="pl-2">
                          Sửa báo cáo
                        </Typography.Text>
                      </Flex>
                    </Link>
                  ),
                  key: "0",
                },
              ]
            : []),
          ...(permissionAttendance.includes(EXPORT_REPORT)
            ? [
                {
                  label: (
                    <Flex
                      onClick={() => handleExport(idDocument, record?.name)}
                    >
                      <LogoutOutlined />
                      <Typography.Text className="pl-2">
                        Xuất báo cáo
                      </Typography.Text>
                    </Flex>
                  ),
                  key: "1",
                },
              ]
            : []),
          ...(permissionAttendance.includes(SHARE_REPORT)
            ? [
                {
                  label: (
                    <Flex
                      onClick={() =>
                        handleOpenShareDraw(idDocument, record?.name)
                      }
                    >
                      <ShareAltOutlined />
                      <Typography.Text className="pl-2">
                        Chia sẻ báo cáo
                      </Typography.Text>
                    </Flex>
                  ),
                  key: "2",
                },
              ]
            : []),
          ...(assignCourse
            ? [
                {
                  label: (
                    <Link
                      to={`/management-study/${routesConfig.reportQualityEvaluation}/${idDocument}`}
                    >
                      <Flex>
                        <CommentOutlined />
                        <Typography.Text className="pl-2">
                          Đánh giá chất lượng
                        </Typography.Text>
                      </Flex>
                    </Link>
                  ),
                  key: "3",
                },
              ]
            : []),
          ...(permissionAttendance.includes(GET_REPORT)
            ? [
                {
                  label: (
                    <Flex
                      onClick={() => handleOpenDelete(idDocument, record?.name)}
                    >
                      <DeleteOutlined />
                      <Typography.Text className="pl-2">
                        Xoá báo cáo
                      </Typography.Text>
                    </Flex>
                  ),
                  key: "4",
                },
              ]
            : []),
        ];
        return (
          <Space>
            <Link
              className="action-table"
              to={`/management-study/${routesConfig.reportCheckInDetail}/${idDocument}`}
            >
              <EyeOutlined />
            </Link>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              className="action-table"
            >
              <a onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckIn.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsReportCheckIn.map((item) => ({
      ...item,
    }));
  }, [checkedList, permissionAttendance]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleClose = () => {
    setIsOpenShare(false);
    form.resetFields();
  };
  const handleCloseModalShare = () => {
    setIsOpenConfirmShare(false);
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        take: 10,
        skip: 0,
        search: searchValue,
        course_id: params?.id,
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      take: 10,
      skip: 0,
      search: searchValue,
      course_id: params?.id,
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      {contextHolder}
      <Form.Provider>
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper pb-4">
            <div className="table-header--left">
              <PageHeader title={"Danh sách báo cáo điểm danh"} />
            </div>
            <FilterTable
              searchValue={searchValue}
              dataColumns={dataColumnsReportCheckIn}
              handleOpenFilter={handleOpenFilter}
              handleSubmitSearch={handleSubmitSearch}
              handleSearch={handleSearch}
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              handleChangeInput={handleChangeInput}
            />
          </div>
        </div>
        <TableData
          dataTable={accountReportCheckIn}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
      <ReportCheckInModal
        IsOpenDelete={isOpenDelete}
        handleConfirmDelete={handleConfirmDelete}
        handleNotice={handleNotice}
        idReport={report?.idReport}
        onCloseModal={onCloseModal}
        title={report.title}
        IsConfirmDelete={isConfirmDelete}
      />
      <CommonModal
        open={isExport}
        title={`Xuất báo cáo điểm danh ${report?.title}`}
        desc={
          <Radio.Group onChange={onChangeExport} value={valueTypeExport}>
            <Radio value={".csv"}>Microsoft Excel (.csv)</Radio>
            <Radio value={".doc"}>Microsoft Word (.docx)</Radio>
          </Radio.Group>
        }
        closeIcon={true}
        okText={"Tải xuống"}
        cancelText={"Huỷ"}
        icon={""}
        confirm={handleConfirmExport}
        closeModal={onCloseModal}
        className={""}
      />
      <DrawerCustom
        title={`Chia sẻ báo cáo ${report?.title}`}
        open={isOpenShare}
        onClose={handleClose}
        extraNode={
          <Space>
            <>
              <Button onClick={handleClose} className="btn btn-outlined">
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button
                className="btn btn-primary"
                htmlType="submit"
                onClick={handleOpenShare}
              >
                <Typography.Text>Xác nhận</Typography.Text>
              </Button>
            </>
          </Space>
        }
        widthDrawer={730}
      >
        <ReportCheckInShare
          isOpenConfirmShare={isOpenConfirmShare}
          onCloseModal={onCloseModal}
          handleCloseModalShare={handleCloseModalShare}
          handleNotice={handleNotice}
          idReport={report?.idReport}
          usersShare={usersShare}
          setUsersShare={setUsersShare}
          title={report.title}
        />
        <Modal
          open={isNoticeValidate}
          title={
            <div>
              <InfoCircleOutlined
                style={{ color: "#FFC53D", marginRight: "10px" }}
              />
              <span className="!font-semibold pl-2">
                Chọn người dùng hoặc nhóm người dùng
              </span>
            </div>
          }
          centered
          closeIcon={true}
          onCancel={() => setIsNoticeValidate(false)}
          footer=""
        >
          <Typography.Text>
            Bạn cần chọn ít nhất một người dùng hoặc nhóm người dùng để chia sẻ
          </Typography.Text>
        </Modal>
      </DrawerCustom>
    </Layout>
  );
};

export default ReportCheckIn;
