import React, { FunctionComponent } from 'react';
import CustomCard from "../../../../components/custom-card/CustomCard";
import CustomList from "../../../../components/custom-list/CustomList";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Typography } from 'antd';


type RemoveAccountProps = {
    removeAccount?: () => void;
}

const RemoveAccount: FunctionComponent<RemoveAccountProps> = ({ removeAccount }) => {
    return (
        <div className="remove-account--wrapper">
            <CustomCard title={
                <div className="card-heading--info">
                    <Typography.Title level={5}>
                        Xoá tài khoản người dùng
                    </Typography.Title>
                    <Typography.Text className="sub-title">
                        Người dùng sẽ không thể tiếp tục sử dụng
                    </Typography.Text>
                </div>
            } className="card-info--wrapper">
                <CustomList title="Xoá tài khoản" actions={
                    <Button className="btn btn-danger" danger type="dashed" onClick={removeAccount}>
                        <DeleteOutlined />
                        <Typography.Text>
                            Xoá tài khoản
                        </Typography.Text>
                    </Button>
                } />
            </CustomCard>
        </div>
    );
};

export default RemoveAccount;
