import "./detail-info.scss";
import { Button, Form, message, Modal, Typography } from "antd";
import { EditOutlined, WarningOutlined } from "@ant-design/icons";
import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { DataType } from "../../../../types/listUsers";
import CustomList from "../../../../components/custom-list/CustomList";
import CustomCard from "../../../../components/custom-card/CustomCard";
import DetailSettings from "./DetailSettings";
import RemoveAccount from "./RemoveAccount";
import { useNavigate, useParams } from "react-router-dom";
import ChangePasswordModal from "../change-password/ChangePassword";
import { get, toNumber } from "lodash";
import { routesConfig } from "../../../../config/routes";
import {
  changePasswordField,
  dataInfo,
  dataLanguages,
  dataTimes,
} from "../../constants/dataInfo";
import {
  deleteAccountUser,
  getRoles,
} from "../../../../service/list-account-users/listAccountUsersApi";
import {
  changePassword,
  getLanguages,
  getTimeZone,
  updateInfoAccount,
} from "../../../../service/info-detail/infoDetailApi";
import { messageResponse } from "../../../role-management/constants/message";
import { convertArrayToNumber, findObjName } from "../../../../utils/arrays";

type DetailInfoProps = {
  dataUser?: DataType | any;
  refreshData: () => void;
};

const DetailInfo: FunctionComponent<DetailInfoProps> = (
  props: DetailInfoProps
) => {
  const { dataUser, refreshData } = props;

  const [form] = Form.useForm();
  const [isOpenedModal, setIsOpenedModal] = useState<boolean | undefined>(
    false
  );
  const [userPassword, setUserPassword] = useState({
    oldPassword: "",
    newPassword: "",
    reWriteNewPassword: "",
  });

  const [dataOptions, setDataOptions] = useState({
    dataRoles: [],
    dataTimeZone: [],
    dataLanguages: [],
  });

  const [dataUpdated, setDataUpdated] = useState({
    muiGio: undefined,
    ngonNgu: undefined,
  });

  const [editingField, setEditingField] = useState<string>("");

  const [dataUpdate, setDataUpdate] = useState({});

  const navigation = useNavigate();
  const params = useParams();

  const updateUser = (dataUpdate: any) => {
    try {
      const id = get(params, "id", "");
      updateInfoAccount(id, dataUpdate).then((res) => {
        const { statusCode, data } = res.data;
        if (statusCode === 200) {
          message.success(get(data, "message", messageResponse.successDelete));
          setEditingField("");
          setDataUpdated({ muiGio: undefined, ngonNgu: undefined });
          refreshData();
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (Object.keys(dataUpdate).length > 0) {
      updateUser(dataUpdate);
    }
  }, [dataUpdate]);

  useEffect(() => {
    if (dataUser) {
      form.setFieldsValue({
        email: dataUser?.email,
        vaiTros: convertArrayToNumber(dataUser?.vaiTros),
        trangThai: dataUser?.trangThai,
        ngonNgu: dataUser?.ngonNgu,
        muiGio: dataUser?.muiGio,
      });
    }
  }, [dataUser]);

  useEffect(() => {
    const fetchDataOptions = async () => {
      try {
        Promise.all([getRoles(), getTimeZone(), getLanguages()]).then((res) => {
          const dataRole = res[0].data.data;
          const dataTime = res[1].data.data;
          const dataLanguage = res[2].data.data;
          setDataOptions({
            ...dataOptions,
            dataTimeZone: dataTime,
            dataLanguages: dataLanguage,
            dataRoles: dataRole,
          });
        });
      } catch (err) {
        console.error(err);
      }
    };

    fetchDataOptions();
  }, []);

  const handleUpdateDataInfo = (data: DataType) => {
    const valueTime = findObjName(dataOptions.dataTimeZone, data?.muiGio);
    const valueLanguage = findObjName(dataOptions.dataLanguages, data?.ngonNgu);

    setDataUpdate({
      ...data,
      email: data.email,
      vaiTros: data.vaiTros,
      trangThai: data.trangThai,
      ngonNgu:
        data.ngonNgu &&
        dataOptions.dataLanguages.length > 0 &&
        valueLanguage.name,
      muiGio:
        data.muiGio &&
        dataOptions.dataTimeZone.length > 0 &&
        `(${valueTime.code})${" "}${valueTime.name}`,
    });
  };

  const handleCloseEdit = () => {
    setEditingField("");
  };

  const removeAccount = () => {
    Modal.confirm({
      title: "Xoá tài khoản",
      icon: <WarningOutlined />,
      content:
        "Mọi dữ liệu tài khoản đã xoá sẽ không thể khôi phục. Bạn chắc chắn muốn xoá tài khoản?",
      className: "modal-custom danger",
      okButtonProps: {
        className: "btn btn-filled--danger",
      },
      cancelButtonProps: {
        className: "btn btn-outlined",
      },
      okText: "Xác nhận",
      cancelText: "Huỷ",
      onOk: () => {
        deleteAccountUser(get(params, "id", ""))
          .then((res) => {
            const { statusCode, data } = res.data;
            if (statusCode === 200) {
              message.success(
                get(data, "message", messageResponse.successDelete)
              );
              navigation(routesConfig.listUsers);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      },
      onCancel: () => {},
    });
  };

  const openModal = () => {
    setIsOpenedModal(true);
  };

  const closeModal = () => {
    setIsOpenedModal(false);
    form.resetFields();
  };

  const handleSubmit = (values: any) => {
    changePassword({ id: toNumber(params.id), ...values }).then((res) => {
      const { data, statusCode } = res.data;
      if (statusCode === 200) {
        message.success(get(data, "message", messageResponse.successDelete));
      }
    });
  };

  const handleEditField = (fieldName: string) => {
    setEditingField(fieldName);
  };

  return (
    <>
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "formChangePassword") {
            const { formChangePassword } = forms;
            const dataPasswords =
              formChangePassword.getFieldsValue(changePasswordField);
            handleSubmit(dataPasswords);
          }
        }}
      >
        <CustomCard
          title={
            <div className="card-heading--info">
              <Typography.Title level={5}>Thông tin cơ bản</Typography.Title>
              <Typography.Text className="sub-title">
                Thay đổi thông tin cơ bản của người dùng
              </Typography.Text>
            </div>
          }
          className="card-info--wrapper"
        >
          <Form form={form} onFinish={handleUpdateDataInfo}>
            {dataInfo.map(({ fieldName, title, fieldValue }) => {
              return (
                <Fragment key={fieldName}>
                  <CustomList
                    title={title}
                    key={fieldName}
                    name={fieldName}
                    description={get(dataUser, fieldName, "")}
                    fieldValue={fieldValue}
                    options={dataOptions}
                    languageOptions={dataLanguages}
                    timeOptions={dataTimes}
                    editFieldName={editingField}
                    closeActions={handleCloseEdit}
                    actions={
                      editingField !== fieldName && (
                        <a
                          className="item-action"
                          onClick={() => handleEditField(fieldName)}
                        >
                          <EditOutlined />
                        </a>
                      )
                    }
                  />
                </Fragment>
              );
            })}
          </Form>
        </CustomCard>
        <DetailSettings openModal={openModal} />
        <RemoveAccount removeAccount={removeAccount} />
        <ChangePasswordModal
          open={isOpenedModal}
          title="Đổi mật khẩu"
          hideModal={closeModal}
          initalValue={userPassword}
          footer={
            <>
              <div className="btn-modal--footer">
                <Button className="btn btn-outlined" onClick={closeModal}>
                  <Typography.Text>Hủy</Typography.Text>
                </Button>
                <Button
                  className="btn btn-primary"
                  htmlType="submit"
                  onClick={() => form.submit()}
                >
                  <Typography.Text>Lưu</Typography.Text>
                </Button>
              </div>
            </>
          }
        />
      </Form.Provider>
    </>
  );
};

export default DetailInfo;
