export const status = [
  {
    value: '0',
    label: 'Chưa có thành viên'
  },
  {
    value: '1',
    label: 'Đã có thành viên'
  }
]

export const active = [
  {
    value: true,
    label: 'Đang hoạt động'
  },
  {
    value: false,
    label: 'Không hoạt động'
  }
]
export const validateFieldCreateSpecializedGroup = [
  "organizationName",
  "organizationCode",
  "organizationRemark",
];
