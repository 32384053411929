import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Form, Menu, Select } from "antd";
import "./style.scss";
import FilterStar from "./FilterStar";
import FilterPrice from "./FilterPrice";

import { MenuItem } from "../../types/sidebar";
import { useViewport } from "../../hooks/useViewport";
import { FILTER_SORT } from "../../constants";
import useDebounce from "../../hooks/useDebounce";

interface FilterProps {
  items?: MenuItem[];
  onChange?: (value: any) => void;
  onChangeSort?: (value: any) => void;
}

function Filter({
  items = [],
  onChange = () => {},
  onChangeSort = () => {},
}: FilterProps) {
  const { width } = useViewport();
  const [collapsed, setCollapsed] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [cost, setCost] = useState();
  const costDebounce = useDebounce(cost, 300);

  useEffect(() => {
    onChange({ ...formValues, cost: costDebounce });
  }, [costDebounce, formValues]);

  useEffect(() => {
    if (width <= 850) setCollapsed(false);
    else setCollapsed(true);
  }, [width]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const filter: MenuItem[] =
    items.length > 0
      ? items
      : [
          {
            label: "Chuyên ngành",
            key: "specialized",
            children: [
              {
                label: (
                  <Form.Item name="photoshop" valuePropName="checked">
                    <Checkbox>
                      Photoshop {"("}90{")"}
                    </Checkbox>
                  </Form.Item>
                ),
                key: "photoshop",
              },
              {
                label: (
                  <Form.Item name="blender" valuePropName="checked">
                    <Checkbox>
                      Blender {"("}80{")"}
                    </Checkbox>
                  </Form.Item>
                ),
                key: "blender",
              },
              {
                label: (
                  <Form.Item name="canva" valuePropName="checked">
                    <Checkbox>
                      Canva {"("}80{")"}
                    </Checkbox>
                  </Form.Item>
                ),
                key: "canva",
              },
            ],
          },
          {
            label: "Xếp hạng",
            key: "rank",
            children: [
              {
                label: (
                  <Form.Item name="star5" valuePropName="checked">
                    <Checkbox>
                      <FilterStar star={5} />
                    </Checkbox>
                  </Form.Item>
                ),
                key: "star5",
              },
              {
                label: (
                  <Form.Item name="star4" valuePropName="checked">
                    <Checkbox>
                      <FilterStar star={4} />
                    </Checkbox>
                  </Form.Item>
                ),
                key: "star4",
              },
              {
                label: (
                  <Form.Item name="star3" valuePropName="checked">
                    <Checkbox>
                      <FilterStar star={3} />
                    </Checkbox>
                  </Form.Item>
                ),
                key: "star3",
              },
              {
                label: (
                  <Form.Item name="star2" valuePropName="checked">
                    <Checkbox>
                      <FilterStar star={2} />
                    </Checkbox>
                  </Form.Item>
                ),
                key: "star2",
              },
            ],
          },
          {
            label: "Chi phí",
            key: "3",
            children: [
              {
                label: (
                  <Form.Item name="free" valuePropName="checked">
                    <Checkbox>Miễn phí</Checkbox>
                  </Form.Item>
                ),
                key: "free",
              },
              {
                label: (
                  <div>
                    <Form.Item name="paid" valuePropName="checked">
                      <Checkbox>
                        <div>Trả phí</div>
                      </Checkbox>
                    </Form.Item>
                    <div className="pr-3 pl-1">
                      <FilterPrice />
                    </div>
                  </div>
                ),
                key: "paid",
              },
            ],
          },
        ];

  const handleValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.hasOwnProperty("cost")) {
      setCost(changedValues.cost);
    } else {
      if (allValues.hasOwnProperty("cost")) delete allValues.cost;
      setFormValues(allValues);
    }
  };

  return (
    <div
      className={collapsed ? "mooc-filter" : "mooc-filter mooc-filter-absolute"}
    >
      <Form initialValues={{}} onValuesChange={handleValuesChange}>
        <div className={collapsed ? "flex mb-3 gap-12" : "flex gap-12"}>
          <div className="flex flex-column justify-end">
            <Button
              className="btn-outlined btn-filter mooc-filter-collapse-btn"
              onClick={toggleCollapsed}
            >
              {collapsed ? (
                <div className="font-size-14 font-weight-5 text-black flex align-center filter-hide-btn">
                  <img
                    className="mooc-filter-collapse__icon"
                    alt="menu-icon"
                    src="/assets/icons/menu-icon.svg"
                  />
                  <span className="text-btn-filter"> Ẩn bộ lọc</span>
                </div>
              ) : (
                <div className="flex align-center">
                  <img
                    className="mooc-filter-collapse__icon"
                    alt="menu-icon"
                    src="/assets/icons/menu-icon.svg"
                  />
                  <span className="text-btn-filter">Hiển thị bộ lọc</span>
                </div>
              )}
            </Button>
          </div>
          {collapsed ? (
            <div className="filter-ab">
              <p className="font-size-14 mb-1">Sắp xếp theo</p>
              <Form.Item>
                <Select
                  onChange={(value) => onChangeSort(value)}
                  className="btn-filter"
                  defaultValue={1}
                  style={{ width: 145 }}
                  options={FILTER_SORT}
                />
              </Form.Item>
            </div>
          ) : (
            ""
          )}
        </div>
        {collapsed ? (
          <Menu
            defaultOpenKeys={["specialized"]}
            mode="inline"
            inlineCollapsed={!collapsed}
            items={filter}
          />
        ) : null}
      </Form>
    </div>
  );
}

export default Filter;
