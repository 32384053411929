// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-plan-managment-create-appraisal .ant-form-item {
  margin-bottom: 5px; }

.course-plan-managment-create-appraisal .label-down-text {
  color: rgba(0, 0, 0, 0.45); }

.course-plan-managment-create-appraisal .colItem {
  margin-bottom: 24px; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/organize-appraisal-board/organize-appraisal-board-list/components/form-create-organize-appraisal/FormCreateOrganizeAppraisal.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAGA;EAEQ,kBAAkB,EAAA;;AAF1B;EAMQ,0BCLuB,EAAA;;ADD/B;EAUQ,mBAAmB,EAAA","sourcesContent":["@import \"../../../../../../styles/variables\";\n\n\n.course-plan-managment-create-appraisal {\n    .ant-form-item {\n        margin-bottom: 5px;\n    }\n\n    .label-down-text {\n        color: $text-gray\n    }\n\n    .colItem {\n        margin-bottom: 24px;\n    }\n}","$font-family-base: Roboto-Bold, Helvetica;\n$color-default: rgba(0, 0, 0, 0.88);\n$white: #fff;\n$border-primary:rgba(0, 0, 0, 0.06);\n$text-gray: rgba(0, 0, 0, 0.45);\n$color-primary: #1677FF;\n$color-border: #D9D9D9;\n$color-border-blue: #F0F5FF;\n$color-button-main: #4096ff;\n$color-text-blue: #ADC6FF;\n$hover-active-dropdown: #E6F7FF;\n$text-recall: #FF4D4F;\n$back-ground-1: #FAFAFA;\n$color-green: #52C41A;\n$green: #079455;\n$color-orange: #FAAD14;\n$black: #000;\n$light-boder: #eaecf0;\n$color-boder: #d0d5dd;\n$primary-violet: #7f56d9;\n$text-color-secondary: #475467;\n$bg-gray: #f2f4f7;\n$bg-welcome: #f9fafb;\n$bg-green: #ecfdf3;\n$outline-icon: #98A2B3;\n$button-danger: #D92D20;\n$btn-disabled: #80808026;\n$text-btn-disabled: #cbc5c5;\n$color-link-orange: #FA8C16;\n$note-color: #d92d20;\n$color-hover-sidebar: #e6f7ff;\n$primary: #7f56d9;\n$hover-primary: #6941c6;\n$text-color: #344054;\n$gray: #d0d5dd;\n$iconColor: #667085;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
