// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.course-plan-managment-detail-speialized {
  width: 100%;
  height: 100%; }
  .course-plan-managment-detail-speialized .wrapper {
    padding: 50px;
    width: 100%;
    height: 100%;
    overflow-x: auto; }
    .course-plan-managment-detail-speialized .wrapper .card-info--wrapper {
      width: 800px;
      margin-bottom: 0; }
      .course-plan-managment-detail-speialized .wrapper .card-info--wrapper.no-bottom-space {
        margin-bottom: 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-plan-managment/specialized-group/specialized-group-detail/DetailSpecializedGroup.scss"],"names":[],"mappings":"AAAA;EACI,WAAY;EACd,YAAY,EAAA;EAFd;IAKQ,aAAgB;IAChB,WAAgB;IAEhB,YAAgB;IAChB,gBAAgB,EAAA;IATxB;MAYY,YAAuB;MAEvB,gBAAmB,EAAA;MAd/B;QAiBgB,gBAAgB,EAAA","sourcesContent":[".course-plan-managment-detail-speialized {\n    width : 100%;\n  height: 100%;\n\n    .wrapper {\n        padding   : 50px;\n        width     : 100%;\n        // height: calc(100vh - 168px);\n        height    : 100%;\n        overflow-x: auto;\n\n        .card-info--wrapper {\n            width           : 800px;\n            // margin-bottom: 24px;\n            margin-bottom   : 0;\n\n            &.no-bottom-space {\n                margin-bottom: 0;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
