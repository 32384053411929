import React, { useEffect, useRef, useState } from "react";
import { Editor } from "grapesjs";
import { Select } from "antd";
import { getCourseDetail } from "../../../../service/course-construct";
import "./styles.scss";
interface ChooseLecturerTraitProps {
  value: string;
  onChange: (value: string) => void;
  editor: Editor;
}
const channel = new BroadcastChannel("lecturer_channel");

interface DataItem {
    label: string;
    value: number;
    id: number;
}

const ChooseLecturerTrait: React.FC<ChooseLecturerTraitProps> = ({
  value,
  onChange,
  editor,
}) => {
  const [traitValue, setTraitValue] = useState(value);
  const [defaultLecturer, setDefaultLecturer] = useState<any[]>([]);
  const [selectedLecturer, setSelectedLecturer] = useState<number[]>([]);

  const currentUrl = window.location.href;


  const url = new URL(currentUrl);
  const pathname = url.pathname;
  const segments = pathname.split("/");
  const detailIndex = segments.indexOf("content-course-detail");
  const courseId = segments[detailIndex + 1];

  const handleGetLecturer = async () => {
    const res = await getCourseDetail(courseId).then((res) => {
      const { data } = res.data;
      let options = [];
      options = data.assigners.flatMap((item: { assigned: any[] }) =>
        item.assigned.map(
          (assign: { assignUserName: string; assignUserId: string }) => ({
            label: assign.assignUserName,
            value: assign.assignUserName,
            id: assign.assignUserId,
          })
        )
      );
     
      const uniqueData = options.reduce((acc: { ids: { has: (arg0: any) => any; add: (arg0: any) => void; }; uniqueArray: any[]; }, current: { id: any; }) => {
        if (!acc.ids.has(current.id)) {
            acc.ids.add(current.id);
            acc.uniqueArray.push(current);
        }
        return acc;
    }, { ids: new Set<number>(), uniqueArray: [] as DataItem[] }).uniqueArray;
      setDefaultLecturer(uniqueData);
      const selected = options.map((item: { value: any }) => item.value);
      handleSplitStr(value);
    });
  };

  const handleSplitStr = (str: string) => {
    const selectedUserIds = str? str.split(",").map(Number) : [];
    setSelectedLecturer(selectedUserIds);
  };

  const getIds = (names: string[], items: DataItem[]): number[] => {
    return names.map(name => {
        const foundItem = items.find(item => item.label === name);
        return foundItem ? foundItem.id : -1; // Use -1 or another value to indicate not found
    }).filter(id => id !== -1); // Remove any not found values
};

  const handleJoinArr = (arr: any[]) => {
    const convertedArr = getIds(arr, defaultLecturer)
    setSelectedLecturer(arr);
    const newStr = convertedArr.join(",");
    setTraitValue(newStr);
    onChange(newStr);
    channel.postMessage({
      type: "CHOOSE_LECTURER",
      payload: newStr,
      uuid: editor.getSelected()?.getAttributes()['uuid']
    });
  };

  const onSearch = (value: string) => {
  };

  useEffect(() => {
    handleGetLecturer();
  }, []);

  return (
    <div>
      <Select
        value={selectedLecturer}
        className="w-36 no-border"
        id="display-for-select"
        mode="multiple"
        allowClear
        placeholder="Chọn giảng viên"
        defaultValue={selectedLecturer}
        // defaultValue={[16, 18, 38]}
        options={defaultLecturer}
        onChange={(event) => {
          handleJoinArr(event);
        }}
        onSearch={onSearch}
      />
    </div>
  );
};

export default ChooseLecturerTrait;
