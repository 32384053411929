import { Button, Col, Collapse, Drawer, Form, message, Modal, Radio, Row, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import "./index.scss"
import { isEmpty, size } from 'lodash'
import FormInput from '../../../form-input/FormInput'
import { regexPassword } from '../../../../utils/regex'
import { RuleObject } from 'antd/es/form'
import { StoreValue } from 'antd/es/form/interface'
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { changePasswordQTCS } from '../../../../service/list-account-users/listAccountUsersApi'
import { getDetailBasicAdminAccount } from '../../../../service/management-participate-platform'

type Props = {
  open: boolean
  onClose: () => void
  fetchData: () => void
  detailData: any
}

export default function ChangePassUserDrawer(props: Props) {
  const { open, onClose, detailData, fetchData } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataDetail, setDataDetail] = useState<any>({})

  const validations = {
    passwordLogin: [
      {
        required: true,
        message: "Vui lòng nhập mật khẩu",
      },
      {
        min: 8,
        max: 25,
        message: "Độ dài mật khẩu từ 8-25 ký tự.",
      },
      {
        pattern: regexPassword,
        message:
          "Mật khẩu phải bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt, và có độ dài từ 8-25 ký tự.",
      },
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator(_: RuleObject, value: StoreValue) {
          if (value && getFieldValue("oldPassword") === value) {
            return Promise.reject(
              new Error("Mật khẩu mới phải khác mật khẩu hiện tại.")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
    passwordLoginConfirm: [
      {
        required: true,
        message: "Vui lòng xác nhập mật khẩu mới",
      },
      ({ getFieldValue }: { getFieldValue: (name: string) => StoreValue }) => ({
        validator(_: RuleObject, value: StoreValue) {
          if (!isEmpty(value)) {
            if (getFieldValue("passwordLogin") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Mật khẩu không khớp.")
            );
          }
          return Promise.resolve();
        },
      }),
    ],
  }

  const getListDataDetail = async () => {
    await getDetailBasicAdminAccount(detailData?.id).then((res) => {
      if (res.status === 200) {
        setDataDetail(res.data?.data)
      }
    })
  }

  useEffect(() => {
    if (open) {
      getListDataDetail();
    }
  }, [open]);

  const changePassword = async (values: any) => {
    const data = {
      userId: detailData?.id,
      newPassword: values.passwordLogin,
      confirmPassword: values.passwordLoginConfirm
    }
    await changePasswordQTCS(data).then((res) => {
      if (res.status === 200) {
        onClose()
        message.success("Đổi mật khẩu thành công")
        form.resetFields()
        fetchData()
      }
    })
  }

  return (
    <Form.Provider onFormFinish={(name, { values, forms }) => {
      if (name === "form-change-password-lecturer-account") {
        Modal.confirm({
          title: `Xác nhận đổi mật khẩu`,
          content: `Vui lòng xác nhận đổi mật khẩu cho tài khoản quản trị cơ sở`,
          onOk: () => {
            onClose()
            message.success("Đổi mật khẩu thành công")
            fetchData()
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>
      <Drawer
        title={"Đổi mật khẩu cho tài khoản quản trị cơ sở"}
        open={open}
        onClose={onClose}
        className='change-password-lecturer-account-drawer'
        width={736}
        bodyStyle={{ padding: "0px" }}
        extra={
          <Space>
            <Button onClick={() => {
              form.resetFields()
              onClose()
            }} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>
                Hủy
              </Typography.Text>
            </Button>
            <Button className="btn btn-primary"
              onClick={() => form.submit()
              }>
              <SaveOutlined />
              <Typography.Text>
                Lưu
              </Typography.Text>
            </Button>
          </Space>
        }
      >
        <Form
          className="change-password-lecturer-account"
          layout={"vertical"}
          form={form}
          name="form-change-password-lecturer-account"
        >
          <div>
            <Collapse defaultActiveKey={["1", "2"]}>
              <Panel
                header="Thông tin tài khoản"
                key="1"
                className="setting-chapter-title">
                <div className='m-5'>
                  <span className='flex'><Typography.Title level={5} className='min-w-[160px]'>Cơ sở đào tạo</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.unitName}</p></span>
                  <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Người liên hệ</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.name}</p></span>
                  <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Email đăng nhập</Typography.Title> <p className='mt-0.5 ml-2'>{dataDetail?.username}</p></span>
                </div>
              </Panel>

              <Panel
                header="Đổi mật khẩu"
                key="2"
                className="setting-chapter-title">
                <div className='m-5'>
                  <Row gutter={24} className="">
                    <Col span={12}>
                      <Form.Item name="passwordLogin" label="Mật khẩu" rules={validations.passwordLogin}>
                        <FormInput
                          placeholder="Nhập mật khẩu cho tài khoản"
                          passwordInput={true}
                          style={{ height: '32px' }}
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="passwordLoginConfirm" label="Xác nhận mật khẩu" rules={validations.passwordLoginConfirm}>
                        <FormInput
                          placeholder="Xác nhận mật khẩu cho tài khoản"
                          passwordInput={true}
                          style={{ height: '32px' }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Form>
      </Drawer>
    </Form.Provider>
  )
}
