import React from "react";
import { Flex, Popover, Radio } from "antd";
import {
  QuestionBeProps,
  QuestionProps,
} from "../../../../../../../types/course";
import { convertNumberingTypeToText } from "../../../../../../../utils/format";
import { isEmpty, toNumber } from "lodash";
import { QuestionMarkIcon } from "../../../../../../../components/icons";
import SingleMenu from "../../../../../../../components/menu/SingleMenu";

function TrueOrFalse({ data, configs }: QuestionProps) {
  const result = data?.result?.results[0];
  const isTrue = data?.result?.isTrue === true;

  const renderText = (uuid: string) => {
    if (result && result[0]?.uuid === uuid && result[0]?.isTrue) {
      return "text-result";
    }
    if (result && result[0]?.uuid === uuid && result[0]?.isTrue === false) {
      return "text-danger";
    }
  };

  const renderChooseStyle = () => {
    if (result && result[0]?.isTrue === true) return "choose-correct";

    if (result && result[0]?.isTrue === false) return "choose-incorrect";

    return "";
  };

  const renderFeedback = () => {
    if (isEmpty(data?.result) && !isTrue)
      return data?.settingResponse?.notYet || "Không có phải hồi";

    if (isTrue) return data?.settingResponse?.correct || "Không có phải hồi";

    return data?.settingResponse?.incorrect || "Không có phải hồi";
  };

  return (
    <div>
      <div className="question-header mb-3">
        <div className="question-title">
          <Flex
            justify="space-between"
            className="font-size-16 line-height-24 font-weight-6"
          >
            Câu hỏi {toNumber(data?.index) + 1}:{" "}
            {configs?.generalConfig?.showQuizScore && (
              <span className="total-point font-size-14">
                {`(${data?.totalPoints || 0} điểm)`}
              </span>
            )}
          </Flex>
          <Flex align="flex-start" gap={8}>
            <div
              className="font-size-16 line-height-24 font-weight-6"
              dangerouslySetInnerHTML={{ __html: `${data?.question}` }}
            ></div>
            {data?.settingHint?.isDisplayInstruction || data?.isShowHint ? (
              <Popover
                content={
                  <div style={{ padding: "5px 16px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${data?.settingHint?.content || "Không có gợi ý"}`,
                      }}
                    />
                  </div>
                }
              >
                <span className="quest-icon text-secondary w-max-content">
                  <QuestionMarkIcon />
                </span>
              </Popover>
            ) : (
              ""
            )}
          </Flex>
          <div className="flex align-center gap-8 mt-1">
            {result ? (
              <span
                className={`font-size-14 line-height-20 font-weight-6 ${data?.result?.isTrue ? "score-correct-wrapper text-correct" : "score-incorrect-wrapper text-danger"}`}
              >
                Điểm: {data?.result?.points || 0}/
                {data?.result?.totalPoints || 0}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        className={`${renderChooseStyle()}`}
      >
        <div className="flex flex-column gap-12">
          {data?.questions &&
            data?.questions?.length > 0 &&
            data?.questions?.map((quiz: QuestionBeProps, index) => (
              <Radio
                disabled
                key={quiz?.uuid + index}
                checked={result && quiz?.uuid === result[0]?.uuid}
              >
                <span
                  className={`${renderText(quiz?.uuid)} font-size-16 line-height-24 font-weight-5 ${quiz?.isTrue === true && "text-result"}`}
                >
                  {convertNumberingTypeToText(
                    index + 1,
                    data?.settingGeneral.numberingType
                  )}
                  . {quiz?.content || "Không có nội dung"}
                </span>
              </Radio>
            ))}
        </div>
      </div>
      <div className="flex-1 mt-2">
       {configs?.generalConfig?.showFeedbackContent &&
          !data?.settingResponse?.isDisplayPerResponse && !isEmpty(data?.settingResponse) && (
            <SingleMenu
              titleClassName={`comment-menu ${isTrue ? "report-ans-wrapper-correct" : "report-ans-wrapper"} `}
              contentClassName={`${isTrue ? "bg-green" : "bg-purple"}`}
              className={`${isTrue ? "bg-green" : "bg-purple"}`}
              title={
                <p className="font-size-16 line-height-24 font-weight-6">
                  Phản hồi: {renderFeedback()}
                </p>
              }
              content={
                <p className="font-size-16 line-height-24 mt-1 font-weight-5">
                  {data?.result?.message || "Không có nhận xét"}
                </p>
              }
            />
          )}
      </div>
    </div>
  );
}

export default TrueOrFalse;
