import { StateCreator } from "zustand";
import {
  ListAnswer,
  ListQuestion,
  ListSurveySection,
  SurveyInfo,
} from "../../../types/survey-managment/survey";
import {
  postUpdateSurveyContentSection,
  putUpdateSurveyDescription,
} from "../../../service/survey-managment/list-survey";
import { message } from "antd";

export interface SurveyDetailSlice {
  loading: boolean;
  error: boolean;
  success: boolean;
  listSurveySections: ListSurveySection[];
  listSurveySectionsInput: ListSurveySection[];
  setListSurveySectionsInput: (data: ListSurveySection[]) => void;
  saveDraftSurveySections: (data: ListSurveySection[], id: number) => void;
  setListSurveySections: (data: ListSurveySection[]) => void;
  changeListSurveySectionsOrder: (data: ListSurveySection[]) => void;
  updateSurveySection: (data: ListSurveySection[]) => void;
  updateSectionListQuestion: (data: ListQuestion[]) => void;
  updateQuestionListAnswer: (data: ListAnswer[]) => void;
  listSurveyFilterSection: ListSurveySection[];
  setListSurveyFilterSection: (data: ListSurveySection[]) => void;
}

export const createSurveyDetailSlice: StateCreator<SurveyDetailSlice> = (
  set,
  get
) => ({
  loading: false,
  error: false,
  success: false,
  listSurveySections: [],
  listSurveySectionsInput: [],
  setListSurveySectionsInput: (data) => {
    set(() => ({ listSurveySectionsInput: data, success: true }));
  },
  setListSurveySections: (data) => {
    let sortedData = data.sort((a, b) => {
      if (a.orderNumber && b.orderNumber) {
        return a.orderNumber - b.orderNumber;
      }
      // Xử lý trường hợp orderNumber có thể undefined
      // Ví dụ: Trả về 0, không thay đổi thứ tự
      return 0;
    });

    // Sắp xếp moocSurveyQuestion của mỗi section
    sortedData.forEach((section) => {
      if (section.moocSurveyQuestion) {
        section.moocSurveyQuestion.sort((a, b) => {
          if (a.orderNumber !== undefined && b.orderNumber !== undefined) {
            return a.orderNumber - b.orderNumber;
          }
          return 0;
        });

        // Sắp xếp moocSurveyQuestionAnswer của mỗi câu hỏi
        section.moocSurveyQuestion.forEach((question) => {
          if (question.moocSurveyQuestionAnswer) {
            question.moocSurveyQuestionAnswer.sort((a, b) => {
              if (
                a.answerOrderNumber !== undefined &&
                b.answerOrderNumber !== undefined
              ) {
                return a.answerOrderNumber - b.answerOrderNumber;
              }
              return 0;
            });
          }
        });
      }
    });

    set(() => ({ listSurveySections: sortedData, success: true }));

    // set(() => ({ listSurveySections: data, success: true }));
  },
  saveDraftSurveySections: async (data, id) => {
    try {
      console.log(`storeDataInput::`, data, id);
      console.log(id);
      const response = await postUpdateSurveyContentSection(data, id);
    } catch (error) {
      set({ loading: false, error: true });
    }
  },
  changeListSurveySectionsOrder: (data) => {
    set(() => ({ listSurveySections: data, success: true }));
  },
  updateSurveySection: async (data) => {
    // try {
    //   console.log(`storeDataInput::`, data);
    //   const response = await postUpdateSurveyContentSection(data, id);
    //   console.log(response);
    // } catch (error) {
    //   set({ loading: false, error: true });
    // }
  },
  updateSectionListQuestion: (data) => {
    set((state) => {
      let newInfo = { ...state.listSurveySections };
      return { ...state, listSurveySections: newInfo };
    });
  },
  updateQuestionListAnswer: () => { },
  listSurveyFilterSection: [],
  setListSurveyFilterSection: (data) => {
    set(() => ({ listSurveyFilterSection: data, success: true }));
  },
});

export interface SurveyInfoSlice {
  loading: boolean;
  error: boolean;
  success: boolean;
  surveyInfo: SurveyInfo;
  setSurveyInfo: (data: SurveyInfo) => void;
  updateSurveyDescription: (id: number, data: string) => void;
}

export const createSurveyInfoSlice: StateCreator<SurveyInfoSlice> = (
  set,
  get
) => ({
  loading: false,
  error: false,
  success: false,
  surveyInfo: {},
  setSurveyInfo: (data) => {
    set((state) => ({ surveyInfo: data, success: true }));
  },
  updateSurveyDescription: async (id, description) => {
    set((state) => {
      let newInfo = { ...state.surveyInfo };
      newInfo.surveyDescription = description;
      newInfo.isSurveyDescriptionAdded = true;
      return { ...state, surveyInfo: newInfo };
    });
    try {
      console.log(`descriptionStoreInput::`, description);
      const response = await putUpdateSurveyDescription(id, description);
      console.log(response);
      if (response.status === 200) {
        //message.success(`Lưu thành công`);
      } else {
        message.error(`Gặp vấn đề trong quá trình lưu, vui lòng thao tác lại`);
      }
    } catch (error) {
      set({ loading: false, error: true });
    }
  },
});

/* Lọc

saveDraftSurveySections: (data: ListSurveySection[]) => {
    const { listSurveySectionsInput } = get();

    let updateList = [...listSurveySectionsInput]
    console.log(`store-listSurveySectionsInput:: `, listSurveySectionsInput);

    // Create a copy of data to avoid mutating the original array
    const updateData = [...data];

    // Iterate over each section in data
    updateData.forEach((newSection) => {
      // Find if the section already exists in listSurveySectionsInput
      const existingIndex = updateList.findIndex(
        (section) => section.id === newSection.id
      );

      if (existingIndex !== -1) {
        // If the section exists, update it with the new data
        updateList[existingIndex] = {
          ...updateList[existingIndex],
          ...newSection,
        };
      } else if (!newSection.id) {
        // If the section does not have an id, add it to listSurveySectionsInput
        updateList.push(newSection);
      }
    });

    // Check for elements in listSurveySectionsInput that are not present in data and mark them as deleted
    updateList.forEach((section) => {
      const existsInData = updateData.some(
        (newSection) => newSection.id === section.id
      );
      if (!existsInData && section.id) {
        section.isDeleted = true;
      }
    });

    // Update the state and mark as successful
    console.log(`store-updateList:: `,updateList)
    set(() => ({ listSurveySectionsInput: updateList, success: true }));
  },


*/
