import { useState } from "react";
import { Input, Radio, Row, Space, ConfigProvider } from "antd";
import { ListAnswer } from "../../../../../../types/survey-managment/survey";

const QuestionType2 = (props: any) => {
    const [options, setOptions] = useState<ListAnswer[]>(props.listOptions || []);

    const [imageShowHtmls, setImageShowHtmls] = useState<string[]>(
        props.listOptions.map((item: ListAnswer) => item.answerImage) || []
    );

    return (
        <div>
            <div>
                <br />
                <ConfigProvider
                    theme={{
                        components: {
                            Input: {
                                colorTextPlaceholder: "black",
                                paddingInline: 0,
                                paddingBlock: 0,
                            },
                            Button: {
                                paddingInline: 2,
                                paddingBlock: 0,
                            },
                        },
                    }}
                >
                    <Space direction="vertical" size="small" style={{ display: "flex" }}>
                        {options.map((option, index) => {
                            return (
                                <Row
                                    style={{
                                        margin: "0 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    key={index}
                                >
                                    <Input
                                        prefix={<Radio checked={false}></Radio>}
                                        variant="borderless"
                                        value={
                                            option.answerText || option.answerText === ""
                                                ? option.answerText
                                                : `Lựa chọn văn bản ${options.length}`
                                        }
                                    />
                                </Row>
                            );
                        })}
                    </Space>
                </ConfigProvider>
                <br />
            </div>
        </div>
    );
};

export default QuestionType2;
