import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getMoocScoringRules: (enterpriseUuid: string) => Promise<AxiosResponse<any>> = (enterpriseUuid) => {
    return axiosConfigV2.get(`/mooc-course-block-quiz/mooc-scoring-rules/${enterpriseUuid}`);
};

export const getEnterpriseByCourseId: (courseId: string) => Promise<AxiosResponse<any>> = (courseId) => {
    return axiosConfigV2.get(`/mooc-course-block-quiz/get-enterprise-by-course-id/${courseId}`);
};

export const getConfigBlockQuizGeneral: (courseBlockId: number) => Promise<AxiosResponse<any>> = (courseBlockId) => {
    return axiosConfigV2.get(`/mooc-course-block-quiz/config-block-quiz-general/${courseBlockId}`);
};

export const getConfigBlockQuizAction: (courseBlockId: number) => Promise<AxiosResponse<any>> = (courseBlockId) => {
    return axiosConfigV2.get(`/mooc-course-block-quiz/config-block-quiz-action/${courseBlockId}`);
};

export const getConfigBlockQuizEvaluate: (courseBlockId: number) => Promise<AxiosResponse<any>> = (courseBlockId) => {
    return axiosConfigV2.get(`/mooc-course-block-quiz/config-block-quiz-evaluate/${courseBlockId}`);
};

export const postConfigBlockQuizGeneral: (payload: any) => Promise<AxiosResponse<any>> = (payload) => {
    return axiosConfigV2.post(`/mooc-course-block-quiz/config-block-quiz-general`, payload);
};

export const postConfigBlockQuizAction: (payload: any) => Promise<AxiosResponse<any>> = (payload) => {
    return axiosConfigV2.post(`/mooc-course-block-quiz/config-block-quiz-action`, payload);
};

export const postConfigBlockQuizEvaluate: (payload: any) => Promise<AxiosResponse<any>> = (payload) => {
    return axiosConfigV2.post(`/mooc-course-block-quiz/config-block-quiz-evaluate`, payload);
};
