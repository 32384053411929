import { Layout } from "antd";
import PageHeaderCommon from "../../../../../components/page-header-common/PageHeaderCommon";
import MyCourseContentDetail from "./MyCourseContentDetail";
import { Link, useNavigate, useParams } from "react-router-dom";
import { routesConfig } from "../../../../../config/routes";
import { useState } from "react";

const { Content } = Layout;
const imgPath = "/images/";

interface IProps {
  titleCourse?: string
}
const MyCourseDetail = ({titleCourse}: IProps) => {
  const params = useParams();
  const [title, setTitle] = useState(titleCourse)
  const navigate = useNavigate();

  const listBreadcrumb = [
    {
      title: "Thực hiện điểm danh",
    },
    {
      title: <span>{title}</span>,
    },
  ];

  return (
    <Content>
      {/* <PageHeaderCommon
        // listBreadcrumb={listBreadcrumb}
        title={""}
        // arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        // handleBack={() => navigate(`/management-study/${routesConfig.course}`)}
      /> */}
      <MyCourseContentDetail id={params.id as string} setTitle={setTitle} />
    </Content>
  );
};

export default MyCourseDetail;
