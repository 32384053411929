import { Badge, Card, Typography } from "antd";
import React, { Fragment, useRef } from "react";
import CustomCard from "../../../../components/custom-card/CustomCard";
import "./detail.scss";
import CustomList from "../../../../components/custom-list/CustomList";
import { getMoocCourseConstructionPlanById } from "../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
import { DetailTypeMoocCourseConstructionPlan } from "../../../../types/course-plan-managment/mooc-course-construction-plan";
import {
  ApprovalStatusEnum,
  StatusEum,
} from "../../mooc-course-construction-plan/list-course-plan/constants/data";

interface InforPlanProps {
  detailCourseConstruct?: DetailTypeMoocCourseConstructionPlan;
  ref?: any;
}

const InformationPlan = (props: InforPlanProps) => {
  const { detailCourseConstruct, ref } = props;
  // console.log({ detailCourseConstruct })

  // console.log({ ref })
  const handleReturnStatus = (statusCode: any) => {
    switch (statusCode) {
      case StatusEum.SAVE_DRAFT:
        return "Lưu bản nháp";
      case StatusEum.WAIT_APPROVE:
        return "Chờ duyệt";
      case StatusEum.APPROVED:
        return "Đã duyệt";
      case StatusEum.REFUSE:
        return "Từ chối";
      case StatusEum.PUBLISHED:
        return "Đã xuất bản";
      case StatusEum.END:
        return "Kết thúc";
      default:
        return "";
    }
  };

  return (
    <div ref={ref} className="Card">
      <Card>
        <Typography.Title
          style={{
            fontSize: 20,
            fontWeight: 500,
            color: "#000000D9",
            lineHeight: "28px",
            fontFamily: "Roboto",
          }}
        >
          Thông tin đăng ký kế hoạch
        </Typography.Title>
        <Typography.Text
          style={{
            fontSize: 14,
            fontWeight: 400,
            color: "#00000073",
            lineHeight: "22px",
            fontFamily: "Roboto",
          }}
        >
          Thông tin chung về đăng ký kế hoạch xây dựng khoá học
        </Typography.Text>
        <div className="container">
          <div className="item">
            <Typography.Title
              style={{ marginBottom: 0 }}
              className="item-title"
            >
              Tên đăng ký
            </Typography.Title>
            <Typography.Text className="item-content" style={{ fontSize: 16 }}>
              {detailCourseConstruct?.registrationName}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Title
              className="item-title"
              style={{ marginBottom: 0 }}
            >
              Mã đăng ký
            </Typography.Title>
            <Typography.Text className="item-content" style={{ fontSize: 16 }}>
              {detailCourseConstruct?.registrationCode}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Title
              style={{ marginBottom: 0 }}
              className="item-title"
            >
              Trạng thái
            </Typography.Title>
            <Typography.Text className="item-content" style={{ fontSize: 16 }}>
              <Badge
                key={detailCourseConstruct?.constructionPlanStatus}
                color={
                  detailCourseConstruct?.constructionPlanStatus === 1
                    ? "#D9D9D9"
                    : detailCourseConstruct?.constructionPlanStatus === 2
                    ? "#FAAD14"
                    : detailCourseConstruct?.constructionPlanStatus === 3
                    ? "#1677FF"
                    : detailCourseConstruct?.constructionPlanStatus === 4
                    ? "#FF0000"
                    : detailCourseConstruct?.constructionPlanStatus === 5
                    ? "#1677FF"
                    : detailCourseConstruct?.constructionPlanStatus === 6
                    ? "#52C41A"
                    : "white"
                }
                style={{ marginRight: 10 }}
              />
              <span>
                {handleReturnStatus(
                  detailCourseConstruct?.constructionPlanStatus
                )}
              </span>
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Title
              style={{ marginBottom: 0 }}
              className="item-title"
            >
              Khoa
            </Typography.Title>
            <Typography.Text className="item-content" style={{ fontSize: 16 }}>
              {detailCourseConstruct &&
                detailCourseConstruct.moocConstructionPlanIndustry &&
                detailCourseConstruct.moocConstructionPlanIndustry.length > 0 &&
                detailCourseConstruct.moocConstructionPlanIndustry.map(
                  (item: any, index: number, array: any[]) => (
                    <span key={index}>
                      {item.moocIndustry.name}
                      {index < array.length - 1 ? ", " : ""}
                    </span>
                  )
                )}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Title
              style={{ marginBottom: 0 }}
              className="item-title"
            >
              Chuyên ngành
            </Typography.Title>
            <Typography.Text className="item-content" style={{ fontSize: 16 }}>
              {detailCourseConstruct &&
                detailCourseConstruct.moocConstructionPlanIndustryGroup &&
                detailCourseConstruct.moocConstructionPlanIndustryGroup.length >
                  0 &&
                detailCourseConstruct.moocConstructionPlanIndustryGroup.map(
                  (item: any, index: number, array: any[]) => (
                    <span key={index}>
                      {item?.moocIndustryGroup?.name}
                      {index < array.length - 1 ? ", " : ""}
                    </span>
                  )
                )}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Title
              style={{ marginBottom: 0 }}
              className="item-title"
            >
              Trường chủ trì
            </Typography.Title>
            <Typography.Text className="item-content" style={{ fontSize: 16 }}>
              {detailCourseConstruct?.moocUniversity?.name}
            </Typography.Text>
          </div>
          <div className="item">
            <Typography.Title
              style={{ marginBottom: 0 }}
              className="item-title"
            >
              Trường hợp tác
            </Typography.Title>
            <Typography.Text className="item-content" style={{ fontSize: 16 }}>
              {detailCourseConstruct?.moocConstructionPlanCollaborator
                ?.map((collab: any) => collab.moocUniversity?.name)
                .join(", ") || ""}
            </Typography.Text>
          </div>

          <div className="item">
            <Typography.Title
              style={{ marginBottom: 0 }}
              className="item-title"
            >
              Loại khoá học
            </Typography.Title>
            <Typography.Text className="item-content" style={{ fontSize: 16 }}>
              {detailCourseConstruct?.courseType === 1
                ? "Tự triển khai"
                : "Hợp tác xây dựng"}
            </Typography.Text>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default InformationPlan;
