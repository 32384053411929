import React, { FunctionComponent } from "react";
import { FormInstance } from "antd/lib";
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  form: FormInstance;
  setFilterData: (value: string[], name: string) => void;
  clearFilter: () => void;
  onFinish?: () => void;
  dataOptions?: any[];
  tab: number;
  filterData: any;
};

const FormFilterMoocCourseConstructionPlan: FunctionComponent<FormFilterProps> = ({
  handleCloseFilter,
  onFinish,
  form,
  showFilter,
  clearFilter,
  dataOptions,
  tab,
  filterData,
  setFilterData,
}) => {
  const { RangePicker } = DatePicker;

  return (
    <div className="gc-style">
      <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
        <Form form={form} name="filterForm" onFinish={onFinish}>
          <Row gutter={[{ xs: 8, xl: 16, md: 12 }, 12]}>
            {dataOptions?.map((filter) => {
              if (filter.type === "RangePicker") {
                return (
                  <Col
                    xl={{ span: filter.size }}
                    md={{ span: filter.size + 1 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    key={filter.key}
                  >
                    <Form.Item name={filter.key}>
                      <RangePicker
                        placeholder={[filter.title, filter.title]}
                        format="DD/MM/YYYY"
                        value={filterData[filter.key]}
                        onChange={(_value, dateString: any) => {
                          setFilterData(dateString, filter.key);
                        }}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              if (filter.type === "FormItemDropdown") {
                return (
                  <Col
                    xl={{ span: filter.size }}
                    md={{ span: filter.size + 1 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    key={filter.key}
                  >
                    <Form.Item name={filter.key}>
                      <FormItemDropdown
                        modeDropdown="multiple"
                        placeholder={`Chọn ${filter.title.toLowerCase()}`}
                        options={filter.data}
                        onChange={(option) => setFilterData(option, filter.key)}
                      />
                    </Form.Item>
                  </Col>
                );
              }
              return null;
            })}
            <Col
              xl={{ span: 6 }}
              md={{ span: 8 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              className="flex align-end"
            >
              <div className="group-btn--filter" style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button
                  className="btn btn-primary filter-btn"
                  htmlType="submit"
                  onClick={() => form.submit()}
                  style={{ marginRight: '8px' }}
                >
                  <FilterOutlined />
                  <Typography.Text>Lọc</Typography.Text>
                </Button>

                <Button
                  className="btn btn-outlined clear-filter-btn"
                  onClick={clearFilter}
                >
                  <DeleteOutlined />
                  <Typography.Text>Xóa tất cả</Typography.Text>
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default FormFilterMoocCourseConstructionPlan;
