import React from 'react';
import {Button, Col, DatePicker, Form, FormInstance, Row, Typography} from "antd";
import {DeleteOutlined, FilterOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";

type FormFilterProps = {
  handleCloseFilter: () => void;
  showFilter?: boolean;
  clearFilter: (form: FormInstance) => void;
  onFinish?: (values: any) => void;
  filterData: any; //FilterDataSurveyOperationHistory;
};

const FormTable = ({
                     onFinish,
                     showFilter,
                     clearFilter,
                     filterData,
                   }: FormFilterProps) => {
  const {RangePicker} = DatePicker;
  const [form] = useForm()


  return (
    <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
      <Form
        form={form}
        name="filterForm"
        className="filter-form"
        onFinish={onFinish}
      >
        <Row gutter={[{xs: 8, xl: 24, md: 16}, 12]} className="row-filter">
          <Col
            xl={{span: 6}}
            md={{span: 4}}
            sm={{span: 4}}
            xs={{span: 24}}
          >
            <Typography.Title level={5}>Ngày ghi danh</Typography.Title>
            <Form.Item name="enrolledDate">
              <RangePicker
                placeholder={["Bắt đầu", "Kết thúc"]}
                format="DD/MM/YYYY"
                value={filterData.enrolledDate}
                // onChange={(_value, dateString: any) => {
                //   setFilterData(dateString, "rangeDate");
                // }}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{span: 6}}
            md={{span: 4}}
            sm={{span: 4}}
            xs={{span: 24}}
            className="flex align-end col-filter"
          >
            <div className="group-btn--filter">
              <Button
                className="btn btn-primary filter-btn"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <FilterOutlined/>
                <Typography.Text>Lọc</Typography.Text>
              </Button>
              <Button
                className="btn btn-outlined clear-filter-btn"
                onClick={() => clearFilter(form)}
              >
                <DeleteOutlined/>
                <Typography.Text>Xóa tất cả</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FormTable;
