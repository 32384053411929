import React from "react";
import { QuestionProps } from "../../../../../types/course";
import { Form, Radio } from "antd";

function TrueOrFalse({ isCorrect, isIncorrect }: QuestionProps) {
  return (
    <Form.Item
      name="TrueOrFalse"
      initialValue={"apple"}
      className={`${isCorrect ? "choose-correct" : ""} ${isIncorrect ? "choose-incorrect" : ""}`}
    >
      <Radio.Group disabled className="flex flex-column gap-12">
        <Radio value="apple">
          <span className="font-size-16 line-height-24 font-weight-5 text-result">
            Đúng
          </span>
        </Radio>
        <Radio value="pear">
          <span className="font-size-16 line-height-24 font-weight-5">Sai</span>
        </Radio>
      </Radio.Group>
    </Form.Item>
  );
}

export default TrueOrFalse;
