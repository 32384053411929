import {
  Button,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  message,
  Modal,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import React, { ChangeEvent, useEffect, useState, KeyboardEvent } from "react";
import { formatOriginTime } from "../../../utils/converDate";
import FilterSurvey from "./components/filter-survey/FilterSurvey";
import moment from "moment";
import "./index.scss";
import {
  IDataCreateSurvey,
  SurveyType,
} from "../../../types/survey-managment/survey";
import {
  EditOutlined,
  CopyOutlined,
  SendOutlined,
  CloudSyncOutlined,
  HistoryOutlined,
  DeleteOutlined,
  EyeOutlined,
  MoreOutlined,
  CloseOutlined,
  SaveOutlined,
  UndoOutlined,
  CopyTwoTone,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import { FilterData } from "../../../types/course-construction";
import {
  createSurvey,
  getSurveyDetail,
  postSurveySearch,
  surveyDelete,
} from "../../../service/survey-managment/list-survey";
import DrawerCustom from "../../../components/drawer/Drawer";
import FormCreateSurvey from "./components/form-create-survey/FormCreateSurvey";
import {
  ApprovalStatusEnum,
  courseType,
  SurveyStatusEnum,
  validateFieldCreateSurvey,
} from "./constants/data";
import { getCurrentPageFromLink } from "../../../utils/getPageFromLink";
import { getUniversity, getUniversityHost } from "../../../service/common";
import {
  getIndustry,
  getIndustryGroup,
} from "../../../service/course-construct";
import { HelpService } from "../../../service/helper.service";
import { get } from "lodash";
import { DataType } from "../../../types/survey-managment/listSurvey";
import { useNavigate, useParams } from "react-router-dom";
import ApproveSurvey from "./components/approve-survey/approveSurvey";
import { postCreateMoocSurveyCopy } from "../../../service/survey-managment/mooc-survey/mooc-survey-api";
import { useAuthStore } from "../../../stores/stores";
import { error } from "console";
import { revokeSurvey } from "../../../service/survey-managment/mooc-survey-approval/mooc-survey-approval-api";
import { routesConfig } from "../../../config/routes";
import { postMoocRevokeSurvey } from "../../../service/survey-managment/mooc-survey-publish/mooc-survey-publish-api";
import TableData from "../../../components/table-data/TableData";
import { getApprovalConfiguration } from "../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";
type ContentConstructionProps = {
  title?: string;
};
const Survey = (props: ContentConstructionProps) => {
  const params = useParams();
  const navigation = useNavigate();
  const helpService = new HelpService();
  const { title } = props;
  const [detailData, setDetailData] = useState<IDataCreateSurvey>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [listSurvey, setListSurvey] = useState<SurveyType[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchValueNotFound, setSearchValueNotFound] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [idDetail, setIdDetail] = useState(0);
  const [form] = Form.useForm();
  const [browseData, setBrowseData] = useState<DataType>();
  const [isOpenModalApprove, setIsOpenModalApprove] = useState<boolean>(false);
  const userId = useAuthStore((states) => states.user?.id);
  const [filterData, setFilterData] = useState<FilterData>({
    createDate: null,
    publicDate: null,
    courseType: null,
    status: null,
  });
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [approval, setApproval] = useState<[]>([]);
  const [duplicateSurvey, setDuplicateSurvey] = useState<boolean>(false);
  const [universityHost, setUniversityHost] = useState<Number>();
  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });

  const [dataOptions, setDataOptions] = useState({
    dataIndustry: [],
    dataIndustryGroup: [],
    dataUniversity: [],
    dataUniversityHost: [],
    dataCollaborator: [],
    dataCourseType: [...courseType],
  });

  const [isOpened, setIsOpened] = useState<boolean>(false);
  const handleOpenCreate = () => {
    console.log(dataOptions);
    setIdDetail(0);
    setIsOpened(true);
  };
  const handleClose = () => {
    setIsOpened(false);
    setDetailData({});
    form.resetFields();
  };
  useEffect(() => {
    const pageLink = getCurrentPageFromLink(location.search);
    form.resetFields();
    if (!isEmpty(pageLink)) {
      setPageNumber(Number(pageLink));
    } else {
      const fetchDataOptions = async () => {
        const response = await Promise.all([
          getUniversity(),
          getIndustry(),
          getIndustryGroup(),
          getUniversityHost(),
        ]);
        const dataUniversity = response[0].data.data;
        const dataIndustry = response[1].data.data;
        const dataIndustryGroup = response[2].data.data;
        const dataUniversityHost = response[3].data.data;
        console.log("Hello", getUserInfo?.enterpriseUUID);
        const universityHostFind = dataUniversity.find(
          (u: any) => u.enterprise_id === getUserInfo?.enterpriseUUID
        );
        console.log(universityHostFind);
        form.setFieldsValue({
          universityId: universityHostFind?.id || [],
        });
        setUniversityHost(universityHostFind);
        console.log(dataUniversityHost);
        // console.log(dataUniversity);
        setDataOptions({
          ...dataOptions,
          dataIndustry: dataIndustry,
          dataUniversity: dataUniversity,
          dataIndustryGroup: dataIndustryGroup,
          dataCollaborator: dataUniversity,
          dataUniversityHost: dataUniversityHost,
        });
      };
      fetchDataOptions();
      getApprovalConfigurationList();
    }
  }, []);

  const getListData = () => {
    setLoading(true);
    const data = {
      keyword: searchValue,
      pageNumber: pageNumber,
      pageSize: pageSize,
      createdDateFrom: !isEmpty(filterData.createDate?.[0])
        ? new Date(filterData.createDate?.[0]).toISOString()
        : "",
      createdDateTo: !isEmpty(filterData.createDate?.[1])
        ? new Date(filterData.createDate?.[1]).toISOString()
        : "",
      publicDateFrom: !isEmpty(filterData.publicDate?.[0])
        ? new Date(filterData.publicDate?.[0]).toISOString()
        : "",
      publicDateTo: !isEmpty(filterData.publicDate?.[1])
        ? new Date(filterData.publicDate?.[1]).toISOString()
        : "",
      status: !isEmpty(filterData.status) ? filterData.status : null,
      courseType: !isEmpty(filterData.courseType)
        ? filterData.courseType
        : null,
      sortField: fieldSortOrder,
      sortOrder: statusOrder,
    };
    postSurveySearch(data)
      .then((res) => {
        const { data } = res.data;
        setTotalRecords(res.data.data.totalRecords);
        setListSurvey(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getApprovalConfigurationList = async () => {
    const data = {
      approvalType: 1,
    };
    await getApprovalConfiguration(data).then((res: any) => {
      const approvalData = res.data.data.data;
      const modifiedApproval = approvalData
        .map((item: any) => ({
          id: `${item.id}-1`,
          name: `Chờ ${item.approvalLevelName} duyệt`,
        }))
        .concat(
          approvalData.map((item: any) => ({
            id: `${item.id}-2`,
            name: `${item.approvalLevelName} đã duyệt`,
          }))
        )
        .concat(
          approvalData.map((item: any) => ({
            id: `${item.id}-3`,
            name: `${item.approvalLevelName} từ chối`,
          }))
        );
      // .concat(
      //   approvalData.map((item: any) => ({
      //     id: `${item.id}-4`,
      //     name: `${item.approvalLevelName} đã thu hồi`,
      //   }))
      // );
      setApproval(modifiedApproval);
    });
  };

  useEffect(() => {
    getListData();
  }, [pageNumber, pageSize, statusOrder]);

  useEffect(() => {
    getListData();
  }, [duplicateSurvey]);

  const formatDateV2 = (date: string) => {
    return moment(date).format("DD/MM/yyyy");
  };

  const handleSubmitSearch = () => {
    setPageNumber(1);
    setPageSize(10);
    setSearchValueNotFound(searchValue);
    getListData();
  };
  const onFinishCreate = (values: any) => {
    if (idDetail) {
      values = { id: idDetail, ...values, surveyStatus: 1 };
    }
    values = {
      ...values,
      surveyStatus: 1,
      courseType: values.courseType[0]
        ? values.courseType[0]
        : values.courseType,
    };
    createSurvey(values)
      .then((res) => {
        const { data, statusCode, message } = res.data;
        if (statusCode === 200) {
          helpService.successMessage(get(data, "message", message));
          form.resetFields();
          setIdDetail(0);
          getListData();
          handleClose();

          navigation(`/survey-training/survey-detail/${data.id}`);
        }
      })
      .catch((err) => {});
  };
  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      createDate: null,
      publicDate: null,
      courseType: null,
      status: null,
    });
    setPageNumber(1);
    setPageSize(10);
    const data = {
      keyword: "",
      pageNumber: 1,
      pageSize: 10,
    };
    postSurveySearch(data)
      .then((res) => {
        const { data } = res.data;
        setTotalRecords(res.data.data.totalRecords);
        setListSurvey(data.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  const deleteSurvey = async (value: any) => {
    if (value.surveyStatus == SurveyStatusEnum.PUBLISHED) {
      message.error("Không thể xóa khảo sát đã xuất bản");
    } else {
      await surveyDelete(value.id).then((res) => {
        const { data, statusCode, message } = res.data;
        if (statusCode === 200) {
          helpService.successMessage(get(data, "message", message));
          getListData();
        }
      });
    }
  };
  const closeApproveModal = () => {
    setIsOpenModalApprove(false);
    getListData();
  };
  const revoke = async (id: number) => {
    const data = {
      surveyId: id,
      submitterId: userId,
    };
    revokeSurvey(data).then((res) => {
      const { data, statusCode, message } = res.data;
      if (statusCode === 200) {
        helpService.successMessage(get(data, "message", message));
        getListData();
      }
    });
  };

  const revokePublishSurvey = async (id: any) => {
    try {
      setLoading(true);
      postMoocRevokeSurvey(id).then((res) => {
        // const { statusCode } = res.data;
        if (res.data.statusCode == 200) {
          message.success(`Yêu cầu thu hồi đợt khảo sát thành công`);
          getListData();
          setLoading(false);
        }
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const createSurveyCopy = async (id: number) => {
    try {
      // console.log({ id })
      setLoading(true);

      const res = await postCreateMoocSurveyCopy(Number(id));
      console.log({ res });
      if (res && res.data && res.data.statusCode === 200) {
        // getListData();

        setLoading(false);

        message.success(
          `Tạo thành công bản sao với tên ${res?.data?.data?.survey_name}`
        );
        setDuplicateSurvey(!duplicateSurvey);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReturnStatus = (record: any) => {
    switch (record.surveyStatus) {
      case SurveyStatusEnum.SAVE_DRAFT:
        return "Lưu bản nháp";
      case SurveyStatusEnum.WAIT_APPROVE:
        if (record.approvalStatus && record.approvalLevelName) {
          switch (record.approvalStatus) {
            case ApprovalStatusEnum.WAIT_APPROVE:
              return `Chờ ${record.approvalLevelName} duyệt`;
            case ApprovalStatusEnum.APPROVED:
              return `${record.approvalLevelName} đã duyệt`;
            default:
              return "Chờ duyệt";
          }
        }
        return "Chờ duyệt";
      case SurveyStatusEnum.APPROVED:
        if (
          record.approvalStatus == ApprovalStatusEnum.APPROVED &&
          record.approvalLevelName
        ) {
          return `${record.approvalLevelName} đã duyệt`;
        }
        return "Đã duyệt";
      case SurveyStatusEnum.REFUSE:
        if (
          record.approvalStatus == ApprovalStatusEnum.REFUSE &&
          record.approvalLevelName
        ) {
          return `${record.approvalLevelName} từ chối`;
        }
        return "Từ chối";
      case SurveyStatusEnum.PUBLISHED:
        return "Đã xuất bản";
      case SurveyStatusEnum.END:
        return "Kết thúc";
      case SurveyStatusEnum.REVOKE:
        return "Thu hồi xuất bản";
      default:
        return "";
    }
  };
  const handleReturnCourseType = (courseType: number) => {
    switch (courseType) {
      case 1:
        return "Tự triển khai";
      case 2:
        return "Hợp tác xây dựng";
      case 3:
        return "Dùng chung";
      default:
        return "";
    }
  };
  useEffect(() => {
    form.resetFields();
    if (detailData && Object.keys(detailData).length > 0) {
      form.setFieldsValue({
        surveyName: detailData?.surveyName || "",
        industryId: detailData?.industryId || [],
        industryGroupId: detailData?.industryGroupId || [],
        courseType: [detailData?.courseType] || [],
        universityId: detailData?.universityId || [],
        collaboratorId: detailData?.collaboratorId || [],
      });
    }
  }, [detailData]);
  const getDetailSurveyData = async (id: number) => {
    try {
      setLoading(true);
      const response = await getSurveyDetail(JSON.stringify(id) || "0").then(
        (res) => {
          const { data } = res.data;
          setDetailData(data);
          setTimeout(() => {
            setLoading(false);
            setIsOpened(true);
          }, 500);
        }
      );
    } catch (error) {
      setLoading(false);
    }
    console.log(detailData);
  };
  const handleSearchEnter = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };
  const handleView = async (id: number) => {
    navigation(`/survey-training/survey-detail/${id}`, {
      state: { mode: "view" },
    });
  };
  const getOptionsAction = (record: any): MenuProps["items"] => {
    let options1: MenuProps["items"] = [
      {
        key: "1",
        label: "Sửa khảo sát",
        icon: <EditOutlined />,
      },
      {
        key: "2",
        label: "Tạo bản sao",
        icon: <CopyOutlined />,
      },
      {
        key: "3",
        label: "Gửi duyệt",
        icon: <SendOutlined />,
      },
      {
        key: "4",
        label: "Xuất bản",
        icon: <CloudSyncOutlined />,
      },
      {
        key: "5",
        label: "Thu hồi gửi duyệt",
        icon: <UndoOutlined />,
      },
      {
        key: "6",
        label: "Lịch sử hoạt động",
        icon: <HistoryOutlined />,
      },
      {
        key: "8",
        label: "Thu hồi khảo sát",
        icon: <UndoOutlined />,
      },
      {
        key: "7",
        label: "Xóa khảo sát",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
    ];
    if (
      [
        SurveyStatusEnum.WAIT_APPROVE,
        SurveyStatusEnum.APPROVED,
        SurveyStatusEnum.END,
        SurveyStatusEnum.PUBLISHED,
      ].includes(record.surveyStatus)
    ) {
      options1 = options1.filter((option) => option?.key !== "7");
    }
    if (
      record.surveyStatus !== SurveyStatusEnum.SAVE_DRAFT &&
      record.surveyStatus !== SurveyStatusEnum.REFUSE
    ) {
      options1 = options1.filter((option) => option?.key !== "1");
    }
    if (
      record.surveyStatus === SurveyStatusEnum.REFUSE ||
      record.surveyStatus === SurveyStatusEnum.SAVE_DRAFT ||
      record.surveyStatus === SurveyStatusEnum.WAIT_APPROVE
    ) {
      if (
        record.surveyStatus === SurveyStatusEnum.WAIT_APPROVE &&
        record.approvalStatus &&
        record.approvalStatus === ApprovalStatusEnum.WAIT_APPROVE
      ) {
        options1 = options1.filter((option) => option?.key !== "3");
      }
    } else {
      options1 = options1.filter((option) => option?.key !== "3");
    }
    if (
      record.surveyStatus !== SurveyStatusEnum.WAIT_APPROVE ||
      record.approvalStatus == ApprovalStatusEnum.APPROVED
    ) {
      options1 = options1.filter((option) => option?.key !== "5");
    }

    if (record.surveyStatus !== SurveyStatusEnum.APPROVED) {
      options1 = options1.filter((option) => option?.key !== "4");
    }
    if (record.surveyStatus !== SurveyStatusEnum.PUBLISHED) {
      options1 = options1.filter((option) => option?.key !== "8");
    }
    return options1;
  };
  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      key: "numericalOrder",
      width: 2,
      render: (_: any, record, index: number) => {
        const orderNumber = (pageNumber - 1) * pageSize + index + 1;
        return <span className="table-order-header">{orderNumber}</span>;
      },
    },
    {
      title: "Tên đợt khảo sát",
      width: 10,
      dataIndex: "surveyName",
      sorter: true,
      render: (_: any, record) => {
        return (
          <div className="table-order-course-name">
            <img
              className="item-carousel__image"
              loading="eager"
              alt=""
              src={"/images/ic_school_placeholder.svg"}
            />
            <div>
              <span className="course-title">{record.surveyName}</span>
              <span>{record.universityName}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Trạng thái",
      width: 6,
      dataIndex: "surveyStatus",
      render: (_: any, record) => {
        return (
          <div className="table-status">
            <div
              className="color-icon"
              style={{
                backgroundColor:
                  record.surveyStatus === 1
                    ? "#D9D9D9"
                    : record.surveyStatus === 2
                    ? "#FAAD14"
                    : record.surveyStatus === 3
                    ? "#1677FF"
                    : record.surveyStatus === 4
                    ? "#FF0000"
                    : record.surveyStatus === 5
                    ? "#1677FF"
                    : record.surveyStatus === 6
                    ? "#52C41A"
                    : record.surveyStatus === 7
                    ? "#FF0000"
                    : "white",
              }}
            ></div>
            {handleReturnStatus(record)}
          </div>
        );
      },
    },
    {
      title: "Loại khoá học",
      width: 7,
      dataIndex: "courseType",
      render: (_: any, record) => {
        return (
          <div className="table-course-type">
            {record.courseType === 2 ? (
              <div className="table-course-type-2">Hợp tác xây dựng</div>
            ) : record.courseType === 1 ? (
              <div className="table-course-type-1">Tự triển khai</div>
            ) : (
              <div>Dùng chung</div>
            )}
          </div>
        );
      },
    },
    {
      title: "Ngày tạo",
      width: 5,
      dataIndex: "createdDate",
      render: (_: any, record) => {
        return (
          <>{record.createdDate ? formatDateV2(record.createdDate) : null}</>
        );
      },
    },
    {
      title: "Ngày xuất bản",
      width: 5,
      dataIndex: "publishedDate",
      render: (_: any, record) => {
        return (
          <>{record.publishedDate ? formatOriginTime(record.publishedDate.toString()) : null}</>
        );
      },
    },
    {
      title: "",
      width: 3,
      fixed: "right",
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };
        return (
          <>
            <Space>
              <a
                className="action-table"
                // onClick={() => handleView(record.id)}
                onClick={() =>
                  navigation(`/survey-training/survey-detail/${location.id}`, {
                    state: { mode: "view" },
                  })
                }
              >
                <EyeOutlined />
              </a>
              <Dropdown
                menu={{
                  items: getOptionsAction(record),
                  onClick: ({ key }) => {
                    if (
                      key === "1" &&
                      (record.surveyStatus == 1 || record.surveyStatus == 4)
                    ) {
                      form.resetFields();
                      setIdDetail(record.id);
                      // setIsOpened(true);
                      getDetailSurveyData(record.id);
                    }
                    if (key === "2") {
                      Modal.confirm({
                        icon: <CopyTwoTone />,
                        title: `Bạn muốn tạo bản sao cho khảo sát ${record.surveyName}?`,
                        content: `Tên của bản sao khảo sát là "${record.surveyName} (Bản sao)"`,
                        onOk: () => {
                          createSurveyCopy(record.id);
                        },
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "3") {
                      setIsOpenModalApprove(true);
                      setBrowseData(record);
                    }
                    if (key === "4") {
                      navigation(
                        `/survey-training/publish-survey/${record.id}`
                      );
                    }
                    if (key === "6") {
                      navigation(
                        `/survey-training/mooc-action-history-list/${record.id}`
                      );
                    }
                    if (key === "5") {
                      Modal.confirm({
                        title: `Thu hồi gửi duyệt khảo sát ${record.surveyName}`,
                        content: `Bạn có chắc chắn muốn thu hồi gửi duyệt khảo sát ${record.surveyName}?`,
                        onOk: () => revoke(record.id),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key === "7") {
                      Modal.confirm({
                        title: `Xoá khảo sát ${record.surveyName}`,
                        content: (
                          <>
                            Khảo sát đã xoá sẽ không thể khôi phục.
                            <br />
                            Bạn có chắc chắn muốn xoá khảo sát?
                          </>
                        ),
                        onOk: () => deleteSurvey(record),
                        centered: true,

                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                    if (key == "8") {
                      Modal.confirm({
                        title: `Thu hồi khảo sát ${record.surveyName}`,
                        content: `Bạn có chắc chắn muốn thu hồi khảo sát ${record.surveyName}?`,
                        onOk: () => revokePublishSurvey(record.id),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ",
                        footer: (_, { OkBtn, CancelBtn }) => (
                          <>
                            <CancelBtn />
                            <OkBtn />
                          </>
                        ),
                      });
                    }
                  },
                }}
              >
                <Button>
                  <MoreOutlined />
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
    },
  ];

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    // chuyển Camel To Snake
    const fieldName = sorter.field.replace(/([A-Z])/g, "_$1").toLowerCase();
    setStatusOrder(data);
    setFieldSortOrder(fieldName);
  };
  return (
    <Layout className="page-header-group survey-managment">
      <Form.Provider
        onFormFinish={(name, { values, forms }) => {
          if (name === "createSurvey") {
            const { createSurvey } = forms;
            const data = createSurvey.getFieldsValue(validateFieldCreateSurvey);
            onFinishCreate(data);
          }
          if (name === "approveModal") {
            setIsOpenModalApprove(false);
          }
        }}
      >
        <div className="table-header--wrapper">
          <FilterSurvey
            handleOpen={handleOpenCreate}
            title={title}
            searchValue={searchValue}
            filterData={filterData}
            setFilterData={handleChangeFilter}
            handleChangeInput={handleChangeInput}
            handleSubmitSearch={handleSubmitSearch}
            clearFilter={clearFilter}
            form={form}
            handleSearchEnter={handleSearchEnter}
            approvalConfiguration={approval}
          />
        </div>
        <div className="tabs-heading">
          <TableData
            dataTable={listSurvey}
            dataColumns={dataColumns}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            pageSize={pageSize}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            handleTableChangeProps={handleTableChange}
            pagination={true}
            searchValue={searchValue}
          />
        </div>
        <DrawerCustom
          title={detailData?.id ? "Sửa đợt khảo sát" : "Tạo đợt khảo sát"}
          open={isOpened}
          onClose={handleClose}
          extraNode={
            <Space>
              <Button onClick={handleClose} className="btn btn-outlined">
                <CloseOutlined />
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button
                className="btn btn-primary"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <SaveOutlined />
                <Typography.Text>Lưu</Typography.Text>
              </Button>
            </Space>
          }
          widthDrawer={690}
        >
          <FormCreateSurvey
            form={form}
            dataOptions={dataOptions}
            id={idDetail}
          />
        </DrawerCustom>
        <ApproveSurvey
          open={isOpenModalApprove}
          handleCancel={() => {
            closeApproveModal();
          }}
          browseData={browseData}
        />
      </Form.Provider>
    </Layout>
  );
};

export default Survey;
