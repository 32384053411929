// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-list-top .table-wrapper {
  padding: 0; }
`, "",{"version":3,"sources":["webpack://./src/pages/system-suggest-course/synthesis-report/tab-list-top.scss"],"names":[],"mappings":"AAAA;EAEQ,UAAU,EAAA","sourcesContent":[".table-list-top {\n    .table-wrapper{\n        padding: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
