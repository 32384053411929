import "./detail.scss";
import { Button, Input, Radio, Row, Space, ConfigProvider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useEffect, useState } from "react";
import { Divider } from "antd";
import { useSurveyRespond } from "../../../../../../../../../stores/stores";


const QuestionType8 = (props: any) => {
  const questionAnswer = props.questionAnswer;
  const { updateAnswer } = useSurveyRespond();
  const [inputValue, setInputValue] = useState<string>(questionAnswer);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }
  useEffect(() => {
    updateAnswer(props.surveyId, props.sectionId, props.questionId, inputValue)
  }, [inputValue])

  return (
    <div style={{ width: "100%" }}>
      <Input
        placeholder="Nhập câu trả lời của bạn"
        value={inputValue}
        onChange={handleChangeInput}
        styles={{
          input: {
            border: "1px solid #D9D9D9",
            borderStyle: "none none solid none",
            padding: "5px 12px",
          }
        }}
        disabled={props.typeSurvey === "2"}
      ></Input>
    </div>
  );
};

export default QuestionType8;
