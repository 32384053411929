import {
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileDoneOutlined,
  HeartOutlined,
  HistoryOutlined,
  LoadingOutlined,
  MoreOutlined,
  RedoOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
  ThunderboltOutlined,
  UploadOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Layout,
  MenuProps,
  Modal,
  Row,
  Space,
  Spin,
  TableColumnsType,
  TableProps,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { isBuffer, isEmpty } from "lodash";
import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import TableData from "../../table-data/TableData";
import PageHeader from "../../page-header/PageHeader";
import FilterStudentAccountsImport from "../../list-student-accounts-import/filter-table/FilterTable";
import { formatDateV2 } from "../../../utils/converDate";
import { exportFailAccountStudentExcelFile, getListStudentImportExcelFile, saveApprovedAccount } from "../../../service/list-account-student/listAccountStudent";
import FormInput from "../../form-input/FormInput";


type Props = {
  handleClose: () => void
  saveAccount: () => void;
  open: boolean
  importMode: boolean
};

type PositionType = 'left' | 'right';

const ListStudentsAccountImportModal = (props: Props) => {
  const [tab, setTab] = useState("1");
  const { handleClose, open, importMode, saveAccount } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedSaveTemplate, setIsOpenedSaveTemplate] = useState<boolean>(false);
  const [listAdminInterface, setListAdminInterface] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [clearRowkey, setClearRowkey] = useState<boolean>(false);
  const [importModeExcel, setImportModeExcel] = useState<boolean>(false)

  const [pageSize, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [countReviewed, setCountReviewed] = useState<number>(0);
  const [countAwaitingReview, setCountAwaitingReview] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataUpdate, setDataUpdate] = useState<any>();
  const [lastPreview, setlastPreview] = useState<any>(null);
  const [isOpenedConfirm, setIsOpenedConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const [position, setPosition] = useState<PositionType[]>(['right']);
  const [filterData, setFilterData] = useState<any>({
    status: null,
    approvedDate: null,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const handleOpenCreate = () => {
    setIsOpened(true);
    setDataUpdate({})
  };

  const getListData = async () => {
    setLoading(true);
    const data = {
      page: pageNumber,
      size: pageSize,
      sort: statusOrder ? [`${fieldSortOrder},${statusOrder}`] : [],
      keyword: searchValue.trim() || undefined,
      status: tab === "1" ? true : false,
    };
    if(importModeExcel) {
      await exportFailAccountStudentExcelFile(data).then((res) => {
        if(res.status === 200) {
          const fileData = res.data
          const blob = new Blob([fileData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
      
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(blob);
      
          downloadLink.download = "Danh sách tài khoản sinh viên fail.xlsx";
      
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          message.success("Tải xuống thành công!")
        }
      })
    } else {
      await getListStudentImportExcelFile(data)
        .then((res) => {
          const { data } = res.data
          setListAdminInterface(data.data)
          setTotalRecords(data.total);
          setCountAwaitingReview(data?.validCount)
          setCountReviewed(data?.countInvalidCount)
  
          setTimeout(() => {
            setLoading(false);
          }, 500);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
    setImportModeExcel(false)
  };
  useEffect(() => {
    if (location.state?.tab) {
      setTab(location.state?.tab);
    }
  }, [navigate]);

  useEffect(() => {
    getListData();
  }, [tab, pageNumber, pageSize, statusOrder, lastPreview]);

  useEffect(() => {
    if(importModeExcel) {
      getListData();
    }
  }, [importModeExcel]);

  useEffect(() => {
    if (importMode) {
      getListData();
    }
  }, [importMode]);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleSubmitSearch = () => {
    getListData();
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      getListData();
    }
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };


  const clearFilter = () => {
    // setLoading(true)
    form.resetFields();
    setFilterData({
      status: null,
      courseType: null,
      approvedDate: null,
    });
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 10,
      render: (_: any, record, index: number) => {
        return <div>{record?.numericalOrder}</div>;
      },
    },
    {
      title: "Email đăng nhập",
      dataIndex: "username",
      key: "username",
      sorter: true,
      width: 30,
    },
    {
      title: "Họ và tên",
      dataIndex: "fullName",
      key: "fullName",
      width: 30,
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      width: 20,
    },
    {
      title: "Ngày sinh",
      dataIndex: "createdDate",
      key: "createdDate",
      width: 20,
      render: (_: any, record) => {
        return (
          <>{record?.birthday ? formatDateV2(record?.birthday) : null}</>
        );
      },
    },
    {
      title: "Số giấy tờ cá nhân",
      dataIndex: "cardId",
      key: "cardId",
      width: 30,
    },
    {
      title: "Mã sinh viên",
      dataIndex: "code",
      key: "code",
      width: 30,
    },
    {
      title: "Ngày nhập học",
      dataIndex: "name",
      key: "name",
      width: 30,
      render: (_: any, record) => {
        return (
          <>{record?.admissionDate ? formatDateV2(record?.admissionDate) : null}</>
        );
      },
    },
    {
      title: "Lớp niên khóa",
      dataIndex: "classSemester",
      key: "classSemester",
      width: 30,
    },
    {
      title: "Mô tả",
      dataIndex: "errorCode",
      key: "errorCode",
      width: 30,
    },
  ];

  const defaultCheckedList = dataColumns.map((item) => item.key as string);

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const itemsTab: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div>
          Tài khoản hợp lệ
          <span
            className={`number-record ${tab === "1" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countAwaitingReview}
          </span>
        </div>
      ),
      children: (
        <div className="waiting-assignment">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listAdminInterface}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
            setSelectedRowKeysProps={setSelectedRowKeys}
            clearRowkey={clearRowkey}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div>
          Tài khoản không hợp lệ
          <span
            className={`number-record ${tab === "2" ? "number-record-active" : "number-record-disabled"
              }`}
          >
            {countReviewed}
          </span>
        </div>
      ),
      children: (
        <div className="assigned">
          <TableData
            dataColumns={dataColumns}
            loadingTable={loading}
            dataTable={listAdminInterface}
            pageSize={pageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            scroll={{ x: 1500, y: 800 }}
            pagination={true}
            hideSelection={false}
            handleTableChangeProps={handleTableChange}
          />
        </div>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Quản trị cơ sở",
    },
  ];

  const onChange = (key: string) => {
    setTab(key);
    setFilterData({});
    setPageNumber(1);
    form.resetFields();
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const operations = tab === "2" ? <p className="text-blue-600 cursor-pointer" onClick={() => setImportModeExcel(true)}>Kết xuất tài khoản không hợp lệ</p> : null;

  return (
    <Modal
      title="Danh sách File tài khoản sinh viên"
      className="list-student-accounts-import"
      open={open}
      onCancel={handleClose}
      width={"90%"}
      height={"90%"}
      footer={
        <div></div>
      }
    >
      <Layout className="page-header-group course-evaluation">
        <Form.Provider
          onFormFinish={(name, { values, forms }) => {
            if (name === "filterForm") {
              getListData()
            }
          }}
        >
          <div
            className={`page-header-group--layout ${showFilter ? "open-filter" : ""
              }`}
          >
            <div className="table-header--wrapper">
              <div className="table-header--left">
                <PageHeader title={"Danh sách File tài khoản sinh viên"} listBreadcrumb={listBreadcrumb} />
              </div>
              <FilterStudentAccountsImport
                handleClose={handleClose}
                saveAccount={saveAccount}
                tab={tab}
              />
            </div>
          </div>
          <div className="tabs-heading">
            <Row className="page-header-group--layout">
              <Col md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 7 }}>
                <div className="heading-search">
                  <FormInput placeholder="Nhập từ khóa tìm kiếm..." value={searchValue}
                    onChange={handleChangeInput}
                    onKeyPress={handleSearch}
                    afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
                </div>
              </Col>
            </Row>
            <Tabs items={itemsTab} activeKey={tab} onChange={onChange} tabBarExtraContent={operations} />
          </div>
        </Form.Provider>

      </Layout>
    </Modal>
  );
};

export default ListStudentsAccountImportModal;
