import "./detail.scss";
import {
  Button,
  Input,
  Row,
  Space,
  ConfigProvider,
  Col,
  Typography,
  Radio,
  Divider,
  Tooltip
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ListAnswer } from "../../../../../../../types/survey-managment/survey";
import TextArea from "antd/es/input/TextArea";

const QuestionType7 = (props: any) => {
  const filteredOptionsA: ListAnswer[] = props.listOptions.filter(
    (option: ListAnswer) => {
      return (
        option.answerText &&
        option.answerText !== "" &&
        (!option.linkedAnswerText ||
          option.linkedAnswerText === "" ||
          option.linkedAnswerText === null)
      );
    }
  );

  const filteredOptionsB: ListAnswer[] = props.listOptions.filter(
    (option: ListAnswer) => {
      return (
        option.linkedAnswerText &&
        option.linkedAnswerText !== "" &&
        (!option.answerText ||
          option.answerText === "" ||
          option.answerText === null)
      );
    }
  );

  const [optionsA, setOptionsA] = useState<ListAnswer[]>(
    filteredOptionsA || []
  );
  const [optionsB, setOptionsB] = useState<ListAnswer[]>(
    filteredOptionsB || []
  );

  /*
  const onHandleDeleteOption = (index: number, column: string) => {
    if (column === "A") {
      /*
      const newOptionsA = optionsA.map((option, idx) => {
        if (idx === index) {
          // Nếu là tùy chọn cần xóa, chỉ cần đặt trạng thái is_deleted thành true
          return {
            ...option,
            is_deleted: true
          };
        }
        return option;
      });

      const newOptionsA = optionsA.filter((_, i) => i !== index);

      setOptionsA(newOptionsA);
      props.setUpdateListAnswer([...newOptionsA, ...optionsB]);
    } else if (column === "B") {
      /*
      const newOptionsB = optionsB.map((option, idx) => {
        if (idx === index) {
          // Nếu là tùy chọn cần xóa, chỉ cần đặt trạng thái is_deleted thành true
          return {
            ...option,
            is_deleted: true,
          };
        }
        return option;
      });

      const newOptionsB = optionsB.filter((_, i) => i !== index);

      setOptionsB(newOptionsB);
      props.setUpdateListAnswer([...newOptionsB, ...optionsA]);
    }
  };

  const onHandleAddOption = (column: string) => {
    if (column === "A") {
      const addedOption = {
        answerText: `Lựa chọn ${optionsA.length + 1}`,
        answerOrderNumber: optionsA.length + 1,
      };
      const newOptions = [...optionsA, addedOption];
      props.setUpdateListAnswer([...newOptions, ...optionsB]);
      setOptionsA(newOptions);
    } else if (column === "B") {
      const addedOption = {
        linkedAnswerText: `Lựa chọn ${optionsB.length + 1}`,
        linkedAnswerOrderNumber: optionsB.length + 1,
      };
      const newOptions = [...optionsB, addedOption];
      props.setUpdateListAnswer([...newOptions, ...optionsA]);
      setOptionsB(newOptions);
    }
  };
*/

  const onHandleDeleteOption = (index: number, column: string) => {
    if (column === "A") {
      const newOptionsA = optionsA.filter((_, i) => i !== index);
      const updatedListOptions = [
        ...newOptionsA,
        ...props.listOptions.filter(
          (option: any) =>
            !option.answerText ||
            option.answerText === "" ||
            option.answerText === null
        ),
      ];

      setOptionsA(newOptionsA);
      props.setUpdateListAnswer(updatedListOptions);
    } else if (column === "B") {
      const newOptionsB = optionsB.filter((_, i) => i !== index);
      const updatedListOptions = [
        ...newOptionsB,
        ...props.listOptions.filter(
          (option: any) =>
            !option.linkedAnswerText ||
            option.linkedAnswerText === "" ||
            option.linkedAnswerText === null
        ),
      ];

      setOptionsB(newOptionsB);
      props.setUpdateListAnswer(updatedListOptions);
    }
  };

  const onHandleAddOption = (column: string) => {
    if (column === "A") {
      const addedOption = {
        answerText: `Lựa chọn ${optionsA.length + 1}`,
        answerOrderNumber: optionsA.length + 1,
      };
      const newOptions = [...optionsA, addedOption];
      const updatedListOptions = [
        ...newOptions,
        ...props.listOptions.filter(
          (option: any) =>
            !option.answerText ||
            option.answerText === "" ||
            option.answerText === null
        ),
      ];

      setOptionsA(newOptions);
      props.setUpdateListAnswer(updatedListOptions);
    } else if (column === "B") {
      const addedOption = {
        linkedAnswerText: `Lựa chọn ${optionsB.length + 1}`,
        linkedAnswerOrderNumber: optionsB.length + 1,
      };
      const newOptions = [...optionsB, addedOption];
      const updatedListOptions = [
        ...newOptions,
        ...props.listOptions.filter(
          (option: any) =>
            !option.linkedAnswerText ||
            option.linkedAnswerText === "" ||
            option.linkedAnswerText === null
        ),
      ];

      setOptionsB(newOptions);
      props.setUpdateListAnswer(updatedListOptions);
    }
  };

  const handleChangeAnswerText = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    column: string
  ) => {
    if (column === "A") {
      const newOptions = optionsA.map((option, idx) => {
        if (idx === index) {
          return {
            ...option,
            answerText: event.target.value,
          };
        }
        return option;
      });
      props.setUpdateListAnswer([...newOptions, ...optionsB]);
      setOptionsA(newOptions);
    } else if (column === "B") {
      const newOptions = optionsB.map((option, idx) => {
        if (idx === index) {
          return {
            ...option,
            linkedAnswerText: event.target.value,
          };
        }
        return option;
      });
      props.setUpdateListAnswer([...newOptions, ...optionsA]);
      setOptionsB(newOptions);
    }
  };

  console.log(props.listOptions);
  useEffect(() => {
    const filteredOptionsA: ListAnswer[] = props.listOptions.filter(
      (option: ListAnswer) => {
        return (
          option.answerText &&
          option.answerText !== "" &&
          (!option.linkedAnswerText ||
            option.linkedAnswerText === "" ||
            option.linkedAnswerText === null)
        );
      }
    );

    const filteredOptionsB: ListAnswer[] = props.listOptions.filter(
      (option: ListAnswer) => {
        return (
          option.linkedAnswerText &&
          option.linkedAnswerText !== "" &&
          (!option.answerText ||
            option.answerText === "" ||
            option.answerText === null)
        );
      }
    );
    setOptionsA(filteredOptionsA);
    setOptionsB(filteredOptionsB);
  }, [props]);

  return (
    <div className="survey-managment-construction">
      <div>
        <br />
        <ConfigProvider
          theme={{
            components: {
              Input: {
                colorTextPlaceholder: "black",
                paddingInline: 0,
                paddingBlock: 0,
              },
              Button: {
                paddingInline: 2,
                paddingBlock: 0,
              },
            },
          }}
        >
          <Row
            gutter={32}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <Space direction="horizontal"> */}
            <Col span={12}>
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex", paddingLeft: "20px" }}
              >
                <Typography.Title level={5}>Cột A</Typography.Title>

                {optionsA.map((option, index) => (
                  <Row
                    style={{
                      margin: "0 auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <>{index + 1}.</>
                    <TextArea
                      style={{
                        flex: 1,
                        marginLeft: "10px",
                        marginRight: "10px",
                        padding: "10px 0",
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "1.5",
                      }}
                      autoSize
                      variant="borderless"
                      value={option.answerText}
                      onChange={(event) =>
                        handleChangeAnswerText(event, index, "A")
                      }
                    />
                    <Tooltip placement="top" title="Xóa lựa chọn">
                      <Button
                        style={{ width: 20, height: 25, marginLeft: "5px" }}
                        icon={<CloseOutlined />}
                        onClick={() => onHandleDeleteOption(index, "A")}
                      />
                    </Tooltip>

                  </Row>
                ))}

                <Row
                  style={{
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Input
                    prefix={
                      <>
                        <span>{optionsA.length + 1}.</span>
                        <Button
                          type="link"
                          onClick={() => onHandleAddOption("A")}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Thêm lựa chọn
                        </Button>
                      </>
                    }
                    variant="borderless"
                    type="submit"
                  />
                </Row>
              </Space>
            </Col>

            <Col span={12}>
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex", paddingRight: "20px" }}
              >
                <Typography.Title level={5}>Cột B</Typography.Title>

                {optionsB.map((option, index) => (
                  <Row
                    style={{
                      margin: "0 auto",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <>{String.fromCharCode(97 + index)}.</>
                    <TextArea
                      style={{
                        flex: 1,
                        marginLeft: "10px",
                        marginRight: "10px",
                        padding: "10px 0",
                        display: "flex",
                        alignItems: "center",
                        lineHeight: "1.5",
                      }}
                      autoSize
                      variant="borderless"
                      value={option.linkedAnswerText}
                      onChange={(event) =>
                        handleChangeAnswerText(event, index, "B")
                      }
                    />
                    <Tooltip placement="top" title="Xóa lựa chọn">
                      <Button
                        style={{ width: 20, height: 25, marginLeft: "5px" }}
                        icon={<CloseOutlined />}
                        onClick={() => onHandleDeleteOption(index, "B")}
                      />
                    </Tooltip>

                  </Row>
                ))}

                <Row
                  style={{
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Input
                    prefix={
                      <>
                        <span>
                          {String.fromCharCode(97 + optionsB.length)}.{" "}
                        </span>
                        <Button
                          type="link"
                          onClick={() => onHandleAddOption("B")}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          Thêm lựa chọn
                        </Button>
                      </>
                    }
                    variant="borderless"
                    type="submit"
                  />
                </Row>
              </Space>
            </Col>
            {/* </Space> */}
          </Row>

          {props.isQuestionHasOther && (
            <>
              <Divider />
              <Row
                style={{
                  margin: "0 auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Input
                  prefix={
                    <>
                      <Radio checked={false}></Radio>
                      <span>
                        <i>
                          {" "}
                          Lựa chọn nhập <u>"Khác"</u>
                        </i>
                      </span>
                    </>
                  }
                  variant="borderless"
                />
              </Row>
            </>
          )}
        </ConfigProvider>
        <br />
      </div>
    </div>
  );
};

export default QuestionType7;
