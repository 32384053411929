import { Outlet, useLocation } from 'react-router-dom'

import { AuthGuard } from '../../components/authentication/auth-guard'
import { Layout, Menu } from "antd";
import Header from "../header/Header";
import { useViewport } from '../../hooks/useViewport';
import { menuItems } from "../sidebar/MenuItem";
import Sidebar from "../sidebar/Sidebar";
import { useState } from 'react';
import LoadingFullScreen from "../../components/loading-full-screen/LoadingFullScreen";

export default function DashboardRootPageNoMenu() {
  const location = useLocation()
  const pạthname = location.pathname
  const { width } = useViewport();
  const isMobile = width <= 768;
  const [loading, setLoading] = useState(false);

  return (
    <AuthGuard>
      <main>
        <Layout>
          <Header />
          {/* {isMobile && (
            <Menu
              mode="horizontal"
              defaultSelectedKeys={[location.pathname]}
              items={menuItems}
              className="menu-mobile"
            />
          )} */}
          <Layout>
            {/* <Sidebar /> */}
            {loading && <LoadingFullScreen spinning={loading} />}
            <Outlet />
          </Layout>
        </Layout>
      </main>
    </AuthGuard>
  )
}
