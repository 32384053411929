// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-learning-materials .learning-materials-content {
  padding-left: 24px; }

.add-learning-materials .setup-time-title {
  font-weight: bold;
  padding: 6px 0px; }

.add-learning-materials .pe {
  padding-right: 24px; }

.add-learning-materials .multimedia-img {
  width: 50px; }

.add-learning-materials .radio-item {
  padding: 10px 0px; }
  .add-learning-materials .radio-item .add-chapter-title {
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 500; }
  .add-learning-materials .radio-item .add-chapter-description {
    margin-left: 8px;
    font-size: 14px;
    font-weight: normal;
    padding: 6px 0px; }
  .add-learning-materials .radio-item h2 {
    padding: 6px 0px; }

.add-learning-materials .setup-time .setup-time-time {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%; }

.add-learning-materials .ant-form-vertical .ant-form-item-label {
  font-size: 16px !important;
  font-weight: 500 !important; }
`, "",{"version":3,"sources":["webpack://./src/components/drawer/add-learning-materials-references/addLearningMaterialsReferences.scss"],"names":[],"mappings":"AAAA;EAEI,kBAAkB,EAAA;;AAFtB;EAOI,iBAAiB;EACjB,gBAAgB,EAAA;;AARpB;EAWI,mBAAmB,EAAA;;AAXvB;EAcI,WAAW,EAAA;;AAdf;EAiBI,iBAAiB,EAAA;EAjBrB;IAmBM,kBAAkB;IAClB,eAAe;IACf,gBAAgB,EAAA;EArBtB;IAwBI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,gBAAgB,EAAA;EA3BpB;IA8BI,gBAAgB,EAAA;;AA9BpB;EAqCM,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,UAAU,EAAA;;AAxChB;EA6CI,0BAA0B;EAC1B,2BAA2B,EAAA","sourcesContent":[".add-learning-materials{\n  .learning-materials-content{\n    padding-left: 24px;\n \n  }\n \n  .setup-time-title{\n    font-weight: bold;\n    padding: 6px 0px;\n  }\n  .pe{\n    padding-right: 24px;\n  }\n  .multimedia-img{\n    width: 50px;\n  }\n  .radio-item{\n    padding: 10px 0px;\n    .add-chapter-title{\n      margin-bottom: 0px;\n      font-size: 16px;\n      font-weight: 500;\n    }\n  .add-chapter-description{\n    margin-left: 8px;\n    font-size: 14px;\n    font-weight: normal;\n    padding: 6px 0px;\n  }\n  h2{\n    padding: 6px 0px;\n  }\n  }\n\n  .setup-time{\n\n    .setup-time-time{\n      display: flex;\n      align-items: center;\n      justify-content: space-between;\n      width: 60%;\n\n    }\n  }\n  .ant-form-vertical .ant-form-item-label{\n    font-size: 16px !important;\n    font-weight: 500 !important;\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
