import React, { useEffect, useState } from "react";
import "./style.scss";
import Sidebar from "./Sidebar";
import Content from "./Content";
import Discuss from "./Discuss";
import { Spin } from "antd";
import { toNumber } from "lodash";
import { CourseDetail } from "../../../../types/course";
import { useParams } from "react-router-dom";
import { getCourse } from "../../../../service/course-construct/statistic";
import { useViewport } from "../../../../hooks/useViewport";
import CustomDraw from "../../../drawer/CustomDraw";
import { MenuUnfoldOutlined } from "@ant-design/icons";

function StatisticCourse() {
  const params = useParams();
  const courseId = toNumber(params?.id);
  const [allRefetch, setAllRefetch] = useState<boolean>(false);
  const [allLoading, setAllLoading] = useState<boolean>(false);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [course, setCourse] = useState<CourseDetail>();
  const { width } = useViewport();

  useEffect(() => {
    const getCourses = async () => {
      if (courseId) {
        try {
          setAllLoading(true);
          const res = await getCourse(courseId);
          setCourse(res.data.data);
        } catch (err) {
        } finally {
          setAllLoading(false);
        }
      }
    };
    getCourses();
  }, [courseId, allRefetch]);

  return (
    <Spin spinning={allLoading}>
      <div className="statistic-course-page">
        <Sidebar course={course} onCollapsed={setIsCollapsed} />
        <Content />
        {width < 1400 ? (
          <CustomDraw
            width={500}
            className="custom-draw"
            title="Thảo luận"
            content={
              <Discuss
                className="w-full"
                onRefetch={setAllRefetch}
                setAllLoading={setAllLoading}
                isSidebarCollapsed={isCollapsed}
              />
            }
          >
            {" "}
            <div
              className="btn-collapsed pointer center"
              style={{ width: 50, height: 50 }}
            >
              <MenuUnfoldOutlined />
            </div>
          </CustomDraw>
        ) : (
          <Discuss
            onRefetch={setAllRefetch}
            setAllLoading={setAllLoading}
            isSidebarCollapsed={isCollapsed}
          />
        )}
      </div>
    </Spin>
  );
}

export default StatisticCourse;
