import { Button, Checkbox, Flex, Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import {
  createFormPublishGroupQualityAssessment,
  getSettingQualityAssessment
} from "../../service/control-quality-course";
const { Title, Text } = Typography;

interface IProps {
  isOpen: boolean;
  handleClose: () => void;
  handleNotice: (type: any, message: string) => void;
  numberTab: string;
  handleRefresh: () => void;
}

const ModalQualityForm = ({
  isOpen,
  handleClose,
  handleNotice,
  numberTab,
  handleRefresh,
}: IProps) => {
  const [paramGroup, setParamGroup] = useState<any>([]);
  const [dataFormStandard, setDataFormStandard] = useState<any>([]);
  const clearModal = () => {
    handleClose();
  };
  const fetchData = async () => {
    try {
      const response = await getSettingQualityAssessment();
      const { data } = response.data;
      setDataFormStandard(data);
    } catch (error) { }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCheckBox = (e: any) => {
    const { value, checked } = e.target;
    if (checked) {
      setParamGroup([...paramGroup, value]);
    } else if (!checked && paramGroup?.includes(value)) {
      const newData = paramGroup.filter((item: string) => item !== value);
      setParamGroup(newData);
    }
  };

  const handleUseForm = async () => {
    try {
      await createFormPublishGroupQualityAssessment({
        group_ids: paramGroup,
      });
      handleRefresh();
      clearModal();
      handleNotice("success", "Thêm tiêu chí mẫu thành công");
    } catch (error) { }
  };
  return (
    <Modal
      className="lg:!w-[700px]"
      title=""
      closeIcon={false}
      centered
      open={isOpen}
      onOk={handleUseForm}
      onCancel={clearModal}
      footer={
        <div className="flex justify-end">
          <Button className="px-2 mr-2" onClick={clearModal}>
            Hủy
          </Button>
          <Button
            className="px-2"
            disabled={numberTab === "2"}
            type="primary"
            onClick={handleUseForm}
          >
            Sử dụng tiêu chí mẫu
          </Button>
        </div>
      }
    >
      <div className="max-h-[650px] overflow-auto">
        <p className="pb-4">Bạn có thể chọn nhiều tiêu chí để sử dụng</p>
        {dataFormStandard?.map((data: any) => (
          <div className="w-full border rounded-md p-4 mb-2" key={data?.id}>
            <Flex className="border-b pb-2" justify="space-between">
              <Flex align="center" className="w-full">
                <Checkbox
                  onChange={(e) => handleCheckBox(e)}
                  className="flex items-center !w-[initial]"
                  value={data?.id}
                />
                <Title level={5} className="pl-2 !mb-0">
                  {data?.title}
                </Title>
              </Flex>
            </Flex>
            <div className="pl-8 border-b">
              {data?.mooc_quality_assessment_form_field?.map(
                (item: { title: string }) => (
                  <div className="p-4 border-l border-b">
                    <Text>{item?.title}</Text>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ModalQualityForm;
