import { Button, Col, Row, Space, Typography } from "antd";
import { TypeChapterList } from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import DetailChapter from "../../../mooc-course-construction-plan/detail-course-plan/component/course-structure/detail-chapter/DetailChapter";
import { PlusOutlined } from "@ant-design/icons";

interface CourseStructureProps {
    dataCourseStructure: TypeChapterList[];
    setDataCourseStructure: (data: TypeChapterList[]) => void;
}

const CourseStructureApprovalCourseRegister: React.FC<CourseStructureProps> = ({
    dataCourseStructure,
    setDataCourseStructure,
}) => {

    return (
        <Space size="middle" direction="vertical" style={{width:"100%"}}>
        <CustomCard>
            <Row justify="space-between" style={{ marginTop: "20px" }}>
            <Col>
                <Row>
                <Typography.Title level={4}>Cấu trúc khóa học</Typography.Title>
                </Row>
                <Row>
                <Typography.Text style={{ color: "GrayText" }}>
                    Xây dựng cấu trúc của khoá học
                </Typography.Text>
                </Row>
            </Col>
            <Col>
                {/* <Button onClick={handleToggleShowDetailAll}>
                {isShowDetailAll ? "Thu gọn" : "Hiển thị chi tiết"}
                </Button> */}
            </Col>
            </Row>
        </CustomCard>

        <DetailChapter
            // chapterList={chapterList}
            chapterList={dataCourseStructure}
            // updateChapterList={updateChapterList}
            // isShowDetailAll={isShowDetailAll}
            // setIsShowDetailAll={setIsShowDetailAll}
        />

        {/* thêm chương */}
        <>
            <Button
            type="primary"
            // onClick={handleAddNewChapter}
            className="w-full button-sticky"
            >
            <PlusOutlined />
            Thêm chương
            </Button>
        </>
        </Space>
    )
}

export default CourseStructureApprovalCourseRegister;