// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-editor.view .gjs-cv-canvas .gjs-toolbar-items {
  display: none; }
`, "",{"version":3,"sources":["webpack://./src/pages/course-construction/course-browse/course-approval/course-approval/introduction-page/index.scss"],"names":[],"mappings":"AACA;EAGQ,aAAa,EAAA","sourcesContent":["// Setting view\n.my-editor.view {\n    .gjs-cv-canvas {\n      .gjs-toolbar-items {\n        display: none;\n      }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
