import { Button, Checkbox, CheckboxProps, Col, message, Radio, Row, Typography, Upload } from 'antd';
import { UploadProps } from 'antd/lib';
import { HelpService } from '../../../../service/helper.service';
import { isEmpty } from 'lodash';
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea, { TextAreaProps } from 'antd/es/input/TextArea';
import { confirmMessage } from '../../../../pages/page-builder/grapesjs-service';
import { useEffect, useState } from 'react';
import { ImageAnswerInfo } from '../../../../types/course-construction';
import { useAddLesson, useAuthStore } from '../../../../stores/stores';
import { FileType } from '../../../../utils/uploadImage';
import { DEFAULT_MAX_LENGTH_COURSE_TEXTAREA, initialQuestionSettingValue, TypeCreateQuestion } from '../../../../constants';
import { BASE_URL_V2 } from '../../../../config/api/configApiv2';

type IProp = {
  data: ImageAnswerInfo;
  handelUpdateAnswer: (data: ImageAnswerInfo, typeAction: number) => void;
  valueRadio: any,
};

const QuestionImageItem = (props: IProp) => {
  const { handelUpdateAnswer, valueRadio } = props;
  const { Text, Link } = Typography;
  const helpService = new HelpService();

  const accessToken = useAuthStore((state) => state.accessToken);

  const settingQuestion = useAddLesson((state) => state.settingQuestion);
  const typeCreateQuestion = useAddLesson((state) => state.typeCreateQuestion);

  const [loadingUpload, setLoadingUpload] = useState(false);
  const [dataAnswer, setDataAnswer] = useState<ImageAnswerInfo>({} as ImageAnswerInfo);
  const [disableChecked, setDisableChecked] = useState<boolean>(false);

  useEffect(() => {
    setDataAnswer(props.data);
  }, [props.data]);

  useEffect(() => {
    if (typeCreateQuestion === TypeCreateQuestion.COURSEWARE) {
      if (Object.keys(settingQuestion).length > 0) {
        setDisableChecked(!settingQuestion.canEnterAnswer);
      } else {
        setDisableChecked(!initialQuestionSettingValue.canEnterAnswer);
      }
    }
    if (typeCreateQuestion === TypeCreateQuestion.SURVEY) {
      setDisableChecked(false);
    }
  }, [settingQuestion]);

  const handleBeforeUpload = (file: FileType) => {
    setLoadingUpload(true);
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      helpService.errorMessage('Bạn phải tải lên file JPG hoặc PNG !');
      setTimeout(() => {
        setLoadingUpload(false);
      }, 333);
    }
    if (file.size / 1024 / 1024 > 25) {
      message.error("File tải lên không vượt quá 25mb");
      setTimeout(() => {
        setLoadingUpload(false);
      }, 300)
    }
    return isJpgOrPng;
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    setTimeout(() => {
      setLoadingUpload(false);
    }, 333);
    if (info.file.status === 'error') {
      helpService.errorMessage('Xảy ra lỗi khi tải ảnh lên !');
    }
    if (info.file.status === 'done') {
      helpService.successMessage('Tải ảnh lên thành công !');
      const saveAnswer = dataAnswer;
      saveAnswer.filePath = info.file.response?.filePath;
      saveAnswer.fileName = info.file.response?.fileName;
      setDataAnswer(saveAnswer);
      handelUpdateAnswer(saveAnswer, 1);
    }
  };

  const handleDeleteAnswer = () => {
    confirmMessage('Xóa lựa chọn', 0, 'Bạn có chắc muốn xóa lựa chọn này ?', () => {
      handelUpdateAnswer(dataAnswer, 0);
    });
  }

  const handleChangeText: TextAreaProps['onChange'] = (e) => {
    const saveAnswer = dataAnswer;
    saveAnswer.value = e.target.value;
    setDataAnswer(saveAnswer);
    handelUpdateAnswer(saveAnswer, 2);
  };

  const handleChangeDisplay: CheckboxProps['onChange'] = (e) => {
    const saveAnswer = dataAnswer;
    saveAnswer.isDisplayContent = !e.target.checked;
    setDataAnswer(saveAnswer);
    handelUpdateAnswer(saveAnswer, 3);
  };

  return (
    <>
      <section className='answer'>
        <Radio value={valueRadio} disabled={disableChecked}></Radio>
        <Upload
          listType='picture-card'
          className='avatar-uploader ml-2 mb-1'
          showUploadList={false}
          action={BASE_URL_V2 + '/mooc-course-block-quiz/upload-file'}
          headers={{ Authorization: 'Bearer ' + accessToken }}
          beforeUpload={handleBeforeUpload}
          onChange={handleChange}
        >
          {
            loadingUpload ? (
              <div className='upload-content'><LoadingOutlined /></div>
            ) : (
              !isEmpty(dataAnswer.filePath) ? (
                < img className='image-present' src={dataAnswer.filePath ?? undefined} alt='image' />
              ) : (
                <div className='upload-content'><PlusOutlined /><span>Tải lên</span></div>
              )
            )
          }
        </Upload>

        <div className='mb-3 ml-2 form-value w-full'>
          <Row>
            <Col span={24} className='form-value__header'>
              <Text>Câu trả lời {dataAnswer.id}</Text>
              <Checkbox checked={dataAnswer.isDisplayContent} onChange={handleChangeDisplay}>Hiển thị nội dung mô
                tả</Checkbox>
            </Col>
            <Col span={24}>
              <TextArea
                onChange={handleChangeText}
                value={dataAnswer.value}
                showCount
                placeholder="Nhập nôi dung mô tả"
                maxLength={DEFAULT_MAX_LENGTH_COURSE_TEXTAREA}
                autoSize={{ minRows: 1, maxRows: 10 }} />
            </Col>
          </Row>
        </div>
        <Button className='btn-delete-item ml-2' onClick={() => {
          handleDeleteAnswer()
        }} type='default' icon={<DeleteOutlined />} />
      </section>
    </>
  )
}
export default QuestionImageItem;
