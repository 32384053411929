// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-blog-content-drawer .avatar-uploader img {
  height: 100%;
  object-fit: cover; }
`, "",{"version":3,"sources":["webpack://./src/components/drawer/add-blog-content/index.scss"],"names":[],"mappings":"AAAA;EAGM,YAAY;EACZ,iBAAiB,EAAA","sourcesContent":[".add-blog-content-drawer {\n  .avatar-uploader {\n    img {\n      height: 100%;\n      object-fit: cover;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
