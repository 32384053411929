import { Badge, Col, Form, Modal, Row, TableColumnsType } from 'antd'
import React, { useEffect, useState } from 'react'
import { getListLecturersAssigns } from '../../service/assign-course-construction'
import { AssignedSponsor, DataType } from '../../types/assign-course-construction'
import FormItemDropdown from '../form-dropdown/FormItemDropdown'
import TableData from '../table-data/TableData'

type Props = {
  open: boolean
  clearForm: boolean
  title: string
  handleOk: () => void
  handleCancel: () => void
  selectedRowKeys: React.Key[]
}

const validations = {
  lecturerName: {
    required: true,
    message: "Vui lòng chọn giảng viên",
  },
}

const dataColumn: TableColumnsType<DataType> = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    width: 3,
    render: (_: any, record, index) => {
      return (
        <div>
          {index + 1}
        </div>
      )
    },
  },
  {
    title: 'Tên khoá học',
    dataIndex: 'name',
    key: 'name',
    width: 10
  },
  {
    title: 'Trạng thái',
    dataIndex: 'dateConfirm',
    key: 'dateConfirm',
    width: 10,
    render: (_: any, record) => {
      return (
        <div>
          <Badge key={"gray"} color={"gray"} className='mr-5' />
          Chờ phân công
        </div>
      )
    },
  },
]


export default function CourseAssignmentModal(props: Props) {
  const { open, title, handleOk, handleCancel, selectedRowKeys, clearForm } = props
  const [form] = Form.useForm()
  const [listLecturers, setListLecturers] = useState<AssignedSponsor[]>([])

  const onOk = () => {
    form.submit()
  }

  const getDataLecturer = async () => {
    await getListLecturersAssigns().then((res) => {
      setListLecturers(res.data.data)
    })
  }

  useEffect(() => {
    if (clearForm) {
      form.resetFields()
    }
  }, [clearForm])

  useEffect(() => {
    getDataLecturer()
  }, [])

  return (
    <Modal title={title} open={open} onOk={onOk} onCancel={handleCancel} centered okText="Lưu" cancelText="Huỷ">
      <Form layout={"vertical"} form={form} name="create-course-plan-modal">
        <Row gutter={16}>
          <Col span={24}>
            <TableData dataTable={selectedRowKeys} dataColumns={dataColumn}
              handleTableChangeProps={() => { }} pagination={false} />
          </Col>
          <Col span={24}>
            <Form.Item name="lecturerName" label="Tên giảng viên" rules={[validations.lecturerName]} hasFeedback>
              <FormItemDropdown modeDropdown="multiple" options={listLecturers} allowClear={true}
                placeholder="Chọn giảng viên"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}
