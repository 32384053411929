// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey_name {
  color: rgba(0, 0, 0, 0.45); }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey/components/form-create-survey/form-create-survey.scss"],"names":[],"mappings":"AAAA;EACI,0BAA0B,EAAA","sourcesContent":[".survey_name{\n    color: rgba(0, 0, 0, 0.45);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
