export const optionsDropDownQuestionType = [
  {
    type: 1,
    value: "Nhiều lựa chọn văn bản",
    label: "Nhiều lựa chọn văn bản",
  },
  {
    type: 2,
    value: "Nhiều lựa chọn ảnh",
    label: "Nhiều lựa chọn ảnh",
  },
  {
    type: 3,
    value: "Lựa chọn đúng sai",
    label: "Lựa chọn đúng sai",
  },
  {
    type: 4,
    value: "Lựa chọn Dropdown",
    label: "Lựa chọn Dropdown",
  },
  {
    type: 5,
    value: "Nhiều trả lời văn bản",
    label: "Nhiều trả lời văn bản",
  },
  {
    type: 6,
    value: "Nhiều trả lời ảnh",
    label: "Nhiều trả lời ảnh",
  },
  {
    type: 7,
    value: "Câu hỏi kết nối",
    label: "Câu hỏi kết nối",
  },
  {
    type: 8,
    value: "Câu trả lời ngắn",
    label: "Câu trả lời ngắn",
  },
];
