import {FunctionComponent, useEffect, useState} from "react";
import {Layout, Tabs, TabsProps} from "antd";
import {useLocation} from "react-router-dom";
import PageHeader from "../../../../components/page-header/PageHeader";
import SurveyPlanInfo from "../../../../components/course-construction/survey-plan/survey-detail/plan-info/PlanInfo";
import PlanDetail from "../../../../components/course-construction/survey-plan/survey-detail/plan-detail/PlanDetail";
import OperationHistory
  from "../../../../components/course-construction/survey-plan/survey-detail/operation-history/OperationHistory";
import ActiveSetting
  from "../../../../components/course-construction/survey-plan/survey-detail/active-setting/ActiveSetting";
import {getSurveyPlanById} from "../../../../service/course-construct/survey-plan";
import SafeLink from "../../../../components/link/SafeLink";
import {routesConfig} from "../../../../config/routes";
import SurveyQuestionTab from "../../../../components/course-construction/survey-plan/survey-detail/survey-question";

const {Content} = Layout;
const imgPath = "/images/";

var indexDB = require("idbcache").default;

interface QuizLayoutProps {
  id?: number;
  code?: string;
  name?: string;
  surveyMethod?: boolean;
  timeStart?: string;
  timeEnd?: string;
  description?: string;
  targetType?: number;
  targetValue?: string;
  targetDescription?: string;
  applicatbleObjectKey?: boolean;
  applicatbleObjectSchools?: string;
  applicatbleCourseRegister?: null;
  applicatbleCourseCompleted?: null;
  applicatbleIndustry?: null;
  isDeleted?: boolean;
  statusSurvey?: string;
  createdDate?: null;
  createdBy?: null;
}

const SurveyPlanDetail: FunctionComponent = () => {
  const location = useLocation();
  const [isShowSubmitButton, setIsShowSubmitButton] = useState(true);
  let idSurveyPlan = '';
  const {id} = location.state.record
  const [surveyPlan, setSurveyPlan] = useState<QuizLayoutProps>();

  const [tab, setTab] = useState<string>("")


  const getSurveyPlan = async (id: number) => {
    try {
      const res = await getSurveyPlanById(id);
      setSurveyPlan(res.data.data);
    } catch (error) {
      console.error();
    }
  };


  const currentUrl = window.location.href;

  // Parse the URL using the URL object
  const url = new URL(currentUrl);

  // Split the pathname into segments
  const pathSegments = url.pathname.split("/");

  // The last segment will be the last digit you want
  const lastDigit = pathSegments.pop();


  indexDB.get("current-course").then((val: any) => {
    idSurveyPlan = (JSON.parse(val).isEdit)
  });


  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Thông tin kế hoạch",
      children: (
        <>
          <SurveyPlanInfo surveyPlan={surveyPlan}/>
        </>
      ),
    },
    {
      key: "2",
      label: "Kế hoạch chi tiết",
      children: (
        // change children here
        <PlanDetail surveyPlan={surveyPlan}/>
      ),
    },
    {
      key: "3",
      // icon: <LockOutlined />,
      label: "Cài đặt hoạt động",
      children: (
        // change children here
        <ActiveSetting data={surveyPlan}/>
      ),
    },
    {
      key: "4",
      label: "Lịch sử thao tác",
      children: (
        // change children here
        <OperationHistory surveyPlan={surveyPlan}/>
      ),
    },
    {
      key: "5",
      label: "Câu hỏi khảo sát",
      children: (
        // change children here
        <SurveyQuestionTab id={id} tab={tab}/>
      ),
    },
  ];

  const listBreadcrumb = [
    {
      title: "Xây dựng khóa học",
    },
    {
      title: <SafeLink to={`/${routesConfig.courseConstruction}/${routesConfig.surveyPlan}`}>
        <span>Kế hoạch khảo sát</span>
      </SafeLink>,
    },
    {
      title: surveyPlan?.name,
    },
  ];

  // Handle change tabs
  const handleChange = (activeKey: string) => {
    setTab(activeKey)
    getSurveyPlan(Number(lastDigit));
    setIsShowSubmitButton(true);
  };

  // useEffect
  useEffect(() => {
    // if(location.state.idEdit!==undefined) idSurveyPlan = location.state.idEdit
    getSurveyPlan(Number(lastDigit));
  }, [lastDigit]);

  return (
    <>
      <Content>
        <PageHeader
          title={surveyPlan?.name ? surveyPlan?.name : ""}
          listBreadcrumb={listBreadcrumb}
          arrowLeft={imgPath + "arrowLeft.svg"}
          positionItem="column"
          breadcrumb={true}
          headerGroupButton={isShowSubmitButton}
        />
        <div className="tabs-heading">
          <Tabs defaultActiveKey="1" onChange={handleChange} items={items}/>
        </div>
      </Content>
    </>
  );
};

export default SurveyPlanDetail;
