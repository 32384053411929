import { FunctionComponent } from "react";
import "./login.scss";
import CustomCarousel from "../../components/carousel/Carousel";
import LoginPage from "./components/login-page/LoginPage";

const Login: FunctionComponent = () => {
  return (
    <div className="login">
      <div className="left">
        <CustomCarousel />
      </div>
      <div className="login-container">
        <div className="checkbox-and-button">
          <LoginPage />
          <div className="copyright-2024-b">
            Copyright ©2024 BỘ GIÁO DỤC VÀ ĐÀO TẠO
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
