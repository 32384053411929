// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-success-otp {
  font-size: 40px;
  color: #52C41A; }

.success-message-otp {
  font-size: 16px;
  margin-top: 12px;
  margin-bottom: 4px; }
`, "",{"version":3,"sources":["webpack://./src/components/captcha/custom-captcha.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,cACJ,EAAA;;AACA;EACI,eAAe;EACf,gBAAgB;EAChB,kBAAkB,EAAA","sourcesContent":[".icon-success-otp{\n    font-size: 40px;\n    color: #52C41A\n}\n.success-message-otp{\n    font-size: 16px;\n    margin-top: 12px;\n    margin-bottom: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
