// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-type-noti {
  display: flex;
  gap: 10px;
  margin-bottom: 24px; }
  .section-type-noti .title {
    margin-top: 0; }
  .section-type-noti .ant-form-item {
    margin-bottom: 8px; }
  .section-type-noti .ant-form-item-control-input {
    min-height: 0; }

.type-noti-wrapper {
  display: flex;
  gap: 16px; }
  .type-noti-wrapper .title {
    margin-top: 0; }
  .type-noti-wrapper .form-item--type_noti > .ant-form-item {
    margin-bottom: 8px; }
  .type-noti-wrapper .ant-checkbox-wrapper {
    margin-bottom: 8px; }
  .type-noti-wrapper .wrapper-radio {
    padding: 0; }
  .type-noti-wrapper .ant-row {
    flex-flow: row nowrap; }
    .type-noti-wrapper .ant-row .ant-form-item-control {
      max-width: 558px; }

.option-types .ant-checkbox-group {
  flex-direction: column; }
`, "",{"version":3,"sources":["webpack://./src/components/course-construction/content-course-construction/setting-content-course/notification-setting/index.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB,EAAA;EAHrB;IAMI,aAAa,EAAA;EANjB;IAUI,kBAAkB,EAAA;EAVtB;IAcI,aAAa,EAAA;;AAIjB;EACE,aAAa;EACb,SAAS,EAAA;EAFX;IAKI,aAAa,EAAA;EALjB;IAUM,kBAAkB,EAAA;EAVxB;IAeI,kBAAkB,EAAA;EAftB;IAmBI,UAAU,EAAA;EAnBd;IAuBI,qBAAqB,EAAA;IAvBzB;MA0BM,gBAAgB,EAAA;;AAKtB;EACE,sBAAsB,EAAA","sourcesContent":[".section-type-noti {\n  display: flex;\n  gap: 10px;\n  margin-bottom: 24px;\n\n  .title {\n    margin-top: 0;\n  }\n\n  .ant-form-item {\n    margin-bottom: 8px;\n  }\n\n  .ant-form-item-control-input {\n    min-height: 0;\n  }\n}\n\n.type-noti-wrapper {\n  display: flex;\n  gap: 16px;\n\n  .title {\n    margin-top: 0;\n  }\n\n  .form-item--type_noti {\n    > .ant-form-item {\n      margin-bottom: 8px;\n    }\n  }\n\n  .ant-checkbox-wrapper {\n    margin-bottom: 8px;\n  }\n\n  .wrapper-radio {\n    padding: 0;\n  }\n\n  .ant-row {\n    flex-flow: row nowrap;\n\n    .ant-form-item-control {\n      max-width: 558px;\n    }\n  }\n}\n\n.option-types .ant-checkbox-group {\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
