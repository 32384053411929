// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-table--chart {
  margin-bottom: 16px; }
  .section-table--chart .table-wrapper {
    padding: 0; }
    .section-table--chart .table-wrapper .ant-table-cell {
      padding: 16px !important; }
`, "",{"version":3,"sources":["webpack://./src/pages/statistical-report/for-ministry/components/table/overview-table/index.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB,EAAA;EADrB;IAII,UAAU,EAAA;IAJd;MAOM,wBAAwB,EAAA","sourcesContent":[".section-table--chart {\n  margin-bottom: 16px;\n\n  .table-wrapper {\n    padding: 0;\n\n    .ant-table-cell {\n      padding: 16px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
