import { FunctionComponent, useEffect, useMemo, useState } from "react";
import "./index.scss";
import DetailInfo from "../../detail/components/detail-info/DetailInfo";
import { Layout, TableColumnsType, TableProps, Tabs, TabsProps } from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import { useParams } from "react-router-dom";
import { HistoryOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
// import { DetailContent } from "../components/detail-content/DetailContent";
import UserDocument from "../../detail/components/user-document/UserDocument";
import { useAppStore } from "../../../stores/stores";
import { get } from "lodash";
import { getAccountDetail } from "../../../service/list-account-users/listAccountUsersApi";
import { useLocation } from "react-router-dom";
import { routesConfig } from "../../../config/routes";
import PlanInformation from "../../../components/course-construction/assign-course-construction/plan-information";
import DetailedAssignment from "../../../components/course-construction/assign-course-construction/detailed-assignment";
import BrowsingResults from "../../../components/course-construction/assign-course-construction/browsing-results";
import TableData from "../../../components/table-data/TableData";
import { HistoryData } from "../../../types/assign-course-construction";
import { MockDataAssignCourseConstruction } from "../../../components/course-construction/assign-course-construction/mock-data";
import { getHistoryCourse } from "../../../service/assign-course-construction";
import { formatDate, formatDateToIsoString, formatDateToIsoStringHHss } from "../../../utils/converDate";

const { Content } = Layout;
const imgPath = '/images/';

const HistoryCourseConstruction: FunctionComponent = () => {
	const location = useLocation()
	const [pageNumber, setPageNumber] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [totalRecords, setTotalRecords] = useState<number>(0)
	const [history, setHistory] = useState<HistoryData[]>([])
	const [loading, setLoading] = useState<boolean>(true)

	const { accountInfo, setAccountDetail } = useAppStore((state) => {
		return {
			accountInfo: state?.accountInfo,
			setAccountDetail: state?.setAccountDetail
		}
	})

	const params = useParams()

	const getHistoryCourseData = async () => {
		setLoading(true)
		const data = {
			keyword: "",
			pageNumber: pageNumber,
			pageSize: pageSize,
			coursePlanId: location.state.id
		}

		await getHistoryCourse(data).then(res => {
			const { data } = res.data
			setHistory(data.data)
			setTotalRecords(data.totalRecords)
			setTimeout(() => {
				setLoading(false)
			}, 500);
		})
			.catch(err => {
				setLoading(false)
			})
	}

	useEffect(() => {
		getHistoryCourseData()
	}, [pageNumber])

	const listBreadcrumb = [
		{
			title: 'Xây dựng khoá học',
		},
		{
			title: <a href={`/course-construction/${routesConfig.assignCourseConstruction}`}>Phân công xây dựng khoá học</a>,
		},
		{
			title: location.state.name,
		},
	]

	const dataColumns: TableColumnsType<any> = [
		{
			title: 'STT',
			dataIndex: 'stt',
			key: 'stt',
			width: 10,
			shouldCellUpdate(record, prevRecord) {
				return true
			},
			render: (_: any, record, index) => {
				return (
					<div>
						{record?.numericalOrder}
					</div>
				)
			},

		},
		{
			title: 'Tên hoạt động',
			dataIndex: 'name',
			key: 'name',
			width: 80,
		},
		{
			title: 'Thời gian',
			dataIndex: 'date',
			key: 'date',
			width: 30,
			render: (_: any, record, index) => {
				return (
					<div>
						{formatDateToIsoStringHHss(record?.date)}
					</div>
				)
			},
		},
		{
			title: 'Mô tả',
			dataIndex: 'description',
			key: 'description',
			width: 40,
		}
	]

	const defaultCheckedList = dataColumns.map((item) => item.key as string)

	const [checkedList, setCheckedList] = useState(defaultCheckedList)

	const newColumnsAsigned = useMemo(() => {
		return dataColumns.map((item) => ({
			...item,
			hidden: !checkedList.includes(item.key as string)
		}))
	}, [checkedList])

	const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter) => {

	};

	return (
		<>
			<Content>
				<PageHeader title={location.state.name} subTitle="Lịch sử hoạt động" listBreadcrumb={listBreadcrumb} arrowLeft={imgPath + 'arrowLeft.svg'}
					positionItem="column"
					breadcrumb={true}
				/>
				<TableData dataTable={history} dataColumns={newColumnsAsigned}
					setPageNumber={setPageNumber}
					setPageSize={setPageSize}
					pageNumber={pageNumber}
					pageSize={pageSize}
					loadingTable={loading}
					scroll={{ x: 1500, y: 600 }}
					totalRecords={totalRecords}
					handleTableChangeProps={handleTableChange}
					hideSelection={false}
					pagination={true}
				/>
			</Content>
		</>
	);
};

export default HistoryCourseConstruction;
