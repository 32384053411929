import { getPermission } from "../service/common";

export const CheckRole = (listRoleCheck: string[], userRole: string[]) => {
  const role = listRoleCheck.map((item) => {
    const arr = userRole.includes(item);
    return arr;
  });
  return role;
};

export const getPermissions = async () => {
  try {
    const res = await getPermission();
    if (res && res.data && res.data.data && res.data.data.permissions) {
      // console.log(`res.data.data.permissions:::`, res.data.data.permissions);
      // setListPermission(res.data.data.permissions);
      return res.data?.data?.permissions;
      // setListPermission(res.data.data.permissions)
    }
  } catch (error) {}
};
