import { Button, Card, Layout, List, Modal, Typography } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import JSZip from 'jszip';
import InformationPlan from './InformationPlan';
import ModalProgressBar from './ModalProgressBar';
import { getMoocCourseConstructionPlanById } from '../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi';
import { DetailTypeMoocCourseConstructionPlan } from '../../../../types/course-plan-managment/mooc-course-construction-plan';

interface ModalDownloadInformationPlanProps {
  isOpen?: boolean;
  setIsOpen?: any;
  isShowModalDetailDownload?: any;
  setIsShowModalDetailDownload: any;
  detailCourseConstruct: DetailTypeMoocCourseConstructionPlan;
}

const ModalDownloadInformationPlan = (props: any) => {
  const {
    isOpen,
    setIsOpen,
    isShowModalDetailDownload,
    setIsShowModalDetailDownload,
    detailCourseConstruct } = props
  const refFilePdf = useRef<HTMLInputElement>(null)
  const [isOpenModalProgressBar, setIsOpenModalProgressBar] = useState<boolean>(false)
  const [linkDownload, setLinkDownload] = useState<HTMLAnchorElement>()
  // const [detailCourseConstruct, setDetailCourseConstruct] = useState<DetailTypeMoocCourseConstructionPlan>()
  const handleCancel = () => {
    setIsShowModalDetailDownload(false)
  }

  const handleOk = async () => {
    setIsOpenModalProgressBar(true)
    try {


      const input: any = refFilePdf.current
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4'
      })

      html2canvas(input, {
        scale: 10,
      }).then(async function (canvas) {
        const width = pdf.internal.pageSize.getWidth();
        const height = (canvas.height * width) / canvas.width;
        var data = canvas.toDataURL("image/png");
        pdf.addImage(data, "PNG", 0, 0, width, height);
        const zip = new JSZip();
        const folder = zip.folder('pdf_files');

        if (folder) {
          folder.file('document.pdf', pdf.output('blob'));

          const zipBlob = await zip.generateAsync({ type: 'blob' });

          const link = document.createElement('a');
          link.href = URL.createObjectURL(zipBlob);
          console.log(link.href)
          setLinkDownload(link)
          // link.download = 'pdf_documents.zip';
          // document.body.appendChild(link);
          // link.click();
          // document.body.removeChild(link);
          console.log({ linkDownload })


        }
      });
    } catch (e: any) {
      console.log(e)
    }
  }

  // const fetDetailCourseConstruct = async () => {
  //   const res = await getMoocCourseConstructionPlanById(courseConstructPlanId)
  //   setDetailCourseConstruct(res.data.data)
  //   console.log({ res })
  // }

  // useEffect(() => {
  //   fetDetailCourseConstruct()
  // }, [courseConstructPlanId])
  return (
    <>
      <Modal open={isOpen}
        width={800}
        title="Xem trước thông tin chung đăng ký kế hoạch"
        style={{ fontSize: 16, fontWeight: 500, lineHeight: '24px', fontFamily: 'Roboto' }}
        centered={true}

        onOk={handleOk}
        okText='Tải xuống'
        onCancel={handleCancel}
        cancelText="Huỷ"
      >
        <div ref={refFilePdf}>
          <InformationPlan detailCourseConstruct={detailCourseConstruct} />

        </div>
      </Modal>
      {isOpenModalProgressBar && (
        <ModalProgressBar
          isOpen={isOpenModalProgressBar}
          setIsOpen={setIsOpenModalProgressBar}
          isOpenModalFather={isOpen}
          setIsOpenModalFather={setIsOpen}
          refFilePdf={refFilePdf}
          link={linkDownload}
          detailCourseConstruct={detailCourseConstruct}
        />
      )}
    </>

  );

}

export default ModalDownloadInformationPlan