import {
  Avatar,
  Button,
  Dropdown,
  Flex,
  Form,
  Layout,
  MenuProps,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
  message,
} from "antd";
import { useEffect, useMemo, useState } from "react";

import { Link, useNavigate, useParams } from "react-router-dom";

import {
  CommentOutlined,
  DeleteOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MoreOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";

import { isEmpty } from "lodash";
import moment from "moment";
import DrawerCustom from "../../../../components/drawer/Drawer";
import CommonModal from "../../../../components/modal/common";
import TableData from "../../../../components/table-data/TableData";
import { routesConfig } from "../../../../config/routes";
import {
  DEFAULT_EXPORT_SCORE,
  DEFAULT_PERMISSION_AUTO_SCORE,
} from "../../../../constants/automatic-mark";
import {
  getListStudentExam,
  getManagementAutoScore,
  getShareAutoScore,
} from "../../../../service/auto-score";
import { exportData } from "../../../../utils/exportData";
import { generateDocx } from "../../../../utils/exportDataDocx";
import DeleteScoreModal from "./components/score-modal";
import ListScoreShare from "./components/score-share";

interface IProps {
  searchValue: string;
  refreshTag: boolean;
  permissionScore: string[];
}

const { DECENTRALIZE, SHARE, GET } = DEFAULT_PERMISSION_AUTO_SCORE;

const ManagementTableList = ({
  searchValue,
  refreshTag,
  permissionScore,
}: IProps) => {
  const params = useParams();

  const [listScore, setListScore] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);

  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);
  const [isExport, setIsExport] = useState(false);
  const [score, setScore] = useState({
    idScore: "",
    title: "",
  });
  const [isOpenShare, setIsOpenShare] = useState<boolean>(false);
  const [isOpenConfirmShare, setIsOpenConfirmShare] = useState<boolean>(false);
  const [usersShare, setUsersShare] = useState({
    group_ids: [],
    user_ids: [],
    is_view: true,
    is_edit: false,
    is_remove: false,
    notification: "system",
  });
  const [isNoticeValidate, setIsNoticeValidate] = useState<boolean>(false);
  const [idCourse, setIdCourse] = useState(0);

  const [valueTypeExport, setValueTypeExport] = useState<string>(".csv");

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getManagementAutoScore(searchParam);
      const { data } = response.data;
      setListScore(data?.data);
      setTotalPage(data?.count?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData({
      search: searchValue,
      course_block_id: params?.id,
      take: pageSize,
      skip: (pageNumber - 1) * 10,
    });
  }, [pageSize, pageNumber, isConfirmDelete, searchValue, refreshTag]);

  const onCloseModal = () => {
    setIsOpenDelete(false);
    setIsConfirmDelete(false);
    setIsExport(false);
    setIsOpenConfirmShare(false);
    setIsOpenShare(false);
  };
  const handleNotice = (message: string) => {
    messageApi.open({
      type: "success",
      content: message,
    });
  };
  const handleNoticeError = (message: string) => {
    messageApi.open({
      type: "error",
      content: message,
    });
  };
  const handleOpenDelete = (idScore: string, title: string) => {
    setIsOpenDelete(true);
    setScore({
      idScore: idScore,
      title: title,
    });
  };
  const handleOpenShareDraw = async (
    idScore: string,
    title: string,
    idCourse: number
  ) => {
    try {
      const res = await getShareAutoScore(idScore);
      const { data } = res.data;
      const permissionData = data.mooc_report_score_share_permission.map(
        (value: any) => value.permission_id
      );
      const userData = data.mooc_report_score_share_user.map(
        (value: any) => value.user_id
      );
      setScore({
        idScore: data?.id,
        title: title,
      });
      setIdCourse(idCourse);
      setUsersShare({
        group_ids: permissionData,
        user_ids: userData,
        is_view: data?.is_view,
        is_edit: data?.is_edit,
        is_remove: data?.is_remove,
        notification: data?.notification,
      });
    } catch (error) {
      console.log(error);
    }
    setIsOpenShare(true);
  };

  const handleConfirmDelete = () => {
    setIsOpenDelete(false);
    setIsConfirmDelete(true);
  };
  const handleOpenShare = () => {
    if (isEmpty(usersShare?.group_ids) && isEmpty(usersShare?.user_ids)) {
      setIsNoticeValidate(true);
      setIsOpenConfirmShare(false);
    } else {
      setIsOpenConfirmShare(true);
    }
  };
  const onChangeExport = (e: RadioChangeEvent) => {
    setValueTypeExport(e.target.value);
  };

  const handleExport = (idScore: string, title: string) => {
    setIsExport(true);
    setScore({
      idScore: idScore,
      title: title,
    });
  };
  const handleConfirmExport = async () => {
    let scoreExport = [];
    let fileName = "";
    const param = {
      search: searchValue,
      skip: 0,
      take: 100,
      sort: "desc",
    };
    try {
      const response = await getListStudentExam(param, Number(score?.idScore));
      const { data } = response.data;
      fileName = data.name;
      scoreExport = data?.mooc_score?.map((data: any, index: number) => ({
        STT: `${index + 1}`,
        [DEFAULT_EXPORT_SCORE.nameSv]: data?.auth_user?.auth_userprofile?.name,
        [DEFAULT_EXPORT_SCORE.date_birthday]: data?.auth_user?.auth_userprofile
          ?.date_of_birth
          ? moment(data?.auth_user?.auth_userprofile?.date_of_birth).format(
              "DD/MM/yyyy"
            )
          : "",
        [DEFAULT_EXPORT_SCORE.email]: data?.auth_user?.email,
        [DEFAULT_EXPORT_SCORE.score]: `${data?.point || 0}`,
      }));

      if (valueTypeExport === ".csv" && !isEmpty(scoreExport)) {
        exportData(scoreExport, fileName, valueTypeExport);
      } else if (valueTypeExport !== ".csv" && !isEmpty(scoreExport)) {
        const headers = [
          "STT",
          "Họ và tên sinh viên",
          "Ngày tháng năm sinh",
          "Email",
          "Điểm số",
        ];
        generateDocx(scoreExport, headers, fileName.replaceAll(".", ""));
      }

      setIsExport(false);
      if (!isEmpty(scoreExport)) {
        handleNotice("Xuất bảng điểm thành công");
      } else {
        handleNoticeError("Bảng điểm không có dữ liệu");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dataColumnsManagementListScore: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      width: 5,
      render: (_: any, record, index) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "Mã bảng điểm",
      dataIndex: "code",
      key: "code",
      width: 10,
    },
    {
      title: "Tên bảng điểm",
      dataIndex: "name",
      key: "reportName",
      width: 10,
    },
    {
      title: "Người chấm",
      dataIndex: "fullName",
      key: "fullName",
      width: 20,
      render: (_, data: any) => {
        return (
          <Flex align="center">
            <Avatar style={{ backgroundColor: "#f56a00" }}>
              {data?.auth_user?.first_name.charAt(0)}
            </Avatar>
            <Typography.Text className="pl-2">
              {data?.auth_user?.first_name + " " + data?.auth_user?.last_name}
            </Typography.Text>
          </Flex>
        );
      },
    },
    {
      title: "Thời gian chấm",
      dataIndex: "created_at",
      key: "created_at",
      width: 10,
      render: (_: any, record) =>
        record?.created_at && (
          <Flex className="gap-1">
            <p>{moment(record?.created_at).format("DD/MM/yyyy")}</p>
            <p>{moment(record?.created_at).format("HH:mm:ss")}</p>
          </Flex>
        ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const idScore = `${record?.id}`;

        const items: MenuProps["items"] = [
          {
            label: (
              <Flex onClick={() => handleExport(idScore, record?.name)}>
                <LogoutOutlined />
                <Typography.Text className="pl-2">
                  Xuất bảng điểm
                </Typography.Text>
              </Flex>
            ),
            key: "1",
          },
          permissionScore?.includes(SHARE)
            ? {
                label: (
                  <Flex
                    onClick={() =>
                      handleOpenShareDraw(
                        idScore,
                        record?.name,
                        record?.mooc_course?.id
                      )
                    }
                  >
                    <ShareAltOutlined />
                    <Typography.Text className="pl-2">
                      Chia sẻ bảng điểm
                    </Typography.Text>
                  </Flex>
                ),
                key: "2",
              }
            : null,
          {
            label: (
              <Link to={`/${routesConfig.rateAutoScore}/${idScore}`}>
                <Flex>
                  <CommentOutlined />
                  <Typography.Text className="pl-2">
                    Đánh giá chất lượng
                  </Typography.Text>
                </Flex>
              </Link>
            ),
            key: "3",
          },
          permissionScore?.includes(GET)
            ? {
                label: (
                  <Flex onClick={() => handleOpenDelete(idScore, record?.name)}>
                    <DeleteOutlined />
                    <Typography.Text className="pl-2">
                      Xoá bảng điểm
                    </Typography.Text>
                  </Flex>
                ),
                key: "4",
              }
            : null,
        ];
        return (
          <Space>
            {[DECENTRALIZE, SHARE, GET].some((element) =>
              permissionScore.includes(element)
            ) && (
              <Link
                className="action-table"
                to={`/${routesConfig.managementScoreDetail}/${idScore}`}
              >
                <EyeOutlined />
              </Link>
            )}
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              className="action-table"
            >
              <a onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsManagementListScore.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsManagementListScore.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleClose = () => {
    setIsOpenShare(false);
    form.resetFields();
  };
  const handleCloseModalShare = () => {
    setIsOpenConfirmShare(false);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      {contextHolder}
      <Form.Provider>
        <TableData
          dataTable={listScore}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
      <DeleteScoreModal
        IsOpenDelete={isOpenDelete}
        handleConfirmDelete={handleConfirmDelete}
        handleNotice={handleNotice}
        idScore={score?.idScore}
        onCloseModal={onCloseModal}
        title={score.title}
        IsConfirmDelete={isConfirmDelete}
      />
      <CommonModal
        open={isExport}
        title={`Xuất bảng điểm sinh viên`}
        desc={
          <Radio.Group onChange={onChangeExport} value={valueTypeExport}>
            <Radio value={".csv"}>Microsoft Excel (.csv)</Radio>
            <Radio value={".doc"}>Microsoft Word (.docx)</Radio>
          </Radio.Group>
        }
        closeIcon={true}
        okText={"Tải xuống"}
        cancelText={"Huỷ"}
        icon={""}
        confirm={handleConfirmExport}
        closeModal={onCloseModal}
        className={""}
      />
      <DrawerCustom
        title={`Chia sẻ bảng điểm`}
        open={isOpenShare}
        onClose={handleClose}
        extraNode={
          <Space>
            <>
              <Button onClick={handleClose} className="btn btn-outlined">
                <Typography.Text>Hủy</Typography.Text>
              </Button>
              <Button
                className="btn btn-primary"
                htmlType="submit"
                onClick={handleOpenShare}
              >
                <Typography.Text>Xác nhận</Typography.Text>
              </Button>
            </>
          </Space>
        }
        widthDrawer={730}
      >
        <ListScoreShare
          isOpenConfirmShare={isOpenConfirmShare}
          onCloseModal={onCloseModal}
          handleCloseModalShare={handleCloseModalShare}
          handleNotice={handleNotice}
          idScore={score?.idScore}
          usersShare={usersShare}
          setUsersShare={setUsersShare}
          title={score.title}
          idCourse={idCourse}
        />
        <Modal
          open={isNoticeValidate}
          title={
            <div>
              <InfoCircleOutlined
                style={{ color: "#FFC53D", marginRight: "10px" }}
              />
              <span className="!font-semibold pl-2">
                Chọn người dùng hoặc nhóm người dùng
              </span>
            </div>
          }
          centered
          closeIcon={true}
          onCancel={() => setIsNoticeValidate(false)}
          footer=""
        >
          <Typography.Text>
            Bạn cần chọn ít nhất một người dùng hoặc nhóm người dùng để chia sẻ
          </Typography.Text>
        </Modal>
      </DrawerCustom>
    </Layout>
  );
};

export default ManagementTableList;
