import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { axiosConfigV2 } from "../../config/api/configApiv2";

export const getListAssign: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/course-plans/search`, data)
}

export const getDetailCourse: (id: string | undefined) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/course-plans/get-by-id/${id}`)
}

export const getHistoryCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/course-plans/history`, data)
}

export const getEnterprises: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/course-plans/enterprises`, data)
}

export const getLecturersFromEnterprises: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfigV2.post(`/mooc-courses/lecturers`, data)
}

export const assignCoursePlan: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/course-plans/assign-course-plan/${id}`, data)
}

export const getListLecturers: (id: number) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/course-plans/lecturers/${id}`)
}

export const updateAssignCourse: (id: number, data: object) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/course-plans/assign-course/${id}`, data)
}

export const revokeAssign: (id: string) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.put(`/course-plans/revoke-assign/${id}`)
}

export const getListLecturersAssigns: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/course-plans/lecturers-of-university`)
}

export const assignMultiCourse: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.put(`/course-plans/assign-course-plans`, data)
}

export const getLecturerAssign: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/course-plans/lecturers-assign`)
}

