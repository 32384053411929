import {
  Card,
  DatePicker,
  Flex,
  Typography
} from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { routesConfig } from "../../../../../config/routes";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const ResultTraining = ({ dataDetail, timeData }: any) => {
  const handleChangeTime = (dateString: any, name: string) => {
    console.log({dateString});
  };
  return (
    <div>
      {dataDetail?.is_training && (
        <Card className="w-full lg:w-2/3 m-auto my-6">
          <div className="border-b pb-3">
            <Title level={4}>Kết quả huấn luyện mô hình</Title>
            <Text className="text-[#00000073]">
              Chọn thông tin để xem chi tiết nội dung
            </Text>
          </div>

          <Flex align="center" className="py-2">
            <Text>Yếu tố thuộc nội dung thông tin đầu vào của sinh viên</Text>
            <Text className="pl-2">{timeData?.input}</Text>
          </Flex>
          <div>
            <Link
              to={`/prediction-model/${routesConfig.profileStudentModel}/${dataDetail?.id}`}
              className="text-[#1677FF] underline decoration-1"
            >
              Thông tin hồ sơ sinh viên
            </Link>
          </div>
          <div>
            <Link
              to={`/prediction-model/${routesConfig.infoCourseListModel}/${dataDetail?.id}`}
              className="text-[#1677FF] underline decoration-1"
            >
              Thông tin khoá học
            </Link>
          </div>
          {/* ------- */}
          <Flex align="center" className="py-2">
            <Text>Yếu tố thuộc nội dung thông tin hành vi của sinh viên</Text>
            <Text className="pl-2">{timeData?.action}</Text>
          </Flex>
          <div>
            <Link
              to={`/prediction-model/${routesConfig.studentBeforeJoin}/${dataDetail?.id}`}
              className="text-[#1677FF] underline decoration-1"
            >
              Hành vi thực hiện trước khi tham gia khoá học
            </Link>
          </div>
          {/* <div>
            <Link
              to={`/prediction-model/${routesConfig.studentJoin}`}
              className="text-[#1677FF] underline decoration-1"
            >
              Hành vi thực hiện khi tham gia khoá học
            </Link>
          </div> */}
          <div>
            <Link
              to={`/prediction-model/${routesConfig.studentAfterJoin}/${dataDetail?.id}`}
              className="text-[#1677FF] underline decoration-1"
            >
              Hành vi thực hiện sau khi tham gia khoá học
            </Link>
          </div>
        </Card>
      )}
    </div>
  );
};

export default ResultTraining;
