import { Button, ConfigProvider, DatePicker, Form, Modal, Select } from "antd";
import locale from "antd/es/locale/vi_VN";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/vi";
import { useState } from "react";
import { staticReportStore } from "../../../../../stores/stores";
import "./OptionTimeModal.scss";

type Props = {
  handleCancel?: () => void;
  open: boolean;
};

const OptionTimeModal = (props: Props) => {
  const { handleCancel, open } = props;
  const [form] = Form.useForm();
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);


  const { setFilterValues } = staticReportStore();

  const handleConfirm = () => {
    form
      .validateFields()
      .then((values) => {
        setFilterValues({
          timeUnit: values.timeType,
          dateFrom: values.dateFrom
            ? values.dateFrom.format("YYYY-MM-DD")
            : null,
          dateTo: values.dateTo ? values.dateTo.format("YYYY-MM-DD") : null,
        });
        // form.resetFields();
        if (handleCancel) handleCancel();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const disabledDateFrom = (current: Dayjs | null) => {
    const dateTo = form.getFieldValue("dateTo");
    return current && (current > dayjs() || (dateTo && current > dateTo));
  };

  const disabledDateTo = (current: Dayjs | null) => {
    const dateFrom = form.getFieldValue("dateFrom");
    return current && (current > dayjs() || (dateFrom && current < dateFrom));
  };
  const handleFormValuesChange = (_changedValues: any, allValues: any) => {
    const { timeType, dateFrom, dateTo } = allValues;
    setIsConfirmDisabled(!(timeType && dateFrom && dateTo));
  };

  return (
    <ConfigProvider locale={locale}>
      <Modal
        title={<div className="modal-title">Tuỳ chọn</div>}
        open={open}
        centered
        onCancel={handleCancel}
        className="option-time-modal"
        footer={
          <div className="option-time-modal-footer">
            <Button className="btn-blue" onClick={handleCancel}>
              Hủy
            </Button>
            <Button
              disabled={
                isConfirmDisabled
              }
              type="primary" className="btn-gray" onClick={handleConfirm}>
              Xác nhận
            </Button>
          </div>
        }
      >
        <div className="hr-tag" />
        <Form
          className="add-learning-materials"
          layout={"vertical"}
          form={form}
          initialValues={{
            timeType: null,
            dateFrom: null,
            dateTo: null,
          }}
          onValuesChange={handleFormValuesChange}
        >
          <div className="content">
            <div className="item">
              <span className="label">Đơn vị:</span>
              <Form.Item name="timeType">
                <Select
                  placeholder="Chọn đơn vị thời gian"
                  style={{ width: "100%" }}
                  options={[
                    { value: "day", label: "Biểu đồ ngày" },
                    { value: "week", label: "Biểu đồ tuần" },
                    { value: "month", label: "Biểu đồ tháng" },
                    { value: "year", label: "Biểu đồ năm" },
                  ]}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className="item">
              <span className="label">Từ ngày:</span>
              <Form.Item name="dateFrom">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Chọn ngày"
                  disabledDate={disabledDateFrom}
                />
              </Form.Item>
            </div>
            <div className="item">
              <span className="label">Đến ngày:</span>
              <Form.Item name="dateTo">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Chọn ngày"
                  disabledDate={disabledDateTo}
                />
              </Form.Item>
            </div>

            <div className="hr-tag" />
          </div>
        </Form>
      </Modal>
    </ConfigProvider>
  );
};

export default OptionTimeModal;
