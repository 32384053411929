import { IdcardOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Layout, Menu, theme } from "antd";
import { useState } from "react";
import { useAuthStore } from "../../stores/stores";
import Account from "./account/Account";
import Profile from "./profile/Profile";
import "./style.scss";

const { Header, Content, Footer, Sider } = Layout;

const items = [
  {
    label: "Thông tin tài khoản",
    key: "1",
    icon: <UserOutlined />,
  },
  {
    label: "Hồ sơ cá nhân",
    key: "2",
    icon: <IdcardOutlined />,
  },
];

const UserInfo = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("1");
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { getUserInfo } = useAuthStore((state) => {
    return {
      getUserInfo: state.user,
    };
  });

  const renderContent = () => {
    switch (selectedKey) {
      case "1":
        return <Account />;
      case "2":
        return <Profile />;
      default:
        return <div>Chọn một mục từ menu</div>;
    }
  };

  return (
    <div className="user-info">
      <Layout style={{ minHeight: "calc(100vh - 48px)" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div className="account account-drop">
            <div className="account__avatar">
              <div className="account__avatar__wrapper">
                <a onClick={(e) => e.preventDefault()}>
                  <Avatar
                    src={getUserInfo?.anhDaiDien}
                    icon={<UserOutlined />}
                  />{" "}
                </a>
              </div>
            </div>
            {!collapsed ? (
              <div className="account__name">
                <a
                  className="account__name__wrapper"
                  onClick={(e) => e.preventDefault()}
                >
                  <span>{getUserInfo?.hoVaTen}</span>
                  <span className="email">{getUserInfo?.email}</span>
                </a>
              </div>
            ) : null}
          </div>
          <div className="demo-logo-vertical" />
          <Menu
            defaultSelectedKeys={["1"]}
            mode="inline"
            items={items}
            onClick={(e) => setSelectedKey(e.key)}
          />
        </Sider>
        <Layout>
          <Content>{renderContent()}</Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default UserInfo;
