import { AxiosResponse } from "axios";
import { axiosConfig } from "../../config/api/configApi";
import { DataType } from "../../types/listUsers";

export const getListCourseConstructionPlan: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/mooc-course-construction-plan/lookup`)
}


export const searchCoursePlan: (data: object) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-construction-plan/search`, data)
}

export const searchCourseConstructionPlanPlanningApproval: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-construction-plan-planing-approval/search`, data);
};

export const searchCourseConstructionPlanSubmitApprovalPlan: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-construction-plan-submit-approval-plan/search`, data);
};

export const searchCourseConstructionPlanSubmitAppraisalPlan: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-course-construction-plan-submit-appraisal-plan/search`, data);
};