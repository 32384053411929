import "./detail.scss";
import { Button, Input, Radio, Row, Space, ConfigProvider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useEffect, useState } from "react";
import { Divider } from "antd";
import { useSurveyRespond } from "../../../../../../../../../stores/stores";


const QuestionType3 = (props: any) => {
  const listAnswer = [{ value: 1, name: "Đúng" }, { value: 0, name: "Sai" }];
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");


  const handleRadioChange = (optionId: number) => {
    setSelectedOption(optionId);
    setIsOtherSelected(false);
  };

  const handleOtherSelected = () => {
    setIsOtherSelected(true);
    setSelectedOption(null);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  }


  return (
    <div style={{ width: "100%" }}>
      <Space direction="vertical" size="small" className="surveyQuestion" style={{ width: "100%" }}>
        {listAnswer.map((option: any) => (
          <Radio
            key={option.value}
            checked={selectedOption === option.value}
            onChange={() => handleRadioChange(option.value)}
            disabled={props.typeSurvey === "2"}
          >
            {option.name}
          </Radio>
        ))}
        {
          props.listOptions.map((Option: any) => (
            Option.isOther ? (
              <>
                <Radio
                  checked={isOtherSelected}
                  onChange={handleOtherSelected}
                  disabled={props.typeSurvey === "2"}
                >
                  Khác
                </Radio>
                {isOtherSelected && (
                  <>
                    <Input
                      placeholder="Vui lòng nhập..."
                      value={inputValue}
                      onChange={handleChangeInput}
                      style={{ marginTop: "12px", marginBottom: "12px" }}
                      disabled={props.typeSurvey === "2"}
                    />
                  </>
                )}
                {!isOtherSelected && <Divider style={{ margin: "12px 0" }} />}
              </>
            ) : null
          ))
        }
      </Space>
    </div>
  );
};

export default QuestionType3;
