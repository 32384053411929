import { IDataCreateUser } from "./../../../types/listUsers";
import { AxiosResponse } from "axios";
import { axiosConfig } from "../../../config/api/configApi";

export const postMoocOrganizationMemberSearch: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-organization-member/search`, data);
};

export const getMoocOrganizationMemberById: (
  id: string
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.get(`/mooc-organization-member/get-by-id/${id}`);
};

export const putUpdateMoocOrganizationMember: (
  id: number,
  data: object
) => Promise<AxiosResponse<any>> = (id, data) => {
  return axiosConfig.put(`/mooc-organization-member/update/${id}`, data);
};

export const updateMoocOrganizationMemberMany: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.put(`/mooc-organization-member/updateMany`, data);
};

export const postCreateMoocOrganizationMember: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-organization-member/create`, data);
};
export const postCreateManyMoocOrganizationMember: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.post(`/mooc-organization-member/createMany`, data);
};
export const deleteMoocOrganizationMember: (
  id: number
) => Promise<AxiosResponse<any>> = (id) => {
  return axiosConfig.delete(`/mooc-organization-member/delete/${id}`);
};
export const deleteMoocOrganizationMemberMany: (
  data: object
) => Promise<AxiosResponse<any>> = (data) => {
  return axiosConfig.delete(`/mooc-organization-member/deleteMany`, {
    data,
  });
};
// Categories

export const getLookupMoocOrganization: () => Promise<
  AxiosResponse<any>
> = () => {
  return axiosConfig.get(`/mooc-organization/lookup`);
};
export const getLookupAuthUser: () => Promise<AxiosResponse<any>> = () => {
  return axiosConfig.get(`/lookup/get-user`);
};
