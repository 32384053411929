// import "./detail.scss";
import { Button, Input, Checkbox, Row, Space, ConfigProvider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";
import { ListAnswer } from "../../../../../../types/survey-managment/survey";

const QuestionType5 = (props: any) => {
    const [options, setOptions] = useState<ListAnswer[]>(props.listOptions || []);
    const [showPlaceholder, setShowPlaceholder] = useState(true);

    const onHandleDeleteOption = (index: number) => {
        const newOptions = options.filter((_, i) => i !== index);

        setOptions(newOptions);
        props.setUpdateListAnswer(newOptions);
    };

    const onHandleAddOption = () => {
        let newOptions = [...options];

        const addedOption = {
            answerText: `Chọn văn bản ${options.length + 1} `,
            answerOrderNumber: options.length + 1,
        };
        newOptions.push(addedOption);
        setOptions(newOptions);
        props.setUpdateListAnswer(newOptions);
    };

    const handleChangeAnswerText = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newOptions = [...options];
        newOptions[index] = {
            ...newOptions[index],
            answerText: event.target.value,
        };
        setOptions(newOptions);
        props.setUpdateListAnswer(newOptions);
    };

    return (
        <div>
            <div>
                <br />
                <ConfigProvider
                    theme={{
                        components: {
                            Input: {
                                colorTextPlaceholder: "black",
                                paddingInline: 0,
                                paddingBlock: 0,
                            },
                            Button: {
                                paddingInline: 2,
                                paddingBlock: 0,
                            },
                        },
                    }}
                >
                    <Space direction="vertical" size="small" style={{ display: "flex" }}>
                        {options.map((option, index) => {
                            return (
                                <Row
                                    style={{
                                        margin: "0 auto",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                    key={index}
                                >
                                    <Input
                                        prefix={<Checkbox checked={false}></Checkbox>}
                                        // suffix={
                                        //     <Button
                                        //         style={{ width: 20, height: 25 }}
                                        //         icon={<CloseOutlined />}
                                        //         // onClick={() => {
                                        //         //     onHandleDeleteOption(index);
                                        //         // }}
                                        //         disabled
                                        //     />
                                        // }
                                        variant="borderless"
                                        value={
                                            option.answerText || option.answerText === ""
                                                ? option.answerText
                                                : `Lựa chọn văn bản ${options.length}`
                                        }
                                        // onChange={(event) => handleChangeAnswerText(event, index)}
                                    />
                                </Row>
                            );
                        })}
                    </Space>
                </ConfigProvider>
                <br />
            </div>
        </div>
    );
};

export default QuestionType5;
