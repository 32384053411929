import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Flex, Form, Typography } from "antd";
import { FormInstance } from "antd/lib";
import moment from "moment";
import { useState } from "react";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const InteractStudent = ({ behaviourTag, setDateBehavior }: any) => {
  const [filterData, setFilterData] = useState<any>([]);

  const [form] = Form.useForm();

  const disabledDate = (current: any) => {
    // Can not select days after today
    return current && current > moment().endOf("day");
  };

  const handleFilterInteract = () => {
    setDateBehavior(filterData)
  };

  const clearFilterInteract = (formInstance: FormInstance) => {
    formInstance.resetFields();
    setDateBehavior([])
  };
  const handleChangeTime = (dateString: any, name: string) => {
    setFilterData(dateString)
  };
  return (
    <Card className="w-full lg:w-1/2 lg:ml-2 mb-3 lg:mb-0">
      <Title level={5}>
        Thống kê hành vi sinh viên tương tác với danh sách gợi ý
      </Title>
      <Form
        name="formFilterTag"
        onFinish={handleFilterInteract}
        form={form}
        className="flex-wrap flex"
      >
        <Form.Item name="dateInteract">
          <Flex>
            <RangePicker
              placeholder={["Từ ngày", "Đến ngày"]}
              format="YYYY-MM-DD"
              onChange={(_value, dateString: any) =>
                handleChangeTime(dateString, "planCreationDate")
              }
              disabledDate={disabledDate}
            />
            <Flex className="ml-3">
              <Button
                className="btn btn-primary filter-btn mr-2"
                htmlType="submit"
              >
                <FilterOutlined />
                <Typography.Text>Lọc</Typography.Text>
              </Button>
              <Button
                className="btn btn-outlined clear-filter-btn"
                onClick={() => clearFilterInteract(form)}
              >
                <DeleteOutlined />
                <Typography.Text>Xóa bộ lọc</Typography.Text>
              </Button>
            </Flex>
          </Flex>
        </Form.Item>
      </Form>
      <Flex className="border-b p-4 bg-[#FAFAFA] mt-4" justify="space-between">
        <Text className="w-4/5 border-r">Nội dung hành vi</Text>
        <Text className="w-1/5 text-center">Số lượng</Text>
      </Flex>
      <Flex className="border-b p-4 mt-4" justify="space-between">
        <Text className="w-4/5">Đúng theo gợi ý</Text>
        <Text className="w-1/5 text-center">{behaviourTag?.success}</Text>
      </Flex>
      <Flex className="px-4 mt-4" justify="space-between">
        <Text className="w-4/5">Không đúng gợi ý</Text>
        <Text className="w-1/5 text-center">{behaviourTag?.fail}</Text>
      </Flex>
    </Card>
  );
};

export default InteractStudent;
