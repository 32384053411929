import "../login-page/login-page.scss";
import { Button, Form, Spin } from "antd";
import FormInput from "../../../../components/form-input-login/FormInput";
import { FormEvent, useState } from "react";
import { getMailOtp } from "../login-page/services";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { regexEmail } from '../../../../utils/regex';
import { isEmpty } from "lodash";

export const ForgotPassword = (
  loadingLogin: boolean,
  backToLogin: (id: string) => void,
  handleAddClassShow: (id: string) => void,
  setForgotPasswordStep: React.Dispatch<React.SetStateAction<boolean>>,
  setIsCountTimeLeft: React.Dispatch<React.SetStateAction<boolean>>,
  setLoadingLogin: React.Dispatch<React.SetStateAction<boolean>>,
  verifyMailStep: boolean,
  setVerifyMailStep: React.Dispatch<React.SetStateAction<boolean>>,
  setTimeLeft: React.Dispatch<React.SetStateAction<number>>,
  handleAddClassHide: (id: string) => void,
  verifyMail: string,
  setVerifyMail: React.Dispatch<React.SetStateAction<string>>
) => {
  const [form] = Form.useForm();
  const getMailOTP = async () => {
    try {
      const response = await getMailOtp(verifyMail);
      setTimeout(() => {
        handleAddClassHide("forgot-password");
        handleAddClassShow("verify-otp");
        setVerifyMailStep(true);
        setLoadingLogin(false);
        setIsCountTimeLeft(true);
        setTimeLeft(60);
      }, 500);
    } catch (error) {
      setLoadingLogin(false);
    }
  };

  const handleSendMail = (event: FormEvent<HTMLFormElement>) => {
    // event.preventDefault();

    if (verifyMail) {
      setLoadingLogin(true);
      getMailOTP();
    }
  };

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
      }}
    >
      <div id="forgot-password" className={`login-card__item`}>
        <Spin tip="Loading" size="large" spinning={loadingLogin}>
          <div className="header-container">
            <div
              className="back-button"
              onClick={() => {
                backToLogin("forgot-password");
                handleAddClassShow("login-card");
                // backToLogin("login-card");
                setForgotPasswordStep(false);
                setIsCountTimeLeft(false);
              }}
            >
              <ArrowLeftOutlined style={{ marginRight: "8px" }} />
              Quay lại
            </div>
            <h2 className="ng-nhp1">Quên mật khẩu</h2>
          </div>
          <Form
            form={form}
            id="forgot-password-form"
            className="form-and-button"
            onFinish={handleSendMail}
          >
            <div className="inputs">
              <Form.Item
                name="forgotPassword"
                rules={[
                  {
                    required: true,
                    validator: (_: unknown, value: any) => {
                      if (!isEmpty(value) && !value.match(new RegExp(regexEmail))) {
                        return Promise.reject(new Error("Định dạng email không đúng"))
                      }
                      if (isEmpty(value)) {
                        return Promise.reject(new Error("Vui lòng nhập địa chỉ email"))
                      }
                      return Promise.resolve()
                    }
                  },
                ]}
              >
                <FormInput
                  // required={true}
                  title="Email"
                  inputPrefix="/inputprefix@2x.png"
                  placeholder="Nhập email"
                  // errorInvalid="Email không hợp lệ."
                  inputSuffix="/inputsuffix@2x.png"
                  inputSuffixIcon={false}
                  propMinWidth="7.56rem"
                  // regexValue={/^[a-zA-Z0-9._%+-]+@[a-zA-Z]{2,}\.[a-zA-Z]{2,}$/}
                  value={verifyMail}
                  onChange={setVerifyMail}
                />
              </Form.Item>
            </div>
            <Button
              // onClick={() => form.submit()}
              type="primary"
              className={`button11`}
              loading={loadingLogin}
              htmlType="submit"
            >
              Gửi yêu cầu
            </Button>
          </Form>
        </Spin>
      </div>
    </Form.Provider>
  );
};
