import { DeleteOutlined, EyeOutlined, MinusCircleOutlined, MoreOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Layout, MenuProps, Space, TableColumnsType, TableProps } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { formatOriginTimev3 } from "../../../utils/converDate";
import TableData from "../../../components/table-data/TableData";
import FilterExamTable
  from '../../../components/course-library/personal-exam-repository/bank-question/filter-table-exam';
import FilterGroup from "../../../components/course-library/personal-exam-repository/bank-question/filter-group";
import { useForm } from "antd/es/form/Form";
import { FilterData } from "../../../components/course-library/personal-exam-repository/bank-question/filter-group/filter";
import DetailQuestion from '../../../components/course-library/personal-exam-repository/list-question/detail-question';
import PageHeader from '../../../components/page-header/PageHeader';


type ListExamPersonalProps = {
  title: string,
}

const mockData = [
  {
    id: 1,
    code: '001',
    name: 'Test',
    numberOfQuestion: 30,
    topic: 'Kỹ thuật',
    usage: 2,
    permission: 'Nội bộ',
    status: 'Bản nháp',
    updatedDate: '20/12/2024',
    checked: false,
  },
  {
    id: 2,
    code: '002',
    name: 'Test2',
    numberOfQuestion: 30,
    topic: 'Kỹ thuật 2',
    usage: 2,
    permission: 'Nội bộ 2',
    status: 'Bản nháp 2',
    updatedDate: '20/12/2024',
    checked: false,
  }
]


const BankQuestion = ({ title }: ListExamPersonalProps) => {

  const [form] = useForm()

  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedDetail, setIsOpenedDetail] = useState<boolean>(false)
  const [isOpenedHistory, setIsOpenedHistory] = useState<boolean>(false)
  const [isOpenedEditPermission, setIsOpenedEditPermission] = useState<boolean>(false)
  const [isOpenedSentPermission, setIsOpenedSentPermission] = useState<boolean>(false)
  const [isOpenedPublishModal, setIsOpenedPublishModal] = useState<boolean>(false)
  const [idDetailData, setIdDetailData] = useState<number | undefined>();

  const [page, setPage] = useState<number>(1)
  const [size, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [searchValue, setSearchValue] = useState<string>("")
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [typeQuestion, setTypeQuestion] = useState<boolean>(false) // false: danh sách câu hỏi, //true: câu hỏi lưu trũ

  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [mode, setMode] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataTable, setDataTable] = useState<any>(mockData);

  const [countChoose, setCountChoose] = useState<number>(0)
  const [dataOptions, setDataOptions] = useState({
    dataTopic: [],
    dataHierarchy: [
      {
        id: 1,
        name: "Cấp Bộ"
      },
      {
        id: 2,
        name: "Cấp Trường"
      },
      {
        id: 3,
        name: "Cấp Giảng Viên"
      }
    ],
  })

  const [filterData, setFilterData] = useState<FilterData>({
    questionId: null,
    difficulty: null,
    permission: null,
    updatedDate: null,
    status: null,
  });

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      {
        key: "0",
        label: "Gửi kiểm duyệt",
      },
      {
        key: "1",
        label: "Xuất bản",
      },
      {
        key: "2",
        label: "Chỉnh sửa",
      },
      {
        key: "3",
        label: "Sửa quyền truy cập",
      },
      {
        key: "4",
        label: "Lịch sử kiểm duyệt",
      },
      {
        key: "5",
        label: "Xóa",
        icon: <DeleteOutlined />,
        className: "dropdown-delete",
        danger: true,
      },
    ];
    return options1;
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: 'Mã câu hỏi',
      dataIndex: "code",
      key: "code",
      width: 5,
    },
    {
      title: "Câu hỏi",
      dataIndex: "name",
      key: "name",
      width: 15,
      sorter: true
    },
    {
      title: "Loại câu hỏi",
      dataIndex: "topic",
      key: "topic",
      width: 5,
      sorter: true
    },
    {
      title: "Độ khó",
      dataIndex: "usage",
      key: "usage",
      width: 5,
    },
    {
      title: "Phân cấp",
      dataIndex: "usage",
      key: "usage",
      width: 5,
    },
    {
      title: "Lượt dùng",
      dataIndex: "usage",
      key: "usage",
      width: 5,
    },
    {
      title: "Đơn vị xuất bản",
      dataIndex: "usage",
      key: "usage",
      width: 5,
    },
    {
      title: "Ngày câp nhật",
      dataIndex: "updatedDate",
      key: "updatedDate",
      sorter: true,
      width: 8,
      render: (_: any, record) => {
        return (
          <>{record.updatedDate ? formatOriginTimev3(record.updatedDate) : null}</>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      key: "operation",
      width: 5,
      render: (_, record, index) => {
        const location = {
          id: `${record.id}`,
        };

        const handleChangeCount = (type: string) => {
          const arr = [...mockData]
          console.log("arr", arr)
          arr[index].checked = !arr[index].checked
          setDataTable(arr)

          if (type === "add") {
            setCountChoose(countChoose + 1)
          } else {
            setCountChoose(countChoose - 1)
          }
        }

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenedDetail(true)
                  setIdDetailData(record.id)
                }}
              >
                <EyeOutlined />
              </a>
              <>
                {
                  !record?.checked ?
                    <Button onClick={() => handleChangeCount("add")}><PlusCircleOutlined /></Button> :
                    <Button onClick={() => handleChangeCount("")}><MinusCircleOutlined /></Button>
                }
                <Button><DeleteOutlined className='text-red-500' /></Button>
              </>
            </Space>
          </>
        );
      },
      fixed: "right",
    },
  ];

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  }

  const handleOpen = () => {
    setIsOpened(true)
  }

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSearch = () => {

  }

  const handleSubmitSearch = () => {

  }

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({ ...filterData, [name]: value });
  };

  const clearFilter = () => {

  }

  const onFinish = () => {

  }

  const closeForm = () => {
    // setDetailData({});
    setIsOpened(false);
    // setIsOpenedEdit(false);
    form.resetFields();
    // setCountSchool([]);
    // setCountSchoolSponsor([]);
    // setError({});
  }

  const closeDetail = () => setIsOpenedDetail(false)
  const saveData = () => {
    form.submit();
  };


  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <FilterExamTable
              handleSearch={handleSearch}
              handleOpen={handleOpen}
              handleSubmitSearch={handleSubmitSearch}
              handleChangeInput={handleChange}
              handleOpenFilter={handleOpenFilter}
              searchValue={searchValue}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
              countChoose={countChoose}
            />
          </div>
          <FilterGroup
            form={form}
            showFilter={showFilter}
            clearFilter={clearFilter}
            setFilterData={handleChangeFilter}
            filterData={filterData}
            dataOptions={dataOptions}
            onFinish={onFinish}
            typeQuestion={typeQuestion}
          />
        </div>
        <div className="tabs-heading">
          <TableData
            dataColumns={dataColumns}
            dataTable={dataTable}
            setPageNumber={setPage}
            setPageSize={setPageSize}
            pageNumber={page}
            pageSize={size}
            loadingTable={loading}
            scroll={{ x: 1500, y: 600 }}
            totalRecords={totalRecords}
            hideSelection={true}
            setSelectedRowKeysProps={setSelectedRowKeys}
            pagination={true}
            handleTableChangeProps={handleTableChange} />
          <DetailQuestion open={isOpenedDetail} onClose={() => setIsOpenedDetail(false)} idDetailData={idDetailData} dataOptions={dataOptions}/>
        </div>
      </Form.Provider>
    </Layout>
  );
};

export default BankQuestion;