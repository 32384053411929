import { Empty, Table } from "antd";
import type { ColumnsType, ColumnType } from "antd/es/table";
import { TablePaginationConfig } from "antd/lib";
import { useEffect, useRef, useState } from "react";
import "./TableWithTotals.scss";

interface TableWithTotalsProps<T> {
  data: T[];
  columns: ColumnsType<T>;
  totalLabel?: string;
  keyColumn: keyof T;
  width?: number | string;
  height?: number | string;
  pagination?: false | TablePaginationConfig;
  hasTotal?: boolean;
  calculatePercentage?: boolean;
}

const TableWithTotals = <T extends object>({
  data,
  columns,
  totalLabel = "Tổng số",
  keyColumn,
  height = 275,
  pagination = false,
  hasTotal = true,
  calculatePercentage = false,
  width,
}: TableWithTotalsProps<T>) => {
  const [scroll, setScroll] = useState<{ x?: number; y?: number }>({
    y: undefined,
  });
  const tableRef = useRef<HTMLDivElement>(null);

  const calculateScrollHeight = () => {
    if (tableRef.current) {
      const tableBody = tableRef.current.querySelector(
        ".ant-table-tbody"
      ) as HTMLDivElement | null;

      if (tableBody) {
        const tableBodyHeight = tableBody.scrollHeight;
        if (tableBodyHeight > Number(height)) {
          setScroll({ x: Number(width), y: Number(height) });
        } else {
          setScroll({ x: Number(width), y: undefined });
        }
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      calculateScrollHeight();
    }, 100);
  }, [columns]);
  useEffect(() => {
    window.addEventListener("resize", calculateScrollHeight);
    return () => {
      window.removeEventListener("resize", calculateScrollHeight);
    };
  }, []);

  const getTotalRow = (): Partial<T> => {
    const totalRow: Partial<T> = { [keyColumn]: totalLabel } as Partial<T>;

    columns.forEach((col) => {
      const colType = col as ColumnType<T>;
      if (colType.dataIndex && colType.dataIndex !== keyColumn) {
        const dataIndex = colType.dataIndex as keyof T;

        if (calculatePercentage) {
          let overallTotalCount = 0;
          let totalPercentages = 0;
          const totalColumnKey = "Tổng" as keyof T;
          data.forEach((item) => {
            columns.forEach((col) => {
              const colType = col as ColumnType<T>;
              if (colType.dataIndex && colType.dataIndex !== keyColumn) {
                const dataIndex = colType.dataIndex as keyof T;
                const value = item[dataIndex]?.toString();
                if (value) {
                  const matches = value.match(/\((\d+)\)/);
                  if (matches) {
                    overallTotalCount += parseInt(matches[1], 10);
                  }
                }
              }
            });
          });

          // Tính phần trăm cho từng cột và tính tổng phần trăm cho cột "Tổng"
          columns.forEach((col) => {
            const colType = col as ColumnType<T>;
            if (colType.dataIndex && colType.dataIndex !== keyColumn) {
              const dataIndex = colType.dataIndex as keyof T;

              let totalCount = 0;

              data.forEach((item) => {
                const value = item[dataIndex]?.toString();
                if (value) {
                  const matches = value.match(/\((\d+)\)/);
                  if (matches) {
                    totalCount += parseInt(matches[1], 10);
                  }
                }
              });

              const percentage = (totalCount / overallTotalCount) * 100;
              totalPercentages += percentage;

              totalRow[dataIndex] = `${percentage.toFixed(
                2
              )}% (${totalCount})` as unknown as T[keyof T];
            }
          });

          // Đảm bảo rằng cột "Tổng" đạt 100%
          totalRow[totalColumnKey] = `${totalPercentages.toFixed(
            2
          )}% (${overallTotalCount})` as unknown as T[keyof T];
        } else {
          totalRow[dataIndex] = data.reduce(
            (acc, item) => acc + (Number(item[dataIndex]) || 0),
            0
          ) as unknown as T[keyof T];
        }
      }
    });

    return totalRow;
  };

  const totalRow = getTotalRow();
  const locale = {
    emptyText: <Empty description="Không có kết quả" />,
  };

  return (
    <div ref={tableRef} className="table-with-totals">
      <Table
        columns={columns}
        dataSource={data}
        pagination={pagination}
        rowKey={keyColumn as string}
        scroll={scroll}
        locale={locale}
        summary={() =>
          hasTotal && data?.length > 0 ? (
            <Table.Summary fixed>
              <Table.Summary.Row className="total-row">
                {columns.map((col, index) => {
                  const colType = col as ColumnType<T>;
                  return (
                    <Table.Summary.Cell
                      key={colType.dataIndex as string}
                      index={index}
                    >
                      {index === 0
                        ? totalLabel
                        : totalRow[colType.dataIndex as keyof T]?.toString()}
                    </Table.Summary.Cell>
                  );
                })}
              </Table.Summary.Row>
            </Table.Summary>
          ) : null
        }
      />
    </div>
  );
};

export default TableWithTotals;
