import { ProColumns } from "@ant-design/pro-components";
import { Radio, RadioChangeEvent, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useAddLesson } from "../../../../stores/stores";
import "./styles.scss";
import { QuestionAnswerInfo, QuestionData, QuestionItemDetail, } from "../../../../types/course-construction";
import TextEditor from "../../../text-editor/TextEditor";
import QuestionTrueFalseItem from "./QuestionTrueFalse";
import { SortableList } from "../../../SortableList";
import { initialTrueFalseQuestionValue } from "../../../../constants";
import { mapAnsBEtoFE } from "../../../../utils/arrays";
import { renderQuestionNameByType, renderQuestionDescriptionByType } from "../../../../utils/functionUltils";

function TrueOrFalse({ data = {} as QuestionData }: QuestionItemDetail) {
  const question = useAddLesson((state: any) => state.question);
  const setCurrentQuestionInfo = useAddLesson(
    (state) => state.setCurrentQuestionInfo
  );
  const [radioValue, setRadioValue] = useState(0);
  const [answers, setAnswers] = useState(initialTrueFalseQuestionValue);
  const [questionTitle, setQuestionTitle] = useState(
    `<p><strong>Câu trả lời đúng / sai mới</strong></p>`
  );

  const handleChangeRadio = (e: RadioChangeEvent) => {
    const currentChangeRadioId = e.target.value;

    if (currentChangeRadioId) {
      const distAnswer = [...answers];
      distAnswer.forEach(
        (item) => (item.isTrue = false)
      );

      distAnswer[currentChangeRadioId - 1] = {
        ...distAnswer[currentChangeRadioId - 1],
        isTrue: true,
      };
      setAnswers(distAnswer);
      setRadioValue(currentChangeRadioId);
    }
  };

  const handleChangeText = (current: { id: number; value: string }) => {
    const distAnswer = [...answers];
    const indexOfChangeItem = distAnswer.findIndex(
      (item: QuestionAnswerInfo) => item.id === current.id
    );
    if (indexOfChangeItem !== -1) {
      distAnswer[indexOfChangeItem] = {
        ...distAnswer[indexOfChangeItem],
        value: current.value,
      };
      setAnswers(distAnswer);
    }
  };

  const columns: ProColumns[] = [
    {
      title: "",
      dataIndex: "sort",
      width: 60,
      className: "drag-visible",
    },
    {
      title: "",
      dataIndex: "value",
      className: "drag-visible",
      render: (_: any, record, index) => {
        return (
          <>
            <QuestionTrueFalseItem
              index={record.id}
              value={record.value}
              valueRadio={record.id}
              onChange={handleChangeText}
            />
          </>
        );
      },
    },
  ];

  // const handleDragSortEnd = (
  //   beforeIndex: number,
  //   afterIndex: number,
  //   newDataSource: any
  // ) => {
  //   const newData = [...newDataSource];
  //   distAnswer.current = newData;
  //   setRender(!render);
  // };

  const handleDragChange = (value: any) => {
    setAnswers(value);
  };


  const handleChangeChecked = (checked: boolean, id: number) => {
    const newList = [...answers];
    newList.forEach((item: QuestionAnswerInfo) => {
      if (item.id === id) item.isTrue = checked;
    });
    setAnswers(newList);
  };

  useEffect(() => {
    setQuestionTitle(question.question);

    if (data.answers && data.answers?.length > 0) {
      const feAnswer = mapAnsBEtoFE(data.answers);
      setAnswers(feAnswer);
      const correctAns = feAnswer.find(
        (quiz: any) => quiz.isTrue
      );
      if (correctAns) setRadioValue(correctAns.id);
    } else {
      setAnswers(initialTrueFalseQuestionValue);
      setRadioValue(0)
    }
  }, [question, data.answers]);
  useEffect(() => {
    setCurrentQuestionInfo({
      ...answers,
      questionName: questionTitle,
    });
  }, [answers, questionTitle]);

  return (
    <section className="question-true-false-box question-wrapper">
      <Typography.Title level={5} className="question-title">
        {renderQuestionNameByType(question.type)}
      </Typography.Title>
      <Typography.Title level={1} className="question-description secondary-color">
        {renderQuestionDescriptionByType(question.type)}
      </Typography.Title>
      <TextEditor value={questionTitle} onChange={setQuestionTitle} />
      <div className='tree-course-construct list-answers choose-true-false'>
        <Radio.Group
          name="question-text"
          onChange={handleChangeRadio}
          value={radioValue}
          className="w-full"
        >
          <SortableList
            items={answers}
            onChange={handleDragChange}
            renderItem={(record: any) => (
              <SortableList.Item id={record.id}>
                {/* <SortableList.DragHandle/> */}
                <QuestionTrueFalseItem
                  index={record.id}
                  value={record.value}
                  valueRadio={record.id}
                  onChange={handleChangeText}
                  onChangeChecked={(checked: boolean) =>
                    handleChangeChecked(checked, record.id)
                  }
                />
              </SortableList.Item>
            )}
          />
        </Radio.Group>
      </div>
    </section>
  );
}

export default TrueOrFalse;
