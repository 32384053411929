import {DatePicker, Form, TimePicker, Typography} from 'antd';
import React, {useState} from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import FormItemDropdown from "../../../../form-dropdown/FormItemDropdown";
import vi from "antd/es/date-picker/locale/vi_VN"
import 'dayjs/locale/vi';
import './index.scss'
import {FormInstance} from "antd/lib";
import dayjs from "dayjs";
import {optionsSkills} from "./constants/data";
import {get} from "lodash";
import customParseFormat from "dayjs/plugin/customParseFormat";

const validate = {
  startDate: {
    required: true,
    message: "Vui lòng chọn ngày ghi danh"
  },
  startTime: {
    required: true,
    message: "Vui lòng chọn giờ ghi danh"
  }
}

const locale: typeof vi = {
  ...vi,
  lang: {
    ...vi.lang,
    dateSelect: "Chọn ngày",
    placeholder: "Chọn ngày",
    timeSelect: "Chọn giờ"
  },
  timePickerLocale: {
    placeholder: "Chọn giờ"
  },
}

type ConditionRegisterProps = {
  formInstance?: FormInstance
  dataConfig?: any
  totalRecords: number
  loading?: boolean
  hasMore: boolean
  pageNumber: number,
  fetchData: (number: number) => Promise<any>
  optionCourses?: Array<any>
}

const ConditionRegister = ({
                             formInstance,
                             dataConfig,
                             optionCourses,
                             totalRecords,
                             pageNumber,
                             fetchData,
                             loading,
                             hasMore
                           }: ConditionRegisterProps) => {
  const [selectStartedDate, setStartedDate] = useState(get(dataConfig, 'enrollStatedDate', ''))
  const [selectEndDate, setSelectEndDate] = useState()
  const [selectStartedTime, setStartedTime] = useState(dayjs(get(dataConfig, 'enrollStatedDate', '')))
  const [selectEndTime, setEndTime] = useState(dayjs(get(dataConfig, 'enrollClosedDate', '')))
  dayjs.extend(customParseFormat);


  const handleChangeStartedDate = (date: any) => {
    setStartedDate(date)
  }

  const handleChangeStartedTime = (time: any) => {
    setStartedTime(time)
  }

  const handleChangeEndedTime = (time: any) => {
    setEndTime(time)
  }

  const handleChangeEndedDate = (date: any) => {

    setSelectEndDate(date)
  }
  const disabledHours = () => {
    if (!selectStartedDate) return []
    // If no date selected, all hours are available
    const currentDate = dayjs();
    const selectedDay = dayjs(selectStartedDate);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past hours
      // @ts-ignore
      return [...Array(currentDate.hour()).keys()];
    }
    return []
  };

  const disabledMinutes = () => {
    if (!selectStartedDate) return []
    const currentDate = dayjs();
    const selectedDay = dayjs(selectStartedDate);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past minutes
      // @ts-ignore
      return [...Array(currentDate.minute()).keys()];
    }
    return []
  }

  const disableHoursEndDate = () => {
    if (!selectEndDate) return []
    // If no date selected, all hours are available
    const currentDate = dayjs();
    const selectedDay = dayjs(selectEndDate);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past hours
      // @ts-ignore
      return [...Array(currentDate.hour()).keys()];
    }
    return []
  };

  const disabledMinutesEndDate = () => {
    if (!selectEndDate) return []
    const currentDate = dayjs();
    const selectedDay = dayjs(selectEndDate);
    if (selectedDay.isSame(currentDate, 'day')) {
      // If selected date is today, disable past minutes
      // @ts-ignore
      return [...Array(currentDate.minute()).keys()];
    }
    return []
  }

  const disableDays = (currentDate: any) => {
    if (!selectStartedDate) return currentDate && currentDate.isBefore(dayjs().subtract(1, "day"))
    if (selectStartedDate) {
      return currentDate && currentDate.isBefore(dayjs(selectStartedDate))
    }
  }
  const handleScroll = (event: any) => {
    const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
    if (bottom && !loading && optionCourses && optionCourses.length < totalRecords && hasMore) {
      fetchData(pageNumber + 1);
    }
  };

  return (
    <>
      <CustomCard title="Điều kiện ghi danh" className="wrapper-settings--card">
        <div className="time-register--form">
          <div className="time-register--title">
            <Typography.Title level={5}>
              Thời gian ghi danh
            </Typography.Title>
          </div>
          <div className="form-select--time">
            <Form.Item name="enrollStatedDate" label="Ngày mở ghi danh"
                       className="form-select-item--date"
                       getValueProps={(i) => ({value: i ? dayjs(i) : ""})}
                       dependencies={["enrollClosedDate"]}
                       rules={[{
                         required: true,
                         message: "Vui lòng chọn mở ghi danh"
                       },
                         ({getFieldValue}) => ({
                           validator(_, value) {
                             const futureDate = getFieldValue('enrollClosedDate')
                             if (dayjs(value).isAfter(futureDate)) {
                               return Promise.reject(new Error("Ngày mở ghi danh không được sau ngày đóng ghi danh"))
                             }
                             return Promise.resolve()
                           }
                         })
                       ]}
            >
              <DatePicker disabledDate={(current) => current.isBefore(dayjs().subtract(1, "day"))} locale={locale}
                          format={"DD/MM/YYYY"} onChange={handleChangeStartedDate}/>
            </Form.Item>
            <Form.Item name="startTime" getValueProps={(i) => ({value: i ? dayjs(i) : ""})}
                       rules={[validate.startTime]}
                       className="form-select-item--time">
              <TimePicker disabledHours={disabledHours} disabledMinutes={disabledMinutes} locale={locale}
                          format={'HH:mm:ss'}
                          value={selectStartedDate && dayjs(selectStartedDate).set('hour', 8).set('minute', 0).toDate()}
                          onChange={handleChangeStartedTime}
              />
            </Form.Item>
          </div>
          <div className="form-select--time">
            <Form.Item name="enrollClosedDate" label="Ngày đóng ghi danh" className="form-select-item--date"
                       getValueProps={(i) => ({value: i ? dayjs(i) : ""})}
            >
              <DatePicker disabledDate={(current) => disableDays(current)}
                          onChange={handleChangeEndedDate} locale={locale} format={"DD/MM/YYYY"}/>

            </Form.Item>
            <Form.Item name="endTime" className="form-select-item--time"
                       getValueProps={(i) => ({value: i ? dayjs(i) : ""})}>
              <TimePicker
                disabledHours={disableHoursEndDate}
                disabledMinutes={disabledMinutesEndDate}
                onChange={handleChangeEndedTime} locale={locale}/>
            </Form.Item>
          </div>
        </div>
        <div className="condition-skill--form">
          <div className="condition-skill--title">
            <Typography.Title level={5}>
              Điều kiện về kỹ năng và kiến thức
            </Typography.Title>
          </div>
          <Form.Item name="preSkills" label="Chọn Kỹ năng" className="select-skill">
            <FormItemDropdown modeDropdown="multiple" options={optionsSkills} placeholder="Chọn Kỹ năng"/>
          </Form.Item>
        </div>
        <div className="course-prerequisite--form">
          <div className="course-prerequisite--title">
            <Typography.Title level={5}>
              Khoá học tiên quyết
            </Typography.Title>
          </div>
          <Form.Item name="preCourses" label="Chọn khoá học">
            <FormItemDropdown modeDropdown="multiple" loading={loading} onPopupScroll={handleScroll}
                              options={optionCourses}
                              placeholder="Chọn khoá học"/>
          </Form.Item>
        </div>
      </CustomCard>
    </>
  );
};

export default ConditionRegister;
