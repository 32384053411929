import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  TableColumnType,
  Typography,
  message,
} from "antd";

import FormInput from "../../../form-input/FormInput";
import "./filter-table.scss";
import {
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { DataType } from "../../../../types/assign-course-construction";
import { CheckboxOptionType } from "antd/lib";
import { isEmpty } from "lodash";

type FilterTableProps = {
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  publishCourse: () => void;
  handleOpenFilter: () => void;
  handleOpen: () => void;
  checkedList: Array<string>;
  setCheckedList: (value: string[]) => void;
  dataColumns: TableColumnType<DataType>[];
  selectedRowKeys: React.Key[];
};

const FilterDataPublishCourse: FunctionComponent<FilterTableProps> = ({
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  publishCourse,
  dataColumns,
  checkedList,
  setCheckedList,
  selectedRowKeys,
}) => {
  const options = dataColumns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  const items: MenuProps["items"] = [
    {
      label: (
        <Checkbox.Group
          options={options as CheckboxOptionType[]}
          value={checkedList}
          onChange={(value) => {
            setCheckedList(value as string[]);
          }}
        />
      ),
      key: "radio",
    },
  ];

  const menuProps = {
    items,
  };

  const openModalConfirm = () => {
    if (isEmpty(selectedRowKeys)) {
      message.error("Vui lòng chọn khoá học cần xuất bản")
      return
    }

    Modal.confirm({
      title: `Xác nhận xuất bản`,
      content: `Bạn có chắc chắn muốn xuất bản?`,
      onOk: () => {
        publishCourse();
      },
      okText: "Xác nhận",
      cancelText: "Huỷ",
      centered: true,
      icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]} className="row-filter">
          <Col
            md={{ span: 5 }}
            lg={{ span: 5 }}
            xl={{ span: 5 }}
            xs={{ span: 7 }}
            className="filter-group-select"
          >
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>Bộ lọc</Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col
            md={{ span: 7 }}
            lg={{ span: 7 }}
            xl={{ span: 7 }}
            xs={{ span: 7 }}
          >
            <div className="heading-search">
              <FormInput
                placeholder="Nhập từ khoá cần tìm"
                value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={
                  <SearchOutlined onClick={handleSubmitSearch} />
                }
              />
            </div>
          </Col>
          <Col
            md={{ span: 6 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            xs={{ span: 21 }}
          >
            <div className="heading-resigter">
              <Button onClick={openModalConfirm} className="btn btn-primary">
                <Typography.Text>Xuất bản nhiều</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterDataPublishCourse;
