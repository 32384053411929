import { Form, Input } from "antd";
import { ReactNode } from "react";

interface FormInputCommonProps {
  label?: string;
  name?: string;
  value?: any;
  max?: number;
  min?: number;
  onChange?: (val?: any) => void;
  addonBefore?: string | ReactNode;
  addonAfter?: string | ReactNode;
  controls?: boolean;
  type: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean
}

function FormInputCommon({
  label,
  name,
  value,
  max,
  min,
  type,
  addonBefore,
  placeholder,
  addonAfter,
  controls,
  disabled,
  className,
  ...rest
}: FormInputCommonProps) {
  return (
    <div className={className}>
      <div className="">
        <span>{label}</span>
        <Input
          min={min}
          max={max}
          placeholder={placeholder}
          type={type}
          value={value}
          disabled={disabled}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          {...rest}
        />
      </div>
    </div>
  );
}

export default FormInputCommon;
