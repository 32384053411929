import {StateCreator} from "zustand";

export interface StudyManagementSlice {
	numberTab: string,
	title: string,
	setTabAttendance: (numberTab: string) => void,
	setTitleAttendance: (numberTab: string) => void,
}

export const createStudyManagementSlice: StateCreator<StudyManagementSlice> = (set, get) => ({
	numberTab: '1',
	title: '',
	setTabAttendance: (numberTab) => {
		set({numberTab: numberTab})
	},
	setTitleAttendance: (title) => {
		set({title: title})
	}
})
