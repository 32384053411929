import React, { useState } from "react";
import "./detail.scss";
import {
  Input,
  Button,
  Space,
  Card,
  Dropdown,
  Modal,
  MenuProps,
  Row,
  Col,
} from "antd";
import themeSurvey from "../../../../../../common/constant/theme-survey.const";

interface SurveyDescriptionProps {
  surveyName: string;
  surveyDescription: string;
}

const SurveyDescription: React.FC<SurveyDescriptionProps> = ({
  surveyName,
  surveyDescription,
}) => {
  return (
    <div className="survey-managment-respond-description">
      <Card
        key={surveyName}
        className="wrapper-settings--card form-setting--common notPadding"
      >
        <Row className="surveyContainer-row">
          <Row>
            <Col span={24} className="surveyDiscription">
              <Col span={24} className="surveyTitle">
                {surveyName}
              </Col>
              <div
                className="contain-media"
                dangerouslySetInnerHTML={{
                  __html: ` ${surveyDescription ?? ""}`,
                }}
              />
            </Col>
          </Row>
        </Row>
      </Card>
    </div>
  );
};

export default SurveyDescription;
