import { Button, Checkbox, Col, ColorPicker, ColorPickerProps, Divider, Drawer, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Space, Switch, Typography, Upload, UploadProps, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import FormRadio from '../../form-radio/FormRadio'
import { CloseOutlined, CopyOutlined, ExclamationCircleOutlined, HeatMapOutlined, InfoCircleOutlined, LoadingOutlined, PicCenterOutlined, PlayCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
// import "./index.scss"
import CommonModal from '../../modal/common'
import { useLocation, useNavigate } from 'react-router-dom'
import { FileType } from '../../../utils/uploadImage'
import { createPortalAdministration, getAllUniversity } from '../../../service/portal-administration';
import { isEmpty } from 'lodash';
import { uploadFileImageQuiz } from '../../../service/course-construct'
import { getDetailAdminInterface, putSettingAdminInterface } from '../../../service/admin-interface'
import { getUniversities } from '../../../service/university'
import { globalStore } from '../../../stores/stores'
var indexDB = require("idbcache").default;

type Props = {
  open: boolean
  handleClose: () => void
  getListData: () => void
  tab: string
  mode: string
  dataUpdate: any
}

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  return false
}

export default function SettingAdminInterfaceDrawer(props: Props) {
  const { open, handleClose, getListData, tab, dataUpdate, mode } = props
  const [form] = Form.useForm();

  const [listSchool, setListSchool] = useState<any>([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<boolean>(true);
  const [imageUrl, setImageUrl] = useState<string>();
  const [bgColor, setBgColor] = React.useState<string>('');
  // const [textColor, setTextColor] = useState<string>('');
  const [sidebarColor, setSidebarColor] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');
  const { reloadAdminInterface, setReloadAdminInterface } = globalStore();

  const [listUser, setListUser] = useState<any>([
    {
      id: '1',
      name: 'lecturer',
      label: 'Giảng viên',
      checked: false,
    },
    {
      id: '2',
      name: 'qtkh',
      label: 'Quản trị khóa học',
      checked: false,
    },
    {
      id: '3',
      name: 'qtcs',
      label: 'Quản trị cơ sở',
      checked: false,
    },
    {
      id: '4',
      name: 'ngcb',
      label: 'Người dùng cấp bộ',
      checked: false,
    },
    {
      id: '5',
      name: 'tcm',
      label: 'Tổ chuyên môn',
      checked: false,
    }
  ]);

  const validation = {
    adminInterfaceName: {
      required: true,
      message: "Vui lòng nhập tên trang quản trị"
    },
    codeAdminInterface: {
      required: true,
      message: "Vui lòng nhập mã giao diện"
    },
    urlAdminInterface: {
      required: dataUpdate?.logo ? false : true,
      message: "Vui lòng nhập logo hệ thống"
    },
    bgColor: {
      required: true,
      message: "Vui lòng chọn màu nền"
    },
    // textColor: {
    //   required: true,
    //   message: "Vui lòng chọn màu chữ"
    // },
    sidebarColor: {
      required: true,
      message: "Vui lòng màu sidebar"
    },
  }

  const getUniversity = async () => {
    const data = {
      page: 1,
      size: 1000
    }
    await getUniversities(data).then((res) => {
      const arr = res.data?.content.map((item: any) => ({
        id: item.uuid,
        name: item.name
      }))
      setListSchool(arr)
    })
  }

  const getDetailData = async () => {
    if (dataUpdate) {
      form.setFieldsValue({
        "adminInterfaceName": dataUpdate?.name,
        "codeAdminInterface": dataUpdate?.code,
      })
      setStatus(dataUpdate?.status === 1 ? true : false)
      let arr: any = []
      if (tab === "1") {
        arr = [...listUser]
      } else {
        const newArr = [
          {
            id: '1',
            name: 'lecturer',
            label: 'Giảng viên',
            checked: false,
          },
          {
            id: '2',
            name: 'qtkh',
            label: 'Quản trị khóa học',
            checked: false,
          },
          {
            id: '3',
            name: 'qtcs',
            label: 'Quản trị cơ sở',
            checked: false,
          },
          {
            id: '5',
            name: 'tcm',
            label: 'Tổ chuyên môn',
            checked: false,
          }
        ]
        arr = [...newArr]
      }

      arr[0].checked = dataUpdate?.isGv
      arr[1].checked = dataUpdate?.isQtkh
      arr[2].checked = dataUpdate?.isQtcs
      if (tab === "1") {
        arr[3].checked = dataUpdate?.isCapBo
        arr[4].checked = dataUpdate?.isToChuyenMon
      } else {
        arr[3].checked = dataUpdate?.isToChuyenMon
      }
      setListUser(arr)
      setImageUrl(dataUpdate?.logo)
      if (tab === '2') {
        form.setFieldValue("school", dataUpdate?.enterpriseUuid)
      }
      form.setFieldsValue({
        bgColor: dataUpdate?.color?.backgroundColor.toUpperCase(),
        textColor: dataUpdate?.color?.textColor.toUpperCase(),
        sidebarColor: dataUpdate?.color?.menuColor.toUpperCase(),
      })

      setBgColor(dataUpdate?.color?.backgroundColor)
      setSidebarColor(dataUpdate?.color?.menuColor)
    }
  }

  useEffect(() => {
    form.setFieldValue("typeStatus", 1)
  }, [])

  useEffect(() => {
    getUniversity()
    getDetailData()
  }, [dataUpdate, open])

  const handleChange: UploadProps['onChange'] = async (info) => {
    setLoading(true);
    getBase64(info.fileList?.[0]?.originFileObj as FileType, (url) => {
      setLoading(false);
      setImageUrl(url);
    });

    const formData = new FormData();
    if (!isEmpty(info.fileList?.[0]?.originFileObj)) {
      formData.append("file", info.fileList?.[0]?.originFileObj ?? null);
    }

    await uploadFileImageQuiz(formData).then((res) => {
      if (res.status === 200) {
        setFileName(res.data?.fileName)
        setLoading(false)
      }
    })
    // }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const handleChangeCheckbox = (value: boolean, name: string) => {
    const newArr = [...listUser]
    const obj = newArr.filter((item) => item.name === name)
    if (!isEmpty(obj)) {
      obj[0].checked = value
    }
    setListUser(newArr)
  }

  const renderGroupUser = listUser?.map((item: any) => (
    <Form.Item name={item.name} label="" hasFeedback>
      <Checkbox checked={item.checked} onChange={(e) => handleChangeCheckbox(e.target.checked, item.name)} disabled={mode === 'view'}
      >
        {item.label}
      </Checkbox>
    </Form.Item>
  ))

  const updateAdminInterface = async (values: any) => {
    const data = {
      name: values.adminInterfaceName,
      description: "",
      status: status ? 1 : 0,
      code: values.codeAdminInterface,
      logo: fileName ? fileName : null,
      color: {
        backgroundColor: bgColor,
        textColor: "",
        menuColor: sidebarColor
      },
      isSchool: tab === "1" ? false : true,
      isMinistry: tab === "1" ? true : false,
      enterpriseUuid: null,
      isGv: listUser[0].checked,
      isQtkh: listUser[1].checked,
      isQtcs: listUser[2].checked,
      isCapBo: tab === "1" ? listUser[3].checked : false,
      isToChuyenMon: tab === "1" ? listUser[4].checked : listUser[3].checked
    }

    await putSettingAdminInterface(dataUpdate?.id, data).then((res) => {
      if (res.status === 200) {
        message.success("Lưu thông tin thành công")
        handleClose()
        getListData()
        setReloadAdminInterface(!reloadAdminInterface)
      }
    })
  }

  const resetField = () => {
    form.resetFields()
    handleClose()
  }

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === 'form-setting-admin-interface') {
        Modal.confirm({
          title: `Xác nhận lưu giao diện quản trị`,
          content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu`,
          onOk: () => {
            updateAdminInterface(values)
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>

      <Drawer
        title={mode === 'view' ? 'Xem chi tiết' : "Cài đặt hoạt động"}
        placement={"right"}
        className='setting-admin-interface-drawer'
        width={600}
        onClose={resetField}
        open={open}
        extra={
          mode === "view" ? null :
          <Space>
            <Button onClick={resetField} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>
                Hủy
              </Typography.Text>
            </Button>
            <Button className="btn btn-primary" disabled={loading}
              onClick={() => form.submit()
              }>
              <SaveOutlined />
              <Typography.Text>
                Lưu
              </Typography.Text>
            </Button>
          </Space>
        }
      >
        <>
          <Form layout={"vertical"} form={form} name="form-setting-admin-interface">
            <Typography.Title level={5}>Giao diện quản trị</Typography.Title>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="adminInterfaceName" rules={[validation.adminInterfaceName]} label="Tên trang quản trị">
                  <FormInput
                    placeholder="Nhập tên trang quản trị"
                    disabled={mode === 'view'}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="codeAdminInterface" rules={[validation.codeAdminInterface]} label="Mã giao diện">
                  <FormInput
                    placeholder="Nhập mã giao diện"
                    disabled={mode === 'view'}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className='mt-2 flex'>
                <Form.Item name="urlAdminInterface" rules={[validation.urlAdminInterface]} label="Logo hệ thống" hasFeedback>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept='.png,.svg,.jpg,.jpeg'
                    onChange={handleChange}
                    maxCount={1}
                    disabled={mode === 'view'}
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%', height: "100%", objectFit: 'cover' }} /> : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={12} className='mt-2 flex'>
                <div className='flex flex-col'>
                  <Form.Item name="bgColor" label="Màu nền" hasFeedback>
                    <ColorPicker onChange={(c) => {
                      setBgColor(c.toHexString());
                    }} value={bgColor} size="large" showText disabled={mode === 'view'} />
                  </Form.Item>
                  {/* <Form.Item name="textColor" label="Màu chữ" hasFeedback>
                    <ColorPicker onChange={(c) => {
                      setTextColor(c.toHexString());
                    }} defaultValue={textColor} size="large" showText disabled={mode === 'view'} />
                  </Form.Item> */}
                  <Form.Item name="sidebarColor" label="Màu sidebar" hasFeedback>
                    <ColorPicker onChange={(c) => {
                      setSidebarColor(c.toHexString());
                    }} value={sidebarColor} size="large" showText disabled={mode === 'view'} />
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Typography.Title level={5}>Hoạt động</Typography.Title>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="status" valuePropName="checked" label="Trạng thái">
                  <Switch defaultChecked value={status} onChange={setStatus} disabled={mode === 'view'} />
                </Form.Item>
              </Col>
              <Col span={24} className='mt-2 flex'>
                <Form.Item name="urlApply" label="Đơn vị áp dụng" hasFeedback>
                  <Radio.Group defaultValue={1} disabled={mode === 'view'}
                  >
                    <Radio value={1} checked> {tab === '1' ? 'Cấp bộ' : 'Cấp trường'}</Radio>
                  </Radio.Group>
                </Form.Item>
                {
                  tab === "2" ?
                    <Form.Item name="school" label="" rules={[
                      {
                        required: tab === '2' ? true : false,
                        message: "Vui lòng chọn trường",
                      }
                    ]} className='w-3/5'>
                      <FormItemDropdown placeholder="Chọn trường"
                        options={listSchool} disabled={mode === 'view'} />
                    </Form.Item>
                    : null
                }
              </Col>

              <p className='ml-3 mb-[-15px]'>Nhóm người dùng áp dụng</p>
              <Col span={24} className='mt-2 flex'>
                <div className='flex flex-col'>
                  {renderGroupUser}
                </div>
              </Col>
            </Row>
          </Form>
        </>
      </Drawer>
    </Form.Provider >
  )
}