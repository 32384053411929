import {
  AppstoreOutlined,
  BarChartOutlined,
  DeleteOutlined,
  DownOutlined,
  DownloadOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import {
  Button,
  Col,
  Dropdown,
  Form,
  Layout,
  Select,
  Tabs,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import PageHeader from "../../../components/page-header/PageHeader";
import { getCourseManagementList } from "../../../service/course-management";
import {
  exportAllFeedbackAndEvaluation,
  exportAllResource,
  exportAllUserBehavior,
  getCourseLevel,
  getIndustryGroupFilter,
} from "../../../service/statistical-report";
import { staticReportStore } from "../../../stores/stores";
import OptionTimeModal from "./components/modal/OptionTimeModal";
import FeedbackAndEvaluationTab from "./components/tab/FeedbackAndEvaluationTab";
import ResourceTab from "./components/tab/ResourceTab";
import UserBehaviorTab from "./components/tab/UserBehaviorTab";
import "./style.scss";

type Props = {
  title: string;
};

const StatisticalReportForInstructors = (props: Props) => {
  const { title } = props;
  const {
    staticReportType,
    setStaticReportType,
    filterValues,
    setFilterValues,
    tab,
    setTab,
    callExportAllImages,
    isZipLoading,
    setIsZipLoading,
    exportType,
    setExportType,
  } = staticReportStore();
  const [form] = Form.useForm();
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [isTimeOptions, setIsTimeOptions] = useState<boolean>(false);
  const [courseManagementList, setCourseManagementList] = useState<any>([]);
  const [courseLevel, setCourseLevel] = useState<any>([]);
  const [industryGroupFilter, setIndustryGroupFilter] = useState<any>([]);
  const [selectionText, setSelectionText] = useState("");

  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const clearFilter = () => {
    form.resetFields();
    setFilterValues({
      industryGroupIds: [],
      courseLevelIds: [],
      courseIds: [],
      timeUnit: "day",
      dateFrom: dayjs().subtract(7, "day"),
      dateTo: dayjs(),
    });
  };

  const handleFilterSubmit = () => {
    form.submit();
    const values = form.getFieldsValue();
    setFilterValues({
      courseLevelIds: values.courseLevelIds || [],
      industryGroupIds: values.industryGroupIds || [],
      courseIds: values.courseIds || [],
    });
  };

  const handleTimeUnitChange = (value: string) => {
    let dateFrom,
      dateTo = dayjs();
    let timeUnit = "";

    if (value === "option") {
      setIsTimeOptions(true);
    } else {
      setIsTimeOptions(false);

      switch (value) {
        case "7day":
          dateFrom = dayjs().subtract(7, "day");
          timeUnit = "day";
          break;
        case "1month":
          dateFrom = dayjs().subtract(1, "month");
          timeUnit = "week";
          break;
        case "3month":
          dateFrom = dayjs().subtract(3, "month");
          timeUnit = "month";
          break;
        default:
          dateFrom = dayjs().subtract(7, "day");
      }

      setFilterValues({
        timeUnit: timeUnit,
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
    }

    setLastSelectedValue(value);
  };

  const onChangeTab = (key: string) => {
    setTab(key);
  };
  const [lastSelectedValue, setLastSelectedValue] = useState<string | null>(
    null
  );

  const handleReopenModal = () => {
    if (lastSelectedValue === "option") {
      setIsTimeOptions(true);
    }
  };

  const handleExportAll = async (type: string) => {
    if (staticReportType === "chart") {
      await callExportAllImages(tab);
    } else {
      const payloads = {
        from: dayjs(filterValues.dateFrom).toISOString(),
        to: dayjs(filterValues.dateTo).toISOString(),
        courseLevelIds: filterValues?.courseLevelIds,
        industryGroupIds: filterValues?.industryGroupIds,
        courseIds: filterValues?.courseIds,
        timeUnit: filterValues?.timeUnit,
        exportType: type === "excel" ? "excel" : "pdf",
      };
      try {
        setIsZipLoading(true);
        const exportApiUrl =
          tab === "1"
            ? exportAllResource({ ...payloads })
            : tab === "2"
            ? exportAllFeedbackAndEvaluation({
                ...payloads,
                moduleGroup: filterValues?.moduleGroup,
                classId: filterValues?.classId,
              })
            : tab === "3"
            ? exportAllUserBehavior({
                ...payloads,
                courseStructureType:
                  filterValues?.searchKeywordCourseStructureType,
                moduleGroup: filterValues?.searchKeywordModuleGroup,
                downloadModuleGroup: filterValues?.downloadModuleGroup,
                downloadCourseStructureType:
                  filterValues?.downloadCourseStructureType,
                viewModuleGroup: filterValues?.viewModuleGroup,
                viewCourseStructureType: filterValues?.viewCourseStructureType,
                shareModuleGroup: filterValues?.shareModuleGroup,
                shareCourseStructureType:
                  filterValues?.shareCourseStructureType,
              })
            : exportAllResource({ ...payloads });

        const res = await exportApiUrl;
        const fileName =
          tab === "1"
            ? `Tai-nguyen.${exportType}`
            : tab === "2"
            ? `Phan-hoi-danh-gia.${exportType}`
            : tab === "3"
            ? `Hanh-vi-nguoi-dung.${exportType}`
            : "excel.${type}";
        const fileData = new Blob([res.data], {
          type:
            type === "excel"
              ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              : "application/pdf",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(fileData);
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Error downloading Excel file:", error);
      } finally {
        setIsZipLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCourseManagementList({
          keyword: "",
          page: 1,
          size: 1000000,
          sort: [],
        });
        const { data } = response.data;
        setCourseManagementList(
          data?.content.map((it: any) => ({
            value: it.id,
            label: it.name,
          }))
        );

        const industryGroupFilter = await getIndustryGroupFilter();
        setIndustryGroupFilter(
          industryGroupFilter?.data.data.map((it: any) => ({
            value: it.id,
            label: it.name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchCourseLevel = async () => {
      try {
        const response = await getCourseLevel();
        const { data } = response.data;
        setCourseLevel(
          data?.map((it: any) => ({
            value: it.id,
            label: it.name,
          }))
        );

        const industryGroupFilter = await getIndustryGroupFilter();
        setIndustryGroupFilter(
          industryGroupFilter?.data.data.map((it: any) => ({
            value: it.id,
            label: it.name,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourseLevel();
  }, []);

  useEffect(() => {
    const timeUnitLabel =
      filterValues.timeUnit === "day"
        ? "Biểu đồ ngày"
        : filterValues.timeUnit === "week"
        ? "Biểu đồ tuần"
        : filterValues.timeUnit === "month"
        ? "Biểu đồ tháng"
        : filterValues.timeUnit === "year"
        ? "Biểu đồ năm"
        : "Chọn tùy chọn thời gian";

    const dateFrom = filterValues.dateFrom
      ? dayjs(filterValues.dateFrom).format("DD/MM/YYYY")
      : "";
    const dateTo = filterValues.dateTo
      ? dayjs(filterValues.dateTo).format("DD/MM/YYYY")
      : "";

    setSelectionText(`${timeUnitLabel} - từ ${dateFrom} đến ${dateTo}`);
  }, [filterValues]);

  const items: MenuProps["items"] =
    staticReportType === "chart"
      ? [
          {
            key: "1",
            label: (
              <div
                onClick={() => {
                  setExportType("png");
                  handleExportAll("png");
                }}
              >
                Hình ảnh (PNG)
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div
                onClick={() => {
                  setExportType("pdf");
                  handleExportAll("pdf");
                }}
              >
                PDF
              </div>
            ),
          },
        ]
      : [
          {
            key: "1",
            label: (
              <div
                onClick={() => {
                  setExportType("excel");
                  handleExportAll("excel");
                }}
              >
                Excel
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <div
                onClick={() => {
                  setExportType("pdf");
                  handleExportAll("pdf");
                }}
              >
                PDF
              </div>
            ),
          },
        ];

  return (
    <Layout className="page-header-group statistical-report-for-instructors">
      <Form form={form} layout="vertical" className="add-learning-materials">
        <div className="page-header-group--layout">
          <div className="table-header--wrapper justify-between pb-6">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <div className="flex gap-8">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>Bộ lọc</Typography.Text>
                <DownOutlined />
              </Button>

              {/* <Button
                onClick={handleExportAll}
                loading={isZipLoading}
                type="primary"
                className="export-btn flex-1"
              >
                <DownloadOutlined />
                Xuất dữ liệu
              </Button> */}
              <Dropdown menu={{ items }} placement="bottom">
                <Button type="primary" loading={isZipLoading}>
                  <DownloadOutlined />
                  Xuất dữ liệu
                </Button>
              </Dropdown>
            </div>
          </div>

          {isOpenFilter && (
            <div className="flex flex-wrap gap-8">
              <Col span={6} className="filter-select">
                <Typography.Title level={5}>Cấp</Typography.Title>
                <Form.Item name="courseLevelIds">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Chọn cấp"
                    options={courseLevel}
                  />
                </Form.Item>
              </Col>
              <Col span={6} className="filter-select">
                <Typography.Title level={5}>Khoa</Typography.Title>
                <Form.Item name="industryGroupIds">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Chọn khoa"
                    options={industryGroupFilter}
                  />
                </Form.Item>
              </Col>
              <Col span={6} className="filter-select">
                <Typography.Title level={5}>Khoá học</Typography.Title>
                <Form.Item name="courseIds">
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Chọn khoá"
                    options={courseManagementList}
                  />
                </Form.Item>
              </Col>
              <div>
                <Typography.Title level={5}>&nbsp;</Typography.Title>
                <div className="group-btn--filter gap-8">
                  <Button
                    className="btn btn-primary search-btn"
                    htmlType="submit"
                    onClick={handleFilterSubmit}
                  >
                    <FilterOutlined />
                    <Typography.Text>Lọc</Typography.Text>
                  </Button>
                  <Button
                    className="btn btn-outlined clear-filter-btn"
                    onClick={clearFilter}
                  >
                    <DeleteOutlined />
                    <Typography.Text>Xóa bộ lọc</Typography.Text>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <Tabs
          defaultActiveKey="1"
          onChange={onChangeTab}
          destroyInactiveTabPane={true}
          items={[
            {
              label: "Tài nguyên",
              key: "1",
              children: <ResourceTab />,
            },
            {
              label: "Phản hồi & đánh giá",
              key: "2",
              children: <FeedbackAndEvaluationTab />,
            },
            {
              label: "Hành vi người dùng",
              key: "3",
              children: <UserBehaviorTab />,
            },
          ]}
          tabBarExtraContent={
            <div className="flex align-center gap-8">
              <div>{selectionText}</div>
              <Form.Item name="timeUnit">
                <Select
                  defaultValue={"7day"}
                  style={{ width: 120 }}
                  onSelect={(value) => {
                    if (value === "option" && lastSelectedValue === "option") {
                      handleReopenModal();
                    } else {
                      handleTimeUnitChange(value);
                    }
                  }}
                  options={[
                    { value: "7day", label: "7 Ngày" },
                    { value: "1month", label: "1 Tháng" },
                    { value: "3month", label: "3 Tháng" },
                    { value: "option", label: "Tuỳ chọn" },
                  ]}
                  variant="borderless"
                />
              </Form.Item>
              <Button.Group className="static-button-group">
                <Button
                  type={staticReportType === "chart" ? "primary" : "text"}
                  onClick={() => setStaticReportType("chart")}
                >
                  <BarChartOutlined />
                </Button>
                <Button
                  type={staticReportType === "table" ? "primary" : "text"}
                  onClick={() => setStaticReportType("table")}
                >
                  <AppstoreOutlined />
                </Button>
              </Button.Group>
            </div>
          }
        />
      </Form>
      <OptionTimeModal
        open={isTimeOptions}
        handleCancel={() => setIsTimeOptions(false)}
      />
    </Layout>
  );
};

export default StatisticalReportForInstructors;
