import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import {
  TypeCourseDescriptionDocument,
  TypeCourseStructure,
} from "../../../../../types/course-plan-managment/mooc-course-construction-plan";
import CustomCard from "../../../../../components/custom-card/CustomCard";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  downloadFile,
  upLoadFileDescriptionDocument,
} from "../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";

import axios from "axios";

interface DescriptionDocumentProps {
  listChapter: TypeCourseStructure[];
  dataDescriptionDocument: TypeCourseDescriptionDocument[];
}

const PreviewDescriptionDocument: React.FC<DescriptionDocumentProps> = ({
  listChapter,
  dataDescriptionDocument,
}) => {
  const dataListChapter = useMemo(() => listChapter, [listChapter]);

  const { id } = useParams();

  const dataListDocument = useMemo(
    () => dataDescriptionDocument,
    [dataDescriptionDocument]
  );

  const handleShowDocument = async (indexDocument: number) => {
    const document = dataListDocument[indexDocument];
    const res = await downloadFile({
      filePath: document.filePath,
      destination: "",
    });
    const path = res.data;
    if (path && path !== "") {
      // Mở một tab mới trên trình duyệt với đường dẫn của filePath
      window.open(path, "_blank");
    } else {
      // Nếu không có đường dẫn filePath, bạn có thể hiển thị thông báo hoặc xử lý tùy ý
      console.log("Không có đường dẫn tài liệu.");
    }
  };

  const handleDownload = async (indexDocument: number) => {
    const descriptionDocument = dataListDocument[indexDocument];
    if (descriptionDocument.filePath && descriptionDocument.filePath !== "") {
      try {
        console.log(
          `descriptionDocument.filePath::`,
          descriptionDocument.filePath
        );
        downloadFile({
          filePath: descriptionDocument.filePath,
          destination: "",
        })
          .then(async (res: any) => {
            console.log(`firstLink::`, res.data);
            const response = await axios.get(res.data, {
              responseType: "arraybuffer", // Thay đổi responseType thành "arraybuffer"
            });

            // console.log(`response::`, response)

            // Tạo Blob từ ArrayBuffer và định dạng file từ đuôi mở rộng của file
            const blob = new Blob([response.data], {
              type: descriptionDocument.fileType,
            });

            // Tạo URL tạm thời cho blob
            const url = URL.createObjectURL(blob);

            // Tạo một thẻ <a> ẩn để tải file
            const downloadLink = document.createElement("a");
            downloadLink.href = url;
            downloadLink.setAttribute(
              "download",
              `${descriptionDocument.fileName}.${descriptionDocument.fileType}` ||
                ""
            );
            message.success(
              `Đang tải về! Vui lòng kiểm tra file trong thư mục trên máy tính!`
            );

            // Thêm thẻ <a> vào body để kích hoạt sự kiện click
            document.body.appendChild(downloadLink);

            // Kích hoạt sự kiện click để tải file
            downloadLink.click();

            // Loại bỏ thẻ <a> sau khi đã hoàn tất tải file và giải phóng URL tạm thời
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(url);
          })
          .catch((error) => {
            message.error("Lỗi khi tải file:", error);
          });
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    } else {
      console.log("Không có đường dẫn tài liệu.");
    }
  };

  return (
    <Space size="middle" direction="vertical">
      <CustomCard>
        <Row justify="space-between" style={{ marginTop: "20px" }}>
          <Col>
            <Row>
              <Typography.Title level={4}>Tài liệu mô tả</Typography.Title>
            </Row>
            <Row>
              <Typography.Text style={{ color: "GrayText" }}>
                Danh sách tài liệu đính kèm mô tả đăng ký kế hoạch xây dựng khóa
                học
              </Typography.Text>
            </Row>
          </Col>
        </Row>
        <Divider style={{ marginTop: "5px" }} />
        {dataListDocument &&
          dataListDocument.map((document, indexDocument) => {
            return (
              <div key={indexDocument}>
                <Row justify="space-between" style={{ marginTop: "20px" }}>
                  <Col>
                    <Space direction="horizontal" size="large">
                      <Input
                        style={{ minWidth: "250px" }}
                        variant={!document.fileName ? undefined : "borderless"}
                        value={document.fileName}
                        placeholder="Nhập tên tài liệu"
                        readOnly
                      />

                      <Input
                        style={{ minWidth: "300px" }}
                        value={
                          dataListChapter.find(
                            (chapter) =>
                              chapter.id === document.courseStructureItemId
                          )?.structureItemName
                        }
                        variant="borderless"
                        readOnly
                      />
                    </Space>
                  </Col>
                  <Col>
                    <Space direction="horizontal" size="small">
                      {document.filePath && document.filePath !== "" && (
                        <>
                          <Button
                            onClick={() => handleShowDocument(indexDocument)}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "transparent",
                            }}
                          >
                            <Typography.Text style={{ color: "#5e87ff" }}>
                              Xem
                            </Typography.Text>
                          </Button>
                          <Button
                            onClick={() => handleDownload(indexDocument)}
                            style={{
                              border: "none",
                              outline: "none",
                              background: "transparent",
                            }}
                          >
                            <Typography.Text style={{ color: "#5e87ff" }}>
                              Tải về
                            </Typography.Text>
                          </Button>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ marginTop: "5px" }} />
              </div>
            );
          })}
      </CustomCard>
    </Space>
  );
};

export default PreviewDescriptionDocument;
